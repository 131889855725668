import React from "react";

import { Button } from "components/Button";

import * as S from "./styles";

type OptionsProps = {
  [key: string]: string;
};

type OptionsIconProps = {
  [key: string]: SVGElement;
};

type ButtonOptionsFilter = {
  title?: string;
  data: OptionsProps;
  optionsSelected: string[];
  handlePressButton: (itemSelected: string) => void;

  isIconButton?: boolean;
  iconsData?: OptionsIconProps | any;
};

export function ButtonFaqFilter({
  title = "",
  data,
  optionsSelected,
  handlePressButton,
  isIconButton,
  iconsData,
}: ButtonOptionsFilter) {
  return (
    <S.Container>
      {!!title && <S.Title>{title}</S.Title>}

      <S.Content>
        {Object.keys(data).map((currentItem: string) => (
          <S.Wrapper key={currentItem} type={isIconButton}>
            <Button
              children={data[currentItem]}
              isIcon={isIconButton ? iconsData[currentItem] : null}
              typeButton={
                optionsSelected.includes(currentItem)
                  ? "financialSelected"
                  : "financialNotSelected"
              }
              onClick={() => handlePressButton(currentItem)}
            />
          </S.Wrapper>
        ))}
      </S.Content>
    </S.Container>
  );
}
