import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";

import { ProfileCertificationsShimmer } from "Shimmer/ProfileCertificationsShimmer";
import {
  CertificationCard,
  CertificationProps,
} from "components/CertificationCard";
import { ButtonPlusDashed } from "components/ButtonPlusDashed";

import { api } from "../../../../services/api";
import * as S from "./styles";

export function ProfileCertifications() {
  const history = useHistory();

  const [isLoading, setIsLoading] = useState(true);
  const [previewCertifications, setPreviewCertifications] = useState<
    CertificationProps[]
  >([]);

  useEffect(() => {
    async function loadCertifications() {
      try {
        const response = await api.get("me/certifications", {
          params: {
            status: "approved",
          },
        });

        const sliceCertifications = response.data.certifications.slice(0, 3);
        setPreviewCertifications(sliceCertifications);
      } catch (error) {
        // sendError(error)
        // showToast({
        //   message: 'Ops!!',
        //   description: 'Ocorreu um erro ao listar as marcas',
        //   type: 'danger'
        // })
      } finally {
        setIsLoading(false);
      }
    }

    loadCertifications();
  }, []);

  function handleNavigateCertifications() {
    history.push("/certifications");
  }

  function handleNavigateCreateCertification() {
    history.push("certifications/create");
  }

  function handleNavigateToCertificationDetails(
    certification: CertificationProps
  ) {
    history.push({
      pathname: "certification-details",
      state: { certification },
    });
  }

  return (
    <S.Container>
      <h2>Certificações</h2>

      <S.Content>
        {isLoading && <ProfileCertificationsShimmer />}

        {!isLoading && (
          <>
            {previewCertifications.map((certification) => (
              <CertificationCard
                style={{marginRight: 20}}
                key={certification.id}
                data={certification}
                onClick={() =>
                  handleNavigateToCertificationDetails(certification)
                }
              />
            ))}

            <ButtonPlusDashed
              label="Adicionar certificado"
              onClick={() => handleNavigateCreateCertification()}
            />
          </>
        )}
      </S.Content>

      {!isLoading && previewCertifications.length > 1 && (
        <S.WrapperButton>
          <button type="button" onClick={() => handleNavigateCertifications()}>
            ver todas
          </button>
        </S.WrapperButton>
      )}
    </S.Container>
  );
}
