import styled, { css } from "styled-components";

type ModalProps = {
  isVisible: boolean;
};

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 29px;
`;

export const Title = styled.div`
  ${({ theme }) => css`
    color: #fff;
    font-weight: 700;
    font-size: 18px;
    line-height: 22px;
    margin-right: auto;
    margin-bottom: 50px;
  `};
`;

export const TextWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  width: 100%;
  margin-top: 50px;
  margin-bottom: 20px;
`;

export const Description = styled.div`
  ${({ theme }) => css`
    color: rgba(255, 255, 255, 0.6);
    font-weight: 400;
    font-size: 15px;
    line-height: 20px;
  `}
`;

export const CardTitle = styled.div`
  ${({ theme }) => css`
    color: rgba(255, 255, 255, 0.87);
    font-weight: 400;
    font-size: 15px;
    line-height: 20px;
    margin-top: 20px;
    margin-right: auto;
  `}
`;

export const Negrito = styled.strong`
  ${({ theme }) => css`
    color: rgba(255, 255, 255, 0.87);
    font-weight: 700;
    font-size: 15px;
    line-height: 15px;
  `}
`;

export const Card = styled.div`
  ${({ theme }) => css`
    background-color: ${theme.colors.background_light};
    width: 100%;
    padding: 20px;
    border-radius: 10px;
    margin-top: 10px;
    transition: 0.2s ease all;
    cursor: pointer;

    :hover {
      background-color: ${theme.colors.blue_opacity};
    }
  `};
`;

export const CardHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const Dot = styled.div`
  ${({ theme }) => css`
    width: 5px;
    height: 5px;
    border-radius: 10px;
    background-color: ${theme.colors.error};
    margin-right: 10px;
  `}
`;

export const ButtonsWrapper = styled.div`
  width: 100%;
  margin-top: 100px;
`;

export const BackButton = styled.button`
  ${({ theme }) => css`
    width: 35px;
    height: 35px;
    background-color: ${theme.colors.background_light};
    border-radius: 10px;

    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 25px;
  `}
`;

export const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 20px;
`;

export const EditButtonWrapper = styled.button`
  ${({ theme }) => css`
    width: 40px;
    height: 40px;
    border-radius: 10px;

    display: flex;
    justify-content: center;
    align-items: center;

    border: 1px solid ${theme.colors.primary};

    & > img {
      width: 15px;
      height: 15px;
    }
  `}
`;

export const ImagesList = styled.div`
  width: 100%;
  overflow: auto;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
`;

export const ImageTag = styled.img`
  width: 100px;
  height: 100px;
  border-radius: 10px;
  margin-top: 10px;
  margin-right: 10px;
  object-fit: cover;
`;

export const DownloadQr = styled.img`
  margin-top: 30px;
  margin-bottom: 30px;
  margin-right: auto;
  cursor: pointer;
`;

export const ButtonCalendar = styled.button`
  cursor: pointer;
  & > img {
    width: 40px;
    height: 40px;
  }
`;

export const ScheduleWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
`;
export const ContainerOverlay = styled.aside<ModalProps>`
  ${({ theme, isVisible }) => css`
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;

    display: ${isVisible ? "flex" : "none"};
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: ${theme.layers.alwaysOnTop};
  `}
`;

export const RowButtons = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  width: 80%;
  padding-bottom: 20px;
`;

export const CalendarCancelButton = styled.div`
  ${({ theme }) => css`
    padding: 5px 20px;
    border: 1px solid ${theme.colors.primary};
    border-radius: 10px;
    color: ${theme.colors.title};
    width: 180px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
  `}
`;

export const CalendarApproveButton = styled.div`
  ${({ theme }) => css`
    cursor: pointer;
    padding: 5px 20px;
    background-color: ${theme.colors.primary};
    color: ${theme.colors.background};
    border-radius: 10px;
    width: 180px;
    display: flex;
    justify-content: center;
    align-items: center;
  `}
`;

export const CalendarWrapper = styled.div`
  ${({ theme }) => css`
    margin-top: 30px;
    background-color: ${theme.colors.background_modal};
    border-radius: 30px;
    width: 500px;
    display: flex;
    flex-direction: column;
    align-items: center;

    .DayPicker {
      width: 100%;
      margin-left: 20px;
    }

    .DayPicker-Day,
    .DayPicker-Day--selected {
      padding: 0;
      height: 35px;
      width: 35px;
      border-radius: 100%;
    }

    .DayPicker,
    .DayPicker-Month {
      width: 94% !important;
      border-spacing: 20px;
    }
  `}
`;

export const WrapperInput = styled.div`
  ${({ theme }) => css`
    width: 80%;

    input {
      border: 0.5px solid;
      border-color: ${theme.colors.text};
      border-radius: 8px;
    }

    input[type="time"]::-webkit-calendar-picker-indicator {
      filter: invert(1);
    }
  `}
`;

export const Error = styled.div`
  ${({ theme }) => css`
    color: ${theme.colors.error};
    margin-bottom: 20px;
  `};
`;
