import React, { useCallback, useEffect, useState } from "react";
import logo from "assets/icons/logo-color.svg";
import profile from "assets/icons/profile.svg";
import { FilterProps, Filters } from "./Filters";

import * as S from "./styles";
import { Button } from "components/Button";
import { TablePagination } from "components/TablePagination";
import { api } from "services/api";
import { useHistory, useParams } from "react-router";
import { PmocClientDetailResponseDTO } from "dtos/PmocClientDetailResponseDTO";
import { LoadingProfiz } from "components/LoadingProfiz";
import { format } from "date-fns";
import { addMaskPhone } from "utils/addMaskPhone";
import { formattedCpfCnpj } from "utils/formatedCpfCpnj";
import { ModalRight } from "components/ModalRight";
import ButtonClose from "assets/icons/buttonClose.svg";
import { RadioButtonContainer } from "components/ButtonCheckbox2";
import {
  RadioBox,
  TypeResponseProps,
} from "components/ButtonCheckbox2/RadioBox";
import { useToast } from "hooks/toast";
import { AttachmentArea } from "pages/pmoc/PmocRegisterForm/AttachmentArea";
import { usePmoc } from "hooks/usePmoc";

interface PayloadFilters {
  environment?: TypeResponseProps;
  status?: TypeResponseProps;
  period?: FilterProps;
}

const Details: React.FC = () => {
  const {
    pmocArtFile,
    handleSetPmocArtFile,
  } = usePmoc();

  const { hash }: { hash: string } = useParams();
  const { addToast } = useToast();
  const [details, setDetails] = useState<PmocClientDetailResponseDTO>();
  const [totalItems, setTotalItems] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [offset, setOffset] = useState(0);
  const [isLoading, setLoading] = useState(false);
  const history = useHistory();
  const [openModal, setOpenModal] = useState(false);

  const [typeEnvironment, setTypeEnvironment] = useState<TypeResponseProps>();
  const [typeStatus, setTypeStatus] = useState<TypeResponseProps>();
  const [getTypeFilter, setGetTypeFilter] = useState<FilterProps>();

  const [payloadFilters, setPayloadFilters] = useState<PayloadFilters>();
  const [initialDate, setInitialDate] = useState("");
  const [finalDate, setEndlDate] = useState("");

  const limit = 10;

  const fetchData = useCallback(() => {
    const pmocUserClientPass = sessionStorage.getItem("pmocUserClientPass");

    if (!pmocUserClientPass) {
      history.push(`/public/pmoc/view/client/${hash}`);
      return;
    }

    setLoading(true);

    api
      .get(`pmoc/view/client/detail/${hash}`, {
        headers: {
          pmocUserClientPass,
        },

        params: {
          limit,
          offset,
          month: getTypeFilter?.month,
          year: getTypeFilter?.year,
          ...(getTypeFilter?.initialDate &&
            getTypeFilter?.finalDate && {
            initialDate: getTypeFilter?.initialDate,
            finalDate: getTypeFilter?.finalDate,
          }),
          environmentId: payloadFilters?.environment?.value,
          ...(payloadFilters?.status?.value !== "archived"
            ? { status: payloadFilters?.status?.value }
            : { archive: true }),
        },
      })
      .then((response) => {
        setDetails(response.data);

        handleSetPmocArtFile({
          url: response.data.pmoc.art,
        })

        setTotalItems(response.data.tableData.info?.total);

        setLoading(false);
      })
      .catch((err) => {
        history.push(`/public/pmoc/view/client/${hash}`);

        addToast({
          title: "Ops!!",
          description: err.response?.data?.error,
          type: "error",
        });
      });
  }, 
  // eslint-disable-next-line
  [
    addToast,
    getTypeFilter?.month,
    getTypeFilter?.year,
    hash,
    history,
    offset,
    payloadFilters?.environment?.value,
    payloadFilters?.status?.value,
    getTypeFilter?.initialDate,
    getTypeFilter?.finalDate,
  ]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  useEffect(() => {
    if (initialDate && finalDate) {
      setGetTypeFilter({
        initialDate,
        finalDate,
      });
    }
  }, [initialDate, finalDate, setGetTypeFilter]);

  const downloadPdf = async (detailLink?: string) => {
    history.push({
      pathname: "/public/download/os/details",

      state: {
        pdfDownloadLink: detailLink,
      },
    });
  };

  function handlePageChange(pageNumber: number, itemsPerPage: number) {
    setCurrentPage(pageNumber);
    setOffset((pageNumber - 1) * itemsPerPage);
  }

  function pluralTreatment(periodContract?: number, typeContract?: string) {
    if (periodContract && typeContract === "anos") {
      return periodContract > 1 ? `anos` : `ano`;
    }

    if (periodContract) return periodContract > 1 ? `meses` : `mês`;
  }

  const cleanFilters = () => {
    setTypeEnvironment(undefined);
    setTypeStatus(undefined);
    setPayloadFilters(undefined);
  };

  const handleApplyFilterInRequest = () => {
    setPayloadFilters({
      environment: typeEnvironment,
      status: typeStatus,
      period: getTypeFilter,
    });
    setOpenModal(false);
  };

  // concluida = statusOs: concluded
  // nao agendada = statusOs: "" || new
  // agendada = statusOs: scheduled

  if (!details) {
    return <LoadingProfiz isVisible positionLogo="40%" />;
  }

  return (
    <S.Container>
      <S.Header>
        <div>
          <img src={logo} alt="logo profiz" />

          <div className="pmoc-info">
            <h2>PMOC - {details?.pmoc.name}</h2>
            <span>
              <p>
                Início:{" "}
                {details?.pmoc?.initialDate &&
                  format(new Date(details.pmoc.initialDate), "dd/MM/yyyy")}{" "}
                | Período: {details?.pmoc.period}{" "}
                {pluralTreatment(
                  details?.pmoc.period,
                  details?.pmoc.periodType
                )}
              </p>
            </span>
          </div>
        </div>

        <div className="pmoc-info-address">
          <h3>
            {details?.unity.unityName} (
            {details?.unity.default ? "Principal" : "Adicional"})
          </h3>
          <span>
            <p>
              {details?.unity.street}, {details?.unity.number}
              {details?.unity.district &&
                ` - ${details?.unity.district}`} - {details?.unity.city} -
              {details?.unity.uf}
            </p>
          </span>
        </div>

        <div>
          <img className="profile" src={profile} alt="logo profiz" />

          <div className="pmoc-info">
            <h3>{details?.technicalManager.name}</h3>
            <span>
              <p>
                {details?.technicalManager.document?.cpf_cnpj &&
                  ` ${formattedCpfCnpj({
                    type: details?.technicalManager.document.type as
                      | "cpf"
                      | "cnpj",
                    str: details?.technicalManager.document.cpf_cnpj,
                  })} |`}{" "}
                {details?.technicalManager.phoneNumber &&
                  addMaskPhone(details?.technicalManager.phoneNumber)}
              </p>
            </span>
          </div>
        </div>

        <Button
          onClick={() => {
            setOpenModal(true);
          }}
          className="btn"
          typeButton="outline"
        >
          Filtrar
        </Button>
      </S.Header>

      <S.WrapperHeader>
        <S.Filters>
          <Filters
            finalDate={finalDate}
            initialDate={initialDate}
            setEndDate={setEndlDate}
            setInitialDate={setInitialDate}
            cleanFilters={cleanFilters}
            getTypeFilter={setGetTypeFilter}
          />

          {payloadFilters?.environment && (
            <S.EnvironmentStatusFilter>
              {payloadFilters?.environment.type}{" "}
              <button
                onClick={() => {
                  setTypeEnvironment(undefined);
                  setPayloadFilters({
                    ...payloadFilters,
                    environment: undefined,
                  });
                }}
              >
                x
              </button>
            </S.EnvironmentStatusFilter>
          )}

          {payloadFilters?.status && (
            <S.EnvironmentStatusFilter>
              {payloadFilters?.status?.type}{" "}
              <button
                onClick={() => {
                  setTypeStatus(undefined);
                  setPayloadFilters({
                    ...payloadFilters,
                    status: undefined,
                  });
                }}
              >
                x
              </button>
            </S.EnvironmentStatusFilter>
          )}

        </S.Filters>
        {pmocArtFile?.url?.length > 0 && (
          <S.WrapperAttachmentArea>
            <AttachmentArea showRemove={false} />
          </S.WrapperAttachmentArea>
        )}
      </S.WrapperHeader>
      {isLoading ? (
        <LoadingProfiz isVisible positionLogo="40%" />
      ) : (
        <>
          <S.Table>
            <table>
              <thead>
                <tr>
                  <th>TAG</th>
                  <th> Ambiente </th>
                  <th> Equipamento </th>
                  <th> Serviços </th>
                  <th> Status </th>
                  <th> Link da ordem de seriço </th>
                </tr>
              </thead>
              <tbody>
                {details?.tableData.data.map((data, index) => {
                  return (
                    <tr key={index}>
                      <td>{data.equipmentTag}</td>
                      <td>{data.environmentName}</td>
                      <td>{data.equipmentName}</td>
                      <td>{data.serviceType} </td>
                      <td>
                        <S.Dots status={data.statusOs as "concluded"} />
                        {!data.statusOs || data.statusOs === "new"
                          ? "Não Agendado"
                          : data.statusOs === "concluded"
                            ? "Concluído"
                            : "Agendado"}
                      </td>
                      <td>
                        {data.detailLink && (
                          <span onClick={() => downloadPdf(data?.detailLink)}>
                            Download da ordem de serviço
                          </span>
                        )}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </S.Table>
          <S.Pagination>
            <TablePagination
              rowsLength={totalItems}
              rowsPerPage={limit}
              rowsPage={currentPage}
              handlePageChange={(pageNumber, itemsPerPage) =>
                handlePageChange(pageNumber, itemsPerPage)
              }
            />
          </S.Pagination>
        </>
      )}

      <ModalRight
        minWidth="initial"
        isOpen={openModal}
        handleToggleOpen={() => {
          setOpenModal(!openModal);
        }}
      >
        <S.ContainerModal>
          <S.ButtonClose
            onClick={() => {
              setOpenModal(false);
            }}
          >
            <img src={ButtonClose} alt="button close" />
          </S.ButtonClose>

          <h1>Filtrar</h1>

          <S.Form>
            <h3>Ambinete</h3>

            <RadioButtonContainer>
              {details?.environmentFilters.map((environment) => (
                <RadioBox
                  value={environment.id.toString()}
                  name={environment.name}
                  setType={setTypeEnvironment}
                  type={typeEnvironment as TypeResponseProps}
                />
              ))}
            </RadioButtonContainer>
          </S.Form>

          <S.Form>
            <h3>Status</h3>

            <RadioButtonContainer>
              {[
                { id: "new", name: "Não agendado" },

                { id: "scheduled", name: "Agendado" },
                { id: "concluded", name: "Concluído" },
              ].map((environment) => (
                <RadioBox
                  value={environment.id.toString()}
                  name={environment.name}
                  setType={setTypeStatus}
                  type={typeStatus as TypeResponseProps}
                />
              ))}
            </RadioButtonContainer>
          </S.Form>

          <Button
            onClick={handleApplyFilterInRequest}
            style={{ marginTop: "30px" }}
          >
            Filtrar
          </Button>
        </S.ContainerModal>
      </ModalRight>
    </S.Container>
  );
};
export { Details };
