import styled, { css, keyframes } from "styled-components";
import { darken, lighten } from "polished";

type ModalProps = {
  isVisible: boolean;
};

const ModalScale = keyframes`
  from {
    transform: scale(0.5);
    opacity: 0.4;
  }
  to {
    transform: scale(1);
    opacity: 1;
  }
`;

export const ContainerOverlay = styled.aside<ModalProps>`
  ${({ theme, isVisible }) => css`
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;

    display: ${isVisible ? "flex" : "none"};
    justify-content: center;
    align-items: center;
    z-index: ${theme.layers.alwaysOnTop};
    background-color: rgba(0, 0, 0, 0.4);
  `}
`;

export const BoxModal = styled.div`
  ${({ theme }) => css`
    width: 90%;
    height: 93%;
    max-width: 1200px;
    border-radius: 8px;

    display: flex;
    flex-direction: column;
    align-items: center;

    background-color: ${darken(0.2, "rgba(255, 255, 255, 0.60)")};
    animation: ${ModalScale} 0.5s ease-in-out;
  `}
`;

export const Header = styled.div`
  width: 98%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 18px;

  & > button {
    padding: 2px;
    border-radius: 6px;

    display: flex;
    justify-content: center;
    align-items: center;
    transition: background 0.2s;

    &:hover {
      background: ${({ theme }) => lighten(0.1, "rgba(255, 255, 255, 0.05)")};
    }
  }
`;

export const Main = styled.main`
  width: 90%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 18px;
`;

export const Image = styled.img`
  object-fit: contain;
  border-radius: 8px;

  height: 50vh;
  width: 100vw;
`;

export const ButtonArrow = styled.button`
  height: 50px;
  width: 40px;

  position: absolute;
  top: 44%;

  display: flex;
  justify-content: center;
  align-items: center;
  transition: opacity 0.2s;

  &:hover {
    opacity: 0.6;
  }
`;

export const ButtonArrowPrev = styled(ButtonArrow)`
  left: 0;
  z-index: ${({ theme }) => theme.layers.alwaysOnTop};
`;

export const ButtonArrowNext = styled(ButtonArrow)`
  right: 0;
  z-index: ${({ theme }) => theme.layers.alwaysOnTop};
`;
