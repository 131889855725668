/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";

import { Input } from "components/Input";
import { ModalRight } from "components/ModalRight";
import { ButtonCheckbox } from "components/ButtonCheckbox";
import { ButtonModalAction } from "components/ButtonModalAction";
import { ModalApprovedServiceOrders } from "components/ModalApprovedServiceOrders";
import { EditButton } from "components/EditButton";
import { DeleteButton } from "components/DeleteButton";

import { useSelectedServiceOrder } from "hooks/selectedServiceOrder";

import * as S from "./styles";
import { useClient } from "hooks/budget/client";

type Props = {
  nfInputValue: string;
  onChangeInput: (value: string) => void;
  hasErrorInput: string;
  onGetLinkedDocument: (type: "NF" | "OS") => void;
};

export function ServiceOrderOrReceiptArea({
  nfInputValue,
  onChangeInput,
  hasErrorInput,
  onGetLinkedDocument,
}: Props) {
  const {
    selectedServiceOrder,
    handleClearSelectedServiceOrder,
    hasSelectedServiceOrder,
  } = useSelectedServiceOrder();

  const { client } = useClient();

  const [isVisibleModal, setIsVisibleModal] = useState(false);
  const [checkboxOptionSelected, setCheckboxOptionSelected] = useState([
    {
      type: "NF",
      isSelected: true,
    },
    {
      type: "OS",
      isSelected: false,
    },
  ]);

  const [nfSelected, osSelected] = checkboxOptionSelected.map(
    ({ isSelected }) => isSelected
  );

  function handleToggleCheckbox(type: "NF" | "OS") {
    const newCheckboxOptionSelected = checkboxOptionSelected.map((option) => {
      if (option.type === type) {
        return {
          ...option,
          isSelected: true,
        };
      }
      return {
        ...option,
        isSelected: false,
      };
    });
    setCheckboxOptionSelected(newCheckboxOptionSelected);
    onGetLinkedDocument(type);
  }

  function handleToggleModal() {
    setIsVisibleModal(!isVisibleModal);
  }

  useEffect(() => {
    if(!client.id) {
      handleClearSelectedServiceOrder();
    }
  }, [client])

  return (
    <S.Container>
      <ModalRight isOpen={isVisibleModal} handleToggleOpen={handleToggleModal}>
        <ModalApprovedServiceOrders handleToggleOpen={handleToggleModal} />
      </ModalRight>

      <S.Label>Vincular a esta ordem de serviço (opcional)</S.Label>
      <S.WrapperCheckbox>
        <ButtonCheckbox
          placeholder="Nota fiscal / Ref."
          isChecked={nfSelected}
          onClick={() => handleToggleCheckbox("NF")}
        />

        <ButtonCheckbox
          placeholder="Ordem de serviço"
          isChecked={osSelected}
          onClick={() => handleToggleCheckbox("OS")}
        />
      </S.WrapperCheckbox>

      {nfSelected && (
        <S.Wrapper>
          <Input
            placeholder="Digite o número da NF ou código de referência"
            value={nfInputValue}
            onChange={({ target }) => onChangeInput(target.value.trimStart())}
            hasError={hasErrorInput}
          />
        </S.Wrapper>
      )}

      {osSelected && !hasSelectedServiceOrder && (
        <S.Wrapper>
          <ButtonModalAction
            label=""
            placeholder="Selecione uma ordem de serviço"
            isSelected={isVisibleModal}
            onClick={handleToggleModal}
            disabled={!client.id}
          />
          {!client.id && (
            <S.ErrorText>Selecione um cliente antes</S.ErrorText>
          )}
        </S.Wrapper>
      )}

      {hasSelectedServiceOrder && osSelected && (
        <S.CardServiceOrderSelected>
          <div>
            <S.TitleCard>
              {selectedServiceOrder.id} - {selectedServiceOrder.client?.name}
            </S.TitleCard>

            {selectedServiceOrder.services?.map((currentService) => (
              <S.CardTextDetail key={currentService.idBudgetService}>
                {currentService.service}
              </S.CardTextDetail>
            ))}
          </div>
          <div>
            <EditButton onClick={handleToggleModal} />
            <DeleteButton onClick={handleClearSelectedServiceOrder} />
          </div>
        </S.CardServiceOrderSelected>
      )}
    </S.Container>
  );
}
