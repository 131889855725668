import React, { createContext, useContext, useState } from "react";
import addMaskMoney from "../../utils/addMaskMoney";
import { LocalChecklistProps } from "../../dtos/LocalChecklistDTO";
import { ProviderProps } from "hooks";

type SelectedChecklistContextData = {
  selectedChecklistItems: LocalChecklistProps[];
  handleSetSelectedChecklist: (item: LocalChecklistProps[]) => void;
  handleUpdateQuantity: (id: number, quantity: number) => void;
  calculateTotalChecklists: () => number;
  clearChecklistsData: () => void;
  removeItemChecklist: (id: number) => void;
};

const SelectedChecklistContext = createContext<SelectedChecklistContextData>(
  {} as SelectedChecklistContextData
);

export function useSelectedChecklist(): SelectedChecklistContextData {
  const context = useContext(SelectedChecklistContext);

  if (!context) {
    throw Error(
      "useSelectedChecklist must be used within a SelectedChecklistProvider"
    );
  }

  return context;
}

export const SelectedChecklistProvider = ({ children }: ProviderProps) => {
  const [selectedChecklistItems, setSelectedChecklistItems] = useState<
    LocalChecklistProps[]
  >([]);

  function handleSetSelectedChecklist(item: LocalChecklistProps[]) {
    setSelectedChecklistItems(item);
  }

  function handleUpdateQuantity(id: number, quantity: number) {
    const updatedSelectedChecklist = selectedChecklistItems.map(
      (selectedChecklist: LocalChecklistProps) => {
        if (selectedChecklist.id === id) {
          return {
            ...selectedChecklist,
            quantity,
            total: selectedChecklist.value * quantity,
            formattedTotal: addMaskMoney(selectedChecklist.value * quantity),
          };
        }

        return selectedChecklist;
      }
    );

    setSelectedChecklistItems(updatedSelectedChecklist);
  }

  function calculateTotalChecklists() {
    return selectedChecklistItems.reduce((accumulator, current) => {
      if (current.type === "my_responsibility") {
        return accumulator + current.total;
      }
      return accumulator;
    }, 0);
  }

  function clearChecklistsData() {
    setSelectedChecklistItems([]);
  }

  function removeItemChecklist(id: number) {
    const newItens = selectedChecklistItems.filter((item) => item.id !== id);

    setSelectedChecklistItems(newItens);
  }

  return (
    <SelectedChecklistContext.Provider
      value={{
        selectedChecklistItems,
        handleSetSelectedChecklist,
        handleUpdateQuantity,
        calculateTotalChecklists,
        clearChecklistsData,
        removeItemChecklist,
      }}
    >
      {children}
    </SelectedChecklistContext.Provider>
  );
};
