import React from "react";

import * as S from "./styles";

type PhotoCardStepProps = {
  children: React.ReactNode;
  title: string;
  maxPhotos: number;
  photosQuantity: number;
};

export function PhotoCardStep({
  children,
  title,
  maxPhotos,
  photosQuantity,
}: PhotoCardStepProps) {
  return (
    <S.Container>
      <S.Header>
        <p>{title}</p>

        {photosQuantity === 1 && <p>{photosQuantity} foto</p>}
        {photosQuantity > 1 && <p>{photosQuantity} fotos</p>}

        <p>Máximo de fotos: {maxPhotos}</p>
      </S.Header>

      <S.Content>{children}</S.Content>
    </S.Container>
  );
}
