import styled, { css } from "styled-components";

export const Top = styled.div`
  ${({ theme }) => css`
    margin-bottom: 30px;

    display: flex;
    align-items: center;
    justify-content: space-between;

    & > div {
      display: flex;
      align-items: center;

      & > p {
        font-size: ${theme.font.sizes.large};
        color: ${theme.colors.title};
        font-weight: ${theme.font.bold};
      }
    }

    & > button {
      width: 48px;
      height: 48px;
      cursor: pointer;

      & > img {
        width: 100%;
        height: 100%;
      }

      &:hover {
        opacity: 0.7;
      }
    }
  `}
`;

export const ButtonClose = styled.button`
  ${({ theme }) => css`
    width: 35px;
    height: 35px;
    background-color: ${theme.colors.background_light};
    border-radius: 10px;

    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 25px;
  `}
`;

export const Content = styled.main`
  div:nth-child(2) {
    margin-top: 24px;
    margin-bottom: 20px;
  }
`;

export const RegisteredUnities = styled.div`
  ${({ theme }) => css`
    text-decoration: underline;
    margin-top: 20px;
    font-size: 12px;
    color: ${theme.colors.financial_text};
    cursor: pointer;
  `}
`;

export const Main = styled.main`
  height: 100%;
  margin-top: 40px;
`;

export const WrapperProfile = styled.div`
  ${({ theme }) => css`
    width: 50%;
    margin: 0 auto 36px;

    display: flex;
    flex-direction: column;
    align-items: center;

    & > div {
      width: 80px;
      height: 80px;
      border-radius: 50%;
      background-color: ${theme.colors.background_light};

      display: flex;
      justify-content: center;
      align-items: center;
    }

    & > h2 {
      margin-top: 14px;
      font-size: ${theme.font.sizes.large};
      color: ${theme.colors.title};
    }

    & > span {
      font-size: ${theme.font.sizes.small};
      color: ${theme.colors.text};
      margin-top: 8px;
    }
  `}
`;

export const WrapperDetails = styled.div`
  ${({ theme }) => css`
    width: 100%;
    height: 54px;
    margin-bottom: 30px;

    display: flex;
    align-items: center;

    & > img {
      width: 50px;
      height: 50px;
      margin-right: 18px;
    }

    & > div {
      height: 100%;
      display: flex;
      flex-direction: column;

      & > span {
        font-size: 14px;
        line-height: 18px;
        color: ${theme.colors.title};
      }

      & > span:first-of-type {
        color: ${theme.colors.text};
        margin-bottom: 6px;
      }
    }
  `}
`;

export const WrapperButton = styled.div`
  display: flex;
  align-self: flex-end;
  margin-top: 80px;
`;
