import styled, { css } from "styled-components";

export const Container = styled.div`
  ${({ theme }) => css`
    background-color: ${theme.colors.strong_background};
    height: 100vh;
    display: flex;
  `}

  img {
    height: 100%;
  }
`;

export const WrapperContent = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  padding: 30px;
`;

export const SkipButton = styled.div`
  ${({ theme }) => css`
    cursor: pointer;
    width: 100%;
    text-align: right;
    font-size: 20px;
    line-height: 24px;
    font-family: ${theme.font.family};
    color: #ffffff;
    margin-top: 30px;
  `};
`;

export const CenterWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const BottomWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border: 1px solid red;
  margin-bottom: 100px;
`;

export const ChangePageButton = styled.div`
  ${({ theme }) => css`
    font-size: 20px;
    line-height: 24px;
    font-family: ${theme.font.family};
    color: #ffffff;
    display: flex;
    align-items: center;
    cursor: pointer;
  `};
`;

export const Icon = styled.img``;

export const TextsWrapper = styled.div``;

export const Title = styled.div`
  ${({ theme }) => css`
    font-family: ${theme.font.family};

    font-weight: 700;
    font-size: 45px;
    line-height: 54px;
    color: rgba(255, 255, 255, 0.87);
    width: 40%;
  `};
`;

export const SubTitle = styled.div`
  ${({ theme }) => css`
    font-family: ${theme.font.family};
    margin-top: 20px;
    font-weight: 300;
    font-size: 30px;
    line-height: 35px;
    color: rgba(255, 255, 255, 0.87);
    letter-spacing: -0.01em;
    width: 60%;
  `};
`;
