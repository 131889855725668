import { useEffect, useRef } from "react";

export const useClickOutside = (handler: any) => {
  const domNode = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const trigger = (event: any) => {
      if (domNode.current && !domNode.current.contains(event.target as Node)) {
        handler();
      }
    };
    document.addEventListener("mousedown", trigger);

    return () => {
      document.removeEventListener("mousedown", trigger);
    };
  });

  return domNode;
};