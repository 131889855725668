import React, { useEffect } from "react";

import {
  RouteProps as ReactDOMRouterProps,
  Route as ReactDomRoute,
  Redirect,
} from "react-router-dom";

import { Base as BaseTemplate } from "templates/Base";

import { Auth as AuthTemplate } from "templates/Auth";

import { useAuth } from "../hooks/auth";
import { pushDataLayer } from "utils/pushDataLayer";
import { useLocation } from "react-router-dom";

interface RouteProps extends ReactDOMRouterProps {
  isPrivate?: boolean;
  component: React.ComponentType | React.ComponentType<any>;
}

export function Route({
  isPrivate = false,
  component: Component,
  ...rest
}: RouteProps) {
  const { user } = useAuth();
  const Layout = !!user ? BaseTemplate : AuthTemplate;
  const location = useLocation();
  const pathName = location.pathname;

  useEffect(() => {
    if (isPrivate) {
      pushDataLayer({
        event: "userProfile",
        user: {
          zip: user?.address?.cep,
          city: user?.address?.city,
          dateBirth: user?.birthday,
          state: user?.address?.state,
          country: "Brazil",
          email: user?.email,
          firstName: user?.name,
          lastName: user?.name,
          phone: user?.phone,
          userId: user?.id,
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathName]);

  return (
    <ReactDomRoute
      {...rest}
      render={({ location }) => {
        return isPrivate === !!user ? (
          <Layout>
            <Component />
          </Layout>
        ) : (
          <Redirect
            to={{
              pathname: isPrivate ? "/" : "/home",
              state: {
                from: {
                  location,
                },
              },
            }}
          />
        );
      }}
    />
  );
}
