import { ButtonHTMLAttributes } from "react";

import loadingSvg from "assets/loading.svg";

import * as S from "./style";

export type ButtonProps = ButtonHTMLAttributes<HTMLButtonElement> & {
  typeButton?:
    | "default"
    | "delete"
    | "outline"
    | "outlineDisable"
    | "disabled"
    | "textOnly"
    | "outlinePurple"
    | "outlineBlue"
    | "outlineOrange"
    | "unselected"
    | "financialSelected"
    | "financialNotSelected"
    | "financialFilterSelected"
    | "financialFilterNotSelected"
    | "qrcode";
  loading?: boolean;
  isIcon?: boolean;
};

export function Button({
  typeButton = "default",
  loading,
  children,
  ...rest
}: ButtonProps) {
  return (
    <S.Container type="button" typeButton={typeButton} {...rest}>
      {loading ? <img src={loadingSvg} alt="imagem de loading"/> : children}
    </S.Container>
  );
}
