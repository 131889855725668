import React, { ReactElement, useCallback, useEffect, useState } from "react";

import { SearchInput } from "components/SearchInput";

import checkIcon from "assets/icons/service-checklists-icons/checkIcon.svg";
import measureIcon from "assets/icons/service-checklists-icons/measureIcon.svg";
import photographIcon from "assets/icons/service-checklists-icons/photographIcon.svg";
import runIcon from "assets/icons/service-checklists-icons/runIcon.svg";

import * as S from "./styles";
import { Button } from "components/Button";
import { useServiceChecklist } from "hooks/serviceChecklist";
import { useToast } from "hooks/toast";
import axios from "axios";
import { api } from "services/api";
import { RegisterProps } from "hooks/serviceChecklist";
import { ChecklistPage } from "../ChecklistPage";
import { RegisterPage } from "../RegisterPage";
import { DetailRegisterPage } from "../DetailRegisterPage";
import { ModalRight } from "components/ModalRight";
import { CardChecklist } from "components/CardChecklist";
import { EditButton } from "components/EditButton";
import { DeleteButton } from "components/DeleteButton";
import { NewAlertModal } from "components/NewAlertModal";
import { LoadingProfiz } from "components/LoadingProfiz";
import { RegisterItem } from "../types";
import { TablePagination } from "components/TablePagination";
import debounce from "lodash.debounce";
import { isEqual } from "lodash";
import { DiscardInformationModal } from "components/DiscardInformationModal";
import OptionsFilterComponent from "components/OptionsFilterComponent";
import { ShowWhenHavePermission } from "components/Permission";
import { useAccount } from "hooks/permission/account";
import { searchDatalayer } from "utils/pushDataLayer";
const LIMIT_REGISTERS = 50;

type TOption = {
  [key: string]: ReactElement;
};

type TFiltro = {
  page: string;
  status: string;
  method: string;
  id: string;
  start_date: string;
  end_date: string;
};

type TInfo = {
  filtros: TFiltro;
  prev: string; //pagina anterior
  self: string; //pagina atual
  next: string; //proxima página
  offset: number; //Offset solicitado
  limit: number;
  exibindo: number; //Quantidade de registros sendo exibidos na página atual
  total: number;
};

type RegisterResponse = {
  data: {
    checklistSteps: RegisterProps[];
    info: TInfo;
  };
};

type Option = {
  [key: string]: string;
};

const typeFilterProps: Option = {
  executar: "Executar",
  fotografar: "Fotografar",
  medir: "Medir",
  verificar: "Verificar",
};

export function RegistersTab() {
  const {
    checklistRegisters,
    handleSetChecklistRegisters,
    handleSetIsNewChecklist,
    handleSetDetailRegister,
    actualRegister,
    backupRegister,
  } = useServiceChecklist();

  const { addToast } = useToast();

  const [isOpenAlertModalDelete, setIsOpenAlertModalDelete] = useState(false);
  const [isOpenModalChecklist, setisOpenModalChecklist] = useState(false);
  const [currentPageModal, setCurrentPageModal] = useState("");
  const [isSearching, setIsSearching] = useState(false);
  const [searchRegister, setSearchRegister] = useState("");
  const [registerId, setRegisterId] = useState(0);

  const [registersBackup, setRegistersBackup] = useState<RegisterProps[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [showCompareAlert, setShowCompareAlert] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [offset, setOffset] = useState(0);
  const [totalItems, setTotalItems] = useState(0);
  const defaultLimit = 20;
  const [typeFilter, setTypeFilter] = useState<string[]>([]);
  const { whoami } = useAccount();

  const setOptionType = (newfilter: string) => {
    let arrFilter = typeFilter.filter((element) => element === newfilter);
    if (arrFilter.length === 0) {
      setTypeFilter([...typeFilter, newfilter]);

      let registersList = JSON.parse(JSON.stringify(registersBackup));

      let aux = registersList.filter((element: RegisterItem) => {
        return [...typeFilter, newfilter].find((e) => {
          return e === element.type;
        });
      });

      handleSetChecklistRegisters(aux);
    } else {
      let fType = typeFilter.filter((element) => element !== newfilter);
      setTypeFilter(fType);
      if (fType.length === 0)
        return handleSetChecklistRegisters(registersBackup);

      let registersList = JSON.parse(JSON.stringify(registersBackup));
      let aux = registersList.filter((element: RegisterItem) => {
        return fType.find((e) => {
          return e === element.type;
        });
      });

      handleSetChecklistRegisters(aux);
    }
  };

  const loadInfo = async (searchName?: string) => {
    if (isOpenModalChecklist) return;
    try {
      setIsLoading(true);
      const { data }: RegisterResponse = await api.get(
        "/service/checklist/step",
        {
          params: {
            offset: offset,
            limit: defaultLimit,
            accountId: whoami?.id,
          },
        }
      );
      const { checklistSteps, info } = data;

      setTotalItems(info?.total || 0);
      if (searchName && searchName.length > 0) {
        setRegistersBackup(checklistSteps);
        let filterElements = checklistSteps.filter((element) => {
          let elementLowerCase = element.name.toLowerCase(); //.service.name.toLowerCase();
          let searchValue = searchName.toLowerCase();
          return elementLowerCase.includes(searchValue);
        });
        handleSetChecklistRegisters(filterElements);
        searchDatalayer({ search_term: searchName, success: true });

        return;
      }

      handleSetChecklistRegisters(checklistSteps);
      setRegistersBackup(checklistSteps);
      setTotalItems(info?.total || 0);
    } catch (error) {
      addToast({
        title: "Ops!!",
        description:
          axios.isAxiosError(error) && error.response?.data.error
            ? error.response.data.error
            : "Ocorreu um erro ao buscar a listagem de registros.",
        type: "error",
      });
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    loadInfo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [offset, isOpenModalChecklist]);

  function handleSearchRegister(search: string) {
    setIsSearching(true);
    setSearchRegister(search);
    debounceFn(search);
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debounceFn = useCallback(debounce(getRegisters, 1000), []);

  function getRegisters(search?: string) {
    if (!search || search.length < 1) {
      setIsSearching(false);
      loadInfo();
    }
    if (search) {
      const validateString = /[a-zA-Z@!#$%^&*()/\\]/;
      if (!validateString.test(search)) {
        return addToast({
          title: "Ops!!",
          description: "Não é possível fazer busca apenas com números.",
          type: "error",
        });
      }

      //const filteredValues = checklistRegisters.filter((currentRegister) => {
      //  let registerLowerCase = currentRegister.name.toLowerCase();
      //  let searchValue = search.toLowerCase();

      //  let result;

      //  if (selectedRegisterCategories.length > 0) {
      //    result =
      //      registerLowerCase.indexOf(searchValue) > -1 &&
      //      selectedRegisterCategories.includes(currentRegister.type);
      //  } else {
      //    result = registerLowerCase.indexOf(searchValue) > -1;
      //  }

      //  return result;
      //});

      setIsSearching(false);
      loadInfo(search);
    }
  }

  function handleCancel() {
    setSearchRegister("");
    //handleFilterByCategory();
    handleSetChecklistRegisters(registersBackup);
  }

  function handleNavigateToCreate() {
    if (totalItems >= LIMIT_REGISTERS) {
      addToast({
        title: "Máximo permitido",
        description: `Ops, só é permitido criar ${LIMIT_REGISTERS} registros`,
        type: "info",
      });
      return;
    }

    handleSetIsNewChecklist(true);

    setCurrentPageModal("detailRegisterPage");
    setisOpenModalChecklist(true);
  }

  function handlePageChange(pageNumber: number, itemsPerPage: number) {
    setCurrentPage(pageNumber);
    setOffset((pageNumber - 1) * itemsPerPage);
  }

  async function handleDeleteRegister(idRegister: number) {
    if (idRegister <= 0) return;
    try {
      setIsLoading(true);
      await api.delete(`/service/checklist/step/${idRegister}`);
      addToast({
        title: "Sucesso",
        description: "Registro deletado com sucesso!",
        type: "success",
      });

      //carregando novamente a página...
      await loadInfo();
    } catch (error) {
      addToast({
        type: "error",
        title: "Oops!",
        description:
          axios.isAxiosError(error) && error.response?.data.error
            ? error.response.data.error
            : "Não foi possível deletar o registro.",
      });
    } finally {
      setIsLoading(false);
    }
  }

  function setPageRegister() {
    setCurrentPageModal("registerPage");
  }

  function setPageRegisterDetail() {
    setCurrentPageModal("detailRegisterPage");
  }

  const setPageComponent = (keyPage: string) => {
    const pagesArr: TOption = {
      checklistPage: (
        <ChecklistPage
          handleDetailRegister={setPageRegisterDetail}
          handleFunction={setPageRegister}
          handleFunctionClose={() => {
            setisOpenModalChecklist(false);
          }}
        />
      ),
      registerPage: (
        <RegisterPage
          handleFunction={setPageRegisterDetail}
          handlePageClose={() => {
            setCurrentPageModal("checklistPage");
          }}
        />
      ),
      detailRegisterPage: (
        <DetailRegisterPage
          handlePageClose={() => {
            setisOpenModalChecklist(false);
          }}
          isTabRegister
        />
      ),
    };
    return pagesArr[keyPage];
  };

  function typeIcon(typeI: string) {
    const opt: Option = {
      fotografar: photographIcon,
      medir: measureIcon,
      verificar: checkIcon,
      executar: runIcon,
    };
    return opt[typeI];
  }

  const handleSetEditPage = (index: number, register: RegisterItem) => {
    handleSetDetailRegister({
      indexItem: index,
      registerDetail: register,
    });
    handleSetIsNewChecklist(false);
    setCurrentPageModal("detailRegisterPage");
    setisOpenModalChecklist(true);
  };

  return (
    <>
      {isLoading ? (
        <LoadingProfiz isVisible={isLoading} />
      ) : (
        <>
          <DiscardInformationModal
            showModal={showCompareAlert}
            handleConfirmButton={() => {
              setShowCompareAlert(false);
              setisOpenModalChecklist(!isOpenModalChecklist);
            }}
            handleCancelButton={() => {
              setShowCompareAlert(false);
            }}
          />
          <NewAlertModal
            isVisible={isOpenAlertModalDelete}
            title="Remover registro do checklist?"
            description={
              "O Registro ainda poderá ser visualizado no menu “Registros”."
            }
            action="choose"
            handleConfirm={() => {
              handleDeleteRegister(registerId);
              setIsOpenAlertModalDelete(false);
            }}
            onCloseModal={() => setIsOpenAlertModalDelete(false)}
            labelConfirm="Remover registro do checklist"
            labelCancel="Cancelar"
            buttonConfirmColor="#FA8B94"
          />
          <div>
            <SearchInput
              placeholder="Procure por um registro customizado"
              searchValue={searchRegister}
              onChange={(e) => handleSearchRegister(e.target.value)}
              loadingInput={isSearching}
              handleCancel={handleCancel}
            />
          </div>
          <div>
            <OptionsFilterComponent
              data={typeFilterProps}
              optionsSelected={typeFilter}
              handlePressButton={setOptionType}
            />
          </div>
          <ShowWhenHavePermission
            moduleHash="service-checklist-step"
            actionHash="create"
          >
            <div>
              <Button
                typeButton="outline"
                onClick={() => {
                  handleNavigateToCreate();
                }}
              >
                Criar novo registro
              </Button>
            </div>

            <S.SubText>
              Crie até {LIMIT_REGISTERS} registros para vincular a um checklist.
            </S.SubText>
          </ShowWhenHavePermission>

          <ModalRight
            isOpen={isOpenModalChecklist}
            handleToggleOpen={() => {
              if (!isEqual(actualRegister, backupRegister)) {
                setShowCompareAlert(true);
                return;
              }
              setisOpenModalChecklist(!isOpenModalChecklist);
              handleSetDetailRegister({});
            }}
          >
            {setPageComponent(currentPageModal)}
          </ModalRight>

          {checklistRegisters.length === 0 ? (
            <S.EmptyMessage>
              <p>Não existem resgistros cadastrados</p>
            </S.EmptyMessage>
          ) : (
            checklistRegisters.map((currentRegister, index) => {
              return (
                <S.WrapperService key={index}>
                  <CardChecklist>
                    <S.WrapperInfoCard>
                      <S.ContainerImg>
                        <img
                          src={typeIcon(currentRegister.type)}
                          alt="imagem"
                        />
                        <S.WrapperData>
                          <h6>{currentRegister.name}</h6>
                          <p>{currentRegister.description}</p>
                        </S.WrapperData>
                      </S.ContainerImg>
                      {/*validar o checklist padrao nao pode ser editado...*/}
                      <S.WrapperButtonContent>
                        <ShowWhenHavePermission
                          moduleHash="service-checklist-step"
                          actionHash="edit"
                        >
                          <S.WrapperButton>
                            <EditButton
                              onClick={() => {
                                handleSetEditPage(index, currentRegister);
                              }}
                            />
                          </S.WrapperButton>
                        </ShowWhenHavePermission>

                        <ShowWhenHavePermission
                          moduleHash="service-checklist-step"
                          actionHash="delete"
                        >
                          <S.WrapperButton>
                            <DeleteButton
                              onClick={() => {
                                setRegisterId(currentRegister.id);
                                setIsOpenAlertModalDelete(true);
                              }}
                            />
                          </S.WrapperButton>
                        </ShowWhenHavePermission>
                      </S.WrapperButtonContent>
                    </S.WrapperInfoCard>
                  </CardChecklist>
                </S.WrapperService>
              );
            })
          )}
          <S.ContainerPagination>
            <TablePagination
              rowsLength={totalItems}
              rowsPerPage={defaultLimit}
              rowsPage={currentPage}
              handlePageChange={(pageNumber, itemsPerPage) =>
                handlePageChange(pageNumber, itemsPerPage)
              }
            />
          </S.ContainerPagination>
        </>
      )}
    </>
  );
}
