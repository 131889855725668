import React from "react";

import { useResumePmoc } from "hooks/resumePmoc";

import { DetailOfMonthPmoc } from "pages/pmoc/DetailOfMonthPmoc";
import { ServiceOrderDetail } from "components/ServiceOrderDetail";
import { ServiceOrderDetailArchived } from "components/ServiceOrderDetailArchived";
import { ServicesHub } from "pages/fieldService/ServicesHub";
import { RegisterSignatures } from "pages/fieldService/RegisterSignatures";
import { ChecklistDetails } from "components/ChecklistDetails";

export function ModalResumePmoc() {
  const { modalSelected } = useResumePmoc();

  return (
    <>
      {modalSelected === "detailOfMonthPmoc" && <DetailOfMonthPmoc />}

      {modalSelected === "serviceOrderDetail" && <ServiceOrderDetail />}

      {modalSelected === "serviceOrderDetailArchived" && (
        <ServiceOrderDetailArchived />
      )}

      {modalSelected === "servicesHub" && <ServicesHub />}

      {modalSelected === "registerSignatures" && <RegisterSignatures />}

      {modalSelected === "checklistDetails" && <ChecklistDetails />}
    </>
  );
}
