import { ArrowButton } from "components/ArrowButton";
import * as S from "./styles";
import { CardMode } from "./CardMode";
import { ModeProps } from "dtos/businessProposalsDTO";
import { useHistory } from "react-router";

export function BusinessProposalsMode() {
  const history = useHistory();

  function handleNavigateToNewProposals(mode: ModeProps) {
    history.push(`/budgets/create/${mode}`);
  }

  return (
    <S.Container>
      <S.Header>
        <ArrowButton />

        <h1>Novo orçamento</h1>
      </S.Header>
      <S.Description>
        Selecione o tipo de orçamento que deseja criar.
      </S.Description>

      <S.Content>
        <div>
          <CardMode
            mode="products"
            title="Produtos"
            description="Oferte produtos"
            onClick={() => handleNavigateToNewProposals("products")}
          />
        </div>

        <div>
          <CardMode
            mode="services"
            title="Serviços"
            description="Oferte serviços"
            onClick={() => handleNavigateToNewProposals("services")}
          />
        </div>

        <div>
          <CardMode
            mode="products-services"
            title="Produtos e serviços"
            description="Oferte produtos e serviços"
            onClick={() => handleNavigateToNewProposals("products-services")}
          />
        </div>
      </S.Content>
    </S.Container>
  );
}
