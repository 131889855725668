import { MdChevronLeft, MdChevronRight, MdClose } from "react-icons/md";
import { Carousel } from "react-responsive-carousel";

import "react-responsive-carousel/lib/styles/carousel.min.css";

import * as S from "./styles";

type Props = {
  images: string[];
  imageSelected: number;
  isVisible: boolean;
  showModalArrow?: boolean;
  onCloseModal: () => void;
};

export function ImagePreview({
  images,
  imageSelected,
  isVisible,
  showModalArrow = true,
  onCloseModal,
}: Props) {
  return (
    <S.ContainerOverlay isVisible={isVisible}>
      <S.BoxModal>
        <S.Header>
          <button type="button" onClick={onCloseModal}>
            <MdClose size={32} color="black" />
          </button>
        </S.Header>

        <S.Main>
          <Carousel
            centerMode
            centerSlidePercentage={100}
            dynamicHeight
            useKeyboardArrows
            showStatus={false}
            showThumbs={false}
            selectedItem={imageSelected}
            showArrows={showModalArrow}
            renderArrowPrev={(clickHandler) =>
              showModalArrow && (
                <S.ButtonArrowPrev onClick={clickHandler}>
                  <MdChevronLeft size={40} color="black" />
                </S.ButtonArrowPrev>
              )
            }
            renderArrowNext={(clickHandler) =>
              showModalArrow && (
                <S.ButtonArrowNext onClick={clickHandler}>
                  <MdChevronRight size={40} color="black" />
                </S.ButtonArrowNext>
              )
            }
          >
            {images.map((image, index) => (
              <S.Image key={index} src={image} alt={`Foto ${index + 1}`} />
            ))}
          </Carousel>
        </S.Main>
      </S.BoxModal>
    </S.ContainerOverlay>
  );
}
