import styled, { css } from "styled-components";
import media from "styled-media-query";
import { lighten } from "polished";

export const Container = styled.div`
  ${({ theme }) => css`
    width: 100%;
    max-width: 1400px;
    margin: 0 auto;
    padding: 30px;

    header {
      width: 64%;
      display: flex;
      align-items: center;
      justify-content: space-between;

      ${media.lessThan("large")`
        flex-direction: column;
        align-items: flex-start;

        & > div:last-of-type {
          margin-top: 20px;
          margin-left: 0;
        }
      `}

      h1 {
        font-size: ${theme.font.sizes.large};
        color: ${theme.colors.title};
        font-weight: ${theme.font.bold};
        margin-left: 30px;
      }

      & > div {
        display: flex;
        align-items: center;
        justify-content: space-between;
      }

      & > div:last-of-type {
        width: 60%;
        min-width: 350px;
      }
    }
  `}
`;

export const Content = styled.div`
  width: 46%;
  min-height: 80vh;
  min-width: 470px;
  margin-top: 50px;

  & > div:first-of-type {
    margin: 20px 0 30px;
  }
`;

export const SubText = styled.p`
  ${({ theme }) => css`
    margin-top: 5px;
    margin-bottom: 20px;
    color: ${theme.colors.financial_text};
    font-size: ${theme.font.sizes.small};
    text-align: left;
    letter-spacing: 0.26px;
    line-height: 16px;
  `};
`;
export const WrapperInput = styled.div`
  margin-bottom: 15px;
`;

export const WrapperInputArea = styled.div`
  ${({ theme }) => css`
    margin-bottom: 15px;

    label {
      color: ${theme.colors.title};
      text-align: left;
      font-size: 12px;
      margin-bottom: 5px;
    }
    textarea {
      margin-top: 5px;
      background: ${lighten(0.05, theme.colors.background)};
      width: 100%;
      border-radius: 8px;

      padding: 10px;

      border: 1px solid #232129;
      color: ${theme.colors.title};

      & + div {
        margin-top: 8px;
      }

      &:focus {
        border-color: ${theme.colors.primary_light};
      }

      &:-webkit-autofill {
        -webkit-box-shadow: 0 0 0 24px ${lighten(0.05, theme.colors.background)}
          inset;
        -webkit-text-fill-color: ${theme.colors.text_extra_light};

        filter: none;
      }

      &:read-only {
        opacity: 0.7;
      }

      &:disabled {
        cursor: not-allowed;
      }
    }
  `}
`;
