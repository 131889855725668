import { useEffect, useState } from "react";

import { Dropdown, DropdownOption } from "components/DropDown";
import { Input } from "components/Input";
import { CommentsTextArea } from "components/CommentsTextArea";
import { Button } from "components/Button";
import { useToast } from "hooks/toast";

import { useServiceDropDown } from "hooks/servicesDropDown";
import { useServiceFormTree } from "hooks/serviceFormTree";

import { api } from "services/api";

import { ServiceName } from "../Areas/ServiceName";
import { Equipments } from "../Areas/Equipments";
import { EquipmentTypes } from "../Areas/EquipmentTypes";
import { Capacity } from "../Areas/Capacity";

import arrowLeft from "assets/icons/arrow-left.svg";

import * as S from "./styles";
import {
  ServiceDTO,
  ServiceForListProps,
  ServiceOptionsInputProps,
} from "dtos/ServiceDTO";

import { useServiceChecklist } from "hooks/serviceChecklist";
import { useSelectedService } from "hooks/budget/selectedServices";

import { ChecklistItemList } from "pages/fieldService/ServiceChecklist/types";
import { EditButton } from "components/EditButton";
import { DeleteButton } from "components/DeleteButton";
import { CardChecklist } from "components/CardChecklist";
import { AlertModal } from "components/AlertModal";
import { useSelectedServiceOrder } from "hooks/selectedServiceOrder";
import { isEqual } from "lodash";
import axios from "axios";
import { DiscardInformationModal } from "components/DiscardInformationModal";
import {
  Label,
  SelectCurrentAccountForCreateNewItem,
  ShowWhenHavePermission,
} from "components/Permission";
import { useAccount } from "hooks/permission/account";
import { LoadingPageModal } from "components/LoadingPageModal";

const runTimeExtensionOptions = [
  { id: 1, name: "Minutos" },
  { id: 2, name: "Horas" },
  { id: 3, name: "Dias" },
];

const warrantyExtensionOptions = [
  { id: 1, name: "Dias" },
  { id: 2, name: "Meses" },
  { id: 3, name: "Anos" },
];

type FormCreateServicerops = {
  data?: ServiceDTO | ServiceForListProps;
  handleGoBack: () => void;
  handleAddChecklist?: () => void;
  handleEditPage?: () => void;
  loadServicesCommon?(): Promise<void>;
};

type ExtensionProps = {
  id: number;
  name: string;
};

export function FormCreateService({
  handleGoBack,
  handleAddChecklist,
  data,
  handleEditPage,
  loadServicesCommon,
}: FormCreateServicerops) {
  const { addToast } = useToast();
  const { categoriesTree, handleSetCategoriesTree } = useServiceFormTree();
  const {
    selectedCategoryId,
    selectedService,
    selectedEquipment,
    selectedEquipmentType,
    selectedCapacity,
    handleCategoryId,
    handleService,
    handleEquipment,
    handleEquipmentType,
    handleCapacity,
    reset,
    handleSetEqualService,
    isEqualService,
  } = useServiceDropDown();

  const { whoami } = useAccount();

  const {
    handleSetChecklistDetail,
    handleSetIsNewChecklist,
    handleSetEditPage,
    handleSetIsNotMenusAccess,
    handleSetSelectedItemChecklist,
  } = useServiceChecklist();

  const { handleSetOptionsInputService, optionsInputService } =
    useSelectedServiceOrder();

  const { handleSetLatestServiceAsChecked } = useSelectedService();

  const [isLoadingButton, setIsLoadingButton] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [hasErrorOnCategory, setHasErrorOnCategory] = useState("");
  const [hasErrorOnServiceName, setHasErrorOnServiceName] = useState("");

  const [warrantyTime, setWarrantyTime] = useState("");
  const [hasErrorOnWarrantyTime, setHasErrorOnWarrantyTime] = useState("");

  const [warrantyExtension, setWarrantyExtension] = useState(
    {} as ExtensionProps
  );
  const [hasErrorOnWarrantyExtension, setHasErrorOnWarrantyExtension] =
    useState("");

  const [runTime, setRunTime] = useState("");
  const [hasErrorOnRunTime, setHasErrorOnRunTime] = useState("");

  const [runTimeExtension, setRunTimeExtension] = useState(
    {} as ExtensionProps
  );
  const [hasErrorOnRuntimeExtension, setHasErrorOnRuntimeExtension] =
    useState("");

  const [brand, setBrand] = useState("");
  const [observationText, setObservationText] = useState("");

  const [price, setPrice] = useState("");
  const [unMaskedPrice, setUnmaskedPrice] = useState(0);
  const [hasErrorOnPrice, setHasErrorOnPrice] = useState("");
  const [serviceChecklist, setServiceChecklist] = useState<ChecklistItemList[]>(
    []
  );
  const [isOpenAlertModalDelete, setIsOpenAlertModalDelete] = useState(false);
  const [indexDelete, setIndexDelete] = useState<number>();
  const [backupService, setBackupService] = useState({});

  const [showCompareAlert, setShowCompareAlert] = useState(false);

  function setDataContext() {
    const objAux: ServiceOptionsInputProps = {
      brand,
      warrantyTime,
      warrantyExtension,
      runTime,
      runTimeExtension,
      observationText,
      price,
      unMaskedPrice,
    };

    handleSetOptionsInputService(objAux);
  }

  function handleDeleteRegister(ind: number) {
    let aux = serviceChecklist.filter((element, index) => {
      return index !== ind;
    });
    setServiceChecklist(aux);

    //limpando o hook(só é possível apagar na criação do serviço)
    handleSetSelectedItemChecklist([]);
  }

  async function getCategories() {
    try {
      setIsLoading(true);
      const response = await api.get("/services/new-service-form", {
        params: {
          accountId: whoami?.id,
        },
      });
      const { categoriesTree: categoriesTreeResponse } = response.data;

      handleSetCategoriesTree(categoriesTreeResponse);
      setIsLoading(false);
    } catch (err) {
      // sendError(err);
    }
  }

  useEffect(() => {
    if (Object.keys(backupService).length < 1) {
      handleSetEqualService(true);
      return;
    }
    const actualService = setUpService();
    handleSetEqualService(isEqual(actualService, backupService));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    runTime,
    warrantyTime,
    selectedCategoryId,
    selectedService,
    selectedEquipment,
    selectedEquipmentType,
    selectedCapacity,
    unMaskedPrice,
    runTimeExtension,
    warrantyExtension,
    observationText,
    brand,
    serviceChecklist,
  ]);

  function setUpService() {
    const runTimeFormatted = Number(runTime);
    const warrantyTimeFormatted = Number(warrantyTime);
    const service = {
      categoryID: selectedCategoryId,
      serviceTypeID: selectedService.id,
      equipmentID: selectedEquipment.id ?? 0,
      ...(selectedEquipmentType.id
        ? { equipmentTypeID: selectedEquipmentType.id }
        : {}),
      ...(selectedCapacity.id ? { capacityID: selectedCapacity.id } : {}),
      price: unMaskedPrice,
      runtime: {
        ...(runTimeFormatted !== 0 ? { time: runTimeFormatted } : {}),
        ...(runTimeExtension.name ? { extension: runTimeExtension.name } : {}),
      },
      warranty: {
        ...(warrantyTimeFormatted !== 0 ? { time: warrantyTimeFormatted } : {}),
        ...(warrantyExtension.name
          ? { extension: warrantyExtension.name }
          : {}),
      },
      description: observationText,
      brand,
      // TODO Desmarcar apos api estar pronta
      //enviando checklists para a api(POST)
      //serviceChecklist: serviceChecklist.map(checklist => checklist.id),
      ...(serviceChecklist.length > 0
        ? { serviceChecklist: serviceChecklist[0].id }
        : {}),
    };
    return service;
  }

  useEffect(() => {
    const service = setUpService();
    //setActualService(service);
    setBackupService(service);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getCategories();

    //setando os servicos adicionados
    if (data?.serviceChecklist) {
      setServiceChecklist(data.serviceChecklist);
      handleSetSelectedItemChecklist(data.serviceChecklist);
    }

    if (Object.keys(optionsInputService).length > 0) {
      setBrand(optionsInputService.brand!);
      setWarrantyTime(optionsInputService.warrantyTime!);
      setWarrantyExtension(optionsInputService.warrantyExtension!);
      setRunTime(optionsInputService.runTime!);
      setRunTimeExtension(optionsInputService.runTimeExtension!);
      setObservationText(optionsInputService.observationText!);
      setPrice(optionsInputService.price!);
      setUnmaskedPrice(optionsInputService.unMaskedPrice!);
    }
    // return () => {
    //   reset();
    // };
  }, [whoami?.id]); // eslint-disable-line react-hooks/exhaustive-deps

  function handleSelectCategory({ id }: DropdownOption) {
    if (selectedCategoryId === id) {
      return;
    }

    handleCategoryId(id);
    handleService({} as DropdownOption);
    handleEquipment({} as DropdownOption);
    handleEquipmentType({} as DropdownOption);
    handleCapacity({} as DropdownOption);

    setHasErrorOnCategory("");
  }

  function handleWarrantyExtension({ id }: DropdownOption) {
    const findCurrentExtension = warrantyExtensionOptions.find(
      (extension) => extension.id === id
    );

    if (findCurrentExtension) {
      setWarrantyExtension(findCurrentExtension);
    }

    setHasErrorOnWarrantyExtension("");
  }

  function handleSelectRuntimeExtension({ id }: DropdownOption) {
    const findCurrentExtensionRunTime = runTimeExtensionOptions.find(
      (extension) => extension.id === id
    );

    if (findCurrentExtensionRunTime) {
      setRunTimeExtension(findCurrentExtensionRunTime);
    }

    setHasErrorOnRuntimeExtension("");
  }

  async function handleCreateService() {
    if (!selectedCategoryId) {
      setHasErrorOnCategory("Categoria é obrigatório");
      addToast({
        title: "Ops!!",
        description: "Alguns campos são obrigatórios",
        type: "error",
      });
      return;
    } else {
      setHasErrorOnCategory("");
    }

    if (!selectedService.id) {
      setHasErrorOnServiceName("Serviço é obrigatório");
      addToast({
        title: "Ops!!",
        description: "Alguns campos são obrigatórios",
        type: "error",
      });
      return;
    } else {
      setHasErrorOnServiceName("");
    }

    const runTimeFormatted = Number(runTime);
    const warrantyTimeFormatted = Number(warrantyTime);

    if (runTimeFormatted <= 0 && runTimeExtension.name) {
      setHasErrorOnRunTime("Digite o tempo de execução.");
      addToast({
        title: "Ops!!",
        description: "Preencha o campo de tempo (Obrigatório)",
        type: "error",
      });
      return;
    } else {
      setHasErrorOnRunTime("");
    }

    if (runTimeFormatted > 0 && !runTimeExtension.name) {
      setHasErrorOnRuntimeExtension("Selecione uma das opções");

      addToast({
        title: "Ops!!",
        description: "Preencha o campo de tempo (Obrigatório)",
        type: "error",
      });
      return;
    } else {
      setHasErrorOnRuntimeExtension("");
    }

    if (warrantyTimeFormatted <= 0 && warrantyExtension.name) {
      setHasErrorOnWarrantyTime("Digite o tempo de garantia.");
      addToast({
        title: "Ops!!",
        description: "Preencha o campo de tempo (Obrigatório)",
        type: "error",
      });
      return;
    } else {
      setHasErrorOnWarrantyTime("");
    }

    if (warrantyTimeFormatted > 0 && !warrantyExtension.name) {
      setHasErrorOnWarrantyExtension("Selecione uma das opções");

      addToast({
        title: "Ops!!",
        description: "Preencha o campo de tempo (Obrigatório)",
        type: "error",
      });

      return;
    } else {
      setHasErrorOnWarrantyExtension("");
    }

    if (
      !selectedCategoryId ||
      !selectedService.id ||
      (runTimeFormatted <= 0 && runTimeExtension.name) ||
      (runTimeFormatted > 0 && !runTimeExtension) ||
      (warrantyTimeFormatted <= 0 && warrantyExtension.name) ||
      (warrantyTimeFormatted > 0 && !warrantyExtension)
    ) {
      return;
    }

    try {
      setIsLoadingButton(true);

      const service = {
        categoryID: selectedCategoryId,
        serviceTypeID: selectedService.id,
        equipmentID: selectedEquipment.id ?? 0,
        ...(selectedEquipmentType.id
          ? { equipmentTypeID: selectedEquipmentType.id }
          : {}),
        ...(selectedCapacity.id ? { capacityID: selectedCapacity.id } : {}),
        price: unMaskedPrice ? unMaskedPrice : 0,
        runtime: {
          ...(runTimeFormatted !== 0 ? { time: runTimeFormatted } : {}),
          ...(runTimeExtension.name
            ? { extension: runTimeExtension.name }
            : {}),
        },
        warranty: {
          ...(warrantyTimeFormatted !== 0
            ? { time: warrantyTimeFormatted }
            : {}),
          ...(warrantyExtension.name
            ? { extension: warrantyExtension.name }
            : {}),
        },
        description: observationText,
        brand,
        // TODO Desmarcar apos api estar pronta
        //enviando checklists para a api(POST)
        //serviceChecklist: serviceChecklist.map(checklist => checklist.id),
        ...(serviceChecklist.length > 0
          ? { checklistId: serviceChecklist[0].id }
          : {}),
      };

      await api.post("/service", { ...service, accountId: whoami?.id });

      handleSetLatestServiceAsChecked(true);
      loadServicesCommon && (await loadServicesCommon());

      reset();
      handleClearRunTimeExtension();
      handleClearWarrantyExtension();
      handleSetOptionsInputService({} as ServiceOptionsInputProps);

      handleSetSelectedItemChecklist([]);

      addToast({
        title: "Sucesso",
        description: "Serviço criado com sucesso",
        type: "success",
      });

      handleGoBack();
    } catch (error) {
      addToast({
        title: "Ops!!",
        description:
          axios.isAxiosError(error) && error.response?.data.error
            ? error.response.data.error
            : "Ocorreu um erro ao criar um novo serviço.",
        type: "error",
      });
    } finally {
      setIsLoadingButton(false);
    }
  }

  function handleClearRunTimeExtension() {
    setRunTimeExtension({} as ExtensionProps);
  }

  function handleClearWarrantyExtension() {
    setWarrantyExtension({} as ExtensionProps);
  }

  function handleChangePrice(text: string) {
    setPrice(text);
  }

  async function detailItem(currentChecklist: ChecklistItemList) {
    try {
      //setando os detalhes do checklist
      handleSetChecklistDetail(currentChecklist);

      //setado o tipo de tela para detalhe do checklist..
      handleSetIsNewChecklist(false);
      //setCurrentPageModal("checklistPage");
      //setisOpenModalChecklist(true);
      if (handleEditPage) handleEditPage();
    } catch (error) {
      addToast({
        title: "Ops!!",
        description: "Não foi possível obter os detalhes do checklist.",
        type: "error",
      });
    } finally {
    }
  }

  return isLoading ? (
    <LoadingPageModal />
  ) : (
    <>
      <AlertModal
        isVisible={isOpenAlertModalDelete}
        title="Deseja apagar?"
        description={"Deseja realmente apagar esse checklist?"}
        action="choose"
        handleConfirm={() => {
          handleDeleteRegister(indexDelete as number);
          setIsOpenAlertModalDelete(false);
        }}
        onCloseModal={() => setIsOpenAlertModalDelete(false)}
      />

      <DiscardInformationModal
        showModal={showCompareAlert}
        handleConfirmButton={() => {
          setShowCompareAlert(false);
          handleSetIsNotMenusAccess(false);
          handleSetSelectedItemChecklist([]);
          handleGoBack();
          handleSetEqualService(true);
        }}
        handleCancelButton={() => {
          setShowCompareAlert(false);
        }}
      />

      <S.Top>
        <S.ButtonClose
          onClick={() => {
            if (!isEqualService) {
              setShowCompareAlert(true);
              return;
            }
            handleSetIsNotMenusAccess(false);
            handleSetSelectedItemChecklist([]);
            handleGoBack();
          }}
        >
          <img src={arrowLeft} alt="flecha pra esquerda" />
        </S.ButtonClose>

        <p>Criar novo serviço</p>
      </S.Top>

      <S.Content id="form_servicos_comum">
        <S.Wrapper>
          <Dropdown
            label="Categoria*"
            placeholder="Selecione uma categoria"
            options={categoriesTree}
            categoryIdSelected={selectedCategoryId}
            onClickedValue={handleSelectCategory}
            hasError={hasErrorOnCategory}
            moduleHashValue="service-type"
          />
        </S.Wrapper>

        <S.Wrapper>
          <Label>Conta</Label>
          <SelectCurrentAccountForCreateNewItem
            moduleHash="service"
            actionHash="create"
          />
        </S.Wrapper>

        <S.Wrapper>
          <ServiceName
            moduleHash="service-type"
            hasErrorOnService={hasErrorOnServiceName}
          />
        </S.Wrapper>

        <S.Wrapper>
          <Equipments hasErrorOnEquipment={""} moduleHash="equipment" />
        </S.Wrapper>

        <S.Wrapper>
          <EquipmentTypes moduleHash="equipment-type" />
        </S.Wrapper>

        <S.Wrapper>
          <Capacity moduleHash="equipment-capacity" />
        </S.Wrapper>

        <S.Wrapper>
          <Input
            name="Marca (opcional)"
            type="text"
            value={brand}
            placeholder="Ex: Consul"
            onChange={(text) => setBrand(text.currentTarget.value.trimStart())}
          />
        </S.Wrapper>

        <S.Wrapper>
          <S.Row>
            <S.WrapperInput>
              <span> Tempo de execução (opcional) </span>
              <Input
                type="number"
                placeholder="Ex: 2"
                value={runTime}
                onChange={(text) => setRunTime(text.currentTarget.value)}
                hasError={hasErrorOnRunTime}
                onFocus={() => setHasErrorOnRunTime("")}
              />
            </S.WrapperInput>

            <S.WrapperDrodown>
              <Dropdown
                placeholder="Selecione"
                options={runTimeExtensionOptions}
                categoryIdSelected={runTimeExtension.id}
                // eslint-disable-next-line react/jsx-no-bind
                onClickedValue={handleSelectRuntimeExtension}
                hasError={hasErrorOnRuntimeExtension}
                clearDropDownItem
                // eslint-disable-next-line react/jsx-no-bind
                handleClear={handleClearRunTimeExtension}
              />
            </S.WrapperDrodown>
          </S.Row>
        </S.Wrapper>

        <S.Wrapper>
          <S.Row>
            <S.WrapperInput>
              <span> Garantia (opcional)</span>
              <Input
                type="number"
                placeholder="Ex: 180"
                value={warrantyTime}
                onChange={(text) => setWarrantyTime(text.currentTarget.value)}
                hasError={hasErrorOnWarrantyTime}
                onFocus={() => setHasErrorOnWarrantyTime("")}
              />
            </S.WrapperInput>

            <S.WrapperDrodown>
              <Dropdown
                placeholder="Selecione"
                options={warrantyExtensionOptions}
                categoryIdSelected={warrantyExtension.id}
                // eslint-disable-next-line react/jsx-no-bind
                onClickedValue={handleWarrantyExtension}
                hasError={hasErrorOnWarrantyExtension}
                clearDropDownItem
                // eslint-disable-next-line react/jsx-no-bind
                handleClear={handleClearWarrantyExtension}
              />
            </S.WrapperDrodown>
          </S.Row>
        </S.Wrapper>

        <S.Wrapper>
          <CommentsTextArea
            label="Detalhamento do Serviço (opcional)"
            value={observationText}
            onChange={(event) =>
              setObservationText(event.target.value.trimStart())
            }
          />
        </S.Wrapper>

        <S.Wrapper>
          <Input
            name="Valor (opcional)"
            maskType="money"
            valueCurrency={price}
            onChangeCurrency={({ formattedValue, floatValue }) => {
              handleChangePrice(formattedValue);
              setUnmaskedPrice(floatValue!);
            }}
            hasError={hasErrorOnPrice}
            onFocusClearError={() => setHasErrorOnPrice("")}
          />
        </S.Wrapper>

        {serviceChecklist.length > 0 && (
          <S.Wrapper>
            {serviceChecklist.map((currentChecklist, index) => {
              return (
                <S.WrapperService key={index}>
                  <CardChecklist>
                    <S.WrapperInfoCard>
                      <S.WrapperData>
                        <h6>{currentChecklist.name}</h6>
                        <p>{currentChecklist?.registers?.length} Registros</p>
                      </S.WrapperData>
                      <S.WrapperButtom>
                        {/*validar o checklist padrao nao pode ser editado...*/}
                        <EditButton
                          onClick={() => {
                            handleSetEditPage(true);
                            detailItem(currentChecklist);
                          }}
                        />
                        <DeleteButton
                          //disabled={currentChecklist.default ? true : false}
                          onClick={() => {
                            setIndexDelete(index);
                            setIsOpenAlertModalDelete(true);
                            //handleDeleteRegister(index)
                          }}
                        />
                      </S.WrapperButtom>
                    </S.WrapperInfoCard>
                  </CardChecklist>
                </S.WrapperService>
              );
            })}
          </S.Wrapper>
        )}

        {/* TODO comentar os botão antes de subir pra api */}
        <ShowWhenHavePermission
          moduleHash="service-checklist"
          actionHash="view"
        >
          <S.Wrapper>
            <Button
              typeButton="outline"
              onClick={() => {
                handleSetEditPage(false);

                setDataContext();
                if (handleAddChecklist) handleAddChecklist();
              }}
            >
              Incluir checklists
            </Button>
          </S.Wrapper>
        </ShowWhenHavePermission>

        <S.Wrapper>
          <Button
            loading={isLoadingButton}
            onClick={() => {
              handleSetIsNotMenusAccess(false);
              handleCreateService();
            }}
          >
            Salvar
          </Button>
        </S.Wrapper>
      </S.Content>
    </>
  );
}
