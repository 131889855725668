import styled, { css } from "styled-components";

export const Container = styled.div`
  ${({ theme }) => css`
    width: 52%;
    height: 100%;
    margin-bottom: 50px;
    padding-left: 30px;
    padding-top: 20px;
    overflow: hidden;

    border-right: 1px solid ${theme.colors.background_light};
  `}
`;

export const Top = styled.div`
  ${({ theme }) => css`
    margin-bottom: 30px;

    display: flex;
    align-items: center;

    h4 {
      font-size: ${theme.font.sizes.large};
      color: ${theme.colors.title};
      font-weight: ${theme.font.bold};
    }
  `}
`;

export const ButtonClose = styled.button`
  ${({ theme }) => css`
    width: 35px;
    height: 35px;
    background-color: ${theme.colors.background_light};
    border-radius: 10px;

    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 25px;
  `}
`;

export const WrapperDate = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    width: 92%;
    flex-direction: row;
    justify-content: space-between;

    & > div {
      display: flex;
      align-items: center;
      margin-left: 50px;

      & > div {
        width: 8px;
        height: 8px;
        border-radius: 50%;
        background-color: ${theme.colors.schedule};
        margin-right: 6px;
      }
    }

    p {
      display: inline;
      font-size: ${theme.font.sizes.small};
      font-family: ${theme.font.normal};
      color: ${theme.colors.primary_medium};
    }

    span {
      color: ${theme.colors.title};
      font-size: ${theme.font.sizes.small};
      font-family: ${theme.font.normal};
    }
  `}
`;

export const Content = styled.main`
  width: 92%;
  margin: 40px 0;

  & > div:not(#section-stages) {
    ${({ theme }) => css`
      margin-bottom: 30px;

      & > span {
        display: block;
        color: ${theme.colors.title};
        font-size: ${theme.font.sizes.xsmall};
        margin-bottom: 8px;
      }
    `}
  }
`;

export const ContentBoxTitle = styled.h4`
  ${({ theme }) => css`
    font-size: ${theme.font.sizes.xsmall};
    color: ${theme.colors.title};
    margin-bottom: 14px;
  `}
`;

export const WrapperTotal = styled.div`
  margin-top: 20px;
`;

export const ContentBoxText = styled.p`
  ${({ theme }) => css`
    color: ${theme.colors.text};
    font-size: ${theme.font.sizes.xsmall};
    margin-bottom: 4px;
    display: flex;
    align-items: center;
    white-space: pre-wrap;

    .moneyText {
      color: ${theme.colors.primary};
    }
  `}
`;

export const ContentBoxObservationText = styled.pre`
  ${({ theme }) => css`
    white-space: pre-wrap;
    color: ${theme.colors.text};
    font-size: ${theme.font.sizes.xsmall};
    margin-bottom: 4px;
    font-family: ${theme.font.family};
  `}
`;

export const TextBold = styled.span`
  ${({ theme }) => css`
    color: ${theme.colors.text};
    font-size: ${theme.font.sizes.xsmall};
    font-weight: ${theme.font.bold};
  `}
`;

export const Separator = styled.div`
  width: 100%;
  padding: 4px 0;
`;

export const SectionStages = styled.div`
  margin-top: 20px;
  margin-bottom: 30px;

  & > span {
    display: block;
    color: ${({ theme }) => theme.colors.title};
    font-size: ${({ theme }) => theme.font.sizes.small};
    margin-bottom: 18px;
  }
`;

export const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 8px;

  & > span {
    ${({ theme }) => css`
      color: ${theme.colors.text};
      font-size: ${theme.font.sizes.small};
      font-weight: ${theme.font.bold};
    `}
  }
`;

export const WrapperButtonsSVG = styled.div`
  margin: 20px 0 50px;
  padding: 40px 0;
  display: flex;
  justify-content: flex-start;
  border-top: 1px solid #707070;
  border-bottom: 1px solid #707070;
  display: flex;
  justify-content: space-around;
`;

export const Footer = styled.div`
  margin-top: 30px;
`;

export const ObservationButton = styled.button`
  ${({ theme }) => css`
    color: ${theme.colors.text};
    font-size: 12px;
    text-decoration: underline;
  `}
`;
