import { useEffect, useRef, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { FiX, FiChevronLeft } from "react-icons/fi";
import { useTheme } from "styled-components";
import * as Yup from "yup";

import { Input } from "components/Input";
import { Button } from "components/Button";
import { AlertModal } from "components/AlertModal";
import { LoadingProfiz } from "components/LoadingProfiz";

import LinearEditSvg from "assets/icons/linear-edit.svg";
import CopySvg from "assets/icons/copy.svg";
import WarningSvg from "assets/icons/warning-icon.svg";
import AcceptIcon from "assets/icons/check-background.svg";
import WarningIcon from "assets/icons/warning-icon.svg";

import { useToast } from "hooks/toast";
import { ResearchResponseDTO } from "dtos/ResearchResponseDTO";

import apiv2 from "services/apiv2";
import * as S from "./styles";
import { AvaliationCard, AvaliationProps } from "components/AvaliationCard";
import { AvaliationsResponseDTO } from "dtos/AvaliationsResponseDTO";
import { ModalRight } from "components/ModalRight";
import { ModalServiceOrderDetails } from "components/ModalServiceOrderDetails";
import { useHasPermission } from "hooks/permission/useHasPermission";
import { ShowWhenHavePermission } from "components/Permission";

type RouteParams = {
  emailCliente: string;
  hashResearch: string;
  fromList?: boolean;
};

export function SendSatisfactionSurvey() {
  const theme = useTheme();
  const history = useHistory();
  const { permission } = useHasPermission({
    actionHash: "edit",
    moduleHash: "satisfaction-survey",
  });

  const location = useLocation<RouteParams>();
  const { addToast } = useToast();

  const inputRef = useRef<HTMLInputElement>(null);

  const [idServiceOrder, setIdServiceOrder] = useState(0);

  const [loadingButton, setLoadingButton] = useState(false);
  const [loadingPage, setLoadingPage] = useState(true);
  const [isVisibleAlertModal, setIsVisibleAlertModal] = useState(false);

  const [emailInput, setEmailInput] = useState(location.state?.emailCliente);
  const [errorInput, setErrorInput] = useState("");

  const [sendedResearch, setSendedResearch] = useState(false);
  const [sendEmail, setSendEmail] = useState(false);

  const [isInputDisabled, setIsInputDisabled] = useState(true);
  const [visibleButton, setVisibleButton] = useState(false);
  const [survey, setSurvey] = useState<ResearchResponseDTO>(
    {} as ResearchResponseDTO
  );
  const [avaliation, setAvaliation] = useState<AvaliationsResponseDTO>(
    {} as AvaliationsResponseDTO
  );
  const [modalVisible, setModalVisible] = useState(false);

  const [generatedLink, setGeneratedLink] = useState(false);

  async function loadServiceOrder() {
    try {
      setLoadingPage(true);
      const { 2: serviceOrderIdFromUrl } = location.pathname.split("/");

      const { data } = await apiv2.get(
        `/budgets/service-order/${serviceOrderIdFromUrl}`
      );

      setAvaliation(data.avaliation);
      const { id } = data;

      setIdServiceOrder(id);
    } catch {
      addToast({
        type: "error",
        title: "Ops...",
        description:
          "Não foi possível carregar os dados do da ordem de serviço.",
      });
    } finally {
      setLoadingPage(false);
    }
  }

  async function loadSatisfaction() {
    const { data } = await apiv2.get(
      `/budgets/service-order/research/${location.state?.hashResearch}`
    );
    setSurvey(data);
  }

  async function handleUpdateClientEmail() {
    try {
      const schema = Yup.object().shape({
        email: Yup.string()
          .email("Insira um e-mail válido")
          .required("O e-mail é obrigatório"),
      });

      await schema.validate({ email: emailInput }, { abortEarly: false });

      await apiv2.put(`/budgets/service-order/avaliations/${avaliation.id}`, {
        email: emailInput,
      });

      addToast({
        title: "Tudo certo!",
        description: "E-mail do cliente atualizado com sucesso!",
        type: "success",
      });

      setIsInputDisabled(true);
      setVisibleButton(false);
      setSendEmail(true);
      loadServiceOrder();
      if (location.state?.hashResearch) {
        loadSatisfaction();
      }
      setSendedResearch(false);
    } catch (error) {
      if (error instanceof Yup.ValidationError) {
        return setErrorInput(error.message);
      }

      addToast({
        title: "Ops...",
        description: "Ocorreu um erro ao atualizar o e-mail do cliente",
        type: "error",
      });
    }
  }

  function handleChangeInput(text: string) {
    setEmailInput(text);

    if (!!inputRef.current?.value) {
      setVisibleButton(true);
    } else {
      setVisibleButton(false);
    }
  }

  function handleCopy() {
    navigator.clipboard.writeText(
      `${window.location.origin}/research-form/${location.state?.hashResearch}`
    );
    addToast({
      title: "Tudo certo!",
      description: "Link copiado para área de transferencia.",
      type: "success",
    });
  }

  function handleCloseSendSatisfactionSurvey() {
    window.history.replaceState("/", "", "/");
    history.push({
      pathname: "/service-order",
      state: { before: "SendSatisfactionSurvey" },
    });
  }

  function handleToggleEnableInput() {
    inputRef.current?.focus();
    setIsInputDisabled(false);
    setSendEmail(false);
  }

  async function handleSubmitSatisfactionSurvey() {
    try {
      setLoadingButton(true);
      await apiv2.put(
        `budgets/service-order/${idServiceOrder}/research-email`,
        { hashResearch: location.state?.hashResearch }
      );

      addToast({
        type: "success",
        title: "Sucesso!",
        description: "Pesquisa enviada com sucesso!",
      });
      setSendedResearch(true);
      loadServiceOrder();
      if (location.state?.hashResearch) {
        loadSatisfaction();
      }
    } catch (error) {
      addToast({
        type: "error",
        title: "Ops...",
        description: "Ocorreu um erro ao enviar o e-mail de pesquisa",
      });
    } finally {
      setLoadingButton(false);
    }
  }

  useEffect(() => {
    if (location.state?.emailCliente) {
      setSendEmail(true);
    }
  }, [location.state?.emailCliente]);

  useEffect(() => {
    if (location.state?.hashResearch) {
      loadSatisfaction();
    }
    loadServiceOrder();
  }, [location]); // eslint-disable-line

  function handleGoBack() {
    localStorage.setItem("profiz-tabs", "1");
    history.push("/ratings");
  }

  const validationInput = !!emailInput && sendEmail ? false : true;

  return (
    <>
      {loadingPage ? (
        <LoadingProfiz isVisible={loadingPage} />
      ) : (
        <S.Container>
          <ModalRight
            isOpen={modalVisible}
            handleToggleOpen={() => setModalVisible(!modalVisible)}
          >
            <ModalServiceOrderDetails
              detailLink={survey?.serviceOrder?.detailLink}
              orderServiceId={survey?.serviceOrder?.id}
              onCloseModal={() => setModalVisible(false)}
            />
          </ModalRight>
          <AlertModal
            isVisible={isVisibleAlertModal}
            title="Atenção!"
            description='Fique tranquilo! Você poderá enviar a pesquisa de satisfação acessando "Meu Perfil" -> "Avaliações".'
            action="confirm"
            labelConfirm="OK"
            handleConfirm={() => {}}
            onCloseModal={handleCloseSendSatisfactionSurvey}
          />

          <S.Top>
            {location.state.fromList ? (
              <S.ButtonClose onClick={handleGoBack}>
                <FiChevronLeft size={14} color={theme.colors.title} />
              </S.ButtonClose>
            ) : (
              <S.ButtonClose onClick={() => setIsVisibleAlertModal(true)}>
                <FiX size={14} color={theme.colors.title} />
              </S.ButtonClose>
            )}
          </S.Top>
          {survey.status === "sended" && (
            <>
              <S.TitleText>
                Avaliação OS nº{survey.serviceOrder.sequenceNumber}
              </S.TitleText>
              <div style={{ width: "90%", marginTop: 30 }}>
                <AvaliationCard
                  data={
                    {
                      clientName: survey.serviceOrder.client.name,
                      sequenceNumber: survey.serviceOrder.sequenceNumber,
                    } as AvaliationProps
                  }
                  showStatus={false}
                />
                <S.Wrapper>
                  <img
                    src={WarningIcon}
                    style={{ marginRight: 10 }}
                    alt="Icone accept"
                  ></img>
                  <S.TextRow>A pesquisa ainda não foi respondida</S.TextRow>
                </S.Wrapper>
              </div>
              <Button
                onClick={() => setModalVisible(true)}
                style={{ width: "90%", marginBottom: 30 }}
                typeButton="outline"
              >
                Ver ordem de serviço
              </Button>
            </>
          )}

          <S.TitleText>
            {survey.status === "sended"
              ? "Reenvie a pesquisa de satisfação ao cliente"
              : "Envie uma pesquisa de satisfação ao seu cliente!"}
          </S.TitleText>

          <S.Content>
            <S.WrapperSubtitle>
              <S.Subtitle>
                Recebendo avaliações pelos serviços prestados, você pode ganhar
                destaque em nossa plataforma e conseguir cada vez mais clientes!
              </S.Subtitle>
            </S.WrapperSubtitle>

            <S.WrapperInput>
              <div>
                <Input
                  name="O e-mail com a pesquisa será enviado para: "
                  placeholder="sem e-mail cadastrado"
                  value={emailInput}
                  onChange={(e) => handleChangeInput(e.target.value)}
                  customRef={inputRef}
                  readOnly={isInputDisabled}
                  labelIcon="Ativar campo de email"
                  icon={!visibleButton && permission ? LinearEditSvg : ""}
                  onClickIcon={handleToggleEnableInput}
                  hasError={errorInput}
                  onFocusClearError={() => setErrorInput("")}
                />
              </div>

              {visibleButton && emailInput && (
                <S.ButtonSaveEmail onClick={handleUpdateClientEmail}>
                  Salvar
                </S.ButtonSaveEmail>
              )}
            </S.WrapperInput>
            <S.WrapperInfo>
              <span>
                O e-mail está incorreto ou ainda não foi preenchido? <br />
                Preencha o campo com o e-mail correto e clique no ícone do lápis
                para cadastrar.
              </span>
            </S.WrapperInfo>
            <S.WrapperButton>
              <ShowWhenHavePermission
                moduleHash="satisfaction-survey"
                actionHash="edit-send"
              >
                <Button
                  loading={loadingButton}
                  disabled={validationInput}
                  typeButton={validationInput ? "disabled" : "default"}
                  onClick={handleSubmitSatisfactionSurvey}
                >
                  <span>
                    {survey.status === "sended"
                      ? "Reenviar pesquisa de satisfação via e-mail"
                      : "Enviar pesquisa de satisfação via e-mail"}
                  </span>
                </Button>
              </ShowWhenHavePermission>
            </S.WrapperButton>
            {(sendedResearch || !avaliation.sendEmailPermission) && (
              <S.SendedSurvey>
                <img
                  src={avaliation.sendEmailPermission ? AcceptIcon : WarningSvg}
                  style={{ marginRight: 10 }}
                  alt="Icone accept"
                ></img>
                <div>
                  <S.TopText
                    style={{
                      color: avaliation.sendEmailPermission
                        ? theme.colors.primary
                        : theme.colors.model,
                    }}
                  >
                    {avaliation.sendEmailPermission
                      ? "Pesquisa enviada com sucesso!"
                      : "Você já fez uma tentativa de reenvio da pesquisa."}
                  </S.TopText>
                  <S.Subtitle>
                    {avaliation.sendEmailPermission
                      ? `Assim que a avaliação dos serviços da OS nº${
                          survey.serviceOrder.sequenceNumber + " "
                        }for realizada, ela será exibida em seu perfil.`
                      : `Como você já reenviou a pesquisa uma vez, o número de tentativas de reenvio foi esgotado. Por favor, oriente o seu cliente a verificar a caixa de spam caso não tenha recebido o e-mail, ou gere um link da pesquisa para compartilhar com ele.
                `}
                  </S.Subtitle>
                </div>
              </S.SendedSurvey>
            )}
            <S.TitleText style={{ marginTop: 30 }}>Ou gere um link</S.TitleText>
            <S.WrapperSubtitle style={{ marginBottom: 0 }}>
              <S.Subtitle>
                Você pode gerar um link da pesquisa e enviar para o cliente por
                onde preferir.
              </S.Subtitle>
            </S.WrapperSubtitle>
            {!generatedLink ? (
              <S.WrapperButton>
                <Button
                  typeButton={"default"}
                  onClick={() => setGeneratedLink(true)}
                >
                  <span>Gerar link</span>
                </Button>
              </S.WrapperButton>
            ) : (
              <S.WrapperButton style={{ marginTop: 10 }}>
                <div>
                  <Input
                    paddingRight={50}
                    placeholder="sem e-mail cadastrado"
                    value={`${window.location.origin}/research-form/${location.state?.hashResearch}`}
                    disabled
                    customRef={inputRef}
                    readOnly={isInputDisabled}
                    labelIcon="Copiar link"
                    icon={CopySvg}
                    onClickIcon={handleCopy}
                    hasError={errorInput}
                    onFocusClearError={() => setErrorInput("")}
                  />
                </div>
              </S.WrapperButton>
            )}
          </S.Content>
        </S.Container>
      )}
    </>
  );
}
