import styled, { css, keyframes } from "styled-components";
import { lighten, shade } from "polished";

type ModalProps = {
  isVisible: boolean;
};

type ButtonConfirmProps = {
  color?: string;
};

const ModalScale = keyframes`
  from {
    transform: scale(0.5);
    opacity: 0.4;
  }
  to {
    transform: scale(1);
    opacity: 1;
  }
`;

export const ContainerOverlay = styled.aside<ModalProps>`
  ${({ theme, isVisible }) => css`
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;

    display: ${isVisible ? "flex" : "none"};
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: ${theme.layers.alwaysOnTop};
  `}
`;

export const BoxModal = styled.div`
  ${({ theme }) => css`
    width: 20%;
    min-width: 380px;
    max-width: 460px;
    padding: 16px;
    border-radius: 10px;

    display: flex;
    flex-direction: column;

    background-color: ${theme.colors.strong_background};
    animation: ${ModalScale} 0.3s ease-in-out;
  `}
`;

export const Header = styled.div`
  width: 100%;
  height: 56px;
  margin-bottom: 10px;
  display: flex;
  justify-content: center;
  align-items: center;

  & > button {
    padding: 2px;
    border-radius: 6px;

    display: flex;
    justify-content: center;
    align-items: center;
    transition: background 0.2s;

    &:hover {
      background: ${({ theme }) => lighten(0.1, theme.colors.background_modal)};
    }
  }
`;

export const Main = styled.div`
  width: 100%;
  height: 46%;
  margin-bottom: 30px;
`;

export const Title = styled.h4`
  ${({ theme }) => css`
    font-family: ${theme.font.family};
    font-size: ${theme.font.sizes.large};
    font-weight: ${theme.font.bold};
    color: ${theme.colors.text_light_87};
    text-align: center;
    margin-bottom: 15px;
  `}
`;

export const Description = styled.p`
  ${({ theme }) => css`
    font-family: ${theme.font.family};
    font-size: ${theme.font.sizes.xsmall};
    font-weight: ${theme.font.normal};
    color: ${theme.colors.text_light_60};
    line-height: 20px;
    margin: 0 8px;
    text-align: center;
  `}
`;

export const HighlightedDescription = styled.p`
  ${({ theme }) => css`
    font-family: ${theme.font.family};
    font-size: ${theme.font.sizes.small};
    font-weight: ${theme.font.bold};
    color: ${theme.colors.text_light_87};
    line-height: 20px;
    margin: 0 8px;
    text-align: center;
  `}
`;

export const Footer = styled.footer`
  width: 100%;

  display: flex;
  align-items: center;
  justify-content: flex-end;
  border-top: 1px solid ${({ theme }) => theme.colors.background_light};
`;

export const WrapperButtons = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

export const ButtonConfirm = styled.button<ButtonConfirmProps>`
  ${({ theme, color }) => css`
    width: 100%;
    height: 50px;
    color: ${theme.colors.background};
    font-size: ${theme.font.sizes.small};

    display: flex;
    justify-content: center;
    align-items: center;

    background-color: ${!!color ? color : theme.colors.primary};
    border-radius: 10px;
    transition: background 0.2s;

    &:hover {
      background: ${shade(0.2, !!color ? color : theme.colors.primary)};
    }
    margin-bottom: 25px;
  `}
`;

export const ButtonCancel = styled.button`
  ${({ theme }) => css`
    width: 100%;
    height: 45px;
    color: ${theme.colors.title};
    font-size: ${theme.font.sizes.small};

    display: flex;
    justify-content: center;
    align-items: center;

    background-color: ${theme.colors.strong_background};
    border-radius: 8px;
    transition: opacity 0.2s;

    &:hover {
      opacity: 0.7;
    }

    margin-bottom: 15px;
  `}
`;
