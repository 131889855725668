import React, { ButtonHTMLAttributes } from 'react'

import * as S from './styles'

type Manual = {
  type: string
  name: string
  image: string
  link: string
}

type ManualCardProps = ButtonHTMLAttributes<HTMLButtonElement> & {
  manual: Manual
}

export function ManualCard({ manual, ...rest }: ManualCardProps) {
  function handleOpenManual(link: string) {
    window.open(link)
  }
  return (
    <S.Container onClick={() => handleOpenManual(manual.link)} {...rest}>
      <img src={manual.image} alt={`imagem do manual ${manual.name}`} />

      <div>
        <p>{manual.type}</p>
        <p>{manual.name}</p>
      </div>
    </S.Container>
  )
}
