import styled, { css } from "styled-components";

export const Container = styled.div`
  ${({ theme }) => css`
    padding: 15px;
    background-color: ${theme.colors.background_light};
    border-radius: 12px;
    width: 100%;
  `}
`;

export const Title = styled.p`
  ${({ theme }) => css`
    color: ${theme.colors.text_light};
    font-size: ${theme.font.sizes.small};
    font-family: ${theme.font.family};
  `}
`;

export const WrapperInput = styled.div`
  display: flex;
  align-items: center;
`;

export const InputContainer = styled.div`
  ${({ theme }) => css`
    width: 50px;
    min-width: 50px;
    height: 50px;
    border: 1px dashed ${theme.colors.primary};
    border-radius: 10px;

    display: flex;
    justify-content: center;
    align-items: center;

    input {
      display: none;
    }
  `}
`;

export const Description = styled.p`
  ${({ theme }) => css`
    color: ${theme.colors.text_light};
    font-size: ${theme.font.sizes.xsmall};
    font-family: ${theme.font.family};
    margin-left: 20px;
    letter-spacing: 0.26px;
  `}
`;