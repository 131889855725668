import styled, { css } from 'styled-components'

export const Container = styled.button`
  ${({ theme }) => css`
    width: 315px;
    height: 100px;
    background-color: ${theme.colors.background_modal};
    border-radius: 15px;

    display: flex;
    align-items: center;

    img {
      object-fit: cover;
      width: 100%;
      max-width: 100px;
      height: 100px;
      background-color: ${theme.colors.background};
      border-radius: 15px;
    }

    div {
      padding: 15px;

      p {
        color: ${theme.colors.title};
        font-size: ${theme.font.sizes.small};
        text-align: left;
        margin-bottom: 5px;
      }

      p + p {
        color: ${theme.colors.text};
        font-size: ${theme.font.sizes.xsmall};
        text-align: left;
      }
    }
  `}
`
