import React, {
  useEffect,
  useState,
  ChangeEvent,
  useRef,
  FormEvent,
} from "react";
import { useHistory, useLocation } from "react-router";

import { FiPaperclip, FiX } from "react-icons/fi";
import { FaFilePdf } from "react-icons/fa";
import { FaArrowsAltH, FaArrowsAltV } from "react-icons/fa";
import { MdClose, MdRotate90DegreesCcw } from "react-icons/md";
import Cropper, { ReactCropperElement } from "react-cropper";
import { useTheme } from "styled-components";
import "cropperjs/dist/cropper.css";

import { useToast } from "hooks/toast";

import { InsertCertificatesShimmer } from "Shimmer/InsertCertificatesShimmer";

import { ArrowButton } from "components/ArrowButton";
import { Dropdown, DropdownOption } from "components/DropDown";
import { Input } from "components/Input";
import { Button } from "components/Button";
import { AlertModal } from "components/AlertModal";

import { mappedDropdown } from "utils/mappedDropDown";
import { api } from "services/api";

import { CompanyBrand } from "./CompanyBrand";

import * as S from "./styles";

type DropDownProps = {
  id: number;
  name: string;
};

type IdSelectedProps = null | number;

type RouteState = {
  before: string;
};

export function InsertCertificates() {
  const { addToast } = useToast();
  const theme = useTheme();
  const history = useHistory();
  const location = useLocation<RouteState>();

  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingButton, setIsLoadingButton] = useState(false);
  const [isOpenAlertModal, setIsOpenAlertModal] = useState(false);

  const [areas, setAreas] = useState<DropDownProps[]>([]);
  const [certificationsType, setCertificationsType] = useState<DropDownProps[]>(
    []
  );
  const [qualificationsType, setQualificationsType] = useState<DropDownProps[]>(
    []
  );

  const [hasQualification, setHasQualification] = useState(true);

  const [areaSelected, setAreaSelected] = useState<IdSelectedProps>(null);
  const [certificationTypeSelected, setCertificationTypeSelected] =
    useState<IdSelectedProps>(null);
  const [qualificationTypeSelected, setQualificationTypeSelected] =
    useState<IdSelectedProps>(null);
  const [companyBrandSelected, setCompanyBrandSelected] =
    useState<IdSelectedProps>(null);
  const [fileValue, setFileValue] = useState("");
  const [fileCertificationEncode, setFileCertificationEncode] = useState("");
  const [fileCertificationType, setFileCertificationType] = useState("jpg");
  const [expirationDate, setExpirationDate] = useState("");

  const [hasErrorOnArea, setHasErrorOnArea] = useState("");
  const [hasErrorOnCertificationType, setHasErrorOnCertificationType] =
    useState("");
  const [hasErrorOnQualificationType, setHasErrorOnQualificationType] =
    useState("");
  const [hasErrorOnCompanyBrand, setHasErrorOnCompanyBrand] = useState("");
  const [hasErrorOnExpirationDate, setHasErrorOnExpirationDate] = useState("");
  const [addedDate, setAddedDate] = useState(false);

  const fileInputRef = useRef<HTMLInputElement>(null);
  const cropperRef = useRef<ReactCropperElement>(null);
  const [isOpenModalEditImage, setIsOpenModalEditImage] = useState(false);
  const [scaleX, setScaleX] = useState(1);
  const [scaleY, setScaleY] = useState(1);

  useEffect(() => {
    async function fetchDetailsCertifications() {
      try {
        const response = await api.get("me/certifications/detail");

        const { area, certificationType, qualificationType } = response.data;
        const mappedArea = mappedDropdown(area);
        const mappedCertificationType = mappedDropdown(certificationType);
        const mappedQualificationType = mappedDropdown(qualificationType);

        setAreas(mappedArea);
        setCertificationsType(mappedCertificationType);
        setQualificationsType(mappedQualificationType);
      } catch (error) {
        addToast({
          type: "error",
          title: "Erro ao carregar os dados",
          description: "Ocorreu um erro ao carregar os dados da página",
        });

        // sendError(error)
      } finally {
        setIsLoading(false);
      }
    }

    fetchDetailsCertifications();
  }, [addToast]);

  function handleSelectArea({ id }: DropdownOption) {
    setAreaSelected(id);
    setHasErrorOnArea("");
  }

  function handleSelectCertificationType({ id }: DropdownOption) {
    setCertificationTypeSelected(id);

    if (id === 1) {
      setHasQualification(true);
      setAddedDate(false);
    } else {
      setHasQualification(false);
    }

    setHasErrorOnCertificationType("");
  }

  function handleSelectQualificationType({ id }: DropdownOption) {
    setQualificationTypeSelected(id);
    setHasErrorOnQualificationType("");
  }

  function handleSelectCompanyBrand({ id }: DropdownOption) {
    setCompanyBrandSelected(id);
    setHasErrorOnCompanyBrand("");
  }

  function getBase64(event: ChangeEvent<HTMLInputElement>) {
    if (event.target.files) {
      const url = URL.createObjectURL(event.target.files[0]);
      const file = event.target.files[0];
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const [_, extension] = file.type.split("/");

      setFileValue(url);
      setFileCertificationType(extension);

      if (extension === "pdf") {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
          setFileCertificationEncode(reader.result as string);
        };
        reader.onerror = (error) => {
          addToast({
            type: "error",
            title: "Erro ao adicionar o arquivo!",
            description: "Tente novamente",
          });
        };
      } else {
        setIsOpenModalEditImage(true);
      }
    }
  }

  function handleDeleteFile() {
    setFileValue("");
    setFileCertificationEncode("");
    setFileCertificationType("");

    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
  }

  function handleConfirmModal() {
    setIsOpenAlertModal(false);
    const beforeScreen = location?.state?.before && location.state.before;

    if (beforeScreen === "finishRegister") {
      history.push("/");
    } else {
      history.goBack();
    }
  }
  async function handleSaveCertification() {
    setIsLoadingButton(true);

    try {
      if (!areaSelected && areaSelected !== 0) {
        setHasErrorOnArea("Selecione um campo de atuação");
      } else {
        setHasErrorOnArea("");
      }

      if (!certificationTypeSelected) {
        setHasErrorOnCertificationType("Selecione um tipo de certificação");
      } else {
        setHasErrorOnCertificationType("");
      }

      if (
        (!certificationTypeSelected && !qualificationTypeSelected) ||
        (certificationTypeSelected === 1 && !qualificationTypeSelected)
      ) {
        setHasErrorOnQualificationType("Selecione um tipo de qualificação");
      } else {
        setHasErrorOnQualificationType("");
      }

      if (!companyBrandSelected) {
        setHasErrorOnCompanyBrand("Selecione um emissor/fabricante");
      } else {
        setHasErrorOnCompanyBrand("");
      }

      const dateValid = new Date(expirationDate).toString() !== "Invalid Date";

      if (
        (!dateValid && addedDate) ||
        (expirationDate.length === 0 && addedDate)
      ) {
        setHasErrorOnExpirationDate("Digite uma data válida");
        return;
      } else {
        setHasErrorOnExpirationDate("");
      }
      if (
        (!areaSelected && areaSelected !== 0) ||
        !certificationTypeSelected ||
        (!certificationTypeSelected && !qualificationTypeSelected) ||
        (certificationTypeSelected === 1 && !qualificationTypeSelected) ||
        !companyBrandSelected
      ) {
        addToast({
          type: "error",
          title: "Erro ao cadastrar o certificado",
          description: "Preencha todos os campos obrigatórios",
        });
        return;
      }

      if (!fileCertificationEncode) {
        addToast({
          type: "error",
          title: "Cadastre um certificado!",
          description: "Adicione um anexo",
        });
        return;
      }

      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const [_, fileBase64Splited] = fileCertificationEncode.split(",");

      const certificationData = {
        area: areaSelected,
        certificationType: certificationTypeSelected,
        companyBrand: companyBrandSelected,
        expirationDate,
        file: {
          encode: fileBase64Splited,
          extensao: fileCertificationType,
        },
        ...(certificationTypeSelected === 1 && {
          qualificationType: qualificationTypeSelected,
        }),
      };

      await api.post("me/certifications", certificationData);

      setIsOpenAlertModal(true);
    } catch (err: any) {
      if (err.response.status === 400) {
        addToast({
          type: "error",
          title: "Insira outro anexo",
          description: "A extensão do anexo não é suportada!",
        });
        return;
      }

      // sendError(err)
      addToast({
        type: "error",
        title: "Ops!!",
        description: "Ocorreu um erro ao adicionar sua certificação",
      });
    } finally {
      setIsLoadingButton(false);
    }
  }

  const handleEditFinishImage = () => {
    const imageElement = cropperRef?.current;
    const cropper = imageElement?.cropper;
    const img = cropper!.getCroppedCanvas().toDataURL();
    setFileCertificationEncode(img);
    setFileValue(img);
    setIsOpenModalEditImage(false);
  };

  const rotateImage = () => {
    const imageElement = cropperRef?.current;
    const cropper = imageElement?.cropper;
    cropper!.rotate(-90);
  };

  const flipImage = (type: any) => {
    const imageElement = cropperRef?.current;
    const cropper = imageElement?.cropper;

    if (type === "h") {
      cropper!.scaleX(scaleX === 1 ? -1 : 1);
      setScaleX(scaleX === 1 ? -1 : 1);
    } else {
      cropper!.scaleY(scaleY === 1 ? -1 : 1);
      setScaleY(scaleY === 1 ? -1 : 1);
    }
  };

  const handleSubmitForm = (event: FormEvent<HTMLFormElement>): void => {
    event.preventDefault();
  };

  return (
    <S.Container>
      <AlertModal
        isVisible={isOpenAlertModal}
        title="O certificado foi inserido com sucesso"
        description="O certificado será analisado/verificado pelo time da Profiz. Após a aprovação, o seu certificado estará listado na página de Minhas Certificações"
        action="confirm"
        handleConfirm={() => handleConfirmModal()}
        onCloseModal={() => handleConfirmModal()}
      />

      <S.ContainerOverlay isVisible={isOpenModalEditImage}>
        <S.BoxModal>
          <S.Header>
            <button
              type="button"
              onClick={() => {
                setIsOpenModalEditImage(false);
                handleDeleteFile();
              }}
            >
              <MdClose size={28} color={theme.colors.text} />
            </button>
          </S.Header>

          <S.Title>Editar certificado</S.Title>

          <S.WrapperActionButtons>
            <button type="button" onClick={rotateImage}>
              <MdRotate90DegreesCcw size={28} color={theme.colors.text} />
            </button>

            <button onClick={() => flipImage("h")}>
              <FaArrowsAltH size={28} color={theme.colors.text} />
            </button>

            <button onClick={() => flipImage("v")}>
              <FaArrowsAltV size={28} color={theme.colors.text} />
            </button>
          </S.WrapperActionButtons>
          <Cropper
            src={fileValue}
            style={{ height: 400, width: "100%" }}
            guides={false}
            ref={cropperRef}
          />

          <S.Footer>
            <S.WrapperButtons>
              <S.ButtonCancel
                onClick={() => {
                  setIsOpenModalEditImage(false);
                  handleDeleteFile();
                }}
              >
                Cancelar
              </S.ButtonCancel>
              <S.ButtonConfirm onClick={() => handleEditFinishImage()}>
                Confirmar
              </S.ButtonConfirm>
            </S.WrapperButtons>
          </S.Footer>
        </S.BoxModal>
      </S.ContainerOverlay>

      <header>
        <div>
          <ArrowButton
            handleFunction={() => {
              const beforeScreen =
                location?.state?.before && location.state.before;

              if (beforeScreen === "finishRegister") {
                history.push("/");
              } else {
                history.goBack();
              }
            }}
          />
        </div>

        <div>
          <h1>Minhas certificações</h1>
          <span>Insira os dados da sua certificação.</span>
        </div>
      </header>
      <S.Content>
        {isLoading && <InsertCertificatesShimmer />}

        {!isLoading && (
          <form id="form_envio_certificado" onSubmit={handleSubmitForm}>
            <S.Wrapper>
              <Dropdown
                label="Campo de atuação*"
                placeholder="Selecione um campo de atuação"
                options={areas}
                // eslint-disable-next-line react/jsx-no-bind
                onClickedValue={handleSelectArea}
                hasError={hasErrorOnArea}
              />
            </S.Wrapper>

            <S.Wrapper>
              <Dropdown
                label="Tipo de certificação*"
                placeholder="Selecione o tipo de certificação"
                options={certificationsType}
                // eslint-disable-next-line react/jsx-no-bind
                onClickedValue={handleSelectCertificationType}
                hasError={hasErrorOnCertificationType}
              />
            </S.Wrapper>

            {hasQualification && (
              <S.Wrapper>
                <Dropdown
                  label="Tipo de Qualificação*"
                  placeholder="Selecione o tipo de qualificação"
                  options={qualificationsType}
                  // eslint-disable-next-line react/jsx-no-bind
                  onClickedValue={handleSelectQualificationType}
                  hasError={hasErrorOnQualificationType}
                />
              </S.Wrapper>
            )}

            <S.Wrapper>
              <CompanyBrand
                hasError={hasErrorOnCompanyBrand}
                // eslint-disable-next-line react/jsx-no-bind
                handleSelectCompanyBrand={handleSelectCompanyBrand}
              />
            </S.Wrapper>
            <S.Wrapper style={{ position: "relative" }}>
              <Input
                name="Data de validade"
                placeholder="dd/mm/aaaa"
                type="date"
                value={expirationDate}
                onClick={() => setAddedDate(true)}
                onChange={(text) => {
                  setAddedDate(true);
                  setExpirationDate(text.currentTarget.value);
                }}
                max={"2999-12-12"}
                onFocusClearError={() => setHasErrorOnExpirationDate("")}
                hasError={hasErrorOnExpirationDate}
              />
              <S.ClearDate
                type="button"
                onClick={() => {
                  setExpirationDate("1970-01-01");
                  setAddedDate(false);
                  setTimeout(() => {
                    setExpirationDate("");
                  }, 100);
                }}
              >
                X
              </S.ClearDate>
            </S.Wrapper>

            <S.WrapperAttachmentButton>
              <S.Wrapper>
                <label htmlFor="attachment">
                  <FiPaperclip size="18" color={theme.colors.primary} />
                  <span>Inserir anexo</span>
                  <input
                    id="attachment"
                    type="file"
                    accept="image/*, .pdf"
                    onChange={getBase64}
                    ref={fileInputRef}
                  />
                </label>
              </S.Wrapper>

              <p>
                Ter seu certificado anexado significa maiores chances de
                prospectar clientes.
              </p>
            </S.WrapperAttachmentButton>

            {!!fileCertificationEncode && (
              <S.WrapperPreview>
                <S.PreviewButton onClick={() => handleDeleteFile()}>
                  {fileCertificationType === "pdf" ? (
                    <div>
                      <FaFilePdf size={20} color={theme.colors.text} />
                    </div>
                  ) : (
                    <img src={fileValue} alt="Previsualização do anexado" />
                  )}
                  <FiX size={15} color={theme.colors.text} />
                </S.PreviewButton>

                <p>1 item selecionado</p>
              </S.WrapperPreview>
            )}

            <Button
              loading={isLoadingButton}
              onClick={() => handleSaveCertification()}
            >
              Adicionar certificação
            </Button>
          </form>
        )}
      </S.Content>
    </S.Container>
  );
}
