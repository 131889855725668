import { CSSProperties } from "react";

import ScheduleIcon from 'assets/icons/schedule.svg';

import * as S from "./styles";

interface Props {
  name: string;
  placeholder: string;
  hasError?: string;
  onClick?: () => void;
  containerStyle?: CSSProperties | undefined;
  calendarIcon?: boolean;
  disabled?: boolean;
}

export function FormItem({
  name,
  placeholder,
  hasError = "",
  containerStyle,
  onClick,
  calendarIcon = false,
  disabled = false,
}: Props) {

  return (
    <>
      {!!name && <S.Label>{name}</S.Label>}
      <S.Container
        style={containerStyle}
        isErrored={!!hasError}
        onClick={onClick}
        disabled={disabled}
      >
        <S.Placeholder hasError={!!hasError}>{placeholder}</S.Placeholder>
        {calendarIcon && <img src={ScheduleIcon} alt="Agenda" width={30} height={30} />}
      </S.Container>
      {!!hasError && (
        <S.ErrorText>{hasError}</S.ErrorText>
      )}
    </>
  );
}
