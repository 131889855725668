import React, { useEffect, useState } from "react";

import { Dropdown, DropdownOption } from "components/DropDown";
import { InputAddNewCategory } from "components/InputAddNewCategory";
import { DisabledDropDown } from "components/DisabledDropDown";

import { useServiceFormTree, ServiceProps } from "hooks/serviceFormTree";
import { useServiceDropDown } from "hooks/servicesDropDown";
import { useToast } from "hooks/toast";

import { api } from "services/api";
import { useAccount } from "hooks/permission/account";
import { useHasPermission } from "hooks/permission/useHasPermission";

type ServiceNameProps = {
  hasErrorOnService: string;
  name?: string;
  disabled?: boolean;
  moduleHash?: string;
};

export function ServiceName({
  hasErrorOnService,
  name,
  disabled = false,
  moduleHash
}: ServiceNameProps) {
  const { addToast } = useToast();

  const { categoriesTree, handleSetCategoriesTree } = useServiceFormTree();
  const {
    selectedCategoryId,
    selectedService,
    handleService,
    handleEquipment,
    handleEquipmentType,
    handleCapacity,
  } = useServiceDropDown();

  const [loadingButton, setLoadingButton] = useState(false);
  const [newService, setNewService] = useState("");
  const [services, setServices] = useState<ServiceProps[]>([]);
  const [hasError, setHasError] = useState("");
  const { whoami } = useAccount();
  const { permission } = useHasPermission({
    actionHash: "view",
    moduleHash: "service-type",
  });

  useEffect(() => {
    const currentCategory = categoriesTree.find((category) => {
      return Number(category.id) === Number(selectedCategoryId);
    });

    if (currentCategory) {
      setServices(currentCategory.services);
    }
  }, [categoriesTree, selectedCategoryId]);

  useEffect(() => {
    setHasError(hasErrorOnService);
  }, [hasErrorOnService]);

  function handleNewServiceName(text: string) {
    setNewService(text.trimStart());
  }

  async function handleSubmit() {
    if (!newService) {
      addToast({
        title: "Ops",
        description: "Preencha com alguma informação antes de prosseguir",
        type: "error",
      });
      return;
    }

    try {
      setLoadingButton(true);
      const response = await api.post("/services/name", {
        categoryID: selectedCategoryId,
        name: newService,
        accountId: whoami?.id
      });

      const { id, name } = response.data;

      const newServiceName: ServiceProps = {
        id,
        name,
        equipments: [],
        default: "false",
      };

      const formattedCategories = categoriesTree.map((category) => {
        if (category.id === selectedCategoryId) {
          return {
            ...category,
            services: [...category.services, newServiceName],
          };
        }

        return category;
      });

      handleSetCategoriesTree(formattedCategories);
      setNewService("");

      addToast({
        title: "Sucesso",
        description: "Serviço cadastrado com sucesso!",
        type: "success",
      });
    } catch (err) {
      addToast({
        title: "Ops",
        description: "Ocorreu um erro ao cadastrar",
        type: "error",
      });
    } finally {
      setLoadingButton(false);
    }
  }

  function handleSelectService({ id, name }: DropdownOption) {
    handleService({ id, name });
    setHasError("");

    handleEquipment({} as DropdownOption);
    handleEquipmentType({} as DropdownOption);
    handleCapacity({} as DropdownOption);
  }

  function handleWarning() {
    addToast({
      title: "Atenção!",
      description: "Selecione uma categoria",
      type: "info",
    });
  }

  return (
    <>
      {selectedCategoryId > 0 ? (
        <Dropdown
          label="Serviço*"
          disabled={disabled}
          name={name}
          placeholder="Selecione um serviço"
          options={services}
          hasError={hasError}
          type="service"
          onClickedValue={handleSelectService}
          hasInput
          categoryIdSelected={Number(selectedService.id)}
          moduleHashValue={moduleHash}
          actionView={permission}
        >
          <InputAddNewCategory
            isLoadingButton={loadingButton}
            value={newService}
            // eslint-disable-next-line react/jsx-no-bind
            handleChangeNewCategory={handleNewServiceName}
            // eslint-disable-next-line react/jsx-no-bind
            handleSave={handleSubmit}
          />
        </Dropdown>
      ) : (
        <DisabledDropDown
          hasError={hasError}
          label="Serviço*"
          title="Selecione um serviço"
          onClick={() => handleWarning()}
        />
      )}
    </>
  );
}
