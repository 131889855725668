import styled, { css } from "styled-components";

export const Wrapper = styled.div`
  margin-bottom: 24px;
`;

export const wrapperText = styled.div`
  justify-content: center;
`;

export const Container = styled.div`
  align-items: center;
`;

export const Top = styled.div`
  ${({ theme }) => css`
    margin-bottom: 30px;

    display: flex;
    align-items: center;

    p {
      font-size: ${theme.font.sizes.large};
      color: ${theme.colors.title};
      font-weight: ${theme.font.bold};
    }
  `}
`;

export const Title = styled.p`
  ${({ theme }) => css`
    font-size: 18px;
    font-family: ${theme.font.bold};
    color: ${theme.colors.text_extra_light};
    letter-spacing: 0.4px;
    line-height: 20px;
    margin-bottom: 5px;
  `}
`;

export const BackButton = styled.button`
  ${({ theme }) => css`
    width: 35px;
    height: 35px;
    background-color: ${theme.colors.background_light};
    border-radius: 10px;

    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 25px;
  `}
`;
export const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const WrapperDrodown = styled.div`
  margin-top: 15px;
  width: 90%;
`;

export const WrapperInput = styled.div`
  ${({ theme }) => css`
    span {
      margin-bottom: 5px;
      display: block;
      color: ${theme.colors.title};
      font-size: 12px;
    }

    width: 100%;

    margin-right: 10px;
  `}
`;

export const WrapperInfoCard = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
  border-radius: 10px;
  min-height: 90px;
  align-items: center;
  justify-content: space-between;
`;

export const WrapperData = styled.div`
  ${({ theme }) => css`
    padding: 20px;
    h6 {
      margin-top: 5px;
      color: ${theme.colors.title};
      font-size: ${theme.font.sizes.small};
      text-align: left;
      letter-spacing: 0.26px;
      line-height: 16px;
      margin-bottom: 10px;
    }

    p {
      margin-top: 5px;
      color: ${theme.colors.text_light};
      font-size: ${theme.font.sizes.xsmall};
      text-align: left;
      letter-spacing: 0.26px;
      line-height: 16px;
    }
  `};
`;

export const WrapperButtom = styled.div`
  ${({ theme }) => css`
    width: 10%;
    min-height: 100px;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    background-color: ${theme.colors.background_light};

    display: flex;
    flex-direction: column;
  `}
`;
export const WrapperService = styled.div`
  margin-bottom: 20px;
`;