import React, { createContext, useContext, useState } from "react";
import { ProviderProps } from "hooks";

type TopMenuContextData = {
  isTopVisible: boolean;
  handleSetMenu: (visible: boolean) => void;
};

const TopMenuContext = createContext<TopMenuContextData>(
  {} as TopMenuContextData
);

export function useTopMenu(): TopMenuContextData {
  const context = useContext(TopMenuContext);
  if (!context) {
    throw Error("useTopMenu must be used within a TopMenuProvider");
  }
  return context;
}

export const TopMenuProvider = ({ children }: ProviderProps) => {
  const [isTopVisible, setVisible] = useState(true);

  function handleSetMenu(visible: boolean) {
    setVisible(visible);
  }

  return (
    <TopMenuContext.Provider value={{ isTopVisible, handleSetMenu }}>
      {children}
    </TopMenuContext.Provider>
  );
};
