import axios from "axios";
import { Button } from "components/Button";
import { LoadingProfiz } from "components/LoadingProfiz";

import { SearchInput } from "components/SearchInput";
import { EnvironmentDTO } from "dtos/PmocEnvironmentDTO";
import { useEnvironment } from "hooks/pmoc/environmentPmoc";
import { useToast } from "hooks/toast";
import React, { useEffect, useState } from "react";
import { FiChevronLeft } from "react-icons/fi";
import { api } from "services/api";
import { useTheme } from "styled-components";
import { EnvironmentPageProps } from "../EnvironmentFlow/types";

import * as S from "./styles";

type PageProps = {
  handleToggleOpen: () => void;
  handleChooseEnvironment: EnvironmentPageProps;
  handleSetOpenEnvironmentModal?: (value: boolean) => void;
};

type EnvironmentProps = {
  environments: EnvironmentDTO[];
};

type ResponseEnvironmentsProps = {
  data: EnvironmentProps;
};

export function ListEnvironment({
  handleChooseEnvironment,
  handleToggleOpen,
  handleSetOpenEnvironmentModal,
}: PageProps) {
  const theme = useTheme();
  const { addToast } = useToast();

  const [listEnvironments, setListEnvironments] = useState<EnvironmentDTO[]>(
    [] as EnvironmentDTO[]
  );

  const [listBackup, setListBackup] = useState<EnvironmentDTO[]>(
    [] as EnvironmentDTO[]
  );

  const [searchInput, setSearchInput] = useState("");

  const {
    handleSetEnvironment,
    handleSetNameFlow,
    userIdPMOC,
    unityIdPMOC,
    handleSetEnvironmentId
  } = useEnvironment();

  const [loadingModal, setLoadingModal] = useState(false);
  useEffect(() => {
    SetData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function SetData() {
    try {
      setLoadingModal(true);

      const { data }: ResponseEnvironmentsProps = await api.get(
        `/client/${userIdPMOC}/unities/${unityIdPMOC}/environments`
      );

      setListEnvironments(data.environments);
      setListBackup(data.environments);
    } catch (error) {
      addToast({
        title: "Erro",
        description:
          axios.isAxiosError(error) && error.response?.data.error
            ? error.response.data.error
            : "Não foi possível carregar os ambientes.",
        type: "error",
      });
    } finally {
      setLoadingModal(false);
    }
  }

  function handleCancelSearch() {
    setSearchInput("");
    setListEnvironments(listBackup);
  }

  async function handleFilterEnvironments(searchValue: string) {
    setSearchInput(searchValue);
    if (searchValue === "" || !searchValue.trim()) {
      return setListEnvironments(listBackup);
    }

    try {
      let result = listEnvironments.filter((element: EnvironmentDTO) =>{
        return element.name.toLowerCase().includes(searchValue.toLowerCase()) ||
            (element.equipments && element.equipments.find(eqt => {
              return eqt.equipment.name.toLowerCase().includes(searchValue.toLowerCase())
            }))
      })
      setListEnvironments(result);
    } catch (error) {
      addToast({
        title: "Erro",
        description: "Não foi possível filtrar os ambientes.",
        type: "error",
      });
    }
  }

  function handleBackButton() {
    return handleToggleOpen();
  }

  async function handleAddEnvironment() {
    handleSetEnvironment({} as EnvironmentDTO);
    handleSetNameFlow("pmoc");
    handleChooseEnvironment("createEnvironment");
  }

  async function handleEditEnvironment(idEnvironment: number) {
    handleSetNameFlow("pmoc");
    handleSetEnvironmentId(idEnvironment);
    return handleChooseEnvironment("detailEnvironment");
  }

  return (
    <>
      {loadingModal ? (
        <LoadingProfiz isVisible={loadingModal} />
      ) : (
          <S.Container>
          <S.Content>
            <S.BackButton onClick={() => handleBackButton()}>
              <FiChevronLeft size={16} color={theme.colors.text} />
            </S.BackButton>
          <S.Top>
              <S.Title>Ambientes e equipamentos</S.Title>
              <S.SubTitle>
                Visualize os ambientes e equipamentos desta unidade.
              </S.SubTitle>
          </S.Top>

          <SearchInput
            searchValue={searchInput}
            placeholder="Procure por um ambiente ou equipamento"
            onChange={(e) => handleFilterEnvironments(e.target.value)}
            handleCancel={handleCancelSearch}
          />

          <Button typeButton="outline" onClick={handleAddEnvironment}>
            Criar ambientes e equipamentos
          </Button>

          {listEnvironments && listEnvironments.length > 0 ? (
            listEnvironments.map((environment) => {
              return (
                <S.CardEnvironmentInfo
                  key={environment.id}
                  onClick={() =>
                    handleEditEnvironment(environment.id as number)
                  }
                >
                  <S.ContentInfo>
                    <S.TitleCard><strong>{environment.name}</strong></S.TitleCard>
                    {environment.equipments && (
                      <S.CardInfo>
                        | {environment.equipments?.length > 1
                          ? environment.equipments.length + " Equipamentos"
                          : environment.equipments.length + " Equipamento"}
                      </S.CardInfo>
                    )}
                  </S.ContentInfo> 
                </S.CardEnvironmentInfo>
              );
            })
          ) : (
            <S.ViewMessage>
                  <S.MessageText>Você ainda não criou nenhum ambiente para esta unidade.</S.MessageText>
                  <S.MessageText>Toque em <strong>“Criar ambientes e equipamentos”</strong> para começar.</S.MessageText>
            </S.ViewMessage>
            )}
            
          </S.Content>
          <S.Footer>
              {listEnvironments && listEnvironments.length > 0 && (<Button
                onClick={() => {
                  handleBackButton();
                  handleSetOpenEnvironmentModal &&
                    handleSetOpenEnvironmentModal(false);
                }}
              >
                Salvar cadastro de ambientes
              </Button>)}
          </S.Footer>
        </S.Container>
      )}
    </>
  );
}
