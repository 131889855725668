import styled, { css } from "styled-components";

export const Container = styled.div`
  flex-direction: column;
  height: 88vh;
`;
export const Content = styled.div`
  max-width: 70vw;
`;

export const Header = styled.header`
  display: flex;
  flex-direction: row;
  align-items: center;

  margin: 15px 0 0 32px;

  & > div {
    margin-right: 32px;
  }
`;

export const HeaderTitle = styled.div`
  ${({ theme }) => css`
    font-size: 18px;
    font-weight: ${theme.font.bold};
    letter-spacing: 0.4px;
    line-height: 20px;
    color: ${theme.colors.financial_title};
  `};
`;

export const WrapperSearch = styled.div`
  margin-top: 20px;
  margin-bottom: 20px;
  width: 500px;
`;

export const WrapperFilter = styled.div``;

export const TabContainer = styled.div`
  ${({ theme }) => css`
    margin-top: 90px;
    padding-left: 100px;

    .react-tabs__tab-list {
      border-bottom: 1px solid rgba(255, 255, 255, 0.12);
      display: flex;
      justify-content: center;
    }

    .react-tabs__tab {
      margin-right: 142px;
      color: ${theme.colors.text};
    }

    .react-tabs__tab--selected {
      background: inherit;
      border: 0px;
      color: ${theme.colors.text};

      font-weight: 500;
      line-height: 1.35;
      white-space: normal;
      letter-spacing: 0.02857em;
      border-bottom: 3px solid ${theme.colors.primary_light};
    }
  `}
`;
