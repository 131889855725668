import { ServiceFromApiDTO } from "dtos/ServiceFromApiDTO";
import addMaskMoney from "./addMaskMoney";
import { ServiceDTO } from "dtos/ServiceDTO";

export function mappedServicesFromApi(services: ServiceFromApiDTO[]): ServiceDTO[] {
  return services.map((currentService) => {
    const total = currentService.service.price * currentService.quantity;

    return {
      id: currentService.service.id,
      idBudgetService: currentService.idBudgetService,
      categoryId: currentService.service.categoryId,
      service: {
        id: currentService.service.service.id,
        name: currentService.service.service.name,
      },
      equipment: {
        id: currentService.service.equipment.id,
        name: currentService.service.equipment.name,
      },
      equipmentType: {
        id: currentService.service.equipmentType?.id,
        name: currentService.service.equipmentType?.name,
      },
      capacity: {
        id: currentService.service.capacity?.id,
        name: currentService.service.capacity?.name,
      },
      ...(currentService.service.runtime?.time
        ? {
            runtime: {
              time: currentService.service.runtime?.time,
              extension: currentService.service.runtime?.extension,
            },
          }
        : {}),
      ...(currentService.service.warranty?.time
        ? {
            warranty: {
              time: currentService.service.warranty?.time,
              extension: currentService.service.warranty?.extension,
            },
          }
        : {}),
      ...(currentService.service.description
        ? { description: currentService.service.description }
        : {}),
      ...(currentService.service.brand
        ? { brand: currentService.service.brand }
        : {}),
      price: currentService.service.price,
      quantity: currentService.quantity,
      total,
      formattedTotal: addMaskMoney(total),
      checked: true,
      serviceChecklist: currentService.service.serviceChecklist,
    };
  });
}
