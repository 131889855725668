import { ModeProps } from "dtos/businessProposalsDTO";
import styled, { css } from "styled-components";

interface Props {
  mode: ModeProps;
}

export const Container = styled.button<Props>`
  ${({ theme, mode }) => css`
    width: 250px;
    height: 220px;
    border-radius: 20px;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    transition: opacity 0.2s ease-in-out;

    &:hover {
      opacity: 0.8;
    }

    ${mode === "products" &&
    css`
      background-color: ${theme.colors.blue_opacity};
    `}

    ${mode === "services" &&
    css`
      background-color: ${theme.colors.orange_opacity};
    `}

    ${mode === "products-services" &&
    css`
      background-color: ${theme.colors.yellow_opacity};
    `}
  `}
`;

export const Title = styled.span`
  ${({ theme }) => css`
    font-size: 14px;
    text-align: center;
    font-family: ${theme.font.bold};
    color: ${theme.colors.text_light_87};
    margin: 10px 0 5px;
  `}
`;

export const Subtitle = styled.span`
  ${({ theme }) => css`
    font-size: 14px;
    text-align: center;
    line-height: 20px;
    font-family: ${theme.font.normal};
    color: ${theme.colors.text_light_60};
  `}
`;
