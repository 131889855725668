import styled, { css } from "styled-components";

export const Container = styled.section`
  width: 100%;
  max-width: 1400px;
  margin: 0 auto;

  padding: 30px;

  header {
    display: flex;
    align-items: center;

    & > h2 {
      ${({ theme }) => css`
        font-size: 2rem;
        color: ${theme.colors.title};
        margin-left: 3%;
      `}
    }
  }
`;

export const Content = styled.section`
  ${({ theme }) => css`
    margin-top: 35px;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: ${theme.grid.gutter};
    margin-bottom: 100px;
  `}
`;
