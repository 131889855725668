import styled, { css } from "styled-components";

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 29px;
`;

export const Title = styled.div`
  ${({ theme }) => css`
    color: #fff;
    font-weight: 700;
    font-size: 18px;
    line-height: 22px;
    margin-right: auto;
    margin-bottom: 50px;
  `};
`;

export const TextWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  width: 100%;
  margin-top: 50px;
  margin-bottom: 20px;
`;

export const Description = styled.div`
  ${({ theme }) => css`
    color: rgba(255, 255, 255, 0.6);
    font-weight: 400;
    font-size: 15px;
    line-height: 20px;
  `}
`;

export const Negrito = styled.strong`
  ${({ theme }) => css`
    color: rgba(255, 255, 255, 0.87);
    font-weight: 700;
    font-size: 15px;
    line-height: 15px;
  `}
`;

export const Card = styled.div`
  ${({ theme }) => css`
    background-color: ${theme.colors.background_light};
    width: 100%;
    padding: 20px;
    border-radius: 10px;
    margin-top: 10px;
  `};
`;

export const CardHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const Dot = styled.div`
  ${({ theme }) => css`
    width: 5px;
    height: 5px;
    border-radius: 10px;
    background-color: ${theme.colors.error};
    margin-right: 10px;
  `}
`;

export const ButtonsWrapper = styled.div`
  width: 100%;
  margin-top: 100px;
`;
