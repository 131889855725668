import styled, { css, keyframes } from "styled-components";
import { lighten, shade } from "polished";

type ModalProps = {
  isVisible: boolean;
};

export const Container = styled.div`
  ${({ theme }) => css`
    width: 100%;
    max-width: 1400px;
    margin: 0 auto;
    padding: 30px;

    header {
      display: flex;
      align-items: center;
      margin-top: 30px;

      h1 {
        font-size: ${theme.font.sizes.large};
        color: ${theme.colors.title};
        font-weight: ${theme.font.bold};
      }

      div {
        width: 80px;
        display: flex;
        justify-content: space-between;
      }

      div + div {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        padding-left: 25px;

        span {
          font-size: ${theme.font.sizes.xsmall};
          color: ${theme.colors.text};
          margin-top: 3px;
        }
      }
    }
  `}
`;

export const ClearDate = styled.button`
  position: absolute;
  color: red;
  right: 40px;
  top: 41px;
  font-size: 12px;
`;

export const Content = styled.div`
  width: 40%;
  min-width: 470px;
  margin-top: 29px;
`;

export const Wrapper = styled.div`
  margin-bottom: 24px;
`;

export const WrapperAttachmentButton = styled.div`
  ${({ theme }) => css`
    div {
      width: 100%;
      height: 45px;
      border: 1px dashed ${theme.colors.primary};
      border-radius: 10px;
    }

    label {
      width: 100%;
      height: 100%;
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: ${theme.font.sizes.xsmall};
      color: ${theme.colors.title};

      span {
        margin-left: 10px;
      }

      input {
        display: none;
      }
    }

    p {
      font-size: ${theme.font.sizes.xsmall};
      color: ${theme.colors.text};
      margin-top: 15px;
      margin-bottom: 29px;
    }
  `}
`;

export const WrapperAttachmentDetail = styled.div`
  ${({ theme }) => css`
    p {
      font-size: ${theme.font.sizes.xsmall};
      color: ${theme.colors.text};
      margin-top: 15px;
      margin-bottom: 29px;
    }
  `}
`;

export const WrapperPreview = styled.div`
  ${({ theme }) => css`
    p {
      font-size: ${theme.font.sizes.xsmall};
      color: ${theme.colors.text};
      margin-top: 5px;
    }
  `}
`;

export const PreviewButton = styled.button`
  ${({ theme }) => css`
    background-color: transparent;
    display: flex;
    height: 60px;

    div {
      width: 60px;
      height: 60px;

      border: 1px solid ${theme.colors.text};
      border-radius: 10px;

      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: 5px;
    }

    img {
      width: 60px;
      height: 60px;
      object-fit: cover;
      border-radius: 10px;
      margin-right: 5px;
    }
  `}
`;

const ModalScale = keyframes`
  from {
    transform: scale(0.5);
    opacity: 0.4;
  }
  to {
    transform: scale(1);
    opacity: 1;
  }
`;

export const ContainerOverlay = styled.aside<ModalProps>`
  ${({ theme, isVisible }) => css`
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    display: ${isVisible ? "flex" : "none"};
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: ${theme.layers.alwaysOnTop};
  `}
`;

export const BoxModal = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    width: 60%;
    width: 60%;
    background-color: ${theme.colors.background_modal};
    animation: ${ModalScale} 0.3s ease-in-out;
    border-radius: 10px;
    padding: 0 16px 16px;
  `}
`;

export const Header = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 5px;

  & > button {
    padding: 2px;
    border-radius: 6px;

    display: flex;
    justify-content: center;
    align-items: center;
    transition: background 0.2s;

    &:hover {
      background: ${({ theme }) => lighten(0.1, theme.colors.background_modal)};
    }
  }
`;

export const Title = styled.h4`
  ${({ theme }) => css`
    font-size: 20px;
    color: ${theme.colors.title};
    text-align: center;
    margin-bottom: 12px;
  `}
`;

export const WrapperActionButtons = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 20px;
  margin-bottom: 10px;
`;

export const Footer = styled.footer`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  border-top: 1px solid ${({ theme }) => theme.colors.background_light};
  width: 100%;
  padding-top: 12px;
`;

export const WrapperButtons = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

export const ButtonConfirm = styled.button`
  ${({ theme }) => css`
    width: 100px;
    height: 40px;
    color: ${theme.colors.background};
    font-size: ${theme.font.sizes.small};

    display: flex;
    justify-content: center;
    align-items: center;

    background-color: ${theme.colors.primary};
    border-radius: 8px;
    transition: background 0.2s;

    &:hover {
      background: ${shade(0.2, theme.colors.primary)};
    }
  `}
`;

export const ButtonCancel = styled.button`
  ${({ theme }) => css`
    width: 100px;
    height: 40px;
    color: ${theme.colors.title};
    font-size: ${theme.font.sizes.small};

    display: flex;
    justify-content: center;
    align-items: center;

    background-color: ${lighten(0.1, theme.colors.background_modal)};
    border-radius: 8px;
    transition: opacity 0.2s;

    &:hover {
      opacity: 0.7;
    }
  `}
`;
