import React from "react";

import { PermissionNotification } from "components/PermissionNotification";
import { Notification } from "components/Notification";

import * as S from "./styles";

export function AsideTop() {
  return (
    <S.Container>
      <PermissionNotification />
      <Notification />
    </S.Container>
  );
}
