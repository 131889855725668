import { useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router";
import debounce from "lodash.debounce";

import AddButtonFinancialSvg from "assets/icons/financial-icons/add-button.svg";

import addMaskMoney from "utils/addMaskMoney";

import { LoadingProfiz } from "components/LoadingProfiz";
import { EmptyPage } from "components/EmptyPage";
import { SearchInput } from "components/SearchInput";
import { ArrowButton } from "components/ArrowButton";
import { TablePagination } from "components/TablePagination";

import { useToast } from "hooks/toast";
import { useAccount } from "hooks/permission/account";
import { DailySummaryProps } from "dtos/receipts/ReceiptListDTO";
import { api } from "services/api";
import { searchDatalayer } from "utils/pushDataLayer";

import * as S from "./styles";

const Receipts = () => {
  const history = useHistory();
  const { addToast } = useToast();
  const { whoami } = useAccount();

  const [isLoading, setIsLoading] = useState(false);
  const [allReceipts, setAllReceipts] = useState<DailySummaryProps[]>([]);

  const defaultLimit = 50;
  const [totalItems, setTotalItems] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [offset, setOffset] = useState(0);

  const [loadingInput, setLoadingInput] = useState(false);
  const [searchInput, setSearchInput] = useState("");

  useEffect(() => {
    if (!whoami?.isMe) history.push("/home");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [whoami]);

  async function getReceiptList(search?: string) {
    try {
      setIsLoading(true);

      const response = await api.get("/receipts", {
        params: {
          offset,
          limit: defaultLimit,
          search,
        },
      });

      setAllReceipts(response.data.receipts);
      setTotalItems(response.data.info.total);

      if (!!search) {
        searchDatalayer({ search_term: search, success: true });
      }
    } catch (error) {
      addToast({
        title: "Ops!!",
        description: "Ocorreu um erro ao listar os orçamentos",
        type: "error",
      });
    } finally {
      setIsLoading(false);
      setLoadingInput(false);
    }
  }

  function handlePageChange(pageNumber: number, itemsPerPage: number) {
    setCurrentPage(pageNumber);
    setOffset((pageNumber - 1) * itemsPerPage);
  }

  function handleChange(searchValue: string) {
    setLoadingInput(true);
    setSearchInput(searchValue);
    debounceFn(searchValue);
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debounceFn = useCallback(debounce(getReceiptList, 3000), []);

  function handleSearchCancel() {
    setSearchInput("");
    getReceiptList("");
  }

  useEffect(() => {
    getReceiptList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [offset]);

  if (isLoading) return <LoadingProfiz isVisible={isLoading} />;

  return (
    <S.Container>
      <header>
        <div>
          <ArrowButton />
          <h1>Recibos</h1>
        </div>
      </header>

      <S.Content>
        <S.Title>Recibos</S.Title>
        <S.AddButtonReceipts
          onClick={() => history.push("/financial/create-receipt")}
        >
          <img src={AddButtonFinancialSvg} alt="Botão adicionar" />
          <S.ButtonText>Novo recibo avulso</S.ButtonText>
        </S.AddButtonReceipts>

        <S.Subtitle>Histórico</S.Subtitle>

        <SearchInput
          loadingInput={loadingInput}
          searchValue={searchInput}
          placeholder="Busque por nome ou número"
          onChange={(e) => handleChange(e.target.value)}
          handleCancel={handleSearchCancel}
        />

        {allReceipts.length <= 0 && <EmptyPage />}

        {allReceipts.map((item) => {
          return (
            <>
              <S.ReceipsContent>
                <S.HeaderReceips>
                  <S.TextReceips>Recebimento: {item.day}</S.TextReceips>
                  <S.Balance>
                    <S.TextReceips>Saldo do dia: </S.TextReceips>
                    <S.BalanceReceip style={{ marginLeft: 5 }}>
                      {addMaskMoney(item.balanceOfDay)}
                    </S.BalanceReceip>
                  </S.Balance>
                </S.HeaderReceips>
                <S.Line />
              </S.ReceipsContent>

              {item.data.map((data) => {
                return (
                  <S.ItemReceipt
                    onClick={() =>
                      history.push(`/financial/edit-receipt/${data.id}`)
                    }
                  >
                    <S.DetailsRecepitContainer>
                      <S.BalanceReceip>{data.receipt}</S.BalanceReceip>
                      <S.BalanceReceipGreen>
                        {addMaskMoney(data.value)}
                      </S.BalanceReceipGreen>
                    </S.DetailsRecepitContainer>
                    <S.DetailsRecepitContainer>
                      <S.BalanceClient> Cliente: {data.client}</S.BalanceClient>
                      <S.BalanceClientTypeTransaction>
                        {data.typeTransaction}
                      </S.BalanceClientTypeTransaction>
                    </S.DetailsRecepitContainer>
                  </S.ItemReceipt>
                );
              })}
            </>
          );
        })}

        {allReceipts.length > 0 && (
          <S.ContainerPagination>
            <TablePagination
              rowsLength={totalItems}
              rowsPerPage={defaultLimit}
              rowsPage={currentPage}
              handlePageChange={(pageNumber, itemsPerPage) =>
                handlePageChange(pageNumber, itemsPerPage)
              }
            />
          </S.ContainerPagination>
        )}
      </S.Content>
    </S.Container>
  );
};
export { Receipts };
