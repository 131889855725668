import React, { createContext, useState, useContext } from 'react'
import { ProviderProps } from 'hooks';

interface BudgetTabIndexContextData {
  selectedIndex: number;
  handleSetSelectedIndex: (index: number) => void;
}

const BudgetTabIndexContext =
  createContext<BudgetTabIndexContextData>(
    {} as BudgetTabIndexContextData
  )

export function useBudgetTabIndex(): BudgetTabIndexContextData {
  const context = useContext(BudgetTabIndexContext)

  if (!context) {
    throw Error(
      'useBudgetTabIndex must be used within an BudgetTabIndexProvider'
    )
  }

  return context;
}

export const BudgetTabIndexProvider = ({ children }: ProviderProps) => {
  const [selectedIndex, setSelectedIndex] = useState(0);

  function handleSetSelectedIndex(index: number) {
    setSelectedIndex(index);
  }

  return (
    <BudgetTabIndexContext.Provider
      value={{
        selectedIndex,
        handleSetSelectedIndex
      }}
    >
      {children}
    </BudgetTabIndexContext.Provider>
  )
}
