import styled, { css, DefaultTheme } from "styled-components";
import { lighten, shade } from "polished";
import { ButtonProps } from ".";

type ContainerProps = Pick<ButtonProps, "typeButton" | "isIcon">;

const containerModifier = {
  default: (theme: DefaultTheme) => css`
    background-color: ${theme.colors.primary};

    &:hover {
      background: ${shade(0.2, theme.colors.primary_light)};
    }

    &:disabled {
      background: ${theme.colors.background};
      color: ${theme.colors.title};
    }
  `,

  financialFilterSelected: (theme: DefaultTheme) => css`
    background-color: ${theme.colors.primary};
    color: ${theme.colors.financial_text};

    &:hover {
      background: ${shade(0.2, theme.colors.primary_light)};
    }
  `,

  financialFilterNotSelected: (theme: DefaultTheme) => css`
    background-color: ${theme.colors.background_modal};
  `,

  delete: (theme: DefaultTheme) => css`
    background-color: ${theme.colors.error};

    &:hover {
      background: ${shade(0.2, theme.colors.error)};
    }
  `,

  outline: (theme: DefaultTheme) => css`
    background-color: transparent;
    border: 1px solid ${theme.colors.primary};
    color: ${theme.colors.title};

    &:hover {
      background: ${shade(0.2, theme.colors.primary_light)};
      color: ${theme.colors.background};
    }

    &:disabled {
      background: ${theme.colors.background};
      color: ${theme.colors.title};
    }
  `,

  outlineDisable: (theme: DefaultTheme) => css`
    background-color: transparent;
    border: 1px solid ${theme.colors.primary};
    color: ${theme.colors.title};

    &:hover {
      background: ${shade(0.2, theme.colors.primary_light)};
      color: ${theme.colors.background};
    }

    &:disabled {
      background: ${theme.colors.background_modal};
      color: ${theme.colors.title};
      border: 0px;
    }
  `,


  qrcode: (theme: DefaultTheme) => css`
    background-color: transparent;
    border: 1px solid yellow;
    color: ${theme.colors.title};

    &:hover {
      background: ${shade(0.2, "yellow")};
      color: ${theme.colors.background};
    }

    &:disabled {
      background: ${theme.colors.background};
      color: ${theme.colors.title};
    }
  `,


  outlinePurple: (theme: DefaultTheme) => css`
    background-color: transparent;
    border: 1px solid ${theme.colors.lilac};

    img {
      height: 18px;
      width: 18px;
    }

    &:hover {
      background: ${shade(0.2, theme.colors.lilac_light)};
      color: ${theme.colors.lilac};
    }
  `,

  outlineBlue: (theme: DefaultTheme) => css`
    background-color: transparent;
    border: 1px solid ${theme.colors.schedule};
    color: rgba(255, 255, 255, 0.87);

    img {
      height: 18px;
      width: 18px;
    }

    &:hover {
      background: ${shade(0.2, theme.colors.schedule)};
    }
  `,
    outlineOrange: (theme: DefaultTheme) => css`
    background-color: transparent;
    border: 1px solid ${theme.colors.pmoc_orange};
    color: rgba(255, 255, 255, 0.87);

    img {
      height: 18px;
      width: 18px;
    }

    &:hover {
      background: ${shade(0.2, theme.colors.pmoc_orange)};
    }
  `,

  disabled: (theme: DefaultTheme) => css`
    background-color: ${theme.colors.background_modal};
    color: ${theme.colors.text};

    &:hover {
      background: ${lighten(0.15, theme.colors.background)};
      cursor: not-allowed;
    }
  `,

  unselected: (theme: DefaultTheme) => css`
    background-color: ${theme.colors.background_light};
    color: ${theme.colors.text};

    &:hover:not():disabled {
      background: ${lighten(0.15, theme.colors.background)};
    }
  `,

  textOnly: (theme: DefaultTheme) => css`
    background: transparent;
    color: ${theme.colors.primary};

    &:hover {
      color: ${shade(0.2, theme.colors.primary_light)};
      cursor: pointer;
    }
  `,

  financialSelected: (theme: DefaultTheme) => css`
    background-color: ${theme.colors.primary};
    height: 35px;
    margin-top: 0px;

    &:hover {
      background: ${shade(0.2, theme.colors.primary_light)};
    }
  `,

  financialNotSelected: (theme: DefaultTheme) => css`
    background-color: ${theme.colors.background_light};
    color: ${theme.colors.financial_text};
    height: 35px;
    margin-top: 0px;

    &:hover {
      background: ${lighten(0.15, theme.colors.background)};
    }
  `,
};

export const Container = styled.button<ContainerProps>`
  ${({ theme, typeButton, isIcon }) => css`
    width: 100%;
    height: 45px;
    border-radius: 10px;
    border: 0;
    font-weight: 500;
    margin-top: 16px;
    transition: background-color 0.2s;
    color: ${theme.colors.background};
    display: flex;
    justify-content: ${isIcon ? "flex-start" : "center"};
    padding-left: ${isIcon ? "10px" : "0px"};
    align-items: center;

    ${containerModifier[typeButton!](theme)}

    &:disabled {
      cursor: not-allowed;
    }

    img {
      width: 42px;
      height: 12px;
    }
  `}
`;
