import React, { useState, useEffect } from "react";
import { useHistory } from "react-router";

import { ArrowButton } from "components/ArrowButton";
import { Button } from "components/Button";
import { ModalRejectedRegistration } from "components/ModalRejectedRegistration";

import { useAuth } from "hooks/auth";

import questionsSVG from "assets/icons/faq/frequent-quetions.svg";
import SuggestionSVG from "assets/icons/faq/suggestions.svg";

import * as S from "./styles";
import TourPage from "./TourPage";

const HelpPage: React.FC = () => {
  const history = useHistory();
  const { user } = useAuth();

  const [showTour, setShowTour] = useState(false);
  const [activeModalRejectedRegistration, setActiveModalRejectedRegistration] =
    useState(false);

  useEffect(() => {
    if (user?.profileDisapproved) {
      setActiveModalRejectedRegistration(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (showTour) {
    return (
      <>
        <TourPage setShowTour={setShowTour} />
      </>
    );
  }

  return (
    <S.Container>
      <ModalRejectedRegistration
        isVisible={activeModalRejectedRegistration}
        setIsActive={setActiveModalRejectedRegistration}
      />
      <S.Header>
        <div>
          <ArrowButton />
          <h1>Ajuda</h1>
        </div>
      </S.Header>

      <S.Content>
        <S.Option
          onClick={() => {
            history.push("/faq");
          }}
        >
          <img
            src={questionsSVG}
            alt="Perguntas frequentes"
            width={35}
            height={35}
          />

          <S.Text>
            <S.Title>Perguntas Frequentes</S.Title>
            <S.SubTitle>
              Encontre respostas para as perguntas mais comuns que <br />
              recebemos dos usuários Profiz.
            </S.SubTitle>
          </S.Text>
        </S.Option>

        <S.Option
          onClick={() => {
            history.push("/feedback");
          }}
        >
          <img
            src={SuggestionSVG}
            alt="Supporte e Sugestões"
            width={35}
            height={35}
          />

          <S.Text>
            <S.Title>Suporte e Sugestões</S.Title>
            <S.SubTitle>
              Entre em contato com o nosso time e tire suas dúvidas.
            </S.SubTitle>
          </S.Text>
        </S.Option>
        <Button
          typeButton="default"
          style={{ width: 470 }}
          onClick={() => setShowTour(true)}
        >
          Rever tour do APP
        </Button>
      </S.Content>
    </S.Container>
  );
};

export default HelpPage;
