import styled, { css } from "styled-components";
import media from "styled-media-query";

export const Container = styled.div`
  ${({ theme }) => css`
    background-color: ${theme.colors.strong_background};
    width: 100%;
    max-width: 1400px;
    margin: 0 auto;
    padding: 30px;

    header {
      width: 50%;
      display: flex;
      justify-content: space-between;
      align-items: center;

      ${media.lessThan("large")`
        flex-direction: column;
        align-items: flex-start;

        & > div:last-of-type {
          margin-top: 20px;
          margin-left: 0;
        }
      `}

      h1 {
        font-size: ${theme.font.sizes.large};
        color: ${theme.colors.text_light_87};
        font-weight: 700;
        margin-left: 15px;
      }

      & > div {
        display: flex;
        align-items: center;
      }

      & > div:last-of-type {
        min-width: 350px;
        margin-left: 45px;
      }
    }
  `}
`;

export const Content = styled.div`
  width: 50%;
  margin-top: 10px;
  padding-bottom: 10px;
  margin-left: 45px;
`;

export const ButtonCard = styled.button`
  ${({ theme }) => css`
    /* flex: 1; */
    display: flex;
    width: 100%;
    height: 85px;
    align-items: center;
    justify-content: flex-start;
    background-color: ${theme.colors.financial_card};
    gap: 15px;
    padding: 15px 15px;
    border-radius: 10px;
    margin-top: 10px;
  `}
`;

export const BolderTextSmall = styled.p`
  ${({ theme }) => css`
    text-align: left;
    font-family: ${theme.font.family};
    font-weight: 700;
    font-size: 12px;
    color: ${theme.colors.text_light_87};
    line-height: 14.52px;
  `};
`;

export const TextInfo = styled.p`
  ${({ theme }) => css`
    text-align: left;
    font-size: 12px;
    margin-top: 3px;
    font-family: ${theme.font.family};
    font-weight: 400;
    letter-spacing: 0.26px;
    color: ${theme.colors.text_light_60};
    line-height: 14.52px;
  `}
`;
