import { Route } from "./Route";

import { Route as RouteReact } from "react-router-dom";

import { PmocListing } from "pages/pmoc/PmocListing";
import { ResumePmoc } from "pages/pmoc/ResumePmoc";
import { PmocRegisterForm } from "pages/pmoc/PmocRegisterForm";
import { InitialPmocForm } from "pages/pmoc/InitialPmocForm";
import { PmocExecutionRegisterForm } from "pages/pmoc/PmocExecutionRegisterForm";
import { PmocArchivedRegisterForm } from "pages/pmoc/PmocArchivedRegisterForm";
import { PmocOrderServiceList } from "pages/pmoc/PmocOrderServiceList";
import { Client } from "pages/pmoc/View/Client";
import { Details } from "pages/pmoc/View/Details";
import { DownloadPdf } from "pages/fieldService/DownloadPdf";
import { UnscheduledOSPage } from "pages/pmoc/DetailOSPages/UnscheduledOSPage";
import { SchedulingServicePmoc } from "pages/pmoc/SchedulingServicePmoc";
import { ScheduledOSPage } from "pages/pmoc/DetailOSPages/ScheduledOSPage";
import { PmocServicesHub } from "pages/pmoc/PmocServicesHub";
import { PmocRegisterSignatures } from "pages/pmoc/PmocRegisterSignatures";
import { ConcludedOSPage } from "pages/pmoc/DetailOSPages/ConcludedOSPage";
import { ArchivedOSPage } from "pages/pmoc/DetailOSPages/ArchivedOSPage";
import { PmocConcludedRegisterForm } from "pages/pmoc/PmocConcludedRegisterForm";
import { DownloadServiceOrder } from "pages/fieldService/DownloadServiceOrder";

export function PmocRoutes() {
  return (
    <>
      <Route path="/pmoc" component={PmocListing} exact isPrivate />
      <Route path="/pmoc/new" component={InitialPmocForm} exact isPrivate />
      <Route path="/pmoc/resume/:id" component={ResumePmoc} exact isPrivate />
      <Route
        path="/pmoc/finish-register/:id"
        component={PmocRegisterForm}
        exact
        isPrivate
      />
      <Route
        path="/pmoc/execution/:id"
        component={PmocExecutionRegisterForm}
        exact
        isPrivate
      />

      <Route
        path="/pmoc/concluded/:id"
        component={PmocConcludedRegisterForm}
        exact
        isPrivate
      />

      <Route
        path="/pmoc/archived/:id"
        component={PmocArchivedRegisterForm}
        exact
        isPrivate
      />
      <Route
        path="/pmoc/execution/order-services-list/:id/:year/:month"
        component={PmocOrderServiceList}
        exact
        isPrivate
      />

      <RouteReact
        path="/public/pmoc/view/client/:hash"
        component={Client}
        exact
      />

      <RouteReact
        path="/public/pmoc/view/client/detail/:hash"
        component={Details}
        exact
      />

      <RouteReact
        path="/public/download/pdf/details"
        component={DownloadPdf}
        exact
      />

      <RouteReact
        path="/public/download/os/details"
        component={DownloadServiceOrder}
        exact
      />
      <Route
        path="/pmoc/execution/order-service/unscheduled/:id/:idPmoc/:year/:month"
        component={UnscheduledOSPage}
        exact
        isPrivate
      />
      <Route
        path="/pmoc/execution/order-service/scheduled/:id/:idPmoc/:year/:month"
        component={ScheduledOSPage}
        exact
        isPrivate
      />

      <Route
        path="/pmoc/execution/order-service/concluded/:id/:idPmoc/:year/:month"
        component={ConcludedOSPage}
        exact
        isPrivate
      />

      <Route
        path="/pmoc/execution/order-service/archived/:id/:idPmoc/:year/:month"
        component={ArchivedOSPage}
        exact
        isPrivate
      />

      <Route
        path="/pmoc/execution/order-service/scheduling/:id/:idPmoc/:year/:month"
        component={SchedulingServicePmoc}
        exact
        isPrivate
      />

      <Route
        isPrivate
        path="/pmoc-service-signatures/:id/:idPmoc/:year/:month"
        component={PmocRegisterSignatures}
      />

      <Route
        isPrivate
        path="/pmoc-service-photos/:id/:idPmoc/:year/:month"
        component={PmocServicesHub}
      />
    </>
  );
}
