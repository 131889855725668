/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";

import { Dropdown, DropdownOption } from "components/DropDown";
import { InputAddNewCategory } from "components/InputAddNewCategory";
import { DisabledDropDown } from "components/DisabledDropDown";

import { useServiceFormTree, DropDownProps } from "hooks/serviceFormTree";
import { useServiceDropDown } from "hooks/servicesDropDown";
import { useToast } from "hooks/toast";
import { useAccount } from "hooks/permission/account";
import { useHasPermission } from "hooks/permission/useHasPermission";

import { api } from "services/api";

interface CapacityProps {
  moduleHash?: string;
}

export function Capacity({ moduleHash }: CapacityProps) {
  const { addToast } = useToast();
  const { whoami } = useAccount();
  const { permission } = useHasPermission({
    actionHash: "view",
    moduleHash: "equipment-capacity",
  });

  const { categoriesTree } = useServiceFormTree();
  const {
    selectedCategoryId,
    selectedEquipment,
    selectedService,
    selectedCapacity,
    handleCapacity,
  } = useServiceDropDown();

  const [loadingButton, setLoadingButton] = useState(false);
  const [newCapacity, setNewCapacity] = useState("");
  const [capacities, setCapacities] = useState<DropDownProps[]>([]);

  useEffect(() => {
    const currentCategory = categoriesTree.find(
      (category) => category.id === Number(selectedCategoryId)
    );

    if (!currentCategory) return;

    const currentService = currentCategory.services.find(
      (service) => service.id === Number(selectedService.id)
    );

    if (!currentService) return;

    const currentEquipment = currentService.equipments.find(
      (equipment) => equipment.id === Number(selectedEquipment.id)
    );

    if (!currentEquipment) return;

    setCapacities(currentEquipment.capacities);
  }, [
    categoriesTree,
    selectedCategoryId,
    selectedService.id,
    selectedEquipment.id,
  ]);

  function handleNewCapacity(text: string) {
    setNewCapacity(text.trimStart());
  }

  async function handleSubmit() {
    if (!newCapacity) {
      addToast({
        title: "Ops",
        description: "Preencha com alguma informação antes de prosseguir",
        type: "error",
      });
      return;
    }

    try {
      setLoadingButton(true);
      const response = await api.post("services/capacities", {
        equipmentID: selectedEquipment.id,
        name: newCapacity,
        accountId: whoami?.id
      });

      const { id, name } = response.data;

      capacities.push({ id, name, default: "false" });
      setCapacities([...capacities]);

      setNewCapacity("");

      addToast({
        title: "Sucesso",
        description: "Capacidade cadastrada com sucesso!",
        type: "success",
      });
    } catch (err) {
      addToast({
        title: "Ops",
        description: "Ocorreu um erro ao cadastrar",
        type: "error",
      });
    } finally {
      setLoadingButton(false);
    }
  }

  function handleSelectCapacity({ id, name }: DropdownOption) {
    handleCapacity({ id, name });
  }

  function handleWarning() {
    if (!selectedCategoryId) {
      addToast({
        title: "Atenção!",
        description: "Selecione uma categoria",
        type: "info",
      });
      return;
    }

    if (!selectedService.id) {
      addToast({
        title: "Atenção!",
        description: "Selecione um serviço",
        type: "info",
      });
      return;
    }

    if (!selectedEquipment.id) {
      addToast({
        title: "Atenção!",
        description: "Selecione um equipamento",
        type: "info",
      });
    }
  }

  function handleClearCapacity() {
    handleCapacity({} as DropdownOption);
  }

  return (
    <>
      {selectedCategoryId > 0 &&
      selectedService.id > 0 &&
      selectedEquipment.id > 0 ? (
        <Dropdown
          label="Capacidade (opcional)"
          placeholder="Selecione uma capacidade"
          options={capacities}
          setCapacities={setCapacities}
          // eslint-disable-next-line react/jsx-no-bind
          onClickedValue={handleSelectCapacity}
          hasInput
          type="capacity"
          categoryIdSelected={selectedCapacity.id}
          clearDropDownItem
          // eslint-disable-next-line react/jsx-no-bind
          handleClear={handleClearCapacity}
          moduleHashValue={moduleHash}
          actionView={permission}
        >
          <InputAddNewCategory
            isLoadingButton={loadingButton}
            value={newCapacity}
            // eslint-disable-next-line react/jsx-no-bind
            handleChangeNewCategory={handleNewCapacity}
            // eslint-disable-next-line react/jsx-no-bind
            handleSave={handleSubmit}
          />
        </Dropdown>
      ) : (
        <DisabledDropDown
          label="Capacidade (opcional)"
          title="Selecione uma capacidade"
          onClick={() => handleWarning()}
        />
      )}
    </>
  );
}
