import React, { useEffect, useState } from "react";
import { useTheme } from "styled-components";
import { FiX } from "react-icons/fi";

import { useToast } from "hooks/toast";
import addMaskMoney from "utils/addMaskMoney";
import { useSelectedService } from "hooks/budget/selectedServices";

import { SearchInput } from "components/SearchInput";
import { Button } from "components/Button";
import { CheckBoxService } from "components/CheckBoxService";

import { api } from "services/api";
import { ServiceDTO, ServiceOptionsInputProps } from "dtos/ServiceDTO";

import * as S from "./styles";
import { useServiceChecklist } from "hooks/serviceChecklist";
import { useServiceDropDown } from "hooks/servicesDropDown";
import { useSelectedServiceOrder } from "hooks/selectedServiceOrder";

type ListServicesProps = {
  handleToggleOpen: () => void;
  handleCreateService: () => void;
  handleEditService: (data: ServiceDTO) => void;
};

export function ListServices({
  handleToggleOpen,
  handleCreateService,
  handleEditService,
}: ListServicesProps) {
  const theme = useTheme();
  const { addToast } = useToast();

  const {
    selectedServices,
    checkedServices,
    latestServiceAsChecked,
    handleSetSelectedServices,
    handleSetCheckedServices,
    handleSetLatestServiceAsChecked,
  } = useSelectedService();

  const { reset } = useServiceDropDown();

  const { handleSetOptionsInputService } = useSelectedServiceOrder();

  const [services, setServices] = useState<ServiceDTO[]>([]);
  const [searchInputValue, setSearchInputValue] = useState("");
  const [servicesDataFiltered, setServicesDataFiltered] = useState<
    ServiceDTO[]
  >([]);
  const [selectedServicesForBudget, setSelectedServicesForBudget] =
    useState<ServiceDTO[]>(checkedServices);

  const { handleSetSelectedItemChecklist } = useServiceChecklist();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    async function loadServices() {
      try {
        const { data } = await api.get("/services");

        const formattedServices = data.services.map(
          (currentService: ServiceDTO) => {
            let setCheckedServices = checkedServices.find(
              (checkedService) => checkedService.id === currentService.id
            )
              ? { ...currentService, checked: true }
              : { ...currentService, checked: false };

            return setCheckedServices;
          }
        );

        setServices(formattedServices);

        if (selectedServices.length > 0) {
          const idsServicesAlreadySelected = selectedServices.map(
            (serviceAlreadySelected) => serviceAlreadySelected.id
          );

          const unsSelectedServices = formattedServices.filter(
            (currentService: ServiceDTO) => {
              const isSelected = idsServicesAlreadySelected.find(
                (idService) => idService === currentService.id
              );

              return !isSelected;
            }
          );

          const unsSelectedServicesLatestToOld = unsSelectedServices.reverse();

          if (latestServiceAsChecked) {
            handleSetLatestServiceAsCheckedList(
              unsSelectedServicesLatestToOld[0]
            );
          }

          setServicesDataFiltered(unsSelectedServicesLatestToOld);
          return;
        }
        const formattedServicesLatestToOld = formattedServices.reverse();
        if (latestServiceAsChecked) {
          handleSetLatestServiceAsCheckedList(formattedServicesLatestToOld[0]);
        }

        setServicesDataFiltered(formattedServicesLatestToOld);
      } catch (err) {
        addToast({
          type: "error",
          title: "Erro ao carregar os serviços",
          description:
            "Ocorreu uma falha ao carregar os serviços, tente novamente",
        });
      }
    }

    loadServices();

    function handleSetLatestServiceAsCheckedList(service: ServiceDTO) {
      service.checked = true;

      const latestService = {
        ...service,
        quantity: 1,
        total: service.price,
        formattedTotal: addMaskMoney(service.price),
      };

      setSelectedServicesForBudget((oldSelectedServices) => [
        ...oldSelectedServices,
        latestService,
      ]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addToast, selectedServices]);

  function handleAddServicesIntoBudget() {
    if (selectedServicesForBudget.length < 1 || !selectedServicesForBudget) {
      handleSetSelectedServices(selectedServices);
      return handleToggleOpen();
    }

    const concatServices = [...selectedServices, ...selectedServicesForBudget];

    const filterServices = Array.from(
      concatServices
        .reduce((map, item) => map.set(item.id, item), new Map())
        .values()
    );

    handleSetSelectedServices(filterServices);

    setSelectedServicesForBudget([]);
    handleToggleOpen();
  }

  function handleSearchService(text: string) {
    setSearchInputValue(text);

    const filteredServices = services.filter((service) => {
      const serviceLowerCase = service.service.name.toLowerCase();

      const searchValue = text.toLowerCase();

      return serviceLowerCase.indexOf(searchValue) > -1;
    });

    setServicesDataFiltered(filteredServices);
  }

  function handleSearchCancel() {
    setSearchInputValue("");
    setServicesDataFiltered(services);
  }

  function handleSelectServiceForBudget(currentService: ServiceDTO) {
    const isServiceAlreadySelected = selectedServicesForBudget.find(
      (materialItem) => materialItem.id === currentService.id
    );

    if (isServiceAlreadySelected) {
      const removedService = selectedServicesForBudget.filter(
        (material) => material.id !== currentService.id
      );

      handleSetCheckedServices(removedService);
      setSelectedServicesForBudget(removedService);
      return;
    }

    const newService = {
      ...currentService,
      quantity: 1,
      total: currentService.price,
      formattedTotal: addMaskMoney(currentService.price),
    };

    setSelectedServicesForBudget((oldSelectedServices) => [
      ...oldSelectedServices,
      newService,
    ]);

    handleSetCheckedServices([...checkedServices, newService]);
  }

  async function handleRemoveSelectService(idService: number) {
    try {
      await api.delete(`/service/${idService}`);

      setServicesDataFiltered((oldServices) =>
        oldServices.filter((service) => service.id !== idService)
      );

      addToast({
        title: "Sucesso",
        description: "Serviço deletado com sucesso",
        type: "success",
      });
    } catch (error) {
      // sendError(error)
      addToast({
        title: "Opss!",
        description: "Não foi possível deletar o item",
        type: "error",
      });
    }
  }

  function resetDataNewService() {
    reset();
    handleSetOptionsInputService({} as ServiceOptionsInputProps);
  }

  return (
    <>
      <S.Top>
        <S.ButtonClose onClick={() => handleToggleOpen()}>
          <FiX size={10} color={theme.colors.text} />
        </S.ButtonClose>

        <p>Meus serviços</p>
      </S.Top>

      <S.Content>
        <S.WrapperSearch>
          <SearchInput
            placeholder="Procure por um serviço."
            description="Selecione um ou mais serviços para compor o orçamento."
            searchValue={searchInputValue}
            onChange={(event) => handleSearchService(event.target.value)}
            // eslint-disable-next-line react/jsx-no-bind
            handleCancel={handleSearchCancel}
          />
        </S.WrapperSearch>

        <S.Wrapper>
          <Button
            typeButton="outline"
            onClick={() => {
              handleSetSelectedItemChecklist([]);

              resetDataNewService();
              handleCreateService();
            }}
          >
            Criar novo serviço
          </Button>
        </S.Wrapper>

        <S.ListCardsService>
          {servicesDataFiltered.map((service) => (
            <S.Wrapper key={service.id}>
              <CheckBoxService
                data={service}
                onSelect={() => handleSelectServiceForBudget(service)}
                // eslint-disable-next-line react/jsx-no-bind
                handleChangeService={handleEditService}
                // eslint-disable-next-line react/jsx-no-bind
                handleDeleteService={handleRemoveSelectService}
              />
            </S.Wrapper>
          ))}
        </S.ListCardsService>

        <S.Footer>
          <Button
            onClick={() => {
              handleSetLatestServiceAsChecked(false);
              handleAddServicesIntoBudget();
            }}
          >
            {selectedServicesForBudget.length} serviço selecionado
            <span style={{ margin: "0 30px" }}>|</span>
            Adicionar serviço
          </Button>
        </S.Footer>
      </S.Content>
    </>
  );
}
