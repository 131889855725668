import React, { useEffect, useState } from "react";
import logo from "assets/logo.svg";

import * as S from "./styles";
import { Input } from "components/Input";
import { Button } from "components/Button";
import { api } from "services/api";
import { useHistory, useParams } from "react-router";
import { LoadingProfiz } from "components/LoadingProfiz";
import { useToast } from "hooks/toast";

interface ClientProps {
  pmocId: string;
  pmocName: string;
  pmocUserClienteId: string;
  pmocUserClienteName: string;
}

const Client: React.FC = () => {
  const history = useHistory();
  const { addToast } = useToast();
  const { hash }: { hash: string } = useParams();
  const [client, setClient] = useState<ClientProps>();
  const [codPmoc, setCodePmoc] = useState("");
  const [hasErrorcodPmoc, setHasErrorCodePmoc] = useState("");

  useEffect(() => {
    api
      .get(`pmoc/view/client/${hash}`)
      .then((response) => {
        setClient(response.data);
      })
      .catch((err) => {
        history.push("/public/404");
      });
  }, [hash, history]);

  const handleSubmit = async () => {
    try {
      if (!codPmoc) {
        setHasErrorCodePmoc("Código de acesso é obrigatório");
        throw Error("Informe o código de acesso");
      }
      sessionStorage.setItem("pmocUserClientPass", codPmoc);

      history.push(`/public/pmoc/view/client/detail/${hash}`);
    } catch (err) {
      addToast({
        title: "Ops!!",
        description: err instanceof Error ? err.message : "Aconteceu um error",
        type: "error",
      });
    }
  };

  if (!client) {
    return <LoadingProfiz isVisible positionLogo="40%" />;
  }

  return (
    <S.Container>
      <img src={logo} alt="logo profiz" />

      <S.Form>
        <div>
          <Input name="ID do PMOC" disabled defaultValue={client.pmocName} />
        </div>
        <div>
          <Input
            name="Cliente"
            disabled
            defaultValue={client.pmocUserClienteName}
          />
        </div>

        <div>
          <Input
            onChange={(e) => {
              setCodePmoc(e.target.value);
            }}
            onFocusClearError={() => {
              setHasErrorCodePmoc("");
            }}
            hasError={hasErrorcodPmoc}
            placeholder="Digite o código de acesso enviado pelo instalador"
            name="Código de acesso"
          />
        </div>
        <Button onClick={handleSubmit}>Entrar</Button>
      </S.Form>
    </S.Container>
  );
};
export { Client };
