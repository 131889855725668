import { ReactElement, cloneElement, useState } from "react";
import { useHistory } from "react-router";

import { Button } from "components/Button";

import {
  ActionHash,
  useHasPermission,
} from "hooks/permission/useHasPermission";
import { useAccount } from "hooks/permission/account";

import BlockSvg from "assets/icons/permission/block.svg";

import * as S from "./styles";
import { ModalCompleteRegistration } from "components/ModalCompleteRegistration";
import { useAuth } from "hooks/auth";
import { ModalRejectedRegistration } from "components/ModalRejectedRegistration";

export type HasPermissionAlertProps = {
  children: ReactElement;
  navigateTo?: string;
  moduleHash?: string;
  actionHash?: ActionHash;
  grantPermission?: boolean;
};

export function HasPermissionAlert({
  children,
  navigateTo,
  moduleHash = "",
  actionHash = "",
  grantPermission = false,
}: HasPermissionAlertProps) {
  const { whoami } = useAccount();
  const history = useHistory();
  const { permission } = useHasPermission({
    actionHash,
    moduleHash,
  });

  const { user } = useAuth();

  let hasPermission = grantPermission ? grantPermission : permission;

  if (
    (moduleHash === "receipts" || moduleHash === "financial") &&
    !whoami?.isMe
  ) {
    hasPermission = false;
  }

  const [showAlert, setShowAlert] = useState(false);
  const [activeModalRegister, setActiveModalRegister] = useState(false);
  const [activeModalRejectedRegistration, setActiveModalRejectedRegistration] =
    useState(false);

  function handleClick() {
    if (
      user?.profileDisapproved &&
      moduleHash !== "budgets" &&
      moduleHash !== "budgets-service-order"
    ) {
      setActiveModalRejectedRegistration(true);
      return;
    }

    if (navigateTo) {
      if (hasPermission) {
        history.push(navigateTo);
        return;
      }
    }
    setActiveModalRegister(true);
    setShowAlert(true);
  }

  function renderChildren() {
    return cloneElement(children, {
      onClick: handleClick,
      hasPermission,
    });
  }

  return (
    <>
      {renderChildren()}

      {activeModalRejectedRegistration && (
        <ModalRejectedRegistration
          isVisible={activeModalRejectedRegistration}
          setIsActive={setActiveModalRejectedRegistration}
        />
      )}

      {showAlert && (
        <>
          {(user.profileApproved && user.profileComplete && !permission) ||
          ((moduleHash === "receipts" || moduleHash === "financial") &&
            !whoami?.isMe) ? (
            <S.ContainerOverlay>
              <S.BoxAlert>
                <img src={BlockSvg} alt="Ícone de cadeado" />

                <h1>Módulo bloqueado</h1>
                <span>Você não tem permissão para</span>
                <span>acessar este módulo.</span>

                <Button onClick={() => setShowAlert(false)}>Ok, fechar</Button>
              </S.BoxAlert>
            </S.ContainerOverlay>
          ) : (
            <ModalCompleteRegistration
              isVisible={activeModalRegister}
              setIsActive={setActiveModalRegister}
            />
          )}
        </>
      )}
    </>
  );
}
