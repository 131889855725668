import styled, { css } from "styled-components";

export const Container = styled.div`
  ${({ theme }) => css`
    width: 100%;
    padding: 20px;
    background-color: ${theme.colors.background_light};
    border-radius: 10px;
    margin-bottom: 10px;

    p {
      font-size: ${theme.font.sizes.xsmall};
      color: ${theme.colors.text};
    }

    & > div {
      margin-right: 20px;
      display: flex;
    }
  `}
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const Content = styled.div`
  display: flex;
  margin-top: 15px;

  & > div:first-child {
    margin-right: 12px;
  }

  & > div {
    margin-right: 20px;
  }
`;
