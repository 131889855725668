import React, { useEffect, useState } from "react";

import * as S from "./styles";

import EnvironmentSvg from "assets/icons/pmoc/environmentIcon.svg";
import PencilSvg from "assets/icons/pencil.svg";
import { FiChevronLeft } from "react-icons/fi";

import { EnvironmentDTO } from "dtos/PmocEnvironmentDTO";
import { ModalPageProps } from "components/ModalPageClients/types";
import { EnvironmentPageProps } from "../EnvironmentFlow/types";
import { useToast } from "hooks/toast";
import { useTheme } from "styled-components";
import { useEnvironment } from "hooks/pmoc/environmentPmoc";
import { Button } from "components/Button";
import { api } from "services/api";

import { DetailEquipmentDTO } from "dtos/PmocEnvironmentDTO";
import { AlertModal } from "components/AlertModal";
import axios from "axios";
import { LoadingProfiz } from "components/LoadingProfiz";
import { usePmoc } from "hooks/usePmoc";
import { ShowWhenHavePermission } from "components/Permission";

type ResponseEnvironmentApi = {
  data: EnvironmentDTO;
};

type PageProps = {
  handleChooseEditModal?: ModalPageProps;
  handleChooseEnvironment?: EnvironmentPageProps;
};

export function DetailEnvironment({
  handleChooseEditModal,
  handleChooseEnvironment,
}: PageProps) {
  const { addToast } = useToast();
  const theme = useTheme();

  const {
    nameFlow,
    environment,
    handleSetEquipment,
    userIdPMOC,
    unityIdPMOC,
    handleSetEnvironment,
    environmentId,
    handleSetEquipmentId,
  } = useEnvironment();

  const { initialPmocProps } = usePmoc();

  const [modalError, setModalError] = useState(false);

  const [loadingModal, setLoadingModal] = useState(false);

  useEffect(() => {
    setEnvironment();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function setEnvironment() {
    try {
      setLoadingModal(true);

      const routeValue = `/client/${userIdPMOC}/unities/${unityIdPMOC}/environment/${environmentId}`;

      const { data }: ResponseEnvironmentApi = await api.get(
        initialPmocProps && initialPmocProps.id && nameFlow === "pmoc"
          ? `${routeValue}/${initialPmocProps.id}`
          : routeValue
      );

      handleSetEnvironment(data);
    } catch (error) {
      addToast({
        title: "Erro",
        description:
          axios.isAxiosError(error) && error.response?.data.error
            ? error.response.data.error
            : "Não foi possível carregar as informações.",
        type: "error",
      });
    } finally {
      setLoadingModal(false);
    }
  }

  function handleBackButton() {
    if (nameFlow === "unity" && handleChooseEditModal) {
      return handleChooseEditModal("unityDetail");
    }

    if (nameFlow === "pmoc" && handleChooseEnvironment) {
      return handleChooseEnvironment("listEnvironment");
    }
  }

  function handleEditButton() {
    if (nameFlow === "unity" && handleChooseEditModal) {
      return handleChooseEditModal("editEnvironment");
    }

    if (nameFlow === "pmoc" && handleChooseEnvironment) {
      return handleChooseEnvironment("editEnvironment");
    }
  }

  function handleCreateEquipment() {
    handleSetEquipment({} as DetailEquipmentDTO);

    if (nameFlow === "unity" && handleChooseEditModal) {
      return handleChooseEditModal("createEquipment");
    }

    if (nameFlow === "pmoc" && handleChooseEnvironment) {
      return handleChooseEnvironment("createEquipment");
    }
  }

  function handleSaveEnvironment() {
    handleBackButton();
  }

  async function handleEditEquipto(idEquipment: number) {
    try {
      handleSetEquipmentId(idEquipment);
      if (nameFlow === "unity" && handleChooseEditModal) {
        return handleChooseEditModal("detailEquipment");
      }

      if (nameFlow === "pmoc" && handleChooseEnvironment) {
        return handleChooseEnvironment("detailEquipment");
      }
    } catch (error) {
      addToast({
        title: "Erro",
        description:
          axios.isAxiosError(error) && error.response?.data.error
            ? error.response.data.error
            : "Não foi possível carregar os detalhes do equipamento.",
        type: "error",
      });
    }
  }

  function paramsEnvironmentValidate() {
    return !!environment.location ||
      !!environment.airConditionedArea ||
      !!environment.numberOccupants?.variable ||
      !!environment.numberOccupants?.fixed ||
      !!environment.thermalLoad
      ? true
      : false;
  }

  return (
    <>
      {loadingModal ? (
        <LoadingProfiz isVisible={loadingModal} />
      ) : (
        <S.Container>
          <S.Content>
            <AlertModal
              isVisible={modalError}
              title="Complete os dados dos equipamentos"
              description="Alguns equipamentos cadastrados estão com dados incompletos, toque sobre eles para completar o cadastro."
              action="choose"
              handleConfirm={() => setModalError(false)}
              onCloseModal={() => setModalError(false)}
            />

            <S.Top>
              <S.BackButton
                onClick={() => {
                  handleBackButton();
                }}
              >
                <FiChevronLeft size={16} color={theme.colors.text} />
              </S.BackButton>

              <ShowWhenHavePermission
                moduleHash="client-unity-environment"
                actionHash="edit"
              >
                <S.EditUnity
                  onClick={() => {
                    handleEditButton();
                  }}
                >
                  <img src={PencilSvg} alt="Editar ambiente" />
                </S.EditUnity>
              </ShowWhenHavePermission>
            </S.Top>

            <S.Title>{environment.name}</S.Title>

            <S.WrapperIcon>
              <img src={EnvironmentSvg} alt="Imagem de detalhe da unidade" />
            </S.WrapperIcon>

            {paramsEnvironmentValidate() && (
              <>
                <S.SubTitle>Ambiente</S.SubTitle>
                <S.CardEnvironmentInfo>
                  <S.TitleCard>{environment.name}</S.TitleCard>
                  <S.CardInfo>
                    {environment.location ? environment.location : ""}
                    {environment.airConditionedArea && environment.location
                      ? " | "
                      : ""}
                    {environment.airConditionedArea
                      ? environment.airConditionedArea + " m²"
                      : ""}
                    {environment.airConditionedArea &&
                    environment.numberOccupants &&
                    environment.numberOccupants?.fixed
                      ? " | "
                      : ""}

                    {environment.numberOccupants &&
                    environment.numberOccupants?.fixed
                      ? environment.numberOccupants?.fixed +
                        (Number(environment.numberOccupants?.fixed) > 1
                          ? " pessoas fixas"
                          : " pessoa fixa")
                      : ""}
                  </S.CardInfo>
                  <S.CardInfo>
                    {environment.numberOccupants &&
                    environment.numberOccupants?.variable
                      ? environment.numberOccupants?.variable +
                        ` ${
                          Number(environment.numberOccupants?.variable) > 1
                            ? "pessoas variáveis"
                            : "pessoa variável"
                        }`
                      : ""}
                    {environment.numberOccupants &&
                    environment.numberOccupants?.variable &&
                    environment.thermalLoad
                      ? " | "
                      : ""}

                    {environment.thermalLoad ? environment.thermalLoad : ""}
                  </S.CardInfo>
                </S.CardEnvironmentInfo>
              </>
            )}

            <ShowWhenHavePermission
              moduleHash="client-unity-equipment"
              actionHash="create"
            >
              <Button typeButton="outline" onClick={handleCreateEquipment}>
                Adicionar equipamentos
              </Button>
            </ShowWhenHavePermission>

            {environment.equipments && environment.equipments.length > 0 && (
              <S.WrapperSpaceInfo>
                <S.SubTitle>Equipamentos</S.SubTitle>
              </S.WrapperSpaceInfo>
            )}

            {environment.equipments && environment.equipments.length > 0 ? (
              <ShowWhenHavePermission
                moduleHash="client-unity-equipment"
                actionHash="view"
              >
                {environment.equipments.map((currentEquipment, index) => {
                  return (
                    <S.WrapperCardEquipment
                      onClick={async () => {
                        await handleEditEquipto(Number(currentEquipment.id));
                      }}
                      key={index}
                    >
                      <S.WrapperInfoEquipament>
                        <S.TitleCard>
                          {currentEquipment.tagEquipment
                            ? currentEquipment.tagEquipment
                            : "Sem tag"}
                          {currentEquipment.isDisable && (
                            <S.CardInfoError> (Inativo) </S.CardInfoError>
                          )}
                        </S.TitleCard>

                        <S.CardInfo>
                          {currentEquipment.category
                            ? `${currentEquipment.category.name}${
                                currentEquipment.equipment &&
                                currentEquipment.equipment.name
                                  ? " | "
                                  : ""
                              }`
                            : ""}
                          {currentEquipment.equipment
                            ? `${currentEquipment.equipment.name}${
                                currentEquipment.equipmentType &&
                                currentEquipment.equipmentType.name
                                  ? " | "
                                  : ""
                              }`
                            : ""}
                          {currentEquipment.equipmentType
                            ? `${currentEquipment.equipmentType.name}${
                                currentEquipment.capacity &&
                                currentEquipment.capacity.name
                                  ? " | "
                                  : ""
                              }`
                            : ""}
                          {currentEquipment.capacity
                            ? `${currentEquipment.capacity.name}${
                                currentEquipment.brand ? " | " : ""
                              }`
                            : ""}
                          {currentEquipment.brand
                            ? `${currentEquipment.brand}${
                                currentEquipment.model ? " | " : ""
                              }`
                            : ""}
                          {currentEquipment.model
                            ? `${currentEquipment.model}${
                                currentEquipment.componentName ? " | " : ""
                              }`
                            : ""}
                          {currentEquipment.componentName
                            ? currentEquipment.componentName
                            : ""}
                        </S.CardInfo>
                        <S.CardInfo>
                          {nameFlow === "unity"
                            ? ""
                            : currentEquipment.services?.length
                            ? ` ${
                                currentEquipment.componentName ? " | " : ""
                              } ${
                                currentEquipment.services?.length
                              } serviço(s) vinculado(s)`
                            : ""}
                        </S.CardInfo>
                        {nameFlow !== "unity" &&
                          (!currentEquipment.services ||
                            (currentEquipment.services &&
                              currentEquipment.services.length < 1) ||
                            currentEquipment.services[0].id < 1) && (
                            <S.CardInfoError>
                              (Nenhum serviço vinculado)
                            </S.CardInfoError>
                          )}
                      </S.WrapperInfoEquipament>
                    </S.WrapperCardEquipment>
                  );
                })}
              </ShowWhenHavePermission>
            ) : (
              <S.ViewMessage>
                <S.MessageText>
                  Você ainda não criou nenhum equipamento para este ambiente.
                </S.MessageText>
                <S.MessageText>
                  Toque em <strong>“Adicionar equipamentos”</strong> para
                  começar.
                </S.MessageText>
              </S.ViewMessage>
            )}
          </S.Content>

          {nameFlow && nameFlow === "pmoc" && (
            <S.Footer>
              {environment &&
              environment.equipments &&
              environment.equipments.length > 0 ? (
                <Button typeButton="default" onClick={handleSaveEnvironment}>
                  Salvar
                </Button>
              ) : (
                <Button typeButton="disabled">Salvar</Button>
              )}
            </S.Footer>
          )}
        </S.Container>
      )}
    </>
  );
}
