import { AvaliationCard, AvaliationProps } from "components/AvaliationCard";
import { LoadingProfiz } from "components/LoadingProfiz";
import { TablePagination } from "components/TablePagination";
import { AvaliationsResponseData } from "dtos/AvaliationsResponseDTO";
import { useToast } from "hooks/toast";
import { useEffect, useState } from "react";
import { useHistory } from "react-router";
import apiv2 from "services/apiv2";
import * as S from "./styles";

export function PendingList() {
  const [avaliations, setAvaliations] = useState<AvaliationProps[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [pagination, setPagination] = useState({ page: 1, offset: 0 });
  const [totalItems, setTotalItems] = useState(0);

  const history = useHistory();
  const { addToast } = useToast();

  async function loadAvaliations() {
    try {
      const response = await apiv2.get<AvaliationsResponseData>(
        "budgets/service-orders/avaliations?status=pending",
        {
          params: {
            offset: pagination.offset,
            limit: 15,
          },
        }
      );

      setTotalItems(response.data.info?.total || 0);

      const avaliations = response.data.avaliations;

      const mappedAvaliations = avaliations.map((avaliation) => ({
        id: avaliation.id,
        executed: avaliation.executed,
        stars: avaliation.stars,
        sequenceNumber: avaliation.serviceOrder.sequenceNumber || 0,
        serviceOrder: avaliation.serviceOrder,
        clientName: avaliation.serviceOrder.client.name,
      }));

      setAvaliations(mappedAvaliations);
    } catch (error: any) {
      addToast({
        title: "Erro ao carregar as avaliações",
        description: error.response.data.details[0],
        type: "error",
      });
    } finally {
      setIsLoading(false);
    }
  }

  async function handleNavigateToRatingDetails(avaliation: AvaliationProps) {
    const { data } = await apiv2.get(
      `budgets/service-orders/avaliations/${avaliation.id}`
    );

    history.push({
      pathname: `/satisfaction-survey/${avaliation?.serviceOrder?.id}`,
      state: {
        fromList: true,
        emailCliente: avaliation?.serviceOrder?.client.email,
        hashResearch: data.hashResearch,
      },
    });
  }

  useEffect(() => {
    loadAvaliations();
  }, [pagination]); // eslint-disable-line

  return (
    <S.Content>
      {isLoading ? (
        <LoadingProfiz isVisible={isLoading} />
      ) : (
        <>
          {avaliations.length > 0 ? (
            avaliations.map((avaliation) => (
              <S.WrapperAvaliation key={avaliation.id}>
                <AvaliationCard
                  status={"pending"}
                  data={avaliation}
                  onClick={() => handleNavigateToRatingDetails(avaliation)}
                />
              </S.WrapperAvaliation>
            ))
          ) : (
            <S.EmptyList>Você não possui nenhuma avaliação</S.EmptyList>
          )}
          <TablePagination
            rowsPage={pagination.page}
            rowsLength={totalItems}
            rowsPerPage={15}
            handlePageChange={(page) =>
              setPagination({
                ...pagination,
                page,
                offset: page === 1 ? 0 : page * 15 - 15,
              })
            }
          />
        </>
      )}
    </S.Content>
  );
}
