/* eslint-disable react-hooks/exhaustive-deps */
import React, { Dispatch, useEffect, useState } from "react";

import ArrowRight from "assets/icons/arrow-right-onboarding.svg";
import ArrowLeft from "assets/icons/arrow-left-onboarding.svg";
import ArrowLeftDisabled from "assets/icons/arrow-left-disabled.svg";

import * as S from "./styles";

const PROGRESS_BAR_WIDTH = 165;

type ProgressBarProps = {
  steps: number;
  currentStep: number;
  setCurrentStep: Dispatch<React.SetStateAction<number>>;
  endOnboarding?: () => void;
};

export default function ProgressBar({
  steps,
  currentStep,
  setCurrentStep,
  endOnboarding,
}: ProgressBarProps) {
  const [margins, setMargins] = useState<Number[]>([]);

  useEffect(() => {
    getValues();
  }, [currentStep]);

  const getValues = () => {
    const marginsArr: number[] = [];
    const stepsArr = Array.from(Array(steps).keys());
    const valueToSum = 100 / steps;

    let lastValue = valueToSum;
    marginsArr.push(0);

    stepsArr.forEach((value) => {
      marginsArr.push(lastValue);
      lastValue = lastValue + valueToSum;
    });

    marginsArr.splice(marginsArr.length - 1, 1);

    setMargins(marginsArr);
  };

  function handlePrevArrow() {
    if (currentStep <= 1) return;
    setCurrentStep((prevState) => prevState - 1);
  }

  function handleNextArrow() {
    if (currentStep < steps) {
      setCurrentStep((prevState) => prevState + 1);
    } else if (currentStep === steps) {
      endOnboarding?.();
    }
  }

  return (
    <S.Container>
      <button
        disabled={currentStep <= 1}
        onClick={handlePrevArrow}
        style={{ cursor: "pointer" }}
      >
        {currentStep === 1 ? (
          <img src={ArrowLeftDisabled} alt="arrow" />
        ) : (
          <img src={ArrowLeft} alt="arrow" />
        )}
      </button>
      <S.ProgressBar style={{ width: PROGRESS_BAR_WIDTH }}>
        <S.BarColor
          style={{
            width: PROGRESS_BAR_WIDTH / steps,
            marginLeft: `${margins[currentStep - 1]}%`,
          }}
        />
      </S.ProgressBar>
      <button onClick={handleNextArrow} style={{ cursor: "pointer" }}>
        <img src={ArrowRight} alt="arrow" />
      </button>
    </S.Container>
  );
}
