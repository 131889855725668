import styled, { css } from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  height: 88vh;
  weight: 90vw;
`;
export const Content = styled.div`
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
`;

export const ContentPages = styled.div`
  ${({ theme }) => css`
    display: grid;
    flex-direction: row;
    grid-template-columns: repeat(2, 1fr);

    gap: ${theme.grid.gutter};
    flex-direction: column;
  `}
`;

export const LeftContent = styled.div`
  ${({ theme }) => css`
    display: flex;
    overflow: auto;
    flex-direction: column;
    padding-right: 30px;
  `}
`;

export const RightContent = styled.div`
  ${({ theme }) => css`
    display: flex;
    overflow: auto;
    flex-direction: column;
    height: ${window.screen.height <= 768
      ? window.screen.height * 0.4
      : window.screen.height * 0.55}px;
    max-width: 600px;
    padding-right: 30px;

    padding-bottom: 10%;

    /*firefox*/
    scrollbar-width: thin;
    scrollbar-color: ${theme.colors.primary} ${theme.colors.menu_detail_light};

    &::-webkit-scrollbar {
      width: 3px; /* width of the entire scrollbar */
    }
    &::-webkit-scrollbar-track {
      background: ${theme.colors.menu_detail_light};
    }
    &::-webkit-scrollbar-thumb {
      background-color: ${theme.colors.primary};
      border-radius: 10px;
    }
  `}
`;

export const NotEventView = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const WrapperSearch = styled.div`
  margin-top: -110px;
  margin-bottom: 20px;
  width: 500px;
  position: relative;
`;

export const WrapperFilter = styled.div`
  margin-top: 50px;
`;

export const MessageDiv = styled.div`
  ${({ theme }) => css`
    justify-content: center;
    align-items: center;
    padding-top: 30px;
    h6 {
      padding-top: 15px;
      color: ${theme.colors.financial_title};
      font-size: ${theme.font.sizes.large};
      font-weight: ${theme.font.bold};
      text-align: center;
      letter-spacing: 0.26px;
      line-height: 16px;
    }
  `}
`;
