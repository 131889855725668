import styled, { css } from "styled-components";

export const Container = styled.div`
  ${({ theme }) => css`
    width: 100%;
    height: 78px;
    background-color: ${theme.colors.strong_background};
    border-bottom: 1px solid #2b2b2b;

    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding-left: 50px;
    padding-right: 50px;
  `}
`;
