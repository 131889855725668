import { useState } from "react";

import { ListItemCard } from "../ListItemCard";
import { ModalMemberDetail } from "../ModalMemberDetail";

import { TeamMemberDTO } from "dtos/permission/TeamMemberDTO";

import * as S from "./styles";
import { Pages } from "pages/permission/ManagementOfTeam";

type TeamMemberListProps = {
  members: TeamMemberDTO[];
  updateList: () => void;
  setViewPageDetail?: (page: Pages | null) => void;
  setEdditingPermission?: (value: boolean) => void;
  setMemberInviteId?: (value: number) => void;
  setCurrentProfileId?: (value: number) => void;
};

export function TeamMemberList({
  members,
  updateList,
  setViewPageDetail,
  setEdditingPermission,
  setMemberInviteId,
  setCurrentProfileId,
}: TeamMemberListProps) {
  const [memberInvitedId, setMemberInvitedId] = useState(0);
  const [showModalDetail, setShowModalDetail] = useState(false);

  function unSelectMember() {
    if (showModalDetail) {
      setMemberInvitedId(0);
    }
  }

  function handleCloseModal() {
    unSelectMember();
    setShowModalDetail(false);
    updateList();
  }

  function handleSelectMember(id: number, profileId: number) {
    setMemberInvitedId(id);
    setCurrentProfileId && setCurrentProfileId(profileId);
    setMemberInviteId && setMemberInviteId(id);
    setShowModalDetail(true);
  }

  if (members.length === 0) {
    return (
      <S.Container>
        <S.ContentEmptyMessage>
          <S.Label>
            Você ainda não adicionou nenhum membro da equipe, clique em
            <S.Highlight> "Convidar novo membro para equipe" </S.Highlight>para
            começar.
          </S.Label>
        </S.ContentEmptyMessage>
      </S.Container>
    );
  }
  return (
    <S.Container>
      {showModalDetail && (
        <ModalMemberDetail
          setEdditingPermission={setEdditingPermission}
          setViewPageDetail={setViewPageDetail}
          invitedId={memberInvitedId}
          openModalDetail={showModalDetail}
          handleCloseModal={handleCloseModal}
        />
      )}

      <S.ContentList>
        {members.map((member) => (
          <ListItemCard
            image={member.photo}
            roundedImage
            title={member.name}
            boldTitle
            isSelected={member.inviteId === memberInvitedId}
            onClick={() =>
              handleSelectMember(member.inviteId, member.profile.id)
            }
          />
        ))}
      </S.ContentList>
    </S.Container>
  );
}
