import styled, { css } from "styled-components";

type Props = {
  status: string;
};

export const Container = styled.div`
  position: relative;
  height: calc(100vh - 102px);
  display: flex;
  flex-direction: column;
`;

export const Top = styled.div`
  ${({ theme }) => css`
    margin-bottom: 30px;

    display: flex;
    align-items: center;
    justify-content: space-between;

    & > div {
      display: flex;
      align-items: center;
    }

    h2 {
      font-size: ${theme.font.sizes.large};
      color: ${theme.colors.title};
      font-weight: ${theme.font.bold};
    }
  `}
`;

export const ButtonClose = styled.button`
  ${({ theme }) => css`
    width: 35px;
    height: 35px;
    background-color: ${theme.colors.background_light};
    border-radius: 10px;

    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 25px;
  `}
`;

export const StatusBadge = styled.div<Props>`
  ${({ theme, status }) => css`
    height: 7px;
    width: 7px;
    border-radius: 50%;
    margin-right: 12px;
    background-color: ${status === "concluido"
      ? theme.colors.primary
      : theme.colors.text};
  `}
`;

export const StatusText = styled.span`
  ${({ theme }) => css`
    font-size: ${theme.font.sizes.xsmall};
    color: ${theme.colors.text};
  `}
`;

export const Content = styled.div`
  margin-bottom: 20px;
  overflow-y: auto;
  height: 100%;
`;

export const InputContainer = styled.div`
  ${({ theme }) => css`
    width: 100%;
    min-height: 52px;
    max-height: 110px;
    padding: 6px 14px;
    border-radius: 12px;
    margin-bottom: 10px;
    border: 1px solid #707070;
    background-color: ${theme.colors.background_light};

    display: flex;
    align-items: center;
    justify-content: space-between;

    &:focus-within {
      border: 1px solid ${theme.colors.primary};
    }

    & > textarea {
      max-height: 100% !important;
      width: 86%;
      color: ${theme.colors.text_extra_light};
      font-family: ${theme.font.family};
      font-size: ${theme.font.sizes.small};
      resize: none;
      border: none;
      outline: none;
      background: none;
    }
  `}
`;

export const SendButton = styled.button`
  ${({ theme }) => css`
    width: 35px;
    height: 100%;
    min-height: 20px;
    color: ${theme.colors.primary};
    transition: background 0.2s;
  `}
`;

export const WrapperButtons = styled.div`
  ${({ theme }) => css`
    width: 60%;
    min-width: 300px;
    margin: 0 auto;
    padding: 22px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 8px;
    border: 1px solid ${theme.colors.background_light};

    & > div {
      width: 80%;
      display: flex;
      justify-content: space-around;

      & > button {
        width: 80px;
        height: 38px;
        border-radius: 8px;
        color: ${theme.colors.background};
        font-size: ${theme.font.sizes.xsmall};
        background-color: ${theme.colors.primary};
        transition: opacity 0.2s;
      }

      & > button:last-of-type {
        color: ${theme.colors.text};
        background-color: transparent;
        border: 1px solid #707070;
      }

      & > button:hover {
        opacity: 0.8;
      }
    }

    & > span {
      font-size: ${theme.font.sizes.xsmall};
      color: ${theme.colors.title};
      margin-bottom: 16px;
    }
  `}
`;
