import React, { createContext, useContext, useState } from "react";
import { ProviderProps } from "hooks";

type TFilter = {
  typeMoviment: string[] | [];
  setTypeMoviment: (value: string) => Promise<void>;
};

const FinancialWithdrawalsContext = createContext<TFilter>({} as TFilter);

export const FinancialWithdrawalsProvider = ({ children }: ProviderProps) => {
  const [typeMoviment, setTMov] = useState<string[] | []>([]);

  async function setTypeMoviment(value: string) {
    let arrFilter = typeMoviment.filter((element) => element === value);

    return arrFilter.length === 0
      ? setTMov([...typeMoviment, value])
      : setTMov(typeMoviment.filter((element) => element !== value));
  }

  return (
    <FinancialWithdrawalsContext.Provider
      value={{ typeMoviment: typeMoviment, setTypeMoviment }}
    >
      {children}
    </FinancialWithdrawalsContext.Provider>
  );
};

export default FinancialWithdrawalsContext;

export function useFinancialWithdrawals(): TFilter {
  const context = useContext(FinancialWithdrawalsContext);
  if (!context) {
    throw Error("useClient must be used within a FinancialFilterProvider");
  }

  return context;
}
