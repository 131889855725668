import styled from 'styled-components'

const gradient = `
  #3737376b 0%,
  #393939d6 50%,
  #40404061 100%
`

export const ShimmerDescription = styled.div`
  width: 60%;
  height: 12px;
  margin-bottom: 20px;
  border-radius: 10px;

  background-image: linear-gradient(-90deg, ${gradient});

  background-size: 400% 400%;
  animation: shimmer 1.2s ease-in-out infinite;
  @keyframes shimmer {
    0% {
      background-position: 0% 0%;
    }
    100% {
      background-position: -135% 0%;
    }
  }
  &.white {
    background-image: linear-gradient(-90deg, ${gradient});
  }
`

export const ShimmerInput = styled.div`
  width: 100%;
  height: 45px;
  margin-bottom: 8px;
  border-radius: 10px;

  background-image: linear-gradient(-90deg, ${gradient});

  background-size: 400% 400%;
  animation: shimmer 1.2s ease-in-out infinite;

  @keyframes shimmer {
    0% {
      background-position: 0% 0%;
    }
    100% {
      background-position: -135% 0%;
    }
  }
  &.white {
    background-image: linear-gradient(-90deg, ${gradient});
  }
`

export const ShimmerCard = styled.div`
  width: 100%;
  height: 260px;
  margin-bottom: 20px;
  border-radius: 10px;

  background-image: linear-gradient(-90deg, ${gradient});

  background-size: 400% 400%;
  animation: shimmer 1.2s ease-in-out infinite;

  @keyframes shimmer {
    0% {
      background-position: 0% 0%;
    }
    100% {
      background-position: -135% 0%;
    }
  }
  &.white {
    background-image: linear-gradient(-90deg, ${gradient});
  }
`
