import React from "react";

import * as S from "./styles";

type BoxProps = {
  title?: string;
  titleColor?: "text" | "primary";
  children: React.ReactNode;
};

export function ContentBox({ title, titleColor = "text", children }: BoxProps) {
  return (
    <S.Container titleColor={titleColor}>
      <p>{title}</p>

      <S.Content>{children}</S.Content>
    </S.Container>
  );
}
