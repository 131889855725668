import React, { useEffect, useState } from "react";

import * as S from "./styles";
import { Button } from "components/Button";

import InviteIcon from "assets/icons/permission/invite-icon.svg";
import ProfizLogo from "assets/icons/dark-logo.svg";
import { useHistory, useParams } from "react-router";
import { useToast } from "hooks/toast";
import { LoadingProfiz } from "components/LoadingProfiz";
import { AccountData, ProfileData } from "dtos/permission/AnswerInviteDTO";

type RouteParams = {
  registerHash: string;
};

type InvitedType = {
  name: string;
};

type UserType = {
  id: number;
  name: string;
  email: string;
  accountExists: boolean;
};

export function TeamInvitePage() {
  const { registerHash } = useParams<RouteParams>();
  const { addToast } = useToast();

  const [loading, setLoading] = useState(true);

  const [invitedInfo, setInvitedInfo] = useState<InvitedType>(
    {} as InvitedType
  );
  const [userInfo, setUserInfo] = useState<UserType>({} as UserType);
  const [accountInfo, setAccountInfo] = useState<AccountData>(
    {} as AccountData
  );
  const [profileInfo, setProfileInfo] = useState<ProfileData>(
    {} as ProfileData
  );

  const history = useHistory();

  useEffect(() => {
    setData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function setData() {
    fetch(
      `${process.env.REACT_APP_BASE_URL}/v1/users/teams/answer-invite/${registerHash}`,
      {
        method: "GET",
        headers: {
          "App-Token": `${process.env.REACT_APP_TOKEN_CONNECT_BACKEND}`,
        },
      }
    )
      .then((response) => {
        if (response.ok) return response.json();
        throw new Error("Status code error");
      })
      .then((data) => {
        setInvitedInfo(data.invitedBy);
        setUserInfo(data.user);
        setAccountInfo(data.account);
        setProfileInfo(data.profile);
      })
      .catch((_) => {
        addToast({
          title: "Ops!!",
          description:
            "O convite já foi respondido ou o código solicitado é inválido",
          type: "error",
        });

        history.push("/public/404");
      })
      .finally(() => {
        setLoading(false);
      });
  }

  return (
    <>
      {loading ? (
        <LoadingProfiz isVisible={loading} />
      ) : (
        <S.Container>
          <div className="inviteDetail">
            <S.ProfizLogo>
              <img
                src={InviteIcon}
                alt="Icone convite"
                height={window.screen.height * 0.2}
              />
            </S.ProfizLogo>
            <p className="title">
              Você recebeu um convite para fazer parte de uma equipe!
            </p>
            <p className="subtitle">
              <strong>{invitedInfo.name || ""}</strong> quer adicionar você como{" "}
              <strong>{profileInfo.name || ""}</strong> da equipe{" "}
              <strong>{accountInfo.companyName || ""}</strong>.
            </p>

            <Button
              onClick={() => {
                history.push(
                  `/permission/public/permissions-list/${registerHash}`
                );
              }}
            >
              Ver detalhes do convite
            </Button>
          </div>
          <div className="info">
            <div className="content">
              <div className="header">
                <img
                  src={ProfizLogo}
                  alt="Logo Profiz"
                  height={window.screen.height * 0.03}
                />
              </div>
              <p className="message">
                Esta mensagem foi enviada para {userInfo.email || ""}.
              </p>

              <p className="footer">
                2022 © PROFIZ TECNOLOGIA E SERVIÇOS LTDA.
                CNPJ:46.536.392/0001-09
              </p>
            </div>
          </div>
        </S.Container>
      )}
    </>
  );
}
