import { ButtonHTMLAttributes } from "react";

import * as S from "./styles";
import { NewAlertModal } from "components/NewAlertModal";

type CardChecklistProps = {
  isChecked?: boolean;
  isCheckedButtonClick?: boolean;
  children: any;
  color?: "default" | "primary";
  isCheckbox?: boolean;
  onDeleteChecklist?: () => void;
  handlePressButton?: () => void;
  nameTypeCard?: string;
  modalOpen?: boolean;
  setModal?: () => void;
  isRegisterCard?: boolean;
} & ButtonHTMLAttributes<HTMLButtonElement>;

export function CardChecklist({
  isChecked = false,
  children,
  color = "default",
  isCheckbox = true,
  isCheckedButtonClick = true,
  onDeleteChecklist,
  handlePressButton,
  nameTypeCard,
  modalOpen = false,
  setModal,
  isRegisterCard = false,
  ...rest
}: CardChecklistProps) {
  return (
    <S.Container>
      <NewAlertModal
        isVisible={modalOpen}
        title={
          isRegisterCard
            ? "Remover registro do checklist?"
            : "Excluir checklist?"
        }
        description={
          isRegisterCard
            ? "O Registro ainda poderá ser visualizado no menu “Registros”."
            : `Deseja realmente excluir este checklist? Os registros criados ainda poderão ser visualizados na aba "registros".`
        }
        action="choose"
        handleConfirm={() => {
          onDeleteChecklist?.();
          setModal?.();
        }}
        onCloseModal={() => setModal?.()}
        labelConfirm={
          isRegisterCard ? "Remover registro do checklist" : "Excluir checklist"
        }
        labelCancel="Cancelar"
        buttonConfirmColor="#FA8B94"
      />

      <S.CardContainer color={color} isChecked={isChecked}>
        {isCheckbox && handlePressButton && (
          <S.WrapperCheck
            onClick={handlePressButton}
            disabled={isCheckedButtonClick ? false : true}
          >
            <S.CheckBox>
              <S.MarkChecked isChecked={isChecked} />
            </S.CheckBox>
          </S.WrapperCheck>
        )}
        {children}
      </S.CardContainer>
    </S.Container>
  );
}
