import React from 'react'

import * as S from './styles'

export function LocalChecklistShimmer() {
  return (
    <>
      <div>
        <S.ShimmerInput />
        <S.ShimmerDescription />
      </div>

      <div style={{ marginBottom: '25px' }}>
        <S.ShimmerInput />
      </div>

      {[1, 2, 3].map((item) => (
        <div key={item}>
          <S.ShimmerCard />
        </div>
      ))}
    </>
  )
}
