import { EditButton } from "components/EditButton";
import { DeleteButton } from "components/DeleteButton";
import { ModalRight } from "components/ModalRight";

import * as S from "./styles";
import { useState } from "react";
import { useEditClient } from "hooks/editClient";
import { UnityForm } from "components/UnityForm";
import { UnityDTO } from "dtos/ClientDTO";
import { useClient } from "hooks/budget/client";
import { RenderLocationAddress } from "components/RedirectLocationAddress";

type Props = {
  showUnitiesList: () => void;
  disabled?: boolean;
  module?: string;
};

export function CardSelectedUnity({
  showUnitiesList,
  disabled = false,
  module,
}: Props) {
  const [modalOpen, setModalOpen] = useState(false);

  const {
    setSelectedUnity,
    clearUnityData: clearUnityEditClient,
    redefineClientUnity,
  } = useEditClient();

  const { unity, clearUnityData, handleSetUnityClient } = useClient();

  function handleRemoveUnity() {
    clearUnityData();
    clearUnityEditClient();
    redefineClientUnity();
  }

  function handleChooseOtherUnity() {
    setSelectedUnity(unity);
    setModalOpen(true);
  }

  return (
    <>
      <ModalRight
        isOpen={modalOpen}
        handleToggleOpen={() => setModalOpen(!modalOpen)}
      >
        <UnityForm
          module={module}
          handleToggleOpen={() => {
            setModalOpen(!modalOpen);
          }}
          handlePmocEditModal={(unity?: UnityDTO) => {
            if (unity) {
              handleSetUnityClient(unity);
              setModalOpen(!modalOpen);
            }
            setModalOpen(!modalOpen);
          }}
        />
      </ModalRight>

      <S.Label>Endereço (opcional)</S.Label>

      <S.Container>
        <S.Content>
          <p>
            {unity.name} {unity.default ? "(principal)" : "(adicional)"}
          </p>
          <RenderLocationAddress
            city={unity?.city}
            complement={unity?.complement}
            district={unity?.district}
            number={Number(unity?.number)}
            postalCode={unity?.postalCode}
            street={unity?.street}
            uf={unity?.uf}
          />
        </S.Content>

        <S.WrapperActions>
          <EditButton
            disabled={disabled}
            onClick={() => handleChooseOtherUnity()}
          />
          <DeleteButton
            disabled={disabled}
            onClick={() => handleRemoveUnity()}
          />
        </S.WrapperActions>
      </S.Container>
    </>
  );
}
