import styled, { css } from "styled-components";

export const Container = styled.div`
  flex: 1;
`;

export const ButtonCalendar = styled.button`
  ${({ disabled }) => css`
    cursor: ${disabled ? 'not-allowed' : 'pointer'};

    & > img {
      width: 40px;
      height: 40px;
    }
  `}
`;
