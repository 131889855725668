import styled, { css } from "styled-components";

type CheckboxProps = {
  isSelected: boolean;
};

export const Container = styled.div`
  ${({ theme }) => css`
    width: 100%;
    background-color: ${theme.colors.background_light};
    border-radius: 10px;
    padding: 14px 10px;
    margin-top: 26px;
  `}
`;

export const CheckArea = styled.button`
  padding-left: 8px;
  height: 30px;
  /* background-color: red; */

  display: flex;
  align-items: center;
`;

export const Checkbox = styled.div<CheckboxProps>`
  ${({ theme, isSelected }) => css`
    width: 15px;
    height: 15px;
    margin-right: 15px;
    border: 1px solid ${theme.colors.text};
    border-radius: 5px;

    display: flex;
    justify-content: center;
    align-items: center;

    & > div {
      width: 7px;
      height: 7px;
      background-color: ${isSelected ? theme.colors.primary : "transparent"};
      border-radius: 2px;
    }
  `}
`;

export const PaymentText = styled.span`
  ${({ theme }) => css`
    font-size: ${theme.font.sizes.xsmall};
    color: ${theme.colors.text};
  `}
`;

export const WrapperPayment = styled.div`
  display: flex;
  align-items: center;

  &:not(:last-child) {
    margin-bottom: 20px;
  }
`;

export const WrapperPosition = styled.div`
  position: relative;
`;

export const Input = styled.input.attrs({
  type: "number",
})`
  ${({ theme }) => css`
    height: 31px;
    width: 60px;
    outline: none;
    margin-left: 15px;
    margin-right: 10px;

    text-align: center;
    color: ${theme.colors.text_extra_light};
    font-size: ${theme.font.sizes.xsmall};

    border: 1px solid #707070;
    border-radius: 8px;
    background-color: transparent;

    &:focus-within {
      border: 1px solid ${theme.colors.primary};
    }

    &[type="number"] {
      -moz-appearance: textfield;
    }

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
    }
  `}
`;
