import { useState } from "react";

import { switchServiceStage } from "components/ButtonRegistrationDetails/switchServiceStage";
import { CarouselImages } from "./CarouselImages";
import {
  PhotosProps,
  PhotoURIProps,
  SignatureProps,
  StageProps,
} from "dtos/ServiceStagesDTO";

import CloseIcon from "assets/icons/buttonClose.svg";

import * as S from "./styles";

export type StagePhotoData = {
  key: StageProps;
  amountPhotos?: number;
  photos?: PhotosProps[];
  signatures?: SignatureProps[];
};

type Props = {
  serviceName?: string;
  stagePhotoData?: StagePhotoData;
  handleToggleModal: () => void;
};

export function ModalGalleryRegisterImages({
  serviceName,
  stagePhotoData,
  handleToggleModal,
}: Props) {
  const [carouselImages, setCarouselImages] = useState<PhotoURIProps[]>([]);
  const [imageSelected, setImageSelected] = useState(0);
  const [stageService, setStageService] = useState("");
  const [isVisibleCarousel, setIsVisibleCarousel] = useState(false);

  const { key, amountPhotos, photos, signatures } = stagePhotoData!;
  const stageText = switchServiceStage(key);

  function handleToggleCarousel() {
    setIsVisibleCarousel((prev) => !prev);
  }

  function handleSetImagesInCarousel(images: PhotoURIProps[], name: string) {
    handleToggleCarousel();
    setCarouselImages(images);
    setStageService(name);
  }

  return (
    <>
      <S.Top>
        <CarouselImages
          images={carouselImages}
          stageService={stageService}
          imageSelected={imageSelected}
          isVisible={isVisibleCarousel}
          onCloseModal={handleToggleCarousel}
        />

        <div style={{ display: "flex", alignItems: "center" }}>
          <S.BackButton onClick={handleToggleModal}>
            <img src={CloseIcon} alt="Fechar" />
          </S.BackButton>

          <h4>{stageText}</h4>
        </div>

        {serviceName && (
          <S.WrapperServiceName>
            <S.CardText>Serviço</S.CardText>
            <S.Card>
              <span>{serviceName}</span>
            </S.Card>
          </S.WrapperServiceName>
        )}

        <S.WrapperInfo>
          <span>
            total de fotos: <b>{amountPhotos || signatures?.length}</b>
          </span>
        </S.WrapperInfo>
      </S.Top>

      <S.Content>
        {photos?.map((data) => (
          <div key={data.id}>
            <div>
              <S.Title>{data.name}</S.Title>
              <S.Title>
                fotos: <b>{data.photos.length}</b>
              </S.Title>
            </div>

            <S.ListImages>
              {data.photos.map((image, index) => (
                <li
                  key={image.id}
                  onClick={() => {
                    handleSetImagesInCarousel(data.photos, data.name);
                    setImageSelected(index);
                  }}
                >
                  <S.Image src={image.link} alt={`fotos ${data.name}`} />
                </li>
              ))}
            </S.ListImages>
          </div>
        ))}

        {signatures?.map((signature) => {
          const stageName =
            signature.type === "client-sign"
              ? "Assinatura do cliente"
              : "Profiz";

          return (
            <div key={signature.id}>
              <div>
                <S.Title>{stageName}</S.Title>
                <S.Title>
                  fotos: <b>{!!signature ? "1" : "0"}</b>
                </S.Title>
              </div>

              <S.ListImages>
                <li
                  onClick={() =>
                    handleSetImagesInCarousel([signature], stageName)
                  }
                >
                  <S.ImageSignature src={signature?.link} alt="Assinatura" />
                </li>
              </S.ListImages>
            </div>
          );
        })}
      </S.Content>
    </>
  );
}
