import React from 'react'

import * as S from './styles'

type ContentLeftProps = {
  children: React.ReactNode
}

export function ContentLeft({ children }: ContentLeftProps) {
  return <S.Container>{children}</S.Container>
}
