/* eslint-disable no-useless-escape */
import { useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import { useTheme } from "styled-components";
import * as Yup from "yup";
import axios from "axios";

import { useToast } from "hooks/toast";
import { useAuth } from "hooks/auth";

import { Input } from "components/Input";
import { Button } from "components/Button";
import { ModalPolicyAndTermOfUse } from "components/ModalPolicyAndTermOfUse";
import { PasswordVerification } from "components/PasswordVerification";
import { GoogleButton } from "../SignIn/SocialLogin/GoogleButton";

import getValidationErrors from "utils/getValidationErrors";

import profiz from "assets/Profiz.svg";
import BudgetSvg from "assets/budget.svg";
import HomenProfiz from "assets/homens-leveros.svg";
import ServiceOrderSvg from "assets/service-order.svg";
import PmocSvg from "assets/pmoc.svg";
import qrcode from "assets/qrcode.svg";
import ServiceOpportunitySVG from "assets/service-opportunity.svg";
import TeamsSVG from "assets/icons/permission/teams-icon.svg";
import Shop from "assets/icons/menu-icons/shop.svg";

import * as S from "./styles";

type Errors = {
  [key: string]: string;
};

type RouteParams = {
  indicationHash?: string;
};

type ModalPolicyAndTermOfUseProps = {
  isVisible: boolean;
  policyOrTermOfUse: "policy" | "termOfUse";
};

const services = [
  {
    service: "Orçamentos",
    icon: BudgetSvg,
  },
  {
    service: "Ordens de serviço",
    icon: ServiceOrderSvg,
  },
  { service: "PMOC", icon: PmocSvg },
  { service: "Equipes", icon: TeamsSVG },
  { service: "Oportunidades de serviço", icon: ServiceOpportunitySVG },
  { service: "Loja", icon: Shop },
];

export function Register() {
  const { addToast } = useToast();
  const { signUp } = useAuth();
  const theme = useTheme();
  const history = useHistory();

  const { indicationHash } = useParams<RouteParams>();

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const [showPassword, setShowPassword] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [hasError, setHasError] = useState<Errors>({} as Errors);
  const [modalPolicyAndTermOfUseProps, setModalPolicyAndTermOfUseProps] =
    useState<ModalPolicyAndTermOfUseProps>({
      isVisible: false,
    } as ModalPolicyAndTermOfUseProps);

  async function handleSubmit() {
    try {
      setIsLoading(true);
      const schema = Yup.object().shape({
        name: Yup.string().required("Nome é obrigatório"),
        email: Yup.string()
          .required("E-mail é obrigatório")
          .email("Digite um e-mail válido"),
        password: Yup.string()
          .required("Senha é obrigatório")
          .min(8, "Mínimo 8 caracteres"),
      });

      await schema.validate(
        { name, email, password },
        {
          abortEarly: false,
        }
      );

      setHasError({});

      await signUp({ name, email, password, indicationHash });
      history.push("/home");

      addToast({
        title: "Tudo certo!",
        description: "Cadastro realizado com sucesso.",
        type: "success",
      });
    } catch (err: any) {
      if (err instanceof Yup.ValidationError) {
        const errors = getValidationErrors(err);
        setHasError(errors);

        err.errors.forEach((textError) => {
          addToast({
            title: "Ops!!",
            description: textError,
            type: "error",
          });
        });

        return;
      }

      addToast({
        title: "Ops!!",
        description:
          axios.isAxiosError(err) && err.response?.data.error
            ? err.response.data.error
            : "Ocorreu um erro ao criar o seu cadastro",
        type: "error",
      });
      setPassword("");
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <S.BackgroundContent>
      <S.Background>
        <aside>
          <img className="profiz" src={profiz} alt="logo da profiz" />

          <section>
            <div>
              <S.Title>
                Gestão e organização{" "}
                <span>
                  dos seus serviços com <br /> mais
                </span>{" "}
                eficiência <span> e </span> 100% grátis.
              </S.Title>

              <S.Services>
                {services.map((service) => {
                  return (
                    <S.Service>
                      <img src={service.icon} alt={service.service} />
                      <p>{service.service}</p>
                    </S.Service>
                  );
                })}
              </S.Services>
            </div>

            <S.QrCode>
              <img
                className="homens"
                src={HomenProfiz}
                alt="trabalhadores da profiz"
              />

              <div className="qrCode">
                <img src={qrcode} alt="qr code" />

                <span>Baixe o aplicativo.</span>
                <p>Disponível para Android e iOS, escaneie o QR Code.</p>
              </div>
            </S.QrCode>
          </section>
        </aside>
      </S.Background>

      <S.Content>
        <S.AnimationContainer>
          <form id="form_register">
            <S.Title2>
              Criar conta grátis na <span>Profiz</span>
            </S.Title2>
            <div>
              <S.WrapperInput>
                <Input
                  name="Nome"
                  type="text"
                  placeholder="Seu nome"
                  onChange={(text) =>
                    setName(text.currentTarget.value.trimStart())
                  }
                  value={name}
                  hasError={hasError.name}
                  onFocusClearError={() =>
                    setHasError({ ...hasError, name: "" })
                  }
                />
              </S.WrapperInput>

              <S.WrapperInput>
                <Input
                  name="E-mail"
                  type="email"
                  placeholder="exemplo@ex.com"
                  onChange={(text) =>
                    setEmail(text.currentTarget.value.trimStart())
                  }
                  value={email}
                  hasError={hasError.email}
                  onFocusClearError={() =>
                    setHasError({ ...hasError, email: "" })
                  }
                />
              </S.WrapperInput>

              <S.WrapperInput style={{ marginTop: 20, marginBottom: 0 }}>
                <Input
                  name="Senha"
                  autoComplete="new-password"
                  type={showPassword ? "text" : "password"}
                  placeholder="Digite sua senha"
                  onChange={(text) => setPassword(text.currentTarget.value)}
                  hasError={hasError.password}
                  onFocusClearError={() =>
                    setHasError({ ...hasError, password: "" })
                  }
                />

                <S.ShowPassword
                  type="button"
                  onClick={() => setShowPassword(!showPassword)}
                >
                  {showPassword ? (
                    <AiOutlineEyeInvisible
                      size={27}
                      color={theme.colors.subtitle}
                    />
                  ) : (
                    <AiOutlineEye size={27} color={theme.colors.subtitle} />
                  )}
                </S.ShowPassword>
              </S.WrapperInput>
              <PasswordVerification password={password} />

              <S.WrapperButton>
                <Button loading={isLoading} onClick={handleSubmit}>
                  Cadastrar
                </Button>
              </S.WrapperButton>

              <S.Dividers>
                <S.Divider />
                <span>ou</span>
                <S.Divider />
              </S.Dividers>

              <S.Wrapper>
                <GoogleButton text="Cadastre-se com o Google" {...(!!indicationHash && { indicationHash })} />
              </S.Wrapper>

              <S.Wrapper>
                <S.Divider />
                <S.SocialAreaContent>
                  <p style={{ marginBottom: "20px" }}>
                    Ja é um membro ?{" "}
                    <span
                      onClick={() => {
                        history.push("/");
                      }}
                    >
                      {" "}
                      Entrar{" "}
                    </span>
                  </p>
                </S.SocialAreaContent>
              </S.Wrapper>
            </div>
            <S.Wrapper>
              <p style={{ maxWidth: "400px", margin: "0 auto" }}>
                Ao continuar, você concorda com os{" "}
                <button
                  style={{
                    fontSize: theme.font.sizes.xsmall,
                    color: theme.colors.text,
                  }}
                  type="button"
                  onClick={() =>
                    setModalPolicyAndTermOfUseProps({
                      isVisible: true,
                      policyOrTermOfUse: "termOfUse",
                    })
                  }
                >
                  <u>Termos de uso</u>
                </button>{" "}
                e com a{" "}
                <button
                  style={{
                    fontSize: theme.font.sizes.xsmall,
                    color: theme.colors.text,
                  }}
                  type="button"
                  onClick={() =>
                    setModalPolicyAndTermOfUseProps({
                      isVisible: true,
                      policyOrTermOfUse: "policy",
                    })
                  }
                >
                  <u>Política de Privacidade</u>
                </button>{" "}
                da Profiz.
              </p>
            </S.Wrapper>
          </form>
        </S.AnimationContainer>
      </S.Content>

      {modalPolicyAndTermOfUseProps.isVisible && (
        <ModalPolicyAndTermOfUse
          isVisible={modalPolicyAndTermOfUseProps.isVisible}
          policyOrTermOfUse={modalPolicyAndTermOfUseProps.policyOrTermOfUse}
          onCloseModal={() =>
            setModalPolicyAndTermOfUseProps({
              isVisible: false,
            } as ModalPolicyAndTermOfUseProps)
          }
        />
      )}
    </S.BackgroundContent>
  );
}
