import React, { useState } from "react";
import * as Yup from "yup";

import { Input } from "components/Input";
import { Button } from "components/Button";

import { useToast } from "hooks/toast";
import getValidationErrors from "utils/getValidationErrors";

import { api } from "services/api";
import * as S from "../styles";

import profizLap from "assets/laptop5.svg";
import qrcode from "assets/qrcode.svg";
import profiz from "assets/Profiz.svg";

type Errors = {
  [key: string]: string;
};

type EmailSendCodeProps = {
  handleToggleOpen: () => void;
};

export function EmailSendCode({ handleToggleOpen }: EmailSendCodeProps) {
  const { addToast } = useToast();

  const [email, setEmail] = useState("");

  const [isLoading, setIsLoading] = useState(false);
  const [hasError, setHasError] = useState<Errors>({} as Errors);

  async function handleSendCodeToResetPassword() {
    setIsLoading(true);
    try {
      const schema = Yup.object().shape({
        email: Yup.string()
          .email("Digite um e-mail válido")
          .required("E-mail é obrigatório"),
      });

      await schema.validate({ email }, { abortEarly: false });

      setHasError({});

      await api.post("/users/password-recovery/generate", {
        email,
      });

      addToast({
        title: "Ok",
        description:
          "Enviamos um código para o seu e-mail, verifique sua caixa de entrada",
      });

      addToast({
        title: "Importante",
        description:
          "Lembre-se de verificar as pastas de spam e lixo eletrônico de seu e-mail também.",
      });
      handleToggleOpen();
    } catch (error: any) {
      if (error instanceof Yup.ValidationError) {
        const errors = getValidationErrors(error);
        setHasError(errors);
        return;
      }
      addToast({
        type: "error",
        title: "Erro ao enviar código",
        description: error.response.data.error,
      });
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <S.BackgroundContent>
      <S.Background>
        <aside>
          <img className="profiz" src={profiz} alt="logo da profiz" />

          <section>
            <S.Title>
              Boas vindas <span>à Profiz!</span>
            </S.Title>

            <S.SubTitle>
              A solução <span> grátis </span>
              completa para <span> gestão de serviços.</span>
            </S.SubTitle>

            <img
              className="laptop"
              src={profizLap}
              alt="imagens do sistema profiz versao web e mobile"
            />

            <S.QrCode>
              <img src={qrcode} alt="qr code" />

              <div>
                <span>Baixe o aplicativo.</span>
                <p>Disponível para Android e iOS, escaneie o QR Code.</p>
              </div>
            </S.QrCode>
          </section>
        </aside>
      </S.Background>

      <S.Content>
        <div className="content">
          <S.Description>
            Você receberá no seu e-mail um código para cadastrar uma nova senha.
          </S.Description>

          <S.WrapperInput
            onKeyPress={(e) => {
              if (e.key === "Enter") {
                e.preventDefault();
                handleSendCodeToResetPassword();
              }
            }}
          >
            <Input
              name="E-mail"
              type="email"
              placeholder="Digite seu melhor e-mail"
              onChange={(text) => setEmail(text.currentTarget.value)}
              hasError={hasError.email}
              onFocusClearError={() => setHasError({ ...hasError, email: "" })}
            />
          </S.WrapperInput>

          <Button
            loading={isLoading}
            onClick={() => handleSendCodeToResetPassword()}
          >
            Continuar
          </Button>
        </div>
      </S.Content>
    </S.BackgroundContent>
  );
}
