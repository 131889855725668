import { LocalChecklistFromApiDTO } from "dtos/LocalChecklistFromApiDTO";
import addMaskMoney from "./addMaskMoney";
import { LocalChecklistProps } from "dtos/LocalChecklistDTO";

export function mappedLocalChecklistFromApi(
  localChecklists: LocalChecklistFromApiDTO[]
): LocalChecklistProps[] {
  return localChecklists.map((checklist) => {
    const total = checklist.checklist.value * checklist.quantity;

    return {
      id: checklist.checklist.id,
      categoryID: checklist.checklist.category_id,
      name: checklist.checklist.name,
      value: checklist.checklist.value,
      status: checklist.checklist.status,
      type: checklist.type,
      quantity: checklist.quantity,
      total,
      formattedTotal: addMaskMoney(total),
      isChecked: true,
    };
  });
}
