import styled, { css, keyframes } from "styled-components";
import media from "styled-media-query";
import { lighten } from "polished";

export const BackgroundContent = styled.div`
  width: 100%;
  height: 100vh;
  display: grid;
  grid-template-columns: 50% 1fr;
  align-items: center;

  ${media.lessThan("large")`
    display: flex;
    justify-content: center;
  `}

`;

export const Content = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;

  ${media.lessThan("large")`
    overflow-y: auto;
    margin-bottom: 50px;
  `}
`;

export const InputCodeWrapper = styled.div`
  ${media.lessThan("large")`
    display: flex;
    flex-direction: row;
  `}
`;

export const WrapperInput = styled.div`
  margin-bottom: 18px;
  position: relative;
`;

export const WrapperButton = styled.div`
  margin-top: 28px;
`;

export const Dividers = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
  width: 100%;
  color: rgba(255, 255, 255, 0.6);
  margin-top: 15px;

  span {
    margin-bottom: 4px;
  }
`;

export const SocialAreaContent = styled.section`
  ${({ theme }) => css`
    margin-top: 15px;
    p {
      font-size: ${theme.font.sizes.small};
      color: ${theme.colors.title};

      @media (max-width: 1366px) {
        margin-bottom: 0px;
      }

      span {
        color: ${theme.colors.primary200};
        text-decoration: underline;
        font-weight: 600;
        cursor: pointer;
        line-height: 14px;
      }
    }
  `}
`;

export const Divider = styled.div`
  vertical-align: middle;
  width: 100%;
  height: 1px;
  background-color: #353535;
`;

export const Title2 = styled.p`
  ${({ theme }) => css`
    font-size: 24px;
    font-weight: 700;
    line-height: 30px;
    color: ${theme.colors.title};
    margin-bottom: 30px;

    @media (max-width: 1366px) {
      font-size: ${theme.font.sizes.xxlarge};
    }

    span {
      color: ${theme.colors.primary};
    }
  `}
`;

export const Background = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;

  justify-content: space-between;
  align-items: center;
  flex-direction: column;

   ${media.lessThan("large")`
    display: none;
  `}

  aside {
    width: 100%;
    height: 100vh;
    margin-left: 250px;

    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;

    @media (max-width: 1366px) {
      max-width: 500px;
      margin-left: 50px;
    }

    .profiz {
      margin-top: 10%;

      height: 40px;

      @media (max-width: 1366px) {
        margin-bottom: 20px;
      }
    }
  }

  section {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    margin-top: 50px;
  }
`;

export const Services = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: flex-start;
    color: ${theme.colors.text_light_87};
    font-size: 20px;
    line-height: 14px;
    font-weight: 600;
    margin-top: 5rem;
    flex-wrap: wrap;

    @media (max-width: 1366px) {
      margin-top: 2.5rem;
      gap: 5px;
      flex-wrap: nowrap;
    }
  `}
`;

export const Service = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 15px;
  justify-content: space-between;
  line-height: 24px;
  p {
    width: 100px;
    text-align: center;
    font-size: 14px;
    font-weight: 600;

    @media (max-width: 1366px) {
      font-size: 14px;
      width: 100px;
    }
  }

  img {
    width: 45px;
    height: 45px;
  }

  @media (max-width: 1366px) {
    img {
      width: 35px;
      height: 35px;
    }

    p {
      font-size: 14px;
      font-weight: 600;
    }
  }
`;

export const QrCode = styled.div`
  ${({ theme }) => css`
    display: grid;
    grid-template-columns: 1fr 200px;
    align-items: flex-end;
    justify-content: flex-end;
    height: 100%;

    .qrCode {
      flex-direction: column;
      display: flex;
      margin-bottom: 40px;
      margin-left: 20px;
      overflow: visible;
      img {
        width: 57px;
        height: 57px;

        @media (max-width: 1366px) {
          margin-top: 53px;
          width: 37px;
          height: 37px;
        }
      }

      span {
        color: ${theme.colors.text_light_87};
        font-size: 20px;
        line-height: 34px;
        font-weight: 700;

        @media (max-width: 1366px) {
          font-size: 12px;
          line-height: 16px;
          white-space: nowrap;
        }
      }
    }

    p {
      color: ${theme.colors.text_light_60};
      font-size: 14px;
      line-height: 20px;
      font-weight: 400;

      @media (max-width: 1366px) {
        font-size: 10px;
        line-height: 16px;
        white-space: nowrap;
      }
    }

    .homens {
      width: 510px;

      @media (max-width: 1366px) {
        width: 400px;
      }

      @media (min-width: 1900px) {
        width: 800px;
      }
    }
  `}
`;

export const SubTitle = styled.p`
  ${({ theme }) => css`
    color: ${theme.colors.text};

    font-size: 28px;
    line-height: 30px;

    @media (max-width: 1366px) {
      font-size: 16px;
    }

    span {
      color: ${theme.colors.title};

      font-size: 28px;
      font-weight: 500;

      @media (max-width: 1366px) {
        font-size: 16px;
      }
    }
  `}
`;
export const Title = styled.h1`
  ${({ theme }) => css`
    color: ${theme.colors.title};
    font-size: 24px;
    line-height: 36px;
    margin-bottom: 10px;

    @media (max-width: 1366px) {
      font-size: 24px;
      line-height: 36px;
    }

    span {
      color: ${theme.colors.text};
      font-size: 24px;
      font-weight: 400;

      @media (max-width: 1366px) {
        font-size: 24px;
      }
    }
  `}
`;

export const Wrapper = styled.div`
  ${({ theme }) => css`
    div {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 20px;
      margin-bottom: 20px;
    }

    p {
      font-size: ${theme.font.sizes.xsmall};
      color: ${theme.colors.text};
    }
  `}
`;

export const WrapperCode = styled.div`
  margin: 50px 0 30px;
  width: 100%;
  position: relative;
`;

export const Label = styled.div`
  ${({ theme }) => css`
    color: ${theme.colors.title};
    text-align: left;
    font-size: 12px;
    margin-bottom: 5px;
  `}
`;

export const ShowPassword = styled.button`
  height: 68%;
  width: 42px;

  position: absolute;
  right: 2px;
  top: 30%;
  transition: background 0.2s;

  &:hover {
    background: ${({ theme }) => lighten(0.2, theme.colors.background_light)};
  }
`;

const appearFromLeft = keyframes`
  from{
    opacity: 0;
    transform: translateX(-50px)
  } to {
    opacity: 1;
    transform: translateX(0)
  }
`;

export const AnimationContainer = styled.div`
  ${({ theme }) => css`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    animation: ${appearFromLeft} 1s;

    form {
      width: 100%;
      max-width: 530px;
      margin: 80px 0 25px 0;
      text-align: center;

      & > img {
        margin-bottom: 23px;
      }

      h1 {
        margin-bottom: 25px;
      }

      a {
        color: ${theme.colors.text};
        font-size: ${theme.font.sizes.small};
        display: block;
        transition: color 0.2;
      }
    }
  `}
`;

