export type TourTypeProps =
  | "homeOnboarding"
  | "budgetOnboarding"
  | "serviceOrderOnboarding"
  | "eventsCalendarOnboarding"
  | "pmocOnboarding";

interface StepProps {
  id: number;
  title: string;
  description: string;
}

interface LabelProps {
  steps: StepProps[];
  type: TourTypeProps;
}

const labels: LabelProps[] = [
  {
    type: "homeOnboarding",
    steps: [
      {
        id: 1,
        title: "Boas vindas à Profiz!",
        description:
          "Experiência completa e 100% grátis finalizando o cadastro.",
      },
      {
        id: 2,
        title: "Libere todas funcionalidades!",
        description:
          "Cadastre seu CNPJ ou envie suas certificações profissionais.",
      },
      {
        id: 3,
        title: "Conheça a Vitrine de profissionais",
        description:
          "Depois do cadastro aprovado, seu perfil será exibido para novos clientes.",
      },
      {
        id: 4,
        title: "Explore todas as funções",
        description:
          "Crie orçamentos, ordens de serviço, PMOCs, consulte sua agenda e muito mais!",
      },
    ],
  },
  {
    type: "budgetOnboarding",
    steps: [
      {
        id: 1,
        title: "Faça seu orçamento",
        description:
          "Preencha os campos vazios com as informações necessárias.",
      },
      {
        id: 2,
        title: "Finalize e envie aos seus clientes",
        description:
          "Espere o cliente aceitar e clique em “Aprovar e gerar ordem de serviço”.",
      },
      {
        id: 3,
        title: "Execute quando quiser!",
        description: "Você pode agendar ou iniciar imediatamente a execução.",
      },
      {
        id: 4,
        title: "Adicione fotos e registros",
        description:
          "Durante a execução do serviço, inclua fotos e registros importantes.",
      },
      {
        id: 5,
        title: "Adicione sua assinatura",
        description: "Acrescente assinaturas na tela do celular ou com foto.",
      },
      {
        id: 6,
        title: "Pesquisa de Satisfação",
        description: "Envie uma pesquisa de avaliação para seus clientes.",
      },
      {
        id: 7,
        title: "Vitrine de profissionais",
        description:
          "Tenha um perfil destaque em nossa vitrine e ganhe novos clientes!.",
      },
    ],
  },
  {
    type: "serviceOrderOnboarding",
    steps: [
      {
        id: 1,
        title: "Crie uma Ordem de Serviço!",
        description: "Preencha os campos vazios com as informações.",
      },
      {
        id: 2,
        title: "Agende a visita!",
        description:
          "Irá aparecer a opção de agendar ou iniciar imediatamente a execução.",
      },
      {
        id: 3,
        title: "Adicione fotos e registros",
        description:
          "Durante a execução do serviço, inclua fotos e registros importantes.",
      },
      {
        id: 4,
        title: "Adicione sua assinatura",
        description:
          "Acrescente assinaturas através da tela ou foto e transmita confiança.",
      },
      {
        id: 5,
        title: "Pesquisa de Satisfação",
        description:
          "Envie uma pesquisa de avaliação e ganhe credibilidade com seus clientes.",
      },
      {
        id: 6,
        title: "Vitrine de Instaladores",
        description:
          "Ganhe destaque em nossa vitrine    com certificados e avaliações.",
      },
      {
        id: 7,
        title: "Como ter um perfil de destaque?",
        description:
          "Acumule avaliações e comentários positivos de seus clientes.",
      },
    ],
  },
  {
    type: "eventsCalendarOnboarding",
    steps: [
      {
        id: 1,
        title: "Calendário de Eventos",
        description:
          "Use a lupa para fazer pesquisas ou  role a tela para descobrir eventos.",
      },
      {
        id: 2,
        title: "Pesquise, salve e compartilhe!",
        description: "É possível salvar para acompanhar as novidades.",
      },
      {
        id: 3,
        title: "Como saber mais?",
        description: "Clique no evento e veja todas informações.",
      },
    ],
  },
  {
    type: "pmocOnboarding",
    steps: [
      {
        id: 1,
        title: "PMOC",
        description: "Crie PMOCs de maneira fácil.",
      },
      {
        id: 2,
        title: "Defina identidades",
        description:
          "Selecione clientes, ambiente, equipamento, escolha uma data e muito mais.",
      },
      {
        id: 3,
        title: "Agende serviços de PMOC",
        description:
          "Escolha uma data para que o app gere uma ordem de serviço automaticamente.",
      },
      {
        id: 4,
        title: "Registre os serviços de PMOC",
        description:
          "Adicione fotos e outros registros, deixando tudo salvo no histórico.",
      },
    ],
  },
];

export function getInfosToUseInTheTour(
  currentType: TourTypeProps,
  currentStep: number
): { amountSteps: number; title: string; description: string } {
  const myStepSelected = {} as ReturnType<typeof getInfosToUseInTheTour>;

  labels.forEach(({ type, steps }) => {
    if (type === currentType) {
      const stepSelected = steps.find((step) => step.id === currentStep);

      myStepSelected.amountSteps = steps.length;
      myStepSelected.title = stepSelected?.title!;
      myStepSelected.description = stepSelected?.description!;
    }
  });

  return { ...myStepSelected };
}
