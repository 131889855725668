import { EditButton } from "components/EditButton";
import { DeleteButton } from "components/DeleteButton";
import { ModalRight } from "components/ModalRight";

import { usePmoc } from "hooks/usePmoc";

import * as S from "./styles";
import { useEnvironment } from "hooks/pmoc/environmentPmoc";
import { useState } from "react";
import { useEditClient } from "hooks/editClient";
import { UnityForm } from "components/UnityForm";
import { UnityDTO } from "dtos/ClientDTO";

type CardSelectedUnityProps = {
  disableDeleteButton?: boolean;
  disableEditButton?: boolean;
}

export function CardSelectedUnity({
    disableDeleteButton=false,
    disableEditButton=false
}:CardSelectedUnityProps) {
  const { unity, clearUnityData, handleSetUnity } = usePmoc();
  const { handleReset } = useEnvironment();
  const [modalOpen, setModalOpen] = useState(false);

  const { setSelectedUnity } = useEditClient();

  function handleRemoveUnity() {
    handleReset();
    clearUnityData();
  }

  function handleChooseOtherUnity() {
    setSelectedUnity(unity);
    setModalOpen(true);
  }

  return (
    <>
      <ModalRight
        isOpen={modalOpen}
        handleToggleOpen={() => setModalOpen(!modalOpen)}
      >
        <UnityForm
          handlePmocEditModal={(unity?: UnityDTO) => {
            if (unity) {
              handleSetUnity(unity);
            }
            setModalOpen(!modalOpen);
          }}
          modulePmoc
        />
      </ModalRight>

      <S.Label>Unidade*</S.Label>

      <S.Container>
        <S.Content>
          <p>
            {unity.name} {unity.default ? "(principal)" : "(adicional)"}
          </p>
          <p>
            {unity.street}, {unity.number}{" "}
            {unity.district ? " , " + unity.district : ""}
          </p>
          <p>
            {unity.city}, {unity.uf}
          </p>
        </S.Content>

        <S.WrapperActions>
          <EditButton
            disabled={disableEditButton}
            onClick={() => handleChooseOtherUnity()}
          />
          <DeleteButton
            disabled={disableDeleteButton}
            onClick={() => handleRemoveUnity()}
          />
        </S.WrapperActions>
      </S.Container>
    </>
  );
}
