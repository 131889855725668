import { format, parseISO } from "date-fns";
import { UnityDTO } from "dtos/ClientDTO";

export function mappedClientUnities(unities: UnityDTO[]) {
  return unities.map((unity) => ({
    id: unity.id,
    clientId: unity.clientId,
    name: unity.name.length ? unity.name : "Endereço sem nome",
    responsible: unity.responsible,
    email: unity.email,
    phone: unity.phone,
    postalCode: unity.postalCode,
    street: unity.street,
    district: unity.district,
    number: unity.number,
    complement: unity.complement,
    city: unity.city,
    uf: unity.uf,
    additionalInfo: unity.additionalInfo,
    default: unity.default,
    createdAt: format(parseISO(unity.createdAt!), "dd/MM/yyyy"),
    environments: unity.environments,
  }));
}

export function mappedOneClientUnity(unity: UnityDTO) {
  return {
    id: unity.id,
    clientId: unity.clientId,
    name: unity.name.length ? unity.name : "Endereço sem nome",
    responsible: unity.responsible,
    email: unity.email,
    phone: unity.phone,
    postalCode: unity.postalCode,
    street: unity.street,
    number: unity.number,
    complement: unity.complement,
    city: unity.city,
    uf: unity.uf,
    additionalInfo: unity.additionalInfo,
    default: unity.default,
    createdAt: format(parseISO(unity.createdAt!), "dd/MM/yyyy"),
    environments: unity.environments,
  };
}
