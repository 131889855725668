import styled, { css } from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 87px;

  img {
    width: 180px;
    height: 190px;
  }
`;

export const Content = styled.div`
  width: 345px;
  text-align: center;
`;

export const Title = styled.h1`
  ${({ theme }) => css`
    font-family: ${theme.font.family};
    font-weight: ${theme.font.normal};
    font-size: ${theme.font.sizes.medium};
    color: ${theme.colors.text_light_87};
    line-height: 20px;
    margin-top: 30px;
    margin-bottom: 10px;
  `}
`;

export const Highlight = styled.span`
  ${({ theme }) => css`
    font-family: ${theme.font.family};
    font-weight: ${theme.font.normal};
    font-size: ${theme.font.sizes.xsmall};
    color: ${theme.colors.text_light_87};
    line-height: 20px;
  `}
`;

export const Description = styled.span`
  ${({ theme }) => css`
    font-family: ${theme.font.family};
    font-weight: ${theme.font.normal};
    font-size: ${theme.font.sizes.xsmall};
    color: ${theme.colors.text_light_60};
    line-height: 20px;
  `}
`;
