/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef } from "react";

import { AsideTop } from "components/AsideTop";
import AsideLeftMenu from "components/AsideMenuLeft";

import * as S from "./styles";
import { useTopMenu } from "hooks/topMenu";

export type BaseTemplateProps = {
  children: React.ReactNode;
};

export function Base({ children }: BaseTemplateProps) {
  const divRef = useRef({} as HTMLDivElement);

  const { isTopVisible } = useTopMenu();

  useEffect(() => {
    divRef.current.scrollTop = 0;
  }, [window.location.href]);

  return (
    <S.Container>
      <AsideLeftMenu />

      <S.Wrapper ref={divRef}>
        {isTopVisible && <AsideTop />}
        <S.Content>{children}</S.Content>
      </S.Wrapper>
    </S.Container>
  );
}
