import styled, { css } from 'styled-components';
import { lighten } from "polished";

export const Container = styled.div`
  ${({ theme }) => css`
    min-height: 50px;

    display: flex;
    justify-content: space-between;
    align-items:center;

    .responsible-card {
      border-radius: 10px;
      display: flex;
      padding:5px;
      min-width:88%;
      background: ${lighten(0.05, theme.colors.background)};
      .icon {
        height: 40px;
        margin-right:5px;
      }
    }

    .edit-button {
      display: flex;
      align-items:center;
      justify-content:center;
      border-radius: 10px;
      min-height:50px;
      min-width:50px;
      background: ${lighten(0.05, theme.colors.background)};
      .icon {
        height: 13px;
      }
    }

    .responsible-name {
      font-size: 12px;
      font-family: ${theme.font.family};
      font-weight: ${theme.font.bold};
      color: ${theme.colors.text_extra_light};
    }
  `}
`;

export const Content = styled.div`
  width: 80%;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const Label = styled.p`
  ${({ theme }) => css`
    font-size: ${theme.font.sizes.xsmall};
    margin-bottom: 5px;
    color: ${theme.colors.title};
  `}
`
