import styled, { css } from 'styled-components'

export const Container = styled.div``

export const WrapperContent = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  &:first-of-type {
    margin-bottom: 10px;
  }
`

export const Title = styled.span`
  ${({ theme }) => css`
    font-size: ${theme.font.sizes.xsmall};
    color: ${theme.colors.title};
  `}
`

export const TextDetail = styled.span`
  ${({ theme }) => css`
    font-size: ${theme.font.sizes.xsmall};
    color: ${theme.colors.text};
  `}
`
