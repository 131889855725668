import React, { useEffect, useState } from "react";
import { ModalPageProps } from "components/ModalPageClients/types";
import { EnvironmentPageProps } from "../EnvironmentFlow/types";
import { CarouselImages } from "components/ModalGalleryRegisterImages/CarouselImages";

import { FiChevronLeft } from "react-icons/fi";
import PencilSvg from "assets/icons/pencil.svg";
//import QRCodeIcon from 'assets/icons/pmoc/qrCodeBorder.svg';

import * as S from "./styles";
import { useToast } from "hooks/toast";
import { useTheme } from "styled-components";
import { useEnvironment } from "hooks/pmoc/environmentPmoc";
import { api } from "services/api";
import { AlertModal } from "components/AlertModal";
import { Button } from "components/Button";

import DetailEquipamentIcon from "assets/icons/pmoc/detailEquipamentIcon.svg";
import {
  DetailEquipmentDTO,
  PmocServiceProps,
  ServiceProps,
} from "dtos/PmocEnvironmentDTO";
import axios from "axios";
import { LoadingPageModal } from "components/LoadingPageModal";
import { usePmoc } from "hooks/usePmoc";
import { EditButton } from "components/EditButton";
import { DeleteButton } from "components/DeleteButton";
import { Photo } from "dtos/ServiceChecklistDTO";
import { ShowWhenHavePermission } from "components/Permission";

type PageProps = {
  handleChooseEditModal?: ModalPageProps;
  handleChooseEnvironment?: EnvironmentPageProps;
};

export function DetailEquipment({
  handleChooseEditModal,
  handleChooseEnvironment,
}: PageProps) {
  const { addToast } = useToast();
  const theme = useTheme();

  const {
    nameFlow,
    environment,
    handleSetEquipment,
    userIdPMOC,
    unityIdPMOC,
    equipmentId,
    equipment,
    handleSetPmocService,
    pushStackNavigation,
  } = useEnvironment();
  const { initialPmocProps } = usePmoc();

  const [showAlertDelete, setShowAlertDelete] = useState(false);
  const [loadingModal, setLoadingModal] = useState(false);
  const [selectedService, setSelectedService] = useState({} as ServiceProps);

  const [photoModal, setPhotoModal] = useState(false);

  const [stagePhotoData, setStagePhotoData] = useState<Photo[]>([]);
  const [photoIndex, setPhotoIndex] = useState(0);

  useEffect(() => {
    SetData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function SetData() {
    try {
      setLoadingModal(true);

      const routeValue = `/client/${userIdPMOC}/unities/${unityIdPMOC}/environment/${environment.id}/equipment/${equipmentId}`;
      const { data } = await api.get(
        nameFlow === "pmoc" && initialPmocProps && initialPmocProps.id
          ? `${routeValue}/${initialPmocProps.id}`
          : routeValue
      );
      handleSetEquipment(data);
    } catch (error) {
      addToast({
        title: "Erro",
        description: "Não foi possível carregar os dados do equipamento.",
        type: "error",
      });
    } finally {
      setLoadingModal(false);
    }
  }

  function handleBackButton() {
    if (nameFlow === "unity" && handleChooseEditModal) {
      return handleChooseEditModal("detailEnvironment");
    }

    if (nameFlow === "pmoc" && handleChooseEnvironment) {
      return handleChooseEnvironment("detailEnvironment");
    }
  }

  function handleEditButton() {
    if (nameFlow === "unity" && handleChooseEditModal) {
      return handleChooseEditModal("editEquipment");
    }

    if (nameFlow === "pmoc" && handleChooseEnvironment) {
      return handleChooseEnvironment("editEquipment");
    }
  }

  async function HandleDeleteService(idService: number) {
    try {
      setLoadingModal(true);

      //bater no endpoint para desvincular o servico ao equipamento...
      await api.delete(`/pmoc/service/${idService}`);

      let auxEquipment: DetailEquipmentDTO = JSON.parse(
        JSON.stringify(equipment)
      );

      if (auxEquipment.services) {
        auxEquipment = {
          ...auxEquipment,
          services: auxEquipment.services.filter((element) => {
            return Number(element.id) !== Number(idService);
          }),
        };

        handleSetEquipment(auxEquipment);
      }

      addToast({
        title: "Ok!!",
        description: "Serviço desvinculado do equipamento com sucesso!",
        type: "success",
      });
    } catch (error) {
      addToast({
        title: "Erro",
        description:
          axios.isAxiosError(error) && error.response?.data.error
            ? error.response.data.error
            : "Não foi possível desvincular o serviço do equipamento.",
        type: "error",
      });
    } finally {
      setLoadingModal(false);
    }
  }

  // async function handleQRCodeDownload() {
  //     return;
  // }

  function handlePhotoButton() {
    if (nameFlow === "unity" && handleChooseEditModal) {
      return handleChooseEditModal("createPhotoEquipment");
    }

    if (nameFlow === "pmoc" && handleChooseEnvironment) {
      return handleChooseEnvironment("createPhotoEquipment");
    }
  }

  function handleServiceButton() {
    return handleChooseEnvironment && handleChooseEnvironment("servicesList");
  }

  async function handleSetPage(element: ServiceProps) {
    try {
      if (!element.serviceModelId) return;

      setLoadingModal(true);
      pushStackNavigation("detailEquipment");
      const { data } = await api.get(`/pmoc/service/persistance/${element.id}`);
      handleSetPmocService({
        ...data,
        serviceIdPmoc: element.id,
      } as PmocServiceProps);
      handleChooseEnvironment && handleChooseEnvironment("editService");
    } catch (error) {
      addToast({
        title: "Erro",
        description:
          axios.isAxiosError(error) && error.response?.data.error
            ? error.response.data.error
            : "Não foi possível não foi possível obter os dados do serviço.",
        type: "error",
      });
    } finally {
      setLoadingModal(false);
    }
  }

  const handleTogglePhotoModal = () => {
    setPhotoModal(!photoModal);
  };

  return (
    <>
      {loadingModal ? (
        <LoadingPageModal />
      ) : (
        <S.Container>
          <S.Content>
            <AlertModal
              isVisible={showAlertDelete}
              title="Deseja desvincular?"
              description="Deseja realmente desvincular este serviço do equipamento?"
              action="choose"
              handleConfirm={() => {
                setShowAlertDelete(false);
                HandleDeleteService(selectedService.id);
              }}
              onCloseModal={() => setShowAlertDelete(false)}
            />

            <S.Top>
              <S.BackButton
                onClick={() => {
                  handleBackButton();
                }}
              >
                <FiChevronLeft size={16} color={theme.colors.text} />
              </S.BackButton>

              <ShowWhenHavePermission
                moduleHash="client-unity-equipment"
                actionHash="edit"
              >
                <S.EditUnity
                  onClick={() => {
                    handleEditButton();
                  }}
                >
                  <img src={PencilSvg} alt="Editar ambiente" />
                </S.EditUnity>
              </ShowWhenHavePermission>
            </S.Top>

            <S.Title>
              {equipment.tagEquipment ? equipment.tagEquipment : "Sem tag"}
            </S.Title>

            <S.WrapperIcon>
              <img src={DetailEquipamentIcon} alt={"icone padrao de detalhe"} />
            </S.WrapperIcon>

            <div style={{ marginBottom: 3 }}>
              <S.SubTitle>Equipamento</S.SubTitle>
            </div>
            <S.CardEquipmentInfo>
              <S.TitleCard>{equipment.tagEquipment}</S.TitleCard>
              <S.CardInfo>
                {equipment.category
                  ? `${equipment.category.name}${
                      equipment.equipment && equipment.equipment.name
                        ? " | "
                        : ""
                    }`
                  : ""}
                {equipment.equipment
                  ? `${equipment.equipment.name}${
                      equipment.equipmentType && equipment.equipmentType.name
                        ? " | "
                        : ""
                    }`
                  : ""}
                {equipment.equipmentType
                  ? `${equipment.equipmentType.name}${
                      equipment.capacity && equipment.capacity.name ? " | " : ""
                    }`
                  : ""}
                {equipment.capacity
                  ? `${equipment.capacity.name}${equipment.brand ? " | " : ""}`
                  : ""}
                {equipment.brand
                  ? `${equipment.brand}${equipment.model ? " | " : ""}`
                  : ""}
                {equipment.model
                  ? `${equipment.model}${equipment.componentName ? " | " : ""}`
                  : ""}
                {equipment.componentName ? equipment.componentName : ""}
              </S.CardInfo>
            </S.CardEquipmentInfo>

            {equipment.tagPhotos && equipment.tagPhotos.length > 0 && (
              <>
                <S.WrapperInput>
                  <S.SubTitle>Etiquetas ou TAGS já existentes</S.SubTitle>
                </S.WrapperInput>
                <S.ViewImage>
                  {equipment.tagPhotos &&
                    equipment.tagPhotos.length > 0 &&
                    equipment.tagPhotos.map((element, index) => {
                      return (
                        <div key={element.id}>
                          <CarouselImages
                            isVisible={photoModal}
                            imageSelected={photoIndex}
                            images={stagePhotoData}
                            onCloseModal={handleTogglePhotoModal}
                          />
                          <S.Image
                            src={element.link}
                            key={element.id}
                            onClick={() => {
                              if (
                                equipment.tagPhotos &&
                                equipment.tagPhotos.length > 0
                              ) {
                                setPhotoIndex(index);
                                setStagePhotoData(
                                  equipment.tagPhotos.map((photo) => {
                                    return { id: photo.id, link: photo.link };
                                  })
                                );
                                handleTogglePhotoModal();
                              }
                            }}
                          />
                        </div>
                      );
                    })}
                </S.ViewImage>
              </>
            )}

            {equipment.invoice && Object.keys(equipment.invoice).length > 0 && (
              <>
                <S.WrapperInput>
                  <S.SubTitle>Nota fiscal</S.SubTitle>
                </S.WrapperInput>
                <S.ViewImage>
                  {equipment.invoice &&
                    Object.keys(equipment.invoice).length > 0 && (
                      <div key={equipment.invoice.id}>
                        <CarouselImages
                          isVisible={photoModal}
                          imageSelected={photoIndex}
                          images={stagePhotoData}
                          onCloseModal={handleTogglePhotoModal}
                        />
                        <S.Image
                          key={equipment.invoice.id}
                          src={equipment.invoice.link}
                          onClick={() => {
                            if (equipment.invoice) {
                              setPhotoIndex(equipment.invoice.id);
                              setStagePhotoData([
                                {
                                  id: equipment.invoice.id,
                                  link: equipment.invoice.link,
                                },
                              ]);
                              handleTogglePhotoModal();
                            }
                          }}
                        />
                      </div>
                    )}
                </S.ViewImage>
              </>
            )}

            {nameFlow === "pmoc" &&
              equipment &&
              equipment.services &&
              equipment.services.length > 0 && (
                <S.WrapperInput>
                  <S.Title>Serviços</S.Title>
                </S.WrapperInput>
              )}

            {nameFlow === "pmoc" &&
              equipment &&
              equipment.services &&
              equipment.services.length > 0 &&
              equipment.services.map((element, index) => {
                return (
                  <S.CardService key={index}>
                    <S.ViewInfoItem>
                      <strong>
                        <S.TitleEquipmentInfo>
                          {element.name}
                        </S.TitleEquipmentInfo>
                      </strong>
                      <S.EquipmentInfoText>
                        {element.runtime &&
                          Number(element.runtime.time) !== 0 &&
                          element.runtime.extension &&
                          `Execução: ${element.runtime.time} ${element.runtime.extension}`}
                        {element.warranty &&
                        Number(element.warranty.time) &&
                        element.runtime &&
                        Number(element.runtime.time) !== 0
                          ? ` | `
                          : ""}
                        {element.warranty &&
                          Number(element.warranty.time) !== 0 &&
                          element.warranty.extension &&
                          `Garantia: ${element.warranty.time} ${element.warranty.extension}`}
                      </S.EquipmentInfoText>
                      <S.WrapperChecklist>
                        <p className="title">Etapas do serviço</p>
                        <p className="quantityChecklist">
                          {`${element.amountChecklist} checklist${
                            Number(element.amountChecklist) > 1 ? "s" : ""
                          }`}
                        </p>
                      </S.WrapperChecklist>
                    </S.ViewInfoItem>
                    <S.WrapperButton>
                      <EditButton
                        onClick={() => {
                          handleSetPage(element);
                        }}
                      />
                      <DeleteButton
                        onClick={() => {
                          setSelectedService(element);
                          setShowAlertDelete(true);
                        }}
                      />
                    </S.WrapperButton>
                  </S.CardService>
                );
              })}

            {/*
            <S.WrapperInput marginTop={30}>
                <S.ButtonQRCode onClick={handleQRCodeDownload}>
                    <img src={QRCodeIcon} alt="icone qrcode" />
                    <S.EquipmentInfoTextButton>
                        Baixar QR {`\n`}Code
                    </S.EquipmentInfoTextButton>
                </S.ButtonQRCode>
            </S.WrapperInput> */}
          </S.Content>

          <S.Footer>
            <ShowWhenHavePermission
              moduleHash="client-unity-equipment"
              actionHash="edit"
            >
              <Button
                typeButton="outline"
                onClick={() => {
                  handlePhotoButton();
                }}
              >
                Adicionar fotos do equipamento
              </Button>
            </ShowWhenHavePermission>

            {nameFlow === "pmoc" && (
              <Button typeButton="outlineOrange" onClick={handleServiceButton}>
                Adicionar serviços ao equipamento
              </Button>
            )}

            {nameFlow === "pmoc" &&
              equipment &&
              equipment.services &&
              equipment.services.length > 0 && (
                <Button
                  typeButton="default"
                  onClick={() => {
                    handleBackButton();
                  }}
                >
                  Salvar e adicionar ao ambiente
                </Button>
              )}
          </S.Footer>
        </S.Container>
      )}
    </>
  );
}
