import React, { useState } from "react";
import { useTheme } from "styled-components";
import request from "axios";

import shop from "assets/icons/menu-icons/shop.svg";
import { MenuButton } from "../MenuButton";
import { api } from "services/api";
import { useAuth } from "hooks/auth";
import { ModalCompleteRegistration } from "components/ModalCompleteRegistration";
import { ModalRejectedRegistration } from "components/ModalRejectedRegistration";

import { useToast } from "hooks/toast";

export function ShopOptions() {
  const theme = useTheme();
  const { user } = useAuth();
  const [activeModalRegister, setActiveModalRegister] = useState(false);
  const [activeModalRejectedRegistration, setActiveModalRejectedRegistration] =
    useState(false);
  const { addToast } = useToast();

  const handleLogin = async () => {
    try {
      if (user?.profileDisapproved) {
        setActiveModalRejectedRegistration(true);
        return;
      }

      if (user.profileComplete && user.profileApproved) {
        const { data } = await api.post("/webview/auth");
        const win = window.open(data.url, "_blank");
        win?.focus();
      } else {
        setActiveModalRegister(true);
      }
    } catch (error) {
      addToast({
        title: "Ops!!",
        description:
          request.isAxiosError(error) && error.response?.data.error
            ? error.response.data.error
            : "Não foi possível fazer login na loja",
        type: "error",
      });
    }
  };

  return (
    <>
      <MenuButton
        id="shop"
        icon={shop}
        title="Loja"
        link=""
        onClick={handleLogin}
        colorSelected={theme.colors.purple_opacity}
        hasPermission={user.profileComplete && user.profileApproved}
      />
      <ModalCompleteRegistration
        isVisible={activeModalRegister}
        setIsActive={setActiveModalRegister}
      />
      <ModalRejectedRegistration
        isVisible={activeModalRejectedRegistration}
        setIsActive={setActiveModalRejectedRegistration}
      />
    </>
  );
}
