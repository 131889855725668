import React, { useState } from 'react'

import { CommentsTextArea } from 'components/CommentsTextArea'
import { ModalRight } from 'components/ModalRight'
import { ModalDefaultObservations } from 'components/ModalDefaultObservations'

type ObservationProps = {
  valueTextArea: string
  onChangeTextArea: (value: string) => void
}

export function DefaultObservationsArea({
  valueTextArea,
  onChangeTextArea
}: ObservationProps) {
  const [isOpen, setIsOpen] = useState(false)

  function handleToggleOpen() {
    setIsOpen(!isOpen)
  }

  return (
    <>
      <ModalRight
        isOpen={isOpen}
        // eslint-disable-next-line react/jsx-no-bind
        handleToggleOpen={handleToggleOpen}
      >
        <ModalDefaultObservations
          // eslint-disable-next-line react/jsx-no-bind
          handleToggleOpen={handleToggleOpen}
        />
      </ModalRight>

      <CommentsTextArea
        label="Se desejar, adicione observações extras. (opcional)"
        placeholder="Adicione observações sobre o serviço"
        buttonText="Adicionar um texto padrão"
        value={valueTextArea}
        onChange={(e) => onChangeTextArea(e.target.value.trimStart())}
        // eslint-disable-next-line react/jsx-no-bind
        onPressTextPattern={handleToggleOpen}
      />
    </>
  )
}
