import { ChangeEvent, useCallback, useRef, useState } from "react";

import { FiPlus } from "react-icons/fi";
import { useTheme } from "styled-components";

import loadingImg from "assets/loading-indicator.svg";

import { useToast } from "hooks/toast";
import { useAuth } from "hooks/auth";

import { AlertModal } from "components/AlertModal";

import * as S from "./styles";

type FileProps = {
  base64Image: string;
  extension: string;
};

type AddSignaturePhotoProps = {
  type?: "signature" | "photo";
  photosQuantity: number;
  handleSetProviderSignaturePicture?: (url: string) => void;
  handleAddNewPhoto: (photo: FileProps) => void;
  maxPhotos: number;
  loading?: boolean;
};

export function AddSignaturePhoto({
  type,
  photosQuantity,
  maxPhotos,
  handleAddNewPhoto,
  handleSetProviderSignaturePicture,
  loading,
}: AddSignaturePhotoProps) {
  const theme = useTheme();
  const { addToast } = useToast();
  const { user } = useAuth();

  const [isVisibleModal, setIsVisibleModal] = useState(false);

  const hiddenFileInput = useRef<HTMLInputElement | null>(null);
  const photoLimitReached = photosQuantity >= maxPhotos;

  const validationErrorAlert = () => {
    if (photoLimitReached) {
      addToast({
        title: "Máximo permitido",
        description: `Ops, o máximo de fotos permitidos é ${maxPhotos}`,
        type: "error",
      });
      return;
    }
  };

  const handleAddSignature = () => {
    validationErrorAlert();
    if (!photoLimitReached && type === "signature" && user?.digitalSign) {
      setIsVisibleModal(true);
      return;
    }

    hiddenFileInput.current?.click();
  };

  async function confirmDigitalSignatureFromProfile() {
    validationErrorAlert();
    setIsVisibleModal(false);

    if (handleSetProviderSignaturePicture) {
      handleSetProviderSignaturePicture(user?.digitalSign!);
    }

    setIsVisibleModal(false);
  }

  function handleFileInput() {
    setIsVisibleModal(false);
    if (hiddenFileInput.current) hiddenFileInput.current.click();
  }

  const handleAddSignaturePhoto = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      if (e.target.files) {
        const file = e.target.files[0];
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const [_, extension] = file.type.split("/");

        const reader = new FileReader();

        reader.readAsDataURL(file);

        reader.onload = async () => {
          const result = reader.result as string;

          const { 1: fileBase64Splited } = result.split(",");

          handleAddNewPhoto({ base64Image: fileBase64Splited, extension });
        };
      }
    },
    [photosQuantity, addToast] // eslint-disable-line react-hooks/exhaustive-deps
  );

  return (
    <section>
      <AlertModal
        isVisible={isVisibleModal}
        title="Assinatura digital cadastrada"
        description="Você possui uma assinatura cadastrada em seu perfil, deseja anexar ao Recibo?"
        labelConfirm="Sim"
        labelCancel="Não, buscar arquivo"
        action="choose"
        handleConfirm={confirmDigitalSignatureFromProfile}
        handleCancel={handleFileInput}
        onCloseModal={() => setIsVisibleModal(false)}
      />

      <S.Container quantityPhotosValidation={photoLimitReached}>
        {loading ? (
          <img src={loadingImg} alt="carregando..." />
        ) : (
          <>
            <button
              onClick={() => {
                handleAddSignature();
              }}
            >
              <FiPlus size="18" color={theme.colors.primary} />
            </button>

            {photoLimitReached || (
              <input
                id={`attachment-${type}`}
                type="file"
                ref={hiddenFileInput}
                accept="image/*"
                onChange={handleAddSignaturePhoto}
              />
            )}
          </>
        )}
      </S.Container>
    </section>
  );
}
