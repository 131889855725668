import styled, { css } from "styled-components";

export const Container = styled.div`
  width: 52%;
  max-width: 740px;
  height: 100%;
  margin-bottom: 50px;
  padding-left: 30px;
  padding-top: 20px;
`;

export const Header = styled.div`
  ${({ theme }) => css`
    margin-bottom: 30px;

    display: flex;
    align-items: center;
    gap: 10px;

    h4 {
      font-size: ${theme.font.sizes.large};
      color: ${theme.colors.title};
      font-weight: ${theme.font.bold};
    }
  `}
`;

export const CardContent = styled.div`
  ${({ theme }) => css`
    width: 620px;
    padding: 20px 15px;
    border-radius: 10px;
    background-color: ${theme.colors.menu_detail};
  `}
`;

export const Title = styled.h3`
  ${({ theme }) => css`
    font-size: ${theme.font.sizes.large};
    color: ${theme.colors.text_light_87};
    font-weight: ${theme.font.bold};
  `}
`;

export const FooterTitle = styled.h4`
  ${({ theme }) => css`
    font-size: ${theme.font.sizes.small};
    color: ${theme.colors.text_light_87};
    font-weight: ${theme.font.bold};
  `}
`;

export const Text = styled.p`
  ${({ theme }) => css`
    line-height: 18px;
    color: ${theme.colors.text_light_60};
    font-size: ${theme.font.sizes.xsmall};
    margin-bottom: 5px;
  `}
`;

export const PreFormattedText = styled.pre`
   ${({ theme }) => css`
    line-height: 18px;
    white-space: pre-wrap;
    color: ${theme.colors.text_light_60};
    font-size: ${theme.font.sizes.xsmall};
  `}
`;

export const WrapperInfo = styled.div`
  margin-bottom: 35px;
`;

export const WrapperRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const ButtonText = styled.button`
  ${({ theme }) => css`
    font-size: ${theme.font.sizes.xsmall};
    color: ${theme.colors.primary};
    text-decoration: underline;
    cursor: pointer;
  `}
`;

export const ButtonTextBold = styled(ButtonText)`
  ${({ theme }) => css`
    font-weight: ${theme.font.bold};
  `}
`;
