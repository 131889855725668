import styled, { css } from "styled-components";

export const Description = styled.p`
  ${({ theme }) => css`
    font-family: ${theme.font.family};
    font-size: ${theme.font.sizes.xsmall};
    color: ${theme.colors.text_light_60};
    font-weight: ${theme.font.normal};
    line-height: 20px;
    margin-top: 5px;
  `}
`;
