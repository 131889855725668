import React, { createContext, useContext, useState } from "react";
import { ClientProps as ClientDTO } from "dtos/ClientDTO";
import { ServiceByOrderProps } from "dtos/ServiceOrderDTO";
import { ServiceOptionsInputProps } from "dtos/ServiceDTO";
import { ProviderProps } from "hooks";

type ClientProps = Omit<ClientDTO, "email" | "cpf_cnpj" | "initialsLetterName">;

type ReferenceProps = {
  id: string;
  type: "budget" | "service" | "budget-service" | "service-order" | "other";
};

export type ServiceOrderProps = {
  id: number;
  sequenceNumber: number;
  status: "concluded";
  client: ClientProps;
  reference: ReferenceProps;
  services: ServiceByOrderProps[];
  checked: boolean;
};

type SelectedServiceOrderData = {
  selectedServiceOrder: ServiceOrderProps;
  optionsInputService: ServiceOptionsInputProps;
  handleSetOptionsInputService: (
    optionsInput: ServiceOptionsInputProps
  ) => void;
  hasSelectedServiceOrder: boolean;
  handleSetSelectedServiceOrder: (serviceOrder: ServiceOrderProps) => void;
  handleClearSelectedServiceOrder: () => void;
};

const SelectedServiceOrderContext = createContext(
  {} as SelectedServiceOrderData
);

export function useSelectedServiceOrder() {
  const context = useContext(SelectedServiceOrderContext);

  if (!context) {
    throw new Error(
      "useSelectedServiceOrders must be used within an ServiceOrdersProvider"
    );
  }

  return context;
}

export const SelectedServiceOrderProvider = ({ children }: ProviderProps) => {
  const [selectedServiceOrder, setSelectedServiceOrders] = useState(
    {} as ServiceOrderProps
  );

  const [optionsInputService, setOptions] = useState(
    {} as ServiceOptionsInputProps
  );

  const hasSelectedServiceOrder = Object.keys(selectedServiceOrder).length > 0;

  function handleSetOptionsInputService(
    optionsInput: ServiceOptionsInputProps
  ) {
    setOptions(optionsInput);
  }

  function handleSetSelectedServiceOrder(serviceOrder: ServiceOrderProps) {
    setSelectedServiceOrders(serviceOrder);
  }

  function handleClearSelectedServiceOrder() {
    setSelectedServiceOrders({} as ServiceOrderProps);
  }

  return (
    <SelectedServiceOrderContext.Provider
      value={{
        selectedServiceOrder,
        hasSelectedServiceOrder,
        optionsInputService,
        handleSetSelectedServiceOrder,
        handleClearSelectedServiceOrder,
        handleSetOptionsInputService,
      }}
    >
      {children}
    </SelectedServiceOrderContext.Provider>
  );
};
