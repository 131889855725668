import React from "react";
import BlockSvg from "assets/icons/permission/block.svg";

import * as S from "./styles";

type ModalProps = {
  isVisible: boolean;
  setIsActive?: (value: boolean) => void;
};

export function ModalRejectedRegistration({
  isVisible,
  setIsActive,
}: ModalProps) {
  return (
    <>
      {isVisible && (
        <S.ContainerOverlay>
          <S.BoxAlert>
            <img src={BlockSvg} alt="Ícone de cadeado" />
            <h1>Cadastro reprovado!</h1>

            <span>
              Seu cadastro foi reprovado, regularize seu perfil para continuar
              usufruindo das funcionalidades!
            </span>

            <S.WrapperButton
              onClick={() => {
                setIsActive && setIsActive(!isVisible);
              }}
            >
              <button>Mais tarde</button>
            </S.WrapperButton>
          </S.BoxAlert>
        </S.ContainerOverlay>
      )}
    </>
  );
}
