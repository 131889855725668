import { useEffect, useState } from "react";
import { useTheme } from "styled-components";
import { Redirect, useHistory, useLocation, useParams } from "react-router-dom";
import { FiChevronLeft } from "react-icons/fi";
import { format, parseISO } from "date-fns";
import Rating from "react-rating";

import { useToast } from "hooks/toast";
import { useServiceOrder } from "hooks/serviceOrder";
import { useAccount } from "hooks/permission/account";

import { LoadingProfiz } from "components/LoadingProfiz";
import { ContentBox } from "components/ContentBox";
import { ButtonSVG } from "components/ButtonSVG";
import { Button } from "components/Button";
import { ButtonRegistrationDetails } from "components/ButtonRegistrationDetails";
import {
  ModalGalleryRegisterImages,
  StagePhotoData,
} from "components/ModalGalleryRegisterImages";

import emptyStarImg from "assets/icons/empty-star.svg";
import fillStarImg from "assets/icons/fill-star.svg";

import {
  ServiceOrderDetailProps,
  ClientProps,
  ServiceProps,
  LocalChecklistProps,
  MaterialProps,
} from "dtos/ServiceOrderDetailDTO";
import { SignatureProps } from "dtos/ServiceStagesDTO";

import apiv2 from "services/apiv2";

import * as S from "./styles";
import { UnityDTO } from "dtos/ClientDTO";
import { api } from "services/api";
import { useClient } from "hooks/budget/client";
import { AvaliationsResponseDTO } from "dtos/AvaliationsResponseDTO";
import { ShowWhenHavePermission } from "components/Permission";
import { useHasPermission } from "hooks/permission/useHasPermission";
import { RenderLocationAddress } from "components/RedirectLocationAddress";
import { CardServiceFromOS } from "./CardServiceFromOS";
import { ModalRight } from "components/ModalRight";

type ResponseProps = {
  emailCliente: string;
  hashResearch: string;
};

type RouteParams = {
  id: string;
};

export type MappedServiceProps = {
  hour: string[];
} & ServiceProps;

type LocationProps = {
  before?: string;
};

type UnitiesResponse = {
  unities: UnityDTO[];
};

export function ServiceOrderConcludedDetail() {
  const { id } = useParams<RouteParams>();

  const { opportunity_id, handleSetOpportunityId } = useClient();
  const { permission } = useHasPermission({
    actionHash: "view",
    moduleHash: "satisfaction-survey",
  });

  const { whoami } = useAccount();
  const theme = useTheme();
  const history = useHistory();
  const location = useLocation<LocationProps>();
  const { addToast } = useToast();

  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingButton, setIsLoadingButton] = useState(false);
  const [pdfLink, setPdfLink] = useState("");
  const [idSequence, setIdSequence] = useState(0);

  const [clientInServiceOrder, setClientInServiceOrder] = useState(
    {} as ClientProps
  );

  const [unityClient, setUnityClient] = useState({} as UnityDTO);

  const [checklistInServiceOrder, setChecklistInServiceOrder] = useState<
    LocalChecklistProps[]
  >([]);

  const [materialsInServiceOrder, setMaterialsInServiceOrder] = useState<
    MaterialProps[]
  >([]);

  const [observationInServiceOrder, setObservationInServiceOrder] =
    useState("");
  const [formattedCreationDate, setFormattedCreationDate] = useState("");

  const [signaturesInServiceOrder, setSignaturesInServiceOrder] = useState<
    SignatureProps[]
  >([]);
  const [stagePhotoData, setStagePhotoData] = useState({} as StagePhotoData);
  const [isVisibleModal, setIsVisibleModal] = useState(false);
  const [rating, setRating] = useState({} as AvaliationsResponseDTO);
  const [isRedirect, setIsRedirect] = useState(false);
  const [origin, setOrigin] = useState("" as "web" | "mobile" | "mse");

  const { selectedServiceOrder, handleSetSelectedServiceOrder } =
    useServiceOrder();

  const [isShowCase, setIsShowCase] = useState(false);

  async function loadUnityData(clientResponse: ClientProps) {
    try {
      if (clientResponse?.address && clientResponse?.address?.id) {
        clientResponse.address &&
          setUnityClient(clientResponse?.address as UnityDTO);
        return;
      }

      const response = await api.get<UnitiesResponse>(
        `/clients/${clientResponse.id}/unities`,
        {
          params: {
            limit: 50,
            offset: 0,
            client_id: clientResponse.id,
          },
        }
      );

      const defaultUnity = response.data.unities.find((unity) => unity.default);

      defaultUnity && setUnityClient(defaultUnity);
    } catch {
      addToast({
        title: "Ops!",
        description:
          "Ocorreu um erro ao carregar o endereço do cliente, tente novamente.",
        type: "error",
      });
    }
  }
  const [isCopied, setIsCopied] = useState(false);

  useEffect(() => {
    async function getServiceOrderDetails() {
      try {
        const { data } = await apiv2.get(`/budgets/service-order/${id}`, {
          params: {
            accountId: whoami?.id,
          },
        });

        const {
          sequenceNumber,
          detailLink,
          createdAt,
          client,
          localChecklists,
          avaliation,
          materials,
          observations,
          signatures,
          isShowcase,
          origin,
        }: ServiceOrderDetailProps = data;

        setOrigin(origin);
        setIsShowCase(isShowcase);
        setIdSequence(sequenceNumber);
        setSignaturesInServiceOrder(signatures);
        setPdfLink(detailLink);
        const formattedDate = format(new Date(createdAt), "dd/MM/yyyy");
        setFormattedCreationDate(formattedDate);
        setRating(avaliation);

        setClientInServiceOrder(client);
        await loadUnityData(client);
        setChecklistInServiceOrder(localChecklists);
        setMaterialsInServiceOrder(materials);
        setObservationInServiceOrder(observations);

        handleSetSelectedServiceOrder(data);
      } catch (error) {
        addToast({
          type: "error",
          title: "Ops...",
          description: "Erro ao buscar detalhes da ordem de serviço",
        });
      } finally {
        setIsLoading(false);
      }
    }

    getServiceOrderDetails();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  function handleGoBack() {
    if (location.state?.before === "RegisterSignatures") {
      history.goBack();
      return;
    }

    history.push("/service-order", {
      before: "ServiceOrderConcludedDetail",
    });
  }

  useEffect(() => {
    return history.listen(() => {
      handleSetOpportunityId(0);
    });
  }, [handleSetOpportunityId, history]);

  async function handleConcludeServiceOrder() {
    try {
      setIsLoadingButton(true);
      const { 3: serviceOrderIdFromUrl } = window.location.pathname.split("/");

      const { data } = await apiv2.put<ResponseProps>(
        `budgets/service-order/${serviceOrderIdFromUrl}`,
        {
          originUpdate: "web",
        }
      );

      addToast({
        title: "Sucesso",
        description: "Ordem de serviço encerrada com sucesso!",
        type: "success",
      });

      window.history.replaceState("/", "", "/");

      if (permission && origin !== "mse") {
        history.push({
          pathname: `/satisfaction-survey/${serviceOrderIdFromUrl}`,
          state: { ...data },
        });
      } else if (origin === "mse") {
        history.replace("/service-order");
      } else {
        history.replace("/home");
      }
    } catch (error) {
      addToast({
        title: "Ops!",
        description: "Falha ao encerrar a ordem de serviço",
        type: "error",
      });
    } finally {
      setIsLoadingButton(false);
    }
  }

  function handleToggleOpen() {
    setIsVisibleModal((prev) => !prev);
  }

  function handleOpenGallerySignaturesIntoOS(signatures: SignatureProps[]) {
    handleToggleOpen();
    setStagePhotoData({ key: "", signatures });
  }

  function handleNavigateToRating() {
    setIsRedirect(true);
  }

  async function handleSendMessage() {
    try {
      setIsLoadingButton(true);
      const response = await api.get(`/opportunities/${opportunity_id}`);

      const clientFirstName = response.data.name.split(" ")[0];
      const serviceCreationDate = format(
        parseISO(response.data.createdAt),
        "dd/MM/yyyy"
      );

      const defaultMessage = `Olá ${clientFirstName}, tudo bem? O serviço executado em ${serviceCreationDate} completou 12 meses. Tudo certo com o seu equipamento? Podemos agendar uma visita, manutenção ou limpeza?`;

      navigator.clipboard.writeText(defaultMessage);

      setIsCopied(true);
    } catch (error) {
      addToast({
        title: "Ops",
        description: "Não foi possível obter a mensagem padrão",
        type: "error",
      });
    } finally {
      addToast({
        title: "Sucesso",
        description: "Mensagem copiada!",
        type: "success",
      });
      setIsLoadingButton(false);
    }
  }

  return (
    <>
      {isLoading ? (
        <LoadingProfiz isVisible={isLoading} />
      ) : (
        <S.Container>
          <ModalRight
            isOpen={isVisibleModal}
            handleToggleOpen={handleToggleOpen}
          >
            <ModalGalleryRegisterImages
              stagePhotoData={stagePhotoData}
              handleToggleModal={handleToggleOpen}
            />
          </ModalRight>

          <S.Top>
            <S.ButtonClose onClick={handleGoBack}>
              <FiChevronLeft size={10} color={theme.colors.title} />
            </S.ButtonClose>

            <h4>Ordem de Serviço {idSequence}</h4>
          </S.Top>

          {location.state?.before !== "RegisterSignatures" && (
            <S.WrapperInfoHeader style={{ flexDirection: "row" }}>
              <Rating
                initialRating={rating.stars}
                readonly
                emptySymbol={<S.Star src={emptyStarImg} />}
                fullSymbol={<S.Star src={fillStarImg} />}
              />

              {!!rating.stars ? (
                <S.ButtonText onClick={handleNavigateToRating}>
                  Ver pesquisa de satisfação
                </S.ButtonText>
              ) : (
                <span style={{ marginLeft: "12px" }}>
                  Esta OS não possui avaliação
                </span>
              )}
            </S.WrapperInfoHeader>
          )}

          <S.WrapperInfoHeader>
            <span>Data de Criação: {formattedCreationDate}</span>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                flexDirection: "row",
                marginTop: 10,
              }}
            >
              <span>Status</span>

              <div style={{ marginLeft: "auto" }} />
              <span>Concluída</span>
            </div>
          </S.WrapperInfoHeader>

          <S.Content style={{ marginBottom: 10 }}>
            <div>
              <span>Cliente:</span>

              <ContentBox>
                <S.ContentBoxTitle>
                  {clientInServiceOrder.name}
                </S.ContentBoxTitle>
                <S.ContentBoxText>
                  {clientInServiceOrder.cpf_cnpj}
                </S.ContentBoxText>
                <S.ContentBoxText>
                  {clientInServiceOrder.phone}
                </S.ContentBoxText>
                <S.ContentBoxText>
                  {clientInServiceOrder.email}
                </S.ContentBoxText>
              </ContentBox>
            </div>

            <div>
              <ContentBox title="Endereço">
                {!unityClient.uf ? (
                  <S.ContentBoxText>Sem endereço cadastrado.</S.ContentBoxText>
                ) : (
                  <>
                    <S.ContentBoxTitle>
                      {unityClient.name}{" "}
                      {unityClient.default ? "(principal)" : "(adicional)"}
                    </S.ContentBoxTitle>

                    <RenderLocationAddress
                      city={unityClient?.city}
                      complement={unityClient?.complement}
                      district={unityClient?.district}
                      number={Number(unityClient?.number)}
                      postalCode={unityClient?.postalCode}
                      street={unityClient?.street}
                      uf={unityClient?.uf}
                    />
                  </>
                )}
              </ContentBox>
            </div>
            <div>
              <span>Serviço(s):</span>

              {selectedServiceOrder.services.map((serviceData) => (
                <div
                  key={serviceData.service.service.id}
                  style={{ marginBottom: 20 }}
                >
                  <ContentBox>
                    <CardServiceFromOS data={serviceData} />
                  </ContentBox>
                </div>
              ))}
            </div>

            {selectedServiceOrder.localChecklists.length > 0 && (
              <div>
                <span>Itens adicionais:</span>

                <ContentBox>
                  {checklistInServiceOrder.map((checklist) => {
                    const {
                      checklist: checklistData,
                      quantity,
                      type,
                    } = checklist;

                    const status = checklistData?.status.find(
                      (sts) => sts.type === type
                    );

                    return (
                      <div key={checklistData.id}>
                        <S.ContentBoxText>
                          <span style={{ color: theme.colors.title }}>
                            {checklistData?.name}:
                          </span>{" "}
                          &nbsp;
                          {status?.label} &nbsp;
                          {type === "my_responsibility" && (
                            <span>({quantity})</span>
                          )}
                        </S.ContentBoxText>
                      </div>
                    );
                  })}
                </ContentBox>
              </div>
            )}

            {materialsInServiceOrder.length > 0 && (
              <div>
                <span>Materiais:</span>

                <ContentBox>
                  {materialsInServiceOrder.map((material, index) => {
                    const { material: materialData, quantity } = material;

                    return (
                      <>
                        <div key={materialData.id}>
                          <S.ContentBoxText>
                            {materialData.item.name} &nbsp; ({quantity})
                          </S.ContentBoxText>
                          <S.ContentBoxText>
                            <span style={{ color: theme.colors.title }}>
                              Tipo:
                            </span>{" "}
                            {materialData.type.name} &nbsp;
                          </S.ContentBoxText>
                        </div>
                        {index !== materialsInServiceOrder.length - 1 && (
                          <div style={{ padding: "5px 0" }} />
                        )}
                      </>
                    );
                  })}
                </ContentBox>
              </div>
            )}

            {!!observationInServiceOrder && (
              <div>
                <span>Observações:</span>

                <ContentBox>
                  <S.ContentBoxObservationText>
                    {observationInServiceOrder}
                  </S.ContentBoxObservationText>
                </ContentBox>
              </div>
            )}

            {!selectedServiceOrder.isNewSignVersion && (
              <S.WrapperSignature>
                <ButtonRegistrationDetails
                  title="Assinaturas"
                  amount={signaturesInServiceOrder?.length || 0}
                  isDisabled={
                    !signaturesInServiceOrder?.length ||
                    signaturesInServiceOrder?.length === 0
                  }
                  onViewRegistration={() =>
                    handleOpenGallerySignaturesIntoOS(signaturesInServiceOrder)
                  }
                />
              </S.WrapperSignature>
            )}

            <S.WrapperButton>
              {location.state?.before === "RegisterSignatures" && (
                <ShowWhenHavePermission
                  moduleHash="budgets-service-order"
                  actionHash="edit-finish"
                >
                  <Button
                    loading={isLoadingButton}
                    onClick={() => handleConcludeServiceOrder()}
                  >
                    Encerrar ordem de serviço
                  </Button>
                </ShowWhenHavePermission>
              )}
            </S.WrapperButton>
          </S.Content>
          <S.ContentBoxTitle style={{ marginTop: 5, marginBottom: 5 }}>
            Envie o resumo das informações ao cliente
          </S.ContentBoxTitle>

          <S.Footer>
            <S.LinkDownloadPdf href={pdfLink} target="_blank" rel="noreferrer">
              <ButtonSVG title="Download PDF" typeSvg="pdf" />
            </S.LinkDownloadPdf>
          </S.Footer>
          {isRedirect && <Redirect to={`/ratings/${rating.id}`} push />}

          {opportunity_id !== 0 && !isShowCase && (
            <Button
              style={{ marginTop: 12 }}
              loading={isLoadingButton}
              onClick={() => handleSendMessage()}
            >
              {isCopied ? "Mesagem copiada!" : "Copie a mensagem aqui!"}
            </Button>
          )}
        </S.Container>
      )}
    </>
  );
}
