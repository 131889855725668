import styled, { css } from "styled-components";

type TypeButton = {
  type?: boolean;
};

export const Container = styled.div`
  margin-bottom: 30px;
`;

export const Title = styled.p`
  ${({ theme }) => css`
    font-size: 12px;
    font-family: ${theme.font.normal};
    color: ${theme.colors.text_extra_light};
    letter-spacing: 0.26px;
    line-height: 16px;
    margin-bottom: 10px;
  `}
`;

export const Content = styled.div`
  display: flex;
  flex-direction: row;
`;

export const Wrapper = styled.div<TypeButton>`
  ${({ type }) => css`
    width: ${type ? "140px" : "120px"};
    margin-right: 5px;
    justify-content: center;
  `}
`;
