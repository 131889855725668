import styled, { css } from "styled-components";

type MenuProps = {
  isOpenMenu: boolean;
};

export const Container = styled.div<MenuProps>`
  ${({ theme, isOpenMenu }) => css`
    min-width: ${isOpenMenu ? 290 : 90}px;
    height: 100vh;
    overflow: auto;
    background: ${theme.colors.financial_card};
    transition: min-width 0.3s;
    padding-left: ${isOpenMenu ? 15 : 10}px;
    padding-right: ${isOpenMenu ? 15 : 10}px;

    display: flex;
    flex-direction: column;
    justify-content: space-between;

    ::-webkit-scrollbar {
      display: none;
    }
  `}
`;

export const WrapperRow = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  margin-top: 30px;
`;

export const Content = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
`;

export const ButtonToggleMenu = styled.button<MenuProps>`
  ${({ theme, isOpenMenu }) => css`
    width: 25px;
    height: 25px;
    top: 9px;
    left: ${isOpenMenu ? 277.5 : 77.5}px;
    background-color: ${theme.colors.menu_detail_light};
    border-radius: 8px;
    position: absolute;

    img {
      transform: ${isOpenMenu ? "rotate(0)" : "rotate(180deg)"};
    }
  `}
`;

export const LogoContainer = styled.button`
  padding: 20px;

  img {
    width: 35px;
    height: 35px;
  }
`;

export const ProfileInfo = styled.div`
  ${({ theme }) => css`
    background-color: ${theme.colors.menu_detail};
    border-radius: 10px;
    padding: 10px;
    display: flex;
    align-items: center;
    margin-bottom: 5px;
  `}
`;

export const UserPhoto = styled.div`
  img {
    width: 50px;
    height: 50px;
    border-radius: 25px;
  }
`;

export const WrapperProfileDetail = styled.div`
  flex: 1;
  display: flex;
  justify-content: space-between;
`;

export const ProfileDetail = styled.div`
  margin-left: 10px;
  display: flex;
  flex-direction: column;
  max-width: 120px;
`;

export const WelcomeText = styled.p`
  ${({ theme }) => css`
    font-family: ${theme.font.family};
    font-size: ${theme.font.sizes.xsmall};
    font-weight: ${theme.font.normal};
    color: ${theme.colors.financial_text};
    line-height: 16px;
    letter-spacing: 0.26px;
  `}
`;

export const UserName = styled.p`
  ${({ theme }) => css`
    font-family: ${theme.font.family};
    font-size: ${theme.font.sizes.small};
    font-weight: ${theme.font.bold};
    color: ${theme.colors.primary};
    line-height: 15px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  `}
`;

export const WrapperViewProfile = styled.button`
  display: flex;
  flex-direction: row;
  margin-top: 3px;
  cursor: pointer;
  text-decoration: none;
  align-items: center;
`;

export const ViewProfileText = styled.p`
  ${({ theme }) => css`
    font-family: ${theme.font.family};
    font-size: ${theme.font.sizes.xsmall};
    font-weight: ${theme.font.normal};
    color: ${theme.colors.text_light_87};
    text-decoration: underline;
    line-height: 16px;
    letter-spacing: 0.24px;
    margin-right: 5px;
  `}
`;

export const MenuContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 14px;
`;

export const ExitButton = styled.button<MenuProps>`
  ${({ theme, isOpenMenu }) => css`
    width: 100%;
    height: 40px;
    border-radius: 10px;
    background-color: ${theme.colors.menu_detail};
    margin-top: 15px;
    margin-bottom: 20px;
    padding: 15px;
    cursor: pointer;
    display: flex;
    justify-content: ${isOpenMenu ? "flex-start" : "center"};
    align-items: center;

    p {
      font-family: ${theme.font.family};
      font-size: ${theme.font.sizes.xsmall};
      font-weight: ${theme.font.normal};
      color: ${theme.colors.financial_text};
      line-height: 16px;
      letter-spacing: 0.26px;
    }
  `}
`;
