import styled, { css } from "styled-components";
import { lighten } from 'polished'

export const Container = styled.div`
  ${({ theme }) => css`
    margin-top: 20px;
    margin-bottom: 30px;
    width: 100%;
  `}
`;


export const TitleEnvironment = styled.p`
  ${({ theme }) => css`
    font-size: 12px;
    color: rgba(255, 255, 255, 0.87);
    font-weight: ${theme.font.normal};
    margin-bottom: 15px;
    margin-top:20px;
  `}
`;

export const EquipmentProps = styled.div`
  ${({ theme }) => css`
      .title{
        font-size: 12px;
        color: rgba(255, 255, 255, 0.87);
        font-weight: ${theme.font.normal};
        margin-bottom: 15px;
      }
  `}
`;

export const OSCard = styled.div`
    ${({ theme }) => css`
      background: ${theme.colors.background_modal};
      border-radius: 8px;
      padding:15px;
      margin-bottom:5px;

      display:flex;
      flex-direction:row;
      justify-content:space-between;
      cursor:pointer;

      &:hover {
        background: ${lighten(0.05, theme.colors.background_modal)};
      }

      .text-container{
        .title{
            font-size: 12px;
            color: rgba(255, 255, 255, 0.87);
            font-weight: ${theme.font.normal};
            margin-bottom: 10px;
          }
        .text-info{
          font-size: 12px;
          color: rgba(255, 255, 255, 0.6);
          font-weight: ${theme.font.normal};
          margin-top: 10px;
        }
      }

    .container-ball{
      display:flex;
      flex-direction:row;

      .element-ball{
        height:8px;
        width:8px;
        border-radius: 8px;
        margin-right: 5px;
        margin-top:3px;
      }
      .text-status{
        font-size: 12px;
        color: rgba(255, 255, 255, 0.6);
        font-weight: ${theme.font.normal};
      }
    }

  `}
`;

