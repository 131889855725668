import styled, { css } from "styled-components";

export const ContainerService = styled.div``;

export const ServiceName = styled.p`
  ${({ theme }) => css`
    font-family: ${theme.font.bold};
    font-size: 16px;
    letter-spacing: 0.4px;
    line-height: 20px;
    color: ${theme.colors.title};
    margin-top: 20px;
  `}
`;

export const TitlePhotos = styled.p`
  ${({ theme }) => css`
    color: ${theme.colors.text};
    font-size: ${theme.font.sizes.small};
    margin-bottom: 20px;
  `}
`;

export const WrapperPhotos = styled.div`
  display: flex;
`;

export const WrapperPhoto = styled.div`
  margin-right: 15px;
`;

export const Wrapper = styled.div`
  margin-top: 15px;
`;
