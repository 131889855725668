export type AddressProps = {
  postalCode?: string;
  street?: string;
  number?: number;
  district?: string;
  city?: string;
  uf?: string;
  complement?: string;
};

export default function formatAddress(address: AddressProps) {
  const streetNumberFormatted = address?.street
    ? address?.number
      ? `${address.street}, ${address.number}`
      : address.street
    : "";

  const cityUfFormatted = address?.city
    ? address?.uf
      ? `${address.city} - ${address.uf}`
      : address.city
    : "";

  const postalCodeFormatted = address?.postalCode
    ? `CEP: ${address.postalCode}`
    : "";

  const addressFormatted = address?.district
    ? `${streetNumberFormatted} - ${address?.district} - ${cityUfFormatted} ${postalCodeFormatted}`
    : `${streetNumberFormatted} ${cityUfFormatted} ${postalCodeFormatted}`;

  return addressFormatted;
}
