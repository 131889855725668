import styled, { css } from 'styled-components'

export const Container = styled.div`
  margin-bottom: 14px;
`

export const WrapperMyResponsibilityText = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 6px;
`

export const PrimaryText = styled.span`
  ${({ theme }) => css`
    font-size: ${theme.font.sizes.xsmall};
    color: ${theme.colors.title};
  `}
`

export const SecondaryText = styled.span`
  ${({ theme }) => css`
    font-size: ${theme.font.sizes.xsmall};
    color: ${theme.colors.text};
  `}
`
