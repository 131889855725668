import React, { HTMLAttributes, useEffect, useState } from "react";
import NumberFormat from "react-number-format";

import { Button } from "components/Button";
import { LocalChecklistProps, StatusProps } from "dtos/LocalChecklistDTO";
import { sortStatusLocalChecklist } from "utils/sortStatusLocalChecklist";
import { useToast } from "hooks/toast";
import { useSelectedChecklist } from "hooks/budget/selectedLocalChecklist";
import { api } from "services/api";

import PencilSvg from "assets/icons/pencil.svg";
import CheckSvg from "assets/icons/check.svg";

import * as S from "./styles";

type CardLocalChecklistProps = HTMLAttributes<HTMLButtonElement> & {
  checklist: LocalChecklistProps;
  handleSelectChecklist: (checklist: LocalChecklistProps) => void;
  handleRemoveSelectLocalChecklist: (checkListId: number) => void;
  handleUpdatePriceLocalChecklist: (checklistId: number, price: number) => void;
};

export function CardLocalChecklist({
  checklist,
  handleSelectChecklist,
  handleRemoveSelectLocalChecklist,
  handleUpdatePriceLocalChecklist,
  ...rest
}: CardLocalChecklistProps) {
  const { addToast } = useToast();
  const { selectedChecklistItems, handleSetSelectedChecklist } = useSelectedChecklist();

  const [inputPrice, setInputPrice] = useState(checklist.value);
  const [unmaskedPrice, setUnmaskedPrice] = useState<number>();
  const [showInput, setShowInput] = useState(false);

  const [isChecked, setIsChecked] = useState(false);
  const [currentChecklistStatus, setCurrentChecklistStatus] = useState("");

  const [editingValue, setEditingValue] = useState(false);

  const orderedStatus = sortStatusLocalChecklist(checklist.status);

  useEffect(() => {
    if (selectedChecklistItems) {
      const findChecklistSelected = selectedChecklistItems.find(
        (currentChecklist) => currentChecklist.id === checklist.id
      );

      if (findChecklistSelected) {
        const formattedChecklist = {
          ...findChecklistSelected,
          isChecked: true,
        };

        setIsChecked(true);
        handleSelectChecklist(formattedChecklist);
        setCurrentChecklistStatus(findChecklistSelected.type);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checklist.id, selectedChecklistItems]);

  function handleSelectStateOfChecklist(checklistStatus: StatusProps) {
    setCurrentChecklistStatus(checklistStatus.type);
    setShowInput(checklistStatus.type === "my_responsibility");

    const parsedChecklist = {
      ...checklist,
      status: checklist.status,
      type: checklistStatus.type,
      quantity: 1,
      isChecked,
    };

    handleSelectChecklist(parsedChecklist);
  }

  function handleSelectCheckbox() {
    setIsChecked(!isChecked);

    const parsedChecklist = {
      ...checklist,
      type: currentChecklistStatus || "",
      quantity: 1,
      isChecked: !isChecked,
    };

    handleSelectChecklist(parsedChecklist);

    if (isChecked) {
      setCurrentChecklistStatus("");
      handleRemoveSelectLocalChecklist(checklist.id);
    }
  }

  async function handleEditingInputPrice() {
    try {
      if (checklist.value === Number(inputPrice)) {
        return;
      }

      const checklistObj = {
        name: checklist.name,
        price: unmaskedPrice,
      };

      await api.put(`/checklist/${checklist.id}`, checklistObj);

      handleUpdatePriceLocalChecklist(checklist.id, unmaskedPrice!);

      const newChecklists: LocalChecklistProps[] = [];

      selectedChecklistItems.forEach((item) => {
        if(item.id === checklist.id) {
          if(item.value && unmaskedPrice) {
            item.value = unmaskedPrice;
            item.total = unmaskedPrice * item.quantity
            item.formattedTotal = (unmaskedPrice * item.quantity).toLocaleString('pt-BR',{ style: 'currency', currency: 'BRL' });
            newChecklists.push(item);
          }
        }
      })

      handleSetSelectedChecklist(newChecklists);

      addToast({
        title: "Tudo certo!",
        description: "Preço atualizado com sucesso.",
        type: "success",
      });
    } catch (error) {
      // sendError(error);
      addToast({
        title: "Ops!",
        description: "Erro ao atualizar o preço. Tente novamente.",
        type: "error",
      });
    } finally {
      setEditingValue(false);
    }
  }

  return (
    <S.Container isSelected={isChecked}>
      <S.Header onClick={() => handleSelectCheckbox()} {...rest}>
        <S.WrapperCheckbox>
          <S.CheckBox hasError={!isChecked && !!currentChecklistStatus}>
            <S.CheckBoxContent isSelected={isChecked} />
          </S.CheckBox>
          <S.ItemChecklistName>{checklist.name}</S.ItemChecklistName>
        </S.WrapperCheckbox>

        <S.Label>Selecione um status:</S.Label>
      </S.Header>

      <S.Content>
        {orderedStatus.map((status: any) => (
          <S.WrapperButton key={status.type}>
            <Button
              typeButton={
                currentChecklistStatus === status.type
                  ? "default"
                  : "unselected"
              }
              onClick={() => handleSelectStateOfChecklist(status)}
            >
              {status.label}
            </Button>
          </S.WrapperButton>
        ))}

        {isChecked && !currentChecklistStatus && (
          <S.InformativeText>
            Selecione um status para o item.
          </S.InformativeText>
        )}
      </S.Content>

      {(showInput || currentChecklistStatus === "my_responsibility") && (
        <S.Footer>
          <S.Label>Defina o valor do item: </S.Label>

          <S.InputContainer style={{ border: !editingValue ? "none" : "" }}>
            <NumberFormat
              disabled={!editingValue}
              value={inputPrice}
              prefix="R$ "
              decimalSeparator=","
              thousandSeparator="."
              decimalScale={2}
              fixedDecimalScale
              onValueChange={({ floatValue }) => {
                setInputPrice(floatValue!);
                setUnmaskedPrice(floatValue);
              }}
              style={{
                width: "80%",
                height: "90%",
                border: "none",
                background: "transparent",
                outline: "none",
                paddingLeft: "10px",
                color: "#fff",
              }}
            />
            {!editingValue ? (
              <S.ButtonEditPrice onClick={() => setEditingValue(true)}>
                <img src={PencilSvg} alt="editar preço do checklist" />
              </S.ButtonEditPrice>
            ) : (
              <S.ButtonEditPrice onClick={handleEditingInputPrice}>
                <img
                  style={{ width: 30, height: 30 }}
                  src={CheckSvg}
                  alt="editar preço do checklist"
                />
              </S.ButtonEditPrice>
            )}
          </S.InputContainer>
        </S.Footer>
      )}
    </S.Container>
  );
}
