import styled, { css } from "styled-components";

export const Container = styled.div`
  ${({ theme }) => css`
    max-width: 1400px;
    width: 600px;
    padding: 30px;

    header {
      display: flex;
      align-items: center;
    }
  `}
`;

export const Wrapper = styled.div`
  margin-right: 30px;
`;

export const Content = styled.div`
  margin-top: 25px;
`;

export const Title = styled.h1`
  ${({ theme }) => css`
    font-family: ${theme.font.family};
    font-size: ${theme.font.sizes.large};
    color: ${theme.colors.title};
    font-weight: ${theme.font.bold};
  `}
`;

export const Label = styled.p`
  ${({ theme }) => css`
    font-family: ${theme.font.family};
    font-size: ${theme.font.sizes.xsmall};
    color: ${theme.colors.text_light_60};
    font-weight: ${theme.font.normal};
    line-height: 20px;
    margin-top: 5px;
  `}
`;
