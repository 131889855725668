import styled, { css } from "styled-components";

export const Container = styled.div`
  ${({ theme }) => css`
    width: 100%;
    margin-top: 20px;

    header {
      display: flex;
      align-items: center;
    }

    h1 {
      font-family: ${theme.font.family};
      font-size: ${theme.font.sizes.large};
      color: ${theme.colors.title};
      font-weight: ${theme.font.bold};
    }
  `}
`;

export const Content = styled.div`
  margin-top: 10px;
`;

export const Label = styled.p`
  ${({ theme }) => css`
    font-family: ${theme.font.family};
    font-size: ${theme.font.sizes.xsmall};
    color: ${theme.colors.text_light_60};
    font-weight: ${theme.font.normal};
    line-height: 20px;
  `}
`;

export const Wrapper = styled.div`
  margin-right: 30px;
`;
