import React from "react";

import logoProfiz from "assets/icons/logo-profiz.svg";

import * as S from "./styles";

type Props = {
  isVisible: boolean;
  // posicionamento horizontal do logo para páginas que não possuem o menu lateral
  positionLogo?: "40%" | "30%";
};

export function LoadingProfiz({ isVisible, positionLogo = "30%" }: Props) {
  return (
    <S.ContainerOverlay isVisible={isVisible} positionLogo={positionLogo}>
      <img src={logoProfiz} alt="logo animado da profiz" />
    </S.ContainerOverlay>
  );
}
