import { useClient } from "hooks/budget/client";
import { ExecutionListProps } from "dtos/PmocDetailOfMonthDTO";
import * as S from './styles';
import theme from "styles/theme";

type OSListComponentProps = {
  osList: ExecutionListProps[];
  statusOS: string;
  handleRoutePage?: (idOS:number, idExecution?:number, persistenceId?:number) => void;
}

export type OptionStatusProps = {
  [key: string]: {
    label: string;
    color: string;
  };
};

export function orderServiceStatusFactory(status:string) {
  const dictOS:OptionStatusProps = {
    pending: {
      label: 'Não agendado',
      color: theme.colors.error,
    },
    unscheduled: {
      label: 'Não agendado',
      color: theme.colors.error,
    },
    scheduled: {
      label: 'Agendado',
      color: theme.colors.model,
    },
    concluded: {
      label: 'Concluído',
      color: theme.colors.primary,
    },
    archived: {
      label: 'Arquivado',
      color: theme.colors.text,
    }
  }
  return dictOS[status.toLowerCase()];
}

export function OSListComponent({ osList, statusOS, handleRoutePage }: OSListComponentProps) {
  const { client } = useClient();
  const { label: statusLabel, color: statusColor } = orderServiceStatusFactory(statusOS);

  return (
    <S.Container>
      {osList.map((os, index) => {
        return (
        <div key={index}>
          <S.TitleEnvironment>
              {os.environmentName}
          </S.TitleEnvironment>
          {os.equipments.map(eqto => {
            return(<S.EquipmentProps key={eqto.equipmentId}>
              <p className="title" >
                {eqto.equipmentTag}
              </p>
              {eqto.servicesExecutions.map((executions, index) => {
                return (
                  <S.OSCard
                    key={index}
                    onClick={()=> handleRoutePage && handleRoutePage(executions.idOrderService, executions.id_exec, executions.servicePersistencePmocId)}
                  >
                    <div className="text-container">
                      <p className="title"><strong>{executions.serviceType}</strong></p>
                      <p className="text-info">Cliente: {client.name} </p>
                      <p className="text-info">Periodicidade: {executions.frequencyService}</p>
                    </div>

                    <div className="container-ball">
                      <div className="element-ball" style={{backgroundColor: statusColor}}/>
                      <p className="text-status">{statusLabel}</p>
                    </div>
                  </S.OSCard>
                )
              })}
            </S.EquipmentProps>)
          })}
        </div>
        )
      })}
    </S.Container>
  );
}
