import { ArrowButton } from "components/ArrowButton";
import { Button } from "components/Button";
import { Dropdown, DropdownOption } from "components/DropDown";
import { Input } from "components/Input";
import { useServiceChecklist } from "hooks/serviceChecklist";
import { useToast } from "hooks/toast";
import React, { useEffect, useState } from "react";

import getValidationErrors from "utils/getValidationErrors";

import * as Yup from "yup";

import * as S from "./styles";
import { ChecklistDetail, RegisterItem } from "../types";
import { api } from "services/api";
import { NewAlertModal } from "components/NewAlertModal";
import { useTheme } from "styled-components";
import axios from "axios";
import { LoadingProfiz } from "components/LoadingProfiz";
import { isEqual } from "lodash";
import { DiscardInformationModal } from "components/DiscardInformationModal";
import { EnvironmentPageProps, Page } from "pages/pmoc/EnvironmentFlow/types";
import { useEnvironment } from "hooks/pmoc/environmentPmoc";
import {
  Label,
  SelectCurrentAccountForCreateNewItem,
} from "components/Permission";
import { useAccount } from "hooks/permission/account";

type DetailRegisterProps = {
  handlePageClose?: () => void;
  isTabRegister?: boolean;
  notSteps?: boolean;
  handleChooseEnvironment?: EnvironmentPageProps;
};

type DictOption = {
  [key: string]: DropdownOption;
};

type Errors = {
  [key: string]: string;
};

export function DetailRegisterPage({
  handlePageClose,
  handleChooseEnvironment,
}: DetailRegisterProps) {
  const {
    isNewChecklist,
    detailRegister,
    detailChecklist,
    newItemChecklist,
    handleSetDetailRegister,
    handleSetChecklistDetail,
    handleSetActualRegister,
    handleSetBackupRegister,
    actualRegister,
    backupRegister,
  } = useServiceChecklist();

  const { popAndDeleteStackNavigation } = useEnvironment();

  const [showCompareAlert, setShowCompareAlert] = useState(false);
  const [alertModal, setAlertModal] = useState(false);
  const theme = useTheme();
  const { addToast } = useToast();
  const { whoami } = useAccount();

  const optionsType = [
    { id: 1, name: "Fotografar" },
    { id: 2, name: "Executar" },
    { id: 3, name: "Medir" },
    { id: 4, name: "Verificar" },
  ];

  const opt: DictOption = {
    fotografar: { id: 1, name: "Fotografar" },
    executar: { id: 2, name: "Executar" },
    medir: { id: 3, name: "Medir" },
    verificar: { id: 4, name: "Verificar" },
  };

  const [typeRegister, setTypeRegister] = useState<DropdownOption>(
    detailRegister &&
      detailRegister.registerDetail &&
      detailRegister.registerDetail.type
      ? opt[detailRegister.registerDetail.type]
      : optionsType[0]
  );

  const [nameRegister, setNameRegister] = useState(
    detailRegister &&
      detailRegister.registerDetail &&
      detailRegister.registerDetail.name
      ? detailRegister.registerDetail.name
      : ""
  );
  const [detailInfo, setDetailInfo] = useState(
    detailRegister &&
      detailRegister.registerDetail &&
      detailRegister.registerDetail.description
      ? detailRegister.registerDetail.description
      : ""
  );

  const [hasError, setHasError] = useState<Errors>({} as Errors);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    handleSetActualRegister({
      type: typeRegister?.name.toLowerCase() || "fotografar",
      name: nameRegister.trim(),
      description: detailInfo.trim(),
    });
    handleSetBackupRegister({
      type: typeRegister?.name.toLowerCase() || "fotografar",
      name: nameRegister.trim(),
      description: detailInfo.trim(),
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    handleSetBackupRegister({
      type: typeRegister?.name.toLowerCase() || "fotografar",
      name: nameRegister.trim(),
      description: detailInfo.trim(),
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [typeRegister, nameRegister, detailInfo]);

  function handleSetSelectType({ id, name }: DropdownOption) {
    setTypeRegister({ id, name });
  }

  async function addRegister() {
    let newRegister = {
      type: typeRegister?.name.toLowerCase(),
      name: nameRegister,
      description: detailInfo,
    };

    let aux = JSON.parse(
      JSON.stringify(isNewChecklist ? newItemChecklist : detailChecklist)
    );

    aux.registers = [newRegister];

    await createNewChecklistRegister(aux);
  }

  async function createNewChecklistRegister(detail: ChecklistDetail) {
    try {
      setIsLoading(true);
      await api.post("/service/checklist/step", {
        ...detail.registers[0],
        accountId: whoami?.id,
      });
    } catch (error) {
      addToast({
        title: "Ops!!",
        description:
          axios.isAxiosError(error) && error.response?.data.error
            ? error.response.data.error
            : "Não foi possível criar o registro.",
        type: "error",
      });
    } finally {
      setIsLoading(false);
    }
  }

  async function editRegister() {
    try {
      setIsLoading(true);
      const { data } = await api.put(
        `/service/checklist/step/${detailRegister.registerDetail.id}`,
        {
          type: typeRegister?.name.toLowerCase() || "fotografar",
          name: nameRegister.trim(),
          description: detailInfo.trim(),
          accountId: whoami?.id,
        }
      );

      const listRegister = JSON.parse(
        JSON.stringify(
          detailChecklist?.registers ? detailChecklist?.registers : []
        )
      ).map((register: RegisterItem) => {
        return Number(register?.id) === Number(data?.id) ? data : register;
      });

      handleSetChecklistDetail({
        ...detailChecklist,
        registers: listRegister,
      });

      /* SETA O CONTEXTO PARA VAZIO */
      handleSetDetailRegister({});

      if (handleChooseEnvironment) {
        let page = popAndDeleteStackNavigation();
        handleChooseEnvironment(page as Page);
        return;
      }

      /* VOLTA PARA A PAGINA DE LISTAGEM DE REGISTROS */
      handlePageClose && handlePageClose();
      addToast({
        title: "OK!!",
        description: `Registro foi ${
          Object.keys(detailRegister).length > 0 ? "editado" : "criado"
        } com sucesso.`,
        type: "success",
      });
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        const errors = getValidationErrors(err);
        setHasError(errors);
        addToast({
          title: "Ops!!",
          description: "O nome do registro é obrigatório!",
          type: "error",
        });
        return;
      }
      addToast({
        title: "Ops!!",
        description:
          axios.isAxiosError(err) && err.response?.data.error
            ? err.response.data.error
            : `Não foi possível ${
                Object.keys(detailRegister).length > 0 ? "editar" : "criar"
              } o registro.`,
        type: "error",
      });
    } finally {
      setIsLoading(false);
    }
  }

  async function handleCreateRegister() {
    try {
      const schema = () => {
        return Yup.object().shape({
          nameRegister: Yup.string().required("Valor do item é obrigatório"),
        });
      };

      await schema().validate(
        {
          nameRegister,
        },
        { abortEarly: false }
      );

      if (detailRegister && Object.keys(detailRegister).length > 0) {
        return setAlertModal(true);
      }
      await addRegister();
      /* SETA O CONTEXTO PARA VAZIO */
      handleSetDetailRegister({});

      if (handleChooseEnvironment) {
        let page = popAndDeleteStackNavigation();
        handleChooseEnvironment(page as Page);
        return;
      }

      /* VOLTA PARA A PAGINA DE LISTAGEM DE REGISTROS */
      handlePageClose && handlePageClose();
      addToast({
        title: "OK!!",
        description: `Registro ${
          Object.keys(detailRegister).length > 0 ? "editado" : "criado"
        } com sucesso.`,
        type: "success",
      });
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        const errors = getValidationErrors(err);
        setHasError(errors);
        addToast({
          title: "Ops!!",
          description: "O nome do registro é obrigatório!",
          type: "error",
        });
        return;
      }
    } finally {
    }
  }

  return (
    <>
      {isLoading ? (
        <LoadingProfiz isVisible={isLoading} />
      ) : (
        <S.Container>
          <NewAlertModal
            isVisible={alertModal}
            title="Salvar as alterações deste registro?"
            description={
              "As alterações deste registro impactarão em todos os checklists em que ele está vinculado."
            }
            action="choose"
            handleConfirm={async () => {
              await editRegister();
              setAlertModal(false);
            }}
            onCloseModal={() => {
              setAlertModal(false);
            }}
            labelConfirm="Salvar alterações"
            labelCancel="Cancelar"
            buttonConfirmColor={theme.colors.primary}
          />
          <DiscardInformationModal
            showModal={showCompareAlert}
            handleConfirmButton={() => {
              setShowCompareAlert(false);
              handleSetDetailRegister({});
              handlePageClose && handlePageClose();
            }}
            handleCancelButton={() => {
              setShowCompareAlert(false);
            }}
          />
          <header>
            <div>
              <ArrowButton
                handleFunction={() => {
                  handleSetDetailRegister({});

                  if (!isEqual(actualRegister, backupRegister)) {
                    setShowCompareAlert(true);
                    return;
                  }
                  /* SETA O CONTEXTO PARA VAZIO */
                  if (handleChooseEnvironment) {
                    let page = popAndDeleteStackNavigation();
                    handleChooseEnvironment(page as Page);
                    return;
                  }
                  handlePageClose && handlePageClose();
                }}
              />
              <h1>
                {detailRegister &&
                detailRegister.registerDetail &&
                detailRegister.registerDetail.type
                  ? "Editar"
                  : "Novo"}{" "}
                registro customizado
              </h1>
            </div>
          </header>
          <S.Content>
            {detailRegister &&
            detailRegister.registerDetail &&
            detailRegister.registerDetail.type ? (
              <></>
            ) : (
              <>
                <Label>Conta</Label>
                <SelectCurrentAccountForCreateNewItem
                  moduleHash="service-checklist-step"
                  actionHash="create"
                />
              </>
            )}

            <Dropdown
              label="O que você deseja fazer?*"
              placeholder="Selecione uma opção"
              options={optionsType}
              onClickedValue={handleSetSelectType}
              categoryIdSelected={typeRegister?.id}
            />

            <S.WrapperInput>
              <Input
                name="Nome do novo registro*"
                placeholder="Nome/identificação do novo fluxo"
                value={nameRegister}
                onChange={(e) => setNameRegister(e.target.value.trimStart())}
                hasError={hasError.nameRegister}
                onFocusClearError={() =>
                  setHasError({ ...hasError, nameRegister: "" })
                }
              />
            </S.WrapperInput>

            <S.WrapperInputArea>
              <label>Detalhamento (opcional)</label>
              <textarea
                name="Detalhamento (opcional)"
                placeholder="Texto livre."
                value={detailInfo}
                onChange={(e) => setDetailInfo(e.target.value.trimStart())}
                rows={15}
              />
            </S.WrapperInputArea>

            <Button typeButton="default" onClick={handleCreateRegister}>
              {detailRegister &&
              detailRegister.registerDetail &&
              detailRegister.registerDetail.type
                ? "Editar"
                : "Criar"}{" "}
              registro
            </Button>
          </S.Content>
        </S.Container>
      )}
    </>
  );
}
