import styled, { css } from "styled-components";
import { generateMedia } from "styled-media-query";

type ModalProps = {
  isVisible: boolean;
  positionLogo: string;
};

const customMedia = generateMedia({
  medium: "700px",
  small: "368px",
  xsmall: "294px",
});


export const ContainerOverlay = styled.div<ModalProps>`
  ${({ theme, isVisible, positionLogo }) => css`
    width: 100%;
    flex: 1;
    min-width: 1200px;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;

    display: ${isVisible ? "flex" : "none"};
    background-color: rgba(0, 0, 0, 0.3);
    z-index: ${theme.layers.alwaysOnTop};

    & > img {
      height: 330px;
      width: 330px;

      position: absolute;
      right: ${positionLogo};
      top: 32%;

      ${customMedia.lessThan("medium")`
        ${
          css`
            display:flex;
            height: 100px;
            width: 100px;
            top:${window.screen.height*0.45}px;
            left:${window.screen.width*0.35}px;
            position:relative;
          `
        }
      `}
    }
  `}
`;
