import React from "react";

import { redirectToRespectiveAppStore } from "hooks/redirectToRespectiveAppStore";
import MobileApp from "assets/icons/mobile-app/mobileAppIcon.svg";
import { Button } from "components/Button";

import * as S from "./styles";

export function MobilePage() {
  return (
    <S.Container>
      <S.ContainerImage>
        <img src={MobileApp} alt="imagem" />
      </S.ContainerImage>

      <S.WrapperText>
        <h6>Que tal acessar o aplicativo?</h6>
        <p>
          Você acessou a Profiz pelo
          <br />
          navegador do seu celular,
          <br />
          tente acessar pelo app.
        </p>
      </S.WrapperText>

      <S.WrapperButton>
        <Button typeButton="default" onClick={redirectToRespectiveAppStore}>
          Acessar o app Profiz
        </Button>
      </S.WrapperButton>
    </S.Container>
  );
}
