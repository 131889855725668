import { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router";
import * as Yup from "yup";
import { format, parseISO } from "date-fns";
import request from "axios";
import { isEqual } from "lodash";
import axios from "axios";

import { ContentLeft } from "templates/ContentLeft";

import { ArrowButton } from "components/ArrowButton";
import { Input } from "components/Input";
import { Button } from "components/Button";
import { ButtonSVG } from "../../../components/ButtonSVG";
import { LoadingProfiz } from "components/LoadingProfiz";
import { DiscardInformationModal } from "components/DiscardInformationModal";

import { useAuth } from "hooks/auth";
import { useClient } from "hooks/budget/client";
import { useSelectedService } from "hooks/budget/selectedServices";
import { useSelectedChecklist } from "hooks/budget/selectedLocalChecklist";
import { useSelectedMaterial } from "hooks/budget/selectedMaterials";
import { useMaterialsForEditingBudget } from "hooks/budget/materialsForEditingBudget";
import { useTotalBudget } from "hooks/budget/totalBudget";
import { useSelectedPaymentConditions } from "hooks/budget/selectedPaymentConditions";
import { useObservation } from "hooks/budget/observation";
import { useToast } from "hooks/toast";
import { useBudgetTabIndex } from "hooks/budgetTabIndex";
import { useWarningMessage } from "hooks/warningMessage";
import { useSelectedProducts } from "hooks/budget/selectedProducts";

import { mappedServicesFromApi } from "utils/mappedServicesFromApi";
import { mappedServicesForApi } from "utils/mappedServicesForApi";
import { mappedLocalChecklistFromApi } from "utils/mappedLocalChecklistFromApi";
import { mappedLocalChecklistForApi } from "utils/mappedLocalChecklistForApi";
import { mappedMaterialsFromApi } from "utils/mappedMaterialsFromApi";
import { mappedMaterialsForApi } from "utils/mappedMaterialsForApi";
import addMaskMoney from "utils/addMaskMoney";
import getValidationErrors from "utils/getValidationErrors";
import {
  mappedBudgetProductToUseInApp,
  mappedBudgetProducts,
} from "utils/mappedBudgetProducts";
import { ModeProps } from "dtos/businessProposalsDTO";

import { ClientArea } from "./ClientArea";
import { ServiceArea } from "./ServiceArea";
import { LocalChecklistArea } from "./LocalChecklistArea";
import { MaterialsArea } from "./MaterialsArea";
import { PaymentConditionsArea } from "./PaymentConditionsArea";
import { DefaultObservationsArea } from "./DefaultObservationsArea";
import { UnityAreaComponent } from "components/UnityAreaComponent";
import { ProductsArea } from "components/ModalProducts/ProductsArea";

import * as S from "./styles";
import apiv2 from "services/apiv2";
import { api } from "services/api";

import {
  BudgetDetailsProps,
  MappedBudgetProps,
  PickedClientDTO,
  PickedUnityDTO,
} from "dtos/BudgetDetailsDTO";
import { ClientProps, UnityDTO } from "dtos/ClientDTO";

import { useAccount } from "hooks/permission/account";

type RouteParams = {
  id: string;
};

type Errors = {
  [key: string]: string;
};

type UnitiesResponse = {
  unities: UnityDTO[];
};

export function EditBudget() {
  const { id: budgetId } = useParams<RouteParams>();

  const { user } = useAuth();
  const { whoami } = useAccount();
  const { addToast } = useToast();
  const history = useHistory();
  const { handleSetSelectedIndex } = useBudgetTabIndex();

  const [isLoading, setIsLoading] = useState(true);
  const [updatingBudget, setUpdatingBudget] = useState(false);
  const [loadingButtonModel, setLoadingButtonModel] = useState(false);

  const [pdfLink, setPdfLink] = useState("");
  const [idSequence, setIdSequence] = useState<number>();
  const [formattedCreationDate, setFormattedCreationDate] = useState("");
  const {
    client,
    handleSetClient,
    unity,
    handleSetUnityClient,
    clearUnityData,
    clearClientData,
  } = useClient();

  const {
    selectedProductsInContext,
    handleSetSelectedProducts,
    clearSelectedProducts,
  } = useSelectedProducts();
  const { selectedServices, handleSetSelectedServices, clearServicesData } =
    useSelectedService();
  const {
    selectedChecklistItems,
    handleSetSelectedChecklist,
    clearChecklistsData,
  } = useSelectedChecklist();
  const { selectedMaterials, handleSetSelectedMaterials, clearMaterialsData } =
    useSelectedMaterial();
  const { handleSetMaterialsForEditingBudget } = useMaterialsForEditingBudget();
  const { totalBudget } = useTotalBudget();
  const {
    selectedPromptPayment,
    selectedInstallmentPayment,
    selectedIncomingPayment,
    handleSetSelectedPromptPayment,
    handleSetSelectedInstallmentPayment,
    handleSetSelectedIncomingPayment,
  } = useSelectedPaymentConditions();

  const { text, handleSetText, clearObservationData } = useObservation();
  const { linkPage, handleClearLinkPage } = useWarningMessage();

  const [mode, setMode] = useState<ModeProps | "">("");
  const [budgetValidation, setBudgetValidation] = useState("");

  const [hasError, setHasError] = useState<Errors>({} as Errors);
  const [hasErrorOnClient, setHasErrorOnClient] = useState("");
  const [hasErrorOnUnity] = useState("");

  const [hasErrorOnPaymentConditions, setHasErrorOnPaymentConditions] =
    useState("");

  const [budgetData, setBudgetData] = useState({} as BudgetDetailsProps);

  const [initialBudgetDataToCompare, setInitialBudgetDataToCompare] = useState(
    {} as MappedBudgetProps
  );
  const [isBudgetChanged, setIsBudgetChanged] = useState(false);
  const [warningMessage, setWarningMessage] = useState(false);

  async function getUnityData(clientResponseId: number) {
    const response = await api.get<UnitiesResponse>(
      `/clients/${clientResponseId}/unities`,
      {
        params: {
          limit: 50,
          offset: 0,
          client_id: clientResponseId,
        },
      }
    );

    return response.data.unities;
  }

  async function getBudgetById(budgetId: string) {
    const response = await apiv2.get(`/budgets/${budgetId}`);
    return response.data;
  }

  useEffect(() => {
    async function getAllBudgetData() {
      try {
        const budgetResponse = await getBudgetById(budgetId);

        const {
          mode,
          sequenceNumber,
          created_at,
          detailLink,
          client: clientResponse,
          products,
          services,
          localChecklists,
          materials,
          total,
          payment_condition,
          budget_validity,
          observations,
        } = budgetResponse;

        const unitiesResponse = await getUnityData(clientResponse?.id);
        const defaultUnity = unitiesResponse?.find((unity) => unity.default);

        setMode(mode);
        setBudgetData(budgetResponse);
        const dateFormatted = format(parseISO(created_at), "dd/MM/yyyy");

        setIdSequence(sequenceNumber);
        setPdfLink(detailLink);
        setFormattedCreationDate(dateFormatted);

        handleSetClient(clientResponse);

        const mappedProducts = mappedBudgetProductToUseInApp(products);
        handleSetSelectedProducts(mappedProducts);

        const mappedServices = mappedServicesFromApi(services);
        handleSetSelectedServices(mappedServices);

        const mappedLocalChecklists =
          mappedLocalChecklistFromApi(localChecklists);
        handleSetSelectedChecklist(mappedLocalChecklists);

        const mappedMaterials = mappedMaterialsFromApi(materials);
        handleSetSelectedMaterials(mappedMaterials);
        handleSetMaterialsForEditingBudget(mappedMaterials);

        handleSetSelectedPromptPayment(payment_condition.promptPayment, total);
        handleSetSelectedInstallmentPayment(
          payment_condition.installment,
          total
        );
        handleSetSelectedIncomingPayment(payment_condition.incoming, total);
        handleSetBudgetValidation(budget_validity);
        handleSetText(observations);

        let unityValidated = {} as UnityDTO;

        if (clientResponse?.address && Number(clientResponse?.address?.id)) {
          unityValidated = clientResponse.address;
          handleSetUnityClient(clientResponse.address);
        } else if (defaultUnity) {
          unityValidated = defaultUnity;
          handleSetUnityClient(defaultUnity);
        }

        setInitialBudgetDataToCompare({
          client: formatClientToCompareInfos(clientResponse),
          unity: formatUnityToCompareInfos(unityValidated),
          services: mappedServicesFromApi(services),
          products: mappedBudgetProductToUseInApp(products),
          localChecklists: mappedLocalChecklistFromApi(localChecklists),
          materials: mappedMaterialsFromApi(materials),
          payment_condition: {
            promptPayment: payment_condition.promptPayment,
            installment: payment_condition.installment,
            incoming: payment_condition.incoming,
          },
          total,
          budget_validity,
          observations,
        });
      } catch {
        addToast({
          title: "Erro",
          description: "Erro ao carregar os dados do orçamento.",
          type: "error",
        });
      } finally {
        setIsLoading(false);
      }
    }

    getAllBudgetData();

    return () => clearAllDataBudget();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [budgetId]);

  useEffect(() => {
    const confirmGoBackIfBudgetHasChanges = history.block((_, action) => {
      if (action === "POP" && isBudgetChanged) {
        const isConfirmed = window.confirm(
          "Existem alterações não salvas no seu orçamento. Deseja realmente sair?"
        );

        if (isConfirmed) {
          history.replace("/budgets");
          return;
        }

        history.replace(history.location.pathname);
        return false;
      }
    });

    return () => {
      confirmGoBackIfBudgetHasChanges();
    };
  }, [history, isBudgetChanged]);

  function clearAllDataBudget() {
    clearClientData();
    clearSelectedProducts();
    clearServicesData();
    clearChecklistsData();
    clearMaterialsData();
    clearObservationData();
    clearUnityData();
    handleClearLinkPage();
  }

  function handleSetBudgetValidation(budgetValidationText: string) {
    setBudgetValidation(budgetValidationText);
  }

  const createAddress = () => {
    return Object.keys(unity).length > 0
      ? unity
      : {
          id: 0,
          clientId: "",
          name: "",
          responsible: "",
          email: "",
          phone: "",
          postalCode: "",
          street: "",
          district: "",
          number: "",
          complement: "",
          city: "",
          uf: "",
          additionalInfo: "",
          default: true,
          createdAt: "",
          environments: [],
        };
  };

  async function handleUpdateBudget() {
    try {
      setUpdatingBudget(true);

      if (!client.id) {
        setHasErrorOnClient("Cliente é obrigatório");
      } else {
        setHasErrorOnClient("");
      }

      if (
        !selectedProductsInContext.length &&
        (mode === "products" || mode === "products-services")
      ) {
        addToast({
          title: "Ops!",
          description:
            "Você precisa ter pelo menos um produto adicionado neste orçamento.",
          type: "error",
        });
        return;
      }

      if (
        !selectedServices.length &&
        (mode === "services" || mode === "products-services")
      ) {
        addToast({
          title: "Ops!",
          description:
            "Você precisa ter pelo menos um serviço adicionado neste orçamento.",
          type: "error",
        });
        return;
      }

      if (
        totalBudget > 0 &&
        !selectedPromptPayment.length &&
        !selectedIncomingPayment.length &&
        !selectedInstallmentPayment.length
      ) {
        setHasErrorOnPaymentConditions(
          "Condições de pagamento são obrigatórias"
        );
      } else {
        setHasErrorOnPaymentConditions("");
      }

      const schema = () => {
        return Yup.object().shape({
          budgetValidation: Yup.string().required(
            "Validade do orçamento é obrigatória."
          ),
        });
      };

      setHasError({} as Errors);
      await schema().validate({ budgetValidation }, { abortEarly: false });

      if (
        !client.id ||
        (totalBudget > 0 &&
          !selectedPromptPayment.length &&
          !selectedIncomingPayment.length &&
          !selectedInstallmentPayment.length)
      ) {
        throw new Error("Alguns campos são obrigatórios");
      }

      const updateBudget = {
        client: {
          ...client,
          address: createAddress(),
        },
        products: mappedBudgetProducts(selectedProductsInContext),
        services: mappedServicesForApi(selectedServices),
        localChecklists: mappedLocalChecklistForApi(selectedChecklistItems),
        materials: mappedMaterialsForApi(selectedMaterials),
        budget_validity: budgetValidation,
        total: totalBudget,
        payment_condition: {
          promptPayment:
            totalBudget > 0
              ? selectedPromptPayment
              : [
                  {
                    index: "promptPayment",
                    discount: null,
                    total: 0,
                    formattedTotal: "R$ 0,00",
                    description: "Gratuito",
                  },
                ],
          installment: selectedInstallmentPayment,
          incoming: selectedIncomingPayment,
        },
        observations: text,
        originUpdate: "web",
        mode,
      };

      await apiv2.put(`/budgets/${budgetId}`, updateBudget);

      setInitialBudgetDataToCompare(updateBudget as any);
      setIsBudgetChanged(false);

      addToast({
        title: "Sucesso",
        description: "Orçamento atualizado com sucesso!",
        type: "success",
      });
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        const errors = getValidationErrors(err);
        setHasError(errors);
        throw new Error(err.message);
      }
      addToast({
        title: "Ops!!",
        description:
          axios.isAxiosError(err) && err.response?.data.error
            ? err.response.data.error
            : "Ocorreu um problema ao atualizar o orçamento.",
        type: "error",
      });
    } finally {
      setUpdatingBudget(false);
    }
  }

  useEffect(() => {
    //Ao trocar de cliente, retira o erro.
    if (hasErrorOnClient) {
      setHasErrorOnClient("");
    }
  }, [client, hasErrorOnClient]);

  async function handleSaveBudgetAsModel() {
    try {
      setLoadingButtonModel(true);
      const budgetData = {
        client: {},
        products: mappedBudgetProducts(selectedProductsInContext),
        services: mappedServicesForApi(selectedServices),
        localChecklists: mappedLocalChecklistForApi(selectedChecklistItems),
        materials: mappedMaterialsForApi(selectedMaterials),
        budget_validity: budgetValidation,
        total: totalBudget,
        payment_condition: {
          promptPayment: selectedPromptPayment,
          installment: selectedInstallmentPayment,
          incoming: selectedIncomingPayment,
        },
        observations: text,
        originCreate: "web",
        mode,
      };

      await apiv2.post(`budgets/${budgetId}/model`, budgetData);

      addToast({
        title: "Certo!",
        description: "Este orçamento foi salvo como modelo.",
        type: "success",
      });
    } catch (error) {
      addToast({
        title: "Ops!!",
        description:
          axios.isAxiosError(error) && error.response?.data.error
            ? error.response.data.error
            : "Ocorreu um erro ao salvar o orçamento como modelo.",
        type: "error",
      });
    } finally {
      setLoadingButtonModel(false);
    }
  }

  async function handleApproveBudgetAndOrGenerateServiceOrder() {
    try {
      if (!client.id) {
        setHasErrorOnClient("Cliente é obrigatório");
      } else {
        setHasErrorOnClient("");
      }

      if (
        !selectedProductsInContext.length &&
        (mode === "products" || mode === "products-services")
      ) {
        addToast({
          title: "Ops!",
          description:
            "Você precisa ter pelo menos um produto adicionado neste orçamento.",
          type: "error",
        });
        return;
      }

      if (
        !selectedServices.length &&
        (mode === "services" || mode === "products-services")
      ) {
        addToast({
          title: "Ops!",
          description:
            "Você precisa ter pelo menos um serviço adicionado neste orçamento.",
          type: "error",
        });
        return;
      }

      if (
        totalBudget > 0 &&
        !selectedPromptPayment.length &&
        !selectedIncomingPayment.length &&
        !selectedInstallmentPayment.length
      ) {
        setHasErrorOnPaymentConditions(
          "Condições de pagamento são obrigatórias"
        );
      } else {
        setHasErrorOnPaymentConditions("");
      }

      const schema = () => {
        return Yup.object().shape({
          budgetValidation: Yup.string().required(
            "Validade do orçamento é obrigatório"
          ),
        });
      };

      setHasError({} as Errors);
      await schema().validate(
        {
          budgetValidation,
        },
        { abortEarly: false }
      );

      if (
        !client.id ||
        (totalBudget > 0 &&
          !selectedPromptPayment.length &&
          !selectedIncomingPayment.length &&
          !selectedInstallmentPayment.length)
      ) {
        addToast({
          title: "Opss",
          description: "Alguns campos são obrigatórios",
          type: "error",
        });

        return;
      }

      const newBudgetData = {
        client: {
          ...client,
          address: createAddress(),
        },
        products: mappedBudgetProducts(selectedProductsInContext),
        services: mappedServicesForApi(selectedServices),
        localChecklists: mappedLocalChecklistForApi(selectedChecklistItems),
        materials: mappedMaterialsForApi(selectedMaterials),
        budget_validity: budgetValidation,
        total: totalBudget,
        payment_condition: {
          promptPayment:
            totalBudget > 0
              ? selectedPromptPayment
              : [
                  {
                    index: "promptPayment",
                    discount: null,
                    total: 0,
                    formattedTotal: "R$ 0,00",
                    description: "Gratuito",
                  },
                ],
          installment: selectedInstallmentPayment,
          incoming: selectedIncomingPayment,
        },
        observations: text,
        originCreate: "web",
        mode,
      };

      const response = await apiv2.post(
        `/budgets/${budgetId}/approve`,
        newBudgetData
      );

      const serviceOrderId = response.data.serviceOrderId;

      if (mode !== "products") {
        history.replace(`/service-order/pending/${serviceOrderId}`);
      } else {
        history.replace(`/budgets/concluded/${budgetId}`);
      }
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        const errors = getValidationErrors(err);
        setHasError(errors);
        addToast({
          title: "Opss",
          description: err.message,
          type: "error",
        });
        return;
      }
      addToast({
        title: "Ops!!",
        description:
          axios.isAxiosError(err) && err.response?.data.error
            ? err.response.data.error
            : "Não foi possível gerar a ordem de serviço no momento.",
        type: "error",
      });
    }
  }

  async function handleArchiveBudget() {
    try {
      setIsLoading(true);

      await apiv2.put(`/budgets/${budgetId}/archive`, {
        originUpdate: "web",
      });

      addToast({
        title: "Orçamento arquivado com sucesso.",
        description: "Você será redirecionado para a listagem de orçamentos.",
        type: "success",
      });

      history.goBack();
      handleSetSelectedIndex(2);
    } catch (error) {
      addToast({
        title: "Ops!!",
        description:
          axios.isAxiosError(error) && error.response?.data.error
            ? error.response.data.error
            : "Não foi possível arquivar este orçamento.",
        type: "error",
      });
    } finally {
      setIsLoading(false);
    }
  }

  async function handleOpenPdfPage() {
    try {
      await handleUpdateBudget();

      history.push({
        pathname: "/download/pdf",
        state: {
          pdfDownloadLink: pdfLink,
        },
      });
    } catch (error) {
      addToast({
        title: "Ops!!",
        description:
          axios.isAxiosError(error) && error.response?.data.error
            ? error.response.data.error
            : "Verifique os campos obrigatórios.",
        type: "error",
      });
    }
  }

  function formatPaymentCondition(
    paymentCondition: any,
    type: "promptPayment" | "installment" | "incoming"
  ) {
    if (
      !paymentCondition ||
      !paymentCondition.length ||
      typeof paymentCondition !== "object"
    ) {
      return;
    }

    if (type === "promptPayment") {
      const promptPayment = paymentCondition[0];
      return {
        index: promptPayment.index,
        discount: promptPayment.discount,
        total: promptPayment.total,
      };
    }

    if (type === "installment") {
      const installment = paymentCondition[0];
      return {
        typeInstallment: installment.typeInstallment,
        numberInstallments: installment.numberInstallments,
        discount: installment.discount,
        addition: installment.addition,
        total: installment.total,
      };
    }

    if (type === "incoming") {
      const incoming = paymentCondition[0];

      return {
        index: incoming.index,
        amount: incoming.amount,
        total: incoming.total,
      };
    }
  }

  function formatClientToCompareInfos(
    currentClient: ClientProps
  ): PickedClientDTO {
    if (Object.keys(currentClient).length > 0) {
      return {
        id: currentClient.id,
        name: currentClient.name,
        email: currentClient.email,
        phone: currentClient.phone,
        cpf_cnpj: currentClient.cpf_cnpj,
      };
    }

    return {} as PickedClientDTO;
  }

  function formatUnityToCompareInfos(currentUnity: UnityDTO): PickedUnityDTO {
    if (Object.keys(currentUnity).length > 0) {
      return {
        id: currentUnity.id,
        name: currentUnity.name,
        responsible: currentUnity.responsible,
        postalCode: currentUnity.postalCode,
        street: currentUnity.street,
        number: currentUnity.number,
        district: currentUnity.district,
        city: currentUnity.city,
        uf: currentUnity.uf,
        complement: currentUnity.complement,
        additionalInfo: currentUnity.additionalInfo,
      };
    }

    return {} as PickedUnityDTO;
  }

  function verifyIfObjectsAreEqual(object1: any, object2: any) {
    return isEqual(JSON.stringify(object1), JSON.stringify(object2));
  }

  function detectingChangesIntoBudgetToSaveIt() {
    const someObjectHasChanged = (
      Object.entries(initialBudgetDataToCompare) as [
        keyof MappedBudgetProps,
        any
      ][]
    ).some(([key, initialInfo]) => {
      switch (key) {
        case "client":
          return !verifyIfObjectsAreEqual(
            initialInfo,
            formatClientToCompareInfos(client)
          );
        case "unity":
          return !verifyIfObjectsAreEqual(
            initialInfo,
            formatUnityToCompareInfos(unity)
          );
        case "services":
          return !verifyIfObjectsAreEqual(initialInfo, selectedServices);
        case "products":
          return !verifyIfObjectsAreEqual(
            initialInfo,
            selectedProductsInContext
          );
        case "localChecklists":
          return !verifyIfObjectsAreEqual(initialInfo, selectedChecklistItems);
        case "materials":
          return !verifyIfObjectsAreEqual(initialInfo, selectedMaterials);
        case "payment_condition":
          return (
            !verifyIfObjectsAreEqual(
              formatPaymentCondition(
                initialInfo.promptPayment,
                "promptPayment"
              ),
              formatPaymentCondition(selectedPromptPayment, "promptPayment")
            ) ||
            !verifyIfObjectsAreEqual(
              formatPaymentCondition(initialInfo.installment, "installment"),
              formatPaymentCondition(selectedInstallmentPayment, "installment")
            ) ||
            !verifyIfObjectsAreEqual(
              formatPaymentCondition(initialInfo.incoming, "incoming"),
              formatPaymentCondition(selectedIncomingPayment, "incoming")
            )
          );
        case "total":
          return initialInfo !== totalBudget;
        case "budget_validity":
          return initialInfo !== budgetValidation;
        case "observations":
          return initialInfo !== text;
        default:
          return false;
      }
    });

    setIsBudgetChanged(someObjectHasChanged);
  }

  useEffect(() => {
    detectingChangesIntoBudgetToSaveIt();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    client,
    unity,
    selectedServices,
    selectedProductsInContext,
    selectedChecklistItems,
    selectedMaterials,
    budgetValidation,
    totalBudget,
    selectedPromptPayment,
    selectedInstallmentPayment,
    selectedIncomingPayment,
    text,
  ]);

  const handleLogin = async () => {
    try {
      if (user.profileComplete && user.profileApproved) {
        const { data } = await api.post("/webview/auth");
        const win = window.open(data.url, "_blank");
        win?.focus();
      } else {
        addToast({
          title: "Ops!!",
          description: "Não foi possível fazer login na loja",
          type: "error",
        });
      }
    } catch (error) {
      addToast({
        title: "Ops!!",
        description:
          request.isAxiosError(error) && error.response?.data.error
            ? error.response.data.error
            : "Não foi possível fazer login na loja",
        type: "error",
      });
    } finally {
      history.goBack();
    }
  };

  window.onbeforeunload = function () {
    return "Ao sair os dados serão perdidos. Deseja realmente sair?";
  };

  function handleConfirmChangesToSaveBudget() {
    setWarningMessage(false);
    setIsBudgetChanged(false);

    if (linkPage && linkPage.length > 0) {
      return linkPage === "shopLink" ? handleLogin() : history.push(linkPage);
    }

    history.goBack();
  }

  function handleGoBack() {
    if (isBudgetChanged) {
      handleClearLinkPage();
      setWarningMessage(true);
      return;
    }

    history.push("/budgets");
  }

  if (isLoading) {
    return <LoadingProfiz isVisible={isLoading} />;
  }

  return (
    <S.Container>
      <DiscardInformationModal
        showModal={warningMessage}
        handleConfirmButton={handleConfirmChangesToSaveBudget}
        handleCancelButton={() => {
          setWarningMessage(false);
        }}
      />

      <ContentLeft>
        <header>
          <div>
            <ArrowButton handleFunction={handleGoBack} />
          </div>

          <S.TitleHeader>Resumo do orçamento</S.TitleHeader>
        </header>

        <S.Wrapper>
          <S.InfoWrapper>
            <S.TextGreen>{idSequence}</S.TextGreen>
            <S.TextGreen>{formattedCreationDate}</S.TextGreen>
          </S.InfoWrapper>

          <ClientArea module="budget" hasError={hasErrorOnClient} />

          <S.Wrapper>
            <UnityAreaComponent
              module="budget"
              hasError={hasErrorOnUnity}
              label="selecione o endereço do cliente"
            />
          </S.Wrapper>

          {(mode === "products" || mode === "products-services") && (
            <ProductsArea hasIdBudget={!!budgetData.id} />
          )}

          {(mode === "services" || mode === "products-services") && (
            <ServiceArea />
          )}

          <LocalChecklistArea />

          <MaterialsArea />

          <S.Wrapper>
            <S.Title>Informações de pagamento</S.Title>

            <S.Wrapper>
              <Input
                name="Total*"
                value={addMaskMoney(totalBudget)}
                readOnly
                placeholder="R$10,00"
              />
            </S.Wrapper>

            <PaymentConditionsArea hasError={hasErrorOnPaymentConditions} />

            <S.WrapperBudgetValidity>
              <S.WrapperInputBudgetValidity>
                <Input
                  name="Validade do orçamento*"
                  placeholder="Ex: 7"
                  type="number"
                  onChange={(e) => handleSetBudgetValidation(e.target.value)}
                  value={budgetValidation}
                  hasError={hasError.budgetValidation}
                />
              </S.WrapperInputBudgetValidity>

              <span>dias</span>
            </S.WrapperBudgetValidity>

            <S.Wrapper>
              <DefaultObservationsArea
                valueTextArea={text}
                // eslint-disable-next-line react/jsx-no-bind
                onChangeTextArea={(text) => handleSetText(text.trimStart())}
              />
            </S.Wrapper>

            <S.WrapperButton>
              <Button
                // disabled={!isBudgetChanged}
                loading={updatingBudget}
                onClick={handleUpdateBudget}
              >
                Salvar alterações
              </Button>
            </S.WrapperButton>

            <S.WrapperButtonsSVG>
              {/* <ButtonSVG title="Enviar para o cliente" typeSvg="client" /> */}

              <ButtonSVG
                title="Salvar como modelo"
                loading={loadingButtonModel}
                typeSvg="model"
                onClick={() => handleSaveBudgetAsModel()}
              />

              <ButtonSVG
                title="Download PDF"
                typeSvg="pdf"
                onClick={handleOpenPdfPage}
              />
            </S.WrapperButtonsSVG>

            {whoami?.isMe && (
              <S.WrapperButton>
                <Button
                  onClick={() => handleApproveBudgetAndOrGenerateServiceOrder()}
                >
                  {mode === "products"
                    ? "Aprovar orçamento"
                    : "Aprovar e gerar ordem de serviço"}
                </Button>
              </S.WrapperButton>
            )}

            <S.WrapperArchiveButton>
              <Button typeButton="textOnly" onClick={handleArchiveBudget}>
                Arquivar orçamento
              </Button>
            </S.WrapperArchiveButton>
          </S.Wrapper>
        </S.Wrapper>
      </ContentLeft>
    </S.Container>
  );
}
