import axios from "axios";

const apiv2 = axios.create({
  baseURL: `${process.env.REACT_APP_BASE_URL}/v2`,
  headers: {
    "App-Token": process.env.REACT_APP_TOKEN_CONNECT_BACKEND,
  },
});

export default apiv2;
