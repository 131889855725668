import { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { format, startOfMonth, lastDayOfMonth, parseISO } from "date-fns";
import request from "axios";

import { EmptyPage } from "components/EmptyPage";
import { LoadingProfiz } from "components/LoadingProfiz";
import { ArrowButton } from "components/ArrowButton";
import { Calendar, DayModifiers } from "components/Calendar";
import { ButtonContentBox } from "components/ButtonContentBox";
import { SelectCurrentAccountForCreateNewItem } from "components/Permission";
import { UserExecutionCard } from "components/UserExecutionCard";

import { useToast } from "hooks/toast";
import { useAccount } from "hooks/permission/account";
import { UserExecutionProps } from "dtos/ServiceOrderDetailDTO";

import { api } from "services/api";

import FilterSVG from "assets/filter.svg";

import * as S from "./styles";

type EventProps = {
  estimatedCompletionTime: number;
  id: string;
  scheduledStartDate: string;
  scheduledEndDate: string;
  service: {
    client: {
      id: string;
      name: string;
    };
    finishDate: string;
    idBudgetService: string;
    idServiceOrder: string;
    sequenceNumber: string;
    status: string;
    statusServiceOrder: string;
  };
  title: string;
  type: string;
  user: UserExecutionProps
};

type DateProps = {
  date: string;
  events: EventProps[];
};

export function Schedule() {
  const { addToast } = useToast();
  const history = useHistory();
  const { whoami } = useAccount();

  const [openModal, setOpenModal] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedDate, setSelectedDate] = useState<Date | undefined>(undefined);
  const [dayMonthSelected, setDayMonthSelected] = useState<Date>(new Date());

  const [datesWithSchedules, setDatesWithSchedules] = useState<string[]>([]);
  const [allAppointmentsGroupedByDay, setAllAppointmentsGroupedByDay] =
    useState<DateProps[]>([]);
  const [currentDateAppointments, setCurrentDateAppointments] = useState<
    EventProps[]
  >([]);

  useEffect(() => {
    async function getServiceOrdersByDate() {
      try {
        const firstDayOfCurrentMonth = startOfMonth(dayMonthSelected);
        const lastDayOfCurrentMonth = lastDayOfMonth(dayMonthSelected);

        const startDate = format(firstDayOfCurrentMonth, "yyyy-MM-dd");
        const endDate = format(lastDayOfCurrentMonth, "yyyy-MM-dd");

        await api.get("/verifyUserProfile");

        if (!whoami?.id) return;

        const { data } = await api.get(
          `users/teams/${whoami?.id}/members/schedule`,
          {
            params: {
              offset: 0,
              limit: 200,
              status: "scheduled",
              startDate,
              endDate,
              accountId: whoami?.id,
            },
          }
        );

        if (Object.keys(data.dates).length === 0) {
          setAllAppointmentsGroupedByDay([]);
          setDatesWithSchedules([]);
          return;
        }

        setAllAppointmentsGroupedByDay(data.dates);
        getDatesWithServices(data.dates);
      } catch (error) {
        if (request.isAxiosError(error) && error.response?.status === 401)
          return;

        addToast({
          type: "error",
          title: "Ops...",
          description: "Erro ao buscar ordens de serviço",
        });
      } finally {
        setIsLoading(false);
      }
    }

    getServiceOrdersByDate();
  }, [dayMonthSelected, whoami?.id]); // eslint-disable-line

  useEffect(() => {
    async function setDateInit() {
      if (currentDateAppointments.length === 0) {
        const formattedDate = format(dayMonthSelected, "yyyy-MM-dd");

        const foundAppointments = allAppointmentsGroupedByDay.find(
          (currentDate) => currentDate.date === formattedDate
        );

        if (foundAppointments) {
          setCurrentDateAppointments(foundAppointments.events);
          setSelectedDate(dayMonthSelected);
        }
      }
    }
    setDateInit();
  }, [allAppointmentsGroupedByDay, whoami]); // eslint-disable-line

  function getDatesWithServices(dates: DateProps[]) {
    const datesWithSchedules = dates.map(({ date }) => {
      return date;
    });

    setDatesWithSchedules(datesWithSchedules);
  }

  function handleGetDayMonthViewed(date: Date) {
    setDayMonthSelected(date);
  }

  function handleChangeDate(day: Date, { selected, disabled }: DayModifiers) {
    if (disabled || selected) return;
    const formattedDate = format(day, "yyyy-MM-dd");

    const foundAppointments = allAppointmentsGroupedByDay.find(
      (currentDate) => currentDate.date === formattedDate
    );

    setSelectedDate(day);
    if (foundAppointments) setCurrentDateAppointments(foundAppointments.events);
  }

  function handleNavigateToServiceOrderDetail(
    idServiceOrder: number,
    status: string
  ) {
    if (status === "concluded") {
      return history.push(`/service-order/concluded/${idServiceOrder}`);
    }

    return history.push(`/service-order/scheduled/${idServiceOrder}`);
  }

  function handleShowFilterAccount() {
    setOpenModal((old) => !old);
  }

  return (
    <>
      <S.Container>
        <header>
          <div>
            <ArrowButton />
          </div>

          <div>
            <h1>Agenda</h1>
          </div>
          <SelectCurrentAccountForCreateNewItem
            moduleHash="schedule"
            actionHash="view"
            openModal={openModal}
          >
            <button
              style={{ width: 35, height: 35 }}
              onClick={handleShowFilterAccount}
            >
              <img src={FilterSVG} alt="filtro" />
            </button>
          </SelectCurrentAccountForCreateNewItem>
        </header>

        {isLoading ? (
          <LoadingProfiz isVisible={isLoading} />
        ) : (
          <S.Content>
            <div>
              <Calendar
                selectDays={selectedDate}
                onChangeDate={handleChangeDate}
                datesWithServices={datesWithSchedules}
                onGetDayMonthViewed={handleGetDayMonthViewed}
              />
            </div>

            <p>Informações importantes sobre este dia</p>

            {currentDateAppointments.length === 0 ? (
              <EmptyPage />
            ) : (
              <>
                {currentDateAppointments.map((events) => {
                  const { scheduledStartDate, service, title, user } = events;

                  const formattedDate = format(
                    parseISO(scheduledStartDate),
                    "dd/MM/yyyy HH:mm"
                  );
                  const [date, hour] = formattedDate.split(" ");
                  const scheduleDateText = `${date} às ${hour}`;

                  const finishDateFormatted = service.finishDate
                    ? format(new Date(service.finishDate), "dd/MM/yyyy HH:mm")
                    : "";
                  const [dateFinish, hourFinish] =
                    finishDateFormatted.split(" ");

                  return (
                    <S.WrapperOrder key={service.idBudgetService}>
                      <span>Agendado para: {scheduleDateText}</span>

                      <ButtonContentBox
                        onClick={() =>
                          handleNavigateToServiceOrderDetail(
                            Number(service.idServiceOrder),
                            service.statusServiceOrder
                          )
                        }
                      >
                        <h4>{title}</h4>

                        <S.WrapperStatusService>
                          <S.MarkerStatusService status={service.status} />
                          <h4 style={{ marginTop: 10 }}>
                            {service.status === "concluded"
                              ? "Concluído em: "
                              : "Em andamento"}
                            {service.finishDate &&
                              `${dateFinish} - ${hourFinish}`}
                          </h4>
                        </S.WrapperStatusService>

                        <p>Cliente: {service.client.name}</p>
                        <span>
                          Ordem de serviço: {service.sequenceNumber} (
                          {service.statusServiceOrder === "concluded"
                            ? "Concluído"
                            : "Em andamento"}
                          )
                        </span>

                        <UserExecutionCard userExecution={user} />
                      </ButtonContentBox>
                    </S.WrapperOrder>
                  );
                })}
              </>
            )}
          </S.Content>
        )}
      </S.Container>
    </>
  );
}
