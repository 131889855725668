import styled, { css, keyframes } from "styled-components";
import { lighten, shade } from "polished";
import { generateMedia } from "styled-media-query";

type ModalProps = {
  isVisible: boolean;
};

const customMedia = generateMedia({
  medium: "700px",
  small: "368px",
  xsmall: "294px",
});

const ModalScale = keyframes`
  from {
    transform: scale(0.5);
    opacity: 0.4;
  }
  to {
    transform: scale(1);
    opacity: 1;
  }
`;

export const ContainerOverlay = styled.aside<ModalProps>`
  ${({ theme, isVisible }) => css`
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;

    display: ${isVisible ? "flex" : "none"};
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: ${theme.layers.alwaysOnTop};
  `}
`;

export const BoxModal = styled.div`
  ${({ theme }) => css`
    width: 20%;
    min-width: 380px;
    max-width: 460px;
    padding: 0 16px 16px;
    border-radius: 10px;

    display: flex;
    flex-direction: column;

    background-color: #000000;
    animation: ${ModalScale} 0.3s ease-in-out;

    ${customMedia.lessThan("medium")`
      ${css`
        display: flex;
        min-width: 300px;
        max-width: 310px;
      `}
    `}
  `}
`;

export const Header = styled.div`
  width: 100%;
  height: 56px;
  margin-bottom: 10px;
  display: flex;
  justify-content: flex-end;
  align-items: center;

  & > button {
    padding: 2px;
    border-radius: 6px;

    display: flex;
    justify-content: center;
    align-items: center;
    transition: background 0.2s;

    &:hover {
      background: ${({ theme }) => lighten(0.1, theme.colors.background_modal)};
    }
  }
`;

export const Main = styled.div`
  width: 100%;
  height: 46%;
  margin-bottom: 20px;
`;

export const Title = styled.h4`
  ${({ theme }) => css`
    font-size: 20px;
    color: ${theme.colors.title};
    text-align: center;
    margin-bottom: 12px;
  `}
`;

export const Description = styled.p`
  ${({ theme }) => css`
    font-size: ${theme.font.sizes.medium};
    color: ${theme.colors.text};
    margin: 0 8px;
    text-align: center;
  `}
`;

export const Footer = styled.footer`
  width: 100%;
  padding-top: 12px;

  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

export const WrapperButtons = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: center;
`;

export const ButtonConfirm = styled.button`
  ${({ theme }) => css`
    width: 100%;
    height: 40px;
    color: ${theme.colors.background};
    font-size: ${theme.font.sizes.small};

    display: flex;
    justify-content: center;
    align-items: center;

    background-color: ${theme.colors.primary};
    border-radius: 8px;
    transition: background 0.2s;

    &:hover {
      background: ${shade(0.2, theme.colors.primary)};
    }
  `}
`;

export const ButtonCancel = styled.button`
  ${({ theme }) => css`
    margin-top: 10px;
    width: 100%;
    height: 40px;
    color: ${theme.colors.title};
    font-size: ${theme.font.sizes.small};

    display: flex;
    justify-content: center;
    align-items: center;

    border-radius: 8px;
    transition: opacity 0.2s;

    &:hover {
      opacity: 0.7;
    }
  `}
`;
