import styled, { css } from 'styled-components';
import { lighten } from "polished";

interface ContainerProps {
  isErrored: boolean;
  disabled?: boolean;
}

interface PlaceholderProps {
  hasError: boolean;
}

export const Label = styled.p`
  ${({ theme }) => css`
    color: ${theme.colors.title};
    text-align: left;
    font-size: 12px;
    margin-bottom: 5px;
  `}
`;

export const Placeholder = styled.p<PlaceholderProps>`
  ${({ theme }) => css`
    color: ${theme.colors.text};
    text-align: left;
    font-size: 12px;
  `}
`;

export const Container = styled.div<ContainerProps>`
  ${({ theme, disabled, isErrored }) => css`
    background: ${lighten(0.05, theme.colors.background)};
    border-radius: 10px;
    position: relative;

    min-height: 45px;
    width: 100%;
    border: 1px solid
      ${isErrored? theme.colors.error: '#232129'};

    color: ${theme.colors.title};
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0 0 0 10px;
    cursor: ${disabled ? 'normal' : 'pointer'};
    transition: all 0.4s ease;

    &:hover {
      opacity: ${disabled ? 1 : 0.7};
    }

    img {
      position: absolute;
      right: 10px;
    }
  `}
`;

export const ErrorText = styled.p`
  ${({ theme }) => css`
    font-size: 12px;
    font-family: ${theme.font.family};
    color: ${theme.colors.error};
    margin-top: 5px;
  `}
`;