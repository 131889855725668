import React, { useEffect, useState } from "react";

import { Dropdown, DropdownOption } from "components/DropDown";
import { Input } from "components/Input";
import { CommentsTextArea } from "components/CommentsTextArea";
import { Button } from "components/Button";
import { ServiceDTO, ServiceForListProps } from "dtos/ServiceDTO";
import { useToast } from "hooks/toast";

import { useServiceFormTree } from "hooks/serviceFormTree";
import { useServiceDropDown } from "hooks/servicesDropDown";
import { useSelectedService } from "hooks/budget/selectedServices";
import { useConfirmModalClient } from "hooks/budget/confirmModalClient";

import { api } from "services/api";

import { ServiceName } from "components/ModalServices/Areas/ServiceName";
import { Equipments } from "components/ModalServices/Areas/Equipments";
import { EquipmentTypes } from "components/ModalServices/Areas/EquipmentTypes";
import { Capacity } from "components/ModalServices/Areas/Capacity";

import arrowLeft from "assets/icons/arrow-left.svg";

import * as S from "./styles";
import { ChecklistItemList } from "pages/fieldService/ServiceChecklist/types";
import { EditButton } from "components/EditButton";
import { DeleteButton } from "components/DeleteButton";
import { CardChecklist } from "components/CardChecklist";
import { useServiceChecklist } from "hooks/serviceChecklist";
import { AlertModal } from "components/AlertModal";
import { DiscardInformationModal } from "components/DiscardInformationModal";
import axios from "axios";
import { isEqual } from "lodash";
import { ShowWhenHavePermission } from "components/Permission";
import { useAccount } from "hooks/permission/account";
import { LoadingPageModal } from "components/LoadingPageModal";

const runTimeExtensionOptions = [
  { id: 1, name: "Minutos" },
  { id: 2, name: "Horas" },
  { id: 3, name: "Dias" },
];

const warrantyExtensionOptions = [
  { id: 1, name: "Dias" },
  { id: 2, name: "Meses" },
  { id: 3, name: "Anos" },
];

type ExtensionProps = {
  id: number;
  name: string;
};

type FormEditServiceProps = {
  data: ServiceDTO | ServiceForListProps;
  handleGoBack: () => void;
  handleAddChecklist?: () => void;
  handleEditPage?: () => void;
  handleSetDataFunction?: (value: any) => void;
};

export function FormEditService({
  data,
  handleGoBack,
  handleAddChecklist,
  handleEditPage,
  handleSetDataFunction,
}: FormEditServiceProps) {
  const { addToast } = useToast();
  const { categoriesTree, handleSetCategoriesTree } = useServiceFormTree();
  const {
    selectedCategoryId,
    selectedService,
    selectedEquipment,
    selectedEquipmentType,
    selectedCapacity,
    handleCategoryId,
    handleService,
    handleEquipment,
    handleEquipmentType,
    handleCapacity,
    reset,
    handleSelectedChecklists,
    serviceChecklistCxt,
    isEqualService,
    handleSetEqualService,
  } = useServiceDropDown();
  const { updatedSelectedService, checkedServices, handleSetCheckedServices } =
    useSelectedService();

  const {
    handleSetChecklistDetail,
    handleSetIsNewChecklist,
    handleSetEditPage,
    handleSetIsNotMenusAccess,
    handleSetSelectedItemChecklist,
  } = useServiceChecklist();

  const { setShowToastModalRight } = useConfirmModalClient();

  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingButton, setIsLoadingButton] = useState(false);

  const [brand, setBrand] = useState("");
  const [runTime, setRunTime] = useState("");
  const [runTimeExtension, setRunTimeExtension] = useState(
    {} as ExtensionProps
  );
  const [warrantyTime, setWarrantyTime] = useState("");
  const [warrantyExtension, setWarrantyExtension] = useState(
    {} as ExtensionProps
  );
  const [observationText, setObservationText] = useState("");
  const [price, setPrice] = useState<number | string>(0);
  const [unMaskedPrice, setUnmaskedPrice] = useState(0);

  const [hasErrorOnCategory, setHasErrorOnCategory] = useState("");
  const [hasErrorOnServiceName, setHasErrorOnServiceName] = useState("");
  const [hasErrorOnRunTime, setHasErrorOnRunTime] = useState("");
  const [hasErrorOnRuntimeExtension, setHasErrorOnRuntimeExtension] =
    useState("");
  const [hasErrorOnWarrantyTime, setHasErrorOnWarrantyTime] = useState("");
  const [hasErrorOnWarrantyExtension, setHasErrorOnWarrantyExtension] =
    useState("");
  const [hasErrorOnPrice, setHasErrorOnPrice] = useState("");

  const [serviceChecklist, setServiceChecklist] = useState<ChecklistItemList[]>(
    []
  );

  const [isOpenAlertModalDelete, setIsOpenAlertModalDelete] = useState(false);

  const [indexDelete, setIndexDelete] = useState<number>();

  const [warningMessage, setWarningMessage] = useState(false);

  const [showCompareAlert, setShowCompareAlert] = useState(false);
  const [backupService, setBackupService] = useState({});
  const { whoami } = useAccount();

  function handleDeleteRegister(ind: number) {
    let aux = serviceChecklist.filter((element, index) => {
      return index !== ind;
    });
    let auxData = data;

    setServiceChecklist(aux);

    auxData.serviceChecklist = aux;

    if (handleSetDataFunction) handleSetDataFunction(auxData);
  }

  async function getCategories() {
    try {
      if (data.id) {
        const response = await api.get(
          `/services/new-service-form/${data.id}`,
          {
            params: {
              accountId: whoami?.id,
            },
          }
        );

        const { categoriesTree: categoriesTreeResponse } = response.data;

        handleSetCategoriesTree(categoriesTreeResponse);
      }
    } catch (err) {
      // sendError(err);
    } finally {
      setIsLoading(false);
    }
  }

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (!isLoading) {
      if (!selectedCategoryId) {
        setHasErrorOnCategory("Categoria é obrigatório");
        setShowToastModalRight(true);
      }

      if (!selectedService.id) {
        setHasErrorOnServiceName("Serviço é obrigatório");
        setShowToastModalRight(true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCategoryId, selectedService]);

  useEffect(() => {
    getCategories();

    handleCategoryId(data.categoryId);
    handleService({ id: data.service.id, name: data.service.name });
    handleEquipment({ id: data.equipment.id, name: data.equipment.name });
    setPrice(data.price);
    setUnmaskedPrice(data.price);

    if (data.equipmentType?.id && data.equipmentType?.name)
      handleEquipmentType({
        id: data.equipmentType.id,
        name: data.equipmentType.name,
      });
    if (data.capacity?.id && data.capacity?.name)
      handleCapacity({ id: data.capacity.id, name: data.capacity.name });
    if (data?.brand) setBrand(data.brand);
    if (data?.description) setObservationText(data.description);
    if (data.runtime?.time) setRunTime(String(data.runtime.time));
    if (data.warranty?.time) setWarrantyTime(String(data.warranty.time));
    if (data?.serviceChecklist) {
      handleSetSelectedItemChecklist(data?.serviceChecklist);
      setServiceChecklist(data.serviceChecklist);
      handleSelectedChecklists(data.serviceChecklist);
    }

    const findRunTimeExtension = runTimeExtensionOptions.find(
      (currentRunTimeExtension) =>
        currentRunTimeExtension.name === data.runtime?.extension
    );
    if (findRunTimeExtension) {
      setRunTimeExtension(findRunTimeExtension);
    }

    const findWarrantyExtension = warrantyExtensionOptions.find(
      (currentWarrantyExtension) =>
        String(currentWarrantyExtension.name) === data.warranty?.extension
    );
    if (findWarrantyExtension) {
      setWarrantyExtension(findWarrantyExtension);
    }

    const formattedData = JSON.parse(JSON.stringify(data));

    ["type", "formattedTotal", "checked", "quantity", "total"].map((prop) => {
      return Reflect.deleteProperty(formattedData, prop);
    });
    setBackupService(formattedData);
    return () => {
      reset();
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (Object.keys(backupService).length < 1) return;
    const service = setUpService();
    handleSetEqualService(isEqual(backupService, service));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    runTime,
    runTimeExtension,
    warrantyTime,
    warrantyExtension,
    selectedCategoryId,
    selectedService,
    selectedEquipment,
    selectedEquipmentType,
    selectedCapacity,
    unMaskedPrice,
    brand,
    observationText,
    serviceChecklistCxt,
  ]);

  function setUpService() {
    const runTimeFormatted = Number(runTime);
    const warrantyTimeFormatted = Number(warrantyTime);

    const updateServiceData = {
      id: data.id,
      categoryId: selectedCategoryId,
      service: {
        id: selectedService.id,
        name: selectedService.name,
      },
      equipment: {
        id: selectedEquipment.id || 0,
        name: selectedEquipment.name || "",
      },
      equipmentType: {
        id: selectedEquipmentType.id || 0,
        name: selectedEquipmentType.name || "",
      },
      capacity: {
        id: selectedCapacity.id || 0,
        name: selectedCapacity.name || "",
      },
      price: unMaskedPrice,
      runtime: {
        ...(runTimeFormatted !== 0 ? { time: runTimeFormatted } : {}),
        ...(runTimeExtension.name ? { extension: runTimeExtension.name } : {}),
      },
      warranty: {
        ...(warrantyTimeFormatted !== 0 ? { time: warrantyTimeFormatted } : {}),
        ...(warrantyExtension.name
          ? { extension: warrantyExtension.name }
          : {}),
      },
      description: observationText,
      brand,
      //checked: true,
      // TODO Desmarcar apos api estar pronta
      //enviar checklist do contexto no put
      serviceChecklist: serviceChecklistCxt,
    };
    return updateServiceData;
  }

  function handleSelectCategory({ id }: DropdownOption) {
    if (selectedCategoryId === id) {
      return;
    }

    handleCategoryId(id);
    handleService({} as DropdownOption);
    handleEquipment({} as DropdownOption);
    handleEquipmentType({} as DropdownOption);
    handleCapacity({} as DropdownOption);

    setHasErrorOnCategory("");
  }

  function handleWarrantyExtension({ id }: DropdownOption) {
    const findCurrentExtension = warrantyExtensionOptions.find(
      (extension) => extension.id === id
    );

    if (findCurrentExtension) {
      setWarrantyExtension(findCurrentExtension);
    }

    setHasErrorOnWarrantyExtension("");
  }

  function handleSelectRuntimeExtension({ id }: DropdownOption) {
    const findCurrentExtensionRunTime = runTimeExtensionOptions.find(
      (extension) => extension.id === id
    );

    if (findCurrentExtensionRunTime) {
      setRunTimeExtension(findCurrentExtensionRunTime);
    }

    setHasErrorOnRuntimeExtension("");
  }

  async function handleEditService() {
    if (!selectedCategoryId) {
      setHasErrorOnCategory("Categoria é obrigatório");
      addToast({
        title: "Ops!!",
        description: "Alguns campos são obrigatórios",
        type: "error",
      });
      return;
    } else {
      setHasErrorOnCategory("");
    }

    if (!selectedService.id) {
      setHasErrorOnServiceName("Serviço é obrigatório");
      addToast({
        title: "Ops!!",
        description: "Alguns campos são obrigatórios",
        type: "error",
      });
      return;
    } else {
      setHasErrorOnServiceName("");
    }

    const runTimeFormatted = Number(runTime);
    const warrantyTimeFormatted = Number(warrantyTime);

    if (runTimeFormatted <= 0 && runTimeExtension.name) {
      setHasErrorOnRunTime("Digite o tempo de execução.");
      addToast({
        title: "Ops!!",
        description: "Preencha o campo de tempo (Obrigatório)",
        type: "error",
      });
      return;
    } else {
      setHasErrorOnRunTime("");
    }

    if (runTimeFormatted > 0 && !runTimeExtension.name) {
      setHasErrorOnRuntimeExtension("Selecione uma das opções");

      addToast({
        title: "Ops!!",
        description: "Preencha o campo de tempo (Obrigatório)",
        type: "error",
      });
      return;
    } else {
      setHasErrorOnRuntimeExtension("");
    }

    if (warrantyTimeFormatted <= 0 && warrantyExtension.name) {
      setHasErrorOnWarrantyTime("Digite o tempo de garantia.");
      addToast({
        title: "Ops!!",
        description: "Preencha o campo de tempo (Obrigatório)",
        type: "error",
      });
      return;
    } else {
      setHasErrorOnWarrantyTime("");
    }

    if (warrantyTimeFormatted > 0 && !warrantyExtension.name) {
      setHasErrorOnWarrantyExtension("Selecione uma das opções");

      addToast({
        title: "Ops!!",
        description: "Preencha o campo de tempo (Obrigatório)",
        type: "error",
      });
      return;
    } else {
      setHasErrorOnWarrantyExtension("");
    }

    if (
      !selectedCategoryId ||
      !selectedService.id ||
      (runTimeFormatted <= 0 && runTimeExtension.name) ||
      (runTimeFormatted > 0 && !runTimeExtension.name) ||
      (warrantyTimeFormatted <= 0 && warrantyExtension.name) ||
      (warrantyTimeFormatted > 0 && !warrantyExtension.name)
    ) {
      return;
    }

    try {
      setIsLoadingButton(true);

      const updateServiceData = {
        id: data.id,
        categoryId: selectedCategoryId,
        service: {
          id: selectedService.id,
          name: selectedService.name,
        },
        equipment: {
          id: selectedEquipment.id ?? 0,
          name: selectedEquipment.name ?? "",
        },
        equipmentType: {
          id: selectedEquipmentType.id,
          name: selectedEquipmentType.name,
        },
        capacity: {
          id: selectedCapacity.id,
          name: selectedCapacity.name,
        },
        price: unMaskedPrice ? unMaskedPrice : 0,
        runtime: {
          ...(runTimeFormatted !== 0 ? { time: runTimeFormatted } : {}),
          ...(runTimeExtension.name
            ? { extension: runTimeExtension.name }
            : {}),
        },
        warranty: {
          ...(warrantyTimeFormatted !== 0
            ? { time: warrantyTimeFormatted }
            : {}),
          ...(warrantyExtension.name
            ? { extension: warrantyExtension.name }
            : {}),
        },
        description: observationText,
        brand,
        checked: true,
        // TODO Desmarcar apos api estar pronta
        //enviar checklist do contexto no put
        serviceChecklist: serviceChecklistCxt,
      };

      updatedSelectedService(updateServiceData);
      await api.put(`/service/${data.id}`, {
        categoryID: selectedCategoryId,
        serviceTypeID: selectedService.id,
        equipmentID: selectedEquipment.id,
        equipmentTypeID: selectedEquipmentType.id,
        capacityID: selectedCapacity.id,
        price: unMaskedPrice ? unMaskedPrice : 0,
        brand,
        runtime: {
          ...(runTimeFormatted !== 0 ? { time: runTimeFormatted } : {}),
          ...(runTimeExtension.name
            ? { extension: runTimeExtension.name }
            : {}),
        },
        warranty: {
          ...(warrantyTimeFormatted !== 0
            ? { time: warrantyTimeFormatted }
            : {}),
          ...(warrantyExtension.name
            ? { extension: warrantyExtension.name }
            : {}),
        },
        description: observationText,
        checklistId: serviceChecklistCxt[0].id,
      });

      handleSetSelectedItemChecklist([]);

      addToast({
        title: "Sucesso",
        description: "Serviço atualizado com sucesso",
        type: "success",
      });

      handleGoBack();
    } catch (error) {
      addToast({
        title: "Ops!!",
        description:
          axios.isAxiosError(error) && error.response?.data.error
            ? error.response.data.error
            : "Ocorreu um erro ao atualizar o serviço.",
        type: "error",
      });
    } finally {
      setIsLoadingButton(false);
    }
  }

  function handleClearRunTimeExtension() {
    setRunTimeExtension({} as ExtensionProps);
  }

  function handleClearWarrantyExtension() {
    setWarrantyExtension({} as ExtensionProps);
  }

  function handleChangePrice(text: string) {
    setPrice(text);
  }

  async function detailItem(currentChecklist: ChecklistItemList) {
    try {
      //setando os detalhes do checklist
      handleSetChecklistDetail(currentChecklist);

      //setado o tipo de tela para detalhe do checklist.
      handleSetIsNewChecklist(false);
      //setCurrentPageModal("checklistPage");
      //setisOpenModalChecklist(true);
      if (handleEditPage) handleEditPage();
    } catch (error) {
      addToast({
        title: "Ops!!",
        description: "Não foi possível obter os detalhes do checklist.",
        type: "error",
      });
    } finally {
    }
  }

  function updateCheckedService() {
    const updatedCheckedServices = checkedServices.map((checkedService) => {
      if (checkedService.id === data.id) {
        return {
          ...data,
          idBudgetService: checkedService.idBudgetService,
          formattedTotal: price.toString(),
          total: unMaskedPrice,
          price: unMaskedPrice,
          quantity: checkedService.quantity,
          checked: true,
        };
      }

      return checkedService;
    });

    handleSetCheckedServices(updatedCheckedServices);
  }

  function validateFieldsOnClose() {
    if (!selectedService.id) {
      setHasErrorOnServiceName("Serviço é obrigatório");

      setWarningMessage(true);
    } else {
      setHasErrorOnServiceName("");

      handleGoBack();
    }
  }

  function validateFields() {
    if (selectedService.id && selectedService.id) {
      setShowToastModalRight(false);
    }
  }

  return isLoading ? (
    <LoadingPageModal />
  ) : (
    <>
      <AlertModal
        isVisible={isOpenAlertModalDelete}
        title="Deseja apagar?"
        description={"Deseja realmente apagar esse checklist?"}
        action="choose"
        handleConfirm={() => {
          handleDeleteRegister(indexDelete as number);
          setIsOpenAlertModalDelete(false);
        }}
        onCloseModal={() => setIsOpenAlertModalDelete(false)}
      />

      <AlertModal
        isVisible={warningMessage}
        title="Retornar"
        description="É necessário revisar os campos obrigatórios"
        action="choose"
        handleConfirm={() => {
          setWarningMessage(false);
        }}
        onCloseModal={() => {
          setWarningMessage(false);
        }}
        labelConfirm={"Continuar editando"}
      />

      <DiscardInformationModal
        showModal={showCompareAlert}
        handleConfirmButton={() => {
          setShowCompareAlert(false);
          handleSetSelectedItemChecklist([]);
          handleSetIsNotMenusAccess(false);
          validateFieldsOnClose();
          handleSetEqualService(true);
        }}
        handleCancelButton={() => {
          setShowCompareAlert(false);
        }}
      />

      <S.Top>
        <S.ButtonClose
          onClick={() => {
            if (!isEqualService) {
              setShowCompareAlert(true);
              return;
            }
            handleSetSelectedItemChecklist([]);
            handleSetIsNotMenusAccess(false);
            validateFieldsOnClose();
          }}
        >
          <img src={arrowLeft} alt="flecha pra esquerda" />
        </S.ButtonClose>

        <p>Editar serviço</p>
      </S.Top>

      <S.Content>
        <S.Wrapper>
          <Dropdown
            label="Categoria*"
            placeholder="Selecione uma categoria"
            options={categoriesTree}
            categoryIdSelected={selectedCategoryId}
            onClickedValue={handleSelectCategory}
            hasError={hasErrorOnCategory}
          />
        </S.Wrapper>

        <S.Wrapper>
          <ServiceName
            hasErrorOnService={hasErrorOnServiceName}
            moduleHash="service-type"
          />
        </S.Wrapper>

        <S.Wrapper>
          <Equipments hasErrorOnEquipment={""} moduleHash="equipment" />
        </S.Wrapper>

        <S.Wrapper>
          <EquipmentTypes moduleHash="equipment-type" />
        </S.Wrapper>

        <S.Wrapper>
          <Capacity moduleHash="equipment-capacity" />
        </S.Wrapper>

        <S.Wrapper>
          <Input
            name="Marca (opcional)"
            type="text"
            placeholder="Ex: Consul"
            value={brand}
            onChange={(text) => setBrand(text.currentTarget.value.trimStart())}
          />
        </S.Wrapper>

        <S.Wrapper>
          <S.Row>
            <S.WrapperInput>
              <span> Tempo de execução (opcional) </span>
              <Input
                type="number"
                placeholder="Ex: 2"
                onChange={(text) => setRunTime(text.currentTarget.value)}
                value={runTime}
                hasError={hasErrorOnRunTime}
                onFocus={() => setHasErrorOnRunTime("")}
              />
            </S.WrapperInput>

            <S.WrapperDrodown>
              <Dropdown
                placeholder="Selecione"
                options={runTimeExtensionOptions}
                categoryIdSelected={runTimeExtension.id}
                // eslint-disable-next-line react/jsx-no-bind
                onClickedValue={handleSelectRuntimeExtension}
                hasError={hasErrorOnRuntimeExtension}
                clearDropDownItem
                // eslint-disable-next-line react/jsx-no-bind
                handleClear={handleClearRunTimeExtension}
              />
            </S.WrapperDrodown>
          </S.Row>
        </S.Wrapper>

        <S.Wrapper>
          <S.Row>
            <S.WrapperInput>
              <span> Garantia (opcional)</span>
              <Input
                type="number"
                placeholder="Ex: 180"
                onChange={(text) => setWarrantyTime(text.currentTarget.value)}
                value={warrantyTime}
                hasError={hasErrorOnWarrantyTime}
                onFocus={() => setHasErrorOnWarrantyTime("")}
              />
            </S.WrapperInput>

            <S.WrapperDrodown>
              <Dropdown
                placeholder="Selecione"
                options={warrantyExtensionOptions}
                categoryIdSelected={warrantyExtension.id}
                // eslint-disable-next-line react/jsx-no-bind
                onClickedValue={handleWarrantyExtension}
                hasError={hasErrorOnWarrantyExtension}
                clearDropDownItem
                // eslint-disable-next-line react/jsx-no-bind
                handleClear={handleClearWarrantyExtension}
              />
            </S.WrapperDrodown>
          </S.Row>
        </S.Wrapper>

        <S.Wrapper>
          <CommentsTextArea
            label="Detalhamento do Serviço (opcional)"
            value={observationText}
            onChange={(event) =>
              setObservationText(event.target.value.trimStart())
            }
          />
        </S.Wrapper>

        <S.Wrapper>
          <Input
            name="Valor (opcional)"
            maskType="money"
            valueCurrency={price}
            onChangeCurrency={({ formattedValue, floatValue }) => {
              handleChangePrice(formattedValue);
              setUnmaskedPrice(floatValue!);
            }}
            hasError={hasErrorOnPrice}
            onFocusClearError={() => setHasErrorOnPrice("")}
          />
        </S.Wrapper>
        <ShowWhenHavePermission
          moduleHash="service-checklist"
          actionHash="view"
        >
          {/*checklists*/}
          {serviceChecklist.length > 0 && (
            <S.Wrapper>
              {serviceChecklist.map((currentChecklist, index) => {
                return (
                  <S.WrapperService key={index}>
                    <CardChecklist>
                      <S.WrapperInfoCard>
                        <S.WrapperData>
                          <h6>{currentChecklist?.name}</h6>
                          <p>{currentChecklist?.registers?.length} Registros</p>
                        </S.WrapperData>
                        <S.WrapperButtom>
                          {/*validar o checklist padrao nao pode ser editado...*/}

                          <ShowWhenHavePermission
                            moduleHash="service-checklist"
                            actionHash="edit"
                          >
                            <EditButton
                              onClick={() => {
                                handleSetEditPage(true);
                                detailItem(currentChecklist);
                              }}
                            />
                          </ShowWhenHavePermission>
                          <ShowWhenHavePermission
                            moduleHash="service-checklist"
                            actionHash="delete"
                          >
                            <DeleteButton
                              disabled={true}
                              onClick={() => {
                                setIndexDelete(index);
                                setIsOpenAlertModalDelete(true);
                                //handleDeleteRegister(index)
                              }}
                            />
                          </ShowWhenHavePermission>
                        </S.WrapperButtom>
                      </S.WrapperInfoCard>
                    </CardChecklist>
                  </S.WrapperService>
                );
              })}
            </S.Wrapper>
          )}

          {/* TODO comentar os botão antes de subir pra api */}

          <S.Wrapper>
            <Button
              typeButton="outline"
              onClick={() => {
                if (handleAddChecklist) {
                  handleSetEditPage(false);
                  handleAddChecklist();
                }
              }}
            >
              Incluir checklists
            </Button>
          </S.Wrapper>
        </ShowWhenHavePermission>

        <S.Wrapper>
          <Button
            loading={isLoadingButton}
            onClick={() => {
              updateCheckedService();
              handleSetIsNotMenusAccess(false);
              handleEditService();
              validateFields();
            }}
          >
            Salvar
          </Button>
        </S.Wrapper>
      </S.Content>
    </>
  );
}
