import styled, { css } from "styled-components";
import media from "styled-media-query";

export const Container = styled.div`
  ${({ theme }) => css`
    width: 100%;
    max-width: 1400px;
    margin: 0 auto;
    padding: 30px;

    header {
      width: 64%;
      display: flex;
      align-items: center;
      justify-content: space-around;

      ${media.lessThan("large")`
        flex-direction: column;
        align-items: flex-start;
        & > div:last-of-type {
          margin-top: 20px;
          margin-left: 0;
        }
      `}

      h1 {
        font-size: ${theme.font.sizes.large};
        color: ${theme.colors.title};
        font-weight: ${theme.font.bold};
        margin-left: 0px;
        margin-right: 40px;
      }

      & > div {
        display: flex;
        align-items: center;
        justify-content: space-between;
      }

      & > div:last-of-type {
        width: 60%;
        min-width: 340px;
      }
    }
  `}
`;

export const Content = styled.div`
  width: 46%;
  min-height: 80vh;
  min-width: 470px;
  margin-top: 30px;
  & > div:first-of-type {
    margin: 20px 0 30px;
  }
`;

export const WrapperEmptyMessage = styled.div`
  ${({ theme }) => css`
    height: 300px;
    padding-top: 30px;
    p {
      margin-top: 5px;
      margin-bottom: 5px;
      color: ${theme.colors.financial_text};
      font-size: ${theme.font.sizes.small};
      letter-spacing: 0.26px;
      text-align: center;
      line-height: 16px;
    }
  `};
`;

export const SubText = styled.p`
  ${({ theme }) => css`
    margin-top: 5px;
    margin-bottom: 20px;
    color: ${theme.colors.financial_text};
    font-size: ${theme.font.sizes.small};
    font-width: ${theme.font.normal};
    text-align: left;
    letter-spacing: 0.26px;
    line-height: 16px;
  `};
`;

export const DivMessage = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: row;
    align-items: center;
    img {
      padding-right: 15px;
      height: 35px;
    }
    p {
      color: ${theme.colors.financial_text};
      font-size: ${theme.font.sizes.small};
      font-width: ${theme.font.normal};
      text-align: left;
      letter-spacing: 0.26px;
      line-height: 16px;
    }
  `};
`;
export const ButtonClose = styled.button`
  ${({ theme }) => css`
    width: 35px;
    height: 35px;
    background-color: ${theme.colors.background_light};
    border-radius: 10px;

    display: flex;
    justify-content: center;
    align-items: center;
  `}
`;

export const WrapperInput = styled.div`
  margin-bottom: 15px;
`;

export const WrapperService = styled.div`
  margin-bottom: 20px;
`;

export const WrapperInfoCard = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
  border-radius: 10px;
  min-height: 90px;
  align-items: center;
  justify-content: space-between;
`;

export const ContainerImg = styled.div`
  display: flex;
  flex-direction: row;
  margin-left: 15px;
`;

export const WrapperData = styled.div`
  ${({ theme }) => css`
    padding: 10px;
    padding-left: 30px;
    max-width: 360px;
    h6 {
      margin-top: 5px;
      color: ${theme.colors.title};
      font-size: ${theme.font.sizes.small};
      text-align: left;
      letter-spacing: 0.26px;
      line-height: 16px;
      margin-bottom: 10px;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: normal;
    }

    p {
      margin-top: 5px;
      color: ${theme.colors.text_light};
      font-size: ${theme.font.sizes.xsmall};
      text-align: left;
      letter-spacing: 0.26px;
      line-height: 16px;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: pre-wrap;
    }
  `};
`;

export const WrapperButtonContent = styled.div`
  max-width: 10%;
  height: 87px;
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export const WrapperButtom = styled.div`
  ${({ theme }) => css`
    width: 100%;
    height: 100%;
    max-height: 100px;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 0px;
    background-color: ${theme.colors.background_light};

    display: flex;
    flex-direction: column;

    & + & {
      border-top-right-radius: 0;
      border-bottom-right-radius: 10px;
    }
  `}
`;
