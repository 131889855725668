import React from 'react'

import * as S from './styles'

export function ProfileCertificationsShimmer() {
  return (
    <>
      <div>
        <S.ShimmerCertification />
      </div>

      <div>
        <S.ShimmerCertification />
      </div>

      <div>
        <S.ShimmerCertification />
      </div>

      <div>
        <S.ShimmerCertification />
      </div>
    </>
  )
}
