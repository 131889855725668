import React, { useState } from "react";
import { MdClose } from "react-icons/md";
import { useTheme } from "styled-components";
import { Button } from "components/Button";

import * as S from "./styles";

type ModalProps = {
  isVisible: boolean;
  description: string;
  labelConfirm?: string;
  labelCancel?: string;
  action: "confirm" | "choose";
  handleConfirm: (type: string) => void;
  handleCancel?: () => void;
  onCloseModal: () => void;
  zIndex?: number;
};

export function AlertModalCheckbox({
  isVisible,
  description,
  labelConfirm = "Confirmar",
  labelCancel = "Fechar",
  action,
  handleConfirm,
  onCloseModal,
  zIndex,
}: ModalProps) {
  const theme = useTheme();
  const [checked, setChecked] = useState("orcamento");

  return (
    <S.ContainerOverlay isVisible={isVisible} style={{ zIndex }}>
      <S.BoxModal>
        <S.Header>
          <button type="button" onClick={() => onCloseModal()}>
            <MdClose size={28} color={theme.colors.text} />
          </button>
        </S.Header>

        <S.Main>
          <S.Description>{description}</S.Description>
        </S.Main>

        <S.Footer>
          {action === "confirm" ? (
            <S.ButtonConfirm onClick={() => onCloseModal()}>OK</S.ButtonConfirm>
          ) : (
            <S.WrapperButtons>
              <S.CheckboxWrapper onClick={() => setChecked("first")}>
                <S.CheckBox checked={checked === "first"}>
                  <div />
                </S.CheckBox>
                <S.CheckboxText>{labelConfirm}</S.CheckboxText>
              </S.CheckboxWrapper>

              <S.CheckboxWrapper onClick={() => setChecked("second")}>
                <S.CheckBox checked={checked === "second"}>
                  <div />
                </S.CheckBox>
                <S.CheckboxText>{labelCancel}</S.CheckboxText>
              </S.CheckboxWrapper>
            </S.WrapperButtons>
          )}
        </S.Footer>

        <Button
          onClick={() => handleConfirm(checked)}
          style={{ marginTop: "30px" }}
          typeButton="default"
        >
          Salvar
        </Button>
      </S.BoxModal>
    </S.ContainerOverlay>
  );
}
