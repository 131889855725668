import React, { useState, TextareaHTMLAttributes } from "react";
import { FiAlertCircle } from "react-icons/fi";
import { useTheme } from "styled-components";

import * as S from "./styles";

interface CommentsTextAreaProps
  extends TextareaHTMLAttributes<HTMLTextAreaElement> {
  label?: string;
  buttonText?: string;
  hasError?: string;
  onPressTextPattern?: () => void;
  onFocusClearError?: () => void;
  handleEditingBlur?: () => void;
}

export function CommentsTextArea({
  label,
  buttonText = "",
  hasError = "",
  onPressTextPattern,
  onFocusClearError,
  handleEditingBlur,
  ...rest
}: CommentsTextAreaProps) {
  const theme = useTheme();
  const [isFocused, setIsFocused] = useState(false);

  function handleInputBlur() {
    setIsFocused(false);
    handleEditingBlur?.();
  }

  function handleOnfocus() {
    if (onFocusClearError) {
      onFocusClearError();
    }
    setIsFocused(true);
  }

  return (
    <>
      {!!label && <S.Label>{label}</S.Label>}

      <S.Container isErrored={!!hasError} isFocused={isFocused}>
        <textarea onFocus={handleOnfocus} onBlur={handleInputBlur} {...rest} />

        {buttonText && (
          <S.ButtonComments onClick={onPressTextPattern}>
            {buttonText}
          </S.ButtonComments>
        )}

        {!!hasError && (
          <S.Error title={hasError}>
            <FiAlertCircle color={theme.colors.error} size="20" />
          </S.Error>
        )}
      </S.Container>
    </>
  );
}
