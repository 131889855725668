import styled, { css } from "styled-components";

export const Container = styled.div`
  ${({ theme }) => css`
    & > p {
      margin-bottom: 5px;
      color: ${theme.colors.text};
    }
  `}
`;

export const Content = styled.button`
  ${({ theme }) => css`
    width: 100%;
    padding: 17px 15px;
    border-radius: 8px;
    border: 1px solid transparent;
    background: ${theme.colors.menu_background};

    display: flex;
    flex-direction: column;
    align-items: flex-start;
    transition: background 0.2s;

    &:focus-within {
      border: 1px solid ${theme.colors.primary};
    }

    &:hover {
      background: ${theme.colors.background_light};
    }

    h4 {
      font-size: ${theme.font.sizes.xsmall};
      color: ${theme.colors.title};
      margin-bottom: 12px;
    }

    p {
      color: ${theme.colors.text};
      font-size: ${theme.font.sizes.xsmall};
      margin-bottom: 6px;
    }

    span {
      color: ${theme.colors.text};
      font-size: ${theme.font.sizes.xsmall};
    }
  `}
`;
