/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router";
import debounce from "lodash.debounce";

import DownloadSvg from "assets/icons/download-button.svg";

import { useToast } from "hooks/toast";
import { useAccount } from "hooks/permission/account";
import { useAuth } from "hooks/auth";

import { EmptyPage } from "components/EmptyPage";
import { LoadingProfiz } from "components/LoadingProfiz";
import { SearchInput } from "components/SearchInput";
import { ModalRejectedRegistration } from "components/ModalRejectedRegistration";

import apiv2 from "services/apiv2";

import { TablePagination } from "components/TablePagination";

import * as S from "./styles";
import { searchDatalayer } from "utils/pushDataLayer";

type ServiceProps = {
  id: number;
  idBudgetService: number;
  scheduledDate: string;
  service: string;
};

export type ServiceOrdersResponse = {
  id: number;
  sequenceNumber: number;
  detailLink: string;
  status: string;
  client: {
    name: string;
  };
  services: ServiceProps[];
};

export function Pending() {
  const { user } = useAuth();
  const { whoami } = useAccount();
  const history = useHistory();
  const { addToast } = useToast();

  const [isLoading, setIsLoading] = useState(true);

  const [searchInputValue, setSearchInputValue] = useState("");

  const [allServiceOrdersPending, setAllServiceOrdersPending] = useState<
    ServiceOrdersResponse[]
  >([]);
  const [inputLoading, setInputLoading] = useState(false);

  const [totalItems, setTotalItems] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [offset, setOffset] = useState(0);
  const [activeModalRejectedRegistration, setActiveModalRejectedRegistration] =
    useState(false);
  const searchLimit = 50;

  useEffect(() => {
    getUnscheduledServiceOrder();
  }, [offset, whoami?.id]);

  async function getUnscheduledServiceOrder() {
    try {
      setIsLoading(true);
      if (whoami?.id) {
        const response = await apiv2.get(`budgets/service-orders`, {
          params: {
            offset: offset,
            limit: searchLimit,
            status: "new|scheduled",
            archive: false,
            orderBy: "date-DESC",
            accountId: whoami?.id,
          },
        });

        setTotalItems(response.data.info.total);

        const { ordens } = response.data;

        setAllServiceOrdersPending(ordens);
      }
    } catch (error) {
      addToast({
        title: "Ops!!",
        description:
          "Ocorreu um erro ao listar as ordens de serviços pendentes",
        type: "error",
      });
    } finally {
      setIsLoading(false);
      setInputLoading(false);
    }
  }

  function handleSearchCancel() {
    setSearchInputValue("");
    handleSearchServiceOrderByClient("");
  }

  async function handleSearchServiceOrderByClient(client: string) {
    try {
      const response = await apiv2.get(`budgets/service-orders`, {
        params: {
          offset: 0,
          limit: searchLimit,
          status: "new|scheduled",
          client,
          archive: false,
          orderBy: "date-DESC",
          accountId: whoami?.id,
        },
      });

      const { ordens } = response.data;

      setTotalItems(response.data.info.total);

      setAllServiceOrdersPending(ordens);

      if (client) {
        searchDatalayer({ search_term: client, success: true });
      }
    } catch (error) {
      addToast({
        title: "Ops!!",
        description:
          "Ocorreu um erro ao listar as ordens de serviços pendentes",
        type: "error",
      });
    } finally {
      setIsLoading(false);
      setInputLoading(false);
    }
  }

  const debounceFn = useCallback(
    debounce(handleSearchServiceOrderByClient, 3000),
    []
  );

  function handleChange(searchValue: string) {
    setInputLoading(true);
    setSearchInputValue(searchValue);
    debounceFn(searchValue);
  }

  function handleRedirect(serviceOrderData: ServiceOrdersResponse) {
    if (user?.profileDisapproved) {
      setActiveModalRejectedRegistration(true);
      return;
    }

    setSearchInputValue("");

    const hasScheduled = serviceOrderData.services.some(
      (service) => !!service.scheduledDate
    );

    if (hasScheduled) {
      history.push(`/service-order/scheduled/${serviceOrderData.id}`);
      return;
    }

    history.push(`/service-order/pending/${serviceOrderData.id}`);
  }

  function handlePageChange(pageNumber: number, itemsPerPage: number) {
    setCurrentPage(pageNumber);
    setOffset((pageNumber - 1) * itemsPerPage);
  }

  function handleOpenPdfPage(pdfLink: string) {
    history.push({
      pathname: "/download/pdf",
      state: {
        pdfDownloadLink: pdfLink,
      },
    });
  }

  return (
    <>
      <ModalRejectedRegistration
        isVisible={activeModalRejectedRegistration}
        setIsActive={setActiveModalRejectedRegistration}
      />
      {isLoading ? (
        <LoadingProfiz isVisible={isLoading} />
      ) : (
        <S.Content>
          <S.ContainerSearch>
            <SearchInput
              loadingInput={inputLoading}
              searchValue={searchInputValue}
              placeholder="Procure pelo nome ou endereço do cliente"
              onChange={(event) => handleChange(event.target.value)}
              // eslint-disable-next-line react/jsx-no-bind
              handleCancel={handleSearchCancel}
            />
          </S.ContainerSearch>

          {allServiceOrdersPending.length === 0 ? (
            <EmptyPage />
          ) : (
            <table>
              <thead>
                <tr>
                  <th>Número</th>

                  <th> Serviço(s) </th>
                  <th> Cliente </th>
                  <th> Status </th>
                  {user?.profileDisapproved && <th> PDF </th>}
                </tr>
              </thead>
              <tbody>
                {allServiceOrdersPending.map((serviceOrder) => (
                  <tr
                    key={serviceOrder.id}
                    onClick={() => {
                      handleRedirect(serviceOrder);
                    }}
                  >
                    <td className="columm-id">{serviceOrder.sequenceNumber}</td>

                    <td>
                      {serviceOrder.services.map(
                        ({ service: serviceName, idBudgetService }, index) => {
                          if (index > 3) return null;
                          if (index === 3) return <p>...</p>;
                          return (
                            <p key={idBudgetService}>
                              {serviceName}
                              {index < serviceOrder.services.length - 1 && ", "}
                            </p>
                          );
                        }
                      )}
                    </td>
                    <td>{serviceOrder.client.name}</td>

                    <td>
                      <span /> Em Andamento
                    </td>
                    {user?.profileDisapproved && (
                      <td
                        onClick={() =>
                          handleOpenPdfPage(serviceOrder?.detailLink)
                        }
                        style={{
                          width: 70,
                        }}
                      >
                        <S.ContainerImage>
                          <img src={DownloadSvg} alt="Download" />
                        </S.ContainerImage>
                      </td>
                    )}
                  </tr>
                ))}
              </tbody>
            </table>
          )}

          <S.ContainerPagination>
            <TablePagination
              rowsLength={totalItems}
              rowsPerPage={searchLimit}
              rowsPage={currentPage}
              handlePageChange={(pageNumber, itemsPerPage) =>
                handlePageChange(pageNumber, itemsPerPage)
              }
            />
          </S.ContainerPagination>
        </S.Content>
      )}
    </>
  );
}
