import { keyframes } from "styled-components";

export const fadeInfo = keyframes`
  0% {
    opacity: 1;
    transform: translateX(0);
  }
  50% {
    opacity: 0;
    transform: translateX(500px);
  }

  100% {
    opacity: 0;
    transform: translateX(1000px);
    display: none;
  }
`;

export const translateToTop = keyframes`
  from {
    transform: translate(0, 0);
  }

  to {
    transform: translate(50px, -160px);
  }
`;

export const translateToRight = keyframes`
  from {
    transform: translate(0, 0);
  }

  to {
    transform: translate(190px, -160px);
  }
`;

export const translateToRightImg = keyframes`
  from {
    transform: translateX(0);
  }

  to {
    transform: translateX(240px);
  }
`;

export const fadeFormToTop = keyframes`
  from {
    opacity: 0;
    transform: translateY(300px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

export const translateStarsToTop = keyframes`
  from {
    transform: translateY(0);
  }

  to {
    transform: translateY(-10px);
  }
`;
