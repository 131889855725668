import styled, { css } from "styled-components";

export const Container = styled.div`
  ${({ theme }) => css`
    background-color: ${theme.colors.strong_background};
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    width: 100%;
    padding: 10px;
    margin-left: 200px;
  `}
`;

export const Title = styled.h1`
  ${({ theme }) => css`
    font-family: ${theme.font.family};
    color: ${theme.colors.text_extra_light};
    font-size: 32px;
    line-height: 38px;
    font-weight: 700;

    span {
      color: ${theme.colors.primary};
    }
  `}
`;

export const Line = styled.div`
  ${({ theme }) => css`
    border: 1px solid ${theme.colors.text};
    width: 450px;
    margin-top: 25px;
    margin-bottom: 25px;
  `}
`;

export const SubTitle = styled.p`
  ${({ theme }) => css`
    font-family: ${theme.font.family};
    font-size: 18px;
    line-height: 27px;
    color: ${theme.colors.text_extra_light};
    text-align: start;
  `}
`;
