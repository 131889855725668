import styled, { css } from "styled-components";

interface MarkerStatusProps {
  status: string;
}

export const Content = styled.div``;

export const WrapperCalendar = styled.div`
  ${({ theme }) => css`
    margin-bottom: 40px;
    background: ${theme.colors.background_light};
    border-radius: 8px;

    display: flex;
    flex-direction: column;
    align-items: center;

    aside {
      width: 100%;
      display: flex;
      justify-content: center;
    }

    .DayPicker-wrapper {
      padding: 10px 2px;
    }

    .DayPicker,
    .DayPicker-Month {
      width: 90%;
      border-spacing: 16px;
    }

    .DayPicker-Day,
    .DayPicker-Day--selected {
      height: 36px;
    }
  `}
`;

export const WrapperInput = styled.div`
  margin-top: 10px;
  width: 80%;

  input {
    border-radius: 8px;
  }

  input[type="time"]::-webkit-calendar-picker-indicator {
    filter: invert(1);
  }
`;

export const WrapperButtons = styled.div`
  display: flex;

  width: 80%;
  margin-top: 20px;
  margin-bottom: 40px;
  margin-left: 10px;

  > button {
    margin: 0px 10px 0px 0px;
  }
`;

export const Description = styled.p`
  ${({ theme }) => css`
    font-size: ${theme.font.sizes.small};
    color: ${theme.colors.title};
    margin-bottom: 20px;
  `}
`;

export const WrapperOrder = styled.div`
  ${({ theme }) => css`
    margin-bottom: 26px;

    & > span {
      display: block;
      font-size: ${theme.font.sizes.xsmall};
      color: ${theme.colors.title};
      margin-bottom: 10px;
    }
  `}
`;

export const DeleteScheduleButton = styled.button`
  ${({ theme }) => css`
    width: 80%;
    margin-bottom: 40px;
    display: flex;
    align-items: center;
    cursor: pointer;
    transition: all 0.5s;

    & > p {
      margin-left: 10px;
      font-size: ${theme.font.sizes.small};
      font-family: ${theme.font.normal};
      color: ${theme.colors.text};
    }

    &:hover {
      opacity: 0.6;
    }
  `}
`;

export const WrapperStatusService = styled.div`
  display: flex;
  align-items: center;
`;

export const MarkerStatusService = styled.div<MarkerStatusProps>`
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background-color: ${({ status, theme }) =>
    status === "concluded" ? theme.colors.primary : theme.colors.model};
  margin-right: 6px;
`;
