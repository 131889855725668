import React, { useEffect, useState } from "react";

import * as S from "./styles";
import { Line } from "rc-progress";

interface PasswordVerificationProps {
  password: string;
}

interface scoreVerification {
  score?: number;
  color?: string;
  verification?: string;
}

const PasswordVerification = ({ password }: PasswordVerificationProps) => {
  const [passwordScore, setPasswordScore] = useState<scoreVerification>({});
  function scorePassword(password: string) {
    var score = 0;
    if (!password) return score;

    const letters: { [key: string]: number } = {};
    for (var i = 0; i < password.length; i++) {
      letters[password[i]] = (letters[password[i]] || 0) + 1;
      score += 5.0 / letters[password[i]];
    }

    const variations: { [key: string]: boolean } = {
      digits: /\d/.test(password),
      lower: /[a-z]/.test(password),
      upper: /[A-Z]/.test(password),
      nonWords: /\W/.test(password),
    };

    var variationCount = 0;
    for (var check in variations) {
      variationCount += variations[check] === true ? 1 : 0;
    }
    score += (variationCount - 1) * 10;
    return parseInt(score.toString());
  }

  useEffect(() => {
    function checkPassStrength() {
      var score = scorePassword(password);

      if (score >= 90)
        return { score, color: "#00ff6b", verification: "Muito forte" };
      if (score >= 90)
        return { score, color: "#0af56d", verification: "Muito forte" };
      if (score >= 80)
        return { score, color: "#14eb6e", verification: "Forte" };
      if (score >= 65)
        return { score, color: "#f2cf1f", verification: "Normal" };
      if (score >= 50)
        return { score, color: "#ff6900", verification: "Fraco" };
      if (score >= 35)
        return { score, color: "#ff6900", verification: "Fraco" };
      if (score >= 30)
        return { score, color: "#ff6900", verification: "Muito fraco" };
      if (score >= 20)
        return { score, color: "#ff5400", verification: "Muito fraco" };
      if (score >= 10)
        return { score, color: "#ff3e00", verification: "Muito fraco" };
      if (score >= 1)
        return { score, color: "#ff2900", verification: "Muito fraco" };

      return { score, color: "", verification: "" };
    }

    setPasswordScore(checkPassStrength());
  }, [password]);

  return (
    <S.Container color={passwordScore.color}>
      <Line
        percent={passwordScore.score}
        strokeWidth={1}
        strokeColor={passwordScore.color}
      />
      <span>{passwordScore.verification}</span>
    </S.Container>
  );
};
export { PasswordVerification };
