import { useState } from "react";
import { useHistory } from "react-router";
import * as Yup from "yup";

import { Page } from "templates/Page";
import { Input } from "components/Input";
import { Button } from "components/Button";
import {
  Label,
  ListItemCard,
  ModalProfileDetail,
  RoleAndPermission,
  mappedProfileIcons,
} from "components/Permission";

import { ButtonModalAction } from "components/ButtonModalAction";
import { ModalRight } from "components/ModalRight";

import { useAccount } from "hooks/permission/account";
import { useToast } from "hooks/toast";
import getValidationErrors from "utils/getValidationErrors";
import { Profile } from "dtos/permission/ProfileDTO";

import * as S from "./styles";

type Errors = {
  [key: string]: string;
};

export function InviteMember() {
  const history = useHistory();
  const { whoami } = useAccount();
  const { addToast } = useToast();

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [memberRole, setMemberRole] = useState<Profile>({} as Profile);

  const [loading, setLoading] = useState(false);
  const [hasError, setHasError] = useState<Errors>({} as Errors);
  const [showModalRoleAndPermission, setShowModalRoleAndPermission] =
    useState(false);
  const [showModalProfileDetail, setShowModalProfileDetail] = useState(false);

  async function handleSubmit(event: React.FormEvent) {
    try {
      event.preventDefault();
      setLoading(true);

      const schema = Yup.object().shape({
        name: Yup.string().required("Nome é obrigatório"),
        email: Yup.string()
          .required("E-mail é obrigatório")
          .email("Digite um e-mail válido"),
        roleId: Yup.number().required("Cargo é obrigatório"),
      });

      await schema.validate(
        {
          name,
          email,
          roleId:
            Object.keys(memberRole).length > 0 ? memberRole.id : undefined,
        },
        {
          abortEarly: false,
        }
      );
      setHasError({});
      history.push("/teams/management/invite/confirm", {
        name,
        email,
        memberRole,
      });
    } catch (error) {
      if (error instanceof Yup.ValidationError) {
        const errors = getValidationErrors(error);
        setHasError(errors);

        addToast({
          title: "Ops...",
          description: "Preencha todos os campos obrigatórios",
          type: "error",
        });

        return;
      }
    } finally {
      setLoading(false);
    }
  }

  function handleSelectRole(memberRole: Profile) {
    setShowModalRoleAndPermission(false);
    setMemberRole(memberRole);
  }

  return (
    <Page
      title="Convidar novo membro para equipe"
      subtitle="Preencha os dados da pessoa que você quer adicionar à sua equipe."
    >
      <ModalRight
        isOpen={showModalRoleAndPermission}
        handleToggleOpen={() => setShowModalRoleAndPermission(false)}
      >
        <RoleAndPermission handleSelectRole={handleSelectRole} />

        <Button typeButton="disabled" style={{ marginTop: 200 }}>
          Atribuir novo cargo
        </Button>
      </ModalRight>

      {showModalProfileDetail && (
        <ModalProfileDetail
          profile={memberRole}
          openModalDetail={showModalProfileDetail}
          handleToggleModal={() => setShowModalProfileDetail(false)}
        />
      )}

      <S.Content>
        <Input
          name="Nome*"
          placeholder="Ex: André Silva"
          value={name}
          onChange={(e) => setName(e.target.value.trimStart())}
          hasError={hasError.name}
          onFocusClearError={() => {
            setHasError({ ...hasError, name: "" });
          }}
        />

        <S.Wrapper>
          <Input
            name="Email*"
            placeholder="Ex: exemplo@exemplo.com.br"
            value={email}
            onChange={(e) => setEmail(e.target.value.trimStart())}
            hasError={hasError.email}
            onFocusClearError={() => {
              setHasError({ ...hasError, email: "" });
            }}
          />
        </S.Wrapper>

        <S.Wrapper>
          <Label>Equipe*</Label>
          <ListItemCard
            image={whoami?.companyLogo || ""}
            title={whoami?.companyName || ""}
            roundedImage
            disabled
          />
        </S.Wrapper>

        <S.Wrapper>
          {Object.keys(memberRole).length === 0 ? (
            <ButtonModalAction
              label="Cargo*"
              placeholder="Defina um cargo para o novo membro"
              onClick={() => setShowModalRoleAndPermission(true)}
              hasError={hasError.roleId}
            />
          ) : (
            <S.Wrapper>
              <Label>Cargo* </Label>

              <ListItemCard
                image={mappedProfileIcons[memberRole.id]}
                title={memberRole.name}
                subtitle={memberRole.default ? "Pré-definido" : "Personalizado"}
                onClick={() => setShowModalRoleAndPermission(true)}
              />

              <Button
                typeButton="textOnly"
                style={{
                  width: 100,
                  marginTop: 0,
                  textDecoration: "underline",
                }}
                onClick={() => setShowModalProfileDetail(true)}
              >
                ver permissões
              </Button>
            </S.Wrapper>
          )}
        </S.Wrapper>
      </S.Content>

      <Button
        typeButton={
          !name || !email || Object.keys(memberRole).length === 0
            ? "disabled"
            : "default"
        }
        loading={loading}
        onClick={handleSubmit}
      >
        Concluir cadastro
      </Button>
    </Page>
  );
}
