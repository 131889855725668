import { useEffect, useState } from "react";
import { FiChevronLeft } from "react-icons/fi";
import { useHistory, useLocation } from "react-router-dom";
import { useTheme } from "styled-components";

import { LoadingProfiz } from "components/LoadingProfiz";
import { useToast } from "hooks/toast";

import AcceptIcon from "assets/icons/check-background.svg";
import TimesIcon from "assets/icons/times-background.svg";

import * as S from "./styles";
import apiv2 from "services/apiv2";
import { AvaliationCard } from "components/AvaliationCard";
import { Button } from "components/Button";
import { ModalRight } from "components/ModalRight";
import { ModalServiceOrderDetails } from "components/ModalServiceOrderDetails";

type RouteState = {
  before?: string;
};

export function RatingDetails() {
  const location = useLocation<RouteState>();
  const theme = useTheme();
  const history = useHistory();
  const { addToast } = useToast();
  const avaliationId = history.location.pathname.split("/")[2];

  const [isLoading, setIsLoading] = useState(true);
  const [avaliation, setAvaliation] = useState<any>({});
  const [modalVisible, setModalVisible] = useState(false);

  function handleGoBack() {
    if (location.state?.before && location.state?.before.length > 0) {
      return history.push("/home");
    }

    localStorage.setItem("profiz-tabs", "0");
    history.push("/ratings");
  }

  useEffect(() => {
    async function loadAvaliationDetails() {
      try {
        const { data } = await apiv2(
          `budgets/service-orders/avaliations/${avaliationId}`
        );

        setAvaliation({ ...data, clientName: data.serviceOrder.client.name });
      } catch (error: any) {
        addToast({
          title: "Erro ao carregar os detalhes da avaliação",
          description: error.response.data.details[0],
          type: "error",
        });
      } finally {
        setIsLoading(false);
      }
    }

    loadAvaliationDetails();
  }, []); // eslint-disable-line

  return (
    <S.Container>
      {isLoading ? (
        <LoadingProfiz isVisible={isLoading} />
      ) : (
        <>
          <ModalRight
            isOpen={modalVisible}
            handleToggleOpen={() => setModalVisible(!modalVisible)}
          >
            <ModalServiceOrderDetails
              detailLink={avaliation.serviceOrder.detailLink}
              orderServiceId={avaliation.serviceOrder.id}
              onCloseModal={() => setModalVisible(false)}
            />
          </ModalRight>
          <S.Top>
            <S.ButtonClose onClick={handleGoBack}>
              <FiChevronLeft size={10} color={theme.colors.title} />
            </S.ButtonClose>
          </S.Top>
          <S.Title>
            {" "}
            Avaliação OS nº{avaliation.serviceOrder.sequenceNumber}
          </S.Title>

          <S.Content>
            <AvaliationCard
              style={{ cursor: "default" }}
              showStatus={false}
              data={avaliation}
            />
            <S.Wrapper>
              <img
                src={avaliation.executed ? AcceptIcon : TimesIcon}
                style={{ marginRight: 10 }}
                alt="Icone accept"
              ></img>
              <S.TextRow>
                {avaliation.executed
                  ? "O serviço foi executado"
                  : "O serviço não foi executado"}
              </S.TextRow>
            </S.Wrapper>
            <S.TagsContent>
              {avaliation.tags.map((item: string) => (
                <div key={item}>
                  <S.Wrapper
                    style={{
                      width: "unset",
                      justifyContent: "center",
                      alignItems: "center",
                      padding: 10,
                      marginRight: 10,
                    }}
                  >
                    <S.TextRow>{item}</S.TextRow>
                  </S.Wrapper>
                </div>
              ))}
            </S.TagsContent>
            {avaliation.observations.length > 0 && (
              <>
                <S.TextRow style={{ marginTop: 30 }}>
                  Comentário do cliente
                </S.TextRow>
                <S.Wrapper style={{ padding: 10 }}>
                  <S.TextRow>{avaliation.observations}</S.TextRow>
                </S.Wrapper>
              </>
            )}

            <Button onClick={() => setModalVisible(true)} typeButton="outline">
              Ver ordem de serviço
            </Button>
          </S.Content>
        </>
      )}
    </S.Container>
  );
}
