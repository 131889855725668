/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { useHistory } from "react-router";

export default function RedirectComponent() {
  const history = useHistory();

  useEffect(() => {
    history.push("/home");
  }, []);
  
  return <></>;
}
