import React, { useEffect, useState } from "react";

import axios from "axios";

import { Button } from "components/Button";
import { LoadingProfiz } from "components/LoadingProfiz";

import { ServiceOportunityProps } from "dtos/ServiceOportunityDTO";

import { useHistory } from "react-router";

import { useToast } from "hooks/toast";
import { useClient } from "hooks/budget/client";
import { useServiceOpportunityTabIndex } from "hooks/opportunityServiceTabIndex";

import { api } from "services/api";

import * as S from "./styles";
import { ArrowButton } from "components/ArrowButton";
import { ImagePreview } from "components/ImagePreview";

export function OpportunityDetail() {
  const { addToast } = useToast();
  const history = useHistory();

  const {
    handleSetClient,
    handleSetComingFromOpportunity,
    handleSetOpportunityId,
  } = useClient();
  const { handleSetSelectedIndex } = useServiceOpportunityTabIndex();

  const opportunityId = history.location.pathname.split("/")[2];

  const [isLoading, setIsLoading] = useState(true);
  const [opportunity, setOpportunity] = useState({} as ServiceOportunityProps);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedImage, setSelectedImage] = useState("");

  useEffect(() => {
    (async () => {
      try {
        const response = await api.get(`/opportunities/${opportunityId}`);

        setOpportunity(response.data);
      } catch (error) {
      } finally {
        setIsLoading(false);
      }
    })();
  }, [opportunityId]);

  async function handleUnarchiveOpportunity() {
    try {
      setIsLoading(true);

      await api.delete(`/opportunities/${opportunityId}/unarchive`);
      addToast({
        title: "Oportunidade desarquivada com sucesso.",
        description: "",
        type: "success",
      });

      handleSetSelectedIndex(0);
      history.push("/service-opportunity");
    } catch (error) {
      addToast({
        type: "error",
        title: "Oops!",
        description:
          axios.isAxiosError(error) && error.response?.data.error
            ? error.response.data.error
            : "Não foi possível arquivar a oportunidade.",
      });
    } finally {
      setIsLoading(false);
    }
  }

  async function handleGenerateBudget() {
    try {
      setIsLoading(true);

      const response = await api.put(
        `/opportunities/${opportunityId}/costumer/link`
      );
      handleSetClient(response.data);
      handleSetComingFromOpportunity(true);
      handleSetOpportunityId(Number(opportunityId));

      history.push("/budgets/create/services");
    } catch (error) {
      addToast({
        title: "Oops!",
        description:
          axios.isAxiosError(error) && error.response?.data.error
            ? error.response.data.error
            : "Não foi possível aprovar a oportunidade no momento.",
        type: "error",
      });
    } finally {
      setIsLoading(false);
    }
  }

  async function handleArchiveOpportunity() {
    try {
      setIsLoading(true);

      await api.put(`/opportunities/${opportunityId}/archive`);

      addToast({
        title: "Oportunidade arquivada com sucesso.",
        description: "",
        type: "success",
      });

      handleSetSelectedIndex(2);
      history.goBack();
    } catch (error) {
      addToast({
        title: "Oops!",
        description:
          axios.isAxiosError(error) && error.response?.data.error
            ? error.response.data.error
            : "Não foi possível arquivar a oportunidade.",
        type: "error",
      });
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <>
      {isLoading ? (
        <LoadingProfiz isVisible={isLoading} />
      ) : (
        <S.Container>
          <ArrowButton />

          <S.Title>Oportunidade de serviço</S.Title>
          <S.Subtitle>
            Esses são os dados preenchidos por seu futuro cliente via Vitrine de
            Profissionais
          </S.Subtitle>

          <S.Form>
            <S.FormItem>
              <S.Label>Nome completo</S.Label>
              <S.Value>{opportunity.name}</S.Value>
              <S.Line />
            </S.FormItem>

            <S.FormItem>
              <S.Label>Telefone</S.Label>
              <S.Value>
                {!!opportunity.telephone && opportunity.telephone}
              </S.Value>
              <S.Line />
            </S.FormItem>

            <S.FormItem>
              <S.Label>E-mail</S.Label>
              <S.Value>{opportunity.email}</S.Value>
              <S.Line />
            </S.FormItem>

            <S.FormItem>
              <S.Label>CEP</S.Label>
              <S.Value>{opportunity.postalCode}</S.Value>
              <S.Line />
            </S.FormItem>

            <S.FormItem>
              <S.Label>Rua</S.Label>
              <S.Value>{opportunity.street}</S.Value>
              <S.Line />
            </S.FormItem>

            <S.FormItem>
              <S.Inline>
                <S.Block>
                  <S.Label>Número da residência</S.Label>
                  <S.Value>{opportunity.number}</S.Value>
                  <S.Line />
                </S.Block>

                <S.Block>
                  <S.Label>Complemento</S.Label>
                  <S.Value>{opportunity.complement}</S.Value>
                  <S.Line />
                </S.Block>
              </S.Inline>
            </S.FormItem>

            <S.FormItem>
              <S.Label>Bairro</S.Label>
              <S.Value>{opportunity.district}</S.Value>
              <S.Line />
            </S.FormItem>

            <S.FormItem>
              <S.Inline>
                <S.Block>
                  <S.Label>Cidade</S.Label>
                  <S.Value>{opportunity.city}</S.Value>
                  <S.Line />
                </S.Block>

                <S.Block>
                  <S.Label>Estado</S.Label>
                  <S.Value>{opportunity.uf}</S.Value>
                  <S.Line />
                </S.Block>
              </S.Inline>
            </S.FormItem>

            <S.FormItem>
              <S.Label>Categoria</S.Label>
              <S.Value>{opportunity.serviceCategory}</S.Value>
              <S.Line />
            </S.FormItem>

            <S.FormItem>
              <S.Label>Serviço</S.Label>
              <S.Value>{opportunity.serviceType}</S.Value>
              <S.Line />
            </S.FormItem>

            {!!opportunity.equipment && (
              <S.FormItem>
                <S.Label>Equipamento</S.Label>
                <S.Value>{opportunity.equipment}</S.Value>
                <S.Line />
              </S.FormItem>
            )}

            {!!opportunity.images && opportunity.images.length > 0 && (
              <S.FormItem>
                <S.Label>Fotos do local</S.Label>
                <S.ImagesWrapper>
                  {opportunity.images.map((image, index) => (
                    <img
                      src={image.url}
                      height={"200px"}
                      width={"30%"}
                      alt="img"
                      style={{
                        marginRight: "3%",
                      }}
                      onClick={() => {
                        setSelectedImage(image.url);
                        setIsModalVisible(true);
                      }}
                    />
                  ))}
                </S.ImagesWrapper>
                <S.Line />
              </S.FormItem>
            )}

            <ImagePreview
              images={[selectedImage]}
              imageSelected={0}
              isVisible={isModalVisible}
              onCloseModal={() => {
                setIsModalVisible(false);
              }}
              showModalArrow={false}
            />

            <S.FormItem>
              <S.Label>Observações</S.Label>
              <S.Value>{opportunity.comments}</S.Value>
              <S.Line />
            </S.FormItem>

            {opportunity.status !== "approved" &&
              (opportunity.archived ? (
                <S.ArchiveButton onClick={handleUnarchiveOpportunity}>
                  <S.ArchiveText>Desarquivar oportunidade</S.ArchiveText>
                </S.ArchiveButton>
              ) : (
                <>
                  <S.WrapperButton>
                    <Button onClick={handleGenerateBudget}>
                      Aprovar e gerar orçamento
                    </Button>
                  </S.WrapperButton>

                  <S.ArchiveButton onClick={handleArchiveOpportunity}>
                    <S.ArchiveText>Arquivar oportunidade</S.ArchiveText>
                  </S.ArchiveButton>
                </>
              ))}
          </S.Form>
        </S.Container>
      )}
    </>
  );
}
