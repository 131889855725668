import styled, { css } from "styled-components";

export const Container = styled.div`
  ${({ theme }) => css`
    margin: 0px;
    padding: 0px;
    background-color: ${theme.colors.strong_background};
    min-height: 100vh;
    min-width: 100vw;
    display: flex;
    flex-direction: column;
    position: fixed;
  `}
`;

export const ContainerImage = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  image {
    height: 80px;
    width: 80px;
  }

  margin-top: 50%;
  margin-bottom: 15px;
`;

export const WrapperText = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0px 20 px;

    h6 {
      font-family: ${theme.font.family};
      font-size: ${theme.font.sizes.xsmall};
      font-weight: ${theme.font.normal};
      color: ${theme.colors.financial_title};
      line-height: 18px;
      letter-spacing: 0.21px;
      text-align: center;
    }

    p {
      font-family: ${theme.font.family};
      font-size: ${theme.font.sizes.xsmall};
      font-weight: ${theme.font.normal};
      color: ${theme.colors.financial_text};
      line-height: 14px;
      letter-spacing: 0.17px;
      text-align: center;
    }
  `}
`;

export const WrapperButton = styled.div`
  padding: 0px 20px;
`;
