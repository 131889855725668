import React from "react";

import { useTheme } from "styled-components";
import { AiOutlineExclamation } from "react-icons/ai";

import * as S from "./styles";

function CardProfileUnderAnalysis() {
  const theme = useTheme();

  return (
    <S.WrapperUnderAnalysis>
      <S.WrapperIconExclamation>
        <AiOutlineExclamation size={20} color={theme.colors.model} />
      </S.WrapperIconExclamation>

      <S.TitleProfileUnderAnalysis>
        <strong>Perfil em análise.</strong> <br />O prazo para conclusão é de
        até 10 dias úteis.
      </S.TitleProfileUnderAnalysis>
    </S.WrapperUnderAnalysis>
  );
}

export { CardProfileUnderAnalysis };
