import { ButtonHTMLAttributes } from "react";
import BlockGraySvg from "assets/icons/permission/block-gray.svg";

import * as S from "./styles";

import { useAuth } from "hooks/auth";

export type ModuleCardProps = {
  svg: string;
  title: string;
  hasPermission?: boolean;
} & ButtonHTMLAttributes<HTMLButtonElement>;

export function ModuleCard({
  svg,
  title,
  hasPermission = true,
  ...rest
}: ModuleCardProps) {
  const { user } = useAuth();

  function returnBlockedModule(title: string) {
    if (user?.profileDisapproved) {
      if (title === "Calendário de eventos" || title === "Manuais") {
        return false;
      } else return true;
    }
    return false;
  }

  return (
    <S.Card {...rest}>
      {(returnBlockedModule(title) || !hasPermission) && (
        <S.IconWrapper>
          <img src={BlockGraySvg} alt="Ícone de cadeado" />
        </S.IconWrapper>
      )}

      <img src={svg} alt="Ícone do card" />
      <p>{title}</p>
    </S.Card>
  );
}
