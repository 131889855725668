import Allowed from "assets/icons/permission/allowed.svg";
import Denied from "assets/icons/permission/denied.svg";

import * as S from "./styles";

type HasPermissionCardProps = {
  label: string;
  hasPermission: boolean;
};
export function HasPermissionCard({
  label,
  hasPermission,
}: HasPermissionCardProps) {
  return (
    <S.Container>
      <p>{label}</p>
      <img src={hasPermission ? Allowed : Denied} alt="Ícone da permissão" />
    </S.Container>
  );
}
