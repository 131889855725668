import { useEffect, useState } from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { HiOutlinePlus } from "react-icons/hi";
import { useHistory } from "react-router";
import "react-tabs/style/react-tabs.css";

import { useAuth } from "hooks/auth";
import { useBudgetTabIndex } from "hooks/budgetTabIndex";
import { updateUserOnboarding } from "utils/updateUserOnboarding";

import { ArrowButton } from "components/ArrowButton";
import OnboardingCard from "components/OnboardingCard";
import { ModalRejectedRegistration } from "components/ModalRejectedRegistration";

import { Pending } from "./Pending";
import { Model } from "./Models";
import { Concluded } from "./Concluded";
import { Archived } from "./Archived";

import { api } from "services/api";
import { useToast } from "hooks/toast";

import * as S from "./styles";

export function Budgets() {
  const { user, updateUser } = useAuth();
  const history = useHistory();
  const { addToast } = useToast();
  const { selectedIndex, handleSetSelectedIndex } = useBudgetTabIndex();

  const [tourModalVisible, setTourModalVisible] = useState(false);
  const [currentStep, setCurrentStep] = useState(1);
  const [activeModalRejectedRegistration, setActiveModalRejectedRegistration] =
    useState(false);

  useEffect(() => {
    const userSeenOnboarding = user.onboarding.budgetOnboarding;
    if (!userSeenOnboarding) {
      setTourModalVisible(true);
    }

    if (user?.profileDisapproved) {
      setActiveModalRejectedRegistration(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function updateUserOnboardingBudgets() {
    try {
      const updateUserData = updateUserOnboarding(user, "budgetOnboarding");

      await api.put("/users/me", updateUserData);
      updateUser(updateUserData);
    } catch (error) {
      addToast({
        title: "Erro",
        description: "Ocorreu um erro ao atualizar o usuário.",
        type: "error",
      });
    }
  }

  return (
    <S.Container>
      <OnboardingCard
        type={"budgetOnboarding"}
        currentStep={currentStep}
        setCurrentStep={setCurrentStep}
        isTourModalVisible={tourModalVisible}
        setIsTourModalVisible={setTourModalVisible}
        onUpdateOnboarding={updateUserOnboardingBudgets}
      />
      <ModalRejectedRegistration
        isVisible={activeModalRejectedRegistration}
        setIsActive={setActiveModalRejectedRegistration}
      />
      <header>
        <div>
          <ArrowButton handleFunction={() => history.push("/home")} />
          <h1>Orçamentos</h1>
        </div>

        {!user?.profileDisapproved && (
          <S.ButtonAdd to="/budgets-mode">
            <HiOutlinePlus size={20} />
          </S.ButtonAdd>
        )}
      </header>

      <S.TabContainer>
        <Tabs
          selectedIndex={selectedIndex}
          onSelect={(index: number) => {
            handleSetSelectedIndex(index);
          }}
        >
          <TabList>
            <Tab>Pendentes</Tab>
            <Tab>Aprovados</Tab>
            <Tab>Arquivados</Tab>
            <Tab>Modelos</Tab>
          </TabList>

          <TabPanel>
            <Pending />
          </TabPanel>

          <TabPanel>
            <Concluded />
          </TabPanel>

          <TabPanel>
            <Archived />
          </TabPanel>

          <TabPanel>
            <Model />
          </TabPanel>
        </Tabs>
      </S.TabContainer>
    </S.Container>
  );
}
