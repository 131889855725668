import styled, { css } from "styled-components";

export const Container = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 25px;
`;

export const ContentList = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const ContentEmptyMessage = styled.div`
  width: 60%;
  text-align: center;
`;

export const Label = styled.span`
  ${({ theme }) => css`
    font-family: ${theme.font.family};
    font-size: ${theme.font.sizes.xsmall};
    font-weight: ${theme.font.normal};
    color: ${theme.colors.text_light_60};
    line-height: 20px;
    margin-top: 10px;
  `}
`;

export const Highlight = styled.span`
  ${({ theme }) => css`
    font-family: ${theme.font.family};
    font-size: ${theme.font.sizes.xsmall};
    font-weight: ${theme.font.bold};
    color: ${theme.colors.text_light_60};
    line-height: 20px;
    margin-top: 10px;
  `}
`;
