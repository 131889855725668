import { useState, useCallback, useEffect } from "react";

import debounce from "lodash.debounce";

import { ArrowButton } from "components/ArrowButton";
import { SearchInput } from "components/SearchInput";
import { Button } from "components/Button";
import { useHistory, useLocation } from "react-router";
import * as S from "./styles";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import { useClient } from "hooks/budget/client";
import { usePmoc } from "hooks/usePmoc";
import { useAuth } from "hooks/auth";
import { useToast } from "hooks/toast";
import { updateUserOnboarding } from "utils/updateUserOnboarding";
import { api } from "services/api";
import OnboardingCard from "components/OnboardingCard";
import { PendingPMOC } from "./PendingPMOC";
import { ExecutionPMOC } from "./ExecutionPMOC";
import { ConcludedPMOC } from "./ConcludedPMOC";
import { ArchivedPMOC } from "./ArchivedPMOC";
import { searchDatalayer } from "utils/pushDataLayer";

export function PmocListing() {
  const history = useHistory();
  const { addToast } = useToast();
  const { user, updateUser } = useAuth();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const statusList = queryParams.get("statusList");

  const beforePage = !!statusList ? Number(statusList) : 0;

  const [searchInputValue, setSearchInputValue] = useState("");
  const [searchPmoc, setSearchPmoc] = useState("");
  const [selectPage, setSelectedPage] = useState<number>(beforePage || 0);
  const [inputLoading, setInputLoading] = useState(false);

  const [tourModalVisible, setTourModalVisible] = useState(false);
  const [currentStep, setCurrentStep] = useState(1);

  useEffect(() => {
    const userSeenOnboarding = user.onboarding.pmocOnboarding;
    if (!userSeenOnboarding) {
      setTourModalVisible(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function updateUserOnboardingPMOC() {
    try {
      const updateUserData = updateUserOnboarding(user, "pmocOnboarding");

      await api.put("/users/me", updateUserData);
      updateUser(updateUserData);
    } catch (error) {
      addToast({
        title: "Erro",
        description: "Ocorreu um erro ao atualizar o usuário.",
        type: "error",
      });
    }
  }

  const { clearClientData } = useClient();
  const { clearPmocData } = usePmoc();

  function clearInfos() {
    clearClientData();
    clearPmocData();
  }

  //eslint-disable-next-line react-hooks/exhaustive-deps
  const debounceSearch = useCallback(
    debounce((text) => {
      setSearchPmoc(text);
      if (text) {
        searchDatalayer({ search_term: text, success: true });
      }
    }, 800),
    []
  );

  function handleChangeInput(text: string) {
    setInputLoading(true);
    setSearchInputValue(text);
    debounceSearch(text);
  }

  function handleCancelSearch() {
    setSearchInputValue("");
    setSearchPmoc("");
  }

  function handleNavigateToNewPmoc() {
    clearInfos();
    history.push("/pmoc/new");
  }

  return (
    <S.Container>
      <OnboardingCard
        currentStep={currentStep}
        setCurrentStep={setCurrentStep}
        isTourModalVisible={tourModalVisible}
        setIsTourModalVisible={setTourModalVisible}
        onUpdateOnboarding={updateUserOnboardingPMOC}
        type={"pmocOnboarding"}
      />
      <header>
        <div>
          <ArrowButton handleFunction={() => history.replace("/")} />
        </div>
        <div>
          <h1>PMOC</h1>
        </div>
      </header>

      <div className="content">
        <SearchInput
          searchValue={searchInputValue}
          placeholder="Procure pelo id, nome da pmoc ou cliente"
          onChange={(event) => handleChangeInput(event.target.value)}
          handleCancel={handleCancelSearch}
          loadingInput={inputLoading}
        />

        <Button typeButton="outline" onClick={handleNavigateToNewPmoc}>
          Criar novo PMOC
        </Button>

        <S.TabContainer>
          <Tabs
            selectedIndex={selectPage}
            onSelect={(index: number) => {
              setSelectedPage(index);
            }}
          >
            <TabList>
              <Tab>Pendentes</Tab>
              <Tab>Em execução</Tab>
              <Tab>Concluídas</Tab>
              <Tab>Arquivadas</Tab>
            </TabList>
            <TabPanel>
              <PendingPMOC
                search={searchPmoc}
                setSearchLoading={setInputLoading}
              />
            </TabPanel>
            <TabPanel>
              <ExecutionPMOC
                search={searchPmoc}
                setSearchLoading={setInputLoading}
              />
            </TabPanel>
            <TabPanel>
              <ConcludedPMOC
                search={searchPmoc}
                setSearchLoading={setInputLoading}
              />
            </TabPanel>
            <TabPanel>
              <ArchivedPMOC
                search={searchPmoc}
                setSearchLoading={setInputLoading}
              />
            </TabPanel>
          </Tabs>
        </S.TabContainer>
      </div>
    </S.Container>
  );
}
