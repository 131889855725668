import React, { useState } from 'react'

import { ButtonModalAction } from 'components/ButtonModalAction'
import { ModalRight } from 'components/ModalRight'
import { ModalMaterials } from 'components/ModalMaterials'
import { CardSelectedMaterial } from 'components/CardSelectedMaterial'
import { EditButton } from 'components/EditButton'
import { DeleteButton } from 'components/DeleteButton'
import { Button } from 'components/Button'
import { FormEditMaterial }  from 'components/ModalMaterials/FormEditMaterial'

import { MaterialProps } from 'dtos/MaterialDTO'

import { useSelectedMaterial } from 'hooks/budget/selectedMaterials'

import * as S from './styles'

type Props = {
  disabled?: boolean;
}

export function MaterialsArea({ disabled }: Props) {
  const [isOpen, setIsOpen] = useState(false);
  const [showMaterialDetailModal, setShowMaterialDetailModal] = useState(false);
  const [materialToEdit, setMaterialToEdit] = useState({} as MaterialProps);

  const { 
    selectedMaterials,
    handleUpdateQuantity,
    handleRemoveSelectedMaterial 
  } = useSelectedMaterial();

  function onIncrement(id: number, quantity: number) {
    if (disabled) {
      return;
    }
    handleUpdateQuantity(id, quantity)
  }

  function handleToggleOpen() {
    if (disabled) {
      return;
    }
    setIsOpen(!isOpen)
  }

  function handleToggleEditMaterialModal() {
    setShowMaterialDetailModal(prevState => !prevState);
  }

  function handleEditMaterial(material: MaterialProps) {
    setMaterialToEdit(material);
    setShowMaterialDetailModal(true);
  }

  return (
    <S.Container>
      <ModalRight
        isOpen={showMaterialDetailModal}
        handleToggleOpen={handleToggleEditMaterialModal}
      >
        <FormEditMaterial  
          data={materialToEdit}
          handleListMaterials={handleToggleEditMaterialModal}
        />
      </ModalRight>

      <ModalRight
        isOpen={isOpen}
        // eslint-disable-next-line react/jsx-no-bind
        handleToggleOpen={handleToggleOpen}
      >
        <ModalMaterials
          // eslint-disable-next-line react/jsx-no-bind
          handleToggleOpen={handleToggleOpen}
        />
      </ModalRight>

      {selectedMaterials.length <= 0 ? (
        <ButtonModalAction
          label="Materiais (opcional)"
          placeholder="Complete o material"
          isSelected={isOpen}
          // eslint-disable-next-line react/jsx-no-bind
          onClick={handleToggleOpen}
        />
      ) : (
        <>
          <S.Label>Materiais (opcional)</S.Label>

          {selectedMaterials.map((currentMaterial, index) => (
            <S.Content key={index}>
              <S.WrapperCard> 
                <CardSelectedMaterial
                  key={currentMaterial.id}
                  data={currentMaterial}
                  // eslint-disable-next-line react/jsx-no-bind
                  onIncrement={onIncrement}
                  disabled={disabled}
                />
              </S.WrapperCard>
              <S.WrapperActions>
                <EditButton 
                  disabled={disabled}
                  onClick={() => handleEditMaterial(currentMaterial)} 
                  />
                <DeleteButton 
                  disabled={disabled}
                  onClick={() => handleRemoveSelectedMaterial(currentMaterial.id)} 
                  />
              </S.WrapperActions>
            </S.Content>
          ))}

          <Button
            disabled={disabled}
            typeButton={disabled ? 'disabled' : 'outline'} 
            onClick={() => handleToggleOpen()}
          >
            Adicionar outro material
          </Button>
        </>
      )}
    </S.Container>
  )
}
