import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";

import { EmptyPage } from "components/EmptyPage";

import { useToast } from "hooks/toast";
import { useAccount } from "hooks/permission/account";

import { getNotificationIcon } from "./getNotificationIcon";

import { api } from "services/api";
import apiv2 from "services/apiv2";

import * as S from "./styles";

type DataProps = {
  linkedId: number;
  type:
    | "ordem-servico"
    | "feedback"
    | "perfil"
    | "financeiro-solicitacao"
    | "evento"
    | "opportunity-showcase"
    | "opportunity-service-order"
    | "pesquisa-satisfacao"
    | "invite"
    | "certificado"
    | "service-assignment"
    | "service-concluded"
    | "budget"
    | "service-order"
    | "events"
    | "home"
    | "profile"
    | "manuals"
    | "checklists"
    | "pmoc"
    | string;
  status:
    | "concluded"
    | "scheduled"
    | "answered"
    | "new"
    | "accepted"
    | "removed"
    | "denied"
    | "ativo"
    | string;
  permissionInvitationHash: string;
};

type NotificationData = {
  id: number;
  date: string;
  title: string;
  description: string;
  readStatus: boolean;
  permissionInvitationHash: string;
  data: DataProps;
};

type NotificationProps = {
  id: number;
  countPending: number;
  header: string;
  date: string;
  data: NotificationData[];
};

export type NotificationListProps = {
  titleList?: string;
  toggleNotification: boolean;
  handleToggleNotification: () => void;
  getAmountNotification: (amount: number) => void;
  notificationType?: "permission";
};

type ServiceOrderResponse = {
  id: number;
  sequenceNumber: number;
  status: string;
  archived: boolean;
  services: ServiceProps[];
};

type ServiceProps = {
  id: number;
  scheduledDate: string;
  service: string;
  status: "new" | "pending" | "scheduled" | "concluded" | "closed";
};

export function NotificationList({
  titleList = "",
  toggleNotification,
  handleToggleNotification,
  getAmountNotification,
  notificationType,
}: NotificationListProps) {
  const { addToast } = useToast();
  const history = useHistory();
  const location = useLocation();
  const { whoami } = useAccount();

  const [notifications, setNotifications] = useState<NotificationProps[]>([]);
  const [quantityNotification, setQuantityNotification] = useState(0);

  const showButtonMarkAllRead = !!notifications.length;

  useEffect(() => {
    loadNotifications();
  }, [whoami?.id]); // eslint-disable-line

  useEffect(() => {
    if (
      quantityNotification > 0 &&
      location.pathname === "/home" &&
      notificationType !== "permission"
    ) {
      addToast({
        title: "Notificações",
        description: `Você possui notificações não lida(s)`,
        type: "info",
      });
    }
  }, [quantityNotification]); // eslint-disable-line

  async function loadNotifications() {
    try {
      const { data } = await api.get("users/me/notifications/history", {
        params: {
          offset: 0,
          limit: 50,
          accountId: whoami?.id,
          ...(notificationType === "permission" && {
            permissionsNotification: "invite",
          }),
        },
      });
      setNotifications(data.notifications);

      if (data.notifications.length > 0) {
        getAmountNotification(data.countPending);
        setQuantityNotification(data.countPending);
      } else {
        getAmountNotification(0);
        setQuantityNotification(0);
      }
    } catch (err) {
      addToast({
        title: "Ops...",
        description: "Erro ao carregar as notificações.",
        type: "error",
      });
    }
  }

  async function checkInvitationStatus(invitationHash: string) {
    try {
      await api.get(`users/teams/answer-invite/${invitationHash}`);
    } catch (err) {
      throw new Error("O convite já foi respondido!");
    }
  }

  async function handleOpenPermissionNotifications(
    data: DataProps,
    invitationHash: string
  ) {
    if (data.status === "denied" || data.status === "removed") return;

    if (data.status === "accepted") {
      history.push("/teams/management", {
        currentPageDetail: "teamMember",
        activeTab: "added",
      });
      return;
    }

    if (data.status === "new") {
      await checkInvitationStatus(invitationHash);

      history.push(`/teams/invite/${invitationHash}`);
    }
  }

  async function handleOpenNotification({
    data,
    id,
    permissionInvitationHash,
  }: NotificationData) {
    try {
      await api.put(`/users/me/notifications/${id}/read`);

      if (data.type === "budget" && Number(data.linkedId) !== 0) {
        try {
          const { data: budget } = await apiv2.get(`/budgets/${data.linkedId}`);

          if (budget.type === "model") {
            return history.push(`/budgets/model/${data.linkedId}`);
          } else if (budget.status === "concluded") {
            return history.push(`/budgets/concluded/${data.linkedId}`);
          } else if (budget.status === "pending" && !budget.archived) {
            return history.push(`budgets/${data.linkedId}`);
          } else if (budget.status === "pending" && budget.archived) {
            return history.push(`budgets/archived/${data.linkedId}`);
          }
        } catch (error) {
          addToast({
            title: "Ops...",
            description: "Não foi possível visualizar a notificação.",
            type: "error",
          });
        }
      } else if (data.type === "budget") {
        return history.push(`/budgets`);
      }

      if (data.type === "manuals") {
        if (Number(data.linkedId) !== 0) {
          return history.push(`/brands/${data.linkedId}/equipments`);
        }
        return history.push(`/brands`);
      }

      if (data.type === "checklists") {
        return history.push(`/service-checklist`);
      }

      if (
        (data.type === "ordem-servico" || data.type === "service-order") &&
        data.linkedId === 0
      ) {
        return history.push(`/service-order`);
      }

      if (
        data.type === "ordem-servico" ||
        data.type === "service-order" ||
        data.type === "service-concluded"
      ) {
        try {
          const { data: dataServiceOrder } = await apiv2.get(
            `/budgets/service-order/${data.linkedId}`
          );

          if (dataServiceOrder.status === "new" && !dataServiceOrder.arquived) {
            history.push(`/service-order/pending/${data.linkedId}`);
          } else if (
            dataServiceOrder.status === "new" &&
            dataServiceOrder.arquived
          ) {
            history.push(`/service-order/pending/${data.linkedId}/archived`);
          } else if (
            dataServiceOrder.status === "scheduled" &&
            !dataServiceOrder.archived
          ) {
            history.push(`/service-order/scheduled/${data.linkedId}`);
          } else if (
            dataServiceOrder.status === "scheduled" &&
            dataServiceOrder.archived
          ) {
            history.push(`/service-order/scheduled/${data.linkedId}/archived`);
          } else if (dataServiceOrder.status === "concluded") {
            history.push(`/service-order/concluded/${data.linkedId}`);
          }
        } catch (error) {
          addToast({
            title: "Ops...",
            description: "Não foi possível visualizar a notificação.",
            type: "error",
          });
        }
      }

      if (data.type === "perfil") {
        return history.push("/profile");
      }

      if (data.type === "profile") {
        return history.push("/profile");
      }

      if (data.type === "versioning") {
        return history.push("/app-version-detail");
      }

      if (data.type === "feedback") {
        history.push({
          pathname: "/feedback",
          state: { feedbackParamId: data.linkedId },
        });
      }

      if (data.type === "evento" || data.type === "events") {
        if (data.linkedId !== 0) {
          localStorage.setItem("@Profiz:EventId", data.linkedId.toString());
          history.push({
            pathname: "/events-calendar",
            state: { idEvent: data.linkedId },
          });
        } else {
          history.push({ pathname: "/events-calendar" });
        }
      }

      if (data.type === "opportunity-showcase") {
        history.push(`/service-opportunity/${data.linkedId}`);
      }

      if (data.type === "opportunity-service-order") {
        history.push(`/service-opportunity-os/${data.linkedId}`);
      }

      if (data.type === "pesquisa-satisfacao" && data.status === "answered") {
        history.push({
          pathname: `/ratings/${Number(data.linkedId)}`,
          state: { before: "notifications" },
        });
      }

      if (data.type === "invite") {
        await handleOpenPermissionNotifications(data, permissionInvitationHash);
      }

      if (data.type === "certificado") {
        if (location.pathname === "/certification-details") {
          history.push({
            pathname: "/home",
          });
        }

        history.push({
          pathname: "certification-details",
          state: {
            isNotification: true,
            idCertification: data.linkedId,
          },
        });
      }

      if (data.type === "pmoc" && data.linkedId !== 0) {
        try {
          const { data: pmoc } = await api.get(`/pmoc/${data.linkedId}`);

          if (pmoc.status === "pending") {
            history.push(`/pmoc/finish-register/${data.linkedId}`);
          } else if (pmoc.status === "execution") {
            history.push(`/pmoc/execution/${data.linkedId}`);
          } else if (pmoc.status === "concluded") {
            history.push(`/pmoc/concluded/${data.linkedId}`);
          } else if (pmoc.status === "archived") {
            history.push(`/pmoc/archived/${data.linkedId}`);
          } else {
            history.push(`/pmoc`);
          }
        } catch (error) {
          addToast({
            title: "Ops...",
            description: "Não foi possível visualizar a notificação.",
            type: "error",
          });
        }
      } else if (data.type === "pmoc") {
        history.push(`/pmoc`);
      }

      if (data.type === "service-assignment") {
        try {
          const { data: dataServiceOrder } = await apiv2.get(
            `budgets/service-orders`,
            {
              params: {
                offset: 0,
                limit: 50,
                id: data.linkedId,
              },
            }
          );

          const order: ServiceOrderResponse[] = dataServiceOrder?.ordens;

          if (order[0].archived) {
            const hasScheduled = order[0].services.find(
              (service) => !!service.scheduledDate
            );

            if (hasScheduled) {
              history.push(
                `/service-order/scheduled/${data.linkedId}/archived`
              );
            } else {
              history.push(`/service-order/pending/${data.linkedId}/archived`);
            }
          } else if (order[0].status === "concluded") {
            history.push(`/service-order/concluded/${data.linkedId}`);
          } else {
            const hasScheduled = order[0].services.find(
              (service) => !!service.scheduledDate
            );

            if (hasScheduled) {
              history.push(`/service-order/scheduled/${data.linkedId}`);
            } else {
              history.push(`/service-order/pending/${data.linkedId}`);
            }
          }
        } catch (error) {
          addToast({
            title: "Ops...",
            description: "Não foi possível visualizar a notificação.",
            type: "error",
          });
        }
      }

      if (data.type === "receipt") {
        data.linkedId === 0
          ? history.push(`/financial/receipts`)
          : history.push(`/financial/edit-receipt/${data.linkedId}`);
      }
    } catch (error) {
      if (error instanceof Error) {
        addToast({
          title: "Ops...",
          description: error.message,
          type: "error",
        });

        return;
      }

      addToast({
        title: "Ops...",
        description: "Erro ao abrir a notificação.",
        type: "error",
      });
    } finally {
      handleToggleNotification();
      loadNotifications();
    }
  }

  const markAllRead = async () => {
    try {
      const params =
        notificationType === "permission"
          ? { permissionsNotification: "invite" }
          : {};

      await api.put("users/me/notifications/pendings/read", null, { params });

      loadNotifications();
    } catch (error) {
      addToast({
        title: "Ops...",
        description: "Erro ao atualizar notificações.",
        type: "error",
      });
    }
  };

  return (
    <S.Container toggleNotification={toggleNotification}>
      {showButtonMarkAllRead && (
        <S.MarkAllRead>
          <button onClick={markAllRead}> Marcar todas como lidas</button>
        </S.MarkAllRead>
      )}

      {titleList && <h1>{titleList}</h1>}

      {notifications.length === 0 && (
        <S.Wrapperempty>
          <EmptyPage />
        </S.Wrapperempty>
      )}

      {notifications.map((notificationDay) => {
        return (
          <div key={notificationDay.id}>
            <S.HeaderDate>
              <h4>{notificationDay.header}</h4>
            </S.HeaderDate>

            <S.ListNotifications>
              {notificationDay.data.map((notification) => (
                <S.ItemNotification
                  key={notification.id}
                  readStatus={notification.readStatus}
                  onClick={() => handleOpenNotification(notification)}
                >
                  {!notification.readStatus && <S.BadgeUnread />}

                  <img
                    src={getNotificationIcon(
                      notification.data.type,
                      notification.data.status
                    )}
                    alt="Ícone da notificação"
                  />

                  <S.WrapperNotification>
                    <h4>{notification.title}</h4>
                    {notification.data.type !== "versioning" && (
                      <p>{notification.description}</p>
                    )}
                  </S.WrapperNotification>
                </S.ItemNotification>
              ))}
            </S.ListNotifications>
          </div>
        );
      })}
    </S.Container>
  );
}
