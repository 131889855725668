import styled, { css } from "styled-components";

export const Container = styled.div``;

export const Top = styled.div`
  ${({ theme }) => css`
    margin: 20px 0px;

    display: flex;
    align-items: center;
    p {
      font-size: ${theme.font.sizes.large};
      color: ${theme.colors.text_extra_light};
      font-weight: ${theme.font.bold};
    }
  `}
`;

export const BackButton = styled.button`
  ${({ theme }) => css`
    width: 35px;
    height: 35px;
    background-color: ${theme.colors.background_light};
    border-radius: 10px;

    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 25px;
    margin-bottom:10px;
  `}
`;

export const ContainerModalPeriod = styled.div`
  display: flex;
  padding: 0 15px;
`;

export const Content = styled.div`
  padding: 0 20px;
`;

type InputProps = {
  marginTop?: number;
};

export const WrapperInput = styled.div<InputProps>`
  ${({ marginTop }) => css`
    margin-top: ${marginTop ? marginTop : 15}px;
  `}
`;

export const Title = styled.p`
  ${({ theme }) => css`
    font-size: 18px;
    font-family: ${theme.font.bold};
    color: ${theme.colors.text_extra_light};
    letter-spacing: 0.4px;
    line-height: 20px;
    margin-bottom: 5px;
  `}
`;

export const SubTitle = styled.p`
  ${({ theme }) => css`
    font-size: 14px;
    font-family: ${theme.font.bold};
    color: ${theme.colors.financial_title};
    letter-spacing: 0.26px;
    line-height: 16px;
  `}
`;

export const WrapperDualInput = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const WrapperInputSmall = styled.div`
  padding: 4px;
  width: 48%;
`;
