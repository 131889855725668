import styled, { css } from "styled-components";

export const Top = styled.div`
  ${({ theme }) => css`
    margin-bottom: 30px;

    display: flex;
    align-items: center;

    p {
      font-size: ${theme.font.sizes.large};
      color: ${theme.colors.title};
      font-weight: ${theme.font.bold};
    }
  `}
`;

export const ButtonClose = styled.button`
  ${({ theme }) => css`
    width: 35px;
    height: 35px;
    background-color: ${theme.colors.background_light};
    border-radius: 10px;

    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 25px;
  `}
`;

export const Content = styled.main``;

export const WrapperInput = styled.div`
  margin-top: 28px;
  margin-bottom: 26px;
`;

export const ListCardsMaterial = styled.ul`
  list-style: none;
  min-height: 30vh;
  margin-bottom: 80px;
`;

export const Footer = styled.div`
  width: 40%;
  max-width: 600px;
  height: 60px;

  position: fixed;
  bottom: 20px;
`;
