import styled from 'styled-components'

export const Container = styled.div`
  width: 100%;
  height: 100%;

  & > div {
    margin-top: 40px;
  }
`
