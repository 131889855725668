import styled, { css } from "styled-components";

export const CardItem = styled.div`
  ${({ theme }) => css`
    margin-bottom: 8px;
    background: ${theme.colors.card_container};
    border-radius: 8px;
    justify-content: space-around;
    padding: 15px 0 15px 15px;
    display: flex;
    flex-direction: row;
    cursor: pointer;

    max-width: 500px;
  `};
`;

export const WrapperDataIconCard = styled.div`
  ${({ theme }) => css`
    h5 {
      font-size: 18px;
      font-weight: ${theme.font.normal};
      color: ${theme.colors.financial_title};
      text-align: center;
      letter-spacing: 0.4px;
      line-height: 20px;
    }

    p {
      margin-top: 5px;
      color: ${theme.colors.financial_text};
      font-size: ${theme.font.sizes.xsmall};
      text-align: center;
      letter-spacing: 0.26px;
      line-height: 16px;
    }
  `};
`;

export const WrapperFavButton = styled.button`
  img {
    padding-top: 10px;
  }
`;

export const WrapperMid = styled.div`
  ${({ theme }) => css`
    width: 60%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-bottom: 5px;
    padding-left: 10px;
    padding-right: 5px;
    p {
      color: ${theme.colors.financial_text};
      font-size: ${theme.font.sizes.xsmall};
      text-align: left;
      letter-spacing: 0.26px;
      line-height: 16px;
    }
  `};
`;

export const HeaderTitle = styled.div`
  ${({ theme }) => css`
    font-size: 14px;
    font-weight: ${theme.font.bold};
    letter-spacing: 0.31px;
    line-height: 16px;
    text-align: left;
    color: ${theme.colors.financial_title};
  `};
`;

export const SubTitle = styled.div`
  ${({ theme }) => css`
    font-size: 14px;
    font-weight: ${theme.font.bold};
    letter-spacing: 0.52px;
    color: ${theme.colors.financial_title};
  `};
`;

export const SubTitleContainer = styled.div`
  margin-top: 20px;
`;

export const WrapperImg = styled.div`
display: flex;
  img {
    height: 84px;
    width: 84px;
    border-radius: 8px;
    align-self: center;
  }
`;
