import React, { createContext, useContext } from 'react'

import { useSelectedChecklist } from './selectedLocalChecklist'
import { useSelectedMaterial } from './selectedMaterials'
import { useSelectedService } from './selectedServices'
import { useSelectedProducts } from './selectedProducts'
import { ProviderProps } from 'hooks'

type TotalBudgetContextData = {
  totalBudget: number
}

const TotalBudgetContext = createContext<TotalBudgetContextData>(
  {} as TotalBudgetContextData
)

export function useTotalBudget(): TotalBudgetContextData {
  const context = useContext(TotalBudgetContext)

  if (!context) {
    throw Error('useTotalBudget must be used within a TotalBudgetProvider')
  }

  return context
}

export const TotalBudgetProvider = ({ children }: ProviderProps) => {
  const { calculateTotalChecklists } = useSelectedChecklist();
  const { calculateTotalMaterials } = useSelectedMaterial();
  const { calculateTotalServices } = useSelectedService();
  const { calculateTotalProducts } = useSelectedProducts();

  const totalBudget =
    calculateTotalChecklists() +
    calculateTotalMaterials() +
    calculateTotalProducts() +
    calculateTotalServices()

  return (
    <TotalBudgetContext.Provider
      value={{
        totalBudget
      }}
    >
      {children}
    </TotalBudgetContext.Provider>
  )
}
