import { useEffect } from "react";
import { useHistory } from "react-router-dom";

import logoImg from "assets/logo.svg";
import checkCircleImg from "assets/icons/check-circle.svg";

import * as S from "./styles";

export function CompleteReseachFeedback() {
  const history = useHistory();

  useEffect(() => {
    history.block();
  }, [history]);

  return (
    <S.Container>
      <S.Main>
        <S.CheckCircle src={checkCircleImg} alt="check de sucesso" />

        <S.Title>
          A pesquisa de satisfação <br /> já foi respondida!
        </S.Title>

        <div>
          <S.Text>
            Colaborando com nossa pesquisa, você ajuda os profissionais
            cadastrados na nossa plataforma a alcançar a excelência em qualidade
            de prestação de serviços.
          </S.Text>
        </div>

        <S.Text>Atenciosamente, equipe</S.Text>
        <S.LogoProfiz src={logoImg} alt="logo da profiz" />
      </S.Main>
    </S.Container>
  );
}
