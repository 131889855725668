import { useEffect, useState } from "react";

import { useToast } from "hooks/toast";
import { useRegisterPhotosModal } from "hooks/registerPhotosModal";
import { useServiceOrder } from "hooks/serviceOrder";

import {
  Photo,
  RegisteredPhoto,
  RegisterProps,
} from "dtos/ServiceChecklistDTO";
import { ServiceWithChecklistDTO } from "dtos/ServiceWithChecklistDTO";

import { AlertModal } from "components/AlertModal";
import { Input } from "components/Input";
import { Button } from "components/Button";
import { CardCheckRegister } from "components/CardCheckRegister";
import { AddPhoto } from "components/AddPhoto";
import { PhotoCardStep } from "../PhotoCardStep";
import { ServiceRegisterPhoto } from "components/ServiceRegisterPhoto";
import { ArrowButton } from "components/ArrowButton";
import { CarouselImages } from "components/ModalGalleryRegisterImages/CarouselImages";
import { ModalExecutionServiceChecklistType } from "..";

import arrowRight from "assets/icons/arrow-right.svg";
import LoadingIndicatorIcon from "assets/loading-indicator.svg";

import apiv2 from "services/apiv2";

import * as S from "./styles";

export type ImageResult = {
  encode: string;
  extension: string;
};

type RunServiceChecklistProps = {
  onNavigateToStepChecklist?: (
    pageStep: ModalExecutionServiceChecklistType
  ) => void;
};

export function RunServiceChecklist({
  onNavigateToStepChecklist,
}: RunServiceChecklistProps) {
  const { addToast } = useToast();
  const { handleToggleRegisterPhotosModal } = useRegisterPhotosModal();

  const [isVisibleModal, setIsVisibleModal] = useState(false);
  const [loadingUploadPhoto, setLoadingUploadPhoto] = useState(false);
  const [loadingPhotoId, setLoadingPhotoId] = useState(0);
  const [photoModal, setPhotoModal] = useState(false);

  const [stagePhotoData, setStagePhotoData] = useState<Photo[]>([]);
  const [selectedPhotoIndex, setSelectedPhotoIndex] = useState(0);
  const [isSaveChecklist, setIsSaveChecklist] = useState(false);

  const {
    selectedServiceOrder,
    currentService,
    currentServiceChecklist,
    handleSetCurrentServiceChecklist,
    handleUpdateRegister,
    handleMarkServiceAsCompleted,
    handleDeletePhotoFromRegister,
  } = useServiceOrder();

  useEffect(() => {
    handleLoadServiceChecklist();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function handleLoadServiceChecklist() {
    const filteredService: ServiceWithChecklistDTO =
      selectedServiceOrder.services.filter(
        (service: ServiceWithChecklistDTO) => {
          return service.idBudgetService === currentService.idBudgetService;
        }
      )[0];

    const loadRegisters =
      filteredService.service.serviceChecklist[0].registers.map(
        (register: RegisterProps) => {
          if (
            register.type === "fotografar" &&
            register.photos &&
            register.photos?.length > 0
          ) {
            const formattedPhotos = register.photos.map((photo) => {
              return {
                id: photo.id,
                link: photo.url,
              };
            });
            return {
              ...register,
              photosFormatted: formattedPhotos,
            };
          }
          return register;
        }
      );

    handleSetCurrentServiceChecklist({
      ...filteredService.service.serviceChecklist[0],
      registers: loadRegisters,
    });
  }

  function handleUpdateRegisterValue(value: string, register: RegisterProps) {
    handleUpdateRegister({
      ...register,
      value,
    });
  }

  async function onPictureChoice(
    picture: ImageResult,
    register: RegisterProps
  ) {
    try {
      setLoadingUploadPhoto(true);

      const { data } = await apiv2.post<RegisteredPhoto>(
        `upload/budget-service/${currentService.idBudgetService}/checklist/${currentServiceChecklist.id}/register/${register.id}/picture`,
        {
          grupo: `ordem-servico/${selectedServiceOrder.id}`,
          extensao: picture.extension,
          encode: picture.encode,
        }
      );

      const photoFormatted = {
        id: data.id,
        link: data.url,
      };

      handleUpdateRegister({
        ...register,
        photos: register.photos ? [...register.photos, data] : [data],
        photosFormatted: register.photosFormatted
          ? [...register.photosFormatted, photoFormatted]
          : [photoFormatted],
      });

      addToast({
        title: "Sucesso",
        description: "Foto adicionada com sucesso",
        type: "success",
      });
    } catch (err) {
      addToast({
        title: "Ops!",
        description: "Falha ao adicionar a foto, tente mais tarde.",
        type: "error",
      });
    } finally {
      setLoadingUploadPhoto(false);
    }
  }

  async function onDeletePicture(pictureId: number, registerId: number) {
    try {
      setLoadingPhotoId(pictureId);

      await apiv2.delete(
        `upload/budget-service/${currentService.idBudgetService}/checklist/${currentServiceChecklist.id}/register/${registerId}/picture/${pictureId}`
      );

      handleDeletePhotoFromRegister(pictureId, registerId);

      addToast({
        title: "Sucesso",
        description: "Foto removida com sucesso",
        type: "success",
      });
    } catch (err) {
      addToast({
        title: "Ops!",
        description: "Falha ao remover a foto, tente mais tarde.",
        type: "error",
      });
    } finally {
      setLoadingPhotoId(0);
    }
  }

  function getOptionForRegister(register: RegisterProps) {
    const maxPhotos = 3;

    const options = {
      medir: (
        <Input
          name={register.name}
          onChange={(e) =>
            handleUpdateRegisterValue(e.target.value.trimStart(), register)
          }
          value={register.value}
        />
      ),
      verificar: <CardCheckRegister register={register} />,
      executar: <CardCheckRegister register={register} />,
      fotografar: (
        <PhotoCardStep
          title={register.name}
          maxPhotos={maxPhotos}
          photosQuantity={register.photosFormatted?.length || 0}
        >
          <AddPhoto
            loading={loadingUploadPhoto}
            stageID={register.id}
            maxPhotos={maxPhotos}
            photosQuantity={register.photosFormatted?.length || 0}
            onPictureChoice={(photo) => onPictureChoice(photo, register)}
          />

          <S.WrapperDescription>
            {!register.photosFormatted?.length && (
              <S.Description>Adicione uma foto</S.Description>
            )}
          </S.WrapperDescription>

          {register.photosFormatted &&
            register.photosFormatted?.length > 0 &&
            register.photosFormatted.map((photo, index) => (
              <ServiceRegisterPhoto
                loading={loadingPhotoId === photo.id}
                url={photo.link}
                key={photo.id}
                onDelete={() => onDeletePicture(photo.id, register.id)}
                onClick={() => {
                  setStagePhotoData(register.photosFormatted ?? []);
                  setSelectedPhotoIndex(index);
                  handleTogglePhotoModal();
                }}
              />
            ))}
        </PhotoCardStep>
      ),
    };

    return options[register.type];
  }

  function validadeIfRegistersAreFilled() {
    let registersAreFilled = true;

    currentServiceChecklist.registers.forEach((register) => {
      if (register.type === "medir" && !register.value) {
        registersAreFilled = false;
        return;
      }

      if (
        (register.type === "verificar" || register.type === "executar") &&
        !register.isChecked
      ) {
        registersAreFilled = false;
        return;
      }

      if (
        register.type === "fotografar" &&
        (!register.photosFormatted || register.photosFormatted.length <= 0)
      ) {
        registersAreFilled = false;
        return;
      }
    });

    return registersAreFilled;
  }

  function handleConfirmRegistersChecklist() {
    setIsVisibleModal(false);

    if (selectedServiceOrder.isNewSignVersion) {
      onNavigateToStepChecklist?.("registerSignatures");
    } else {
      handleSaveChecklistAndCompleteService();
    }
  }

  const handleTogglePhotoModal = () => {
    setPhotoModal(!photoModal);
  };

  async function handleCompleteService() {
    try {
      await apiv2.put(
        `/budgets/service-order/service/${currentService.idBudgetService}`
      );

      handleMarkServiceAsCompleted();
    } catch (err) {
      addToast({
        title: "Ops!!",
        description: "Não foi possível concluir o serviço atual",
        type: "error",
      });
    }
  }

  async function handleSaveChecklistAndCompleteService() {
    try {
      setIsSaveChecklist(true);

      await apiv2.put(
        `/budgets/service-order/${selectedServiceOrder.id}/services/${currentService.idBudgetService}/checklists-register`,
        { serviceChecklist: [currentServiceChecklist] }
      );

      handleSetCurrentServiceChecklist(currentServiceChecklist);

      addToast({
        title: "Sucesso",
        description: "Checklist do serviço finalizado com sucesso",
        type: "success",
      });

      if (currentService.status !== "concluded") {
        await handleCompleteService();
      }
      handleToggleRegisterPhotosModal();
    } catch (err) {
      addToast({
        title: "Ops!!",
        description: "Não foi possível salvar as alterações do checklist",
        type: "error",
      });
    } finally {
      setIsSaveChecklist(false);
    }
  }

  function handleSaveRegistersChecklist() {
    if (!isVisibleModal && !validadeIfRegistersAreFilled()) {
      setIsVisibleModal(true);
      return;
    }

    if (selectedServiceOrder.isNewSignVersion) {
      onNavigateToStepChecklist?.("registerSignatures");
    } else {
      handleSaveChecklistAndCompleteService();
    }
  }

  return (
    <>
      <AlertModal
        isVisible={isVisibleModal}
        title="Checklist incompleto!"
        description="Você não preencheu todos os campos do checklist dos serviços. Deseja continuar mesmo assim?"
        action="choose"
        handleConfirm={() => handleConfirmRegistersChecklist()}
        onCloseModal={() => setIsVisibleModal(false)}
      />

      <CarouselImages
        isVisible={photoModal}
        imageSelected={selectedPhotoIndex}
        images={stagePhotoData}
        onCloseModal={handleTogglePhotoModal}
      />

      <ArrowButton handleFunction={handleToggleRegisterPhotosModal} />
      <S.Header>
        <h2>Checklist do serviço</h2>

        <p>
          Vamos fazer o checklist dos serviços com base nos que você cadastrou
          no menu "Meus serviços".
        </p>
      </S.Header>

      <S.Content>
        {currentServiceChecklist.registers.map((register) => (
          <S.Wrapper key={register.id}>
            {getOptionForRegister(register)}
          </S.Wrapper>
        ))}
      </S.Content>

      <S.Footer>
        {selectedServiceOrder.isNewSignVersion ? (
          <S.NextButton
            type="button"
            disabled={isSaveChecklist}
            onClick={handleSaveRegistersChecklist}
          >
            <span>Próximo</span>

            {isSaveChecklist ? (
              <img src={LoadingIndicatorIcon} alt="ícone de loading" />
            ) : (
              <img src={arrowRight} alt="flecha pra direita" />
            )}
          </S.NextButton>
        ) : (
          <Button
            loading={isSaveChecklist}
            disabled={isSaveChecklist}
            onClick={handleSaveRegistersChecklist}
          >
            Concluir checklist
          </Button>
        )}
      </S.Footer>
    </>
  );
}
