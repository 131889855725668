import styled, { css } from "styled-components";

export const Container = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 87px;

    h1 {
      font-family: ${theme.font.family};
      font-weight: ${theme.font.bold};
      font-size: ${theme.font.sizes.large};
      color: ${theme.colors.text_light_87};
      line-height: 20px;
    }

    img {
      width: 180px;
      height: 190px;
      margin-top: 40px;
      margin-bottom: 50px;
    }
  `}
`;

export const Content = styled.div`
  width: 345px;
  text-align: center;
`;

export const Highlight = styled.span`
  ${({ theme }) => css`
    font-family: ${theme.font.family};
    font-weight: ${theme.font.bold};
    font-size: ${theme.font.sizes.xsmall};
    color: ${theme.colors.text_light_87};
    line-height: 20px;
  `}
`;

export const Description = styled.span`
  ${({ theme }) => css`
    font-family: ${theme.font.family};
    font-weight: ${theme.font.normal};
    font-size: ${theme.font.sizes.xsmall};
    color: ${theme.colors.text_light_60};
    line-height: 20px;
  `}
`;
