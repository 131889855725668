import styled, { css } from "styled-components";

export const Container = styled.div`
  ${({ theme }) => css`
    width: 100%;

    h2 {
      font-size: ${theme.font.sizes.medium};
      color: ${theme.colors.title};
    }
  `}
`;

export const Content = styled.div`
  ${({ theme }) => css`
    display: grid;
    grid-template-columns: repeat(3, 0.5fr);
    gap: ${theme.grid.small};
    margin-top: 15px;
  `}
`;

export const WrapperButton = styled.div`
  ${({ theme }) => css`
    width: 500px;
    display: flex;
    justify-content: flex-end;

    button {
      background-color: transparent;
      color: ${theme.colors.text};
      margin-top: 18px;

      &:hover {
        text-decoration: underline;
      }
    }
  `}
`;
