import { Button } from "components/Button";
import React, { CSSProperties } from "react";

import * as S from "./styles";

type OptionsProps = {
  [key: string]: string | boolean;
};

type TIcone = {
  icon: string;
  name: string;
};

type OptionsIconProps = {
  [key: string]: TIcone;
};

type Props = {
  title?: string;
  data: OptionsProps;
  optionsSelected: string[];
  handlePressButton: (itemSelected: string) => void;
  style?: CSSProperties;
  isIconButton?: boolean;
  iconsData?: OptionsIconProps | any;
  isDisabled?: boolean;
};

const OptionsFilterComponent: React.FC<Props> = ({
  title = "",
  data,
  optionsSelected,
  handlePressButton,
  isIconButton = false,
  iconsData,
  style,
  isDisabled = false,
}) => {
  return (
    <S.Container style={style}>
      {!!title && <S.Title>{title}</S.Title>}

      <S.Content>
        {Object.keys(data).map((currentItem: string) => (
          <S.Wrapper key={currentItem} isIconButton={isIconButton}>
            <Button
              isIcon={isIconButton}
              typeButton={
                optionsSelected.includes(currentItem)
                  ? "financialFilterSelected"
                  : "financialFilterNotSelected"
              }
              onClick={() => handlePressButton(currentItem)}
              disabled={isDisabled}
            >
              {isIconButton && (
                <S.ViewCard
                  typeButton={
                    optionsSelected.includes(currentItem)
                      ? "financialFilterSelected"
                      : "financialFilterNotSelected"
                  }
                >
                  <S.ImageIcon
                    src={isIconButton ? iconsData[currentItem].icon : null}
                    alt={isIconButton ? iconsData[currentItem].name : null}
                  />
                </S.ViewCard>
              )}

              <S.Label
                typeButton={
                  optionsSelected.includes(currentItem)
                    ? "financialFilterSelected"
                    : "financialFilterNotSelected"
                }
              >
                {data[currentItem]}
              </S.Label>
            </Button>
          </S.Wrapper>
        ))}
      </S.Content>
    </S.Container>
  );
};

export default OptionsFilterComponent;
