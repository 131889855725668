import { Button } from "components/Button";
import { Input } from "components/Input";
import React, { useEffect, useState } from "react";
import { FiChevronLeft } from "react-icons/fi";
import * as Yup from "yup";

import { useTheme } from "styled-components";

import * as S from "./styles";
import { useToast } from "hooks/toast";
import { EnvironmentDTO } from "dtos/PmocEnvironmentDTO";
import { api } from "services/api";
import { useEnvironment } from "hooks/pmoc/environmentPmoc";
import getValidationErrors from "utils/getValidationErrors";
import { ModalPageProps } from "components/ModalPageClients/types";
import { EnvironmentPageProps } from "../EnvironmentFlow/types";
import { useEditClient } from "hooks/editClient";
import { UnityDTO } from "dtos/ClientDTO";
import { AlertModal } from "components/AlertModal";

import axios from "axios";
import { LoadingProfiz } from "components/LoadingProfiz";
import { ShowWhenHavePermission } from "components/Permission";

type Errors = {
  [key: string]: string;
};

type PageProps = {
  handleChooseEditModal?: ModalPageProps;
  handleChooseEnvironment?: EnvironmentPageProps;
};

type ResponseEnvironmentApi = {
  data: EnvironmentDTO;
};

type ResponseUnityProps = {
  data: UnityDTO;
};

export function EditEnvironment({
  handleChooseEditModal,
  handleChooseEnvironment,
}: PageProps) {
  const theme = useTheme();
  const { addToast } = useToast();

  const {
    userIdPMOC,
    unityIdPMOC,
    nameFlow,
    environment,
    handleSetEnvironment,
  } = useEnvironment();
  const { setSelectedUnity } = useEditClient();

  const [isVisibleModal, setIsVisibleModal] = useState(false);

  const [nameEnvironment, setNameEnvironment] = useState<string>("");
  const [environmentLocation, setEnvironmentLocation] = useState<string>("");
  const [fixedNumber, setFixedNumber] = useState<string>("");
  const [variableNumber, setVariableNumber] = useState<string>("");
  const [airConditioned, setAirConditioned] = useState<string>("");
  const [thermal, setThermal] = useState<string>("");

  const [loadingButton, setLoadingButton] = useState(false);
  const [loadingButtonDelete, setLoadingButtonDelete] = useState(false);

  const [loadingInfo, setLoadingInfo] = useState(false);

  const [hasError, setHasError] = useState<Errors>({} as Errors);

  useEffect(() => {
    SetData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [environment]);

  async function SetData() {
    try {
      setLoadingInfo(true);

      environment.name && setNameEnvironment(environment.name);
      environment.location && setEnvironmentLocation(environment.location);
      environment.numberOccupants &&
        environment.numberOccupants.fixed &&
        setFixedNumber(environment.numberOccupants?.fixed.toString());
      environment.numberOccupants &&
        environment.numberOccupants.variable &&
        setVariableNumber(environment.numberOccupants.variable.toString());
      environment.airConditionedArea &&
        setAirConditioned(environment.airConditionedArea.toString());
      environment.thermalLoad && setThermal(environment.thermalLoad);
    } catch (err) {
      addToast({
        title: "Ops!!",
        description: "Ocorreu um erro ao carregar os dados",
        type: "error",
      });
    } finally {
      setLoadingInfo(false);
    }
  }

  function handleBackButton() {
    if (nameFlow === "unity" && handleChooseEditModal) {
      return handleChooseEditModal("detailEnvironment");
    }

    if (nameFlow === "pmoc" && handleChooseEnvironment) {
      return handleChooseEnvironment("detailEnvironment");
    }
  }

  async function handleEdit() {
    try {
      setLoadingButton(true);

      if (nameEnvironment.trim().length < 1) {
        setHasError((errors) => ({
          ...errors,
          nameEnvironment: "Nome é obrigatório.",
        }));
        addToast({
          title: "Ops!!",
          description: "Os campos obrigatórios devem ser preenchidos.",
          type: "error",
        });
        return;
      }

      setHasError({});

      let obj: EnvironmentDTO = {
        name: nameEnvironment.trim(),
        location: environmentLocation.trim(),
        numberOccupants: {
          fixed: fixedNumber.trim(),
          variable: variableNumber.trim(),
        },
        airConditionedArea: airConditioned.trim(),
        thermalLoad: thermal.trim(),
      };

      await api.put(
        `/client/${userIdPMOC}/unities/${unityIdPMOC}/environment/${environment.id}`,
        obj
      );

      addToast({
        title: "Tudo Certo!",
        description: "O ambiente foi alterado com sucesso",
        type: "success",
      });

      //setando o ambiente
      const { data }: ResponseEnvironmentApi = await api.get(
        `/client/${userIdPMOC}/unities/${unityIdPMOC}/environment/${environment.id}`
      );
      handleSetEnvironment(data);

      //mandar para o fluxo de unidades...
      if (nameFlow === "unity" && handleChooseEditModal) {
        return handleChooseEditModal("detailEnvironment");
      }

      if (nameFlow === "pmoc" && handleChooseEnvironment) {
        return handleChooseEnvironment("detailEnvironment");
      }
    } catch (error) {
      if (error instanceof Yup.ValidationError) {
        const errors = getValidationErrors(error);
        setHasError(errors);

        addToast({
          title: "Ops!!",
          description: "Os campos obrigatórios devem ser preenchidos.",
          type: "error",
        });
        return;
      }

      addToast({
        title: "Erro",
        description:
          axios.isAxiosError(error) && error.response?.data.error
            ? error.response.data.error
            : "Não foi possível editar o ambiente.",
        type: "error",
      });
    } finally {
      setLoadingButton(false);
    }
  }

  async function handleDelete() {
    try {
      setLoadingButtonDelete(true);

      await api.delete(
        `/client/${userIdPMOC}/unities/${unityIdPMOC}/environment/${environment.id}`
      );

      addToast({
        title: "Tudo certo!",
        description: "Ambiente deletado com sucesso!",
        type: "success",
      });

      if (nameFlow === "unity" && handleChooseEditModal) {
        //atualizar o contexto da unidade...
        const { data }: ResponseUnityProps = await api.get(
          `/clients/${userIdPMOC}/unities/${unityIdPMOC}`
        );
        setSelectedUnity(data);

        return handleChooseEditModal("unityDetail");
      }

      //mandar para o fluxo de pmoc
      if (nameFlow === "pmoc" && handleChooseEnvironment) {
        return handleChooseEnvironment("listEnvironment");
      }
    } catch (error) {
      addToast({
        title: "Erro",
        description:
          axios.isAxiosError(error) && error.response?.data.error
            ? error.response.data.error
            : "Não foi possível deletar o ambiente.",
        type: "error",
      });
    } finally {
      setLoadingButtonDelete(false);
    }
  }

  return (
    <>
      {loadingInfo ? (
        <LoadingProfiz isVisible={loadingInfo} />
      ) : (
        <S.Container>
          <AlertModal
            isVisible={isVisibleModal}
            title="Deseja apagar?"
            description="Deseja realmente apagar esse item?"
            action="choose"
            handleConfirm={() => handleDelete()}
            onCloseModal={() => setIsVisibleModal(false)}
          />

          <S.BackButton onClick={() => handleBackButton()}>
            <FiChevronLeft size={16} color={theme.colors.text} />
          </S.BackButton>
          <S.Top>
            <p>Editar ambiente</p>
          </S.Top>

          <S.WrapperInput>
            <Input
              name="Nome do ambiente*"
              placeholder="Ex.: Consultório, recepção."
              value={nameEnvironment}
              onChange={(e) => setNameEnvironment(e.target.value.trimStart())}
              hasError={hasError.nameEnvironment}
              onFocusClearError={() =>
                setHasError({ ...hasError, nameEnvironment: "" })
              }
            />
          </S.WrapperInput>

          <S.WrapperInput>
            <Input
              name="Localização do ambiente no prédio"
              placeholder="Ex.: Térreo, 1º andar, cobertura."
              value={environmentLocation}
              onChange={(e) =>
                setEnvironmentLocation(e.target.value.trimStart())
              }
            />
          </S.WrapperInput>

          <S.WrapperInput>
            <S.SubTitle>Número de ocupantes do ambiente</S.SubTitle>
            <S.WrapperDualInput>
              <S.WrapperInputSmall>
                <Input
                  type="number"
                  name="Fixos"
                  placeholder="Ex.: 8."
                  value={fixedNumber}
                  onChange={(e) => setFixedNumber(e.target.value)}
                />
              </S.WrapperInputSmall>
              <S.WrapperInputSmall>
                <Input
                  type="number"
                  name="Variáveis"
                  placeholder="Ex.: 8."
                  value={variableNumber}
                  onChange={(e) => setVariableNumber(e.target.value)}
                />
              </S.WrapperInputSmall>
            </S.WrapperDualInput>
          </S.WrapperInput>

          <S.WrapperInput>
            <Input
              type="number"
              name="Área climatizada (m²)"
              placeholder="Ex.: 10m²"
              value={airConditioned}
              onChange={(e) => setAirConditioned(e.target.value)}
            />
          </S.WrapperInput>

          <S.WrapperInput>
            <Input
              name="Carga térmica do ambiente"
              placeholder="Ex.: 9000 BTU's."
              value={thermal}
              onChange={(e) => setThermal(e.target.value.trimStart())}
            />
          </S.WrapperInput>

          <S.WrapperInput>
            <Button
              loading={loadingButton}
              disabled={loadingButton}
              typeButton="default"
              onClick={handleEdit}
            >
              Salvar alterações
            </Button>
          </S.WrapperInput>

          <S.WrapperInput>
            <ShowWhenHavePermission
              moduleHash="client-unity-environment"
              actionHash="delete"
            >
              <Button
                typeButton="delete"
                loading={loadingButtonDelete}
                disabled={loadingButtonDelete}
                onClick={() => {
                  setIsVisibleModal(true);
                }}
              >
                Deletar ambiente
              </Button>
            </ShowWhenHavePermission>
          </S.WrapperInput>
        </S.Container>
      )}
    </>
  );
}
