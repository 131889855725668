import { useAuth } from 'hooks/auth';
import Profile from "assets/icons/profile.svg";

import * as S from './styles';

export function CardResponsible() {
  const { user } = useAuth();

  return (
    <>
    <S.Label>Responsável pela execução*</S.Label>
    <S.Container>
      <div className='responsible-card'>
        <img
          className='icon'
          src={Profile}
          alt="Imagem de icone de perfil"
        />
        <S.Content>
          <p className="responsible-name">{user.name}</p>
        </S.Content>
      </div>
    </S.Container>
    </>
  );
}
