import { FormEvent, useEffect, useRef, useState } from "react";
import { useHistory, useParams } from "react-router";
import request from "axios";
import axios from "axios";
import { isEqual } from "lodash";
import * as Yup from "yup";

import { ContentLeft } from "templates/ContentLeft";

import { Input } from "components/Input";
import { Button } from "components/Button";
import { ArrowButton } from "components/ArrowButton";
import { UnityAreaComponent } from "components/UnityAreaComponent";
import { DiscardInformationModal } from "components/DiscardInformationModal";

import { useAuth } from "hooks/auth";
import { useToast } from "hooks/toast";
import { useClient } from "hooks/budget/client";
import { useSelectedService } from "hooks/budget/selectedServices";
import { useSelectedChecklist } from "hooks/budget/selectedLocalChecklist";
import { useSelectedMaterial } from "hooks/budget/selectedMaterials";
import { useSelectedPaymentConditions } from "hooks/budget/selectedPaymentConditions";
import { useObservation } from "hooks/budget/observation";
import { useTotalBudget } from "hooks/budget/totalBudget";
import { useSelectedProducts } from "hooks/budget/selectedProducts";

import { useWarningMessage } from "hooks/warningMessage";
import addMaskMoney from "utils/addMaskMoney";
import getValidationErrors from "utils/getValidationErrors";
import { mappedBudgetProducts } from "utils/mappedBudgetProducts";
import { mappedServicesForApi } from "utils/mappedServicesForApi";
import { mappedLocalChecklistForApi } from "utils/mappedLocalChecklistForApi";
import { mappedMaterialsForApi } from "utils/mappedMaterialsForApi";

import { ClientArea } from "./ClientArea";
import { ServiceArea } from "./ServiceArea";
import { MaterialsArea } from "./MaterialsArea";
import { LocalChecklistArea } from "./LocalChecklistArea";
import { PaymentConditionsArea } from "./PaymentConditionsArea";
import { DefaultObservationsArea } from "./DefaultObservationsArea";

import { ModeProps } from "dtos/businessProposalsDTO";

import * as S from "./styles";
import { api } from "services/api";
import apiv2 from "services/apiv2";
import { ProductsArea } from "components/ModalProducts/ProductsArea";

type Errors = {
  [key: string]: string;
};

type RouteParams = {
  mode: ModeProps;
};

export function NewBudget() {
  const { user } = useAuth();
  const { addToast } = useToast();
  const history = useHistory();
  const { mode } = useParams<RouteParams>();
  const [disableButton, setDisableButton] = useState(false);

  const {
    client,
    clearClientData,
    comingFromOpportunity,
    opportunity_id,
    clearUnityData,
    unity,
    comingFromOSOpportunity,
  } = useClient();
  const { selectedProductsInContext, clearSelectedProducts } =
    useSelectedProducts();
  const { selectedServices, clearServicesData } = useSelectedService();
  const { selectedChecklistItems, clearChecklistsData } =
    useSelectedChecklist();
  const { selectedMaterials, clearMaterialsData } = useSelectedMaterial();
  const {
    selectedPromptPayment,
    selectedIncomingPayment,
    selectedInstallmentPayment,
    handleDeleteSelectedPaymentConditions,
  } = useSelectedPaymentConditions();
  const { totalBudget } = useTotalBudget();
  const { text, handleSetText, clearObservationData } = useObservation();
  const {
    budgetMessage,
    handleSetBudgetMessage,
    handleSetModifyBudget,
    modifyBudget,
    linkPage,
    handleClearLinkPage,
  } = useWarningMessage();

  const [budgetValidation, setBudgetValidation] = useState(
    comingFromOSOpportunity ? "7" : ""
  );

  const [hasError, setHasError] = useState<Errors>({} as Errors);
  const [hasErrorOnClient, setHasErrorOnClient] = useState("");
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [hasErrorOnUnity, setHasErrorOnUnity] = useState("");
  const [hasErrorOnProducts, setHasErrorOnProducts] = useState("");
  const [hasErrorOnServices, setHasErrorOnServices] = useState("");

  const [hasErrorOnPaymentConditions, setHasErrorOnPaymentConditions] =
    useState("");

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [actualInfos, setActualInfos] = useState(generateBudgetData());
  const [warningMessage, setWarningMessage] = useState(false);

  const containerComponent = useRef(null);

  useEffect(() => {
    if (budgetMessage && modifyBudget) {
      setWarningMessage(true);
      handleSetBudgetMessage(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [budgetMessage]);

  function clearFormData() {
    clearClientData();
    clearSelectedProducts();
    clearServicesData();
    clearChecklistsData();
    clearMaterialsData();
    clearObservationData();
    handleDeleteSelectedPaymentConditions();
    clearUnityData();
    handleClearLinkPage();
    clearSelectedProducts();
  }

  useEffect(() => {
    if (!comingFromOpportunity && !comingFromOSOpportunity) {
      clearFormData();
      clearUnityData();
    }
    return () => clearFormData();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    //Ao trocar de cliente, retira o erro.
    if (hasErrorOnClient) {
      setHasErrorOnClient("");
    }
  }, [client]); // eslint-disable-line react-hooks/exhaustive-deps

  function handleSetBudgetValidation(budgetValidationText: string) {
    setBudgetValidation(budgetValidationText);
  }

  function generateBudgetData() {
    let clientAddress =
      Object.keys(unity).length > 0
        ? unity
        : {
            id: 0,
            clientId: "",
            name: "",
            responsible: "",
            email: "",
            phone: "",
            postalCode: "",
            street: "",
            district: "",
            number: "",
            complement: "",
            city: "",
            uf: "",
            additionalInfo: "",
            default: true,
            createdAt: "",
            environments: [],
          };

    let clientObj;

    if (comingFromOSOpportunity) {
      clientObj = client;
    } else {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      clientObj = {
        ...client,
        address: clientAddress,
      };
    }

    return {
      client: clientObj,
      opportunity_id: opportunity_id,
      products: mappedBudgetProducts(selectedProductsInContext),
      services: mappedServicesForApi(selectedServices),
      localChecklists: mappedLocalChecklistForApi(selectedChecklistItems),
      materials: mappedMaterialsForApi(selectedMaterials),
      budget_validity: budgetValidation,
      total: totalBudget,
      payment_condition: {
        promptPayment:
          totalBudget > 0
            ? selectedPromptPayment
            : [
                {
                  index: "promptPayment",
                  discount: null,
                  total: 0,
                  formattedTotal: "R$ 0,00",
                  description: "Gratuito",
                },
              ],
        installment: selectedInstallmentPayment,
        incoming: selectedIncomingPayment,
      },
      observations: text,
      mode,
    };
  }

  async function handleSubmit() {
    try {
      setDisableButton(true);
      if (!client.id) {
        setHasErrorOnClient("Cliente é obrigatório");
      } else {
        setHasErrorOnClient("");
      }

      if (!selectedProductsInContext.length) {
        setHasErrorOnProducts("Produto é obrigatório");
      } else {
        setHasErrorOnProducts("");
      }

      if (
        (mode === "products" || mode === "products-services") &&
        !selectedProductsInContext.length
      ) {
        setHasErrorOnProducts("Produto é obrigatório");
      } else {
        setHasErrorOnProducts("");
      }

      if (
        (mode === "services" || mode === "products-services") &&
        !selectedServices.length
      ) {
        setHasErrorOnServices("Serviço é obrigatório");
      } else {
        setHasErrorOnServices("");
      }

      if (
        totalBudget > 0 &&
        !selectedPromptPayment.length &&
        !selectedIncomingPayment.length &&
        !selectedInstallmentPayment.length
      ) {
        setHasErrorOnPaymentConditions(
          "Condições de pagamento são obrigatórias"
        );
      } else {
        setHasErrorOnPaymentConditions("");
      }

      const schema = () => {
        return Yup.object().shape({
          budgetValidation: Yup.string().required(
            "Validade do orçamento é obrigatório"
          ),
        });
      };

      setHasError({} as Errors);
      await schema().validate(
        {
          budgetValidation,
        },
        { abortEarly: false }
      );

      const validationByModePC =
        mode === "products"
          ? !selectedProductsInContext.length
          : mode === "services"
          ? !selectedServices.length
          : !selectedProductsInContext.length || !selectedServices.length;

      if (
        !client.id ||
        validationByModePC ||
        (totalBudget > 0 &&
          !selectedPromptPayment.length &&
          !selectedIncomingPayment.length &&
          !selectedInstallmentPayment.length)
      ) {
        addToast({
          title: "Ops!!",
          description: "Alguns campos são obrigatórios",
          type: "error",
        });
        return;
      }

      const newBudgetData = generateBudgetData();
      await apiv2.post("/budgets", { ...newBudgetData, originCreate: "web" });

      addToast({
        title: "Orçamento cadastrado com sucesso!",
        description: "Entre na listagem de orçamentos para vê-lo",
        type: "success",
      });

      history.push("/budgets");
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        const errors = getValidationErrors(err);
        setHasError(errors);
      }
      addToast({
        title: "Ops!!",
        description:
          axios.isAxiosError(err) && err.response?.data.error
            ? err.response.data.error
            : "Ocorreu um erro ao cadastrar o orçamento.",
        type: "error",
      });
    } finally {
      setDisableButton(false);
    }
  }

  useEffect(() => {
    if (isEqual(actualInfos, generateBudgetData())) {
      return handleSetModifyBudget(false);
    }
    handleSetModifyBudget(true);

    return () => {
      handleSetModifyBudget(false);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    client,
    unity,
    opportunity_id,
    selectedProductsInContext,
    selectedServices,
    selectedChecklistItems,
    selectedMaterials,
    budgetValidation,
    totalBudget,
    selectedPromptPayment,
    selectedInstallmentPayment,
    selectedIncomingPayment,
    text,
  ]);

  const handleLogin = async () => {
    try {
      if (user.profileComplete && user.profileApproved) {
        const { data } = await api.post("/webview/auth");
        const win = window.open(data.url, "_blank");
        win?.focus();
      } else {
        addToast({
          title: "Ops!!",
          description: "Não foi possível fazer login na loja",
          type: "error",
        });
      }
    } catch (error) {
      addToast({
        title: "Ops!!",
        description:
          request.isAxiosError(error) && error.response?.data.error
            ? error.response.data.error
            : "Não foi possível fazer login na loja",
        type: "error",
      });
    } finally {
      history.goBack();
    }
  };

  const handleSubmitForm = (event: FormEvent<HTMLFormElement>): void => {
    event.preventDefault();
  };

  window.onbeforeunload = function () {
    return "Ao sair os dados serão perdidos. Deseja realmente sair?";
  };

  return (
    <S.Container ref={containerComponent}>
      <DiscardInformationModal
        showModal={warningMessage}
        handleConfirmButton={() => {
          setWarningMessage(false);
          handleSetBudgetMessage(false);
          handleSetModifyBudget(false);
          if (linkPage && linkPage.length > 0) {
            return linkPage === "shopLink"
              ? handleLogin()
              : history.push(linkPage);
          }
          return history.goBack();
        }}
        handleCancelButton={() => {
          setWarningMessage(false);
          handleSetBudgetMessage(false);
        }}
      />
      <ContentLeft>
        <header>
          <div>
            <ArrowButton
              handleFunction={() => {
                if (modifyBudget) {
                  handleClearLinkPage();
                  return setWarningMessage(true);
                }
                return history.replace("/budgets");
              }}
            />
          </div>

          <h1>Novo orçamento</h1>
        </header>

        <form
          id={
            mode === "products"
              ? "form_orcamento_produto"
              : mode === "services"
              ? "form_orcamento_servico"
              : mode === "products-services"
              ? "form_orcamento_produto_servico"
              : ""
          }
          onSubmit={handleSubmitForm}
        >
          <S.Wrapper>
            <ClientArea
              module={"budget"}
              hasError={hasErrorOnClient}
              disabledDelete={comingFromOpportunity}
            />

            <S.Wrapper>
              <UnityAreaComponent
                module="budget"
                hasError={hasErrorOnUnity}
                label="Selecione o endereço do cliente"
              />
            </S.Wrapper>

            {(mode === "products" || mode === "products-services") && (
              <ProductsArea hasError={hasErrorOnProducts} />
            )}

            {(mode === "services" || mode === "products-services") && (
              <ServiceArea hasError={hasErrorOnServices} />
            )}

            <LocalChecklistArea />

            <MaterialsArea />

            <S.Wrapper>
              <S.Title>Informações de pagamento</S.Title>

              <S.Wrapper>
                <Input
                  name="Total*"
                  value={addMaskMoney(totalBudget)}
                  readOnly
                  placeholder="R$10,00"
                />
              </S.Wrapper>

              <PaymentConditionsArea hasError={hasErrorOnPaymentConditions} />

              <S.WrapperBudgetValidity>
                <S.WrapperInputBudgetValidity>
                  <Input
                    name="Validade do orçamento*"
                    placeholder="Ex: 7"
                    type="number"
                    onChange={(e) => handleSetBudgetValidation(e.target.value)}
                    value={budgetValidation}
                    hasError={hasError.budgetValidation}
                  />
                </S.WrapperInputBudgetValidity>

                <span>dias</span>
              </S.WrapperBudgetValidity>

              <S.Wrapper>
                <DefaultObservationsArea
                  valueTextArea={text}
                  // eslint-disable-next-line react/jsx-no-bind
                  onChangeTextArea={(text) => handleSetText(text.trimStart())}
                />
              </S.Wrapper>

              <S.WrapperButton>
                <Button disabled={disableButton} onClick={() => handleSubmit()}>
                  Criar orçamento
                </Button>
              </S.WrapperButton>
            </S.Wrapper>
          </S.Wrapper>
        </form>
      </ContentLeft>
    </S.Container>
  );
}
