import React, { useRef, useCallback, useState, ChangeEvent } from "react";

import { FiPlus } from "react-icons/fi";

import { LoadingProfiz } from "components/LoadingProfiz";

import { useToast } from "hooks/toast";

import { api } from "services/api";

import theme from "styles/theme";
import * as S from "./styles";

type FileProps = {
  base64Image: string;
  extension: string;
};

interface CardProps {
  disabled?: boolean;
  handleUpdateDigitalSign: (digitalSign: string) => void;
}

export function CardUploadDigitalSign({
  disabled = false,
  handleUpdateDigitalSign,
}: CardProps) {
  const [isLoading, setIsLoading] = useState(false);
  const { addToast } = useToast();

  const hiddenFileInput = useRef<HTMLInputElement | null>(null);

  const handleAddSignature = () => {
    if (hiddenFileInput.current) hiddenFileInput.current.click();
  };

  const handleSubmitPhoto = async ({ base64Image, extension }: FileProps) => {
    try {
      const response = await api.put("/users/me/digital-sign", {
        photo: {
          encode: base64Image,
          extensao: extension,
        },
      });

      handleUpdateDigitalSign(response.data.digitalSign);

      addToast({
        type: "success",
        title: "Assinatura atualizada com sucesso.",
      });
    } catch (error) {
      addToast({
        title: "Erro ao atualizar assinatura.",
        type: "error",
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleAddSignaturePhoto = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      setIsLoading(true);
      if (e.target.files) {
        const file = e.target.files[0];
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const [_, extension] = file.type.split("/");

        const reader = new FileReader();

        reader.readAsDataURL(file);

        reader.onload = async () => {
          const result = reader.result as string;

          const { 1: fileBase64Splited } = result.split(",");

          await handleSubmitPhoto({
            base64Image: fileBase64Splited,
            extension,
          });
        };
      }
    },
    [] //eslint-disable-line react-hooks/exhaustive-deps
  );

  return isLoading ? (
    <LoadingProfiz isVisible={isLoading} />
  ) : (
    <S.Container>
      <S.WrapperInput>
        <S.InputContainer>
          <button disabled={disabled} onClick={handleAddSignature}>
            <FiPlus size="18" color={theme.colors.primary} />
          </button>

          <input
            type="file"
            ref={hiddenFileInput}
            accept="image/*"
            onChange={handleAddSignaturePhoto}
          />
        </S.InputContainer>

        <S.Description>
          Adicione uma foto da sua assinatura e personalize seus PDF's.
        </S.Description>
      </S.WrapperInput>
    </S.Container>
  );
}
