import React, { createContext, useContext, useState } from 'react'
import { MaterialProps } from '../../dtos/MaterialDTO'
import addMaskMoney from '../../utils/addMaskMoney'
import { ProviderProps } from 'hooks'

export type SelectedMaterialProps = {
  quantity: number
  total: number
  formattedTotal: string
} & MaterialProps

type UpdateMaterialData = {
  id: number
  categoryId: number
  type: {
    id: number
    name: string
  }
  item: {
    id: number
    name: string
  }
  price: number
  formattedPrice: string
}

type SelectedMaterialsContextData = {
  selectedMaterials: SelectedMaterialProps[]
  handleSetSelectedMaterials: (
    selectedMaterials: SelectedMaterialProps[]
  ) => void
  handleUpdateQuantity: (id: number, quantity: number) => void
  calculateTotalMaterials: () => number
  clearMaterialsData: () => void
  handleUpdatedSelectedMaterial: (material: UpdateMaterialData) => void
  handleRemoveSelectedMaterial: (materialId: number) => void
}

const SelectedMaterialContext = createContext<SelectedMaterialsContextData>(
  {} as SelectedMaterialsContextData
)

export function useSelectedMaterial(): SelectedMaterialsContextData {
  const context = useContext(SelectedMaterialContext)

  if (!context) {
    throw Error(
      'useSelectedMaterial must be used within a SelectedMaterialProvider'
    )
  }

  return context
}

export const SelectedMaterialProvider = ({ children }: ProviderProps) => {
  const [selectedMaterials, setSelectedMaterials] = useState<
    SelectedMaterialProps[]
  >([])

  function handleSetSelectedMaterials(
    selectedMaterialsValue: SelectedMaterialProps[]
  ) {
    setSelectedMaterials(selectedMaterialsValue)
  }

  function handleUpdateQuantity(id: number, quantity: number) {
    const updatedSelectedMaterials = selectedMaterials.map(
      (selectedMaterial: SelectedMaterialProps) => {
        if (selectedMaterial.id === id) {
          return {
            ...selectedMaterial,
            total: selectedMaterial.price * quantity,
            formattedTotal: addMaskMoney(selectedMaterial.price * quantity),
            quantity
          }
        }

        return selectedMaterial
      }
    )

    setSelectedMaterials(updatedSelectedMaterials)
  }

  function handleRemoveSelectedMaterial(id: number) {
    setSelectedMaterials((oldSelectedMaterials) => {
      return oldSelectedMaterials.filter((material) => material.id !== id)
    })
  }

  function calculateTotalMaterials() {
    return selectedMaterials.reduce((accumulator, current) => {
      return accumulator + current.total
    }, 0)
  }

  function clearMaterialsData() {
    setSelectedMaterials([])
  }

  function handleUpdatedSelectedMaterial(material: UpdateMaterialData) {
    const payloadSelectedServices = selectedMaterials.map((currentMaterial) => {
      if (currentMaterial.id === material.id) {
        return {
          ...material,
          total: currentMaterial.quantity * material.price,
          formattedTotal: addMaskMoney(
            currentMaterial.quantity * material.price
          ),
          quantity: currentMaterial.quantity
        }
      }
      return currentMaterial
    }) as SelectedMaterialProps[]

    setSelectedMaterials(payloadSelectedServices)
  }

  return (
    <SelectedMaterialContext.Provider
      value={{
        selectedMaterials,
        handleSetSelectedMaterials,
        handleUpdateQuantity,
        handleRemoveSelectedMaterial,
        calculateTotalMaterials,
        clearMaterialsData,
        handleUpdatedSelectedMaterial
      }}
    >
      {children}
    </SelectedMaterialContext.Provider>
  )
}
