import { StageProps } from "dtos/ServiceStagesDTO";
import { switchServiceStage } from "./switchServiceStage";

import * as S from "./styles";

type Props = {
  title?: string;
  serviceStage?: StageProps;
  amount: number;
  onViewRegistration: () => void;
  isDisabled?: boolean;
};

export function ButtonRegistrationDetails({
  title,
  serviceStage,
  amount,
  isDisabled,
  onViewRegistration,
}: Props) {
  const stageText = switchServiceStage(serviceStage!);

  return (
    <S.Container>
      <S.Title>
        {title || stageText} ({amount})
      </S.Title>

      <S.ButtonViewAllRegistration disabled={isDisabled} onClick={onViewRegistration}>
        Ver todos
      </S.ButtonViewAllRegistration>
    </S.Container>
  );
}
