import styled, { css } from "styled-components";

export const Container = styled.div`
  width: 100%;
`;

export const Header = styled.header`
  ${({ theme }) => css`
    display: flex;
    margin-top: 30px;
    margin-left: 30px;
  `}
`;

export const Content = styled.div`
  align-items: center;
  padding-left: 30px;
`;

export const TitleDate = styled.h1`
  ${({ theme }) => css`
    font-family: ${theme.font.family};
    font-style: normal;
    font-weight: ${theme.font.bold};
    font-size: 18px;
    line-height: 22px;
    color: rgba(255, 255, 255, 0.87);
    margin-top: 30px;
  `}
`;

export const Subtitle = styled.p`
  ${({ theme }) => css`
    font-family: ${theme.font.family};
    font-style: normal;
    font-weight: ${theme.font.normal};
    font-size: 12px;
    line-height: 15px;
    color: rgba(255, 255, 255, 0.6);
    margin-top: 4px;
  `}
`;

export const PmocInfo = styled.div`
  ${({ theme }) => css`
    margin-top: 25px;
    width: 470px;
    height: 160px;
    background: ${theme.colors.financial_card};
    border-radius: 12px;
    padding: 16px;
  `}
`;

export const PmocInfoText = styled.h1`
  ${({ theme }) => css`
    font-family: ${theme.font.family};
    font-weight: ${theme.font.bold};
    font-style: normal;
    font-size: ${theme.font.sizes.xsmall};
    line-height: 15px;
    color: rgba(255, 255, 255, 0.87);
  `}
`;

export const PmocInfoAddressWrapper = styled.div`
  margin-top: 10px;
`;

export const PmocInfoAddress = styled.div`
  ${({ theme }) => css`
    font-family: ${theme.font.family};
    font-weight: ${theme.font.normal};
    font-size: ${theme.font.sizes.xsmall};
    font-size: 12px;
    font-style: normal;
    line-height: 15px;
    color: rgba(255, 255, 255, 0.6);
  `}
`;

export const UserInfoWrapper = styled.div`
  margin-top: 25px;
  display: flex;
  align-items: center;
`;

export const UserInfoTextWrapper = styled.div`
  flex-direction: column;
  margin-left: 10px;
`;

export const ServiceOrderTitle = styled.div`
  ${({ theme }) => css`
    margin-top: 40px;
    margin-bottom: 25px;
    font-family: ${theme.font.family};
    font-weight: ${theme.font.bold};
    font-size: ${theme.font.sizes.medium};
    color: rgba(255, 255, 255, 0.87);
    line-height: 22px;
  `}
`;

export const CardMonthWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 122px [col-start]);
  width: 470px;
`;

export const CardMonth = styled.button`
  ${({ theme }) => css`
    width: 106px;
    height: 106px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    background-color: ${theme.colors.menu_detail};
    margin-right: 15px;
    margin-bottom: 15px;

    border-radius: 10px;

    div {
      width: 60px;
      height: 60px;
    }
  `}
`;

export const MonthPercentage = styled.p`
  ${({ theme }) => css`
    font-family: ${theme.font.bold};
    font-size: ${theme.font.sizes.xsmall};
    font-style: normal;
    line-height: 15px;
    color: rgba(255, 255, 255, 0.87);
    margin-bottom: 8px;
  `}
`;

export const MonthText = styled.p`
  ${({ theme }) => css`
    margin-top: 12px;
    font-family: ${theme.font.bold};
    font-size: ${theme.font.sizes.small};
    font-style: normal;
    line-height: 15px;
    color: rgba(255, 255, 255, 0.87);
  `}
`;
