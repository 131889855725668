import { Button } from "components/Button";
import { useState } from "react";
import {
  DragDropContext,
  Draggable,
  DropResult,
  Droppable,
} from "react-beautiful-dnd";

import * as S from "./styles";

import dragdropIcon from "assets/icons/service-checklists-icons/dragdropIcon.svg";
import checkIcon from "assets/icons/service-checklists-icons/checkIcon.svg";
import measureIcon from "assets/icons/service-checklists-icons/measureIcon.svg";
import photographIcon from "assets/icons/service-checklists-icons/photographIcon.svg";
import runIcon from "assets/icons/service-checklists-icons/runIcon.svg";

import { Input } from "components/Input";
import { useServiceChecklist } from "hooks/serviceChecklist";
import { CardChecklist } from "components/CardChecklist";
import { EditButton } from "components/EditButton";
import { DeleteButton } from "components/DeleteButton";
import { useToast } from "hooks/toast";
import { api } from "services/api";
import { ChecklistDetail, RegisterItem } from "../types";

import getValidationErrors from "utils/getValidationErrors";

import * as Yup from "yup";
import { ArrowButton } from "components/ArrowButton";
import { NewAlertModal } from "components/NewAlertModal";
import { LoadingProfiz } from "components/LoadingProfiz";
import axios from "axios";
import theme from "styles/theme";
import { EnvironmentPageProps, Page } from "pages/pmoc/EnvironmentFlow/types";
import { useEnvironment } from "hooks/pmoc/environmentPmoc";
import {
  Label,
  SelectCurrentAccountForCreateNewItem,
  ShowWhenHavePermission,
} from "components/Permission";
import { useAccount } from "hooks/permission/account";

type ChecklistProps = {
  handleFunction?: () => void;
  handleFunctionClose?: () => void;
  handleDetailRegister?: () => void;
  handleSaveInfo?: () => void;
  handleChooseEnvironment?: EnvironmentPageProps;
};

type Option = {
  [key: string]: string;
};

type Errors = {
  [key: string]: string;
};

const REGISTERS_LIMIT = 40;

export function ChecklistPage({
  handleFunction,
  handleFunctionClose,
  handleDetailRegister,
  handleSaveInfo,
  handleChooseEnvironment,
}: ChecklistProps) {
  const {
    isNewChecklist,
    detailChecklist,
    newItemChecklist,
    handleNewItemChecklist,
    handleSetChecklistDetail,
    handleSetDetailRegister,
    handleSetIsChecklistPage,
  } = useServiceChecklist();

  const { accounts, whoami } = useAccount();

  const { pushStackNavigation, popAndDeleteStackNavigation } = useEnvironment();


  const [nameChecklist, setNameChecklist] = useState(
    isNewChecklist
      ? newItemChecklist && newItemChecklist?.name
        ? newItemChecklist?.name
        : ""
      : detailChecklist.name
  );

  const { addToast } = useToast();
  const [isOpenAlertModal, setIsOpenAlertModal] = useState(false);
  const [isOpenAlertModalDelete, setIsOpenAlertModalDelete] = useState(false);
  const [hasError, setHasError] = useState<Errors>({} as Errors);

  const [objRegister, setObjectRegister] = useState<RegisterItem>(
    {} as RegisterItem
  );

  const [isLoading, setIsLoading] = useState(false);

  async function handleCreateChecklist() {
    try {
      if (
        !isNewChecklist &&
        detailChecklist?.registers?.length >= REGISTERS_LIMIT
      ) {
        addToast({
          title: "Máximo permitido",
          description: "Ops, só é permitido vincular até 40 registros.",
          type: "info",
        });
        return;
      }

      const schema = () => {
        return Yup.object().shape({
          nameChecklist: Yup.string().required("Valor do item é obrigatório"),
        });
      };

      await schema().validate(
        {
          nameChecklist,
        },
        { abortEarly: false }
      );

      setNewUserName();

      if (handleChooseEnvironment) {
        pushStackNavigation("checklistPage");
        handleChooseEnvironment("registerPage");
        return;
      }

      handleFunction && handleFunction();
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        const errors = getValidationErrors(err);
        setHasError(errors);

        addToast({
          title: "Ops!!",
          description: "O nome do checklist é obrigatório!",
          type: "error",
        });

        return;
      }
    }
  }

  function typeIcon(typeI: string) {
    const opt: Option = {
      fotografar: photographIcon,
      medir: measureIcon,
      verificar: checkIcon,
      executar: runIcon,
    };
    return opt[typeI];
  }

  function setNewUserName() {
    if (nameChecklist) {
      let aux = JSON.parse(
        JSON.stringify(isNewChecklist ? newItemChecklist : detailChecklist)
      );
      aux.name = nameChecklist;
      isNewChecklist
        ? handleNewItemChecklist(aux)
        : handleSetChecklistDetail(aux);
    }
  }
  async function handleSaveData() {
    try {
      setIsLoading(true);
      setNewUserName();

      let checklistApi = JSON.parse(
        JSON.stringify(isNewChecklist ? newItemChecklist : detailChecklist)
      );

      let orderRegisters = checklistApi.registers.map(
        (element: RegisterItem, index: number) => {
          return {
            id: Number(element.id),
            order: Number(index) + 1,
          };
        }
      );

      isNewChecklist
        ? await api.post(`/service/checklist`, {
            name: nameChecklist,
            stepIds: orderRegisters,
            accountId: whoami?.id,
          })
        : await api.put(`/service/checklist/${detailChecklist.id}`, {
            name: nameChecklist,
            stepIds: orderRegisters,
            accountId: whoami?.id,
          });

      //setar o contexto para vazio novamente em caso de criar um novo checklist
      if (isNewChecklist) {
        handleNewItemChecklist({});
      }
      handleSetChecklistDetail({} as ChecklistDetail);

      addToast({
        title: "OK!",
        description: `Checklist ${
          isNewChecklist ? "criado" : "alterado"
        } com sucesso.`,
        type: "success",
      });

      if (handleChooseEnvironment) {
        let page = popAndDeleteStackNavigation();
        handleChooseEnvironment(page as Page);
        return;
      }

      /* FECHA O MODAL */
      if (handleSaveInfo) {
        handleSaveInfo();
        return;
      }
      handleFunctionClose && handleFunctionClose();
    } catch (error) {
      addToast({
        title: "Ops!!",
        description:
          axios.isAxiosError(error) && error.response?.data.error
            ? error.response.data.error
            : `Não foi possível ${
                isNewChecklist ? "criar" : "alterar"
              } o checklist.`,
        type: "error",
      });
    } finally {
      setIsLoading(false);
    }
  }
  async function handleDeleteRegister(itemRegister: RegisterItem) {
    try {
      setIsLoading(true);

      const aux = JSON.parse(
        JSON.stringify(isNewChecklist ? newItemChecklist : detailChecklist)
      );

      if (aux.registers.length < 2) {
        return setIsOpenAlertModal(true);
      }

      let filterRegister = aux.registers.filter((register: RegisterItem) => {
        return Number(register.id) !== Number(itemRegister.id);
      });

      aux.registers = filterRegister;
      isNewChecklist
        ? handleNewItemChecklist(aux)
        : handleSetChecklistDetail(aux);

      if (!isNewChecklist && detailChecklist && detailChecklist.id) {
        //desvinculando o registro...
        await api.delete(`/service/checklist/step/link/${itemRegister.linkId}`);
      }

      addToast({
        title: "OK!",
        description: "Registro deletado com sucesso.",
        type: "success",
      });
    } catch (error) {
      addToast({
        title: "Ops!!",
        description:
          axios.isAxiosError(error) && error.response?.data.error
            ? error.response.data.error
            : "Não foi possível deletar o registro.",
        type: "error",
      });
    } finally {
      setIsLoading(false);
    }
  }
  function handleSetRegister(register: RegisterItem, index: number) {
    handleSetDetailRegister({ indexItem: index, registerDetail: register });
    handleSetIsChecklistPage(true);

    if (handleChooseEnvironment) {
      pushStackNavigation("checklistPage");
      handleChooseEnvironment("detailRegisterPage");
      return;
    }

    handleDetailRegister && handleDetailRegister();
  }

  function handleOnDragEnd(result: DropResult) {
    if (!result.destination) return;

    const aux = JSON.parse(
      JSON.stringify(isNewChecklist ? newItemChecklist : detailChecklist)
    );
    const items = Array.from(
      isNewChecklist ? newItemChecklist.registers : detailChecklist.registers
    );
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    aux.registers = items;

    isNewChecklist
      ? handleNewItemChecklist(aux)
      : handleSetChecklistDetail(aux);
  }

  const disabledButtonCondition =
    (detailChecklist?.default || !detailChecklist?.isNew) &&
    (!newItemChecklist?.registers || newItemChecklist?.registers.length < 1);

  return (
    <>
      {isLoading ? (
        <LoadingProfiz isVisible={isLoading} />
      ) : (
        <S.Container>
          <NewAlertModal
            isVisible={isOpenAlertModal}
            title={"Impossível excluir todos os registros"}
            description={
              "Você precisa ter pelo menos um registro adicionado neste checklist."
            }
            action="choose"
            isNotCancelButton
            handleConfirm={() => {
              setIsOpenAlertModal(false);
            }}
            onCloseModal={() => {
              setIsOpenAlertModal(false);
            }}
            labelConfirm="Confirmar"
            labelCancel="Cancelar"
            buttonConfirmColor={theme.colors.primary}
          />
          <NewAlertModal
            isVisible={isOpenAlertModalDelete}
            title="Remover registro do checklist?"
            description={
              "O Registro ainda poderá ser visualizado no menu “Registros”."
            }
            action="choose"
            handleConfirm={async () => {
              await handleDeleteRegister(objRegister);
              setIsOpenAlertModalDelete(false);
            }}
            onCloseModal={() => {
              setIsOpenAlertModalDelete(false);
            }}
            labelConfirm="Remover registro do checklist"
            labelCancel="Cancelar"
            buttonConfirmColor="#FA8B94"
          />

          <header>
            <div>
              <ArrowButton
                handleFunction={() => {
                  if (handleChooseEnvironment) {
                    let page = popAndDeleteStackNavigation();
                    handleChooseEnvironment(page as Page);
                    return;
                  }

                  handleFunctionClose && handleFunctionClose();
                }}
              />
              <h1>{isNewChecklist ? "Novo" : "Editar"} Checklist</h1>
            </div>
          </header>
          {/*Input do nome do Checklist*/}
          {/*listagem dos registros do checklist*/}

          <S.Content>
            {isNewChecklist && accounts.length > 1 && (
              <S.WrapperInput>
                <Label>Conta</Label>
                <SelectCurrentAccountForCreateNewItem
                  moduleHash="service-checklist"
                  actionHash="create"
                />
              </S.WrapperInput>
            )}

            <S.WrapperInput>
              <Input
                name="Nome do checklist*"
                placeholder="EX: Checklist Manut. Prev. CSN"
                value={nameChecklist}
                onChange={(e) => setNameChecklist(e.target.value.trimStart())}
                hasError={hasError.nameChecklist}
                onFocusClearError={() =>
                  setHasError({ ...hasError, nameChecklist: "" })
                }
                disabled={
                  !isNewChecklist &&
                  (detailChecklist.default || !detailChecklist?.isNew)
                    ? true
                    : false
                }
              />
            </S.WrapperInput>

            <S.DivMessage>
              <img src={dragdropIcon} alt="imagem" />
              <p>
                Mantenha os registros pressionados para arrastar e organizar na{" "}
                ordem que preferir
              </p>
            </S.DivMessage>
            <ShowWhenHavePermission
              moduleHash="service-checklist-step"
              actionHash="view"
            >
              <Button
                typeButton="outline"
                disabled={
                  !isNewChecklist &&
                  (detailChecklist.default || !detailChecklist?.isNew)
                    ? true
                    : false
                }
                onClick={handleCreateChecklist}
                style={{ marginBottom: 30 }}
              >
                Incluir registros
              </Button>
            </ShowWhenHavePermission>

            <ShowWhenHavePermission
              moduleHash="service-checklist-step-link"
              actionHash="view"
            >
              {/*Verifica se o checklist e novo ou sendo editado e faz os devidos tratamentos*/}
              {isNewChecklist ? (
                // novo checklist
                !newItemChecklist ||
                !newItemChecklist.registers ||
                (newItemChecklist.registers &&
                  newItemChecklist.registers.length < 1) ? (
                  <>
                    <S.WrapperEmptyMessage>
                      <p>Nenhum registro vinculado ao checklist</p>
                      <p>clique em “incluir registros”</p>
                      <p>para começar.</p>
                    </S.WrapperEmptyMessage>
                  </>
                ) : (
                  <DragDropContext
                    onDragEnd={(result) => {
                      handleOnDragEnd(result);
                    }}
                  >
                    <Droppable droppableId="newChecklistList">
                      {(provided) => (
                        <ul
                          className="newChecklistList"
                          {...provided.droppableProps}
                          ref={provided.innerRef}
                        >
                          {newItemChecklist.registers.map(
                            (currentRegister: RegisterItem, index: number) => {
                              return (
                                <Draggable
                                  key={index}
                                  draggableId={index.toString()}
                                  index={index}
                                >
                                  {(provided) => (
                                    <S.WrapperService
                                      ref={provided.innerRef}
                                      {...provided.draggableProps}
                                      {...provided.dragHandleProps}
                                    >
                                      <CardChecklist>
                                        <S.WrapperInfoCard>
                                          <S.ContainerImg>
                                            <img
                                              src={typeIcon(
                                                currentRegister.type
                                              )}
                                              alt="imagem"
                                            />
                                            <S.WrapperData>
                                              <h6>{currentRegister.name}</h6>
                                              <p>
                                                {currentRegister.description}
                                              </p>
                                            </S.WrapperData>
                                          </S.ContainerImg>
                                          <S.WrapperButtonContent>
                                            <S.WrapperButtom>
                                              {/*validar o checklist padrao nao pode ser editado...*/}
                                              <EditButton
                                                disabled={true}
                                                onClick={() => {
                                                  handleSetRegister(
                                                    currentRegister,
                                                    index
                                                  );
                                                }}
                                              />
                                            </S.WrapperButtom>

                                            <S.WrapperButtom>
                                              <DeleteButton
                                                onClick={() => {
                                                  if (
                                                    newItemChecklist.registers
                                                      .length < 2
                                                  ) {
                                                    return setIsOpenAlertModal(
                                                      true
                                                    );
                                                  }
                                                  setObjectRegister(
                                                    currentRegister
                                                  );
                                                  setIsOpenAlertModalDelete(
                                                    true
                                                  );
                                                }}
                                              />
                                            </S.WrapperButtom>
                                          </S.WrapperButtonContent>
                                        </S.WrapperInfoCard>
                                      </CardChecklist>
                                    </S.WrapperService>
                                  )}
                                </Draggable>
                              );
                            }
                          )}
                          {provided.placeholder}
                        </ul>
                      )}
                    </Droppable>
                  </DragDropContext>
                )
              ) : !detailChecklist.registers ||
                //edicao checklist
                detailChecklist.registers.length < 1 ? (
                <S.WrapperEmptyMessage>
                  <S.WrapperEmptyMessage>
                    <p>Nenhum registro vinculado ao checklist</p>
                    <p>clique em “incluir registros”</p>
                    <p>para começar.</p>
                  </S.WrapperEmptyMessage>
                </S.WrapperEmptyMessage>
              ) : (
                <>
                  {/*Bloqueando arrastar em elemento padrao*/}
                  {detailChecklist.default ? (
                    detailChecklist.registers.map((currentRegister, index) => {
                      return (
                        <S.WrapperService key={index}>
                          <CardChecklist>
                            <S.WrapperInfoCard>
                              <S.ContainerImg>
                                <img
                                  src={typeIcon(currentRegister.type)}
                                  alt="imagem"
                                />
                                <S.WrapperData>
                                  <h6>{currentRegister.name}</h6>
                                  <p>{currentRegister.description}</p>
                                </S.WrapperData>
                              </S.ContainerImg>
                              <S.WrapperButtonContent>
                                <ShowWhenHavePermission
                                  moduleHash="service-checklist-step"
                                  actionHash="edit"
                                >
                                  <S.WrapperButtom>
                                    {/*validar o checklist padrao nao pode ser editado...*/}
                                    <EditButton
                                      disabled={
                                        detailChecklist.default ? true : false
                                      }
                                      onClick={() => {
                                        handleSetRegister(
                                          currentRegister,
                                          index
                                        );
                                      }}
                                    />
                                  </S.WrapperButtom>
                                </ShowWhenHavePermission>

                                <ShowWhenHavePermission
                                  moduleHash="service-checklist-step-link"
                                  actionHash="delete"
                                >
                                  <S.WrapperButtom>
                                    <DeleteButton
                                      disabled={
                                        detailChecklist.default ? true : false
                                      }
                                      onClick={() => {
                                        //handleDeleteRegister(currentRegister.id, index)
                                        if (
                                          detailChecklist.registers.length < 2
                                        ) {
                                          return setIsOpenAlertModal(true);
                                        }
                                        setObjectRegister(currentRegister);
                                        setIsOpenAlertModalDelete(true);
                                      }}
                                    />
                                  </S.WrapperButtom>
                                </ShowWhenHavePermission>
                              </S.WrapperButtonContent>
                            </S.WrapperInfoCard>
                          </CardChecklist>
                        </S.WrapperService>
                      );
                    })
                  ) : (
                    <DragDropContext
                      onDragEnd={(result) => {
                        handleOnDragEnd(result);
                      }}
                    >
                      <Droppable droppableId="detailList">
                        {(provided) => (
                          <ul
                            className="detailList"
                            {...provided.droppableProps}
                            ref={provided.innerRef}
                          >
                            {detailChecklist.registers.map(
                              (currentRegister, index) => {
                                return (
                                  <Draggable
                                    key={index}
                                    draggableId={index.toString()}
                                    index={index}
                                  >
                                    {(provided) => (
                                      <S.WrapperService
                                        ref={provided.innerRef}
                                        {...provided.draggableProps}
                                        {...provided.dragHandleProps}
                                      >
                                        <CardChecklist>
                                          <S.WrapperInfoCard>
                                            <S.ContainerImg>
                                              <img
                                                src={typeIcon(
                                                  currentRegister.type
                                                )}
                                                alt="imagem"
                                              />
                                              <S.WrapperData>
                                                <h6>{currentRegister.name}</h6>
                                                <p>
                                                  {currentRegister.description}
                                                </p>
                                              </S.WrapperData>
                                            </S.ContainerImg>
                                            <S.WrapperButtonContent>
                                              <S.WrapperButtom>
                                                {/*validar o checklist padrao nao pode ser editado...*/}
                                                <EditButton
                                                  disabled={
                                                    detailChecklist.default ||
                                                    !detailChecklist?.isNew
                                                      ? true
                                                      : false
                                                  }
                                                  onClick={() => {
                                                    handleSetRegister(
                                                      currentRegister,
                                                      index
                                                    );
                                                  }}
                                                />
                                              </S.WrapperButtom>

                                              <S.WrapperButtom>
                                                <DeleteButton
                                                  disabled={
                                                    detailChecklist.default ||
                                                    !detailChecklist?.isNew
                                                      ? true
                                                      : false
                                                  }
                                                  onClick={() => {
                                                    if (
                                                      detailChecklist.registers
                                                        .length < 2
                                                    ) {
                                                      return setIsOpenAlertModal(
                                                        true
                                                      );
                                                    }
                                                    setObjectRegister(
                                                      currentRegister
                                                    );
                                                    setIsOpenAlertModalDelete(
                                                      true
                                                    );
                                                  }}
                                                />
                                              </S.WrapperButtom>
                                            </S.WrapperButtonContent>
                                          </S.WrapperInfoCard>
                                        </CardChecklist>
                                      </S.WrapperService>
                                    )}
                                  </Draggable>
                                );
                              }
                            )}
                            {provided.placeholder}
                          </ul>
                        )}
                      </Droppable>
                    </DragDropContext>
                  )}
                </>
              )}
            </ShowWhenHavePermission>

            <div>
              <Button
                typeButton={disabledButtonCondition ? "unselected" : "default"}
                disabled={disabledButtonCondition}
                onClick={handleSaveData}
              >
                Salvar
              </Button>
            </div>
          </S.Content>
        </S.Container>
      )}
    </>
  );
}
