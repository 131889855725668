import { ChangeEvent, useEffect, useState } from "react";

import { FiPaperclip } from "react-icons/fi";
import TrashIcon from "assets/icons/trash.svg";

import { useTheme } from "styled-components";
import { useToast } from "hooks/toast";
import { usePmoc } from "hooks/usePmoc";

import * as S from "./styles";
import { api } from "services/api";
import axios from "axios";

import loadingSvg from "assets/loading.svg";
import DownloadSvg from "assets/icons/download-button.svg";

type ARTFileResponse = {
  url: string;
};

type ARTFileProps = {
  url: string;
};

export function AttachmentArea() {
  const theme = useTheme();
  const { addToast } = useToast();
  const {
    handleSetArtEncode,
    handleSetArtExtension,
    clearArt,
    handleSetPmocArtFile,
    initialPmocProps,
    pmocArtFile,
  } = usePmoc();

  const [loadingModal, setLoadingModal] = useState(false);

  const [hasArtFile, setHasArtFile] = useState(false);

  useEffect(() => {
    if (
      pmocArtFile &&
      Object.keys(pmocArtFile).length > 0 &&
      pmocArtFile.url.length > 0
    ) {
      handleSetArtEncode(pmocArtFile.url);
      setHasArtFile(true);
      return;
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pmocArtFile]);

  async function handledownload() {
    const fileName = pmocArtFile.url.split("/pmoc/artfile/")[1];
    const url = `${process.env.REACT_APP_BASE_URL}/upload/pmoc/artfile/${
      fileName ? fileName : pmocArtFile.url
    }`;
    window.open(url, "_blank");
  }

  async function handleRemoveArt() {
    try {
      setLoadingModal(true);

      await api.delete(`/pmoc/${initialPmocProps.id}/artfile`);

      setHasArtFile(false);
      handleSetPmocArtFile({} as ARTFileProps);
      clearArt();

      addToast({
        type: "success",
        title: "Tudo certo!",
        description: "Arquivo excluído com sucesso.",
      });
    } catch (error) {
      addToast({
        title: "Erro",
        description:
          axios.isAxiosError(error) && error.response?.data.error
            ? error.response?.data.error
            : "Não foi possível excluir o arquivo",
        type: "error",
      });
    } finally {
      setLoadingModal(false);
    }
  }

  const UpdateARTPhoto = async (encode: string, extension: string) => {
    try {
      let splitEnconde = encode.split(",")[1];
      let artObj = {
        artFile: { encode: splitEnconde, extensao: extension },
      };
      const { data } = await api.put<ARTFileResponse>(
        `/pmoc/${initialPmocProps.id}/artfile`,
        artObj
      );
      handleSetPmocArtFile(data);

      return true;
    } catch (error) {
      addToast({
        title: "Erro",
        description:
          axios.isAxiosError(error) && error.response?.data.error
            ? error.response?.data.error
            : "Não foi possível enviar o arquivo",
        type: "error",
      });
      return false;
    }
  };

  function handleUploadArt(event: ChangeEvent<HTMLInputElement>) {
    try {
      setLoadingModal(true);
      if (event.target.files) {
        const file = event.target.files[0];
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const [_, extension] = file.type.split("/");

        const reader = new FileReader();

        reader.readAsDataURL(file);
        reader.onload = async () => {
          if (reader.result && extension) {
            let result = await UpdateARTPhoto(
              reader.result as string,
              extension
            );
            if (!result) {
              return setHasArtFile(false);
            }

            handleSetArtEncode(reader.result as string);
            handleSetArtExtension(extension);
            setHasArtFile(true);
          }
        };
        reader.onerror = (error) => {
          addToast({
            type: "error",
            title: "Erro ao adicionar o arquivo!",
            description: "Tente novamente",
          });

          return setHasArtFile(false);
        };
      }
    } catch (error) {
      addToast({
        type: "error",
        title: "Erro",
        description: "Erro ao submeter o arquivo.",
      });
    } finally {
      setLoadingModal(false);
    }
  }

  return (
    <>
      {loadingModal ? (
        <S.WrapperAttachmentButton>
          <S.WrapperLoading>
            <img src={loadingSvg} alt="imagem de loading" />
          </S.WrapperLoading>
        </S.WrapperAttachmentButton>
      ) : (
        <>
          {hasArtFile ? (
            <S.WrapperArt>
              <p>ART</p>

              <div className="inline">
                <div className="container">
                  <FiPaperclip size="18" color={theme.colors.primary} />
                  <p className="attach">1 anexo</p>
                </div>

                <button onClick={handledownload}>
                  <img src={DownloadSvg} alt="Download" />
                </button>

                <button onClick={handleRemoveArt}>
                  <img src={TrashIcon} alt="Excluir" height={16} />
                </button>
              </div>
            </S.WrapperArt>
          ) : (
            <S.WrapperAttachmentButton>
              <S.Wrapper>
                <label htmlFor="attachment">
                  <FiPaperclip size="18" color={theme.colors.primary} />
                  <span>Anexar ART</span>
                  <input
                    id="attachment"
                    type="file"
                    accept="image/*, .pdf"
                    onChange={handleUploadArt}
                  />
                </label>
              </S.Wrapper>
            </S.WrapperAttachmentButton>
          )}
        </>
      )}
    </>
  );
}
