import { useState, useEffect } from "react";

import { useAccount } from "./account";

export type ActionHash =
  | ""
  | "view"
  | "edit"
  | "delete"
  | "create"
  | "invite-send"
  | "member-view"
  | "profile-create"
  | "member-edit"
  | "member-delete"
  | "invite-send"
  | "profile-view"
  | "edit-unarchive"
  | "edit-archive"
  | "edit-finish"
  | "edit-send"
  | "create-budget-service-order"
  | "invite-delete"
  | "view-members-schedule";

type Props = {
  moduleHash: string;
  actionHash: ActionHash;
};

export const useHasPermission = ({ moduleHash, actionHash }: Props) => {
  const { whoami } = useAccount();
  const [permission, setPermission] = useState(false);

  async function hasPermission() {
    if (!moduleHash || !actionHash) {
      setPermission(false);
      return;
    }

    if (whoami?.isMe) {
      setPermission(true);
      return;
    }

    const currentModule = whoami?.profile.modules?.find(
      (module) => module.hash === moduleHash
    );

    if (currentModule) {
      const currentActionPermission = currentModule.permissions.find(
        (permission) => permission.hash === actionHash
      );

      setPermission(!!currentActionPermission?.active);
    }
  }

  useEffect(() => {
    hasPermission();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [whoami?.profile.modules]);

  return {
    permission,
  };
};
