import styled, { css } from "styled-components";

export const Container = styled.button`
  ${({ theme }) => css`
    width: 100%;

    background-color: ${theme.colors.background_light};
    border-radius: 10px;
    padding: 15px;

    h4 {
      font-size: ${theme.font.sizes.xsmall};
      color: ${theme.colors.title};
    }

    p {
      font-size: ${theme.font.sizes.xsmall};
      color: ${theme.colors.text};
      text-align: left;
      margin-top: 13px;
    }
  `}
`;

export const WrapperStatus = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    div {
      height: 7px;
      width: 7px;
      margin-right: 8px;
      border-radius: 5px;
      background-color: ${theme.colors.primary};
    }

    span {
      font-size: ${theme.font.sizes.xsmall};
      color: ${theme.colors.text};
    }
  `}
`;
