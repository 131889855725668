import React, { ButtonHTMLAttributes, useEffect, useState } from "react";
import { format, parseISO } from "date-fns";

import * as S from "./styles";

type AdressProps = {
  postalCode: string;
  street: string;
  district: string;
  number: string;
  city: string;
  complement?: string;
  uf?: string;
};

type ClientProps = {
  id: string | number;
  name: string;
  phone: string;
  address: AdressProps;
  email?: string;
  cpfCnpj?: string;
};

type ServiceProps = {
  id: number;
  scheduledDate: string;
  service: string;
};

export type OrderServiceProps = {
  id: number;
  sequenceNumber: number;
  status: string;
  client: ClientProps;
  services: ServiceProps[];
};

type CardProps = ButtonHTMLAttributes<HTMLButtonElement> & {
  data: OrderServiceProps;
};

export function OrderServiceCard({ data, ...rest }: CardProps) {
  const [nextScheduledFormatted, setNextScheduledFormatted] = useState("");
  const [listServicesFormatted, setListServicesFormatted] = useState("");

  useEffect(() => {
    let nextScheduled = data.services[0].scheduledDate;
    let listServices = "";

    data.services.forEach((item, index, array) => {
      if (item.scheduledDate.length <= 0) return;

      if (parseISO(item.scheduledDate) < parseISO(nextScheduled)) {
        nextScheduled = item.scheduledDate;
      }

      if (index >= 2) {
        listServices = `${listServices} ...`;
        return;
      }

      if (index !== array.length - 1) {
        listServices = `${listServices}, ${item.service}`;
        return;
      }

      listServices = item.service;
    });

    if (nextScheduled.length > 0) {
      setNextScheduledFormatted(
        format(parseISO(nextScheduled), "dd/MM/yyyy hh:mm")
      );
    }

    setListServicesFormatted(listServices);
  }, [data.services]);

  return (
    <S.Container {...rest}>
      <header>
        <h4>Ordem de serviço {data.sequenceNumber}</h4>

        <S.WrapperStatus>
          <div />
          <span>Em andamento</span>
        </S.WrapperStatus>
      </header>

      <div>
        <p>Agendado para: {nextScheduledFormatted}</p>

        <p>Cliente: {data.client.name}</p>
        <p>Serviço: {listServicesFormatted}</p>
      </div>
    </S.Container>
  );
}
