import styled, { css } from "styled-components";

export const Container = styled.section`
  width: 100%;
  max-width: 1400px;
  margin: 0 auto;

  padding: 30px;

  header {
    display: flex;
    align-items: center;

    & > h2 {
      ${({ theme }) => css`
        font-size: 2rem;
        color: ${theme.colors.title};
        margin-left: 3%;
      `}
    }
  }
`;

export const Content = styled.section`
  ${({ theme }) => css`
    margin-top: 40px;
    display: grid;
    grid-template-columns: repeat(6, 0.5fr);
    gap: ${theme.grid.small};
    margin-bottom: 100px;
  `}
`;

export const Manual = styled.button`
  ${({ theme }) => css`
    background-color: transparent;
    margin-bottom: 14px;

    div {
      width: 150px;

      background-color: ${theme.colors.detail};
      border-radius: 15px;

      img {
        width: 160px;
        height: 90px;
        border-radius: 14px;
        background-color: ${theme.colors.detail};
        object-fit: fill;
      }
    }

    p {
      color: ${theme.colors.title};
      margin-top: 10px;
      font-size: ${theme.font.sizes.small};
      font-weight: bold;
      text-align: left;
    }
  `}
`;
