import SendSvg from "assets/icons/send.svg";
import FolderSvg from "assets/icons/folder.svg";
import DownloadSvg from "assets/icons/download.svg";

import DownloadDisabledSvg from "assets/icons/download-disabled.svg";
import FolderDisabledSvg from "assets/icons/folder-disabled.svg";

export function switchSVG(typeSvg: string) {
  switch (typeSvg) {
    case "client":
      return SendSvg;
    case "model":
      return FolderSvg;
    case "modelDisabled":
      return FolderDisabledSvg;
    case "pdf":
      return DownloadSvg;
    case "pdfDisabled":
      return DownloadDisabledSvg;
    default:
      return SendSvg;
  }
}
