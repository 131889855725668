import { ButtonHTMLAttributes } from "react";

import * as S from "./styles";

type ListItemCardProps = {
  image: string;
  roundedImage?: boolean;
  title: string;
  boldTitle?: boolean;
  subtitle?: string;
  isSelected?: boolean;
} & ButtonHTMLAttributes<HTMLButtonElement>;

export function ListItemCard({
  image,
  roundedImage = false,
  title,
  boldTitle = false,
  subtitle = "",
  isSelected = false,
  ...rest
}: ListItemCardProps) {
  return (
    <S.Container isSelected={isSelected} {...rest}>
      <S.Content>
        {image.length > 0 ? (
          <S.Image
            roundedImage={roundedImage}
            src={image}
            alt="Imagem do item"
          />
        ) : (
          <S.Default />
        )}
        <S.Title boldTitle={boldTitle}>{title}</S.Title>
      </S.Content>

      <S.Subtitle>{subtitle}</S.Subtitle>
    </S.Container>
  );
}
