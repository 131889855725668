import { lighten } from "polished";
import styled, { css } from "styled-components";

interface CardUnityProps {
  isSelected?: boolean;
}

export const Top = styled.div`
  ${({ theme }) => css`
    margin-bottom: 30px;

    display: flex;
    align-items: center;

    p {
      font-size: ${theme.font.sizes.large};
      color: ${theme.colors.title};
      font-weight: ${theme.font.bold};
    }
  `}
`;

export const ButtonClose = styled.button`
  ${({ theme }) => css`
    width: 35px;
    height: 35px;
    background-color: ${theme.colors.background_light};
    border-radius: 10px;

    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 25px;
  `}
`;

export const Content = styled.main`
`;

export const WrapperList = styled.div`
  margin-top: 30px;
`;

export const EditClientButton = styled.button`
  ${({ theme }) => css`
    width: 10% !important;
    align-items: center;
    justify-content: center;
    color: ${theme.colors.text_extra_light};
    :hover {
      background-color: ${lighten(0.2, theme.colors.background_light)};
    }
  `}
`;

export const Profile = styled.div`
  ${({ theme }) => css`
    height: 35px;
    width: 35px;
    border-radius: 50%;
    margin-right: 12px;

    display: flex;
    justify-content: center;
    align-items: center;
    background-color: ${theme.colors.background_light};

    span {
      text-transform: uppercase;
      letter-spacing: 0.4px;
      font-size: ${theme.font.sizes.xsmall};
      color: ${theme.colors.text_extra_light};
    }
  `}
`;

export const CardUnity = styled.div<CardUnityProps>`
  ${({ theme, isSelected }) => css`
    background-color: ${theme.colors.background_light};
    min-height: 110px;
    width: 100%;
    padding: 15px;
    border-radius: 10px;
    margin-bottom: 10px;
    cursor: pointer;
    transition: all 0.6s ease;

    display: flex;
    align-items: center;

    &:hover {
      opacity: 0.7;
    }

    border: 1px solid ${isSelected 
      ? theme.colors.primary_light
      : theme.colors.background_light 
    };

    div.content {
      width: 100%;
      margin-left: 10px;
    }

    p.unity-name {
      font-family: ${theme.font.family};
      font-weight: ${theme.font.bold};
      font-size: 12px;
      color: ${theme.colors.title};
      margin-bottom: 10px;

      span {
        font-weight: ${theme.font.light};
        margin-left: 5px;
      }
    }

    .register-date {
      display: flex;
      align-items: center;
      margin-bottom: 10px;

      p {
        font-family: ${theme.font.family};
        font-weight: ${theme.font.light};
        font-size: 12px;
        color: ${theme.colors.text};
        margin-left: 5px;
      }
    }

    .address {
      font-family: ${theme.font.family};
      font-size: 12px;
      color: ${theme.colors.text};
    }
  `}
`;

export const Circle = styled.div`
  ${({ theme }) => css`
    height: 6px;
    width: 6px;
    border-radius: 50%;
    background-color: ${theme.colors.primary_light};
  `}
`;

export const Checkbox = styled.div`
  ${({ theme }) => css`
    background-color: transparent;
    border: 1px solid ${theme.colors.title};
    height: 14px;
    width: 14px;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
  `}
`;

export const Marker = styled.div`
  ${({ theme }) => css`
    background-color: ${theme.colors.primary_light};
    height: 6px;
    width: 6px;
    border-radius: 1.5px;
  `}
`;

export const WrapperButton = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
`;

export const Label = styled.p`
  ${({ theme }) => css`
    color: ${theme.colors.title};
    text-align: left;
    font-size: 12px;
    margin-bottom: 10px;
  `}
`;

export const WrapperEnvironment = styled.div`
  ${({ theme }) => css`
    background-color: ${theme.colors.background_light};
    padding: 15px;
    border-radius: 10px;
    display: flex;
    align-items: center;
    margin-bottom: 6px;

    p {
      font-family: ${theme.font.family};
      font-weight: ${theme.font.bold};
      color: ${theme.colors.text_extra_light};
      text-align: left;
      font-size: 12px;
    }

    span {
      color: ${theme.colors.title};
      text-align: left;
      font-size: 12px;
    }
  `}
`;

export const ErrorText = styled.p`
  ${({ theme }) => css`
    font-size: 12px;
    font-family: ${theme.font.family};
    color: ${theme.colors.error};
    margin-top: 5px;
  `}
`;