import styled, { css } from "styled-components";
import { opacify } from "polished";


type CheckboxProps = {
  isChecked: boolean;
};

export const Container = styled.div`
  ${({ theme }) => css`
    margin-bottom: 30px;
    width: 100%;
    max-width: 1400px;
    padding: 0 70px;

    header {
      display: flex;
      flex-direction:row;
      margin: 30px 0;
      width:50%;
      justify-content:space-between;
    }

    div.content {
      width: 60%;
    }

    div.list {
      margin-top: 30px;
      margin-bottom: 50px;
    }
  `}
`;

export const Title = styled.h1`
  ${({ theme }) => css`
    font-size: ${theme.font.sizes.large};
    color: ${theme.colors.title};
    font-weight: ${theme.font.bold};
  `}
`;

export const Wrapper = styled.div`
  margin-bottom: 20px;
`;


export const TitleCard = styled.div`
    ${({ theme }) => css`
      background: ${theme.colors.background_modal};
      border-radius: 8px;
      padding:15px;
      margin-bottom:5px;

      display:flex;
      flex-direction:row;
    .titleInfo {
      display:flex;
      flex-direction:column;
      margin-left: 8px;
      .title {
        font-size: ${theme.font.sizes.small};
        color: ${theme.colors.text_extra_light };
        font-weight: ${theme.font.bold};
        margin-right: 5px;
      }
      
      .pmoc-infos {
        display:flex;
        flex-direction:row;
        margin-top:5px;
        .start-date{
          font-size: ${theme.font.sizes.small};
          color: ${theme.colors.financial_text};
          font-weight: ${theme.font.family};
        }
        .period{
          margin-left:5px;
          font-size: ${theme.font.sizes.small};
          color: ${theme.colors.financial_text};
          font-weight: ${theme.font.family};
        }
      }
    }
  `}
`;

export const FilterElement = styled.div`
  ${({ theme }) => css`
      padding:20px 0px;    
      display:flex;
      flex-direction:row;
      .name-filter {
        margin-left:5px;
        font-size: 12px;
        color: rgba(255, 255, 255, 0.6);
        font-weight: ${theme.font.normal};
        margin-right: 5px;
      }
  `}
`;


export const TabContainer = styled.div`
  ${({ theme }) => css`
    margin-top: 20px;
    width: 100%;
    min-width: 600px;
    

    .react-tabs__tab-list {
      border-bottom: 1px solid rgba(255, 255, 255, 0.12);
      display: flex;
      justify-content: space-around;
        
    }

    .react-tabs__tab {
      color: ${theme.colors.text};
      width: 50%;
      display: flex;
      justify-content:center;
      align-items: center;
    }

    .react-tabs__tab--selected {
      background: inherit;
      border: 0px;
      color: ${theme.colors.text};

      font-weight: 500;
      line-height: 1.35;
      white-space: normal;
      letter-spacing: 0.02857em;
      border-bottom: 3px solid ${theme.colors.primary_light};
    }
  `}
`;


export const WrapperCheck = styled.button`
  ${({ theme }) => css`
    display: flex;
    padding-left: 10px;
    height: 110px;

    border-top-left-radius:10px;
    border-bottom-left-radius:10px;

    width: 60px;
    justify-content: center;
    align-items: center;
    transition: background 0.2s;
    &:hover {
      background: ${opacify(0.03, theme.colors.background_light)};
    }
    &:disabled {
      cursor: not-allowed;
      background: transparent;
    }
  `};
`;

export const MarkChecked = styled.div<CheckboxProps>`
  ${({ theme, isChecked }) => css`
    width: 10px;
    height: 10px;
    border-radius: 1.2px;
    cursor: pointer;

    background-color: ${isChecked
      ? theme.colors.primary
      : theme.colors.background_light};
  `};
`;


export const CheckBox = styled.div`
  ${({ theme }) => css`
    padding: 3px;
    margin-right: 15px;

    display: flex;
    align-items: center;
    justify-content: center;

    border-radius: 3px;
    border: 1px solid ${theme.colors.text};
  `}
`;


export const CloseButton = styled.button`
  ${({ theme }) => css`
    width: 40px;
    height: 40px;
    border-radius: 10px;

    display: flex;
    justify-content: center;
    align-items: center;

    background-color: ${theme.colors.background_modal};

    & > img {
      width: 15px;
      height: 15px;
    }
  `}
`;
