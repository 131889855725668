import React, { createContext, useContext, useEffect, useState } from "react";
import { isEqual } from "lodash";

import { PMOCServiceProps, TimeProps } from "dtos/ServiceDTO";
import { useServiceDropDown } from "./servicesDropDown";
import { DropdownOption } from "components/DropDown";
import { ProviderProps } from "hooks";

type HandleModalServicesData = {
  setCurrentEditPmocService: React.Dispatch<
    React.SetStateAction<PMOCServiceProps>
  >;
  currentPmocEditService?: PMOCServiceProps;
  setIsOpenFormPmoc: (value: boolean) => void;
  isOpenFormPmoc: boolean;
  handlePmocServiceClean: () => void;
  setFormData: React.Dispatch<React.SetStateAction<DataProps>>;
  formData: DataProps;
  setOpenModalConfirm: React.Dispatch<React.SetStateAction<boolean>>;
  openModalConfirm: boolean;
  handleCloseForm: () => void;
};

export interface DataProps {
  categoryId?: string;
  serviceTypeId?: string;
  price?: number | string;
  frequency?: string;
  runtime?: TimeProps;
  serviceSteps?: string;
  warranty?: TimeProps;
  serviceType?: {
    id: number;
    name: string;
  };
  serviceCategory: {
    id: number;
    name: string;
  };
}

const HandleModalServices = createContext<HandleModalServicesData>(
  {} as HandleModalServicesData
);

export function useHandleModalService(): HandleModalServicesData {
  const context = useContext(HandleModalServices);

  if (!context) {
    throw Error(
      "useHandleModalService must be used within a HandleModalProvider"
    );
  }

  return context;
}

export const HandleModalProvider = ({ children }: ProviderProps) => {
  const [currentPmocEditService, setCurrentEditPmocService] = useState(
    {} as PMOCServiceProps
  );
  const [isOpenFormPmoc, setIsOpenFormPmoc] = useState(false);

  const [formData, setFormData] = useState<DataProps>({} as DataProps);

  const {
    selectedService,
    handleCategoryId,
    handleService,
    selectedCategoryId,
  } = useServiceDropDown();

  const [openModalConfirm, setOpenModalConfirm] = useState(false);

  const handlePmocServiceClean = () => {
    setCurrentEditPmocService({} as PMOCServiceProps);
    setFormData({} as DataProps);
    handleService({} as DropdownOption);
    handleCategoryId(0);

    setIsOpenFormPmoc(false);
    setOpenModalConfirm(false);
  };

  useEffect(() => {
    if (currentPmocEditService?.id) {
      setFormData(currentPmocEditService);
      handleCategoryId(currentPmocEditService?.serviceCategory?.id);
      handleService(currentPmocEditService?.serviceType);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    currentPmocEditService,
    currentPmocEditService?.id,
    currentPmocEditService?.serviceType,
  ]);

  const handleCloseForm = () => {
    const objCompareCreateForm = {
      frequency: undefined,
      id: undefined,
      price: undefined,
      runTime: undefined,
      runtTimeExtension: undefined,
      warrantyTime: undefined,
      warrantyExtension: undefined,
      serviceCategoryId: "0",
      serviceCategoryName: undefined,
      serviceSteps: undefined,
      selectedServiceId: undefined,
    };

    const objCompareDefault = {
      frequency: currentPmocEditService?.frequency,
      id: currentPmocEditService?.id,
      price: currentPmocEditService?.price,
      runTime: currentPmocEditService.runtime?.time,
      runtTimeExtension: currentPmocEditService.runtime?.extension,
      warrantyTime: currentPmocEditService.warranty?.time,
      warrantyExtension: currentPmocEditService.warranty?.extension,
      serviceCategoryId:
        currentPmocEditService?.serviceCategory?.id?.toString(),
      serviceCategoryName: currentPmocEditService?.serviceCategory?.name,
      serviceSteps: currentPmocEditService?.serviceSteps,
      selectedServiceId: currentPmocEditService?.serviceType?.id,
    };

    const objCompareForm = {
      frequency: formData.frequency,
      id: currentPmocEditService.id,
      price: formData.price,
      runTime: formData.runtime?.time,
      runtTimeExtension: formData.runtime?.extension,
      warrantyTime: formData.warranty?.time,
      warrantyExtension: formData?.warranty?.extension,
      serviceCategoryId: selectedCategoryId.toString(),
      serviceCategoryName: currentPmocEditService?.serviceCategory?.name,
      serviceSteps: formData?.serviceSteps,
      selectedServiceId: selectedService?.id?.toString(),
    };

    const notHasAlterations = isEqual(
      currentPmocEditService?.id ? objCompareDefault : objCompareCreateForm,
      objCompareForm
    );

    if (notHasAlterations) {
      handlePmocServiceClean();
    } else {
      setOpenModalConfirm(true);
    }
  };

  return (
    <HandleModalServices.Provider
      value={{
        setOpenModalConfirm,
        handleCloseForm,
        openModalConfirm,
        formData,
        setFormData,
        handlePmocServiceClean,
        currentPmocEditService,
        setCurrentEditPmocService,
        isOpenFormPmoc,
        setIsOpenFormPmoc,
      }}
    >
      {children}
    </HandleModalServices.Provider>
  );
};
