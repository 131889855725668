import { createContext, useContext, useState } from "react";
import { AddressProps, WarningClientProps } from "dtos/ClientDTO";
import { ProviderProps } from "hooks";

type WarningMessageContextData = {
  modifyClient: WarningClientProps;
  modifyUnity: AddressProps;
  budgetMessage: boolean;
  modifyBudget: boolean;
  linkPage: string;
  handleSetLinkPage: (value: string) => void;
  handleClearLinkPage: () => void;
  handleSetModifyBudget: (value: boolean) => void;
  handleSetBudgetMessage: (value: boolean) => void;
  handleSetModifyClient: (modifyClient: WarningClientProps) => void;
  handleSetModifyUnity: (modifyUnity: AddressProps) => void;
  handleCleanModifyUnity: () => void;
  handleClearWarningMessage: () => void;
};

const WarningMessageContext = createContext<WarningMessageContextData>(
  {} as WarningMessageContextData
);

export function useWarningMessage(): WarningMessageContextData {
  const context = useContext(WarningMessageContext);

  if (!context) {
    throw Error("useContact must be used within a WarningMessageProvider");
  }
  return context;
}

export function WarningMessageProvider({ children }: ProviderProps) {
  const [modifyClient, setModifyClient] = useState({} as WarningClientProps);
  const [modifyUnity, setModifyUnity] = useState({} as AddressProps);
  const [budgetMessage, setBudgetMessage] = useState(false);
  const [modifyBudget, setModifyBudget] = useState(false);
  const [linkPage, setLinkPage] = useState("");

  function handleSetLinkPage(value: string) {
    setLinkPage(value);
  }
  function handleClearLinkPage() {
    setLinkPage("");
  }

  function handleSetModifyBudget(value: boolean) {
    setModifyBudget(value);
  }

  function handleSetBudgetMessage(value: boolean) {
    setBudgetMessage(value);
  }

  function handleSetModifyClient(client: WarningClientProps) {
    setModifyClient(client);
  }

  function handleSetModifyUnity(unity: AddressProps) {
    setModifyUnity(unity);
  }

  function handleCleanModifyUnity() {
    setModifyUnity({} as AddressProps);
  }

  function handleClearWarningMessage() {
    setModifyClient({} as WarningClientProps);
    setModifyUnity({} as AddressProps);
  }

  return (
    <WarningMessageContext.Provider
      value={{
        modifyClient,
        modifyUnity,
        budgetMessage,
        modifyBudget,
        linkPage,
        handleClearLinkPage,
        handleSetLinkPage,
        handleSetModifyBudget,
        handleSetBudgetMessage,
        handleSetModifyClient,
        handleSetModifyUnity,
        handleCleanModifyUnity,
        handleClearWarningMessage,
      }}
    >
      {children}
    </WarningMessageContext.Provider>
  );
}
