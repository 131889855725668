import { darken, lighten } from "polished";
import styled, { css } from "styled-components";
import media from "styled-media-query";

export const Container = styled.aside`
  ${({ theme }) => css`
    .DayPicker {
      background: transparent;
      border-radius: ${theme.font.sizes.xsmall} !important;
    }

    .DayPicker-wrapper {
      padding: 16px 0;
      width: 100%;
    }

    .DayPicker,
    .DayPicker-Month {
      width: 86%;
    }

    .DayPicker-Month {
      font-size: 14px;
      border-spacing: 20px;
      border-collapse: separate;

      ${media.lessThan("small")`
        border-spacing: 0;
      `}
    }

    .DayPicker-Weekday {
      color: ${theme.colors.text};
      font-size: 13px;
    }

    .DayPicker-Day {
      height: 40px;
      width: 40px;
      color: ${theme.colors.title};
      cursor: pointer;

      &:hover {
        background: ${lighten(0.6, theme.colors.background_light)} !important;
      }
    }

    .DayPicker-Day:not(.DayPicker-Day--disabled) {
      color: ${lighten(0.1, theme.colors.title)};
    }

    .DayPicker-Day--disabled {
      opacity: 0.5;
    }

    .DayPicker-Day--selected {
      height: 40px;
      border: 1px solid ${theme.colors.primary};
      background: transparent !important;
    }

    .DayPicker-Day--today {
      color: ${theme.colors.primary} !important;
    }
  `}
`;

export const DayContainer = styled.div`
  ${({ theme }) => css`
    height: 30px;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    & > p {
      font-size: ${theme.font.sizes.small};
      color: ${theme.colors.title};
      font-weight: ${theme.font.bold};
      margin-bottom: 20px;
    }

    & > div {
      height: 5.5px;
      width: 5.5px;
      border-radius: 50%;
      margin-top: 8px;
      background: ${darken(0.06, theme.colors.primary)};
    }
  `}
`;

export const Navbar = styled.div`
  ${({ theme }) => css`
    width: 80%;
    padding-top: 10px;
    display: flex;
    margin: 0 auto;
    justify-content: space-between;
    align-items: center;

    & > button {
      padding: 7px 8px;
      border-radius: 2px;

      display: flex;
      align-items: center;
      justify-content: center;
      transition: background 0.2s;

      &:hover {
        background: ${lighten(0.2, theme.colors.background_light)};
      }
    }

    & > span {
      font-size: ${theme.font.sizes.small};
      color: ${theme.colors.title};
    }
  `}
`;
