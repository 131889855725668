import styled, { css } from "styled-components";

type TitleProps = {
  titleColor?: "text" | "primary";
};

export const Container = styled.div<TitleProps>`
  ${({ theme, titleColor }) => css`
    & > p {
      margin-bottom: 5px;
      color: ${titleColor === "text"
        ? theme.colors.text
        : theme.colors.primary};
      font-size: ${theme.font.sizes.small};
    }
  `}
`;

export const Content = styled.div`
  ${({ theme }) => css`
    background-color: ${theme.colors.background_modal};
    padding: 18px 15px;
    border-radius: 8px;

    p:not(:first-of-type) {
      margin-top: 4px;
    }

    span {
      color: ${theme.colors.text};
      font-size: ${theme.font.sizes.xsmall};
    }
  `}
`;
