import { ReactElement, useEffect, useState } from "react";
import { Tabs, TabPanel, TabList, Tab } from "react-tabs";

import { ModalRight } from "components/ModalRight";
import { ArrowButton } from "components/ArrowButton";
import { LoadingProfiz } from "components/LoadingProfiz";
import { FormPmocServices } from "./FormPmocServices";
import { FormEditService } from "components/ModalServices/FormEditService";
import { FormCreateService } from "components/ModalServices/FormCreateService";
import { CommonServiceList } from "./CommonServiceList";
import { DiscardInformationModal } from "components/DiscardInformationModal";
import { ServiceChecklist } from "../ServiceChecklist";
import { ChecklistPage } from "../ServiceChecklist/ChecklistPage";
import { RegisterPage } from "../ServiceChecklist/RegisterPage";
import { DetailRegisterPage } from "../ServiceChecklist/DetailRegisterPage";

import {
  PMOCServiceProps,
  ServiceForListProps,
  ServiceOptionsInputProps,
} from "dtos/ServiceDTO";

import addMaskMoney from "utils/addMaskMoney";
import { useToast } from "hooks/toast";
import { useServiceChecklist } from "hooks/serviceChecklist";
import { useServiceDropDown } from "hooks/servicesDropDown";
import { useHandleModalService } from "hooks/handleModalServices";
import { useSelectedServiceOrder } from "hooks/selectedServiceOrder";

import { api } from "services/api";

import { PmocService } from "./PmocServicelist";

import * as S from "./styles";
import { useAccount } from "hooks/permission/account";

type TOption = {
  [key: string]: ReactElement;
};

export function MyServicesList() {
  const { addToast } = useToast();
  const {
    isOpenFormPmoc,
    setCurrentEditPmocService,
    setIsOpenFormPmoc,
    currentPmocEditService,
    handleCloseForm,
  } = useHandleModalService();

  const {
    handleSetIsNotMenusAccess,
    selectedItemChecklist,
    isChecklistPage,
    detailChecklist,
    isEditPage,
    handleSetEditPage,
    handleSetSelectedItemChecklist,
  } = useServiceChecklist();

  const { reset, isEqualService, handleSetEqualService } = useServiceDropDown();

  const { whoami } = useAccount();

  const [currentEditService, setCurrentEditService] = useState(
    {} as ServiceForListProps
  );

  const { handleSetOptionsInputService } = useSelectedServiceOrder();

  const [loading, setLoading] = useState(true);
  const [isOpenCreateService, setIsOpenCreateServices] = useState(false);
  const [isOpenFormEditService, setIsOpenFormEditService] = useState(false);

  const [currentPageModal, setCurrentPageModal] = useState("");

  const [servicesPmoc, setServicesPmoc] = useState<PMOCServiceProps[]>([]);

  const [selectPage, setSelectedPage] = useState<number>(0);
  const [servicesCommon, setServicesCommon] = useState<ServiceForListProps[]>(
    []
  );

  const [showCompareAlert, setShowCompareAlert] = useState(false);

  const setPageComponent = (keyPage: string) => {
    const pagesArr: TOption = {
      serviceChecklist: (
        <ServiceChecklist
          handleAssociationFunction={handleSaveChecklistService}
          handleEditItemChecklist={() => setCurrentPageModal("checklistPage")}
          handlePageClose={() => {
            SetPageBefore();
          }}
        />
      ),
      formEditService: (
        <FormEditService
          data={currentEditService}
          handleGoBack={handleToggleEditServiceModal}
          handleAddChecklist={setChecklistInEditPage}
          handleEditPage={setEditPage}
        />
      ),

      formCreateService: (
        <FormCreateService
          data={currentEditService}
          handleGoBack={handleToggleCreateServiceModal}
          handleAddChecklist={setChecklistInCreatePage}
          loadServicesCommon={loadServicesCommon}
          handleEditPage={setEditPage}
        />
      ),

      formPmocService: (
        <FormPmocServices
          loadServicesPmoc={loadServicesPmoc}
          handleCloseModalPmoc={handleCloseModalPmocs}
          currentPmocEditService={currentPmocEditService as PMOCServiceProps}
          handleAddChecklist={setChecklistInEditPage}
          handleEditPage={setEditPage}
        />
      ),

      checklistPage: (
        <ChecklistPage
          handleDetailRegister={setPageRegisterDetail}
          handleFunction={setPageRegister}
          handleFunctionClose={() => {
            setPageBeforeChecklistpage();
          }}
          handleSaveInfo={() => {
            handleSaveChecklist();
          }}
        />
      ),
      registerPage: (
        <RegisterPage
          handleFunction={setPageRegisterDetail}
          handlePageClose={() => {
            setCurrentPageModal("checklistPage");
          }}
        />
      ),
      detailRegisterPage: (
        <DetailRegisterPage
          handlePageClose={() => {
            setCurrentPageModal(
              isChecklistPage ? "checklistPage" : "registerPage"
            );
          }}
        />
      ),
    };
    return pagesArr[keyPage];
  };

  function resetDataNewService() {
    reset();
    handleSetOptionsInputService({} as ServiceOptionsInputProps);
  }

  function setEditPage() {
    setCurrentPageModal("checklistPage");
  }

  function setPageRegisterDetail() {
    setCurrentPageModal("detailRegisterPage");
  }

  function setPageRegister() {
    setCurrentPageModal("registerPage");
  }

  function SetPageBefore() {
    if (isOpenFormPmoc) {
      setCurrentPageModal("formPmocService");
      return;
    }

    isOpenFormEditService
      ? setCurrentPageModal("formEditService")
      : setCurrentPageModal("formCreateService");
  }

  function setPageBeforeChecklistpage() {
    if (isOpenFormPmoc) {
      setCurrentPageModal("formPmocService");
      return;
    }

    if (!isEditPage) return setCurrentPageModal("serviceChecklist");
    isOpenFormEditService
      ? setCurrentPageModal("formEditService")
      : setCurrentPageModal("formCreateService");
  }

  function setChecklistInEditPage() {
    handleSetIsNotMenusAccess(true);
    setCurrentPageModal("serviceChecklist");
  }

  function setChecklistInCreatePage() {
    handleSetIsNotMenusAccess(true);
    setCurrentPageModal("serviceChecklist");
  }

  function handleSaveChecklist() {
    let aux = currentEditService;
    if (aux && aux.serviceChecklist) {
      aux.serviceChecklist = aux.serviceChecklist.map((element) => {
        if (element.id === detailChecklist.id) {
          element.name = detailChecklist.name;
          if (detailChecklist.default)
            element.default = detailChecklist.default;
          element.registers = detailChecklist.registers;
          return element;
        }
        return element;
      });
    }
    setCurrentEditService(aux);
    setPageBeforeChecklistpage();
  }

  function handleSaveChecklistService() {
    let aux = currentEditService;

    aux.serviceChecklist = selectedItemChecklist;
    setCurrentEditService(aux);

    if (isOpenFormPmoc) {
      setCurrentPageModal("formPmocService");
      return;
    }

    isOpenFormEditService
      ? setCurrentPageModal("formEditService")
      : setCurrentPageModal("formCreateService");
  }

  function handleToggleCreateServiceModal() {
    handleSetIsNotMenusAccess(false);
    handleSetEditPage(false);

    setIsOpenCreateServices(!isOpenCreateService);

    if (isOpenCreateService) {
      loadServicesCommon();
    }
  }

  function handleToggleEditServiceModal() {
    setIsOpenFormEditService(!isOpenFormEditService);

    handleSetIsNotMenusAccess(false);
    handleSetEditPage(false);

    if (isOpenFormEditService) {
      loadServicesCommon();
    }
  }

  function handleOpenFormEditService(currentEditService: ServiceForListProps) {
    setCurrentEditService(currentEditService);
    handleToggleEditServiceModal();
  }

  const handleToogleModal = () => {
    setCurrentEditService({} as ServiceForListProps);

    resetDataNewService();
    setCurrentPageModal("formCreateService");
    handleToggleCreateServiceModal();
  };

  const handleToogleEditModal = (currentService: ServiceForListProps) => {
    setCurrentPageModal("formEditService");
    handleOpenFormEditService(currentService);
  };

  const handleOpenModalPmoc = (currentService: PMOCServiceProps) => {
    setCurrentPageModal("formPmocService");
    setCurrentEditPmocService(currentService);

    if (currentService.serviceChecklist) {
      handleSetSelectedItemChecklist(currentService.serviceChecklist);
    }
    setIsOpenFormPmoc(true);
  };

  const handleCloseModalPmocs = () => {
    handleCloseForm();
  };

  async function loadServicesCommon() {
    try {
      const { data } = await api.get("/services", {
        params: {
          accountId: whoami?.id,
        },
      });

      const formattedServices = data.services.map(
        (currentService: ServiceForListProps) => ({
          ...currentService,
          formattedTotal: addMaskMoney(currentService.price),
        })
      ) as ServiceForListProps[];

      formattedServices.sort((idA, idB) => {
        if (idA.id > idB.id) {
          return -1;
        }
        if (idA.id < idB.id) {
          return 1;
        }
        return 0;
      });

      setServicesCommon(formattedServices);
    } catch (error) {
      addToast({
        title: "",
        description: "",
        type: "error",
      });
    } finally {
      setLoading(false);
    }
  }

  async function loadServicesPmoc() {
    try {
      const { data } = await api.get("/pmoc/service");
      const ordenedServices = data.userServices.sort(
        (idA: PMOCServiceProps, idB: PMOCServiceProps) => {
          if (idA.id > idB.id) {
            return -1;
          }
          if (idA.id < idB.id) {
            return 1;
          }
          return 0;
        }
      );

      setServicesPmoc(ordenedServices);
    } catch (error) {
      addToast({
        title: "",
        description: "",
        type: "error",
      });
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    loadServicesCommon();
    loadServicesPmoc();
  }, []); // eslint-disable-line

  return (
    <S.Container>
      <DiscardInformationModal
        showModal={showCompareAlert}
        handleConfirmButton={() => {
          setShowCompareAlert(false);
          handleSetEqualService(true);
          if (currentPageModal === "formEditService") {
            handleToggleEditServiceModal();
            return;
          }
          handleToggleCreateServiceModal();
        }}
        handleCancelButton={() => {
          setShowCompareAlert(false);
        }}
      />

      <header>
        <div>
          <ArrowButton />
          <h1>Meus Serviços</h1>
        </div>
      </header>

      <ModalRight
        isOpen={isOpenCreateService}
        handleToggleOpen={() => {
          if (!isEqualService) {
            setShowCompareAlert(true);
            return;
          }
          handleToggleCreateServiceModal();
        }}
      >
        {setPageComponent(currentPageModal)}
      </ModalRight>

      <ModalRight
        isOpen={isOpenFormEditService}
        handleToggleOpen={() => {
          if (!isEqualService) {
            setShowCompareAlert(true);
            return;
          }
          handleToggleEditServiceModal();
        }}
      >
        {setPageComponent(currentPageModal)}
      </ModalRight>

      <ModalRight
        isOpen={isOpenFormPmoc}
        handleToggleOpen={() => {
          handleSetIsNotMenusAccess(false);
          handleCloseModalPmocs();
          handleSetSelectedItemChecklist([]);
        }}
      >
        {setPageComponent(currentPageModal)}
      </ModalRight>

      <S.Content>
        {loading ? (
          <LoadingProfiz isVisible={loading} />
        ) : (
          <>
            <S.TabContainer>
              <Tabs
                selectedIndex={selectPage}
                onSelect={(index: number) => {
                  setSelectedPage(index);
                }}
              >
                <TabList>
                  <Tab>Serviço comum</Tab>
                  <Tab>Serviço de PMOC</Tab>
                </TabList>

                <TabPanel>
                  <CommonServiceList
                    setServicesCommon={setServicesCommon}
                    servicesCommon={servicesCommon}
                    loadServicesCommon={loadServicesCommon}
                    createServiceModal={handleToogleModal}
                    editServiceModal={handleToogleEditModal}
                  />
                </TabPanel>

                <TabPanel>
                  <PmocService
                    pmocs={servicesPmoc}
                    loadServicesPmoc={loadServicesPmoc}
                    handleToogleFormPmoc={setIsOpenFormPmoc}
                    createServiceModal={() => {
                      setIsOpenFormPmoc(true);
                      setCurrentPageModal("formPmocService");
                    }}
                    editServiceModal={handleOpenModalPmoc}
                  />
                </TabPanel>
              </Tabs>
            </S.TabContainer>
          </>
        )}
      </S.Content>
    </S.Container>
  );
}
