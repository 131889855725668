import styled, { css } from 'styled-components';

export const Container = styled.div`
  ${({ theme }) => css`
    width: 100%;
    max-width: 1400px;
    margin: 0 auto;
    padding: 30px;

    header {
      display: flex;
      align-items: center;

      div {
        width: 80px;
        display: flex;
        justify-content: space-between;
      }
    }
  `}
`;

export const WrapperCenter = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 60vh;

    h1 {
      font-size: ${theme.font.sizes.xlarge};
      color: ${theme.colors.title};
      font-weight: ${theme.font.bold};
      margin-left: 25px;
      margin-bottom: -20px;
    }
  `}
`;