import { useTheme } from "styled-components";
import { FiX } from "react-icons/fi";

import * as S from "./styles";

import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import { ListProducts } from "../ListProducts";
type ListProductsProps = {
  handleToggleOpen: () => void;
  handleCreateProduct: () => void;
  handleEditProduct: (id: number) => void;
};

export function Products({
  handleToggleOpen,
  handleCreateProduct,
  handleEditProduct,
}: ListProductsProps) {
  const theme = useTheme();

  return (
    <>
      <S.Top>
        <S.ButtonClose onClick={() => handleToggleOpen()}>
          <FiX size={10} color={theme.colors.text} />
        </S.ButtonClose>

        <p>Produtos</p>
      </S.Top>

      <S.TabContainer>
        <Tabs selectedIndex={0} onSelect={() => {}}>
          <TabList>
            <Tab>Meus produtos</Tab>
          </TabList>

          <TabPanel>
            <ListProducts
              handleToggleOpen={handleToggleOpen}
              handleCreateProduct={handleCreateProduct}
              handleEditProduct={handleEditProduct}
            />
          </TabPanel>
        </Tabs>
      </S.TabContainer>
    </>
  );
}
