import React, { useState } from "react";

import { Button } from "components/Button";
import { ModalRight } from "components/ModalRight";
import { ButtonModalAction } from "components/ButtonModalAction";
import { ModalLocalChecklist } from "components/ModalLocalChecklist";
import { CardSelectedLocalChecklist } from "components/CardSelectedLocalChecklist";

import { useSelectedChecklist } from "hooks/budget/selectedLocalChecklist";

import * as S from "./styles";

type Props = {
  disabled?: boolean;
};

export function LocalChecklistArea({ disabled }: Props) {
  const { selectedChecklistItems } = useSelectedChecklist();

  const [isOpen, setIsOpen] = useState(false);

  function handleToggleOpen() {
    if (disabled) {
      return;
    }
    setIsOpen(!isOpen);
  }

  return (
    <S.Container>
      <ModalRight
        isOpen={isOpen}
        // eslint-disable-next-line react/jsx-no-bind
        handleToggleOpen={handleToggleOpen}
      >
        <ModalLocalChecklist
          // eslint-disable-next-line react/jsx-no-bind
          handleToggleOpen={handleToggleOpen}
        />
      </ModalRight>

      {selectedChecklistItems.length > 0 ? (
        <>
          <S.Label>Itens adicionais (opcional)</S.Label>

          <CardSelectedLocalChecklist
            disabled={disabled}
            checklistItems={selectedChecklistItems}
            // eslint-disable-next-line react/jsx-no-bind
          />

          <Button
            disabled={disabled}
            typeButton={disabled ? "disabled" : "outline"}
            onClick={() => handleToggleOpen()}
          >
            Adicionar outro item
          </Button>
        </>
      ) : (
        <ButtonModalAction
          label="Itens adicionais (opcional)"
          placeholder="Selecione os itens adicionais"
          isSelected={isOpen}
          // eslint-disable-next-line react/jsx-no-bind
          onClick={handleToggleOpen}
        />
      )}
    </S.Container>
  );
}
