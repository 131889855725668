import React from "react";

import logoProfiz from "assets/icons/logo-simple.svg";

import * as S from "./styles";
import { Button } from "components/Button";
import { useHistory } from "react-router";

type ModalProps = {
  isVisible: boolean;
  setIsActive?: (value: boolean) => void;
};

function ModalCompletedProfile({ isVisible, setIsActive }: ModalProps) {
  const history = useHistory();

  return (
    <>
      {isVisible && (
        <S.ContainerOverlay>
          <S.BoxAlert>
            <img
              src={logoProfiz}
              alt="Logo Profiz"
              style={{ height: 30, width: 30 }}
            />

            <h1>Perfil atualizado e em análise!</h1>
            <span>
              Que tal adicionar certificados e aumentar suas chances de
              negócios?
            </span>

            <Button
              onClick={() => {
                history.push({
                  pathname: "/certifications/create",
                  state: { before: "finishRegister" },
                });
                setIsActive && setIsActive(false);
              }}
            >
              Adicionar certificações
            </Button>
            <S.WrapperButton
              onClick={() => {
                history.push("/");
                setIsActive && setIsActive(false);
              }}
            >
              <button>Mais tarde</button>
            </S.WrapperButton>
          </S.BoxAlert>
        </S.ContainerOverlay>
      )}
    </>
  );
}

export { ModalCompletedProfile };
