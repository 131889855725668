import styled, { css } from 'styled-components'

export const Container = styled.button`
  ${({ theme }) => css`
    width: 150px;
    height: 150px;
    background-color: transparent;

    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    border-radius: 15px;
    border: 2px dotted ${theme.colors.primary};

    div {
      width: 35px;
      height: 35px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 17px;

      border: 1px solid ${theme.colors.primary};

      span {
        font-size: ${theme.font.sizes.large};
        color: ${theme.colors.primary};
      }
    }

    p {
      font-size: ${theme.font.sizes.xsmall};
      color: ${theme.colors.text};
      margin-top: 9px;
    }
  `}
`
