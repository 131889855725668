import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router";

import { ArrowButton } from "components/ArrowButton";
import { SearchComercialModelsManual } from "components/SearchComercialModelsManual";
import { EquipmentCard } from "components/EquipmentCard";

import { useToast } from "hooks/toast";

import { api } from "services/api";

import { EquipmentsShimmer } from "Shimmer/EquipmentsShimmer";

import * as S from "./styles";

export type TypeEquipmentsManualProps = {
  id: string;
  image: string;
  name: string;
  itens: string;
  brand: {
    id: string;
    name: string;
    image: string;
  };
};

type RouteParams = {
  brandId: string;
  categoryId: string;
};

export function TypeEquipmentsManual() {
  const { categoryId, brandId } = useParams<RouteParams>();
  const history = useHistory();
  const { addToast } = useToast();

  const [isLoading, setIsLoading] = useState(true);
  const [typesEquipamentsOfCategory, setTypesEquipamentsOfCategory] = useState<
    TypeEquipmentsManualProps[]
  >([]);

  async function loadTypesEquipamentsOfCategory(idCategory: string) {
    try {
      const response = await api.get(
        `equipment?idMarca=${brandId}&idCategoria=${categoryId}`
      );

      if (!response.data.categories.length && response.data.models.length) {
        history.push(`/brands/${brandId}/equipments/${categoryId}/models`);
        return;
      }

      const typesEquipmentsData = response.data.categories.map(
        (typeEquipment: TypeEquipmentsManualProps) => ({
          id: typeEquipment.id,
          image: typeEquipment.image,
          name: typeEquipment.name,
          itens: `${typeEquipment.itens.toString()} modelos`,
        })
      );

      setTypesEquipamentsOfCategory(typesEquipmentsData);
    } catch (err) {
      // sendError(err)
      addToast({
        title: "Ops!!",
        description: "Ocorreu um erro ao listar os tipos de equipamentos",
        type: "error",
      });
    } finally {
      setIsLoading(false);
    }
  }

  useEffect(() => {
    loadTypesEquipamentsOfCategory(categoryId);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  function handleShowModel(categoryIdParams: string) {
    history.push(`/brands/${brandId}/equipments/${categoryIdParams}/models`);
  }

  return (
    <S.Container>
      <header>
        <ArrowButton />

        <h2>Tipos de equipamento</h2>

        <SearchComercialModelsManual description="Ou selecione um tipo de equipamento para ver os modelos." />
      </header>

      <S.Content>
        {isLoading && <EquipmentsShimmer />}
        {!isLoading &&
          typesEquipamentsOfCategory.map((typesEquipament) => (
            <EquipmentCard
              key={typesEquipament.id}
              onClick={() => {
                handleShowModel(typesEquipament.id);
              }}
              equipment={typesEquipament}
            />
          ))}
      </S.Content>
    </S.Container>
  );
}
