import { useCallback, useEffect, useState } from "react";

import * as S from "../styles";
import * as SP from "./styles";
import { SearchInput } from "components/SearchInput";
import { LoadingProfiz } from "components/LoadingProfiz";
import { api } from "services/api";
import { useToast } from "hooks/toast";
import { CheckBoxProducts } from "components/CheckBoxProducts";

import { ProductPropsUsedInContext } from "hooks/budget/selectedProducts";
import axios from "axios";
import { ModalRight } from "components/ModalRight";
import { FormCreateProduct } from "components/ModalProducts/FormCreateProduct";
import { FormEditProduct } from "components/ModalProducts/FormEditProduct";
import { searchDatalayer } from "utils/pushDataLayer";
import debounce from "lodash.debounce";

interface ProductsResponse {
  products: ProductPropsUsedInContext[];
}

interface ProductsProps {
  handleOpenModalCreateProducts: (value: boolean) => void;
  openModalCreateProducts: boolean;
}

const MyProducts = ({
  handleOpenModalCreateProducts,
  openModalCreateProducts,
}: ProductsProps) => {
  const { addToast } = useToast();
  const [isLoading, setIsLoading] = useState(false);
  const [searchInput, setSearchInput] = useState("");
  const [products, setProducts] = useState<ProductPropsUsedInContext[]>([]);
  const [idProduct, setIdProduct] = useState(0);
  const [isSearching, setIsSearching] = useState(false);

  const [isOpenFormEditProducts, setIsOpenFormEditProducts] = useState(false);

  function sortProductsById(products: ProductPropsUsedInContext[]) {
    return products.sort((a, b) => b.id - a.id);
  }

  async function loadProducts(search?: string) {
    try {
      setIsLoading(true);
      const { data } = await api.get<ProductsResponse>("products", {
        params: {
          limit: 100,
          offset: 0,
          search,
        },
      });

      const sortedProducts = sortProductsById(data.products);

      setProducts(sortedProducts);
      setIsLoading(false);
    } catch (error) {
      addToast({
        title: "Ops!!",
        description:
          axios.isAxiosError(error) && error.response?.data.error
            ? error.response.data.error
            : "Não foi possível carregar os produtos.",
        type: "error",
      });
    } finally {
      setIsLoading(false);
    }
  }

  async function handlePressDeleteProduct(productId: number) {
    try {
      await api.delete(`/products/${productId}`);

      addToast({
        title: "Ok!!",
        description: "Produto excluído com sucesso.",
        type: "success",
      });

      loadProducts();
    } catch (error) {
      addToast({
        title: "Ops!!",
        description:
          axios.isAxiosError(error) && error.response?.data.error
            ? error.response.data.error
            : "Não foi possível excluir o produto.",
        type: "error",
      });
    }
  }

  function handleSearchProduct(text: string) {
    setIsSearching(true);

    setSearchInput(text);
    debounceFn(text);
    searchDatalayer({ search_term: text, success: true });
  }

  function handleSearchCancel() {
    setSearchInput("");
    loadProducts();
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debounceFn = useCallback(debounce(getProducts, 3000), []);

  function getProducts(search?: string) {
    if (!!search) {
      loadProducts(search);
      setIsSearching(false);

      return;
    }

    loadProducts();

    setIsSearching(false);
  }

  useEffect(() => {
    loadProducts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {isLoading ? (
        <LoadingProfiz isVisible={isLoading} />
      ) : (
        <S.Content>
          <S.ContainerSearch>
            <SearchInput
              placeholder="Busque por um produto"
              searchValue={searchInput}
              onChange={(event) => handleSearchProduct(event.target.value)}
              handleCancel={handleSearchCancel}
              loadingInput={isSearching}
            />
          </S.ContainerSearch>

          {!!products.length ? (
            products.map((product) => {
              return (
                <SP.Wrapper key={product.id}>
                  <CheckBoxProducts
                    dontUseCheckbox
                    spacingPrice
                    data={product}
                    onSelect={() => {}}
                    handleChangeProduct={(id) => {
                      setIdProduct(id);
                      setIsOpenFormEditProducts(true);
                    }}
                    handleDeleteProduct={handlePressDeleteProduct}
                  />
                </SP.Wrapper>
              );
            })
          ) : (
            <S.ContainerNotProducts>
              Você ainda não criou nenhum produto, <br /> clique no
              <span> botão “+”</span> para começar
            </S.ContainerNotProducts>
          )}

          <ModalRight
            isOpen={openModalCreateProducts}
            handleToggleOpen={() => {
              handleOpenModalCreateProducts(!openModalCreateProducts);
            }}
          >
            <FormCreateProduct
              refetchListProducts={loadProducts}
              handleGoBack={() => {
                handleOpenModalCreateProducts(false);
              }}
            />
          </ModalRight>

          <ModalRight
            isOpen={isOpenFormEditProducts}
            handleToggleOpen={() => {
              setIsOpenFormEditProducts(!isOpenFormEditProducts);
            }}
          >
            <FormEditProduct
              refetchListProducts={loadProducts}
              idProduct={idProduct}
              handleGoBack={() => {
                setIsOpenFormEditProducts(false);
              }}
            />
          </ModalRight>
        </S.Content>
      )}
    </>
  );
};
export { MyProducts };
