import styled, { css } from "styled-components";

export const TabContainer = styled.div`
  ${({ theme }) => css`
    margin-top: 40px;

    .react-tabs__tab-list {
      border-bottom: 1px solid rgba(255, 255, 255, 0.12);
      display: flex;
      justify-content: space-between;
    }

    .react-tabs__tab {
      width: 50%;
      padding-left: 40px;
      font-size: ${theme.font.sizes.xsmall};
      color: ${theme.colors.text_light_38};
    }

    .react-tabs__tab--selected {
      background: inherit;
      border: 0px;
      color: ${theme.colors.text_light_87};
      font-weight: ${theme.font.normal};
      white-space: normal;
      letter-spacing: 0.02857em;
      border-bottom: 3px solid ${theme.colors.primary};
    }

    .react-tabs__tab-panel .react-tabs__tab-panel--selected {
      margin-top: 20px;
    }
  `}
`;
