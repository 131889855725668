import React, { useState } from 'react'
import { FormCreateLocalChecklist } from './FormCreateLocalChecklist'
import { ListLocalChecklist } from './ListLocalChecklist'

type SwippeModalProps = {
  listChecklist: boolean,
  createChecklist: boolean
}

type ModalChecklistProps = {
  handleToggleOpen: () => void
}

export function ModalLocalChecklist({
  handleToggleOpen
}: ModalChecklistProps) {

  const [
    isOpenModals,
    setIsOpenModals
  ] = useState({ listChecklist: true, createChecklist: false } as SwippeModalProps)

  const { listChecklist, createChecklist } = isOpenModals
  
  function handleSwippeModals(
    modalName: 'listChecklist' | 'createChecklist'
    ) {

    setIsOpenModals(() => {
      if (modalName === 'listChecklist') {
        return {
          listChecklist: !listChecklist,
          createChecklist: false,
        }
      }

      return {
        listChecklist: false,
        createChecklist: !createChecklist,
      }
    })
  }

  return (
    <>
      {listChecklist && (
        <ListLocalChecklist
          handleToggleOpen={handleToggleOpen}
          handleCreateChecklist={() => handleSwippeModals('createChecklist')}
        />
      )}

      {createChecklist && (
        <FormCreateLocalChecklist
          handleToggleModal={() => handleSwippeModals('listChecklist')}
        />
      )}
    </>
  )
}