import offlineIcon from 'assets/icons/offlineIcon.png';

import * as S from './styles';

export function Offline() {
  return (
    <S.Container>
      <S.OfflineImage alt="Ícone offline" src={offlineIcon} />

      <S.Title>Sem conexão com a internet</S.Title>

      <S.Info>Confira sua conexão e atualize a página</S.Info>
    </S.Container>
  );
};
