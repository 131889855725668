import React, { useEffect, useState } from "react";
import * as Yup from "yup";

import { Dropdown, DropdownOption } from "components/DropDown";
import { Input } from "components/Input";
import { Button } from "components/Button";

import { api } from "services/api";

import { MaterialCategoryResponseProps } from "dtos/MaterialCategoriesResponseDTO";
import { MaterialTypeDropDownProps } from "dtos/MaterialTypeDropDownDTO";
import { MaterialDropDownProps } from "dtos/MaterialDropDownDTO";
import { MaterialCategoryDropDownProps } from "dtos/MaterialCategoriesDropDownDTO";
import { MaterialProps } from "dtos/MaterialDTO";

import { useToast } from "hooks/toast";
import { DropDownProps } from "hooks/serviceFormTree";
import { useSelectedMaterial } from "hooks/budget/selectedMaterials";
import { useMaterialsForEditingBudget } from "hooks/budget/materialsForEditingBudget";

import getValidationErrors from "utils/getValidationErrors";

import { MaterialType } from "../Areas/MaterialType";
import { MaterialName } from "../Areas/MaterialName";

import arrowLeft from "assets/icons/arrow-left.svg";

import * as S from "./styles";

type FormMaterialProps = {
  data: MaterialProps;
  handleListMaterials: () => void;
};

type Errors = {
  [key: string]: string;
};

export function FormEditMaterial ({
  data,
  handleListMaterials,
}: FormMaterialProps) {
  const { addToast } = useToast();
  const { handleUpdatedSelectedMaterial } = useSelectedMaterial();
  const { handleUpdatedMaterial } = useMaterialsForEditingBudget();

  const [loadingButton, setLoadingButton] = useState(false);

  const [categories, setCategories] = useState<MaterialCategoryDropDownProps[]>(
    []
  );
  const [materialsType, setMaterialsType] = useState<
    MaterialTypeDropDownProps[]
  >([]);
  const [materials, setMaterials] = useState<MaterialDropDownProps[]>([]);

  const [categorySelected, setCategorySelected] = useState<number>(
    data.categoryId
  );
  const [materialTypeSelected, setMaterialTypeSelected] =
    useState<DropDownProps>(data.type);
  const [materialSelected, setMaterialSelected] = useState<DropDownProps>(
    data.item
  );
  const [formattedPrice, setFormattedPrice] = useState(data.formattedPrice);
  const [unMaskedPrice, setUnMaskedPrice] = useState(data.price);

  const [hasError, setHasError] = useState<Errors>({} as Errors);
  const [hasErrorOnCategory, setHasErrorOnCategory] = useState("");
  const [hasErrorOnMaterialType, setHasErrorOnMaterialType] = useState("");
  const [hasErrorOnMaterialName, setHasErrorOnMaterialName] = useState("");

  async function getCategories() {
    const response = await api.get("/materials/categories");
    const { categories: categoriesResponse } = response.data;
    const parsedMaterialsCategory = categoriesResponse.map(
      (category: MaterialCategoryResponseProps) => ({
        id: category.id,
        name: category.label,
      })
    );
    setCategories(parsedMaterialsCategory);
  }

  async function getMaterialsAndTypeMaterials() {
    const response = await api.get("/material/form");
    const {
      materialsType: materialsTypeResponse,
      materials: materialsResponse,
    } = response.data;

    setMaterialsType(materialsTypeResponse);
    setMaterials(materialsResponse);
  }

  useEffect(() => {
    window.scrollTo(0, 0);
    getCategories();
    getMaterialsAndTypeMaterials();
  }, []);

  function handleSelectCategory({ id }: DropdownOption) {
    setCategorySelected(id);
    setHasErrorOnCategory("");
  }

  function handleSelectMaterialType({ id, name }: DropdownOption) {
    setMaterialTypeSelected({ id, name });

    const materialsSelected = materialsType.find(
      (currentMaterialsType) =>
        currentMaterialsType.id === id
    )

    if(materialsSelected) {
      setMaterialSelected(materialsSelected.materials[0]);
    }

    setHasErrorOnMaterialType("");
  }

  function handleSelectMaterialName({ id, name }: DropdownOption) {
    setMaterialSelected({ id, name });
    setHasErrorOnMaterialName("");
  }

  async function handleEditMaterial() {
    try {
      if (!categorySelected) {
        setHasErrorOnCategory("Categoria do material é obrigatório");
      } else {
        setHasErrorOnCategory("");
      }

      if (!materialTypeSelected) {
        setHasErrorOnMaterialType("Tipo de material é obrigatório");
      } else {
        setHasErrorOnMaterialType("");
      }

      if (!materialSelected) {
        setHasErrorOnMaterialName("Material é obrigatório");
      } else {
        setHasErrorOnMaterialName("");
      }

      const schema = () => {
        return Yup.object().shape({
          formattedPrice: Yup.string().required("Valor do item é obrigatório"),
        });
      };

      await schema().validate(
        {
          formattedPrice,
        },
        { abortEarly: false }
      );

      if (!categorySelected || !materialTypeSelected || !materialSelected) {
        return;
      }

      setLoadingButton(true);

      const parsedMaterial = {
        id: data.id,
        categoryId: categorySelected,
        type: {
          id: materialTypeSelected.id,
          name: materialTypeSelected.name,
        },
        item: {
          id: materialSelected.id,
          name: materialSelected.name,
        },
        price: unMaskedPrice,
        formattedPrice,
      };

      if (!data.checked) {
        await api.put(`/material/${data.id}`, {
          categoryId: categorySelected,
          typeId: materialTypeSelected.id,
          itemId: materialSelected.id,
          price: unMaskedPrice,
        });
      }

      handleUpdatedSelectedMaterial(parsedMaterial);
      handleUpdatedMaterial(parsedMaterial);

      addToast({
        title: "Sucesso",
        description: "Material atualizado com sucesso",
        type: "success",
      });

      handleListMaterials();
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        const errors = getValidationErrors(err);
        setHasError(errors);

        return;
      }
      // sendError(err)

      addToast({
        title: "Ops!!",
        description: "Erro ao editar o material",
        type: "error",
      });
    } finally {
      setLoadingButton(false);
    }
  }

  return (
    <>
      <S.Top>
        <S.ButtonClose onClick={() => handleListMaterials()}>
          <img src={arrowLeft} alt="flecha pra esquerda" />
        </S.ButtonClose>

        <p>Editar material</p>
      </S.Top>

      <S.Content>
        <S.Wrapper>
          <Dropdown
            label="Categoria*"
            placeholder="Selecione uma categoria"
            options={categories}
            categoryIdSelected={data.categoryId}
            // eslint-disable-next-line react/jsx-no-bind
            onClickedValue={handleSelectCategory}
            hasError={hasErrorOnCategory}
          />
        </S.Wrapper>

        <S.Wrapper>
          <MaterialType
            materialsType={materialsType}
            selectedMaterialTypeId={materialTypeSelected.id}
            // eslint-disable-next-line react/jsx-no-bind
            handleSelectMaterialType={handleSelectMaterialType}
            hasError={hasErrorOnMaterialType}
          />
        </S.Wrapper>
        <S.Wrapper>
          <MaterialName
            selectedMaterialTypeId={materialTypeSelected.id}
            selectedMaterialId={materialSelected.id}
            materialsType={materialsType}
            materials={materials}
            // eslint-disable-next-line react/jsx-no-bind
            handleSelectMaterialName={handleSelectMaterialName}
            // eslint-disable-next-line react/jsx-no-bind
            handleAddErrorOnMaterialType={setHasErrorOnMaterialType}
            hasError={hasErrorOnMaterialName}
          />
        </S.Wrapper>

        <S.Wrapper>
          <Input
            name="Valor*"
            maskType="money"
            valueCurrency={formattedPrice}
            onChangeCurrency={({ formattedValue, floatValue }) => {
              setFormattedPrice(formattedValue);
              setUnMaskedPrice(floatValue!);
            }}
            hasError={hasError.formattedPrice}
            onFocusClearError={() =>
              setHasError({ ...hasError, formattedPrice: "" })
            }
          />
        </S.Wrapper>

        <S.Wrapper>
          <Button loading={loadingButton} onClick={() => handleEditMaterial()}>
            Salvar material
          </Button>
        </S.Wrapper>
      </S.Content>
    </>
  );
}
