import styled, { css } from "styled-components";

export const Container = styled.div`
  ${({ theme }) => css`
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: ${theme.colors.menu_background};
    padding: 10px;
    border-radius: 10px;
    margin-bottom: 5px;

    p {
      font-family: ${theme.font.family};
      font-size: ${theme.font.sizes.xsmall};
      font-weight: ${theme.font.normal};
      color: ${theme.colors.text_light_60};
      line-height: 20px;
    }
  `}
`;
