export function switchTextRating(value: number) {
  switch (value) {
    case 1:
      return "Ruim";
    case 2:
      return "Insatisfeito";
    case 3:
      return "Razoável";
    case 4:
      return "Bom";
    case 5:
      return "Perfeito";
    default:
      return "";
  }
}
