import React, { useEffect, useState } from "react";

import { ArrowButton } from "components/ArrowButton";
import { Button } from "components/Button";

import { ServiceOrderDTO } from "dtos/ServiceOrderDTO2";
import addMaskDateAndHour from "utils/addMaskDateAndHour";

import SchedulePMOC from "assets/icons/schedule-pmoc.svg";
import CalendarSvg from "assets/schedule.svg";

import * as S from "./styles";
import { Calendar } from "components/Calendar";
import apiv2 from "services/apiv2";
import { useToast } from "hooks/toast";
import { Input } from "components/Input";
import { format } from "date-fns";

type DetailEquipmentProps = {
  handleBackButton: () => void;
  handleUpdate: () => void;
  serviceOrderData: ServiceOrderDTO;
};

const serviceStatus = {
  pending: () => "Não agendado",
  scheduled: (scheduleDate: string) =>
    `Agendado para ${addMaskDateAndHour(scheduleDate)}`,
  concluded: () => "Concluído",
};
type SelectedScheduleProps = {
  date: string;
  status: "concluded" | "scheduled" | "pending";
};

export function SchedulePmoc({
  handleBackButton,
  serviceOrderData,
  handleUpdate,
}: DetailEquipmentProps) {
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [serviceOrder, setServiceOrder] = useState({} as ServiceOrderDTO);
  const [dateModalVisible, setDateModalVisible] = useState(false);
  const [selectedHour, setSelectedHour] = useState("");
  const [selectedSchedule, setSelectedSchedule] =
    useState<SelectedScheduleProps>({} as SelectedScheduleProps);
  const [hourError, setHourError] = useState("");
  const { addToast } = useToast();

  useEffect(() => {
    setServiceOrder(serviceOrderData);
    setSelectedSchedule({
      status: serviceOrderData.services[0].status,
      date: serviceOrderData.services[0].scheduleDate,
    });

    if (serviceOrderData.services[0].status === "scheduled") {
      const scheduleDate = addMaskDateAndHour(
        serviceOrderData.services[0].scheduleDate
      );
      const hoursOfDate = scheduleDate.split(" ")[1];

      setSelectedHour(hoursOfDate);
      setSelectedDate(new Date(serviceOrderData.services[0].scheduleDate));
    }
  }, [serviceOrderData]);

  const handleScheduleDate = () => {
    if (!selectedHour) {
      setHourError("Digite uma hora válida");
      return;
    }
    const hour = selectedHour.split(":")[0];
    const minute = selectedHour.split(":")[1];
    if (
      new Date(selectedDate.setHours(Number(hour), Number(minute))) < new Date()
    ) {
      setHourError("A data não pode ser um dia anterior a hoje.");
      return;
    }
    const date = format(
      new Date(selectedDate.setHours(Number(hour), Number(minute))),
      "yyyy-MM-dd'T'HH:mm"
    );

    setSelectedSchedule({ status: "scheduled", date: date });
    setDateModalVisible(false);
  };

  const handleSubmit = async () => {
    try {
      const serviceToPost = serviceOrder.services[0];
      const serviceApiAux = {
        services: [
          {
            idBudgetService: serviceToPost.idBudgetService,
            id: serviceToPost.service.service.id,
            date: format(
              new Date(selectedSchedule.date),
              "yyyy-MM-dd HH:mm:ss"
            ),
          },
        ],
        originUpdate: "web",
      };

      const getScheduleStatus = async () => {
        if (serviceToPost.status === "scheduled") {
          await apiv2.put(
            `budgets/service-order/${serviceOrder.id}/reschedule`,
            serviceApiAux
          );
        } else {
          await apiv2.post(
            `budgets/service-order/${serviceOrder.id}/schedule`,
            serviceApiAux
          );
        }
      };
      getScheduleStatus();
      addToast({
        title: "Sucesso",
        description: "Agendamento criado com sucesso",
        type: "success",
      });

      setTimeout(() => {
        handleUpdate();
        handleBackButton();
      }, 1000);
    } catch (error) {
      addToast({
        title: "Ops!!",
        description: "Ocorreu um erro ao salvar os serviços.",
        type: "error",
      });
    }
  };

  if (!serviceOrderData.id || !serviceOrder.id) {
    return <></>;
  }

  return (
    <S.Content>
      <S.ContainerOverlay isVisible={dateModalVisible}>
        <S.CalendarWrapper>
          <Calendar
            selectDays={selectedDate}
            onChangeDate={(day) => {
              setSelectedDate(day);
              setHourError("");
            }}
          />
          <S.WrapperInput style={{ marginBottom: !hourError ? 20 : 0 }}>
            <Input
              name="Definir horário"
              placeholder="Defina o horário"
              value={selectedHour}
              type="time"
              onChange={(e) => {
                setSelectedHour(e.target.value);
                setHourError("");
              }}
            />
          </S.WrapperInput>
          {hourError && <S.Error>{hourError}</S.Error>}
          <S.RowButtons>
            <S.CalendarCancelButton
              onClick={() => {
                setDateModalVisible(false);
              }}
            >
              Cancelar
            </S.CalendarCancelButton>
            <S.CalendarApproveButton
              onClick={() => {
                handleScheduleDate();
              }}
            >
              Confirmar
            </S.CalendarApproveButton>
          </S.RowButtons>
        </S.CalendarWrapper>
      </S.ContainerOverlay>
      <S.Header style={{ marginBottom: 20, marginRight: "auto" }}>
        <ArrowButton handleFunction={handleBackButton} />
      </S.Header>

      <S.Title>Agendamento</S.Title>
      <img src={SchedulePMOC} alt="schedule" />

      <S.ScheduleWrapper>
        <S.Description>Vamos agendar os serviços deste PMOC.</S.Description>
        <S.ButtonCalendar onClick={() => setDateModalVisible(true)}>
          <img
            src={CalendarSvg}
            alt="mostra calendário para agendar o serviço"
          />
        </S.ButtonCalendar>
      </S.ScheduleWrapper>

      <S.Description
        style={{ marginRight: "auto", marginTop: 20, marginBottom: 20 }}
      >
        {serviceOrder?.environment?.name}
      </S.Description>

      <S.Negrito style={{ marginRight: "auto" }}>
        {serviceOrder.equipment?.tagEquipment}
      </S.Negrito>
      {serviceOrder.services.length > 0 && (
        <S.Card>
          <S.CardHeader>
            <S.Negrito>
              {serviceOrder?.services[0]?.service?.service?.name}
            </S.Negrito>
            <S.Description style={{ marginLeft: 10 }}>
              {` (${serviceOrder?.equipment?.currentFrequencyService})`}
            </S.Description>
          </S.CardHeader>
          <S.CardHeader style={{ marginTop: 10 }}>
            <S.Dot
              style={{
                backgroundColor:
                  selectedSchedule.status === "scheduled"
                    ? "#FFF595"
                    : selectedSchedule.status === "concluded"
                    ? "#D3FF9A"
                    : "",
              }}
            />
            {serviceOrder.services[0].idBudgetService && (
              <S.Negrito style={{ fontWeight: 400 }}>
                {serviceStatus[selectedSchedule.status](selectedSchedule.date)}
              </S.Negrito>
            )}
          </S.CardHeader>
        </S.Card>
      )}

      <Button onClick={() => handleSubmit()} typeButton="default">
        Salvar
      </Button>
    </S.Content>
  );
}
