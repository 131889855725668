import styled, { css } from 'styled-components';

export const Container = styled.div`
  ${({ theme }) => css`
    display: flex;
    padding: 0 30px;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    width: 100vw;
    height: 90vh;

    img {
      height: 120px;
      width: 120px;
    }

    h1 {
      font-size: ${theme.font.sizes.large};
      color: ${theme.colors.title};
      font-weight: ${theme.font.bold};
      text-align: center;
    }

    p {
      font-size: ${theme.font.sizes.xsmall};
      color: ${theme.colors.text_light};
      font-weight: ${theme.font.normal};
      text-align: center;
      margin-top: 20px;
    }
  `};
`;