import styled, { css } from "styled-components";

export const Container = styled.div`
  ${({ theme }) => css`
    background-color: ${theme.colors.strong_background};
    width: 100%;
    max-width: 1400px;
    margin: 0 auto;
    padding: 30px;
  `}
`;

export const Title = styled.h1`
  ${({ theme }) => css`
    font-size: 18px;
    font-family: ${theme.font.family};
    color: ${theme.colors.text_light_87};
    font-weight: 700;
    margin-left: 15px;
  `}
`;

export const Header = styled.div`
  width: 50%;
  display: flex;
  margin-left: 45px;
  align-items: center;
`;

export const Content = styled.div`
  width: 50%;
  margin-top: 20px;
  padding-bottom: 10px;
  margin-left: 45px;

  div {
    display: flex;
    width: 810px;
    height: 300px;
    border: 1px solid #9b9b9b;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    margin-bottom: 20px;
  }
`;
