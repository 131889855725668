import React, { useState } from 'react'

import { MaterialProps } from 'dtos/MaterialDTO'

import { EditButton } from 'components/EditButton'
import { DeleteButton } from 'components/DeleteButton'
import { AlertModal } from 'components/AlertModal'

import * as S from './styles'

type CardMaterialProps = {
  data: MaterialProps
  onSelect: () => void
  handleChangeMaterial: (data: MaterialProps) => void
  handleDeleteMaterial: (idMaterial: number) => void
}

export function CardMaterial({
  data,
  onSelect,
  handleChangeMaterial,
  handleDeleteMaterial
}: CardMaterialProps) {
  const [isSelected, setIsSelected] = useState(data.checked)
  const [isVisibleModal, setIsVisibleModal] = useState(false)

  function handleSelectMaterial() {
    setIsSelected((prev) => !prev)

    onSelect()
  }

  function handleToggleModal() {
    handleDeleteMaterial(data.id)
    setIsVisibleModal(false)
  }

  return (
    <>
      <AlertModal
        isVisible={isVisibleModal}
        title="Deseja apagar?"
        description="Deseja realmente apagar esse material?"
        action="choose"
        handleConfirm={() => handleToggleModal()}
        onCloseModal={() => setIsVisibleModal(false)}
      />
      <S.Container isSelected={isSelected}>
        <S.Button onClick={() => handleSelectMaterial()}>
          <S.WrapperCheckbox>
            <S.Checkbox isSelected={isSelected}>
              <div />
            </S.Checkbox>
          </S.WrapperCheckbox>

          <S.Content>
            <div>
              <S.Title>{data.item.name}</S.Title>
              <S.Price>{data.formattedPrice}</S.Price>
            </div>

            <div>
              <S.Title>Tipo: {data.type.name}</S.Title>
            </div>
          </S.Content>
        </S.Button>

        {!data.checked && (
          <S.WrapperActions>
            <EditButton onClick={() => handleChangeMaterial(data)} />
            <DeleteButton onClick={() => setIsVisibleModal(true)} />
          </S.WrapperActions>
        )}
      </S.Container>
    </>
  )
}
