import { useState, useCallback } from "react";
import { FiX } from "react-icons/fi";
import axios from "axios";
import PencilSvg from "assets/icons/pencil.svg";
import { UnityDTO } from "dtos/ClientDTO";

import { ModalRight } from "components/ModalRight";
import { FormItem } from "components/FormItem";
import { SearchInput } from "components/SearchInput";
import { LoadingProfiz } from "components/LoadingProfiz";
import { CardSelectedUnity } from "./CardSelectedUnity";

import { useClient } from "hooks/budget/client";
import { useToast } from "hooks/toast";
import { usePmoc } from "hooks/usePmoc";
import { useTheme } from "styled-components";

import { mappedClientUnities } from "utils/mappedClientUnities";

import { api } from "services/api";

import * as S from "./styles";
import { Button } from "components/Button";
import { UnityForm } from "components/UnityForm";
import { useEditClient } from "hooks/editClient";

type UnitiesResponse = {
  unities: UnityDTO[];
};

type Props = {
  hasError?: string;
  disableDeleteButton?: boolean;
  disableEditButton?: boolean;
};

export function UnityArea({
  hasError,
  disableDeleteButton=false,
  disableEditButton=false,
}: Props) {
  const theme = useTheme();
  const {
    hasClient,
    client,
    handleSetUnityClient,
  } = useClient();
  const { addToast } = useToast();
  const { hasUnity, handleSetUnity, handleSetHasUnity } = usePmoc();

  const { handleSetEditClient, clearUnityData, setSelectedUnity } =
    useEditClient();

  const [unities, setUnities] = useState<UnityDTO[]>([]);
  const [filteredUnities, setFilteredUnities] = useState<UnityDTO[]>([]);

  const [isLoading, setIsLoading] = useState(false);
  const [isOpenUnityArea, setIsOpenUnityArea] = useState(false);
  const [searchInput, setSearchInput] = useState("");
  const [selectedUnityId, setSelectedUnityId] = useState(0);
  const [createUnity, setCreateUnity] = useState(false);

  const [modalOpen, setModalOpen] = useState(false);


  const hasDefaultUnity = unities.every((unity) => unity.default);

  const getUnities = useCallback(async () => {
    try {
      setIsLoading(true);
      const response = await api.get<UnitiesResponse>(
        `/clients/${client.id}/unities`,
        {
          params: {
            limit: 50,
            offset: 0,
            client_id: client.id,
          },
        }
      );

      const orderedList = response.data.unities.sort((current, next) => {
        if (current.default && !next.default) {
          return -1;
        } else {
          return 1;
        }
      });

      setUnities(mappedClientUnities(orderedList));
      setFilteredUnities(mappedClientUnities(orderedList));
    } catch (error) {
      addToast({
        title: "Erro",
        description:
          axios.isAxiosError(error) && error.response?.data.error
            ? error.response?.data.error
            : "Não foi possível carregar as unidades.",
        type: "error",
      });
    } finally {
      setIsLoading(false);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addToast, client, modalOpen]);

  function handleToggleUnityModal() {
    if (!hasClient) {
      addToast({
        type: "info",
        title: "Oops!",
        description: "Você deve selecionar um cliente primeiro.",
      });

      return;
    }
    setIsOpenUnityArea((prevState) => !prevState);
    getUnities();
  }

  function handleSearchUnity(text: string) {
    setSearchInput(text);

    const filtered = unities.filter((unity) => {
      const unitiesLowerCase = unity.name.toLowerCase();
      const searchValue = text.toLowerCase();
      return unitiesLowerCase.includes(searchValue);
    });

    setFilteredUnities(filtered);
  }

  function handleSearchCancel() {
    setSearchInput("");
    setFilteredUnities(unities);
  }

  function handleSwapCreateUnity() {
    clearUnityData();
    setCreateUnity((prevState) => !prevState);
    handleSetEditClient(client);
    if (filteredUnities.length >= 1) setSelectedUnity({} as UnityDTO);
  }
  function handleSelectUnity(unity: UnityDTO) {
    setSelectedUnityId(unity.id);
    handleSetUnity(unity);
    handleSetHasUnity(true);
    handleToggleUnityModal();
  }

  const handleCloseNewUnity = () => {
    getUnities();
    setCreateUnity((prevState) => !prevState);
  };

  return (
    <>
      {hasUnity ? (
        <CardSelectedUnity
          disableDeleteButton={disableDeleteButton}
          disableEditButton={disableEditButton}
        />
      ) : (
        <FormItem
          name="Unidade*"
          placeholder="Escolha uma das unidades cadastradas do cliente"
          hasError={hasError}
          onClick={handleToggleUnityModal}
        />
      )}

      <ModalRight
        handleToggleOpen={handleToggleUnityModal}
        isOpen={isOpenUnityArea}
      >
        {isLoading ? (
          <LoadingProfiz isVisible={isLoading} />
        ) : (
          <>
            {createUnity ? (
              <UnityForm
                modulePmoc
                defaultUnity={hasDefaultUnity}
                handleToggleOpen={() => {
                  handleToggleUnityModal();
                }}
                handleFunction={() => {
                  handleCloseNewUnity();
                }}
              />
            ) : (
              <>
                <S.Top>
                  <S.ButtonClose onClick={() => handleToggleUnityModal()}>
                    <FiX size={10} color={theme.colors.text} />
                  </S.ButtonClose>

                  <p>Unidades cadastradas</p>
                </S.Top>

                <S.Content>
                  <div>
                    <SearchInput
                      placeholder="Procure pelo nome ou endereço da unidade"
                      searchValue={searchInput}
                      onChange={(event) =>
                        handleSearchUnity(event.target.value)
                      }
                      // eslint-disable-next-line react/jsx-no-bind
                      handleCancel={handleSearchCancel}
                    />
                  </div>

                  <div>
                    <Button
                      typeButton="outline"
                      onClick={handleSwapCreateUnity}
                    >
                      Adicionar nova unidade
                    </Button>
                  </div>

                  {filteredUnities.length < 1 ? (
                    <S.WrapperMessage>
                      <p className="message">
                        Não existem unidades cadastradas.
                      </p>
                      <p className="message">
                        Pressione 'Adicionar nova unidade' para criar uma
                        unidade principal.
                      </p>
                    </S.WrapperMessage>
                  ) : (
                    <S.WrapperList>
                      {filteredUnities.map((item) => (
                        <S.CardUnity
                          isSelected={item.id === selectedUnityId}
                          key={item.id}
                          onClick={() => handleSelectUnity(item)}
                        >
                          <S.Checkbox>
                            {item.id === selectedUnityId && <S.Marker />}
                          </S.Checkbox>

                          <div className="content">
                            <p className="unity-name">
                              {item.name}
                              <span>
                                ({item.default ? "principal" : "adicional"})
                              </span>
                            </p>

                            <div className="register-date">
                              <S.Circle />
                              <p>Cadastrado em {item.createdAt}</p>
                            </div>

                            <p className="address">
                              {item.street}, {item.number} - {item.city} -{" "}
                              {item.uf}
                            </p>
                          </div>
                          <S.Top
                            marginLeft={35}
                            marginTop={5}
                          >
                            <S.EditUnity
                              onClick={(event) => {
                                setSelectedUnity(item);
                                setModalOpen(true);
                                event.stopPropagation();
                              }}
                            >
                              <img src={PencilSvg} alt="Editar endereço" />
                            </S.EditUnity>
                          </S.Top>
                        </S.CardUnity>
                      ))}
                    </S.WrapperList>
                  )}
                </S.Content>
              </>
            )}
          </>
        )}
      </ModalRight>

      <ModalRight
        isOpen={modalOpen}
        handleToggleOpen={() => {
          setModalOpen(false);
        }}
      >
      <UnityForm
        getFilteredUnities={setFilteredUnities}
        defaultUnity={hasDefaultUnity}
        handleToggleOpen={() => {
          setModalOpen(false);
          setIsOpenUnityArea(false);
        }}
        handlePmocEditModal={(unity?: UnityDTO) => {
          if (unity) {
            handleSetUnityClient(unity);
            handleSelectUnity(unity);
          }
          setIsOpenUnityArea(false);
          setModalOpen(false);
        }}
        />
        </ModalRight>
    </>
  );
}
