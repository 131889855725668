import React, { ReactElement, useState } from "react";

import { ButtonModalAction } from "components/ButtonModalAction";
import { CardSelectedServices } from "components/CardSelectedServices";
import { Button } from "components/Button";
import { ModalRight } from "components/ModalRight";
//import { ModalServices } from "components/ModalServices";
import { EditButton } from "components/EditButton";
import { DeleteButton } from "components/DeleteButton";
import { FormEditService } from "components/ModalServices/FormEditService";

import { useSelectedService } from "hooks/budget/selectedServices";

import { ServiceDTO } from "dtos/ServiceDTO";

import * as S from "./styles";
import { useServiceChecklist } from "hooks/serviceChecklist";
import { ServiceChecklist } from "pages/fieldService/ServiceChecklist";
import { ChecklistPage } from "pages/fieldService/ServiceChecklist/ChecklistPage";
import { RegisterPage } from "pages/fieldService/ServiceChecklist/RegisterPage";
import { DetailRegisterPage } from "pages/fieldService/ServiceChecklist/DetailRegisterPage";
import { ListServices } from "components/ModalServices/ListServices";
import { FormCreateService } from "components/ModalServices/FormCreateService";
import { useServiceDropDown } from "hooks/servicesDropDown";
import { DiscardInformationModal } from "components/DiscardInformationModal";

type ServiceAreaProps = {
  hasError?: string;
};

type TOption = {
  [key: string]: ReactElement;
};

export function ServiceArea({ hasError = "" }: ServiceAreaProps) {
  const {
    selectedServices,
    handleSetSelectedServices,
    handleUpdateQuantity,
    handleSetCheckedServices,
  } = useSelectedService();

  const [isOpenListService, setIsOpenListServices] = useState(false);
  const [isOpenFormEditService, setIsOpenFormEditService] = useState(false);
  const [currentEditService, setCurrentEditService] = useState<ServiceDTO>(
    {} as ServiceDTO
  );

  const [isCreatePage, setIsCreatePage] = useState(false);
  const [currentPageModal, setCurrentPageModal] = useState("");
  const [showCompareAlert, setShowCompareAlert] = useState(false);

  const {
    handleSetIsNotMenusAccess,
    selectedItemChecklist,
    isChecklistPage,
    //handleSetSelectedItemChecklist,
    detailChecklist,
    isEditPage,
    handleSetEditPage,
  } = useServiceChecklist();

  const { isEqualService, handleSetEqualService } = useServiceDropDown();

  const setPageComponent = (keyPage: string) => {
    const pagesArr: TOption = {
      serviceChecklist: (
        <ServiceChecklist
          handleAssociationFunction={handleSaveChecklistService}
          handleEditItemChecklist={() => setCurrentPageModal("checklistPage")}
          handlePageClose={() => {
            setPageBefore();
          }}
        />
      ),

      formEditService: (
        <FormEditService
          data={currentEditService}
          handleGoBack={() => {
            isOpenListService
              ? handleListServices()
              : handleToggleFormEditService();
          }}
          handleAddChecklist={setChecklistInEditPage}
          handleEditPage={setEditPage}
          handleSetDataFunction={setCurrentEditService}
        />
      ),

      checklistPage: (
        <ChecklistPage
          handleDetailRegister={setPageRegisterDetail}
          handleFunction={setPageRegister}
          handleFunctionClose={() => {
            setPageBeforeChecklistpage();
            //setCurrentPageModal("serviceChecklist");
          }}
          handleSaveInfo={() => {
            handleSaveChecklist();
          }}
        />
      ),
      registerPage: (
        <RegisterPage
          handleFunction={setPageRegisterDetail}
          handlePageClose={() => {
            setCurrentPageModal("checklistPage");
          }}
        />
      ),
      detailRegisterPage: (
        <DetailRegisterPage
          handlePageClose={() => {
            setCurrentPageModal(
              isChecklistPage ? "checklistPage" : "registerPage"
            );
          }}
        />
      ),
      listServices: (
        <ListServices
          handleToggleOpen={handleToggleListServices}
          // eslint-disable-next-line react/jsx-no-bind
          handleCreateService={handleCreateService}
          // eslint-disable-next-line react/jsx-no-bind
          handleEditService={handleEditService}
        />
      ),

      formCreateService: (
        <FormCreateService
          // eslint-disable-next-line react/jsx-no-bind
          data={currentEditService}
          handleGoBack={handleListServices}
          handleAddChecklist={setChecklistInCreatePage}
          handleEditPage={setEditPage}
        />
      ),
    };
    return pagesArr[keyPage];
  };

  function handleListServices() {
    setCurrentPageModal("listServices");
  }

  function handleCreateService() {
    setCurrentEditService({} as ServiceDTO);
    setCurrentPageModal("formCreateService");
  }

  function handleEditService(data: ServiceDTO) {
    setCurrentEditService(data);
    setCurrentPageModal("formEditService");
    setIsOpenFormEditService(false);
  }

  function setEditPage() {
    setCurrentPageModal("checklistPage");
  }

  function setPageRegisterDetail() {
    setCurrentPageModal("detailRegisterPage");
  }

  function setPageRegister() {
    setCurrentPageModal("registerPage");
  }

  function setPageBeforeChecklistpage() {
    if (!isEditPage) return setCurrentPageModal("serviceChecklist");
    isCreatePage
      ? setCurrentPageModal("formCreateService")
      : setCurrentPageModal("formEditService");
  }

  function setPageBefore() {
    isCreatePage
      ? setCurrentPageModal("formCreateService")
      : setCurrentPageModal("formEditService");
  }

  function setChecklistInEditPage() {
    if (isCreatePage) setIsCreatePage(false);
    handleSetIsNotMenusAccess(true);
    setCurrentPageModal("serviceChecklist");
  }

  function setChecklistInCreatePage() {
    setIsCreatePage(true);
    handleSetIsNotMenusAccess(true);
    setCurrentPageModal("serviceChecklist");
  }

  function handleSaveChecklist() {
    let aux = currentEditService;
    if (aux && aux.serviceChecklist) {
      aux.serviceChecklist = aux.serviceChecklist.map((element) => {
        if (element.id === detailChecklist.id) {
          element.name = detailChecklist.name;
          //element.quantity = detailChecklist.registers.length;
          if (detailChecklist.default)
            element.default = detailChecklist.default;
          element.registers = detailChecklist.registers;
          return element;
        }
        return element;
      });
    }
    setCurrentEditService(aux);
    //setCurrentPageModal("formEditService");
    //setCurrentPageModal("serviceChecklist");

    setPageBeforeChecklistpage();
  }

  function handleSaveChecklistService() {
    let aux = currentEditService;
    // aux.serviceChecklist =
    //   aux && aux.serviceChecklist
    //     ? [...aux.serviceChecklist, ...]
    //     : selectedItemChecklist;

    aux.serviceChecklist = selectedItemChecklist;
    setCurrentEditService(aux);
    //handleSetSelectedItemChecklist([]);

    setPageBefore();
    //setCurrentPageModal("formEditService");

    //setPageBeforeChecklistpage();
  }

  function handleToggleListServices() {
    setIsOpenListServices(!isOpenListService);

    handleSetIsNotMenusAccess(false);
    handleSetEditPage(false);
  }

  function handleOpenFormEditService(currentService: ServiceDTO) {
    setCurrentEditService(currentService);
    setIsOpenFormEditService(!isOpenFormEditService);
  }

  function handleToggleFormEditService() {
    setIsOpenFormEditService(!isOpenFormEditService);
    handleSetIsNotMenusAccess(false);
    handleSetEditPage(false);
  }

  function onIncrement(id: number, quantity: number) {
    handleUpdateQuantity(id, quantity);
  }

  function handleDeleteService(serviceId: number) {
    const filteredServices = selectedServices.filter(
      (service) => service.id !== serviceId
    );
    handleSetCheckedServices(filteredServices);
    handleSetSelectedServices(filteredServices);
  }

  return (
    <S.Container>
      <DiscardInformationModal
        showModal={showCompareAlert}
        handleConfirmButton={() => {
          setShowCompareAlert(false);
          handleSetEqualService(true);
          if (isOpenListService) {
            handleToggleListServices();
            return;
          }
          if (isOpenFormEditService) {
            handleToggleFormEditService();
            return;
          }
        }}
        handleCancelButton={() => {
          setShowCompareAlert(false);
        }}
      />
      <ModalRight
        isOpen={isOpenListService}
        // eslint-disable-next-line react/jsx-no-bind
        handleToggleOpen={() => {
          if (
            (currentPageModal === "formEditService" ||
              currentPageModal === "formCreateService") &&
            !isEqualService
          ) {
            setShowCompareAlert(true);
            return;
          }
          handleToggleListServices();
        }}
      >
        {/* <ModalServices
          // eslint-disable-next-line react/jsx-no-bind
          handleToggleOpen={handleToggleListServices}
        /> */}

        {setPageComponent(currentPageModal)}
      </ModalRight>

      <ModalRight
        isOpen={isOpenFormEditService}
        // eslint-disable-next-line react/jsx-no-bind
        handleToggleOpen={() => {
          if (currentPageModal === "formEditService" && !isEqualService) {
            setShowCompareAlert(true);
            return;
          }
          handleToggleFormEditService();
        }}
      >
        {/* <FormEditService
          data={currentEditService}
          // eslint-disable-next-line react/jsx-no-bind
          handleGoBack={handleToggleFormEditService}
        /> */}
        {setPageComponent(currentPageModal)}
      </ModalRight>

      {selectedServices.length <= 0 ? (
        <ButtonModalAction
          label="Serviço*"
          placeholder="Adicione serviços no seu orçamento"
          isSelected={isOpenListService}
          // eslint-disable-next-line react/jsx-no-bind
          onClick={() => {
            setCurrentPageModal("listServices");
            handleToggleListServices();
          }}
          hasError={hasError}
        />
      ) : (
        <>
          <S.Label>Serviço*</S.Label>

          <S.Content>
            {selectedServices.map((currentService) => (
              <S.WrapperCard>
                <CardSelectedServices
                  key={currentService.id}
                  data={currentService}
                  // eslint-disable-next-line react/jsx-no-bind
                  onIncrement={onIncrement}
                />

                <S.WrapperActions>
                  <EditButton
                    onClick={() => {
                      setCurrentPageModal("formEditService");
                      handleOpenFormEditService(currentService);
                    }}
                  />
                  <DeleteButton
                    onClick={() => handleDeleteService(currentService.id)}
                  />
                </S.WrapperActions>
              </S.WrapperCard>
            ))}
          </S.Content>

          <Button
            typeButton="outline"
            onClick={() => {
              setCurrentPageModal("listServices");
              handleToggleListServices();
            }}
          >
            Adicionar outro serviço
          </Button>
        </>
      )}
    </S.Container>
  );
}
