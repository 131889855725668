import { MdClose, MdChevronLeft, MdChevronRight } from "react-icons/md";
import { useTheme } from "styled-components";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";

import { PhotoURIProps } from "dtos/ServiceStagesDTO";

import * as S from "./styles";

type Props = {
  images: PhotoURIProps[];
  imageSelected: number;
  stageService?: string;
  isVisible: boolean;
  onCloseModal: () => void;
};

export function CarouselImages({
  images,
  imageSelected,
  stageService,
  isVisible,
  onCloseModal,
}: Props) {
  const theme = useTheme();

  return (
    <S.ContainerOverlay isVisible={isVisible}>
      <S.BoxModal>
        <S.Header>
          <button type="button" onClick={onCloseModal}>
            <MdClose size={32} color={theme.colors.input} />
          </button>
        </S.Header>

        <S.Title>{stageService}</S.Title>

        <S.Main>
          <Carousel
            centerMode
            centerSlidePercentage={100}
            dynamicHeight
            useKeyboardArrows
            showStatus={false}
            showThumbs={false}
            selectedItem={imageSelected}
            renderArrowPrev={(clickHandler) => (
              <S.ButtonArrowPrev onClick={clickHandler}>
                <MdChevronLeft size={40} color={theme.colors.input} />
              </S.ButtonArrowPrev>
            )}
            renderArrowNext={(clickHandler) => (
              <S.ButtonArrowNext onClick={clickHandler}>
                <MdChevronRight size={40} color={theme.colors.input} />
              </S.ButtonArrowNext>
            )}
          >
            {images.map((image, index) => (
              <S.Image
                key={image.id}
                src={image.link}
                alt={`Foto ${index + 1}`}
              />
            ))}
          </Carousel>
        </S.Main>
      </S.BoxModal>
    </S.ContainerOverlay>
  );
}
