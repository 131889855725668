import React from "react";
import { useLocation } from "react-router";

import { AccountData } from "dtos/permission/AnswerInviteDTO";

import LogoProfiz from "assets/icons/logo-color.svg";
import AndroidIcon from "assets/icons/android-icon.svg";
import IOSIcon from "assets/icons/ios-icon.svg";

import * as S from "./styles";

type RouteState = {
  accountInfo: AccountData;
};

export function AppStoreRedirectPage() {
  const location = useLocation<RouteState>();

  function handleAndroidStore() {
    const PLAY_STORE_LINK =
      "https://play.google.com/store/apps/details?id=br.com.profiz.appprestador";
    window.open(PLAY_STORE_LINK);
  }

  function handleIOSStore() {
    const APP_STORE_LINK =
      "itms-apps://apps.apple.com/br/app/profiz/id1534493326";
    window.open(APP_STORE_LINK);
  }

  return (
    <>
      <S.Container>
        <div className="inviteDetail">
          <div className="image">
            <img
              src={LogoProfiz}
              alt="logo profiz"
              height={window.screen.height * 0.05}
            />
          </div>
          <p className="title">Baixe nosso app!</p>
          <p className="subtitle">
            Baixe nosso aplicativo para usufruir dos benefícios concedidos por{" "}
            <strong>{location.state.accountInfo.companyName}</strong>.
          </p>
        </div>

        <S.AppButton onClick={handleAndroidStore}>
          <img src={AndroidIcon} alt="icone android" height={20} />
          <p className="title">
            Disponível no <br />
            <strong>Google Play</strong>
          </p>
          <img src={LogoProfiz} alt="logo profiz" height={20} />
        </S.AppButton>

        <S.AppButton onClick={handleIOSStore}>
          <img src={IOSIcon} alt="icone ios" height={20} />
          <p className="title">
            Disponível na <br />
            <strong>App Store</strong>
          </p>
          <img src={LogoProfiz} alt="logo profiz" height={20} />
        </S.AppButton>
      </S.Container>
    </>
  );
}
