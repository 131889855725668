import styled, { css } from "styled-components";

export const Container = styled.div`
  ${({ theme }) => css`
    display: flex;
    width: 100%;
    border-radius: 10px;
    background-color: ${theme.colors.background_light};

    & + & {
      margin-top: 15px;
    }
  `}
`;

export const Content = styled.button`
  ${({ theme }) => css`
    width: 90%;

    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    padding: 16px 0 16px 30px;

    & > div:first-of-type {
      width: 90%;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      gap: 10px;
      font-size: 1.6rem;

      p {
        font-weight: 500;
      }

      span {
        font-weight: bold;
        color: ${theme.colors.primary};
      }
    }
  `}
`;

export const Title = styled.p`
  ${({ theme }) => css`
    font-size: 1.5rem;

    color: ${theme.colors.title};
    font-weight: ${theme.font.bold};
  `}
`;

export const PriceText = styled.p`
  ${({ theme }) => css`
    font-size: 1.5rem;
    color: ${theme.colors.title};
  `}
`;

export const Description = styled.p`
  ${({ theme }) => css`
    font-size: 1.4rem;
    color: ${theme.colors.text};
    margin-bottom: 4px;
  `}
`;

export const WrapperActions = styled.div`
  ${({ theme }) => css`
    width: 10%;
    min-height: 110px;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    background-color: ${theme.colors.background_light};

    display: flex;
    flex-direction: column;
  `}
`;

export const Steps = styled.div`
  display: flex;
  justify-content: flex-start;

  ${({ theme }) => css`
    p {
      font-size: 1.6rem;
      color: ${theme.colors.text};
      margin-bottom: 4px;
      text-decoration: underline;
    }
  `}
`;
