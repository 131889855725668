import styled, { css, keyframes } from "styled-components";
import { lighten } from "polished";

type ModalProps = {
  isVisible: boolean;
};

const ModalScale = keyframes`
  from {
    transform: scale(0.5);
    opacity: 0.4;
  }
  to {
    transform: scale(1);
    opacity: 1;
  }
`;

export const ContainerOverlay = styled.aside<ModalProps>`
  ${({ theme, isVisible }) => css`
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;

    display: ${isVisible ? "flex" : "none"};
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: ${theme.layers.alwaysOnTop};
  `}
`;

export const BoxModal = styled.div`
  ${({ theme }) => css`
    width: 20%;
    min-width: 380px;
    max-width: 460px;
    padding: 0 16px 16px;
    border-radius: 10px;

    display: flex;
    flex-direction: column;

    background-color: ${theme.colors.background_modal};
    animation: ${ModalScale} 0.3s ease-in-out;
  `}
`;

export const Header = styled.div`
  width: 100%;
  height: 56px;
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  & > button {
    border-radius: 6px;
    transition: background 0.2s;

    &:hover {
      background: ${({ theme }) => lighten(0.1, theme.colors.background_modal)};
    }
  }

  h4 {
    margin-top: 12px;
  }
`;

export const Main = styled.div`
  width: 100%;
  height: 46%;
  min-height: 150px;
`;

export const Title = styled.h4`
  font-size: 18px;
  color: rgba(255, 255, 255, 0.87);
  text-align: center;
  margin-bottom: 12px;
`;

export const Description = styled.p`
  ${({ theme }) => css`
    font-size: ${theme.font.sizes.medium};
    color: ${theme.colors.text};
    margin: 0 8px;
    text-align: center;
  `}
`;

export const Share = styled.button`
  ${({ theme }) => css`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;

    p {
      font-family: ${theme.font.family};
      font-weight: ${theme.font.normal};
      font-size: ${theme.font.sizes.small};
      line-height: 15px;
      color: rgba(255, 255, 255, 0.87);
      margin-left: 15px;
    }
  `}
`;

export const Line = styled.div`
  height: 1px;
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 15px;
  margin-bottom: 15px;
  border: 1px solid #353535;
`;
