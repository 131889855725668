import { ChangeEvent, useCallback } from "react";
import { FiPlus } from "react-icons/fi";
import { useTheme } from "styled-components";

import { ImageResult } from "pages/fieldService/ServicesHub/RunServiceChecklist";

import { useToast } from "hooks/toast";

import loadingImg from "assets/loading-indicator.svg";

import * as S from "./styles";

type AddPhotoServiceButton = {
  loading?: boolean;
  stageID: number;
  maxPhotos: number;
  photosQuantity: number;
  onPictureChoice: (photo: ImageResult) => void;
};

export function AddPhoto({
  loading,
  stageID,
  maxPhotos,
  photosQuantity,
  onPictureChoice,
}: AddPhotoServiceButton) {
  const theme = useTheme();
  const { addToast } = useToast();

  const photoLimitReached = photosQuantity >= maxPhotos;

  const validationErrorAlert = () =>{
    if (photoLimitReached) {
      addToast({
        title: "Máximo permitido",
        description: `Ops, o máximo de fotos permitidos é ${maxPhotos}`,
        type: "error",
      });
      return
    }
  }

  const handleAddPhoto = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      if (e.target.files) {
        const file = e.target.files[0];

        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const [_, extension] = file.type.split("/");

        const reader = new FileReader();

        reader.readAsDataURL(file);

        reader.onload = async () => {
          const result = reader.result as string;

          const { 1: fileBase64Splited } = result.split(",");

          onPictureChoice({ encode: fileBase64Splited, extension });
        };
      }
    },
    [onPictureChoice]
  );

  return (
    <S.Container photoLimitReached={photoLimitReached}>
      {loading ? (
        <img src={loadingImg} alt="carregando..." />
      ) : (
        <label htmlFor={`attachment-${stageID}`} onClick={validationErrorAlert}>
          <FiPlus size="18" color={theme.colors.primary} />
          <input
          id={`attachment-${stageID}`}
          type="file"
          accept="image/*"
          onChange={handleAddPhoto}
          disabled={photoLimitReached}
          />
        </label>
      )}
    </S.Container>
  );
}
