import React from 'react';

import * as S from './styles';

export function MarketPlaceHome() {
  return (
    <S.Container>
      <h1>Marketplace</h1>
    </S.Container>
  );
}