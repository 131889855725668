/* eslint-disable array-callback-return */
import React, { useEffect, useState } from "react";
import { useTheme } from "styled-components";
import { FiChevronLeft } from "react-icons/fi";

import { useEditClient } from "hooks/editClient";

import { UnityDTO } from "dtos/ClientDTO";

import { Button } from "components/Button";
import { SearchInput } from "components/SearchInput";
import { ModalPageProps } from "components/ModalPageClients/types";
import { ShowWhenHavePermission } from "components/Permission";

import { UnityCard } from "components/UnityCard";

import * as S from "./styles";
import { searchDatalayer } from "utils/pushDataLayer";

type PageProps = {
  handleChooseEditModal: ModalPageProps;
};

export default function RegisteredUnities({
  handleChooseEditModal,
}: PageProps) {
  const [searchInput, setSearchInput] = useState("");
  const [actualUnities, setActualUnities] = useState<UnityDTO[]>([]);

  const { setSelectedUnity, redefineClientUnity, unities } = useEditClient();

  const theme = useTheme();

  function handleSearchCancel() {
    setSearchInput("");
    setActualUnities(unities);
  }

  useEffect(() => {
    setActualUnities(unities);
  }, [unities]);

  function handleSearch() {
    const searchedUnities = unities.filter((unity) => {
      if (
        unity.name
          .toLocaleLowerCase()
          .search(searchInput.toLocaleLowerCase()) >= 0 ||
        unity.street
          .toLocaleLowerCase()
          .search(searchInput.toLocaleLowerCase()) >= 0 ||
        unity.city
          .toLocaleLowerCase()
          .search(searchInput.toLocaleLowerCase()) >= 0 ||
        unity.uf.toLocaleLowerCase().search(searchInput.toLocaleLowerCase()) >=
          0
      ) {
        return unity;
      }
    });

    searchDatalayer({ search_term: searchInput, success: true });

    setActualUnities(searchedUnities);
  }

  useEffect(() => {
    if (searchInput.length === 0) {
      handleSearchCancel();
    }
    if (searchInput.length < 3) return;
    handleSearch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchInput]);

  return (
    <S.Container>
      <S.Content>
        <S.Top>
          <S.BackButton onClick={() => handleChooseEditModal("clientDetails")}>
            <FiChevronLeft size={16} color={theme.colors.text} />
          </S.BackButton>

          <p>Endereços Cadastrados ({unities.length})</p>
        </S.Top>
        <S.WrapperInput>
          <SearchInput
            placeholder="Procure pelo nome ou endereço"
            searchValue={searchInput}
            onChange={(e) => setSearchInput(e.target.value)}
            handleCancel={handleSearchCancel}
          />
        </S.WrapperInput>
        <ShowWhenHavePermission moduleHash="client-unity" actionHash="create">
          <Button
            onClick={() => {
              setSelectedUnity({} as UnityDTO);
              redefineClientUnity();
              handleChooseEditModal("unityForm");
            }}
          >
            Adicionar novo endereço
          </Button>
        </ShowWhenHavePermission>

        {actualUnities.length > 0 ? (
          actualUnities.map((unity, index) => (
            <UnityCard
              key={index}
              unity={unity}
              handleEditUnity={() => {
                setSelectedUnity(unity);
                handleChooseEditModal("unityDetail");
              }}
            />
          ))
        ) : (
          <S.EmptySearch>Nenhum endereço encontrado.</S.EmptySearch>
        )}
      </S.Content>
    </S.Container>
  );
}
