import React, { useCallback, useEffect, useState } from "react";
import { FiX } from "react-icons/fi";
import { useTheme } from "styled-components";

import {
  PromptPaymentProps,
  PaymentSelectedInstallmentProps,
  // PaymentIncomingProps,
  IndexPromptProps,
  IndexInstallmentProps,
} from "dtos/PaymentConditionsDTO";
import { useSelectedPaymentConditions } from "hooks/budget/selectedPaymentConditions";

import { useToast } from "hooks/toast";

import { Button } from "components/Button";
import { PromptPaymentConditions } from "./PromptPaymentConditions";
import { PaymentConditionsInstallments } from "./PaymentConditionsInstallments";

import * as S from "./styles";

type ModalEditPaymentConditionsProps = {
  handleToggleOpen: () => void;
};

export function ModalEditPaymentConditions({
  handleToggleOpen,
}: ModalEditPaymentConditionsProps) {
  const theme = useTheme();
  const { addToast } = useToast();

  const {
    selectedPromptPayment,
    selectedInstallmentPayment,
    selectedIncomingPayment,
    handleSetSelectedPromptPayment,
    handleSetSelectedInstallmentPayment,
    handleDeleteSelectedInstallmentPayment,
    // handleSetSelectedIncomingPayment,
  } = useSelectedPaymentConditions();

  const [promptPayment, setPromptPayment] = useState<PromptPaymentProps[]>([]);
  const [promptPaymentSelectItems, setPromptPaymentSelectItems] = useState<
    PromptPaymentProps[]
  >([]);

  const [installmentPayment, setInstallmentPayment] = useState<
    PaymentSelectedInstallmentProps[]
  >([]);

  const [installmentPaymentItems, setInstallmentPaymentItems] = useState<
    PaymentSelectedInstallmentProps[]
  >([]);

  // const [incomingPayment, setIncomingPayment] = useState<
  //   PaymentIncomingProps[]
  // >([]);

  // const [incomingPaymentItems, setIncomingPaymentItems] = useState<
  //   PaymentIncomingProps[]
  // >([])

  const [isLoading, setIsLoading] = useState(false);

  function paymentsNotSelected(
    type: "simple" | "discount" | "addition",
    oldPayment: PaymentSelectedInstallmentProps[]
  ) {
    return oldPayment.filter((payment) => payment.typeInstallment !== type);
  }

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    try {
      if (selectedPromptPayment.length > 0) {
        const promptPaymentAlredySelected = selectedPromptPayment.map(
          (promptPaymentItem) => ({
            index: promptPaymentItem.index,
            discount: promptPaymentItem.discount,
          })
        );

        setPromptPayment(promptPaymentAlredySelected);
        setPromptPaymentSelectItems(promptPaymentAlredySelected);
      }

      if (selectedInstallmentPayment.length > 0) {
        const installmentPaymentAlredySelected = selectedInstallmentPayment.map(
          (installmentPaymentItem) => ({
            typeInstallment: installmentPaymentItem.typeInstallment,
            numberInstallments: installmentPaymentItem.numberInstallments,
            discount: installmentPaymentItem.discount,
            addition: installmentPaymentItem.addition,
          })
        );

        setInstallmentPayment(installmentPaymentAlredySelected);
        setInstallmentPaymentItems(installmentPaymentAlredySelected);
      }

      // if (selectedIncomingPayment.length > 0) {
      //   const incomingPaymentAlredySelected = selectedIncomingPayment.map(
      //     incomingPaymentItem => ({
      //       index: incomingPaymentItem.index,
      //       amount: incomingPaymentItem.amount,
      //     }),
      //   )

      //   setIncomingPayment(incomingPaymentAlredySelected)
      //   setIncomingPaymentItems(incomingPaymentAlredySelected)
      // }
    } finally {
      setIsLoading(false);
    }
  }, [
    selectedPromptPayment,
    selectedInstallmentPayment,
    selectedIncomingPayment,
  ]);

  const handleSetPromptPaymentSelected = useCallback(
    ({ index, discount }: PromptPaymentProps) => {
      if (index === "promptPayment" || discount?.value === 0) {
        setPromptPayment((oldPromptPayment) => {
          const filterPromptPayment = oldPromptPayment.filter(
            (promptPaymentItem) =>
              promptPaymentItem.index === "promptPaymentWithDiscount"
          );

          return [...filterPromptPayment, { index, discount: null }];
        });
      }

      if (index === "promptPaymentWithDiscount") {
        setPromptPayment((oldPromptPayment) => {
          const filterPromptPayment = oldPromptPayment.filter(
            (promptPaymentItem) => promptPaymentItem.index === "promptPayment"
          );

          return [...filterPromptPayment, { index, discount }];
        });
      }
    },
    [promptPayment] // eslint-disable-line react-hooks/exhaustive-deps
  );

  const handleRemovePaymentSelected = useCallback(
    (index: IndexPromptProps) => {
      const filteredPromptPayment = promptPayment.filter(
        (promptPaymentItem) => promptPaymentItem.index !== index
      );
      setPromptPayment(filteredPromptPayment);
    },
    [promptPayment]
  );

  const handleSetInstallmentPaymentSelected = useCallback(
    ({
      typeInstallment,
      numberInstallments,
      discount,
      addition,
    }: PaymentSelectedInstallmentProps) => {
      if (typeInstallment === "simple") {
        setInstallmentPayment((oldInstallmentPayment) => {
          const filteredPayment = paymentsNotSelected(
            typeInstallment,
            oldInstallmentPayment
          );

          return [
            ...filteredPayment,
            {
              typeInstallment,
              numberInstallments,
              discount: null,
              addition: null,
            },
          ];
        });
      }

      if (typeInstallment === "discount") {
        setInstallmentPayment((oldInstallmentPayment) => {
          const filteredPayment = paymentsNotSelected(
            typeInstallment,
            oldInstallmentPayment
          );

          return [
            ...filteredPayment,
            {
              typeInstallment,
              numberInstallments,
              discount,
              addition: null,
            },
          ];
        });
      }

      if (typeInstallment === "addition") {
        setInstallmentPayment((oldInstallmentPayment) => {
          const filteredPayment = paymentsNotSelected(
            typeInstallment,
            oldInstallmentPayment
          );

          return [
            ...filteredPayment,
            {
              typeInstallment,
              numberInstallments,
              discount: null,
              addition,
            },
          ];
        });
      }
    },
    [installmentPayment] // eslint-disable-line react-hooks/exhaustive-deps
  );

  const handleRemovePaymentWithInstallmentsSelected = useCallback(
    (typeInstallment: IndexInstallmentProps) => {
      const filteredInstallmentPayment = installmentPayment.filter(
        (installmentPaymentItem) =>
          installmentPaymentItem.typeInstallment !== typeInstallment
      );

      setInstallmentPayment(filteredInstallmentPayment);
    },
    [installmentPayment]
  );

  // const handleSetIncomingPaymentSelected = useCallback(
  //   ({ index, amount }: PaymentIncomingProps) => {
  //     setIncomingPayment([
  //       {
  //         index,
  //         amount
  //       }
  //     ])
  //   },
  //   [incomingPayment]
  // )

  function promptPaymentHandler() {
    if (promptPayment.length > 1) {
      return setLatestPayment();
    }

    return handleSetSelectedPromptPayment(promptPayment);
  }

  function setLatestPayment() {
    const lastElement = promptPayment.length - 1;
    handleSetSelectedPromptPayment([promptPayment[lastElement]]);
  }

  function installmentsHandler() {
    if (showInstallmentsOnCard() === true) {
      handleSetSelectedInstallmentPayment(installmentPayment);
    } else {
      returnErrorInstallments();
    }
  }

  function showInstallmentsOnCard() {
    const installmentsArray = getArrayOfInstallments(installmentPayment);

    if (hasDuplicatedInstallments(installmentsArray)) {
      return false;
    }

    return true;
  }

  function getArrayOfInstallments(
    rawPromptPayment: PaymentSelectedInstallmentProps[]
  ) {
    const installments = rawPromptPayment.map((installment) => {
      return installment.numberInstallments;
    });

    return installments;
  }

  function hasDuplicatedInstallments(array: number[]) {
    return new Set(array).size !== array.length;
  }

  function returnErrorInstallments() {
    return addToast({
      title: "Condição de pagamento inválida!",
      description: "Crie uma condição de pagamento com diferentes parcelas",
      type: "info",
    });
  }

  function handleSavePaymentsConditions() {
    promptPaymentHandler();

    const installmentsExists =
      installmentPayment.length > 0 &&
      installmentPayment[0].numberInstallments !== 0;

    if (installmentsExists) {
      installmentsHandler();
    } else {
      handleDeleteSelectedInstallmentPayment();
    }

    handleToggleOpen();
    // handleSetSelectedIncomingPayment(incomingPayment);
  }
  return (
    <>
      <S.Top>
        <S.ButtonClose onClick={() => handleToggleOpen()}>
          <FiX size={10} color={theme.colors.text} />
        </S.ButtonClose>

        <div>
          <p>Condições de pagamento</p>
          <S.InfoPaymentsText>
            Os descontos e saldos são calculados automaticamente e apresentados
            no corpo do orçamento.
          </S.InfoPaymentsText>
        </div>
      </S.Top>

      <S.Content>
        <PromptPaymentConditions
          handlePaymentSelected={handleSetPromptPaymentSelected}
          handlePaymentRemove={handleRemovePaymentSelected}
          selectedItems={promptPaymentSelectItems}
        />

        <PaymentConditionsInstallments
          handlePaymentSelected={handleSetInstallmentPaymentSelected}
          handlePaymentRemove={handleRemovePaymentWithInstallmentsSelected}
          selectedItems={installmentPaymentItems}
        />

        {/* <PaymentConditionsInComing
          handlePaymentSelected={handleSetIncomingPaymentSelected}
        /> */}

        <S.WrapperButton>
          <Button
            loading={isLoading}
            onClick={() => handleSavePaymentsConditions()}
          >
            Adicionar condições de pagamento
          </Button>
        </S.WrapperButton>
      </S.Content>
    </>
  );
}
