import styled, { css } from "styled-components";

export const ModalContent = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  padding: 20px;
`;

export const RadarIcon = styled.img`
  width: 293px;
  height: 293px;
  align-self: center;
  object-fit: contain;
`;

export const TextContainer = styled.div`
  margin-top: 50px;
  color: white;
  margin-left: 20px;
  margin-bottom: 50px;
`;

export const BolderText = styled.div`
  font-size: 18px;
  line-height: 22px;
  font-weight: 700;
`;

export const BolderTextTitle = styled.div`
  ${({ theme }) => css`
    text-align: center;
    font-size: 18px;
    line-height: 22px;
    font-weight: 700;
    color: ${theme.colors.text_light_87};
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding-left: 50px;
  `}
`;

export const BackButton = styled.div`
  ${({ theme }) => css`
    margin-bottom: 50px;

    width: 100%;
    display: grid;
    grid-template-columns: 1fr 50px;
    justify-content: flex-end;
    place-items: center;
  `}
`;

export const MettersContainer = styled.div`
  display: flex;
  justify-self: center;
  align-self: center;
  justify-content: center;
  align-items: center;
  margin-bottom: 30px;
  margin-top: 10px;
`;

export const Text = styled.div`
  font-weight: 300;
  font-size: 14px;
  line-height: 17px;
  margin-top: 10px;
`;

export const MarkerLine = styled.div`
  border: 0.3px solid #353535;
  height: 5px;
  width: 0;
  margin-bottom: 5px;
`;
