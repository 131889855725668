import styled, { css } from "styled-components";

export const Container = styled.div`
  ${({ theme }) => css`
    width: 100%;
    background-color: ${theme.colors.background_light};
    border-radius: 10px;
    margin-bottom: 15px;
    display: flex;
    justify-content: space-between;
  `}
`;

export const Content = styled.div`
  width: 80%;
  padding: 15px;
`;

export const ChecklistText = styled.span`
  ${({ theme }) => css`
    display: block;
    margin-bottom: 4px;
    font-size: ${theme.font.sizes.xsmall};
    color: ${theme.colors.text};

    span {
      color: ${theme.colors.title};
    }
  `}
`;

export const Separator = styled.div`
  ${({ theme }) => css`
    width: 100%;
    height: 0.2px;
    opacity: 0.2;
    background-color: ${theme.colors.text};
    margin-bottom: 12px;
  `}
`;

export const WrapperActions = styled.div`
  ${({ theme }) => css`
    min-width: 47px;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    background-color: ${theme.colors.background_light};

    display: flex;
    flex-direction: column;
    justify-content: space-between;
  `}
`;
