import React from "react";
import logo from "assets/icons/logo-color.svg";

import * as S from "./styles";
const NotFound: React.FC = () => {
  return (
    <S.Container>
      <img src={logo} alt="logo profiz" />
      <h1>Error 404</h1>
      <p>Recurso não encontrado.</p>
    </S.Container>
  );
};
export { NotFound };
