import styled, { css } from "styled-components";

type TContainerCursor = {
  defaultCursor?: boolean;
};

export const Container = styled.div<TContainerCursor>`
  ${({ theme, defaultCursor }) => css`
    background: ${theme.colors.background_modal};
    border-radius: 10px;
    flex: 1;
    width: 470px;
    margin-top: 5px;
    margin-bottom: 10px;
    cursor: ${defaultCursor ? "default" : "pointer"};
  `}
`;

export const WrapperButton = styled.p`
  padding: 8px;
  align-items: center;
  text-align: left;
`;

export const Title = styled.span`
  ${({ theme }) => css`
    font-size: 14px;
    font-family: ${theme.font.normal};
    color: ${theme.colors.financial_title};
    line-height: 45px;
    margin-left: 10px;
    text-align: left;
  `}
`;

export const Icon = styled.span`
  ${({ theme }) => css`
    font-weight: bold;
    font-size: 20px;
    color: ${theme.colors.primary};
    margin-right: 5px;
    margin-top: 10px;
    float: right;
  `}
`;

export const WrapperElements = styled.div`
  padding-left: 20px;
  padding-bottom: 10px;
  position: relative;
  align-item: justify;
`;
