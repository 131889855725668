import styled, { css } from "styled-components";

export const Card = styled.div`
  ${({ theme }) => css`
    background-color: ${theme.colors.menu_detail};
    border-radius: 10px;
    margin-top: 20px;
    padding: 20px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  `}
`;

export const TextContainer = styled.div``;

export const TitleWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const Title = styled.div`
  ${({ theme }) => css`
    color: ${theme.colors.financial_title};
    font-weight: bold;
    font-family: ${theme.font.family};
    font-size: 12px;
  `}
`;

export const AfterTitle = styled.div`
  ${({ theme }) => css`
    color: ${theme.colors.primary};
    margin-left: 10px;
    font-size: 11px;
  `}
`;

export const CreatedAt = styled.div`
  ${({ theme }) => css`
    font-size: 12px;
    color: ${theme.colors.title};
  `}
`;

export const AddressText = styled.div`
  ${({ theme }) => css`
    font-size: 12px;
    color: ${theme.colors.financial_text};
    margin-top: 20px;
  `}
`;

export const GreenCircle = styled.div`
  ${({ theme }) => css`
    background-color: ${theme.colors.primary};
    width: 7px;
    height: 7px;
    border-radius: 7px;
    margin-right: 10px;
    margin-top: 2px;
  `}
`;

export const EmptySearch = styled.p`
  ${({ theme }) => css`
    color: ${theme.colors.financial_title};
    font-weight: bold;
    font-family: ${theme.font.family};
    text-align: center;
    margin-top: 80px;
  `}
`;

export const EditUnity = styled.button`
  ${({ theme }) => css`
    height: 40px;
    width: 40px;

    display: flex;
    align-items: center;
    transition: opacity 0.2s;
    border: 1px solid ${theme.colors.primary};
    border-radius: 15px;
    padding: 10px;

    &:hover {
      opacity: 0.7;
    }
    img {
      width: 16px;
      height: 16px;
    }
  `}
`;
