import React, { useEffect, useState } from "react";
import { FiChevronLeft } from "react-icons/fi";
import { useTheme } from "styled-components";
import * as Yup from "yup";

import { Input } from "components/Input";
import { Button } from "components/Button";
import { ButtonCheckbox } from "components/ButtonCheckbox";

import { useToast } from "hooks/toast";
import { useEditClient } from "hooks/editClient";
import getValidationErrors from "utils/getValidationErrors";
import { useClient } from "hooks/budget/client";

import { ClientProps } from "dtos/ClientDTO";

import { ModalName } from "../types";

import { isEqual } from "lodash";
import { api } from "services/api";

import { ModalRight } from "components/ModalRight";
import { UnityForm } from "components/UnityForm";

import * as S from "./styles";
import { useConfirmModalClient } from "hooks/budget/confirmModalClient";
import { AlertModalCheckbox } from "components/AlertModalCheckbox";

type DocumentProps = {
  document: "cpf" | "cnpj";
  isChecked: boolean;
};

type Errors = {
  [key: string]: string;
};

type EditClientProps = {
  handleSwapListClients?: (modal: ModalName) => void;
  isFromList?: boolean;
  module?: string;
  toggleModal?: () => void;
};

export function FormEditClient({
  handleSwapListClients,
  isFromList,
  toggleModal,
  module,
}: EditClientProps) {
  const theme = useTheme();
  const { addToast } = useToast();
  const { editedClient, handleSetEditClient, loadUnities } = useEditClient();
  const { handleSetClient, client, getClient } = useClient();
  const { handleChangeModalProps, setConfirmModalProps, setHasChangeds } =
    useConfirmModalClient();

  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");

  const [openModal, setOpenModal] = useState(false);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [isUnityDefault, setIsUnityDefault] = useState(false);

  // eslint-disable-next-line camelcase
  const [document_cpf, setDocumentCpf] = useState("");
  // eslint-disable-next-line camelcase
  const [document_cnpj, setDocumentCnpj] = useState("");
  const [typeCheckbox, setTypeCheckbox] = useState<DocumentProps[]>([
    {
      document: "cpf",
      isChecked: true,
    },
    {
      document: "cnpj",
      isChecked: false,
    },
  ]);

  const [loadingButton, setLoadingButton] = useState(false);

  const [hasError, setHasError] = useState<Errors>({} as Errors);

  const [showModalNewUnity, setShowModalNewUnity] = useState(false);

  const moduleValidation = module !== "budget" && module !== "serviceOrder";

  useEffect(() => {
    if (editedClient) {
      setName(editedClient.name);
      setPhone(editedClient.phone);
      setEmail(editedClient.email!);
      if (!module) {
        loadUnities();
      }

      if (editedClient.cpf_cnpj?.length === 14) {
        setDocumentCpf(editedClient.cpf_cnpj);
        handleToggleDocument("cpf");
      } else if (editedClient.cpf_cnpj?.length === 18) {
        setDocumentCnpj(editedClient.cpf_cnpj);
        handleToggleDocument("cnpj");
      }
    }
  }, [editedClient, module]); // eslint-disable-line react-hooks/exhaustive-deps

  const [cpfSelected, cnpjSelected] = typeCheckbox.map(
    ({ isChecked }) => isChecked
  );

  function handleToggleDocument(type: "cpf" | "cnpj") {
    const documentType = typeCheckbox.map((item) => {
      if (item.document === type) {
        return {
          ...item,
          isChecked: true,
        };
      }

      if (item.document !== type) {
        return {
          ...item,
          isChecked: false,
        };
      }

      return item;
    });

    setTypeCheckbox(documentType);
  }

  async function handleUpdateDataClient() {
    setLoadingButton(true);
    try {
      const regexPhone =
        /\(?\b([0-9]{2,3}|0((x|[0-9]){2,3}[0-9]{2}))\)?\s*[0-9]{4,5}[- ]*[0-9]{4}\b/;
      const documentRegex =
        /(^\d{3}\.\d{3}\.\d{3}-\d{2}$)|(^\d{2}\.\d{3}\.\d{3}\/\d{4}-\d{2}$)/;

      const schema = () =>
        Yup.object().shape({
          name: Yup.string().required("Nome é obrigatório"),
          email: Yup.string().email("Digite um e-mail válido"),
          phone: Yup.string()
            .matches(new RegExp(regexPhone), "Digite um telefone válido")
            .nullable(),
          document_cpf: Yup.string()
            .matches(new RegExp(documentRegex), "Digite um CPF válido")
            .nullable(),
          document_cnpj: Yup.string()
            .matches(new RegExp(documentRegex), "Digite um CNPJ válido")
            .nullable(),
        });

      await schema().validate(
        {
          name,
          email,
          phone: phone.length > 0 ? phone : null,
          document_cpf: document_cpf.length > 0 ? document_cpf : null,
          document_cnpj: document_cnpj.length > 0 ? document_cnpj : null,
        },
        { abortEarly: false }
      );
      setHasError({});

      const clientData: ClientProps = {
        id: editedClient.id,
        name,
        phone,
        email,
        // eslint-disable-next-line camelcase
        cpf_cnpj: cpfSelected ? document_cpf : document_cnpj,
      };

      const { data } = await api.put(`/clients/${editedClient.id}`, clientData);

      if (isFromList) {
        if (Number(client?.id) === Number(editedClient?.id)) {
          handleSetClient({ ...data, id: editedClient.id });
        }
        if (toggleModal) {
          toggleModal();
        }
      }

      if (handleSwapListClients) {
        handleSwapListClients("clientDetails");
      }
      handleSetEditClient(data);

      addToast({
        title: "Certo!",
        description: "Cliente atualizado com sucesso",
        type: "success",
      });
    } catch (error) {
      if (error instanceof Yup.ValidationError) {
        const errors = getValidationErrors(error);
        setHasError(errors);

        addToast({
          title: "Ops...",
          description: "Preencha todos os campos obrigatórios",
          type: "error",
        });

        return;
      }

      addToast({
        title: "Ops...",
        description: "Erro ao atualizar os dados do cliente",
        type: "error",
      });
    } finally {
      setLoadingButton(false);
    }
  }

  function handleConfirm(): void {
    setConfirmModalProps(false);
  }

  function handleCloseModal(): void {
    setConfirmModalProps(false);
    if (handleSwapListClients) handleSwapListClients("clientDetails");
  }

  const validationLengthCpfcnpj =
    editedClient?.cpf_cnpj?.length === 14
      ? true
      : editedClient?.cpf_cnpj?.length === 0
      ? true
      : !editedClient?.cpf_cnpj
      ? true
      : false;

  const clientObj = {
    ...editedClient,
    id: editedClient.id,
    name: name,
    phone: phone,
    email: email,
    cpf_cnpj: cpfSelected ? document_cpf : document_cnpj,
    typeCheckbox: [
      { document: "cpf", isChecked: validationLengthCpfcnpj },
      { document: "cnpj", isChecked: editedClient?.cpf_cnpj?.length === 18 },
    ],
  };

  const hasChangeCliente = isEqual(clientObj, {
    ...editedClient,
    typeCheckbox,
  });

  useEffect(() => {
    setHasChangeds(hasChangeCliente);
  }, [setHasChangeds, hasChangeCliente]);

  function handleBackButton() {
    if (isFromList && toggleModal) {
      toggleModal();
    } else {
      if (handleSwapListClients) {
        if (!hasChangeCliente) {
          handleChangeModalProps({
            value: true,
            handleConfirm,
            handleCloseModal: () => {
              handleCloseModal();
            },
          });
          return;
        }

        handleSwapListClients("clientDetails");
      }
    }
  }

  async function saveClientInBudget() {
    const clientData: ClientProps = {
      id: editedClient.id,
      name,
      phone,
      email,
      // eslint-disable-next-line camelcase
      cpf_cnpj: cpfSelected ? document_cpf : document_cnpj,
    };

    handleSetClient(clientData);
    setOpenModal(false);
    handleBackButton();

    addToast({
      title: "Certo!",
      description: `Cliente salvo ${
        module === "serviceOrder" ? "na Ordem de serviço" : "no Orçamento"
      }`,
      type: "success",
    });
  }

  async function checkingIfTheClientExists() {
    const hasClient = await getClient(editedClient.id);

    if (moduleValidation || !client.id) {
      handleUpdateDataClient();
      return;
    }

    if (!!hasClient) {
      setOpenModal(true);
    } else {
      saveClientInBudget();
    }
  }

  async function handleTypeSave(type: string) {
    if (type === "first") {
      await saveClientInBudget();
    } else if (type === "second") {
      await handleUpdateDataClient();
    }
  }

  return (
    <>
      <AlertModalCheckbox
        isVisible={openModal}
        labelConfirm={`Somente  ${
          module === "serviceOrder"
            ? "nesta Ordem de serviço"
            : "neste Orçamento"
        }`}
        labelCancel={"Cadastro geral deste Cliente"}
        description={
          "Você quer que a alteração dos dados do Cliente seja aplicada:"
        }
        action="choose"
        handleConfirm={(type) => handleTypeSave(type)}
        onCloseModal={() => setOpenModal(false)}
      />
      <S.Top>
        <S.BackButton onClick={handleBackButton}>
          <FiChevronLeft size={16} color={theme.colors.text} />
        </S.BackButton>

        <p>Editar cliente</p>
      </S.Top>

      <S.Content>
        <S.WrapperInput>
          <Input
            name="Cliente*"
            placeholder="Nome do cliente"
            value={name}
            onChange={(e) => setName(e.target.value.trimStart())}
            hasError={hasError.name}
            onFocusClearError={() => setHasError({ ...hasError, name: "" })}
          />
        </S.WrapperInput>

        <S.WrapperInput>
          <Input
            name="E-mail"
            placeholder="E-mail do cliente"
            value={email}
            onChange={(e) => setEmail(e.target.value.trimStart())}
            hasError={hasError.email}
            onFocusClearError={() => setHasError({ ...hasError, email: "" })}
          />
        </S.WrapperInput>

        <S.WrapperInput>
          <Input
            name="Telefone"
            placeholder="Ex: (11) 99999-9999"
            maskType="phone"
            value={phone}
            hasError={hasError.phone}
            onChange={(e) => setPhone(e.target.value)}
          />
        </S.WrapperInput>

        <S.WrapperCheckbox>
          <ButtonCheckbox
            label="Selecione uma opção:"
            placeholder="Pessoa física"
            isChecked={cpfSelected}
            onClick={() => handleToggleDocument("cpf")}
          />
          <ButtonCheckbox
            placeholder="Pessoa jurídica"
            isChecked={cnpjSelected}
            onClick={() => handleToggleDocument("cnpj")}
          />
        </S.WrapperCheckbox>

        {cpfSelected && (
          <S.WrapperInput>
            <Input
              name="CPF"
              placeholder="999.999.999-99"
              maskType="cpf"
              // eslint-disable-next-line camelcase
              value={document_cpf}
              hasError={hasError.document_cpf}
              onChange={(e) => setDocumentCpf(e.target.value)}
            />
          </S.WrapperInput>
        )}

        {cnpjSelected && (
          <S.WrapperInput>
            <Input
              name="CNPJ"
              placeholder="99.999.999/9999-99"
              maskType="cnpj"
              // eslint-disable-next-line camelcase
              value={document_cnpj}
              hasError={hasError.document_cnpj}
              onChange={(e) => setDocumentCnpj(e.target.value)}
            />
          </S.WrapperInput>
        )}

        <S.WrapperButton style={{ marginTop: 10 }}>
          <Button loading={loadingButton} onClick={checkingIfTheClientExists}>
            Atualizar dados do cliente
          </Button>
        </S.WrapperButton>

        <ModalRight
          isOpen={showModalNewUnity}
          handleToggleOpen={() => setShowModalNewUnity(false)}
        >
          <UnityForm
            defaultUnity={isUnityDefault}
            handleToggleOpen={() => setShowModalNewUnity(false)}
          />
        </ModalRight>
      </S.Content>
    </>
  );
}
