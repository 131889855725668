import React, { createContext, useContext, useState } from 'react';
import { ProviderProps } from 'hooks';

type ObservationContextData = {
  text: string;
  handleSetText: (textValue: string) => void;
  clearObservationData: () => void;
};

const ObservationContext = createContext<ObservationContextData>(
  {} as ObservationContextData,
);

export function useObservation(): ObservationContextData {
  const context = useContext(ObservationContext);

  if (!context) {
    throw Error('useObservation must be used within a ContactProvider');
  }

  return context;
}

export const ObservationProvider = ({ children }: ProviderProps) => {
  const [text, setText] = useState('');

  function handleSetText(textValue: string) {
    setText(textValue);
  }

  function clearObservationData() {
    setText('');
  }

  return (
    <ObservationContext.Provider
      value={{ text, handleSetText, clearObservationData }}
    >
      {children}
    </ObservationContext.Provider>
  );
};
