import { MaterialFromApiDTO } from 'dtos/MaterialFromApiDTO'
import addMaskMoney from './addMaskMoney'
import { SelectedMaterialProps } from 'hooks/budget/selectedMaterials'

export function mappedMaterialsFromApi(materials: MaterialFromApiDTO[]):SelectedMaterialProps[] {
  return materials.map((currentMaterial) => {
    if (!currentMaterial.material) return {} as SelectedMaterialProps

    const total = currentMaterial.material.price * currentMaterial.quantity

    return {
      id: currentMaterial.material?.id,
      categoryId: currentMaterial.material.categoryId,
      type: {
        id: currentMaterial.material.type?.id,
        name: currentMaterial.material.type?.name
      },
      item: {
        id: currentMaterial.material.item?.id,
        name: currentMaterial.material.item?.name
      },
      price: currentMaterial.material.price,
      quantity: currentMaterial.quantity,
      formattedPrice: addMaskMoney(currentMaterial.material.price),
      total,
      formattedTotal: addMaskMoney(total),
      checked: true
    }
  })
}
