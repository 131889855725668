import styled, { css } from "styled-components";
import { AiOutlineClose } from "react-icons/ai";
import { lighten } from "polished";

type DropdownProps = {
  isOpen: boolean;
  hasError: boolean;
  hasValue: boolean;
};

export const Container = styled.div`
  width: 100%;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-end;
`;

export const Content = styled.div`
  ${({ theme }) => css`
    flex: 1;
    position: relative;

    p {
      font-size: ${theme.font.sizes.xsmall};
      color: ${theme.colors.title};
      text-align: left;
      margin-bottom: 5px;
    }
  `}
`;
export const Dropdown = styled.button<DropdownProps>`
  ${({ theme, isOpen, hasError, hasValue, disabled }) => css`
    width: 100%;
    height: 50px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    background-color: ${theme.colors.menu_background};
    color: ${hasValue ? theme.colors.text_extra_light : theme.colors.text};

    border: 1px solid
      ${hasError ? theme.colors.error : theme.colors.menu_background};
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    border-bottom-left-radius: ${isOpen ? 0 : "10px"};
    border-bottom-right-radius: ${isOpen ? 0 : "10px"};
    cursor: ${disabled && "not-allowed"};
  `}
`;

export const CardBrand = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const CardBrandText = styled.div`
  ${({ theme }) => css`
    margin-left: 10px;
    font-family: ${theme.font.family};
    color: ${theme.colors.financial_title};
    font-size: 12px;
  `}
`;

export const OptionsContainer = styled.div`
  ${({ theme }) => css`
    width: 100%;
    max-height: 200px;
    background-color: ${theme.colors.menu_background};
    padding-top: 10px;
    display: flex;
    flex-direction: column;
    overflow: auto;
    position: absolute;
    z-index: ${theme.layers.alwaysOnTop};
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;

    &::-webkit-scrollbar {
      width: 10px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: ${theme.colors.background_light};
      border-radius: 10px;
    }
  `}
`;

export const WrapperChildren = styled.div`
  padding-left: 16px;
  padding-right: 16px;
  padding-top: 10px;
  border-top: 0.3px solid rgba(155, 155, 155, 0.2);
`;

export const ButtonsWrapper = styled.div`
  ${({ theme }) => css`
    z-index: 1000;
    position: absolute;
    right: 8px;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    transition: 0.3s ease all;
  `};
`;

export const Buttons = styled.button`
  ${({ theme }) => css`
    height: 30px;
    width: 30px;
    border-radius: 10px;
    margin-left: 5px;
    background-color: ${theme.colors.background_light};
    display: flex;
    justify-content: space-around;
    align-items: center;
  `}
`;

export const MiddleLine = styled.div`
  border-right: 0.1px solid grey;
  height: 30px;
`;

export const OptionButton = styled.button`
  ${({ theme }) => css`
    min-height: 45px;
    padding-left: 16px;
    color: ${theme.colors.text};
    border-top: 0.3px solid rgba(155, 155, 155, 0.2);
    text-align: left;
    padding-bottom: 5px;
    transition: background 0.2s;
    width: 100%;

    &:hover {
      background: ${lighten(0.2, theme.colors.background_light)};
    }
  `}
`;

export const EditingWrapper = styled.div`
  ${({ theme }) => css`
    min-height: 45px;
    padding-left: 16px;
    color: ${theme.colors.text};
    border-top: 0.3px solid rgba(155, 155, 155, 0.2);
    text-align: left;
    padding-bottom: 5px;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
  `}
`;

export const ShowInputButton = styled.button`
  ${({ theme }) => css`
    padding: 10px 12px 20px;
    background-color: transparent;
    text-align: left;
    color: ${theme.colors.primary};
    border-top: 0.3px solid rgba(155, 155, 155, 0.2);

    &:hover {
      text-decoration: underline;
    }
  `}
`;

export const ErrorText = styled.span`
  ${({ theme }) => css`
    font-size: 12px;
    color: ${theme.colors.error};
    padding-top: 5px;
    display: flex;
  `}
`;

export const ButtonClearSelectedItem = styled.button`
  width: 40px;
  height: 50px;
  justify-content: center;
  align-items: center;
`;

export const ClearIcon = styled(AiOutlineClose)`
  ${({ theme }) => css`
    font-size: 15px;
    color: ${theme.colors.text};
  `}
`;
