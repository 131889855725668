import axios from "axios";
import { LoadingProfiz } from "components/LoadingProfiz";
import { PmocListingProps, TInfo } from "dtos/PmocDTO";
import { useToast } from "hooks/toast";
import { useEffect, useState } from "react";
import { api } from "services/api";

import * as S from "./styles";
import { TablePagination } from "components/TablePagination";
import { PmocItemComponent } from "../PmocItemComponent";
import { useHistory } from "react-router";

type ResponseProps = {
  data: {
    info: TInfo;
    pmocs: PmocListingProps[];
  };
};

type ConcludedPMOCProps = {
  search?: string;
  setSearchLoading: (value: boolean) => void;
};

export function ConcludedPMOC({
  search,
  setSearchLoading,
}: ConcludedPMOCProps) {
  const { addToast } = useToast();
  const [pmocItens, setPmocItens] = useState<PmocListingProps[]>(
    [] as PmocListingProps[]
  );
  const [isLoading, setIsLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [offset, setOffset] = useState(0);
  const [totalItems, setTotalItems] = useState(0);
  const defaultLimit = 20;

  const history = useHistory();

  const getPmocList = async () => {
    try {
      setIsLoading(true);
      const params =
        search && search.length > 0
          ? {
              offset: offset,
              limit: defaultLimit,
              search: search,
              status: "concluded",
              order: "DESC",
            }
          : {
              offset: offset,
              limit: defaultLimit,
              status: "concluded",
              order: "DESC",
            };

      const { data }: ResponseProps = await api.get("/pmoc", { params });

      const { pmocs, info } = data;

      setTotalItems(info?.total || 0);
      setPmocItens(pmocs);
    } catch (error) {
      addToast({
        title: "Ops!!",
        description:
          axios.isAxiosError(error) && error.response?.data.error
            ? error.response.data.error
            : "Não foi possível a lista de pmocs.",
        type: "error",
      });
    } finally {
      setIsLoading(false);
      setSearchLoading(false);
    }
  };

  useEffect(() => {
    getPmocList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [offset, search]);

  function handlePageChange(pageNumber: number, itemsPerPage: number) {
    setCurrentPage(pageNumber);
    setOffset((pageNumber - 1) * itemsPerPage);
  }

  return (
    <>
      {isLoading && <LoadingProfiz isVisible={isLoading} />}
      <div>
        {pmocItens.length > 0 ? (
          pmocItens.map((pmoc) => {
            return (
              <PmocItemComponent
                pmoc={pmoc}
                handleScreenRedirector={() => {
                  history.push(`/pmoc/concluded/${pmoc.id}`);
                }}
              />
            );
          })
        ) : (
          <S.EmptyList>
            <p>
              Você ainda não criou nenhum serviço de PMOC,
              <br /> clique em
              <strong> "Criar novo PMOC"</strong> para começar.
            </p>
          </S.EmptyList>
        )}
      </div>

      <S.ContainerPagination>
        <TablePagination
          rowsLength={totalItems}
          rowsPerPage={defaultLimit}
          rowsPage={currentPage}
          handlePageChange={(pageNumber, itemsPerPage) =>
            handlePageChange(pageNumber, itemsPerPage)
          }
        />
      </S.ContainerPagination>
    </>
  );
}
