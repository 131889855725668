import React, { createContext, useContext, useState } from "react";
import { api } from "services/api";

import { AddressProps, ClientProps, UnityDTO } from "../../dtos/ClientDTO";
import axios from "axios";
import { ProviderProps } from "hooks";

type ClientContextData = {
  client: ClientProps;
  handleSetClient: (client: ClientProps) => void;
  handleSetAddress: (address: AddressProps) => void;
  handleSetComingFromOpportunity: (value: boolean) => void;
  comingFromOpportunity: boolean;
  handleSetComingFromOSOpportunity: (value: boolean) => void;
  comingFromOSOpportunity: boolean;
  address?: AddressProps;
  clearClientData: () => void;
  hasClient: boolean;
  hasAddress: boolean;
  opportunity_id: number;
  handleSetOpportunityId: (id: number) => void;
  getClient(clientId: number): Promise<ClientProps | boolean | undefined>;

  hasUnity: boolean;
  handleSetHasUnityClient: (state: boolean) => void;
  unity: UnityDTO;
  handleSetUnityClient: (unity: UnityDTO) => void;
  clearUnityData: () => void;
};

const ClientContext = createContext<ClientContextData>({} as ClientContextData);

export function useClient(): ClientContextData {
  const context = useContext(ClientContext);

  if (!context) {
    throw Error("useClient must be used within a ClientProvider");
  }

  return context;
}

export const ClientProvider = ({ children }: ProviderProps) => {
  const [client, setClient] = useState({} as ClientProps);
  const [hasClient, setHasClient] = useState(false);
  const [address, setAddress] = useState({} as AddressProps);
  const [hasAddress, setHasAddress] = useState(false);
  const [comingFromOpportunity, setComingFromOpportunity] = useState(false);
  const [opportunityId, setOpportunityId] = useState(0);
  const [comingFromOSOpportunity, setComingFromOSOpportunity] = useState(false);

  const [hasUnity, setHasUnity] = useState(false);
  const [unity, setUnity] = useState({} as UnityDTO);

  async function getClient(
    id: number
  ): Promise<ClientProps | boolean | undefined> {
    try {
      const { data } = await api.get<ClientProps>(`/clients/${id}`);

      return data;
    } catch (err) {
      const responseError =
        axios.isAxiosError(err) &&
        (err.response?.status === 500 || err.response?.status === 404);

      if (responseError) {
        return false;
      }
    }
  }

  function handleSetUnityClient(unity: UnityDTO) {
    if (unity?.id?.toString() === "0") {
      setUnity({} as UnityDTO);
      setHasUnity(false);
      return;
    }
    setUnity(unity);
    setHasUnity(true);
  }

  function handleSetHasUnityClient(state: boolean) {
    setHasUnity(state);
  }

  function clearUnityData() {
    setHasUnity(false);
    setUnity({} as UnityDTO);
  }

  function handleSetComingFromOpportunity(value: boolean) {
    setComingFromOpportunity(value);
  }

  function handleSetOpportunityId(id: number) {
    setOpportunityId(id);
  }

  function handleSetComingFromOSOpportunity(value: boolean) {
    setComingFromOSOpportunity(value);
  }

  function handleSetClient(clientValue: ClientProps) {
    if (clientValue.id === 0) {
      setClient({} as ClientProps);
      setHasClient(false);
      return;
    }
    setClient(clientValue);
    setHasClient(true);

    if (clientValue.address?.postalCode !== "") {
      setHasAddress(true);
    }
  }

  function handleSetAddress(address: AddressProps) {
    setAddress(address);
    setHasAddress(true);
  }

  function clearClientData() {
    setClient({} as ClientProps);
    setHasClient(false);
    setHasAddress(false);
    setAddress({} as AddressProps);
    setHasUnity(false);
    setUnity({} as UnityDTO);
  }

  return (
    <ClientContext.Provider
      value={{
        client,
        getClient,
        handleSetClient,
        handleSetAddress,
        address,
        clearClientData,
        comingFromOpportunity,
        handleSetComingFromOpportunity,
        hasClient,
        hasAddress,
        opportunity_id: opportunityId,
        handleSetOpportunityId,
        hasUnity,
        handleSetHasUnityClient,
        unity,
        handleSetUnityClient,
        clearUnityData,
        handleSetComingFromOSOpportunity,
        comingFromOSOpportunity,
      }}
    >
      {children}
    </ClientContext.Provider>
  );
};
