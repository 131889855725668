import { useTheme } from "styled-components";
import { FiX } from "react-icons/fi";

import loadingImg from "assets/loading.svg";

import * as S from "./styles";

type PhotoProps = {
  url: string;
  loading?: boolean;
  onClick?: () => void;
  onDelete?: () => void;
};

export function ServiceRegisterPhoto({
  url,
  loading,
  onClick,
  onDelete,
}: PhotoProps) {
  const theme = useTheme();

  return (
    <S.Container>
      {loading && (
        <S.Overlay>
          <img src={loadingImg} alt="Carregando..." />
        </S.Overlay>
      )}

      <S.Photo style={{cursor: "pointer"}} src={url} onClick={onClick} />

      {!loading && onDelete && (
        <S.ButtonDelete onClick={onDelete}>
          <FiX size={13} color={theme.colors.title} />
        </S.ButtonDelete>
      )}
    </S.Container>
  );
}
