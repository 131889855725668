import styled, { css } from 'styled-components'

export const Container = styled.div`
  margin-top: 24px;
`

export const Label = styled.p`
  ${({ theme }) => css`
    font-size: ${theme.font.sizes.xsmall};
    margin-bottom: 5px;
    text-transform: capitalize;
    color: ${theme.colors.title};
  `}
`

export const Content = styled.div`
  margin-top: 15px;
`
