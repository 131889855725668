import styled, { css } from "styled-components";

export const Container = styled.div`
  width: 100%;
`;

export const Header = styled.header`
  ${({ theme }) => css`
    display: flex;
    padding: 30px;

    h1 {
      display: flex;
      font-size: ${theme.font.sizes.large};
      color: ${theme.colors.title};
      font-weight: ${theme.font.bold};
      margin-top: 30px;
    }
  `}
`;

export const Content = styled.div`
  align-items: center;
  padding-left: 30px;
`;

export const Option = styled.div`
  ${({ theme }) => css`
    display: flex;
    width: 470px;
    height: 100px;
    background: ${theme.colors.background_modal};
    flex-direction: row;
    border-radius: 10px;
    padding: 15px;
    margin-bottom: 15px;
    cursor: pointer;
  `}
`;

export const Text = styled.div`
  padding-left: 15px;
`;

export const Title = styled.p`
  ${({ theme }) => css`
    font-size: 14px;
    font-family: ${theme.font.normal};
    color: ${theme.colors.text_extra_light};
    letter-spacing: 0.31px;
    line-height: 20px;
    margin-bottom: 5px;
    text-align: left;
  `}
`;

export const SubTitle = styled.p`
  ${({ theme }) => css`
    font-size: 12px;
    font-family: ${theme.font.normal};
    color: ${theme.colors.financial_text};
    letter-spacing: 0.26px;
    line-height: 20px;
    text-align: left;
  `}
`;

export const TourDescription = styled.p`
  ${({ theme }) => css`
    font-family: ${theme.font.family};
    font-weight: 400;
    font-size: 14px;
    color: rgba(255, 255, 255, 0.87);
    line-height: 17px;
    margin-top: 30px;
  `};
`;
