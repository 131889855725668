import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import Rating from "react-rating";

import { Button } from "components/Button";
import { CommentsTextArea } from "components/CommentsTextArea";
import { switchTextRating } from "utils/switchTextRating";

import profizLogo from "assets/icons/logo-color.svg";
import emptyStarImg from "assets/icons/empty-star.svg";
import fillStarImg from "assets/icons/fill-star.svg";

import apiv2 from "services/apiv2";
import { useToast } from "hooks/toast";
import { listBadOptions, listGoodOptions } from "./optionsButton";

import * as S from "./styles";

type ButtonSelected = {
  id: number;
  name: string;
  active: boolean;
};

export function RatingServiceOrder() {
  const { addToast } = useToast();
  const history = useHistory();
  const hash = history.location.pathname.split("/")[2];

  const [buttonsSelected, setButtonsSelected] = useState<ButtonSelected[]>([]);

  const [ratingSelected, setRatingSelected] = useState(0);
  const [ratingText, setRatingText] = useState("");
  const [comments, setComments] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (ratingSelected > 3) {
      setButtonsSelected(listGoodOptions);
      return;
    }
    setButtonsSelected(listBadOptions);
  }, [ratingSelected]);

  function handleHoverStars(value: number) {
    if (ratingSelected > 0) return;

    const textValue = switchTextRating(value);
    setRatingText(textValue);

    if (value === undefined) {
      setRatingText("");
    }
  }

  function handleSelectRating(value: number) {
    setRatingSelected(value);

    const textValue = switchTextRating(value);
    setRatingText(textValue);
  }

  function handleSelectButton(buttonId: number) {
    const selectButtons = buttonsSelected.map((item) => {
      if (item.id === buttonId) {
        return { ...item, active: !item.active };
      }
      return { ...item };
    });

    setButtonsSelected(selectButtons);
  }

  async function handleSubmit() {
    try {
      setIsLoading(true);

      const filterButtonsSelect = buttonsSelected.filter(
        (button) => button.active
      );
      const mappedButtons = filterButtonsSelect.map((button) => button.name);

      const ratingFormData = {
        executed: true,
        observations: comments,
        stars: ratingSelected,
        tags: mappedButtons,
        visible: true,
      };

      await apiv2.put(
        `/budgets/service-order/research/${hash}`,
        ratingFormData
      );
      history.push(`/research-form/${hash}/success`);
    } catch (error) {
      addToast({
        title: "Ops...",
        description: "Ocorreu um erro ao enviar sua avaliação.",
        type: "error",
      });
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <S.Container>
      <S.Main isSelectedStars={ratingSelected > 0}>
        <S.LogoProfiz src={profizLogo} alt="logo da profiz" />

        <div>
          <S.WrapperText>
            <S.Title>Avalie sua experiência</S.Title>
            <S.Text>
              Deixando sua nota, você ajuda na qualificação do profissional e
              melhora o rankeamento da plataforma.
            </S.Text>
          </S.WrapperText>

          <S.WrapperRating>
            <Rating
              quiet
              emptySymbol={<S.Star src={emptyStarImg} />}
              fullSymbol={<S.Star src={fillStarImg} />}
              initialRating={ratingSelected}
              onChange={handleSelectRating}
              onHover={handleHoverStars}
            />
            <p>{ratingText}</p>
          </S.WrapperRating>

          <S.WrapperForm isSelectedStars={ratingSelected > 0}>
            <S.Wrapper>
              <S.Text>Do que você mais gostou no serviço?</S.Text>

              <div>
                {buttonsSelected.map((button) => (
                  <S.Button
                    key={button.name}
                    isActive={button.active}
                    onClick={() => handleSelectButton(button.id)}
                  >
                    {button.name}
                  </S.Button>
                ))}
              </div>
            </S.Wrapper>

            <S.WrapperComments>
              <S.Title>Tem um comentário?</S.Title>
              <S.Text>
                Caso tenha um comentário a respeito do serviço <br /> ou do
                profissional, digite abaixo.
              </S.Text>

              <CommentsTextArea
                placeholder="Escreva aqui seu comentário..."
                value={comments}
                onChange={(e) => setComments(e.target.value)}
              />

              <Button loading={isLoading} onClick={handleSubmit}>
                Enviar avaliação
              </Button>
            </S.WrapperComments>
          </S.WrapperForm>
        </div>
      </S.Main>
    </S.Container>
  );
}
