import React, { useCallback, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router";

import { ContentLeft } from "templates/ContentLeft";
import { PhotoCardStep } from "pages/fieldService/ServicesHub/PhotoCardStep";

import {
  SignatureProps,
  SignatureType,
  useServiceSignaturePhotos,
} from "hooks/serviceSignaturePhotos";
import { useResumePmoc } from "hooks/resumePmoc";
import { useToast } from "hooks/toast";
import { ArrowButton } from "components/ArrowButton";
import { Button } from "components/Button";
import { AddSignaturePhoto } from "./AddSignaturePhoto";
import { ServiceRegisterPhoto } from "components/ServiceRegisterPhoto";

import apiv2 from "services/apiv2";

import * as S from "./styles";
import { useServiceOrder } from "hooks/serviceOrder";
import { LoadingProfiz } from "components/LoadingProfiz";
import { CarouselImages } from "components/ModalGalleryRegisterImages/CarouselImages";
import axios from "axios";

type Photo = {
  id: number;
  type?: string;
  link: string;
};

type RouteParams = {
  id: string;
  idPmoc: string;
  year: string;
  month: string;
};


export function PmocRegisterSignatures() {
  const { addToast } = useToast();

  const { id, idPmoc, year, month } = useParams<RouteParams>();

  const history = useHistory();
  const {
    selectedServiceSignaturePhotos,
    handleSelectedServiceSignaturePhotos,
  } = useServiceSignaturePhotos();

  const { selectedServiceOrder } = useServiceOrder();

  const { modalSelected, handleChangeModalSelected } = useResumePmoc();

  const [isLoading, setIsLoading] = useState(false);

  const [loadingSignatureId, setLoadingSignatureId] = useState(0);
  const [providerSignaturePicture, setProviderSignaturePicture] =
    useState<Photo>({} as Photo);
  const [clientSignaturePicture, setClientSignaturePicture] = useState<Photo>(
    {} as Photo
  );

  const [photoModal, setPhotoModal] = useState(false);

  const [stagePhotoData, setStagePhotoData] = useState<Photo[]>([]);

  const handleTogglePhotoModal = () => {
    setPhotoModal(!photoModal);
  };

  const loadInfoOrderService = async () => {
    try {
      setIsLoading(true);

      const { data } = await apiv2.get(
        `/budgets/service-order/${selectedServiceOrder.id}`
      );

      if (data.signatures && data.signatures.length > 0) {
        const findProviderSignature = data.signatures.find(
          (photo: Photo) =>
            photo.type === "signature" || photo.type === "profile-sign"
        );

        const findClientSignature = data.signatures.find(
          (photo: Photo) => photo.type === "client-sign"
        );

        if (findProviderSignature) {
          setProviderSignaturePicture({
            id: findProviderSignature.id,
            link: findProviderSignature.link,
          });
        }

        if (findClientSignature) {
          setClientSignaturePicture({
            id: findClientSignature.id,
            link: findClientSignature.link,
          });
        }
      }
    } catch (error) {
      addToast({
        title: "Ops!!",
        description: "Não foi possível carregar as assinaturas",
        type: "error",
      });
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    loadInfoOrderService();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const findProviderSignature = selectedServiceSignaturePhotos.find(
      (photo) => photo.type === "signature" || photo.type === "profile-sign"
    );
    const findClientSignature = selectedServiceSignaturePhotos.find(
      (photo) => photo.type === "client-sign"
    );

    if (findProviderSignature) {
      setProviderSignaturePicture({
        id: findProviderSignature.id,
        link: findProviderSignature.link,
      });
    }

    if (findClientSignature) {
      setClientSignaturePicture({
        id: findClientSignature.id,
        link: findClientSignature.link,
      });
    }
  }, [selectedServiceSignaturePhotos]);

  const handleAddNewPhoto = useCallback(
    ({ id, link, type }: SignatureProps) => {
      const photo = {
        id,
        link,
      };

      if (type === "signature" || type === "profile-sign") {
        setProviderSignaturePicture(photo);
        return;
      }

      setClientSignaturePicture(photo);
    },
    []
  );

  async function handleDeletePhotoInApi(
    signatureId: number,
    type: SignatureType
  ) {
    try {
      setLoadingSignatureId(signatureId);
      await apiv2.delete(`budgets/service-order/photo/${signatureId}`);

      const newSignatures = selectedServiceSignaturePhotos.filter(
        (signature) => signature.type !== type
      );

      handleSelectedServiceSignaturePhotos(newSignatures);

      if (type === "signature" || type === "profile-sign") {
        setProviderSignaturePicture({} as Photo);
      }

      if (type === "client-sign") {
        setClientSignaturePicture({} as Photo);
      }

      addToast({
        type: "success",
        title: "OK!",
        description: "Foto removida com sucesso!",
      });
    } catch {
      addToast({
        type: "error",
        title: "Erro!",
        description: "Não foi possível remover a foto!",
      });
    } finally {
      setLoadingSignatureId(0);
    }
  }

  function handleSetProviderSignaturePicture(photo: Photo) {
    setProviderSignaturePicture(photo);
  }

  async function handleNavigateToServiceOrderDetails() {
    try {

      setIsLoading(true);
      if (modalSelected === "registerSignatures") {
        handleChangeModalSelected("checklistDetails");
        return;
      }
      await apiv2.put(
        `budgets/service-order/${id}`,
        {
          originUpdate: "web",
        }
      );
      history.push(`/pmoc/execution/order-service/concluded/${id}/${idPmoc}/${year}/${month}`);
    } catch (error) {
      addToast({
          title: "Ops...",
          description: axios.isAxiosError(error)
            ? error.response?.data.error
            : "Não foi possível encerrar a ordem de serviço.",
          type: "error",
      });
    } finally {
      setIsLoading(false);
    }
  }

  function handleGoBack() {
    if (modalSelected === "registerSignatures") {
      handleChangeModalSelected("servicesHub");
      return;
    }

    history.goBack();
  }

  return (
    <>
      {isLoading ? (
        <LoadingProfiz isVisible={isLoading} />
      ) : (
        <>
          <S.Container>
            <CarouselImages
              imageSelected={0}
              images={stagePhotoData}
              isVisible={photoModal}
              onCloseModal={handleTogglePhotoModal}
            />

            <header>
              <ArrowButton handleFunction={handleGoBack} />
              <h1>Checklist do serviço</h1>
            </header>

            <ContentLeft>
              <S.Content>
                <S.Label>Finalização dos registros do serviço.</S.Label>

                <PhotoCardStep
                  title="Sua assinatura"
                  maxPhotos={1}
                  photosQuantity={
                    Object.keys(providerSignaturePicture).length === 0 ? 0 : 1
                  }
                >
                  <AddSignaturePhoto
                    maxPhotos={1}
                    type="signature"
                    photosQuantity={
                      Object.keys(providerSignaturePicture).length === 0 ? 0 : 1
                    }
                    handleAddNewPhoto={(photo) => {
                      handleAddNewPhoto({ ...photo, type: "signature" });
                    }}
                    handleSetProviderSignaturePicture={
                      handleSetProviderSignaturePicture
                    }
                  />
                  <S.Wrapper>
                    {Object.keys(providerSignaturePicture).length === 0 ? (
                      <S.Descrition>
                        Tire uma foto da sua assinatura para anexar a ordem de
                        serviço.
                      </S.Descrition>
                    ) : (
                      <ServiceRegisterPhoto
                        onClick={() => {
                          setStagePhotoData([providerSignaturePicture] ?? []);
                          handleTogglePhotoModal();
                        }}
                        loading={
                          loadingSignatureId === providerSignaturePicture.id
                        }
                        key={providerSignaturePicture.id}
                        url={providerSignaturePicture.link}
                        onDelete={() =>
                          handleDeletePhotoInApi(
                            providerSignaturePicture.id,
                            "signature"
                          )
                        }
                      />
                    )}
                  </S.Wrapper>
                </PhotoCardStep>

                <PhotoCardStep
                  title="Assinatura do cliente"
                  maxPhotos={1}
                  photosQuantity={
                    Object.keys(clientSignaturePicture).length === 0 ? 0 : 1
                  }
                >
                  <AddSignaturePhoto
                    maxPhotos={1}
                    type="client-sign"
                    photosQuantity={
                      Object.keys(clientSignaturePicture).length === 0 ? 0 : 1
                    }
                    handleAddNewPhoto={(photo) => {
                      handleAddNewPhoto({ ...photo, type: "client-sign" });
                    }}
                  />

                  <S.Wrapper>
                    {Object.keys(clientSignaturePicture).length === 0 ? (
                      <S.Descrition>
                        Tire uma foto da assinatura do cliente para anexar a
                        ordem de serviço.
                      </S.Descrition>
                    ) : (
                      <ServiceRegisterPhoto
                        loading={
                          loadingSignatureId === clientSignaturePicture.id
                        }
                        key={clientSignaturePicture.id}
                        url={clientSignaturePicture.link}
                        onDelete={() =>
                          handleDeletePhotoInApi(
                            clientSignaturePicture.id,
                            "client-sign"
                          )
                        }
                        onClick={() => {
                          setStagePhotoData([clientSignaturePicture] ?? []);
                          handleTogglePhotoModal();
                        }}
                      />
                    )}
                  </S.Wrapper>
                </PhotoCardStep>

                <S.Footer>
                  <Button onClick={() => handleNavigateToServiceOrderDetails()}>
                    Finalizar registros
                  </Button>
                </S.Footer>
              </S.Content>
            </ContentLeft>
          </S.Container>
        </>
      )}
    </>
  );
}
