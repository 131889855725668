import styled, { css } from "styled-components";

export const Container = styled.div`
  width: 80%;
  padding: 15px;

  header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 9px;
  }
`;

export const Title = styled.p`
  ${({ theme }) => css`
    font-size: ${theme.font.sizes.xsmall};
    color: ${theme.colors.title};
  `}
`;

export const Description = styled.p`
  ${({ theme }) => css`
    font-size: ${theme.font.sizes.xsmall};
    color: ${theme.colors.text};
    margin-bottom: 4px;
  `}
`;

export const ObservationButton = styled.button`
  ${({ theme }) => css`
    color: ${theme.colors.text};
    font-size: 12px;
    text-decoration: underline;
  `}
`;

export const WrapperContent = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  &:first-of-type {
    margin-bottom: 10px;
  }
`;

export const TextDetail = styled.span`
  ${({ theme }) => css`
    font-size: ${theme.font.sizes.xsmall};
    color: ${theme.colors.text};
  `}
`;

export const Checklist = styled.div`
  ${({ theme }) => css`
    padding-top: 3px;
    p {
      color: ${theme.colors.primary};
      letter-spacing: 0.26px;
      line-height: 16px;
      font-size: ${theme.font.sizes.xsmall};
      text-align: left;
    }
  `}
`;
