import styled, { css } from "styled-components";

export const Container = styled.div`
  ${({ theme }) => css`
    width: 100%;
    max-width: 1400px;
    min-width: 470px;
    margin: 0 auto;
    padding: 30px;

    header {
      display: flex;
      align-items: center;

      h1 {
        font-size: ${theme.font.sizes.large};
        color: ${theme.colors.title};
        font-weight: ${theme.font.bold};
        margin-left: 25px;
      }

      div {
        width: 80px;
        display: flex;
        justify-content: space-between;
      }
    }
  `}
`;

export const Wrapper = styled.div`
  ${({ theme }) => css`
    margin-top: 30px;

    p {
      margin-bottom: 5px;
      color: ${theme.colors.text};
      font-size: ${theme.font.sizes.small};
    }
  `}
`;

export const WrapperDate = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    margin-top: 30px;

    & > div {
      display: flex;
      align-items: center;
      margin-left: 50px;

      & > div {
        width: 7px;
        height: 7px;
        border-radius: 50%;
        background-color: ${theme.colors.model};
        margin-right: 6px;
      }
    }

    span {
      color: ${theme.colors.title};
      font-size: ${theme.font.sizes.xsmall};
    }
  `}
`;

export const Content = styled.section`
  .service {
    position: relative;
  }
`;

export const ContentBoxTitle = styled.h4`
  ${({ theme }) => css`
    font-size: ${theme.font.sizes.xsmall};
    color: ${theme.colors.text};
    margin-bottom: 14px;
  `}
`;

export const ContentBoxText = styled.p`
  ${({ theme }) => css`
    color: ${theme.colors.text};
    font-size: ${theme.font.sizes.xsmall};
    margin-bottom: 4px;
  `}
`;

export const ContentBoxObservationText = styled.pre`
  ${({ theme }) => css`
    white-space: pre-wrap;
    color: ${theme.colors.text};
    font-size: ${theme.font.sizes.xsmall};
    margin-bottom: 4px;
    font-family: ${theme.font.family};
  `}
`;

export const ServiceInfoBox = styled.section`
  p {
    margin-right: 4px;
  }
`;

export const WrapperArchiveButton = styled.div``;

export const CheckIconImage = styled.img`
  height: 40px;
  width: 40px;
  position: absolute;
  top: 40px;
  right: 20px;
`;

export const Status = styled.div`
  ${({ theme }) => css`
    font-size: ${theme.font.sizes.xsmall};
    color: ${theme.colors.title};
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    gap: 10px;
    font-weight: bold;
    .status {
      width: 10px;
      height: 10px;
      background-color: ${theme.colors.primary};
      border-radius: 30px;
    }
  `}
`;

export const ObservationButton = styled.button`
  ${({ theme }) => css`
    color: ${theme.colors.text};
    font-size: 12px;
    text-decoration: underline;
  `}
`;
