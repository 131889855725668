import styled, { css } from "styled-components";
import { darken } from "polished";

export const Container = styled.section`
  max-width: 1400px;
  width: 600px;
  padding: 30px;

  header {
    display: flex;
    justify-content: space-between;
  }
`;

export const Banner = styled.img`
  position: absolute;
  top: -30px;
  left: -30px;
`;

export const ContentArrowButtons = styled.div`
  width: 80px;
  z-index: 2;
  display: flex;
  justify-content: space-between;
`;

export const ProfileInfo = styled.section`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    margin-top: 30px;

    img {
      width: 80px;
      height: 80px;
      border-radius: 50%;
      margin-right: 20px;
    }

    div {
      display: flex;
      flex-direction: column;

      h4 {
        color: ${theme.colors.title};
        font-size: ${theme.font.sizes.medium};
      }
    }
  `}
`;

export const WrapperInfo = styled.div`
  ${({ theme }) => css`
    span {
      color: ${theme.colors.text};
      font-size: ${theme.font.sizes.xsmall};
      line-height: 16px;
      margin-top: 5px;
    }
  `}
`;

export const WrapperImageProfile = styled.div`
  ${({ theme }) => css`
    position: relative;
    margin-bottom: 32px;
    width: 100px;
    height: 100px;
    border-radius: 50px;
    justify-content: center;
    align-items: center;
    display: flex;

    img {
      width: 100px;
      height: 100px;
      border-radius: 50px;
    }

    input {
      display: none;
    }

    label {
      height: 30px;
      width: 30px;

      position: absolute;
      bottom: 0;
      right: 0;

      display: flex;
      align-items: center;
      justify-content: center;

      background-color: ${theme.colors.primary};
      border: 1px solid ${theme.colors.menu_detail_light};
      border-radius: 15px;
      transition: background 0.2s;

      &:hover {
        background: ${darken(0.05, "#34382e")};
      }
    }
  `}
`;

export const Edit = styled.button`
  img {
    width: 35px;
    height: 35px;
  }
`;

export const Content = styled.section`
  ${({ theme }) => css`
    width: 100%;
    margin-top: 30px;
    border-radius: 30px;

    & > div:first-of-type {
      display: flex;
      margin-bottom: 30px;
    }
  `}
`;

export const WrapperRow = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 20px;
`;

export const WrapperCard = styled.div`
  margin-right: 5px;
`;

export const WrapperCertifications = styled.div`
  width: 35%;
  margin-right: 50px;
`;
