import React from 'react';

import * as S from './styles';

type ButtonProps = {
    onChange: () => void;
    isChecked: boolean;
    enableBackgroundButtonColor?: string;
    disableBackgroundButtonColor?: string;
    buttonColor?: string;
}


export function ToogleButton({ onChange, isChecked, enableBackgroundButtonColor, disableBackgroundButtonColor, buttonColor }: ButtonProps) {
    return (
        <S.Container
            buttonColor={buttonColor}
            enableColor={enableBackgroundButtonColor}
            disableColor={disableBackgroundButtonColor}

            isChecked={isChecked}
        >
            <input
                type="checkbox"
                role="switch"
                checked={isChecked}
                onChange={onChange}
            />
        </S.Container>);
}