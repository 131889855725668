import React from "react";

import lupa from "assets/lupa.svg";

import * as S from "./styles";

export function EmptyPage() {
  return (
    <S.Container>
      <img src={lupa} alt="Homem segurando uma lupa" />
      <S.Description>Não encontramos nada por aqui</S.Description>
    </S.Container>
  );
}
