import styled, { css } from "styled-components";

export const Container = styled.div`
  ${({ theme }) => css`
    max-width: 1400px;
    width: 600px;
    padding: 30px;

    header {
      display: flex;
      align-items: center;
    }
  `}
`;

export const Wrapper = styled.div`
  margin-top: 30px;
`;

export const WrapperButtons = styled.div`
  position: fixed;
  bottom: 20px;
  width: 500px;
`;
