import styled, { css } from "styled-components";

export const Container = styled.div`
  ${({ theme }) => css`
    width: 100%;
    max-width: 1400px;
    min-width: 470px;
    margin: 0 auto;
    padding: 30px;

    header {
      display: flex;
      align-items: center;

      h1 {
        font-size: ${theme.font.sizes.large};
        color: ${theme.colors.title};
        font-weight: ${theme.font.bold};
        margin-left: 25px;
      }

      div {
        width: 80px;
        display: flex;
        justify-content: space-between;
      }
    }
  `}
`;

export const Wrapper = styled.div`
  margin-top: 30px;
`;

export const WrapperArchivedText = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    justify-content: center;

    p {
      color: ${theme.colors.text_light};
      font-size: 14px;
    }
  `}
`;

export const WrapperButton = styled.div`
  margin-top: 25px;
  margin-bottom: 60px;
`;

export const WrapperArchiveButton = styled.div`
  margin-top: -60px;
`;

export const WrapperButtonsSVG = styled.div`
  margin: 20px 0 50px;
  padding: 40px 0;
  display: flex;
  justify-content: flex-start;
  border-top: 1px solid #707070;
  border-bottom: 1px solid #707070;
  display: flex;
  justify-content: space-around;
`;

export const Title = styled.p`
  ${({ theme }) => css`
    font-size: ${theme.font.sizes.small};
    color: ${theme.colors.title};
  `}
`;

export const WrapperBudgetValidity = styled.div`
  ${({ theme }) => css`
    width: 100%;
    margin-top: 30px;

    display: flex;
    flex-direction: row;
    align-items: center;

    span {
      font-size: 14px;
      color: ${theme.colors.text_light};
      margin-left: 20px;
      margin-top: 19px;
    }
  `}
`;

export const WrapperInputBudgetValidity = styled.div`
  width: 90%;
`;

export const InfoWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 18px;
`;

export const TextGreen = styled.div`
  ${({ theme }) => css`
    font-size: ${theme.font.sizes.small};
    font-family: ${theme.font.normal};
    color: ${theme.colors.primary_medium};
  `}
`;

export const TitleHeader = styled.h1`
  padding-left: 32px;
`;

export const ContentBoxText = styled.p`
  ${({ theme }) => css`
    color: ${theme.colors.text};
    font-size: ${theme.font.sizes.xsmall};
    margin-bottom: 4px;
    display: flex;
    align-items: center;

    .moneyText {
      color: ${theme.colors.primary};
    }
  `}
`;
