import styled, { css } from "styled-components";
import { FiSearch } from "react-icons/fi";

export const Container = styled.div`
  ${({ theme }) => css`
    width: 100%;
    display: flex;
    flex-direction: column;

    p {
      margin-top: 8px;
      color: ${theme.colors.text};
      font-size: ${theme.font.sizes.small};
    }

    div {
      position: relative;
      width: 100%;
      display: flex;
      justify-content: space-between;
    }

    h4 {
      color: ${theme.colors.title};
      font-size: ${theme.font.sizes.large};
      margin-right: 28px;
      margin-bottom: 8px;
    }

    span {
      margin-top: 13px;
      color: ${theme.colors.text};
      font-size: ${theme.font.sizes.xsmall};
    }
  `}
`;

export const SearchButton = styled.button`
  justify-content: center;
  align-items: center;
  padding: 15px;
  position: absolute;
  right: 12px;

  .rai-activity-indicator {
    width: 10px !important;
  }
`;

export const SearchIcon = styled(FiSearch)`
  ${({ theme }) => css`
    color: ${theme.colors.text};
    font-size: 19px;
  `}
`;

export const CancelButton = styled.button`
  ${({ theme }) => css`
    width: 80px;
    justify-content: center;
    align-items: center;
    color: ${theme.colors.text_light};
  `}
`;
