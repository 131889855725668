import React, { useEffect, useState } from "react";
import { MdClose } from "react-icons/md";
import { useTheme } from "styled-components";

import CopyLinkIcon from "assets/icons/member-get-member/copy-link-icon.svg";
import GmailIcon from "assets/icons/member-get-member/share-with-gmail.svg";

import { useClickOutside } from "hooks/clickOutside";
import { useToast } from "hooks/toast";

import { api } from "services/api";

import * as S from "./styles";

type ModalProps = {
  isVisible: boolean;
  onCloseModal: () => void;
  zIndex?: number;
  sharedLink?: string;
};

export function ShareOnSocialMediaModal({
  isVisible,
  onCloseModal,
  zIndex,
  sharedLink,
}: ModalProps) {
  const { addToast } = useToast();
  const theme = useTheme();

  const [isCopied, setIsCopied] = useState(false);
  const [sharedUrl, setSharedUrl] = useState("");

  useEffect(() => {
    const trigger = (event: KeyboardEvent) => {
      if (event.key === "Escape") {
        onCloseModal();
      }
    };
    document.addEventListener("keydown", trigger);

    return () => {
      document.removeEventListener("keydown", trigger);
    };
  });

  useEffect(() => {
    (async () => {
      try {
        if (sharedLink) setSharedUrl(sharedLink);
        else {
          const { data } = await api.get("users/me");
          setSharedUrl(data.indication.url);
        }
      } catch (error) {
        addToast({
          title: "Ops",
          description: "Não foi possível obter o link de indicação",
          type: "error",
        });
      }
    })();
  });

  const domNode = useClickOutside(() => {
    onCloseModal();
  });

  const handleCopySharedURL = () => {
    navigator.clipboard.writeText(sharedUrl);

    setIsCopied(true);
  };

  const handleShareWithGmail = () => {
    let url;
    if (sharedLink)
      url = `https://mail.google.com/mail/?view=cm&fs=1&tf=1&to=&su=Acesse+as+execuções+da+PMOC&body=${sharedUrl}&ui=2&tf=1&pli=1`;
    else
      url = `https://mail.google.com/mail/?view=cm&fs=1&tf=1&to=&su=Crie+sua+conta+na+profiz&body=${sharedUrl}&ui=2&tf=1&pli=1`;

    window.open(url, "sharer", "toolbar=0,status=0,width=648,height=648");
  };

  return (
    <S.ContainerOverlay isVisible={isVisible} style={{ zIndex }}>
      <S.BoxModal ref={domNode}>
        <S.Header>
          <S.Title>Compartilhe seu link</S.Title>
          <button type="button" onClick={() => onCloseModal()}>
            <MdClose size={24} color={theme.colors.primary} />
          </button>
        </S.Header>

        <S.Main>
          <S.Share onClick={handleCopySharedURL}>
            <img src={CopyLinkIcon} alt="compartilhar link" />
            <p>{isCopied ? "Link copiado!" : "Copiar link"}</p>
          </S.Share>

          <S.Line />

          <S.Share onClick={handleShareWithGmail}>
            <img src={GmailIcon} alt="compartilhar com gmail" />
            <p>Gmail</p>
          </S.Share>
        </S.Main>
      </S.BoxModal>
    </S.ContainerOverlay>
  );
}
