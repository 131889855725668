import styled, { css } from "styled-components";
import { generateMedia } from "styled-media-query";

const customMedia = generateMedia({
  medium: "700px",
  small: "368px",
  xsmall: "294px",
});

export const Container = styled.div`
  ${({ theme }) => css`
    overflow-x: hidden;
    display: flex;
    flex-direction: row;
    height: 100vh;
    min-width: 368px;
    background-color: white;

    .inviteDetail {
      width: ${window.screen.width * 0.5}px;
      background-color: ${theme.colors.strong_background};
      height: 100vh;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: 0px ${window.screen.height * 0.2}px;

      .title {
        font-size: 16px;
        font-weight: ${theme.font.bold};
        color: ${theme.colors.text_extra_light};
        padding: 10px;
        text-align: center;
      }
      .subtitle {
        font-size: 12px;
        font-weight: ${theme.font.normal};
        color: ${theme.colors.text_light_60};
        padding: 10px;
        text-align: center;
        strong {
          color: ${theme.colors.text_light_87};
        }
      }
    }

    .info {
      width: ${window.screen.width * 0.5}px;
      align-items: center;
      justify-content: center;
      display: flex;
      flex-direction: column;

      .content {
        width: ${window.screen.height * 0.6}px;
        padding: 30px;
        .header {
          border-color: ${theme.colors.border_color};
          border-bottom: 0.1mm solid;
          display: flex;
          padding-bottom: 20px;
          ${customMedia.greaterThan("medium")`
            ${css`
              justify-content: center;
            `}
          `}
        }
        .message {
          margin-top: 20px;
          color: ${theme.colors.text_invite_60};
          font-size: 12px;
          padding-bottom: 20px;
          .underline {
            text-decoration: underline;
            cursor: pointer;
          }
          border-color: ${theme.colors.border_color};
          border-bottom: 0.1mm solid;
        }
        .footer {
          margin-top: 20px;
          color: ${theme.colors.text_invite_60};
          font-size: 12px;
        }
      }
    }

    ${customMedia.lessThan("medium")`
      ${css`
          display:flex;
          flex-direction: column;
          .inviteDetail{
            display:flex;
            height:${window.screen.height * 0.6}px;
            width:100%;
            background-color: ${theme.colors.strong_background};
            padding: 0px ${window.screen.height * 0.08}px;
          };
          .info{
            display:flex;
            height:${window.screen.height * 0.4}px;
            width:${window.screen.width}px;
            width:100%;
            justify-content:start;
            .content{
              width:${window.screen.height * 0.5}px;
              padding:30px;
              .header{
                img{
                  margin-bottom:5px;
                }
                border-color:${theme.colors.border_color};
                border-bottom: 0.1mm solid;
                justify:content:center;
              }
              .message{
                margin-top:20px;
                color:${theme.colors.text_invite_60};
                font-size:12px;
                .underline{
                  text-decoration: underline;
                  cursor:pointer;
                  margin-bottom:20px;
                }
              }
              .footer{
                margin-top:20px;
                color:${theme.colors.text_invite_60};
                font-size:12px;
              }
            }
          };
        `}
    `}
  `}
`;

export const ProfizLogo = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
  ${customMedia.lessThan("xsmall")`
      display: none;
  `}
`;
