import React, { FormEvent, memo, useEffect, useState } from "react";

import {
  PromptPaymentProps,
  IndexPromptProps,
} from "../../../dtos/PaymentConditionsDTO";

import * as S from "./styles";

type PaymentsConditiosInCashProps = {
  selectedItems: PromptPaymentProps[] | null | undefined;
  handlePaymentSelected: ({ index, discount }: PromptPaymentProps) => void;
  handlePaymentRemove: (index: IndexPromptProps) => void;
};

const PromptPaymentConditions = memo(
  ({
    selectedItems,
    handlePaymentSelected,
    handlePaymentRemove,
  }: PaymentsConditiosInCashProps) => {
    const [inputPrice, setInputPrice] = useState("");
    const [labelTypeDiscount, setLabelTypeDiscount] = useState("");
    const [isVisibleTooltip, setIsVisibleTooltip] = useState(false);

    const [selectedPayment, setSelectedPayment] = useState([
      { index: "promptPayment", isSelected: false },
      { index: "promptPaymentWithDiscount", isSelected: false },
    ]);

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [_, withDiscount] = selectedPayment.map(
      ({ isSelected }) => isSelected
    );

    useEffect(() => {
      if (selectedItems === undefined) return;

      if (selectedItems === null) return;

      const findPaymentById = selectedItems.find(
        ({ index }) => index === "promptPayment"
      );

      const findWithDiscountById = selectedItems.find(
        ({ index }) => index === "promptPaymentWithDiscount"
      );

      if (findPaymentById !== undefined) {
        setSelectedPayment((oldPayment) => {
          const newPayment = oldPayment.filter(
            ({ index }) => index === "promptPaymentWithDiscount"
          );

          return [{ index: "promptPayment", isSelected: true }, ...newPayment];
        });
      }

      if (findWithDiscountById !== undefined) {
        setSelectedPayment((oldPayment) => {
          const newPayment = oldPayment.filter(
            ({ index }) => index === "promptPayment"
          );

          return [
            ...newPayment,
            { index: "promptPaymentWithDiscount", isSelected: true },
          ];
        });

        if (findWithDiscountById.discount) {
          setInputPrice(findWithDiscountById.discount.value.toString());

          const { type } = findWithDiscountById.discount;
          type === "money"
            ? setLabelTypeDiscount("reais")
            : setLabelTypeDiscount("%");
        }
      }
    }, [selectedItems]);

    function handleSelectPayment(type: IndexPromptProps) {
      const newSelectedPayment = selectedPayment.map(
        ({ index, isSelected }) => {
          if (index === type) {
            return { index, isSelected: !isSelected };
          }

          return { index, isSelected: false };
        }
      );

      setSelectedPayment(newSelectedPayment);

      if (type === "promptPayment" && newSelectedPayment[0].isSelected) {
        handlePaymentSelected({
          index: "promptPayment",
          discount: null,
        });
      } else {
        handlePaymentRemove(type);
      }
    }

    // function clearInputsNotSelected(type: IndexProps) {
    //   if (type === 'promptPayment') {
    //     setInputPrice('')
    //     setLabelTypeDiscount('')
    //     setIsVisibleTooltip(false)
    //   }
    // }

    function handleChooseTypeDiscount(type: "money" | "percent") {
      type === "money"
        ? setLabelTypeDiscount("reais")
        : setLabelTypeDiscount("%");

      setIsVisibleTooltip(false);

      handlePaymentSelected({
        index: withDiscount ? "promptPaymentWithDiscount" : "promptPayment",
        discount: { value: Number(inputPrice), type },
      });
    }

    function handleChangePrice(event: FormEvent<HTMLInputElement>) {
      const { value } = event.currentTarget;
      setInputPrice(value);
      setIsVisibleTooltip(true);
    }

    return (
      <S.Container>
        <S.CheckArea onClick={() => handleSelectPayment("promptPayment")}>
          <S.Checkbox isSelected={selectedPayment[0].isSelected}>
            <div />
          </S.Checkbox>

          <S.PaymentText>À vista (sem desconto)</S.PaymentText>
        </S.CheckArea>

        <S.WrapperPayment>
          <S.CheckArea
            onClick={() => handleSelectPayment("promptPaymentWithDiscount")}
          >
            <S.Checkbox isSelected={selectedPayment[1].isSelected}>
              <div />
            </S.Checkbox>

            <S.PaymentText>À vista com</S.PaymentText>
          </S.CheckArea>

          <S.Input
            value={inputPrice}
            disabled={!withDiscount}
            onChange={(e) => handleChangePrice(e)}
          />

          {!!inputPrice && isVisibleTooltip && (
            <S.ToolTip>
              <button
                type="button"
                onClick={() => handleChooseTypeDiscount("percent")}
              >
                {`${inputPrice}% de desconto`}
              </button>

              <div />

              <button
                type="button"
                onClick={() => handleChooseTypeDiscount("money")}
              >
                {`R$ ${inputPrice} de desconto`}
              </button>
            </S.ToolTip>
          )}
          <S.PaymentText>{labelTypeDiscount} de desconto</S.PaymentText>
        </S.WrapperPayment>
      </S.Container>
    );
  }
);

export { PromptPaymentConditions };
