import { useEffect, useState } from "react";
import { Switch, Route as RouteReact } from "react-router-dom";

import { useAuth } from "hooks/auth";
import { useNetworkStatus } from "hooks/useNetworkStatus";

import { SignIn } from "../pages/auth/SignIn";
import { Register } from "../pages/auth/Register";
import { ForgotPassword } from "../pages/auth/ForgotPassword";

import { Home } from "../pages/common/Home";
import { Manuals } from "../pages/fieldService/Manuals";
import { Schedule } from "pages/fieldService/Schedule";
import { MyClients } from "pages/fieldService/MyClient";
import { MyServicesList } from "pages/fieldService/MyServicesList";

import { Profile } from "pages/common/Profile";
import { FinishRegister } from "pages/common/FinishRegister";
import { RatingList } from "pages/common/RatingList";
import { RatingDetails } from "pages/common/RatingDetails";

import { EquipmentsManual } from "../pages/fieldService/EquipmentsManual";
import { TypeEquipmentsManual } from "../pages/fieldService/TypeEquipmentsManual";
import { ComercialModelsManual } from "../pages/fieldService/ComercialModelsManual";
import { EquipmentsManualList } from "../pages/fieldService/EquipmentsManualList";

import { Certifications } from "../pages/common/Certifications";
import { InsertCertificates } from "../pages/common/InsertCertificates";
import { CertificationDetails } from "../pages/common/CertificationDetails";

import { NewBudget } from "../pages/fieldService/NewBudget";
import { Budgets } from "../pages/fieldService/Budgets";
import { EditBudget } from "../pages/fieldService/EditBudget";
import { ModelBudget } from "pages/fieldService/ModelBudget";
import { ApprovedBudget } from "pages/fieldService/ApprovedBudget";
import { ArchivedBudget } from "pages/fieldService/ArchivedBudget";
import { Occurrences } from "pages/common/Occurrences";
import { DownloadPdf } from "pages/fieldService/DownloadPdf";
import { ServiceOrder } from "../pages/fieldService/ServiceOrder";
import { NewServiceOrder } from "pages/fieldService/NewServiceOrder";
import { ServiceOrderPendingDetail } from "../pages/fieldService/ServiceOrderPendingDetail";
import { ServiceOrderScheduledDetail } from "pages/fieldService/ServiceOrderScheduledDetail";
import { ServiceOrderConcludedDetail } from "pages/fieldService/ServiceOrderConcludedDetail";
import { SendSatisfactionSurvey } from "pages/fieldService/SendSatisfactionSurvey";

import { SchedulingService } from "../pages/fieldService/SchedulingService";
import { ServicesHub } from "../pages/fieldService/ServicesHub";
import { RegisterSignatures } from "../pages/fieldService/RegisterSignatures";

import { Route } from "./Route";
import { Pending } from "pages/fieldService/ArchivedServiceOrder/Pending";
import { Scheduled } from "pages/fieldService/ArchivedServiceOrder/Scheduled";

import ProfitzExtract from "pages/common/ProfitzExtract";

import { HowItWorks } from "pages/MemberGetMember/HowItWorks";
import { MyEarnings } from "pages/MemberGetMember/MyEarnings";

import { MarketPlaceRoutes } from "./marketplace.routes";
import { PmocRoutes } from "./pmoc.routes";

import HelpPage from "pages/common/HelpPage";
import { Faq } from "pages/common/Faq";
import { ServiceChecklist } from "pages/fieldService/ServiceChecklist";
import FirstTimePage from "components/FirstTimePage";
import { EventsCalendar } from "pages/common/EventsCalendar";
import { Opportunities } from "pages/opportunities";
import { OpportunityDetail } from "pages/opportunities/OpportunityDetail";
import { ServiceOrderOpportunityDetail } from "pages/opportunities/ServiceOrderOpportunityDetail";
import RedirectComponent from "pages/common/RedirectComponent";
import { NotFound } from "pages/pmoc/View/404";
import { Teams } from "pages/permission/Teams";
import { ManagementOfTeam } from "pages/permission/ManagementOfTeam";
import { MyInvite } from "pages/permission/MyInvite";
import { ConfirmExitTeam } from "pages/permission/ConfirmExitTeam";
import { ExitSuccess } from "pages/permission/ExitSuccess";
import { InviteMember } from "pages/permission/InviteMember";
import { ConfirmInvite } from "pages/permission/ConfirmInvite";
import { NewInviteDetail } from "pages/permission/NewInvite";
import { InviteRefused } from "pages/permission/InviteRefused";
import { BusinessProposalsMode } from "pages/fieldService/BusinessProposalsMode";
import { Financial } from "pages/financial";
import { Receipts } from "pages/financial/Receipts";
import { CreateReceipt } from "pages/financial/Receipts/CreateReceipt";
import { EditReceipt } from "pages/financial/Receipts/EditReceipt";
import { IssuePreviewReceipt } from "pages/financial/Receipts/IssuePreviewReceipt";
import { ServiceDetailInServiceOrder } from "pages/fieldService/ServiceDetailInServiceOrder";
import { AppVersion } from "pages/version/AppVersion";
import { Products } from "pages/fieldService/Products";
import { Offline } from "pages/common/Offline";
import { DetailsProducts } from "pages/fieldService/Products/MyProducts/DetailsProducts";

export function Routes() {
  const { user } = useAuth();
  const isOnline = useNetworkStatus();

  const [isFirstTime, setIsFirstTime] = useState(false);

  const getFirstTime = () => {
    const firstTime = localStorage.getItem("Profiz:FirstTime");
    if (!firstTime && !user.profileApproved) {
      setIsFirstTime(true);
    }
  };

  const endOnboarding = () => {
    localStorage.setItem("Profiz:FirstTime", "true");
    setIsFirstTime(false);
  };

  useEffect(() => {
    if (user) {
      getFirstTime();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  if (!isOnline) {
    return <Offline />;
  }

  if (isFirstTime) {
    return <FirstTimePage endOnboarding={endOnboarding} />;
  }

  return (
    <Switch>
      <Route path="/" exact component={SignIn} />
      <Route path="/register" exact component={Register} />
      <Route path="/forgot-password" exact component={ForgotPassword} />

      <RouteReact path="/public/404" component={NotFound} exact />

      <Route path="/home" exact component={Home} isPrivate />
      <Route path="/brands" exact component={Manuals} isPrivate />
      <Route path="/schedule" exact component={Schedule} isPrivate />
      <Route path="/clients" exact component={MyClients} isPrivate />
      <Route path="/feedback" exact component={Occurrences} isPrivate />
      <Route path="/services" exact component={MyServicesList} isPrivate />
      <Route
        path="/brands/:brandId/equipments"
        component={EquipmentsManual}
        isPrivate
        exact
      />
      <Route
        path="/brands/:brandId/equipments/:categoryId/types"
        component={TypeEquipmentsManual}
        isPrivate
      />
      <Route
        path="/brands/:brandId/equipments/:categoryId/models"
        component={ComercialModelsManual}
        isPrivate
      />
      <Route path="/models" component={ComercialModelsManual} isPrivate />
      <Route
        path="/brands/:brandId/equipments/:equipmentId/manuals/:type"
        component={EquipmentsManualList}
        isPrivate
      />
      <Route
        path="/certifications"
        component={Certifications}
        isPrivate
        exact
      />
      <Route
        path="/certifications/create"
        component={InsertCertificates}
        isPrivate
      />
      <Route
        path="/certification-details"
        component={CertificationDetails}
        isPrivate
      />
      <Route path="/profile" component={Profile} isPrivate />
      <Route path="/profitz-extract" component={ProfitzExtract} isPrivate />
      <Route path="/complete-profile" component={FinishRegister} isPrivate />
      <Route
        path="/budgets-mode"
        exact
        component={BusinessProposalsMode}
        isPrivate
      />
      <Route path="/budgets/create/:mode" component={NewBudget} isPrivate />
      <Route path="/budgets" exact component={Budgets} isPrivate />
      <Route path="/budgets/:id" component={EditBudget} exact isPrivate />
      <Route
        path="/budgets/archived/:id"
        component={ArchivedBudget}
        exact
        isPrivate
      />
      <Route
        path="/budgets/model/:id"
        component={ModelBudget}
        isPrivate
        exact
      />
      <Route
        path="/budgets/concluded/:id"
        component={ApprovedBudget}
        isPrivate
        exact
      />
      <Route
        path="/scheduling/service/:id"
        exact
        component={SchedulingService}
        isPrivate
      />
      <Route path="/service-order" exact component={ServiceOrder} isPrivate />
      <Route
        path="/service-order/no-linked-budget"
        component={NewServiceOrder}
        exact
        isPrivate
      />
      <Route
        path="/service-order/pending/:id"
        exact
        component={ServiceOrderPendingDetail}
        isPrivate
      />
      <Route
        path="/service-order/pending/:id/archived"
        exact
        component={Pending}
        isPrivate
      />
      <Route
        path="/service-order/scheduled/:id"
        exact
        component={ServiceOrderScheduledDetail}
        isPrivate
      />
      <Route
        path="/service-order/scheduled/:id/archived"
        exact
        component={Scheduled}
        isPrivate
      />
      <Route
        path="/service-order/concluded/:id"
        exact
        component={ServiceOrderConcludedDetail}
        isPrivate
      />

      <Route
        path="/service-order/concluded/:serviceOrderId/detail-service/:serviceId"
        exact
        component={ServiceDetailInServiceOrder}
        isPrivate
      />

      <Route
        path="/service-checklist"
        exact
        component={ServiceChecklist}
        isPrivate
      />

      <Route isPrivate path="/service-photos/:id" component={ServicesHub} />
      <Route
        isPrivate
        path="/service-signatures/:id/"
        component={RegisterSignatures}
      />
      <Route
        path="/satisfaction-survey/:id"
        exact
        component={SendSatisfactionSurvey}
        isPrivate
      />
      <Route path="/ratings" component={RatingList} exact isPrivate />
      <Route path="/ratings/:id" component={RatingDetails} exact isPrivate />

      <Route
        path="/events-calendar"
        component={EventsCalendar}
        exact
        isPrivate
      />

      <Route path="/download/pdf" component={DownloadPdf} exact isPrivate />

      <Route path="/help" exact component={HelpPage} isPrivate />

      <Route path="/faq" exact component={Faq} isPrivate />

      <Route path="/member-get-member" exact component={HowItWorks} isPrivate />

      <Route path="/my-earnings" exact component={MyEarnings} isPrivate />

      <Route
        path="/service-opportunity"
        exact
        component={Opportunities}
        isPrivate
      />
      <Route
        path="/service-opportunity/:id"
        component={OpportunityDetail}
        exact
        isPrivate
      />
      <Route
        path="/service-opportunity-os/:id"
        component={ServiceOrderOpportunityDetail}
        exact
        isPrivate
      />

      <Route path="/teams" component={Teams} isPrivate exact />
      <Route
        path="/teams/management"
        component={ManagementOfTeam}
        isPrivate
        exact
      />
      <Route path="/teams/my-invite" component={MyInvite} isPrivate exact />
      <Route
        path="/teams/my-invite/confirm-exit"
        component={ConfirmExitTeam}
        isPrivate
        exact
      />
      <Route
        path="/teams/my-invite/confirm-exit/success"
        component={ExitSuccess}
        isPrivate
      />
      <Route
        path="/teams/management/invite"
        component={InviteMember}
        isPrivate
        exact
      />
      <Route
        path="/teams/management/invite/confirm"
        component={ConfirmInvite}
        isPrivate
      />

      <Route
        path="/teams/invite/:invitationHash"
        component={NewInviteDetail}
        isPrivate
      />

      <Route path="/teams/refused" component={InviteRefused} isPrivate />

      <Route path="/financial" component={Financial} exact isPrivate />
      <Route path="/financial/receipts" component={Receipts} exact isPrivate />
      <Route
        path="/financial/create-receipt"
        component={CreateReceipt}
        exact
        isPrivate
      />

      <Route
        path="/financial/edit-receipt/:idReceipt"
        component={EditReceipt}
        exact
        isPrivate
      />

      <Route
        path="/financial/edit-receipt/:idReceipt/preview"
        component={IssuePreviewReceipt}
        exact
        isPrivate
      />

      <Route path="/products" component={Products} exact isPrivate />
      <Route
        path="/products/details-products/:productId"
        component={DetailsProducts}
        exact
        isPrivate
      />

      <Route
        path="/app-version-detail"
        component={AppVersion}
        exact
        isPrivate
      />

      <PmocRoutes />

      <MarketPlaceRoutes />
      <Route path="*" component={RedirectComponent} exact isPrivate />
    </Switch>
  );
}
