import { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import * as Yup from "yup";

import { Input } from "components/Input";
import { Button } from "components/Button";
import { ArrowButton } from "components/ArrowButton";
import { LoadingProfiz } from "components/LoadingProfiz";
import { UnityAreaComponent } from "components/UnityAreaComponent";
import { ContentLeft } from "templates/ContentLeft";

import { ClientArea } from "pages/fieldService/EditBudget/ClientArea";
import { ServiceArea } from "pages/fieldService/EditBudget/ServiceArea";
import { LocalChecklistArea } from "pages/fieldService/EditBudget/LocalChecklistArea";
import { MaterialsArea } from "pages/fieldService/EditBudget/MaterialsArea";
import { PaymentConditionsArea } from "pages/fieldService/EditBudget/PaymentConditionsArea";

import { useToast } from "hooks/toast";
import { useClient } from "hooks/budget/client";
import { useSelectedService } from "hooks/budget/selectedServices";
import { useSelectedChecklist } from "hooks/budget/selectedLocalChecklist";
import { useSelectedMaterial } from "hooks/budget/selectedMaterials";
import { useMaterialsForEditingBudget } from "hooks/budget/materialsForEditingBudget";
import { useSelectedPaymentConditions } from "hooks/budget/selectedPaymentConditions";
import { useTotalBudget } from "hooks/budget/totalBudget";
import { useObservation } from "hooks/budget/observation";
import { useBudgetTabIndex } from "hooks/budgetTabIndex";
import {
  ProductPropsUsedInContext,
  useSelectedProducts,
} from "hooks/budget/selectedProducts";

import { ServiceFromApiDTO } from "dtos/ServiceFromApiDTO";
import { LocalChecklistFromApiDTO } from "dtos/LocalChecklistFromApiDTO";
import { MaterialFromApiDTO } from "dtos/MaterialFromApiDTO";
import { ModeProps } from "dtos/businessProposalsDTO";

import addMaskMoney from "utils/addMaskMoney";
import getValidationErrors from "utils/getValidationErrors";
import { mappedServicesFromApi } from "utils/mappedServicesFromApi";
import { mappedMaterialsFromApi } from "utils/mappedMaterialsFromApi";
import { mappedLocalChecklistFromApi } from "utils/mappedLocalChecklistFromApi";
import { mappedServicesForApi } from "utils/mappedServicesForApi";
import { mappedLocalChecklistForApi } from "utils/mappedLocalChecklistForApi";
import { mappedMaterialsForApi } from "utils/mappedMaterialsForApi";
import {
  mappedBudgetProductToUseInApp,
  mappedBudgetProducts,
} from "utils/mappedBudgetProducts";

import apiv2 from "services/apiv2";
import * as S from "./styles";
import { ProductsArea } from "components/ModalProducts/ProductsArea";
import { DefaultObservationsArea } from "../EditBudget/DefaultObservationsArea";
import { ServiceDTO } from "dtos/ServiceDTO";

type RouteParams = {
  id: string;
};

type Errors = {
  [key: string]: string;
};

export function ModelBudget() {
  const history = useHistory();
  const { addToast } = useToast();
  const { id } = useParams<RouteParams>();

  const { handleSetSelectedIndex } = useBudgetTabIndex();

  const { client, handleSetClient, clearClientData, clearUnityData, unity } =
    useClient();
  const {
    selectedProductsInContext,
    handleSetSelectedProducts,
    clearSelectedProducts,
  } = useSelectedProducts();
  const { selectedServices, handleSetSelectedServices, clearServicesData } =
    useSelectedService();
  const {
    selectedChecklistItems,
    handleSetSelectedChecklist,
    clearChecklistsData,
  } = useSelectedChecklist();
  const { selectedMaterials, handleSetSelectedMaterials, clearMaterialsData } =
    useSelectedMaterial();
  const { handleSetMaterialsForEditingBudget } = useMaterialsForEditingBudget();
  const { totalBudget } = useTotalBudget();
  const {
    selectedPromptPayment,
    selectedInstallmentPayment,
    selectedIncomingPayment,
    handleSetSelectedPromptPayment,
    handleSetSelectedInstallmentPayment,
    handleSetSelectedIncomingPayment,
  } = useSelectedPaymentConditions();
  const { text, handleSetText, clearObservationData } = useObservation();

  const [idSequence, setIdSequence] = useState(null);
  const [mode, setMode] = useState<ModeProps | "">("");

  const [servicesFromApi, setServicesFromApi] = useState<ServiceFromApiDTO[]>(
    []
  );
  const [selectedServicesState, setSelectedServicesState] = useState<
    ServiceDTO[]
  >([]);
  const [selectedProductsState, setSelectedProductsState] = useState<
    ProductPropsUsedInContext[]
  >([]);

  const [localChecklistFromApi, setLocalChecklistFromApi] = useState<
    LocalChecklistFromApiDTO[]
  >([]);
  const [materialsFromApi, setMaterialsFromApi] = useState<
    MaterialFromApiDTO[]
  >([]);

  const [budgetValidation, setBudgetValidation] = useState("");

  const [isLoading, setIsLoading] = useState(true);
  const [loadingButton, setLoadingButton] = useState(false);
  const [loadingCreateNewModel, setLoadingCreateNewModel] = useState(false);

  const [hasError, setHasError] = useState<Errors>({} as Errors);
  const [hasErrorOnClient, setHasErrorOnClient] = useState("");
  const [hasErrorOnPaymentConditions, setHasErrorOnPaymentConditions] =
    useState("");
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [hasErrorOnUnity, setHasErrorOnUnity] = useState("");

  async function getBudgetById(budgetId: string) {
    try {
      const response = await apiv2.get(`/budgets/${budgetId}`);
      const {
        // created_at,
        mode,
        sequenceNumber,
        client: clientResponse,
        products,
        services,
        localChecklists,
        materials,
        total,
        payment_condition,
        budget_validity,
        observations,
      } = await response.data;

      setIdSequence(sequenceNumber);
      setMode(mode);

      handleSetClient(clientResponse);

      const mappedProducts = mappedBudgetProductToUseInApp(products);
      handleSetSelectedProducts(mappedProducts);
      setSelectedProductsState(mappedProducts);

      setServicesFromApi(services);
      const mappedServices = mappedServicesFromApi(services);
      handleSetSelectedServices(mappedServices);
      setSelectedServicesState(mappedServices);

      setLocalChecklistFromApi(localChecklists);
      const mappedLocalChecklists =
        mappedLocalChecklistFromApi(localChecklists);
      handleSetSelectedChecklist(mappedLocalChecklists);

      setMaterialsFromApi(materials);
      const mappedMaterials = mappedMaterialsFromApi(materials);
      handleSetSelectedMaterials(mappedMaterials);
      handleSetMaterialsForEditingBudget(mappedMaterials);

      handleSetSelectedPromptPayment(payment_condition.promptPayment, total);
      handleSetSelectedInstallmentPayment(payment_condition.installment, total);
      handleSetSelectedIncomingPayment(payment_condition.incoming, total);
      setBudgetValidation(budget_validity);
      handleSetText(observations);
    } catch (err) {
      addToast({
        title: "Ops...",
        description: "Erro ao carregar os dados do orçamento",
        type: "error",
      });
    } finally {
      setIsLoading(false);
    }
  }

  useEffect(() => {
    getBudgetById(id);

    return () => clearAllDataBudget();
  }, [id]); // eslint-disable-line

  function clearAllDataBudget() {
    clearClientData();
    clearSelectedProducts();
    clearServicesData();
    clearChecklistsData();
    clearMaterialsData();
    clearObservationData();
    clearUnityData();
  }

  useEffect(() => {
    //Ao trocar de cliente, retira o erro.
    if (hasErrorOnClient) {
      setHasErrorOnClient("");
    }
  }, [client]); // eslint-disable-line react-hooks/exhaustive-deps

  async function handleGenerateBudget() {
    setLoadingButton(true);
    try {
      if (!client.id) {
        setHasErrorOnClient("Cliente é obrigatório");
      } else {
        setHasErrorOnClient("");
      }

      if (
        !selectedPromptPayment.length &&
        !selectedIncomingPayment.length &&
        !selectedInstallmentPayment.length
      ) {
        setHasErrorOnPaymentConditions(
          "Condições de pagamento são obrigatórias"
        );
      } else {
        setHasErrorOnPaymentConditions("");
      }

      const schema = Yup.object().shape({
        budgetValidation: Yup.string().required(
          "Validade do orçamento é obrigatório"
        ),
      });

      await schema.validate({ budgetValidation }, { abortEarly: false });

      setHasError({} as Errors);

      if (
        !client.id ||
        totalBudget <= 0 ||
        (!selectedPromptPayment.length &&
          !selectedIncomingPayment.length &&
          !selectedInstallmentPayment.length)
      ) {
        return addToast({
          title: "Ops!!",
          description: "Alguns campos são obrigatórios",
          type: "error",
        });
      }

      let clientAddress =
        Object.keys(unity).length > 0
          ? unity
          : {
              id: 0,
              clientId: "",
              name: "",
              responsible: "",
              email: "",
              phone: "",
              postalCode: "",
              street: "",
              district: "",
              number: "",
              complement: "",
              city: "",
              uf: "",
              additionalInfo: "",
              default: true,
              createdAt: "",
              environments: [],
            };

      const budgetData = {
        client: { ...client, address: clientAddress },
        products: mappedBudgetProducts(selectedProductsInContext),
        services: servicesFromApi,
        localChecklists: localChecklistFromApi,
        materials: materialsFromApi,
        budget_validity: budgetValidation,
        total: totalBudget,
        payment_condition: {
          promptPayment: selectedPromptPayment,
          installment: selectedInstallmentPayment,
          incoming: selectedIncomingPayment,
        },
        observations: text,
        originCreate: "web",
        mode,
      };

      await apiv2.post("/budgets", budgetData);

      addToast({
        title: "Sucesso",
        description: "Orçamento gerado com sucesso",
        type: "success",
      });

      history.goBack();
      handleSetSelectedIndex(0);
    } catch (error) {
      if (error instanceof Yup.ValidationError) {
        const errors = getValidationErrors(error);
        setHasError(errors);

        return;
      }

      addToast({
        title: "Ops!!",
        description: "Erro ao gerar o orçamento",
        type: "error",
      });
    } finally {
      setLoadingButton(false);
    }
  }

  async function handleGenerateNewModelBudget() {
    try {
      setLoadingCreateNewModel(true);

      if (
        mode === "products-services" &&
        selectedProductsInContext === selectedProductsState &&
        selectedServices === selectedServicesState
      ) {
        return addToast({
          title: "Ops!!",
          description: "Nenhum serviço ou produto foi alterado",
          type: "info",
        });
      }

      if (mode === "services" && selectedServices === selectedServicesState) {
        return addToast({
          title: "Ops!!",
          description: "Nenhum serviço foi alterado",
          type: "info",
        });
      }

      if (
        mode === "products" &&
        selectedProductsInContext === selectedProductsState
      ) {
        return addToast({
          title: "Ops!!",
          description: "Nenhum produto foi alterado",
          type: "info",
        });
      }

      const budgetData = {
        client: {},
        products: mappedBudgetProducts(selectedProductsInContext),
        services: mappedServicesForApi(selectedServices),
        localChecklists: mappedLocalChecklistForApi(selectedChecklistItems),
        materials: mappedMaterialsForApi(selectedMaterials),
        budget_validity: budgetValidation,
        total: totalBudget,
        payment_condition: {
          promptPayment: selectedPromptPayment,
          installment: selectedInstallmentPayment,
          incoming: selectedIncomingPayment,
        },
        observations: text,
        originCreate: "web",
        mode,
      };

      await apiv2.post(`/budgets/${id}/model`, budgetData);

      history.goBack();

      addToast({
        title: "Certo!",
        description: "Seu modelo de orçamento foi gerado com sucesso",
        type: "success",
      });
    } catch (error) {
      addToast({
        title: "Ops!!",
        description: "Erro ao gerar modelo de orçamento",
        type: "error",
      });
    } finally {
      setLoadingCreateNewModel(false);
    }
  }

  return (
    <S.Container>
      {isLoading ? (
        <LoadingProfiz isVisible={isLoading} />
      ) : (
        <ContentLeft>
          <header>
            <div>
              <ArrowButton />
            </div>

            <h1>Modelo de orçamento {idSequence}</h1>
          </header>

          <S.Wrapper>
            <ClientArea hasError={hasErrorOnClient} />

            <S.Wrapper>
              <UnityAreaComponent
                hasError={hasErrorOnUnity}
                label="Selecione o endereço do cliente"
              />
            </S.Wrapper>

            {(mode === "products" || mode === "products-services") && (
              <ProductsArea hasIdBudget={!!id} />
            )}

            {(mode === "services" || mode === "products-services") && (
              <ServiceArea />
            )}

            <LocalChecklistArea />

            <MaterialsArea />

            <S.Wrapper>
              <S.Title>Informações de pagamento</S.Title>

              <S.Wrapper>
                <Input
                  name="Total*"
                  value={addMaskMoney(totalBudget)}
                  readOnly
                  placeholder="R$10,00"
                />
              </S.Wrapper>

              <PaymentConditionsArea hasError={hasErrorOnPaymentConditions} />

              <S.WrapperBudgetValidity>
                <S.WrapperInputBudgetValidity>
                  <Input
                    name="Validade do orçamento*"
                    placeholder="Ex: 7"
                    type="number"
                    onChange={(e) => setBudgetValidation(e.target.value)}
                    value={budgetValidation}
                    hasError={hasError.budgetValidation}
                    onFocusClearError={() => setHasError({})}
                  />
                </S.WrapperInputBudgetValidity>

                <span>dias</span>
              </S.WrapperBudgetValidity>

              <S.Wrapper>
                <DefaultObservationsArea
                  valueTextArea={text}
                  onChangeTextArea={handleSetText}
                />
              </S.Wrapper>

              <S.WrapperButton>
                <Button
                  loading={loadingCreateNewModel}
                  onClick={() => handleGenerateNewModelBudget()}
                >
                  Gerar novo modelo
                </Button>
              </S.WrapperButton>

              <S.WrapperButton>
                <Button
                  loading={loadingButton}
                  onClick={() => handleGenerateBudget()}
                >
                  Gerar novo orçamento
                </Button>
              </S.WrapperButton>
            </S.Wrapper>
          </S.Wrapper>
        </ContentLeft>
      )}
    </S.Container>
  );
}
