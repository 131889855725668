import styled, { css } from "styled-components";

export const Container = styled.div`
  width: 70%;
  margin-left: 25px;
  max-width: 822px;
`;

export const Header = styled.div`
  max-width: 290px;
  margin: 21px 0 35px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  h1 {
    ${({ theme }) => css`
      font-size: 18px;
      color: ${theme.colors.text_light_87};
      font-weight: ${theme.font.bold};
    `}
  }
`;

export const Description = styled.span`
  ${({ theme }) => css`
    font-size: 14px;
    line-height: 20px;
    color: ${theme.colors.text_light_87};
    font-weight: ${theme.font.normal};
  `}
`;

export const Content = styled.div`
  margin-top: 40px;

  display: flex;
  align-items: center;

  div {
    margin-right: 24px;
  }
`;

