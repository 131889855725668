import { useEffect, useState } from "react";

import { Dropdown, DropdownOption } from "components/DropDown";
import { InputAddNewCategory } from "components/InputAddNewCategory";
import { DisabledDropDown } from "components/DisabledDropDown";

import { useServiceFormTree, DropDownProps } from "hooks/serviceFormTree";
import { useServiceDropDown } from "hooks/servicesDropDown";
import { useToast } from "hooks/toast";
import { useAccount } from "hooks/permission/account";
import { useHasPermission } from "hooks/permission/useHasPermission";

import { api } from "services/api";

interface EquipmentTypeProps {
  moduleHash?: string;
}

export function EquipmentTypes({ moduleHash }: EquipmentTypeProps) {
  const { addToast } = useToast();
  const { whoami } = useAccount();
  const { permission } = useHasPermission({
    actionHash: "view",
    moduleHash: "equipment-type",
  });

  const { categoriesTree, handleSetCategoriesTree } = useServiceFormTree();
  const {
    selectedCategoryId,
    selectedEquipment,
    selectedService,
    selectedEquipmentType,
    handleEquipmentType,
  } = useServiceDropDown();

  const [loadingButton, setLoadingButton] = useState(false);
  const [newEquipmentType, setNewEquipmentType] = useState("");
  const [equipmentTypes, setEquipmentTypes] = useState<DropDownProps[]>([]);

  useEffect(() => {
    const currentCategory = categoriesTree.find(
      (category) => category.id === Number(selectedCategoryId)
    );

    if (!currentCategory) return;

    const currentService = currentCategory.services.find(
      (service) => service.id === Number(selectedService.id)
    );

    if (!currentService) return;

    const currentEquipment = currentService.equipments.find(
      (equipment) => equipment.id === Number(selectedEquipment.id)
    );

    if (!currentEquipment) return;

    setEquipmentTypes(currentEquipment.types);
  }, [
    categoriesTree,
    selectedCategoryId,
    selectedService.id,
    selectedEquipment.id
  ]);

  function handleNewEquipmentType(text: string) {
    setNewEquipmentType(text.trimStart());
  }

  async function handleSubmit() {
    if (!newEquipmentType) {
      addToast({
        title: "Ops",
        description: "Preencha com alguma informação antes de prosseguir",
        type: "error",
      });
      return;
    }

    try {
      setLoadingButton(true);
      const response = await api.post(
        `/services/equipment/${selectedEquipment.id}/type`,
        {
          name: newEquipmentType,
          accountId: whoami?.id
        }
      );

      const { types } = response.data;

      const formattedCategories = categoriesTree.map((category) => {
        if (category.id === selectedCategoryId) {
          return {
            ...category,
            services: category.services.map((service) => {
              if (service.id === Number(selectedService.id)) {
                return {
                  ...service,
                  equipments: service.equipments.map((equipment) => {
                    if (equipment.id === Number(selectedEquipment.id)) {
                      return {
                        ...equipment,
                        types,
                      };
                    }
                    return equipment;
                  }),
                };
              }
              return service;
            }),
          };
        }

        return category;
      });

      handleSetCategoriesTree(formattedCategories);
      setNewEquipmentType("");

      addToast({
        title: "Sucesso",
        description: "Tipo de equipamento cadastrado com sucesso!",
        type: "success",
      });
    } catch (err) {
      addToast({
        title: "Ops",
        description: "Ocorreu um erro ao cadastrar",
        type: "error",
      });
    } finally {
      setLoadingButton(false);
    }
  }

  function handleSelectEquipmentType({ id, name }: DropdownOption) {
    handleEquipmentType({ id, name });
  }

  function handleWarning() {
    if (!selectedCategoryId) {
      addToast({
        title: "Atenção!",
        description: "Selecione uma categoria",
        type: "info",
      });
      return;
    }

    if (!selectedService.id) {
      addToast({
        title: "Atenção!",
        description: "Selecione um serviço",
        type: "info",
      });
      return;
    }

    if (!selectedEquipment.id) {
      addToast({
        title: "Atenção!",
        description: "Selecione um equipamento",
        type: "info",
      });
    }
  }

  function handleClearEquipmentType() {
    handleEquipmentType({} as DropdownOption);
  }

  return (
    <>
      {selectedCategoryId > 0 &&
      selectedService.id > 0 &&
      selectedEquipment.id > 0 ? (
        <Dropdown
          label="Tipo (opcional)"
          placeholder="Selecione o tipo de equipamento"
          type="type"
          options={equipmentTypes}
          onClickedValue={handleSelectEquipmentType}
          hasInput
          categoryIdSelected={selectedEquipmentType.id}
          clearDropDownItem
          handleClear={handleClearEquipmentType}
          moduleHashValue={moduleHash}
          actionView={permission}
        >
          <InputAddNewCategory
            isLoadingButton={loadingButton}
            value={newEquipmentType}
            handleChangeNewCategory={handleNewEquipmentType}
            handleSave={handleSubmit}
          />
        </Dropdown>
      ) : (
        <DisabledDropDown
          label="Tipo (opcional)"
          title="Selecione o tipo de equipamento"
          onClick={handleWarning}
        />
      )}
    </>
  );
}
