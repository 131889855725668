import styled, { css } from 'styled-components'

export const Container = styled.section`
  ${({ theme }) => css`
    width: 100%;
    max-width: 1400px;
    margin: 0 auto;
    padding: 30px;

    header {
      display: flex;
      align-items: center;

      h1 {
        font-size: ${theme.font.sizes.large};
        color: ${theme.colors.title};
        font-weight: ${theme.font.bold};
        margin-left: 25px;
      }

      div {
        width: 80px;

        display: flex;
        justify-content: space-between;
      }
    }
  `}
`

export const Content = styled.section`
  ${({ theme }) => css`
    margin-top: 35px;
    display: grid;
    //I want two columns with 50%
    grid-template-columns: repeat(8, 0.5fr);
    gap: ${theme.grid.small};

    margin-bottom: 100px;
  `}
`
