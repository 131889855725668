import ServiceOrderSvg from "assets/service-order.svg";
import ProfileSvg from "assets/icons/profile.svg";
import EventsSvg from "assets/eventsBackground.svg";
import FeedbackSvg from "assets/feedback.svg";
import LogoProfizSvg from "assets/icons/logo-profiz-border.svg";
import ServiceOpportunitySvg from "assets/service-opportunity.svg";
import InviteNewSvg from "assets/icons/permission/hand-shake.svg";
import InviteAcceptedSvg from "assets/icons/permission/allowed.svg";
import InviteRemovedSvg from "assets/icons/permission/denied.svg";

type NotificationIconMapped = {
  [key: string]: string;
};

const notificationIcons: NotificationIconMapped = {
  "ordem-servico": ServiceOrderSvg,
  feedback: FeedbackSvg,
  perfil: ProfileSvg,
  evento: EventsSvg,
  "opportunity-service-order": ServiceOpportunitySvg,
  "opportunity-showcase": ServiceOpportunitySvg,
  "invite-new": InviteNewSvg,
  "invite-accepted": InviteAcceptedSvg,
  "invite-removed": InviteRemovedSvg,
  "invite-denied": InviteRemovedSvg,
  default: LogoProfizSvg,
};

export function getNotificationIcon(type: string, status?: string) {
  let actionType = type;

  if (type === "invite" && !!status) {
    actionType = `${type}-${status}`;
  }

  const iconComponent =
    notificationIcons[actionType] || notificationIcons["default"];
  return iconComponent;
}
