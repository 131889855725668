import styled, { css } from "styled-components";

export const Container = styled.div`
  ${({ theme }) => css`
    width: 100%;
    max-width: 1400px;
    min-width: 470px;
    margin: 0 auto;
    padding: 30px;

    header {
      display: flex;
      align-items: center;

      h1 {
        font-size: ${theme.font.sizes.large};
        color: ${theme.colors.title};
        font-weight: ${theme.font.bold};
        margin-left: 25px;
      }

      div {
        width: 80px;
        display: flex;
        justify-content: space-between;
      }
    }
  `}
`;
export const Content = styled.form`
  width: 100%;
`;

export const ContentImage = styled.div`
  width: 100%;
  height: 190px;
  margin-top: 40px;
  display: flex;
  justify-content: center;
  margin-bottom: 48px;
`;

export const Label = styled.p`
  ${({ theme }) => css`
    color: ${theme.colors.text};
    font-size: ${theme.font.sizes.small};
    line-height: 20px;
  `}
`;

export const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const SchedulingButton = styled.button`
  width: 35px;
  height: 35px;
  border-radius: 8px;

  img {
    width: 35px;
    height: 35px;
  }
`;

export const ContentListService = styled.div`
  margin-top: 20px;
`;

export const ContentBoxTitle = styled.h4`
  ${({ theme }) => css`
    font-size: ${theme.font.sizes.xsmall};
    color: ${theme.colors.title};
    margin-bottom: 14px;
  `}
`;

export const ContentBoxText = styled.p`
  ${({ theme }) => css`
    color: ${theme.colors.text};
    font-size: ${theme.font.sizes.xsmall};
    margin-bottom: 4px;
  `}
`;

export const WrapperService = styled.div`
  display: flex;
  width: 100%;

  margin-bottom: 20px;

  div {
    width: 100%;
  }

  button {
    margin-left: 10px;
    margin-top: 25px;
  }
`;

export const ServiceInfoBox = styled.section`
  p {
    margin-right: 4px;
  }
`;
export const CheckBoxContainer = styled.div`
  ${({ theme }) => css`
    margin-top: 20px;

    span {
      color: ${theme.colors.text};
    }
  `}
`;
