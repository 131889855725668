import styled, { css, DefaultTheme } from "styled-components";
import { Tooltip } from "../Tooltip";

interface ContainerProps {
  isFocused: boolean;
  isErrored: boolean;
}

export const Label = styled.p`
  ${({ theme }) => css`
    color: ${theme.colors.title};
    text-align: left;
    font-size: 12px;
    margin-bottom: 8px;
  `}
`;

const containerModifiers = {
  isErrored: (theme: DefaultTheme) => css`
    border-color: ${theme.colors.error};
  `,

  isFocused: (theme: DefaultTheme) => css`
    border-color: ${theme.colors.primary_light};
  `,
};

export const Container = styled.div<ContainerProps>`
  ${({ theme, isErrored, isFocused }) => css`
    width: 100%;
    background: ${theme.colors.menu_background};
    border-radius: 10px;
    position: relative;
    padding: 14px 14px 6px;
    border: 1px solid #232129;
    color: ${theme.colors.title}
    display: flex;
    flex-direction: column;
    justify-content: flex-end;

    & + div {
      margin-top: 8px;
    }

    ${!!isFocused && containerModifiers.isFocused(theme)};
    ${!!isErrored && containerModifiers.isErrored(theme)};

    textarea {
      width: 100%;
      min-height: 100px;
      resize: none;
      overflow: auto;
      background-color: transparent;
      font-size: 13px;
      font-family: ${theme.font.family};
      color: ${theme.colors.text_extra_light};
      margin-top: 2px;
      border: 0;
      outline: none;
    }

    textarea::-webkit-scrollbar {
      width: 5px;
    }

    textarea::-webkit-scrollbar-thumb {
      background: ${theme.colors.background_light};
      border-radius: 5px;
    }

  `}
`;

export const ButtonComments = styled.button`
  padding-top: 5px;

  ${({ theme }) => css`
    color: ${theme.colors.text};
    text-decoration: underline;
  `}
`;

export const Error = styled(Tooltip)`
  position: absolute;
  top: 12px;
  right: 8px;

  height: 20px;
  margin-left: 16px;
  svg {
    margin: 0;
  }
  span {
    background: ${({ theme }) => theme.colors.error};
    color: #fff;
    &::before {
      border-color: ${({ theme }) => theme.colors.error} transparent;
    }
  }
`;
