import styled, { css } from "styled-components";
import { darken } from "polished";
import { ToggleButton } from "components/CardCheckRegister/styles";

export const Container = styled.div`
  width: 100%;
  padding-bottom: 30px;

  .rc-slider-disabled {
    background-color: transparent;
  }
  header {
    height: 100px;
    display: flex;
    align-items: center;
    padding: 0 30px;

    div {
      width: 80px;
      display: flex;
      justify-content: space-between;
    }
  }

  main {
    height: 100%;
    margin: 0 auto;

    display: flex;
    flex-direction: column;
    align-items: center;
  }
`;

export const WrapperCardProfileUnderAnalysis = styled.div`
  width: 100%;
  padding: 0 30px;
`;

export const Title = styled.div`
  ${({ theme }) => css`
    font-size: 18px;
    font-weight: 700;
    color: ${theme.colors.text_light_87};
    font-family: ${theme.font.family};
    margin-right: auto;
    margin-left: 30px;
    line-height: 22px;
  `}
`;

export const SubTitle = styled.div`
  ${({ theme }) => css`
    font-size: 14px;
    font-weight: 400;
    color: ${theme.colors.text_light_87};
    font-family: ${theme.font.family};
    margin-right: auto;
    margin-left: 30px;
    margin-top: 10px;
  `}
`;

export const WrapperImageProfile = styled.div`
  ${({ theme }) => css`
    position: relative;
    margin-bottom: 32px;
    width: 100px;
    height: 100px;
    border-radius: 50px;
    background-color: ${theme.colors.text_light_87};
    justify-content: center;
    align-items: center;
    display: flex;

    img {
      width: 100px;
      height: 100px;
      border-radius: 50px;
    }

    input {
      display: none;
    }

    label {
      height: 30px;
      width: 30px;

      position: absolute;
      bottom: 0;
      right: 0;

      display: flex;
      align-items: center;
      justify-content: center;

      background-color: ${theme.colors.primary};
      border: 1px solid #353535;
      border-radius: 15px;
      transition: background 0.2s;

      &:hover {
        background: ${darken(0.05, "#34382e")};
      }
    }
  `}
`;

export const Form = styled.div`
  width: 100%;
  padding: 10px 30px 0;
`;

export const WrapperInput = styled.div`
  margin-bottom: 14px;
`;

export const WrapperSmallInput = styled.div`
  display: flex;
  margin-bottom: 14px;
  width: 100%;
  justify-content: space-between;
`;

export const WrapperDescription = styled.div`
  width: 80%;
  margin-top: 20px;
  margin-bottom: 12px;
`;

export const ButtonsContainer = styled.div`
  margin-top: 40px;
`;

export const WrapperButton = styled.div`
  margin-top: 20px;
`;

export const Top = styled.div`
  ${({ theme }) => css`
    margin-bottom: 30px;

    display: flex;
    align-items: center;

    p {
      font-size: ${theme.font.sizes.large};
      color: ${theme.colors.title};
      font-weight: ${theme.font.bold};
    }
  `}
`;

export const ButtonClose = styled.button`
  ${({ theme }) => css`
    width: 35px;
    height: 35px;
    background-color: ${theme.colors.background_light};
    border-radius: 10px;

    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 25px;
  `}
`;

export const ModalContent = styled.div`
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Wrapper = styled.div`
  ${({ theme }) => css`
    width: 100%;
    padding: 10px;
    background-color: ${theme.colors.background_light};
    border-radius: 10px;
    display: flex;
    justify-content: space-between;
    color: ${theme.colors.text_extra_light};
    font-size: 12px;
    font-weight: 400;
  `}
`;

export const TextContainer = styled.div`
  margin-top: 70px;
  color: white;
  margin-right: 50px;
`;

export const Text = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  margin-top: 10px;
`;

export const MettersContainer = styled.div`
  display: flex;
  justify-self: center;
  align-self: center;
  justify-content: center;
  align-items: center;
  margin-bottom: 30px;
  margin-top: 10px;
`;
export const BolderText = styled.div`
  font-size: 18px;
  line-height: 22px;
  font-weight: 700;
`;

export const Marker = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 5px;
`;

export const MarkerLine = styled.div`
  border: 0.3px solid #353535;
  height: 5px;
  width: 0;
  margin-bottom: 5px;
`;

export const AddAreaText = styled.div`
  ${({ theme }) => css`
    margin-left: 10px;
    color: ${theme.colors.primary};
    font-size: 12px;
    font-weight: 400;
  `}
`;

export const AddAreaOfExpertise = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const Check = styled.div`
  ${({ theme }) => css`
    width: 10px;
    height: 10px;
    background: ${theme.colors.primary};
    border-radius: 3px;
  `};
`;
export const Checkbox = styled(ToggleButton)`
  ${({ theme }) => css`
    border-radius: 5px;
    width: 20px;
    height: 20px;
    background: transparent;
    border: 1px solid ${theme.colors.text_light_87};
    display: flex;
    justify-content: center;
    align-items: center;
  `}
`;

export const Divider = styled.div`
  ${({ theme }) => css`
    border-bottom: 0.5px solid ${theme.colors.background_light};
    margin-top: 10px;
    margin-bottom: 5px;
  `}
`;

export const ExpertiseContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 10px;
`;

export const ExpertiseAreaContainer = styled.div`
  overflow-y: auto;
`;

export const WrapperSignaturePreview = styled.div`
  ${({ theme }) => css`
    margin-top: 20px;
    min-height: 50px;
    position: relative;
    width: 100%;

    display: flex;
    align-items: center;
    justify-content: center;

    border: 1px dashed ${theme.colors.text_light};
    border-radius: 10px;

    p {
      color: ${theme.colors.text_light};
      font-size: ${theme.font.sizes.xsmall};
    }

    img {
      max-height: 480px;
      max-width: 640px;
      border-radius: 10px;
      object-fit: scale-down;
    }
  `}
`;

export const AcceptView = styled.div`
  display: flex;
  margin-top: 80px;
`;
