import React, { useEffect, useState } from "react";
import { useHistory, useLocation, useParams } from "react-router";

import { EmptyPage } from "components/EmptyPage";
import { ArrowButton } from "components/ArrowButton";
import { Button } from "components/Button";
import { EquipmentCard } from "components/EquipmentCard";
import { SearchComercialModelsManual } from "components/SearchComercialModelsManual";

import { api } from "services/api";

import { ComercialModelsShimmer } from "Shimmer/ComercialModelsShimmer";

import { useToast } from "hooks/toast";

import * as S from "./styles";

type GuideItemsProps = {
  id: string;
  name: string;
  file: string;
};

type GuideProps = {
  type: string;
  items: GuideItemsProps[];
};

export type EquipmentCardProps = {
  id: string;
  image: string;
  name: string;
  code: string;
  brand: {
    id: string;
    name: string;
    image: string;
  };

  guides: GuideProps[];
};

type ShowManualsProps = {
  equipmentId: string;
  type: "technicalManual" | "errorCode";
};

type RouteParams = {
  brandId: string;
  categoryId: string;
};

type RouteState = {
  searchedWord: string;
};

export function ComercialModelsManual() {
  const history = useHistory();
  const { addToast } = useToast();
  const location = useLocation<RouteState>();

  const { categoryId, brandId } = useParams<RouteParams>();

  const [isLoading, setIsLoading] = useState(true);
  const [comercialModelsOfCategory, setComercialModelsOfCategory] = useState<
    EquipmentCardProps[]
  >([]);

  async function searchEquipmentsWithCommercialCode(text: string) {
    try {
      setIsLoading(true);
      const response = await api.get(`/equipment?search=${text}`);

      setComercialModelsOfCategory(response.data.models);
    } catch (err) {
      // sendError(err);

      addToast({
        title: "Ops!!",
        description: "Ocorreu um erro ao buscar o modelo pelo código",
        type: "error",
      });
    } finally {
      setIsLoading(false);
    }
  }

  async function loadComercialModelsOfCategory() {
    try {
      const response = await api.get(
        `equipment?idMarca=${brandId}&idCategoria=${categoryId}`
      );

      setComercialModelsOfCategory(response.data.models);
    } catch (err) {
      // sendError(err)
      addToast({
        title: "Ops!!",
        description: "Ocorreu um erro ao listar os modelos comerciais",
        type: "error",
      });
    } finally {
      setIsLoading(false);
    }
  }

  useEffect(() => {
    if (!!location.state) {
      searchEquipmentsWithCommercialCode(location.state.searchedWord);
      return;
    }

    loadComercialModelsOfCategory();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [brandId, categoryId, addToast, location.state?.searchedWord]);

  function handleShowManuals({ equipmentId, type }: ShowManualsProps) {
    history.push(
      `/brands/${brandId}/equipments/${equipmentId}/manuals/${type}`
    );
  }

  return (
    <S.Container>
      <header>
        <ArrowButton />

        <h2>Manuais e códigos de erro</h2>

        <SearchComercialModelsManual
          description="Ou selecione um modelo."
          value={location.state?.searchedWord}
        />
      </header>

      {comercialModelsOfCategory.length === 0 ? (
        <EmptyPage />
      ) : (
        <S.Content>
          {isLoading && <ComercialModelsShimmer />}

          {!isLoading &&
            comercialModelsOfCategory.map((equipment) => (
              <S.CardContent>
                <EquipmentCard equipment={equipment} key={equipment.id} />

                <S.ButtonContent>
                  <Button
                    onClick={() => {
                      handleShowManuals({
                        equipmentId: equipment.id,
                        type: "technicalManual",
                      });
                    }}
                  >
                    Manuais
                  </Button>

                  {equipment.guides.map((guide) => {
                    if (guide.type === "codigo-erro") {
                      return (
                        <Button
                          onClick={() => {
                            handleShowManuals({
                              equipmentId: equipment.id,
                              type: "errorCode",
                            });
                          }}
                        >
                          Código de erros
                        </Button>
                      );
                    }

                    return false;
                  })}
                </S.ButtonContent>
              </S.CardContent>
            ))}
        </S.Content>
      )}
    </S.Container>
  );
}
