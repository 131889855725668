import styled, { css } from "styled-components";
import { lighten } from "polished";


export const Container = styled.div`
  align-items: center;
  min-height: 80vh;
`;

export const Top = styled.div`
  ${({ theme }) => css`
    margin-bottom: 30px;
  `}
`;

export const BackButton = styled.button`
  ${({ theme }) => css`
    width: 35px;
    height: 35px;
    background-color: ${theme.colors.background_light};
    border-radius: 10px;

    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 25px;
    margin-bottom:10px;
  `}
`;

export const Header = styled.div`
  margin-bottom: 20px;
`;

export const Title = styled.p`
  ${({ theme }) => css`
    font-size: 18px;
    font-family: ${theme.font.bold};
    color: ${theme.colors.title};
    font-weight: ${theme.font.bold};
    margin-bottom:10px;
  `}
`;

export const WrapperItem = styled.div`
  margin-top: 20px;
`;

export const SubTitle = styled.p`
  ${({ theme }) => css`
    font-size: 12px;
    font-family: ${theme.font.family};
    color: ${theme.colors.financial_text};
    letter-spacing: 0.26px;
    line-height: 16px;
  `}
`;

export const WrapperActions = styled.div`
  ${({ theme }) => css`
    display: flex;
    min-width: 47px;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    background-color: ${theme.colors.background_light};
    flex-direction: column;
    justify-content: space-between;
  `}
`;

export const ContentInfo = styled.div`
  height: 100%;
  display: flex;
  min-width: 90%;
  padding: 10px;
`;

export const CardEnvironmentInfo = styled.div`
  ${({ theme }) => css`
    display: flex;
    width: 100%;
    background: ${theme.colors.background_light};
    border-radius: 8px;
    margin-top: 10px;
    cursor: pointer;
    padding:5px;
    &:hover {
      background: ${lighten(0.07, theme.colors.menu_detail)};
    }
  `}
`;

export const TitleCard = styled.p`
  ${({ theme }) => css`
    font-size: 12px;
    font-family: ${theme.font.bold};
    color: ${theme.colors.text_extra_light};
    letter-spacing: 0.26px;
    line-height: 16px;
    margin-right:5px;
  `}
`;

export const CardInfo = styled.p`
  ${({ theme }) => css`
    font-size: 12px;
    font-family: ${theme.font.family};
    color: ${theme.colors.financial_text};
    letter-spacing: 0.26px;
    line-height: 16px;
  `}
`;

export const ViewMessage = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 60px;
  flex-direction: column;
`;

export const MessageText = styled.p`
  ${({ theme }) => css`
    font-size: 14px;
    font-family: ${theme.font.family};
    color: ${theme.colors.financial_title};
  `}
`;

export const Footer = styled.div`
  ${({ theme }) => css`
  width: 100%;
  max-width: 602px;
  height: 60px;
  position: fixed;
  bottom: 20px;
  `}
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom:10%;
`;
