import React from 'react'

import * as S from './styles'

export function EquipmentsShimmer() {
  return (
    <>
      <div>
        <S.ShimmerEquipment />
      </div>

      <div>
        <S.ShimmerEquipment />
      </div>

      <div>
        <S.ShimmerEquipment />
      </div>

      <div>
        <S.ShimmerEquipment />
      </div>

      <div>
        <S.ShimmerEquipment />
      </div>

      <div>
        <S.ShimmerEquipment />
      </div>

      <div>
        <S.ShimmerEquipment />
      </div>

      <div>
        <S.ShimmerEquipment />
      </div>

      <div>
        <S.ShimmerEquipment />
      </div>

      <div>
        <S.ShimmerEquipment />
      </div>

      <div>
        <S.ShimmerEquipment />
      </div>

      <div>
        <S.ShimmerEquipment />
      </div>
      <div>
        <S.ShimmerEquipment />
      </div>
    </>
  )
}
