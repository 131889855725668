import { lighten } from "polished";
import styled, { css, keyframes } from "styled-components";
import background from "assets/imagem-fundo_login.svg";

export const Wrapper = styled.div`
  margin-bottom: 20px;
  position: relative;
`;

export const Title2 = styled.div`
  ${({ theme }) => css`
    font-size: 42px;

    font-weight: 700;
    line-height: 30px;
    color: ${theme.colors.title};
    margin-bottom: 30px;

    @media (max-width: 1366px) {
      font-size: ${theme.font.sizes.xxlarge};
    }

    span {
      color: ${theme.colors.primary};
    }
  `}
`;

export const Dividers = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
  width: 100%;
  color: rgba(255, 255, 255, 0.6);
  margin-top: 15px;

  span {
    margin-bottom: 4px;
  }
`;

export const Divider = styled.div`
  vertical-align: middle;
  width: 100%;
  height: 1px;
  background-color: #353535;
`;

export const SocialAreaContent = styled.section`
  ${({ theme }) => css`
    margin-top: 15px;
    p {
      font-size: ${theme.font.sizes.small};
      color: ${theme.colors.title};
      margin-bottom: 20px;

      span {
        color: ${theme.colors.primary200};
        text-decoration: underline;
        font-weight: 600;
        cursor: pointer;
        line-height: 14px;
      }
    }
  `}
`;

export const ShowPassword = styled.button`
  height: 68%;
  width: 42px;

  position: absolute;
  right: 2px;
  top: 30%;
  transition: background 0.2s;

  &:hover {
    background: ${({ theme }) => lighten(0.2, theme.colors.background_light)};
  }
`;

export const Container = styled.div`
  height: 100vh;
  display: flex;
  flex-direction: column;

  margin: 0 auto;
  width: 100%;

  background: url(${background}) no-repeat;
  background-size: contain;

  header {
    color: red;
  }
`;

export const ContainerMobile = styled.div`
  height: 100vh;
  display: flex;
  width: 100%;

  justify-content: center;

  background-position: 50% 143%;
`;

export const BackgroundContent = styled.div`
  width: 100%;
  height: 100vh;
  display: grid;
  grid-template-columns: 50% 1fr;
  align-items: center;
  background-position: right -190px center;

  @media (max-width: 1366px) {
    background-size: 300px;
    background-position: right -150px center;
  }
`;

export const Background = styled.div`
  width: 100%;
  display: flex;

  justify-content: space-between;
  align-items: center;
  flex-direction: column;

  .laptop {
    width: 410px;
    margin-left: 100px;
    margin-top: 60px;
    margin-bottom: 60px;

    @media (max-width: 1532px) {
      width: 600px;
    }

    @media (max-width: 1366px) {
      margin-top: 50px;
      margin-bottom: 50px;
      width: 410px;
    }
  }

  aside {
    width: 100%;
    max-width: 680px;

    @media (max-width: 1366px) {
      max-width: 500px;
    }

    .profiz {
      margin-bottom: 50px;
      height: 40px;
    }
  }

  section {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 0 auto;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 90%;
`;

export const QrCode = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    justify-content: flex-start;

    span {
      color: ${theme.colors.text_light_87};
      font-size: 20px;
      line-height: 14px;
      font-weight: 700;

      @media (max-width: 1366px) {
        font-size: 12px;
      }
    }

    p {
      color: ${theme.colors.text_light_60};
      font-size: 14px;
      line-height: 12px;
      font-weight: 400;

      @media (max-width: 1366px) {
        font-size: 10px;
      }
    }

    img {
      margin-right: 10px;
      width: 57px;
      height: 57px;
    }
  `}
`;

export const Title = styled.h1`
  ${({ theme }) => css`
    color: ${theme.colors.title};
    font-size: 42px;
    line-height: 30px;
    margin-bottom: 10px;

    @media (max-width: 1366px) {
      font-size: 37px;
    }

    span {
      color: ${theme.colors.text};
      font-size: 42px;
      font-weight: 400;

      @media (max-width: 1366px) {
        font-size: 24px;
      }
    }
  `}
`;

export const SubTitle = styled.p`
  ${({ theme }) => css`
    color: ${theme.colors.text};
    font-size: 28px;
    line-height: 30px;

    @media (max-width: 1366px) {
      font-size: 16px;
    }

    span {
      color: ${theme.colors.title};

      font-size: 28px;
      font-weight: 500;

      @media (max-width: 1366px) {
        font-size: 16px;
      }
    }
  `}
`;

const appearFromLeft = keyframes`
  from{
    opacity: 0;
    transform: translateX(-50px)
  } to {
    opacity: 1;
    transform: translateX(0)
  }
`;

export const AnimationContainer = styled.div`
  ${({ theme }) => css`
    width: 100%;
    display: flex;
    flex-direction: column;

    align-items: center;
    justify-content: center;

    animation: ${appearFromLeft} 1s;

    form {
      & > img {
        margin-bottom: 23px;
      }

      margin: 80px 0 25px 0;
      width: 530px;
      text-align: center;

      h1 {
        margin-bottom: 25px;
      }

      a {
        color: ${theme.colors.text};
        font-size: ${theme.font.sizes.small};
        display: block;
        transition: color 0.2;
      }
    }
  `}
`;
