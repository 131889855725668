import { FiX } from "react-icons/fi";
import { useTheme } from "styled-components";
import Modal from "react-modal";

import { Button } from "components/Button";

import { useAuth } from "hooks/auth";

import "rc-slider/assets/index.css";
import * as S from "./styles";
import { useHistory } from "react-router";
import { useEffect } from "react";
import { api } from "services/api";

Modal.setAppElement("#root");

type PopUpNewsModalProps = {
  isOpen: boolean;
  setIsOpen: (value: boolean) => void;
};

const PopUpNewsModal = ({ isOpen, setIsOpen }: PopUpNewsModalProps) => {
  const { user } = useAuth();
  const history = useHistory();

  const theme = useTheme();

  useEffect(() => {
    if (!user?.popupNews?.id) {
      setIsOpen(false);
    }

    if (user?.popupNews?.id && isOpen)
      api.post(`users/popup-novidades/register`, {
        idPopup: user.popupNews.id,
      });
  }, [user?.popupNews?.id, isOpen, setIsOpen]);

  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      background: theme.colors.background,
      border: "none",
      width: 570,
      borderRadius: 10,
      maxHeight: "90vh",
    },
  };

  const handleActionPopUp = (type: "url" | "screen") => {
    if (type === "screen") {
      if (
        user.popupNews.action.data === "receipt" &&
        !user.popupNews.action.detailId
      ) {
        user.popupNews.action.data = "financial/receipts";
      }

      if (
        user.popupNews.action.data === "receipt" &&
        user.popupNews.action.detailId
      ) {
        user.popupNews.action.data = "financial/edit-receipt";
      }
    }

    const linkVariation = {
      url: () => {
        window.open(user.popupNews.action.data, "_blank");
        setIsOpen(false);
      },
      screen: () => {
        history.push({
          pathname: `${user.popupNews.action.data}/${user.popupNews.action.detailId}`,
        });
      },
    };

    linkVariation[type]();
  };

  return (
    <Modal
      isOpen={isOpen}
      style={{
        ...customStyles,
        overlay: {
          background: "rgba(0, 0, 0, 0.5)",
          zIndex: 1000,
        },
      }}
    >
      <S.ModalContent>
        <S.BackButton
          onClick={() => {
            setIsOpen(false);
          }}
        >
          <S.BolderTextTitle>{user.popupNews.title}</S.BolderTextTitle>
          <FiX cursor={"pointer"} size={16} color={theme.colors.primary} />
        </S.BackButton>

        <S.RadarIcon
          src={user.popupNews.imgDesk}
          alt={user.popupNews.title}
        ></S.RadarIcon>
        <S.TextContainer>
          <S.BolderText>{user.popupNews.subTitle}</S.BolderText>
          <S.Text>{user.popupNews.description}</S.Text>
        </S.TextContainer>
        <Button
          onClick={() => {
            handleActionPopUp(user.popupNews.action.type);
          }}
          typeButton="default"
        >
          {user.popupNews.buttonTitle}
        </Button>
      </S.ModalContent>
    </Modal>
  );
};

export default PopUpNewsModal;
