import React, { ChangeEvent, useCallback, useRef, useState } from "react";
import { FiPlus } from "react-icons/fi";
import { useTheme } from "styled-components";

import { AlertModal } from "components/AlertModal";

import { useToast } from "hooks/toast";
import { useAuth } from "hooks/auth";
import { useServiceOrder } from "hooks/serviceOrder";

import { Photo } from "..";
import apiv2 from "services/apiv2";

import * as S from "./styles";
import axios from "axios";

type SignatureResponseProps = {
  id: number;
  group: string;
  file: string;
  type: string;
  url: string;
};

type FileProps = {
  base64Image: string;
  extension: string;
};

type AddSignaturePhotoProps = {
  signatureType: "signature" | "provider-sign" | "client-sign";
  photosQuantity: number;
  handleAddNewPhoto: (signaturePhoto: Photo) => void;
  onSetProviderDigitalSignature?: (providerSignaturePhoto: Photo) => void;
  maxPhotos: number;
};

export function AddSignaturePhoto({
  signatureType,
  photosQuantity,
  maxPhotos,
  handleAddNewPhoto,
  onSetProviderDigitalSignature,
}: AddSignaturePhotoProps) {
  const { 2: serviceOrderIdFromUrl } = window.location.pathname.split("/");
  const theme = useTheme();
  const { addToast } = useToast();
  const { user } = useAuth();
  const { selectedServiceOrder, currentService } = useServiceOrder();

  const [
    isOpenModalAddProviderDigitalSignature,
    setIsOpenModalAddProviderDigitalSignature,
  ] = useState(false);

  const hiddenFileInput = useRef<HTMLInputElement | null>(null);
  const photoLimitReached = photosQuantity >= maxPhotos;

  const validationErrorAlert = () => {
    if (photoLimitReached) {
      addToast({
        title: "Máximo permitido",
        description: `Ops, o máximo de fotos permitidos é ${maxPhotos}`,
        type: "error",
      });
      return;
    }
  };

  const handleAddSignature = () => {
    validationErrorAlert();
    if (
      !photoLimitReached &&
      (signatureType === "provider-sign" || signatureType === "signature") &&
      user.digitalSign
    ) {
      setIsOpenModalAddProviderDigitalSignature(true);
      return;
    }

    hiddenFileInput.current?.click();
  };

  async function confirmDigitalSignatureFromProfile() {
    try {
      validationErrorAlert();
      setIsOpenModalAddProviderDigitalSignature(false);

      const idBudgetService = selectedServiceOrder.isNewSignVersion
        ? currentService.idBudgetService
        : 0;

      const { data } = await apiv2.post<SignatureResponseProps>(
        `/upload/service-order/${serviceOrderIdFromUrl}/budget-service/${idBudgetService}/service-sign`,
        { signType: "profile-sign", encode: "", extensao: "", grupo: "" }
      );

      if (onSetProviderDigitalSignature) {
        onSetProviderDigitalSignature({
          id: data.id,
          link: data.url,
        });
      }

      setIsOpenModalAddProviderDigitalSignature(false);

      addToast({
        title: "Sucesso!",
        description: "Foto adicionada com sucesso",
        type: "success",
      });
    } catch (error) {
      addToast({
        title: "Ops!",
        description:
          axios.isAxiosError(error) && error.response?.data.error
            ? error.response.data.error
            : "Falha ao adicionar uma foto, tente mais tarde.",
        type: "error",
      });
    }
  }

  function handleFileInput() {
    setIsOpenModalAddProviderDigitalSignature(false);
    if (hiddenFileInput.current) hiddenFileInput.current.click();
  }

  const handleGetSignaturePhotoFromFileReader = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      if (e.target.files) {
        const file = e.target.files[0];
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const [_, extension] = file.type.split("/");

        const reader = new FileReader();

        reader.readAsDataURL(file);

        reader.onload = async () => {
          const result = reader.result as string;

          const { 1: fileBase64Splitted } = result.split(",");

          await handleSubmitPhoto({
            base64Image: fileBase64Splitted,
            extension,
          });
        };
      }
    },
    [photosQuantity, addToast] // eslint-disable-line react-hooks/exhaustive-deps
  );

  async function handleSubmitPhoto({ base64Image, extension }: FileProps) {
    try {
      const idBudgetService = selectedServiceOrder.isNewSignVersion
        ? currentService.idBudgetService
        : 0;

      const response = await apiv2.post<SignatureResponseProps>(
        `/upload/service-order/${serviceOrderIdFromUrl}/budget-service/${idBudgetService}/service-sign`,
        {
          signType: signatureType,
          encode: base64Image,
          extensao: extension,
          grupo: selectedServiceOrder.isNewSignVersion
            ? `service-sign/${currentService.idBudgetService}`
            : "",
        }
      );

      const photo = {
        id: response.data.id,
        link: response.data.url,
      };

      handleAddNewPhoto(photo);

      addToast({
        title: "Sucesso",
        description: "Foto adicionada com sucesso",
        type: "success",
      });
    } catch (err) {
      addToast({
        title: "Ops!",
        description:
          axios.isAxiosError(err) && err.response?.data.error
            ? err.response.data.error
            : "Falha ao adicionar a foto, tente mais tarde.",
        type: "error",
      });
    }
  }

  return (
    <section>
      <AlertModal
        isVisible={isOpenModalAddProviderDigitalSignature}
        title="Assinatura digital cadastrada"
        description="Você possui uma assinatura cadastrada em seu perfil, deseja anexar a Ordem de Serviço?"
        labelConfirm="Sim"
        labelCancel="Não, buscar arquivo"
        action="choose"
        handleConfirm={confirmDigitalSignatureFromProfile}
        handleCancel={handleFileInput}
        onCloseModal={() => setIsOpenModalAddProviderDigitalSignature(false)}
      />

      <S.Container quantityPhotosValidation={photoLimitReached}>
        <button onClick={handleAddSignature}>
          <FiPlus size="18" color={theme.colors.primary} />
        </button>

        {photoLimitReached || (
          <input
            id={`attachment-${signatureType}`}
            type="file"
            ref={hiddenFileInput}
            accept="image/*"
            onChange={handleGetSignaturePhotoFromFileReader}
          />
        )}
      </S.Container>
    </section>
  );
}
