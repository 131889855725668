import BlockGraySvg from "assets/icons/permission/block-gray.svg";

import * as S from "./styles";
const BlockIcon = () => {
  return (
    <S.IconWrapper>
      <img src={BlockGraySvg} alt="Ícone de cadeado" />
    </S.IconWrapper>
  );
};
export { BlockIcon };
