import styled, { css } from "styled-components";

interface MarkerStatusProps {
  status: string;
}

export const Container = styled.div`
  ${({ theme }) => css`
    width: 100%;
    max-width: 1400px;
    padding: 0 30px;

    header {
      display: flex;
      align-items: center;
      margin: 30px 0;

      h1 {
        font-size: ${theme.font.sizes.large};
        color: ${theme.colors.title};
        font-weight: ${theme.font.bold};
        margin-right: 350px;
      }

      & > div:last-of-type {
        margin-left: 20px;
      }
    }
  `}
`;

export const Content = styled.div`
  width: 540px;
  margin-bottom: 8%;
  margin-left: 14px;

  & > div:first-of-type {
    width: 92%;
    margin-bottom: 20px;
    border-radius: 15px;
    background-color: ${({ theme }) => theme.colors.background_light};

    display: flex;
    flex-direction: column;
    justify-content: center;

    .DayPicker-Day,
    .DayPicker-Day--selected {
      padding: 0;
      height: 40px;
      width: 40px;
      border-radius: 50%;
    }

    .DayPicker,
    .DayPicker-Month {
      width: 94%;
      border-spacing: 20px;
    }
  }

  & > p {
    ${({ theme }) => css`
      font-size: ${theme.font.sizes.small};
      color: ${theme.colors.title};
      margin: 40px 0 26px;
    `}
  }
`;

export const WrapperOrder = styled.div`
  ${({ theme }) => css`
    margin-bottom: 26px;

    & > span {
      display: block;
      font-size: ${theme.font.sizes.xsmall};
      color: ${theme.colors.title};
      margin-bottom: 10px;
    }
  `}
`;

export const WrapperStatusService = styled.div`
  display: flex;
  align-items: center;
`;

export const MarkerStatusService = styled.div<MarkerStatusProps>`
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background-color: ${({ status, theme }) =>
    status === "concluded" ? theme.colors.primary : theme.colors.model};
  margin-right: 6px;
`;
