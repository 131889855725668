import styled, { css } from "styled-components";


export const Container = styled.div`
  ${({ theme }) => css`
    width: 100%;
    max-width: 1400px;
    min-width: 470px;
    margin: 0 auto;
    padding: 30px;

    header {
      display: flex;
      align-items: center;

      h1 {
        font-size: ${theme.font.sizes.large};
        color: ${theme.colors.title};
        font-weight: ${theme.font.bold};
        margin-left: 25px;
      }

      div {
        width: 80px;
        display: flex;
        justify-content: space-between;
      }
    }
  `}
`;

export const Top = styled.div`
  ${({ theme }) => css`
    margin-bottom: 30px;

    display: flex;
    align-items: center;
    p {
      font-size: ${theme.font.sizes.large};
      color: ${theme.colors.title};
      font-weight: ${theme.font.bold};
    }
  `}
`;

export const BackButton = styled.button`
  ${({ theme }) => css`
    width: 35px;
    height: 35px;
    background-color: ${theme.colors.background_light};
    border-radius: 10px;

    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 25px;
  `}
`;


export const Title = styled.p`
  ${({ theme }) => css`
    font-size: 18px;
    font-family: ${theme.font.bold};
    color: ${theme.colors.text_extra_light};
    letter-spacing: 0.4px;
    line-height: 20px;
    margin-bottom: 5px;
  `}
`;

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  margin-left: 14px;
`;



export const Content = styled.section`
  
  display:flex;
  flex-direction:row;
`;

