import styled, { css } from "styled-components";

export const Container = styled.div`
  ${({ theme }) => css`
    width: 52%;
    min-width: 652px;
    height: 100%;
    margin-bottom: 50px;
    padding-left: 30px;
    padding-top: 20px;
    overflow: hidden;

    border-right: 1px solid ${theme.colors.background_light};
  `}
`;

export const Wrapper = styled.div`
  ${({ theme }) => css`
    padding: 5px;
    width: 100%;
    background-color: ${theme.colors.background_light};
    border-radius: 10px;
    display: flex;
    align-items: center;
    cursor: default;
    margin-top: 20px;
  `}
`;

export const TagsContent = styled.div`
  grid-template-columns: repeat(3, 1fr);
  display: grid;
`;

export const TextRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  color: white;
  white-space: pre-wrap;
`;

export const Top = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;

    h4 {
      font-size: ${theme.font.sizes.large};
      color: ${theme.colors.title};
      font-weight: ${theme.font.bold};
    }
  `}
`;

export const Title = styled.div`
  ${({ theme }) => css`
    font-size: ${theme.font.sizes.large};
    color: ${theme.colors.title};
    font-weight: ${theme.font.bold};
    margin-bottom: 20px;
    margin-top: 20px;
    cursor: default;
  `}
`;

export const ButtonClose = styled.button`
  ${({ theme }) => css`
    width: 35px;
    height: 35px;
    background-color: ${theme.colors.background_light};
    border-radius: 10px;

    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 25px;
  `}
`;

export const Content = styled.main`
  width: 90%;
  margin-bottom: 110px;
`;

export const ContentBox = styled.div`
  ${({ theme }) => css`
    padding: 22px 16px;
    border-radius: 10px;
    background-color: ${theme.colors.background_light};

    & > div {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 8px;

      & > div {
        display: flex;
        align-items: center;
      }
    }
  `}
`;

export const StarImage = styled.img`
  width: 16px;
  height: 16px;
  margin-right: 6px;
`;

export const TextDetailGreen = styled.p`
  ${({ theme }) => css`
    font-size: ${theme.font.sizes.xsmall};
    color: ${theme.colors.primary};
  `}
`;

export const TitleDetail = styled.p`
  ${({ theme }) => css`
    font-size: ${theme.font.sizes.xsmall};
    color: ${theme.colors.title};
    margin-bottom: 10px;
  `}
`;

export const TextDetail = styled.p`
  ${({ theme }) => css`
    font-size: ${theme.font.sizes.xsmall};
    color: ${theme.colors.text};
    line-height: 19px;
  `}
`;

export const TextRating = styled(TextDetail)`
  font-size: ${({ theme }) => theme.font.sizes.small};
`;

export const Label = styled.label`
  ${({ theme }) => css`
    font-size: ${theme.font.sizes.xsmall};
    color: ${theme.colors.title};
    margin-bottom: 12px;
    display: block;
  `}
`;

export const WrapperRating = styled.div`
  ${({ theme }) => css`
    margin-top: 30px;

    & > div {
      display: flex;
      align-items: center;

      & > p {
        font-size: ${theme.font.sizes.xsmall};
        color: ${theme.colors.text};
        font-weight: 600;
        margin-left: 12px;
      }
    }
  `}
`;

export const Star = styled.img`
  width: 30px;
  height: 30px;
  margin-right: 10px;
`;

export const WrapperButtons = styled.div`
  margin-top: 30px;

  & > div {
    width: 56%;
    max-width: 315px;
    display: flex;
    flex-wrap: wrap;
  }
`;

export const Button = styled.button`
  ${({ theme }) => css`
    height: 35px;
    border-radius: 8px;
    padding: 0 12px;
    margin-right: 10px;
    margin-bottom: 10px;

    display: flex;
    align-items: center;
    justify-content: center;

    font-size: ${theme.font.sizes.xsmall};
    font-weight: ${theme.font.bold};
    color: ${theme.colors.text};
    background-color: ${theme.colors.background_modal};
  `}
`;

export const WrapperComments = styled.div`
  margin-top: 30px;
`;

export const WrapperButtonText = styled.div`
  margin-top: 30px;
  display: flex;
  align-items: center;

  & > span {
    font-size: ${({ theme }) => theme.font.sizes.xsmall};
    color: ${({ theme }) => theme.colors.text};
    cursor: pointer;
    margin-right: 8px;

    &:hover {
      text-decoration: underline;
    }
  }

  & > img {
    width: 14px;
    height: 14px;
  }
`;
