import { StatusProps } from '../dtos/LocalChecklistDTO'

export function sortStatusLocalChecklist(status: StatusProps[]) {
  const orderStatus = status.map((statusItem) => {
    if (statusItem.type === 'available') {
      return { ...statusItem, order: 1 }
    }

    if (statusItem.type === 'client_responsibility') {
      return { ...statusItem, order: 2 }
    }

    return { ...statusItem, order: 3 }
  })

  const sortedStatus = orderStatus.sort(
    (first, secondary) => first.order - secondary.order
  )

  return sortedStatus
}
