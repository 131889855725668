import styled, { css } from 'styled-components'
import { opacify } from 'polished'

type ColorProps = {
  color: 'default' | 'primary'
}

type CheckboxProps = {
  isChecked: boolean
}

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`

export const Label = styled.span`
  ${({ theme }) => css`
    font-size: ${theme.font.sizes.xsmall};
    color: ${theme.colors.title};

    margin-bottom: 10px;
  `}
`

export const Button = styled.button<ColorProps>`
  ${({ theme, color }) => css`
    height: 45px;
    border-radius: 8px;
    padding: 8px 12px;
    background: ${color === 'primary'
      ? theme.colors.primary_medium
      : theme.colors.background_light};

    display: flex;
    align-items: center;
    justify-content: center;
    transition: background 0.2s;

    &:hover {
      background: ${opacify(0.03, theme.colors.background_light)};
    }
  `}
`

export const CheckBox = styled.div`
  ${({ theme }) => css`
    padding: 3px;
    margin-right: 15px;

    display: flex;
    align-items: center;
    justify-content: center;

    border-radius: 3px;
    border: 1px solid ${theme.colors.text};
  `}
`

export const MarkChecked = styled.div<CheckboxProps>`
  ${({ theme, isChecked }) => css`
    width: 7px;
    height: 7px;
    border-radius: 1.2px;

    background-color: ${isChecked
      ? theme.colors.primary
      : theme.colors.background_light};
  `};
`

export const Placeholder = styled.span<ColorProps>`
  ${({ theme, color }) => css`
    font-size: ${theme.font.sizes.xsmall};
    color: ${color === 'primary' ? theme.colors.primary : theme.colors.text};
  `}
`
