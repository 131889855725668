import React, { createContext, useContext, useState } from "react";

import {
  RegisterProps,
  ServiceChecklistDTO,
} from "../dtos/ServiceChecklistDTO";
import { ServiceOrderDTO } from "../dtos/ServiceOrderDTO2";
import { ServiceWithChecklistDTO } from "../dtos/ServiceWithChecklistDTO";
import { ProviderProps } from "hooks";

type ServiceOrderContextData = {
  selectedServiceOrder: ServiceOrderDTO;
  currentService: ServiceWithChecklistDTO;
  currentServiceChecklist: ServiceChecklistDTO;
  handleSetSelectedServiceOrder: (serviceOrder: ServiceOrderDTO) => void;
  handleSetCurrentServiceChecklist: (
    currentServiceChecklist: ServiceChecklistDTO
  ) => void;
  handleSetCurrentService: (service: ServiceWithChecklistDTO) => void;
  handleMarkServiceAsCompleted: () => void;
  handleUpdateRegister: (updateRegister: RegisterProps) => void;
  handleDeletePhotoFromRegister: (photoId: number, registerId: number) => void;
};

const ServiceOrderContext = createContext<ServiceOrderContextData>(
  {} as ServiceOrderContextData
);

export function useServiceOrder(): ServiceOrderContextData {
  const context = useContext(ServiceOrderContext);

  if (!context) {
    throw Error("useServiceOrder must be used within a ServiceOrderProvider");
  }

  return context;
}

export function ServiceOrderProvider({ children }: ProviderProps) {
  const [selectedServiceOrder, setSelectedServiceOrder] =
    useState<ServiceOrderDTO>({} as ServiceOrderDTO);
  const [currentService, setCurrentService] = useState(
    {} as ServiceWithChecklistDTO
  );
  const [currentServiceChecklist, setCurrentServiceChecklist] =
    useState<ServiceChecklistDTO>({} as ServiceChecklistDTO);

  function handleSetSelectedServiceOrder(serviceOrder: ServiceOrderDTO) {
    setSelectedServiceOrder(serviceOrder);
  }

  function handleSetCurrentService(service: ServiceWithChecklistDTO) {
    setCurrentService(service);
  }

  function handleSetCurrentServiceChecklist(
    currentServiceChecklist: ServiceChecklistDTO
  ) {
    setCurrentServiceChecklist(currentServiceChecklist);
  }

  function handleMarkServiceAsCompleted() {
    const updatingServiceStatus: ServiceWithChecklistDTO[] =
      selectedServiceOrder.services.map((service) => {
        if (service.idBudgetService === currentService.idBudgetService) {
          return {
            ...service,
            status: "concluded",
          };
        }
        return service;
      });

    setSelectedServiceOrder({
      ...selectedServiceOrder,
      services: updatingServiceStatus,
    });
  }

  function handleUpdateRegister(updateRegister: RegisterProps) {
    const updateRegisters = currentServiceChecklist.registers.map(
      (register) => {
        if (register.id === updateRegister.id) {
          return updateRegister;
        }
        return register;
      }
    );

    setCurrentServiceChecklist({
      ...currentServiceChecklist,
      registers: updateRegisters,
    });

    const servicesFormated = selectedServiceOrder.services.map((service) => {
      if (service.idBudgetService === currentService.idBudgetService) {
        return {
          ...service,
          service: {
            ...service.service,
            ...(service.service.serviceChecklist
              ? {
                  serviceChecklist: [
                    {
                      ...service.service.serviceChecklist[0],
                      registers: updateRegisters,
                    },
                  ],
                }
              : {}),
          },
        };
      }
      return service;
    });

    setSelectedServiceOrder({
      ...selectedServiceOrder,
      services: servicesFormated,
    });
  }

  function handleDeletePhotoFromRegister(photoId: number, registerId: number) {
    const updateRegisters = currentServiceChecklist.registers.map(
      (register) => {
        if (
          register.id === registerId &&
          register.photos &&
          register.photosFormatted
        ) {
          const filteredPhotos = register.photos.filter(
            (photo) => photo.id !== photoId
          );
          const filteredPhotosFormatted = register.photosFormatted.filter(
            (photo) => photo.id !== photoId
          );

          return {
            ...register,
            photos: filteredPhotos,
            photosFormatted: filteredPhotosFormatted,
          };
        }
        return register;
      }
    );

    setCurrentServiceChecklist({
      ...currentServiceChecklist,
      registers: updateRegisters,
    });
  }

  return (
    <ServiceOrderContext.Provider
      value={{
        selectedServiceOrder,
        currentService,
        currentServiceChecklist,
        handleSetSelectedServiceOrder,
        handleSetCurrentService,
        handleSetCurrentServiceChecklist,
        handleMarkServiceAsCompleted,
        handleUpdateRegister,
        handleDeletePhotoFromRegister,
      }}
    >
      {children}
    </ServiceOrderContext.Provider>
  );
}
