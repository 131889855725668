import React from "react";

import { MessageProps } from "dtos/FeedbackDTO";

import * as S from "./styles";

type Props = {
  message: MessageProps;
};

export function MessageBox({ message }: Props) {
  return (
    <S.Container isAdm={message.isAdm}>
      {!message.isAdm ? (
        <>
          <S.MessageBalloon>
            <S.Text>{message.message}</S.Text>
          </S.MessageBalloon>
          <S.Detail isAdm={message.isAdm} />

          <S.ProfilePhoto src={message.image} isAdm={message.isAdm} />
        </>
      ) : (
        <>
          <S.ProfilePhoto src={message.image} isAdm={message.isAdm} />

          <S.Detail isAdm={message.isAdm} />
          <S.MessageBalloon>
            <S.Text isAdm={message.isAdm}>{message.message}</S.Text>
          </S.MessageBalloon>
        </>
      )}
    </S.Container>
  );
}
