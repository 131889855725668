import { createContext, useContext, useState } from "react";
import { UnityDTO } from "dtos/ClientDTO";
import { ProviderProps } from "hooks";

type InitialPmocProps = {
  id: number;
  name: string;
};

type PeriodProps = {
  period: number;
  frequency: string;
};

type ARTFileProps = {
  url: string;
};

type PmocContextData = {
  initialPmocProps: InitialPmocProps;
  handleSetInitialPmocProps: (props: InitialPmocProps) => void;

  periodProps: PeriodProps;
  handleSetPeriodProps: (props: PeriodProps) => void;

  startDate: string;
  handleSetStartDate: (date: string) => void;

  hasUnity: boolean;
  handleSetHasUnity: (state: boolean) => void;
  unity: UnityDTO;
  handleSetUnity: (unity: UnityDTO) => void;
  clearUnityData: () => void;
  pmocArtFile: ARTFileProps;
  handleSetPmocArtFile: (file: ARTFileProps) => void;
  artFileCertificationEncode: string;
  artFileCertificationType: string;
  handleSetArtEncode: (encode: string) => void;
  handleSetArtExtension: (extension: string) => void;
  clearArt: () => void;
  contractStartDate: string;
  handleSetContractStartDate: (date: string) => void;
  clearPmocData: () => void;
};

const PmocContext = createContext({} as PmocContextData);

export function usePmoc() {
  const context = useContext(PmocContext);
  if (!context) {
    throw new Error("usePmoc must be used within a PmocProvider");
  }

  return context;
}

export function PmocProvider({ children }: ProviderProps) {
  const [initialPmocProps, setInitialPmocProps] = useState(
    {} as InitialPmocProps
  );
  const [periodProps, setPeriod] = useState({} as PeriodProps);

  const [hasUnity, setHasUnity] = useState(false);
  const [unity, setUnity] = useState({} as UnityDTO);

  const [artFileCertificationEncode, setArtFileCertificationEncode] =
    useState("");
  const [artFileCertificationType, setArtFileCertificationType] = useState("");

  const [pmocArtFile, setPmocArtFile] = useState({} as ARTFileProps);

  const [contractStartDate, setContractStartDate] = useState("");

  const [startDate, setStartDate] = useState("");

  function handleSetStartDate(date: string) {
    setStartDate(date);
  }

  function handleSetPmocArtFile(file: ARTFileProps) {
    setPmocArtFile(file);
  }

  function handleSetPeriodProps(periodProps: PeriodProps) {
    let periodFrequency = periodProps.frequency;

    if (periodProps.frequency === "anos") {
      periodFrequency = periodProps.period > 1 ? `anos` : `ano`;
    } else {
      periodFrequency = periodProps.period > 1 ? `meses` : `mês`;
    }

    setPeriod({
      ...periodProps,
      frequency: periodFrequency,
    });
  }

  function clearPmocData() {
    setHasUnity(false);
    setUnity({} as UnityDTO);
    setInitialPmocProps({} as InitialPmocProps);
    setArtFileCertificationEncode("");
    setArtFileCertificationType("");
    setContractStartDate("");
    setStartDate("");
  }

  function handleSetContractStartDate(date: string) {
    setContractStartDate(date);
  }

  function clearArt() {
    setArtFileCertificationEncode("");
    setArtFileCertificationType("");
  }

  function handleSetArtEncode(encode: string) {
    setArtFileCertificationEncode(encode);
  }

  function handleSetArtExtension(extension: string) {
    setArtFileCertificationType(extension);
  }

  function handleSetInitialPmocProps(props: InitialPmocProps) {
    setInitialPmocProps(props);
  }

  function handleSetUnity(unity: UnityDTO) {
    setUnity(unity);
    setHasUnity(true);
  }

  function handleSetHasUnity(state: boolean) {
    setHasUnity(state);
  }

  function clearUnityData() {
    setHasUnity(false);
    setUnity({} as UnityDTO);
  }

  return (
    <PmocContext.Provider
      value={{
        initialPmocProps,
        handleSetInitialPmocProps,
        unity,
        hasUnity,
        handleSetHasUnity,
        handleSetUnity,
        clearUnityData,
        artFileCertificationEncode,
        artFileCertificationType,
        handleSetArtEncode,
        handleSetArtExtension,
        clearArt,
        contractStartDate,
        handleSetContractStartDate,
        clearPmocData,
        periodProps,
        handleSetPeriodProps,
        pmocArtFile,
        handleSetPmocArtFile,
        startDate,
        handleSetStartDate,
      }}
    >
      {children}
    </PmocContext.Provider>
  );
}
