import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";

import { CertificationsShimmer } from "Shimmer/CertificationsShimmer";
import { ArrowButton } from "components/ArrowButton";
import { ButtonPlusDashed } from "components/ButtonPlusDashed";
import {
  CertificationCard,
  CertificationProps,
} from "components/CertificationCard";

import { api } from "services/api";

import { useToast } from "hooks/toast";

import * as S from "./styles";

export function Certifications() {
  const history = useHistory();
  const { addToast } = useToast();

  const [isLoading, setIsLoading] = useState(true);
  const [certifications, setCertifications] = useState<CertificationProps[]>(
    []
  );

  useEffect(() => {
    async function loadCertifications() {
      try {
        const { data } = await api.get("/me/certifications", {
          params: {
            status: "approved",
          },
        });

        setCertifications(data.certifications);
      } catch (error) {
        addToast({
          title: "Ops!!",
          description: "Ocorreu um erro ao listar as certificações",
          type: "error",
        });
      } finally {
        setIsLoading(false);
      }
    }
    loadCertifications();
  }, [addToast]);

  function handleNavigateCreateCertification() {
    history.push("certifications/create");
  }

  function handleNavigateToCertificationDetails(
    certification: CertificationProps
  ) {
    history.push({
      pathname: "certification-details",
      state: { certification },
    });
  }

  return (
    <S.Container>
      <header>
        <div>
          <ArrowButton />
        </div>

        <h1>Minhas certificações</h1>
      </header>

      <S.Content>
        {isLoading && <CertificationsShimmer />}

        {!isLoading && (
          <>
            <ButtonPlusDashed
              label="Adicionar certificado"
              onClick={() => handleNavigateCreateCertification()}
            />
            {certifications.map((certification) => (
              <CertificationCard
                key={certification.id}
                data={certification}
                onClick={() =>
                  handleNavigateToCertificationDetails(certification)
                }
              />
            ))}
          </>
        )}
      </S.Content>
    </S.Container>
  );
}
