/* eslint-disable no-useless-escape */
import React, { useState } from "react";
import { useTheme } from "styled-components";
import { useHistory } from "react-router-dom";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import * as Yup from "yup";

import { Input } from "components/Input";
import { Button } from "components/Button";

import { ResetPasswordData } from "..";
import { useToast } from "hooks/toast";
import getValidationErrors from "utils/getValidationErrors";

import { api } from "services/api";
import * as S from "../styles";
import { PasswordVerification } from "components/PasswordVerification";

import profizLap from "assets/laptop5.svg";
import qrcode from "assets/qrcode.svg";
import profiz from "assets/Profiz.svg";

type Errors = {
  [key: string]: string;
};

type ResetPasswordProps = {
  resetPasswordData: ResetPasswordData;
};

export function ResetPassword({ resetPasswordData }: ResetPasswordProps) {
  const { addToast } = useToast();
  const { goBack } = useHistory();
  const theme = useTheme();

  const { code, email } = resetPasswordData;

  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);

  const [isLoading, setIsLoading] = useState(false);
  const [hasError, setHasError] = useState<Errors>({} as Errors);

  async function handleConfirmNewPassword() {
    setIsLoading(true);
    try {
      const schema = Yup.object().shape({
        password: Yup.string()
          .required("Senha é obrigatória")
          .min(8, "A senha deve conter pelo menos 8 dígitos"),
      });

      await schema.validate({ password }, { abortEarly: false });

      setHasError({});

      await api.put(`/users/password-recovery/${code}/update`, {
        email: email,
        password,
      });

      addToast({
        title: "Tudo certo!",
        description:
          "Sua senha foi alterada com sucesso. Você já pode fazer login novamente.",
      });

      goBack();
    } catch (error) {
      if (error instanceof Yup.ValidationError) {
        const errors = getValidationErrors(error);
        setHasError(errors);
        addToast({
          title: "Ops!!",
          description: errors.password,
        });
        return;
      }

      addToast({
        title: "Ops!!",
        description: "Ocorreu um erro ao resetar sua senha, tente novamente",
        type: "error",
      });
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <S.BackgroundContent>
      <S.Background>
        <aside>
          <img className="profiz" src={profiz} alt="logo da profiz" />

          <section>
            <S.Title>
              Boas vindas <span>à Profiz!</span>
            </S.Title>

            <S.SubTitle>
              A solução <span> grátis </span>
              completa para <span> gestão de serviços.</span>
            </S.SubTitle>

            <img
              className="laptop"
              src={profizLap}
              alt="imagens do sistema profiz versao web e mobile"
            />

            <S.QrCode>
              <img src={qrcode} alt="qr code" />

              <div>
                <span>Baixe o aplicativo.</span>
                <p>Disponível para Android e iOS, escaneie o QR Code.</p>
              </div>
            </S.QrCode>
          </section>
        </aside>
      </S.Background>
      <S.Content>
        <div className="content">
          <S.Description>
            Pronto, agora você já pode criar uma nova senha para o seu cadastro!
          </S.Description>

          <S.WrapperInput
            onKeyPress={(e) => {
              if (e.key === "Enter") {
                e.preventDefault();
                handleConfirmNewPassword();
              }
            }}
            style={{ marginBottom: 0 }}
          >
            <Input
              name="Nova senha"
              type={showPassword ? "text" : "password"}
              placeholder="Digite sua senha"
              onChange={(text) => setPassword(text.currentTarget.value)}
              hasError={hasError.password}
              onFocusClearError={() =>
                setHasError({ ...hasError, password: "" })
              }
            />

            <S.ShowPassword
              type="button"
              onClick={() => setShowPassword(!showPassword)}
            >
              {showPassword ? (
                <AiOutlineEyeInvisible
                  size={27}
                  color={theme.colors.subtitle}
                />
              ) : (
                <AiOutlineEye size={27} color={theme.colors.subtitle} />
              )}
            </S.ShowPassword>
          </S.WrapperInput>

          <S.WrapperPasswordBar>
            <PasswordVerification password={password} />
          </S.WrapperPasswordBar>

          <S.WrapperButton>
            <Button
              loading={isLoading}
              onClick={() => handleConfirmNewPassword()}
            >
              Confirmar nova senha
            </Button>
          </S.WrapperButton>
        </div>
      </S.Content>
    </S.BackgroundContent>
  );
}
