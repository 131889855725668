import React, { ChangeEvent, useEffect, useState } from "react";
import * as Yup from "yup";
import axios from "axios";
import { useTheme } from "styled-components";
import { MdCreate } from "react-icons/md";
import { HiOutlineUser } from "react-icons/hi";
import { lighten } from "polished";
import Slider from "rc-slider";

import { ArrowButton } from "components/ArrowButton";
import { Button } from "components/Button";
import { Input } from "components/Input";
import { CardUploadDigitalSign } from "components/CardUploadDigitalSign";
import { Dropdown } from "components/DropDown";
import { states } from "components/UnityForm/states";
import { ModalRight } from "components/ModalRight";
import { AlertModal } from "components/AlertModal";
import { LoadingPageModal } from "components/LoadingPageModal";
import { ModalPolicyAndTermOfUse } from "components/ModalPolicyAndTermOfUse";
import { CardProfileUnderAnalysis } from "components/CardProfileUnderAnalysis";
import { NewAlertModal } from "components/NewAlertModal";

import { useAuth } from "hooks/auth";
import { useToast } from "hooks/toast";

import { AreasOfExpertise } from "dtos/UserDTO";

import { api } from "services/api";
import { getCep } from "services/cep";
import getValidationErrors from "utils/getValidationErrors";

import trashSvg from "assets/icons/trash.svg";

import * as S from "./styles";

type Errors = {
  [key: string]: string;
};

type PersonModeProps = {
  toggleModal(): void;
};

type ExpertiseArea = {
  id: string;
  name: string;
};

type ArrayExpertise = {
  letter: string;
  items: ExpertiseArea[];
};

type ModalPolicyAndTermOfUseProps = {
  isVisible: boolean;
  policyOrTermOfUse: "policy" | "termOfUse";
};

const marks = {
  0: (
    <S.Marker>
      <S.MarkerLine />
      <div>0</div>
    </S.Marker>
  ),
  50: (
    <S.Marker>
      <S.MarkerLine />
      <div>50</div>
    </S.Marker>
  ),
  100: (
    <S.Marker>
      <S.MarkerLine />
      <div>100</div>
    </S.Marker>
  ),
  150: (
    <S.Marker>
      <S.MarkerLine />
      <div>150</div>
    </S.Marker>
  ),
  200: (
    <S.Marker>
      <S.MarkerLine />
      <div>200</div>
    </S.Marker>
  ),
  250: (
    <S.Marker>
      <S.MarkerLine />
      <div>250</div>
    </S.Marker>
  ),
  300: (
    <S.Marker>
      <S.MarkerLine />
      <div>300</div>
    </S.Marker>
  ),
  350: (
    <S.Marker>
      <S.MarkerLine />
      <div>350</div>
    </S.Marker>
  ),
  400: (
    <S.Marker>
      <S.MarkerLine />
      <div>400</div>
    </S.Marker>
  ),
};

export function PersonMode({ toggleModal }: PersonModeProps) {
  const { addToast } = useToast();
  const { user, updateUser, signOut } = useAuth();

  const [name, setName] = useState("");
  const [cep, setCep] = useState("");
  const [city, setCity] = useState("");
  const [uf, setUf] = useState("");
  const [street, setStreet] = useState("");
  const [neighborhood, setNeighborhood] = useState("");
  const [number, setNumber] = useState("");
  const [complement, setComplement] = useState("");
  const [phone, setPhone] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [companyLogo, setCompanyLogo] = useState("");
  const [cnpj, setCnpj] = useState("");
  const [digitalSign, setDigitalSign] = useState("");

  const [hasError, setHasError] = useState<Errors>({} as Errors);

  const [loadingButton, setLoadingButton] = useState(false);
  const [loading, setLoading] = useState(true);
  const [loadingButtonDeleteAcount, setLoadingButtonDeleteAcount] =
    useState(false);

  const [expertiseArea, setExpertiseArea] = useState<ArrayExpertise[]>([]);

  const [expertiseForm, setExpertiseForm] = useState({
    distanceExpertice: 10,
  });
  const [checkedExpertise, setCheckedExpertise] = useState<string[]>([]);
  const [areasOfExpertise, setAreasOfExpertise] = useState<AreasOfExpertise[]>(
    []
  );
  const [areasOfExpertiseLoaded, setAreasOfExpertiseLoaded] = useState<
    AreasOfExpertise[]
  >([]);
  const [actuationAreaModal, setActuactionAreaModal] = useState(false);
  const [signatureModalVisible, setSignatureModalVisible] = useState(false);
  const [acceptedExclusionTerm, setAcceptedExclusionTerm] = useState(false);
  const [modalDeleteAcountVisible, setModalDeleteAcountVisible] =
    useState(false);
  const [modalConfirmDeleteAcountVisible, setModalConfirmDeleteAcountVisible] =
    useState(false);

  const [modalPolicyAndTermOfUseProps, setModalPolicyAndTermOfUseProps] =
    useState<ModalPolicyAndTermOfUseProps>({
      isVisible: false,
    } as ModalPolicyAndTermOfUseProps);

  const theme = useTheme();

  useEffect(() => {
    setName(user.name);
    setCep(user.address!.cep);
    setCity(user.address!.city);
    if (user.document.cnpj) {
      setCnpj(user.document.cnpj);
    }
    setUf(user.address!.state);
    setStreet(user.address!.street);
    setNeighborhood(user.address!.neighborhood);
    setNumber(user.address!.number);
    setComplement(user.address!.complement);
    setPhone(() => {
      const dddPhone = user.phone!.slice(0, 2);
      const firstPart = user.phone!.slice(2, 7);
      const secondaryPart = user.phone!.slice(7, 11);

      return `(${dddPhone}) ${firstPart}-${secondaryPart}`;
    });
    setExpertiseForm({
      distanceExpertice: Number(user.distanceLimitActuation) ?? 10,
    });

    setCompanyName(user.companyName!);
    setCompanyLogo(user.companyLogo!);
    setDigitalSign(user.digitalSign!);
    loadExpertiseArea();

    setTimeout(() => {
      setLoading(false);
    }, 300);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  const loadExpertiseArea = async () => {
    try {
      const { data } = await api.get("/users/areas-of-expertise");
      const arrayToSet: ArrayExpertise[] = [];
      const letterArray: string[] = [];
      const filteredData = data.areasOfExpertise.sort(
        (a: AreasOfExpertise, b: AreasOfExpertise) =>
          a.name.localeCompare(b.name)
      );

      filteredData.forEach((item: ExpertiseArea) => {
        const letterArrayIndex = letterArray.findIndex(
          (letter) => item.name[0] === letter
        );
        if (letterArrayIndex < 0) {
          letterArray.push(item.name[0]);
          arrayToSet.push({
            letter: item.name[0],
            items: [item],
          });
        } else {
          const index = arrayToSet.findIndex(
            (setLetter) => setLetter.letter === item.name[0]
          );
          arrayToSet[index].items.push(item);
        }
      });

      // eslint-disable-next-line array-callback-return
      let expertiseArea = filteredData.map((item: AreasOfExpertise) => {
        const index = user.areasOfExpertise.findIndex((area) =>
          typeof area === "object" ? area.id === item.id : -1
        );
        if (index >= 0) {
          return item.id;
        }
      });

      // eslint-disable-next-line array-callback-return
      let expertiseAreaFull = filteredData.map((item: AreasOfExpertise) => {
        const index = user.areasOfExpertise.findIndex((area) =>
          typeof area === "object" ? area.id === item.id : -1
        );
        if (index >= 0) {
          return item;
        }
      });

      expertiseAreaFull = expertiseAreaFull.filter(
        (item: AreasOfExpertise) => typeof item !== "undefined"
      );

      setAreasOfExpertiseLoaded(filteredData);
      setAreasOfExpertise(expertiseAreaFull);
      expertiseArea = expertiseArea.filter(
        (item: string) => typeof item !== "undefined"
      );
      setExpertiseArea(arrayToSet);
      if (expertiseArea.length > 0) {
        setCheckedExpertise(expertiseArea);
      }
    } catch (error) {
      addToast({
        title: "Erro",
        description: "Ocorreu um erro ao carregar os banners.",
        type: "error",
      });
    }
  };

  const getChecked = (id: string) => {
    return checkedExpertise.findIndex((item) => item === id);
  };

  const checkExpertise = (id: string, idx: number) => {
    const index = checkedExpertise.findIndex((item) => item === id);
    const expertiseIndex = areasOfExpertiseLoaded.findIndex(
      (item) => item.id === id
    );
    if (index < 0) {
      setCheckedExpertise([...checkedExpertise, id]);
      areasOfExpertise.push(areasOfExpertiseLoaded[expertiseIndex]);
    } else {
      checkedExpertise.splice(index, 1);
      setCheckedExpertise([...checkedExpertise]);
      areasOfExpertise.splice(idx, 1);
    }
    setAreasOfExpertise([...areasOfExpertise]);
  };

  async function fetchAddressWithCep() {
    const formattedCep = cep.replaceAll("_", "").replace("-", "");
    if (formattedCep.length < 8) return;

    const response = await getCep(cep);

    if (response.erro) {
      addToast({
        title: "Erro",
        description: "CEP não encontrado",
        type: "error",
      });
      return;
    }

    setCity(response.localidade);
    setUf(response.uf);
    setStreet(response.logradouro);
    setNeighborhood(response.bairro);
    setComplement(response.complemento);
  }

  function handleUpdateCompanyLogo(event: ChangeEvent<HTMLInputElement>) {
    if (event.target.files) {
      const url = URL.createObjectURL(event.target.files[0]);

      setCompanyLogo(url);

      const file = event.target.files[0];

      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const [_, extension] = file.type.split("/");

      const reader = new FileReader();

      reader.readAsDataURL(file);

      reader.onload = ({ target }) => {
        const base64 = target?.result as string;
        const splitedBase64 = base64.split(",")[1];

        api
          .put("/users/me/company-logo", {
            photo: {
              encode: splitedBase64,
              extensao: extension,
            },
          })
          .then((response) => {
            updateUser(response.data);

            addToast({
              title: "Sucesso",
              description: "Logo atualizada com sucesso",
              type: "success",
            });
          })
          .catch(() => {
            addToast({
              title: "Ops",
              description: "Erro ao atualizar logo da empresa.",
              type: "error",
            });
          });
      };

      reader.onerror = () => {
        addToast({
          title: "Ops",
          description: "Erro ao atualizar logo da empresa.",
          type: "error",
        });
      };
    }
  }

  async function handleUpdateUser() {
    setLoadingButton(true);

    const companyDocument =
      user.document?.type === "PJ"
        ? Yup.string().required("O cnpj da empresa é obrigatório.")
        : Yup.string();

    try {
      if (expertiseForm.distanceExpertice <= 0) {
        addToast({
          title: "Ops",
          description: "Limitação de distância deve ser maior que 0 Km",
          type: "error",
        });
        return;
      }

      const schema = () => {
        // eslint-disable-next-line no-shadow
        return Yup.object().shape({
          name: Yup.string().required("Nome obrigatório"),
          phone: Yup.string().required("Telefone é obrigatório"),
          companyDocument,
          companyName: Yup.string().required("Nome da empresa é obrigatório"),
          cep: Yup.string()
            .min(8, "Mínimo 8 dígitos")
            .required("CEP é obrigatório"),
          city: Yup.string().required("Cidade é obrigatória"),
          uf: Yup.string()
            .min(2, "Mínimo 2 caracteres")
            .max(2, "Máximo 2 caracteres")
            .required("UF é obrigatório"),
          street: Yup.string().required("Rua é obrigatória"),
          neighborhood: Yup.string().required("Bairro é obrigatório"),
          number: Yup.string().required("Número é obrigatório"),
          complement: Yup.string(),
        });
      };

      await schema().validate(
        {
          name,
          phone,
          companyName,
          cep,
          city,
          uf,
          street,
          neighborhood,
          number,
          complement,
          companyDocument: cnpj,
        },
        { abortEarly: false }
      );

      setHasError({});

      const cepFormatted = cep.replace(/[^0-9]/g, "");
      const phoneFormatted = phone.replace(/[^0-9]/g, "");
      const cnpjFormatted = cnpj.replace(/\D/g, "");

      const data = {
        name,
        distanceLimitActuation: expertiseForm.distanceExpertice,
        phone: phoneFormatted,
        companyName,
        areasOfExpertise: checkedExpertise,
        address: {
          cep: cepFormatted,
          city,
          state: uf,
          street,
          neighborhood,
          number,
          complement,
        },
        ...(cnpj.trim() !== "" && { cnpj: cnpjFormatted }),
      };

      const response = await api.put("/users/me", data);

      updateUser(response.data);
      toggleModal();

      addToast({
        title: "Tudo Certo!",
        description: "Seus dados foram atualizados com sucesso",
        type: "success",
      });
    } catch (error) {
      if (error instanceof Yup.ValidationError) {
        const errors = getValidationErrors(error);
        setHasError(errors);

        addToast({
          title: "Ops!!",
          description: "Preencha todos os campos obrigatórios",
          type: "error",
        });

        return;
      }

      addToast({
        title: "Ops!!",
        description:
          axios.isAxiosError(error) && error.response?.data.error
            ? error.response.data.error
            : "Ocorreu um erro ao salvar as alterações.",
        type: "error",
      });
    } finally {
      setLoadingButton(false);
    }
  }

  async function deleteSignature() {
    try {
      await api.delete("/users/me/digital-sign");
      updateUser({ ...user, digitalSign: "" });
      addToast({
        title: "Sucesso!",
        description: "Assinatura deletada com sucesso !",
        type: "success",
      });
    } catch (error) {
      addToast({
        title: "Ops!!",
        description: "Ocorreu um erro ao deletar a assinatura.",
        type: "error",
      });
    } finally {
      setSignatureModalVisible(false);
    }
  }

  async function handleDeleteAccount() {
    try {
      setLoadingButtonDeleteAcount(true);
      await api.delete("/users/me");
      setModalDeleteAcountVisible(false);

      signOut();
      addToast({
        title: "Sucesso!!",
        description:
          "Pedido de exclusão de conta está sendo analisado pelo time Profiz.",
        type: "success",
      });
    } catch (err) {
      addToast({
        title: "Ops!!",
        description:
          axios.isAxiosError(err) && err.response?.data.error
            ? err.response.data.error
            : "Ocorreu um erro ao deletar a conta.",
        type: "error",
      });
    } finally {
      setModalConfirmDeleteAcountVisible(false);
      setAcceptedExclusionTerm(false);
      setLoadingButtonDeleteAcount(false);
    }
  }

  return (
    <S.Container>
      <header>
        <div>
          <ArrowButton
            handleFunction={() => {
              toggleModal();
            }}
          />
        </div>
      </header>

      <AlertModal
        isVisible={signatureModalVisible}
        title="Aviso !"
        action="choose"
        description="Tem certeza que deseja deletar a sua assinatura ?"
        handleConfirm={deleteSignature}
        onCloseModal={() => setSignatureModalVisible(false)}
      />

      <ModalRight
        isOpen={actuationAreaModal}
        handleToggleOpen={() => setActuactionAreaModal(false)}
      >
        <div style={{ marginBottom: 20 }}>
          <ArrowButton
            handleFunction={() => {
              setActuactionAreaModal(false);
            }}
          />
        </div>
        <S.TextContainer style={{ marginTop: 0 }}>
          <S.BolderText style={{ color: theme.colors.text_light_87 }}>
            Áreas de atuação
          </S.BolderText>
          <S.Text style={{ color: theme.colors.text_light_87 }}>
            Você poderá receber oportunidades de serviço de acordo as áreas
            selecionadas
          </S.Text>
          <S.BolderText
            style={{
              color: theme.colors.text_light_87,
              fontSize: 14,
              marginTop: 20,
            }}
          >
            Selecione todas suas áreas de atuação
          </S.BolderText>
        </S.TextContainer>
        <S.ExpertiseAreaContainer>
          {expertiseArea.map((item) => {
            return (
              <div key={item.letter}>
                <S.Text
                  style={{
                    color: theme.colors.primary,
                  }}
                >
                  {item.letter}
                </S.Text>
                <S.Divider />
                {item.items.map((expertise: ExpertiseArea, index: number) => (
                  <div key={expertise.id}>
                    <S.ExpertiseContainer>
                      <S.Checkbox
                        onClick={() => checkExpertise(expertise.id, -1)}
                      >
                        {getChecked(expertise.id) >= 0 && <S.Check />}
                      </S.Checkbox>
                      <S.Text
                        style={{
                          marginLeft: 10,
                          fontSize: 12,
                          marginTop: 0,
                          color: theme.colors.text_light_87,
                        }}
                      >
                        {expertise.name}
                      </S.Text>
                    </S.ExpertiseContainer>
                    <S.Divider />
                  </div>
                ))}
              </div>
            );
          })}
        </S.ExpertiseAreaContainer>
        <Button
          style={{ marginTop: "30px" }}
          typeButton="default"
          disabled={checkedExpertise.length === 0}
          onClick={() => setActuactionAreaModal(false)}
        >
          Salvar
        </Button>
      </ModalRight>

      <main>
        {loading ? (
          <LoadingPageModal />
        ) : (
          <>
            {user.profileComplete && !user.profileApproved && (
              <S.WrapperCardProfileUnderAnalysis>
                <CardProfileUnderAnalysis />
              </S.WrapperCardProfileUnderAnalysis>
            )}

            <S.Title>Dados do negócio</S.Title>

            {user.deletionRequest && (
              <S.SubTitle>
                Seu pedido de exclusão de conta está sendo analisado pelo time
                Profiz!
              </S.SubTitle>
            )}

            <S.SubTitle
              style={{ marginTop: 10, fontSize: 12, marginBottom: 20 }}
            >
              Adicione a logo do seu negócio, seus PDFs ficarão incríveis!
            </S.SubTitle>

            <S.WrapperImageProfile>
              {companyLogo ? (
                <img src={companyLogo} alt="foto da empresa" />
              ) : (
                <HiOutlineUser
                  color={theme.colors.menu_detail_light}
                  size={30}
                />
              )}

              <label htmlFor="avatar">
                <MdCreate size={20} color={theme.colors.menu_detail_light} />

                <input
                  disabled={user.underAnalysis}
                  id="avatar"
                  type="file"
                  accept="image/*"
                  onChange={handleUpdateCompanyLogo}
                />
              </label>
            </S.WrapperImageProfile>

            <S.Form>
              <>
                <S.WrapperInput>
                  <Input
                    disabled={user.underAnalysis}
                    name="Nome da empresa*"
                    placeholder="Nome da sua empresa"
                    value={companyName}
                    onChange={(e) => setCompanyName(e.target.value.trimStart())}
                    hasError={hasError.companyName}
                    onFocusClearError={() =>
                      setHasError({ ...hasError, companyName: "" })
                    }
                  />
                </S.WrapperInput>

                <S.WrapperInput>
                  <Input
                    disabled={user.underAnalysis}
                    name={user.document?.type === "PJ" ? "CNPJ*" : "CNPJ"}
                    placeholder="Seu CNPJ"
                    maskType="cnpj"
                    value={cnpj}
                    onChange={(e) => setCnpj(e.target.value)}
                    hasError={hasError.cnpj}
                    onFocusClearError={() =>
                      setHasError({ ...hasError, cnpj: "" })
                    }
                  />
                </S.WrapperInput>

                <S.WrapperInput>
                  <Input
                    disabled={user.underAnalysis}
                    name="Celular/WhatsApp com DDD*"
                    placeholder="(99) 99999-9999"
                    maskType="phone"
                    value={phone}
                    onChange={(e) => setPhone(e.target.value)}
                    hasError={hasError.phone}
                    onFocusClearError={() =>
                      setHasError({ ...hasError, phone: "" })
                    }
                  />
                </S.WrapperInput>

                <S.WrapperInput>
                  <Input
                    disabled={user.underAnalysis}
                    name="CEP*"
                    placeholder="Seu CEP"
                    maskType="zip-code"
                    value={cep}
                    onChange={(e) => setCep(e.target.value)}
                    handleEditingBlur={() => fetchAddressWithCep()}
                    hasError={hasError.cep}
                    onFocusClearError={() =>
                      setHasError({ ...hasError, cep: "" })
                    }
                  />
                </S.WrapperInput>

                <S.WrapperSmallInput>
                  <div style={{ width: "50%", marginRight: 20 }}>
                    <Input
                      disabled={user.underAnalysis}
                      name="Cidade*"
                      placeholder="Nome da cidade"
                      value={city}
                      onChange={(e) => setCity(e.target.value.trimStart())}
                      hasError={hasError.city}
                      onFocusClearError={() =>
                        setHasError({ ...hasError, city: "" })
                      }
                    />
                  </div>
                  <div style={{ width: "50%" }}>
                    <Dropdown
                      disabled={user.underAnalysis}
                      label="UF*"
                      placeholder="Ex: SP"
                      options={states}
                      optionSelected={uf}
                      onClickedValue={({ name }) => {
                        setUf(name);
                      }}
                      hasError={hasError.stateUnity}
                    />
                  </div>
                </S.WrapperSmallInput>

                <S.WrapperInput>
                  <Input
                    disabled={user.underAnalysis}
                    name="Rua*"
                    placeholder="Nome da rua"
                    value={street}
                    onChange={(e) => setStreet(e.target.value.trimStart())}
                    hasError={hasError.street}
                    onFocusClearError={() =>
                      setHasError({ ...hasError, street: "" })
                    }
                  />
                </S.WrapperInput>

                <S.WrapperInput>
                  <Input
                    disabled={user.underAnalysis}
                    name="Bairro*"
                    placeholder="Nome do bairro"
                    value={neighborhood}
                    onChange={(e) =>
                      setNeighborhood(e.target.value.trimStart())
                    }
                    hasError={hasError.neighborhood}
                    onFocusClearError={() =>
                      setHasError({ ...hasError, neighborhood: "" })
                    }
                  />
                </S.WrapperInput>

                <S.WrapperInput>
                  <Input
                    disabled={user.underAnalysis}
                    name="Número*"
                    placeholder="Ex: 1234"
                    type="text"
                    value={number}
                    onChange={(e) => setNumber(e.target.value.trimStart())}
                    hasError={hasError.number}
                    onFocusClearError={() =>
                      setHasError({ ...hasError, number: "" })
                    }
                  />
                </S.WrapperInput>

                <S.WrapperInput>
                  <Input
                    disabled={user.underAnalysis}
                    name="Complemento"
                    placeholder="Complemento do endereço"
                    value={complement}
                    onChange={(e) => setComplement(e.target.value.trimStart())}
                  />
                </S.WrapperInput>

                <S.WrapperDescription>
                  <S.SubTitle style={{ marginLeft: 0 }}>
                    Dados pessoais
                  </S.SubTitle>
                </S.WrapperDescription>

                <S.WrapperInput>
                  <Input
                    disabled={true}
                    name="E-mail*"
                    value={user?.email}
                    style={{
                      color: theme.colors.text_light_38,
                      backgroundColor: theme.colors.detail_light,
                      fontFamily: theme.font.family,
                    }}
                  />
                </S.WrapperInput>

                <S.WrapperInput>
                  <Input
                    disabled={true}
                    name="Seu nome*"
                    value={user?.name}
                    style={{
                      color: theme.colors.text_light_38,
                      backgroundColor: theme.colors.detail_light,
                    }}
                  />
                </S.WrapperInput>

                <S.WrapperInput>
                  <Input
                    disabled={true}
                    name="Seu CPF*"
                    value={user?.document?.cpf}
                    maskType="cpf"
                    style={{
                      color: theme.colors.text_light_38,
                      backgroundColor: theme.colors.detail_light,
                    }}
                  />
                </S.WrapperInput>

                <S.Title
                  style={{ fontSize: 14, marginLeft: 0, marginBottom: 10 }}
                >
                  Áreas de atuação*
                </S.Title>
                {areasOfExpertise
                  .filter((item) => typeof item !== "undefined")
                  .map((item, index) => {
                    if (typeof item === "object") {
                      return (
                        <S.Wrapper style={{ marginBottom: 10 }} key={item.id}>
                          {item.name}
                          <div
                            onClick={() =>
                              checkedExpertise.length > 1 &&
                              !user.underAnalysis &&
                              checkExpertise(item.id, index)
                            }
                            style={{
                              marginRight: 10,
                              cursor: "pointer",
                              fontSize: 14,
                            }}
                          >
                            X
                          </div>
                        </S.Wrapper>
                      );
                    } else {
                      return <></>;
                    }
                  })}
                <S.AddAreaOfExpertise>
                  <Button
                    disabled={user.underAnalysis}
                    onClick={() => setActuactionAreaModal(true)}
                    typeButton="textOnly"
                    style={{
                      background: `${lighten(0.05, theme.colors.background)}`,
                      width: 40,
                      height: 40,
                      marginTop: 0,
                    }}
                  >
                    +
                  </Button>
                  <S.AddAreaText>Adicionar áreas de atuação</S.AddAreaText>
                </S.AddAreaOfExpertise>

                <S.Title
                  style={{
                    fontSize: 14,
                    marginLeft: 0,
                    marginBottom: 10,
                    marginTop: 20,
                  }}
                >
                  Limitação de distância por km*
                </S.Title>

                <S.SubTitle style={{ marginLeft: 0 }}>
                  Defina sua área de prestação de serviços com base no seu
                  endereço.
                </S.SubTitle>
                <S.MettersContainer>
                  <Button
                    disabled={user.underAnalysis}
                    typeButton="textOnly"
                    style={{
                      background: `${lighten(0.05, theme.colors.background)}`,
                      width: 40,
                      height: 40,
                    }}
                    onClick={() =>
                      setExpertiseForm({
                        distanceExpertice:
                          expertiseForm.distanceExpertice < 5
                            ? 0
                            : expertiseForm.distanceExpertice - 5,
                      })
                    }
                  >
                    -
                  </Button>
                  <S.Text
                    style={{
                      color: theme.colors.text_light_87,
                      marginRight: 10,
                      marginLeft: 10,
                      marginTop: 15,
                    }}
                  >
                    {expertiseForm.distanceExpertice} Km
                  </S.Text>
                  <Button
                    disabled={user.underAnalysis}
                    typeButton="textOnly"
                    style={{
                      background: `${lighten(0.05, theme.colors.background)}`,
                      width: 40,
                      height: 40,
                    }}
                    onClick={() =>
                      setExpertiseForm({
                        distanceExpertice:
                          expertiseForm.distanceExpertice > 395
                            ? 400
                            : expertiseForm.distanceExpertice + 5,
                      })
                    }
                  >
                    +
                  </Button>
                </S.MettersContainer>
                <Slider
                  disabled={user.underAnalysis}
                  marks={marks}
                  trackStyle={{ background: "#2e2e2e" }}
                  railStyle={{ background: "#2e2e2e" }}
                  handleStyle={{
                    opacity: 1,
                    background: "#2e2e2e",
                    borderColor: "theme.colors.primary",
                    boxShadow: "none",
                  }}
                  dotStyle={{ display: "none" }}
                  min={0}
                  value={expertiseForm.distanceExpertice}
                  max={400}
                  onChange={(event) =>
                    setExpertiseForm({
                      ...expertiseForm,
                      distanceExpertice: typeof event === "number" ? event : 0,
                    })
                  }
                  defaultValue={expertiseForm.distanceExpertice}
                />

                <S.Title
                  style={{
                    fontSize: 14,
                    marginLeft: 0,
                    marginBottom: 10,
                    marginTop: 60,
                  }}
                >
                  Assinatura
                </S.Title>

                <CardUploadDigitalSign
                  disabled={user.underAnalysis}
                  handleUpdateDigitalSign={setDigitalSign}
                />
                <S.WrapperSignaturePreview>
                  {digitalSign ? (
                    <>
                      <img src={digitalSign} alt="assinatura digital" />
                      {!user.underAnalysis && (
                        <div
                          onClick={() => setSignatureModalVisible(true)}
                          style={{
                            cursor: "pointer",
                            marginLeft: 5,
                          }}
                        >
                          <img
                            src={trashSvg}
                            width="35"
                            height="20"
                            alt="Excluir"
                          />
                        </div>
                      )}
                    </>
                  ) : (
                    <p>Sua assinatura aparecerá aqui</p>
                  )}
                </S.WrapperSignaturePreview>
              </>

              <S.ButtonsContainer>
                <S.WrapperButton>
                  <Button
                    loading={loadingButton}
                    onClick={() => handleUpdateUser()}
                    disabled={
                      checkedExpertise.length === 0 ||
                      user.underAnalysis ||
                      user.deletionRequest
                    }
                  >
                    Salvar alterações
                  </Button>

                  <Button typeButton="textOnly" onClick={() => toggleModal()}>
                    Cancelar edição
                  </Button>

                  <Button
                    typeButton="textOnly"
                    style={{ color: theme.colors.text }}
                    disabled={user.deletionRequest ? true : false}
                    onClick={() => setModalDeleteAcountVisible(true)}
                  >
                    Excluir conta Profiz
                  </Button>
                </S.WrapperButton>
              </S.ButtonsContainer>
            </S.Form>
          </>
        )}

        <ModalRight
          isOpen={modalDeleteAcountVisible}
          handleToggleOpen={() => {
            setAcceptedExclusionTerm(false);
            setModalDeleteAcountVisible(false);
          }}
        >
          <div style={{ marginBottom: 20 }}>
            <ArrowButton
              handleFunction={() => {
                setAcceptedExclusionTerm(false);
                setModalDeleteAcountVisible(false);
              }}
            />
          </div>
          <S.TextContainer style={{ marginTop: 0 }}>
            <S.BolderText>Exclusão de conta Profiz</S.BolderText>
            <S.Text>
              Na Profiz você tem controle dos seus dados e pode solicitar a
              exclusão de sua conta a qualquer momento.
            </S.Text>
            <S.Text>
              Ao realizar a solicitação, o time de atendimento Profiz irá
              realizar a exclusão dos dados e dentro de poucos dias o acesso ao
              app e demais áreas do ecossistema Profiz serão excluídos.
            </S.Text>
            <S.Text>
              Você poderá criar uma nova conta no futuro, mas os dados excluídos
              não poderão ser recuperados.
            </S.Text>
            <S.Text>
              Para mais informações acesse nossa política de privacidade pelo
              link abaixo.
            </S.Text>
            <p
              onClick={() =>
                setModalPolicyAndTermOfUseProps({
                  isVisible: true,
                  policyOrTermOfUse: "policy",
                })
              }
            >
              <S.Text
                style={{ color: theme.colors.primary, cursor: "pointer" }}
              >
                Acessar política de privacidade
              </S.Text>
            </p>

            <S.BolderText style={{ marginTop: 30 }}>
              Esta ação será irreversivel! Deseja prosseguir?
            </S.BolderText>
          </S.TextContainer>

          <S.AcceptView>
            <S.Checkbox
              onClick={() => setAcceptedExclusionTerm(!acceptedExclusionTerm)}
            >
              {acceptedExclusionTerm && <S.Check />}
            </S.Checkbox>

            <S.Text
              style={{
                marginLeft: 10,
                fontSize: 12,
                marginTop: 0,
                color: theme.colors.text_light_87,
              }}
            >
              Estou de acordo com o processo de exclusão de dados.
            </S.Text>
          </S.AcceptView>
          <Button
            typeButton={
              !acceptedExclusionTerm || loadingButtonDeleteAcount
                ? "default"
                : "delete"
            }
            disabled={!acceptedExclusionTerm || loadingButtonDeleteAcount}
            loading={loadingButtonDeleteAcount}
            onClick={() => setModalConfirmDeleteAcountVisible(true)}
          >
            Excluir conta Profiz
          </Button>

          <NewAlertModal
            isVisible={modalConfirmDeleteAcountVisible}
            title="Aviso!"
            description="Deseja realmente excluir a sua conta?"
            highlightedDescription="Lembre-se, não será possivel reverter a solicitação"
            action="choose"
            handleConfirm={handleDeleteAccount}
            onCloseModal={() => {
              setAcceptedExclusionTerm(!acceptedExclusionTerm);
              setModalConfirmDeleteAcountVisible(false);
            }}
            labelConfirm="Confirmar"
            labelCancel="Fechar"
            buttonConfirmColor="#FA8B94"
          />
        </ModalRight>
      </main>

      {modalPolicyAndTermOfUseProps.isVisible && (
        <ModalPolicyAndTermOfUse
          isVisible={modalPolicyAndTermOfUseProps.isVisible}
          policyOrTermOfUse={modalPolicyAndTermOfUseProps.policyOrTermOfUse}
          onCloseModal={() =>
            setModalPolicyAndTermOfUseProps({
              isVisible: false,
            } as ModalPolicyAndTermOfUseProps)
          }
        />
      )}
    </S.Container>
  );
}
