import React from "react";
import BlockSvg from "assets/icons/permission/block.svg";

import * as S from "./styles";
import { Button } from "components/Button";
import { useHistory } from "react-router";

type ModalProps = {
  isVisible: boolean;
  setIsActive?: (value: boolean) => void;
};

export function ModalCompleteRegistration({
  isVisible,
  setIsActive,
}: ModalProps) {
  const history = useHistory();

  return (
    <>
      {isVisible && (
        <S.ContainerOverlay>
          <S.BoxAlert>
            <img src={BlockSvg} alt="Ícone de cadeado" />
            <h1>Libere essa funcionalidade!</h1>
            <span>Complete o cadastro e desfrute gratuitamente</span>
            <span>de todas as funcionalidades do app.</span>
            <Button
              onClick={() => {
                history.push("/complete-profile");
                setIsActive && setIsActive(false);
              }}
            >
              Completar cadastro
            </Button>
            <S.WrapperButton
              onClick={() => {
                setIsActive && setIsActive(!isVisible);
              }}
            >
              <button>Mais tarde</button>
            </S.WrapperButton>
          </S.BoxAlert>
        </S.ContainerOverlay>
      )}
    </>
  );
}
