import React, { useEffect, useState } from "react";
import { useTheme } from "styled-components";
import { FiX, FiChevronLeft } from "react-icons/fi";

import { ListObservations } from "./ListObservations";
import { CreateNewObservation } from "./CreateNewObservation";
import { EditObservation } from "./EditObservation";

import * as S from "./styles";

type ModalProps = {
  listObservation: boolean;
  createObservation: boolean;
  editObservation: boolean;
};

type Props = {
  handleToggleOpen: () => void;
};

type ObservationItems = {
  id: string;
  message: string;
};

export function ModalDefaultObservations({ handleToggleOpen }: Props) {
  const theme = useTheme();

  const [modalOpened, setModalOpened] = useState({
    listObservation: true,
    createObservation: false,
    editObservation: false,
  } as ModalProps);

  const { listObservation, createObservation, editObservation } = modalOpened;

  const [optionalItemSelected, setOptionalItemSelected] =
    useState<ObservationItems | null>(null);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  function handleToggleModal(
    modal: "listObservation" | "createObservation" | "editObservation"
  ) {
    setModalOpened(() => {
      if (modal === "listObservation") {
        return {
          listObservation: true,
          createObservation: false,
          editObservation: false,
        };
      }
      if (modal === "editObservation") {
        return {
          listObservation: false,
          createObservation: false,
          editObservation: true,
        };
      }
      return {
        listObservation: false,
        createObservation: true,
        editObservation: false,
      };
    });
  }

  return (
    <>
      <S.Top>
        {listObservation ? (
          <S.ButtonClose onClick={() => handleToggleOpen()}>
            <FiX size={16} color={theme.colors.text} />
          </S.ButtonClose>
        ) : (
          <S.ButtonClose onClick={() => handleToggleModal("listObservation")}>
            <FiChevronLeft size={16} color={theme.colors.text} />
          </S.ButtonClose>
        )}

        <div>
          <p>Observações</p>
          <S.InfoObservationText>
            {listObservation
              ? "Selecione um texto padrão para anexar ao orçamento."
              : "Crie um texto padrão para anexar ao orçamento."}
          </S.InfoObservationText>
        </div>
      </S.Top>

      <S.Content>
        {listObservation && (
          <ListObservations
            handleToggleOpen={handleToggleOpen}
            openCreateObservation={() => handleToggleModal("createObservation")}
            openEditObservation={() => handleToggleModal("editObservation")}
            setObservation={setOptionalItemSelected}
          />
        )}

        {createObservation && (
          <CreateNewObservation
            handleToggleOpen={() => handleToggleModal("listObservation")}
          />
        )}

        {editObservation && (
          <EditObservation
            handleToggleOpen={() => handleToggleModal("listObservation")}
            editObservation={optionalItemSelected}
          />
        )}
      </S.Content>
    </>
  );
}
