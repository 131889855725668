import React, { useEffect, useState } from "react";

import * as S from "./styles";
import { FinancialDateInput } from "components/FinancialDateInput";

export interface FilterProps {
  year?: string;
  month?: string | number;
  initialDate?: string;
  finalDate?: string;
}

interface FiltersProps {
  initialDate: string;
  finalDate: string;
  getTypeFilter: (type?: FilterProps) => void;
  setInitialDate: (initalDate: string) => void;
  setEndDate: (endDate: string) => void;
  cleanFilters: () => void;
}

const Filters: React.FC<FiltersProps> = ({
  getTypeFilter,
  cleanFilters,
  setEndDate,
  setInitialDate,
  initialDate,
  finalDate,
}) => {
  const [type, setType] = useState<
    "all" | "currentMonth" | "currentYear" | "calender"
  >("all");

  var dataAtual = new Date();
  const getAll = () => {
    getTypeFilter(undefined);
    cleanFilters();
    setType("all");
  };

  const getCurrentMonth = () => {
    var numeroMes = dataAtual.getMonth();
    var numeroMesAtual = numeroMes + 1;

    getTypeFilter({
      month: numeroMesAtual,
    });
    setType("currentMonth");
  };

  const getCurrentYear = () => {
    var numeroAnoAtual = dataAtual.getFullYear();

    getTypeFilter({
      year: numeroAnoAtual.toString(),
    });
    setType("currentYear");
  };

  useEffect(() => {
    if (type !== "calender") {
      setEndDate("");
      setInitialDate("");
    }
  }, [type, setEndDate, setInitialDate]);

  return (
    <>
      <S.FilterTags>
        <S.TransactionTypeContainer>
          <S.RadioBox type="button" onClick={getAll} isActive={type === "all"}>
            <span>Todas</span>
          </S.RadioBox>

          <S.RadioBox
            type="button"
            onClick={getCurrentMonth}
            isActive={type === "currentMonth"}
          >
            <span>Este mês</span>
          </S.RadioBox>

          <S.RadioBox
            type="button"
            onClick={getCurrentYear}
            isActive={type === "currentYear"}
          >
            <span>Este ano</span>
          </S.RadioBox>

          <S.RadioBox
            style={{ width: "100%", backgroundColor: "initial" }}
            type="button"
            isActive={type === "calender"}
            onClick={() => setType("calender")}
          >
            <S.WrapperCalendar>
              <FinancialDateInput
                label="De"
                currentDate={initialDate}
                handleSetDate={setInitialDate}
                handleClearButtonFilter={() => {}}
              />

              <FinancialDateInput
                label="Até"
                currentDate={finalDate}
                handleSetDate={setEndDate}
                handleClearButtonFilter={() => {}}
              />
            </S.WrapperCalendar>
          </S.RadioBox>
        </S.TransactionTypeContainer>
      </S.FilterTags>
    </>
  );
};
export { Filters };
