import styled, { css } from "styled-components";

export const Label = styled.p`
  ${({ theme }) => css`
    font-family: ${theme.font.family};
    font-size: ${theme.font.sizes.xsmall};
    font-weight: ${theme.font.normal};
    color: ${theme.colors.text_light_87};
    margin-bottom: 10px;
  `}
`;
