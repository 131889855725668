import styled, { css } from "styled-components";
import media from "styled-media-query";

export const Container = styled.div`
  ${({ theme }) => css`
    min-height: 100vh;
    overflow-x: hidden;
    background-color: ${theme.colors.strong_background};

    display: flex;
    flex-direction: column;
    align-items: center;
  `}
`;

export const Main = styled.main`
  width: 40%;
  max-width: 400px;
  margin-top: 8rem;

  display: flex;
  flex-direction: column;
  align-items: center;

  ${media.lessThan("large")`
    width: 80%;
  `}

  & > div {
    margin-bottom: 50px;
  }
`;

export const CheckCircle = styled.img`
  width: 100px;
  height: 100px;
  margin-bottom: 22px;
`;

export const Title = styled.h4`
  ${({ theme }) => css`
    font-size: ${theme.font.sizes.xxlarge};
    font-weight: ${theme.font.bold};
    color: ${theme.colors.title};
    text-align: center;
    margin-bottom: 12px;
  `}
`;

export const Text = styled.p`
  ${({ theme }) => css`
    width: 90%;
    margin: 0 auto;
    line-height: 20px;
    text-align: center;
    color: ${theme.colors.text};
    font-size: ${theme.font.sizes.xsmall};
  `}
`;

export const LogoProfiz = styled.img`
  width: 99px;
  height: 27px;
  margin-top: 8px;
`;
