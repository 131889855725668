import { useState, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";

import * as Yup from "yup";

import LinearEditSvg from "assets/icons/linear-edit.svg";

import { ClientArea } from "components/ClientArea";
import { ArrowButton } from "components/ArrowButton";
import { Button } from "components/Button";
import { FormItem } from "components/FormItem";

import { CardResponsible } from "./CardResponsible";
import { UnityArea } from "./UnityArea";
import { AttachmentArea } from "./AttachmentArea";

import { useClient } from "hooks/budget/client";
import { useToast } from "hooks/toast";
import { usePmoc } from "hooks/usePmoc";
import { useHistory } from "react-router";

import { api } from "services/api";
import getValidationErrors from "utils/getValidationErrors";
import { hasLettersAndNumbers } from "utils/verifyHasLettersAndNumbers";
import { hasLowercaseLetters } from "utils/hasLowercaseLetters";

import * as S from "./styles";
import { EnvironmentArea } from "./EnvironmentArea";

import { EnvironmentDTO } from "dtos/PmocEnvironmentDTO";
import { AlertModal } from "components/AlertModal";
import { LoadingProfiz } from "components/LoadingProfiz";
import { ModalRight } from "components/ModalRight";
import { Input } from "components/Input";

import { format } from "date-fns";
import { ScheduledComponent } from "./ScheduledComponent";

type Errors = {
  [key: string]: string;
};

type RouteParams = {
  id: string;
};

type ARTFileProps = {
  url: string;
};

type DictOption = {
  [key: string]: { status: string };
};

function StatePmoc(statusPmoc: string) {
  const status: DictOption = {
    Pendente: {
      status: "pending",
    },
    execução: {
      status: "execution",
    },
    Concluída: {
      status: "concluded",
    },
  };
  let value = Object.keys(status).find((key) => {
    const sts = status[key];
    return sts.status === statusPmoc;
  });
  return (
    <S.WapperPmocStatus>
      <div className="element-ball" />
      <p className="text-status">{value}</p>
    </S.WapperPmocStatus>
  );
}

type EnvironmentProps = {
  environmentId: number | string;
  environmentName: string;
  equipments: [
    {
      equipmentId: number | string;
      equipmentTag: string;
    }
  ];
};

export function PmocRegisterForm() {
  const { id } = useParams<RouteParams>();

  const history = useHistory();
  const { addToast } = useToast();
  const {
    initialPmocProps,
    clearPmocData,
    periodProps,
    handleSetUnity,
    handleSetPeriodProps,
    handleSetPmocArtFile,
    handleSetInitialPmocProps,
    unity,
    hasUnity,
  } = usePmoc();

  const { client, clearClientData, handleSetClient } = useClient();

  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingModal, setIsLoadingModal] = useState(false);

  const [loadedUnityEnvironments, setLoadedUnityEnvironments] = useState<
    EnvironmentDTO[]
  >([]);

  const [hasError, setHasError] = useState<Errors>({} as Errors);

  const [hasErrorOnHash, setHasErrorOnHash] = useState<string[]>([]);
  const [isInputDisabled, setIsInputDisabled] = useState(true);
  const [visibleButtonSaveHash, setVisibleButtonSaveHash] = useState(false);
  const [randomHash, setRandomHash] = useState("");

  const inputRef = useRef<HTMLInputElement>(null);

  const [modalErrorMessage, setModalErrorMessage] = useState(false);

  const [releaseCreationButton, setReleaseCreationButton] = useState(false);
  const [isNotCloseModalEnvironment, setIsNotClosedModalEnvironment] =
    useState(true);

  const [enableExecutionButton, setEnableExecutionButton] = useState(false);
  const [scheduledModal, setScheduledModal] = useState(false);
  const [environmentsList, setEnvironmentsList] = useState<EnvironmentProps[]>(
    [] as EnvironmentProps[]
  );
  const [selectedDate, setSelectedDate] = useState<Date | undefined>(undefined);

  const [statusPmoc, setStatusPmoc] = useState("pending");

  const clearInfos = async () => {
    clearClientData();
    clearPmocData();
  };

  useEffect(() => {
    (async () => {
      if (Object.keys(unity).length > 0 && unity.id) {
        try {
          setIsLoading(true);
          if (hasUnity) {
            const response = await api.get(
              `/clients/${client.id}/unities/${unity?.id}`
            );
            setLoadedUnityEnvironments(response.data.environments);
          }
        } catch (error) {
          addToast({
            type: "error",
            title: "Erro",
            description: "Não foi possível carregar os ambientes.",
          });
        } finally {
          setIsLoading(false);
        }
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [unity]);

  function handleChangeDate(day: Date) {
    setSelectedDate(day);
  }

  const setData = async () => {
    try {
      setIsLoadingModal(true);
      const response = await api.get(`/pmoc/${id}`);

      response.data &&
        response.data.id &&
        response.data.name &&
        handleSetInitialPmocProps({
          id: response.data.id,
          name: response.data.name,
        });

      response.data &&
        response.data.status &&
        setStatusPmoc(response.data.status);

      response.data &&
        response.data.status &&
        setStatusPmoc(response.data.status);

      response.data &&
        response.data.client &&
        handleSetClient(response.data.client);
      response.data &&
        response.data.unity &&
        handleSetUnity(response.data.unity);

      response.data &&
        response.data.contractPeriod &&
        response.data.contractPeriod.period &&
        response.data.contractPeriod.type &&
        handleSetPeriodProps({
          period: response.data.contractPeriod.period,
          frequency: response.data.contractPeriod.type,
        });

      response.data && response.data.art
        ? handleSetPmocArtFile({
            url: response.data.art,
          })
        : handleSetPmocArtFile({} as ARTFileProps);

      response.data &&
      response.data.permissionToFinish &&
      response.data.permissionToFinish.length > 0 &&
      response.data.permissionToFinish === "s"
        ? setReleaseCreationButton(true)
        : setReleaseCreationButton(false);

      response.data &&
      response.data.months &&
      (Object.keys(response.data.months).length > 0 ||
        response.data.months.length > 0)
        ? setEnableExecutionButton(true)
        : setEnableExecutionButton(false);

      response.data &&
        response.data.environments &&
        setEnvironmentsList(response.data.environments);

      response.data &&
        response.data.passClientView &&
        setRandomHash(response.data.passClientView);
    } catch (error) {
      addToast({
        title: "Erro",
        description:
          axios.isAxiosError(error) && error.response?.data.error
            ? error.response?.data.error
            : "Não foi possível carregar as informações do PMOC",
        type: "error",
      });
    } finally {
      setIsLoadingModal(false);
    }
  };

  useEffect(() => {
    setData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!isNotCloseModalEnvironment) {
      setData();
      setIsNotClosedModalEnvironment(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isNotCloseModalEnvironment]);

  function verifyErrors() {
    let hasValidationErrors = false;

    if (!randomHash) {
      setHasError((errors) => ({
        ...errors,
        randomHash: "Código de acesso é obrigatório.",
      }));
      hasValidationErrors = true;
    }

    if (hasValidationErrors) {
      return true;
    }
    return false;
  }

  function handleChangeInput(text: string) {
    setRandomHash(text.trim().toUpperCase());

    if (inputRef.current?.value.trim()) setVisibleButtonSaveHash(true);
    else setVisibleButtonSaveHash(false);
  }

  function handleToggleEnableInput() {
    setIsInputDisabled(false);
    inputRef.current?.focus();
  }

  async function handleSaveRandomHash() {
    try {
      setIsLoading(true);
      let hashErrors: string[] = [];
      if (!randomHash) {
        hashErrors = [...hashErrors, "Campo obrigatório"];
      }

      if (randomHash.length !== 6) {
        hashErrors = [...hashErrors, "O campo deve conter 6 caracteres."];
      }

      if (!hasLettersAndNumbers(randomHash)) {
        hashErrors = [...hashErrors, "O campo deve conter letras e números."];
      }

      if (hasLowercaseLetters(randomHash)) {
        hashErrors = [...hashErrors, "Todas as letras tem que ser maiúsculas."];
      }

      if (hashErrors.length > 0) {
        setHasErrorOnHash(hashErrors);
        return;
      }

      await api.put(`/pmoc/${initialPmocProps.id}/view/client/password`, {
        pmocUserClientPass: randomHash,
      });

      setHasErrorOnHash([]);
      setIsInputDisabled(true);
      setVisibleButtonSaveHash(false);

      addToast({
        type: "success",
        title: "Código de acesso atualizado com sucesso",
      });
    } catch (error) {
      addToast({
        title: "Erro",
        description:
          axios.isAxiosError(error) && error.response?.data.error
            ? error.response?.data.error
            : "Não foi possível atualizar o código de acesso.",
        type: "error",
      });
    } finally {
      setIsLoading(false);
    }
  }

  async function handleArchivePmoc() {
    try {
      setIsLoading(true);
      await api.put(`pmoc/${initialPmocProps.id}/archive`, {
        originUpdate: "web",
      });

      addToast({
        type: "success",
        title: "PMOC arquivada com sucesso.",
        description:
          "Você será redirecionado para a listagem de PMOCs arquivadas.",
      });

      history.push(`/pmoc?statusList=3`);

      clearInfos();
    } catch (error) {
      addToast({
        title: "Erro",
        description:
          axios.isAxiosError(error) && error.response?.data.error
            ? error.response?.data.error
            : "Não foi possível arquivar a PMOC.",
        type: "error",
      });
    } finally {
      setIsLoading(false);
    }
  }

  async function handleUpdatePmoc() {
    try {
      if (!selectedDate) return;
      setIsLoading(true);

      if (verifyErrors()) {
        addToast({
          type: "error",
          title: "Erro",
          description: "Preencha todos os campos obrigatórios.",
        });

        return;
      }

      setHasError({});

      await api.put(`/pmoc/${initialPmocProps.id}`, {
        startDate: format(selectedDate, "yyyy-MM-dd"),
      });

      addToast({
        type: "success",
        title: "PMOC atualizado com sucesso.",
      });
      history.push(`/pmoc/execution/${initialPmocProps.id}`);
    } catch (error) {
      if (error instanceof Yup.ValidationError) {
        const errors = getValidationErrors(error);
        setHasError(errors);

        addToast({
          title: "Ops...",
          description: "Preencha todos os campos obrigatórios",
          type: "error",
        });

        return;
      }

      addToast({
        title: "Erro",
        description:
          axios.isAxiosError(error) && error.response?.data.error
            ? error.response?.data.error
            : "Não foi possível finalizar o cadastro do PMOC",
        type: "error",
      });
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <>
      {isLoadingModal ? (
        <LoadingProfiz isVisible={isLoadingModal} />
      ) : (
        <S.Container>
          <AlertModal
            isVisible={modalErrorMessage}
            title="Selecione um ambiente e complete os dados dos equipamentos"
            description={`Não existem ambientes selecionados para se vincular ao PMOC.\n Clique em "Ambientes e equipamentos" para selecionar e vincular um ambiente.`}
            action="confirm"
            handleConfirm={() => setModalErrorMessage(false)}
            onCloseModal={() => setModalErrorMessage(false)}
          />

          <ModalRight
            isOpen={scheduledModal}
            handleToggleOpen={() => setScheduledModal(false)}
          >
            <ScheduledComponent
              closeModal={() => {
                setScheduledModal(false);
              }}
              loading={isLoading}
              selectedDate={selectedDate}
              handleChangeDate={handleChangeDate}
              handleUpdatePmoc={handleUpdatePmoc}
            />
          </ModalRight>

          <div style={{ marginTop: 20 }}>
            <ArrowButton
              handleFunction={() => {
                history.push("/pmoc?statusList=0");
                clearInfos();
              }}
            />
          </div>
          <header>
            <S.Title>{initialPmocProps.name} </S.Title>
            {StatePmoc(statusPmoc)}
          </header>

          <div className="content">
            <S.Wrapper>
              <ClientArea
                disableDeleteButton
                disableEditButton
                hasError={hasError.client}
              />
            </S.Wrapper>

            <S.Wrapper>
              <UnityArea
                disableDeleteButton
                disableEditButton
                hasError={hasError.unity}
              />
            </S.Wrapper>

            <S.Wrapper>
              <CardResponsible />
            </S.Wrapper>

            <S.Wrapper>
              {periodProps && periodProps.period ? (
                <FormItem
                  name="Período do contrato*"
                  placeholder={periodProps.period + " " + periodProps.frequency}
                  disabled
                />
              ) : (
                <FormItem
                  name="Período do contrato*"
                  placeholder={`Sem período cadastrado`}
                  disabled
                />
              )}
            </S.Wrapper>

            <S.WrapperInput>
              <div>
                <Input
                  name="Código de acesso da PMOC*"
                  placeholder="Ex: A1B2C3"
                  value={randomHash}
                  onChange={(e) => handleChangeInput(e.target.value)}
                  customRef={inputRef}
                  readOnly={isInputDisabled}
                  labelIcon="Ativar campo de código"
                  icon={visibleButtonSaveHash ? "" : LinearEditSvg}
                  onClickIcon={handleToggleEnableInput}
                  hasError={hasError.randomHash || hasErrorOnHash[0]}
                  onFocusClearError={() =>
                    setHasError({ ...hasError, randomHash: "" })
                  }
                  maxLength={6}
                />
              </div>

              {visibleButtonSaveHash && (
                <S.ButtonSaveHash onClick={handleSaveRandomHash}>
                  Salvar
                </S.ButtonSaveHash>
              )}
            </S.WrapperInput>

            {hasErrorOnHash.length > 0 &&
              hasErrorOnHash.map((error) => (
                <S.WrapperHashError>
                  <span>*{error}</span>
                </S.WrapperHashError>
              ))}

            <S.WrapperInfo>
              <span>
                O código de acesso serve para liberar os dados da PMOC no painel
                do cliente.
              </span>
            </S.WrapperInfo>

            <S.Wrapper>
              <AttachmentArea />
            </S.Wrapper>

            {environmentsList && environmentsList.length > 0 && (
              <S.Wrapper>
                <S.TitleExecutionButton>
                  Ambientes e equipamentos
                </S.TitleExecutionButton>
                {environmentsList.map((element) => {
                  return (
                    <S.EnvironmentList key={element.environmentId}>
                      <p className="card-title"> {element.environmentName} </p>
                      <p className="card-text">
                        {" "}
                        |{" "}
                        {element.equipments.length > 1
                          ? `${element.equipments.length} equipamentos`
                          : `${element.equipments.length} equipamento`}{" "}
                      </p>
                    </S.EnvironmentList>
                  );
                })}
              </S.Wrapper>
            )}

            <S.Wrapper>
              <EnvironmentArea
                environments={loadedUnityEnvironments}
                hasError={hasError.environments}
                setOpenModal={setIsNotClosedModalEnvironment}
              />
            </S.Wrapper>

            <Button
              disabled={
                isLoading || !releaseCreationButton || enableExecutionButton
              }
              onClick={() => {
                setScheduledModal(true);
              }}
            >
              Gerar ordens de serviço
            </Button>

            <Button
              typeButton="textOnly"
              onClick={() => {
                handleArchivePmoc();
              }}
              loading={isLoading}
              disabled={isLoading}
            >
              Arquivar PMOC
            </Button>
          </div>
        </S.Container>
      )}
    </>
  );
}
