import { Button } from "components/Button";
import { CommentsTextArea } from "components/CommentsTextArea";
import { Dropdown, DropdownOption } from "components/DropDown";
import { Input } from "components/Input";
import { PMOCServiceProps } from "dtos/ServiceDTO";
import arrowLeft from "assets/icons/arrow-left.svg";

import * as S from "./styles";
import { FormEvent, useEffect, useState } from "react";
import { api } from "services/api";
import { useServiceFormTree } from "hooks/serviceFormTree";
import { useServiceDropDown } from "hooks/servicesDropDown";
import { ServiceName } from "components/ModalServices/Areas/ServiceName";
import { DROPDOWN_OPTIONS, HOURS, PERIODS } from "./valuesDropdown";
import { useToast } from "hooks/toast";
import { CardChecklist } from "components/CardChecklist";
import { EditButton } from "components/EditButton";
import { DeleteButton } from "components/DeleteButton";
import { LoadingPageModal } from "components/LoadingPageModal";
import { DataProps, useHandleModalService } from "hooks/handleModalServices";
import { DiscardInformationModal } from "components/DiscardInformationModal";
import axios from "axios";
import { useServiceChecklist } from "hooks/serviceChecklist";
import { ChecklistItemList } from "pages/fieldService/ServiceChecklist/types";
import { AlertModal } from "components/AlertModal";

interface FormPmocProps {
  currentPmocEditService: PMOCServiceProps;
  handleCloseModalPmoc: () => void;
  loadServicesPmoc: () => void;
  handleAddChecklist: () => void;
  handleEditPage?: () => void;
}
const FormPmocServices = ({
  currentPmocEditService,
  loadServicesPmoc,
  handleEditPage,
  handleAddChecklist,
}: FormPmocProps) => {
  const { addToast } = useToast();
  const { handleSetCategoriesTree, categoriesTree } = useServiceFormTree();
  const {
    selectedCategoryId,
    selectedService,
    handleCategoryId,
    handleService,
    reset,
  } = useServiceDropDown();

  const {
    handleSetChecklistDetail,
    handleSetIsNewChecklist,
    handleSetEditPage,
    handleSetSelectedItemChecklist,
    selectedItemChecklist,
    handleSetIsNotMenusAccess,
  } = useServiceChecklist();

  const {
    formData,
    setFormData,
    setOpenModalConfirm,
    openModalConfirm,
    handleCloseForm,
    setCurrentEditPmocService,
    handlePmocServiceClean,
  } = useHandleModalService();

  const [loading, setLoading] = useState(false);
  const [hasErrorOnCategory, setHasErrorOnCategory] = useState("");
  const [hasErrorOnService, setHasErrorOnService] = useState("");
  const [hasErrorOnRunTime, setHasErrorOnRuntime] = useState("");
  const [hasErrorOnRuntimeDropdown, setHasErrorOnRuntimeDropown] = useState("");
  const [hasErrorOnWarranty, setHasErrorOnWarranty] = useState("");
  const [hasErrorOnWarrantyDropdown, setHasErrorOnWarrantyDropdown] =
    useState("");
  const [hasErrorOnFrequency, setHasErrorOnFrequency] = useState("");
  const [unMaskedPrice, setUnmaskedPrice] = useState(
    currentPmocEditService.price ? currentPmocEditService.price : 0
  );
  const [price, setPrice] = useState<number | string>(
    currentPmocEditService.price ? currentPmocEditService.price : 0
  );

  const [serviceChecklist, setServiceChecklist] = useState<ChecklistItemList[]>(
    []
  );
  const [indexDelete, setIndexDelete] = useState<number>();
  const [isOpenAlertModalDelete, setIsOpenAlertModalDelete] = useState(false);

  useEffect(() => {
    setLoading(true);
    if (currentPmocEditService.id) {
      api
        .get(`services/new-service-form/${currentPmocEditService.id}`)
        .then((response) => {
          const { categoriesTree } = response.data;
          handleSetCategoriesTree(categoriesTree);
          setLoading(false);
        });
      setServiceChecklist(selectedItemChecklist);
      return;
    }
    api.get(`services/new-service-form`).then((response) => {
      const { categoriesTree } = response.data;
      handleSetCategoriesTree(categoriesTree);
      setLoading(false);
    });
    setServiceChecklist(selectedItemChecklist);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (currentPmocEditService?.id) {
      if (selectedCategoryId !== currentPmocEditService?.serviceCategory?.id) {
        setHasErrorOnService("selecione um serviço");
      } else {
        setHasErrorOnService("");
      }
    }
  }, [
    currentPmocEditService?.id,
    currentPmocEditService?.serviceCategory?.id,
    selectedCategoryId,
  ]);

  function handleSelectCategory({ id }: DropdownOption) {
    if (selectedCategoryId === id) {
      return;
    }

    handleCategoryId(id);
    handleService({} as DropdownOption);

    setHasErrorOnCategory("");
    setHasErrorOnService("");
  }

  const handleChangeFieldValues = (e: DataProps) => {
    setFormData({
      ...formData,
      categoryId: selectedCategoryId?.toString(),
      serviceTypeId: selectedService.id?.toString(),
      frequency: e.frequency?.toString().toLowerCase(),
      price: e.price,
      serviceSteps: e.serviceSteps,
      runtime: {
        extension: e.runtime?.extension?.toString() || "",
        time: e.runtime?.time || "",
      },
      warranty: {
        extension: e.warranty?.extension?.toString() || "",
        time: e.warranty?.time || "",
      },
    });
  };

  const handlePostForm = async (data: DataProps) => {
    try {
      const pmocService =
        serviceChecklist.length > 0
          ? {
              ...data,
              warranty: {
                ...data.warranty,
                time: Number(data.warranty?.time),
              },
              runtime: {
                ...data.runtime,
                time: Number(data.runtime?.time),
              },
              checklistId: serviceChecklist[0].id,
            }
          : {
              ...data,
              warranty: {
                ...data.warranty,
                time: Number(data.warranty?.time),
              },
              runtime: {
                ...data.runtime,
                time: Number(data.runtime?.time),
              },
            };

      await api.post("/pmoc/service", pmocService);
      setFormData({} as DataProps);
    } catch (error) {
      addToast({
        title: "Ops!!",
        description:
          axios.isAxiosError(error) && error.response?.data.error
            ? error.response.data.error
            : "Ocorreu um erro na criação do serviço de PMOC.",
        type: "error",
      });
    }
  };

  const handleUpdateForm = async (data: DataProps) => {
    await api.put(`/pmoc/service/${currentPmocEditService.id}`, {
      ...data,
      warranty: {
        ...data.warranty,
        time: Number(data.warranty?.time),
      },
      price: unMaskedPrice,
      runtime: {
        ...data.runtime,
        time: Number(data.runtime?.time),
      },
      checklistId: serviceChecklist[0].id,
    });
  };

  const validations = () => {
    const objData = {
      ...formData,
      categoryId: selectedCategoryId ? selectedCategoryId?.toString() : "",
      serviceTypeId: selectedService?.id?.toString(),
    };

    if (!objData.categoryId) {
      setHasErrorOnCategory("Categoria é obrigatório");
    } else {
      setHasErrorOnCategory("");
    }
    if (!objData.serviceTypeId) {
      setHasErrorOnService("Serviço é obrigatório");
    } else {
      setHasErrorOnService("");
    }
    if (!objData.frequency) {
      setHasErrorOnFrequency("Periodicidade do serviço é obrigatório");
    } else {
      setHasErrorOnFrequency("");
    }

    if (Number(objData?.runtime?.time) > 0 && !!!objData.runtime?.extension) {
      setHasErrorOnRuntimeDropown("Selecione uma das opções");
    } else {
      setHasErrorOnRuntimeDropown("");
    }
    if (Number(objData?.runtime?.time) <= 0 && objData.runtime?.extension) {
      setHasErrorOnRuntime("O valor tem que ser maior que zero");
    } else {
      setHasErrorOnRuntime("");
    }
    if (Number(objData?.warranty?.time) > 0 && !!!objData.warranty?.extension) {
      setHasErrorOnWarrantyDropdown("Seleciona uma das opções");
    } else {
      setHasErrorOnWarrantyDropdown("");
    }
    if (Number(objData?.warranty?.time) <= 0 && objData.warranty?.extension) {
      setHasErrorOnWarranty("O valor tem que ser maior que zero");
    } else {
      setHasErrorOnWarranty("");
    }

    if (currentPmocEditService?.id) {
      if (selectedCategoryId !== currentPmocEditService?.serviceCategory.id) {
        setHasErrorOnService("selecione um serviço");
      } else {
        setHasErrorOnService("");
      }
    }

    setOpenModalConfirm(false);
  };

  const handleSubmit = async () => {
    try {
      const objData = {
        ...formData,
        categoryId: selectedCategoryId ? selectedCategoryId?.toString() : "",
        serviceTypeId: selectedService.id?.toString(),
      };

      validations();

      if (
        !objData.categoryId ||
        !objData.serviceTypeId ||
        !objData.frequency ||
        (Number(objData.runtime?.time) <= 0 && objData.runtime?.extension) ||
        (Number(objData.runtime?.time) > 0 && !objData.runtime?.extension) ||
        (Number(objData.warranty?.time) <= 0 && objData.warranty?.extension) ||
        (Number(objData.warranty?.time) > 0 && !objData.warranty?.extension)
      ) {
        addToast({
          title: "Ops...",
          description: "Preencha os campos obrigatórios....",
          type: "error",
        });
        setOpenModalConfirm(false);

        return;
      }

      currentPmocEditService?.id
        ? await handleUpdateForm(objData)
        : await handlePostForm(objData);

      handleCloseForm();

      loadServicesPmoc();
      handlePmocServiceClean();
      reset();
      handleSetIsNotMenusAccess(false);
      handleSetSelectedItemChecklist([]);

      addToast({
        title: "Sucesso.",
        description: "Serviço criado com sucesso",
        type: "success",
      });

      return false;
    } catch (error) {
      setOpenModalConfirm(false);
      return true;
    }
  };

  async function detailItem(currentChecklist: ChecklistItemList) {
    try {
      handleSetChecklistDetail(currentChecklist);
      handleSetIsNewChecklist(false);
      if (handleEditPage) handleEditPage();
    } catch (error) {
      addToast({
        title: "Ops!!",
        description: "Não foi possível obter os detalhes do checklist.",
        type: "error",
      });
    } finally {
    }
  }

  function handleDeleteRegister(ind: number) {
    let aux = serviceChecklist.filter((element, index) => {
      return index !== ind;
    });
    setServiceChecklist(aux);
    handleSetSelectedItemChecklist([]);
  }

  const handleSubmitForm = (event: FormEvent<HTMLFormElement>): void => {
    event.preventDefault();
  };

  return (
    <S.Container>
      <AlertModal
        isVisible={isOpenAlertModalDelete}
        title="Deseja apagar?"
        description={"Deseja realmente apagar esse checklist?"}
        action="choose"
        handleConfirm={() => {
          handleDeleteRegister(indexDelete as number);
          setIsOpenAlertModalDelete(false);
        }}
        onCloseModal={() => setIsOpenAlertModalDelete(false)}
      />

      <DiscardInformationModal
        showModal={openModalConfirm}
        handleConfirmButton={() => {
          setOpenModalConfirm(false);
          handlePmocServiceClean();
          setCurrentEditPmocService({} as PMOCServiceProps);
          setHasErrorOnService("");
        }}
        handleCancelButton={() => {
          setOpenModalConfirm(false);
        }}
      />
      <S.Top>
        <S.ButtonClose
          onClick={() => {
            handleSetIsNotMenusAccess(false);
            handleCloseForm();
            handleSetSelectedItemChecklist([]);
          }}
        >
          <img src={arrowLeft} alt="flecha pra esquerda" />
        </S.ButtonClose>

        <p>
          {!!currentPmocEditService.id
            ? "Editar serviço de PMOC"
            : "Novo serviço de PMOC"}
        </p>
      </S.Top>

      {loading ? (
        <LoadingPageModal />
      ) : (
        <form id="form_servicos_PMOC" onSubmit={handleSubmitForm}>
          <S.Wrapper>
            <Dropdown
              label="Categoria*"
              placeholder="Selecione uma categoria"
              options={categoriesTree}
              name={"category"}
              categoryIdSelected={currentPmocEditService?.serviceCategory?.id}
              onClickedValue={handleSelectCategory}
              hasError={hasErrorOnCategory}
            />
          </S.Wrapper>

          <S.Wrapper>
            <ServiceName name="service" hasErrorOnService={hasErrorOnService} />
          </S.Wrapper>

          <S.Wrapper>
            <Dropdown
              label="Periodicidade do serviço*"
              placeholder="Selecione uma periodicidade"
              options={DROPDOWN_OPTIONS}
              optionSelected={currentPmocEditService?.frequency?.replace(
                /^\w/,
                (letra) => letra.toUpperCase()
              )}
              categoryIdSelected={currentPmocEditService?.serviceCategory?.id}
              onClickedValue={({ name }) =>
                handleChangeFieldValues({
                  ...formData,
                  frequency: name,
                })
              }
              hasError={hasErrorOnFrequency}
            />
          </S.Wrapper>

          <S.Wrapper>
            <S.Row>
              <div>
                <S.Title>Tempo de execução (opcional)</S.Title>
                <Input
                  style={{ minWidth: "300px" }}
                  placeholder="Ex: 2"
                  inputName={"timeExecution"}
                  type={"number"}
                  defaultValue={currentPmocEditService.runtime?.time}
                  hasError={hasErrorOnRunTime}
                  onChange={(e) => {
                    handleChangeFieldValues({
                      ...formData,
                      runtime: {
                        extension: formData.runtime?.extension || "",
                        time: e.target.value || "",
                      },
                    });
                  }}
                />
              </div>

              <Dropdown
                placeholder="Selecione"
                options={HOURS}
                hasError={hasErrorOnRuntimeDropdown}
                optionSelected={currentPmocEditService.runtime?.extension?.replace(
                  /^\w/,
                  (letra) => letra.toUpperCase()
                )}
                onClickedValue={({ name, id }) => {
                  handleChangeFieldValues({
                    ...formData,
                    runtime: {
                      extension: name,
                      time: formData.runtime?.time || "",
                    },
                  });
                }}
              />
            </S.Row>
          </S.Wrapper>

          <S.Wrapper>
            <S.Row>
              <div>
                <S.Title>Garantia (opcional)</S.Title>
                <Input
                  style={{ minWidth: "300px" }}
                  placeholder="Ex: 2"
                  hasError={hasErrorOnWarranty}
                  defaultValue={currentPmocEditService.warranty?.time}
                  inputName={"garanted"}
                  type={"number"}
                  onChange={(e) => {
                    handleChangeFieldValues({
                      ...formData,
                      warranty: {
                        extension: formData.warranty?.extension || "",
                        time: e.target.value,
                      },
                    });
                  }}
                />
              </div>

              <Dropdown
                placeholder="Selecione"
                options={PERIODS}
                hasError={hasErrorOnWarrantyDropdown}
                optionSelected={currentPmocEditService.warranty?.extension?.replace(
                  /^\w/,
                  (letra) => letra.toUpperCase()
                )}
                onClickedValue={({ name, id }) => {
                  handleChangeFieldValues({
                    ...formData,
                    warranty: {
                      extension: name,
                      time: formData.warranty?.time || "",
                    },
                  });
                }}
              />
            </S.Row>
          </S.Wrapper>

          <S.Wrapper>
            <CommentsTextArea
              label="Detalhamento do Serviço (opcional)"
              placeholder="Texto livre"
              defaultValue={currentPmocEditService?.serviceSteps}
              onChange={(event) => {
                handleChangeFieldValues({
                  ...formData,
                  serviceSteps: event.target.value,
                });
              }}
            />
          </S.Wrapper>

          <S.Wrapper>
            <Input
              value={currentPmocEditService.price}
              name="Valor (opcional)"
              maskType="money"
              inputName="price"
              {...(currentPmocEditService.id && {
                valueCurrency: price,
              })}
              onChangeCurrency={({ formattedValue, value, floatValue }) => {
                setPrice(formattedValue);
                setUnmaskedPrice(floatValue!);
                handleChangeFieldValues({
                  ...formData,
                  price: floatValue,
                });
              }}
              hasError={""}
              onFocusClearError={() => {}}
            />
          </S.Wrapper>

          {serviceChecklist.length > 0 && (
            <S.Wrapper>
              {serviceChecklist.map((currentChecklist, index) => {
                return (
                  <S.WrapperService key={index}>
                    <CardChecklist>
                      <S.WrapperInfoCard>
                        <S.WrapperData>
                          <h6>{currentChecklist.name}</h6>
                          <p>{currentChecklist?.registers?.length} Registros</p>
                        </S.WrapperData>
                        <S.WrapperButtom>
                          {/*validar o checklist padrao nao pode ser editado...*/}
                          <EditButton
                            onClick={() => {
                              handleSetEditPage(true);
                              detailItem(currentChecklist);
                            }}
                          />
                          <DeleteButton
                            //disabled={currentChecklist.default ? true : false}
                            onClick={() => {
                              setIndexDelete(index);
                              setIsOpenAlertModalDelete(true);
                              //handleDeleteRegister(index)
                            }}
                          />
                        </S.WrapperButtom>
                      </S.WrapperInfoCard>
                    </CardChecklist>
                  </S.WrapperService>
                );
              })}
            </S.Wrapper>
          )}

          <S.Wrapper>
            <Button typeButton="outline" onClick={() => handleAddChecklist()}>
              Incluir checklists
            </Button>
          </S.Wrapper>

          <S.Wrapper>
            <Button loading={false} onClick={handleSubmit}>
              Salvar
            </Button>
          </S.Wrapper>
        </form>
      )}
    </S.Container>
  );
};
export { FormPmocServices };
