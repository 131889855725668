import styled, { css } from "styled-components";

export const Container = styled.div`
  ${({ theme }) => css`
  width: 50px;
  min-width: 50px;
    height: 50px;
    border: 1px dashed ${theme.colors.primary};
    border-radius: 10px;

    display: flex;
    justify-content: center;
    align-items: center;
    input {
      display: none;
    }
  `}
`;
