import * as S from "./styles";
import { ArrowButton } from "components/ArrowButton";

type PageDetailProps = {
  title: string;
  subtitle: string;
  children: React.ReactNode;
  hideGoBackButton?: boolean;
  handleGoBack?: () => void;
};

export function PageDetail({
  title,
  subtitle,
  hideGoBackButton,
  handleGoBack,
  children,
}: PageDetailProps) {
  return (
    <S.Container>
      <header>
        {!hideGoBackButton && (
          <S.Wrapper>
            <ArrowButton handleFunction={handleGoBack} />
          </S.Wrapper>
        )}
        <h1>{title}</h1>
      </header>

      <S.Content>
        <S.Label>{subtitle}</S.Label>
        {children}
      </S.Content>
    </S.Container>
  );
}
