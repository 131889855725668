import { useState, useEffect } from "react";

import axios from "axios";
import { ExecutionListProps } from "dtos/PmocDetailOfMonthDTO";
import { api } from "services/api";
import { useToast } from "hooks/toast";
import { OSListComponent } from "../OSListComponent";

import * as S from './styles';
import { useHistory } from "react-router";

type EnvironmentFilter = {
  id: number;
  name: string;
}

type ArchivedTabProps = {
  year: number;
  month: string;
  idPmoc: number;
  setLoading: (value:boolean) => void;
  filter?: EnvironmentFilter;
  filterModalClose?: boolean;
};

type ResponseProps = {
  data: {
    executionList: ExecutionListProps[];
  }
}

export function ArchivedTab({
  year,
  month,
  setLoading,
  filter,
  filterModalClose,
  idPmoc }: ArchivedTabProps) {
  
  const { addToast } = useToast();
  const [orderServiceList, setOrderServiceList] = useState<ExecutionListProps[]>([] as ExecutionListProps[]);
 
  const history = useHistory();

  const setData = async () => {
   try {
     setLoading(true);
     
     let params = filter && Object.keys(filter).length > 0 ?
       {
           year: year,
           month: month,
           archive:true,
           environmentId: Number(filter.id)
       } :
       {
           year: year,
           month: month,
           archive:true,
        }

     const { data }: ResponseProps = await api.get(`pmoc/${idPmoc}/service/executions`,
       {
         params
       });
     const { executionList } = data;
     setOrderServiceList(executionList);
     
    } catch (error) {
      addToast({
        title: "Erro",
        description:
          axios.isAxiosError(error) && error.response?.data.error
            ? error.response?.data.error
            : "Não foi possível carregar as OS do mês selecionado.",
        type: "error",
      });
    }
    finally {
      setLoading(false);
    }
  }
  
  async function handleSetPage(idOS: number) {
    history.push(`/pmoc/execution/order-service/archived/${idOS}/${idPmoc}/${year}/${month}`);
  }


  useEffect(() => { 
    if (!filterModalClose) return;
    setData();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter, filterModalClose]);
  
  return (<>
    {orderServiceList.length < 1 ? (
      <S.EmptyList>
        <p>Você não possui ordens de serviço arquivadas.</p>
      </S.EmptyList>
    ): (
        <OSListComponent
          osList={orderServiceList}
          statusOS="archived"
          handleRoutePage={handleSetPage}          
        />
    )}
  </>);
}