/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { FiX } from "react-icons/fi";
import { RiUser3Line } from "react-icons/ri";
import { useTheme } from "styled-components";

import { Button } from "components/Button";
import { AlertModal } from "components/AlertModal";
import { ShowWhenHavePermission } from "components/Permission";

import { api } from "services/api";
import { useToast } from "hooks/toast";
import { useEditClient } from "hooks/editClient";

import emailSvg from "assets/icons/square-email.svg";
import phoneSvg from "assets/icons/contacts.svg";
import docucumentSvg from "assets/icons/document.svg";
import locationSvg from "assets/icons/location.svg";

import editClientSvg from "assets/icons/edit-wrapper.svg";

import * as S from "./styles";
import { ModalPageProps } from "../types";
import { RenderLocationAddress } from "components/RedirectLocationAddress";

type Props = {
  handleToggleOpen: () => void;
  handleChooseEditModal: ModalPageProps;
};

export function ClientDetails({
  handleToggleOpen,
  handleChooseEditModal,
}: Props) {
  const theme = useTheme();
  const { addToast } = useToast();
  const { editedClient, loadUnities, unities, clearEditClientData } =
    useEditClient();

  // eslint-disable-next-line camelcase
  const { id, name, phone, email, address, cpf_cnpj } = editedClient;

  const [isVisibleAlert, setIsVisibleAlert] = useState(false);
  const [loadingButton, setLoadingButton] = useState(false);

  async function handleDeleteClient() {
    setLoadingButton(true);
    setIsVisibleAlert(false);

    try {
      await api.delete(`clients/${id}`);

      addToast({
        type: "success",
        title: "Tudo certo!",
        description: "Cliente excluído com sucesso!",
      });

      handleToggleOpen();
      clearEditClientData();
    } catch (error) {
      addToast({
        type: "error",
        title: "Erro ao excluir cliente",
        description: "Ocorreu um erro ao excluir o cliente, tente novamente.",
      });
    } finally {
      setLoadingButton(false);
    }
  }

  useEffect(() => {
    window.scrollTo(0, 0);
    loadUnities();
  }, []);

  return (
    <>
      <AlertModal
        isVisible={isVisibleAlert}
        title="Excluir cliente"
        description="Tem certeza que deseja excluir este cliente?"
        action="choose"
        handleConfirm={() => handleDeleteClient()}
        onCloseModal={() => setIsVisibleAlert(false)}
      />
      <S.Top>
        <div>
          <S.ButtonClose
            onClick={() => {
              handleToggleOpen();
              clearEditClientData();
            }}
          >
            <FiX size={10} color={theme.colors.text} />
          </S.ButtonClose>

          <p>Detalhes do cliente</p>
        </div>

        <ShowWhenHavePermission moduleHash="client" actionHash="edit">
          <button
            type="button"
            onClick={() => handleChooseEditModal("editClient")}
          >
            <img src={editClientSvg} alt="Editar o perfil do cliente" />
          </button>
        </ShowWhenHavePermission>
      </S.Top>

      <S.Main>
        <S.WrapperProfile>
          <div>
            <RiUser3Line size={38} color={theme.colors.primary} />
          </div>

          <h2>{name && name}</h2>
          {address?.city || address?.uf ? (
            <span>{`${address?.city} - ${address?.uf}`}</span>
          ) : (
            <span>Sem endereço cadastrado</span>
          )}
        </S.WrapperProfile>

        {email && (
          <S.WrapperDetails>
            <img src={emailSvg} alt="email do cliente" />

            <div>
              <span>E-mail</span>
              <span>{email}</span>
            </div>
          </S.WrapperDetails>
        )}

        {phone && (
          <S.WrapperDetails>
            <img src={phoneSvg} alt="telefone do cliente" />

            <div>
              <span>Telefone</span>
              <span>{phone}</span>
            </div>
          </S.WrapperDetails>
        )}

        {/* eslint-disable-next-line camelcase */}
        {cpf_cnpj && (
          <S.WrapperDetails>
            <img src={docucumentSvg} alt="documento do cliente" />

            <div>
              <span>CPF/CNPJ</span>
              {/* eslint-disable-next-line camelcase */}
              <span>{cpf_cnpj}</span>
            </div>
          </S.WrapperDetails>
        )}

        {!!unities.length ? (
          <ShowWhenHavePermission moduleHash="client-unity" actionHash="view">
            <S.WrapperDetails>
              <img src={locationSvg} alt="endereço do cliente" />

              <div>
                <span>Endereço</span>
                <RenderLocationAddress
                  city={address?.city}
                  complement={address?.complement}
                  district={address?.district}
                  number={Number(address?.number)}
                  postalCode={address?.postalCode}
                  street={address?.street}
                  uf={address?.uf}
                />

                {unities.length > 0 ? (
                  <ShowWhenHavePermission
                    moduleHash="client-unity"
                    actionHash="view"
                  >
                    <S.RegisteredUnities
                      onClick={() => handleChooseEditModal("unityList")}
                    >
                      ver todos os endereços cadastrados ({unities.length})
                    </S.RegisteredUnities>
                  </ShowWhenHavePermission>
                ) : (
                  <S.RegisteredUnities>
                    Não possui endereços adicionais
                  </S.RegisteredUnities>
                )}
              </div>
            </S.WrapperDetails>
          </ShowWhenHavePermission>
        ) : (
          <ShowWhenHavePermission moduleHash="client-unity" actionHash="create">
            <S.WrapperDetails>
              <img src={locationSvg} alt="endereço do cliente" />

              <div>
                <S.RegisteredUnities
                  onClick={() => handleChooseEditModal("unityForm")}
                >
                  Adicionar novo endereço
                </S.RegisteredUnities>
              </div>
            </S.WrapperDetails>
          </ShowWhenHavePermission>
        )}

        <S.WrapperButton>
          <ShowWhenHavePermission moduleHash="client" actionHash="delete">
            <Button
              typeButton="delete"
              loading={loadingButton}
              onClick={() => setIsVisibleAlert(true)}
            >
              Excluir contato
            </Button>
          </ShowWhenHavePermission>
        </S.WrapperButton>
      </S.Main>
    </>
  );
}
