import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components'

export const Container = styled.div`
  ${({ theme }) => css`
    width: 100%;
    max-width: 1400px;
    min-width: 470px;
    margin: 0 auto;
    padding: 30px;
    position: relative;
  
    header {
      display: flex;
      align-items: center;
      justify-content: space-between;

      h1 {
        font-size: ${theme.font.sizes.large};
        color: ${theme.colors.title};
        font-weight: ${theme.font.bold};
        margin-left: 25px;
      }

      div {
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
    }

    /* header {
      display: flex;
      align-items: center;

      h1 {
        font-size: ${theme.font.sizes.large};
        color: ${theme.colors.title};
        font-weight: ${theme.font.bold};
        margin-left: 25px;
      }

      div {
        width: 80px;
        display: flex;
        justify-content: space-between;
      }
    } */
  `}
`

export const TabContainer = styled.div`
  ${({ theme }) => css`
    margin-top: 33px;

    .react-tabs__tab-list {
      border-bottom: 1px solid rgba(255, 255, 255, 0.12);
      display: flex;
      justify-content: center;
    }

    .react-tabs__tab {
      margin-right: 142px;
      color: ${theme.colors.text};
    }

    .react-tabs__tab--selected {
      background: inherit;
      border: 0px;
      color: ${theme.colors.text};

      font-weight: 500;
      line-height: 1.35;
      white-space: normal;
      letter-spacing: 0.02857em;
      border-bottom: 3px solid ${theme.colors.primary_light};
    }
  `}
`

export const ButtonAdd = styled(Link)`
  ${({ theme }) => css`
    width: 50px;
    height: 46px;
    border-radius: 8px;
    margin-right: 26px;
    background: ${theme.colors.primary};
    text-decoration: none;

    font-size: ${theme.font.sizes.xlarge};
    color: ${theme.colors.background};

    display: flex;
    align-items: center;
    justify-content: center;
  `}
`;
