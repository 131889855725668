import { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { useTheme } from "styled-components";
import { FiCheck } from "react-icons/fi";

import { useAuth } from "hooks/auth";
import { useRegisterPhotosModal } from "hooks/registerPhotosModal";
import { useServiceOrder } from "hooks/serviceOrder";
import { ServiceWithChecklistDTO } from "dtos/ServiceWithChecklistDTO";

import { ModalRight } from "components/ModalRight";
import { ContentLeft } from "templates/ContentLeft";
import { ArrowButton } from "components/ArrowButton";
import { Button } from "components/Button";
import { ShowWhenHavePermission } from "components/Permission";
import { RunServiceChecklist } from "./RunServiceChecklist";
import { RegisterSignatures } from "../RegisterSignatures";
import { useAccount } from "hooks/permission/account";
import apiv2 from "services/apiv2";

import arrowRight from "assets/icons/arrow-right-service-hub.svg";

import * as S from "./styles";
import { LoadingProfiz } from "components/LoadingProfiz";
import { ServiceOrderDTO } from "dtos/ServiceOrderDTO2";

export type ModalExecutionServiceChecklistType =
  | "runServiceChecklist"
  | "registerSignatures";

export function ServicesHub() {
  const history = useHistory();
  const { user } = useAuth();
  const { whoami } = useAccount();

  const theme = useTheme();
  const {
    currentService,
    selectedServiceOrder,
    handleSetSelectedServiceOrder,
    handleSetCurrentService,
    handleSetCurrentServiceChecklist,
  } = useServiceOrder();

  const { isOpenRegisterPhotosModal, setIsOpenRegisterPhotosModal, handleToggleRegisterPhotosModal } =
    useRegisterPhotosModal();

  const [isLoadingServiceOrder, setIsLoadingServiceOrder] = useState(true);
  const [modalExecutionServiceChecklist, setModalExecutionServiceChecklist] =
    useState<ModalExecutionServiceChecklistType>("runServiceChecklist");
  const [hasAnyServiceConcluded, setHasAnyServiceConcluded] = useState(false);

  useEffect(() => {
    async function loadServiceChecklist() {
      try {
        const { 2: serviceOrderIdFromUrl } = window.location.pathname.split("/");
      const { data: serviceOrderDetail } = await apiv2.get<ServiceOrderDTO>(
        `/budgets/service-order/${serviceOrderIdFromUrl}`,
        {
          params: {
            accountId: whoami?.id,
          },
        }
      );

      const currentServiceFound = serviceOrderDetail.services.find(current => current.idBudgetService === currentService.idBudgetService);
      if (currentServiceFound) {
        handleSetCurrentService(currentServiceFound);
      };

      handleSetSelectedServiceOrder(serviceOrderDetail);
      } finally {
        setIsLoadingServiceOrder(false);
      }
    }

    loadServiceChecklist();

    return () => {
      setIsOpenRegisterPhotosModal(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    function findServicesAlreadyConcluded() {
      const servicesAlreadyConcluded = selectedServiceOrder?.services?.find(
        (currentService: ServiceWithChecklistDTO) =>
          currentService.status === "concluded"
      );

      if (!!servicesAlreadyConcluded) {
        setHasAnyServiceConcluded(true);
      }
    }
    findServicesAlreadyConcluded();
  }, [selectedServiceOrder]);

  function handleNavigateToRunServiceChecklist(
    service: ServiceWithChecklistDTO
  ) {
    if (service.service.serviceChecklist) {
      handleSetCurrentService(service);
      handleSetCurrentServiceChecklist(service.service.serviceChecklist[0]);
      handleToggleRegisterPhotosModal();
    }
  }

  function handleGoBack() {
    history.goBack();
  }

  function handleNavigateToFinishServiceOrder() {
    const { 2: serviceOrderIdFromUrl } = window.location.pathname.split("/");
    history.push({
      pathname: `/service-order/concluded/${serviceOrderIdFromUrl}`,
      state: { before: "RegisterSignatures" },
    });
  }

  function handleNavigateToRegisterSignatures() {
    const { 2: serviceOrderIdFromUrl } = window.location.pathname.split("/");
    history.push(`/service-signatures/${serviceOrderIdFromUrl}`);
  }

  function handleNextOrSkipChecklistExecution() {
    if (selectedServiceOrder.isNewSignVersion) {
      handleNavigateToFinishServiceOrder();
    } else {
      handleNavigateToRegisterSignatures();
    }
  }

  if (isLoadingServiceOrder) {
    return <LoadingProfiz isVisible={true} />;
  }

  return (
    <S.Container>
      <ModalRight
        isOpen={isOpenRegisterPhotosModal}
        handleToggleOpen={() => {
          handleToggleRegisterPhotosModal();
          setModalExecutionServiceChecklist("runServiceChecklist");
        }}
      >
        {modalExecutionServiceChecklist === "runServiceChecklist" && (
          <RunServiceChecklist
            onNavigateToStepChecklist={(modal) =>
              setModalExecutionServiceChecklist(modal)
            }
          />
        )}

        {modalExecutionServiceChecklist === "registerSignatures" && (
          <RegisterSignatures
            onNavigateToStepChecklist={(modal) =>
              setModalExecutionServiceChecklist(modal)
            }
          />
        )}
      </ModalRight>

      <header>
        <ArrowButton handleFunction={handleGoBack} />
        <h1>Checklist dos serviços</h1>
      </header>

      <ContentLeft>
        <S.Content>
          <S.Wrapper>
            <S.Label>Vamos fazer o checklist dos serviços realizados.</S.Label>
          </S.Wrapper>

          {selectedServiceOrder?.services?.map((currentService) => {
            let labelNameService =
              selectedServiceOrder.pmoc.id > 0
                ? currentService.service.service.name +
                  " " +
                  selectedServiceOrder.equipment?.tagEquipment
                : currentService.service.service.name +
                  " " +
                  currentService.service.equipment.name;

            return (
              <div key={currentService.idBudgetService}>
                {currentService?.userExecution?.id &&
                Number(user.id) !== currentService.userExecution.id ? (
                  <Button
                    type="button"
                    disabled
                    typeButton="disabled"
                    onClick={() => {
                      handleNavigateToRunServiceChecklist(currentService);
                    }}
                  >
                    {labelNameService}
                    {currentService.status === "concluded" && (
                      <div>
                        <FiCheck size={20} color={theme.colors.primary} />
                      </div>
                    )}
                  </Button>
                ) : (
                  <Button
                    type="button"
                    typeButton={
                      currentService.status === "concluded"
                        ? "unselected"
                        : "outline"
                    }
                    onClick={() => {
                      handleNavigateToRunServiceChecklist(currentService);
                    }}
                  >
                    {labelNameService}
                    {currentService.status === "concluded" && (
                      <div>
                        <FiCheck size={20} color={theme.colors.primary} />
                      </div>
                    )}
                  </Button>
                )}
              </div>
            );
          })}

          <ShowWhenHavePermission
            moduleHash="budgets-service-order"
            actionHash="edit-finish"
          >
            <S.WrapperButton>
              {hasAnyServiceConcluded ? (
                <button
                  type="button"
                  onClick={handleNextOrSkipChecklistExecution}
                >
                  <img
                    src={arrowRight}
                    alt="flecha pra direita"
                    width="22"
                    height="22"
                  />
                </button>
              ) : (
                <button
                  type="button"
                  onClick={handleNextOrSkipChecklistExecution}
                >
                  Pular
                </button>
              )}
            </S.WrapperButton>
          </ShowWhenHavePermission>
        </S.Content>
      </ContentLeft>
    </S.Container>
  );
}
