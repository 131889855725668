import styled, { css } from "styled-components";

export const Wrapper = styled.div`
  margin-top: 20px;
`;

export const WrapperRole = styled.div`
  margin-top: 20px;
`;

export const Label = styled.span`
  ${({ theme }) => css`
    font-family: ${theme.font.family};
    font-size: ${theme.font.sizes.xsmall};
    font-weight: ${theme.font.normal};
    color: ${theme.colors.text_light_60};
    line-height: 20px;
  `}
`;

export const Highlight = styled.span`
  ${({ theme }) => css`
    font-family: ${theme.font.family};
    font-size: ${theme.font.sizes.xsmall};
    font-weight: ${theme.font.normal};
    color: ${theme.colors.text_light_87};
    line-height: 20px;
  `}
`;
