import styled, { css } from "styled-components";

export const WrapperGroup = styled.div`
  margin-top: 30px;
`;

export const ContainerProfile = styled.div`
  display: flex;
`;

export const EditPermission = styled.button`
  ${({ theme }) => css`
    height: 40px;
    width: 40px;

    display: flex;
    justify-content: center;
    align-items: center;
    transition: opacity 0.2s;
    border-radius: 12px;
    background-color: ${theme.colors.background_light};
    padding: 10px;

    &:hover {
      opacity: 0.7;
    }
    img {
      margin-right: 0px !important;
      width: 16px !important;
      height: 16px !important;
    }
  `}
`;
export const ProfileInfo = styled.section`
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;

  img {
    width: 30px;
    border-radius: 50%;
  }
`;

export const WrapperProfile = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;

    img {
      width: 80px;
      height: 80px;
      border-radius: 50%;
      margin-right: 10px;
    }

    h4 {
      font-family: ${theme.font.family};
      font-weight: ${theme.font.bold};
      color: ${theme.colors.text_light_87};
      font-size: ${theme.font.sizes.small};
      margin-bottom: 5px;
    }
  `}
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
`;

export const Text = styled.span`
  ${({ theme }) => css`
    font-family: ${theme.font.family};
    font-size: ${theme.font.sizes.xsmall};
    font-weight: ${theme.font.normal};
    color: ${theme.colors.text_light_60};
    line-height: 16px;
  `}
`;

export const Highlight = styled.span`
  ${({ theme }) => css`
    font-family: ${theme.font.family};
    font-size: ${theme.font.sizes.xsmall};
    font-weight: ${theme.font.bold};
    color: ${theme.colors.text_light_87};
    line-height: 16px;
  `}
`;

export const CompanyInfo = styled.section`
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;

  img {
    width: 35px;
    height: 35px;
    border-radius: 50%;
    margin-right: 10px;
  }
`;
