import React from "react";

import { Container } from "./styles";

interface TooltipProps {
  title: string;
  className?: string;
  children: React.ReactNode;
  onClick?: () => void;
}

export function Tooltip({ title, className, onClick, children }: TooltipProps) {
  return (
    <Container className={className} onClick={onClick}>
      {children}
      <span>{title}</span>
    </Container>
  );
}
