import React from 'react'

import { QuantityCounter } from 'components/QuantityCounter'
import { SelectedMaterialProps } from 'hooks/budget/selectedMaterials'

import * as S from './styles'

type CardSelectedMaterialProps = {
  data: SelectedMaterialProps;
  onIncrement: (id: number, price: number) => void;
  disabled?: boolean;
}

export function CardSelectedMaterial({
  data,
  onIncrement,
  disabled
}: CardSelectedMaterialProps) {
  function handleIncrement(quantity: number) {
    if (disabled) {
      return;
    }
    onIncrement(data.id, quantity)
  }

  return (
    <S.Container>
      <S.WrapperContent>
        <S.Title>{data.item.name}</S.Title>
        <S.Title>{data.formattedTotal}</S.Title>
      </S.WrapperContent>

      <S.WrapperContent>
        <S.TextDetail>Quantidade:</S.TextDetail>

        <QuantityCounter
          initialValue={data.quantity}
          // eslint-disable-next-line react/jsx-no-bind
          onIncrement={handleIncrement}
          disabled={disabled}
        />
      </S.WrapperContent>
    </S.Container>
  )
}
