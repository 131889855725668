import React, { useEffect, useState } from "react";
import * as Yup from "yup";

import { Dropdown, DropdownOption } from "components/DropDown";
import { Input } from "components/Input";
import { Button } from "components/Button";

import { useToast } from "hooks/toast";
import { DropdownDTO } from "dtos/DropdownDTO";

import getValidationErrors from "utils/getValidationErrors";

import arrowLeft from "assets/icons/arrow-left.svg";

import { api } from "../../../services/api";
import * as S from "./styles";

type FormCreateLocalChecklistProps = {
  handleToggleModal: () => void;
};

type IdSelectedProps = null | number;

type ChecklistCategories = {
  id: number;
  name: string;
};

type Errors = {
  [key: string]: string;
};

export function FormCreateLocalChecklist({
  handleToggleModal,
}: FormCreateLocalChecklistProps) {
  const { addToast } = useToast();

  const [categories, setCategories] = useState<DropdownDTO[]>([]);
  const [name, setName] = useState("");
  const [price, setPrice] = useState("");
  const [unmaskedPrice, setUnmaskedPrice] = useState("");

  const [hasErrorOnCategory, setHasErrorOnCategory] = useState("");
  const [hasError, setHasError] = useState<Errors>({} as Errors);

  const [loadingButton, setLoadingButton] = useState(false);

  const [categorySelected, setCategorySelected] =
    useState<IdSelectedProps>(null);

  useEffect(() => {
    async function getCategories() {
      try {
        const { data } = await api.get("/services/categories");

        const formmatedCategories = data.categories.map(
          (category: ChecklistCategories) => ({
            id: category.id,
            name: category.name,
          })
        );

        setCategories(formmatedCategories);
      } catch (error) {
        addToast({
          title: "Erro ao carregar categorias",
          type: "error",
        });
      }
    }

    getCategories();
  }, [addToast]);

  function handleSelectCategory({ id }: DropdownOption) {
    setHasErrorOnCategory("");
    setCategorySelected(id);
  }

  async function handleCreateNewLocalChecklist() {
    setLoadingButton(true);
    try {
      !categorySelected
        ? setHasErrorOnCategory("Selecione uma categoria")
        : setHasErrorOnCategory("");

      const schema = () => {
        return Yup.object().shape({
          name: Yup.string().required("O nome é obrigatório"),
          price: Yup.string().required("O valor é obrigatório"),
        });
      };

      await schema().validate(
        {
          name,
          price,
        },
        { abortEarly: false }
      );

      if (!categorySelected) {
        return;
      }

      const checklistData = {
        category_id: categorySelected,
        nome: name,
        valor: unmaskedPrice,
      };

      await api.post("/checklist", checklistData);
      handleToggleModal();

      setHasError({} as Errors);
    } catch (error) {
      if (error instanceof Yup.ValidationError) {
        const errors = getValidationErrors(error);
        setHasError(errors);

        addToast({
          title: "Ops!!",
          description: "Preencha todos os campos obrigatórios",
          type: "error",
        });

        return;
      }

      addToast({
        title: "Erro ao criar checklist",
        type: "error",
      });
    } finally {
      setLoadingButton(false);
    }
  }

  return (
    <>
      <S.Top>
        <S.ButtonClose onClick={() => handleToggleModal()}>
          <img src={arrowLeft} alt="flecha pra esquerda" />
        </S.ButtonClose>

        <p>Criar novo item adicional</p>
      </S.Top>

      <S.Content>
        <S.Wrapper>
          <Dropdown
            label="Categoria*"
            placeholder="Selecione uma categoria"
            options={categories}
            // eslint-disable-next-line react/jsx-no-bind
            onClickedValue={handleSelectCategory}
            hasError={hasErrorOnCategory}
          />
        </S.Wrapper>

        <S.Wrapper>
          <Input
            name="Nome do item*"
            placeholder="Ex: Andaime"
            value={name}
            onChange={(e) => setName(e.target.value.trimStart())}
            hasError={hasError.name}
            onFocusClearError={() => setHasError({ ...hasError, name: "" })}
          />
        </S.Wrapper>

        <S.Wrapper>
          <Input
            name="Valor*"
            placeholder="Defina o valor"
            value={price}
            maskType="money"
            onChangeCurrency={({ formattedValue, value }) => {
              setPrice(formattedValue);
              setUnmaskedPrice(value);
            }}
            hasError={hasError.price}
            onFocusClearError={() => setHasError({ ...hasError, price: "" })}
          />
        </S.Wrapper>

        <S.Wrapper>
          <Button
            loading={loadingButton}
            onClick={() => handleCreateNewLocalChecklist()}
          >
            Criar novo item
          </Button>
        </S.Wrapper>
      </S.Content>
    </>
  );
}
