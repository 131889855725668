import { shade } from 'polished'
import styled, { css } from 'styled-components'

type QuantityProps = {
  disabled?: boolean;
}

export const Container = styled.div`
  display: flex;
`

export const ButtonAction = styled.button`
  ${({ theme, disabled }) => css`
    width: 23px;
    height: 23px;
    background-color: ${disabled ? theme.colors.background_light : theme.colors.primary};
    border-radius: 5px;

    display: flex;
    justify-content: center;
    align-items: center;
    transition: background 0.2s;

    &:hover {
      background: ${!disabled && shade(0.2, theme.colors.primary)};
      cursor: ${disabled ? 'not-allowed' : 'pointer'};
    }
  `}
`

export const WrapperQuantity = styled.div<QuantityProps>`
  ${({ theme, disabled }) => css`
    width: 23px;
    height: 23px;
    background-color: ${disabled ? theme.colors.text_light : 'rgba(211, 255, 154, 0.2)'};
    border-radius: 5px;
    margin: 0 5px;

    display: flex;
    justify-content: center;
    align-items: center;
  `}
`

export const Quantity = styled.span<QuantityProps>`
  ${({ theme, disabled }) => css`
    font-size: 10px;
    color: ${disabled ? theme.colors.background : theme.colors.primary};
    letter-spacing: 0.26px;
  `}
`
