import React from "react";

import * as S from "./styles";

type ModalRightProps = {
  isModalEditOpen: boolean;
  children: React.ReactNode;
  toggleModal: () => void;
};

export function ModalEditRight({
  isModalEditOpen,
  children,
  toggleModal,
}: ModalRightProps) {
  return (
    <>
      {isModalEditOpen && (
        <S.Container aria-hidden={!isModalEditOpen}>
          <S.Overlay onClick={() => toggleModal()} />

          <S.Content>
            <header />

            <section>{children}</section>
          </S.Content>
        </S.Container>
      )}
    </>
  );
}
