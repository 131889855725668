import styled, { css } from "styled-components";

export const Container = styled.div`
  ${({ theme }) => css`
    width: 100%;
    max-width: 1400px;
    min-width: 470px;
    margin: 0 auto;
    padding: 30px;
    position: relative;

    header {
      display: flex;
      align-items: center;
      justify-content: space-between;

      h1 {
        font-size: ${theme.font.sizes.large};
        color: ${theme.colors.title};
        font-weight: ${theme.font.bold};
        margin-left: 25px;
      }

      div {
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
    }
  `}
`;

export const TabContainer = styled.div`
  ${({ theme }) => css`
    margin-top: 33px;

    .react-tabs__tab-list {
      border-bottom: 1px solid rgba(255, 255, 255, 0.12);
      display: flex;
      justify-content: center;
    }

    .react-tabs__tab {
      color: ${theme.colors.text};
      width: 100%;
      text-align: center;
    }

    .react-tabs__tab--selected {
      background: inherit;
      border: 0px;
      color: ${theme.colors.text};
      width: 100%;
      text-align: center;

      font-weight: 500;
      line-height: 1.35;
      white-space: normal;
      letter-spacing: 0.02857em;
      border-bottom: 3px solid ${theme.colors.primary_light};
    }
  `}
`;

export const ContainerSearch = styled.div`
  width: 44%;
  position: absolute;
  top: 30px;
  left: 27%;
`;

export const Content = styled.div`
  ${({ theme }) => css`
    .columm-id {
      width: 130px;
      border-top-left-radius: 10px;
      border-bottom-left-radius: 10px;
    }

    padding-top: 20px;


    color: ${theme.colors.title};
    transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    margin-bottom: 20px;
    margin-top: 20px;

    table {
      width: 100%;
      display: table;
      border-spacing: 0;
      border-collapse: collapse;

      thead {
        display: table-header-group;

        > tr {
          color: inherit;
          display: table-row;
          outline: 0;
          vertical-align: middle;
        }

        th {
          text-align: left;
          display: table-cell;
          padding: 16px;
          font-size: ${theme.font.sizes.xsmall}
          text-align: left;
          font-weight: 400;
          line-height: 1.43;

          letter-spacing: 0.01071em;
          vertical-align: inherit;
        }
      }

      tbody {
        display: table-row-group;
        vertical-align: middle;
        border-color: inherit;

        tr {
          color: inherit;
          display: table-row;
          outline: 0;
          height: 70px;
          vertical-align: middle;
          background-color: ${theme.colors.background_modal};



          td {
            border: 2px solid ${theme.colors.card_container};
            display: table-cell;
            padding: 16px;
            font-size: ${theme.font.sizes.xsmall}
            text-align: left;
            font-weight: 400;
            line-height: 1.43;
            color: ${theme.colors.text};
            letter-spacing: 0.01071em;
            vertical-align: inherit;
            cursor: pointer;
          }

          &:nth-child(even) {
            background-color: ${theme.colors.detail}
          };

          &:hover {
            background-color: rgba(255, 255, 255, 0.04);
          }
        }
      }
    }
  `}
`;

export const ButtonAdd = styled.button`
  ${({ theme }) => css`
    width: 180px;
    height: 46px;
    border-radius: 8px;
    margin-right: 26px;
    background: ${theme.colors.primary};
    text-decoration: none;
    font-size: 12px;

    color: ${theme.colors.background};

    display: flex;
    align-items: center;
    justify-content: center;
    gap: 15px;
  `}
`;

export const ContainerNotProducts = styled.div`
  ${({ theme }) => css`
    text-align: center;
    margin-top: 100px;
    font-family: ${theme.font.family};
    font-size: ${theme.font.sizes.small};
    color: ${theme.colors.text_light_60};
    font-weight: ${theme.font.normal};

    span {
      color: #fff;
      font-weight: ${theme.font.bold};
    }
  `}
`;
