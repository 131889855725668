import React from "react";
import { ServiceOportunityProps } from "dtos/ServiceOportunityDTO";

import * as S from "./styles";

type Props = {
  serviceOpportunity: ServiceOportunityProps;
  onSelect: () => void;
};

export function OpportunityCard({ serviceOpportunity, onSelect }: Props) {
  return (
    <S.OpportunityCard onClick={onSelect}>
      <S.Header>
        <S.Title>
          Solicitação <p>{serviceOpportunity.id}</p>
        </S.Title>

        <S.ServiceOrderStatus>
          <S.StatusBadge />
          <span>
            {serviceOpportunity.status === "pending"
              ? "Nova"
              : serviceOpportunity.status === "approved" && "Aprovada"}
          </span>
        </S.ServiceOrderStatus>
      </S.Header>

      <S.Content>
        <S.InfoWrapper>
          <p>Cliente:</p>
          <span>{serviceOpportunity.name}</span>
        </S.InfoWrapper>

        <S.InfoWrapper>
          <p>Serviço:</p>
          <span>{serviceOpportunity.serviceType}</span>
        </S.InfoWrapper>
      </S.Content>
    </S.OpportunityCard>
  );
}
