import { ArrowButton } from "components/ArrowButton";
import { SearchInput } from "components/SearchInput";
import React, { useEffect, useState } from "react";

import * as S from "./styles";

import checkIcon from "assets/icons/service-checklists-icons/checkIcon.svg";
import measureIcon from "assets/icons/service-checklists-icons/measureIcon.svg";
import photographIcon from "assets/icons/service-checklists-icons/photographIcon.svg";
import runIcon from "assets/icons/service-checklists-icons/runIcon.svg";
import { useToast } from "hooks/toast";
import { RegisterItem, TInfo } from "../types";
import { useServiceChecklist } from "hooks/serviceChecklist";
import OptionsFilterComponent from "components/OptionsFilterComponent";
import { Button } from "components/Button";
import { DeleteButton } from "components/DeleteButton";
import { EditButton } from "components/EditButton";
import { CardChecklist } from "components/CardChecklist";
import { api } from "services/api";
import axios from "axios";
import { RegisterProps } from "hooks/serviceChecklist";
import { NewAlertModal } from "components/NewAlertModal";
import { LoadingProfiz } from "components/LoadingProfiz";
import { TablePagination } from "components/TablePagination";
import { EnvironmentPageProps, Page } from "pages/pmoc/EnvironmentFlow/types";
import { useEnvironment } from "hooks/pmoc/environmentPmoc";
import { useAccount } from "hooks/permission/account";

//import debounce from "lodash.debounce";

type RegisterPageProps = {
  isRegisterFirstTime?: boolean;
  handlePageClose?: () => void;
  handleFunction?: () => void;
  handleChooseEnvironment?: EnvironmentPageProps;
};

type Option = {
  [key: string]: string;
};

const typeFilterProps: Option = {
  executar: "Executar",
  fotografar: "Fotografar",
  medir: "Medir",
  verificar: "Verificar",
};

type RegisterResponse = {
  checklistSteps: RegisterProps[];
  info: TInfo;
};

const REGISTER_LIMIT = 40;

export function RegisterPage({
  handlePageClose,
  handleFunction,
  handleChooseEnvironment,
  isRegisterFirstTime = false,
}: RegisterPageProps) {
  const {
    isNewChecklist,
    detailChecklist,
    newItemChecklist,
    checklistRegisters,
    handleSetDetailRegister,
    handleNewItemChecklist,
    handleSetChecklistDetail,
    handleSetIsChecklistPage,
    handleSetChecklistRegisters,
    detailRegister,
  } = useServiceChecklist();

  const { pushStackNavigation, popAndDeleteStackNavigation } = useEnvironment();

  const [isLoading, setIsLoading] = useState(false);
  const [searchInput, setSearchInput] = useState("");
  const { addToast } = useToast();
  const [typeFilter, setTypeFilter] = useState<string[]>([]);
  const [isOpenAlertModalDelete, setIsOpenAlertModalDelete] = useState(false);

  const [infoRegister, setInfoRegister] = useState<RegisterItem>(
    {} as RegisterItem
  );
  const [selectedRegisters, setSelectedRegisters] = useState<RegisterItem[]>(
    []
  );
  const [registersBackup, setRegistersBackup] = useState<RegisterProps[]>([]);

  const [currentPage, setCurrentPage] = useState(1);
  const [offset, setOffset] = useState(0);
  const [totalItems, setTotalItems] = useState(0);
  const defaultLimit = 20;
  const { whoami } = useAccount();

  const loadInfo = async () => {
    try {
      setIsLoading(true);
      const { data } = await api.get<RegisterResponse>(
        "/service/checklist/step",
        {
          params: {
            offset: offset,
            limit: defaultLimit,
            accountId: whoami?.id,
          },
        }
      );

      const { checklistSteps, info } = data;

      setRegistersBackup(checklistSteps);
      setTotalItems(info?.total || 0);

      if (
        isNewChecklist &&
        newItemChecklist &&
        newItemChecklist.registers &&
        newItemChecklist.registers.length > 0
      ) {
       const filterRegisters = checklistSteps.filter((checklistStep) => {
          const hasRegisterAlreadySaved = newItemChecklist.registers.some(
            (register: RegisterItem) => Number(register.id) === Number(checklistStep.id)
          )

          return !hasRegisterAlreadySaved
        });

        handleSetChecklistRegisters(filterRegisters);
        return;
      }

      if (
        detailChecklist &&
        detailChecklist.registers &&
        detailChecklist.registers.length > 0 &&
        !isRegisterFirstTime
      ) {
       const filterRegisters = checklistSteps.filter((checklistStep) => {
          const hasRegisterAlreadySaved = detailChecklist.registers.some(
            (register: RegisterItem) => Number(register.id) === Number(checklistStep.id)
          )

          return !hasRegisterAlreadySaved
        });

        handleSetChecklistRegisters(filterRegisters);
        return;
      }
      handleSetChecklistRegisters(checklistSteps);
    } catch (error) {
      addToast({
        title: "Ops!!",
        description:
          axios.isAxiosError(error) && error.response?.data.error
            ? error.response.data.error
            : "Ocorreu um erro ao buscar a listagem de registros.",
        type: "error",
      });
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    loadInfo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [offset, detailRegister]);

  const setOptionType = (newfilter: string) => {
    let arrFilter = typeFilter.filter((element) => element === newfilter);
    if (arrFilter.length === 0) {
      setTypeFilter([...typeFilter, newfilter]);

      let registersList = JSON.parse(JSON.stringify(registersBackup));

      let aux = registersList.filter((element: RegisterItem) => {
        return [...typeFilter, newfilter].find((e) => {
          return e === element.type;
        });
      });

      handleSetChecklistRegisters(aux);
    } else {
      let fType = typeFilter.filter((element) => element !== newfilter);
      setTypeFilter(fType);
      if (fType.length === 0)
        return handleSetChecklistRegisters(registersBackup);

      let registersList = JSON.parse(JSON.stringify(registersBackup));
      let aux = registersList.filter((element: RegisterItem) => {
        return fType.find((e) => {
          return e === element.type;
        });
      });

      handleSetChecklistRegisters(aux);
    }
  };

  function typeIcon(typeI: string) {
    const opt: Option = {
      fotografar: photographIcon,
      medir: measureIcon,
      verificar: checkIcon,
      executar: runIcon,
    };
    return opt[typeI];
  }

  function handleSearchService(text: string) {
    setSearchInput(text);

    if (text.length < 1) {
      handleSetChecklistRegisters(registersBackup);
      return;
    }

    const filteredRegisters = checklistRegisters.filter(
      (element: RegisterItem) => {
        const elementLowerCase = element.name.toLowerCase();
        const searchValue = text.toLowerCase();
        return elementLowerCase.includes(searchValue);
      }
    );

    handleSetChecklistRegisters(filteredRegisters);
  }

  function handleSearchCancel() {
    setSearchInput("");
    handleSetChecklistRegisters(registersBackup);
  }

  function handleSetPage(idx?: number, register?: RegisterItem) {
    if (register) {
      handleSetDetailRegister({ indexItem: idx, registerDetail: register });
    }
    handleSetIsChecklistPage(false);

    if (handleChooseEnvironment) {
      pushStackNavigation("registerPage");
      handleChooseEnvironment("detailRegisterPage");
      return;
    }

    handleFunction && handleFunction();
  }

  const handleDeleteItem = async () => {
    try {
      setIsLoading(true);
      await api.delete(`/service/checklist/step/${infoRegister.id}`);
      setIsOpenAlertModalDelete(false);
      addToast({
        title: "Ok!!",
        description: "Registro excluído com sucesso.",
        type: "success",
      });
      loadInfo();
    } catch (error) {
      addToast({
        title: "Ops!!",
        description:
          axios.isAxiosError(error) && error.response?.data.error
            ? error.response.data.error
            : "Não foi possível excluir o registro.",
        type: "error",
      });
    } finally {
      setIsLoading(false);
    }
  };

  function ValidateSelectedItemChecklist(id: number) {
    return selectedRegisters.some(
      (register) => Number(register.id) === Number(id)
    )
  }

  function filterSelected(register: RegisterItem) {
    if (selectedRegisters.length < 1) {
      return setSelectedRegisters([register]);
    }

    if (selectedRegisters.length >= REGISTER_LIMIT) {
      const filterArray = selectedRegisters.filter((element) => {
        return Number(element.id) !== Number(register.id);
      });
      setSelectedRegisters(filterArray);
      if (filterArray.length >= REGISTER_LIMIT) {
        addToast({
          title: "Máximo permitido",
          description: "Ops, só é permitido criar 40 registros.",
          type: "info",
        });
      }
      return;
    }

    const filterSelected = selectedRegisters.find((element) => {
      return Number(element.id) === Number(register.id);
    })
      ? selectedRegisters.filter((element) => {
          return Number(element.id) !== Number(register.id);
        })
      : ([...selectedRegisters, register] as RegisterProps[]);
    setSelectedRegisters(filterSelected);
  }

  function SaveRegisters() {
    try {
      setIsLoading(true);
      let aux = isNewChecklist ? newItemChecklist : detailChecklist;
      if (handlePageClose || handleChooseEnvironment) {
        if (
          (!aux.registers || aux.registers.length < 1) &&
          selectedRegisters.length < 1
        ) {
          addToast({
            title: "Ops!!",
            description: "Todo checklist deve ter ao menos um registro.",
            type: "error",
          });
          return;
        }

        const filterRegisters =
          aux.registers && aux.registers.length > 0
            ? aux.registers.filter((element: RegisterItem) => {
                return element.id;
              })
            : [];

        aux = {
          ...aux,
          registers:
            aux.registers && aux.registers.length > 0
              ? [...filterRegisters, ...selectedRegisters]
              : selectedRegisters,
        };

        isNewChecklist
          ? handleNewItemChecklist(aux)
          : handleSetChecklistDetail(aux);

        addToast({
          title: "Ok!!",
          description:
            "Os registros foram vinculados com sucesso ao checklist.",
          type: "success",
        });

        if (handleChooseEnvironment) {
          let page = popAndDeleteStackNavigation();
          handleChooseEnvironment(page as Page);
          return;
        }

        handlePageClose && handlePageClose();
      }
    } catch (error) {
      addToast({
        title: "Ops!!",
        description: "Ocorreu um erro ao vincular os registros ao checklist.",
        type: "error",
      });
    } finally {
      setIsLoading(false);
    }
  }

  function handlePageChange(pageNumber: number, itemsPerPage: number) {
    setCurrentPage(pageNumber);
    setOffset((pageNumber - 1) * itemsPerPage);
  }

  return (
    <>
      {isLoading ? (
        <LoadingProfiz isVisible={isLoading} />
      ) : (
        <S.Container>
          <NewAlertModal
            isVisible={isOpenAlertModalDelete}
            title="Tem certeza que deseja excluir este registro?"
            description="Esta ação não poderá ser desfeita."
            action="choose"
            handleConfirm={handleDeleteItem}
            onCloseModal={() => setIsOpenAlertModalDelete(false)}
            labelConfirm="Excluir registro"
            labelCancel="Cancelar"
            buttonConfirmColor="#FA8B94"
          />

          <header>
            <div>
              <ArrowButton
                handleFunction={() => {
                  if (handleChooseEnvironment) {
                    let page = popAndDeleteStackNavigation();
                    handleChooseEnvironment(page as Page);
                    return;
                  }

                  handlePageClose && handlePageClose();
                }}
              />
              <h1>Meus Registros Customizados</h1>
            </div>
          </header>
          {/*Input do nome do registro*/}
          <S.Content>
            <div>
              <SearchInput
                searchValue={searchInput}
                onChange={(e) => handleSearchService(e.target.value)}
                placeholder="Busque por um registro customizado"
                handleCancel={handleSearchCancel}
                disabled={checklistRegisters.length < 1 ? true : false}
              />
            </div>

            <S.WrapperFilter>
              <OptionsFilterComponent
                data={typeFilterProps}
                optionsSelected={typeFilter}
                handlePressButton={setOptionType}
                isDisabled={registersBackup.length < 1 ? true : false}
              />
            </S.WrapperFilter>

            <S.WrapperNewRegisterButton>
              <Button
                typeButton="outline"
                onClick={() => {
                  handleSetPage();
                }}
              >
                Criar novo registro
              </Button>
            </S.WrapperNewRegisterButton>

            <S.SubText>
              Vincule até {REGISTER_LIMIT} registros para vincular a um
              checklist.
            </S.SubText>

            {checklistRegisters.length < 1 && (
              <S.WrapperEmptyMessage>
                <p>Nenhum registro criado</p>
                <p>clique em “Criar novo registro”</p>
                <p>para começar.</p>
              </S.WrapperEmptyMessage>
            )}

            {checklistRegisters.map(
              (currentRegister: RegisterItem, index: number) => {
                return (
                  <S.WrapperService key={index}>
                    <CardChecklist
                      isChecked={ValidateSelectedItemChecklist(
                        Number(currentRegister.id)
                      )}
                      isCheckbox
                      isCheckedButtonClick
                      handlePressButton={() => {
                        filterSelected(currentRegister);
                      }}
                    >
                      <S.WrapperInfoCard>
                        <S.ContainerImg>
                          <img
                            src={typeIcon(currentRegister.type)}
                            alt="imagem"
                          />
                          <S.WrapperData>
                            <h6>{currentRegister.name}</h6>
                            <p>{currentRegister.description}</p>
                          </S.WrapperData>
                        </S.ContainerImg>
                        <S.WrapperButton>
                          <EditButton
                            onClick={() => {
                              handleSetPage(index, currentRegister);
                            }}
                          />
                          <DeleteButton
                            onClick={() => {
                              setInfoRegister(currentRegister);
                              setIsOpenAlertModalDelete(true);
                            }}
                          />
                        </S.WrapperButton>
                      </S.WrapperInfoCard>
                    </CardChecklist>
                  </S.WrapperService>
                );
              }
            )}

            <S.ContainerPagination>
              <TablePagination
                rowsLength={totalItems}
                rowsPerPage={defaultLimit}
                rowsPage={currentPage}
                handlePageChange={(pageNumber, itemsPerPage) =>
                  handlePageChange(pageNumber, itemsPerPage)
                }
              />
            </S.ContainerPagination>

            <Button
              //typeButton={selectedRegisters.length>0?"default":"disabled"}
              //disabled={selectedRegisters.length<1}
              onClick={SaveRegisters}
            >
              Salvar
            </Button>
          </S.Content>
        </S.Container>
      )}
    </>
  );
}
