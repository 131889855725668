import { useEnvironment } from "hooks/pmoc/environmentPmoc";
import { useToast } from "hooks/toast";
import React, { ChangeEvent, useCallback, useRef } from "react";
import { useTheme } from "styled-components";
import { Photo } from "../types";
import { FiPlus } from "react-icons/fi";
import * as S from "./styles";
import { api } from "services/api";
import axios from "axios";

type FileProps = {
  base64Image: string;
  extension: string;
};

type PhotoProps = {
  photosQuantity: number;
  handleAddNewPhoto: (signaturePhotos: Photo) => void;
  maxPhoto: number;
  invoiceImg?: boolean;
};

export function AddPhoto({
  photosQuantity,
  handleAddNewPhoto,
  maxPhoto,
  invoiceImg,
}: PhotoProps) {
  const theme = useTheme();
  const { addToast } = useToast();
  const { userIdPMOC, unityIdPMOC, equipment, environment } = useEnvironment();

  const hiddenFileInput = useRef<HTMLInputElement | null>(null);

  const handleAddImage = () => {
    if (photosQuantity >= maxPhoto) {
      addToast({
        title: "Máximo permitido",
        description: `Ops, o máximo de fotos permitidos é ${maxPhoto}`,
        type: "error",
      });
      return;
    }
    //return setIsVisibleModal(true);
    hiddenFileInput.current?.click();
  };

  const handleAddImgPhoto = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      if (photosQuantity >= maxPhoto) {
        addToast({
          title: "Máximo permitido",
          description: `Ops, o máximo de fotos permitidos é ${maxPhoto}`,
          type: "error",
        });
        return;
      }

      if (e.target.files) {
        const file = e.target.files[0];
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const [_, extension] = file.type.split("/");

        const reader = new FileReader();

        reader.readAsDataURL(file);

        reader.onload = async () => {
          const result = reader.result as string;

          const { 1: fileBase64Splited } = result.split(",");

          await handleSubmitPhoto({
            base64Image: fileBase64Splited,
            extension,
          });
        };
      }
    },
    [photosQuantity, addToast] // eslint-disable-line react-hooks/exhaustive-deps
  );

  async function handleSubmitPhoto({ base64Image, extension }: FileProps) {
    try {
      const response = invoiceImg
        ? await api.post(
            `/client/${userIdPMOC}/unities/${unityIdPMOC}/environment/${environment.id}/equipment/${equipment.id}/invoice-photo`,
            {
              encode: base64Image,
              //extensao: 'jpg',
              extensao: extension,
            }
          )
        : await api.post(
            `/client/${userIdPMOC}/unities/${unityIdPMOC}/environment/${environment.id}/equipment/${equipment.id}/tag-photo`,
            {
              encode: base64Image,
              //extensao: 'jpg',
              extensao: extension,
            }
          );
      const { link, id } = response.data;
      const photo = {
        id,
        link,
      };
      handleAddNewPhoto(photo);

      addToast({
        title: "Sucesso",
        description: "Foto adicionada com sucesso",
        type: "success",
      });
    } catch (err) {
      addToast({
        title: "Erro",
        description:
          axios.isAxiosError(err) && err.response?.data.error
            ? err.response.data.error
            : "Falha ao adicionar a foto, tente mais tarde.",
        type: "error",
      });
    }
  }

  return (
    <S.Container>
      <button onClick={handleAddImage}>
        <FiPlus size="18" color={theme.colors.primary} />
      </button>

      <input
        type="file"
        ref={hiddenFileInput}
        accept="image/*"
        onChange={handleAddImgPhoto}
      />
    </S.Container>
  );
}
