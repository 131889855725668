import { useState, useEffect } from 'react';

import { AppVersionsDTO } from 'dtos/version/AppVersionsDTO';

import { api } from 'services/api';
import { useToast } from 'hooks/toast';

export const useFetchAppVersion = () => {
  const { addToast } = useToast();

  const [appVersionDetail, setAppVersionDetail] = useState({} as AppVersionsDTO);
  const [loadingAppVersionDetail, setLoadingAppVersionDetail] = useState(true);

  useEffect(() => {
    async function getAppVersion() {
      try {
        setLoadingAppVersionDetail(true);
        const { data } = await api.get<AppVersionsDTO>('mobile-app-version/mobile');
        setAppVersionDetail(data);
      } catch (err) {
        addToast({
          title: 'Ops!!',
          description: 'Não foi possível consultar a versão do app',
          type: 'error',
        });
      } finally {
        setLoadingAppVersionDetail(false);
      }
    }

    getAppVersion();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { loadingAppVersionDetail, appVersionDetail };
};
