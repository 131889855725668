import styled, { css } from "styled-components";

export const Container = styled.div`
  ${({ theme }) => css`
    width: 100%;
    max-width: 1400px;
    min-width: 470px;
    margin: 0 auto;
    padding: 10px 0 10px 20px;

    header {
      display: flex;
      align-items: center;

      h1 {
        font-size: ${theme.font.sizes.large};
        color: ${theme.colors.title};
        font-weight: ${theme.font.bold};
        margin-left: 25px;
      }

      div {
        width: 80px;
        display: flex;
        justify-content: space-between;
      }
    }
  `}
`;

export const Content = styled.section`
  width: 100%;
  margin-top: 34px;
`;

export const Label = styled.p`
  ${({ theme }) => css`
    color: ${theme.colors.text};
    font-size: ${theme.font.sizes.small};
    line-height: 20px;
    margin-bottom: 10px;
  `}
`;

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  margin-left: 14px;
`;

export const Description = styled.p`
  ${({ theme }) => css`
    font-size: ${theme.font.sizes.xsmall};
    color: ${theme.colors.text_light};
  `}
`;

export const WrapperReportDetail = styled.div`
  ${({ theme }) => css`
    margin-top: 34px;
  `};
`;

export const Footer = styled.div`
  margin-top: 40px;
`;
