import { LocalChecklistProps } from 'dtos/LocalChecklistDTO'

export function mappedLocalChecklistForApi(
  localChecklists: LocalChecklistProps[]
) {
  return localChecklists.map((checklist) => ({
    checklist: {
      id: checklist.id,
      category_id: checklist.categoryID,
      name: checklist.name,
      value: checklist.value,
      status: checklist.status
    },
    type: checklist.type,
    quantity: checklist.quantity
  }))
}
