import React, { useEffect } from "react";

import { useQuery } from "hooks/useQuery";

import logo from "assets/logo.svg";

import * as S from "./styles";

export function WaitingForDownloadPdfMobile() {
  const query = useQuery();

  useEffect(() => {
    const pdfLink = query.get("pdfLink");

    window.location.assign(`${pdfLink}&download=true`);
  }, [query]);

  return (
    <S.Container>
      <img src={logo} alt="Logo Profiz" />

      <h1>O download do seu PDF vai começar automaticamente.</h1>
      <p>Em instantes você poderá visualizar o arquivo baixado.</p>
    </S.Container>
  );
}
