import React, { useEffect, useState } from "react";

import {
  PmocDetailOfMonthDTO,
  EnvironmentProps,
  EquipmentProps,
  ServiceOrderProps,
} from "dtos/PmocDetailOfMonthDTO";

import { useResumePmoc } from "hooks/resumePmoc";
import { useToast } from "hooks/toast";
import { api } from "services/api";

import * as S from "./styles";
import { LoadingProfiz } from "components/LoadingProfiz";

export function UnscheduledServiceOrders() {
  const { addToast } = useToast();

  const {
    pmoc,
    monthSelected,
    handleSetIdServiceOrderSelected,
    handleChangeModalSelected,
  } = useResumePmoc();

  const [isLoading, setIsLoading] = useState(true);
  const [environments, setEnvironments] = useState<EnvironmentProps[]>([]);

  useEffect(() => {
    async function getServiceOrders() {
      try {
        const { data } = await api.get<PmocDetailOfMonthDTO>(
          `/pmoc/${pmoc.id}/months/${monthSelected.id}`,
          {
            params: {
              status: "new",
              archive: false,
            },
          }
        );

        setEnvironments(data.environments);
      } catch (error) {
        addToast({
          title: "Ops!!",
          description:
            "Não foi possível carregar as ordens de serviço não agendadas.",
          type: "info",
        });
      } finally {
        setIsLoading(false);
      }
    }
    getServiceOrders();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function handleSelectedServiceOrder(id: number) {
    handleSetIdServiceOrderSelected(id);
    handleChangeModalSelected("serviceOrderDetail");
  }

  return (
    <>
      {isLoading ? (
        <LoadingProfiz isVisible={isLoading} />
      ) : environments.length !== 0 ? (
        <>
          {environments.map((environment) => {
            return (
              <S.Container key={environment.id}>
                <S.TextTop>{environment.name}</S.TextTop>

                <S.EquipmentsWrapper>
                  {environment.equipments.map((equipment: EquipmentProps) => {
                    return (
                      <S.Container key={equipment.id}>
                        <S.Title>{equipment.tagEquipment}</S.Title>

                        {equipment.serviceOrders.map(
                          (serviceOrder: ServiceOrderProps) => {
                            return (
                              <S.CardServiceOrder
                                key={serviceOrder.id}
                                onClick={() =>
                                  handleSelectedServiceOrder(serviceOrder.id)
                                }
                              >
                                <S.CardServiceOrderContent>
                                  <S.CardServiceOrderHeader>
                                    <S.CardServiceOrderTitle>
                                      {serviceOrder.service.name}
                                    </S.CardServiceOrderTitle>
                                    <S.pmocPeriodType>
                                      ({serviceOrder.service.frequencyService})
                                    </S.pmocPeriodType>
                                  </S.CardServiceOrderHeader>

                                  <S.ServiceOrderStatus>
                                    <S.StatusBadge />

                                    <span>Não agendado</span>
                                  </S.ServiceOrderStatus>
                                </S.CardServiceOrderContent>
                              </S.CardServiceOrder>
                            );
                          }
                        )}
                      </S.Container>
                    );
                  })}
                </S.EquipmentsWrapper>
              </S.Container>
            );
          })}
        </>
      ) : (
        <S.EmptyList>
          <h1>Você não possui ordens de serviço não agendadas</h1>
        </S.EmptyList>
      )}
    </>
  );
}
