import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  buildStyles,
  CircularProgressbarWithChildren,
} from "react-circular-progressbar";

import { MonthProps, ResumePmocDTO } from "dtos/ResumePmocDTO";

import { LoadingProfiz } from "components/LoadingProfiz";
import { ArrowButton } from "components/ArrowButton";
import { ModalRight } from "components/ModalRight";
import { ModalResumePmoc } from "components/ModalResumePmoc";

import PmocHelmetIcon from "assets/icons/pmoc-icons/pmoc-helmet-icon.svg";

import addMaskDate from "utils/addMaskDate";
import addMaskDocument from "utils/addMaskDocument";
import { addMaskPhone } from "utils/addMaskPhone";

import { api } from "services/api";

import { useToast } from "hooks/toast";
import { useResumePmoc } from "hooks/resumePmoc";
import { usePmocTabIndex } from "hooks/pmoc/tabIndex";

import * as S from "./styles";

type RouteParams = {
  id: string;
};

export function ResumePmoc() {
  const { addToast } = useToast();
  const { id } = useParams<RouteParams>();

  const { handleSetSelectedIndex } = usePmocTabIndex();
  const {
    pmoc,
    isOpenModalResumePmoc,
    handleSetPmoc,
    handleSetMonthSelected,
    handleChangeModalSelected,
    handleToggleModalResumePmoc,
  } = useResumePmoc();

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    async function getPmocById() {
      try {
        const { data } = await api.get<ResumePmocDTO>(`pmoc/${id}`);
        handleSetPmoc(data);
      } catch (error) {
        addToast({
          title: "Erro ao carregar PMOC.",
          type: "error",
        });
      } finally {
        setIsLoading(false);
      }
    }

    getPmocById();
  }, [isOpenModalResumePmoc]); // eslint-disable-line

  function handleOpenMonthDetailModal(month: MonthProps) {
    handleSetMonthSelected(month);
    handleChangeModalSelected("detailOfMonthPmoc");
    handleSetSelectedIndex(0);
    handleToggleModalResumePmoc();
  }

  return (
    <S.Container>
      {isLoading ? (
        <LoadingProfiz isVisible={isLoading} />
      ) : (
        <>
          <ModalRight
            isOpen={isOpenModalResumePmoc}
            handleToggleOpen={handleToggleModalResumePmoc}
          >
            <ModalResumePmoc />
          </ModalRight>

          <S.Header>
            <div>
              <ArrowButton />
            </div>
          </S.Header>

          <S.Content>
            <S.TitleDate>{pmoc!.name}</S.TitleDate>

            <S.Subtitle>
              {`Início: ${addMaskDate(pmoc!.startDate)}  |  Período: ${
                pmoc!.contractPeriod.period
              } ${pmoc!.contractPeriod.type}`}
            </S.Subtitle>

            <S.PmocInfo>
              <S.PmocInfoText>{pmoc!.client.name}</S.PmocInfoText>

              <S.PmocInfoAddressWrapper>
                <S.PmocInfoText>
                  {pmoc!.unity.name}

                  {pmoc!.unity.default ? " (principal)" : " (secundária)"}
                </S.PmocInfoText>
                <S.PmocInfoAddress>
                  {!!pmoc!.unity?.number
                    ? `${pmoc!.unity?.street}, ${pmoc!.unity?.number}`
                    : pmoc!.unity?.street}
                </S.PmocInfoAddress>
                <S.PmocInfoAddress>
                  {!!pmoc!.unity?.city && !!pmoc!.unity?.uf
                    ? `${pmoc!.unity?.city} - ${pmoc!.unity?.uf}`
                    : pmoc!.unity?.city}
                </S.PmocInfoAddress>
              </S.PmocInfoAddressWrapper>
              <S.UserInfoWrapper>
                <img src={PmocHelmetIcon} alt="criador da pmoc" />
                <S.UserInfoTextWrapper>
                  <S.PmocInfoText>{pmoc!.technicalManager.name}</S.PmocInfoText>
                  <S.Subtitle>{`${addMaskDocument(
                    pmoc!.technicalManager.cpfCnpj
                  )} | ${addMaskPhone(
                    pmoc!.technicalManager.phone
                  )}`}</S.Subtitle>
                </S.UserInfoTextWrapper>
              </S.UserInfoWrapper>
            </S.PmocInfo>

            <S.ServiceOrderTitle>Ordens de serviço</S.ServiceOrderTitle>
            <S.CardMonthWrapper>
              {pmoc?.months.map((month) => {
                return (
                  <S.CardMonth
                    key={month.id}
                    onClick={() => handleOpenMonthDetailModal(month)}
                  >
                    <div>
                      <CircularProgressbarWithChildren
                        value={month.percentageComplete}
                        styles={buildStyles({
                          textColor: "rgba(255, 255, 255, 0.87)",
                          pathColor: "#D3FF9A",
                          trailColor: "#2F2F2F",
                        })}
                      >
                        <S.MonthPercentage>
                          {Math.round(month.percentageComplete)}%
                        </S.MonthPercentage>
                      </CircularProgressbarWithChildren>
                    </div>

                    <S.MonthText>{month.name}</S.MonthText>
                  </S.CardMonth>
                );
              })}
            </S.CardMonthWrapper>
          </S.Content>
        </>
      )}
    </S.Container>
  );
}
