import { AccountDTO } from "dtos/permission/AccountDTO";
export const permissionValidate = (whoami: AccountDTO, hash: string) => {
  if (!hash) {
    return false;
  }
  if (whoami?.isMe) {
    return true;
  }
  const currentModule = whoami?.profile.modules?.find(
    (module) => module.hash === hash
  );
  if (currentModule) {
    const currentActionPermission = currentModule.permissions.find(
      (permission) => permission.hash === "create"
    );
    return !!currentActionPermission?.active;
  }
  return false;
};
