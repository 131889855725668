import { useState } from "react";

import { AlertModal } from "components/AlertModal";
import { EditButton } from "components/EditButton";
import { DeleteButton } from "components/DeleteButton";

import { ServiceForListProps } from "dtos/ServiceDTO";

import * as S from "./styles";
import { ShowWhenHavePermission } from "components/Permission";

type Props = {
  data: ServiceForListProps;
  openModalEditService: () => void;
  onDeleteService: (idService: number) => void;
};

export function ServiceContent({
  data,
  openModalEditService,
  onDeleteService,
}: Props) {
  const [isVisibleModal, setIsVisibleModal] = useState(false);

  const {
    service,
    equipment,
    equipmentType,
    warranty,
    runtime,
    brand,
    capacity,
    formattedTotal,
    serviceChecklist,
  } = data;
  const equipmentDetailText = `${equipment.name} ${equipmentType?.name} ${capacity?.name}`;

  function handleDeleteService() {
    onDeleteService(data.id);
    setIsVisibleModal(false);
  }

  return (
    <S.Container>
      <AlertModal
        isVisible={isVisibleModal}
        title="Deseja apagar?"
        description="Deseja realmente apagar esse serviço?"
        action="choose"
        handleConfirm={() => handleDeleteService()}
        onCloseModal={() => setIsVisibleModal(false)}
      />

      <S.Content>
        <div>
          <S.Title>{service.name}</S.Title>
          <S.PriceText>{formattedTotal}</S.PriceText>
        </div>
        {equipment.name ? (
          <S.Description>{equipmentDetailText}</S.Description>
        ) : (
          <S.Description>Sem equipamento cadastrado.</S.Description>
        )}
        <S.Description>
          {!!brand && `Marca: ${brand}`} {"\n"}
          {!!runtime?.time &&
            `Execução: ${runtime.time} ${runtime.extension}`}{" "}
          {"\n"}
          {!!warranty?.time &&
            `| Garantia: ${warranty.time} ${warranty.extension}`}
        </S.Description>

        {serviceChecklist && serviceChecklist.length > 0 && (
          <S.Checklist>
            <p>{serviceChecklist.length} checklist(s) vinculado(s)</p>
          </S.Checklist>
        )}
      </S.Content>
      <S.WrapperContent>
        <ShowWhenHavePermission moduleHash={"service"} actionHash="edit">
          <S.WrapperActions>
            <EditButton onClick={openModalEditService} />
          </S.WrapperActions>
        </ShowWhenHavePermission>

        <ShowWhenHavePermission moduleHash={"service"} actionHash="delete">
          <S.WrapperActions>
            <DeleteButton onClick={() => setIsVisibleModal(true)} />
          </S.WrapperActions>
        </ShowWhenHavePermission>
      </S.WrapperContent>
    </S.Container>
  );
}
