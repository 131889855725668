import styled, { css } from "styled-components";

export const Title = styled.p`
  ${({ theme }) => css`
    font-family: ${theme.font.family};
    font-size: ${theme.font.sizes.xsmall};
    font-weight: ${theme.font.bold};
    line-height: 20px;
    color: ${theme.colors.text_light_87};
    margin-top: 16px;
    margin-bottom: 10px;
  `}
`;
