import styled, { css } from "styled-components";
import media from "styled-media-query";

export const Container = styled.div`
  ${({ theme }) => css`
    width: 100%;
    max-width: 1400px;
    margin: 0 auto;
  `}
`;
export const Header = styled.header`
  ${({ theme }) => css`
    width: 100%;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    padding: 42px 0 30px 0;
    align-items: center;
    justify-content: space-between;
    gap: 10px;

    .btn {
      min-width: 350px;
    }

    ${media.lessThan("large")`
      grid-template-columns: repeat(3, 1fr);
      padding: 42px 20px 30px 20px;

    `}

    ${media.lessThan("medium")`
      grid-template-columns: repeat(2, 1fr);
      .btn{
        min-width:100px;
      }
    `}


    @media (max-width: 436px) {
      padding: 26px 20px 25px 20px;

      grid-template-columns: repeat(1, 1fr);
      place-items: start;
    }

    img {
      width: 35px;
      height: 35px;
    }

    span {
      p {
        font-size: 12px;
        color: ${theme.colors.text_light_60};
      }
    }

    h2 {
      font-size: 18px;
      color: ${theme.colors.text_light_87};
      margin-bottom: 5px;
    }

    h3 {
      font-size: 12px;
      color: ${theme.colors.text_light_87};
      margin-bottom: 5px;
    }

    > div {
      display: flex;
      align-items: center;
      gap: 25px;

      .pmoc-info {
        display: flex;
        flex-direction: column;
      }
    }

    .pmoc-info-address {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 0;
    }
  `}
`;

export const Table = styled.div`
  ${({ theme }) => css`
    overflow: auto;
    margin-top: 30px;
    max-height: 60vh;
    min-width: 100%;

    ${media.lessThan("medium")`
      max-height: 50vh;
    `}

    @media (max-width: 460px) {
      margin-top: 25px;
      max-height: 45vh;
    }

    @media (max-height: 780px) {
      max-height: 40vh;
    }

    @media (max-height: 680px) {
      max-height: 33vh;
    }

    @media (max-height: 640px) {
      max-height: 33vh;
    }

    table {
      width: 100%;
      color: white;
      border-collapse: collapse;

      thead {
        color: ${theme.colors.text_light_87};

        background-color: ${theme.colors.menu_detail};
        tr {
          th {
            font-size: 12px;

            padding: 0 10px;

            text-align: start;
            padding: 28px 20px;

            ${media.lessThan("medium")`
              padding: 18px 20px;
            `}
          }
        }
      }

      tbody {
        color: ${theme.colors.text_light_60};

        tr:nth-child(odd) {
          background-color: ${theme.colors.menu_detail_light};
        }

        tr {
          background-color: ${theme.colors.menu_detail};

          td:nth-child(1) {
            min-width: 100px;
          }

          td:nth-child(3) {
            min-width: 400px;
          }

          td:nth-child(4) {
            min-width: 250px;
            text-decoration: underline;
          }

          td:nth-child(5) {
            min-width: 250px;
            display: flex;
            align-items: center;
            color: ${theme.colors.text_light_87};
          }

          td:nth-child(6) {
            min-width: 250px;

            span {
              color: ${theme.colors.schedule};
              text-decoration: underline;
              cursor: pointer;
            }
          }

          td {
            padding: 28px 20px;
            font-size: 12px;

            ${media.lessThan("medium")`
              padding: 18px 20px;
            `}
          }
        }
      }
    }
  `}
`;

export const Pagination = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 20px;
`;

interface DotsProps {
  status: "concluded" | "new" | "scheduled";
}

const dotsTypeVariations = {
  concluded: css`
    background: #d3ff9a;
  `,
  scheduled: css`
    background: #d3ff9a;
  `,
  new: css`
    background: #fff595;
  `,
};

export const Dots = styled.div<DotsProps>`
  width: 6px;
  height: 6px;
  margin-right: 6px;
  border-radius: 30px;
  background-color: red;

  ${(props) => dotsTypeVariations[props.status || "new"]}
`;

export const ContainerModal = styled.div`
  ${({ theme }) => css`
    h1 {
      margin: 30px 0 20px 0;
      color: ${theme.colors.text_light_87};
      font-size: 18px;
    }
  `}

  width: 100%;
  height: 100%;
`;

export const ButtonClose = styled.div`
  ${({ theme }) => css`
    width: 35px;
    height: 35px;
    background-color: ${theme.colors.menu_detail};
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    cursor: pointer;
  `}
`;

export const Form = styled.div`
  ${({ theme }) => css`
    & + & {
      margin-top: 20px;
    }

    h3 {
      color: ${theme.colors.text_light_87};
      font-size: 12px;
      margin-bottom: 15px;
      font-weight: ${theme.font.normal};
    }
  `}
`;

export const Filters = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  overflow: auto;
  padding-right: 20px;
`;

export const EnvironmentStatusFilter = styled.div`
  ${({ theme }) => css`
    background-color: ${theme.colors.primary};
    padding: 10px;
    border-radius: 10px;
    min-width: 100px;
    color: ${theme.colors.strong_background};
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 12px;
    gap: 10px;
    height: 4rem;

    button {
      cursor: pointer;
    }
  `}
`;

export const WrapperHeader = styled.div`
  ${({ theme }) => css`
    display: flex;
    justify-content: space-between;


    ${media.lessThan("medium")`
      flex-direction: column;
    `}
  `}
`;

export const WrapperAttachmentArea = styled.div`
  width: 250px;
  ${media.lessThan("medium")`
    margin-top: 20px;
    margin-bottom: 20px;
    margin-left: 20px;
  `}
`;
