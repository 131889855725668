export const DROPDOWN_OPTIONS = [
  {
    id: 1,
    name: "Semanal",
    default: "true",
  },
  {
    id: 2,
    name: "Quinzenal",
    default: "true",
  },
  {
    id: 3,
    name: "Mensal",
    default: "true",
  },
  {
    id: 4,
    name: "Bimestral",
    default: "true",
  },
  {
    id: 5,
    name: "Trimestral",
    default: "true",
  },
  {
    id: 6,
    name: "Semestral",
    default: "true",
  },
  {
    id: 7,
    name: "Anual",
    default: "true",
  },
];

export const HOURS = [
  {
    id: 1,
    name: "Minutos",
    default: "true",
  },
  {
    id: 2,
    name: "Horas",
    default: "true",
  },
  {
    id: 3,
    name: "Dias",
    default: "true",
  },
];

export const PERIODS = [
  {
    id: 1,
    name: "Dias",
    default: "true",
  },
  {
    id: 2,
    name: "Meses",
    default: "true",
  },
  {
    id: 3,
    name: "Anos",
    default: "true",
  },
];
