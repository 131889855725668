import { useEffect, useState } from "react";

import { Page } from "templates/Page";
import { ModalRight } from "components/ModalRight";
import { ListItemCard } from "../ListItemCard";
import { Label } from "../Label";
import { ModuleList } from "../ModuleList";
import { LoadingPageModal } from "components/LoadingPageModal";
import { Input } from "components/Input";
import { Button } from "components/Button";
import { NewAlertModal } from "components/NewAlertModal";
import { mappedProfileIcons } from "../RoleAndPermission";
import { ShowWhenHavePermission } from "../ShowWhenHavePermission";
import PencilSvg from "assets/icons/pencil.svg";

import { TeamMemberDTO } from "dtos/permission/TeamMemberDTO";

import LogoSvg from "assets/icons/logo-color.svg";

import { api } from "services/api";
import { useAccount } from "hooks/permission/account";
import { useToast } from "hooks/toast";

import * as S from "./styles";
import { Pages } from "pages/permission/ManagementOfTeam";

type ModalProfileDetailProps = {
  invitedId: number;
  openModalDetail: boolean;
  handleCloseModal: () => void;
  setViewPageDetail?: (page: Pages | null) => void;
  setEdditingPermission?: (value: boolean) => void;
};

export function ModalMemberDetail({
  invitedId,
  openModalDetail,
  handleCloseModal,
  setEdditingPermission,
}: ModalProfileDetailProps) {
  const { whoami } = useAccount();
  const { addToast } = useToast();
  const [loading, setLoading] = useState(true);
  const [showRemoveMemberAlert, setShowRemoveMemberAlert] = useState(false);
  const [showSuccessfullyRemovedAlert, setShowSuccessfullyRemovedAlert] =
    useState(false);
  const [member, setMember] = useState<TeamMemberDTO>({} as TeamMemberDTO);

  async function getMemberDetail() {
    try {
      const response = await api.get(
        `users/teams/${whoami?.id}/members/${invitedId}`,
        {}
      );

      setMember(response.data);
    } catch (err) {
      addToast({
        title: "Erro",
        description: "Ocorreu um erro ao carregar ",
        type: "error",
      });
    } finally {
      setLoading(false);
    }
  }

  async function removeTeamMember() {
    try {
      setLoading(true);

      await api.delete(`users/teams/${whoami?.id}/members/${invitedId}`);

      setShowRemoveMemberAlert(false);
      if (member.inviteStatus === "pending") {
        addToast({
          title: "Convite removido",
          description: "Convite removido com sucesso!",
          type: "success",
        });
        handleCloseModal();
        return;
      }

      setShowSuccessfullyRemovedAlert(true);
    } catch (err) {
      addToast({
        title: "Erro",
        description: "Ocorreu um erro ao remover membro",
        type: "error",
      });
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    getMemberDetail();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ModalRight isOpen={openModalDetail} handleToggleOpen={handleCloseModal}>
      {loading ? (
        <LoadingPageModal />
      ) : (
        <>
          <NewAlertModal
            isVisible={showRemoveMemberAlert}
            title="Remover membro da equipe?"
            description="Você tem certeza de que deseja remover este membro da equipe?"
            action="choose"
            handleConfirm={removeTeamMember}
            onCloseModal={() => setShowRemoveMemberAlert(false)}
            labelConfirm="Remover"
            labelCancel="Cancelar"
            buttonConfirmColor="#FA8B94"
          />

          <NewAlertModal
            isVisible={showSuccessfullyRemovedAlert}
            title="Membro removido com sucesso!"
            description="Para adicioná-lo novamente será necessário o envio de um novo convite."
            action="confirm"
            onCloseModal={() => {
              setShowSuccessfullyRemovedAlert(false);
              handleCloseModal();
            }}
            labelConfirm="Ok, fechar"
          />

          <Page
            title=""
            subtitle=""
            handleGoBack={handleCloseModal}
            style={{ position: "relative" }}
          >
            <S.ProfileInfo>
              <S.WrapperProfile>
                <img src={member.photo} alt={member.name} />
                <S.Wrapper>
                  <h4>{member.name}</h4>

                  {!!member.address?.city && (
                    <S.Text>
                      {member.address.city}, {member.address.state}
                    </S.Text>
                  )}
                </S.Wrapper>
              </S.WrapperProfile>

              <img src={LogoSvg} alt="Logo Profiz" />
            </S.ProfileInfo>
            <S.CompanyInfo>
              <S.ContainerProfile>
                <img src={whoami?.companyLogo} alt={whoami?.companyName} />
                <S.Wrapper>
                  <S.Highlight>
                    {member.profile?.name} <S.Text>em</S.Text>{" "}
                    {whoami?.companyName}
                  </S.Highlight>
                  {!!member.address?.city && (
                    <S.Text>
                      {member.address.city}, {member.address.state}
                    </S.Text>
                  )}
                </S.Wrapper>
              </S.ContainerProfile>
              {member.memberActive && (
                <S.EditPermission
                  onClick={(event) => {
                    setEdditingPermission && setEdditingPermission(true);
                  }}
                >
                  <img src={PencilSvg} alt="Editar endereço" />
                </S.EditPermission>
              )}
            </S.CompanyInfo>
            <Label>Nome</Label>
            <ListItemCard
              image={member.photo}
              title={member.name}
              roundedImage
              disabled
            />
            <S.WrapperGroup>
              <Input
                name="E-mail"
                value={member.email}
                disabled
                placeholder="E-mail"
              />
            </S.WrapperGroup>
            <S.WrapperGroup>
              <Label>Cargo</Label>
              <ListItemCard
                title={member.profile.name}
                boldTitle
                subtitle={
                  member.profile.default ? "Pré-definido" : "Personalizado"
                }
                image={mappedProfileIcons[member.profile.id]}
                disabled
              />
            </S.WrapperGroup>
            <S.WrapperGroup>
              <Label>Empresa</Label>
              <ListItemCard
                image={whoami?.companyLogo || ""}
                title={whoami?.companyName || ""}
                roundedImage
                disabled
              />
            </S.WrapperGroup>
            <S.WrapperGroup>
              <Label>Permissões</Label>
              <ModuleList modules={member.profile.modules} />
            </S.WrapperGroup>
            {member.inviteStatus === "pending" && (
              <ShowWhenHavePermission
                moduleHash="team"
                actionHash="invite-delete"
              >
                <Button
                  typeButton="delete"
                  style={{ position: "fixed", bottom: 20, width: 500 }}
                  onClick={() => setShowRemoveMemberAlert(true)}
                >
                  Remover acesso
                </Button>
              </ShowWhenHavePermission>
            )}
            {member.memberActive && (
              <ShowWhenHavePermission
                moduleHash="team"
                actionHash="member-delete"
              >
                <Button
                  typeButton="delete"
                  style={{ position: "fixed", bottom: 20, width: 500 }}
                  onClick={() => setShowRemoveMemberAlert(true)}
                >
                  Remover acesso
                </Button>
              </ShowWhenHavePermission>
            )}
          </Page>
        </>
      )}
    </ModalRight>
  );
}
