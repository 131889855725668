import React from "react";

import * as S from "./styles";

type AuthProps = {
  children: React.ReactNode;
};

export function Auth({ children }: AuthProps) {
  return (
    <S.Container>
      <S.BackgroundContent>{children}</S.BackgroundContent>
    </S.Container>
  );
}
