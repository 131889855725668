import { useState } from "react";
import { Page } from "templates/Page";
import { ModalRight } from "components/ModalRight";
import { ListItemCard } from "../ListItemCard";
import { Label } from "../Label";
import { ModuleList } from "../ModuleList";
import { Button } from "components/Button";

import { Profile } from "dtos/permission/ProfileDTO";
import { mappedProfileIcons } from "../RoleAndPermission";

import * as S from "./styles";
import { useAccount } from "hooks/permission/account";
import { api } from "services/api";
import { NewAlertModal } from "components/NewAlertModal";
import theme from "styles/theme";
type ModalProfileDetailProps = {
  profile: Profile;
  openModalDetail: boolean;
  handleSelectRole?: (memberRole: Profile) => void;
  handleToggleModal: () => void;
  editingPermission?: boolean;
  setEdditingPermission?: (value: boolean) => void;
  memberInvitedId?: number;
};

export function ModalProfileDetail({
  profile,
  openModalDetail,
  handleSelectRole = undefined,
  handleToggleModal,
  editingPermission,
  memberInvitedId,
  setEdditingPermission,
}: ModalProfileDetailProps) {
  const { whoami } = useAccount();
  function handleSelect(memberRole: Profile) {
    !!handleSelectRole && handleSelectRole(memberRole);
    handleToggleModal();
  }

  const [modalInformation, setModalInformation] = useState(false);
  const [updatedProfile, setUpdatedProfile] = useState<Profile>(profile);
  const updatePermission = async (profile: Profile) => {
    const response = await api.put(
      `/users/teams/${whoami?.id}/members/${memberInvitedId}`,
      {
        profileId: profile.id,
      }
    );
    setUpdatedProfile(response.data.profile);
    setModalInformation(true);
  };
  return (
    <ModalRight isOpen={openModalDetail} handleToggleOpen={handleToggleModal}>
      <NewAlertModal
        isVisible={modalInformation}
        title="Cargo alterado!"
        description="Uma notificação foi enviada para o membro selecionado."
        action="confirm"
        handleConfirm={() => {
          setModalInformation(false);
          setEdditingPermission && setEdditingPermission(false);
        }}
        onCloseModal={() => {
          setEdditingPermission && setEdditingPermission(false);

          setModalInformation(false);
        }}
        labelConfirm="confirm"
        labelCancel="Cancelar"
        buttonConfirmColor={theme.colors.primary}
      />

      <Page
        title="Permissões"
        subtitle=""
        handleGoBack={handleToggleModal}
        style={{ position: "relative" }}
      >
        <Label>Cargo*</Label>
        <ListItemCard
          title={updatedProfile.name}
          boldTitle
          subtitle={updatedProfile.default ? "Pré-definido" : "Personalizado"}
          image={mappedProfileIcons[updatedProfile.id]}
        />

        <S.Wrapper>
          <Label>Permissões</Label>
          <ModuleList modules={updatedProfile.modules} />
        </S.Wrapper>

        {editingPermission && (
          <Button
            onClick={() => updatePermission(updatedProfile)}
            style={{ position: "fixed", bottom: 20, width: 500 }}
          >
            Atribuir novo cargo
          </Button>
        )}

        {!!handleSelectRole && (
          <Button
            onClick={() => handleSelect(updatedProfile)}
            style={{ position: "fixed", bottom: 20, width: 500 }}
          >
            Salvar
          </Button>
        )}
      </Page>
    </ModalRight>
  );
}
