import { useHistory } from "react-router";
import { format, parse } from "date-fns";
import { UserExecutionCard } from "components/UserExecutionCard";

import CheckIcon from "assets/icons/check.svg";
import { ServiceWithChecklistDTO } from "dtos/ServiceWithChecklistDTO";

import * as S from "./styles";

type CardServiceFromOSProps = {
  data: ServiceWithChecklistDTO;
};

export function CardServiceFromOS({ data }: CardServiceFromOSProps) {
  const history = useHistory();
  const formattedScheduleDate = parse(
    data.scheduleDate,
    "yyyy-MM-dd HH:mm:ss",
    new Date()
  );

  function handleNavigateToServiceDetail() {
    const { 3: serviceOrderIdFromUrl } = window.location.pathname.split("/");

    history.push(
      `/service-order/concluded/${serviceOrderIdFromUrl}/detail-service/${data.idBudgetService}`
    );
  }

  return (
    <S.Container>
      <S.Header>
        <div>
          <S.Title>{data.service.service.name}</S.Title>
          {data.scheduleDate && (
            <S.Title>
              {format(formattedScheduleDate, "dd/MM/yyyy - HH:mm")}
            </S.Title>
          )}
        </div>

        <img
          src={CheckIcon}
          alt="ícone de um check com background verde transparente"
        />
      </S.Header>

      <S.TextDetail>
        {data.service.equipment.name ? (
          <>
            {data.service.equipment.name}
            {data.service.equipmentType?.name}
            {data.service.capacity?.name &&
              ` | ${data.service.capacity.name}`}{" "}
            <br />
            {data.service.brand && `Marca: ${data.service.brand}`}
            {data.service.runtime?.time &&
              ` | Execução: ${data.service.runtime.time} ${data.service.runtime.extension}`}
            {data.service.warranty?.time &&
              ` - Garantia: ${data.service.warranty.time} ${data.service.warranty.extension}`}
          </>
        ) : (
          <>Sem equipamento cadastrado</>
        )}
      </S.TextDetail>

      <S.Title style={{ marginTop: 8 }}>
        {data.service.serviceChecklist[0]?.name}
      </S.Title>

      <S.WrapperDetailService>
        <S.DetailServiceText onClick={handleNavigateToServiceDetail}>
          Ver detalhes do serviço
        </S.DetailServiceText>
      </S.WrapperDetailService>

      {data.userExecution && data.userExecution.name && (
        <S.WrapperUserExecution>
          <S.TextDetail>Responsável pelo serviço:</S.TextDetail>
          <UserExecutionCard userExecution={data.userExecution} />
        </S.WrapperUserExecution>
      )}
    </S.Container>
  );
}
