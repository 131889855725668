import React, { useEffect, useState } from "react";

import * as S from "./styles";

import { useTheme } from "styled-components";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import ProfizLogo from "assets/icons/logo-color.svg";
import { Input } from "components/Input";
import { Button } from "components/Button";
import { useToast } from "hooks/toast";
import axios from "axios";
import { useHistory, useParams } from "react-router";
import { AccountData, AnswerInvite } from "dtos/permission/AnswerInviteDTO";
import { api } from "services/api";
import { LoadingProfiz } from "components/LoadingProfiz";
import { PasswordVerification } from "components/PasswordVerification";

type Errors = {
  [key: string]: string;
};

type RouteParams = {
  registerHash: string;
};

type UserType = {
  id: number;
  name: string;
  email: string;
  accountExists: boolean;
};

export function TeamRegistrationPage() {
  const history = useHistory();
  const { registerHash } = useParams<RouteParams>();
  const { addToast } = useToast();
  const [hasError, setHasError] = useState<Errors>({} as Errors);
  const [showPassword, setShowPassword] = useState(false);
  const [showPasswordConfirm, setShowPasswordConfirm] = useState(false);
  const theme = useTheme();

  const [password, setPassword] = useState("");
  const [passwordValidate, setPasswordValidate] = useState("");
  const [loading, setLoading] = useState(false);

  const [accountInfo, setAccountInfo] = useState<AccountData>(
    {} as AccountData
  );
  const [userInfo, setUserInfo] = useState<UserType>({} as UserType);

  useEffect(() => {
    setData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function setData() {
    try {
      setLoading(true);
      const { data } = await api.get<AnswerInvite>(
        `/users/teams/answer-invite/${registerHash}`
      );

      setUserInfo(data.user);
      setAccountInfo(data.account);
    } catch (error) {
      addToast({
        title: "Ops!!",
        description:
          axios.isAxiosError(error) && error.response?.data.error
            ? error.response.data.error
            : "Ocorreu um erro ao buscar as informações do convite.",
        type: "error",
      });
    } finally {
      setLoading(false);
    }
  }

  async function Submit() {
    if (!password && !passwordValidate) {
      setHasError({
        ...hasError,
        password: "Insira uma senha válida.",
        passwordValidate:
          "O valor deve ser igual ao campo de definição de senha.",
      });

      addToast({
        title: "Ops!!",
        description: "Preencha os campos obrigatórios.",
        type: "error",
      });
      return;
    }

    if (!password) {
      setHasError({ ...hasError, password: "Insira uma senha válida." });
      addToast({
        title: "Ops!!",
        description: "Preencha os campos obrigatórios.",
        type: "error",
      });
      return;
    }

    if (password !== passwordValidate) {
      setHasError({
        ...hasError,
        passwordValidate:
          "O valor deve ser igual ao campo de definição de senha.",
      });
      addToast({
        title: "Ops!!",
        description:
          "Insira a mesma senha nos campos de definir e confirmar a senha.",
        type: "error",
      });
      return;
    }

    if (password.length <= 7) {
      setHasError({
        ...hasError,
        password: "A senha deve conter pelo menos 8 dígitos",
        passwordValidate: "A senha deve conter pelo menos 8 dígitos",
      });
      addToast({
        title: "Ops!!",
        description: "A senha deve conter pelo menos 8 dígitos.",
        type: "error",
      });
      return;
    }

    try {
      setLoading(true);

      await fetch(
        `${process.env.REACT_APP_BASE_URL}/v1/users/teams/answer-invite/${registerHash}`,
        {
          method: "POST",
          headers: {
            "App-Token": `${process.env.REACT_APP_TOKEN_CONNECT_BACKEND}`,
          },
          body: JSON.stringify({
            accept: true,
            newAccount: {
              name: userInfo.name,
              email: userInfo.email,
              password: password,
            },
          }),
        }
      );

      addToast({
        title: "OK!",
        description: "Cadastro realizado com sucesso.",
        type: "success",
      });

      history.push("/permission/public/app-redirect", {
        accountInfo,
      });
    } catch (error) {
      addToast({
        title: "Ops!!",
        description:
          axios.isAxiosError(error) && error.response?.data.error
            ? error.response.data.error
            : "Ocorreu um erro ao criar um novo cadastro.",
        type: "error",
      });
    } finally {
      setLoading(false);
    }
  }

  function handleValidatePassword() {
    if (passwordValidate === password) {
      setHasError({ ...hasError, passwordValidate: "" });
      return;
    }
    setHasError({
      ...hasError,
      passwordValidate:
        "O valor deve ser igual ao campo de definição de senha.",
    });
  }

  return (
    <>
      {<LoadingProfiz isVisible={loading} />}
      <S.Container>
        <S.TeamCard>
          <S.ProfizLogo>
            <img
              src={ProfizLogo}
              alt="Logo Profiz"
              width="165px"
              height="45px"
            />
          </S.ProfizLogo>
          <S.Header>
            <p className="title">Confirme seu cadastro na Profiz!</p>
            <p className="subtitle">
              Crie uma conta para fazer parte da equipe
              <strong> {accountInfo.companyName}.</strong>
            </p>
          </S.Header>
          <S.WrapperInput>
            <Input
              name="Nome *"
              placeholder="Seu nome completo"
              value={userInfo.name || ""}
              disabled
            />
          </S.WrapperInput>
          <S.WrapperInput>
            <Input
              name="E-mail *"
              placeholder="Seu e-mail"
              value={userInfo.email || ""}
              disabled
            />
          </S.WrapperInput>

          <S.WrapperInputPassword>
            <Input
              type={showPassword ? "text" : "password"}
              name="Defina uma senha *"
              placeholder="Digite sua senha"
              value={password}
              onChange={(e) => setPassword(e.target.value.trimStart())}
              hasError={hasError.password}
              onFocusClearError={() =>
                setHasError({ ...hasError, password: "" })
              }
            />
            <S.ShowPassword
              type="button"
              onClick={() => setShowPassword(!showPassword)}
            >
              {showPassword ? (
                <AiOutlineEyeInvisible
                  size={27}
                  color={theme.colors.subtitle}
                />
              ) : (
                <AiOutlineEye size={27} color={theme.colors.subtitle} />
              )}
            </S.ShowPassword>
          </S.WrapperInputPassword>

          <S.WrapperInputPassword>
            <Input
              type={showPasswordConfirm ? "text" : "password"}
              name="Confirme sua senha *"
              placeholder="Digite sua senha novamente"
              value={passwordValidate}
              onChange={(e) => setPasswordValidate(e.target.value.trimStart())}
              hasError={hasError.passwordValidate}
              onFocusClearError={() =>
                setHasError({ ...hasError, passwordValidate: "" })
              }
              onKeyUp={handleValidatePassword}
            />
            <S.ShowPassword
              type="button"
              onClick={() => setShowPasswordConfirm(!showPasswordConfirm)}
            >
              {showPasswordConfirm ? (
                <AiOutlineEyeInvisible
                  size={27}
                  color={theme.colors.subtitle}
                />
              ) : (
                <AiOutlineEye size={27} color={theme.colors.subtitle} />
              )}
            </S.ShowPassword>
          </S.WrapperInputPassword>
          <S.WrapperInput>
            <PasswordVerification password={password} />
          </S.WrapperInput>
          <S.WrapperInput>
            <Button loading={loading} onClick={Submit}>
              Cadastrar
            </Button>
          </S.WrapperInput>
        </S.TeamCard>
      </S.Container>
    </>
  );
}
