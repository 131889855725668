import styled, { css } from "styled-components";


export const ContainerPagination = styled.div`
  display: flex;
  justify-content: center;
`;

export const EmptyList = styled.div`
  ${({ theme }) => css`
    height: 30vh;
    display: flex;
    align-items: center;
    justify-content: center;

    p {
      color: ${theme.colors.text_extra_light};
      font-size: 12px;
      font-family: ${theme.font.family};
      font-weight: ${theme.font.normal};

      text-align: center;
      line-height: 20px;
    }
  `}
`;