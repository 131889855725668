import React, { createContext, useContext, useState } from 'react'
import { MaterialProps } from '../../dtos/MaterialDTO'
import addMaskMoney from '../../utils/addMaskMoney'
import { ProviderProps } from 'hooks'

export type MaterialsForEditingBudgetProps = {
  quantity: number
  total: number
  formattedTotal: string
} & MaterialProps

type UpdateMaterialsForEditingBudgetProps = {
  id: number
  categoryId: number
  type: {
    id: number
    name: string
  }
  item: {
    id: number
    name: string
  }
  price: number
  formattedPrice: string
}

type MaterialsForEditingBudgetContextData = {
  materialsForEditingBudget: MaterialsForEditingBudgetProps[]
  handleSetMaterialsForEditingBudget: (
    materials: MaterialsForEditingBudgetProps[]
  ) => void
  handleRemoveMaterial: (materialId: number) => void
  handleUpdatedMaterial: (
    material: UpdateMaterialsForEditingBudgetProps
  ) => void
}

const MaterialsForEditingBudgetContext =
  createContext<MaterialsForEditingBudgetContextData>(
    {} as MaterialsForEditingBudgetContextData
  )

export function useMaterialsForEditingBudget(): MaterialsForEditingBudgetContextData {
  const context = useContext(MaterialsForEditingBudgetContext)

  if (!context) {
    throw Error(
      'useMaterialsForEditingBudget must be used within a MaterialsForEditingBudgetProvider'
    )
  }

  return context
}

export const MaterialsForEditingBudgetProvider = ({ children }: ProviderProps) => {
  const [materialsForEditingBudget, setMaterialsForEditingBudget] = useState<
    MaterialsForEditingBudgetProps[]
  >([])

  function handleSetMaterialsForEditingBudget(
    materials: MaterialsForEditingBudgetProps[]
  ) {
    setMaterialsForEditingBudget(materials)
  }

  function handleRemoveMaterial(id: number) {
    setMaterialsForEditingBudget((oldMaterials) => {
      return oldMaterials.filter((material) => material.id !== id)
    })
  }

  function handleUpdatedMaterial(
    material: UpdateMaterialsForEditingBudgetProps
  ) {
    const payloadMaterials = materialsForEditingBudget.map(
      (currentMaterial) => {
        if (currentMaterial.id === material.id) {
          return {
            ...material,
            total: currentMaterial.quantity * material.price,
            formattedTotal: addMaskMoney(
              currentMaterial.quantity * material.price
            ),
            quantity: currentMaterial.quantity
          }
        }
        return currentMaterial
      }
    ) as MaterialsForEditingBudgetProps[]

    setMaterialsForEditingBudget(payloadMaterials)
  }

  return (
    <MaterialsForEditingBudgetContext.Provider
      value={{
        materialsForEditingBudget,
        handleSetMaterialsForEditingBudget,
        handleRemoveMaterial,
        handleUpdatedMaterial
      }}
    >
      {children}
    </MaterialsForEditingBudgetContext.Provider>
  )
}
