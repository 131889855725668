import React, { createContext, useState, useContext } from "react";
import { ProviderProps } from "hooks";

export type SignatureType = "signature" | "client-sign" | "profile-sign";

export type SignatureProps = {
  id: number;
  link: string;
  type: SignatureType;
};

interface ServiceSignaturePhotosContextData {
  selectedServiceSignaturePhotos: SignatureProps[];
  handleSelectedServiceSignaturePhotos(signatures: SignatureProps[]): void;
}

const ServiceSignaturePhotosContext =
  createContext<ServiceSignaturePhotosContextData>(
    {} as ServiceSignaturePhotosContextData
  );

export function useServiceSignaturePhotos(): ServiceSignaturePhotosContextData {
  const context = useContext(ServiceSignaturePhotosContext);

  if (!context) {
    throw Error(
      "useServiceSignaturePhotos must be used within an ServiceSignaturePhotosProvider"
    );
  }

  return context;
}

export const ServiceSignaturePhotosProvider = ({ children }: ProviderProps) => {
  const [selectedServiceSignaturePhotos, setSelectedServiceSignaturePhotos] =
    useState<SignatureProps[]>([]);

  function handleSelectedServiceSignaturePhotos(signatures: SignatureProps[]) {
    setSelectedServiceSignaturePhotos(signatures);
  }

  return (
    <ServiceSignaturePhotosContext.Provider
      value={{
        selectedServiceSignaturePhotos,
        handleSelectedServiceSignaturePhotos,
      }}
    >
      {children}
    </ServiceSignaturePhotosContext.Provider>
  );
};
