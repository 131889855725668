import React, { useEffect, useState } from "react";

import { Dropdown, DropdownOption } from "components/DropDown";
import { InputAddNewCategory } from "components/InputAddNewCategory";

import { MaterialTypeDropDownProps } from "dtos/MaterialTypeDropDownDTO";
import { useToast } from "hooks/toast";
import { api } from "services/api";

type MaterialTypeProps = {
  materialsType: MaterialTypeDropDownProps[];
  selectedMaterialTypeId?: number;
  hasError: string;
  handleSelectMaterialType: ({ id, name }: DropdownOption) => void;
};

export function MaterialType({
  materialsType,
  selectedMaterialTypeId,
  hasError,
  handleSelectMaterialType,
}: MaterialTypeProps) {
  const { addToast } = useToast();

  const [loadingButton, setLoadingButton] = useState(false);

  const [newMaterialType, setNewMaterialType] = useState("");
  const [materialTypeItems, setMaterialTypeItems] = useState<
    MaterialTypeDropDownProps[]
  >([]);

  useEffect(() => {
    setMaterialTypeItems(materialsType);
  }, [materialsType]);

  function handleChangeNewCategory(text: string) {
    setNewMaterialType(text.trimStart());
  }

  async function handleSaveNewCategory() {
    try {
      setLoadingButton(true);

      if (!newMaterialType) {
        addToast({
          title: "Campo Vazio",
          description: "Digite um novo tipo de material primeiro",
          type: "info",
        });
        return;
      }

      const { data } = await api.post("/material/type", {
        name: newMaterialType,
      });

      setMaterialTypeItems((oldMaterialTypeItems) => [
        ...oldMaterialTypeItems,
        {
          id: data.id,
          name: data.name,
          materials: data.materials,
          default: "false",
        },
      ]);

      setNewMaterialType("");

      addToast({
        title: "Sucesso",
        description: "Tipo de material criado com sucesso",
        type: "success",
      });
    } catch (error) {
      // sendError(error)
      addToast({
        title: "Falha ao atualizar",
        description: "Não foi possível criar um novo tipo de material!",
        type: "error",
      });
    } finally {
      setLoadingButton(false);
    }
  }

  function handleDeleteCategoryType(id: number) {
    const filteredMaterialTypeItems = materialTypeItems.filter(
      (item) => Number(item.id) !== id
    );

    setMaterialTypeItems(filteredMaterialTypeItems);
  }

  return (
    <Dropdown
      label="Tipo*"
      type={"categoryType"}
      placeholder="Selecione o tipo de material"
      options={materialTypeItems}
      categoryIdSelected={selectedMaterialTypeId}
      // eslint-disable-next-line react/jsx-no-bind
      onClickedValue={handleSelectMaterialType}
      hasInput
      handleDeleteCategoryType={handleDeleteCategoryType}
      hasError={hasError}
    >
      <InputAddNewCategory
        isLoadingButton={loadingButton}
        value={newMaterialType}
        // eslint-disable-next-line react/jsx-no-bind
        handleChangeNewCategory={handleChangeNewCategory}
        // eslint-disable-next-line react/jsx-no-bind
        handleSave={handleSaveNewCategory}
      />
    </Dropdown>
  );
}
