import styled, { css } from "styled-components";

export const Container = styled.div``;

export const Content = styled.div`
  ${({ theme }) => css`
    .columm-id {
      width: 130px;
      border-top-left-radius: 10px;
      border-bottom-left-radius: 10px;
    }

    padding-top: 20px;


    color: ${theme.colors.title};
    transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    margin-bottom: 20px;
    margin-top: 20px;

    table {
      width: 100%;
      display: table;
      border-spacing: 0;
      border-collapse: collapse;

      thead {
        display: table-header-group;

        > tr {
          color: inherit;
          display: table-row;
          outline: 0;
          vertical-align: middle;
        }

        th {
          text-align: left;
          display: table-cell;
          padding: 16px;
          font-size: ${theme.font.sizes.xsmall}
          text-align: left;
          font-weight: 400;
          line-height: 1.43;

          letter-spacing: 0.01071em;
          vertical-align: inherit;
        }
      }

      tbody {
        display: table-row-group;
        vertical-align: middle;
        border-color: inherit;

        tr {
          color: inherit;
          display: table-row;
          outline: 0;
          height: 70px;
          vertical-align: middle;
          background-color: ${theme.colors.background_modal};

          td {
            border: 2px solid ${theme.colors.card_container};
            display: table-cell;
            padding: 16px;
            font-size: ${theme.font.sizes.xsmall}
            text-align: left;
            font-weight: 400;
            line-height: 1.43;
            color: ${theme.colors.text};
            letter-spacing: 0.01071em;
            vertical-align: inherit;
            cursor: pointer;
          }

          &:nth-child(even) {
            background-color: ${theme.colors.detail}
          };

          &:hover {
            background-color: rgba(255, 255, 255, 0.04);
          }
        }
      }
    }
  `}
`;

export const ContainerPagination = styled.div`
  display: flex;
  justify-content: center;
`;

export const ContainerImage = styled.button`
  ${({ theme }) => css`
    width: 45px;
    height: 45px;
    padding: 14px 8px;
    border-radius: 12px;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: 1px solid ${theme.colors.schedule};

    & > img {
      width: 20px;
      height: 20px;
    }
  `}
`;
