import React, { ReactElement, useState } from "react";

import { ButtonModalAction } from "components/ButtonModalAction";
import { Button } from "components/Button";
import { ModalRight } from "components/ModalRight";
import { EditButton } from "components/EditButton";
import { DeleteButton } from "components/DeleteButton";

import * as S from "./styles";
import { useSelectedProducts } from "hooks/budget/selectedProducts";
import { CardSelectedProducts } from "components/CardSelectedProducts";
import { Products } from "components/ModalProducts/Products";
import { FormCreateProduct } from "components/ModalProducts/FormCreateProduct";
import { FormEditProduct } from "components/ModalProducts/FormEditProduct";
import { AlertModal } from "components/AlertModal";

type ProductAreaProps = {
  hasError?: string;
  disabled?: boolean;
  hasIdBudget?: boolean;
};

type TOption = {
  [key: string]: ReactElement;
};

export function ProductsArea({
  hasIdBudget = false,
  disabled = false,
  hasError = "",
}: ProductAreaProps) {
  const {
    selectedProductsInContext,
    handleSetSelectedProducts,
    handleUpdateQuantity,
  } = useSelectedProducts();

  const [isOpenAlertModal, setIsOpenAlertModal] = useState(false);
  const [isOpenListProducts, setIsOpenListProducts] = useState(false);
  const [isOpenFormEditProduct, setIsOpenFormEditProduct] = useState(false);
  const [currentPageModal, setCurrentPageModal] = useState("");
  const [idSelectedProduct, setIdSelectedProduct] = useState(0);

  const setPageComponent = (keyPage: string) => {
    const pagesArr: TOption = {
      formEditProduct: (
        <FormEditProduct
          idProduct={idSelectedProduct}
          handleGoBack={() => {
            isOpenListProducts
              ? handleListProducts()
              : handleToggleFormEditProduct();
          }}
        />
      ),
      listProducts: (
        <Products
          handleToggleOpen={handleToggleListProducts}
          // eslint-disable-next-line react/jsx-no-bind
          handleCreateProduct={handleCreateProduct}
          // eslint-disable-next-line react/jsx-no-bind
          handleEditProduct={handleEditProduct}
        />
      ),

      formCreateProduct: (
        <FormCreateProduct handleGoBack={handleListProducts} />
      ),
    };
    return pagesArr[keyPage];
  };

  function handleListProducts() {
    setCurrentPageModal("listProducts");
  }

  function handleCreateProduct() {
    setCurrentPageModal("formCreateProduct");
  }

  function handleEditProduct(id: number) {
    setIdSelectedProduct(id);
    setCurrentPageModal("formEditProduct");
    setIsOpenFormEditProduct(false);
  }

  function handleToggleListProducts() {
    setIsOpenListProducts(!isOpenListProducts);
  }

  function handleOpenFormEditProduct() {
    setIsOpenFormEditProduct(!isOpenFormEditProduct);
  }

  function handleToggleFormEditProduct() {
    setIsOpenFormEditProduct(!isOpenFormEditProduct);
  }

  function onIncrement(id: number, quantity: number) {
    handleUpdateQuantity(id, quantity);
  }

  function handleDeleteProduct(productId: number) {
    if (disabled) return;

    if (selectedProductsInContext.length === 1 && hasIdBudget) {
      setIsOpenAlertModal(true);
      return;
    }

    const filteredProducts = selectedProductsInContext.filter(
      (products) => Number(products.id) !== Number(productId)
    );
    handleSetSelectedProducts(filteredProducts);
  }

  return (
    <S.Container>
      <AlertModal
        isVisible={isOpenAlertModal}
        title="Impossível excluir todos os produtos"
        description="Você precisa ter pelo menos um produto adicionado neste orçamento."
        action="confirm"
        handleConfirm={() => setIsOpenAlertModal(false)}
        onCloseModal={() => setIsOpenAlertModal(false)}
      />
      <ModalRight
        isOpen={isOpenListProducts}
        // eslint-disable-next-line react/jsx-no-bind
        handleToggleOpen={() => {
          handleToggleListProducts();
        }}
      >
        {setPageComponent(currentPageModal)}
      </ModalRight>

      <ModalRight
        isOpen={isOpenFormEditProduct}
        // eslint-disable-next-line react/jsx-no-bind
        handleToggleOpen={() => {
          handleToggleFormEditProduct();
        }}
      >
        {setPageComponent(currentPageModal)}
      </ModalRight>

      {selectedProductsInContext.length <= 0 ? (
        <ButtonModalAction
          label="Produto*"
          placeholder="Adicione produtos no seu orçamento"
          isSelected={isOpenListProducts}
          // eslint-disable-next-line react/jsx-no-bind
          onClick={() => {
            setCurrentPageModal("listProducts");
            handleToggleListProducts();
          }}
          hasError={hasError}
        />
      ) : (
        <>
          <S.Label>Produto*</S.Label>

          <S.Content>
            {selectedProductsInContext.map((currentProduct) => (
              <S.WrapperCard key={currentProduct.id}>
                <CardSelectedProducts
                  data={currentProduct}
                  disabled={disabled}
                  // eslint-disable-next-line react/jsx-no-bind
                  onIncrement={onIncrement}
                />

                <S.WrapperActions>
                  <EditButton
                    onClick={() => {
                      setIdSelectedProduct(currentProduct.id);
                      setCurrentPageModal("formEditProduct");
                      handleOpenFormEditProduct();
                    }}
                  />
                  <DeleteButton
                    disabled={disabled}
                    onClick={() => handleDeleteProduct(currentProduct.id)}
                  />
                </S.WrapperActions>
              </S.WrapperCard>
            ))}
          </S.Content>

          <Button
            typeButton={disabled ? "outlineDisable" : "outline"}
            disabled={disabled}
            onClick={() => {
              setCurrentPageModal("listProducts");
              handleToggleListProducts();
            }}
          >
            Adicionar outro produto
          </Button>
        </>
      )}
    </S.Container>
  );
}
