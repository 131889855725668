import { TourTypeProps } from "components/OnboardingCard/data";
import { User } from "hooks/auth";

export const updateUserOnboarding = (user: User, type: TourTypeProps) => {
  const updateUserData: User = {
    ...user,
    onboarding: {
      ...user.onboarding,
      [type]: true,
    },
    areasOfExpertise: user.areasOfExpertise.map((item) => {
      if (typeof item !== "string") {
        return item.id;
      } else {
        return item;
      }
    }),
  };

  delete user.distanceLimitActuation;

  return updateUserData;
};
