import styled, { css } from "styled-components";

export const WrapperService = styled.div`
  margin-bottom: 20px;
`;

export const SubText = styled.p`
  ${({ theme }) => css`
    margin-top: 15px;
    margin-bottom: 20px;
    color: ${theme.colors.financial_text};
    font-size: ${theme.font.sizes.small};
    text-align: left;
    letter-spacing: 0.26px;
    line-height: 16px;
  `};
`;

export const EmptyMessage = styled.div`
  ${({ theme }) => css`
    p {
      margin-top: 50px;
      color: ${theme.colors.title};
      font-size: ${theme.font.sizes.large};
      text-align: center;
      letter-spacing: 0.26px;
      line-height: 16px;
    }
  `};
`;

export const WrapperInfoCard = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
  border-radius: 10px;
  min-height: 90px;
  align-items: center;
  justify-content: space-between;
`;

export const WrapperButton = styled.div`
  ${({ theme }) => css`
    width: 100%;
    height: 100%;
    max-height: 100px;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 0px;
    background-color: ${theme.colors.background_light};

    display: flex;
    flex-direction: column;

    & + & {
      border-top-right-radius: 0;
      border-bottom-right-radius: 10px;
    }
  `}
`;

export const WrapperButtonContent = styled.div`
  max-width: 10%;
  height: 87px;
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export const WrapperActions = styled.div`
  ${({ theme }) => css`
    width: 10%;
    min-height: 110px;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    background-color: ${theme.colors.background_light};

    display: flex;
    flex-direction: column;
  `}
`;

export const WrapperData = styled.div`
  ${({ theme }) => css`
    padding: 10px;
    max-width: 390px;
    h6 {
      margin-top: 5px;
      color: ${theme.colors.title};
      font-size: ${theme.font.sizes.small};
      text-align: left;
      letter-spacing: 0.26px;
      line-height: 16px;
      margin-bottom: 10px;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: normal;
    }

    p {
      margin-top: 5px;
      color: ${theme.colors.text_light};
      font-size: ${theme.font.sizes.xsmall};
      text-align: left;
      letter-spacing: 0.26px;
      line-height: 16px;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: normal;
    }
  `};
`;

export const ContainerImg = styled.div`
  display: flex;
  flex-direction: row;
  margin-left: 15px;
`;

export const ContainerPagination = styled.div`
  display: flex;
  justify-content: center;
`;
