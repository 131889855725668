import styled, { css } from "styled-components";
import { opacify } from "polished";

type CheckboxProps = {
  isSelected: boolean;
};

export const CardObservation = styled.button<CheckboxProps>`
  ${({ theme, isSelected }) => css`
    width: 80%;
    min-height: 60px;
    padding: 0 12px;
    border-radius: 10px;
    margin-bottom: 16px;
    display: flex;
    align-items: center;

    background-color: ${theme.colors.background_light};
    border: 1px solid ${isSelected ? theme.colors.primary : "transparent"};

    &:hover {
      background-color: ${opacify(0.01, theme.colors.background_light)};
    }

    & > p {
      width: 85%;
      padding: 14px 0;
      text-align: left;
      font-size: ${theme.font.sizes.small};
      color: ${theme.colors.text_extra_light};
      white-space: pre-wrap;
    }
  `}
`;

export const CheckboxArea = styled.div`
  width: 12%;
  height: 58px;

  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Checkbox = styled.div<CheckboxProps>`
  ${({ theme, isSelected }) => css`
    width: 15px;
    height: 15.5px;

    display: flex;
    justify-content: center;
    align-items: center;

    border-radius: 4px;
    border: 1px solid #707070;

    & > div {
      width: 7px;
      height: 6.5px;
      border-radius: 1.5px;
      background-color: ${isSelected ? theme.colors.primary : "transparent"};
    }
  `}
`;

export const WrapperButtons = styled.div`
  margin-top: 28px;
  display: flex;
  flex-direction: column;
  gap: 6px;
`;

export const ContainerObservations = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const OptionButton = styled.button`
  ${({ theme }) => css`
    padding: 20px;
    margin-left: 10px;
    border-radius: 10px;
    max-height: 60px;
    background-color: ${theme.colors.background_light};
    &:hover {
      background-color: ${opacify(0.01, theme.colors.background_light)};
    }
  `}
`;
