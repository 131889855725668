import styled, { css, DefaultTheme } from 'styled-components'

type ContainerProps = {
  typeSvg: 'client' | 'model' | 'modelDisabled' | 'pdf' | 'pdfDisabled';
}

const borderModifier = {
  client: (theme: DefaultTheme) => css`
    border: 1px solid ${theme.colors.primary};
  `,
  model: (theme: DefaultTheme) => css`
    border: 1px solid ${theme.colors.model};
  `,
  modelDisabled: (theme: DefaultTheme) => css`
    border: 1px solid ${theme.colors.text_light};
    cursor: not-allowed;
  `,
  pdf: (theme: DefaultTheme) => css`
    border: 1px solid ${theme.colors.schedule};
  `,
  pdfDisabled: (theme: DefaultTheme) => css`
    border: 1px solid ${theme.colors.text_light};
    cursor: not-allowed;
  `,
}

export const Container = styled.button<ContainerProps>`
  ${({ theme, typeSvg }) => css`
    width: 100px;
    height: 101px;
    padding: 14px 8px;
    border-radius: 12px;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    ${borderModifier[typeSvg!](theme)}

    & > img:not(#loading) {
      width: 36px;
      height: 36px;
    }

    & > img#loading {
      transform: scale(0.4);
    }

    & > span {
      font-size: ${theme.font.sizes.small};
      color: ${theme.colors.title};
      margin-top: 10px;
    }
  `}
`
