import { useState } from "react";
import { useHistory } from "react-router";

import { Page } from "templates/Page";
import { ModalRight } from "components/ModalRight";
import { Button } from "components/Button";
import {
  Label,
  ListItemCard,
  ModuleList,
  mappedProfileIcons,
} from "components/Permission";
import { NewAlertModal } from "components/NewAlertModal";
import { LoadingProfiz } from "components/LoadingProfiz";

import { AnswerInvite } from "dtos/permission/AnswerInviteDTO";
import { useToast } from "hooks/toast";
import { api } from "services/api";

import * as S from "./styles";

type ModalNewInviteDetailProps = {
  invite: AnswerInvite;
  openModalDetail: boolean;
  handleCloseModal: () => void;
};

export function ModalNewInviteDetail({
  invite,
  openModalDetail,
  handleCloseModal,
}: ModalNewInviteDetailProps) {
  const history = useHistory();
  const { addToast } = useToast();

  const [loading, setLoading] = useState(false);
  const [refuseModal, setRefuseModal] = useState(false);
  const [acceptModal, setAcceptModal] = useState(false);
  const [invitedAcceptedWithSucess, setinvitedAcceptedWithSucess] =
    useState(false);

  async function handleRefuseInvite() {
    try {
      setLoading(true);

      await api.post(`users/teams/answer-invite/${invite.hash}`, {
        accept: false,
      });

      history.push("/teams/refused", { account: invite.account });
    } catch (err) {
      addToast({
        title: "Erro",
        description: "Ocorreu um erro ao recusar o convite!",
        type: "error",
      });
    } finally {
      setRefuseModal(false);
    }
  }

  async function handleConfirm() {
    try {
      setLoading(true);

      await api.post(`users/teams/answer-invite/${invite.hash}`, {
        accept: true,
      });

      setAcceptModal(false);
      setinvitedAcceptedWithSucess(true);
    } catch (err) {
      addToast({
        title: "Erro",
        description: "Ocorreu um erro ao remover membro",
        type: "error",
      });
    } finally {
      setLoading(false);
    }
  }

  return (
    <>
      <NewAlertModal
        title="Recusar convite?"
        description="Você estará recusando o convite dessa empresa"
        isVisible={refuseModal}
        action="choose"
        labelConfirm="Concordar"
        handleConfirm={handleRefuseInvite}
        handleCancel={() => setRefuseModal(false)}
        onCloseModal={() => setRefuseModal(false)}
      />

      <NewAlertModal
        title="Aceitar convite de novo membro?"
        description="Suas informações de agenda e qualificações serão compartilhadas com a conta da empresa."
        isVisible={acceptModal}
        action="choose"
        labelConfirm="Concordar"
        handleConfirm={handleConfirm}
        handleCancel={() => setAcceptModal(false)}
        onCloseModal={() => setAcceptModal(false)}
      />

      <NewAlertModal
        title="Convite aceito!"
        description="Parabéns! Agora você possui um cargo permissionado e faz parte de uma equipe."
        isVisible={invitedAcceptedWithSucess}
        action="confirm"
        labelConfirm="Ir para a home"
        onCloseModal={() => history.push("/")}
      />

      <ModalRight isOpen={openModalDetail} handleToggleOpen={handleCloseModal}>
        {loading ? (
          <LoadingProfiz isVisible={loading} />
        ) : (
          <Page
            title="Novo convite"
            subtitle="Você foi convidado para fazer parte de uma equipe desta empresa."
            style={{ position: "relative" }}
          >
            <S.Wrapper>
              <Label>Equipe*</Label>
              <ListItemCard
                image={invite.account.photo}
                roundedImage
                title={invite.account.name}
                boldTitle
                disabled
              />
            </S.Wrapper>

            <S.Wrapper>
              <Label>Cargo*</Label>
              <ListItemCard
                image={mappedProfileIcons[invite.profile.id]}
                title={invite.profile.name}
                subtitle={
                  invite.profile.default ? "Pré-definido" : "Personalizado"
                }
                disabled
              />
            </S.Wrapper>

            <S.Wrapper>
              <Label>Permissões</Label>
              <ModuleList modules={invite.profile.modules} />
            </S.Wrapper>

            <S.WrapperButtons>
              <Button onClick={() => setAcceptModal(true)}>
                Aceitar convite
              </Button>

              <Button typeButton="delete" onClick={() => setRefuseModal(true)}>
                Recusar convite
              </Button>
            </S.WrapperButtons>
          </Page>
        )}
      </ModalRight>
    </>
  );
}
