import styled, { css } from "styled-components";

type ContainerButtonProps = {
  hasValue?: boolean;
  hasError?: boolean;
};

export const Container = styled.div`
  ${({ theme }) => css`
    background-color: ${theme.colors.strong_background};
    width: 100%;
    max-width: 1400px;
    margin: 0 auto;
    padding: 30px;
  `}
`;

export const Header = styled.div`
  width: 50%;
  display: flex;
  margin-left: 45px;
`;

export const Content = styled.div`
  width: 50%;
  margin-top: 20px;
  padding-bottom: 10px;
  margin-left: 45px;
`;

export const Title = styled.h1`
  ${({ theme }) => css`
    font-family: ${theme.font.family};
    font-weight: 700;
    font-size: 18px;
    line-height: 21px;
    color: ${theme.colors.text_light_87};
  `}
`;

export const SubTitle = styled.h2`
  ${({ theme }) => css`
    font-family: ${theme.font.family};
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    color: ${theme.colors.text_light_60};
    margin-bottom: 25px;
  `}
`;

export const TextInfoSub = styled.h3`
  ${({ theme }) => css`
    font-size: 14px;
    margin-bottom: 15px;
    line-height: 17px;
    font-weight: 400;
    font-family: ${theme.font.family};
    color: ${theme.colors.text_light_87};
  `}
`;

export const Form = styled.div`
  padding-top: 5px;
  padding-bottom: 15px;
`;

export const WrapperInputs = styled.div`
  padding-bottom: 14px;
`;

export const LabelInput = styled.label`
  ${({ theme }) => css`
    font-family: ${theme.font.family};
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    color: ${theme.colors.text_light_87};
  `}
`;

export const TitleModal = styled.h1`
  ${({ theme }) => css`
    font-family: ${theme.font.family};
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    color: ${theme.colors.text_light_87};
    margin-top: 20px;
  `}
`;

export const ContainerButton = styled.div`
  ${({ theme }) => css`
    color: ${theme.colors.text_light};
    font-size: 12px;
    padding-bottom: 14px;
  `}
`;

export const ButtonOpenModal = styled.div<ContainerButtonProps>`
  ${({ theme, hasValue, hasError }) => css`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
    background-color: ${theme.colors.menu_background};
    padding: 15px;
    border-radius: 10px;
    border-width: 1px;
    border: 1px solid
      ${hasValue
        ? theme.colors.primary
        : hasError
        ? theme.colors.error
        : theme.colors.menu_background};
    cursor: pointer;
  `}
`;

export const CheckContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 15px;
  align-items: center;
`;

export const ButtonOpenModalText = styled.p<ContainerButtonProps>`
  ${({ theme, hasValue }) => css`
    color: ${hasValue ? theme.colors.primary : theme.colors.text_light};
    font-size: 12px;
    font-weight: 400;
    font-family: ${theme.font.family};
  `}
`;

export const TextInfo = styled.p`
  ${({ theme }) => css`
    font-size: 12px;
    font-family: ${theme.font.family};
    font-weight: 400;
    color: ${theme.colors.text};
    margin-bottom: 15px;
  `}
`;

export const LabelButton = styled.p`
  ${({ theme }) => css`
    color: ${theme.colors.title};
    font-size: 12px;
    font-weight: 400;
    line-height: 15px;
    margin-bottom: 5px;
  `};
`;

export const WrapperCalendar = styled.div`
  ${({ theme }) => css`
    background-color: ${theme.colors.background_light};
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 10px;
    margin-bottom: 30px;

    .DayPicker {
      width: 100%;
    }

    .DayPicker-Day,
    .DayPicker-Day--selected {
      padding: 0;
      height: 35px;
      width: 35px;
      border-radius: 100%;
    }

    .DayPicker,
    .DayPicker-Month {
      width: 94% !important;
      border-spacing: 20px;
    }
  `}
`;

export const ContentImage = styled.section`
  display: flex;
  flex-direction: row;
`;

export const WrapperImage = styled.div`
  display: flex;
  align-items: center;
  margin-right: 14px;
`;

export const ErrorText = styled.p`
  ${({ theme }) => css`
    padding-top: 5px;
    font-size: 12px;
    color: ${theme.colors.error};
  `}
`;
