import styled, { css } from "styled-components";

type ButtonProps = {
  isSelected: boolean;
};

export const Container = styled.div<ButtonProps>`
  ${({ theme, isSelected }) => css`
    width: 100%;
    border-radius: 10px;
    background-color: ${theme.colors.background_light};

    border: 1px solid ${isSelected ? theme.colors.primary : "transparent"};
    display: flex;
  `}
`;

export const Button = styled.button`
  width: 90%;
  padding: 15px;

  display: flex;
  align-items: center;
`;

export const CheckBox = styled.div`
  ${({ theme }) => css`
    width: 15px;
    height: 15px;
    margin-right: 15px;
    border: 1px solid ${theme.colors.text};
    border-radius: 5px;

    display: flex;
    justify-content: center;
    align-items: center;
  `}
`;

export const CheckedFilled = styled.div<ButtonProps>`
  ${({ theme, isSelected }) => css`
    width: 7px;
    height: 7px;
    background-color: ${isSelected ? theme.colors.primary : "transparent"};
    border-radius: 2px;
  `}
`;

export const Content = styled.button`
  width: 90%;
  display: flex;
`;

export const WrapperDetail = styled.button`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const WrapperInfo = styled.div`
  width: 100%;
  display: flex;
  text-align: left;
  flex-direction: column;
`;

export const Title = styled.p`
  ${({ theme }) => css`
    font-size: ${theme.font.sizes.xsmall};
    color: ${theme.colors.title};
    margin-bottom: 9px;
  `}
`;

export const Description = styled.p`
  ${({ theme }) => css`
    font-size: ${theme.font.sizes.xsmall};
    color: ${theme.colors.text};
    margin-bottom: 4px;
  `}
`;

export const ObservationButton = styled.button`
  ${({ theme }) => css`
    color: ${theme.colors.text};
    font-size: 12px;
    text-decoration: underline;
    margin-top: 4px;
  `}
`;

export const WrapperPrice = styled.button`
  ${({ theme }) => css`
    p {
      font-size: ${theme.font.sizes.xsmall};
      color: ${theme.colors.title};
    }
  `}
`;

export const WrapperActions = styled.div`
  ${({ theme }) => css`
    width: 10%;
    min-height: 110px;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    background-color: ${theme.colors.background_light};

    display: flex;
    flex-direction: column;
  `}
`;

export const Checklist = styled.div`
  ${({ theme }) => css`
    padding-top: 3px;
    p {
      color: ${theme.colors.primary};
      letter-spacing: 0.26px;
      line-height: 16px;
      font-size: ${theme.font.sizes.xsmall};
      text-align: left;
    }
  `}
`;
