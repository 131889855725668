import { darken } from "polished";
import styled, { css } from "styled-components";
import media from "styled-media-query";

export const Container = styled.div``;

export const FilterTags = styled.div`
  ${media.lessThan("large")`
     padding-left: 20px;
  `}
`;

export const TransactionTypeContainer = styled.div`
  margin: 1rem 0;
  display: flex;
  gap: 10px;
  width: 100%;
`;

interface RadioBoxProps {
  isActive?: boolean;
}

export const RadioBox = styled.button<RadioBoxProps>`
  ${({ theme, isActive }) => css`
    min-width: 85px;
    height: 35px;
    height: 4rem;
    color: ${isActive
      ? theme.colors.strong_background
      : theme.colors.text_light_87};
    background: ${isActive
      ? theme.colors.primary
      : theme.colors.menu_detail_light};
    display: flex;
    align-items: center;
    justify-content: center;
    transition: border-color 0.2s;
    border-radius: 10px;

    &:hover {
      border-color: ${darken(0.1, "#d7d7d7")};
    }

    img {
      width: 20px;
      height: 20px;
    }

    span {
      display: inline-block;
      font-size: 1rem;
      font-size: 12px;
      font-weight: 500;
    }
  `}
`;

export const DateFilterContainer = styled.div`
  margin-top: 30px;
`;

export const WrapperCalendar = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

export const TextDetail = styled.span`
  ${({ theme }) => css`
    font-family: ${theme.font.family};
    font-size: ${theme.font.sizes.xsmall};
    color: ${theme.colors.financial_text};
    line-height: 16px;
    letter-spacing: 0.26px;
    margin-left: 18px;
    margin-right: 18px;
  `}
`;
