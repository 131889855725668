type CategoryResponse = {
  id: number;
  label: string;
  default?: string;
};

export function mappedDropdown(categories: CategoryResponse[]) {
  return categories.map((category) => ({
    id: category.id,
    name: category.label,
    default: category.default,
  }));
}
