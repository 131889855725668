import React from "react";

import { useClient } from "hooks/budget/client";
import { EditButton } from "components/EditButton";
import { DeleteButton } from "components/DeleteButton";

import * as S from "./styles";
import { ClientProps } from "dtos/ClientDTO";
import { useEditClient } from "hooks/editClient";
import { usePmoc } from "hooks/usePmoc";

type CardSelectedClientProps = {
  showModalAddress: () => void;
  showClientsList: () => void;
  disabled?: boolean;
  disabledDelete?: boolean;
  disableEditButton?: boolean;
};

export function CardSelectedClient({
  showClientsList,
  showModalAddress,
  disabled = false,
  disabledDelete,
  disableEditButton=false
}: CardSelectedClientProps) {
  const { client, clearClientData } = useClient();
  const { handleSetEditClient,clearUnityData } = useEditClient();
const { handleSetHasUnity } = usePmoc();

  function handleRemoveClient() {
    clearClientData();
    clearUnityData();
    handleSetHasUnity(false);
  }

  function handleChooseOtherClient(client?: ClientProps) {
    if (client) {
      handleSetEditClient(client);
    }
    showClientsList();
  }

  const setMaskPhone = (phone: string) => {
    if (!phone) return "";
    phone = phone.replace(/\D/g, "");
    phone = phone.replace(/(\d{2})(\d)/, "($1) $2");
    phone = phone.replace(/(\d)(\d{4})$/, "$1-$2");
    return phone;
  };

  return (
    <>
      <S.Label>Cliente*</S.Label>

      <S.Container>
        <S.Content>
          <p>{client.name}</p>
          <p>{client!.cpf_cnpj}</p>
          <p>{setMaskPhone(client!.phone)}</p>
          <p>{client!.email}</p>
        </S.Content>

        <S.WrapperActions>
          <EditButton
            disabled={disabled || disableEditButton}
            onClick={() => handleChooseOtherClient(client)}
          />
          <DeleteButton
            disabled={disabled || disabledDelete}
            onClick={() => handleRemoveClient()}
          />
        </S.WrapperActions>
      </S.Container>
    </>
  );
}
