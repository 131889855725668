import React, { createContext, useContext, useState } from 'react'
import { ProviderProps } from 'hooks'

type ContactContextData = {
  name: string
  phone: string
  handleSetName: (nameInput: string) => void
  handleSetPhone: (phoneInput: string) => void
}

const ContactContext = createContext<ContactContextData>(
  {} as ContactContextData
)

export function useContact(): ContactContextData {
  const context = useContext(ContactContext)

  if (!context) {
    throw Error('useContact must be used within a ContactProvider')
  }

  return context
}

export const ContactProvider = ({ children }: ProviderProps) => {
  const [name, setName] = useState('')
  const [phone, setPhone] = useState('')

  function handleSetName(nameInput: string) {
    setName(nameInput)
  }

  function handleSetPhone(phoneInput: string) {
    setPhone(phoneInput)
  }

  return (
    <ContactContext.Provider
      value={{ name, phone, handleSetName, handleSetPhone }}
    >
      {children}
    </ContactContext.Provider>
  )
}
