import React from "react";

import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";

import { PendingOpportunities } from "./PendingOpportunities";
import { ApprovedOpportunities } from "./ApprovedOpportunities";
import { ArchivedOpportunities } from "./ArchivedOpportunities";

import { ArrowButton } from "components/ArrowButton";

import { useServiceOpportunityTabIndex } from "hooks/opportunityServiceTabIndex";

import * as S from "./styles";

export function Opportunities() {
  const { selectedIndex, handleSetSelectedIndex } =
    useServiceOpportunityTabIndex();

  return (
    <>
      <S.Header>
        <ArrowButton />

        <S.Title>Oportunidade de serviço</S.Title>
      </S.Header>
      <S.Container>
        <S.TabContainer>
          <Tabs
            selectedIndex={selectedIndex}
            onSelect={(index: number) => {
              handleSetSelectedIndex(index);
            }}
          >
            <TabList>
              <Tab>Pendentes</Tab>
              <Tab>Aprovadas</Tab>
              <Tab>Arquivadas</Tab>
            </TabList>

            <TabPanel>
              <PendingOpportunities />
            </TabPanel>

            <TabPanel>
              <ApprovedOpportunities />
            </TabPanel>

            <TabPanel>
              <ArchivedOpportunities />
            </TabPanel>
          </Tabs>
        </S.TabContainer>
      </S.Container>
    </>
  );
}
