import styled, { css } from "styled-components";
import { shade } from "polished";

export const Container = styled.div`
  ${({ theme }) => css`
    width: 52%;
    min-width: 652px;
    height: 100%;
    margin-bottom: 50px;
    padding-left: 30px;
    padding-top: 20px;
    overflow: hidden;

    border-right: 1px solid ${theme.colors.background_light};
  `}
`;

export const TextRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  color: white;
`;

export const Top = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
  `}
`;

export const TitleText = styled.div`
  ${({ theme }) => css`
    margin-top: 10px;
    font-size: ${theme.font.sizes.large};
    color: ${theme.colors.title};
    font-weight: ${theme.font.bold};
  `}
`;
export const ButtonClose = styled.button`
  ${({ theme }) => css`
    width: 35px;
    height: 35px;
    background-color: ${theme.colors.background_light};
    border-radius: 10px;

    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 25px;
  `}
`;

export const Content = styled.div`
  margin-top: 10px;
  width: 90%;
`;

export const WrapperSubtitle = styled.div`
  margin-bottom: 30px;
`;

export const Subtitle = styled.p`
  ${({ theme }) => css`
    font-size: ${theme.font.sizes.small};
    color: ${theme.colors.text};
    line-height: 20px;
  `}
`;

export const WrapperBox = styled.div`
  margin-bottom: 20px;
`;

export const ServiceOrderText = styled.p`
  ${({ theme }) => css`
    font-size: ${theme.font.sizes.small};
    color: ${theme.colors.primary};
    margin-bottom: 8px;
  `}
`;

export const ClientName = styled.p`
  ${({ theme }) => css`
    font-size: ${theme.font.sizes.xsmall};
    color: ${theme.colors.title};
    margin-bottom: 14px;
  `}
`;

export const WrapperInput = styled.div`
  display: flex;
  align-items: flex-end;

  & > div:first-of-type {
    width: 100%;
  }
`;

export const SendedSurvey = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 20px;
`;

export const Wrapper = styled.div`
  ${({ theme }) => css`
    padding: 5px;
    width: 100%;
    background-color: ${theme.colors.background_light};
    border-radius: 10px;
    display: flex;
    align-items: center;
    cursor: pointer;
    margin-top: 20px;
  `}
`;

export const ButtonSaveEmail = styled.button`
  ${({ theme }) => css`
    width: 90px;
    height: 46px;
    background-color: ${theme.colors.primary};
    color: ${theme.colors.background};
    border-radius: 6px;
    margin-left: 16px;

    display: flex;
    justify-content: center;
    align-items: center;

    &:hover {
      background: ${shade(0.2, theme.colors.primary_light)};
    }
  `}
`;

export const WrapperInfo = styled.div`
  ${({ theme }) => css`
    margin-top: 4px !important;
    margin-left: 4px;

    & > span {
      font-size: ${theme.font.sizes.xsmall};
      color: ${theme.colors.text};
    }
  `}
`;

export const TopText = styled.div`
  ${({ theme }) => css`
    font-size: ${theme.font.sizes.small};
    color: ${theme.colors.primary};
  `}
`;

export const WrapperButton = styled.div``;
