import { ToggleButton } from "components/CardCheckRegister/styles";
import styled, { css } from "styled-components";

export const ModalContent = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  padding: 20px;
`;

export const RadarIcon = styled.img`
  width: 293px;
  height: 293px;
  align-self: center;
`;

export const Loading = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 300px;
  img {
    height: 35%;
    width: 35%;
  }
`;

export const TextContainer = styled.div`
  margin-top: 70px;
  color: white;
  margin-right: 50px;
`;

export const BolderText = styled.div`
  font-size: 18px;
  line-height: 22px;
  font-weight: 700;
`;

export const BackButton = styled.button`
  ${({ theme }) => css`
    width: 35px;
    height: 35px;
    background-color: ${theme.colors.background_light};
    border-radius: 10px;

    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 25px;
    margin-bottom: 20px;
  `}
`;

export const MettersContainer = styled.div`
  display: flex;
  justify-self: center;
  align-self: center;
  justify-content: center;
  align-items: center;
  margin-bottom: 30px;
  margin-top: 10px;
`;

export const Text = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  margin-top: 10px;
`;

export const ExpertiseAreaContainer = styled.div`
  max-height: 35vh;
  overflow-y: scroll;
`;

export const ExpertiseByLetter = styled.div``;

export const Divider = styled.div`
  border-bottom: 0.5px solid rgba(255, 255, 255, 0.05);
  margin-top: 10px;
  margin-bottom: 5px;
`;

export const ExpertiseContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 10px;
`;

export const Checkbox = styled(ToggleButton)`
  border-radius: 5px;
  width: 20px;
  height: 20px;
  background: transparent;
  border: 1px solid rgba(255, 255, 255, 0.87);
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Check = styled.div`
  ${({ theme }) => css`
    width: 10px;
    height: 10px;
    background: ${theme.colors.primary};
    border-radius: 3px;
  `};
`;

export const WrapperInput = styled.div`
  margin-bottom: 15px;
`;

export const DoubleWrapperInput = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

export const Marker = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 5px;
`;

export const MarkerLine = styled.div`
  border: 0.3px solid #353535;
  height: 5px;
  width: 0;
  margin-bottom: 5px;
`;
