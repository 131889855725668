import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import { ArrowButton } from "components/ArrowButton";
import { SearchComercialModelsManual } from "components/SearchComercialModelsManual";
import { ModalRejectedRegistration } from "components/ModalRejectedRegistration";

import { api } from "services/api";

import { useToast } from "hooks/toast";
import { useAuth } from "hooks/auth";

import { ManualsShimmer } from "Shimmer/ManualsShimmer";

import * as S from "./styles";

export type Brand = {
  id: string;
  image: string;
  name: string;
  items: string;
};

export function Manuals() {
  const history = useHistory();
  const { addToast } = useToast();
  const { user } = useAuth();

  const [isLoading, setIsLoading] = useState(true);
  const [brands, setBrands] = useState<Brand[]>([]);
  const [activeModalRejectedRegistration, setActiveModalRejectedRegistration] =
    useState(false);

  useEffect(() => {
    async function loadBrands() {
      try {
        const { data } = await api.get("/brands");

        const brandsData = data.brands.map((brand: Brand) => ({
          id: brand.id,
          image: brand.image,
          name: brand.name,
          items: brand.items.toString(),
        }));

        setBrands(brandsData);

        if (user?.profileDisapproved) {
          setActiveModalRejectedRegistration(true);
        }
      } catch (error) {
        // sendError(error)
        addToast({
          title: "Ops!!",
          description: "Ocorreu um erro ao listar as marcas",
          type: "error",
        });
      } finally {
        setIsLoading(false);
      }
    }
    loadBrands();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addToast]);

  function handleShowManualDetail(brandId: string) {
    history.push(`/brands/${brandId}/equipments`);
  }

  return (
    <S.Container>
      <ModalRejectedRegistration
        isVisible={activeModalRejectedRegistration}
        setIsActive={setActiveModalRejectedRegistration}
      />
      <header>
        <ArrowButton />

        <h2>Manuais</h2>

        <SearchComercialModelsManual description="Ou selecione uma marca para ver os modelos." />
      </header>

      <S.Content>
        {isLoading && <ManualsShimmer />}

        <>
          {!isLoading &&
            brands.map((brand) => (
              <S.Manual
                key={brand.id}
                onClick={() => {
                  handleShowManualDetail(brand.id);
                }}
              >
                <div>
                  <img src={brand.image} alt={`imagem da logo ${brand.name}`} />
                </div>

                <p>{brand.name}</p>
              </S.Manual>
            ))}
        </>
      </S.Content>
    </S.Container>
  );
}
