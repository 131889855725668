import styled from "styled-components";

export const IconWrapper = styled.div`
  position: absolute;
  right: 15px;

  img {
    width: 15px;
    height: 18px;
  }
`;
