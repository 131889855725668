import React from "react";

import { useHistory } from "react-router";

import * as S from "./styles";

import arrowLeft from "assets/icons/arrow-left.svg";

type ArrowButtonProps = {
  handleFunction?: () => void;
};

export function ArrowButton({ handleFunction }: ArrowButtonProps) {
  const history = useHistory();

  function handleNavigate() {
    history.goBack();
  }

  return (
    <S.Container
      onClick={() => {
        handleFunction ? handleFunction() : handleNavigate();
      }}
    >
      <img src={arrowLeft} alt="flecha pra esquerda" />
    </S.Container>
  );
}
