import { Button } from "components/Button";
import { Input } from "components/Input";
import React, { useState } from "react";
import { FiChevronLeft } from "react-icons/fi";
import * as Yup from "yup";

import { useTheme } from "styled-components";

import * as S from "./styles";
import { useToast } from "hooks/toast";
import { EnvironmentDTO } from "dtos/PmocEnvironmentDTO";
import { api } from "services/api";
import { useEnvironment } from "hooks/pmoc/environmentPmoc";
import getValidationErrors from "utils/getValidationErrors";
import { ModalPageProps } from "components/ModalPageClients/types";
import { EnvironmentPageProps } from "../EnvironmentFlow/types";
import { useEditClient } from "hooks/editClient";
import { UnityDTO } from "dtos/ClientDTO";

import axios from "axios";

type Errors = {
  [key: string]: string;
};

type PageProps = {
  handleChooseEditModal?: ModalPageProps;
  handleChooseEnvironment?: EnvironmentPageProps;
};

type ResponseUnityProps = {
  data: UnityDTO;
};

export function CreateEnvironment({
  handleChooseEditModal,
  handleChooseEnvironment,
}: PageProps) {
  const theme = useTheme();
  const { addToast } = useToast();

  const { userIdPMOC, unityIdPMOC, nameFlow, handleSetEnvironmentId } =
    useEnvironment();
  const { setSelectedUnity } = useEditClient();

  const [nameEnvironment, setNameEnvironment] = useState<string>("");
  const [environmentLocation, setEnvironmentLocation] = useState<string>("");
  const [fixedNumber, setFixedNumber] = useState<string>("");
  const [variableNumber, setVariableNumber] = useState<string>("");
  const [airConditioned, setAirConditioned] = useState<string>("");
  const [thermal, setThermal] = useState<string>("");
  const [loadingButton, setLoadingButton] = useState(false);

  const [hasError, setHasError] = useState<Errors>({} as Errors);

  function handleBackButton() {
    if (nameFlow === "unity" && handleChooseEditModal) {
      return handleChooseEditModal("unityDetail");
    }

    if (nameFlow === "pmoc" && handleChooseEnvironment) {
      return handleChooseEnvironment("listEnvironment");
    }
  }

  async function handleCreate() {
    try {
      setLoadingButton(true);

      if (nameEnvironment.trim().length < 1) {
        setHasError((errors) => ({
          ...errors,
          nameEnvironment: "Nome é obrigatório.",
        }));
        addToast({
          title: "Ops!!",
          description: "Os campos obrigatórios devem ser preenchidos.",
          type: "error",
        });
        return;
      }

      setHasError({});

      let obj: EnvironmentDTO = {
        name: nameEnvironment.trim(),
        location: environmentLocation.trim(),
        numberOccupants: {
          fixed: fixedNumber.trim(),
          variable: variableNumber.trim(),
        },
        airConditionedArea: airConditioned.trim(),
        thermalLoad: thermal.trim(),
      };

      if (!environmentLocation) {
        delete obj.location;
      }

      if (!fixedNumber && !variableNumber) {
        delete obj.numberOccupants;
      }

      if (!fixedNumber) {
        delete obj.numberOccupants?.fixed;
      }

      if (!variableNumber) {
        delete obj.numberOccupants?.variable;
      }

      if (!thermal) {
        delete obj.thermalLoad;
      }

      if (!airConditioned) {
        delete obj.airConditionedArea;
      }

      const response = await api.post(
        `/client/${userIdPMOC}/unities/${unityIdPMOC}/environment`,
        obj
      );

      addToast({
        title: "Tudo Certo!",
        description: "Um novo ambiente foi criado com sucesso",
        type: "success",
      });

      //mandar para o fluxo de unidades...
      if (nameFlow === "unity" && handleChooseEditModal) {
        //atualizar o contexto da unidade...
        const { data }: ResponseUnityProps = await api.get(
          `/clients/${userIdPMOC}/unities/${unityIdPMOC}`
        );
        setSelectedUnity(data);

        return handleChooseEditModal("unityDetail");
      }

      //mandar para o fluxo de pmoc
      if (nameFlow === "pmoc" && handleChooseEnvironment) {
        handleSetEnvironmentId(Number(response.data.id));
        return handleChooseEnvironment("detailEnvironment");
      }
    } catch (error) {
      if (error instanceof Yup.ValidationError) {
        const errors = getValidationErrors(error);
        setHasError(errors);

        addToast({
          title: "Ops!!",
          description: "Os campos obrigatórios devem ser preenchidos.",
          type: "error",
        });
        return;
      }

      addToast({
        title: "Erro",
        description:
          axios.isAxiosError(error) && error.response?.data.error
            ? error.response.data.error
            : "Não foi possível criar o ambiente.",
        type: "error",
      });
    } finally {
      setLoadingButton(false);
    }
  }

  return (
    <S.Container>
      <S.BackButton onClick={() => handleBackButton()}>
        <FiChevronLeft size={16} color={theme.colors.text} />
      </S.BackButton>
      <S.Top>
        <p>Novo ambiente</p>
      </S.Top>

      <S.WrapperInput>
        <Input
          name="Nome do ambiente*"
          placeholder="Ex.: Consultório, recepção."
          value={nameEnvironment}
          onChange={(e) => setNameEnvironment(e.target.value.trimStart())}
          hasError={hasError.nameEnvironment}
          onFocusClearError={() =>
            setHasError({ ...hasError, nameEnvironment: "" })
          }
        />
      </S.WrapperInput>

      <S.WrapperInput>
        <Input
          name="Localização do ambiente no prédio"
          placeholder="Ex.: Térreo, 1º andar, cobertura."
          value={environmentLocation}
          onChange={(e) => setEnvironmentLocation(e.target.value.trimStart())}
        />
      </S.WrapperInput>

      <S.WrapperInput>
        <S.SubTitle>Número de ocupantes do ambiente</S.SubTitle>
        <S.WrapperDualInput>
          <S.WrapperInputSmall>
            <Input
              type="number"
              name="Fixos"
              placeholder="Ex.: 8."
              value={fixedNumber}
              onChange={(e) => setFixedNumber(e.target.value)}
            />
          </S.WrapperInputSmall>
          <S.WrapperInputSmall>
            <Input
              type="number"
              name="Variáveis"
              placeholder="Ex.: 8."
              value={variableNumber}
              onChange={(e) => setVariableNumber(e.target.value)}
            />
          </S.WrapperInputSmall>
        </S.WrapperDualInput>
      </S.WrapperInput>

      <S.WrapperInput>
        <Input
          type="number"
          name="Área climatizada (m²)"
          placeholder="Ex.: 10m²"
          value={airConditioned}
          onChange={(e) => setAirConditioned(e.target.value)}
        />
      </S.WrapperInput>

      <S.WrapperInput>
        <Input
          name="Carga térmica do ambiente"
          placeholder="Ex.: 9000 BTU's."
          value={thermal}
          onChange={(e) => setThermal(e.target.value.trimStart())}
        />
      </S.WrapperInput>

      <S.WrapperInput>
        <Button
          typeButton="default"
          onClick={handleCreate}
          loading={loadingButton}
          disabled={loadingButton}
        >
          Criar ambiente
        </Button>
      </S.WrapperInput>
    </S.Container>
  );
}
