import { shade } from "polished";
import styled, { css } from "styled-components";

type CheckboxProps = {
  isSelected: boolean;
};

export const Container = styled.div`
  ${({ theme }) => css`
    width: 100%;
    background-color: ${theme.colors.background_light};
    border-radius: 10px;
    padding: 10px;
  `}
`;

export const CheckArea = styled.button`
  padding-left: 8px;
  height: 30px;
  /* background-color: red; */

  display: flex;
  align-items: center;
`;

export const Checkbox = styled.div<CheckboxProps>`
  ${({ theme, isSelected }) => css`
    width: 15px;
    height: 15px;
    margin-right: 15px;
    border: 1px solid ${theme.colors.text};
    border-radius: 5px;

    display: flex;
    justify-content: center;
    align-items: center;

    & > div {
      width: 7px;
      height: 7px;
      background-color: ${isSelected ? theme.colors.primary : "transparent"};
      border-radius: 2px;
    }
  `}
`;

export const PaymentText = styled.span`
  ${({ theme }) => css`
    font-size: ${theme.font.sizes.xsmall};
    color: ${theme.colors.text};
  `}
`;

export const WrapperPayment = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  margin-top: 15px;
`;

export const Input = styled.input.attrs({
  type: "number",
})`
  ${({ theme }) => css`
    height: 31px;
    width: 60px;
    outline: none;
    margin-left: 15px;
    margin-right: 6px;

    text-align: center;
    color: ${theme.colors.text_extra_light};
    font-size: ${theme.font.sizes.xsmall};

    border: 1px solid #707070;
    border-radius: 8px;
    background-color: transparent;

    &:focus-within {
      border: 1px solid ${theme.colors.primary};
    }

    &[type="number"] {
      -moz-appearance: textfield;
    }

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
    }
  `}
`;

export const ToolTip = styled.div`
  ${({ theme }) => css`
    position: absolute;
    top: -103px;
    left: 21%;
    z-index: ${theme.layers.alwaysOnTop};
    border-radius: 8px;
    width: 116px;

    background-color: ${theme.colors.edit_area};

    & > button {
      width: 100%;
      height: 50%;
      padding: 8px 20px;
      border-radius: 8px;
      color: ${theme.colors.title};
      transition: background 0.2s;
    }

    & > button:hover {
      background: ${shade(0.1, theme.colors.edit_area)};
    }

    & > div {
      width: 100%;
      height: 1px;
      background-color: #707070;
      opacity: 0.5;
    }
  `}
`;
