import styled, { css } from "styled-components";

export const Header = styled.div`
  ${({ theme }) => css`
    margin-bottom: 30px;

    h4 {
      font-size: ${theme.font.sizes.large};
      color: ${theme.colors.title};
      font-weight: ${theme.font.bold};
    }
  `}
`;

export const BackButton = styled.button`
  ${({ theme }) => css`
    width: 35px;
    height: 35px;
    background-color: ${theme.colors.background_light};
    border-radius: 10px;

    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 25px;
  `}
`;

export const WrapperServiceName = styled.div`
  margin: 35px 0;
`;

export const CardText = styled.span`
  ${({ theme }) => css`
    display: block;
    font-size: ${theme.font.sizes.xsmall};
    color: ${theme.colors.text_light_87};
    margin-bottom: 8px;
  `}
`;
export const Card = styled.div`
  ${({ theme }) => css`
    width: 100%;
    min-height: 45px;
    background-color: ${theme.colors.detail_light};
    border-radius: 10px;
    padding: 13px 15px;

    & > span {
      font-size: ${theme.font.sizes.xsmall};
      color: ${theme.colors.text_light_60};
    }
  `}
`;

export const ContentReport = styled.div`
  ${({ theme }) => css`
    width: 100%;
    height: 560px;
    padding: 15px;
    border-radius: 10px;
    background-color: ${theme.colors.menu_detail};
    overflow-y: scroll;

    &::-webkit-scrollbar {
      width: 8px;
      height: 8px;
    }

    &::-webkit-scrollbar-thumb {
      background: ${theme.colors.line};
      border-radius: 10px;
    }

    &::-webkit-scrollbar-track {
      background: transparent;
    }

    & > pre {
      margin-right: 16px;
      white-space: pre-wrap;
      font-size: ${theme.font.sizes.xsmall};
      color: ${theme.colors.text_light_87};
    }
  `}
`;
