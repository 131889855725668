import React, { ButtonHTMLAttributes } from 'react'

import * as S from './styles'

type AddClientButtonProps = {
  label: string
  placeholder: string
  isSelected?: boolean
  hasError?: string
} & ButtonHTMLAttributes<HTMLButtonElement>

export function ButtonModalAction({
  label,
  placeholder,
  isSelected = false,
  hasError = '',
  ...rest
}: AddClientButtonProps) {
  return (
    <S.Container>
      {!!label && <p>{label}</p>}
      <S.Button
        isSelected={isSelected}
        hasError={!!hasError}
        {...rest}>
        {placeholder}
      </S.Button>

      {!!hasError && <S.ErrorText>{hasError}</S.ErrorText>}
    </S.Container>
  )
}
