import { shade } from 'polished'
import styled, { css } from 'styled-components'

export const Label = styled.p`
  ${({ theme }) => css`
    font-size: ${theme.font.sizes.xsmall};
    margin-bottom: 5px;
    text-transform: capitalize;
    color: ${theme.colors.title};
  `}
`

export const Container = styled.div`
  ${({ theme }) => css`
    width: 100%;
    min-height: 85px;
    background-color: ${theme.colors.background_light};
    border-radius: 10px;

    display: flex;
    justify-content: space-between;
  `}
`

export const Content = styled.div`
  ${({ theme }) => css`
    padding: 15px;

    p {
      font-size: ${theme.font.sizes.xsmall};
      margin-bottom: 4px;
      color: ${theme.colors.title};
    }

    p + p {
      font-size: ${theme.font.sizes.xsmall};
      margin-bottom: 4px;
      color: ${theme.colors.text};
    }
  `}
`

export const WrapperAddress = styled.div`
  margin-top: 10px;
`

export const AddressMessage = styled.span`
  ${({ theme }) => css`
    display: block;
    margin-bottom: 2px;
    font-size: ${theme.font.sizes.xsmall};
    color: ${theme.colors.text};
    font-family: ${theme.font.family};
  `}
`

export const RegisterAddress = styled.button`
  ${({ theme, disabled }) => css`
    font-size: ${theme.font.sizes.xsmall};
    color: ${disabled ? theme.colors.text_light : theme.colors.primary};
    font-family: ${theme.font.family};
    text-decoration: underline;

    &:hover {
      cursor: ${disabled ? 'not-allowed' : 'pointer'};
      color: ${!disabled && shade(0.2, theme.colors.primary)};
    }
  `}
`

export const WrapperActions = styled.div`
  ${({ theme }) => css`
    min-width: 47px;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    background-color: ${theme.colors.background_light};

    display: flex;
    flex-direction: column;
    justify-content: space-between;
  `}
`
