import React from "react";

import { ModalRight } from "components/ModalRight";
import { CalendarToScheduleService } from "components/CalendarToScheduleService";

import CalendarSvg from "assets/schedule.svg";
import CalendarDisabledSvg from "assets/schedule-disabled.svg";

import * as S from "./styles";

type ScheduleProps = {
  date: string;
  time: string;
};

type CalendarButtonProps = {
  openModalCalendar: boolean;
  currentScheduleDate?: string;
  onToggleModalCalendar: () => void;
  handleSelectedDate: (schedule: ScheduleProps) => void;
  disabled?: boolean;
  onDeleteServiceSchedule: () => void;
};

export function CalendarButton({
  openModalCalendar,
  onToggleModalCalendar,
  currentScheduleDate = "",
  handleSelectedDate,
  disabled = false,
  onDeleteServiceSchedule,
}: CalendarButtonProps) {
  return (
    <>
      <ModalRight
        isOpen={openModalCalendar}
        handleToggleOpen={onToggleModalCalendar}
      >
        <CalendarToScheduleService
          currentScheduleDate={currentScheduleDate}
          handleConfirmDateTime={handleSelectedDate}
          handleCancel={onToggleModalCalendar}
          onDeleteServiceSchedule={onDeleteServiceSchedule}
        />
      </ModalRight>

      <S.ButtonCalendar disabled={disabled} onClick={onToggleModalCalendar}>
        {!disabled && (
          <img
            src={CalendarSvg}
            alt="mostra calendário para agendar o serviço"
          />
        )}
        {disabled && (
          <img
            src={CalendarDisabledSvg}
            alt="mostra calendário para agendar o serviço"
          />
        )}
      </S.ButtonCalendar>
    </>
  );
}
