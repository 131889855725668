import React from "react";
import { MdClose } from "react-icons/md";
import { useTheme } from "styled-components";

import * as S from "./styles";

type ModalPolicyAndTermOfUseProps = {
  isVisible: boolean;
  policyOrTermOfUse: "policy" | "termOfUse";
  onCloseModal: () => void;
};

export function ModalPolicyAndTermOfUse({
  isVisible,
  policyOrTermOfUse,
  onCloseModal,
}: ModalPolicyAndTermOfUseProps) {
  const theme = useTheme();

  return (
    <S.ContainerOverlay isVisible={isVisible}>
      <S.BoxModal>
        <S.Header>
          <S.Title>
            {policyOrTermOfUse === "policy"
              ? "Políticas de privacidade"
              : "Termos de uso"}
          </S.Title>
          <button type="button" onClick={() => onCloseModal()}>
            <MdClose size={24} color={theme.colors.primary} />
          </button>
        </S.Header>

        <S.Main>
          {policyOrTermOfUse === "policy" ? (
            <S.Description>
              <h3>1. Objetivo</h3>
              <br /> <br />
              <p>
                Esse documento de Política de Privacidade tem como objetivo
                apresentar aos nossos usuários, de maneira simples e acessível,
                a maneira como a Profiz cuida da relação à privacidade e
                proteção de dados pessoais de nossos usuários.
                <br /> <br />A Profiz tem o compromisso de cuidar de seus dados
                com segurança e honestidade, seguindo a Lei Geral de Proteção de
                Dados (LGPD).
              </p>
              <br /> <br />
              <h3>2. Quais dados utilizamos e como coletamos?</h3>
              <br /> <br />
              <p>
                Para que a gente consiga prestar alguns serviços, é necessário
                que tenhamos seus dados pessoais, como o seu nome, endereço,
                telefone, profissão, idade, geolocalização, número de IP e
                outros. <br /> <br /> Lembrando que você sempre pode se recusar
                a fornecer seus dados pessoais, porém, sem eles não conseguimos
                te oferecer nossos serviços.
              </p>
              <br /> <br />
              <strong>Tipo de dados que coletamos</strong>
              <br />
              <p>
                <strong>
                  Dados pessoais fornecidos diretamente por você:{" "}
                </strong>
                informações inseridas ou encaminhadas ao acesso ao app Profiz,
                shopping virtual da Profiz ou em solicitações feitas em nosso
                atendimento.
              </p>
              <br />
              <p>
                <strong>Dados pessoais fornecidos por terceiros: </strong>a
                Profiz pode receber seus dados através de terceiros, por via de
                nossos parceiros, prestadores de serviços, ou por aqueles que
                tenham algum relacionamento com você.
              </p>
              <br />
              <p>
                <strong>Dados pessoais coletados automaticamente: </strong>
                também coletamos alguns dados de forma automática, que são
                chamados de informações navegacionais, como cookies e seu
                endereço de IP.
              </p>
              <br /> <br />
              <h3>3. Por que coletamos?</h3>
              <br /> <br />
              <p>Para contratação e execução de serviços;</p>
              <p>
                Avaliação de desempenho de nossos serviços, pesquisa e ofertas
                de produtos;
              </p>
              <p>Segurança e prevenção de fraudes;</p>
              <p>Para o cumprimento de obrigação legal ou regulatória;</p>
              <p>
                Para o exercício regular de direitos em processo judicial,
                administrativo ou arbitral;
              </p>
              <p>
                Comunicação com os clientes e usuários, informar novos eventos e
                envio de mensagens publicitárias.
              </p>
              <br /> <br />
              <h3>4. Cancelamento e exclusão de dados</h3>
              <br /> <br />
              <p>
                Você pode sempre optar por não receber mais qualquer e-mail da
                Profiz, também podendo buscar pelo campo “Descadastrar-se” para
                que a atividade seja encerrada da organização. <br /> <br />
                Os dados coletados serão tratados sem uma data determinada, e
                para alterar suas informações pessoais ou excluí-las do nosso
                banco de dados, deverá pedir uma solicitação através do e-mail:
                juridico@profiz.com.br.
              </p>
              <br /> <br />
              <h3>5. As informações serão compartilhadas?</h3>
              <br /> <br />
              <p>
                A Profiz apenas compartilha seus dados quando é necessário ou
                pertinente para a execução de nossos serviços, como nas
                seguintes hipóteses abaixo.
              </p>
              <br />
              <strong>Prestadores de serviço</strong>
              <br />
              <p>
                Suas informações compartilhadas com os prestadores de serviços
                são apenas as necessárias, e eles são obrigados a não
                compartilhá-las, comercializar, armazenar ou utilizar para
                qualquer outro fim.
              </p>
              <br /> <br />
              <strong>Obrigação legal ou regulatória</strong>
              <br />
              <p>
                A Profiz pode ser solicitada a compartilhar seus dados pessoais
                em atendimento à ordem judicial, solicitações de autoridades
                administrativas, ao cumprimento de obrigação legal ou
                regulatória, assim como atuar de forma colaborativa em
                investigações de prevenção e combate a atos ilícitos.
              </p>
              <br />
              <strong>Mediante a sua solicitação</strong>
              <br />
              <p>
                A Profiz pode compartilhar os seus dados caso você solicite para
                links e sites terceiros. Nesse caso, os seus dados serão
                tratados de acordo com a Política de Privacidade desses
                terceiros e a Profiz não pode controlar ou se responsabilizar
                por essas práticas. <br /> <br />
                Por isso aconselhamos a ler o regulamento da Política de
                Privacidade antes.
              </p>
              <br /> <br />
              <h3>6. Direitos do Usuário</h3>
              <br /> <br />
              <p>
                Como titular de seus dados, você pode exercer diversos direitos,
                como consta no artigo 18 da Lei Geral de Proteção de Dados
                (LGPD), tais como:
                <br /> <br />
                acesso, confirmação da existência de tratamento de informações
                sobre compartilhamento;
                <br />
                corrigir ou solicitar a correção de Dados Pessoais incompletos,
                inexatos ou desatualizados;
                <br />
                solicitar o anonimato, bloqueio ou eliminação de dados pessoais
                desnecessários e excessivos ou tratados em desconformidade com a
                LGPD;
                <br />
                solicitar a portabilidade dos dados pessoais a outro fornecedor
                ou de serviços similares;
                <br />
                peticionar em relação aos seus dados perante à Autoridade
                Nacional de Proteção de Dados;
                <br />
                solicitar a eliminação dos dados pessoais coletados ou
                utilizados com base no seu consentimento;
                <br />
                obter informações sobre as entidades públicas ou privadas com as
                quais compartilhamos os seus dados pessoais;
                <br />
                quando a atividade de tratamento necessitar do seu
                consentimento, você pode recusá-la, assim como saber quais as
                consequências de você não fornecer o seu consentimento nesse
                caso;
                <br />
                quando a atividade de tratamento necessitar do seu
                consentimento, a qualquer momento você pode revogá-la.
              </p>
              <br /> <br />
              <h3>7. Segurança das informações</h3>
              <br /> <br />
              <p>
                A Profiz usa de vários artifícios para manter a privacidade de
                dados pessoais de seus usuários armazenados em sua base,
                procurando manter um ambiente seguro, com o uso das ferramentas
                abaixo.
              </p>
              <br />
              <strong>Criptografia</strong>
              <br />
              <p>
                Esse é um processo de codificação de dados e informações, ou
                seja, os dados são “embaralhados” na origem e decodificados para
                dificultar uma decifração pela internet.
              </p>
              <br />
              <strong>Firewall</strong>
              <br />
              <p>
                É o nome dado a um dispositivo físico e/ou lógico de uma rede de
                computadores, com o objetivo de aplicar uma política de
                segurança a um determinado ponto de controle da rede.
                Basicamente a sua função é administrar o tráfego de dados entre
                redes diferentes e impedir o compartilhamento e/ou recepção de
                acessos nocivos ou não autorizados de uma rede para a outra.
              </p>
              <br />
              <strong>Sistema de Transações na Internet</strong>
              <br />
              <p>
                É um sistema com validação da Verisign, esse programa permite
                que a cada acesso feito na rede da Profiz, uma nova chave de
                criptografia é criada entre o computador do usuário e o de nosso
                portal, impossibilitando que outras pessoas interceptem a
                transação através de programas.
              </p>
              <br />
              <strong>Tecnologia SSL EV</strong>
              <br />
              <p>
                Essa tecnologia é em especial usada no momento final de suas
                compras, para que as suas informações passem por um processo de
                encriptação com tecnologia SSL EV (Secure Socket Loker Extend
                Validation) para que nenhuma outra pessoa tenha acesso a elas
                além da Profiz.
              </p>
              <br /> <br />
              <h3>8. Utilizamos cookies</h3>
              <br /> <br />
              <p>
                Cookies são uma espécie de arquivo residuais que permitem os
                usuários a visitar praticamente todos os sites, eles servem para
                personalizar a experiência do usuário e identificar a pessoa que
                está acessando aquele conteúdo. Os cookies não armazenam nenhuma
                informação pessoal, apenas contêm os dados de login da pessoa.
              </p>
              <br />
              <strong>Por que é necessário?</strong>
              <br />
              <p>
                É essencial para que nossos visitantes naveguem em nosso site,
                não é possível desabilitar e nem se opor ao tratamento.
              </p>
              <br />
              <strong>Desempenho dos Cookies</strong>
              <br />
              <p>
                São utilizados para compreender como os visitantes interagem com
                nossos sites, coletando informações com o tempo de visita.
              </p>
              <br />
              <strong>Funcionalidades</strong>
              <br />
              <p>
                Os Cookies permitem que os usuários tenham uma experiência
                personalizada aos usuários, pois lembram das escolhas feitas
                pelo mesmo.
              </p>
              <br />
              <strong>Marketing</strong>
              <br />
              <p>Utilizado para promover conteúdos de interesse do usuário.</p>
              <br /> <br />
              <h3>9. Contato</h3>
              <br /> <br />
              <p>
                Caso tenha ficado qualquer dúvida sobre essa política, sobre o
                tratamento dos seus dados pessoais ou ainda, caso queira exercer
                seus direitos, você pode nos contactar através do e-mail:
                juridico@profiz.com.br.
              </p>
            </S.Description>
          ) : (
            <S.Description>
              <h3>
                <u>TERMOS DE USO DA PLATAFORMA PROFIZ</u>
              </h3>
              <br />
              <p>
                Este termo de uso de plataforma (“Termo de Uso” ou "Contrato")
                regulamenta a utilização da plataforma nos sites que integram a
                “Profiz”, disponibilizados pela{" "}
                <strong>
                  <u>PROFIZ TECNOLOGIA E SERVICOS LTDA</u>
                </strong>
                , empresa com sede em Birigui/SP, na Rua Nilo Peçanha, nº 507,
                salas 09 e 10, CEP 16.200-065, inscrita no CNPJ/MF sob o nº
                46.536.392/0001-09, doravante denominada simplesmente "Profiz".
                No ato de adesão à Plataforma, o usuário (doravante “Usuário” ou
                “Você” ou "Profissional Prestador de Serviços") se obriga a
                aceitar, plenamente e sem reservas, todos os termos e condições
                deste Termo de Uso. Você declara que está autorizado a aceitar
                este Termo de Uso em seu nome e em nome de sua empresa e todas
                as referências a "você" ou "Profissional Prestador de Serviços"
                fazem referência a você ou sua empresa.
                <br /> <br />
                <strong>
                  Você reconhece ainda que analisou e aceitou as condições de
                  uso. Leia-as atentamente pois o uso deste aplicativo significa
                  que você aceitou todos os termos e concorda em cumpri-los. Se
                  você, usuário, for menor de idade ou declarado incapaz em
                  quaisquer aspectos, precisará da permissão de seus pais ou
                  responsáveis que também deverão concordar com estes mesmos
                  termos e condições.
                </strong>
                <br /> <br />
                Este contrato de uso permite que o Profissional Prestador de
                Serviços adquira as funcionalidades disponíveis na aplicação, de
                acordo com qualquer cadastro na plataforma Profiz, registro
                on-line, descrições de inscrição ou confirmações de inscrição a
                este Contrato ("Formulário(s) ").
              </p>
              <br /> <br />
              <h3>DO OBJETO</h3>
              <br /> <br />
              <p>
                O presente contrato tem por objeto a prestação de serviços e a
                licença de uso de software, pela Profiz em favor do Profissional
                Prestador de Serviços, em caráter (i) pessoal, (ii)
                intransferível, (iii) não-exclusivo e (iv) provisório. A licença
                compreende as seguintes tecnologias:
                <br /> <br />
                Software(s): programação lógica intangível desenvolvida pela
                Profiz, capaz de operacionalizar as atividades desenvolvidas por
                você no uso do aplicativo disponibilizados pela Profiz;
                <br /> <br />
                “Plataforma Web”: Sítio hospedado na rede mundial de
                computadores (“internet”), de titularidade exclusiva da Profiz,
                cujo acesso é disponibilizado em favor a você por meio de contas
                (“login” ou “logins”) e senhas de acesso, cadastradas na base de
                dados da Profiz. Não é permitido o compartilhamento de conta e
                nem acesso simultâneo em dois ou mais dispositivos. As
                funcionalidades disponíveis são voltadas para gestão de serviços
                em campo, revenda de produtos (marketplace) e maquininha de
                cartões.
                <br /> <br />
                Aplicativo ou “app”: Aplicação de internet ou para smartphone,
                passível de ser transferido a um ou mais dispositivos de
                hardware para execução dos softwares desenvolvidos pela Profiz,
                com funcionalidades sobrepostas e complementares à “Plataforma
                Web”.
                <br /> <br />A Profiz disponibiliza a estrutura necessária para
                que o Parceiro ofereça seus produtos por meio de aplicativo
                registrado sob o domínio de propriedade da Profiz (“App”), sem
                participar do controle de estoque, produção e qualidade, não
                sendo, portanto, fornecedora de quaisquer produtos anunciados
                por seus Parceiros.
                <br /> <br />A contratação dos serviços objeto do presente
                contrato é realizada de forma gratuita para os usuários finais e
                para os usuários parceiros conforme orçamento acordado,
                declarando expressamente que você, neste ato, tem ciência de que
                os serviços ora contratados são exatamente aqueles previstos
                neste instrumento, não estando a Profiz obrigada a fornecer
                qualquer nova funcionalidade, recurso ou customização que venha
                a ser incorporada aos Softwares/Plataforma licenciados,
                posteriormente à data de celebração deste Contrato.
                <br /> <br />A prestação dos serviços neste contrato por parte
                da Profiz restringe-se ao serviço de suporte técnico, sendo
                obrigação da Profiz somente manter o software funcionando
                adequadamente, observado o disposto nas Cláusulas 4. O uso do
                software é licenciado, devendo ser utilizado por você sob sua
                condução e responsabilidade. A Profiz não possui qualquer
                ingerência na Plataforma e APP, funcionando o software a partir
                de comandos pré-programados e a partir das informações e
                comandos inseridos pelo próprio usuário.
                <br /> <br />
                Não compreende o objeto do presente contrato:
                <br />
                <ol type="1" style={{ marginLeft: 18 }}>
                  <li>
                    A cessão ou licenciamento de propriedade intelectual,
                    direitos patrimoniais e morais sobre os
                    Softwares/Plataforma, a qualquer título, mas tão-somente a
                    licença de uso;
                  </li>
                  <li>
                    A cessão ou licença sobre direitos de propriedade industrial
                    sobre marcas e elementos gráficos, etc.;
                  </li>
                  <li>
                    A outorga de direito a sublicenciamento, engenharia reversa,
                    adaptação, tradução do código-fonte;
                  </li>
                  <li>
                    A disponibilização dos programas e equipamentos necessários
                    para o acesso aos Softwares/Plataforma;
                  </li>
                  <li>
                    A instalação do software e o treinamento de presencial de
                    usuários, operadores e prepostos seus para sua utilização;
                  </li>
                  <li>
                    A intermediação e operacionalização de serviços entre
                    Profissional Prestador de Serviços e seus clientes.
                  </li>
                </ol>
              </p>
              <br /> <br />
              <h3>2. DO PRAZO, ALTERAÇÕES, MODIFICAÇÕES E RESCISÃO</h3>
              <br /> <br />
              <p>
                Este TERMO DE USO DA PLATAFORMA PROFIZ vigerá por prazo
                indeterminado, a partir do Aceite do Usuário, podendo ser
                modificados ou rescindidos unilateralmente por qualquer das
                partes a qualquer tempo, sem qualquer ônus, mediante simples
                comunicação por meio da própria plataforma Profiz.
                <br /> <br />A Profiz reserva-se no direito de, a qualquer
                tempo, modificar estes termos seja incluindo, removendo ou
                alterando quaisquer de suas cláusulas. Tais modificações terão
                efeito imediato. Após publicadas tais alterações, comunicará
                através de alertas dentro da própria Plataforma, aonde o USUÁRIO
                dará um novo aceite em caso de concordância.
                <br /> <br />A Profiz pode, de tempos em tempos, modificar ou
                descontinuar (temporária ou permanentemente) a distribuição ou a
                atualização deste aplicativo.
                <br /> <br />A Profiz não é obrigada a fornecer nenhum serviço
                de suporte para este aplicativo. O usuário não poderá
                responsabilizar a Profiz nem seus diretores, executivos,
                funcionários, afiliados, agentes, contratados ou licenciadores
                por quaisquer modificações, suspensões ou descontinuidade do
                aplicativo.
                <br /> <br />A Profiz poderá, sem prévio aviso, bloquear e
                cancelar o acesso ao aplicativo quando verificar que o Usuário
                praticou algum ato ou mantenha conduta que (i) viole as leis e
                regulamentos federais, estaduais e/ou municipais, (ii) contrarie
                as regras destes Termos de Uso, ou (iii) viole os princípios da
                moral e dos bons costumes.
              </p>
              <br /> <br />
              <h3>3. LICENÇA LIMITADA</h3>
              <br /> <br />
              <p>
                Você recebeu uma licença limitada, não transferível, não
                exclusiva, e revogável para baixar, instalar, executar e
                utilizar este aplicativo em seu dispositivo. Você reconhece e
                concorda que a Profiz concede ao usuário uma licença exclusiva
                para uso e desta forma não lhe transfere os direitos sobre o
                produto. O aplicativo deverá ser utilizado por você, usuário.
                <br /> <br />A venda, transferência, modificação, engenharia
                reversa ou distribuição bem como a cópia de textos, imagens ou
                quaisquer partes nele contido é expressamente proibida.
              </p>
              <br /> <br />
              <h3>4. DA MANUTENÇÃO INTERNA E DAS INTERRUPÇÕES</h3>
              <br /> <br />
              <p>
                Você fica ciente e consente que a Profiz poderá realizar as
                seguintes interrupções no funcionamento dos
                Softwares/Plataforma:
              </p>
              <br />
              <ol type="1" style={{ marginLeft: 18 }}>
                <li>
                  Interrupções programadas pela Profiz para manutenção
                  preventiva e/ou corretiva nos Softwares, desde que devidamente
                  informadas a você com antecedência mínima de 24 horas.
                </li>
                <li>
                  Interrupções emergenciais ocasionadas por casos fortuitos ou
                  de força maior, inclusive por problemas na Internet, problemas
                  com os fornecedores da Profiz, ataques ou tentativas de
                  ataques hackers ou similares, ou outras causas que
                  notoriamente fujam do controle da Profiz, bem como eventuais
                  falhas sistêmicas. Em caso de emergências, a Profiz entrará em
                  contato com a Profissional Prestador de Serviços para relatar
                  o problema e acordar a forma mais razoável e conveniente de
                  solucioná-lo, sempre visando evitar prejuízos na utilização
                  dos Softwares.
                </li>
              </ol>
              <br />
              <p>
                Nos casos elencados acima, os equipamentos das Partes poderão
                ficar impossibilitados de transmitir e/ou receber dados pelo
                tempo necessário para a conclusão das manutenções.
              </p>
              <br /> <br />
              <h3>5. DA RESPONSABILIDADE</h3>
              <br /> <br />
              <p>
                A Profiz disponibiliza a estrutura necessária para que o
                Parceiro ofereça seus produtos por meio de aplicativo registrado
                sob o domínio de propriedade da Profiz (“App Profiz”), sem
                participar do controle de estoque, produção e qualidade, não
                sendo, portanto, fornecedora de quaisquer produtos anunciados
                por seus Parceiros no App Profiz.
                <br /> <br /> O Parceiro será o único responsável por determinar
                o preço dos produtos por ele ofertados no App Profiz.
                <br /> <br />
                <br />A Profiz disponibilizará ao Parceiro as informações
                referentes aos pedidos efetuados, sempre que alguma compra do
                produto por ele ofertado for confirmada, inclusive os dados
                necessários para emissão de Nota Fiscal ao usuário, a qual
                deverá ser obrigatoriamente emitida pelo Parceiro, em
                conformidade com a legislação vigente, em momento anterior ao
                envio do produto, e entregue ao consumidor, sob pena de
                descredenciamento do Parceiro e/ou bloqueio dos valores que este
                tenha a receber no âmbito deste Termo de Serviço, até que a
                emissão da Nota Fiscal seja regularizada, sem prejuízo de
                eventuais sanções legais aplicáveis.
                <br /> <br />
                O Parceiro deverá informar à Profiz todas as atualizações que
                estejam sob sua responsabilidade referentes ao status dos
                pedidos, tais como, mas não se limitando a, emissão de nota
                fiscal, produto entregue à transportadora, código de rastreio da
                entrega, pedido em trânsito entre centros de distribuição,
                extravio, insucesso de entrega, pedido em rota de entrega,
                pedido entregue ao cliente, sob pena de cancelamento do
                respectivo pedido ou a tomada de qualquer outra providência
                razoável pela Profiz, a fim de corrigir eventuais problemas e/ou
                reclamações relativos aos pedidos em questão.
                <br /> <br /> O Parceiro reconhece e aceita que os produtos
                anunciados no App Profiz o vinculam, na medida em que atua como
                um fornecedor de produtos, nos termos do artigo 30 do Código de
                Defesa do Consumidor e do artigo 429 do Código Civil, cujo
                cumprimento pode ser exigido judicialmente pelo consumidor.
                <br /> <br /> O Parceiro será responsável por realizar trocas
                e/ou cancelamentos de venda dos produtos que venham a apresentar
                defeitos e/ou vícios, bem como realizar cancelamentos por
                arrependimento, sempre nos termos do Código de Defesa do
                Consumidor e legislação aplicável. <br /> <br />
              </p>
              <br /> <br />
              <h3>6. TRATAMENTO DE DADOS PESSOAIS</h3>
              <br /> <br />
              <p>
                Para fins de preservação da privacidade do usuário de internet,
                as Partes comprometem-se a reter a menor quantidade possível de
                dados e registros e excluí-los (i) tão logo atingida a
                finalidade de seu uso ou (ii) se encerrado o prazo determinado
                por obrigação legal, conforme preceitua o disposto no artigo 13,
                §2º da Lei do Marco Civil da Internet. As Partes se obrigam a
                realizar o tratamento de dados pessoais de acordo com as
                disposições legais vigentes, bem como nos moldes da Lei
                13.709/2018, a Lei Geral de Proteção de Dados Pessoais (LGPD),
                visando dar efetiva proteção aos dados coletados de pessoas
                naturais que possam identificá-las ou torna-las identificáveis,
                utilizando-os de tais dados tão-somente para os fins necessários
                à consecução do objeto deste Contrato, ou nos limites do
                consentimento expressamente manifestado por escrito por seus
                respectivos titulares.
                <br /> <br /> O Usuário declara inequívoca ciência de que é de
                sua exclusiva responsabilidade a utilização dos dados obtidos
                por meio da plataforma Profiz, sendo terminantemente vedada a
                utilização de tais informações para fins diversos daqueles
                relativos ao objeto do contrato, bem como, outros fins ilícitos,
                ou que, de qualquer forma, atentem contra a moral e os bons
                costumes. A Profiz não será, em qualquer hipótese,
                responsabilizada pelo uso indevido por parte de você e/ou
                terceiros, com relação a dados armazenados em seus softwares e
                bancos de dados;
                <br /> <br /> Cada Parte deverá informar seus diretores,
                empregados, representantes, profissionais ou consultores que
                lhes prestam consultoria, relativamente a questões referidas
                neste Contrato, ou a quem as Partes fornecem Informações/dados
                confidenciais, que tais informações/dados são confidenciais,
                devendo instruí-los a mantê-las em sigilo e não as divulgar a
                terceiros (com exceção das Pessoas a quem as informações já
                tenham sido divulgadas em conformidade com os termos deste
                Contrato.
                <br /> <br /> As Partes se comprometem a adotar as melhores
                práticas para respeitar a legislação vigente e/ou que venha a
                entrar em vigor sobre proteção de dados, sendo certo que se
                adaptará, inclusive, à Lei nº 13.709/2018 (“Lei de Proteção de
                Dados”) até a sua entrada em vigor.
                <br /> <br /> A Profiz deverá, considerando os meios
                tecnológicos disponíveis e adequados às suas atividades, a
                natureza dos dados armazenados e os riscos a que estão expostos,
                adotar medidas físicas e lógicas, de caráter técnico e
                organizacional, para prover confidencialidade e segurança dos
                dados de modo a evitar sua alteração, perda, subtração e acesso
                não autorizado, bem como a violação da privacidade dos sujeitos
                titulares dos dados.
              </p>
              <br /> <br />
              <h3>7. DISPOSIÇÕES GERAIS</h3>
              <br /> <br />
              <p>
                Estes Termos de Uso não geram contrato de sociedade, franquia ou
                relação de trabalho entre o Usuário e a Profiz, seus
                profissionais, parceiros e/ou anunciantes.
                <br /> <br /> Caso qualquer disposição deste Termos venha a ser
                considerada ilegal, nula ou inexequível por qualquer razão, as
                disposições restantes não serão afetadas, permanecendo válidas e
                aplicáveis na máxima extensão possível.
                <br /> <br /> O Usuário autoriza a Profiz, a utilizar, por prazo
                indeterminado, as informações fornecidas no ato do cadastro e
                durante o uso do aplicativo, para fins estatísticos e envio de
                material publicitário, <i>newletters</i>, informes, etc.
              </p>
              <br /> <br />
              <h3>8. FORO</h3>
              <br /> <br />
              <p>
                Em geral, para a solução de litígios envolvendo a interpretação
                e execução deste contrato, inclusive em relação a fatos
                envolvendo as fases pré e pós contratual, as partes elegem o
                Foro da Comarca Birigui/SP, com exclusão de qualquer outro, por
                mais privilegiado que seja. <br /> <br /> E, por estarem justos
                e acordados seus termos, você declara sua inequívoca ciência e
                manifesta expressa concordância a todas as cláusulas,
                obrigações, declarações, direitos, ônus e responsabilidades
                previstos neste contrato.
              </p>
            </S.Description>
          )}
        </S.Main>
      </S.BoxModal>
    </S.ContainerOverlay>
  );
}
