import styled, { css } from 'styled-components'

type MessageProps = {
  isAdm?: boolean;
}

export const Container = styled.div<MessageProps>`
  ${({ isAdm }) => css`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: ${isAdm ? 'flex-start' : 'flex-end'};

    margin-bottom: 24px;
  `}
`;

export const MessageBalloon = styled.div`
  ${({ theme }) => css`
    background-color: ${theme.colors.background_light};
    padding: 12px 16px;
    max-width: 60%;
    border-radius: 10px;
  `}
`;

export const Detail = styled.div<MessageProps>`
  ${({ theme, isAdm }) => css`
    height: 16px;
    width: 14px;
    background-color: ${theme.colors.background_light};
    clip-path: polygon(0 0, 0 100%, 100% 52%);
    transform: ${isAdm ? 'rotate(180deg)' : 'rotate(0deg)'};
  `}
`;

export const ProfilePhoto = styled.img
  <MessageProps>`
  ${({ theme, isAdm }) => css`
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: ${theme.colors.background_light};
    ${isAdm ? css`margin-right: 12px` : css`margin-left: 12px`}
  `}
`;

export const Text = styled.pre<MessageProps>`
  ${({ theme, isAdm = false }) => css`
    white-space: pre-wrap;
    font-size: ${theme.font.sizes.small};
    color: ${isAdm
      ? theme.colors.primary
      : theme.colors.text_extra_light
    };
  `}
`;
