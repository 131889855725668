import { useEffect, useState } from "react";
import { addDays, format } from "date-fns";
import { useTheme } from "styled-components";
import { FiX } from "react-icons/fi";

import { Calendar, DayModifiers } from "components/Calendar";
import { AlertModal } from "components/AlertModal";

import ScheduleSVG from "assets/icons/schedule.svg";

import * as S from "./styles";

type FinancialDateInputProps = {
  currentDate?: string;
  label?: string;
  dateToCompare?: string;
  handleSetDate: (date: string) => void;
  handleClearButtonFilter: (id: number) => void;
};

export function FinancialDateInput({
  currentDate,
  dateToCompare,
  label,
  handleSetDate,
  handleClearButtonFilter,
}: FinancialDateInputProps) {
  const theme = useTheme();

  const [openCalendar, setOpenCalendar] = useState(false);
  const [dateError, setDateError] = useState(false);
  const [selectedDate, setSelectedDate] = useState<Date | undefined>(() => {
    if (currentDate) {
      return addDays(new Date(currentDate), 1);
    }

    return undefined;
  });

  useEffect(() => {
    if (!currentDate) setSelectedDate(undefined);
  }, [currentDate, setSelectedDate]);

  function toggleCalendar() {
    setOpenCalendar(!openCalendar);
  }

  function handleChangeDate(day: Date, { selected, disabled }: DayModifiers) {
    if (disabled || selected) return;

    if (dateToCompare && day < new Date(dateToCompare)) {
      setDateError(!dateError);
      return;
    }
    handleClearButtonFilter(-1);

    const formattedDate = format(day, "yyyy-MM-dd");
    handleSetDate(formattedDate);
    setSelectedDate(day);
    toggleCalendar();
  }

  return (
    <>
      <AlertModal
        isVisible={dateError}
        title="Erro na data!"
        description="A data limite não pode ser menor que a data inicial."
        action="confirm"
        handleConfirm={() => setDateError(false)}
        onCloseModal={() => setDateError(false)}
      />

      <S.Container>
        <S.ButtonCalendar selectedDate={!!currentDate} onClick={toggleCalendar}>
          <img src={ScheduleSVG} alt="Imagem de um calendário" />
          <S.DetailCalendarText>
            {currentDate
              ? format(addDays(new Date(currentDate), 1), "dd/MM/yyyy")
              : label}
          </S.DetailCalendarText>
        </S.ButtonCalendar>

        {openCalendar && (
          <S.ContainerOverlay>
            <S.ModalCalendar>
              <S.WrapperButton>
                <S.ButtonCloseModal onClick={toggleCalendar}>
                  <FiX size={20} color={theme.colors.text} />
                </S.ButtonCloseModal>
              </S.WrapperButton>

              <Calendar
                selectDays={selectedDate}
                onChangeDate={handleChangeDate}
              />
            </S.ModalCalendar>
          </S.ContainerOverlay>
        )}
      </S.Container>
    </>
  );
}
