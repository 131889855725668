import { HTMLAttributes } from "react";
import { ArrowButton } from "components/ArrowButton";

import * as S from "./styles";

type PageProps = {
  title: string;
  subtitle: string;
  hideGoBackButton?: boolean;
  handleGoBack?: () => void;
  titleAlignedBelow?: boolean;
  children: React.ReactNode;
} & HTMLAttributes<HTMLDivElement>;

export function Page({
  title,
  subtitle,
  hideGoBackButton = false,
  handleGoBack,
  titleAlignedBelow = false,
  children,
  ...rest
}: PageProps) {
  return (
    <S.Container {...rest}>
      <header>
        {!hideGoBackButton && (
          <S.Wrapper>
            <ArrowButton handleFunction={handleGoBack} />
          </S.Wrapper>
        )}
        {!titleAlignedBelow && <S.Title>{title}</S.Title>}
      </header>

      <S.Content>
        {titleAlignedBelow && <S.Title>{title}</S.Title>}
        <S.Label>{subtitle}</S.Label>
        {children}
      </S.Content>
    </S.Container>
  );
}
