import { ArrowButton } from "components/ArrowButton";
import { Button } from "components/Button";

import * as S from './styles';
import { Calendar } from "components/Calendar";

import{
  DayModifiers,
} from "react-day-picker";

type ScheduledProps = {
  closeModal: () => void;
  loading: boolean;
  selectedDate: Date | undefined;
  handleChangeDate: (day: Date, { selected, disabled }: DayModifiers) => void;
  handleUpdatePmoc: () => void;
  finalDate?: Date | undefined;
}

export function ScheduledComponent({
  closeModal,
  loading,
  selectedDate,
  handleChangeDate,
  handleUpdatePmoc,
  finalDate
}: ScheduledProps) {

  
  return (
          <S.ScheduledModal>
              <div className="arrow-button">
                  <ArrowButton
                  handleFunction={
                    closeModal
                  }
                />
              </div>
              <S.Title>Gerar ordens de serviço</S.Title>
              <p className="subtitle">
                Defina a data de início, a partir da qual as ordens de serviço
                serão geradas automaticamente.*
              </p>
              <S.DivModal>
                <Calendar
                  isPmocRescheduling
                  selectDays={selectedDate}
                  onChangeDate={handleChangeDate}
                  finalDate={finalDate}
                  startDate={new Date()}
                />
              </S.DivModal>

              <Button
                disabled={!!!selectedDate || loading}
                loading={loading}
                onClick={handleUpdatePmoc}
              >
                Definir data de início e gerar ordens de serviço
              </Button>

              <p className="info">
                * A PMOC foi editada com a adição de novos serviços e por isso será necessário
                definir uma data para iniciar as novas ordens de serviço - a data precisa ser maior ou
                igual a data de hoje e menor que a data final da PMOC
              </p>
          </S.ScheduledModal>
  );
}