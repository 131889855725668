import CloseSvg from "assets/icons/buttonClose.svg";
import LogoSvg from "assets/icons/logo-color.svg";

import { AccountSelector } from "..";
import { useAccount } from "hooks/permission/account";
import { AccountDTO } from "dtos/permission/AccountDTO";

import * as S from "./styles";

type ModalProps = {
  isVisible: boolean;
  filteredAccounts?: AccountDTO[];
  onCloseModal: () => void;
};

export function AccountModal({
  isVisible,
  filteredAccounts = [],
  onCloseModal,
}: ModalProps) {
  const { accounts, whoami, handleSetWhoami } = useAccount();

  const accountsOnSelector =
    filteredAccounts.length > 0 ? filteredAccounts : accounts;

  async function handleSelectAccount(selectedAccount: AccountDTO) {
    handleSetWhoami(selectedAccount);
    onCloseModal();
  }

  return (
    <S.ContainerOverlay isVisible={isVisible}>
      <S.BoxModal>
        <S.Header>
          <div />

          <img src={LogoSvg} alt="Logo Profiz" />

          <button type="button" onClick={() => onCloseModal()}>
            <img src={CloseSvg} alt="Fechar modal" width={10} height={10} />
          </button>
        </S.Header>

        <S.Main>
          {accountsOnSelector.map((account) => (
            <AccountSelector
              key={account.id}
              data={account}
              isSelected={whoami?.id === account.id}
              onSelect={() => handleSelectAccount(account)}
            />
          ))}
        </S.Main>
      </S.BoxModal>
    </S.ContainerOverlay>
  );
}
