import styled, { css } from "styled-components";

export const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
`;

export const ProgressBar = styled.div`
  ${({ theme }) => css`
    height: 3px;
    background-color: ${theme.colors.strong_detail};
    overflow: hidden;
    border-radius: 5px;
    position: relative;
  `};
`;

export const BarColor = styled.div`
  ${({ theme }) => css`
    height: 3px;
    background-color: ${theme.colors.primary200};
    border-radius: 5px;
  `};
`;
