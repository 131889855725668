import { PmocListingProps } from "dtos/PmocDTO";
import * as S from './styles';

type PmocItemComponentProps = {
  handleScreenRedirector: () => void;
  pmoc: PmocListingProps
}

export function PmocItemComponent({
  handleScreenRedirector,
  pmoc
}: PmocItemComponentProps) {


  return (
  <>
    <S.PmocContainer onClick={handleScreenRedirector}>
      <p style={{marginRight:20}}>{pmoc.id}</p>
      <p>{pmoc.name}</p>
    </S.PmocContainer>         
  </>
  );
}