import styled, { css } from "styled-components";

export const Container = styled.div`
  width: 100%;
  height: 40px;
  background: rgba(53, 53, 53, 0.5);
  border-radius: 8px;
  display: flex;
  flex-direction: row;
  margin-top: 10px;
  padding: 10px;
  align-items: center;

  img {
    width: 25px;
    height: 25px;
    border-radius: 50%;
  }
`;

export const Name = styled.span`
  ${({ theme }) => css`
    color: ${theme.colors.title};
    font-size: 13px;
    line-height: 16px;
    font-weight: ${theme.font.normal};
    margin-left: 10px;
  `}
`
