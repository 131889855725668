import { darken } from "polished";
import styled, { css } from "styled-components";
import media from "styled-media-query";

export const Container = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
`;

export const FilterTags = styled.div`
  ${media.lessThan("large")`
     padding: 0 20px;
  `}
`;

export const TransactionTypeContainer = styled.div`
  margin: 1rem 0;
  display: flex;
  gap: 10px;
`;

interface RadioBoxProps {
  isActive: boolean;
}

export const RadioBox = styled.button<RadioBoxProps>`
  ${({ theme, isActive }) => css`
    width: 14px;
    height: 14px;

    border: 1px solid ${theme.colors.text_light_87};

    display: flex;
    align-items: center;
    justify-content: center;
    transition: border-color 0.2s;
    border-radius: 4px;

    .marked {
      border-radius: 2px;
      ${isActive &&
      css`
        padding: 3px;
        background-color: ${theme.colors.primary};
      `}
    }

    &:hover {
      border-color: ${darken(0.1, "#d7d7d7")};
    }

    img {
      width: 20px;
      height: 20px;
    }

    span {
      display: inline-block;
      font-size: 1rem;
      color: var(--text-title);
      font-size: 12px;
    }
  `}
`;

export const ContainerRadioBox = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    cursor: pointer;

    & + & {
      margin-top: 15px;
    }

    span {
      font-size: 12px;
      color: ${theme.colors.text_light_60};
    }
  `}
`;
