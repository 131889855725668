import { useEffect, useState } from "react";

import { useAccount } from "hooks/permission/account";
import { useToast } from "hooks/toast";
import { useSelectedServiceToSchedule } from "hooks/selectedServiceToSchedule";

import { api } from "services/api";
import apiv2 from "services/apiv2";
import { TeamMemberDTO } from "dtos/permission/TeamMemberDTO";

import { LoadingProfiz } from "components/LoadingProfiz";

import * as S from "./styles";

export function MemberList() {
  const { whoami } = useAccount();
  const { addToast } = useToast();
  const {
    selectedServiceToSchedule,
    selectedMemberIdsByService,
    handleSelectedMemberIdByService,
  } = useSelectedServiceToSchedule();

  const [members, setMembers] = useState<TeamMemberDTO[]>([]);
  const [loading, setIsLoading] = useState(false);

  async function getMembersToExecuteOs() {
    try {
      setIsLoading(true);

      const { data: serviceOrder } = await apiv2.get(
        `/budgets/service-order/${selectedServiceToSchedule?.serviceOrderId}`
      );

      if (serviceOrder.pmoc.length === 0) {
        const { data } = await api.get(
          `users/teams/${whoami?.id}/members?tabFilter=added&permission=budgets-service-order/edit`
        );

        setMembers(data.members);

        const isMeExists = data.members.find(
          (member: TeamMemberDTO) => member.isMe
        );

        if (
          isMeExists?.memberId &&
          Object.entries(selectedMemberIdsByService).length === 0
        ) {
          handleSelectedMemberIdByService({
            [selectedServiceToSchedule.idBudgetService]: isMeExists?.memberId,
          });
        } else if (Object.entries(selectedMemberIdsByService).length === 0) {
          handleSelectedMemberIdByService({
            [selectedServiceToSchedule.idBudgetService]: {
              id: whoami?.id || 0,
              name: whoami?.name || '',
              photo: whoami?.photo || '',
            },
          });
        }
      } else if (Object.entries(selectedMemberIdsByService).length === 0) {
        handleSelectedMemberIdByService({
          [selectedServiceToSchedule.idBudgetService]: {
            id: whoami?.id || 0,
            name: whoami?.name || '',
            photo: whoami?.photo || '',
          },
        });
      }
    } catch (error) {
      addToast({
        title: "Ops",
        description: "Não foi possível carregar os membros da equipe",
        type: "error",
      });
    } finally {
      setIsLoading(false);
    }
  }

  function handleSelectMember(member: TeamMemberDTO) {
    handleSelectedMemberIdByService({
      [selectedServiceToSchedule.idBudgetService]: {
        id: member.memberId,
        name: member.name,
        photo: member.photo
      },
    })
  }

  useEffect(() => {
    getMembersToExecuteOs();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const selectedCurrentMemberId = selectedMemberIdsByService[selectedServiceToSchedule.idBudgetService] !== undefined
    ? selectedMemberIdsByService[selectedServiceToSchedule.idBudgetService].id : 0

  if (loading) return <LoadingProfiz isVisible={loading} />;

  return (
    <>
      <S.Title>Selecione um responsável para o serviço</S.Title>

      <S.Wrapper>
        <S.Member
          selected={whoami?.id === selectedCurrentMemberId}
          onClick={() =>
            handleSelectedMemberIdByService({
              [selectedServiceToSchedule.idBudgetService]: {
                id: whoami?.id || 0,
                name: whoami?.name || '',
                photo: whoami?.photo || '',
              },
            })
          }
        >
          <img src={whoami?.photo} alt="Foto do membro da equipe" />

          {whoami?.id === selectedCurrentMemberId && (
            <>
              <S.Dot />
              <span>{whoami?.name}</span>
            </>
          )}
        </S.Member>

        {members.map((member) => (
          <S.Member
            key={member.memberId}
            selected={member.memberId === selectedCurrentMemberId}
            onClick={() => handleSelectMember(member)}
          >
            <img src={member.photo} alt="Foto do membro da equipe" />

            {member.memberId === selectedCurrentMemberId && (
              <>
                <S.Dot />
                <span>{member.name}</span>
              </>
            )}
          </S.Member>
        ))}
      </S.Wrapper>
    </>
  );
}
