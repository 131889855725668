import styled, { css } from 'styled-components'

export const Container = styled.button`
  ${({ theme }) => css`
    width: 150px;
    height: 150px;
    background-color: ${theme.colors.background_light};
    border-radius: 10px;
    padding: 18px;
    display: flex;
    flex-direction: column;
    transition: opacity 0.3s;

    &:hover {
      opacity: 0.6;
    }

    img {
      width: 35px;
      height: 35px;
      object-fit: cover;
    }

    h4 {
      max-width: 100%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      font-size: ${theme.font.sizes.xsmall};
      color: ${theme.colors.title};
      margin-top: 10px;
      margin-bottom: 10px;
    }

    p {
      max-width: 100%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      text-align: left;
      font-size: ${theme.font.sizes.xsmall};
      color: ${theme.colors.text};
      margin-bottom: 5px;
    }
  `}
`
