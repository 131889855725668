import React, { useEffect, useState } from "react";
import { useParams } from "react-router";

import { ArrowButton } from "components/ArrowButton";
import { ManualCard } from "components/ManualCard";

import { api } from "services/api";

import { EquipmentsShimmer } from "Shimmer/EquipmentsShimmer";

import { useToast } from "hooks/toast";

import * as S from "./styles";

type ItemsProps = {
  id: string;
  name: string;
  file: string;
};

type GuideResponse = {
  type: "manual-tecnico" | "manual-usuario" | "codigo-erro";
  items: ItemsProps[];
};

type Guide = {
  id: string;
  name: string;
  file: string;
  type: string;
  image: string;
};

type RouteParams = {
  brandId: string;
  equipmentId: string;
  type: "technicalManual" | "errorCode";
};

export function EquipmentsManualList() {
  const { addToast } = useToast();
  const { equipmentId, type } = useParams<RouteParams>();

  const [isLoading, setIsLoading] = useState(true);
  const [manuals, setManuals] = useState<Guide[]>([]);

  useEffect(() => {
    async function loadEquipmentsOfBrand() {
      try {
        const response = await api.get(`equipment/${equipmentId}`);

        const { image, guides } = response.data;

        if (type === "technicalManual") {
          let technicalManualsFormatted: Guide[] = [];
          let userManualsFormatted: Guide[] = [];

          const filteredTechnicalManuals: GuideResponse[] = guides.filter(
            (guideSection: GuideResponse) =>
              guideSection.type === "manual-tecnico"
          );

          const filteredUserManuals: GuideResponse[] = guides.filter(
            (guideSection: GuideResponse) =>
              guideSection.type === "manual-usuario"
          );

          if (filteredTechnicalManuals.length > 0) {
            technicalManualsFormatted = filteredTechnicalManuals[0].items.map(
              (technicalManuals) => ({
                id: technicalManuals.id,
                type: "Manual técnico",
                name: technicalManuals.name,
                file: technicalManuals.file,
                image,
              })
            );
          }

          if (filteredUserManuals.length > 0) {
            userManualsFormatted = filteredUserManuals[0].items.map(
              (userManuals) => ({
                id: userManuals.id,
                type: "Manual do usuário",
                name: userManuals.name,
                file: userManuals.file,
                image,
              })
            );
          }

          const manualsFormatted =
            technicalManualsFormatted.concat(userManualsFormatted);
          setManuals(manualsFormatted);
          return;
        }

        if (type === "errorCode") {
          const filteredErrorsCode: GuideResponse[] = guides.filter(
            (guideSection: GuideResponse) => guideSection.type === "codigo-erro"
          );
          if (filteredErrorsCode) {
            const manualsFormatted = filteredErrorsCode[0].items.map(
              (errorCode) => ({
                id: errorCode.id,
                type: "Manual código de erro",
                name: errorCode.name,
                file: errorCode.file,
                image,
              })
            );
            setManuals(manualsFormatted);
          }
          return;
        }
      } catch (err) {
        // sendError(err)
        addToast({
          title: "Ops!!",
          description: "Ocorreu um erro ao listar os manuais",
          type: "error",
        });
      } finally {
        setIsLoading(false);
      }
    }

    loadEquipmentsOfBrand();
  }, [equipmentId, type, addToast]);

  return (
    <S.Container>
      <header>
        <ArrowButton />

        <h2>Manuais e códigos de erro</h2>
      </header>

      {!isLoading && manuals.length === 0 && (
        <S.EmptyValues>
          <span>Nenhum manual encontrado.</span>
        </S.EmptyValues>
      )}

      <S.Content>
        {isLoading && <EquipmentsShimmer />}

        {!isLoading &&
          manuals.map((manual) => (
            <ManualCard
              manual={{
                type: manual.type,
                name: manual.name,
                link: manual.file,
                image: manual.image,
              }}
              key={manual.id}
            />
          ))}
      </S.Content>
    </S.Container>
  );
}
