import React, { useEffect, useState } from "react";

import ToolSvg from "assets/icons/tools.svg";

import { ArrowButton } from "components/ArrowButton";
import { Button } from "components/Button";
import { AlertModal } from "components/AlertModal";

import addMaskDateAndHour from "utils/addMaskDateAndHour";
import { useToast } from "hooks/toast";
import { useServiceOrder } from "hooks/serviceOrder";

import apiv2 from "services/apiv2";

import { usePmocTabIndex } from "hooks/pmoc/tabIndex";
import { useResumePmoc } from "hooks/resumePmoc";

import * as S from "./styles";
import { LoadingProfiz } from "components/LoadingProfiz";

const serviceStatus = {
  pending: () => "Não agendado",
  scheduled: (scheduleDate: string) =>
    `Agendado para ${addMaskDateAndHour(scheduleDate)}`,
  concluded: () => "Concluído",
};

export function ServiceOrderDetailArchived() {
  const { addToast } = useToast();

  const {
    selectedServiceOrder,
    currentService,
    handleSetSelectedServiceOrder,
    handleSetCurrentService,
  } = useServiceOrder();

  const { idServiceOrderSelected, handleChangeModalSelected } = useResumePmoc();
  const { handleSetSelectedIndex } = usePmocTabIndex();

  const tabOptions = {
    new: 0,
    scheduled: 1,
    concluded: 2,
  };

  const [loading, setLoading] = useState(false);
  const [alertModal, setAlertModal] = useState(false);

  async function getServiceOrderById() {
    try {
      setLoading(true);

      const { data } = await apiv2.get(
        `/budgets/service-order/${idServiceOrderSelected}`
      );

      handleSetSelectedServiceOrder(data);
      handleSetCurrentService(data.services[0]);
    } catch (error) {
      addToast({
        title: "Ops!!",
        description: "Não foi possível carregar os dados da ordem de serviço.",
        type: "error",
      });
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    getServiceOrderById();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function handleDearchiveServiceOrder() {
    try {
      setLoading(true);

      await apiv2.put(
        `/budgets/service-order/${idServiceOrderSelected}/dearchive`,
        {
          originUpdate: "web",
        }
      );

      addToast({
        type: "success",
        title: "Ordem de serviço desarquivada",
      });

      handleChangeModalSelected("detailOfMonthPmoc");
      handleSetSelectedIndex(tabOptions[selectedServiceOrder.status]);
    } catch (error) {
      addToast({
        type: "error",
        title: "Ops...",
        description: "Erro ao desarquivar ordem de serviço",
      });
    } finally {
      setLoading(false);
      setAlertModal(false);
    }
  }

  function handleGoBack() {
    handleChangeModalSelected("detailOfMonthPmoc");
    handleSetSelectedIndex(tabOptions[selectedServiceOrder.status]);
  }

  if (!selectedServiceOrder.id) return <></>;

  return (
    <>
      {loading ? (
        <LoadingProfiz isVisible={loading} />
      ) : (
        <>
          <AlertModal
            isVisible={alertModal}
            title="Aviso"
            description={
              "Tem certeza que deseja desarquivar essa ordem de serviço ?"
            }
            action={"choose"}
            handleConfirm={() => handleDearchiveServiceOrder()}
            onCloseModal={() => setAlertModal(false)}
          />

          <ArrowButton handleFunction={handleGoBack} />

          <S.Content>
            <>
              <S.Title>{currentService.service.service.name}</S.Title>

              <img src={ToolSvg} alt="tools" />

              <S.TextWrapper>
                <S.Description>
                  Esta ordem de serviço está vinculada ao equipamento
                  <S.Negrito>
                    {` ${selectedServiceOrder.equipment?.tagEquipment} `}
                  </S.Negrito>
                  do ambiente
                  <S.Negrito>{` ${selectedServiceOrder.environment.name}.`}</S.Negrito>
                </S.Description>
              </S.TextWrapper>

              <S.Card>
                <S.Negrito>{selectedServiceOrder.environment.name}</S.Negrito>
              </S.Card>

              <S.Card>
                <S.Negrito>
                  {selectedServiceOrder.equipment.tagEquipment}
                </S.Negrito>
              </S.Card>

              <S.Card>
                <S.CardHeader>
                  <S.Negrito>{currentService.service.service.name}</S.Negrito>
                  <S.Description style={{ marginLeft: 10 }}>
                    {` (${selectedServiceOrder.equipment.currentFrequencyService})`}
                  </S.Description>
                </S.CardHeader>
                <S.CardHeader style={{ marginTop: 10 }}>
                  <S.Dot
                    style={{
                      backgroundColor:
                        selectedServiceOrder.services[0].status === "scheduled"
                          ? "#FFF595"
                          : selectedServiceOrder.services[0].status ===
                            "concluded"
                          ? "#D3FF9A"
                          : "",
                    }}
                  />

                  {currentService.idBudgetService && (
                    <S.Negrito style={{ fontWeight: 400 }}>
                      {serviceStatus[currentService.status](
                        currentService.scheduleDate
                      )}
                    </S.Negrito>
                  )}
                </S.CardHeader>
              </S.Card>
            </>
            <S.ButtonsWrapper>
              {selectedServiceOrder.services[0].status !== "concluded" && (
                <>
                  <Button typeButton="disabled" disabled>
                    {selectedServiceOrder.services[0].status === "pending"
                      ? "Agendar serviços"
                      : selectedServiceOrder.services[0].status ===
                          "scheduled" && "Reagendar serviços"}
                  </Button>

                  <Button typeButton="disabled" disabled>
                    Iniciar checklist do serviço
                  </Button>
                </>
              )}

              <Button onClick={() => setAlertModal(true)} typeButton="delete">
                Desarquivar ordem de serviço
              </Button>
            </S.ButtonsWrapper>
          </S.Content>
        </>
      )}
    </>
  );
}
