import { ProductResponseProps } from "dtos/businessProposalsDTO";
import { ProductPropsUsedInContext } from "hooks/budget/selectedProducts";
import addMaskMoney from "./addMaskMoney";

export function mappedBudgetProducts(
  selectedProducts: ProductPropsUsedInContext[],
): ProductResponseProps[] {
  return selectedProducts.map(product => ({
    product: {
      id: product.id,
      categoryId: product.categoryId,
      name: product.name,
      price: product.price,
      brand: product.brand,
      warranty: {
        time: product.warranty.time,
        extension: product.warranty.extension,
      },
      observations: product.observations,
      photos: product.photos,
    },
    quantity: product.quantity,
  }));
}

export function mappedBudgetProductToUseInApp(products: ProductResponseProps[]):ProductPropsUsedInContext[] {
  return products.map(({ product, quantity }) => ({
    ...product,
    quantity,
    total: product.price * quantity,
    formattedTotal: addMaskMoney(product.price),
    isChecked: true,
  }));
}
