import styled, { css } from "styled-components";
import { shade } from "polished";

export const Container = styled.div`
  ${({ theme }) => css`
    margin-bottom: 30px;
    width: 100%;
    max-width: 1400px;
    padding: 0 70px;

    header {
      display: flex;
      flex-direction: row;
      margin: 30px 0;
      width: 50%;
      justify-content: space-between;
    }

    div.content {
      width: 50%;
    }

    div.list {
      margin-top: 30px;
      margin-bottom: 50px;
    }
  `}
`;

export const Title = styled.h1`
  ${({ theme }) => css`
    font-size: ${theme.font.sizes.large};
    color: ${theme.colors.title};
    font-weight: ${theme.font.bold};
  `}
`;

export const Wrapper = styled.div`
  margin-bottom: 20px;
`;

export const TitleExecutionButton = styled.p`
  ${({ theme }) => css`
    font-size: ${theme.font.sizes.small};
    color: ${theme.colors.title};
    font-weight: ${theme.font.family};
    margin-bottom: 5px;
  `}
`;

export const AttachButton = styled.button`
  ${({ theme }) => css`
    background-color: transparent;
    width: 100%;
    height: 50px;

    display: flex;
    align-items: center;
    justify-content: center;

    border: 1px dashed ${theme.colors.primary_light};
    border-radius: 10px;

    img {
      margin-right: 10px;
    }

    font-size: 12px;
    font-family: ${theme.font.family};
    font-weight: ${theme.font.bold};
    color: ${theme.colors.title};

    transition: all 0.7s ease;
    &:hover {
      opacity: 0.7;
    }
  `}
`;

export const InlineContent = styled.div`
  display: flex;
  align-items: flex-end;
  width: 100%;

  div.input {
    margin-right: 2%;
    width: 45%;
  }

  div.dropdown {
    width: 100%;
  }
`;

export const Environments = styled.div`
  ${({ theme }) => css`
    margin-top: 5px;
    padding: 10px;
    border-radius: 8px;
    background: ${theme.colors.background_modal};
  `}
`;

export const DivModal = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 12px;
    background: ${theme.colors.background_modal};
    padding-left: 30px;
    margin-top: 30px;
  `}
`;

export const ScheduledModal = styled.div`
  ${({ theme }) => css`
    padding: 0px 30px;

    div.arrow-button {
      margin-bottom: 20px;
    }

    p.subtitle {
      margin-top: 10px;
      font-size: ${theme.font.sizes.small};
      color: ${theme.colors.financial_title};
    }

    p.info {
      margin-top: 30px;
      font-size: ${theme.font.sizes.small};
      color: ${theme.colors.financial_title};
    }
  `}
`;

export const EnvironmentList = styled.div`
  ${({ theme }) => css`
    background: ${theme.colors.background_modal};
    border-radius: 8px;
    padding: 15px;
    margin-bottom: 5px;

    display: flex;
    flex-direction: row;

    .card-title {
      font-size: ${theme.font.sizes.small};
      color: ${theme.colors.title};
      font-weight: ${theme.font.bold};
      margin-right: 5px;
    }

    .card-text {
      font-size: ${theme.font.sizes.small};
      color: ${theme.colors.financial_text};
      font-weight: ${theme.font.family};
    }
  `}
`;

export const WapperPmocStatus = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: row;
    align-items: center;

    .element-ball {
      height: 8px;
      width: 8px;
      background: ${theme.colors.primary};
      border-radius: 8px;
      margin-right: 5px;
    }
    .text-status {
      font-size: ${theme.font.sizes.small};
      color: ${theme.colors.text_extra_light};
      font-weight: ${theme.font.family};
    }
  `}
`;

export const WrapperInput = styled.div`
  display: flex;
  align-items: flex-end;

  & > div:first-of-type {
    width: 100%;
  }
`;

export const ButtonSaveHash = styled.button`
  ${({ theme }) => css`
    width: 90px;
    height: 46px;
    background-color: ${theme.colors.primary};
    color: ${theme.colors.background};
    border-radius: 6px;
    margin-left: 16px;

    display: flex;
    justify-content: center;
    align-items: center;

    &:hover {
      background: ${shade(0.2, theme.colors.primary_light)};
    }
  `}
`;

export const WrapperInfo = styled.div`
  ${({ theme }) => css`
    margin-top: 4px !important;
    margin-bottom: 20px;
    margin-left: 4px;

    & > span {
      font-size: ${theme.font.sizes.xsmall};
      color: ${theme.colors.text};
    }
  `}
`;

export const WrapperHashError = styled.div`
  ${({ theme }) => css`
    margin-top: 4px !important;
    margin-left: 4px;

    & > span {
      font-size: ${theme.font.sizes.xsmall};
      color: ${theme.colors.error};
    }
  `}
`;
