import { SetStateAction, Dispatch } from "react";

import ProgressBar from "components/ProgressBar";
import logoSimple from "assets/icons/logo-simple.svg";

import { getInfosToUseInTheTour, TourTypeProps } from "./data";

import * as S from "./styles";

interface OnboardingCardProps {
  type: TourTypeProps;
  isTourModalVisible: boolean;
  setIsTourModalVisible: Dispatch<SetStateAction<boolean>>;
  currentStep: number;
  setCurrentStep: Dispatch<SetStateAction<number>>;
  onUpdateOnboarding?: () => Promise<void>;
  expertiseModalOpen?: (value: boolean) => void;
}

export default function OnboardingCard({
  type,
  isTourModalVisible,
  setIsTourModalVisible,
  currentStep,
  setCurrentStep,
  onUpdateOnboarding,
  expertiseModalOpen,
}: OnboardingCardProps) {
  const { title, description, amountSteps } = getInfosToUseInTheTour(
    type,
    currentStep
  );

  const endOnboarding = async () => {
    await onUpdateOnboarding?.();
    setIsTourModalVisible(false);

    if (type === "homeOnboarding" && expertiseModalOpen) {
      expertiseModalOpen(true);
    }
  };

  return (
    <S.ContainerOverlay isVisible={isTourModalVisible}>
      <S.Card>
        <S.CardHeader>
          <img src={logoSimple} alt="logo" />
          <S.SkipButton onClick={endOnboarding}>Pular</S.SkipButton>
        </S.CardHeader>
        <S.CardTexts>
          <S.CardTitle>{title}</S.CardTitle>
          <S.CardDescription>{description}</S.CardDescription>
        </S.CardTexts>
        <ProgressBar
          steps={amountSteps}
          currentStep={currentStep}
          setCurrentStep={setCurrentStep}
          endOnboarding={endOnboarding}
        />
      </S.Card>
    </S.ContainerOverlay>
  );
}
