import { useEffect } from "react";

import { useHistory } from "react-router";

import ReceiptSvg from "assets/icons/financial-icons/receipt.svg";

import { useAccount } from "hooks/permission/account";

import { ArrowButton } from "components/ArrowButton";

import * as S from "./styles";

function Financial() {
  const history = useHistory();
  const { whoami } = useAccount();

  useEffect(() => {
    if (!whoami?.isMe) history.push("/home");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [whoami]);

  return (
    <S.Container>
      <header>
        <div>
          <ArrowButton />
          <h1>Financeiro</h1>
        </div>
      </header>

      <S.Content>
        <S.ButtonCard onClick={() => history.push("/financial/receipts")}>
          <img src={ReceiptSvg} alt="Recibos" />
          <div>
            <S.BolderTextSmall>Recibos</S.BolderTextSmall>
            <S.TextInfo>
              Crie e visualize seus recibos com praticidade.
            </S.TextInfo>
          </div>
        </S.ButtonCard>
      </S.Content>
    </S.Container>
  );
}

export { Financial };
