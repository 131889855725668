import { useState, useEffect } from "react";

import axios from "axios";
import { ExecutionListProps } from "dtos/PmocDetailOfMonthDTO";
import { api } from "services/api";
import { useToast } from "hooks/toast";
import { OSListComponent } from "../OSListComponent";

import * as S from "./styles";
import { useHistory } from "react-router";

type EnvironmentFilter = {
  id: number;
  name: string;
};

type UnscheduledTabProps = {
  year: number;
  month: string;
  idPmoc: number;
  filter?: EnvironmentFilter;
  setLoading: (value: boolean) => void;
  filterModalClose?: boolean;
};

type ResponseProps = {
  data: {
    executionList: ExecutionListProps[];
  };
};

type ResponseOSCreated = {
  data: {
    serviceOrderID: string | number;
    status: string;
  };
};

export function UnscheduledTab({
  year,
  month,
  setLoading,
  filter,
  filterModalClose,
  idPmoc,
}: UnscheduledTabProps) {
  const { addToast } = useToast();
  const [orderServiceList, setOrderServiceList] = useState<
    ExecutionListProps[]
  >([] as ExecutionListProps[]);

  const history = useHistory();

  const setData = async () => {
    try {
      setLoading(true);

      let params =
        filter && Object.keys(filter).length > 0
          ? {
              year: year,
              month: month,
              status: "new",
              environmentId: Number(filter.id),
            }
          : {
              year: year,
              month: month,
              status: "new",
            };
      const { data }: ResponseProps = await api.get(
        `pmoc/${idPmoc}/service/executions`,
        {
          params,
        }
      );
      const { executionList } = data;
      setOrderServiceList(executionList);
    } catch (error) {
      addToast({
        title: "Erro",
        description:
          axios.isAxiosError(error) && error.response?.data.error
            ? error.response?.data.error
            : "Não foi possível carregar as OS do mês selecionado.",
        type: "error",
      });
    } finally {
      setLoading(false);
    }
  };
  async function handleSetPage(
    idOS: number,
    idExecution?: number,
    servicePersistencePmocId?: number
  ) {
    try {
      setLoading(true);

      if (idOS < 1 && idExecution && servicePersistencePmocId) {
        const { data }: ResponseOSCreated = await api.put(
          `pmoc/${idPmoc}/service/${servicePersistencePmocId}/execution/${idExecution}`,
          {
            originCreate: 'web',
          }
        );
        const { serviceOrderID } = data;
        history.push(`/pmoc/execution/order-service/unscheduled/${serviceOrderID}/${idPmoc}/${year}/${month}`);
        return;
      }

      history.push(`/pmoc/execution/order-service/unscheduled/${idOS}/${idPmoc}/${year}/${month}`);
    } catch (error) {
      addToast({
        title: "Erro",
        description:
          axios.isAxiosError(error) && error.response?.data.error
            ? error.response?.data.error
            : "Não foi possível carregar as informações da ordem de serviço.",
        type: "error",
      });
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    if (!filterModalClose) return;
    setData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter, filterModalClose]);
  return (
    <>
      {orderServiceList.length < 1 ? (
        <S.EmptyList>
          <p>Você não possui ordens de serviço não agendadas.</p>
        </S.EmptyList>
      ) : (
        <OSListComponent
          osList={orderServiceList}
          statusOS="unscheduled"
          handleRoutePage={handleSetPage}
        />
      )}
    </>
  );
}
