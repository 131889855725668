import { PMOCServiceProps } from "dtos/ServiceDTO";
import React, { useEffect, useState } from "react";
import { SearchInput } from "components/SearchInput";

import * as S from "./styles";
import { api } from "services/api";
import { useToast } from "hooks/toast";
import { Button } from "components/Button";
import { EmptyPage } from "components/EmptyPage";
import { PmocServiceContent } from "../PmocServiceContent";
import { searchDatalayer } from "utils/pushDataLayer";

interface PmocServiceProps {
  createServiceModal: () => void;
  editServiceModal: (currentService: PMOCServiceProps) => void;
  handleToogleFormPmoc: (value: boolean) => void;
  loadServicesPmoc: () => void;
  pmocs: PMOCServiceProps[];
}

const PmocService = ({
  createServiceModal,
  editServiceModal,
  loadServicesPmoc,
  pmocs,
}: PmocServiceProps) => {
  const { addToast } = useToast();
  const [servicesPmoc, setServicesPmoc] = useState<PMOCServiceProps[]>([]);
  const [searchInput, setSearchInput] = useState("");

  useEffect(() => {
    setServicesPmoc(pmocs);
  }, [pmocs]);

  function handleSearchService(text: string) {
    setSearchInput(text);

    const filteredServices = pmocs.filter((service) => {
      const serviceLowerCase = service.serviceType.name.toLowerCase();
      const searchValue = text.toLowerCase();

      return serviceLowerCase.includes(searchValue);
    });

    searchDatalayer({ search_term: text, success: true });

    setServicesPmoc(filteredServices);
  }

  function handleSearchCancel() {
    setSearchInput("");
    setServicesPmoc(pmocs);
  }

  async function handleDeleteService(idService: number) {
    try {
      await api.delete(`/service/${idService}`);

      addToast({
        title: "Sucesso",
        description: "Serviço deletado com sucesso",
        type: "success",
      });

      loadServicesPmoc();
    } catch {
      addToast({
        title: "Opss!",
        description: "Não foi possível deletar o item",
        type: "error",
      });
    }
  }

  const getEditCurrentService = (currentService: PMOCServiceProps) => {
    editServiceModal(currentService);
  };

  return (
    <S.Container>
      <SearchInput
        searchValue={searchInput}
        onChange={(e) => handleSearchService(e.target.value)}
        placeholder="Busque pelo nome do serviço"
        handleCancel={handleSearchCancel}
      />

      <div style={{ marginBottom: "20px" }}>
        <Button typeButton="outline" onClick={createServiceModal}>
          Criar novo serviço de PMOC
        </Button>
      </div>

      {servicesPmoc.length === 0 && <EmptyPage />}

      {servicesPmoc.map((currentService) => (
        <PmocServiceContent
          key={currentService.id}
          currentService={currentService}
          onDeleteService={handleDeleteService}
          onEditServicePmoc={getEditCurrentService}
        />
      ))}
    </S.Container>
  );
};
export { PmocService };
