import React, { ButtonHTMLAttributes } from "react";

import { switchSVG } from "./switchSVG";
import loadingSvg from "assets/loading.svg";

import * as S from "./styles";

type ButtonSVGProps = ButtonHTMLAttributes<HTMLButtonElement> & {
  title: string;
  loading?: boolean;
  typeSvg: "client" | "model" | "modelDisabled" | "pdf" | "pdfDisabled";
};

export function ButtonSVG({
  title,
  loading,
  typeSvg,
  ...rest
}: ButtonSVGProps) {
  const ImageSvg = switchSVG(typeSvg);

  return (
    <S.Container typeSvg={typeSvg} {...rest}>
      {loading ? (
        <img src={loadingSvg} alt="carregando..." id="loading" />
      ) : (
        <>
          <img src={ImageSvg} alt={title} />
          <span>{title}</span>
        </>
      )}
    </S.Container>
  );
}
