/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useState } from "react";
import { FiX } from "react-icons/fi";
import { useTheme } from "styled-components";

import { api } from "services/api";
import addMaskMoney from "utils/addMaskMoney";

import { useToast } from "hooks/toast";
import { LocalChecklistProps } from "dtos/LocalChecklistDTO";
import { useSelectedChecklist } from "hooks/budget/selectedLocalChecklist";
import { LocalChecklistShimmer } from "Shimmer/LocalChecklistShimmer";

import { Button } from "components/Button";
import { SearchInput } from "components/SearchInput";
import { CardLocalChecklist } from "../CardLocalChecklist";

import * as S from "./styles";
import { searchDatalayer } from "utils/pushDataLayer";

type ListLocalChecklistProps = {
  handleToggleOpen: () => void;
  handleCreateChecklist: () => void;
};

type StatusProps = {
  label: string;
  type: "client_responsibility" | "my_responsibility" | "available";
};

type LocalChecklistResponse = {
  id: number;
  category_id: number;
  name: string;
  value: number;
  status: StatusProps[];
  type: string;
  quantity: number;
  total: number;
  formattedTotal: string;
  isChecked: boolean;
};

export function ListLocalChecklist({
  handleToggleOpen,
  handleCreateChecklist,
}: ListLocalChecklistProps) {
  const theme = useTheme();
  const { addToast } = useToast();
  const { handleSetSelectedChecklist } = useSelectedChecklist();

  const [searchInput, setSearchInput] = useState("");
  const [loading, setLoading] = useState(true);

  const [checklistResponse, setChecklistResponse] = useState<
    LocalChecklistProps[]
  >([]);

  const [listItemsChecklist, setListItemsChecklist] = useState<
    LocalChecklistProps[]
  >([]);

  const [checklistGroupItems, setChecklistGroupItems] = useState<
    LocalChecklistProps[]
  >([]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  async function fetchItemsChecklist() {
    try {
      const { data } = await api.get("/checklist");

      const formattedResponse = data.checklist.map(
        (checklistItem: LocalChecklistResponse) => ({
          id: Number(checklistItem.id),
          categoryID: checklistItem.category_id,
          name: checklistItem.name,
          status: checklistItem.status,
          value: Number(checklistItem.value),
        })
      );

      setChecklistResponse(formattedResponse);
      setListItemsChecklist(formattedResponse);
    } catch (error) {
      addToast({
        title: "Erro ao criar checklist",
        type: "error",
      });
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    fetchItemsChecklist();
  }, [addToast]);

  function handleSelectChecklist(checklist: LocalChecklistProps) {
    const isAlreadyExistOnChecklist = checklistGroupItems.find(
      (i) => i.id === checklist.id
    );

    if (isAlreadyExistOnChecklist) {
      const payloadChecklist = checklistGroupItems.map((currentChecklist) => {
        if (currentChecklist.id === checklist.id) {
          return checklist;
        }

        return currentChecklist;
      });

      setChecklistGroupItems(payloadChecklist);
    } else {
      setChecklistGroupItems((oldChecklist) => [...oldChecklist, checklist]);
    }
  }

  const handleRemoveSelectLocalChecklist = useCallback(
    (checklistId: number) => {
      const filtredLocalChecklist = checklistGroupItems.filter(
        (currentLocalChecklist) => currentLocalChecklist.id !== checklistId
      );

      setChecklistGroupItems(filtredLocalChecklist);
    },
    [checklistGroupItems]
  );

  const handleUpdatePriceLocalChecklist = useCallback(
    (id: number, price: number) => {
      const updatedSelectedChecklist = checklistGroupItems.map(
        (selectedChecklist: LocalChecklistProps) => {
          if (selectedChecklist.id === id) {
            return {
              ...selectedChecklist,
              value: price,
              total: price * selectedChecklist.quantity,
              formattedTotal: addMaskMoney(price * selectedChecklist.quantity),
            };
          }

          return selectedChecklist;
        }
      );

      setChecklistGroupItems(updatedSelectedChecklist);
    },
    [checklistGroupItems]
  );

  function handleConfirmLocalChecklist() {
    let validationChecklist = true;

    const parseChecklistBeforeSend = checklistGroupItems.map((checklist) => {
      if (checklist.isChecked && checklist.type.length <= 0) {
        addToast({
          title: "Revise os itens",
          description: `Defina um status para o item ${checklist.name}`,
          type: "error",
        });

        validationChecklist = false;
      }

      if (!checklist.isChecked && checklist.type.length > 0) {
        addToast({
          title: "Revise os itens",
          description: `O item ${checklist.name} não foi marcado no checkbox`,
          type: "error",
        });

        validationChecklist = false;
      }

      return {
        ...checklist,
        // eslint-disable-next-line no-extra-boolean-cast
        total: !!checklist.total ? checklist.total : checklist.value,
        // eslint-disable-next-line no-extra-boolean-cast
        formattedTotal: !!checklist.total
          ? addMaskMoney(checklist.total)
          : addMaskMoney(checklist.value),
      };
    });
    if (validationChecklist) {
      handleSetSelectedChecklist(parseChecklistBeforeSend);
      handleToggleOpen();
    }
  }

  function handleSearchLocalChecklist(text: string) {
    setSearchInput(text);

    const filteredMaterials = listItemsChecklist.filter((checklist) => {
      const materialLowerCase = checklist.name.toLowerCase();

      const searchValue = text.toLowerCase();

      return materialLowerCase.indexOf(searchValue) > -1;
    });

    if (text) {
      searchDatalayer({ search_term: text, success: true });
    }

    setChecklistResponse(filteredMaterials);
  }

  function handleSearchCancel() {
    setSearchInput("");
    setChecklistResponse(listItemsChecklist);
  }

  return (
    <>
      <S.Top>
        <S.ButtonClose onClick={() => handleToggleOpen()}>
          <FiX size={10} color={theme.colors.text} />
        </S.ButtonClose>

        <p>Itens adicionais</p>
      </S.Top>

      {loading ? (
        <LocalChecklistShimmer />
      ) : (
        <S.Content>
          <S.WrapperInput>
            <SearchInput
              placeholder="Procure por um item"
              description="Marque os itens conforme a necessidade"
              searchValue={searchInput}
              onChange={(e) => handleSearchLocalChecklist(e.target.value)}
              handleCancel={() => handleSearchCancel()}
            />
          </S.WrapperInput>

          <S.WrapperInput>
            <Button
              typeButton="outline"
              onClick={() => handleCreateChecklist()}
            >
              Criar novo item
            </Button>
          </S.WrapperInput>

          <S.ListCardsLocalChecklist>
            {checklistResponse.map((checklist) => (
              <S.WrapperCard key={checklist.id}>
                <CardLocalChecklist
                  checklist={checklist}
                  handleSelectChecklist={handleSelectChecklist}
                  handleRemoveSelectLocalChecklist={
                    handleRemoveSelectLocalChecklist
                  }
                  handleUpdatePriceLocalChecklist={
                    handleUpdatePriceLocalChecklist
                  }
                />
              </S.WrapperCard>
            ))}
          </S.ListCardsLocalChecklist>

          <S.Footer>
            <Button onClick={() => handleConfirmLocalChecklist()}>
              <span style={{ opacity: 0.7 }}>
                {checklistGroupItems.length > 1
                  ? `${checklistGroupItems.length} itens selecionados`
                  : `${checklistGroupItems.length} item selecionado`}
              </span>
              <span style={{ margin: "0 30px" }}>|</span>
              Adicionar item
            </Button>
          </S.Footer>
        </S.Content>
      )}
    </>
  );
}
