import styled, { css } from "styled-components";

type ButtonProps = {
  isSelected: boolean;
};

type ImageProps = {
  roundedImage: boolean;
};

type TitleProps = {
  boldTitle: boolean;
};

export const Container = styled.button<ButtonProps>`
  ${({ theme, isSelected, disabled }) => css`
    width: 100%;
    background-color: ${isSelected
      ? theme.colors.primary_opacity
      : theme.colors.menu_background};
    border-radius: 10px;
    padding: 5px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 5px;
    cursor: ${disabled ? "default" : "pointer"};
  `}
`;

export const Content = styled.div`
  display: flex;
  align-items: center;
`;

export const Image = styled.img<ImageProps>`
  ${({ roundedImage }) => css`
    width: 35px;
    height: 35px;
    border-radius: ${roundedImage ? "20px" : "10px"};
  `}
`;

export const Default = styled.div`
  ${({ theme }) => css`
    width: 35px;
    height: 35px;
    border-radius: 20px;
    background-color: ${theme.colors.yellow_opacity};
  `}
`;

export const Title = styled.p<TitleProps>`
  ${({ theme, boldTitle }) => css`
    font-family: ${theme.font.family};
    font-size: ${theme.font.sizes.xsmall};
    font-weight: ${boldTitle ? theme.font.bold : theme.font.normal};
    color: ${theme.colors.text_light_87};
    margin-left: 10px;
  `}
`;

export const Subtitle = styled.p`
  ${({ theme }) => css`
    font-family: ${theme.font.family};
    font-size: ${theme.font.sizes.xsmall};
    color: ${theme.colors.text_light_60};
    margin-right: 5px;
  `}
`;
