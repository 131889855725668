import { ModalPageProps } from "components/ModalPageClients/types";
import { EquipmentDTO } from "dtos/PmocEnvironmentDTO";
import { useEnvironment } from "hooks/pmoc/environmentPmoc";
import React, { useEffect, useState } from "react";
import { EnvironmentPageProps } from "../EnvironmentFlow/types";

import { FiChevronLeft } from "react-icons/fi";

//import QRCodeIcon from 'assets/icons/pmoc/qrCodeBorder.svg';

import * as S from "./styles";
import { useToast } from "hooks/toast";
import { useTheme } from "styled-components";
import { api } from "services/api";
import { Dropdown, DropdownOption } from "components/DropDown";
import { Input } from "components/Input";
import { ToogleButton } from "components/ToogleButton";
import { Button } from "components/Button";

import * as Yup from "yup";
import getValidationErrors from "utils/getValidationErrors";
import axios from "axios";
import { LoadingProfiz } from "components/LoadingProfiz";
import { InputAddNewCategory } from "components/InputAddNewCategory";
import { DisabledDropDown } from "components/DisabledDropDown";

type Errors = {
  [key: string]: string;
};

type PageProps = {
  handleChooseEditModal?: ModalPageProps;
  handleChooseEnvironment?: EnvironmentPageProps;
};

type EquipmentTypeProps = {
  id: number;
  name: string;
  default?: string | undefined;
};

type CapabilitiesProps = {
  id: number;
  name: string;
  default?: string | undefined;
};

type EquipmentProps = {
  id: number;
  name: string;
  default: boolean;
  types: EquipmentTypeProps[];
  capabilities: CapabilitiesProps[];
};

type EquipmentSubProps = {
  id: number;
  name: string;
  default: boolean;
  types: EquipmentTypeProps[];
  capacities: CapabilitiesProps[];
};

type CategoryProps = {
  equipments: EquipmentProps[];
} & CapabilitiesProps;

export function CreateEquipment({
  handleChooseEditModal,
  handleChooseEnvironment,
}: PageProps) {
  const { addToast } = useToast();
  const theme = useTheme();

  const { environment, nameFlow, unityIdPMOC, handleSetEquipmentId } =
    useEnvironment();

  const [brand, setBrand] = useState("");
  const [model, setModel] = useState("");
  const [tagEquipment, setTagEquipment] = useState("");
  const [componentName, setComponentName] = useState("");
  const [isComponentName, setIsComponentName] = useState(false);

  //const [typeTagEquipment, setTypeTagEquipment] = useState(1);

  const [categoryProps, setCategoryProps] = useState<DropdownOption[]>([]);
  const [equipmentsData, setEquipmentsData] = useState<DropdownOption[]>([]);
  const [capacitiesProps, setCapacitiesProps] = useState<DropdownOption[]>([]);

  const [loadingModal, setLoadingModal] = useState(false);

  const [equipmentTypeProps, setEquipmentTypeProps] = useState<
    DropdownOption[]
  >([]);

  const [categoryIdDropdown, setCategoryIdDropdown] = useState<DropdownOption>(
    {} as DropdownOption
  );

  const [equipmentIdDropdown, setEquipmentIdDropdown] =
    useState<DropdownOption>({} as DropdownOption);
  const [equipmentTypeIdDropdown, setEquipmentTypeIdDropdown] =
    useState<DropdownOption>({} as DropdownOption);
  const [capacityIdDropdown, setCapacityIdDropdown] = useState<DropdownOption>(
    {} as DropdownOption
  );

  const [hasError, setHasError] = useState<Errors>({} as Errors);
  const [categoryTree, setCategoryTree] = useState<CategoryProps[]>([]);
  const [equipmentSubTree, setEquipmentSubTree] = useState<EquipmentSubProps[]>(
    []
  );
  const [loadingButton, setLoadingButton] = useState(false);

  const [loadingInputButton, setLoadingInputButton] = useState(false);
  const [newEquipmentType, setNewEquipmentType] = useState("");
  const [newCapacity, setNewCapacity] = useState("");
  const [newEquipment, setNewEquipment] = useState("");

  useEffect(() => {
    SetInfoDropdown();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function SetInfoDropdown() {
    try {
      setLoadingModal(true);
      const response = await api.get("pmoc/equipment/items");

      setCategoryTree(response.data.categoryTree);

      const categoryInfo = response.data.categoryTree.map(
        (element: CategoryProps) => {
          return {
            id: element.id,
            name: element.name,
          };
        }
      );

      setCategoryProps(categoryInfo);
    } catch (error) {
      addToast({
        title: "Erro",
        description:
          axios.isAxiosError(error) && error.response?.data.error
            ? error.response.data.error
            : "Não foi possível carregar as informações.",
        type: "error",
      });
    } finally {
      setLoadingModal(false);
    }
  }

  function handleBackButton() {
    if (!categoryProps || categoryProps.length < 1) {
      return;
    }

    if (nameFlow === "unity" && handleChooseEditModal) {
      return handleChooseEditModal("detailEnvironment");
    }

    if (nameFlow === "pmoc" && handleChooseEnvironment) {
      return handleChooseEnvironment("detailEnvironment");
    }
  }

  function handleSelectedCategory(category: DropdownOption) {
    if (categoryIdDropdown && categoryIdDropdown.id === category.id) return;

    setCategoryIdDropdown(category);

    setEquipmentIdDropdown({} as DropdownOption);
    setEquipmentTypeIdDropdown({} as DropdownOption);
    setCapacityIdDropdown({} as DropdownOption);

    let catInfo: CategoryProps =
      categoryTree.find(
        (element: CategoryProps) => Number(element.id) === Number(category.id)
      ) || ({} as CategoryProps);

    if (Object.keys(catInfo).length > 0) {
      let catInfoFormatted = JSON.parse(JSON.stringify(catInfo.equipments)).map(
        (eqt: EquipmentProps) => {
          return {
            ...eqt,
            capacities: eqt.capabilities,
          };
        }
      );
      setEquipmentSubTree(catInfoFormatted);
    }
    if (catInfo && catInfo.equipments) {
      let aux = JSON.parse(
        JSON.stringify(catInfo.equipments)
      ) as DropdownOption[];
      setEquipmentsData(aux);
    }
    setHasError((errors) => ({
      ...errors,
      hasErrorOnCategory: "",
    }));
  }

  function handleSelectedEquipment(equipmentOption: DropdownOption) {
    if (equipmentIdDropdown && equipmentIdDropdown.id === equipmentOption.id) {
      return;
    }

    setEquipmentIdDropdown(equipmentOption);
    setEquipmentTypeIdDropdown({} as DropdownOption);
    setCapacityIdDropdown({} as DropdownOption);

    let eqtoTree = equipmentSubTree.find(
      (eqto) => eqto.id === equipmentOption.id
    );

    if (eqtoTree) {
      eqtoTree.types && setEquipmentTypeProps(eqtoTree.types);
      eqtoTree.capacities && setCapacitiesProps(eqtoTree.capacities);
    }
    setHasError((errors) => ({
      ...errors,
      hasErrorOnEquipment: "",
    }));
  }

  function handleSelectedCapacity(capacity: DropdownOption) {
    if (capacityIdDropdown && capacityIdDropdown.id === capacity.id) {
      return;
    }

    setCapacityIdDropdown(capacity);
  }

  function handleSelectedEquipmentType(equipmentType: DropdownOption) {
    if (
      equipmentTypeIdDropdown &&
      equipmentTypeIdDropdown.id === equipmentType.id
    ) {
      return;
    }

    setEquipmentTypeIdDropdown(equipmentType);
  }

  function EquipmentCreate() {
    let obj: EquipmentDTO = {
      categoryId: parseInt(categoryIdDropdown.id.toString()),
      equipmentId: parseInt(equipmentIdDropdown.id.toString()),
      equipmentTypeId:
        equipmentTypeIdDropdown && equipmentTypeIdDropdown.id
          ? parseInt(equipmentTypeIdDropdown.id.toString())
          : -1,
      capacityId: capacityIdDropdown.id
        ? parseInt(capacityIdDropdown.id.toString())
        : -1,
      brand: brand,
      model: model,
      tagEquipment: tagEquipment,
      componentName: componentName,
    };

    if (!equipmentTypeIdDropdown || obj.equipmentTypeId === -1) {
      delete obj.equipmentTypeId;
    }
    if (!capacityIdDropdown || obj.capacityId === -1) {
      delete obj.capacityId;
    }
    if (!brand || brand === "") {
      delete obj.brand;
    }
    if (!brand || brand === "") {
      delete obj.brand;
    }

    if (!model || model === "") {
      delete obj.model;
    }
    if (!componentName || componentName === "") {
      delete obj.componentName;
    }

    if (!tagEquipment || tagEquipment === "") {
      delete obj.tagEquipment;
    }

    return obj;
  }

  function handleErrorsValidate() {
    let errorValidate = false;
    if (!categoryIdDropdown || Object.keys(categoryIdDropdown).length < 1) {
      setHasError((errors) => ({
        ...errors,
        hasErrorOnCategory: "Categoria é obrigatório",
      }));
      errorValidate = true;
    }
    if (!equipmentIdDropdown || Object.keys(equipmentIdDropdown).length < 1) {
      setHasError((errors) => ({
        ...errors,
        hasErrorOnEquipment: "Equipamento é obrigatório",
      }));
      errorValidate = true;
    }
    if (isComponentName && componentName.trim().length < 1) {
      setHasError((errors) => ({
        ...errors,
        hasErrorOnComponentName: "Nome do componente é obrigatório",
      }));
      errorValidate = true;
    }
    if (!tagEquipment || tagEquipment.length < 9) {
      setHasError((errors) => ({
        ...errors,
        hasErrorOnTagEquipment:
          "Tag do equipamento é obrigatório e deve ter 9 caracteres",
      }));

      errorValidate = true;
    }
    return errorValidate;
  }

  async function handleSaveEquipment() {
    try {
      setLoadingButton(true);

      if (handleErrorsValidate()) {
        addToast({
          title: "Ops!!",
          description: "Preencha todos os campos obrigatórios!",
          type: "error",
        });
        return;
      }

      const aux = EquipmentCreate();

      const { data } = await api.post(
        `/unities/${unityIdPMOC}/environment/${environment.id}/equipment`,
        aux
      );

      handleSetEquipmentId(Number(data.id));

      addToast({
        title: "Ok!!",
        description: "Equipamento criado com sucesso!",
        type: "success",
      });

      if (nameFlow === "unity" && handleChooseEditModal) {
        return handleChooseEditModal("detailEquipment");
      }

      if (nameFlow === "pmoc" && handleChooseEnvironment) {
        return handleChooseEnvironment("detailEquipment");
      }
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        const errors = getValidationErrors(err);
        setHasError(errors);

        addToast({
          title: "Ops!!",
          description: "Preencha todos os campos obrigatórios!",
          type: "error",
        });
        return;
      }
      addToast({
        title: "Erro",
        description:
          axios.isAxiosError(err) && err.response?.data.error
            ? err.response.data.error
            : "Ocorreu um erro ao criar um novo equipamento.",
        type: "error",
      });
    } finally {
      setLoadingButton(false);
    }
  }

  function handleNewEquipmentType(text: string) {
    setNewEquipmentType(text);
  }

  async function handleSubmitTypeEqto() {
    if (!newEquipmentType) {
      addToast({
        title: "Ops",
        description: "Preencha com alguma informação antes de prosseguir",
        type: "error",
      });
      return;
    }

    try {
      setLoadingInputButton(true);
      const response = await api.post(
        `/services/equipment/${equipmentIdDropdown.id}/type`,
        {
          name: newEquipmentType,
        }
      );

      const { types } = response.data;

      setEquipmentTypeProps([...equipmentTypeProps, types[types.length - 1]]);

      setNewEquipmentType("");

      addToast({
        title: "Sucesso",
        description: "Tipo de equipamento cadastrado com sucesso!",
        type: "success",
      });
    } catch (err) {
      addToast({
        title: "Ops",
        description: "Ocorreu um erro ao cadastrar",
        type: "error",
      });
    } finally {
      setLoadingInputButton(false);
    }
  }

  function handleNewCapacity(text: string) {
    setNewCapacity(text);
  }

  async function handleSubmitCapacity() {
    if (!newCapacity) {
      addToast({
        title: "Ops",
        description: "Preencha com alguma informação antes de prosseguir",
        type: "error",
      });
      return;
    }

    try {
      setLoadingButton(true);
      const response = await api.post("services/capacities", {
        equipmentID: equipmentIdDropdown.id,
        name: newCapacity,
      });

      const capacityValue = response.data;

      setCapacitiesProps([...capacitiesProps, capacityValue]);
      setNewCapacity("");

      addToast({
        title: "Sucesso",
        description: "Capacidade cadastrada com sucesso!",
        type: "success",
      });
    } catch (err) {
      addToast({
        title: "Ops",
        description: "Ocorreu um erro ao cadastrar",
        type: "error",
      });
    } finally {
      setLoadingButton(false);
    }
  }

  function handleNewEquipment(text: string) {
    setNewEquipment(text);
  }

  async function handleSubmitEqto() {
    if (!newEquipment) {
      addToast({
        title: "Ops",
        description: "Preencha com alguma informação antes de prosseguir",
        type: "error",
      });
      return;
    }

    try {
      setLoadingButton(true);
      const response = await api.post("services/equipment", {
        categoryID: Number(categoryIdDropdown.id),
        name: newEquipment,
      });

      const { id, name } = response.data;

      setEquipmentSubTree([
        ...equipmentSubTree,
        {
          id,
          name,
          types: [],
          capacities: [],
          default: false,
        },
      ]);

      setEquipmentsData([...equipmentsData, { id, name, default: "false" }]);

      setNewEquipment("");

      addToast({
        title: "Sucesso",
        description: "Equipamento cadastrado com sucesso!",
        type: "success",
      });
    } catch (err) {
      addToast({
        title: "Ops",
        description: "Ocorreu um erro ao cadastrar",
        type: "error",
      });
    } finally {
      setLoadingButton(false);
    }
  }

  const handleWarningTypeEqt = () => {
    if (!equipmentIdDropdown.id) {
      addToast({
        title: "Atenção!",
        description: "Selecione um equipamento",
        type: "info",
      });
    }
  };

  const handleWarningEqto = () => {
    if (!categoryIdDropdown.id) {
      addToast({
        title: "Atenção!",
        description: "Selecione uma categoria",
        type: "info",
      });
    }
  };

  const handleWarningCapacity = () => {
    if (!equipmentIdDropdown.id) {
      addToast({
        title: "Atenção!",
        description: "Selecione um equipamento",
        type: "info",
      });
    }
  };

  return (
    <>
      {loadingModal ? (
        <LoadingProfiz isVisible={loadingModal} />
      ) : (
        <S.Container>
          <S.Top>
            <S.BackButton
              onClick={() => {
                handleBackButton();
              }}
            >
              <FiChevronLeft size={16} color={theme.colors.text} />
            </S.BackButton>

            <S.Title>Novo Equipamento</S.Title>
          </S.Top>

          <S.Wrapper>
            <Dropdown
              label="Categoria*"
              placeholder="Selecione uma categoria"
              options={categoryProps}
              categoryIdSelected={categoryIdDropdown.id}
              // eslint-disable-next-line react/jsx-no-bind
              onClickedValue={handleSelectedCategory}
              hasError={hasError.hasErrorOnCategory}
            />
          </S.Wrapper>

          <S.Wrapper>
            {categoryIdDropdown && categoryIdDropdown.id > 0 ? (
              <Dropdown
                label="Equipamento*"
                type="equipment"
                hasInput
                placeholder="Selecione um equipamento"
                options={equipmentsData}
                categoryIdSelected={equipmentIdDropdown.id}
                // eslint-disable-next-line react/jsx-no-bind
                onClickedValue={handleSelectedEquipment}
                hasError={hasError.hasErrorOnEquipment}
                moduleHashValue="equipment"
                actionView
              >
                <InputAddNewCategory
                  isLoadingButton={loadingInputButton}
                  value={newEquipment}
                  // eslint-disable-next-line react/jsx-no-bind
                  handleChangeNewCategory={handleNewEquipment}
                  // eslint-disable-next-line react/jsx-no-bind
                  handleSave={handleSubmitEqto}
                />
              </Dropdown>
            ) : (
              <DisabledDropDown
                label="Equipamento*"
                title="Selecione um equipamento"
                onClick={() => handleWarningEqto()}
                hasError={hasError.hasErrorOnEquipment}
              />
            )}
          </S.Wrapper>

          <S.Wrapper>
            <S.WrapperDualInput>
              <S.WrapperInputSmall>
                {equipmentIdDropdown && equipmentIdDropdown.id > 0 ? (
                  <Dropdown
                    label="Tipo"
                    placeholder="Tipo do equipamento"
                    hasInput
                    type="type"
                    options={equipmentTypeProps}
                    categoryIdSelected={equipmentTypeIdDropdown.id}
                    clearDropDownItem
                    // eslint-disable-next-line react/jsx-no-bind
                    onClickedValue={handleSelectedEquipmentType}
                    handleClear={() => {
                      handleSelectedEquipmentType({} as DropdownOption);
                    }}
                    moduleHashValue="equipment-type"
                    actionView
                  >
                    <InputAddNewCategory
                      isLoadingButton={loadingInputButton}
                      value={newEquipmentType}
                      // eslint-disable-next-line react/jsx-no-bind
                      handleChangeNewCategory={handleNewEquipmentType}
                      // eslint-disable-next-line react/jsx-no-bind
                      handleSave={handleSubmitTypeEqto}
                    />
                  </Dropdown>
                ) : (
                  <DisabledDropDown
                    label="Tipo"
                    title="Tipo de equipamento"
                    onClick={() => handleWarningTypeEqt()}
                  />
                )}
              </S.WrapperInputSmall>
              <S.WrapperInputSmall>
                {equipmentIdDropdown && equipmentIdDropdown.id > 0 ? (
                  <Dropdown
                    label="Capacidade"
                    hasInput
                    type="capacity"
                    placeholder="Capacidade do equipamento"
                    options={capacitiesProps}
                    clearDropDownItem
                    categoryIdSelected={capacityIdDropdown.id}
                    // eslint-disable-next-line react/jsx-no-bind
                    onClickedValue={handleSelectedCapacity}
                    handleClear={() => {
                      handleSelectedCapacity({} as DropdownOption);
                    }}
                    moduleHashValue="equipment-capacity"
                    actionView
                  >
                    <InputAddNewCategory
                      isLoadingButton={loadingButton}
                      value={newCapacity}
                      // eslint-disable-next-line react/jsx-no-bind
                      handleChangeNewCategory={handleNewCapacity}
                      // eslint-disable-next-line react/jsx-no-bind
                      handleSave={handleSubmitCapacity}
                    />
                  </Dropdown>
                ) : (
                  <DisabledDropDown
                    label="Capacidade"
                    title="Capacidade do equipamento"
                    onClick={() => handleWarningCapacity()}
                  />
                )}
              </S.WrapperInputSmall>
            </S.WrapperDualInput>
          </S.Wrapper>

          <S.Wrapper>
            <S.WrapperDualInput>
              <S.WrapperInputSmall>
                <Input
                  name="Marca"
                  placeholder="Ex.: Mídea"
                  value={brand}
                  onChange={(e) => setBrand(e.target.value.trimStart())}
                />
              </S.WrapperInputSmall>
              <S.WrapperInputSmall>
                <Input
                  name="Modelo"
                  placeholder="Ex.: Maxiflex Inverter"
                  value={model}
                  onChange={(e) => setModel(e.target.value.trimStart())}
                />
              </S.WrapperInputSmall>
            </S.WrapperDualInput>
          </S.Wrapper>

          <S.WrapperInput>
            <S.Title>Tags do equipamento</S.Title>
            <S.MessageTag>Inserir tags no equipamento*</S.MessageTag>
            {/* <S.WrapperTagButton>
                <S.ContainerTagButton>
                  <Button
                    typeButton={typeTagEquipment < 2 ? "default" : "disabled"}
                    onClick={() => {
                      setTypeTagEquipment(1);
                    }}
                  >
                  Automaticamente
                  </Button>
                </S.ContainerTagButton>
                <S.ContainerTagButton>
                  <Button
                    typeButton={typeTagEquipment > 1 ? "default" : "disabled"}
                    onClick={() => {
                      setTypeTagEquipment(2);
                    }}
                  >
                  Manualmente
                  </Button>
                  </S.ContainerTagButton>
              </S.WrapperTagButton> */}

            <Input
              isCheckedIcon
              maxLength={9}
              name="Definir uma tag com 9 caracteres"
              placeholder="Ex.: LEV-01001"
              value={tagEquipment}
              onChange={(e) => setTagEquipment(e.target.value.trim())}
              hasError={hasError.hasErrorOnTagEquipment}
              onFocusClearError={() =>
                setHasError({ ...hasError, hasErrorOnTagEquipment: "" })
              }
            />
          </S.WrapperInput>

          <S.WrapperInput>
            <S.Title>Componentes</S.Title>
            <S.WrapperDualInput>
              <S.SubTitle>Cadastrar componentes individualmente?</S.SubTitle>
              <ToogleButton
                isChecked={isComponentName}
                onChange={() => {
                  setIsComponentName(!isComponentName);
                }}
              />
            </S.WrapperDualInput>
            {isComponentName && (
              <S.Wrapper>
                <Input
                  name="Nome do componente*"
                  placeholder="Ex.: Condensadora"
                  value={componentName}
                  onChange={(e) => setComponentName(e.target.value.trimStart())}
                  hasError={hasError.hasErrorOnComponentName}
                  onFocusClearError={() =>
                    setHasError({ ...hasError, hasErrorOnComponentName: "" })
                  }
                />
              </S.Wrapper>
            )}
          </S.WrapperInput>

          <S.WrapperInput marginTop={10}>
            <Button
              typeButton="default"
              onClick={handleSaveEquipment}
              loading={loadingButton}
              disabled={loadingButton}
            >
              Criar equipamento
            </Button>
          </S.WrapperInput>
        </S.Container>
      )}
    </>
  );
}
