import styled, { css } from "styled-components";

export const Top = styled.div`
  ${({ theme }) => css`
    margin-bottom: 20px;

    & > div:first-of-type {
      display: flex;
      align-items: center;
      margin-bottom: 14px;
    }

    h4 {
      font-size: ${theme.font.sizes.large};
      color: ${theme.colors.title};
      font-weight: ${theme.font.bold};
    }
  `}
`;

export const InfoText = styled.p`
  ${({ theme }) => css`
    font-size: ${theme.font.sizes.small};
    color: ${theme.colors.text};
    margin-top: 8px;
  `}
`;

export const WrapperStatus = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    justify-content: flex-end;

    & > span {
      font-size: ${theme.font.sizes.small};
      color: ${theme.colors.title};
      margin-right: 20px;
    }

    & > div {
      height: 7px;
      width: 7px;
      border-radius: 50%;
      background-color: ${theme.colors.primary};
      margin-right: 10px;
    }
  `}
`;

export const ButtonClose = styled.button`
  ${({ theme }) => css`
    width: 35px;
    height: 35px;
    background-color: ${theme.colors.background_light};
    border-radius: 10px;

    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 25px;
  `}
`;

export const Content = styled.div`
  margin: 40px 0 120px;
`;

export const Footer = styled.div`
  width: 39%;
  min-width: 590px;
  position: fixed;
  bottom: 5%;
  right: 5%;
`;
