/* eslint-disable import/no-anonymous-default-export */
export default {
  colors: {
    primary: "#D3FF9A",
    primary200: "#B1FE44",
    strong_background: "#121212",
    background: "#212121",
    background_light: "rgba(255, 255, 255, 0.05)",
    background_transparent: "rgba(0, 0, 0, 0.5)",

    menu_background: "#1E1E1E",
    menu_detail: "#272727",
    menu_detail_light: "#353535",

    primary_opacity: "rgba(211, 255, 154, 0.2)",
    primary_opacity_60: "rgba(211, 255, 154, 0.6)",
    white_opacity: "rgba(255, 255, 255, 0.2)",
    yellow_opacity: "rgba(255, 245, 149, 0.2)",
    orange_opacity: "rgba(255, 170, 140, 0.2)",
    purple_opacity: "rgba(184, 149, 255, 0.2)",
    blue_opacity: "rgba(135, 203, 254, 0.2)",
    blue_opacity_60: "rgba(135, 203, 254, 0.6)",
    lilac_opacity: "rgba(184, 149, 255, 0.2)",

    title: "#CFCFCF",
    text: "#9B9B9B",
    background_modal: "#1A1A1A",
    modal_detail: "rgba(220, 220, 220, 0.1)",
    line: "rgba(112, 112, 112, 0.4)",
    pagination_item: "#1e1e1e",

    text_extra_light: "#FFFFFF",

    light: "#eee",

    error: "#FA8B94",

    schedule: "#87CBFE",
    model: "#FFF595",

    google: "#E9E9E9",
    facebook: "#3B5998",

    primary_medium: "#8BC34A",
    primary_light: "#D3FF80",
    subtitle: "#7a7a7a",

    border: "#333333",
    card_container: "#212121",

    detail: "#242424",
    detail_light: "#2F2F2F",
    strong_detail: "#101010",
    bottom_tab_background: "#0f0f0f",

    strong_checkbox: "rgba(75, 75, 75, 0.5)",
    checkbox: "#4b4b4b",

    edit_area: "#373737",

    input: "#2b2b2b",

    text_light: "rgba(233, 233, 233, 0.5)",
    text_light_38: "rgba(255, 255, 255, 0.38)",
    text_light_60: "rgba(255, 255, 255, 0.6)",
    text_light_87: "rgba(255, 255, 255, 0.87)",
    text_invite_60: "rgba(0, 0, 0, 0.6)",

    border_color: "#D8D8D8",

    budget: "#6EBDD6",
    order: "#C86F69",
    manuals: "#C8A269",

    lilac: "#B895FF",
    lilac_light: "#bc9ef7",

    financial_title: "#FFFFFFDE",
    financial_text: "#FFFFFF99",
    financial_card: "#1E1E1E",

    toggle_ball_inactive: "#FFFFFF61",

    pmoc_purple: "#A586E3",
    pmoc_orange: "#FFAA8C",
    pmoc_purple_background: "rgba(165, 134, 227, 0.1)",

    update: "#FFAA8C",

    background_attention: "#FFF5951A",
  },

  grid: {
    container: "130rem",
    gutter: "3.2rem",
    small: "1rem",
  },

  font: {
    family:
      "Inter, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif",
    light: 300,
    normal: 400,
    bold: 600,

    sizes: {
      xsmall: "1.2rem",
      small: "1.4rem",
      medium: "1.6rem",
      large: "1.8rem",
      xlarge: "2.3rem",
      xxlarge: "2.6rem",
      xxxlarge: "2.8rem",
      huge: "5.2rem",
    },
  },

  layers: {
    base: 10,
    overlay: 30,
    modal: 40,
    alwaysOnTop: 50,
  },
};
