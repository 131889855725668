import React from "react";

import { LocalChecklistProps } from "dtos/LocalChecklistDTO";
import { useSelectedChecklist } from "hooks/budget/selectedLocalChecklist";

import { DeleteButton } from "components/DeleteButton";
import { ChecklistWithPriceDetails } from "./ChecklistWithPriceDetails";

import * as S from "./styles";

type Props = {
  checklistItems: LocalChecklistProps[];
  disabled?: boolean;
};

export function CardSelectedLocalChecklist({
  checklistItems,
  disabled,
}: Props) {
  const { handleUpdateQuantity, removeItemChecklist } = useSelectedChecklist();

  function onIncrement(id: number, quantity: number) {
    if (disabled) {
      return;
    }
    handleUpdateQuantity(id, quantity);
  }

  const checkMyResponsability = checklistItems.filter(
    (checklist) => checklist.type === "my_responsibility"
  );

  const checkNotMyResponsability = checklistItems.filter(
    (checklist) => checklist.type !== "my_responsibility"
  );

  return (
    <>
      {checkMyResponsability.map((checklist) => (
        <S.Container>
          <S.Content>
            <ChecklistWithPriceDetails
              key={checklist.id}
              checklist={checklist}
              // eslint-disable-next-line react/jsx-no-bind
              onIncrement={onIncrement}
            />
          </S.Content>
          <S.WrapperActions>
            <DeleteButton
              onClick={() => removeItemChecklist(checklist.id)}
              disabled={disabled}
            />
          </S.WrapperActions>
        </S.Container>
      ))}

      {checkNotMyResponsability.map((checklist) => (
        <S.Container>
          <S.Content>
            <S.ChecklistText>
              {checklist.type === "available"
                ? "Disponivel no local"
                : "A cargo do cliente"}
            </S.ChecklistText>

            <ChecklistWithPriceDetails
              key={checklist.id}
              checklist={checklist}
              // eslint-disable-next-line react/jsx-no-bind
              onIncrement={onIncrement}
            />
          </S.Content>

          <S.WrapperActions>
            <DeleteButton
              onClick={() => removeItemChecklist(checklist.id)}
              disabled={disabled}
            />
          </S.WrapperActions>
        </S.Container>
      ))}
    </>
  );
}
