import { useHistory } from "react-router";

import { Page } from "templates/Page";
import { Button } from "components/Button";

import { useAccount } from "hooks/permission/account";

import LeaveSvg from "assets/icons/permission/leave.svg";

import * as S from "./styles";

export function ExitSuccess() {
  const history = useHistory();
  const { whoami } = useAccount();

  return (
    <Page
      title="Solicitar saída da equipe"
      subtitle=""
      titleAlignedBelow
      hideGoBackButton
    >
      <S.Content>
        <img src={LeaveSvg} alt="Ícone prestador solicitando saída do time" />

        <S.WrapperText>
          <S.Description>
            {whoami?.name} receberá sua solicitação de desligamento da equipe
            <S.Highlight> {whoami?.companyName}</S.Highlight>.
          </S.Description>
        </S.WrapperText>

        <Button onClick={() => history.push("/")}>Ir para a home</Button>
      </S.Content>
    </Page>
  );
}
