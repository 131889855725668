import styled, { css } from "styled-components";

export const Container = styled.div`
  ${({ theme }) => css`
    width: 100%;
    max-width: 1400px;
    min-width: 470px;
    margin: 0 auto;
    padding: 30px;

    header {
      display: flex;
      align-items: center;

      h1 {
        font-size: ${theme.font.sizes.large};
        color: ${theme.colors.title};
        font-weight: ${theme.font.bold};
        margin-left: 25px;
      }

      div {
        width: 80px;
        display: flex;
        justify-content: space-between;
      }
    }
  `}
`;

export const WrapperArchivedText = styled.div`
  ${({ theme }) => css`
    margin-left: 27%;

    p {
      color: ${theme.colors.text_light};
      font-size: 13px;
    }
  `}
`;

export const Wrapper = styled.div`
  ${({ theme }) => css`
    margin-top: 30px;

    p {
      margin-bottom: 5px;
      color: ${theme.colors.text};
      font-size: ${theme.font.sizes.small};
    }
  `}
`;

export const Content = styled.section``;

export const ContentBoxTitle = styled.h4`
  ${({ theme }) => css`
    font-size: ${theme.font.sizes.xsmall};
    color: ${theme.colors.title};
    margin-bottom: 14px;
  `}
`;

export const ContentBoxText = styled.p`
  ${({ theme }) => css`
    color: ${theme.colors.text};
    font-size: ${theme.font.sizes.xsmall};
    margin-bottom: 4px;
    white-space: pre-wrap;
  `}
`;

export const ContentBoxObservationText = styled.pre`
  ${({ theme }) => css`
    white-space: pre-wrap;
    color: ${theme.colors.text};
    font-size: ${theme.font.sizes.xsmall};
    margin-bottom: 4px;
    font-family: ${theme.font.family};
  `}
`;

export const ServiceInfoBox = styled.section`
  /* display: flex; */
  p {
    margin-right: 4px;
  }
`;

export const WrapperArchiveButton = styled.div``;

export const ObservationButton = styled.button`
  ${({ theme }) => css`
    color: ${theme.colors.text};
    font-size: 12px;
    text-decoration: underline;
  `}
`;
