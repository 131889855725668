import React, { useEffect, useState } from "react";

import { Dropdown, DropdownOption } from "components/DropDown";
import { InputAddNewCategory } from "components/InputAddNewCategory";
import { useToast } from "hooks/toast";
import { api } from "services/api";
import { mappedDropdown } from "utils/mappedDropDown";

type CompanyBrandProps = {
  hasError?: string;
  handleSelectCompanyBrand: ({ id, name }: DropdownOption) => void;
};

type DropDownProps = {
  id: number;
  name: string;
  default?: string;
};

export function CompanyBrand({
  hasError,
  handleSelectCompanyBrand,
}: CompanyBrandProps) {
  const { addToast } = useToast();

  const [loadingButton, setLoadingButton] = useState(false);

  const [newCompanyBrand, setNewCompanyBrand] = useState("");
  const [companyBrands, setCompanyBrands] = useState<DropDownProps[]>([]);

  async function fetchCompanyBrands() {
    try {
      const response = await api.get("/me/certifications/detail");

      const { companyBrand } = response.data;

      const mappedBrands = mappedDropdown(companyBrand);

      setCompanyBrands(mappedBrands);
    } catch (error) {
      // sendError(error)
      addToast({
        type: "error",
        title: "Erro ao carregar os dados",
        description:
          "Ocorreu um erro ao carregar os dados dos emissores/fabricantes",
      });
    }
  }

  useEffect(() => {
    fetchCompanyBrands();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  function handleChangeNewCategory(text: string) {
    setNewCompanyBrand(text.trimStart());
  }

  async function handleSaveNewCategory() {
    try {
      setLoadingButton(true);

      if (!newCompanyBrand) {
        addToast({
          title: "Campo Vazio",
          description: "Digite um novo emissor/fabricante primeiro",
          type: "info",
        });
        return;
      }

      const { data } = await api.post("/certifications/brands", {
        label: newCompanyBrand,
      });

      setCompanyBrands((item) => [
        ...item,
        {
          id: data.id,
          name: data.label,
          default: "false",
        },
      ]);

      addToast({
        title: "Sucesso ao atualizar",
        description: "Emissor/Fabricante criado com sucesso",
        type: "success",
      });

      setNewCompanyBrand("");
    } catch (error: any) {
      // sendError(error)
      addToast({
        title: "Falha ao atualizar",
        description:
          error.response.data.error ||
          "Não foi possível criar um novo emissor/fabricante",
        type: "error",
      });
    } finally {
      setLoadingButton(false);
    }
  }

  return (
    <Dropdown
      label="Emissor/Fabricante*"
      placeholder="Selecione o emissor/fabricante"
      type="manufacturers"
      options={companyBrands}
      // eslint-disable-next-line react/jsx-no-bind
      onClickedValue={handleSelectCompanyBrand}
      hasInput
      hasError={hasError}
    >
      <InputAddNewCategory
        isLoadingButton={loadingButton}
        value={newCompanyBrand}
        // eslint-disable-next-line react/jsx-no-bind
        handleChangeNewCategory={handleChangeNewCategory}
        // eslint-disable-next-line react/jsx-no-bind
        handleSave={handleSaveNewCategory}
      />
    </Dropdown>
  );
}
