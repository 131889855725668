import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router";
import { useToast } from "hooks/toast";
import axios from "axios";

import NotAcceptIcon from "assets/icons/times-background.svg";
import AcceptIcon from "assets/icons/financial-icons/accept.svg";

import { AccountData, ProfileData } from "dtos/permission/AnswerInviteDTO";
import { LoadingProfiz } from "components/LoadingProfiz";
import { Button } from "components/Button";
import { AlertModal } from "components/AlertModal";
import { ListItemCard, mappedProfileIcons } from "components/Permission";
import * as S from "./styles";

type RouteParams = {
  registerHash: string;
};

type UserType = {
  id: number;
  name: string;
  email: string;
  accountExists: boolean;
};

export function PermissionsPage() {
  const { registerHash } = useParams<RouteParams>();
  const { addToast } = useToast();
  const [loading, setLoading] = useState(false);
  const [profileInfo, setProfileInfo] = useState<ProfileData>(
    {} as ProfileData
  );
  const [accountInfo, setAccountInfo] = useState<AccountData>(
    {} as AccountData
  );
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [userInfo, setUserInfo] = useState<UserType>({} as UserType);

  const [alertModal, setAlertModal] = useState(false);

  const history = useHistory();

  useEffect(() => {
    setData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function setData() {
    await fetch(
      `${process.env.REACT_APP_BASE_URL}/v1/users/teams/answer-invite/${registerHash}`,
      {
        method: "GET",
        headers: {
          "App-Token": `${process.env.REACT_APP_TOKEN_CONNECT_BACKEND}`,
        },
      }
    )
      .then((response) => {
        setLoading(true);
        if (response.ok) return response.json();
        throw new Error("Status code error");
      })
      .then((data) => {
        setAccountInfo(data.account);
        setProfileInfo(data.profile);
        setUserInfo(data.user);
      })
      .catch((_) => {
        addToast({
          title: "Ops!!",
          description:
            "O convite já foi respondido ou o código solicitado é inválido",
          type: "error",
        });

        history.push("/public/404");
      })
      .finally(() => {
        setLoading(false);
      });
  }

  async function handleRefuse() {
    try {
      setAlertModal(false);
      setLoading(true);
      await fetch(
        `${process.env.REACT_APP_BASE_URL}/v1/users/teams/answer-invite/${registerHash}`,
        {
          method: "POST",
          headers: {
            "App-Token": `${process.env.REACT_APP_TOKEN_CONNECT_BACKEND}`,
          },
          body: JSON.stringify({
            accept: false,
          }),
        }
      );

      addToast({
        title: "Ok!!",
        description: "Sucesso ao recusar o convite.",
        type: "success",
      });

      history.push("/permission/public/refuse-invite", {
        accountInfo,
      });
    } catch (error) {
      addToast({
        title: "Ops!!",
        description:
          axios.isAxiosError(error) && error.response?.data.error
            ? error.response.data.error
            : "Ocorreu um erro ao recusar o convite.",
        type: "error",
      });
    } finally {
      setLoading(false);
    }
  }

  async function handleInviteAccept() {
    if (userInfo.accountExists) {
      await fetch(
        `${process.env.REACT_APP_BASE_URL}/v1/users/teams/answer-invite/${registerHash}`,
        {
          method: "POST",
          headers: {
            "App-Token": `${process.env.REACT_APP_TOKEN_CONNECT_BACKEND}`,
          },
          body: JSON.stringify({
            accept: true,
          }),
        }
      );

      addToast({
        title: "Ok!",
        description: "Convite aceito com sucesso!",
        type: "success",
      });

      return history.push("/");
    }
    history.push(`/permission/public/register/${registerHash}`);
  }
  return (
    <>
      {loading ? (
        <LoadingProfiz isVisible={loading} />
      ) : (
        <>
          <AlertModal
            isVisible={alertModal}
            title="Deseja realmente declinar o convite?"
            description="Esta é uma ação irreversível e após isso será necessário receber um novo convite."
            action="choose"
            handleConfirm={handleRefuse}
            onCloseModal={() => setAlertModal(false)}
          />

          <S.Container>
            <S.TeamCard>
              <S.Header>
                <p className="title">Novo convite</p>
                <p className="subtitle">
                  Você foi convidado para fazer parte de uma equipe desta
                  empresa.
                </p>
              </S.Header>
              <S.WrapperInput>
                <ListItemCard
                  image={accountInfo.companyLogo || ""}
                  roundedImage
                  title={accountInfo.companyName || ""}
                  boldTitle
                  disabled
                />
              </S.WrapperInput>
              <S.WrapperInput>
                <S.CardTeamInfo radius={8}>
                  <p className="title">Cargo</p>
                  <div className="card-info">
                    <div className="container">
                      <img
                        src={mappedProfileIcons[profileInfo.id]}
                        alt="icone cargo"
                        height={35}
                      />
                      <p className="info">{profileInfo.name}</p>
                    </div>
                    <p className="status">Pré-definido</p>
                  </div>
                </S.CardTeamInfo>
              </S.WrapperInput>

              <S.WrapperInput>
                <S.TitlePermissions>Permissões</S.TitlePermissions>
                {profileInfo.modules &&
                  profileInfo.modules.map((module, index) => {
                    return (
                      <S.CardPermissions key={index}>
                        <p className="title">{module.title}</p>
                        {module.permissions &&
                          module.permissions.map((permission, index) => {
                            return (
                              <div className="card-info" key={index}>
                                <p className="info">{permission.description}</p>
                                <img
                                  src={
                                    permission.active
                                      ? AcceptIcon
                                      : NotAcceptIcon
                                  }
                                  alt="icone permissao"
                                  height={35}
                                />
                              </div>
                            );
                          })}
                      </S.CardPermissions>
                    );
                  })}
              </S.WrapperInput>

              <S.WrapperInput>
                <Button onClick={handleInviteAccept}>Aceitar convite</Button>
              </S.WrapperInput>
              <S.WrapperInput>
                <Button
                  typeButton="delete"
                  onClick={() => {
                    setAlertModal(true);
                  }}
                >
                  Recusar convite
                </Button>
              </S.WrapperInput>
            </S.TeamCard>
          </S.Container>
        </>
      )}
    </>
  );
}
