import { Route } from "./Route";

import { Register } from "../pages/auth/Register";

export function IndicationRoutes() {
  return (
    <Route
      path="/register/indication/:indicationHash"
      exact
      component={Register}
    />
  );
}
