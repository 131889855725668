import React, { useRef, useState } from "react";
import { useHistory } from "react-router";
import { useTheme } from "styled-components";
import packageJson from "../../../package.json";

import { AlertModal } from "components/AlertModal";
import {
  HasPermissionAlert,
  SelectCurrentAccount,
} from "components/Permission";
import { ShopOptions } from "./ShopOptions";
import { MenuButton } from "./MenuButton";
// import { ManagementOptions } from "./ManagementOptions";

import { useAuth } from "hooks/auth";
import { useMenu } from "hooks/menu";
import { useWarningMessage } from "hooks/warningMessage";

import LogoSVG from "assets/icons/logo-color.svg";
import ArrowLeftSVG from "assets/icons/menu-icons/arrow-left.svg";
import EyeSVG from "assets/icons/menu-icons/eye.svg";
import HomeSVG from "assets/icons/menu-icons/home.svg";
import VersionSVG from "assets/icons/menu-icons/icon-version.svg";
// import ConfigsSVG from "assets/icons/menu-icons/configs.svg";
import ProfitzIcon from "assets/icons/profitz-icon.svg";
import MoneySVG from "assets/icons/menu-icons/money-sign-drawer.svg";
import AvaliationsSVG from "assets/icons/avaliations-icon.svg";
import TeamsSVG from "assets/icons/permission/teams-icon.svg";
import WalletBalanceSVG from "assets/icons/financial-icons/wallet-balance.svg";

import * as S from "./styles";

export type MenuOptions = {
  id: string;
  icon: string;
  title: string;
  link: string;
  typeButton?: "primary" | "secondary";
  colorSelected: string;
};

const AsideLeftMenu: React.FC = () => {
  const theme = useTheme();
  const { user, signOut } = useAuth();
  const { isOpenMenu, handleSetIsOpenMenu } = useMenu();
  const history = useHistory();
  const containerComponent = useRef(null);

  const { modifyBudget, handleSetBudgetMessage, handleSetLinkPage } =
    useWarningMessage();

  const [isOpenAlertModal, setIsOpenAlertModal] = useState(false);

  return (
    <S.Container isOpenMenu={isOpenMenu} ref={containerComponent}>
      <AlertModal
        isVisible={isOpenAlertModal}
        title="Sair do app"
        description="Deseja realmente sair do app Profiz?"
        action="choose"
        labelConfirm="Sair"
        labelCancel="Cancelar"
        handleConfirm={() => {
          setIsOpenAlertModal(false);
          signOut();
        }}
        handleCancel={() => {
          setIsOpenAlertModal(false);
        }}
        onCloseModal={() => {
          setIsOpenAlertModal(false);
        }}
      />

      <S.ButtonToggleMenu
        isOpenMenu={isOpenMenu}
        onClick={() => {
          if (modifyBudget) {
            return handleSetBudgetMessage(true);
          }
          handleSetIsOpenMenu();
        }}
      >
        <img
          src={ArrowLeftSVG}
          alt="imagem de uma seta apontando para esquerda"
        />
      </S.ButtonToggleMenu>

      <S.Content>
        <S.LogoContainer onClick={() => history.push("/")}>
          <img src={LogoSVG} alt="Logo da profiz" />
        </S.LogoContainer>

        <S.ProfileInfo>
          <S.UserPhoto>
            <img src={user.photo} alt="Foto de perfil do usuário" />
          </S.UserPhoto>
          {isOpenMenu && (
            <S.WrapperProfileDetail>
              <S.ProfileDetail>
                <S.UserName>{user.name}</S.UserName>

                <S.WrapperViewProfile
                  onClick={() => {
                    if (modifyBudget) {
                      handleSetLinkPage("/profile");
                      return handleSetBudgetMessage(true);
                    }
                    history.push("/profile");
                  }}
                >
                  <S.ViewProfileText>ver meu perfil</S.ViewProfileText>
                  <img src={EyeSVG} alt="Ícone de um olho" />
                </S.WrapperViewProfile>
              </S.ProfileDetail>

              {window.location.pathname === "/home" && <SelectCurrentAccount />}
            </S.WrapperProfileDetail>
          )}
        </S.ProfileInfo>
        <HasPermissionAlert
          moduleHash="satisfaction-survey"
          actionHash="view"
          navigateTo="/ratings"
        >
          <MenuButton
            id="ratings"
            icon={AvaliationsSVG}
            title="Avaliações"
            isMenuOption
          />
        </HasPermissionAlert>

        <HasPermissionAlert
          navigateTo="/profitz-extract"
          grantPermission={user.profileComplete && user.profileApproved}
        >
          <MenuButton
            id="profitzExtract"
            icon={ProfitzIcon}
            title="Profitz"
            titleColor={theme.colors.model}
            link="/profitz-extract"
            colorSelected={theme.colors.yellow_opacity}
            isMenuOption
          />
        </HasPermissionAlert>
        <HasPermissionAlert
          navigateTo="/teams"
          grantPermission={user.profileComplete && user.profileApproved}
        >
          <MenuButton id="teams" icon={TeamsSVG} title="Equipes" isMenuOption />
        </HasPermissionAlert>

        <S.MenuContainer>
          <MenuButton
            id="home"
            icon={HomeSVG}
            title="Home"
            link="/"
            colorSelected={theme.colors.white_opacity}
            isMenuOption
          />

          <HasPermissionAlert
            moduleHash="financial"
            actionHash="view"
            navigateTo="/financial"
          >
            <MenuButton
              id="financial"
              icon={WalletBalanceSVG}
              title="Financeiro"
              colorSelected={theme.colors.white_opacity}
              isMenuOption
              isNewModule
              textTag="Novo"
              tagColor="#B895FF"
              tagBgColor="rgba(184, 149, 255, 0.15)"
            />
          </HasPermissionAlert>

          {/* <ManagementOptions /> */}

          <HasPermissionAlert
            grantPermission={user.profileComplete && user.profileApproved}
          >
            <> {user.accessMktplace && <ShopOptions />}</>
          </HasPermissionAlert>

          <HasPermissionAlert
            navigateTo="/member-get-member"
            grantPermission={user.profileComplete && user.profileApproved}
          >
            <MenuButton
              id="memberGetMember"
              icon={MoneySVG}
              title="Indique e ganhe"
              link="/member-get-member"
              colorSelected={theme.colors.white_opacity}
              isMenuOption
            />
          </HasPermissionAlert>

          {/* <MenuButton
            id="configurations"
            icon={ConfigsSVG}
            title="Configurações"
            link="/"
            isMenuOption
          />
          */}

          <MenuButton
            id="app-version"
            icon={VersionSVG}
            title={`Versão ${packageJson.version}`}
            link="/app-version-detail"
            colorSelected={theme.colors.white_opacity}
            isMenuOption
          />
        </S.MenuContainer>
      </S.Content>

      <S.ExitButton
        isOpenMenu={isOpenMenu}
        onClick={() => {
          if (modifyBudget) {
            return handleSetBudgetMessage(true);
          }
          setIsOpenAlertModal(true);
        }}
      >
        <p>Sair</p>
      </S.ExitButton>
    </S.Container>
  );
};

export default AsideLeftMenu;
