import React from "react";

import arrowLeft from "assets/icons/arrow-left.svg";

import * as S from "./styles";

type ModalObservationDetailProps = {
  observation: string;
  handleGoBack: () => void;
  isProduct?: boolean;
};

export function ModalObservationDetail({
  observation,
  handleGoBack,
  isProduct = false
}: ModalObservationDetailProps) {
  return (
    <>
      <S.Top>
        <S.ButtonClose onClick={() => handleGoBack()}>
          <img src={arrowLeft} alt="flecha pra esquerda" />
        </S.ButtonClose>

        <p>Detalhamento do {isProduct?"Produto":"Serviço"}</p>
      </S.Top>

      <S.Content>
        <p>{observation}</p>
      </S.Content>
    </>
  );
}
