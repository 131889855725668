import { ModeProps } from "dtos/businessProposalsDTO";
import * as S from "./styles";

import ProductIcon from "assets/myProducts.svg";
import ServiceIcon from "assets/service-order.svg";
import ProductAndServiceIcon from "assets/service-order-yellow.svg";

interface CardModeProps extends React.HTMLAttributes<HTMLButtonElement> {
  title: string;
  description: string;
  mode: ModeProps;
}
export function CardMode({ title, description, mode, ...rest }: CardModeProps) {
  return (
    <S.Container mode={mode} {...rest}>
      {mode === "products" && (
        <img
          src={ProductIcon}
          height={45}
          width={45}
          alt="ícone de uma maleta"
        />
      )}
      {mode === "services" && (
        <img
          src={ServiceIcon}
          alt="ícone de uma maleta"
          height={45}
          width={45}
        />
      )}
      {mode === "products-services" && (
        <img
          src={ProductAndServiceIcon}
          height={45}
          width={45}
          alt="ícone de uma maleta"
        />
      )}

      <S.Title>{title}</S.Title>
      <S.Subtitle>{description}</S.Subtitle>
    </S.Container>
  );
}
