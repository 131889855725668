import React, { useEffect } from "react";

import { FormNewClient } from "components/ModalClient/FormNewClient";
import { ClientDetails } from "./ClientDetails";
import { FormEditClient } from "./FormEditClient";
import { UnityForm } from "components/UnityForm";
import { UnityDetail } from "components/UnityDetail";
import RegisteredUnities from "components/RegisteredUnities";
import { CreateEnvironment } from "pages/pmoc/CreateEnvironment";

import { ModalName } from "./types";
import { DetailEnvironment } from "pages/pmoc/DetailEnvironment";
import { EditEnvironment } from "pages/pmoc/EditEnvironment";
import { CreateEquipment } from "pages/pmoc/CreateEquipment";
import { CreatePhotoEquipment } from "pages/pmoc/CreatePhotoEquipment";
import { DetailEquipment } from "pages/pmoc/DetailEquipment";
import { EditEquipment } from "pages/pmoc/EditEquipment";
import { EditPhotoEquipment } from "pages/pmoc/EditPhotoEquipment";
import {useConfirmModalClient } from 'hooks/budget/confirmModalClient'

type ModalPageClientsProps = {
  modalSelected: ModalName;
  handleToggleOpen: () => void;
  handleChooseEditModal: (modal: ModalName) => void;
};

export function ModalPageClients({
  handleToggleOpen,
  handleChooseEditModal,
  modalSelected,
}: ModalPageClientsProps) {
  const {setModalName} = useConfirmModalClient()
  useEffect(() =>{
    setModalName(modalSelected)
  },[modalSelected, setModalName])

  return (
    <>
      {modalSelected === "createClient" && (
        <FormNewClient module="client" handleToggleOpen={handleToggleOpen} />
      )}

      {modalSelected === "editClient" && (
        <FormEditClient handleSwapListClients={handleChooseEditModal} />
      )}

      {modalSelected === "clientDetails" && (
        <ClientDetails
          handleChooseEditModal={handleChooseEditModal}
          handleToggleOpen={handleToggleOpen}
        />
      )}

      {modalSelected === "unityForm" && (
        <UnityForm handleChooseEditModal={handleChooseEditModal} />
      )}

      {modalSelected === "unityList" && (
        <RegisteredUnities handleChooseEditModal={handleChooseEditModal} />
      )}

      {modalSelected === "unityDetail" && (
        <UnityDetail handleChooseEditModal={handleChooseEditModal} />
      )}

      {modalSelected === "createEnvironment" && (
        <CreateEnvironment handleChooseEditModal={handleChooseEditModal} />
      )}

      {modalSelected === "editEnvironment" && (
        <EditEnvironment handleChooseEditModal={handleChooseEditModal} />
      )}

      {modalSelected === "detailEnvironment" && (
        <DetailEnvironment handleChooseEditModal={handleChooseEditModal} />
      )}

      {modalSelected === "createEquipment" && (
        <CreateEquipment handleChooseEditModal={handleChooseEditModal} />
      )}

      {modalSelected === "createPhotoEquipment" && (
        <CreatePhotoEquipment handleChooseEditModal={handleChooseEditModal} />
      )}

      {modalSelected === "detailEquipment" && (
        <DetailEquipment handleChooseEditModal={handleChooseEditModal} />
      )}

      {modalSelected === "editEquipment" && (
        <EditEquipment handleChooseEditModal={handleChooseEditModal} />
      )}

      {modalSelected === "editPhotoEquipment" && (
        <EditPhotoEquipment handleChooseEditModal={handleChooseEditModal} />
      )}
    </>
  );
}
