import React, { useEffect, useState } from "react";
import { useTheme } from "styled-components";
import { FiX } from "react-icons/fi";

import { useToast } from "hooks/toast";
import {
  useSelectedMaterial,
  SelectedMaterialProps,
} from "hooks/budget/selectedMaterials";
import { useMaterialsForEditingBudget } from "hooks/budget/materialsForEditingBudget";

import { SearchInput } from "components/SearchInput";
import { Button } from "components/Button";

import addMaskMoney from "utils/addMaskMoney";

import { MaterialProps } from "dtos/MaterialDTO";
import { CardMaterial } from "../CardMaterial";

import { api } from "services/api";

import * as S from "./styles";
import { searchDatalayer } from "utils/pushDataLayer";

type ListMaterialsProps = {
  handleToggleModal: () => void;
  handleCreateMaterial: () => void;
  handleEditMaterial: (data: MaterialProps) => void;
};

export function ListMaterials({
  handleToggleModal,
  handleCreateMaterial,
  handleEditMaterial,
}: ListMaterialsProps) {
  const theme = useTheme();
  const { addToast } = useToast();

  const {
    selectedMaterials,
    handleSetSelectedMaterials,
    handleRemoveSelectedMaterial,
  } = useSelectedMaterial();
  const { materialsForEditingBudget, handleRemoveMaterial } =
    useMaterialsForEditingBudget();

  const [searchInputValue, setSearchInputValue] = useState("");

  const [materials, setMaterials] = useState<MaterialProps[]>([]);
  const [materialsDataFiltered, setMaterialsDataFiltered] = useState<
    MaterialProps[]
  >([]);
  const [selectedMaterialsForBudget, setSelectedMaterialsForBudget] =
    useState<SelectedMaterialProps[]>(selectedMaterials);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  async function getMaterials() {
    try {
      const response = await api.get("/materials");
      const materials: MaterialProps[] = response.data.materials;

      if (selectedMaterials.length > 0) {
        const idsMaterialsAlreadySelected = selectedMaterials.map(
          (materialAlreadySelected) => materialAlreadySelected.id
        );

        const materialsAlreadySelected = materials.map(
          (material: MaterialProps) => {
            const isSelected = idsMaterialsAlreadySelected.find(
              (idMaterial) => idMaterial === material.id
            );

            if (isSelected) {
              return {
                ...material,
                checked: true,
              };
            }

            return {
              ...material,
              formattedPrice: addMaskMoney(material.price),
              checked: false,
            };
          }
        );

        setMaterialsDataFiltered(materialsAlreadySelected);
        return;
      }

      const formattedMaterials = materials.map((material: MaterialProps) => ({
        ...material,
        formattedPrice: addMaskMoney(material.price),
        checked: false,
      }));
      setMaterials(materialsForEditingBudget);
      setMaterialsDataFiltered(formattedMaterials);
    } catch (err) {
      addToast({
        type: "error",
        title: "Falha ao carregar os materiais",
        description: "Não foi possível carregar os materiais cadastrados",
      });
    }
  }
  useEffect(() => {
    getMaterials();
  }, []); // eslint-disable-line

  function handleSelectMaterialForBudget(currentMaterial: MaterialProps) {
    const isMaterialAlreadySelected = selectedMaterialsForBudget.find(
      (materialItem) => materialItem.id === currentMaterial.id
    );

    if (isMaterialAlreadySelected) {
      const removedMaterial = selectedMaterialsForBudget.filter(
        (material) => material.id !== currentMaterial.id
      );

      setSelectedMaterialsForBudget(removedMaterial);
      return;
    }

    const newMaterial = {
      ...currentMaterial,
      quantity: 1,
      total: currentMaterial.price,
      formattedTotal: addMaskMoney(currentMaterial.price),
    };

    setSelectedMaterialsForBudget((oldSelectedMaterials) => [
      ...oldSelectedMaterials,
      newMaterial,
    ]);
  }

  async function handleDeleteMaterial(idMaterial: number) {
    try {
      handleRemoveSelectedMaterial(idMaterial);
      handleRemoveMaterial(idMaterial);

      await api.delete(`/material/${idMaterial}`);

      setMaterialsDataFiltered((oldMaterials) => {
        return oldMaterials.filter((material) => material.id !== idMaterial);
      });

      addToast({
        type: "success",
        title: "Sucesso",
        description: "Material deletado com sucesso",
      });
    } catch (err) {
      // sendError(err)
      addToast({
        type: "error",
        title: "Opss",
        description: "Não foi possível deletar o item",
      });
    }
  }

  function handleAddMaterialsIntoBudget() {
    handleSetSelectedMaterials(selectedMaterialsForBudget);
    handleToggleModal();
  }

  function handleSearchMaterial(text: string) {
    setSearchInputValue(text);

    const filteredMaterials = materials.filter((material) => {
      const materialLowerCase = material.item.name.toLowerCase();

      const searchValue = text.toLowerCase();

      return materialLowerCase.indexOf(searchValue) > -1;
    });

    if (text) {
      searchDatalayer({ search_term: text, success: true });
    }

    setMaterialsDataFiltered(filteredMaterials);
  }

  function handleSearchCancel() {
    setSearchInputValue("");
    setMaterialsDataFiltered(materials);
  }
  return (
    <>
      <S.Top>
        <S.ButtonClose onClick={() => handleToggleModal()}>
          <FiX size={10} color={theme.colors.text} />
        </S.ButtonClose>

        <p>Materiais</p>
      </S.Top>

      <S.Content>
        <S.WrapperInput>
          <SearchInput
            placeholder="Faça uma busca por um material"
            description="Selecione um ou mais materiais para compor o orçamento."
            searchValue={searchInputValue}
            onChange={(event) => handleSearchMaterial(event.target.value)}
            // eslint-disable-next-line react/jsx-no-bind
            handleCancel={handleSearchCancel}
          />
        </S.WrapperInput>

        <S.WrapperInput>
          <Button typeButton="outline" onClick={() => handleCreateMaterial()}>
            Criar novo material
          </Button>
        </S.WrapperInput>

        <S.ListCardsMaterial>
          {materialsDataFiltered.map((material) => (
            <CardMaterial
              key={material.id}
              data={material}
              onSelect={() => {
                handleSelectMaterialForBudget(material);
              }}
              // eslint-disable-next-line react/jsx-no-bind
              handleChangeMaterial={handleEditMaterial}
              // eslint-disable-next-line react/jsx-no-bind
              handleDeleteMaterial={handleDeleteMaterial}
            />
          ))}
        </S.ListCardsMaterial>

        <S.Footer>
          <Button onClick={() => handleAddMaterialsIntoBudget()}>
            <span style={{ opacity: 0.7 }}>
              {selectedMaterialsForBudget.length > 1
                ? `${selectedMaterialsForBudget.length} materiais selecionados`
                : `${selectedMaterialsForBudget.length} material selecionado`}
            </span>
            <span style={{ margin: "0 30px" }}>|</span>
            Adicionar materiais
          </Button>
        </S.Footer>
      </S.Content>
    </>
  );
}
