import styled, { css } from 'styled-components';

type HighlightProps = {
  highlight?: boolean;
};

export const Container = styled.div`
  max-width: 1400px;
  width: 600px;
  padding: 30px;

  header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px;
  }
`;

export const Content = styled.div`
  margin-top: 30px;
  margin-bottom: 60px;
`;

export const VersionTitle = styled.h1`
  ${({ theme }) => css`
    font-size: ${theme.font.sizes.large};
    font-family: ${theme.font.family};
    font-weight: ${theme.font.bold};
    color: ${theme.colors.text_light_87};
  `}
`;

export const Title = styled.h1<HighlightProps>`
  ${({ theme, highlight = false }) => css`
    font-size: 14px;
   font-family: ${theme.font.family};
    font-weight: ${theme.font.bold};
    color: ${highlight ? theme.colors.primary : theme.colors.text_light_87};
    line-height: 20px;
  `}
`;

export const Description = styled.pre`
  ${({ theme }) => css`
    font-size: 12px;
    font-family: ${theme.font.family};
    font-weight: ${theme.font.normal};
    color: ${theme.colors.text_light_87};
    line-height: 20px;
    margin-top: 15px;
    margin-bottom: 18px;
    word-break: break-word;
  `}
`;
