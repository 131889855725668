import React, { ChangeEvent, useEffect, useState } from "react";
import { FiX } from "react-icons/fi";
import { format, parseISO } from "date-fns";
import { useTheme } from "styled-components";

import { MessageBox } from "./MessageBox";

import { useAuth } from "hooks/auth";
import { useToast } from "hooks/toast";
import { MessageProps, FeedbackProps } from "dtos/FeedbackDTO";

import loadingSpinner from "assets/loading-indicator.svg";

import { api } from "services/api";
import * as S from "./styles";

type Props = {
  feedbackId: number;
  handleToggleModal: () => void;
};

export function ChatFeedbackModal({ feedbackId, handleToggleModal }: Props) {
  const theme = useTheme();
  const { addToast } = useToast();
  const { user } = useAuth();

  const [feedback, setFeedback] = useState<FeedbackProps>({} as FeedbackProps);
  const [messages, setMessages] = useState<MessageProps[]>([]);
  const [messageInput, setMessageInput] = useState("");

  const [isLoadingButton, setIsLoadingButton] = useState(false);
  const [showInput, setShowInput] = useState(true);

  useEffect(() => {
    loadMessages();
    window.scrollTo(0, 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const lastMessage = messages[messages.length - 1];

    if (lastMessage && lastMessage.isAdm) {
      setShowInput(false);
    }
  }, [messages]);

  async function loadMessages() {
    try {
      const { data } = await api.get(`/feedbacks/${feedbackId}`);
      const firstMessage = data.message;
      const oldMessages = data.messages;

      const messageData = [
        {
          isAdm: false,
          message: firstMessage,
          image: user.photo,
        },
        ...oldMessages,
      ];

      setMessages(messageData);
      setFeedback(data);
    } catch (err) {
      addToast({
        title: "Ops!!",
        description: "Ocorreu um erro ao carregar as mensagens.",
      });
    }
  }

  function handleChangeMessage({
    currentTarget,
  }: ChangeEvent<HTMLTextAreaElement>) {
    currentTarget.style.maxHeight = "110px";
    currentTarget.style.height = `${currentTarget.scrollHeight}px`;

    setMessageInput(currentTarget.value.trimStart());
  }

  async function handleMarkAsSolved() {
    try {
      await api.put(`/feedbacks/${feedbackId}/concluded`);
      handleToggleModal();

      addToast({
        title: "Sucesso!",
        description: "O feedback foi marcado como resolvido.",
        type: "success",
      });
    } catch (err) {
      addToast({
        title: "Ops!!",
        description: "Ocorreu um erro, tente novamente.",
        type: "error",
      });
    }
  }

  async function handleSendMessage() {
    setIsLoadingButton(true);
    try {
      if (!messageInput) {
        addToast({
          title: "",
          description: "Digite algo para enviar.",
        });
        return;
      }

      const messageTrimmed = messageInput.trim();

      await api.post(`/feedbacks/${feedbackId}/message`, {
        message: messageTrimmed,
      });
      loadMessages();
      setMessageInput("");
    } catch (error) {
      addToast({
        title: "Ops!!",
        description: "Erro ao enviar mensagem.",
        type: "error",
      });
    } finally {
      setIsLoadingButton(false);
    }
  }

  return (
    <S.Container>
      <S.Top>
        <div>
          <S.ButtonClose onClick={() => handleToggleModal()}>
            <FiX size={10} color={theme.colors.text} />
          </S.ButtonClose>

          <h2>Chamado nº {feedbackId}</h2>
        </div>

        <div>
          <S.StatusBadge status={feedback.status} />
          <S.StatusText>
            {feedback.label === "Em análise"
              ? "Aguardando resposta"
              : feedback.label}
            {feedback.label === "Concluído" &&
              ` em ${format(
                parseISO(feedback.dataConcluded),
                "dd/MM/yyyy HH:mm"
              )}`}
          </S.StatusText>
        </div>
      </S.Top>

      <S.Content>
        {messages.map((message) => (
          <MessageBox key={message.id} message={message} />
        ))}
      </S.Content>

      {feedback.status !== "concluido" && (
        <>
          {showInput ? (
            <S.InputContainer>
              <textarea
                name="message"
                placeholder="Digite sua mensagem aqui..."
                value={messageInput}
                onChange={handleChangeMessage}
              />

              {isLoadingButton ? (
                <img
                  src={loadingSpinner}
                  alt="Carregando..."
                  style={{
                    width: "20px",
                    height: "20px",
                  }}
                />
              ) : (
                <S.SendButton onClick={() => handleSendMessage()}>
                  Enviar
                </S.SendButton>
              )}
            </S.InputContainer>
          ) : (
            <S.WrapperButtons>
              <span>O seu chamado foi resolvido?</span>

              <div>
                <button onClick={() => handleMarkAsSolved()}>Sim</button>

                <button onClick={() => setShowInput(true)}>Não</button>
              </div>
            </S.WrapperButtons>
          )}
        </>
      )}
    </S.Container>
  );
}
