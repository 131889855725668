import React, { useEffect, useState } from "react";

import { Dropdown, DropdownOption } from "components/DropDown";
import { Input } from "components/Input";
import { CommentsTextArea } from "components/CommentsTextArea";
import { Button } from "components/Button";
import { ServiceDTO } from "dtos/ServiceDTO";
import { useToast } from "hooks/toast";

import { useServiceFormTree } from "hooks/serviceFormTree";
import { useServiceDropDown } from "hooks/servicesDropDown";
import {
  UpdateServiceData,
  useSelectedService,
} from "hooks/budget/selectedServices";
import { useConfirmModalClient } from "hooks/budget/confirmModalClient";

import { api } from "services/api";

import { ServiceName } from "components/ModalServices/Areas/ServiceName";
import { Equipments } from "components/ModalServices/Areas/Equipments";
import { EquipmentTypes } from "components/ModalServices/Areas/EquipmentTypes";
import { Capacity } from "components/ModalServices/Areas/Capacity";

import arrowLeft from "assets/icons/arrow-left.svg";

import * as S from "./styles";
import {
  ChecklistDetail,
  ChecklistItemList,
} from "pages/fieldService/ServiceChecklist/types";
import { useServiceChecklist } from "hooks/serviceChecklist";
import { EditButton } from "components/EditButton";
import { DeleteButton } from "components/DeleteButton";
import { CardChecklist } from "components/CardChecklist";
import { AlertModal } from "components/AlertModal";

//import axios from "axios";
import { isEqual } from "lodash";
import axios from "axios";
import { DiscardInformationModal } from "components/DiscardInformationModal";
const runTimeExtensionOptions = [
  { id: 1, name: "Minutos" },
  { id: 2, name: "Horas" },
  { id: 3, name: "Dias" },
];

const warrantyExtensionOptions = [
  { id: 1, name: "Dias" },
  { id: 2, name: "Meses" },
  { id: 3, name: "Anos" },
];

type ExtensionProps = {
  id: number;
  name: string;
};

type FormEditServiceProps = {
  data: ServiceDTO;
  handleGoBack: () => void;
  handleAddChecklist?: () => void;
  handleEditPage?: () => void;
  handleSetDataFunction?: (value: any) => void;
  canEdit?: boolean;
};

type ResponseDetailProps = {
  data: ChecklistDetail;
};

type ServiceProps = {
  id: number;
  categoryId: number;
  service: {
    id: number;
    name: string;
  };
  equipment: {
    id: number;
    name: string;
  };
  equipmentType: {
    id: number;
    name: string;
  };
  capacity: {
    id: number;
    name: string;
  };
  price: number;
  brand?: string;
  runtime?: {
    time?: string | number;
    extension?: string;
  };
  checked?: boolean;
  warranty?: {
    time?: number;
    extension?: string;
  };
  description?: string;
  serviceChecklist?: ChecklistItemList[];
};

export function FormEditService({
  data,
  handleGoBack,
  handleAddChecklist,
  handleEditPage,
  handleSetDataFunction,
  canEdit = true,
}: FormEditServiceProps) {
  const { addToast } = useToast();
  const { categoriesTree, handleSetCategoriesTree } = useServiceFormTree();
  const {
    selectedCategoryId,
    selectedService,
    selectedEquipment,
    selectedEquipmentType,
    selectedCapacity,
    handleCategoryId,
    handleService,
    handleEquipment,
    handleEquipmentType,
    handleCapacity,
    reset,
    // TODO Desmarcar apos api estar pronta
    handleSelectedChecklists,
    serviceChecklistCxt,
    isEqualService,
    handleSetEqualService,
  } = useServiceDropDown();

  const {
    handleSetChecklistDetail,
    handleSetIsNewChecklist,
    handleSetEditPage,
    handleSetIsNotMenusAccess,
    handleSetSelectedItemChecklist,
  } = useServiceChecklist();

  const { setShowToastModalRight } = useConfirmModalClient();

  const { updatedSelectedService } = useSelectedService();

  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingButton, setIsLoadingButton] = useState(false);

  const [brand, setBrand] = useState("");
  const [runTime, setRunTime] = useState("");
  const [runTimeExtension, setRunTimeExtension] = useState(
    {} as ExtensionProps
  );
  const [warrantyTime, setWarrantyTime] = useState("");
  const [warrantyExtension, setWarrantyExtension] = useState(
    {} as ExtensionProps
  );
  const [observationText, setObservationText] = useState("");
  const [price, setPrice] = useState("");
  const [unMaskedPrice, setUnmaskedPrice] = useState(0);

  const [hasErrorOnCategory, setHasErrorOnCategory] = useState("");
  const [hasErrorOnServiceName, setHasErrorOnServiceName] = useState("");
  const [hasErrorOnRunTime, setHasErrorOnRunTime] = useState("");
  const [hasErrorOnRuntimeExtension, setHasErrorOnRuntimeExtension] =
    useState("");
  const [hasErrorOnWarrantyTime, setHasErrorOnWarrantyTime] = useState("");
  const [hasErrorOnWarrantyExtension, setHasErrorOnWarrantyExtension] =
    useState("");
  const [hasErrorOnPrice, setHasErrorOnPrice] = useState("");

  const [serviceChecklistState, setServiceChecklist] = useState<
    ChecklistItemList[]
  >([]);

  const [isOpenAlertModalDelete, setIsOpenAlertModalDelete] = useState(false);

  const [indexDelete, setIndexDelete] = useState<number>();

  const [warningMessage, setWarningMessage] = useState(false);

  const [goBack, setGoBack] = useState(true);

  const [showCompareAlert, setShowCompareAlert] = useState(false);
  const [backupService, setBackupService] = useState({});

  function handleDeleteRegister(ind: number) {
    let aux = serviceChecklistState.filter((element, index) => {
      return index !== ind;
    });
    let auxData = data;

    setServiceChecklist(aux);
    auxData.serviceChecklist = aux;

    if (handleSetDataFunction) handleSetDataFunction(auxData);
  }

  async function getCategories() {
    try {
      if (data.id) {
        const response = await api.get(`/services/new-service-form/${data.id}`);
        const { categoriesTree: categoriesTreeResponse } = response.data;

        handleSetCategoriesTree(categoriesTreeResponse);
      }
    } catch (err) {
      // sendError(err);
    }
  }

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (!isLoading) {
      if (!selectedCategoryId) {
        setHasErrorOnCategory("Categoria é obrigatório");
        setShowToastModalRight(true);
        setGoBack(false);
      }

      if (!selectedService.id) {
        setHasErrorOnServiceName("Serviço é obrigatório");
        setShowToastModalRight(true);
        setGoBack(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCategoryId, selectedService]);

  useEffect(() => {
    getCategories();

    handleCategoryId(data.categoryId);
    handleService({ id: data.service.id, name: data.service.name });
    handleEquipment({ id: data.equipment.id, name: data.equipment.name });
    setPrice(String(data.price));

    if (data.equipmentType?.id && data.equipmentType?.name)
      handleEquipmentType({
        id: data.equipmentType.id,
        name: data.equipmentType.name,
      });
    if (data.capacity?.id && data.capacity?.name)
      handleCapacity({ id: data.capacity.id, name: data.capacity.name });
    if (data?.brand) setBrand(data.brand);
    if (data?.description) setObservationText(data.description);
    if (data.runtime?.time) setRunTime(String(data.runtime.time));
    if (data.warranty?.time) setWarrantyTime(String(data.warranty.time));

    if (data?.serviceChecklist) {
      setServiceChecklist(data.serviceChecklist);
      handleSetSelectedItemChecklist(data.serviceChecklist);
      // TODO seta no contexto - Desmarcar apos api estar pronta
      handleSelectedChecklists(data.serviceChecklist);
    }

    const findRunTimeExtension = runTimeExtensionOptions.find(
      (currentRunTimeExtension) =>
        currentRunTimeExtension.name === data.runtime?.extension
    );
    if (findRunTimeExtension) {
      setRunTimeExtension(findRunTimeExtension);
    }

    const findWarrantyExtension = warrantyExtensionOptions.find(
      (currentWarrantyExtension) =>
        String(currentWarrantyExtension.name) === data.warranty?.extension
    );
    if (findWarrantyExtension) {
      setWarrantyExtension(findWarrantyExtension);
    }

    const formattedData = JSON.parse(
      JSON.stringify({
        ...data,
        price: String(data.price).replace(/[^0-9]/g, ""),
      })
    );

    ["type", "formattedTotal", "checked", "quantity", "total"].map((prop) => {
      return Reflect.deleteProperty(formattedData, prop);
    });

    setBackupService(formattedData);
    setIsLoading(false);

    return () => {
      reset();
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (Object.keys(backupService).length < 1) return;
    const service = setUpService();
    handleSetEqualService(isEqual(backupService, service));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    runTime,
    runTimeExtension,
    warrantyTime,
    warrantyExtension,
    selectedCategoryId,
    selectedService,
    selectedEquipment,
    selectedEquipmentType,
    selectedCapacity,
    unMaskedPrice,
    brand,
    observationText,
    serviceChecklistCxt,
  ]);

  function setUpService() {
    const runTimeFormatted = Number(runTime);
    const warrantyTimeFormatted = Number(warrantyTime);

    const updateServiceData = {
      id: data.id,
      categoryId: selectedCategoryId,
      service: {
        id: selectedService.id,
        name: selectedService.name,
      },
      equipment: {
        id: selectedEquipment.id || 0,
        name: selectedEquipment.name || "",
      },
      equipmentType: {
        id: selectedEquipmentType.id || 0,
        name: selectedEquipmentType.name || "",
      },
      capacity: {
        id: selectedCapacity.id || 0,
        name: selectedCapacity.name || "",
      },
      price: String(unMaskedPrice).replace(/[^0-9]/g, ""),
      runtime: {
        ...(runTimeFormatted !== 0 ? { time: runTimeFormatted } : {}),
        ...(runTimeExtension.name ? { extension: runTimeExtension.name } : {}),
      },
      warranty: {
        ...(warrantyTimeFormatted !== 0 ? { time: warrantyTimeFormatted } : {}),
        ...(warrantyExtension.name
          ? { extension: warrantyExtension.name }
          : {}),
      },
      description: observationText,
      brand,
      //checked: true,
      // TODO Desmarcar apos api estar pronta
      //enviar checklist do contexto no put
      serviceChecklist: serviceChecklistCxt,
    };
    return updateServiceData;
  }

  function handleSelectCategory({ id }: DropdownOption) {
    if (selectedCategoryId === id) {
      return;
    }

    handleCategoryId(id);
    handleService({} as DropdownOption);
    handleEquipment({} as DropdownOption);
    handleEquipmentType({} as DropdownOption);
    handleCapacity({} as DropdownOption);

    setHasErrorOnCategory("");
  }

  function handleWarrantyExtension({ id }: DropdownOption) {
    const findCurrentExtension = warrantyExtensionOptions.find(
      (extension) => extension.id === id
    );

    if (findCurrentExtension) {
      setWarrantyExtension(findCurrentExtension);
    }

    setHasErrorOnWarrantyExtension("");
  }

  function handleSelectRuntimeExtension({ id }: DropdownOption) {
    const findCurrentExtensionRunTime = runTimeExtensionOptions.find(
      (extension) => extension.id === id
    );

    if (findCurrentExtensionRunTime) {
      setRunTimeExtension(findCurrentExtensionRunTime);
    }

    setHasErrorOnRuntimeExtension("");
  }

  async function handleEditService() {
    if (!selectedCategoryId) {
      setHasErrorOnCategory("Categoria é obrigatório");
      addToast({
        title: "Ops!!",
        description: "Alguns campos são obrigatórios",
        type: "error",
      });
    } else {
      setHasErrorOnCategory("");
    }

    if (!selectedService.id) {
      setHasErrorOnServiceName("Serviço é obrigatório");
      addToast({
        title: "Ops!!",
        description: "Alguns campos são obrigatórios",
        type: "error",
      });
    } else {
      setHasErrorOnServiceName("");
    }

    const runTimeFormatted = Number(runTime);
    const warrantyTimeFormatted = Number(warrantyTime);

    if (runTimeFormatted <= 0 && runTimeExtension.name) {
      setHasErrorOnRunTime("Digite o tempo de execução.");
      addToast({
        title: "Ops!!",
        description: "Preencha o campo de tempo (Obrigatório)",
        type: "error",
      });
    } else {
      setHasErrorOnRunTime("");
    }

    if (runTimeFormatted > 0 && !runTimeExtension.name) {
      setHasErrorOnRuntimeExtension("Selecione uma das opções");

      addToast({
        title: "Ops!!",
        description: "Preencha o campo de tempo (Obrigatório)",
        type: "error",
      });
    } else {
      setHasErrorOnRuntimeExtension("");
    }

    if (warrantyTimeFormatted <= 0 && warrantyExtension.name) {
      setHasErrorOnWarrantyTime("Digite o tempo de garantia.");
      addToast({
        title: "Ops!!",
        description: "Preencha o campo de tempo (Obrigatório)",
        type: "error",
      });
    } else {
      setHasErrorOnWarrantyTime("");
    }

    if (warrantyTimeFormatted > 0 && !warrantyExtension.name) {
      setHasErrorOnWarrantyExtension("Selecione uma das opções");

      addToast({
        title: "Ops!!",
        description: "Preencha o campo de tempo (Obrigatório)",
        type: "error",
      });
    } else {
      setHasErrorOnWarrantyExtension("");
    }

    if (
      !selectedCategoryId ||
      !selectedService.id ||
      (runTimeFormatted <= 0 && runTimeExtension.name) ||
      (runTimeFormatted > 0 && !runTimeExtension.name) ||
      (warrantyTimeFormatted <= 0 && warrantyExtension.name) ||
      (warrantyTimeFormatted > 0 && !warrantyExtension.name)
    ) {
      return;
    }

    try {
      setIsLoadingButton(true);

      const updateServiceData: ServiceProps = {
        id: data.id,
        categoryId: selectedCategoryId,
        service: {
          id: selectedService.id,
          name: selectedService.name,
        },
        equipment: {
          id: selectedEquipment.id,
          name: selectedEquipment.name,
        },
        equipmentType: {
          id: selectedEquipmentType.id,
          name: selectedEquipmentType.name,
        },
        capacity: {
          id: selectedCapacity.id,
          name: selectedCapacity.name,
        },
        price: unMaskedPrice ? unMaskedPrice : 0,
        runtime: {
          ...(runTimeFormatted !== 0 ? { time: runTimeFormatted } : {}),
          ...(runTimeExtension.name
            ? { extension: runTimeExtension.name }
            : {}),
        },
        warranty: {
          ...(warrantyTimeFormatted !== 0
            ? { time: warrantyTimeFormatted }
            : {}),
          ...(warrantyExtension.name
            ? { extension: warrantyExtension.name }
            : {}),
        },
        description: observationText,
        brand,
        checked: true,
        //enviar checklist do contexto no put
        serviceChecklist: serviceChecklistCxt,
      };

      await handleEditServiceApi(updateServiceData);

      updatedSelectedService(updateServiceData as UpdateServiceData);

      handleSetSelectedItemChecklist([]);

      handleGoBack();
    } catch (error) {
      addToast({
        title: "Ops!!",
        description:
          axios.isAxiosError(error) && error.response?.data.error
            ? error.response.data.error
            : "Ocorreu um erro ao atualizar o serviço.",
        type: "error",
      });
    } finally {
      setIsLoadingButton(false);
    }
  }

  async function handleEditServiceApi(editService: ServiceProps) {
    if (!canEdit) return;
    await api.put(`/service/${editService.id}`, {
      categoryID: editService.categoryId,
      serviceTypeID: editService.service.id,
      equipmentID: editService.equipment.id,
      equipmentTypeID: editService.equipmentType.id,
      capacityID: editService.capacity.id,
      price: editService.price,
      brand: editService.brand,
      runtime: editService.runtime,
      warranty: editService.warranty,
      description: editService.description,
      serviceChecklist: editService.serviceChecklist,
    });

    addToast({
      title: "Sucesso",
      description: "Serviço atualizado com sucesso",
      type: "success",
    });
  }

  function handleClearRunTimeExtension() {
    setRunTimeExtension({} as ExtensionProps);
  }

  function handleClearWarrantyExtension() {
    setWarrantyExtension({} as ExtensionProps);
  }

  function handleChangePrice(text: string) {
    setPrice(text);
  }

  async function detailItem(idItem: number) {
    try {
      const { data }: ResponseDetailProps = await api.get(
        `/service-checklist/${idItem}`
      );
      //setando os detalhes do checklist
      handleSetChecklistDetail(data);

      //setado o tipo de tela para detalhe do checklist..
      handleSetIsNewChecklist(false);
      //setCurrentPageModal("checklistPage");
      //setisOpenModalChecklist(true);
      if (handleEditPage) handleEditPage();
    } catch (error) {
      addToast({
        title: "Ops!!",
        description: "Não foi possível obter os detalhes do checklist.",
        type: "error",
      });
    } finally {
    }
  }

  function validateFieldsOnClose() {
    if (!selectedService.id) {
      setHasErrorOnServiceName("Serviço é obrigatório");

      setWarningMessage(true);
      return;
    } else if (!goBack) {
      addToast({
        title: "Ops!!",
        description: "Salve as informações antes de sair.",
        type: "error",
      });
    } else {
      setHasErrorOnServiceName("");

      handleGoBack();
    }
  }

  return (
    <>
      <AlertModal
        isVisible={isOpenAlertModalDelete}
        title="Deseja apagar?"
        description={"Deseja realmente apagar esse checklist?"}
        action="choose"
        handleConfirm={() => {
          handleDeleteRegister(indexDelete as number);
          setIsOpenAlertModalDelete(false);
        }}
        onCloseModal={() => setIsOpenAlertModalDelete(false)}
      />

      <AlertModal
        isVisible={warningMessage}
        title="Retornar"
        description="É necessário revisar os campos obrigatórios"
        action="choose"
        handleConfirm={() => {
          setWarningMessage(false);
        }}
        onCloseModal={() => {
          setWarningMessage(false);
        }}
        labelConfirm={"Continuar editando"}
      />

      <DiscardInformationModal
        showModal={showCompareAlert}
        handleConfirmButton={() => {
          setShowCompareAlert(false);
          handleSetSelectedItemChecklist([]);
          handleSetIsNotMenusAccess(false);
          validateFieldsOnClose();
          handleSetEqualService(true);
        }}
        handleCancelButton={() => {
          setShowCompareAlert(false);
        }}
      />

      <S.Top>
        <S.ButtonClose
          onClick={() => {
            if (!isEqualService) {
              setShowCompareAlert(true);
              return;
            }
            handleSetSelectedItemChecklist([]);
            handleSetIsNotMenusAccess(false);
            validateFieldsOnClose();
          }}
        >
          <img src={arrowLeft} alt="flecha pra esquerda" />
        </S.ButtonClose>

        <p>Editar serviço</p>
      </S.Top>

      <S.Content>
        <S.Wrapper>
          <Dropdown
            label="Categoria*"
            placeholder="Selecione uma categoria"
            options={categoriesTree}
            categoryIdSelected={selectedCategoryId}
            // eslint-disable-next-line react/jsx-no-bind
            onClickedValue={handleSelectCategory}
            hasError={hasErrorOnCategory}
          />
        </S.Wrapper>

        <S.Wrapper>
          <ServiceName hasErrorOnService={hasErrorOnServiceName} />
        </S.Wrapper>

        <S.Wrapper>
          <Equipments hasErrorOnEquipment={""} />
        </S.Wrapper>

        <S.Wrapper>
          <EquipmentTypes />
        </S.Wrapper>

        <S.Wrapper>
          <Capacity />
        </S.Wrapper>

        <S.Wrapper>
          <Input
            name="Marca (opcional)"
            type="text"
            placeholder="Ex: Consul"
            value={brand}
            onChange={(text) => setBrand(text.currentTarget.value)}
          />
        </S.Wrapper>

        <S.Wrapper>
          <S.Row>
            <S.WrapperInput>
              <span> Tempo de execução (opcional) </span>
              <Input
                type="text"
                placeholder="Ex: 2"
                onChange={(text) => setRunTime(text.currentTarget.value)}
                value={runTime}
                hasError={hasErrorOnRunTime}
                onFocus={() => setHasErrorOnRunTime("")}
              />
            </S.WrapperInput>

            <S.WrapperDrodown>
              <Dropdown
                placeholder="Selecione"
                options={runTimeExtensionOptions}
                categoryIdSelected={runTimeExtension.id}
                // eslint-disable-next-line react/jsx-no-bind
                onClickedValue={handleSelectRuntimeExtension}
                hasError={hasErrorOnRuntimeExtension}
                clearDropDownItem
                // eslint-disable-next-line react/jsx-no-bind
                handleClear={handleClearRunTimeExtension}
              />
            </S.WrapperDrodown>
          </S.Row>
        </S.Wrapper>

        <S.Wrapper>
          <S.Row>
            <S.WrapperInput>
              <span> Garantia (opcional)</span>
              <Input
                type="text"
                placeholder="Ex: 180"
                onChange={(text) => setWarrantyTime(text.currentTarget.value)}
                value={warrantyTime}
                hasError={hasErrorOnWarrantyTime}
                onFocus={() => setHasErrorOnWarrantyTime("")}
              />
            </S.WrapperInput>

            <S.WrapperDrodown>
              <Dropdown
                placeholder="Selecione"
                options={warrantyExtensionOptions}
                categoryIdSelected={warrantyExtension.id}
                // eslint-disable-next-line react/jsx-no-bind
                onClickedValue={handleWarrantyExtension}
                hasError={hasErrorOnWarrantyExtension}
                clearDropDownItem
                // eslint-disable-next-line react/jsx-no-bind
                handleClear={handleClearWarrantyExtension}
              />
            </S.WrapperDrodown>
          </S.Row>
        </S.Wrapper>

        <S.Wrapper>
          <CommentsTextArea
            label="Detalhamento do Serviço (opcional)"
            value={observationText}
            onChange={(event) => setObservationText(event.target.value)}
          />
        </S.Wrapper>

        <S.Wrapper>
          <Input
            name="Valor (opcional)"
            maskType="money"
            valueCurrency={price}
            onChangeCurrency={({ formattedValue, floatValue }) => {
              handleChangePrice(formattedValue);
              setUnmaskedPrice(floatValue!);
            }}
            hasError={hasErrorOnPrice}
            onFocusClearError={() => setHasErrorOnPrice("")}
          />
        </S.Wrapper>

        {/*checklists*/}
        {serviceChecklistState.length > 0 && (
          <S.Wrapper>
            {serviceChecklistState.map((currentChecklist, index) => {
              return (
                <S.WrapperService key={index}>
                  <CardChecklist>
                    <S.WrapperInfoCard>
                      <S.WrapperData>
                        <h6>{currentChecklist.name}</h6>
                        <p>{currentChecklist.registers?.length} Registros</p>
                      </S.WrapperData>
                      <S.WrapperButtom>
                        {/*validar o checklist padrao nao pode ser editado...*/}
                        <EditButton
                          onClick={() => {
                            handleSetEditPage(true);
                            detailItem(currentChecklist.id);
                          }}
                        />
                        <DeleteButton
                          disabled={true}
                          onClick={() => {
                            setIndexDelete(index);
                            setIsOpenAlertModalDelete(true);
                            //handleDeleteRegister(index)
                          }}
                        />
                      </S.WrapperButtom>
                    </S.WrapperInfoCard>
                  </CardChecklist>
                </S.WrapperService>
              );
            })}
          </S.Wrapper>
        )}

        {/* TODO comentar os botão antes de subir pra api */}
        <S.Wrapper>
          <Button
            typeButton="outline"
            onClick={() => {
              handleSetEditPage(false);
              if (handleAddChecklist) handleAddChecklist();
            }}
          >
            Incluir checklists
          </Button>
        </S.Wrapper>

        <S.Wrapper>
          <Button
            loading={isLoadingButton}
            onClick={() => {
              handleSetIsNotMenusAccess(false);
              handleEditService();
            }}
          >
            Salvar
          </Button>
        </S.Wrapper>
      </S.Content>
    </>
  );
}
