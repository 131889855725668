 import { ServiceChecklist } from "pages/fieldService/ServiceChecklist";
 import { ChecklistPage } from "pages/fieldService/ServiceChecklist/ChecklistPage";
 import { DetailRegisterPage } from "pages/fieldService/ServiceChecklist/DetailRegisterPage";
 import { RegisterPage } from "pages/fieldService/ServiceChecklist/RegisterPage";

import { CreateEnvironment } from "../CreateEnvironment";
import { EditEnvironment } from "../EditEnvironment";
import { ListEnvironment } from "../ListEnvironment";
import { DetailEnvironment } from "../DetailEnvironment";


import { CreateEquipment } from "../CreateEquipment";
import { DetailEquipment } from "../DetailEquipment";
import { EditEquipment } from "../EditEquipment";

import { EditPhotoEquipment } from "../EditPhotoEquipment";
import { CreatePhotoEquipment } from "../CreatePhotoEquipment";

import { Page } from "./types";
import { ServiceListPmoc } from "../ServiceListPmoc";
import { PmocServiceForm } from "../PmocServiceForm";

type EnvironmentFlowProps = {
  pageName: Page;
  handleToggleOpen: () => void;
  handleScreenOption: (namePage: Page) => void;
  handleSetOpenEnvironmentModal?: (value: boolean) => void;
};

export function EnvironmentFlow({
  pageName,
  handleToggleOpen,
  handleScreenOption,
  handleSetOpenEnvironmentModal,
}: EnvironmentFlowProps) {
  function handleListEnv(nameScreen: Page) {
    return nameScreen === "listEnvironment" ? (
      <>
        <ListEnvironment
          handleToggleOpen={handleToggleOpen}
          handleChooseEnvironment={handleScreenOption}
          handleSetOpenEnvironmentModal={handleSetOpenEnvironmentModal}
        />
      </>
    ) : (
      handleCreateEnv(nameScreen)
    );
  }

  function handleCreateEnv(nameScreen: Page) {
    return nameScreen === "createEnvironment" ? (
      <>
        <CreateEnvironment handleChooseEnvironment={handleScreenOption} />
      </>
    ) : (
      DetailEnv(nameScreen)
    );
  }

  function DetailEnv(nameScreen: Page) {
    return nameScreen === "detailEnvironment" ? (
      <>
        <DetailEnvironment handleChooseEnvironment={handleScreenOption} />
      </>
    ) : (
      handleEditEnv(nameScreen)
    );
  }

  function handleEditEnv(nameScreen: Page) {
    return nameScreen === "editEnvironment" ? (
      <>
        <EditEnvironment handleChooseEnvironment={handleScreenOption} />
      </>
    ) : (
      handleCreateEqt(nameScreen)
    );
  }

  function handleCreateEqt(nameScreen: Page) {
    return nameScreen === "createEquipment" ? (
      <>
        <CreateEquipment handleChooseEnvironment={handleScreenOption} />
      </>
    ) : (
      handleCreatePhotoEqt(nameScreen)
    );
  }

  function handleCreatePhotoEqt(nameScreen: Page) {
    return nameScreen === "createPhotoEquipment" ? (
      <>
        <CreatePhotoEquipment handleChooseEnvironment={handleScreenOption} />
      </>
    ) : (
      handleDetailEqt(nameScreen)
    );
  }

  function handleDetailEqt(nameScreen: Page) {
    return nameScreen === "detailEquipment" ? (
      <>
        <DetailEquipment handleChooseEnvironment={handleScreenOption} />
      </>
    ) : (
      handleEditEqt(nameScreen)
    );
  }

  function handleEditEqt(nameScreen: Page) {
    return nameScreen === "editEquipment" ? (
      <>
        <EditEquipment handleChooseEnvironment={handleScreenOption} />
      </>
    ) : (
      handleEditPhotoEqt(nameScreen)
    );
  }

  function handleEditPhotoEqt(nameScreen: Page) {
    return nameScreen === "editPhotoEquipment" ? (
      <>
        <EditPhotoEquipment handleChooseEnvironment={handleScreenOption} />
      </>
    ) : (
      handleServicePmocList(nameScreen)
    );
  }
  function handleServicePmocList(nameScreen: Page) {
    return nameScreen === "servicesList" ? (
      <>
        <ServiceListPmoc handleChooseEnvironment={handleScreenOption} />
      </>
    ) : (
      handleCreateService(nameScreen)
    );
  }
  function handleCreateService(nameScreen: Page) {
    return nameScreen === "createService" ? (
      <>
        <PmocServiceForm
          isEditService={false}
          handleChooseEnvironment={handleScreenOption}
        />
      </>
      ) : (
        handleEditService(nameScreen)
      );
  }
  
  function handleEditService(nameScreen: Page) {
    return nameScreen === "editService" ? (
      <>
        <PmocServiceForm
          handleChooseEnvironment={handleScreenOption}
          isEditService={true}
        />
      </>
    ) : (
        handleServiceChecklist(nameScreen)
     );
  }
  function handleServiceChecklist(nameScreen: Page) {
    return nameScreen === "serviceChecklist" ? (
      <>
        <ServiceChecklist handleChooseEnvironment={handleScreenOption} />
      </>
    ) : (
      handleChecklistPage(nameScreen)
    );
  }
   function handleChecklistPage(nameScreen: Page) {
      return nameScreen === "checklistPage" ? (
        <>
          <ChecklistPage handleChooseEnvironment={handleScreenOption} />
        </>
      ) : (
        handleRegisterPage(nameScreen)
      );
   }
   function handleRegisterPage(nameScreen: Page) {
      return nameScreen === "registerPage" ? (
        <>
          <RegisterPage handleChooseEnvironment={handleScreenOption} />
        </>
      ) : (
        handleDetailRegisterPage(nameScreen)
      );
   }
   function handleDetailRegisterPage(nameScreen: Page) {
      return nameScreen === "detailRegisterPage" ? (
        <>
          <DetailRegisterPage handleChooseEnvironment={handleScreenOption} />
        </>
      ) : (
        ""
      );
    }
  return <>{handleListEnv(pageName)}</>;
}
