import styled, { css } from "styled-components";

export const Top = styled.div`
  ${({ theme }) => css`
    margin-bottom: 30px;

    display: flex;
    align-items: center;

    p {
      font-size: ${theme.font.sizes.large};
      color: ${theme.colors.title};
      font-weight: ${theme.font.bold};
    }
  `}
`;

export const ButtonClose = styled.button`
  ${({ theme }) => css`
    width: 35px;
    height: 35px;
    background-color: ${theme.colors.background_light};
    border-radius: 10px;

    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 25px;
  `}
`;

export const Content = styled.div`
  margin-top: 30px;
`;

export const WrapperSearch = styled.div`
  margin-bottom: 14px;
`;

export const Wrapper = styled.div`
  margin-top: 30px;
`;

export const ListCardsService = styled.div`
  margin-bottom: 80px;
`;

export const Footer = styled.div`
  width: 40%;
  max-width: 602px;
  height: 60px;

  position: fixed;
  bottom: 20px;
`;

export const MessageDiv = styled.div`
  ${({ theme }) => css`
    justify-content: center;
    align-items: center;
    padding-top: 30px;
    p {
      padding-top: 15px;
      color: ${theme.colors.text};
      font-size: ${theme.font.sizes.medium};
      font-weight: ${theme.font.normal};
      text-align: center;
    }
  `}
`;

export const TabContainer = styled.div`
  ${({ theme }) => css`
    margin-top: 33px;

    .react-tabs__tab-list {
      border-bottom: 1px solid rgba(255, 255, 255, 0.12);
      display: flex;
      justify-content: center;
    }

    .react-tabs__tab {
      color: ${theme.colors.text};
      width: 100%;
      text-align: center;
    }

    .react-tabs__tab--selected {
      background: inherit;
      border: 0px;
      color: ${theme.colors.text};
      width: 100%;
      text-align: center;

      font-weight: 500;
      line-height: 1.35;
      white-space: normal;
      letter-spacing: 0.02857em;
      border-bottom: 3px solid ${theme.colors.primary_light};
    }
  `}
`;
