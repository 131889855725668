import React, { useCallback, useEffect, useState } from "react";

import { useHistory } from "react-router";

import { LoadingProfiz } from "components/LoadingProfiz";
import { OpportunityCard } from "components/OpportunityCard";
import { SearchInput } from "components/SearchInput";

import { ServiceOportunityProps } from "dtos/ServiceOportunityDTO";

import { api } from "services/api";

import debounce from "lodash.debounce";

import * as S from "./styles";
import axios from "axios";
import { useToast } from "hooks/toast";
import { searchDatalayer } from "utils/pushDataLayer";

export function PendingOpportunities() {
  const history = useHistory();
  const { addToast } = useToast();
  const [isLoading, setIsLoading] = useState(true);
  const [input, setInput] = useState("");
  const [inputLoading, setInputLoading] = useState(false);

  const [opportunities, setOpportunities] = useState<ServiceOportunityProps[]>(
    []
  );
  const [filteredOpportunities, setFilteredOpportunities] = useState<
    ServiceOportunityProps[]
  >([]);

  useEffect(() => {
    async function getOpportunities() {
      try {
        setIsLoading(true);

        const response = await api.get("/opportunities", {
          params: {
            status: "pending",
            archived: false,
          },
        });

        setOpportunities(response.data.opportunities);
        setFilteredOpportunities(response.data.opportunities);
      } catch (error) {
        addToast({
          type: "error",
          title: "Oops!",
          description:
            axios.isAxiosError(error) && error.response?.data.error
              ? error.response.data.error
              : "Não foi possível obter as oportunidades pendentes.",
        });
      } finally {
        setIsLoading(false);
        setInputLoading(false);
      }
    }

    getOpportunities();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function handleSetInput(text: string) {
    setInputLoading(true);
    setInput(text);
    debounceFn(text);
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debounceFn = useCallback(
    debounce(getOpportunitiesByClientName, 3000),
    []
  );

  async function getOpportunitiesByClientName(name: string) {
    try {
      setIsLoading(true);

      const response = await api.get("/opportunities", {
        params: {
          status: "pending",
          archived: false,
          name,
        },
      });

      setFilteredOpportunities(response.data.opportunities);

      if (name) {
        searchDatalayer({ search_term: name, success: true });
      }
    } catch (error) {
      addToast({
        type: "error",
        title: "Oops!",
        description:
          axios.isAxiosError(error) && error.response?.data.error
            ? error.response.data.error
            : "Não foi possível obter as oportunidades pendentes.",
      });
    } finally {
      setIsLoading(false);
      setInputLoading(false);
    }
  }

  function handleCancelSearch() {
    setInput("");
    setFilteredOpportunities(opportunities);
  }

  function handleNavigateToDetail(opportunityId: number, type: string) {
    if (type === "showcase") {
      history.push(`/service-opportunity/${opportunityId}`);
    } else {
      history.push(`/service-opportunity-os/${opportunityId}`);
    }
  }

  return (
    <>
      {isLoading ? (
        <LoadingProfiz isVisible={isLoading} />
      ) : (
        <>
          <S.ContainerSearch>
            <SearchInput
              searchValue={input}
              onChange={(event) => handleSetInput(event.target.value)}
              placeholder="Procure pelo nome do cliente"
              handleCancel={handleCancelSearch}
              loadingInput={inputLoading}
            />
          </S.ContainerSearch>
          {filteredOpportunities.length ? (
            filteredOpportunities.map((opportunity) => (
              <OpportunityCard
                serviceOpportunity={opportunity}
                onSelect={() => {
                  handleNavigateToDetail(opportunity.id, opportunity.type);
                }}
              />
            ))
          ) : (
            <S.EmptyList>
              <S.Text>Não há nada para exibir.</S.Text>
            </S.EmptyList>
          )}
        </>
      )}
    </>
  );
}
