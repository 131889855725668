import React, { ButtonHTMLAttributes } from "react";

import * as S from "./styles";

import { Equipment } from "../../pages/fieldService/EquipmentsManual";

type EquipmentCardProps = ButtonHTMLAttributes<HTMLButtonElement> & {
  equipment: Equipment;
};

export function EquipmentCard({ equipment, ...rest }: EquipmentCardProps) {
  return (
    <S.Container {...rest}>
      <img src={equipment.image} alt={`imagem da marca ${equipment.name}`} />

      <S.InfoContainer>
        <p>{equipment.name}</p>
        {!!equipment && <span>{equipment?.itens?.toString()}</span>}

        {!!equipment.brand && <S.BrandText>{equipment.brand.name}</S.BrandText>}
      </S.InfoContainer>
    </S.Container>
  );
}
