import styled, { css } from 'styled-components'
import { shade } from 'polished'

type ButtonProps = {
  isSelected: boolean
}

export const Container = styled.div<ButtonProps>`
  ${({ theme, isSelected }) => css`
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 1px solid ${isSelected ? theme.colors.primary : 'transparent'};
    margin-bottom: 25px;

    height: 94px;
    width: 100%;
    border-radius: 10px;
    background-color: ${theme.colors.background_light};
  `}
`
export const Button = styled.button`
  width: 90%;
  height: 100%;
  display: flex;
  padding-right: 26px;
  align-items: center;
`

export const WrapperCheckbox = styled.div`
  height: 100%;
  width: 16%;

  display: flex;
  align-items: center;
  padding-left: 26px;
`

export const Checkbox = styled.div<ButtonProps>`
  ${({ theme, isSelected }) => css`
    height: 16px;
    width: 15px;

    display: flex;
    justify-content: center;
    align-items: center;

    border-radius: 4px;
    background-color: transparent;
    border: 1px solid #707070;

    div {
      height: 7px;
      width: 7px;
      border-radius: 1px;
      background-color: ${isSelected ? theme.colors.primary : 'transparent'};
    }
  `}
`

export const Content = styled.main`
  height: 100%;
  width: 80%;
  display: flex;
  flex-direction: column;
  justify-content: center;

  div {
    display: flex;
    justify-content: space-between;
  }

  div + div {
    width: 100%;
    margin-top: 12px;
  }
`

export const Title = styled.span`
  ${({ theme }) => css`
    color: ${theme.colors.title};
    font-size: ${theme.font.sizes.xsmall};
    font-family: ${theme.font.family};

    &:last-child {
      color: ${theme.colors.text};
    }
  `}
`

export const Price = styled.span`
  ${({ theme }) => css`
    color: ${theme.colors.primary};
    font-size: ${theme.font.sizes.xsmall};
    font-family: ${theme.font.family};
  `}
`

export const WrapperActions = styled.div`
  ${({ theme }) => css`
    width: 12%;
    height: 100%;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    background-color: ${theme.colors.edit_area};

    button {
      width: 100%;
      height: 50%;
      display: flex;
      justify-content: center;
      align-items: center;

      &:hover {
        background-color: ${shade(0.1, theme.colors.edit_area)};
      }
    }

    img {
      width: 16px;
      height: 16px;
    }
  `}
`
