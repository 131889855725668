import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";

import { ClientArea } from "components/ClientArea";
import { ArrowButton } from "components/ArrowButton";
import { Button } from "components/Button";
import { FormItem } from "components/FormItem";

import { CardResponsible } from "./CardResponsible";
import { UnityArea } from "./UnityArea";
import { AttachmentArea } from "./AttachmentArea";

import { useClient } from "hooks/budget/client";
import { useToast } from "hooks/toast";
import { usePmoc } from "hooks/usePmoc";
import { useHistory } from "react-router";

import { api } from "services/api";

import * as S from "./styles";

import { AlertModal } from "components/AlertModal";
import { LoadingProfiz } from "components/LoadingProfiz";
import { ModalRight } from "components/ModalRight";

type RouteParams = {
  id: string;
};

type ARTFileProps = {
  url: string;
};

type DictOption = {
  [key: string]: { status: string };
};

function StatePmoc(statusPmoc: string) {
  const status: DictOption = {
    Pendente: {
      status: "pending",
    },
    execução: {
      status: "execution",
    },
    Concluída: {
      status: "concluded",
    },
    Arquivada: {
      status: "archived",
    },
  };
  let value = Object.keys(status).find((key) => {
    const sts = status[key];
    return sts.status === statusPmoc;
  });
  return (
    <S.WapperPmocStatus>
      <div className="element-ball" />
      <p className="text-status">
        {statusPmoc === "execution" && "Em "}
        {value}
      </p>
    </S.WapperPmocStatus>
  );
}

type EnvironmentProps = {
  environmentId: number | string;
  environmentName: string;
  equipments: [
    {
      equipmentId: number | string;
      equipmentTag: string;
    }
  ];
};

type ServiceOrderHistoryProps = {
  year: number;
  months: OSProps[];
};

type OSProps = {
  month: string;
  percentageComplete: string;
};

export function PmocArchivedRegisterForm() {
  const { id } = useParams<RouteParams>();
  const history = useHistory();
  const { addToast } = useToast();
  const {
    initialPmocProps,
    clearPmocData,
    periodProps,
    handleSetUnity,
    handleSetPeriodProps,
    handleSetPmocArtFile,
    handleSetInitialPmocProps,
  } = usePmoc();

  const { clearClientData, handleSetClient } = useClient();

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingModal, setIsLoadingModal] = useState(false);

  const [isNotCloseModalEnvironment, setIsNotClosedModalEnvironment] =
    useState(true);

  const [environmentsList, setEnvironmentsList] = useState<EnvironmentProps[]>(
    [] as EnvironmentProps[]
  );
  const [showArchiveAlert, setShowArchiveAlert] = useState(false);

  const [serviceOrderHistory, setServiceOrderHistory] = useState<
    ServiceOrderHistoryProps[]
  >([] as ServiceOrderHistoryProps[]);
  const [stepPage, setStepPage] = useState(0);
  const [stepModal, setStepModal] = useState(false);

  const [orderServiceList, setOrderServiceList] = useState<OSProps[]>(
    [] as OSProps[]
  );

  const [statusPmoc, setStatusPmoc] = useState("archived");

  const clearInfos = async () => {
    clearClientData();
    clearPmocData();
  };

  const setData = async () => {
    try {
      setIsLoadingModal(true);
      const response = await api.get(`/pmoc/${id}`);

      response.data &&
        response.data.id &&
        response.data.name &&
        handleSetInitialPmocProps({
          id: response.data.id,
          name: response.data.name,
        });

      response.data &&
        response.data.status &&
        setStatusPmoc(response.data.status);

      response.data &&
        response.data.status &&
        setStatusPmoc(response.data.status);

      response.data &&
        response.data.client &&
        handleSetClient(response.data.client);
      response.data &&
        response.data.unity &&
        handleSetUnity(response.data.unity);

      response.data &&
        response.data.contractPeriod &&
        response.data.contractPeriod.period &&
        response.data.contractPeriod.type &&
        handleSetPeriodProps({
          period: response.data.contractPeriod.period,
          frequency: response.data.contractPeriod.type,
        });

      response.data && response.data.art
        ? handleSetPmocArtFile({
            url: response.data.art,
          })
        : handleSetPmocArtFile({} as ARTFileProps);

      response.data &&
        response.data.environments &&
        setEnvironmentsList(response.data.environments);

      response.data &&
        response.data.years &&
        setServiceOrderHistory(response.data.years);

      if (
        response.data &&
        response.data.years &&
        response.data.years.length === 1
      ) {
        filterOSPercentage(response.data.years[0].year, response.data.years);
        setStepPage(1);
      }
    } catch (error) {
      addToast({
        title: "Erro",
        description:
          axios.isAxiosError(error) && error.response?.data.error
            ? error.response?.data.error
            : "Não foi possível carregar as informações do PMOC",
        type: "error",
      });
    } finally {
      setIsLoadingModal(false);
    }
  };

  useEffect(() => {
    setData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!isNotCloseModalEnvironment) {
      setData();
      setIsNotClosedModalEnvironment(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isNotCloseModalEnvironment]);

  async function handleArchivePmoc() {
    try {
      setIsLoading(true);

      let pageReturned =
        serviceOrderHistory.length > 0
          ? `/pmoc/execution/${initialPmocProps.id}`
          : `/pmoc/finish-register/${initialPmocProps.id}`;

      await api.put(`pmoc/${initialPmocProps.id}/unarchive`, {
        originUpdate: "web",
      });

      addToast({
        type: "success",
        title: "PMOC desarquivada com sucesso.",
      });

      history.push(pageReturned);
      clearInfos();
    } catch (error) {
      addToast({
        title: "Erro",
        description:
          axios.isAxiosError(error) && error.response?.data.error
            ? error.response?.data.error
            : "Não foi possível arquivar a PMOC",
        type: "error",
      });
    } finally {
      setIsLoading(false);
    }
  }

  function filterOSPercentage(
    year: number,
    osList: ServiceOrderHistoryProps[]
  ) {
    try {
      setIsLoadingModal(true);
      let osFilter = osList.find((os) => {
        return Number(os.year) === Number(year);
      });
      osFilter && setOrderServiceList(osFilter.months);
    } catch (error) {
      addToast({
        title: "Erro",
        description:
          "Não foi possível obter a listagem de ordens de serviço pelo ano escolhido",
        type: "error",
      });
    } finally {
      setIsLoadingModal(false);
    }
  }

  return (
    <>
      {isLoadingModal ? (
        <LoadingProfiz isVisible={isLoadingModal} />
      ) : (
        <S.Container>
          <AlertModal
            isVisible={showArchiveAlert}
            labelConfirm="Continuar"
            labelCancel="Cancelar"
            title="Atenção!"
            description="Ao desarquivar esta PMOC as Ordens de serviço geradas mas não executadas também serão desarquivadas, mas será necessário criar novos agendamentos para elas. Deseja continuar?"
            action="choose"
            handleConfirm={() => {
              setShowArchiveAlert(false);
              handleArchivePmoc();
            }}
            onCloseModal={() => setShowArchiveAlert(false)}
          />

          <ModalRight
            isOpen={stepModal}
            handleToggleOpen={() => {
              setStepModal(false);
            }}
          >
            {stepPage < 1 ? (
              <>
                <S.Container>
                  <ArrowButton
                    handleFunction={() => {
                      setStepModal(false);
                    }}
                  />
                  <S.ModalHeader>
                    <p className="title">Histórico de ordens de serviço</p>
                    <p className="subtitle">
                      Acompanhe o histórico das ordens de serviço deste PMOC.
                    </p>
                  </S.ModalHeader>
                  <div>
                    {serviceOrderHistory.map((os, index) => {
                      return (
                        <S.OSCard
                          key={index}
                          typeCursor="pointer"
                          onClick={() => {
                            filterOSPercentage(os.year, serviceOrderHistory);
                            setStepPage(1);
                          }}
                        >
                          <p className="text">{os.year}</p>
                        </S.OSCard>
                      );
                    })}
                  </div>
                </S.Container>
              </>
            ) : (
              <>
                <S.Container>
                  <ArrowButton
                    handleFunction={() => {
                      if (serviceOrderHistory.length === 1)
                        return setStepModal(false);
                      setStepPage(0);
                    }}
                  />

                  <S.ModalHeader>
                    <p className="title">Histórico de ordens de serviço</p>
                    <p className="subtitle">
                      Acompanhe o histórico das ordens de serviço deste PMOC.
                    </p>
                  </S.ModalHeader>

                  <div>
                    {orderServiceList.map((os, index) => {
                      return (
                        <S.OSCard key={index} typeCursor="pointer">
                          <p className="text">{os.month}</p>
                          <p className="text">
                            {os.percentageComplete}% concluído
                          </p>
                        </S.OSCard>
                      );
                    })}
                  </div>
                </S.Container>
              </>
            )}
          </ModalRight>

          <div style={{ marginTop: 20 }}>
            <ArrowButton
              handleFunction={() => {
                history.push("/pmoc?statusList=3");
                clearInfos();
              }}
            />
          </div>
          <header>
            <S.Title>{initialPmocProps.name} </S.Title>
            {StatePmoc(statusPmoc)}
          </header>

          <div className="content">
            <S.Wrapper>
              <ClientArea disableDeleteButton disableEditButton />
            </S.Wrapper>

            <S.Wrapper>
              <UnityArea disableDeleteButton disableEditButton />
            </S.Wrapper>

            <S.Wrapper>
              <CardResponsible />
            </S.Wrapper>

            <S.Wrapper>
              {periodProps && periodProps.period ? (
                <FormItem
                  name="Período do contrato*"
                  placeholder={periodProps.period + " " + periodProps.frequency}
                  disabled
                />
              ) : (
                <FormItem
                  name="Período do contrato*"
                  placeholder={`Sem período cadastrado`}
                  disabled
                />
              )}
            </S.Wrapper>

            <S.Wrapper>
              <AttachmentArea disable={true} />
            </S.Wrapper>

            {environmentsList && environmentsList.length > 0 && (
              <S.Wrapper>
                <S.TitleExecutionButton>
                  Ambientes e equipamentos
                </S.TitleExecutionButton>
                {environmentsList.map((element) => {
                  return (
                    <S.EnvironmentList key={element.environmentId}>
                      <p className="card-title"> {element.environmentName} </p>
                      <p className="card-text">
                        {" "}
                        |{" "}
                        {element.equipments.length > 1
                          ? `${element.equipments.length} equipamentos`
                          : `${element.equipments.length} equipamento`}{" "}
                      </p>
                    </S.EnvironmentList>
                  );
                })}
              </S.Wrapper>
            )}

            <S.Wrapper>
              <Button disabled typeButton="outline">
                Ambientes e equipamentos
              </Button>
            </S.Wrapper>

            {serviceOrderHistory.length < 1 && (
              <Button disabled>Gerar ordens de serviço</Button>
            )}

            {serviceOrderHistory.length > 0 && (
              <S.Wrapper>
                <Button
                  onClick={() => {
                    setStepModal(true);
                  }}
                  typeButton="outlineDisable"
                  disabled
                >
                  Histórico de ordens de serviço
                </Button>
              </S.Wrapper>
            )}

            <Button
              typeButton="textOnly"
              onClick={() => {
                setShowArchiveAlert(true);
              }}
              loading={isLoading}
              disabled={isLoading}
            >
              Desarquivar PMOC
            </Button>
          </div>
        </S.Container>
      )}
    </>
  );
}
