import React from "react";
import ProfitzIcon from "assets/icons/profiz-modal-icon.svg";
import * as S from "./styles";

type ModalProps = {
  isVisible: boolean;
  title: string;
  description: string;
  highlightedDescription?: string;
  labelConfirm?: string;
  labelCancel?: string;
  action: "confirm" | "choose";
  handleConfirm?: () => void;
  handleCancel?: () => void;
  onCloseModal: () => void;
  zIndex?: number;
  buttonConfirmColor?: string;
  isNotCancelButton?: boolean;
};

export function NewAlertModal({
  isVisible,
  title,
  description,
  highlightedDescription,
  labelConfirm = "Confirmar",
  labelCancel = "Fechar",
  action,
  handleConfirm,
  handleCancel,
  onCloseModal,
  zIndex,
  buttonConfirmColor,
  isNotCancelButton = false,
}: ModalProps) {
  function handleValidationConfirm() {
    if (handleConfirm) {
      handleConfirm();
      return;
    }
    onCloseModal();
  }

  function handleValidationCancel() {
    if (handleCancel) {
      handleCancel();
      return;
    }
    onCloseModal();
  }

  return (
    <S.ContainerOverlay isVisible={isVisible} style={{ zIndex }}>
      <S.BoxModal>
        <S.Header>
          <img
            src={ProfitzIcon}
            style={{ width: "30px", height: "30px", marginRight: 10 }}
            alt="Logo Profitz"
          />
        </S.Header>

        <S.Main>
          <S.Title>{title}</S.Title>
          <S.Description>{description}</S.Description>

          {highlightedDescription && (
            <S.HighlightedDescription>
              {highlightedDescription}
            </S.HighlightedDescription>
          )}
        </S.Main>

        <S.Footer>
          {action === "confirm" ? (
            <S.ButtonConfirm onClick={() => onCloseModal()}>OK</S.ButtonConfirm>
          ) : (
            <S.WrapperButtons>
              <S.ButtonConfirm
                onClick={handleValidationConfirm}
                color={buttonConfirmColor}
              >
                {labelConfirm}
              </S.ButtonConfirm>
              {!isNotCancelButton && (
                <S.ButtonCancel onClick={handleValidationCancel}>
                  {labelCancel}
                </S.ButtonCancel>
              )}
            </S.WrapperButtons>
          )}
        </S.Footer>
      </S.BoxModal>
    </S.ContainerOverlay>
  );
}
