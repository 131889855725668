import styled, { css } from "styled-components";

export const TabContainer = styled.div`
  ${({ theme }) => css`
    margin-top: 40px;

    .react-tabs__tab-list {
      border-bottom: 1px solid rgba(255, 255, 255, 0.12);
      display: flex;
      justify-content: space-between;
    }

    .react-tabs__tab {
      width: 50%;
      padding-left: 40px;
      font-size: ${theme.font.sizes.xsmall};
      color: ${theme.colors.text_light_38};
    }

    .react-tabs__tab--selected {
      background: inherit;
      border: 0px;
      color: ${theme.colors.text_light_87};
      font-weight: ${theme.font.normal};
      white-space: normal;
      letter-spacing: 0.02857em;
      border-bottom: 3px solid ${theme.colors.primary};
    }

    .react-tabs__tab-panel .react-tabs__tab-panel--selected {
      margin-top: 20px;
    }
  `}
`;

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const TagSoon = styled.div`
  ${({ theme }) => css`
    display: flex;
    margin-left: 10px;
    padding: 4px;
    background-color: ${theme.colors.primary_opacity};
    border: 2px solid ${theme.colors.primary};
    border-radius: 10px;

    span {
      font-size: 8px;
      color: ${theme.colors.primary};
      font-weight: ${theme.font.bold};
    }
  `}
`;
