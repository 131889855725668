import React, { useState } from "react";
import * as Yup from "yup";

import { InputCode } from "components/InputCode";
import { Button } from "components/Button";
import { Input } from "components/Input";

import { ResetPasswordData } from "..";
import { useToast } from "hooks/toast";
import getValidationErrors from "utils/getValidationErrors";

import { api } from "services/api";
import * as S from "../styles";

import profizLap from "assets/laptop5.svg";
import qrcode from "assets/qrcode.svg";
import profiz from "assets/Profiz.svg";

type Errors = {
  [key: string]: string;
};

type EmailSendCodeProps = {
  handleToggleOpen: () => void;
  handleGetResetPasswordData: (data: ResetPasswordData) => void;
};

export function CodeRecovery({
  handleToggleOpen,
  handleGetResetPasswordData,
}: EmailSendCodeProps) {
  const { addToast } = useToast();

  const [code, setCode] = useState("");
  const [confirmedEmail, setConfirmedEmail] = useState("");

  const [isLoading, setIsLoading] = useState(false);
  const [hasError, setHasError] = useState<Errors>({} as Errors);

  function handleChange(value: string) {
    setCode(value);
    if (hasError.code) {
      setHasError({ ...hasError, code: "" });
    }
  }

  async function handleSendCodeToResetPassword() {
    setIsLoading(true);
    try {
      const schema = Yup.object().shape({
        code: Yup.string()
          .min(6, "O código deve conter no mínimo 6 caracteres")
          .required("É obrigatório inserir o código."),
        confirmedEmail: Yup.string()
          .email("Digite um e-mail válido.")
          .required("E-mail obrigatório."),
      });

      await schema.validate(
        {
          confirmedEmail,
          code,
        },
        { abortEarly: false }
      );
      setHasError({});

      await api.post(`/users/password-recovery/${code}/verify`, {
        email: confirmedEmail,
      });

      addToast({
        title: "Código verificado!",
        description: "Agora você pode criar uma nova senha.",
        type: "success",
      });

      handleToggleOpen();
      handleGetResetPasswordData({ email: confirmedEmail, code });
    } catch (error: any) {
      if (error instanceof Yup.ValidationError) {
        const errors = getValidationErrors(error);

        setHasError(errors);

        addToast({
          title: "Ops!!",
          description: "Preencha todos os campos obrigatórios",
          type: "error",
        });
        return;
      }

      addToast({
        title: "Ops!!",
        description: error.response.data.error,
        type: "error",
      });
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <S.BackgroundContent>
      <S.Background>
        <aside>
          <img className="profiz" src={profiz} alt="logo da profiz" />

          <section>
            <S.Title>
              Boas vindas <span>à Profiz!</span>
            </S.Title>

            <S.SubTitle>
              A solução <span> grátis </span>
              completa para <span> gestão de serviços.</span>
            </S.SubTitle>

            <img
              className="laptop"
              src={profizLap}
              alt="imagens do sistema profiz versao web e mobile"
            />

            <S.QrCode>
              <img src={qrcode} alt="qr code" />

              <div>
                <span>Baixe o aplicativo.</span>
                <p>Disponível para Android e iOS, escaneie o QR Code.</p>
              </div>
            </S.QrCode>
          </section>
        </aside>
      </S.Background>

      <S.Content>
        <div className="content">
          <S.Description>
            Digite o código de verificação que foi enviado para o seu e-mail
          </S.Description>

          <S.WrapperCode>
            <InputCode
              length={6}
              onChange={handleChange}
              hasError={hasError.code}
            />
          </S.WrapperCode>

          <S.WrapperInput
            onKeyPress={(e) => {
              if (e.key === "Enter") {
                e.preventDefault();
                handleSendCodeToResetPassword();
              }
            }}
          >
            <Input
              name="Confirme seu e-mail"
              type="email"
              placeholder="example@mail.com"
              onChange={(text) => setConfirmedEmail(text.currentTarget.value)}
              hasError={hasError.confirmedEmail}
              onFocusClearError={() =>
                setHasError({ ...hasError, confirmedEmail: "" })
              }
            />
          </S.WrapperInput>

          <S.WrapperButton>
            <Button
              loading={isLoading}
              onClick={() => handleSendCodeToResetPassword()}
            >
              Verificar código
            </Button>
          </S.WrapperButton>
        </div>
      </S.Content>
    </S.BackgroundContent>
  );
}
