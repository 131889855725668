import styled, { css } from "styled-components";

type CheckBoxProps = {
  checked: boolean;
};

export const Container = styled.button<CheckBoxProps>`
  ${({ theme, checked }) => css`
    width: 100%;
    background-color: ${theme.colors.background_light};
    border-radius: 10px;
    margin-bottom: 22px;
    padding: 16px 12px;

    display: flex;
    align-items: center;

    ${checked
      ? css`
          border: 1px solid ${theme.colors.primary};
        `
      : css`
          border: 1px solid transparent;
        `}

    & > div:last-of-type {
      width: 88%;
      margin-left: 10px;

      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      text-align: left;
    }
  `}
`;

export const WrapperCheckbox = styled.div`
  height: 100%;
  width: 10%;
  max-width: 80px;

  display: flex;
  align-items: center;
  justify-content: center;
`;

export const CheckBox = styled.div<CheckBoxProps>`
  ${({ theme, checked }) => css`
    height: 16px;
    width: 15px;
    border: 1px solid #707070;
    border-radius: 2px;
    background-color: transparent;

    display: flex;
    align-items: center;
    justify-content: center;

    ${checked &&
    css`
      & > div {
        height: 6.4px;
        width: 7px;
        border-radius: 1px;
        background-color: ${theme.colors.primary};
      }
    `}
  `}
`;

export const WrapperTitle = styled.div`
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  & > span:last-of-type {
    ${({ theme }) => css`
      font-size: ${theme.font.sizes.xsmall};
      color: ${theme.colors.title};
    `}
  }
`;

export const Title = styled.span`
  ${({ theme }) => css`
    font-size: ${theme.font.sizes.small};
    color: ${theme.colors.title};
    font-weight: ${theme.font.bold};
  `}
`;

export const TextDetail = styled.span`
  ${({ theme }) => css`
    font-size: ${theme.font.sizes.small};
    color: ${theme.colors.text};
    margin-bottom: 4px;
  `}
`;
