import { Module } from "dtos/permission/ProfileDTO";

import { HasPermissionCard } from "../HasPermissionCard";

import * as S from "./styles";

type ModuleListProps = {
  modules: Module[];
};
export function ModuleList({ modules }: ModuleListProps) {
  return (
    <>
      {modules.map((currentModule) => (
        <div key={currentModule.hash}>
          <S.Title>{currentModule.title}</S.Title>

          {currentModule.permissions.map((currentPermission) => (
            <HasPermissionCard
              key={`${currentModule.hash}-${currentPermission.hash}`}
              label={currentPermission.description}
              hasPermission={currentPermission.active}
            />
          ))}
        </div>
      ))}
    </>
  );
}
