import React, { useEffect, useState } from "react";
import { useTheme } from "styled-components";
import { FiX } from "react-icons/fi";
import { format } from "date-fns";
import { useHistory } from "react-router";

import { useToast } from "hooks/toast";
import { ContentBox } from "components/ContentBox";

import {
  ServiceOrderDetailProps,
  ClientProps,
  ServiceProps,
  ServiceItemProps,
  LocalChecklistProps,
  MaterialProps,
} from "dtos/ServiceOrderDetailDTO";

import apiv2 from "services/apiv2";
import * as S from "./styles";
import { ButtonSVG } from "components/ButtonSVG";

type MappedServiceProps = {
  service: ServiceItemProps;
  quantity: number;
  scheduleDate: string;
  hour: string[];
};

type Props = {
  orderServiceId: number;
  onCloseModal: () => void;
  detailLink?: string;
};

export function ModalServiceOrderDetails({
  orderServiceId,
  onCloseModal,
  detailLink,
}: Props) {
  const [clientInServiceOrder, setClientInServiceOrder] = useState(
    {} as ClientProps
  );
  const [addressClient, setAddressClient] = useState("");
  const [servicesInServiceOrder, setServicesInServiceOrder] = useState<
    MappedServiceProps[]
  >([]);
  const [checklistInServiceOrder, setChecklistInServiceOrder] = useState<
    LocalChecklistProps[]
  >([]);
  const [materialsInServiceOrder, setMaterialsInServiceOrder] = useState<
    MaterialProps[]
  >([]);
  const [observationInServiceOrder, setObservationInServiceOrder] =
    useState("");
  const [formattedCreationDate, setFormattedCreationDate] = useState("");
  const [scheduleStatus, setScheduleStatus] = useState("");
  const [statusServiceOrder, setStatusServiceOrder] = useState("");
  const [sequenceNumber, setSequenceNumber] = useState(0);

  const history = useHistory();
  const theme = useTheme();

  const { addToast } = useToast();

  function handleOpenPdfPage() {
    history.push({
      pathname: "/download/pdf",
      state: {
        pdfDownloadLink: detailLink,
      },
    });
  }

  function getServicesMapped(servicesParam: ServiceProps[]) {
    const mappedServices = servicesParam.map(
      ({ service, scheduleDate, quantity }: ServiceProps) => ({
        service,
        quantity,
        hour: scheduleDate.split(" "),
        scheduleDate: scheduleDate
          ? format(new Date(scheduleDate), "dd/MM/yyyy - HH:mm")
          : "",
      })
    );

    return mappedServices;
  }

  useEffect(() => {
    const allAdressExists = Object.values(clientInServiceOrder).every(
      (value) => value !== ""
    );

    if (!allAdressExists) {
      setAddressClient("sem endereço cadastrado");
      return;
    }

    const { address } = clientInServiceOrder;

    if (allAdressExists) {
      setAddressClient(`
        ${address?.street}, ${address?.number} -
        ${address?.city} - ${address?.uf}
      `);
    }
  }, [clientInServiceOrder]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    async function getServiceOrderDetails() {
      try {
        const { data } = await apiv2.get(
          `/budgets/service-order/${orderServiceId}`
        );

        const {
          status,
          createdAt,
          client,
          services,
          localChecklists,
          materials,
          observations,
          sequenceNumber,
        }: ServiceOrderDetailProps = data;
        const formattedDate = format(new Date(createdAt), "dd/MM/yyyy");
        setFormattedCreationDate(formattedDate);

        if (status === "concluded") {
          setScheduleStatus("Concluido");
        } else if (status === "new") {
          setScheduleStatus("Nova Ordem");
        } else if (status === "scheduled") {
          setScheduleStatus("Agendada");
        } else {
          setScheduleStatus("N.A.");
        }

        const formattedServices = getServicesMapped(services);
        setSequenceNumber(sequenceNumber);
        setClientInServiceOrder(client);
        setServicesInServiceOrder(formattedServices);
        setChecklistInServiceOrder(localChecklists);
        setMaterialsInServiceOrder(materials);
        setObservationInServiceOrder(observations);
        setStatusServiceOrder(status);
      } catch (error) {
        addToast({
          type: "error",
          title: "Ops...",
          description: "Erro ao buscar detalhes da ordem de serviço",
        });
      } finally {
        // loading = false
      }
    }

    getServiceOrderDetails();
  }, [addToast, orderServiceId]);

  return (
    <>
      <S.Top>
        <S.ButtonClose onClick={() => onCloseModal()}>
          <FiX size={10} color={theme.colors.text} />
        </S.ButtonClose>

        <h4>Ordem de Serviço {sequenceNumber}</h4>
      </S.Top>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <S.InfoDate>Data de Criação: {formattedCreationDate}</S.InfoDate>
        <S.ScheduleStatus>
          <S.Dot
            style={{
              background:
                statusServiceOrder === "concluded" ? "#D3FF9A" : "#FFF595",
            }}
          ></S.Dot>
          {scheduleStatus}
        </S.ScheduleStatus>
      </div>

      <S.Content>
        <div>
          <span>Cliente:</span>

          <ContentBox>
            <S.ContentBoxTitle>{clientInServiceOrder.name}</S.ContentBoxTitle>
            <S.ContentBoxText>
              Telefone: {clientInServiceOrder.phone}
            </S.ContentBoxText>

            <S.ContentBoxText>Endereço: {addressClient}</S.ContentBoxText>
          </ContentBox>
        </div>

        <div>
          <span>Serviços:</span>

          {servicesInServiceOrder.map((service, index) => {
            const { service: serviceData, quantity, scheduleDate } = service;

            return (
              <>
                <ContentBox key={serviceData.id}>
                  <S.WrapperContentService>
                    <span>
                      {serviceData.service.name} ({quantity})
                    </span>
                    {scheduleDate && <span>Agendado para: {scheduleDate}</span>}
                  </S.WrapperContentService>

                  <span>{serviceData.equipment.name}</span>

                  {serviceData.equipmentType?.name && (
                    <span>{serviceData.equipmentType.name}</span>
                  )}

                  {serviceData.capacity?.name && (
                    <span>{serviceData.capacity.name}</span>
                  )}

                  {serviceData?.brand && (
                    <span>&ensp;| Marca: {serviceData.brand}</span>
                  )}

                  {serviceData.runtime?.time && serviceData.warranty?.time && (
                    <S.ContentBoxText>
                      Execução: {serviceData.runtime.time}{" "}
                      {serviceData.runtime.extension}
                      &ensp;|&ensp;Garantia: {serviceData.warranty.time}{" "}
                      {serviceData.warranty.extension}
                    </S.ContentBoxText>
                  )}

                  {serviceData?.description && (
                    <S.ContentBoxText>
                      Detalhamento do Serviço: {serviceData.description}
                    </S.ContentBoxText>
                  )}
                </ContentBox>
                {index !== servicesInServiceOrder.length - 1 && <S.Separator />}
              </>
            );
          })}
        </div>

        {checklistInServiceOrder.length > 0 && (
          <div>
            <span>Itens adicionais:</span>

            <ContentBox>
              {checklistInServiceOrder.map((checklist) => {
                const { checklist: checklistData, quantity, type } = checklist;

                const status = checklistData?.status.find(
                  (sts) => sts.type === type
                );

                return (
                  <div key={checklistData.id}>
                    <S.ContentBoxText>
                      <span style={{ color: theme.colors.title }}>
                        {checklistData.name}:
                      </span>{" "}
                      &nbsp;
                      {status?.label} &nbsp;
                      {type === "my_responsibility" && (
                        <span>({quantity})</span>
                      )}
                    </S.ContentBoxText>
                  </div>
                );
              })}
            </ContentBox>
          </div>
        )}

        {materialsInServiceOrder.length > 0 && (
          <div>
            <span>Materiais:</span>

            <ContentBox>
              {materialsInServiceOrder.map((material, index) => {
                const { material: materialData, quantity } = material;

                return (
                  <>
                    <div key={materialData.id}>
                      <S.ContentBoxText>
                        {materialData.item.name} &nbsp; ({quantity})
                      </S.ContentBoxText>
                      <S.ContentBoxText>
                        <span style={{ color: theme.colors.title }}>Tipo:</span>{" "}
                        {materialData.type.name} &nbsp;
                      </S.ContentBoxText>
                    </div>
                    {index !== materialsInServiceOrder.length - 1 && (
                      <div style={{ padding: "5px 0" }} />
                    )}
                  </>
                );
              })}
            </ContentBox>
          </div>
        )}

        {!!observationInServiceOrder && (
          <div>
            <span>Detalhamento do Serviço:</span>

            <ContentBox>
              <S.ContentBoxObservationText>
                {observationInServiceOrder}
              </S.ContentBoxObservationText>
            </ContentBox>
          </div>
        )}

        <S.WrapperButtonsSVG>
          {/* <ButtonSVG title="Enviar para o cliente" typeSvg="client" /> */}
          <ButtonSVG
            title="Download PDF"
            typeSvg="pdf"
            onClick={handleOpenPdfPage}
          />
        </S.WrapperButtonsSVG>
      </S.Content>
    </>
  );
}
