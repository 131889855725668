import { useEffect, useState } from "react";
import { useParams } from "react-router";
import axios from "axios";

import { Page } from "templates/Page";

import { LoadingProfiz } from "components/LoadingProfiz";
import { ModalNewInviteDetail } from "components/Permission";
import { Button } from "components/Button";

import { api } from "services/api";
import { AnswerInvite } from "dtos/permission/AnswerInviteDTO";
import { useToast } from "hooks/toast";

import InviteIcon from "assets/icons/permission/invite-icon.svg";

import * as S from "./styles";

type RouteParams = {
  invitationHash: string;
};

export function NewInviteDetail() {
  const { invitationHash } = useParams<RouteParams>();
  const { addToast } = useToast();

  const [loading, setLoading] = useState(true);
  const [openModal, setOpenModal] = useState(false);
  const [invite, setInvite] = useState<AnswerInvite>({} as AnswerInvite);

  async function setData() {
    try {
      setLoading(true);
      const { data } = await api.get<AnswerInvite>(
        `/users/teams/answer-invite/${invitationHash}`
      );

      setInvite(data);
    } catch (error) {
      addToast({
        title: "Ops!!",
        description:
          axios.isAxiosError(error) && error.response?.data.error
            ? error.response.data.error
            : "Ocorreu um erro ao buscar as informações do convite.",
        type: "error",
      });
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    setData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {loading ? (
        <LoadingProfiz isVisible={loading} />
      ) : (
        <>
          <ModalNewInviteDetail
            invite={invite}
            openModalDetail={openModal}
            handleCloseModal={() => setOpenModal(false)}
          />

          <Page title="Novo convite" subtitle="">
            <S.Container>
              <img src={InviteIcon} alt="Icone convite" />

              <S.Content>
                <S.Title>
                  Você recebeu um convite para fazer parte de uma equipe!
                </S.Title>

                <S.Highlight>{invite.account.name}</S.Highlight>
                <S.Description> quer adicionar você como </S.Description>
                <S.Highlight>{invite.profile.name}</S.Highlight>
                <S.Description> da equipe </S.Description>
                <S.Highlight>{invite.account.companyName}</S.Highlight>

                <Button onClick={() => setOpenModal(true)}>
                  Ver detalhes do convite
                </Button>
              </S.Content>
            </S.Container>
          </Page>
        </>
      )}
    </>
  );
}
