import React, { ButtonHTMLAttributes } from "react";

import * as S from "./styles";

export type DisabledDropDownProps = ButtonHTMLAttributes<HTMLButtonElement> & {
  title: string;
  label: string;
  hasError?: string;
};

export function DisabledDropDown({
  title,
  label,
  hasError,
  ...rest
}: DisabledDropDownProps) {
  return (
    <>
      <S.Label>{label}</S.Label>
      <S.Container hasError={!!hasError} type="button" {...rest}>
        {title}
      </S.Container>
      {!!hasError && <S.ErrorText>{hasError}</S.ErrorText>}
    </>
  );
}
