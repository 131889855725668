import { ReactNode, useEffect, useState } from "react";

import { AccountModal, ListItemCard } from "..";
import { useAccount } from "hooks/permission/account";
import { ActionHash } from "hooks/permission/useHasPermission";

import {
  AccountDTO,
  PermissionAction,
  PermissionModule,
} from "dtos/permission/AccountDTO";

type Props = {
  moduleHash: string;
  actionHash: ActionHash;
  children?: ReactNode;
  openModal?: boolean;
};

type AccountByHashPermission = {
  accounts: AccountDTO[];
} & Props;

type FindModuleByHashProps = {
  moduleHash: string;
  modules: PermissionModule[];
};

export function SelectCurrentAccountForCreateNewItem({
  moduleHash,
  actionHash,
  openModal = false,
  children,
}: Props) {
  const { accounts, whoami } = useAccount();
  const [isOpenChangeModal, setIsOpenChangeModal] = useState(false);

  function findModuleByHash({ moduleHash, modules }: FindModuleByHashProps) {
    return modules.find((module) => module.hash === moduleHash);
  }

  function filterAccountsByHashPermission({
    accounts,
    moduleHash,
    actionHash,
  }: AccountByHashPermission) {
    return accounts.filter((account) => {
      const foundModule = findModuleByHash({
        modules: account.profile?.modules!,
        moduleHash,
      });
      const foundPermission = foundModule?.permissions.find(
        (currentPermission: PermissionAction) =>
          currentPermission?.hash === actionHash
      );

      return foundPermission?.active;
    });
  }

  const filteredAccounts = filterAccountsByHashPermission({
    accounts,
    actionHash,
    moduleHash,
  });

  function handleToggleChangeAccount() {
    setIsOpenChangeModal(!isOpenChangeModal);
  }

  useEffect(() => {
    setIsOpenChangeModal(openModal);
  }, [openModal]);

  if (accounts.length <= 1) return <></>;

  return (
    <>
      <AccountModal
        isVisible={isOpenChangeModal}
        filteredAccounts={filteredAccounts}
        onCloseModal={handleToggleChangeAccount}
      />

      {children ? (
        children
      ) : (
        <ListItemCard
          type="button"
          image={whoami?.photo || ""}
          roundedImage
          title={whoami?.name || ""}
          onClick={handleToggleChangeAccount}
        />
      )}
    </>
  );
}
