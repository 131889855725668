import styled, { css } from "styled-components";

export const Container = styled.div``;

export const Content = styled.div`
  margin-top: 50px;
`;

export const Top = styled.div`
  ${({ theme }) => css`
    margin-bottom: 30px;

    display: flex;
    align-items: center;

    p {
      font-size: ${theme.font.sizes.large};
      color: ${theme.colors.title};
      font-weight: ${theme.font.bold};
    }
  `}
`;

export const BackButton = styled.button`
  ${({ theme }) => css`
    width: 35px;
    height: 35px;
    background-color: ${theme.colors.background_light};
    border-radius: 10px;

    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 25px;
  `}
`;

export const WrapperInput = styled.div`
  margin-top: 28px;
  margin-bottom: 15px;
`;

export const EmptySearch = styled.p`
  ${({ theme }) => css`
    color: ${theme.colors.financial_title};
    font-weight: bold;
    font-family: ${theme.font.family};
    text-align: center;
    margin-top: 80px;
  `}
`;
