
import styled from "styled-components";

export const Container = styled.div`
  display:flex;
  align-items: center;
  justify-content:center;
  width:100%;
  height:70vh;
  img{
    height:35%;
    width:35%;
  }
`;
