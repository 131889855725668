import React, { createContext, useContext, useEffect, useState } from "react";
import addMaskMoney from "../../utils/addMaskMoney";
import { useTotalBudget } from "./totalBudget";
import { ProviderProps } from "hooks";

type FinanceValue = {
  value: number;
  type: "money" | "percent" | "";
};

type SelectedPromptPaymentData = {
  index: "promptPayment" | "promptPaymentWithDiscount";
  discount: FinanceValue | null;
  total?: number;
  formattedTotal?: string;
  description?: string;
};

type SelectedInstallmentPaymentData = {
  typeInstallment: "simple" | "discount" | "addition";
  numberInstallments: number;
  discount: FinanceValue | null;
  addition: FinanceValue | null;
  total?: number;
  formattedTotal?: string;
  description?: string;
  subdescription?: string;
  installmentDescription?: string;
};

export type SelectedIncomingPaymentData = {
  index: "incomingPayment";
  amount: FinanceValue;
  total?: number;
  formattedTotal?: string;
  description?: string;
};

type SelectedPaymentConditionsContextData = {
  selectedPromptPayment: SelectedPromptPaymentData[];
  selectedInstallmentPayment: SelectedInstallmentPaymentData[];
  selectedIncomingPayment: SelectedIncomingPaymentData[];

  handleSetSelectedPromptPayment: (
    selectedPromptPayment: SelectedPromptPaymentData[],
    budgetValue?: number
  ) => void;
  handleSetSelectedInstallmentPayment: (
    selectedInstallmentPayment: SelectedInstallmentPaymentData[],
    budgetValue?: number
  ) => void;
  handleSetSelectedIncomingPayment: (
    selectedIncomingPayment: SelectedIncomingPaymentData[],
    budgetValue?: number
  ) => void;

  handleDeleteSelectedPaymentConditions: () => void;

  handleDeleteSelectedInstallmentPayment: () => void;
};

const SelectedPaymentConditionsContext =
  createContext<SelectedPaymentConditionsContextData>(
    {} as SelectedPaymentConditionsContextData
  );

export function useSelectedPaymentConditions(): SelectedPaymentConditionsContextData {
  const context = useContext(SelectedPaymentConditionsContext);

  if (!context) {
    throw Error(
      "useSelectedPaymentConditions must be used within a SelectedPaymentConditionsProvider"
    );
  }

  return context;
}

export const SelectedPaymentConditionsProvider = ({ children }: ProviderProps) => {
  const { totalBudget: subtotal } = useTotalBudget();

  const [selectedPromptPayment, setSelectedPromptPayment] = useState<
    SelectedPromptPaymentData[]
  >([]);

  const [selectedInstallmentPayment, setSelectedInstallmentPayment] = useState<
    SelectedInstallmentPaymentData[]
  >([]);

  const [selectedIncomingPayment, setSelectedIncomingPayment] = useState<
    SelectedIncomingPaymentData[]
  >([]);

  useEffect(() => {
    if (subtotal === 0) {
      handleSetSelectedPromptPayment([]);
      handleSetSelectedInstallmentPayment([]);
      handleSetSelectedIncomingPayment([]);
      return;
    }

    if (selectedPromptPayment.length > 0) {
      handleSetSelectedPromptPayment(selectedPromptPayment);
    }
    if (selectedInstallmentPayment.length > 0) {
      handleSetSelectedInstallmentPayment(selectedInstallmentPayment);
    }
    if (selectedIncomingPayment.length > 0) {
      handleSetSelectedIncomingPayment(selectedIncomingPayment);
    }
  }, [subtotal]); // eslint-disable-line react-hooks/exhaustive-deps

  function getFormattedAmount(amount: FinanceValue) {
    if (amount.type === "percent") {
      return `${amount.value}%`;
    }

    return addMaskMoney(amount.value);
  }

  function calculateDiscount(discount: FinanceValue, budgetValue = 0) {
    if (budgetValue <= 0) {
      return budgetValue;
    }
    if (discount.type === "percent") {
      return budgetValue - (budgetValue * discount.value) / 100;
    }
    return budgetValue - discount.value;
  }

  function calculateAddition(addition: FinanceValue, budgetValue = 0) {
    if (budgetValue <= 0) {
      return budgetValue;
    }

    if (addition.type === "percent") {
      return budgetValue + (budgetValue * addition.value) / 100;
    }
    return budgetValue + addition.value;
  }

  function getDescriptionCash(discount: FinanceValue) {
    return `À Vista (${getFormattedAmount(discount)} OFF)`;
  }

  function getDescription(amount: number) {
    return `Parcelado em até ${amount}x`;
  }

  function getSubdescription(
    type: "discount" | "addition",
    amount: FinanceValue
  ) {
    if (type === "discount") {
      return `Desconto de ${getFormattedAmount(amount)}.`;
    }
    return `Acréscimo de ${getFormattedAmount(amount)}.`;
  }

  function getDescriptionInstallment(total: number, amount: number) {
    const result = addMaskMoney(total / amount);
    return `${amount}x de ${result}`;
  }

  function getDescriptionIncoming(amount: FinanceValue) {
    return `Entrada de ${getFormattedAmount(amount)}`;
  }

  function handleSetSelectedPromptPayment(
    promptPaymentData: SelectedPromptPaymentData[],
    budgetValue = 0
  ) {
    const formattedPromptPayment = promptPaymentData.map((item) => {
      let descriptionText = "À Vista";
      let result = budgetValue > 0 ? budgetValue : subtotal;

      if (item.discount) {
        result = calculateDiscount(item.discount, result);
        descriptionText = getDescriptionCash(item.discount);
      }

      if (item.total === 0) {
        descriptionText = "Gratuito";
      }

      return {
        index: item.index,
        discount: item.discount,
        total: result,
        formattedTotal: addMaskMoney(result),
        description: descriptionText,
      };
    });

    setSelectedPromptPayment(formattedPromptPayment);
  }

  function handleSetSelectedInstallmentPayment(
    installmentPaymentData: SelectedInstallmentPaymentData[],
    budgetValue = 0
  ) {
    const formattedInstallmentPayment = installmentPaymentData.map(
      (installment) => {
        let result = budgetValue > 0 ? budgetValue : subtotal;
        let subdescriptionText = "";

        if (installment.discount) {
          result = calculateDiscount(installment.discount, result);
          subdescriptionText = getSubdescription(
            "discount",
            installment.discount
          );
        }

        if (installment.addition) {
          result = calculateAddition(installment.addition, result);
          subdescriptionText = getSubdescription(
            "addition",
            installment.addition
          );
        }

        if (installment.total === 0) {
          subdescriptionText = "Gratuito";
        }

        return {
          typeInstallment: installment.typeInstallment,
          numberInstallments: installment.numberInstallments,
          discount: installment.discount,
          addition: installment.addition,
          total: result,
          formattedTotal: addMaskMoney(result),
          description: getDescription(installment.numberInstallments),
          subdescription: subdescriptionText,
          installmentDescription: getDescriptionInstallment(
            result,
            installment.numberInstallments
          ),
        };
      }
    );

    setSelectedInstallmentPayment(formattedInstallmentPayment);
  }

  function handleSetSelectedIncomingPayment(
    incomingPaymentData: SelectedIncomingPaymentData[],
    budgetValue = 0
  ) {
    const formattedIncomingPayment = incomingPaymentData.map((incoming) => {
      const budgetSubtotal = budgetValue > 0 ? budgetValue : subtotal;
      const totalAmount = calculateDiscount(incoming.amount, budgetSubtotal);

      return {
        index: incoming.index,
        amount: incoming.amount,
        total: totalAmount,
        formattedTotal: addMaskMoney(totalAmount),
        description: getDescriptionIncoming(incoming.amount),
      };
    });

    setSelectedIncomingPayment(formattedIncomingPayment);
  }

  function handleDeleteSelectedPaymentConditions() {
    setSelectedPromptPayment([]);
    setSelectedInstallmentPayment([]);
    setSelectedIncomingPayment([]);
  }

  function handleDeleteSelectedInstallmentPayment() {
    setSelectedInstallmentPayment([]);
  }

  return (
    <SelectedPaymentConditionsContext.Provider
      value={{
        selectedPromptPayment,
        selectedInstallmentPayment,
        selectedIncomingPayment,
        handleSetSelectedPromptPayment,
        handleSetSelectedInstallmentPayment,
        handleSetSelectedIncomingPayment,
        handleDeleteSelectedPaymentConditions,
        handleDeleteSelectedInstallmentPayment,
      }}
    >
      {children}
    </SelectedPaymentConditionsContext.Provider>
  );
};
