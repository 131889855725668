import { useState, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";

import CopyLinkIcon from "assets/icons/member-get-member/copy-link-icon.svg";
import ShareIcon from "assets/icons/member-get-member/share-icon.svg";
import LinearEditSvg from "assets/icons/linear-edit.svg";

import { ClientArea } from "components/ClientArea";
import { ArrowButton } from "components/ArrowButton";
import { Button } from "components/Button";
import { FormItem } from "components/FormItem";
import { Input } from "components/Input";

import { CardResponsible } from "./CardResponsible";
import { UnityArea } from "./UnityArea";
import { AttachmentArea } from "./AttachmentArea";

import { useClient } from "hooks/budget/client";
import { useToast } from "hooks/toast";
import { usePmoc } from "hooks/usePmoc";
import { useHistory } from "react-router";

import { api } from "services/api";

import { hasLettersAndNumbers } from "utils/verifyHasLettersAndNumbers";
import { hasLowercaseLetters } from "utils/hasLowercaseLetters";

import * as S from "./styles";

import { AlertModal } from "components/AlertModal";
import { LoadingProfiz } from "components/LoadingProfiz";
import { ModalRight } from "components/ModalRight";
import { ShareOnSocialMediaModal } from "components/ShareOnSocialMediaModal";
import { EnvironmentDTO } from "dtos/PmocEnvironmentDTO";
import { EnvironmentArea } from "./EnvironmentArea";
import { ScheduledComponent } from "./ScheduledComponent";
import { format } from "date-fns";

import { DayModifiers } from "react-day-picker";

type RouteParams = {
  id: string;
};

type ARTFileProps = {
  url: string;
};

type DictOption = {
  [key: string]: { status: string };
};

type DictMonthOption = {
  [key: string]: number;
};

function StatePmoc(statusPmoc: string) {
  const status: DictOption = {
    Pendente: {
      status: "pending",
    },
    execução: {
      status: "execution",
    },
    Concluída: {
      status: "concluded",
    },
  };
  let value = Object.keys(status).find((key) => {
    const sts = status[key];
    return sts.status === statusPmoc;
  });
  return (
    <S.WapperPmocStatus>
      <div className="element-ball" />
      <p className="text-status">
        {statusPmoc === "execution" && "Em "}
        {value}
      </p>
    </S.WapperPmocStatus>
  );
}

type EnvironmentProps = {
  environmentId: number | string;
  environmentName: string;
  equipments: [
    {
      equipmentId: number | string;
      equipmentTag: string;
    }
  ];
};

type ServiceOrderHistoryProps = {
  year: number;
  months: OSProps[];
};

type OSProps = {
  month: string;
  percentageComplete: string;
};

export function PmocExecutionRegisterForm() {
  const { id } = useParams<RouteParams>();

  const history = useHistory();
  const { addToast } = useToast();
  const {
    initialPmocProps,
    clearPmocData,
    periodProps,
    handleSetUnity,
    handleSetPeriodProps,
    handleSetPmocArtFile,
    handleSetInitialPmocProps,
    handleSetStartDate,
    unity,
    hasUnity,
  } = usePmoc();

  const { client, clearClientData, handleSetClient } = useClient();

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingModal, setIsLoadingModal] = useState(false);
  const [isCopied, setIsCopied] = useState(false);
  const [shareModalIsVisible, setShareModalIsVisible] = useState(false);
  const [sharedLink, setSharedLink] = useState("");

  const [hasErrorOnHash, setHasErrorOnHash] = useState<string[]>([]);
  const [isInputDisabled, setIsInputDisabled] = useState(true);
  const [visibleButtonSaveHash, setVisibleButtonSaveHash] = useState(false);
  const [randomHash, setRandomHash] = useState("");

  const inputRef = useRef<HTMLInputElement>(null);

  const [loadedUnityEnvironments, setLoadedUnityEnvironments] = useState<
    EnvironmentDTO[]
  >([]);

  const [isNotCloseModalEnvironment, setIsNotClosedModalEnvironment] =
    useState(true);

  const [environmentsList, setEnvironmentsList] = useState<EnvironmentProps[]>(
    [] as EnvironmentProps[]
  );
  const [showArchiveAlert, setShowArchiveAlert] = useState(false);

  const [serviceOrderHistory, setServiceOrderHistory] = useState<
    ServiceOrderHistoryProps[]
  >([] as ServiceOrderHistoryProps[]);
  const [stepPage, setStepPage] = useState(0);
  const [stepModal, setStepModal] = useState(false);

  const [orderServiceList, setOrderServiceList] = useState<OSProps[]>(
    [] as OSProps[]
  );

  const [selectedYear, setSelectedYear] = useState(0);

  const [scheduledModal, setScheduledModal] = useState(false);
  const [selectedDate, setSelectedDate] = useState<Date | undefined>(undefined);
  const [mustHaveStartDate, setMustHaveStartDate] = useState(false);
  const [mustBeUpdated, setMustBeUpdated] = useState(false);

  const [finalDate, setFinalDate] = useState<Date>();
  const [statusPmoc, setStatusPmoc] = useState("execution");

  const clearInfos = async () => {
    clearClientData();
    clearPmocData();
  };

  useEffect(() => {
    (async () => {
      if (Object.keys(unity).length > 0 && unity.id) {
        try {
          setIsLoading(true);
          if (hasUnity) {
            const response = await api.get(
              `/clients/${client.id}/unities/${unity?.id}`
            );
            setLoadedUnityEnvironments(response.data.environments);
          }
        } catch (error) {
          addToast({
            type: "error",
            title: "Erro",
            description: "Não foi possível carregar os ambientes.",
          });
        } finally {
          setIsLoading(false);
        }
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [unity]);

  const setData = async () => {
    try {
      setIsLoadingModal(true);
      const response = await api.get(`/pmoc/${id}`);

      response.data &&
        response.data.id &&
        response.data.name &&
        handleSetInitialPmocProps({
          id: response.data.id,
          name: response.data.name,
        });

      response.data &&
        response.data.status &&
        setStatusPmoc(response.data.status);
      response.data &&
        response.data.client &&
        handleSetClient(response.data.client);
      response.data &&
        response.data.unity &&
        handleSetUnity(response.data.unity);

      response.data &&
        response.data.contractPeriod &&
        response.data.contractPeriod.period &&
        response.data.contractPeriod.type &&
        handleSetPeriodProps({
          period: response.data.contractPeriod.period,
          frequency: response.data.contractPeriod.type,
        });

      response.data && response.data.art
        ? handleSetPmocArtFile({
            url: response.data.art,
          })
        : handleSetPmocArtFile({} as ARTFileProps);

      response.data &&
        response.data.environments &&
        setEnvironmentsList(response.data.environments);

      response.data &&
        response.data.years &&
        setServiceOrderHistory(response.data.years);

      response.data &&
        response.data.startDate &&
        handleSetStartDate(response.data.startDate);

      if (response.data && response.data.finalDate) {
        setFinalDate(new Date(`${response.data.finalDate} 00:00:00`));
      }

      setSharedLink(
        `${window.location.origin}/public/pmoc/view/client/${response.data?.hashClientView}`
      );

      response.data &&
        response.data.passClientView &&
        setRandomHash(response.data.passClientView);

      if (response.data && response.data.pmocUpdate) {
        setMustBeUpdated(response.data.pmocUpdate.mustBeUpdated);
        setMustHaveStartDate(response.data.pmocUpdate.mustHaveStartDate);
      }

      if (
        response.data &&
        response.data.years &&
        response.data.years.length === 1
      ) {
        filterOSPercentage(response.data.years[0].year, response.data.years);
        setStepPage(1);
      }
    } catch (error) {
      addToast({
        title: "Erro",
        description:
          axios.isAxiosError(error) && error.response?.data.error
            ? error.response?.data.error
            : "Não foi possível carregar as informações do PMOC",
        type: "error",
      });
    } finally {
      setIsLoadingModal(false);
    }
  };

  useEffect(() => {
    setData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!isNotCloseModalEnvironment) {
      setData();
      setIsNotClosedModalEnvironment(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isNotCloseModalEnvironment]);

  async function handleArchivePmoc() {
    try {
      setIsLoading(true);

      await api.put(`pmoc/${initialPmocProps.id}/archive`, {
        originUpdate: "web",
      });

      addToast({
        type: "success",
        title: "PMOC arquivada com sucesso.",
        description:
          "Você será redirecionado para a listagem de PMOCs arquivadas.",
      });

      history.push(`/pmoc?statusList=3`);

      clearInfos();
    } catch (error) {
      addToast({
        title: "Erro",
        description:
          axios.isAxiosError(error) && error.response?.data.error
            ? error.response?.data.error
            : "Não foi possível arquivar a PMOC",
        type: "error",
      });
    } finally {
      setIsLoading(false);
    }
  }

  function filterOSPercentage(
    year: number,
    osList: ServiceOrderHistoryProps[]
  ) {
    try {
      setIsLoadingModal(true);

      setSelectedYear(year);
      let osFilter = osList.find((os) => {
        return Number(os.year) === Number(year);
      });
      osFilter && setOrderServiceList(osFilter.months);
    } catch (error) {
      addToast({
        title: "Erro",
        description:
          "Não foi possível obter a listagem de ordens de serviço pelo ano escolhido",
        type: "error",
      });
    } finally {
      setIsLoadingModal(false);
    }
  }

  function handleChangeInput(text: string) {
    setRandomHash(text.trim().toUpperCase());

    if (inputRef.current?.value.trim()) setVisibleButtonSaveHash(true);
    else setVisibleButtonSaveHash(false);
  }

  function handleToggleEnableInput() {
    setIsInputDisabled(false);
    inputRef.current?.focus();
  }

  async function handleSaveRandomHash() {
    try {
      setIsLoading(true);
      let hashErrors: string[] = [];
      if (!randomHash) {
        hashErrors = [...hashErrors, "Campo obrigatório"];
      }

      if (randomHash.length !== 6) {
        hashErrors = [...hashErrors, "O campo deve conter 6 caracteres."];
      }

      if (!hasLettersAndNumbers(randomHash)) {
        hashErrors = [...hashErrors, "O campo deve conter letras e números."];
      }

      if (hasLowercaseLetters(randomHash)) {
        hashErrors = [...hashErrors, "Todas as letras tem que ser maiúsculas."];
      }

      if (hashErrors.length > 0) {
        setHasErrorOnHash(hashErrors);
        return;
      }

      await api.put(`/pmoc/${initialPmocProps.id}/view/client/password`, {
        pmocUserClientPass: randomHash,
      });

      setHasErrorOnHash([]);
      setIsInputDisabled(true);
      setVisibleButtonSaveHash(false);

      addToast({
        type: "success",
        title: "Código de acesso atualizado com sucesso",
      });
    } catch (error) {
      addToast({
        title: "Erro",
        description:
          axios.isAxiosError(error) && error.response?.data.error
            ? error.response?.data.error
            : "Não foi possível atualizar o código de acesso.",
        type: "error",
      });
    } finally {
      setIsLoading(false);
    }
  }

  const truncateStr = (str: string, size: number) => {
    var shortText = str;
    if (str.length >= size + 3) {
      shortText = str.substring(0, size).concat("...");
    }
    return shortText;
  };

  const handleCopyIndicationURL = () => {
    navigator.clipboard.writeText(sharedLink);
    setIsCopied(true);
  };

  function monthToNumber(month: string) {
    const dictMonth: DictMonthOption = {
      janeiro: 1,
      fevereiro: 2,
      março: 3,
      abril: 4,
      maio: 5,
      junho: 6,
      julho: 7,
      agosto: 8,
      setembro: 9,
      outubro: 10,
      novembro: 11,
      dezembro: 12,
    };
    return dictMonth[month.toLowerCase()];
  }

  function handleChangeDate(day: Date, { selected, disabled }: DayModifiers) {
    if (disabled) return;
    setSelectedDate(day);
  }

  async function handleNotNeededStartDate() {
    try {
      setIsLoading(true);
      await api.put(`/pmoc/${initialPmocProps.id}`, {
        startDate: "",
      });
      addToast({
        type: "success",
        title: "PMOC atualizado com sucesso.",
      });
      await setData();
    } catch (error) {
      addToast({
        title: "Erro",
        description:
          axios.isAxiosError(error) && error.response?.data.error
            ? error.response?.data.error
            : "Não foi possível atualizar o PMOC",
        type: "error",
      });
    } finally {
      setIsLoading(false);
    }
  }

  async function handleUpdatePmoc() {
    try {
      if (!selectedDate) return;
      setIsLoading(true);

      await api.put(`/pmoc/${initialPmocProps.id}`, {
        startDate: format(selectedDate, "yyyy-MM-dd"),
      });

      addToast({
        type: "success",
        title: "PMOC atualizado com sucesso.",
      });

      setScheduledModal(false);

      await setData();
    } catch (error) {
      addToast({
        title: "Erro",
        description:
          axios.isAxiosError(error) && error.response?.data.error
            ? error.response?.data.error
            : "Não foi possível atualizar o PMOC",
        type: "error",
      });
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <>
      {isLoadingModal ? (
        <LoadingProfiz isVisible={isLoadingModal} />
      ) : (
        <S.Container>
          <ShareOnSocialMediaModal
            isVisible={shareModalIsVisible}
            onCloseModal={() => {
              setShareModalIsVisible(false);
            }}
            sharedLink={sharedLink}
          ></ShareOnSocialMediaModal>
          <AlertModal
            isVisible={showArchiveAlert}
            labelConfirm="Continuar"
            labelCancel="Cancelar"
            title="Atenção!"
            description="Ao arquivar esta PMOC todos os agendamentos não executados serão removidos e todas as ordens serviço não executadas serão arquivadas. Deseja continuar?"
            action="choose"
            handleConfirm={() => {
              setShowArchiveAlert(false);
              handleArchivePmoc();
            }}
            onCloseModal={() => setShowArchiveAlert(false)}
          />

          <ModalRight
            isOpen={scheduledModal}
            handleToggleOpen={() => setScheduledModal(false)}
          >
            <ScheduledComponent
              closeModal={() => {
                setScheduledModal(false);
              }}
              loading={isLoading}
              selectedDate={selectedDate}
              handleChangeDate={handleChangeDate}
              handleUpdatePmoc={handleUpdatePmoc}
              finalDate={finalDate}
            />
          </ModalRight>

          <ModalRight
            isOpen={stepModal}
            handleToggleOpen={() => {
              setStepModal(false);
            }}
          >
            {stepPage < 1 ? (
              <>
                <S.Container>
                  <ArrowButton
                    handleFunction={() => {
                      setStepModal(false);
                    }}
                  />
                  <S.ModalHeader>
                    <p className="title">Histórico de ordens de serviço</p>
                    <p className="subtitle">
                      Acompanhe o histórico das ordens de serviço deste PMOC.
                    </p>
                  </S.ModalHeader>
                  <div>
                    {serviceOrderHistory.map((os, index) => {
                      return (
                        <S.OSCard
                          key={index}
                          typeCursor="pointer"
                          onClick={() => {
                            filterOSPercentage(os.year, serviceOrderHistory);
                            setStepPage(1);
                          }}
                        >
                          <p className="text">{os.year}</p>
                        </S.OSCard>
                      );
                    })}
                  </div>
                </S.Container>
              </>
            ) : (
              <>
                <S.Container>
                  <ArrowButton
                    handleFunction={() => {
                      if (serviceOrderHistory.length === 1)
                        return setStepModal(false);
                      setStepPage(0);
                    }}
                  />

                  <S.ModalHeader>
                    <p className="title">Histórico de ordens de serviço</p>
                    <p className="subtitle">
                      Acompanhe o histórico das ordens de serviço deste PMOC.
                    </p>
                  </S.ModalHeader>

                  <div>
                    {orderServiceList.map((os, index) => {
                      return (
                        <S.OSCard
                          key={index}
                          typeCursor="pointer"
                          onClick={() => {
                            let monthNumber = monthToNumber(
                              os.month.toLocaleLowerCase()
                            );
                            history.push(
                              `/pmoc/execution/order-services-list/${initialPmocProps.id}/${selectedYear}/${monthNumber}?statusList=0`
                            );
                          }}
                        >
                          <p className="text">{os.month}</p>
                          <p className="text">
                            {os.percentageComplete}% concluído
                          </p>
                        </S.OSCard>
                      );
                    })}
                  </div>
                </S.Container>
              </>
            )}
          </ModalRight>

          <div style={{ marginTop: 20 }}>
            <ArrowButton
              handleFunction={() => {
                history.push("/pmoc?statusList=1");
                clearInfos();
              }}
            />
          </div>
          <header>
            <S.Title>{initialPmocProps.name} </S.Title>
            {StatePmoc(statusPmoc)}
          </header>

          <div className="content">
            <S.Wrapper>
              <ClientArea disableDeleteButton disableEditButton />
            </S.Wrapper>

            <S.Wrapper>
              <UnityArea disableDeleteButton disableEditButton />
            </S.Wrapper>

            <S.Wrapper>
              <CardResponsible />
            </S.Wrapper>

            <S.Wrapper>
              {periodProps && periodProps.period ? (
                <FormItem
                  name="Período do contrato*"
                  placeholder={periodProps.period + " " + periodProps.frequency}
                  disabled
                />
              ) : (
                <FormItem
                  name="Período do contrato*"
                  placeholder={`Sem período cadastrado`}
                  disabled
                />
              )}
            </S.Wrapper>

            <S.WrapperInput>
              <div>
                <Input
                  name="Código de acesso da PMOC*"
                  placeholder="Ex: A1B2C3"
                  value={randomHash}
                  onChange={(e) => handleChangeInput(e.target.value)}
                  customRef={inputRef}
                  readOnly={isInputDisabled}
                  labelIcon="Ativar campo de código"
                  icon={visibleButtonSaveHash ? "" : LinearEditSvg}
                  onClickIcon={handleToggleEnableInput}
                  hasError={hasErrorOnHash[0]}
                  maxLength={6}
                />
              </div>

              {visibleButtonSaveHash && (
                <S.ButtonSaveHash onClick={handleSaveRandomHash}>
                  Salvar
                </S.ButtonSaveHash>
              )}
            </S.WrapperInput>

            {hasErrorOnHash.length > 0 &&
              hasErrorOnHash.map((error) => (
                <S.WrapperHashError>
                  <span>*{error}</span>
                </S.WrapperHashError>
              ))}

            <S.WrapperInfo>
              <span>
                O código de acessso serve para liberar os dados da PMOC no
                painel do cliente.
              </span>
            </S.WrapperInfo>

            <S.Wrapper>
              <AttachmentArea />
            </S.Wrapper>

            {environmentsList && environmentsList.length > 0 && (
              <S.Wrapper>
                <S.TitleExecutionButton>
                  Ambientes e equipamentos
                </S.TitleExecutionButton>
                {environmentsList.map((element) => {
                  return (
                    <S.EnvironmentList key={element.environmentId}>
                      <p className="card-title"> {element.environmentName} </p>
                      <p className="card-text">
                        {" "}
                        |{" "}
                        {element.equipments.length > 1
                          ? `${element.equipments.length} equipamentos`
                          : `${element.equipments.length} equipamento`}{" "}
                      </p>
                    </S.EnvironmentList>
                  );
                })}
              </S.Wrapper>
            )}

            <S.Wrapper>
              <EnvironmentArea
                environments={loadedUnityEnvironments}
                setOpenModal={setIsNotClosedModalEnvironment}
              />
            </S.Wrapper>

            <S.Wrapper>
              <Button
                disabled={!mustBeUpdated}
                onClick={async () => {
                  if (!mustHaveStartDate) {
                    await handleNotNeededStartDate();
                    setData();
                    return;
                  }
                  setScheduledModal(true);
                }}
              >
                Atualizar PMOC
              </Button>
            </S.Wrapper>

            <S.Wrapper>
              <Button
                disabled={mustBeUpdated}
                onClick={() => {
                  setStepModal(true);
                }}
                typeButton="outlineDisable"
              >
                Histórico de ordens de serviço
              </Button>
            </S.Wrapper>

            <S.ShareArea>
              <S.IndicationLink>{truncateStr(sharedLink, 50)}</S.IndicationLink>
              <S.CopyButton onClick={handleCopyIndicationURL}>
                <img src={CopyLinkIcon} alt="copiar link de indicação" />
                <S.CopyText>{isCopied ? "Copiado" : "Copiar"}</S.CopyText>
              </S.CopyButton>
              <S.ShareButton onClick={() => setShareModalIsVisible(true)}>
                <img src={ShareIcon} alt="compartilhar" />
                <p>Compartilhar</p>
              </S.ShareButton>
            </S.ShareArea>

            <Button
              typeButton="textOnly"
              onClick={() => {
                setShowArchiveAlert(true);
              }}
              loading={isLoading}
              disabled={isLoading}
            >
              Arquivar PMOC
            </Button>
          </div>
        </S.Container>
      )}
    </>
  );
}
