import { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router";
import { format } from "date-fns";
import axios from "axios";

import { useToast } from "hooks/toast";
import { useServiceOrder } from "hooks/serviceOrder";
import { ServiceWithChecklistDTO } from "dtos/ServiceWithChecklistDTO";

import { LoadingProfiz } from "components/LoadingProfiz";
import { ArrowButton } from "components/ArrowButton";
import { Button } from "components/Button";
import { ButtonSVG } from "components/ButtonSVG";
import { UserExecutionCard } from "components/UserExecutionCard";

import apiv2 from "services/apiv2";

import * as S from "./styles";

type RouteParams = {
  id: string;
  idPmoc: string;
  year: string;
  month: string;
};

type DictOption = {
  [key: string]: string;
};

export function ScheduledOSPage() {
  const { id, idPmoc, year, month } = useParams<RouteParams>();
  const { addToast } = useToast();
  const [isLoading, setIsLoading] = useState(false);
  const history = useHistory();

  const [disableButton, setDisableButton] = useState(false);

  const { selectedServiceOrder, handleSetSelectedServiceOrder } =
    useServiceOrder();

  async function setData() {
    try {
      setIsLoading(true);
      const response = await apiv2.get(`/budgets/service-order/${id}`);
      handleSetSelectedServiceOrder(response.data);

      response.data.services &&
        setDisableButton(
          response.data.services.find(
            (element: ServiceWithChecklistDTO) => element.status === "concluded"
          )
        );
    } catch (error) {
      addToast({
        title: "Erro",
        description:
          axios.isAxiosError(error) && error.response?.data.error
            ? error.response?.data.error
            : "Não foi possível carregar as informações da ordem de serviço.",
        type: "error",
      });
    } finally {
      setIsLoading(false);
    }
  }

  useEffect(() => {
    setData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function statusOrderService(status: string) {
    const dictOS: DictOption = {
      pending: "Não agendado",
      scheduled: "Agendado",
      concluded: "Concluído",
      archived: "Arquivado",
    };
    return dictOS[status.toLowerCase()];
  }

  function formatedScheduledDate(date: string) {
    const dateSchedule = new Date(date);
    return format(dateSchedule, "dd/MM/yyyy HH:mm");
  }

  function handleOpenPdfPage() {
    history.push({
      pathname: "/download/pdf",
      state: {
        pdfDownloadLink: selectedServiceOrder.detailLink,
      },
    });
  }

  async function handleArchiveOS() {
    try {
      setIsLoading(true);

      await apiv2.put(`/budgets/service-order/${id}/archive`, {
        originUpdate: "web",
      });

      addToast({
        type: "success",
        title: "Ordem de serviço arquivada com sucesso.",
        description:
          "Você será redirecionado para a listagem de ordens de serviço arquivadas.",
      });

      history.push(
        `/pmoc/execution/order-services-list/${idPmoc}/${year}/${month}?statusList=3`
      );
    } catch (error) {
      addToast({
        title: "Erro",
        description:
          axios.isAxiosError(error) && error.response?.data.error
            ? error.response?.data.error
            : "Não foi possível arquivar a ordem de serviço.",
        type: "error",
      });
    } finally {
      setIsLoading(false);
    }
  }

  function handleRedirect() {
    history.push(
      `/pmoc/execution/order-service/scheduling/${id}/${idPmoc}/${year}/${month}`
    );
  }

  function handleStartServiceOrder() {
    history.push(`/pmoc-service-photos/${id}/${idPmoc}/${year}/${month}`);
  }
  return (
    <>
      {isLoading && <LoadingProfiz isVisible={isLoading} />}
      <S.Container>
        <S.Wrapper>
          <ArrowButton
            handleFunction={() => {
              history.push(
                `/pmoc/execution/order-services-list/${idPmoc}/${year}/${month}?statusList=1`
              );
            }}
          />
        </S.Wrapper>
        <header>
          {selectedServiceOrder.services &&
            selectedServiceOrder.services.map((element) => {
              return (
                <S.Title key={element.idBudgetService}>
                  {element.service.service.name}
                </S.Title>
              );
            })}
        </header>
        <S.Wrapper>
          {selectedServiceOrder.environment &&
            selectedServiceOrder.equipment && (
              <S.Subtitle>
                Esta ordem de serviço está vinculada ao equipamento
                <br />
                <strong>
                  {selectedServiceOrder.equipment.tagEquipment}
                </strong>{" "}
                do ambiente{" "}
                <strong>{selectedServiceOrder.environment.name}</strong>.
              </S.Subtitle>
            )}

          <S.WrapperStatusOS>
            <span>Status: </span>

            <div>
              <S.BadgeStatus />
              <span>Em andamento</span>
            </div>
          </S.WrapperStatusOS>
        </S.Wrapper>
        <div className="content">
          {selectedServiceOrder.environment && (
            <S.Wrapper>
              <S.CardInfo>
                <p className="title">{selectedServiceOrder.environment.name}</p>
              </S.CardInfo>
            </S.Wrapper>
          )}

          {selectedServiceOrder.equipment && (
            <S.Wrapper>
              <S.CardInfo>
                <p className="title">
                  {selectedServiceOrder.equipment.tagEquipment}
                </p>
              </S.CardInfo>
            </S.Wrapper>
          )}

          {selectedServiceOrder.services &&
            selectedServiceOrder.services.map((element) => {
              return (
                <S.OSCard key={element.idBudgetService}>
                  <S.CardDetail>
                    <div className="text-container">
                      <p className="title">
                        <strong>{element.service.service.name}</strong>
                      </p>
                      {element.scheduleDate &&
                        element.scheduleDate.length > 0 && (
                          <p className="text-info">
                            <strong>
                              Agendado para:{" "}
                              {formatedScheduledDate(element.scheduleDate)}
                            </strong>
                          </p>
                        )}
                      <p className="text-info">
                        Cliente: {selectedServiceOrder.client.name}{" "}
                      </p>
                      <p className="text-info">
                        Periodicidade:{" "}
                        {!!element.service.frequency &&
                          element.service.frequency[0].toUpperCase() +
                            element.service.frequency.substring(1)}
                      </p>
                    </div>

                    <div className="container-ball">
                      <div className="element-ball" />
                      <p className="text-status">
                        {statusOrderService(element.status)}
                      </p>
                    </div>
                  </S.CardDetail>

                  {element?.userExecution && (
                    <UserExecutionCard userExecution={element.userExecution} />
                  )}
                </S.OSCard>
              );
            })}

          <S.Wrapper>
            <ButtonSVG
              title="Download PDF"
              typeSvg="pdf"
              onClick={handleOpenPdfPage}
            />
          </S.Wrapper>

          <S.WrapperButtons>
            <Button onClick={handleRedirect} disabled={disableButton}>
              Reagendar serviço
            </Button>

            <Button onClick={handleStartServiceOrder}>
              Iniciar checklist do serviço
            </Button>

            <Button typeButton="delete" onClick={handleArchiveOS}>
              Arquivar ordem de serviço
            </Button>
          </S.WrapperButtons>
        </div>
      </S.Container>
    </>
  );
}
