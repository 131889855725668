import styled, { css } from "styled-components";

export const Content = styled.div`
  height: "6vh";
  min-height: 530px;
`;

export const ButtonClose = styled.button`
  ${({ theme }) => css`
    width: 35px;
    height: 35px;
    background-color: ${theme.colors.background_light};
    border-radius: 10px;

    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 25px;
    margin-bottom: 30px;
    margin-top: 50px;
  `}
`;

export const Title = styled.div`
  ${({ theme }) => css`
    font-family: ${theme.font.family};
    font-weight: 800;
    font-size: 18px;
    line-height: 22px;
    color: rgba(255, 255, 255, 0.87);
  `}
`;

export const Footer = styled.div`
  width: 100%;
  height: 50px;
  margin-top: auto;

  display: flex;
  justify-content: flex-end;

  button {
    width: 50px;

    img {
      width: 22px;
      height: 22px;
    }
  }
`;
