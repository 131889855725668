/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";

import { SavedEvents } from "./SavedEvents";
import { AllEvents } from "./AllEvents";
import { DetailEvent } from "./detailEvent";
import { updateUserOnboarding } from "utils/updateUserOnboarding";

import { ModalRight } from "components/ModalRight";
import OnboardingCard from "components/OnboardingCard";
import { ArrowButton } from "components/ArrowButton";

import { api } from "services/api";
import { useAuth } from "hooks/auth";
import { useToast } from "hooks/toast";
import { useEvent } from "hooks/events";

import * as S from "./styles";
import "react-tabs/style/react-tabs.css";
import { ModalRejectedRegistration } from "components/ModalRejectedRegistration";

type Props = {
  idEvent?: number;
};

export function EventsCalendar({ idEvent }: Props) {
  const { user, updateUser } = useAuth();
  const { handleSetSelectedEvent } = useEvent();
  const { addToast } = useToast();

  const [tourModalVisible, setTourModalVisible] = useState(false);
  const [currentStep, setCurrentStep] = useState(1);

  const [selectPage, setSelectedPage] = useState<number>(0);
  const [showEventDetail, setShowEventDetail] = useState(false);
  const [activeModalRejectedRegistration, setActiveModalRejectedRegistration] =
    useState(false);

  useEffect(() => {
    const userSeenOnboarding = user.onboarding.eventsCalendarOnboarding;
    if (!userSeenOnboarding) {
      setTourModalVisible(true);
    }

    if (user?.profileDisapproved) {
      setActiveModalRejectedRegistration(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function updateUserOnboardingEventsCalendar() {
    try {
      const updateUserData = updateUserOnboarding(
        user,
        "eventsCalendarOnboarding"
      );

      await api.put("/users/me", updateUserData);
      updateUser(updateUserData);
    } catch (error) {
      addToast({
        title: "Erro",
        description: "Ocorreu um erro ao atualizar o usuário.",
        type: "error",
      });
    }
  }

  const getEventDetails = async (id: string) => {
    try {
      const { data } = await api.get(`events/${id}`);
      handleSetSelectedEvent(data);
      setShowEventDetail(true);
      localStorage.removeItem("@Profiz:EventId");
    } catch (error) {
      addToast({
        title: "Erro !",
        description: `Ocorreu um erro ao carregar o detalhe do evento.`,
        type: "error",
      });
    }
  };

  useEffect(() => {
    const eventId = localStorage.getItem("@Profiz:EventId");
    if (eventId) {
      getEventDetails(eventId);
    }
  }, []);

  return (
    <S.Container>
      <ModalRight
        isOpen={showEventDetail}
        handleToggleOpen={() => setShowEventDetail(false)}
      >
        {showEventDetail && (
          <DetailEvent
            typeView={0}
            handleCloseFunction={() => setShowEventDetail(false)}
          />
        )}
      </ModalRight>
      <OnboardingCard
        currentStep={currentStep}
        setCurrentStep={setCurrentStep}
        isTourModalVisible={tourModalVisible}
        setIsTourModalVisible={setTourModalVisible}
        onUpdateOnboarding={updateUserOnboardingEventsCalendar}
        type={"eventsCalendarOnboarding"}
      />
      <ModalRejectedRegistration
        isVisible={activeModalRejectedRegistration}
        setIsActive={setActiveModalRejectedRegistration}
      />
      <S.Content>
        <S.Header>
          <div>
            <ArrowButton />
          </div>
          <S.HeaderTitle>Calendário de eventos</S.HeaderTitle>
        </S.Header>

        {/*filtro de data aqui....*/}
        <S.TabContainer>
          <Tabs
            selectedIndex={selectPage}
            onSelect={(index: number) => {
              setSelectedPage(index);
            }}
          >
            <TabList>
              <Tab>Todos</Tab>
              <Tab>Salvos</Tab>
            </TabList>

            {/*conteudo das duas paginas*/}

            <TabPanel>
              {/*passar o filtro como prop nos componentes da tab...*/}
              <AllEvents idElement={idEvent} />
            </TabPanel>

            <TabPanel>
              <SavedEvents />
            </TabPanel>
          </Tabs>
        </S.TabContainer>
      </S.Content>
    </S.Container>
  );
}
