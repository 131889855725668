import { useEffect, useState } from "react";
import { useTheme } from "styled-components";
import { useHistory, useParams } from "react-router";
import { FiChevronLeft } from "react-icons/fi";
import { format, parseISO } from "date-fns";

import { useToast } from "hooks/toast";

import { LoadingProfiz } from "components/LoadingProfiz";
import { ContentBox } from "components/ContentBox";
import { ButtonSVG } from "components/ButtonSVG";
import { Button } from "components/Button";
import { CalendarButton } from "components/CalendarButton";
import { ShowWhenHavePermission } from "components/Permission";
import { RenderLocationAddress } from "components/RedirectLocationAddress";
import { UserExecutionCard } from "components/UserExecutionCard";
import { CardChecklistDetail } from "components/CardChecklistDetail";
import { ModalRight } from "components/ModalRight";
import { ModalObservationDetail } from "components/ModalObservationDetail";

import {
  ClientProps,
  ServiceProps,
  LocalChecklistProps,
  MaterialProps,
  ServiceOrderDetailProps,
} from "dtos/ServiceOrderDetailDTO";
import { UnityDTO } from "dtos/ClientDTO";
import { RegisterProps } from "dtos/ServiceChecklistDTO";

import CheckSvg from "assets/icons/check.svg";

import {
  useSelectedServiceToSchedule,
  ServiceToScheduleProps,
} from "hooks/selectedServiceToSchedule";
import { useServiceOrder } from "hooks/serviceOrder";
import { useAccount } from "hooks/permission/account";

import { api } from "services/api";
import apiv2 from "services/apiv2";

import * as S from "./styles";

type RouteParams = {
  id: string;
};

type ScheduleCalendar = {
  date: string;
  time: string;
  serviceId?: string;
};

export type MappedServiceProps = {
  hour: string[];
} & ServiceProps;

type UnitiesResponse = {
  unities: UnityDTO[];
};

type CardChecklistDetailProps = {
  nameCheckList: string;
  checklistRegisters: RegisterProps[];
};

export function ServiceOrderScheduledDetail() {
  const { id } = useParams<RouteParams>();

  const theme = useTheme();
  const history = useHistory();
  const { addToast } = useToast();
  const { whoami } = useAccount();
  const {
    selectedServiceToSchedule,
    selectedMemberIdsByService,
    handleSelectedMemberIdByService,
    handleClearSelectedMemberIds,
    handleSetSelectedServiceToSchedule,
  } = useSelectedServiceToSchedule();
  const { selectedServiceOrder } = useServiceOrder();

  const [isLoading, setIsLoading] = useState(true);
  const [openModalCalendar, setOpenModalCalendar] = useState(false);
  const [currentScheduleDate, setCurrentScheduleDate] = useState("");
  const [pdfLink, setPdfLink] = useState("");
  const [sequenceId, setSequenceId] = useState<number | null>(null);

  const [clientInServiceOrder, setClientInServiceOrder] = useState(
    {} as ClientProps
  );

  const [unityClient, setUnityClient] = useState({} as UnityDTO);

  const [servicesInServiceOrder, setServicesInServiceOrder] = useState<
    MappedServiceProps[]
  >([]);

  const [checklistInServiceOrder, setChecklistInServiceOrder] = useState<
    LocalChecklistProps[]
  >([]);

  const [materialsInServiceOrder, setMaterialsInServiceOrder] = useState<
    MaterialProps[]
  >([]);

  const [observationInServiceOrder, setObservationInServiceOrder] =
    useState("");
  const [formattedCreationDate, setFormattedCreationDate] = useState("");

  const { handleSetSelectedServiceOrder } = useServiceOrder();

  const [addressNotExists, setAddressNotExists] = useState(false);

  const [isOpenObservationDetail, setIsOpenObservationDetail] = useState("");
  const [isOpenCheckListDetail, setIsOpenCheckListDetail] =
    useState<CardChecklistDetailProps>({} as CardChecklistDetailProps);

  async function loadUnityData(clientResponse: ClientProps) {
    try {
      if (
        clientResponse?.address &&
        clientResponse?.address?.id?.toString() === "0"
      ) {
        setAddressNotExists(true);
        return;
      }

      if (clientResponse?.address && clientResponse?.address?.id) {
        clientResponse.address &&
          setUnityClient(clientResponse?.address as UnityDTO);
        return;
      }

      const response = await api.get<UnitiesResponse>(
        `/clients/${clientResponse.id}/unities`,
        {
          params: {
            limit: 50,
            offset: 0,
            client_id: clientResponse.id,
          },
        }
      );

      const defaultUnity = response.data.unities.find((unity) => unity.default);

      if (response.data.unities.length < 1 || !defaultUnity) {
        setAddressNotExists(true);
        return;
      }

      defaultUnity && setUnityClient(defaultUnity);
    } catch {
      addToast({
        title: "Opss",
        description:
          "Ocorreu um erro ao carregar o endereço do cliente, tente novamente.",
        type: "error",
      });
    }
  }

  useEffect(() => {
    getServiceOrderDetails();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  async function getServiceOrderDetails() {
    try {
      const { data } = await apiv2.get(`/budgets/service-order/${id}`, {
        params: {
          accountId: whoami?.id,
        },
      });

      const {
        sequenceNumber,
        detailLink,
        createdAt,
        client,
        services,
        localChecklists,
        materials,
        observations,
      }: ServiceOrderDetailProps = data;

      setSequenceId(sequenceNumber);
      setPdfLink(detailLink);
      const formattedDate = format(new Date(createdAt), "dd/MM/yyyy");
      setFormattedCreationDate(formattedDate);

      const formattedServices = getServicesMapped(services);

      setClientInServiceOrder(client);
      await loadUnityData(client);
      setServicesInServiceOrder(formattedServices);
      setChecklistInServiceOrder(localChecklists);
      setMaterialsInServiceOrder(materials);
      setObservationInServiceOrder(observations);

      handleSetSelectedServiceOrder(data);
    } catch (error) {
      addToast({
        type: "error",
        title: "Ops...",
        description: "Erro ao buscar detalhes da ordem de serviço",
      });
    } finally {
      setIsLoading(false);
    }
  }

  function getServicesMapped(servicesParam: ServiceProps[]) {
    const mappedServices = servicesParam.map((currentService) => {
      const formattedScheduleDate = !!currentService.scheduleDate
        ? format(new Date(currentService.scheduleDate), "dd/MM/yyyy - HH:mm")
        : "";

      return {
        ...currentService,
        hour: currentService?.scheduleDate.split(" "),
        scheduleDate: formattedScheduleDate,
      };
    });

    return mappedServices;
  }

  function handleGoBack() {
    history.push("/service-order", {
      before: "",
    });
  }

  async function handleStartServiceOrder() {
    history.push(`/service-photos/${id}`);
  }

  async function handleCreateSchedule(date: Date) {
    try {
      setIsLoading(true);

      const dateFormatted = format(date, "yyyy-MM-dd HH:mm:ss");

      await apiv2.post(`budgets/service-order/${id}/schedule`, {
        services: [
          {
            idBudgetService: selectedServiceToSchedule.idBudgetService,
            id: selectedServiceToSchedule.serviceData.id,
            date: dateFormatted,
            userIdExecution:
              selectedMemberIdsByService[
                selectedServiceToSchedule.idBudgetService
              ].id,
          },
        ],
        originUpdate: "web",
      });

      getServiceOrderDetails();
      onToggleModalCalendar();
    } catch (error) {
      addToast({
        title: "Ops!!",
        description: "Não foi possível agendar o serviço",
        type: "error",
      });
    } finally {
      setIsLoading(false);
    }
  }

  async function handleUpdateSchedule(date: Date) {
    try {
      const dateFormatted = format(date, "yyyy-MM-dd HH:mm:ss");

      await apiv2.put(`budgets/service-order/${id}/reschedule`, {
        services: [
          {
            idBudgetService: selectedServiceToSchedule.idBudgetService,
            id: selectedServiceToSchedule.serviceData.id,
            date: dateFormatted,
            userIdExecution:
              selectedMemberIdsByService[
                selectedServiceToSchedule.idBudgetService
              ].id,
          },
        ],
      });

      getServiceOrderDetails();
      onToggleModalCalendar();
    } catch (error) {
      addToast({
        title: "Ops!!",
        description: "Não foi possível atualizar o agendamento do serviço",
        type: "error",
      });
    }
  }

  function handleConfirmSchedule(scheduleInfo: ScheduleCalendar) {
    const parsedDate = parseISO(`${scheduleInfo.date} ${scheduleInfo.time}`);

    const dateFormatted = format(parsedDate, "dd/MM/yyyy - HH:mm");

    if (selectedServiceToSchedule.status === "pending") {
      handleCreateSchedule(parsedDate);
    }

    if (selectedServiceToSchedule.status === "scheduled") {
      handleUpdateSchedule(parsedDate);
    }

    setServicesInServiceOrder((oldServices) =>
      oldServices.map((oldService) => {
        if (
          oldService.idBudgetService ===
          selectedServiceToSchedule.idBudgetService
        ) {
          return {
            ...oldService,
            scheduleDateFormatted: dateFormatted,
            status: "scheduled",
          };
        }
        return oldService;
      })
    );

    getServiceOrderDetails();
  }

  function onToggleModalCalendar() {
    setOpenModalCalendar(!openModalCalendar);
  }

  function handleToggleModalCalendarAndSetData(
    serviceToSchedule: ServiceToScheduleProps,
    dateTime: string
  ) {
    onToggleModalCalendar();
    setCurrentScheduleDate(dateTime);

    if (!!serviceToSchedule.serviceData.runtime.time) {
      handleSetSelectedServiceToSchedule(serviceToSchedule);
    } else {
      handleSetSelectedServiceToSchedule({
        ...serviceToSchedule,
        serviceData: {
          ...serviceToSchedule.serviceData,
          runtime: { time: 2, extension: "Horas" },
        },
      });
    }
  }

  async function handleArchiveServiceOrder() {
    try {
      setIsLoading(true);

      await apiv2.put(`/budgets/service-order/${id}/archive`, {
        originUpdate: "web",
      });

      addToast({
        type: "success",
        title: "Ordem de serviço arquivada",
        description: "",
      });

      history.push({
        pathname: "/service-order",
        state: { before: "ServiceOrderPendingDetail" },
      });
    } catch (error) {
      addToast({
        type: "error",
        title: "Ops...",
        description: "Erro ao arquivar ordem de serviço",
      });
    } finally {
      setIsLoading(false);
    }
  }

  function handleOpenPdfPage() {
    history.push({
      pathname: "/download/pdf",
      state: {
        pdfDownloadLink: pdfLink,
      },
    });
  }

  function handleDeleteServiceSchedule() {
    setOpenModalCalendar(false);
    setIsLoading(true);
    getServiceOrderDetails();
  }

  function handleToggleModalObservationDetail(observationDetail: string) {
    setIsOpenObservationDetail(observationDetail);
  }

  function handleToggleModalCheckListDetail({
    nameCheckList,
    checklistRegisters,
  }: CardChecklistDetailProps) {
    setIsOpenCheckListDetail({ nameCheckList, checklistRegisters });
  }

  return (
    <>
      <ModalRight
        isOpen={!!isOpenObservationDetail}
        // eslint-disable-next-line react/jsx-no-bind
        handleToggleOpen={() => handleToggleModalObservationDetail("")}
      >
        <ModalObservationDetail
          observation={isOpenObservationDetail}
          // eslint-disable-next-line react/jsx-no-bind
          handleGoBack={() => handleToggleModalObservationDetail("")}
        />
      </ModalRight>

      <ModalRight
        isOpen={!!isOpenCheckListDetail?.nameCheckList}
        // eslint-disable-next-line react/jsx-no-bind
        handleToggleOpen={() =>
          handleToggleModalCheckListDetail({} as CardChecklistDetailProps)
        }
      >
        <CardChecklistDetail
          nameCheckList={isOpenCheckListDetail?.nameCheckList}
          checklistRegisters={isOpenCheckListDetail?.checklistRegisters}
          // eslint-disable-next-line react/jsx-no-bind
          handleGoBack={() =>
            handleToggleModalCheckListDetail({} as CardChecklistDetailProps)
          }
        />
      </ModalRight>
      {isLoading ? (
        <LoadingProfiz isVisible={isLoading} />
      ) : (
        <S.Container>
          <S.Top>
            <S.ButtonClose onClick={() => handleGoBack()}>
              <FiChevronLeft size={10} color={theme.colors.title} />
            </S.ButtonClose>

            <h4>Ordem de Serviço {sequenceId}</h4>
          </S.Top>

          <S.WrapperDate>
            <span>Data de Criação: {formattedCreationDate}</span>

            <div>
              <div />
              <span>Em andamento</span>
            </div>
          </S.WrapperDate>

          <S.Content>
            <div>
              <span>Cliente:</span>

              <ContentBox>
                <S.ContentBoxTitle>
                  {clientInServiceOrder.name}
                </S.ContentBoxTitle>
                <S.ContentBoxText>
                  {clientInServiceOrder.cpf_cnpj}
                </S.ContentBoxText>
                <S.ContentBoxText>
                  {clientInServiceOrder.phone}
                </S.ContentBoxText>
                <S.ContentBoxText>
                  {clientInServiceOrder.email}
                </S.ContentBoxText>
              </ContentBox>
            </div>

            <div>
              <ContentBox title="Endereço">
                {addressNotExists ? (
                  <S.ContentBoxText>Endereço não informado.</S.ContentBoxText>
                ) : (
                  <>
                    <RenderLocationAddress
                      city={unityClient?.city}
                      complement={unityClient?.complement}
                      district={unityClient?.district}
                      number={Number(unityClient?.number)}
                      postalCode={unityClient?.postalCode}
                      street={unityClient?.street}
                      uf={unityClient?.uf}
                    />
                  </>
                )}
              </ContentBox>
            </div>

            {servicesInServiceOrder.length > 0 && (
              <div>
                <S.ServicesTitle>Serviços:</S.ServicesTitle>

                {servicesInServiceOrder.map((service, index) => {
                  const {
                    service: serviceData,
                    quantity,
                    scheduleDate,
                    hour,
                    status,
                    idBudgetService,
                    userExecution,
                  } = service;

                  return (
                    <div key={idBudgetService}>
                      <ContentBox>
                        <S.WrapperContentService>
                          <div>
                            <span>
                              {serviceData.service.name} ({quantity})
                            </span>

                            {!!scheduleDate && status === "concluded" && (
                              <div>
                                <S.BadgeStatus color="primary" />
                                <S.ServiceScheduleStatusText>
                                  Concluído em: {scheduleDate}
                                </S.ServiceScheduleStatusText>
                              </div>
                            )}

                            {!!scheduleDate && status === "scheduled" && (
                              <ShowWhenHavePermission
                                moduleHash="schedule"
                                actionHash="view"
                              >
                                <div>
                                  <S.BadgeStatus />
                                  <S.ServiceScheduleStatusText>
                                    Agendado para: {scheduleDate}
                                  </S.ServiceScheduleStatusText>
                                </div>
                              </ShowWhenHavePermission>
                            )}

                            {!scheduleDate && status !== "concluded" && (
                              <div>
                                <S.BadgeStatus color="error" />

                                <S.ServiceScheduleStatusText>
                                  Não agendado
                                </S.ServiceScheduleStatusText>
                              </div>
                            )}
                          </div>

                          {status === "concluded" && (
                            <S.CheckIconImage src={CheckSvg} alt="check" />
                          )}

                          {status === "pending" && (
                            <ShowWhenHavePermission
                              moduleHash="schedule"
                              actionHash="create"
                            >
                              <CalendarButton
                                openModalCalendar={openModalCalendar}
                                onToggleModalCalendar={() => {
                                  handleClearSelectedMemberIds();
                                  handleSelectedMemberIdByService({
                                    [idBudgetService]: {
                                      id: userExecution?.id || 0,
                                      name: userExecution?.name || "",
                                      photo: userExecution?.photo || "",
                                    },
                                  });
                                  handleToggleModalCalendarAndSetData(
                                    {
                                      idBudgetService,
                                      status,
                                      serviceData: {
                                        id: serviceData.id,
                                        name: serviceData.service.name,
                                        runtime: serviceData.runtime,
                                      },
                                      serviceOrderId: Number(id),
                                    },
                                    `${hour[0]} ${hour[1]}`
                                  );
                                }}
                                currentScheduleDate={currentScheduleDate}
                                handleSelectedDate={(scheduleInfo) => {
                                  handleConfirmSchedule(scheduleInfo);
                                }}
                                onDeleteServiceSchedule={
                                  handleDeleteServiceSchedule
                                }
                              />
                            </ShowWhenHavePermission>
                          )}

                          {status === "scheduled" &&
                            selectedServiceOrder?.origin !== "mse" && (
                              <ShowWhenHavePermission
                                moduleHash="schedule"
                                actionHash="edit"
                              >
                                <CalendarButton
                                  openModalCalendar={openModalCalendar}
                                  onToggleModalCalendar={() => {
                                    handleClearSelectedMemberIds();
                                    handleSelectedMemberIdByService({
                                      [idBudgetService]: {
                                        id: userExecution?.id || 0,
                                        name: userExecution?.name || "",
                                        photo: userExecution?.photo || "",
                                      },
                                    });
                                    handleToggleModalCalendarAndSetData(
                                      {
                                        idBudgetService,
                                        status,
                                        serviceData: {
                                          id: serviceData.id,
                                          name: serviceData.service.name,
                                          runtime: serviceData.runtime,
                                        },
                                        serviceOrderId: Number(id),
                                      },
                                      `${hour[0]} ${hour[1]}`
                                    );
                                  }}
                                  currentScheduleDate={currentScheduleDate}
                                  handleSelectedDate={(scheduleInfo) => {
                                    handleConfirmSchedule(scheduleInfo);
                                  }}
                                  onDeleteServiceSchedule={
                                    handleDeleteServiceSchedule
                                  }
                                />
                              </ShowWhenHavePermission>
                            )}
                        </S.WrapperContentService>
                        {serviceData.equipment?.name ? (
                          <span>{serviceData.equipment.name}</span>
                        ) : (
                          <span>Sem equipamento cadastrado</span>
                        )}
                        &nbsp;
                        {serviceData.equipmentType?.name && (
                          <span>{serviceData.equipmentType.name}</span>
                        )}
                        {serviceData.capacity?.name && (
                          <span> {serviceData.capacity.name}</span>
                        )}
                        {serviceData?.brand && (
                          <span>&ensp;| Marca: {serviceData.brand}</span>
                        )}
                        {serviceData.runtime?.time && (
                          <S.ContentBoxText>
                            Execução: {serviceData.runtime.time}{" "}
                            {serviceData.runtime.extension}
                          </S.ContentBoxText>
                        )}
                        {serviceData.warranty?.time && (
                          <S.ContentBoxText>
                            Garantia: {serviceData.warranty.time}{" "}
                            {serviceData.warranty.extension}
                          </S.ContentBoxText>
                        )}
                        {serviceData?.description && (
                          <>
                            <br />
                            <S.ObservationButton
                              type="button"
                              onClick={() =>
                                handleToggleModalObservationDetail(
                                  serviceData.description!
                                )
                              }
                            >
                              Detalhamento do Serviço
                            </S.ObservationButton>
                          </>
                        )}
                        <br />
                        <S.ObservationButton
                          type="button"
                          onClick={() =>
                            handleToggleModalCheckListDetail({
                              nameCheckList:
                                serviceData?.serviceChecklist![0]?.name,
                              checklistRegisters:
                                serviceData?.serviceChecklist![0]?.registers,
                            })
                          }
                        >
                          Detalhamento dos Registros
                        </S.ObservationButton>
                        {!!userExecution?.name && (
                          <UserExecutionCard userExecution={userExecution} />
                        )}
                      </ContentBox>
                      {index !== servicesInServiceOrder.length - 1 && (
                        <S.Separator />
                      )}
                    </div>
                  );
                })}
              </div>
            )}

            {checklistInServiceOrder.length > 0 && (
              <div>
                <span>Itens adicionais:</span>

                <ContentBox>
                  {checklistInServiceOrder.map((checklist) => {
                    const {
                      checklist: checklistData,
                      quantity,
                      type,
                    } = checklist;

                    const status = checklistData?.status.find(
                      (sts) => sts.type === type
                    );

                    return (
                      <div key={checklistData.id}>
                        <S.ContentBoxText>
                          <span style={{ color: theme.colors.title }}>
                            {checklistData.name}:
                          </span>{" "}
                          &nbsp;
                          {status?.label} &nbsp;
                          {type === "my_responsibility" && (
                            <span>({quantity})</span>
                          )}
                        </S.ContentBoxText>
                      </div>
                    );
                  })}
                </ContentBox>
              </div>
            )}

            {materialsInServiceOrder.length > 0 && (
              <div>
                <span>Materiais:</span>

                <ContentBox>
                  {materialsInServiceOrder.map((material, index) => {
                    const { material: materialData, quantity } = material;

                    return (
                      <>
                        <div key={materialData.id}>
                          <S.ContentBoxText>
                            {materialData.item.name} &nbsp; ({quantity})
                          </S.ContentBoxText>
                          <S.ContentBoxText>
                            <span style={{ color: theme.colors.title }}>
                              Tipo:
                            </span>{" "}
                            {materialData.type.name} &nbsp;
                          </S.ContentBoxText>
                        </div>
                        {index !== materialsInServiceOrder.length - 1 && (
                          <div style={{ padding: "5px 0" }} />
                        )}
                      </>
                    );
                  })}
                </ContentBox>
              </div>
            )}

            {!!observationInServiceOrder && (
              <div>
                <span>Detalhamento do Serviço:</span>

                <ContentBox>
                  <S.ContentBoxObservationText>
                    {observationInServiceOrder}
                  </S.ContentBoxObservationText>
                </ContentBox>
              </div>
            )}

            <ShowWhenHavePermission
              moduleHash="budgets-service-order"
              actionHash="edit"
            >
              <S.WrapperButton>
                <Button typeButton="outline" onClick={handleStartServiceOrder}>
                  Executar checklist
                </Button>
              </S.WrapperButton>
            </ShowWhenHavePermission>
          </S.Content>

          <S.Footer>
            <ButtonSVG
              title="Download PDF"
              typeSvg="pdf"
              onClick={handleOpenPdfPage}
            />
          </S.Footer>

          <S.WrapperArchiveButton>
            <ShowWhenHavePermission
              moduleHash="budgets-service-order"
              actionHash="edit-archive"
            >
              <Button typeButton="textOnly" onClick={handleArchiveServiceOrder}>
                Arquivar ordem de serviço
              </Button>
            </ShowWhenHavePermission>
          </S.WrapperArchiveButton>
        </S.Container>
      )}
    </>
  );
}
