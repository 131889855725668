import { ButtonHTMLAttributes } from "react";

import BlockGraySvg from "assets/icons/permission/block-gray.svg";
import { useAuth } from "hooks/auth";
import * as S from "./styles";

export type HomeModuleCardProps = {
  svg: string;
  title: string;
  description: string;
  isNewModule?: boolean;
  tagColor?: string;
  tagBgColor?: string;
  hasPermission?: boolean;
} & ButtonHTMLAttributes<HTMLButtonElement>;

export function HomeModuleCard({
  svg,
  title,
  description,
  isNewModule = false,
  tagColor,
  tagBgColor,
  hasPermission = true,
  ...rest
}: HomeModuleCardProps) {
  const { user } = useAuth();

  function returnBlockedModule(title: string) {
    if (user?.profileDisapproved) {
      if (
        title === "Orçamentos" ||
        title === "Ordens de serviço" ||
        title === "Ajuda"
      ) {
        return false;
      } else return true;
    }
    return false;
  }

  return (
    <S.Card {...rest}>
      {(returnBlockedModule(title) || !hasPermission) && (
        <S.IconWrapper>
          <img src={BlockGraySvg} alt="Ícone de cadeado" />
        </S.IconWrapper>
      )}

      <img src={svg} alt="imagem" />

      <S.Content>
        <div>
          <h5>{title}</h5>

          {isNewModule && (
            <S.DivTag color={tagColor} bgColor={tagBgColor}>
              <span>Beta</span>
            </S.DivTag>
          )}
        </div>

        <p>{description}</p>
      </S.Content>
    </S.Card>
  );
}
