import React, { useCallback, useState } from "react";
import { useHistory } from "react-router";
import debounce from "lodash.debounce";

import { SearchInput } from "components/SearchInput";

import * as S from "./styles";
import { searchDatalayer } from "utils/pushDataLayer";

type SearchComercialModelsManualProps = {
  description: string;
  value?: string;
};

export function SearchComercialModelsManual({
  description,
  value = "",
}: SearchComercialModelsManualProps) {
  const history = useHistory();

  const [searchedWord, setSearchedWord] = useState(value);
  const [inputLoading, setInputLoading] = useState(false);

  function handleChange(text: string) {
    setInputLoading(true);
    setSearchedWord(text);
    debounceFn(text);
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debounceFn = useCallback(debounce(handleSearchModelCode, 2500), []);

  function handleSearchModelCode(text: string) {
    searchDatalayer({ search_term: text, success: true });

    history.push({
      pathname: `/models`,
      state: { searchedWord: text },
    });
  }

  function handleSearchCancel() {
    setSearchedWord("");
    history.push({
      pathname: `/brands`,
    });
  }

  return (
    <S.Container>
      <SearchInput
        placeholder="Informe o código do modelo."
        description={description}
        searchValue={searchedWord}
        loadingInput={inputLoading}
        onChange={(e) => handleChange(e.target.value)}
        handleCancel={handleSearchCancel}
      />
    </S.Container>
  );
}
