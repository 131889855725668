import React, { useEffect, useState } from "react";

import { useToast } from "hooks/toast";
import { useRegisterPhotosModal } from "hooks/registerPhotosModal";
import { useServiceOrder } from "hooks/serviceOrder";

import {
  Photo,
  RegisteredPhoto,
  RegisterProps,
} from "dtos/ServiceChecklistDTO";

import { LoadingProfiz } from "components/LoadingProfiz";
import { AlertModal } from "components/AlertModal";
import { Input } from "components/Input";
import { CardCheckRegister } from "components/CardCheckRegister";
import { AddPhoto } from "components/AddPhoto";
import { PhotoCardStep } from "../PhotoCardStep";
import { ServiceRegisterPhoto } from "components/ServiceRegisterPhoto";

import arrowLeft from "assets/icons/arrow-left.svg";

import { api } from "services/api";
import apiv2 from "services/apiv2";

import * as S from "./styles";
import { ServiceWithChecklistDTO } from "dtos/ServiceWithChecklistDTO";
import { CarouselImages } from "components/ModalGalleryRegisterImages/CarouselImages";
import { Button } from "components/Button";

export type ImageResult = {
  encode: string;
  extension: string;
};

export function RunServiceChecklist() {
  const { addToast } = useToast();
  const { handleToggleRegisterPhotosModal } = useRegisterPhotosModal();

  const [isVisibleModal, setIsVisibleModal] = useState(false);
  const [loadingUploadPhoto, setLoadingUploadPhoto] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [loadingPhotoId, setLoadingPhotoId] = useState(0);
  const [photoModal, setPhotoModal] = useState(false);

  const [stagePhotoData, setStagePhotoData] = useState<Photo[]>([]);
  const [selectedPhotoIndex, setSelectedPhotoIndex] = useState(0);

  const {
    selectedServiceOrder,
    currentService,
    currentServiceChecklist,
    handleSetCurrentServiceChecklist,
    handleMarkServiceAsCompleted,
    handleUpdateRegister,
    handleDeletePhotoFromRegister,
  } = useServiceOrder();

  function handleUpdateRegisterValue(value: string, register: RegisterProps) {
    handleUpdateRegister({
      ...register,
      value,
    });
  }

  async function onPictureChoice(
    picture: ImageResult,
    register: RegisterProps
  ) {
    try {
      setLoadingUploadPhoto(true);

      const { data } = await api.post<RegisteredPhoto>("/upload/image", {
        grupo: `ordem-servico/${selectedServiceOrder.id}`,
        extensao: picture.extension,
        encode: picture.encode,
      });

      const photoFormatted = {
        id: data.id,
        link: data.url,
      };

      handleUpdateRegister({
        ...register,
        photos: register.photos ? [...register.photos, data] : [data],
        photosFormatted: register.photosFormatted
          ? [...register.photosFormatted, photoFormatted]
          : [photoFormatted],
      });

      addToast({
        title: "Sucesso",
        description: "Foto adicionada com sucesso",
        type: "success",
      });
    } catch (err) {
      addToast({
        title: "Ops!",
        description: "Falha ao adicionar a foto, tente mais tarde.",
        type: "error",
      });
    } finally {
      setLoadingUploadPhoto(false);
    }
  }

  async function onDeletePicture(pictureId: number, stageID: number) {
    try {
      setLoadingPhotoId(pictureId);

      await api.delete(`/upload/file/${pictureId}`);

      handleDeletePhotoFromRegister(pictureId, stageID);

      addToast({
        title: "Sucesso",
        description: "Foto removida com sucesso",
        type: "success",
      });
    } catch (err) {
      addToast({
        title: "Ops!",
        description: "Falha ao remover a foto, tente mais tarde.",
        type: "error",
      });
    } finally {
      setLoadingPhotoId(0);
    }
  }

  function getOptionForRegister(register: RegisterProps) {
    const maxPhotos = 3;

    const options = {
      medir: (
        <Input
          name={register.name}
          onChange={(e) => handleUpdateRegisterValue(e.target.value.trimStart(), register)}
          value={register.value}
        />
      ),
      verificar: <CardCheckRegister register={register} />,
      executar: <CardCheckRegister register={register} />,
      fotografar: (
        <PhotoCardStep
          title={register.name}
          maxPhotos={maxPhotos}
          photosQuantity={register.photosFormatted?.length || 0}
        >
          <AddPhoto
            loading={loadingUploadPhoto}
            stageID={register.id}
            maxPhotos={maxPhotos}
            photosQuantity={register.photosFormatted?.length || 0}
            onPictureChoice={(photo) => onPictureChoice(photo, register)}
          />

          <S.WrapperDescription>
            {!register.photosFormatted?.length && (
              <S.Descrition>Adicione uma foto</S.Descrition>
            )}
          </S.WrapperDescription>

          {register.photosFormatted &&
            register.photosFormatted?.length > 0 &&
            register.photosFormatted.map((photo, index) => (
                <ServiceRegisterPhoto
                  loading={loadingPhotoId === photo.id}
                  url={photo.link}
                  key={photo.id}
                  onDelete={() => onDeletePicture(photo.id, register.id)}
                  onClick={() => {
                    setStagePhotoData(register.photosFormatted ?? []);
                    setSelectedPhotoIndex(index);
                    handleTogglePhotoModal();
                  }}
                />
            ))}
        </PhotoCardStep>
      ),
    };

    return options[register.type];
  }

  async function handleCompleteService() {
    try {
      await apiv2.put(
        `/budgets/service-order/service/${currentService.idBudgetService}`
      );

      handleMarkServiceAsCompleted();
    } catch (err) {
      addToast({
        title: "Ops!!",
        description: "Não foi possível concluir o serviço atual",
        type: "error",
      });
    }
  }

  function validadeIfRegistersAreFilled() {
    let registersAreFilled = true;

    currentServiceChecklist.registers.forEach((register) => {
      if (register.type === "medir" && !register.value) {
        registersAreFilled = false;
        return;
      }

      if (
        (register.type === "verificar" || register.type === "executar") &&
        !register.isChecked
      ) {
        registersAreFilled = false;
        return;
      }

      if (
        register.type === "fotografar" &&
        (!register.photosFormatted || register.photosFormatted.length <= 0)
      ) {
        registersAreFilled = false;
        return;
      }
    });

    return registersAreFilled;
  }

  function handleToggleModal() {
    handleSaveRunServiceChecklist();
    setIsVisibleModal(false);
  }

  async function handleSaveRunServiceChecklist() {
    try {
      if (!isVisibleModal && !validadeIfRegistersAreFilled()) {
        setIsVisibleModal(true);
        return;
      }

      setIsLoading(true);

      await apiv2.put(
        `/budgets/service-order/${selectedServiceOrder.id}/services/${currentService.idBudgetService}/checklists-register`,
        {
          serviceChecklist: [currentServiceChecklist],
        }
      );

      handleSetCurrentServiceChecklist(currentServiceChecklist);

      addToast({
        title: "Sucesso",
        description: "Checklist atualizado com sucesso",
        type: "success",
      });

      if (currentService.status !== "concluded") await handleCompleteService();

      handleToggleRegisterPhotosModal();
    } catch (err) {
      addToast({
        title: "Ops!!",
        description: "Não foi possível salvar as alterações do checklist",
        type: "error",
      });
    } finally {
      setIsLoading(false);
    }
  }

  async function handleLoadServiceChecklist() {
    try {
      setIsLoading(true);

      const { data } = await apiv2.get(
        `/budgets/service-order/${selectedServiceOrder.id}`
      );

      const filteredService: ServiceWithChecklistDTO = data.services.filter(
        (service: ServiceWithChecklistDTO) => {
          return service.idBudgetService === currentService.idBudgetService;
        }
      )[0];

      const loadRegisters =
        filteredService.service.serviceChecklist[0].registers.map(
          (register: RegisterProps) => {
            if (
              register.type === "fotografar" &&
              register.photos &&
              register.photos?.length > 0
            ) {
              const formattedPhotos = register.photos.map((photo) => {
                return {
                  id: photo.id,
                  link: photo.url,
                };
              });
              return {
                ...register,
                photosFormatted: formattedPhotos,
              };
            }
            return register;
          }
        );

      handleSetCurrentServiceChecklist({
        ...filteredService.service.serviceChecklist[0],
        registers: loadRegisters,
      });
    } catch (error) {
      addToast({
        title: "Ops!!",
        description: "Não foi possível carregar as informações do checklist",
        type: "error",
      });
    } finally {
      setIsLoading(false);
    }
  }

  const handleTogglePhotoModal = () => {
    setPhotoModal(!photoModal);
  };

  useEffect(() => {
    handleLoadServiceChecklist();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <AlertModal
        isVisible={isVisibleModal}
        title="Checklist incompleto!"
        description="Você não preencheu todos os campos do checklist dos serviços. Deseja continuar mesmo assim?"
        action="choose"
        handleConfirm={() => handleToggleModal()}
        onCloseModal={() => setIsVisibleModal(false)}
      />

      {isLoading ? (
        <LoadingProfiz isVisible={isLoading} />
      ) : (
        <>
          <CarouselImages
            isVisible={photoModal}
            imageSelected={selectedPhotoIndex}
            images={stagePhotoData}
            onCloseModal={handleTogglePhotoModal}
          />
          <S.ButtonBack
            onClick={() => {
              handleToggleRegisterPhotosModal();
            }}
          >
            <img src={arrowLeft} alt="flecha pra esquerda" />
          </S.ButtonBack>

          <S.Header>
            <h2>Checklist do serviço</h2>

            <p>
              Vamos fazer o checklist dos serviços com base nos que você
              cadastrou no menu "Meus serviços".
            </p>
          </S.Header>

          <S.Content>
            {currentServiceChecklist.registers.map((register) => (
              <S.Wrapper>{getOptionForRegister(register)}</S.Wrapper>
            ))}
          </S.Content>

          <S.Footer>
              <Button onClick={handleSaveRunServiceChecklist}>
                Concluir checklist
              </Button>
          </S.Footer>
        </>
      )}
    </>
  );
}
