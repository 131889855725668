import styled, { keyframes, css } from "styled-components";

import largeLogo from "assets/large-logo.svg";
import background from "assets/imagem-fundo_login.svg";

export const Container = styled.div`
  height: 100vh;
  display: flex;
  flex-direction: column;

  margin: 0 auto;
  width: 100%;

  background: url(${background}) no-repeat;
  background-size: contain;

  header {
    color: red;
  }
`;

export const BackgroundContent = styled.div`
  width: 100%;
  height: 100vh;
  align-items: center;
  background: url(${largeLogo}) no-repeat;
  background-position: right -190px center;

  @media (max-width: 1366px) {
    background-size: 300px;
    background-position: right -150px center;
  }
`;

export const Background = styled.div`
  width: 100%;
  display: flex;

  justify-content: space-between;
  align-items: center;
  flex-direction: column;

  .laptop {
    width: 700px;
    margin-left: 100px;
    margin-top: 60px;
    margin-bottom: 60px;

    @media (max-width: 1532px) {
      width: 600px;
    }

    @media (max-width: 1366px) {
      margin-top: 50px;
      margin-bottom: 50px;
      width: 410px;
    }
  }

  aside {
    width: 100%;
    max-width: 680px;

    @media (max-width: 1366px) {
      max-width: 500px;
    }

    .profiz {
      margin-bottom: 50px;
      height: 40px;
    }
  }

  section {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 0 auto;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 90%;
`;

export const QrCode = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    justify-content: flex-start;

    span {
      color: ${theme.colors.text_light_87};
      font-size: 20px;
      line-height: 14px;
      font-weight: 700;

      @media (max-width: 1366px) {
        font-size: 12px;
      }
    }

    p {
      color: ${theme.colors.text_light_87};
      font-size: 14px;
      line-height: 12px;
      font-weight: 400;

      @media (max-width: 1366px) {
        font-size: 10px;
      }
    }

    img {
      margin-right: 10px;
      width: 57px;
      height: 57px;
    }
  `}
`;

export const Title = styled.h1`
  ${({ theme }) => css`
    color: ${theme.colors.title};
    font-size: 42px;
    line-height: 30px;
    margin-bottom: 10px;

    @media (max-width: 1366px) {
      font-size: 37px;
    }

    span {
      color: ${theme.colors.text};
      font-size: 42px;
      font-weight: 400;

      @media (max-width: 1366px) {
        font-size: 24px;
      }
    }
  `}
`;

export const SubTitle = styled.p`
  ${({ theme }) => css`
    color: ${theme.colors.text};
    font-size: 28px;
    line-height: 30px;

    @media (max-width: 1366px) {
      font-size: 16px;
    }

    span {
      color: ${theme.colors.title};

      font-size: 28px;
      font-weight: 500;

      @media (max-width: 1366px) {
        font-size: 16px;
      }
    }
  `}
`;

const appearFromLeft = keyframes`
  from{
    opacity: 0;
    transform: translateX(-50px)
  } to {
    opacity: 1;
    transform: translateX(0)
  }
`;

export const AnimationContainer = styled.div`
  ${({ theme }) => css`
    width: 100%;
    display: flex;
    flex-direction: column;

    align-items: center;
    justify-content: center;

    animation: ${appearFromLeft} 1s;

    form {
      & > img {
        margin-bottom: 23px;
      }

      margin: 80px 0 25px 0;
      width: 400px;
      text-align: center;

      h1 {
        margin-bottom: 25px;
      }

      a {
        color: ${theme.colors.text};
        font-size: ${theme.font.sizes.small};
        display: block;
        transition: color 0.2;
      }
    }
  `}
`;
