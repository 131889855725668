import styled, { css } from "styled-components";

type DotProps = {
  dotIsSelected?: boolean;
};

export const Container = styled.div`
  ${({ theme }) => css`
    width: 100%;
    background-color: ${theme.colors.strong_background};
    padding-bottom: 30px;
  `}
`;

export const Header = styled.div`
  width: 100%;
  height: 300px;
  background-color: rgba(211, 255, 154, 0.1);
`;

export const HeaderContent = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 90%;
  padding-top: 30px;
  padding-left: 25px;
`;

export const HeaderMenu = styled.div`
  display: flex;
  padding-left: 25px;
  margin-top: 60px;
`;

export const WrapperCard = styled.div`
  margin-right: 25px;
`;

export const Banner = styled.div`
  cursor: pointer;
  min-height: 200px;
  border-radius: 10px;

  img {
    width: auto;
    border-radius: 10px;
    object-fit: cover;
    min-height: 100%;
  }
`;

export const ContentMenu = styled.section`
  ${({ theme }) => css`
    width: 90%;
    border-radius: 30px;
    background-color: ${theme.colors.strong_background};
    margin-top: 30px;
    margin-bottom: 20px;

    display: grid;
    //I want two columns with 50%
    grid-template-columns: repeat(2, 1fr);
    gap: ${theme.grid.small};
  `}
`;

export const ProfileInfo = styled.button`
  display: flex;
  align-items: center;
`;

export const UserPhoto = styled.div`
  img {
    width: 50px;
    height: 50px;
    border-radius: 25px;
  }
`;

export const ProfileDetail = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-left: 10px;
`;

export const Text = styled.span`
  ${({ theme }) => css`
    font-family: ${theme.font.family};
    font-size: ${theme.font.sizes.xsmall};
    font-weight: ${theme.font.normal};
    color: rgba(255, 255, 255, 0.87);
    line-height: 15px;
  `}
`;

export const HighlightText = styled.span`
  ${({ theme }) => css`
    font-family: ${theme.font.family};
    font-size: ${theme.font.sizes.xsmall};
    font-weight: ${theme.font.bold};
    color: ${theme.colors.primary};
    line-height: 15px;
    letter-spacing: 0.26px;
  `}
`;

export const Dot = styled.div<DotProps>`
  ${({ theme, dotIsSelected }) => css`
    display: inline-block;
    margin-right: 10px;
    background: ${dotIsSelected ? theme.colors.primary : "#f5f5f5"};
    cursor: pointer;
    width: 5px;
    height: 5px;
    border-radius: 5px;
  `}
`;
