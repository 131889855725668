import styled, { css } from "styled-components";

export const Container = styled.button`
  ${({ theme }) => css`
    width: 315px;
    background-color: ${theme.colors.background_modal};
    border-radius: 10px;
    display: flex;
    align-items: center;

    img {
      object-fit: cover;
      width: 100%;
      max-width: 100px;
      height: 99%;
      background-color: ${theme.colors.background};
      border-radius: 10px 5px 5px 10px;
    }

    div {
      margin-left: 20px;

      p {
        color: ${theme.colors.title};
        font-size: ${theme.font.sizes.small};
        text-align: left;
        margin-bottom: 5px;
      }

      span {
        color: ${theme.colors.text};
        font-size: ${theme.font.sizes.xsmall};
        text-align: left;
      }
    }
  `}
`;

export const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  margin-right: 10px;
  overflow-wrap: break-word;
  width: 50%;
`;

export const BrandText = styled.p`
  ${({ theme }) => css`
    color: ${theme.colors.text};
    font-size: ${theme.font.sizes.xsmall};
    text-align: left;
    margin-top: 5px;
  `}
`;
