import { useState } from "react";
import { useHistory } from "react-router-dom";
import { useTheme } from "styled-components";
import { FiChevronLeft } from "react-icons/fi";

import * as S from "./styles";
import { useAuth } from "hooks/auth";

import AvaliationIcon from "assets/icons/avaliation-icon.svg";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import { ConcludedList } from "./concludedList";
import { PendingList } from "./pendingList";

export function RatingList() {
  const theme = useTheme();
  const history = useHistory();
  const { user } = useAuth();

  const [index, setIndex] = useState(() => {
    const index = localStorage.getItem("profiz-tabs");
    if (index) {
      return Number(index);
    }
    return 0;
  });

  function handleGoBack() {
    history.push("/home");
  }

  return (
    <S.Container>
      <>
        <S.Top>
          <S.ButtonClose onClick={handleGoBack}>
            <FiChevronLeft size={10} color={theme.colors.title} />
          </S.ButtonClose>
        </S.Top>
        <S.TextHeaderRow>
          <h3>Avaliações</h3>
          <h5>
            Visualize as avaliações que você recebeu com base nos seus serviços.
          </h5>
        </S.TextHeaderRow>

        <S.Wrapper onClick={() => history.push("/ratings")}>
          <img
            src={AvaliationIcon}
            style={{ marginRight: 10 }}
            alt="Logo Avaliações"
          ></img>
          {user.totalAvaliations && user.totalAvaliations > 0 ? (
            <S.TextRow>
              <S.BoldText>{user.averageRating} estrelas</S.BoldText>(
              {user.totalAvaliations} avaliações)
            </S.TextRow>
          ) : (
            <S.TextRow>Não possui avaliações</S.TextRow>
          )}
        </S.Wrapper>
        <S.TabContainer>
          <Tabs
            selectedIndex={index}
            onSelect={(index: number) => {
              setIndex(index);
              localStorage.setItem("profiz-tabs", index.toString());
            }}
          >
            <TabList>
              <Tab>Concluídas</Tab>
              <Tab>Pendentes</Tab>
            </TabList>

            <TabPanel>
              <ConcludedList />
            </TabPanel>
            <TabPanel>
              <PendingList />
            </TabPanel>
          </Tabs>
        </S.TabContainer>
      </>
    </S.Container>
  );
}
