import { ArrowButton } from 'components/ArrowButton';
import { LoadingProfiz } from 'components/LoadingProfiz';

import { useFetchAppVersion } from 'hooks/version/useFetchAppVersion';

import * as S from './styles';

export function AppVersion() {
  const { loadingAppVersionDetail, appVersionDetail } = useFetchAppVersion()

  if (loadingAppVersionDetail) {
    return <LoadingProfiz isVisible={loadingAppVersionDetail} />
  }

  return <S.Container>
    <ArrowButton />

    <S.Content>
      <header>
        <S.VersionTitle>Versão Android</S.VersionTitle>
        <S.Title highlight>{appVersionDetail.androidBundleVersion}</S.Title>
      </header>

      <S.Title>Melhorias do app</S.Title>
      <S.Description>{appVersionDetail.androidBundleDescription}</S.Description>
    </S.Content>

    <S.Content>
      <header>
        <S.VersionTitle>Versão iOS</S.VersionTitle>
        <S.Title highlight>{appVersionDetail.IOSBundleVersion}</S.Title>
      </header>

      <S.Title>Melhorias do app</S.Title>
      <S.Description>{appVersionDetail.IOSBundleDescription}</S.Description>
    </S.Content>
  </S.Container>
}
