import styled, { css } from "styled-components";

export const Container = styled.div``;

type CheckBoxProps = {
  checked: boolean;
};

export const Top = styled.div`
  ${({ theme }) => css`
    margin-bottom: 30px;

    display: flex;
    align-items: center;

    p {
      font-size: ${theme.font.sizes.large};
      color: ${theme.colors.title};
      font-weight: ${theme.font.bold};
    }
  `}
`;

export const BackButton = styled.button`
  ${({ theme }) => css`
    width: 35px;
    height: 35px;
    background-color: ${theme.colors.background_light};
    border-radius: 10px;

    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 25px;
  `}
`;

export const RegisteredUnities = styled.div`
  ${({ theme }) => css`
    text-decoration: underline;
    margin-top: 20px;
    font-size: 12px;
    color: ${theme.colors.financial_text};
    cursor: pointer;
  `}
`;

export const HeaderTitle = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  align-items: center;
`;

export const Content = styled.form`
  margin-top: 50px;
`;

export const WrapperInput = styled.div`
  margin-bottom: 25px;
`;

export const GroupInputs = styled.div`
  margin-bottom: 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  & > div {
    width: 80%;
  }

  & > div:last-of-type {
    width: 16%;
    margin-left: 14px;
  }
`;

export const CheckboxWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
`;

export const CheckBox = styled.div<CheckBoxProps>`
  ${({ theme, checked }) => css`
    height: 16px;
    width: 15px;
    border: 1px solid #707070;
    margin-right: 10px;
    border-radius: 5px;
    background-color: transparent;

    display: flex;
    align-items: center;
    justify-content: center;

    ${checked &&
    css`
      & > div {
        height: 6.4px;
        width: 7px;
        border-radius: 1px;
        background-color: ${theme.colors.primary};
      }
    `}
  `}
`;

export const CheckboxText = styled.div`
  ${({ theme }) => css`
    color: ${theme.colors.financial_text};
    font-family: ${theme.font.family};
    font-size: 12px;
    user-select: none;
  `}
`;

export const ErrorText = styled.span`
  ${({ theme }) => css`
    font-size: 12px;
    color: ${theme.colors.error};
    padding-top: 5px;
    display: flex;
    padding-bottom: 5px;
  `}
`;
