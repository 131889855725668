import { Dispatch, SetStateAction, useState } from "react";

import { Button } from "components/Button";
import { ArrowButton } from "components/ArrowButton";
import OnboardingCard from "components/OnboardingCard";

import * as S from "../styles";
import { TourTypeProps } from "components/OnboardingCard/data";

interface TourPageProps {
  setShowTour: Dispatch<SetStateAction<boolean>>;
}

interface TourProp {
  title: string;
  type: TourTypeProps;
  step: number;
}

const tourProps: TourProp[] = [
  {
    title: "Cadastro",
    type: "homeOnboarding",
    step: 3,
  },
  {
    title: "Orçamentos",
    type: "budgetOnboarding",
    step: 7,
  },
  {
    title: "Ordens de serviços",
    type: "serviceOrderOnboarding",
    step: 7,
  },
  {
    title: "Calendário de eventos",
    type: "eventsCalendarOnboarding",
    step: 3,
  },
  {
    title: "PMOC",
    type: "pmocOnboarding",
    step: 4,
  },
];

export default function TourPage({ setShowTour }: TourPageProps) {
  const [type, setType] = useState<TourTypeProps>("" as TourTypeProps);
  const [currentStep, setCurrentStep] = useState(1);
  const [tourModalVisible, setTourModalVisible] = useState(false);

  function handleOpenTourModal(data: TourProp) {
    setCurrentStep(1);
    setType(data.type);
    setTourModalVisible(true);
  }

  return (
    <S.Container>
      <OnboardingCard
        currentStep={currentStep}
        setCurrentStep={setCurrentStep}
        isTourModalVisible={tourModalVisible}
        setIsTourModalVisible={setTourModalVisible}
        type={type}
      />
      <S.Header>
        <div>
          <ArrowButton handleFunction={() => setShowTour(false)} />
          <h1>Tour do APP</h1>
          <S.TourDescription>
            Escolha a funcionalidade que deseja rever
          </S.TourDescription>
        </div>
      </S.Header>
      <S.Content>
        {tourProps.map((data) => (
          <Button
            key={data.title}
            typeButton="outline"
            style={{ width: 470 }}
            onClick={() => handleOpenTourModal(data)}
          >
            {data.title}
          </Button>
        ))}
      </S.Content>
    </S.Container>
  );
}
