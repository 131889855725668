import React, { useState } from 'react'

import { MaterialProps } from 'dtos/MaterialDTO'

import { ListMaterials } from './ListMaterials'
import { FormCreateMaterial } from './FormCreateMaterial'
import { FormEditMaterial }  from './FormEditMaterial'

type ModalEditMaterialsProps = {
  handleToggleOpen: () => void;
}

export function ModalMaterials({
  handleToggleOpen,
}: ModalEditMaterialsProps) {
  const [openListMaterials, setOpenListMaterials] = useState(true)
  const [openFormCreateMaterial, setOpenFormCreateMaterial] = useState(false)
  const [openFormEditMaterial, setOpenFormEditMaterial] = useState(false)

  const [currentEditMaterial, setCurrentEditMaterial] = useState<MaterialProps>(
    {} as MaterialProps
  )

  function handleListMaterials() {
    setOpenFormCreateMaterial(false)
    setOpenFormEditMaterial(false)
    setOpenListMaterials(true)
  }

  function handleCreateMaterial() {
    setOpenListMaterials(false)
    setOpenFormCreateMaterial(true)
  }

  function handleEditMaterial(data: MaterialProps) {
    setOpenListMaterials(false)
    setOpenFormEditMaterial(true)
    setCurrentEditMaterial(data)
  }

  return (
    <>
      {openListMaterials && (
        <ListMaterials
          handleToggleModal={handleToggleOpen}
          // eslint-disable-next-line react/jsx-no-bind
          handleCreateMaterial={handleCreateMaterial}
          // eslint-disable-next-line react/jsx-no-bind
          handleEditMaterial={handleEditMaterial}
        />
      )}

      {openFormCreateMaterial && (
        <FormCreateMaterial
          // eslint-disable-next-line react/jsx-no-bind
          handleListMaterials={handleListMaterials}
        />
      )}

      {openFormEditMaterial && (
        <FormEditMaterial 
          data={currentEditMaterial}
          // eslint-disable-next-line react/jsx-no-bind
          handleListMaterials={handleListMaterials}
        />
      )}
    </>
  )
}
