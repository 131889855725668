import styled, { css } from 'styled-components'

export const Container = styled.div`
  margin-top: 24px;
`

export const Label = styled.p`
  ${({ theme }) => css`
    font-size: ${theme.font.sizes.xsmall};
    margin-bottom: 5px;
    text-transform: capitalize;
    color: ${theme.colors.title};
  `}
`

export const Content = styled.div`
  display: flex;
  flex-direction: column;
`

export const WrapperCard = styled.div`
  ${({ theme }) => css`
    width: 100%;
    background-color: ${theme.colors.background_light};
    margin-bottom: 15px;
    border-radius: 10px;

    display: flex;
    justify-content: space-between;
    flex-direction: row;
  `}
`

export const WrapperActions = styled.div`
  ${({ theme }) => css`
    min-width: 47px;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    background-color: ${theme.colors.background_light};

    display: flex;
    flex-direction: column;
    justify-content: space-between;
  `}
`
