import styled, { css } from "styled-components";

export const Container = styled.div`
  width: 100%;
`;

export const Header = styled.header`
  ${({ theme }) => css`
    display: flex;
    padding: 30px;

    h1 {
      display: flex;
      font-size: ${theme.font.sizes.large};
      color: ${theme.colors.title};
      font-weight: ${theme.font.bold};
      margin-top: 30px;
    }

    p {
      font-size: ${theme.font.sizes.medium};
      color: ${theme.colors.title};
      margin-top: 15px;
    }

    strong {
      font-weight: ${theme.font.bold};
    }
  `}
`;

export const Content = styled.div`
  align-items: center;
  padding-left: 30px;
`;

export const ShareArea = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    justify-content: space-between;

    width: 470px;
    height: 45px;
    background: ${theme.colors.background_modal};
    border-radius: 10px;

    &:hover {
      cursor: pointer;
    }
  `}
`;

export const IndicationLink = styled.p`
  ${({ theme }) => css`
    font-family: ${theme.font.family};
    font-weight: ${theme.font.normal};
    font-size: ${theme.font.sizes.xsmall};
    line-height: 16px;
    color: #ffffffde;
    margin: 15px;
  `}
`;

export const CopyButton = styled.button`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const CopyText = styled.p`
  ${({ theme }) => css`
    font-family: ${theme.font.family};
    font-weight: ${theme.font.normal};
    font-size: ${theme.font.sizes.xsmall};
    line-height: 16px;
    color: #ffffffde;
    margin-left: 4px;
    text-decoration: underline;
  `}
`;

export const ShareButton = styled.button`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    height: 45px;
    background: ${theme.colors.primary};
    border-radius: 10px;
    margin-left: 20px;
    padding-right: 24px;
    padding-left: 24px;

    p {
      margin-left: 8px;
    }

    img {
      margin-top: 1px;
    }
  `}
`;

export const MyEarningsButton = styled.button`
  ${({ theme }) => css`
    margin-top: 30px;
    width: 470px;
    height: 45px;
    background: ${theme.colors.background};
    border-radius: 10px;
    border: 1px solid ${theme.colors.primary};
    justify-content: center;
    align-items: center;
  `}
`;

export const MyEarningsButtonText = styled.p`
  ${({ theme }) => css`
    font-family: ${theme.font.family};
    color: ${theme.colors.primary};
    font-size: ${theme.font.sizes.small};
  `}
`;

export const InfoWrapper = styled.div`
  width: 100%;
  margin-bottom: 20px;
`;

export const ItemInfoWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const InfoTitle = styled.p`
  ${({ theme }) => css`
    margin-top: 15px;
    margin-bottom: 12px;
    font-family: ${theme.font.bold};
    font-size: ${theme.font.sizes.small};
    color: rgba(255, 255, 255, 0.87);
    line-height: 15px;
    flex-shrink: 1;
  `}
`;

export const Circle = styled.div`
  ${({ theme }) => css`
    width: 30px;
    height: 30px;
    border: 1.2px solid rgba(255, 255, 255, 0.87);
    border-radius: 20px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-right: 10px;
  `}
`;

export const ProfitzCoinText = styled.p`
  ${({ theme }) => css`
    font-family: ${theme.font.normal};
    font-size: ${theme.font.sizes.small};
    color: #fff595;
    line-height: 15px;
    flex-shrink: 1;
  `}
`;

export const TextWrapper = styled.div`
  margin-left: 6px;
  display: flex;
  flex-direction: row;
`;

export const ProfitzText = styled.p`
  ${({ theme }) => css`
    font-family: ${theme.font.bold};
    font-size: ${theme.font.sizes.small};
    color: #fff595;
    line-height: 15px;
    flex-shrink: 1;
  `}
`;

export const ProfitzBonusText = styled.p`
  ${({ theme }) => css`
    font-family: ${theme.font.normal};
    font-size: ${theme.font.sizes.small};
    color: rgba(255, 255, 255, 0.87);
    line-height: 15px;
    flex-shrink: 1;
    padding-left: 4px;
  `}
`;

export const ChangeProfitzText = styled.p`
  ${({ theme }) => css`
    font-family: ${theme.font.family};
    font-size: ${theme.font.sizes.small};
    color: rgba(255, 255, 255, 0.87);
    line-height: 15px;
    flex-shrink: 1;
  `}
`;

export const CashbackItemsWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 12px;
  margin-bottom: 6px;
`;

export const CashbackItem = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 12px;
`;

export const CashbackItemImage = styled.img`
  width: 30px;
  height: 30px;
`;

export const CashbackItemText = styled.p`
  ${({ theme }) => css`
    margin-left: 10px;
    font-family: ${theme.font.normal};
    font-size: ${theme.font.sizes.small};
    color: rgba(255, 255, 255, 0.87);
    line-height: 15px;
    flex-shrink: 1;
  `}
`;

export const Line = styled.div`
  height: 20px;
  width: 1.2px;
  background: white;
  position: relative;
  margin-left: 14px;
`;
