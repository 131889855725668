import { UserExecutionProps } from "dtos/ServiceOrderDetailDTO"

import * as S from './styles'

type UserExecutionCardProps = {
  userExecution: UserExecutionProps
}

export function UserExecutionCard({ userExecution}: UserExecutionCardProps) {
  return <S.Container>
    <img src={userExecution.photo} alt="user-img" />
    <S.Name>{userExecution.name}</S.Name>
  </S.Container>
}
