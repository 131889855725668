import React, { ButtonHTMLAttributes } from 'react'

import * as S from './styles'

type BoxProps = {
  title?: string
  children: React.ReactNode
} & ButtonHTMLAttributes<HTMLButtonElement>

export function ButtonContentBox({ title, children, ...rest }: BoxProps) {
  return (
    <S.Container>
      <p>{title}</p>

      <S.Content {...rest}>{children}</S.Content>
    </S.Container>
  )
}
