import { darken } from "polished";
import styled, { css } from "styled-components";

export const Top = styled.div`
  ${({ theme }) => css`
    margin-bottom: 30px;

    h4 {
      font-size: ${theme.font.sizes.large};
      color: ${theme.colors.title};
      font-weight: ${theme.font.bold};
    }
  `}
`;

export const BackButton = styled.button`
  ${({ theme }) => css`
    width: 35px;
    height: 35px;
    background-color: ${theme.colors.background_light};
    border-radius: 10px;

    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 25px;
  `}
`;

export const WrapperServiceName = styled.div`
  margin: 35px 0;
`;

export const CardText = styled.span`
  ${({ theme }) => css`
    display: block;
    font-size: ${theme.font.sizes.xsmall};
    color: ${theme.colors.text_light_60};
    margin-bottom: 8px;
  `}
`;
export const Card = styled.div`
  ${({ theme }) => css`
    width: 100%;
    min-height: 45px;
    background-color: ${theme.colors.detail_light};
    border-radius: 10px;
    padding: 13px 15px;

    & > span {
      font-size: ${theme.font.sizes.xsmall};
      color: ${theme.colors.text_light_60};
    }
  `}
`;

export const WrapperInfo = styled.div`
  ${({ theme }) => css`
    margin-top: 16px;
    display: flex;
    justify-content: flex-end;

    & > span {
      font-size: ${theme.font.sizes.small};
      color: ${theme.colors.text};
    }
  `}
`;

export const Content = styled.main`
  ${({ theme }) => css`
    margin-top: 50px;

    & > div {
      margin: 20px 0 30px;
      padding-bottom: 40px;
      border-bottom: 1px solid ${theme.colors.line};

      & > div {
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
    }
  `}
`;

export const Title = styled.span`
  ${({ theme }) => css`
    font-size: ${theme.font.sizes.small};
    color: ${theme.colors.text};
  `}
`;

export const ListImages = styled.ul`
  display: flex;
  margin-top: 12px;
  overflow-x: auto;

  & > li {
    list-style: none;
    margin-right: 18px;
    transition: opacity 0.2s;
    cursor: pointer;
  }

  & > li:hover {
    opacity: 0.6;
  }
`;

export const Image = styled.img`
  flex: 0 0 auto;
  width: 70px;
  height: 70px;
  border-radius: 8px;
  object-fit: cover;
`;

export const ImageSignature = styled(Image)`
  ${({ theme }) => css`
    object-fit: scale-down;
    background-color: ${darken(0.2, theme.colors.title)};
  `}
`;
