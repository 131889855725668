import styled, { css } from "styled-components";

export const Top = styled.div`
  ${({ theme }) => css`
    margin-bottom: 30px;

    display: flex;
    align-items: center;

    h4 {
      font-size: ${theme.font.sizes.large};
      color: ${theme.colors.title};
      font-weight: ${theme.font.bold};
    }
  `}
`;

export const WrapperButtonsSVG = styled.div`
  margin: 20px 0 50px;
  padding: 40px 0;
  display: flex;
`;

export const ButtonClose = styled.button`
  ${({ theme }) => css`
    width: 35px;
    height: 35px;
    background-color: ${theme.colors.background_light};
    border-radius: 10px;

    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 25px;
  `}
`;

export const InfoDate = styled.span`
  ${({ theme }) => css`
    color: ${theme.colors.title};
    font-size: ${theme.font.sizes.xsmall};
  `}
`;

export const ScheduleStatus = styled.div`
  ${({ theme }) => css`
    color: ${theme.colors.title};
    font-size: ${theme.font.sizes.xsmall};
    display: flex;
    flex-direction: row;
    align-items: center;
  `}
`;

export const Dot = styled.div`
  width: 10px;
  height: 10px;
  border-radius: 10px;
  margin-right: 5px;
`;

export const Content = styled.main`
  margin-top: 40px;

  & > div {
    ${({ theme }) => css`
      margin-bottom: 30px;

      & > span {
        display: block;
        color: ${theme.colors.title};
        font-size: ${theme.font.sizes.xsmall};
        margin-bottom: 8px;
      }
    `}
  }
`;

export const ContentBoxTitle = styled.h4`
  ${({ theme }) => css`
    font-size: ${theme.font.sizes.xsmall};
    color: ${theme.colors.title};
    margin-bottom: 14px;
  `}
`;

export const ContentBoxText = styled.p`
  ${({ theme }) => css`
    color: ${theme.colors.text};
    font-size: ${theme.font.sizes.xsmall};
    margin-bottom: 4px;
  `}
`;

export const ContentBoxObservationText = styled.pre`
  ${({ theme }) => css`
    white-space: pre-wrap;
    color: ${theme.colors.text};
    font-size: ${theme.font.sizes.xsmall};
    margin-bottom: 4px;
    font-family: ${theme.font.family};
  `}
`;

export const WrapperContentService = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;

  & > span {
    margin-bottom: 4px;
  }

  & > span:last-of-type {
    color: ${({ theme }) => theme.colors.title};
  }
`;

export const Separator = styled.div`
  width: 100%;
  padding: 4px 0;
`;
