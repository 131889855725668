import React, { useState } from "react";

import * as S from "./styles";

import { EditButton } from "components/EditButton";
import { DeleteButton } from "components/DeleteButton";
import { PMOCServiceProps } from "dtos/ServiceDTO";
import { AlertModal } from "components/AlertModal";

interface ServicePmocProps {
  currentService: PMOCServiceProps;
  onDeleteService: (serviceId: number) => void;
  onEditServicePmoc: () => void;
  isCheckbox?: boolean;
  handlePressButton?: () => void;
  isChecked?: boolean;
  isCheckedButtonClick?: boolean;
}

const PmocServiceContent = ({
  currentService,
  onDeleteService,
  onEditServicePmoc,
  isCheckbox = false,
  isChecked = false,
  handlePressButton,
  isCheckedButtonClick=true
}: ServicePmocProps) => {
  const [isVisibleModal, setIsVisibleModal] = useState(false);

  return (
    <S.Container
      isChecked={isChecked}
    >
      <AlertModal
        isVisible={isVisibleModal}
        title="Deseja apagar?"
        description="Deseja realmente apagar esse serviço?"
        action="choose"
        handleConfirm={() => {
          setIsVisibleModal(false);
          onDeleteService(currentService.id);
        }}
        onCloseModal={() => setIsVisibleModal(false)}
      />

      {isCheckbox && handlePressButton && (
        <S.WrapperCheck
          onClick={handlePressButton}
          disabled={isCheckedButtonClick ? false : true}
        >
          <S.CheckBox>
            <S.MarkChecked isChecked={isChecked} />
          </S.CheckBox>
        </S.WrapperCheck>
      )}
      
      <S.Content>
        <S.Title>{currentService.serviceType.name}</S.Title>
        {currentService.warranty &&
          currentService.warranty?.time.length > 0 &&
          currentService.warranty?.extension.length > 0 &&
            <S.Description>
              Garantia: {currentService.warranty?.time}{" "}
              {currentService.warranty?.extension}
            </S.Description>}

        <S.Steps>
          <p>Etapas do serviço</p>
          <span>{currentService.serviceChecklist?.length} checklist</span>
        </S.Steps>
      </S.Content>

      <S.WrapperActions>
        <EditButton
          onClick={() => {
            onEditServicePmoc();
          }}
        />
        <DeleteButton
          onClick={() => {
            setIsVisibleModal(true);
          }}
        />
      </S.WrapperActions>
    </S.Container>
  );
};
export { PmocServiceContent };
