import React, { createContext, useState, useContext } from "react";
import { isMobile } from "react-device-detect";

import { AccountDTO } from "dtos/permission/AccountDTO";

import { api } from "../services/api";
import apiv2 from "../services/apiv2";
import { KEY } from "./permission/account";
import { ProviderProps } from "hooks";

type AddressProps = {
  city: string;
  number: string;
  street: string;
  complement: string;
  neighborhood: string;
  cep: string;
  state: string;
};

type DocumentProps = {
  cpf: string;
  cnpj: string;
  type: "PF" | "PJ";
};

interface IIndication {
  url: string;
  hash: string;
}

export interface OnboardingProps {
  homeOnboarding: boolean;
  budgetOnboarding: boolean;
  serviceOrderOnboarding: boolean;
  eventsCalendarOnboarding: boolean;
  pmocOnboarding: boolean;
}

export interface PopUpNews {
  action: {
    data: string;
    detailId: string;
    moduleId: string;
    type: "url" | "screen";
  };
  buttonTitle: string;
  description: string;
  id: string;
  imgDesk?: string;
  imgMobile?: string;
  subTitle: string;
  title: string;
}
export interface User {
  id: string;
  name: string;
  birthday?: string;
  photo: string;
  document: DocumentProps;
  email: string;
  phone: string;
  companyName?: string;
  companyLogo?: string;
  digitalSign?: string;
  address?: AddressProps;
  profileApproved: boolean;
  profileComplete: boolean;
  profileDisapproved?: boolean;
  underAnalysis?: boolean;
  deletionRequest?: boolean;
  averageRating?: number;
  totalAvaliations?: number;
  profitz: Profitz;
  indication: IIndication;
  areasOfExpertise: AreasOfExpertise[] | string[];
  distanceLimitActuation?: number;
  onboarding: OnboardingProps;
  accessMktplace: boolean;
  popupNews: PopUpNews;
  permissions: {
    accounts: AccountDTO[];
  };
}

export interface AreasOfExpertise {
  id: string;
  name: string;
}
export interface Profitz {
  total: number;
}

interface SignInCredentials {
  email: string;
  password: string;
}

interface SignUpCredentials {
  name: string;
  email: string;
  password: string;
  indicationHash?: string;
}

export type SocialAuthenticationCredentials = {
  user: User;
  token: string;
};

interface AuthContextData {
  user: User;
  signIn(credentials: SignInCredentials): Promise<void>;
  signOut(): void;
  signUp(credentials: SignUpCredentials): Promise<void>;
  updateUser(user: User): void;
  socialAuthentication({
    user,
    token,
  }: SocialAuthenticationCredentials): Promise<void>;
}

interface AuthState {
  token: string;
  user: User;
}

const AuthContext = createContext<AuthContextData>({} as AuthContextData);

export function useAuth(): AuthContextData {
  const context = useContext(AuthContext);

  if (!context) {
    throw Error("useAuth must be used within an AuthProvider");
  }

  return context;
}

export const AuthProvider = ({ children }: ProviderProps) => {
  const [data, setData] = useState<AuthState>(() => {
    const token = localStorage.getItem("@Profiz:token");
    const user = localStorage.getItem("@Profiz:user");

    if (token && user) {
      api.defaults.headers.authorization = `Bearer ${token}`;
      apiv2.defaults.headers.authorization = `Bearer ${token}`;

      return {
        token,
        user: JSON.parse(user),
      };
    }

    return {} as AuthState;
  });

  const signIn = async ({ email, password }: SignInCredentials) => {
    const response = await api.post("/users/auth", {
      email,
      password,
    });

    const { token, user } = response.data;

    localStorage.setItem("@Profiz:token", token);
    localStorage.setItem("@Profiz:user", JSON.stringify(user));

    api.defaults.headers.authorization = `Bearer ${token}`;
    apiv2.defaults.headers.authorization = `Bearer ${token}`;

    setData({ token, user });
  };

  const signOut = () => {
    localStorage.removeItem("@Profiz:token");
    localStorage.removeItem("@Profiz:user");
    localStorage.removeItem(KEY);

    setData({} as AuthState);
  };

  const signUp = async ({
    name,
    email,
    password,
    indicationHash,
  }: SignUpCredentials) => {
    const response = await api.post("/users", {
      name,
      email,
      password,
      indicationHash,
    });

    const { user, token } = response.data;

    if (isMobile) return;

    localStorage.setItem("@Profiz:token", token);
    localStorage.setItem("@Profiz:user", JSON.stringify(user));

    api.defaults.headers.authorization = `Bearer ${token}`;
    apiv2.defaults.headers.authorization = `Bearer ${token}`;

    setData({ token, user });
  };

  const updateUser = (user: User) => {
    localStorage.setItem("@Profiz:user", JSON.stringify(user));

    setData({
      token: data.token,
      user,
    });
  };

  const socialAuthentication = async ({
    user,
    token,
  }: SocialAuthenticationCredentials) => {
    localStorage.setItem("@Profiz:token", token);
    localStorage.setItem("@Profiz:user", JSON.stringify(user));

    api.defaults.headers.authorization = `Bearer ${token}`;
    apiv2.defaults.headers.authorization = `Bearer ${token}`;

    setData({ token, user });
  };

  return (
    <AuthContext.Provider
      value={{
        user: data.user,
        signIn,
        signOut,
        signUp,
        updateUser,
        socialAuthentication,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
