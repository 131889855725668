import { useEffect, useState } from "react";
import { useTheme } from "styled-components";
import { FiX } from "react-icons/fi";

import { Button } from "components/Button";
import { ServiceOrderCard } from "./ServiceOrderCard";
import {
  ServiceOrderProps,
  useSelectedServiceOrder,
} from "hooks/selectedServiceOrder";
import { useAccount } from "hooks/permission/account";

import * as S from "./styles";
import apiv2 from "services/apiv2";
import { useToast } from "hooks/toast";
import { useClient } from "hooks/budget/client";
import { EmptyPage } from "components/EmptyPage";

type Props = {
  handleToggleOpen: () => void;
};

export function ModalApprovedServiceOrders({ handleToggleOpen }: Props) {
  const { whoami } = useAccount();
  const theme = useTheme();
  const { addToast } = useToast();

  const { client, hasClient } = useClient();
  const { selectedServiceOrder, handleSetSelectedServiceOrder } =
    useSelectedServiceOrder();

  const [serviceOrdersResponse, setServiceOrdersResponse] = useState<
    ServiceOrderProps[]
  >([]);

  useEffect(() => {
    async function loadServiceOrders() {
      try {
        const { data } = await apiv2.get("budgets/service-orders", {
          params: {
            offset: 0,
            limit: 100,
            status: "concluded",
            clientID: hasClient ? client.id : null,
            accountId: whoami?.id,
          },
        });

        const mappedServiceOrders = data.ordens.map(
          (serviceOrder: ServiceOrderProps) => {
            if (serviceOrder.id === selectedServiceOrder?.id) {
              return {
                ...serviceOrder,
                checked: true,
              };
            }

            return {
              ...serviceOrder,
              checked: false,
            };
          }
        );

        setServiceOrdersResponse(mappedServiceOrders);
      } catch (error) {
        addToast({
          type: "error",
          title: "Ops...",
          description: "Não foi possível carregar as ordens de serviço.",
        });
      }
    }

    loadServiceOrders();
  }, []); // eslint-disable-line

  function handleSelectServiceOrder(serviceOrderId: number) {
    const checkingServiceOrdersSelected = serviceOrdersResponse.map(
      (serviceOrder) => {
        if (serviceOrder.id === serviceOrderId) {
          return {
            ...serviceOrder,
            checked: !serviceOrder.checked,
          };
        }

        return {
          ...serviceOrder,
          checked: false,
        };
      }
    );

    setServiceOrdersResponse(checkingServiceOrdersSelected);
  }

  function handleSaveServiceOrder() {
    const selectedServiceOrder = serviceOrdersResponse.find(
      (serviceOrder) => serviceOrder.checked
    );

    handleSetSelectedServiceOrder(selectedServiceOrder!);
    handleToggleOpen();
  }

  return (
    <>
      <S.Top>
        <div>
          <S.ButtonClose onClick={() => handleToggleOpen()}>
            <FiX size={10} color={theme.colors.text} />
          </S.ButtonClose>

          <div>
            <h4>Ordens de Serviço</h4>

            <S.InfoText>
              Selecione apenas uma ordem de serviço para ser vinculada
            </S.InfoText>
          </div>
        </div>

        <S.WrapperStatus>
          <div />
          <span>Concluídas</span>
        </S.WrapperStatus>
      </S.Top>

      <S.Content>
        {serviceOrdersResponse.length === 0 && <EmptyPage />}

        {serviceOrdersResponse.map((serviceOrder) => (
          <ServiceOrderCard
            key={serviceOrder.id}
            data={serviceOrder}
            onClick={() => handleSelectServiceOrder(serviceOrder.id)}
          />
        ))}
      </S.Content>

      {serviceOrdersResponse.some(({ checked }) => checked) && (
        <S.Footer>
          <Button onClick={handleSaveServiceOrder}>
            Adicionar ordem de serviço
          </Button>
        </S.Footer>
      )}
    </>
  );
}
