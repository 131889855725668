import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { format, parseISO } from "date-fns";

import { EmptyPage } from "components/EmptyPage";
import { LoadingProfiz } from "components/LoadingProfiz";
import { TablePagination } from "components/TablePagination";
import { ModalRejectedRegistration } from "components/ModalRejectedRegistration";

import { useToast } from "hooks/toast";
import { useAuth } from "hooks/auth";
import { ServiceDTO } from "dtos/ServiceDTO";
import { ProductResponseProps } from "dtos/businessProposalsDTO";

import apiv2 from "services/apiv2";

import * as S from "./styles";

type ServiceItemProps = Omit<
  ServiceDTO,
  "quantity" | "total" | "formattedTotal" | "checked"
>;

type BudgetResponse = {
  id: number;
  sequenceNumber: number;
  client: string;
  created_at: string;
  services: {
    idBudgetService: number;
    quantity: number;
    service: ServiceItemProps;
  }[];
  products: ProductResponseProps[];
};

export function Model() {
  const { user } = useAuth();
  const { addToast } = useToast();
  const history = useHistory();

  const [isLoading, setIsLoading] = useState(true);
  const [allBudgetsModels, setAllBudgetsModels] = useState<BudgetResponse[]>(
    []
  );

  const [totalItems, setTotalItems] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [offset, setOffset] = useState(0);
  const [activeModalRejectedRegistration, setActiveModalRejectedRegistration] =
    useState(false);

  const searchLimit = 50;

  useEffect(() => {
    getModelBudgets();
  }, [offset]); // eslint-disable-line react-hooks/exhaustive-deps

  async function getModelBudgets() {
    try {
      setIsLoading(true);
      const response = await apiv2.get(`budgets`, {
        params: {
          status: "pending",
          type: "model",
          offset: offset,
          limit: searchLimit,
        },
      });

      setTotalItems(response.data.info.total);

      setAllBudgetsModels(response.data.orcamentos);
    } catch (error) {
      addToast({
        title: "Ops!!",
        description: "Ocorreu um erro ao listar os modelos de orçamentos",
        type: "error",
      });
    } finally {
      setIsLoading(false);
    }
  }

  function handleNavigateModelBudget(budgetId: number) {
    if (user?.profileDisapproved) {
      setActiveModalRejectedRegistration(true);
    } else {
      history.push(`/budgets/model/${budgetId}`);
    }
  }

  function handlePageChange(pageNumber: number, itemsPerPage: number) {
    setCurrentPage(pageNumber);
    setOffset((pageNumber - 1) * itemsPerPage);
  }

  return (
    <>
      <ModalRejectedRegistration
        isVisible={activeModalRejectedRegistration}
        setIsActive={setActiveModalRejectedRegistration}
      />
      {isLoading ? (
        <LoadingProfiz isVisible={isLoading} />
      ) : (
        <S.Content>
          {allBudgetsModels.length === 0 ? (
            <EmptyPage />
          ) : (
            <table>
              <thead>
                <tr>
                  <th>Número</th>

                  <th> Produto </th>
                  <th> Serviço </th>
                  <th> Cliente </th>
                  <th> Data </th>
                  <th> Status </th>
                </tr>
              </thead>

              {!isLoading && (
                <tbody>
                  {allBudgetsModels.map(
                    ({
                      id,
                      sequenceNumber,
                      services,
                      products,
                      client,
                      created_at,
                    }) => {
                      const productNamesJoined = products
                        .map(({ product }) => product.name)
                        .join(", ");

                      const serviceNamesJoined = services
                        .map(({ service }) => service.service.name)
                        .join(", ");

                      return (
                        <tr
                          key={id}
                          onClick={() => handleNavigateModelBudget(id)}
                        >
                          <td className="columm-id">{sequenceNumber}</td>
                          <td>
                            {products.length === 0 ? (
                              <p style={{ textAlign: "center" }}>---</p>
                            ) : (
                              <p>
                                {productNamesJoined.length > 42
                                  ? productNamesJoined.slice(0, 39) + "..."
                                  : productNamesJoined}
                              </p>
                            )}
                          </td>
                          <td>
                            {services.length === 0 ? (
                              <p style={{ textAlign: "center" }}>---</p>
                            ) : (
                              <p>
                                {serviceNamesJoined.length > 42
                                  ? serviceNamesJoined.slice(0, 39) + "..."
                                  : serviceNamesJoined}
                              </p>
                            )}
                          </td>
                          <td>
                            {!client ? (
                              <p style={{ textAlign: "center" }}> --- </p>
                            ) : (
                              client
                            )}
                          </td>
                          <td>{format(parseISO(created_at), "dd/MM/yyyy")}</td>

                          <td>Modelo</td>
                        </tr>
                      );
                    }
                  )}
                </tbody>
              )}
            </table>
          )}

          <S.ContainerPagination>
            <TablePagination
              rowsLength={totalItems}
              rowsPerPage={searchLimit}
              rowsPage={currentPage}
              handlePageChange={(pageNumber, itemsPerPage) =>
                handlePageChange(pageNumber, itemsPerPage)
              }
            />
          </S.ContainerPagination>
        </S.Content>
      )}
    </>
  );
}
