import styled, { css } from "styled-components";


interface ContainerProps{
  quantityPhotosValidation:boolean
}


export const Container = styled.div<ContainerProps>`
  ${({ theme,quantityPhotosValidation }) => css`
    width: 50px;
    min-width: 50px;
    height: 50px;
    border: 1px dashed ${theme.colors.primary};
    border-radius: 10px;

    display: flex;
    justify-content: center;
    align-items: center;

    cursor: ${!quantityPhotosValidation ? 'pointer' : 'not-allowed'};
    button{
      cursor: ${!quantityPhotosValidation ? 'pointer' : 'not-allowed'};
    }

    input {
      display: none;
    }
  `}
`;
