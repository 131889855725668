import React from "react";

import { Input } from "components/Input";
import { CardCheckRegister } from "components/CardCheckRegister";
import { ServiceRegisterPhoto } from "components/ServiceRegisterPhoto";
// import { CardPhotoService } from "components/CardPhotoService";

import { useServiceOrder } from "hooks/serviceOrder";
import { RegisterProps } from "dtos/ServiceChecklistDTO";

import * as S from "./styles";

export function ListRegisterForServices() {
  const { selectedServiceOrder } = useServiceOrder();

  function getOptionForRegister(register: RegisterProps) {
    const photosFormatted =
      register.photosFormatted ||
      (register.photos &&
        register.photos.map((photo) => ({
          id: photo.id,
          link: photo.url,
        })));

    const options = {
      medir: <Input name={register.name} value={register.value} disabled />,
      verificar: <CardCheckRegister register={register} editable={false} />,
      executar: <CardCheckRegister register={register} editable={false} />,
      fotografar: (
        <>
          {photosFormatted ? (
            <>
              <S.TitlePhotos>Fotos</S.TitlePhotos>

              <S.WrapperPhotos>
                {photosFormatted.map((photo) => (
                  <S.WrapperPhoto>
                    <ServiceRegisterPhoto key={photo.link} url={photo.link} />
                  </S.WrapperPhoto>
                ))}
              </S.WrapperPhotos>
            </>
          ) : (
            <S.TitlePhotos>Serviço sem foto cadastrada</S.TitlePhotos>
          )}
        </>
      ),
    };

    return options[register.type];
  }

  return (
    <>
      {selectedServiceOrder.services.map((currentService) => (
        <S.ContainerService key={currentService.idBudgetService}>
          {Number(selectedServiceOrder.pmoc.id) <= 0 && (
            <S.ServiceName>{currentService.service.service.name}</S.ServiceName>
          )}

          {currentService.service?.serviceChecklist &&
            currentService.service.serviceChecklist[0].registers.map(
              (register) => (
                <S.Wrapper key={register.id}>
                  {getOptionForRegister(register)}
                </S.Wrapper>
              )
            )}
        </S.ContainerService>
      ))}
    </>
  );
}
