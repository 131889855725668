import styled, { css } from "styled-components";

type SelectedProps = {
  selected: boolean;
};

export const Title = styled.p`
  ${({ theme }) => css`
    font-family: ${theme.font.family};
    font-size: ${theme.font.sizes.small};
    font-weight: ${theme.font.normal};
    color: ${theme.colors.text_light_87};
    line-height: 20px;
    margin-bottom: 10px;
  `}
`;

export const Wrapper = styled.div`
  display: flex;
  height: 100px;
  overflow-x: auto;
`;

export const Member = styled.button<SelectedProps>`
  ${({ theme, selected }) => css`
    display: flex;
    flex-direction: column;
    margin-right: 13px;
    align-items: center;

    img {
      width: 48px;
      height: 48px;
      border-radius: 50%;
      border: 1px solid ${selected ? theme.colors.primary : "transparent"};
    }

    span {
      font-family: ${theme.font.family};
      font-size: ${theme.font.sizes.small};
      font-weight: ${theme.font.bold};
      color: ${theme.colors.text_light_87};
      line-height: 20px;
    }
  `}
`;

export const Dot = styled.div`
  ${({ theme }) => css`
    width: 7px;
    height: 7px;
    border-radius: 50%;
    background-color: ${theme.colors.primary};
    margin: 5px;
  `}
`;
