import styled, { css } from "styled-components";

export const Container = styled.div`
  width: 100%;
  height: 50vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const Description = styled.p`
  ${({ theme }) => css`
    margin-top: 20px;
    font-size: ${theme.font.sizes.small};
    color: ${theme.colors.text};
  `}
`;
