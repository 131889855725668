import { useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import debounce from "lodash.debounce";

import { PageDetail } from "pages/permission/PageDetail";
import { LoadingPageModal } from "components/LoadingPageModal";
import { SearchInput } from "components/SearchInput";
import { Button } from "components/Button";
import { TeamMemberList } from "../TeamMemberList";
import { ShowWhenHavePermission } from "../ShowWhenHavePermission";

import { useAccount } from "hooks/permission/account";
import { useToast } from "hooks/toast";
import { TeamMemberDTO } from "dtos/permission/TeamMemberDTO";

import { api } from "services/api";

import * as S from "./styles";
import { Pages } from "pages/permission/ManagementOfTeam";
import { searchDatalayer } from "utils/pushDataLayer";

type Hashs = "invited" | "added" | "removed";

export type Props = {
  [key: number]: Hashs;
};

type TeamMemberProps = {
  activeTab?: "invited" | "added" | "removed";
  setViewPageDetail?: (page: Pages | null) => void;
  setEdditingPermission?: (value: boolean) => void;
  setMemberInviteId?: (value: number) => void;
  setCurrentProfileId?: (value: number) => void;
  setCurrentTab: (currentTabe: number) => void;
  currentTab: number;
};

export function TeamMember({
  setViewPageDetail,
  setEdditingPermission,
  setMemberInviteId,
  setCurrentProfileId,
  currentTab,
  setCurrentTab,
}: TeamMemberProps) {
  const { whoami } = useAccount();
  const { addToast } = useToast();
  const history = useHistory();

  const mappedTabs: Props = {
    0: "invited",
    1: "added",
    2: "removed",
  };
  const [loading, setLoading] = useState(false);
  const [inputLoading, setInputLoading] = useState(false);
  const [searchValue, setSearchValue] = useState("");

  const [teamMembers, setTeamMembers] = useState<TeamMemberDTO[]>([]);

  function handleCancelSearch() {
    setSearchValue("");
    getTeamMembers("");
  }
  function handleChangeInput(text: string) {
    setInputLoading(true);
    setSearchValue(text);
    debounceSearch(text);
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debounceSearch = useCallback(debounce(getTeamMembers, 3000), []);

  async function getTeamMembers(search?: string) {
    try {
      setLoading(true);
      const { data } = await api.get(`users/teams/${whoami?.id}/members`, {
        params: {
          tabFilter: mappedTabs[currentTab],
          search: search,
        },
      });

      setTeamMembers(data.members);

      if (search) {
        searchDatalayer({
          search_term: search,
          success: true,
        });
      }
    } catch (e) {
      addToast({
        title: "Ops!!",
        description: "Ocorreu um erro ao listar os membros do time",
        type: "error",
      });
    } finally {
      setInputLoading(false);
      setLoading(false);
    }
  }

  useEffect(() => {
    getTeamMembers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentTab]);

  return (
    <>
      {loading ? (
        <LoadingPageModal />
      ) : (
        <PageDetail
          title="Membros da equipe"
          subtitle=""
          hideGoBackButton={true}
        >
          <SearchInput
            searchValue={searchValue}
            placeholder="Procure pelo nome do cliente"
            onChange={(event) => handleChangeInput(event.target.value)}
            handleCancel={handleCancelSearch}
            loadingInput={inputLoading}
          />

          <ShowWhenHavePermission moduleHash="team" actionHash="invite-send">
            <Button
              typeButton="outline"
              onClick={() => history.push("/teams/management/invite")}
            >
              Convidar novo membro para equipe
            </Button>
          </ShowWhenHavePermission>

          <S.TabContainer>
            <Tabs
              selectedIndex={currentTab}
              onSelect={(index: number) => {
                setCurrentTab(index);
              }}
            >
              <TabList>
                <Tab>Convidados</Tab>
                <Tab>Adicionados</Tab>
                <Tab>Removidos</Tab>
              </TabList>

              {Object.keys(mappedTabs).map((_, index) => (
                <TabPanel key={index}>
                  <TeamMemberList
                    setCurrentProfileId={setCurrentProfileId}
                    setMemberInviteId={setMemberInviteId}
                    setEdditingPermission={setEdditingPermission}
                    setViewPageDetail={setViewPageDetail}
                    members={teamMembers}
                    updateList={getTeamMembers}
                  />
                </TabPanel>
              ))}
            </Tabs>
          </S.TabContainer>
        </PageDetail>
      )}
    </>
  );
}
