import React, {
  createContext,
  useContext,
  useState,
  Dispatch,
  SetStateAction,
} from "react";
import axios from "axios";
import { api } from "services/api";

import { ClientProps, UnityDTO } from "../dtos/ClientDTO";
import { useToast } from "./toast";
import { ProviderProps } from "hooks";

type EditClientContextData = {
  editedClient: ClientProps;
  handleSetEditClient: (client: ClientProps) => void;
  clearEditClientData: () => void;
  hasClient: boolean;
  hasAddress: boolean;
  selectedUnity: UnityDTO;
  setSelectedUnity: Dispatch<SetStateAction<UnityDTO>>;
  redefineClientUnity: () => void;
  unities: UnityDTO[];
  setUnities: Dispatch<SetStateAction<UnityDTO[]>>;
  loadUnities: (id?: number) => void;

  hasUnity: boolean;
  handleSetHasUnityClientEdit: (state: boolean) => void;
  unity: UnityDTO;
  handleSetUnityClientEdit: (unity: UnityDTO) => void;
  clearUnityData: () => void;
};

const EditClientContext = createContext<EditClientContextData>(
  {} as EditClientContextData
);

export function useEditClient(): EditClientContextData {
  const context = useContext(EditClientContext);

  if (!context) {
    throw Error("useClient must be used within a ClientProvider");
  }

  return context;
}

export const EditClientProvider = ({ children }: ProviderProps) => {
  const { addToast } = useToast();

  const [editedClient, setEditedClient] = useState({} as ClientProps);
  const [hasClient, setHasClient] = useState(false);
  const [hasAddress, setHasAddress] = useState(false);
  const [selectedUnity, setSelectedUnity] = useState({} as UnityDTO);
  const [unities, setUnities] = useState<UnityDTO[]>([]);

  const [hasUnity, setHasUnity] = useState(false);
  const [unity, setUnity] = useState({} as UnityDTO);

  function handleSetUnityClientEdit(unity: UnityDTO) {
    setUnity(unity);
    setHasUnity(true);
  }

  function handleSetHasUnityClientEdit(state: boolean) {
    setHasUnity(state);
  }

  function clearUnityData() {
    setHasUnity(false);
    setUnity({} as UnityDTO);
  }

  function redefineClientUnity() {
    setSelectedUnity({} as UnityDTO);
  }

  function handleSetEditClient(clientValue: ClientProps) {
    setEditedClient(clientValue);
    setHasClient(true);
  }

  function clearEditClientData() {
    setEditedClient({} as ClientProps);
    setHasClient(false);
    setHasAddress(false);
    setUnities([] as UnityDTO[]);
    setHasUnity(false);
    setUnity({} as UnityDTO);
    setSelectedUnity({} as UnityDTO);
  }

  async function loadUnities(id?: number) {
    try {
      const response = await api.get(
        `/clients/${id ? id : editedClient.id}/unities`,
        {
          params: {
            limit: 50,
            offset: 0,
            client_id: editedClient.id,
          },
        }
      );

      setUnities(response.data.unities);
    } catch (error) {
      if (axios.isAxiosError(error) && error.response?.status === 403) return;

      addToast({
        type: "error",
        title: "Erro ao carregar endereços",
        description:
          "Ocorreu um erro ao carregar os endereços, tente novamente.",
      });
    }
  }

  return (
    <EditClientContext.Provider
      value={{
        editedClient,
        handleSetEditClient,
        clearEditClientData,
        hasClient,
        hasAddress,
        setSelectedUnity,
        selectedUnity,
        redefineClientUnity,
        unities,
        setUnities,
        loadUnities,

        hasUnity,
        handleSetHasUnityClientEdit,
        unity,
        handleSetUnityClientEdit,
        clearUnityData,
      }}
    >
      {children}
    </EditClientContext.Provider>
  );
};
