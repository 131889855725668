import React, { useState } from "react";
import { FiPlus, FiMinus } from "react-icons/fi";

import * as S from "./styles";

type QuantityCounterProps = {
  initialValue: number;
  onIncrement: (result: number) => void;
  disabled?: boolean;
};

export function QuantityCounter({
  initialValue,
  onIncrement,
  disabled
}: QuantityCounterProps) {
  const [quantity, setQuantity] = useState(initialValue);

  function handleRemove() {
    if (disabled) {
      return;
    }

    if (quantity <= 1) return;
    const result = quantity - 1;

    onIncrement(result);
    setQuantity(result);
  }

  function handleAdd() {
    if (disabled) {
      return;
    }

    const result = quantity + 1;

    onIncrement(result);
    setQuantity(result);
  }

  return (
    <S.Container>
      <S.ButtonAction disabled={disabled} onClick={() => handleRemove()}>
        <FiMinus size={12} />
      </S.ButtonAction>

      <S.WrapperQuantity disabled={disabled}>
        <S.Quantity disabled={disabled}>{quantity}</S.Quantity>
      </S.WrapperQuantity>

      <S.ButtonAction disabled={disabled} onClick={() => handleAdd()}>
        <FiPlus size={12} />
      </S.ButtonAction>
    </S.Container>
  );
}
