import styled, { css } from "styled-components";
import { fadeFormToTop, translateStarsToTop } from "../animations";
import media from "styled-media-query";

type ButtonProps = {
  isActive: boolean;
};

type AnimatedProps = {
  isSelectedStars: boolean;
};

export const Container = styled.div`
  ${({ theme }) => css`
    height: 100vh;
    overflow: auto;
    background-color: ${theme.colors.strong_background};

    display: flex;
    flex-direction: column;
    align-items: center;
  `}
`;

export const Main = styled.main<AnimatedProps>`
  ${({ isSelectedStars }) => css`
    margin-top: 60px;
    margin-bottom: 60px;
    width: 40%;
    max-width: 400px;
    min-height: 50vh;
    display: flex;
    flex-direction: column;

    & > div {
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin-top: 40%;
    }

    ${media.lessThan("large")`
      width: 80%;
    `}

    ${isSelectedStars &&
    css`
      & > div {
        animation: ${translateStarsToTop} 1s forwards;
        margin-top: 0;
        transition: margin 1s;
      }
    `}
  `}
`;

export const LogoProfiz = styled.img`
  width: 42px;
  height: 42px;
  align-self: center;
  margin-bottom: 44px;
`;

export const WrapperText = styled.div`
  margin-bottom: 12px;
`;

export const Title = styled.h4`
  ${({ theme }) => css`
    font-size: ${theme.font.sizes.large};
    font-weight: ${theme.font.bold};
    color: ${theme.colors.title};
    margin-bottom: 4px;
  `}
`;

export const Text = styled.p`
  ${({ theme }) => css`
    font-size: ${theme.font.sizes.xsmall};
    color: ${theme.colors.text};
    line-height: 20px;
  `}
`;

export const WrapperRating = styled.div`
  display: flex;
  align-items: center;

  & > p {
    ${({ theme }) => css`
      font-size: ${theme.font.sizes.xsmall};
      color: ${theme.colors.text};
      font-weight: 600;
      margin-left: 12px;
    `}
  }
`;

export const Star = styled.img`
  width: 30px;
  height: 30px;
  margin-right: 10px;
`;

export const Wrapper = styled.div`
  margin-top: 40px;

  & > div:first-of-type {
    margin-top: 8px;
  }

  & > div {
    width: 90%;
    display: flex;
    margin-bottom: 10px;
    flex-wrap: wrap;

    ${media.lessThan("large")`
      width: 100%;
    `}
  }
`;

export const WrapperComments = styled.div`
  margin-top: 32px;
  margin-bottom: 50px;
`;

export const WrapperForm = styled.div<AnimatedProps>`
  ${({ isSelectedStars }) =>
    isSelectedStars
      ? css`
          animation: ${fadeFormToTop} 1s forwards;
        `
      : css`
          display: none;
        `}
  margin-bottom: 50px;
`;

export const Button = styled.button<ButtonProps>`
  ${({ theme, isActive }) => css`
    height: 35px;
    border-radius: 10px;
    padding: 0 12px;
    margin-right: 10px;
    margin-bottom: 10px;

    display: flex;
    align-items: center;
    justify-content: center;

    font-size: ${theme.font.sizes.xsmall};
    transition opacity 0.3s ease-in-out;

    ${
      isActive
        ? css`
            background-color: ${theme.colors.primary};
            color: ${theme.colors.background};
          `
        : css`
            background-color: ${theme.colors.background_modal};
            color: ${theme.colors.text};
          `
    }

    &:hover {
      opacity: 0.6;
    }
  `}
`;
