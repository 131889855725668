import React, { useEffect, useState } from "react";
import { EnvironmentPageProps } from "../EnvironmentFlow/types";

import { ModalPageProps } from "components/ModalPageClients/types";
import { useEnvironment } from "hooks/pmoc/environmentPmoc";
import { useToast } from "hooks/toast";

import { FiChevronLeft } from "react-icons/fi";

import * as S from "./styles";
import { useTheme } from "styled-components";

import { PhotoCardStep } from "pages/fieldService/ServicesHub/PhotoCardStep";
import { Photo } from "../PhotoTools/types";
import { AddPhoto } from "../PhotoTools/AddPhoto";
import { ServiceRegisterPhoto } from "components/ServiceRegisterPhoto";
import { api } from "services/api";
import { Button } from "components/Button";
import { DetailEquipmentDTO } from "dtos/PmocEnvironmentDTO";
import axios from "axios";

type PageProps = {
  handleChooseEditModal?: ModalPageProps;
  handleChooseEnvironment?: EnvironmentPageProps;
};

export function EditPhotoEquipment({
  handleChooseEditModal,
  handleChooseEnvironment,
}: PageProps) {
  const theme = useTheme();
  const { addToast } = useToast();

  const {
    userIdPMOC,
    unityIdPMOC,
    equipment,
    nameFlow,
    environment,
    handleSetEquipment,
  } = useEnvironment();

  const [tagsPictures, setTagsPictures] = useState<Photo[]>([]);
  const [invoicePicture, setInvoicePicture] = useState<Photo[]>([]);

  const [loadingSignatureId, setLoadingSignatureId] = useState(0);

  const [loadingButton, setLoadingButton] = useState(false);

  useEffect(() => {
    SetData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function SetData() {
    equipment &&
      equipment.tagPhotos &&
      equipment.tagPhotos.length > 0 &&
      setTagsPictures(equipment.tagPhotos);

    equipment &&
      equipment.invoice &&
      Object.keys(equipment.invoice).length !== 0 &&
      setInvoicePicture([equipment.invoice]);
  }

  function handleBackButton() {
    if (nameFlow === "unity" && handleChooseEditModal) {
      return handleChooseEditModal("editEquipment");
    }

    if (nameFlow === "pmoc" && handleChooseEnvironment) {
      return handleChooseEnvironment("editEquipment");
    }
  }

  async function handleDeletePhotoTagEquipment(pictureId: number) {
    try {
      setLoadingSignatureId(pictureId);
      await api.delete(
        `/client/${userIdPMOC}/unities/${unityIdPMOC}/environment/${environment.id}/equipment/${equipment.id}/tag-photo/${pictureId}`
      );

      let aux = tagsPictures.filter((element) => {
        return element.id !== pictureId;
      });

      setTagsPictures(aux);

      addToast({
        type: "success",
        title: "OK!",
        description: "Foto removida com sucesso!",
      });
    } catch (err) {
      addToast({
        title: "Erro",
        description:
          axios.isAxiosError(err) && err.response?.data.error
            ? err.response.data.error
            : "Não foi possível remover a foto!",
        type: "error",
      });
    } finally {
      setLoadingSignatureId(0);
    }
  }

  async function handleDeletePhotoInvoiceEquipment(pictureId: number) {
    try {
      setLoadingSignatureId(pictureId);
      await api.delete(
        `/client/${userIdPMOC}/unities/${unityIdPMOC}/environment/${environment.id}/equipment/${equipment.id}/invoice-photo/${pictureId}`
      );

      let aux = invoicePicture.filter((element) => {
        return element.id !== pictureId;
      });

      setInvoicePicture(aux);

      addToast({
        type: "success",
        title: "OK!",
        description: "Foto removida com sucesso!",
      });
    } catch (err) {
      addToast({
        title: "Erro",
        description:
          axios.isAxiosError(err) && err.response?.data.error
            ? err.response.data.error
            : "Não foi possível remover a foto!",
        type: "error",
      });
    } finally {
      setLoadingSignatureId(0);
    }
  }

  function handleSavePhotos() {
    try {
      setLoadingButton(true);

      if (
        !tagsPictures ||
        tagsPictures.length < 1 ||
        !invoicePicture ||
        invoicePicture.length < 1
      ) {
        return handleBackButton();
      }

      //adicionando as fotos inseridas no contexto do equipamento
      let aux: DetailEquipmentDTO = JSON.parse(JSON.stringify(equipment));
      if (tagsPictures && tagsPictures.length > 0) aux.tagPhotos = tagsPictures;
      if (invoicePicture && invoicePicture.length > 0)
        aux.invoice = invoicePicture[0];

      handleSetEquipment(aux);

      handleBackButton();
    } catch (err) {
      addToast({
        title: "Ops!!",
        description: "Ocorreu um erro ao salvar as imagens no equipamento.",
        type: "error",
      });
    } finally {
      setLoadingButton(false);
    }
  }

  return (
    <S.Container>
      <S.Top>
        <S.BackButton onClick={() => handleBackButton()}>
          <FiChevronLeft size={16} color={theme.colors.text} />
        </S.BackButton>
        <S.Title>Editar fotos</S.Title>
      </S.Top>

      <PhotoCardStep
        title="Etiquetas ou TAGS já existentes"
        maxPhotos={3}
        photosQuantity={tagsPictures.length}
      >
        <S.Content>
          <S.Wrapper>
            <AddPhoto
              maxPhoto={3}
              photosQuantity={tagsPictures.length}
              handleAddNewPhoto={(photo) => {
                setTagsPictures([...tagsPictures, photo]);
              }}
            />
          </S.Wrapper>
          {tagsPictures &&
            tagsPictures.length > 0 &&
            tagsPictures.map((photoElement: Photo) => {
              return (
                <S.Wrapper key={photoElement.id}>
                  <ServiceRegisterPhoto
                    loading={loadingSignatureId === photoElement.id}
                    key={photoElement.id}
                    url={photoElement.link}
                    onDelete={() =>
                      handleDeletePhotoTagEquipment(photoElement.id)
                    }
                  />
                </S.Wrapper>
              );
            })}
        </S.Content>
      </PhotoCardStep>

      <PhotoCardStep
        title="Nota fiscal"
        maxPhotos={1}
        //photosQuantity={Object.keys(invoicePicture).length === 0 ? 0 : 1}
        photosQuantity={invoicePicture.length}
      >
        <S.Content>
          <S.Wrapper>
            <AddPhoto
              maxPhoto={1}
              //photosQuantity={Object.keys(invoicePicture).length === 0 ? 0 : 1}
              photosQuantity={invoicePicture.length}
              handleAddNewPhoto={(photo) => {
                setInvoicePicture([photo]);
              }}
              invoiceImg
            />
          </S.Wrapper>
          {invoicePicture &&
            invoicePicture.length > 0 &&
            invoicePicture.map((photoElement: Photo) => {
              return (
                <S.Wrapper key={photoElement.id}>
                  <ServiceRegisterPhoto
                    loading={loadingSignatureId === photoElement.id}
                    key={photoElement.id}
                    url={photoElement.link}
                    onDelete={() =>
                      handleDeletePhotoInvoiceEquipment(photoElement.id)
                    }
                  />
                </S.Wrapper>
              );
            })}
        </S.Content>
      </PhotoCardStep>

      <Button
        loading={loadingButton}
        disabled={loadingButton}
        typeButton="default"
        onClick={handleSavePhotos}
      >
        Salvar
      </Button>
    </S.Container>
  );
}
