import { ServiceForListProps } from "dtos/ServiceDTO";
import React, { useEffect, useState } from "react";
import { SearchInput } from "components/SearchInput";

import * as S from "./styles";
import { ServiceContent } from "../ServiceContent";
import { api } from "services/api";
import { useToast } from "hooks/toast";
import { Button } from "components/Button";
import { EmptyPage } from "components/EmptyPage";
import { ShowWhenHavePermission } from "components/Permission";
import { searchDatalayer } from "utils/pushDataLayer";

interface CommonServiceListProps {
  createServiceModal: () => void;
  editServiceModal: (currentService: ServiceForListProps) => void;
  loadServicesCommon: () => void;
  servicesCommon: ServiceForListProps[];
  setServicesCommon: (value: ServiceForListProps[]) => void;
}

const CommonServiceList = ({
  createServiceModal,
  editServiceModal,
  loadServicesCommon,
  servicesCommon,
}: CommonServiceListProps) => {
  const { addToast } = useToast();
  const [searchInput, setSearchInput] = useState("");
  const [commonData, setcommonData] = useState<ServiceForListProps[]>([]);

  useEffect(() => {
    setcommonData(servicesCommon);
  }, [servicesCommon]);

  function handleSearchService(text: string) {
    setSearchInput(text);

    const filteredServices = servicesCommon.filter((service) => {
      const serviceLowerCase = service.service.name.toLowerCase();
      const searchValue = text.toLowerCase();

      return serviceLowerCase.includes(searchValue);
    });

    searchDatalayer({ search_term: text, success: true });

    setcommonData(filteredServices);
  }

  function handleSearchCancel() {
    setSearchInput("");
    setcommonData(servicesCommon);
  }

  async function handleDeleteService(idService: number) {
    try {
      await api.delete(`/service/${idService}`);

      addToast({
        title: "Sucesso",
        description: "Serviço deletado com sucesso",
        type: "success",
      });

      loadServicesCommon();
    } catch {
      addToast({
        title: "Opss!",
        description: "Não foi possível deletar o item",
        type: "error",
      });
    }
  }

  const getEditCurrentService = (currentService: ServiceForListProps) => {
    editServiceModal(currentService);
  };

  return (
    <S.Container>
      <SearchInput
        searchValue={searchInput}
        onChange={(e) => handleSearchService(e.target.value)}
        placeholder="Busque pelo nome do serviço"
        handleCancel={handleSearchCancel}
      />
      <div style={{ marginBottom: "20px" }}>
        <ShowWhenHavePermission moduleHash={"service"} actionHash="create">
          <Button typeButton="outline" onClick={createServiceModal}>
            Criar novo serviço comum
          </Button>
        </ShowWhenHavePermission>
      </div>

      {commonData.length === 0 && <EmptyPage />}

      {commonData.map((currentService) => (
        <S.WrapperService key={currentService.id}>
          <ServiceContent
            data={currentService}
            openModalEditService={() => {
              getEditCurrentService(currentService);
            }}
            onDeleteService={handleDeleteService}
          />
        </S.WrapperService>
      ))}
    </S.Container>
  );
};
export { CommonServiceList };
