import styled from 'styled-components'

export const ShimmerOrderService = styled.div`
  width: 100%;
  height: 95px;
  border-radius: 10px;

  background-image: linear-gradient(
    -90deg,
    #3737376b 0%,
    #393939d6 50%,
    #40404061 100%
  );

  background-size: 400% 400%;
  animation: shimmer 1.2s ease-in-out infinite;
  @keyframes shimmer {
    0% {
      background-position: 0% 0%;
    }
    100% {
      background-position: -135% 0%;
    }
  }
  &.white {
    background-image: linear-gradient(
      -90deg,
      #3737376b 0%,
      #393939d6 50%,
      #40404061 100%
    );
  }
`
