import { useState } from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";

import { Button } from "components/Button";
import { ArrowButton } from "components/ArrowButton";

import { useServiceChecklist } from "hooks/serviceChecklist";

import { RegistersTab } from "./RegistersTab";
import { ChecklistTab } from "./ChecklistTab";

import * as S from "./styles";
import { EnvironmentPageProps, Page } from "pages/pmoc/EnvironmentFlow/types";
import { useEnvironment } from "hooks/pmoc/environmentPmoc";
import { useHistory } from "react-router";

import { BlockIcon } from "components/BlockIcon";
import { useHasPermission } from "hooks/permission/useHasPermission";

type ServiceChecklistProps = {
  handleAssociationFunction?: () => void;
  handleEditItemChecklist?: () => void;
  handlePageClose?: () => void;
  handleChooseEnvironment?: EnvironmentPageProps;
};

export function ServiceChecklist({
  handleAssociationFunction,
  handleEditItemChecklist,
  handlePageClose,
  handleChooseEnvironment,
}: ServiceChecklistProps) {
  const { isNotMenusAccess } = useServiceChecklist();
  const [selectPage, setSelectedPage] = useState<number>(0);
  const { permission: permissionViewChecklist } = useHasPermission({
    actionHash: "view",
    moduleHash: "service-checklist",
  });

  const history = useHistory();

  const { popAndDeleteStackNavigation } = useEnvironment();

  return (
    <S.Container>
      <header>
        <div>
          <ArrowButton
            handleFunction={() => {
              if (handleChooseEnvironment) {
                let beforePage = popAndDeleteStackNavigation();
                handleChooseEnvironment(beforePage as Page);
                return;
              }
              if (handlePageClose) return handlePageClose();
              return history.goBack();
            }}
          />

          <h1>Meus checklists</h1>
        </div>
      </header>
      <S.Content>
        <>
          {isNotMenusAccess ? (
            <ChecklistTab
              handleEditItemChecklist={handleEditItemChecklist}
              handleChooseEnvironment={handleChooseEnvironment}
            />
          ) : (
            <S.TabContainer>
              <Tabs
                selectedIndex={permissionViewChecklist ? selectPage : 1}
                onSelect={(index: number) => {
                  setSelectedPage(index);
                }}
              >
                <TabList disabled={permissionViewChecklist}>
                  <Tab>
                    Checklists
                    {permissionViewChecklist ? null : <BlockIcon />}
                  </Tab>

                  <Tab>
                    Registros
                    {permissionViewChecklist ? null : <BlockIcon />}
                  </Tab>
                </TabList>
                <TabPanel>
                  <ChecklistTab
                    handleEditItemChecklist={handleEditItemChecklist}
                  />
                </TabPanel>

                <TabPanel>
                  <RegistersTab />
                </TabPanel>
              </Tabs>
            </S.TabContainer>
          )}
        </>

        {isNotMenusAccess && (
          <div>
            <Button
              typeButton="default"
              onClick={() => {
                if (handleChooseEnvironment) {
                  let beforePage = popAndDeleteStackNavigation();
                  handleChooseEnvironment(beforePage as Page);
                  return;
                }

                if (handleAssociationFunction) {
                  handleAssociationFunction();
                }
              }}
            >
              Vincular checklist
            </Button>
          </div>
        )}
      </S.Content>
    </S.Container>
  );
}
