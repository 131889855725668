import styled, { css } from "styled-components";

export const CardItem = styled.div`
  ${({ theme }) => css`
    flex-direction: column;
  `};
`;

export const HeaderTitle = styled.div`
  ${({ theme }) => css`
    font-size: 18px;
    font-weight: ${theme.font.bold};
    letter-spacing: 0.4px;
    line-height: 16px;
    text-align: left;
    color: ${theme.colors.financial_title};
  `};
`;

export const WrapperImg = styled.div`
  img {
    max-height: 22vh;
    max-width: 28vw;
    border-radius: 8px;
    object-fit: fill;
  }
  align-items: center;
  justify-content: center;
  display: flex;
`;

export const WrapperHeader = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    padding-top: 15px;
    padding-bottom: 25px;
    border-bottom: 1px solid ${theme.colors.text};
  `};
`;

export const WrapperTitle = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
`;

export const WrapperInfoMid = styled.div`
  ${({ theme }) => css`
    display: flex;
    justify-content: space-around;
    flex-direction: column;
    padding-top: 15px;
    padding-bottom: 25px;
    border-bottom: 1px solid ${theme.colors.text};

    h5 {
      font-size: 14px;
      font-weight: ${theme.font.normal};
      color: ${theme.colors.financial_title};
      font-weight: ${theme.font.bold};
      text-align: left;
      letter-spacing: 0.31px;
      line-height: 16px;
    }

    h6 {
      padding-top: 15px;
      color: ${theme.colors.financial_title};
      font-size: ${theme.font.sizes.xsmall};
      font-weight: ${theme.font.normal};
      text-align: left;
      letter-spacing: 0.26px;
      line-height: 16px;
    }

    p {
      padding-top: 5px;
      color: ${theme.colors.financial_text};
      font-size: ${theme.font.sizes.xsmall};
      font-weight: ${theme.font.normal};
      text-align: left;
      letter-spacing: 0.26px;
      line-height: 16px;
    }

    a {
      overflow-wrap: break-word;
      word-wrap: break-word;
      word-break: break-word;
      padding-top: 5px;
      color: ${theme.colors.primary_light};
      text-decoration: underline;
      font-size: ${theme.font.sizes.xsmall};
      font-weight: ${theme.font.normal};
      text-align: left;
      letter-spacing: 0.26px;
      line-height: 16px;
    }
  `};
`;

export const WrapperInfoContact = styled.div`
  ${({ theme }) => css`
    display: flex;
    justify-content: space-around;
    flex-direction: column;
    padding-top: 15px;
    padding-bottom: 25px;
    h5 {
      font-size: 14px;
      font-weight: ${theme.font.normal};
      color: ${theme.colors.financial_title};
      font-weight: ${theme.font.bold};
      text-align: left;
      letter-spacing: 0.31px;
      line-height: 16px;
    }

    h6 {
      padding-top: 15px;
      color: ${theme.colors.financial_title};
      font-size: ${theme.font.sizes.xsmall};
      font-weight: ${theme.font.normal};
      text-align: left;
      letter-spacing: 0.26px;
      line-height: 16px;
    }

    p {
      padding-top: 5px;
      color: ${theme.colors.financial_text};
      font-size: ${theme.font.sizes.xsmall};
      font-weight: ${theme.font.normal};
      text-align: left;
      letter-spacing: 0.26px;
      line-height: 16px;
    }
  `};
`;

export const ContainerIconContact = styled.div`
  display: flex;
  flex-direction: row;
  img {
    height: 36px;
    width: 36px;
    margin-top: 15px;
  }
`;

export const ContainerTextContact = styled.div`
  padding-left: 15px;
`;

export const WrapperDataIconCard = styled.div`
  ${({ theme }) => css`
    h5 {
      font-size: 18px;
      font-weight: ${theme.font.normal};
      color: ${theme.colors.title};
      text-align: center;
      letter-spacing: 0.4px;
      line-height: 20px;
    }

    p {
      margin-top: 5px;
      color: ${theme.colors.text};
      font-size: ${theme.font.sizes.xsmall};
      text-align: center;
      letter-spacing: 0.26px;
      line-height: 16px;
    }
  `};
`;

export const WrapperMid = styled.div`
  ${({ theme }) => css`
    width: 60%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    p {
      margin-top: 5px;
      color: ${theme.colors.text};
      font-size: ${theme.font.sizes.xsmall};
      text-align: left;
      letter-spacing: 0.26px;
      line-height: 16px;
    }
  `};
`;

export const WrapperDescription = styled.div`
  ${({ theme }) => css`
    max-width: 100%;
    display: flex;

    p {
      white-space: pre-wrap;
      margin-top: 5px;
      color: ${theme.colors.text};
      font-size: ${theme.font.sizes.xsmall};
      text-align: left;
      letter-spacing: 0.26px;
      line-height: 16px;
    }
  `};
`;

export const WrapperFavButton = styled.button`
  img {
    padding-top: 10px;
  }
  display: flex;
  align-items: start;
`;

export const TextButton = styled.p`
  ${({ theme }) => css`
    color: ${theme.colors.lilac_light};
    font-size: ${theme.font.sizes.xsmall};
    text-align: left;
    letter-spacing: 0.26px;
    line-height: 16px;
  `};
`;

export const ButtonClose = styled.button`
  ${({ theme }) => css`
    width: 35px;
    height: 35px;
    background-color: ${theme.colors.background_light};
    border-radius: 10px;

    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 25px;
  `}
`;

export const ShareWrapper = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 12px;
  `};
`;

export const ToolTipBox = styled.div`
  ${({ theme }) => css`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 120px;
    background-color: ${theme.colors.background_light};
    border: 1px solid ${theme.colors.primary};
    margin-top: 12px;
    padding: 8px;
    position: relative;
    border-radius: 4px;

    &::after {
      content: "";
      width: 0px;
      height: 0px;
      display: block;
      border-left: 10px solid transparent;
      border-right: 10px solid transparent;
      border-bottom: 10px solid transparent;
      border-top: 10px solid ${theme.colors.primary};
      position: absolute;
      bottom: 38px;
      left: 10px;
      transform: rotate(180deg);
    }
  `};
`;

export const TextToolTip = styled.p`
  ${({ theme }) => css`
    color: ${theme.colors.lilac_light};
    font-size: ${theme.font.sizes.xsmall};
    text-align: left;
    letter-spacing: 0.26px;
    line-height: 16px;
  `};
`;
