import React from 'react'

import * as S from './styles'

export function ComercialModelsShimmer() {
  return (
    <>
      <div>
        <S.ShimmerEquipment />
        <S.ShimmerManualButton />
        <S.ShimmerManualButton />
      </div>

      <div>
        <S.ShimmerEquipment />
        <S.ShimmerManualButton />
        <S.ShimmerManualButton />
      </div>

      <div>
        <S.ShimmerEquipment />
        <S.ShimmerManualButton />
        <S.ShimmerManualButton />
      </div>

      <div>
        <S.ShimmerEquipment />
        <S.ShimmerManualButton />
        <S.ShimmerManualButton />
      </div>

      <div>
        <S.ShimmerEquipment />
        <S.ShimmerManualButton />
        <S.ShimmerManualButton />
      </div>

      <div>
        <S.ShimmerEquipment />
        <S.ShimmerManualButton />
        <S.ShimmerManualButton />
      </div>

      <div>
        <S.ShimmerEquipment />
        <S.ShimmerManualButton />
        <S.ShimmerManualButton />
      </div>

      <div>
        <S.ShimmerEquipment />
        <S.ShimmerManualButton />
        <S.ShimmerManualButton />
      </div>

      <div>
        <S.ShimmerEquipment />
        <S.ShimmerManualButton />
        <S.ShimmerManualButton />
      </div>

      <div>
        <S.ShimmerEquipment />
        <S.ShimmerManualButton />
        <S.ShimmerManualButton />
      </div>

      <div>
        <S.ShimmerEquipment />
        <S.ShimmerManualButton />
        <S.ShimmerManualButton />
      </div>

      <div>
        <S.ShimmerEquipment />
        <S.ShimmerManualButton />
        <S.ShimmerManualButton />
      </div>
      <div>
        <S.ShimmerEquipment />
        <S.ShimmerManualButton />
        <S.ShimmerManualButton />
      </div>
    </>
  )
}
