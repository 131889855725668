import React from "react";
import { useTheme } from "styled-components";

import { FiPlus } from "react-icons/fi";
import loadingSvg from "assets/loading.svg";

import * as S from "./styles";

type Props = {
  isLoadingButton: boolean;
  value: string;
  handleChangeNewCategory: (text: string) => void;
  handleSave: () => void;
};

export function InputAddNewCategory({
  isLoadingButton = false,
  value,
  handleChangeNewCategory,
  handleSave,
}: Props) {
  const theme = useTheme();

  return (
    <S.Container>
      <input
        type="text"
        value={value}
        onChange={(event) => handleChangeNewCategory(event.target.value)}
      />

      <button type="button" onClick={() => handleSave()}>
        {isLoadingButton && <img src={loadingSvg} alt="imagem de loading" />}
        {!isLoadingButton && (
          <FiPlus size={theme.font.sizes.xsmall} color={theme.colors.input} />
        )}
      </button>
    </S.Container>
  );
}
