/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router";
import debounce from "lodash.debounce";

import { useToast } from "hooks/toast";
import { useAccount } from "hooks/permission/account";
import { useAuth } from "hooks/auth";

import { EmptyPage } from "components/EmptyPage";
import { SearchInput } from "components/SearchInput";
import { ServiceOrdersResponse } from "../Pending/index";
import { LoadingProfiz } from "components/LoadingProfiz";
import { ModalRejectedRegistration } from "components/ModalRejectedRegistration";

import apiv2 from "services/apiv2";

import { TablePagination } from "components/TablePagination";

import * as S from "./styles";
import { searchDatalayer } from "utils/pushDataLayer";

export function ArchivedServiceOrder() {
  const { user } = useAuth();
  const { whoami } = useAccount();
  const history = useHistory();
  const { addToast } = useToast();

  const [isLoading, setIsLoading] = useState(true);

  const [allServiceOrdersScheduled, setAllServiceOrdersScheduled] = useState<
    ServiceOrdersResponse[]
  >([]);
  const [searchInputValue, setSearchInputValue] = useState("");
  const [loadingInput, setLoadingInput] = useState(false);

  const [totalItems, setTotalItems] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [offset, setOffset] = useState(0);
  const [activeModalRejectedRegistration, setActiveModalRejectedRegistration] =
    useState(false);
  const searchLimit = 50;

  useEffect(() => {
    getScheduledServiceOrder();
  }, [offset, whoami?.id]);

  async function getScheduledServiceOrder() {
    try {
      setIsLoading(true);
      if (whoami?.id) {
        const response = await apiv2.get(`budgets/service-orders`, {
          params: {
            archive: true,
            offset: offset,
            limit: searchLimit,
            orderBy: "date-DESC",
            accountId: whoami?.id,
          },
        });

        setTotalItems(response.data.info.total);

        const { ordens } = response.data;
        setAllServiceOrdersScheduled(ordens);
      }
    } catch (error) {
      addToast({
        title: "Ops!!",
        description:
          "Ocorreu um erro ao listar as ordens de serviços arquivadas",
        type: "error",
      });
    } finally {
      setIsLoading(false);
      setLoadingInput(false);
    }
  }

  function handleChange(searchValue: string) {
    setLoadingInput(true);
    setSearchInputValue(searchValue);
    debounceFn(searchValue);
  }

  const debounceFn = useCallback(
    debounce(handleSearchServiceOrderByClient, 3000),
    []
  ); // eslint-disable-line react-hooks/exhaustive-deps

  function handleRedirect(serviceOrderData: ServiceOrdersResponse) {
    if (user?.profileDisapproved) {
      setActiveModalRejectedRegistration(true);
      return;
    }

    setSearchInputValue("");

    const hasScheduled = serviceOrderData.services.some(
      (service) => !!service.scheduledDate
    );

    if (hasScheduled) {
      history.push(`/service-order/scheduled/${serviceOrderData.id}/archived`);
      return;
    }

    history.push(`/service-order/pending/${serviceOrderData.id}/archived`);
  }

  async function handleSearchServiceOrderByClient(client: string) {
    try {
      const response = await apiv2.get(`budgets/service-orders`, {
        params: {
          offset: 0,
          limit: searchLimit,
          client,
          archive: true,
          orderBy: "date-DESC",
          accountId: whoami?.id,
        },
      });

      const { ordens } = response.data;
      setTotalItems(response.data.info.total);

      setAllServiceOrdersScheduled(ordens);
      if (client) {
        searchDatalayer({ search_term: client, success: true });
      }
    } catch (error) {
      addToast({
        title: "Ops!!",
        description:
          "Ocorreu um erro ao listar as ordens de serviços arquivadas",
        type: "error",
      });
    } finally {
      setLoadingInput(false);
    }
  }

  function handleSearchCancel() {
    setSearchInputValue("");
    handleSearchServiceOrderByClient("");
  }

  function switchStatus(status: string) {
    switch (status) {
      case "scheduled":
        return "Agendada";
      case "new":
        return "Não agendada";
      case "concluded":
        return "Concluída";
      default:
        break;
    }
  }

  function handlePageChange(pageNumber: number, itemsPerPage: number) {
    setCurrentPage(pageNumber);
    setOffset((pageNumber - 1) * itemsPerPage);
  }

  return (
    <>
      <ModalRejectedRegistration
        isVisible={activeModalRejectedRegistration}
        setIsActive={setActiveModalRejectedRegistration}
      />
      {isLoading ? (
        <LoadingProfiz isVisible={isLoading} />
      ) : (
        <S.Content>
          <S.ContainerSearch>
            <SearchInput
              loadingInput={loadingInput}
              searchValue={searchInputValue}
              placeholder="Procure pelo nome ou endereço do cliente"
              onChange={(event) => handleChange(event.target.value)}
              // eslint-disable-next-line react/jsx-no-bind
              handleCancel={handleSearchCancel}
            />
          </S.ContainerSearch>

          {allServiceOrdersScheduled.length === 0 ? (
            <EmptyPage />
          ) : (
            <table>
              <thead>
                <tr>
                  <th>Número</th>

                  <th> Serviço(s) </th>
                  <th> Cliente </th>
                  <th> Status </th>
                </tr>
              </thead>

              <tbody>
                {allServiceOrdersScheduled.map((serviceOrder) => (
                  <tr
                    key={serviceOrder.id}
                    onClick={() => {
                      handleRedirect(serviceOrder);
                    }}
                  >
                    <td className="columm-id">{serviceOrder.sequenceNumber}</td>

                    <td>
                      {serviceOrder.services.map(
                        ({ service, idBudgetService }, index) => {
                          if (index > 3) return null;
                          if (index === 3) return <p>...</p>;
                          return (
                            <p key={idBudgetService}>
                              {service}
                              {index < serviceOrder.services.length - 1 && ", "}
                            </p>
                          );
                        }
                      )}
                    </td>
                    <td>{serviceOrder.client.name}</td>

                    <td>
                      <span /> {switchStatus(serviceOrder.status)}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}

          <S.ContainerPagination>
            <TablePagination
              rowsLength={totalItems}
              rowsPerPage={searchLimit}
              rowsPage={currentPage}
              handlePageChange={(pageNumber, itemsPerPage) =>
                handlePageChange(pageNumber, itemsPerPage)
              }
            />
          </S.ContainerPagination>
        </S.Content>
      )}
    </>
  );
}
