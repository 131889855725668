import styled, { css } from 'styled-components';

export const Container = styled.div`
  flex: 1;
  margin-top: 25px;
`;

export const Title = styled.div`
  ${({ theme }) => css`
    font-family: ${theme.font.family};
    font-size: 12px;
    color: rgba(255, 255, 255, 0.87);
    font-weight: 400;
    padding-bottom: 15px;
  `}
`;

export const WrapperPhotos = styled.div`
  flex-direction: row;
`;

export const ImagePreview = styled.img`
  ${({ theme }) => css`
    width: 100px;
    height: 100px;
    background-color: ${theme.colors.text_extra_light};
    border-radius: 10px;
    margin-right: 10px;
  `}
`;
