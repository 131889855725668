import styled, { css } from "styled-components";
import { lighten } from "polished";

export const Container = styled.div`
  align-items: center;
`;

export const Top = styled.div`
  ${({ theme }) => css`
    margin-bottom: 30px;

    display: flex;
    align-items: center;
    justify-content: space-between;

    p {
      font-size: ${theme.font.sizes.large};
      color: ${theme.colors.title};
      font-weight: ${theme.font.bold};
    }
  `}
`;

export const BackButton = styled.button`
  ${({ theme }) => css`
    width: 35px;
    height: 35px;
    background-color: ${theme.colors.background_light};
    border-radius: 10px;

    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 25px;
  `}
`;

export const EditUnity = styled.button`
  ${({ theme }) => css`
    height: 40px;
    width: 40px;

    display: flex;
    align-items: center;
    transition: opacity 0.2s;
    border: 1px solid ${theme.colors.primary};
    border-radius: 15px;
    padding: 10px;

    &:hover {
      opacity: 0.7;
    }
    img {
      width: 16px;
      height: 16px;
    }
  `}
`;

export const Title = styled.p`
  ${({ theme }) => css`
    font-size: 18px;
    font-family: ${theme.font.bold};
    color: ${theme.colors.text_extra_light};
    letter-spacing: 0.4px;
    line-height: 20px;
    margin-bottom: 5px;
  `}
`;

export const SubTitle = styled.p`
  ${({ theme }) => css`
    font-size: 12px;
    font-family: ${theme.font.family};
    color: ${theme.colors.financial_title};
    letter-spacing: 0.26px;
    line-height: 16px;
  `}
`;

export const Separator = styled.div`
  ${({ theme }) => css`
    border-bottom-width: 1px;
    border-bottom-color: ${theme.colors.card_container};
  `}
`;

export const WrapperChoises = styled.div`
  display: flex;
  flex-direction: row;
`;

export const ButtonText = styled.p`
  ${({ theme }) => css`
    color: ${theme.colors.primary};
    font-size: 11px;
    font-family: ${theme.font.family};
  `}
`;

export const WrapperIcon = styled.div`
  justify-content: center;
  align-items: center;
  margin-top: 50px;
  margin-bottom: 50px;
  display: flex;

  img {
    width: 10vw;
    height: 10vh;
  }
`;

export const WrapperSpaceInfo = styled.div`
  margin-top: 15px;
  margin-bottom: 15px;
`;

export const CardEnvironmentInfo = styled.div`
  ${({ theme }) => css`
    flex: 1;
    min-height: 100px;
    width: 100%;
    padding: 10px;
    background: ${theme.colors.background_light};
    border-radius: 8px;
    margin-top: 15px;
    margin-bottom: 15px;
  `}
`;

export const TitleCard = styled.p`
  ${({ theme }) => css`
    font-size: 12px;
    font-family: ${theme.font.bold};
    color: ${theme.colors.text_extra_light};
    letter-spacing: 0.26px;
    line-height: 16px;
    margin-bottom: 8px;
    margin-top: 10px;
  `}
`;

export const CardInfo = styled.p`
  ${({ theme }) => css`
    font-size: 12px;
    font-family: ${theme.font.family};
    color: ${theme.colors.financial_text};
    letter-spacing: 0.26px;
    line-height: 16px;
    margin-bottom: 8px;
  `}
`;

export const CardInfoError = styled.p`
  ${({ theme }) => css`
    font-size: 12px;
    font-family: ${theme.font.family};
    color: #fa8b94;
    letter-spacing: 0.26px;
    line-height: 16px;
    margin-bottom: 8px;
  `}
`;

export const ViewTextRow = styled.div`
  display: flex;
  flex-direction: row;
`;

type InputProps = {
  marginTop?: number;
};

export const WrapperButton = styled.div<InputProps>`
  ${({ marginTop }) => css`
    margin-top: ${marginTop ? marginTop : 5}%;
  `}
`;

export const WrapperSpace = styled.div`
  height: 40%;
`;

export const WrapperCardEquipment = styled.div`
  ${({ theme }) => css`
    margin-bottom: 12px;
    background: ${theme.colors.background_light};
    border-radius: 8px;
    cursor: pointer;
    &:hover {
      background: ${lighten(0.07, theme.colors.menu_detail)};
    }
  `}
`;

export const WrapperInfoEquipament = styled.div`
  padding: 5px 20px;
  flex-direction: column;
  display: flex;
`;

export const WrapperList = styled.div`
  display: flex;
`;
export const ViewErrorButton = styled.div`
  display: flex;
  flex-direction: row;
`;

export const ViewMessage = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 22vh;
`;

export const MessageText = styled.p`
  ${({ theme }) => css`
    font-size: 16px;
    font-family: ${theme.font.family};
    color: ${theme.colors.financial_title};
    margin-bottom: 5px;
  `}
`;

export const Footer = styled.div`
  ${({ theme }) => css`
    margin-top: 15px;
    width: 100%;
    max-width: 602px;
    height: 60px;
    position: fixed;
    bottom: 20px;
  `}
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 10%;
`;
