import styled, { css } from "styled-components";

import { generateMedia } from "styled-media-query";

const customMedia = generateMedia({
  medium: "700px",
  small: "368px",
  xsmall: "294px",
});

type Props = {
  isActive: boolean;
};

export const Container = styled.div<Props>`
  ${({ theme, isActive }) => css`
    background-color: ${theme.colors.strong_background};
    overflow-x: hidden;
    display: flex;
    flex-direction: row;
    height: 100vh;
    justify-content: space-between;

    ${customMedia.lessThan("medium")`
      ${
        isActive &&
        css`
          padding-top: 70px;
        `
      }
    `}
  `}
`;

export const MobileWarning = styled.div`
  ${({ theme }) => css`
    display: none;
    width: 100%;
    height: 75px;
    background-color: ${theme.colors.menu_background};
    position: fixed;
    top: 0;
    justify-content: center;

    img {
      margin: 10px 10px 0 15px;
    }

    ${customMedia.lessThan("medium")`
      display: flex;
    `}
  `}
`;

export const MobileWrapper = styled.div`
  ${({ theme }) => css`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    div {
      margin-left: 30px;
      margin-right: 30px;
    }
  `}
`;

export const WarningTitle = styled.p`
  ${({ theme }) => css`
    font-size: 13px;
    letter-spacing: 0.26px;
    line-height: 16px;
    color: ${theme.colors.financial_title};
    font-weight: ${theme.font.bold};

    ${customMedia.lessThan("small")`
      font-size: 12px;
    `}

    ${customMedia.lessThan("xsmall")`
     display: inline-block;
     width: 100%;
    `}
  `}
`;

export const WarningSubtitle = styled.p`
  ${({ theme }) => css`
    font-size: 12px;
    letter-spacing: 0.26px;
    line-height: 16px;
    color: ${theme.colors.financial_title};
    font-weight: ${theme.font.normal};

    ${customMedia.greaterThan("xsmall")`
      font-size: 10px;
    `}

    ${customMedia.lessThan("small")`
      display: none;
    `}
  `}
`;

export const ButtonOpenApp = styled.a`
  ${({ theme }) => css`
    text-decoration: none;
    width: 96px;
    height: 45px;
    margin-right: 15px;

    font: ${theme.font.normal};
    font-size: 12px;
    letter-spacing: 0.26px;
    color: ${theme.colors.primary};
    opacity: 1;

    border: 1px solid ${theme.colors.primary};
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;

    ${customMedia.lessThan("medium")`
      margin-right: 25px;
    `}
  `}
`;

export const ProfizLogo = styled.div`
  margin-top: 30px;
  margin-left: 30px;
  height: 45px;

  ${customMedia.lessThan("medium")`
      display: none;
  `}
`;

export const EventCard = styled.div`
  ${({ theme }) => css`
    margin-top: 30px;
    width: 36%;
    max-height: 93vh;
    overflow-x: auto;
    background-color: ${theme.colors.menu_background};
    border-radius: 15px;

    ${customMedia.lessThan("medium")`
      width: 100%;
      margin-top: 0px;
      background-color: #121212;
    `}
  `}
`;

export const CardItem = styled.div`
  ${({ theme }) => css`
    margin-bottom: 8px;
    border-radius: 8px;
    padding: 15px;
    flex-direction: column;
  `};
`;

export const WrapperImg = styled.div`
  img {
    max-height: 25vh;
    min-width: 32vw;
    border-radius: 8px;
    object-fit: fill;
  }
  align-items: center;
  justify-content: center;
  display: flex;

  ${customMedia.lessThan("medium")`
    img {
    max-height: 18vh;
    min-width: 90vw;
    border-radius: 8px;
    object-fit: fill;
  }
  `}
`;

export const Header = styled.div`
  ${({ theme }) => css`
    border-bottom: 1px solid ${theme.colors.text};
    padding-bottom: 25px;
  `}
`;

export const WrapperHeader = styled.div`
  ${({ theme }) => css`
    display: flex;
    margin-left: 10px;
    flex-direction: row;
    padding-top: 15px;
  `};
`;

export const WrapperDataIconCard = styled.div`
  ${({ theme }) => css`
    h5 {
      font-size: 18px;
      font-weight: ${theme.font.normal};
      color: ${theme.colors.title};
      text-align: center;
      letter-spacing: 0.4px;
      line-height: 20px;
    }

    p {
      margin-top: 5px;
      color: ${theme.colors.text};
      font-size: ${theme.font.sizes.xsmall};
      text-align: center;
      letter-spacing: 0.26px;
      line-height: 16px;
    }
  `};
`;

export const TitleWrapper = styled.div`
  ${({ theme }) => css`
    margin-top: 3px;
    margin-left: 20px;

    p {
      margin-top: 6px;
      color: ${theme.colors.financial_text};
      font-size: 12px;
      text-align: left;
      letter-spacing: 0.26px;
      line-height: 16px;
      opacity: 1;
    }
  `};
`;

export const HeaderTitle = styled.div`
  ${({ theme }) => css`
    font-size: 17px;
    font-weight: ${theme.font.normal};
    letter-spacing: 0.4px;
    line-height: 16px;
    text-align: left;
    color: ${theme.colors.financial_title};
  `};
`;

export const WrapperDescription = styled.div`
  ${({ theme }) => css`
    margin-top: 15px;
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 65%;
    p {
      white-space: pre-wrap;
      margin-top: 5px;
      color: ${theme.colors.text};
      font-size: ${theme.font.sizes.xsmall};
      text-align: left;
      letter-spacing: 0.26px;
      line-height: 16px;
    }
  `};
`;

export const WrapperInfoMid = styled.div`
  ${({ theme }) => css`
    display: flex;
    justify-content: space-around;
    flex-direction: column;
    padding-top: 15px;
    padding-bottom: 25px;
    border-bottom: 1px solid ${theme.colors.text};

    h5 {
      font-size: 14px;
      font-weight: ${theme.font.normal};
      color: ${theme.colors.financial_title};
      font-weight: ${theme.font.bold};
      text-align: left;
      letter-spacing: 0.31px;
      line-height: 16px;
    }

    h6 {
      padding-top: 15px;
      color: ${theme.colors.financial_title};
      font-size: ${theme.font.sizes.xsmall};
      font-weight: ${theme.font.normal};
      text-align: left;
      letter-spacing: 0.26px;
      line-height: 20px;
    }

    p {
      padding-top: 5px;
      color: ${theme.colors.financial_text};
      font-size: ${theme.font.sizes.xsmall};
      font-weight: ${theme.font.normal};
      text-align: left;
      letter-spacing: 0.26px;
      line-height: 16px;
    }

    a {
      overflow-wrap: break-word;
      word-wrap: break-word;
      word-break: break-word;
      padding-top: 5px;
      color: ${theme.colors.primary_light};
      text-decoration: underline;
      font-size: ${theme.font.sizes.xsmall};
      font-weight: ${theme.font.normal};
      text-align: left;
      letter-spacing: 0.26px;
      line-height: 16px;
    }
  `};
`;

export const WrapperInfoContact = styled.div`
  ${({ theme }) => css`
    display: flex;
    justify-content: space-around;
    flex-direction: column;
    padding-top: 15px;
    padding-bottom: 25px;
    h5 {
      font-size: 14px;
      font-weight: ${theme.font.normal};
      color: ${theme.colors.financial_title};
      font-weight: ${theme.font.bold};
      text-align: left;
      letter-spacing: 0.31px;
      line-height: 16px;
    }

    h6 {
      padding-top: 15px;
      color: ${theme.colors.financial_title};
      font-size: ${theme.font.sizes.xsmall};
      font-weight: ${theme.font.normal};
      text-align: left;
      letter-spacing: 0.26px;
      line-height: 16px;
    }

    p {
      padding-top: 5px;
      color: ${theme.colors.financial_text};
      font-size: ${theme.font.sizes.xsmall};
      font-weight: ${theme.font.normal};
      text-align: left;
      letter-spacing: 0.26px;
      line-height: 16px;
    }
  `};
`;

export const ContainerIconContact = styled.div`
  display: flex;
  flex-direction: row;
  img {
    height: 36px;
    weight: 36px;
    margin-top: 15px;
  }
`;

export const ContainerTextContact = styled.div`
  padding-left: 15px;
`;

export const Button = styled.a`
  ${({ theme }) => css`
    text-decoration: none;
    margin-top: 30px;
    margin-right: 30px;
    background-color: ${theme.colors.primary};
    width: 180px;
    height: 45px;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    font: ${theme.font.normal};
    font-size: 12px;
    letter-spacing: 0.26px;
    color: #212121;
    opacity: 1;

    ${customMedia.lessThan("medium")`
      display: none;
    `}
  `}
`;

export const ShareWrapper = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 12px;
  `};
`;

export const TextButton = styled.p`
  ${({ theme }) => css`
    color: ${theme.colors.lilac_light};
    font-size: ${theme.font.sizes.xsmall};
    text-align: left;
    letter-spacing: 0.26px;
    line-height: 16px;
  `};
`;

export const ToolTipBox = styled.div`
  ${({ theme }) => css`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 120px;
    background-color: ${theme.colors.strong_background};
    border: 1px solid ${theme.colors.primary};
    margin-top: 12px;
    padding: 8px;
    position: relative;
    border-radius: 4px;

    &::after {
      content: "";
      width: 0px;
      height: 0px;
      display: block;
      border-left: 10px solid transparent;
      border-right: 10px solid transparent;
      border-bottom: 10px solid transparent;
      border-top: 10px solid ${theme.colors.primary};
      position: absolute;
      bottom: 38px;
      left: 10px;
      transform: rotate(180deg);
    }
  `};
`;

export const TextToolTip = styled.p`
  ${({ theme }) => css`
    color: ${theme.colors.lilac_light};
    font-size: ${theme.font.sizes.xsmall};
    text-align: left;
    letter-spacing: 0.26px;
    line-height: 16px;
  `};
`;
