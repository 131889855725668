import styled, { css } from "styled-components";

type ToggleProps = {
  isChecked: boolean;
};

type EditableProps = {
  editable: boolean;
};

export const Container = styled.div`
  ${({ theme }) => css`
    width: 100%;
    padding: 15px 10px;
    border-radius: 10px;
    background-color: ${theme.colors.menu_detail};
  `}
`;

export const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const Title = styled.p`
  ${({ theme }) => css`
    font-family: ${theme.font.normal};
    font-size: 12px;
    letter-spacing: 0.26px;
    line-height: 20px;
    color: ${theme.colors.financial_title};
  `}
`;

export const CommentsDetail = styled.div`
  ${({ theme }) => css`
    width: 100%;
    min-height: 120px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border-radius: 10px;
    background-color: #2f2f2f;
    margin-top: 15px;
  `}
`;

export const WrapperComments = styled.div`
  width: 80%;
  padding: 15px 10px;
  margin-right: 10px;
`;

export const CommentsText = styled.p<EditableProps>`
  ${({ theme, editable }) => css`
    font-family: ${theme.font.normal};
    font-size: 12px;
    letter-spacing: 0.26px;
    line-height: 20px;
    color: ${editable ? theme.colors.financial_title : theme.colors.text};
    margin-bottom: 15px;
  `}
`;

export const WrapperButtons = styled.div`
  ${({ theme }) => css`
    width: 17%;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    background-color: ${theme.colors.background_light};

    display: flex;
    flex-direction: column;
    justify-content: space-between;
  `}
`;

export const ObservationButton = styled.button``;

export const ObservationText = styled.p`
  ${({ theme }) => css`
    font-family: ${theme.font.normal};
    font-size: 12px;
    letter-spacing: 0.26px;
    line-height: 16px;
    color: ${theme.colors.financial_text};
    text-decoration: underline;
    text-decoration-color: ${theme.colors.financial_text};
  `}
`;

export const ToggleButton = styled.button`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    width: 55px;
    height: 30px;
    border-radius: 15px;
    background-color: ${theme.colors.menu_detail_light};
    padding: 5px;
  `}
`;

export const ToggleBall = styled.div<ToggleProps>`
  ${({ theme, isChecked }) => css`
    width: 20px;
    height: 20px;
    border-radius: 10px;
    background-color: ${isChecked
      ? theme.colors.primary
      : theme.colors.toggle_ball_inactive};
    align-self: ${isChecked ? "flex-end" : "flex-start"};
  `}
`;

export const WrapperIcon = styled.div`
  width: 36px;
  align-items: center;
`;

export const CheckIconImage = styled.img`
  height: 40px;
  width: 40px;
`;

export const WrapperTextArea = styled.div`
  margin-top: 15px;
`;

export const CancelButton = styled.button`
  ${({ theme }) => css`
    color: ${theme.colors.primary};
    font-size: ${theme.font.sizes.xsmall};
    text-decoration: underline;
    text-decoration-color: ${theme.colors.primary};
    margin-bottom: 20px;
  `}
`;
