import styled, { css, keyframes } from "styled-components";
import { lighten, shade } from "polished";

type ModalProps = {
  isVisible: boolean;
};

const ModalScale = keyframes`
  from {
    transform: scale(0.5);
    opacity: 0.4;
  }
  to {
    transform: scale(1);
    opacity: 1;
  }
`;

export const ContainerOverlay = styled.aside<ModalProps>`
  ${({ theme, isVisible }) => css`
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;

    display: ${isVisible ? "flex" : "none"};
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: ${theme.layers.alwaysOnTop};
  `}
`;

export const BoxModal = styled.div`
  ${({ theme }) => css`
    width: 20%;
    min-width: 380px;
    max-width: 460px;
    padding: 0 16px 16px;
    border-radius: 10px;

    display: flex;
    flex-direction: column;

    background-color: ${theme.colors.background_modal};
    animation: ${ModalScale} 0.3s ease-in-out;
  `}
`;

export const Header = styled.div`
  width: 100%;
  height: 56px;
  margin-bottom: 10px;
  display: flex;
  justify-content: flex-end;
  align-items: center;

  & > button {
    padding: 2px;
    border-radius: 6px;

    display: flex;
    justify-content: center;
    align-items: center;
    transition: background 0.2s;

    &:hover {
      background: ${({ theme }) => lighten(0.1, theme.colors.background_modal)};
    }
  }
`;

export const Main = styled.div`
  width: 100%;
`;

export const Title = styled.h4`
  ${({ theme }) => css`
    font-size: 20px;
    color: ${theme.colors.title};
    text-align: center;
    margin-bottom: 12px;
  `}
`;

export const Description = styled.p`
  ${({ theme }) => css`
    font-size: ${theme.font.sizes.medium};
    color: ${theme.colors.text};
    margin: 0 8px;
    text-align: left;
    color: ${theme.colors.title};
  `}
`;

export const Footer = styled.footer`
  width: 100%;
  padding-top: 12px;

  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-direction: column;

  > div {
    display: flex;
    flex-direction: column;
  }

  label {
    display: flex;
    align-items: center;

    & + label {
      margin-top: 10px;
    }
  }
`;

export const WrapperButtons = styled.div`
  ${({ theme }) => css`
    width: 100%;
    display: flex;
    justify-content: center;

    color: ${theme.colors.title};

    input {
      margin-right: 10px;
      margin-left: 10px;
      display: block;
    }
  `}
`;

export const ButtonConfirm = styled.button`
  ${({ theme }) => css`
    width: 100px;
    height: 40px;
    color: ${theme.colors.background};
    font-size: ${theme.font.sizes.small};

    display: flex;
    justify-content: center;
    align-items: center;

    background-color: ${theme.colors.primary};
    border-radius: 8px;
    transition: background 0.2s;

    &:hover {
      background: ${shade(0.2, theme.colors.primary)};
    }
  `}
`;

export const ButtonCancel = styled.button`
  ${({ theme }) => css`
    width: 100px;
    height: 40px;
    color: ${theme.colors.title};
    font-size: ${theme.font.sizes.small};

    display: flex;
    justify-content: center;
    align-items: center;

    background-color: ${lighten(0.1, theme.colors.background_modal)};
    border-radius: 8px;
    transition: opacity 0.2s;

    &:hover {
      opacity: 0.7;
    }
  `}
`;

export const CheckboxWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;

  & + & {
    margin-top: 10px;
  }
`;

type CheckBoxProps = {
  checked: boolean;
};

export const CheckBox = styled.div<CheckBoxProps>`
  ${({ theme, checked }) => css`
    height: 16px;
    width: 15px;
    border: 1px solid #707070;
    margin-right: 10px;
    border-radius: 5px;
    background-color: transparent;

    margin-left: 10px;

    display: flex;
    align-items: center;
    justify-content: center;

    ${checked &&
    css`
      & > div {
        height: 6.4px;
        width: 7px;
        border-radius: 1px;
        background-color: ${theme.colors.primary};
      }
    `}
  `}
`;

export const CheckboxText = styled.div`
  ${({ theme }) => css`
    color: ${theme.colors.financial_text};
    font-family: ${theme.font.family};
    font-size: 12px;
    user-select: none;
  `}
`;
