import React, { useEffect, useState } from "react";

import { Dropdown, DropdownOption } from "components/DropDown";
import { InputAddNewCategory } from "components/InputAddNewCategory";
import { DisabledDropDown } from "components/DisabledDropDown";

import {
  useServiceFormTree,
  EquipmentsProps,
  CategoriesTreeProps,
} from "hooks/serviceFormTree";
import { useServiceDropDown } from "hooks/servicesDropDown";
import { useToast } from "hooks/toast";
import { useAccount } from "hooks/permission/account";
import { useHasPermission } from "hooks/permission/useHasPermission";

import { api } from "services/api";

type EquipmentProps = {
  hasErrorOnEquipment: string;
  moduleHash?: string;
};

export function Equipments({
  hasErrorOnEquipment,
  moduleHash,
}: EquipmentProps) {
  const { addToast } = useToast();
  const { whoami } = useAccount();
  const { permission } = useHasPermission({
    actionHash: "view",
    moduleHash: "equipment",
  });

  const { categoriesTree, handleSetCategoriesTree } = useServiceFormTree();
  const {
    selectedCategoryId,
    selectedService,
    selectedEquipment,
    handleEquipment,
    handleEquipmentType,
    handleCapacity,
  } = useServiceDropDown();

  const [loadingButton, setLoadingButton] = useState(false);
  const [newEquipment, setNewEquipment] = useState("");
  const [equipments, setEquipments] = useState<EquipmentsProps[]>([]);
  const [hasError, setHasError] = useState("");

  useEffect(() => {
    const currentCategory = categoriesTree.find(
      (category) => category.id === selectedCategoryId
    );

    if (!currentCategory) return;

    const currentService = currentCategory.services.find(
      (service) => service.id === Number(selectedService.id)
    );

    if (!currentService) return;

    setEquipments(currentService.equipments);
  }, [categoriesTree, selectedCategoryId, selectedService.id]);

  useEffect(() => {
    setHasError(hasErrorOnEquipment);
  }, [hasErrorOnEquipment]);

  function handleNewEquipment(text: string) {
    setNewEquipment(text.trimStart());
  }

  function addNewEquipmentOnTree(
    currentCategory: CategoriesTreeProps,
    newEquipmentData: EquipmentsProps
  ) {
    return currentCategory.services.map((service) => {
      if (service.id === Number(selectedService.id)) {
        return {
          ...service,
          equipments: [...service.equipments, newEquipmentData],
        };
      }
      return service;
    });
  }

  async function handleSubmit() {
    if (!newEquipment) {
      addToast({
        title: "Ops",
        description: "Preencha com alguma informação antes de prosseguir",
        type: "error",
      });
      return;
    }

    try {
      setLoadingButton(true);
      const response = await api.post("services/equipment", {
        categoryID: selectedCategoryId,
        serviceID: selectedService.id,
        name: newEquipment,
        accountId: whoami?.id
      });

      const { id, name } = response.data;

      const newEquipmentData: EquipmentsProps = {
        id,
        name,
        types: [],
        capacities: [],
        default: "false",
      };

      const formattedCategories = categoriesTree.map((category) => {
        if (category.id === selectedCategoryId) {
          return {
            ...category,
            services: addNewEquipmentOnTree(category, newEquipmentData),
          };
        }

        return category;
      });

      handleSetCategoriesTree(formattedCategories);
      setNewEquipment("");

      addToast({
        title: "Sucesso",
        description: "Equipamento cadastrado com sucesso!",
        type: "success",
      });
    } catch (err) {
      addToast({
        title: "Ops",
        description: "Ocorreu um erro ao cadastrar",
        type: "error",
      });
    } finally {
      setLoadingButton(false);
    }
  }

  function handleSelectedEquipment({ id, name }: DropdownOption) {
    handleEquipment({ id, name });
    setHasError("");

    handleEquipmentType({} as DropdownOption);
    handleCapacity({} as DropdownOption);
  }

  function handleWarning() {
    if (!selectedCategoryId) {
      addToast({
        title: "Atenção!",
        description: "Selecione uma categoria",
        type: "info",
      });
      return;
    }

    if (!selectedService.id) {
      addToast({
        title: "Atenção!",
        description: "Selecione um serviço",
        type: "info",
      });
    }
  }

  function handleClearEquipment() {
    handleEquipment({ id: 0, name: "" } as DropdownOption);
    handleEquipmentType({ id: 0, name: "" } as DropdownOption);
    handleCapacity({ id: 0, name: "" } as DropdownOption);
  }

  return (
    <>
      {selectedCategoryId > 0 && selectedService.id > 0 ? (
        <Dropdown
          label="Equipamento (opcional)"
          placeholder="Selecione o equipamento"
          options={equipments}
          hasError={hasError}
          hasInput
          type={"equipment"}
          // eslint-disable-next-line react/jsx-no-bind
          onClickedValue={handleSelectedEquipment}
          categoryIdSelected={selectedEquipment.id}
          handleClear={handleClearEquipment}
          clearDropDownItem
          moduleHashValue={moduleHash}
          actionView={permission}
        >
          <InputAddNewCategory
            isLoadingButton={loadingButton}
            value={newEquipment}
            // eslint-disable-next-line react/jsx-no-bind
            handleChangeNewCategory={handleNewEquipment}
            // eslint-disable-next-line react/jsx-no-bind
            handleSave={handleSubmit}
          />
        </Dropdown>
      ) : (
        <DisabledDropDown
          label="Equipamento (opcional)"
          title="Selecione o equipamento"
          onClick={() => handleWarning()}
        />
      )}
    </>
  );
}
