import React, { createContext, useState, useContext } from "react";
import { ProviderProps } from "hooks";

interface RegisterPhotosModalContextData {
  isOpenRegisterPhotosModal: boolean;
  setIsOpenRegisterPhotosModal: React.Dispatch<React.SetStateAction<boolean>>;
  handleToggleRegisterPhotosModal: () => void;
}

const RegisterPhotosModalContext =
  createContext<RegisterPhotosModalContextData>(
    {} as RegisterPhotosModalContextData
  );

export function useRegisterPhotosModal(): RegisterPhotosModalContextData {
  const context = useContext(RegisterPhotosModalContext);

  if (!context) {
    throw Error(
      "useRegisterPhotosModal must be used within an RegisterPhotosModalProvider"
    );
  }

  return context;
}

export const RegisterPhotosModalProvider = ({ children }: ProviderProps) => {
  const [isOpenRegisterPhotosModal, setIsOpenRegisterPhotosModal] =
    useState(false);

  function handleToggleRegisterPhotosModal() {
    setIsOpenRegisterPhotosModal(!isOpenRegisterPhotosModal);
  }

  return (
    <RegisterPhotosModalContext.Provider
      value={{
        isOpenRegisterPhotosModal,
        setIsOpenRegisterPhotosModal,
        handleToggleRegisterPhotosModal,
      }}
    >
      {children}
    </RegisterPhotosModalContext.Provider>
  );
};
