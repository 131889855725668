import { useHistory, useParams } from "react-router";
import * as S from "./styles";
import { useEffect, useState } from "react";
import axios from "axios";
import { useToast } from "hooks/toast";
import { LoadingProfiz } from "components/LoadingProfiz";
import apiv2 from "services/apiv2";
import { ArrowButton } from "components/ArrowButton";
import { useServiceOrder } from "hooks/serviceOrder";
import { Button } from "components/Button";

type RouteParams = {
  id: string;
  idPmoc: string;
  year: string;
  month: string;
};

type DictOption = {
  [key: string]: string;
};

export function ArchivedOSPage() {
  const { id, idPmoc, year, month } = useParams<RouteParams>();
  const { addToast } = useToast();
  const [isLoading, setIsLoading] = useState(false);
  const history = useHistory();

  const { selectedServiceOrder, handleSetSelectedServiceOrder } =
    useServiceOrder();

  async function setData() {
    try {
      setIsLoading(true);
      const response = await apiv2.get(`/budgets/service-order/${id}`);
      handleSetSelectedServiceOrder(response.data);
    } catch (error) {
      addToast({
        title: "Erro",
        description:
          axios.isAxiosError(error) && error.response?.data.error
            ? error.response?.data.error
            : "Não foi possível carregar as informações da ordem de serviço.",
        type: "error",
      });
    } finally {
      setIsLoading(false);
    }
  }

  useEffect(() => {
    setData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function statusOrderService(status: string) {
    const dictOS: DictOption = {
      pending: "Não agendado",
      scheduled: "Agendado",
      concluded: "Concluído",
      archived: "Arquivado",
    };
    return dictOS[status.toLowerCase()];
  }

  async function handleUnarchiveOS() {
    try {
      setIsLoading(true);

      apiv2.put(`/budgets/service-order/${id}/dearchive`, {
        originUpdate: "web",
      });

      addToast({
        type: "success",
        title: "Ordem de serviço desarquivada.",
        description: "",
      });

      history.push(
        `/pmoc/execution/order-service/unscheduled/${id}/${idPmoc}/${year}/${month}`
      );
    } catch (error) {
      addToast({
        title: "Erro",
        description:
          axios.isAxiosError(error) && error.response?.data.error
            ? error.response?.data.error
            : "Não foi possível arquivar a ordem de serviço.",
        type: "error",
      });
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <>
      {isLoading && <LoadingProfiz isVisible={isLoading} />}
      <S.Container>
        <S.Wrapper>
          <ArrowButton
            handleFunction={() => {
              history.push(
                `/pmoc/execution/order-services-list/${idPmoc}/${year}/${month}?statusList=3`
              );
            }}
          />
        </S.Wrapper>
        <header>
          {selectedServiceOrder.services &&
            selectedServiceOrder.services.map((element) => {
              return (
                <S.Title key={element.idBudgetService}>
                  {element.service.service.name}
                </S.Title>
              );
            })}
        </header>
        <S.Wrapper>
          {selectedServiceOrder.environment &&
            selectedServiceOrder.equipment && (
              <S.Subtitle>
                Esta ordem de serviço está vinculada ao equipamento
                <br />
                <strong>
                  {selectedServiceOrder.equipment.tagEquipment}
                </strong>{" "}
                do ambiente{" "}
                <strong>{selectedServiceOrder.environment.name}</strong>.
              </S.Subtitle>
            )}

          <S.WrapperStatusOS>
            <span>Status: </span>

            <div>
              <S.BadgeStatus />
              <span>Arquivado</span>
            </div>
          </S.WrapperStatusOS>
        </S.Wrapper>
        <div className="content">
          {selectedServiceOrder.environment && (
            <S.Wrapper>
              <S.CardInfo>
                <p className="title">{selectedServiceOrder.environment.name}</p>
              </S.CardInfo>
            </S.Wrapper>
          )}

          {selectedServiceOrder.equipment && (
            <S.Wrapper>
              <S.CardInfo>
                <p className="title">
                  {selectedServiceOrder.equipment.tagEquipment}
                </p>
              </S.CardInfo>
            </S.Wrapper>
          )}

          {selectedServiceOrder.services &&
            selectedServiceOrder.services.map((element) => {
              return (
                <S.Wrapper key={element.idBudgetService}>
                  <S.OSCard>
                    <div className="text-container">
                      <p className="title">
                        <strong>{element.service.service.name}</strong>
                      </p>
                      <p className="text-info">
                        Cliente: {selectedServiceOrder.client.name}{" "}
                      </p>
                      <p className="text-info">
                        Periodicidade:{" "}
                        {!!element.service.frequency &&
                          element.service.frequency[0].toUpperCase() +
                            element.service.frequency.substring(1)}
                      </p>
                    </div>
                    <div className="container-ball">
                      <div className="element-ball" />
                      <p className="text-status">
                        {statusOrderService(element.status)}
                      </p>
                    </div>
                  </S.OSCard>
                </S.Wrapper>
              );
            })}

          <S.WrapperButtons>
            <Button disabled>Agendar serviço</Button>

            <Button disabled>Iniciar checklist do serviço</Button>

            <Button typeButton="delete" onClick={handleUnarchiveOS}>
              Desarquivar ordem de serviço
            </Button>
          </S.WrapperButtons>
        </div>
      </S.Container>
    </>
  );
}
