import styled, { css } from "styled-components";

type InputProps = {
  marginTop?: number;
  marginBottom?: number;
};

type DualInputProps = {
  marginTop?: number;
};

type ButtonModalProps = {
  isSelected?: boolean;
};

export const Content = styled.div`
  padding: 0 20px;
  flex: 1;
`;

export const Title = styled.p`
  ${({ theme }) => css`
    font-size: 18px;
    font-family: ${theme.font.bold};
    color: ${theme.colors.text_extra_light};
    letter-spacing: 0.4px;
    line-height: 20px;
    margin-bottom: 5px;
  `}
`;

export const Wrapper = styled.div`
  margin-bottom: 24px;
`;

export const wrapperText = styled.div`
  justify-content: center;
`;

export const WrapperDualInput = styled.div<DualInputProps>`
  ${({ marginTop = 0 }) => css`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-top: ${marginTop}px;
  `}
`;

export const WrapperInputSmall = styled.div`
  padding: 4px;
  width: 48%;
`;

export const WrapperInput = styled.div<InputProps>`
  ${({ marginTop, marginBottom }) => css`
    margin-top: ${marginTop ? marginTop : 15}px;
    margin-bottom: ${marginBottom ? marginBottom : 15}px;
  `}
`;

export const Container = styled.div`
  align-items: center;
`;

export const Top = styled.div`
  ${({ theme }) => css`
    margin-bottom: 30px;

    display: flex;
    align-items: center;

    p {
      font-size: ${theme.font.sizes.large};
      color: ${theme.colors.title};
      font-weight: ${theme.font.bold};
    }
  `}
`;

export const BackButton = styled.button`
  ${({ theme }) => css`
    width: 35px;
    height: 35px;
    background-color: ${theme.colors.background_light};
    border-radius: 10px;

    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 25px;
  `}
`;

export const ButtonModal = styled.div<ButtonModalProps>`
  ${({ theme, isSelected = false }) => css`
    min-height: 40px;
    background: ${isSelected
      ? theme.colors.primary_light
      : theme.colors.background_modal};
    padding: 15px;
    margin-bottom: 3px;
    border-radius: 8px;

    cursor: pointer;
  `};
`;

export const TitleViewModal = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const wrapperTextModal = styled.div`
  padding-top: 5px;
  padding-left: 10px;
  max-width: 80%;
`;

export const wrapperSubTextModal = styled.div`
  padding-top: 10px;
  padding-bottom: 10px;
`;

export const ViewListItem = styled.div`
  ${({ theme }) => css`
    display: flex;
    min-height: 40px;
    background: ${theme.colors.background_modal};
    padding: 15px;
    margin-bottom: 3px;
    border-radius: 8px;
    flex-direction: row;
    align-items: center;
    width: 85%;
  `}
`;

export const ViewListButtom = styled.div`
  ${({ theme }) => css`
    display: flex;
    min-height: 40px;
    background: ${theme.colors.background_modal};
    padding: 15px;
    margin-bottom: 3px;
    border-radius: 8px;
    align-items: center;
    max-width: 25%;
    justify-content: center;

    img {
      width: 15px;
      height: 15px;
    }

    cursor: pointer;
  `}
`;

export const TitleChecklist = styled.p`
  ${({ theme }) => css`
    font-size: 12px;
    font-family: ${theme.font.bold};
    color: ${theme.colors.text_extra_light};
    letter-spacing: 0.4px;
    line-height: 20px;
    padding: 0 10px;
    max-width: 75%;
  `}
`;

export const SubTitle = styled.p<ButtonModalProps>`
  ${({ theme, isSelected = false }) => css`
    font-size: 12px;
    font-family: ${theme.font.family};
    color: ${isSelected ? theme.colors.detail : theme.colors.financial_title};
    letter-spacing: 0.26px;
    line-height: 16px;
  `}
`;

export const ViewImage = styled.div`
  flex-direction: row;
`;

export const Image = styled.img`
  width: 100px;
  height: 100px;
  border-radius: 5px;
  margin-right: 15px;
`;

export const CloseModal = styled.div`
  height: 100%;
  width: 100%;
`;

export const ContentModal = styled.div`
  ${({ theme }) => css`
    width: 100%;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    padding-top: 25px;
    background: ${theme.colors.background};
    padding-left: 20px;
    padding-right: 20px;
  `}
`;

export const ContainerModal = styled.div`
  ${({ theme }) => css`
    display: flex;
    padding: 10px;
    background-color: ${theme.colors.background};
    max-width: 600px;
    width: 600px;
    min-width: 500px;
    flex-direction: column;
    max-height: 50%;
    overflow: auto;
    padding: 30px;
    border-top-right-radius: 12px;
    border-top-left-radius: 12px;
  `}
`;

type ModalProps = {
  isVisible: boolean;
};

export const ContainerOverlay = styled.aside<ModalProps>`
  ${({ theme, isVisible }) => css`
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;

    display: ${isVisible ? "flex" : "none"};
    justify-content: flex-end;
    align-items: flex-end;
    padding-bottom: 1px;
    padding-right: 70px;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: ${theme.layers.alwaysOnTop};
  `}
`;

export const ContainerModalView = styled.div`
  ${({ theme }) => css`
    background: rgba(0, 0, 0, 0.4);
    justify-content: flex-end;
  `}
`;

export const ContainerModalPeriod = styled.div`
  ${({ theme }) => css`
    display: flex;
    padding: 10px;
    background-color: ${theme.colors.background};
    max-width: 600px;
    width: 600px;
    min-width: 500px;
    flex-direction: column;
    max-height: 60%;
    overflow: auto;
    padding: 30px;
    border-top-right-radius: 12px;
    border-top-left-radius: 12px;
  `}
`;

export const WrapperTagButton = styled.div`
  display:flex;
  flex-direction:row;
  justify-content: space-between;
    margin-bottom: 20px;

`;

export const ContainerTagButton = styled.div`
display:flex; 
width:48%;
`;

export const MessageTag = styled.p`
  ${({ theme }) => css`
    font-size: 12px;
    color: ${theme.colors.title};
    letter-spacing: 0.4px;
    line-height: 15px;
    margin-bottom:5px;
  `}
`;