import styled, { css } from 'styled-components';


export const Container = styled.div`
  align-items: center;
  height:100%;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom:12%;
`;

export const Top = styled.div`
  ${({ theme }) => css`
    display: flex;
    margin-bottom: 30px;
    flex-direction:column;
    p {
      margin-top: 10px;
      font-size: 18px;
      font-family: ${theme.font.bold};
      color: ${theme.colors.text_extra_light};
      letter-spacing: 0.4px;
      line-height: 20px;
      margin-bottom: -15px;
    }
  `}
`;

export const BackButton = styled.button`
  ${({ theme }) => css`
    width: 35px;
    height: 35px;
    background-color: ${theme.colors.background_light};
    border-radius: 10px;

    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 25px;
  `}
`;

export const Title = styled.p`
  ${({ theme }) => css`
    font-size: 18px;
    font-family: ${theme.font.bold};
    color: ${theme.colors.text_extra_light};
    letter-spacing: 0.4px;
    line-height: 20px;
    margin-bottom: 5px;
  `}
`;

export const WrapperButton = styled.div`
  ${({ theme }) => css`
    margin-top:20px;
    display:flex;
    align-items:center;
    flex-direction:column;
    p {
      font-size: 12px;
      color: ${theme.colors.primary};
      letter-spacing: 0.4px;
      line-height: 20px;
    }
  `}
`;


export const Footer = styled.div`
  ${({ theme }) => css`
  width: 100%;
  max-width: 602px;
  height: 60px;
  position: fixed;
  bottom: 20px;
    p {
      font-size: 12px;
      color: ${theme.colors.primary};
      letter-spacing: 0.4px;
      line-height: 20px;
      text-align:center;
      font-weight: bold;
    }
  `}
`;