import styled, { css } from "styled-components";

export const Container = styled.div`
  flex: 1;
`;

export const Label = styled.span`
  ${({ theme }) => css`
    font-size: ${theme.font.sizes.xsmall};
    color: ${theme.colors.title};
  `};
`;

export const Wrapper = styled.div`
  margin-top: 14px;
`;

export const WrapperCheckbox = styled.div`
  width: 70%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 10px;
`;

export const ErrorText = styled.div`
    font-size: 12px;
    color: #FA8B94;
    padding-top: 5px;
`

export const CardServiceOrderSelected = styled.div`
  ${({ theme }) => css`
    min-height: 80px;
    display: flex;
    margin-top: 14px;
    border-radius: 10px;
    background-color: ${theme.colors.background_light};

    & > div:first-of-type {
      width: 90%;
      padding: 18px 14px;
      border-top-left-radius: 10px;
      border-bottom-left-radius: 10px;
    }

    & > div:last-of-type {
      min-width: 47px;
      width: 15%;
      border-top-right-radius: 10px;
      border-bottom-right-radius: 10px;
      background-color: ${theme.colors.background_light};

      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
  `};
`;

export const TitleCard = styled.h5`
  ${({ theme }) => css`
    font-size: ${theme.font.sizes.small};
    color: ${theme.colors.title};
    margin-bottom: 8px;
  `};
`;

export const CardTextDetail = styled.span`
  ${({ theme }) => css`
    display: block;
    font-size: ${theme.font.sizes.small};
    color: ${theme.colors.text};
    margin-bottom: 6px;
  `};
`;
