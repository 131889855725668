import { ReactNode } from "react";
import * as S from "./styles";

type RadioButtonProps = {
  children: ReactNode;
};

export function RadioButtonContainer({ children }: RadioButtonProps) {
  return <S.Container>{children}</S.Container>;
}
