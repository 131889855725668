import React from "react";

import RefuseIcon from "assets/icons/permission/leave.svg";
import { useLocation } from "react-router";
import { AccountData } from "dtos/permission/AnswerInviteDTO";

import * as S from "./styles";

type RouteState = {
  accountInfo: AccountData;
};

export function RefuseInvitePage() {
  const location = useLocation<RouteState>();

  return (
    <S.Container>
      <div className="inviteDetail">
        <p className="title">Convite recusado</p>

        <div className="image">
          <img
            src={RefuseIcon}
            alt="Icone recusar"
            height={window.screen.height * 0.2}
          />
        </div>

        <p className="subtitle">
          Você recusou o convite de{" "}
          <strong>{location.state.accountInfo.companyName}</strong> para fazer
          da equipe.
        </p>
      </div>
    </S.Container>
  );
}
