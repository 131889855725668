/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";

import { DetailEquipmentDTO, EnvironmentDTO } from "dtos/PmocEnvironmentDTO";

import ToolSvg from "assets/icons/tools.svg";
import Download from "assets/icons/download-button.svg";

import { ArrowButton } from "components/ArrowButton";
import { Button } from "components/Button";
import { AlertModal } from "components/AlertModal";

import addMaskDateAndHour from "utils/addMaskDateAndHour";
import { useToast } from "hooks/toast";
import { useServiceOrder } from "hooks/serviceOrder";

import apiv2 from "services/apiv2";

import { DetailEquipment } from "./detailEquipment";
import { DetailEnvironment } from "./detailEnvironment";
import { SchedulePmoc } from "./schedulePmoc";

import { usePmocTabIndex } from "hooks/pmoc/tabIndex";
import { useResumePmoc } from "hooks/resumePmoc";

import * as S from "./styles";
import { ChecklistDetails } from "components/ChecklistDetails";
import { LoadingProfiz } from "components/LoadingProfiz";
import { ShowWhenHavePermission } from "components/Permission";

const serviceStatus = {
  pending: () => "Não agendado",
  scheduled: (scheduleDate: string) =>
    `Agendado para ${addMaskDateAndHour(scheduleDate)}`,
  concluded: () => "Concluído",
};

export function ServiceOrderDetail() {
  const { addToast } = useToast();

  const {
    selectedServiceOrder,
    currentService,
    handleSetSelectedServiceOrder,
    handleSetCurrentService,
  } = useServiceOrder();

  const { idServiceOrderSelected, handleChangeModalSelected } = useResumePmoc();
  const { handleSetSelectedIndex } = usePmocTabIndex();

  const [selectedEnvironment, setSelectedEnvironment] =
    useState<EnvironmentDTO>({} as EnvironmentDTO);
  const [selectedEquipment, setSelectedEquipment] =
    useState<DetailEquipmentDTO>({} as DetailEquipmentDTO);
  const [pdfLink, setPdfLink] = useState("");
  const [loading, setLoading] = useState(false);
  const [showEnvironment, setShowEnvironment] = useState(false);
  const [showEquipment, setShowEquipment] = useState(false);
  const [alertModal, setAlertModal] = useState(false);
  const [showCheckListDetails, setShowCheckListDetails] = useState(false);
  const [showSchedule, setShowSchedule] = useState(false);

  async function getServiceOrderById() {
    try {
      setLoading(true);

      const { data } = await apiv2.get(
        `/budgets/service-order/${idServiceOrderSelected}`
      );

      handleSetSelectedServiceOrder(data);
      handleSetCurrentService(data.services[0]);
      setPdfLink(data.detailLink);

      setSelectedEnvironment(data.environment);
      setSelectedEquipment(data.equipment);
    } catch (error) {
      addToast({
        title: "Ops!!",
        description: "Não foi possível carregar os dados da ordem de serviço.",
        type: "error",
      });
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    getServiceOrderById();
  }, []);

  function handleOpenPdf() {
    window.open(
      `https://stage.app.profiz.com.br/download/pdf/mobile?pdfLink=${pdfLink}`,
      "_blank"
    );
  }

  async function handleArchiveServiceOrder() {
    try {
      setLoading(true);

      await apiv2.put(
        `/budgets/service-order/${idServiceOrderSelected}/archive`,
        {
          originUpdate: "web",
        }
      );

      addToast({
        type: "success",
        title: "Ordem de serviço arquivada",
      });

      handleChangeModalSelected("detailOfMonthPmoc");
      handleSetSelectedIndex(3);
    } catch (error) {
      addToast({
        type: "error",
        title: "Ops...",
        description: "Erro ao arquivar ordem de serviço",
      });
    } finally {
      setLoading(false);
      setAlertModal(false);
    }
  }

  function handleGoBack() {
    const tabOptions = {
      new: 0,
      scheduled: 1,
      concluded: 2,
    };

    handleChangeModalSelected("detailOfMonthPmoc");
    handleSetSelectedIndex(tabOptions[selectedServiceOrder.status]);
  }

  function handleNavigateToServicesHub() {
    handleChangeModalSelected("servicesHub");
  }

  if (!selectedServiceOrder.id) return <></>;

  if (showCheckListDetails) {
    return <ChecklistDetails goBack={() => setShowCheckListDetails(false)} />;
  }

  return (
    <>
      {loading ? (
        <LoadingProfiz isVisible={loading} />
      ) : (
        <>
          <AlertModal
            isVisible={alertModal}
            title="Aviso"
            description={
              "Tem certeza que deseja arquivar essa ordem de serviço ?"
            }
            action={"choose"}
            handleConfirm={() => handleArchiveServiceOrder()}
            onCloseModal={() => setAlertModal(false)}
          />
          {!showEnvironment ? (
            !showEquipment ? (
              !showSchedule ? (
                <>
                  <ArrowButton handleFunction={handleGoBack} />
                  <S.Content>
                    <>
                      <S.Title>{currentService.service.service.name}</S.Title>
                      <img src={ToolSvg} alt="tools" />
                      <S.TextWrapper>
                        <S.Description>
                          Esta ordem de serviço está vinculada ao equipamento
                          <S.Negrito>
                            {` ${selectedServiceOrder.equipment?.tagEquipment} `}
                          </S.Negrito>
                          do ambiente
                          <S.Negrito>{` ${selectedServiceOrder.environment.name}.`}</S.Negrito>
                        </S.Description>
                      </S.TextWrapper>

                      <S.Card onClick={() => setShowEnvironment(true)}>
                        <S.Negrito>
                          {selectedServiceOrder.environment.name}
                        </S.Negrito>
                      </S.Card>

                      <S.Card onClick={() => setShowEquipment(true)}>
                        <S.Negrito>
                          {selectedServiceOrder.equipment?.tagEquipment ||
                            "Equipamento sem tag"}
                        </S.Negrito>
                      </S.Card>

                      <S.Card>
                        <S.CardHeader>
                          <S.Negrito>
                            {currentService.service.service.name}
                          </S.Negrito>
                          <S.Description style={{ marginLeft: 10 }}>
                            {` (${selectedServiceOrder.equipment.currentFrequencyService})`}
                          </S.Description>
                        </S.CardHeader>
                        <S.CardHeader style={{ marginTop: 10 }}>
                          <S.Dot
                            style={{
                              backgroundColor:
                                selectedServiceOrder.services[0].status ===
                                "scheduled"
                                  ? "#FFF595"
                                  : selectedServiceOrder.services[0].status ===
                                    "concluded"
                                  ? "#D3FF9A"
                                  : "",
                            }}
                          />
                          {currentService.idBudgetService && (
                            <S.Negrito style={{ fontWeight: 400 }}>
                              {serviceStatus[currentService.status](
                                currentService.scheduleDate
                              )}
                            </S.Negrito>
                          )}
                        </S.CardHeader>

                        <Button
                          onClick={handleOpenPdf}
                          typeButton="outlineBlue"
                        >
                          <img src={Download} alt="download"></img>Download da
                          ordem de serviços
                        </Button>

                        {selectedServiceOrder.status === "concluded" && (
                          <Button
                            onClick={() => setShowCheckListDetails(true)}
                            typeButton="outline"
                          >
                            Ver checklist do serviço
                          </Button>
                        )}
                      </S.Card>
                    </>

                    <S.ButtonsWrapper>
                      {selectedServiceOrder.status !== "concluded" && (
                        <>
                          <Button
                            onClick={() => setShowSchedule(true)}
                            typeButton="default"
                          >
                            {selectedServiceOrder.status === "new"
                              ? "Agendar serviços"
                              : selectedServiceOrder.status === "scheduled" &&
                                "Reagendar serviços"}
                          </Button>
                          <Button
                            typeButton="default"
                            onClick={handleNavigateToServicesHub}
                          >
                            Iniciar checklist do serviço
                          </Button>
                        </>
                      )}

                      <ShowWhenHavePermission
                        moduleHash="budgets-service-order"
                        actionHash="edit-archive"
                      >
                        <Button
                          onClick={() => setAlertModal(true)}
                          typeButton="delete"
                        >
                          Arquivar ordem de serviço
                        </Button>
                      </ShowWhenHavePermission>
                    </S.ButtonsWrapper>
                  </S.Content>
                </>
              ) : (
                <SchedulePmoc
                  handleBackButton={() => setShowSchedule(false)}
                  serviceOrderData={selectedServiceOrder}
                  handleUpdate={getServiceOrderById}
                />
              )
            ) : (
              <DetailEquipment
                handleBackButton={() => setShowEquipment(false)}
                equipment={selectedEquipment}
              />
            )
          ) : (
            <DetailEnvironment
              environment={selectedEnvironment}
              handleBackButton={() => setShowEnvironment(false)}
            />
          )}
        </>
      )}
    </>
  );
}
