import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import axios from "axios";
import { useTheme } from "styled-components";

import { BsFillImageFill } from "react-icons/bs";
import { ArrowButton } from "components/ArrowButton";
import { LoadingProfiz } from "components/LoadingProfiz";
import { CertificationProps } from "components/CertificationCard";

import { api } from "services/api";
import { useToast } from "hooks/toast";
import * as S from "./styles";

interface RouteState {
  certification: CertificationProps;
}

interface RouteState {
  isNotification?: boolean;
  idCertification?: number;
}

export function CertificationDetails() {
  const theme = useTheme();
  const { addToast } = useToast();
  const location = useLocation<RouteState>();
  const [isPdf, setIsPdf] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [certification, setCertification] = useState<CertificationProps>({
    id: "",
    area: { id: 0, label: "" },
    certificationType: { id: 0, label: "" },
    qualificationType: { id: 0, label: "" },
    companyBrand: { id: 0, label: "" },
    expirationDate: "",
    file: "",
    thumb: "",
  });

  const params = location.state;

  useEffect(() => {
    async function getCertificates() {
      try {
        setIsLoading(true);
        if (params?.isNotification) {
          const { data } = await api.get("me/certifications?status=approved");

          const certificateNotification = data.certifications.find(
            (item: CertificationProps) =>
              Number(item?.id) === params?.idCertification
          );

          setCertification(certificateNotification);
          const certificationPath = certificateNotification.file.split("/");
          const fileName = certificationPath.slice(-1)[0];
          const extension = fileName.split(".")[1];

          if (extension === "pdf") {
            setIsPdf(true);
          }
        } else {
          setCertification(params?.certification);
          const certificationPath = params?.certification?.file.split("/");
          const fileName = certificationPath.slice(-1)[0];
          const extension = fileName.split(".")[1];

          if (extension === "pdf") {
            setIsPdf(true);
          }
        }
      } catch (error) {
        addToast({
          title: "Erro",
          description:
            axios.isAxiosError(error) && error.response?.data.error
              ? error.response.data.error
              : "Não foi possível carregar o certificado",
          type: "error",
        });
      } finally {
        setIsLoading(false);
      }
    }

    getCertificates();
  }, []); // eslint-disable-line

  return isLoading ? (
    <LoadingProfiz isVisible={isLoading} />
  ) : (
    <S.Container>
      <header>
        <div>
          <ArrowButton />
        </div>

        <div>
          <h1>Detalhes do certificado</h1>
        </div>
      </header>

      <S.Content>
        <S.WrapperPreview>
          {certification.file ? (
            isPdf ? (
              <S.PreViewCertificate
                src={certification.thumb}
                alt="preview do certificado"
              />
            ) : (
              <S.PreViewCertificate
                src={certification.file}
                alt="preview do certificado"
              />
            )
          ) : (
            <BsFillImageFill size={50} color={theme.colors.text} />
          )}
        </S.WrapperPreview>

        <S.WrapperDetails>
          <div>
            <span>Campo de atuação</span>
            <span>{certification.area.label}</span>
          </div>

          <div>
            <span>Tipo de certificação</span>
            <span>{certification.certificationType.label}</span>
          </div>

          {certification?.certificationType?.id === 1 && (
            <div>
              <span>Tipo de qualificação</span>
              <span>{certification?.qualificationType?.label}</span>
            </div>
          )}

          <div>
            <span>Emissor/Fabricante</span>
            <span>{certification?.companyBrand?.label || ""}</span>
          </div>

          <div>
            <span>Data de validade</span>
            <span>
              {certification?.expirationDate !== "" &&
              certification?.expirationDate !== "30/11/-0001"
                ? certification?.expirationDate
                : ""}
            </span>
          </div>
        </S.WrapperDetails>

        {/* <S.WrapperButton>
            <Button
              loading={loadingButton}
              typeButton="delete"
              // eslint-disable-next-line react/jsx-no-bind
              onClick={handleDeleteCertificate}
            >
              Remover certificação
            </Button>
          </S.WrapperButton> */}
      </S.Content>
    </S.Container>
  );
}
