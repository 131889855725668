import React, { memo, useCallback, useEffect, useState } from "react";

import { InputWithTooltip } from "components/InputWithTooltip";
import {
  PaymentSelectedInstallmentProps,
  IndexInstallmentProps,
} from "../../../dtos/PaymentConditionsDTO";

import * as S from "./styles";

type PaymentSelectedProps = {
  index: IndexInstallmentProps;
  isSelected: boolean;
};

type PaymentsConditiosInstallmentProps = {
  handlePaymentSelected: ({
    discount,
    addition,
    typeInstallment,
    numberInstallments,
  }: PaymentSelectedInstallmentProps) => void;
  handlePaymentRemove: (index: IndexInstallmentProps) => void;
  selectedItems: PaymentSelectedInstallmentProps[] | null | undefined;
};

const PaymentConditionsInstallments = memo(
  ({
    handlePaymentSelected,
    handlePaymentRemove,
    selectedItems,
  }: PaymentsConditiosInstallmentProps) => {
    const [inputValueDiscount, setInputValueDiscount] = useState("");
    const [inputValueAddition, setInputValueAddition] = useState("");

    const [inputAmountSimple, setInputAmountSimple] = useState("");
    const [inputAmountDiscount, setInputAmountDiscount] = useState("");
    const [inputAmountAddition, setInputAmountAddition] = useState("");

    const [labelDiscount, setLabelDiscount] = useState("");
    const [labelAddition, setLabelAddition] = useState("");

    const [paymentSelected, setPaymentSelected] = useState<
      PaymentSelectedProps[]
    >([
      { index: "simple", isSelected: false },
      { index: "discount", isSelected: false },
      { index: "addition", isSelected: false },
    ]);

    const [simple, withDiscount, withAddition] = paymentSelected.map(
      ({ isSelected }) => isSelected
    );

    useEffect(() => {
      if (selectedItems === undefined) return;

      if (selectedItems === null) return;

      const findSimpleById = selectedItems.find(
        ({ typeInstallment }) => typeInstallment === "simple"
      );

      if (findSimpleById) {
        setPaymentSelected((oldPayment) => {
          const newPayment = oldPayment.filter(
            ({ index }) => index !== "simple"
          );

          setInputAmountSimple(findSimpleById.numberInstallments.toString());

          return [{ index: "simple", isSelected: true }, ...newPayment];
        });
      }

      const findDiscountById = selectedItems.find(
        ({ typeInstallment }) => typeInstallment === "discount"
      );

      if (findDiscountById) {
        setPaymentSelected((oldPayment) => {
          const [simpleObj, additionObj] = oldPayment.filter(
            ({ index }) => index !== "discount"
          );

          const { discount, numberInstallments } = findDiscountById;

          setInputAmountDiscount(numberInstallments.toString());
          if (discount) setInputValueDiscount(discount.value.toString());
          discount?.type === "money"
            ? setLabelDiscount("reais")
            : setLabelDiscount("%");

          return [
            simpleObj,
            { index: "discount", isSelected: true },
            additionObj,
          ];
        });
      }

      const findAdditionById = selectedItems.find(
        ({ typeInstallment }) => typeInstallment === "addition"
      );

      if (findAdditionById) {
        setPaymentSelected((oldPayment) => {
          const newPayment = oldPayment.filter(
            ({ index }) => index !== "addition"
          );

          const { addition, numberInstallments } = findAdditionById;

          setInputAmountAddition(numberInstallments.toString());
          if (addition) setInputValueAddition(addition.value.toString());
          addition?.type === "money"
            ? setLabelAddition("reais")
            : setLabelAddition("%");

          return [...newPayment, { index: "addition", isSelected: true }];
        });
      }
    }, [selectedItems]);

    const handleSelectFinanceTypeDiscount = useCallback(
      (type: "money" | "percent") => {
        setLabelDiscount(type === "money" ? "reais" : "%");
        handlePaymentSelected({
          typeInstallment: "discount",
          numberInstallments: Number(inputAmountDiscount),
          discount: {
            value: Number(inputValueDiscount),
            type,
          },
          addition: null,
        });
      },
      [inputAmountDiscount, inputValueDiscount, handlePaymentSelected]
    );

    const handleSelectFinanceTypeAddition = useCallback(
      (type: "money" | "percent") => {
        setLabelAddition(type === "money" ? "reais" : "%");
        handlePaymentSelected({
          typeInstallment: "addition",
          numberInstallments: Number(inputAmountAddition),
          discount: null,
          addition: {
            value: Number(inputValueAddition),
            type,
          },
        });
      },
      [inputAmountAddition, inputValueAddition, handlePaymentSelected]
    );

    function handleSetAmountSimple() {
      handlePaymentSelected({
        typeInstallment: "simple",
        numberInstallments: Number(inputAmountSimple),
        discount: null,
        addition: null,
      });
    }

    function handleSelectPayment(type: IndexInstallmentProps) {
      const newPaymentSelected = paymentSelected.map(
        ({ index, isSelected }) => {
          if (index === type) {
            return { index, isSelected: !isSelected };
          }

          return { index, isSelected };
        }
      );

      setPaymentSelected(newPaymentSelected);

      if (type === "simple" && !newPaymentSelected[0].isSelected) {
        handlePaymentRemove(type);
      }

      if (type === "discount" && !newPaymentSelected[1].isSelected) {
        handlePaymentRemove(type);
      }

      if (type === "addition" && !newPaymentSelected[2].isSelected) {
        handlePaymentRemove(type);
      }
    }

    // function clearInputsNotSelected(index: IndexInstallmentProps) {
    //   if (index === 'simple') {
    //     setInputAmountDiscount('')
    //     setInputValueDiscount('')

    //     setInputAmountAddition('')
    //     setInputValueAddition('')
    //     return
    //   }

    //   if (index === 'discount') {
    //     setInputAmountSimple('')
    //     setInputAmountAddition('')
    //     setInputValueAddition('')
    //     return
    //   }

    //   if (index === 'addition') {
    //     setInputAmountSimple('')
    //     setInputAmountDiscount('')
    //     setInputValueDiscount('')
    //   }
    // }

    return (
      <S.Container>
        <S.WrapperPayment>
          <S.CheckArea onClick={() => handleSelectPayment("simple")}>
            <S.Checkbox isSelected={simple}>
              <div />
            </S.Checkbox>

            <S.PaymentText>Parcelado em até</S.PaymentText>
          </S.CheckArea>

          <S.Input
            value={inputAmountSimple}
            onChange={(e) => setInputAmountSimple(e.currentTarget.value)}
            disabled={!simple}
            onBlur={() => handleSetAmountSimple()}
          />
          <S.PaymentText>vezes</S.PaymentText>
        </S.WrapperPayment>

        <S.WrapperPayment>
          <S.CheckArea onClick={() => handleSelectPayment("discount")}>
            <S.Checkbox isSelected={withDiscount}>
              <div />
            </S.Checkbox>

            <S.PaymentText>Parcelado em até</S.PaymentText>
          </S.CheckArea>

          <S.Input
            value={inputAmountDiscount}
            onChange={(e) => setInputAmountDiscount(e.currentTarget.value)}
            disabled={!withDiscount}
          />
          <S.PaymentText>vezes, com desconto de </S.PaymentText>

          <S.WrapperPosition>
            <InputWithTooltip
              inputValue={inputValueDiscount}
              setInputValue={setInputValueDiscount}
              handleSelectFinanceType={handleSelectFinanceTypeDiscount}
              disabled={!withDiscount}
            />
          </S.WrapperPosition>
          <S.PaymentText>{labelDiscount}</S.PaymentText>
        </S.WrapperPayment>

        <S.WrapperPayment>
          <S.CheckArea onClick={() => handleSelectPayment("addition")}>
            <S.Checkbox isSelected={withAddition}>
              <div />
            </S.Checkbox>

            <S.PaymentText>Parcelado em até</S.PaymentText>
          </S.CheckArea>

          <S.Input
            value={inputAmountAddition}
            onChange={(e) => setInputAmountAddition(e.currentTarget.value)}
            disabled={!withAddition}
          />
          <S.PaymentText>vezes, com acréscimo de </S.PaymentText>

          <S.WrapperPosition>
            <InputWithTooltip
              inputValue={inputValueAddition}
              setInputValue={setInputValueAddition}
              disabled={!withAddition}
              handleSelectFinanceType={handleSelectFinanceTypeAddition}
              addition={true}
            />
          </S.WrapperPosition>
          <S.PaymentText>{labelAddition}</S.PaymentText>
        </S.WrapperPayment>
      </S.Container>
    );
  }
);

export { PaymentConditionsInstallments };
