import styled, { css } from "styled-components";
import { generateMedia } from "styled-media-query";

const customMedia = generateMedia({
  medium: "700px",
  small: "368px",
  xsmall: "294px",
});


export const Container = styled.div`
  ${({ theme }) => css`
    overflow-x: hidden;
    display: flex;
    flex-direction: row;
    height: 100vh;
    min-width:368px;
    background-color: ${theme.colors.strong_background};
    align-items: center;
    justify-content:center;
    padding:0px 40px;

    .image{
      display:flex;
      align-items:center;
      justify-content:center;
    }

    .title{
      font-size:16px;
      font-weight:${theme.font.bold};
      color:${theme.colors.text_extra_light};
      padding:10px;
      text-align:center;
    }
    .subtitle{
      font-size:12px;
      font-weight:${theme.font.normal};
      color:${theme.colors.text_light_60};
      padding:10px;
      text-align:center;
      strong {
        color:${theme.colors.text_light_87};
      }
    }

    ${customMedia.lessThan("medium")`
      ${
        css`
          display: flex;
          align-items: center;
          justify-content:center;
        `
      }
    `}
  `}
`;



export const ProfizLogo = styled.div`
  display:flex;
  justify-content:center;
  align-items:center;
  height: 45px;
  margin-bottom:10px;
  ${customMedia.lessThan("xsmall")`
      display: none;
  `}
`;

