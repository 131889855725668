import styled, { css } from "styled-components";
import { lighten } from "polished";

export const Container = styled.div`
align-items: center;
`;


export const Top = styled.div`
  ${({ theme }) => css`
    margin-bottom: 30px;

    display: flex;
    align-items: center;
    justify-content: space-between;

    p {
      font-size: ${theme.font.sizes.large};
      color: ${theme.colors.title};
      font-weight: ${theme.font.bold};
    }
  `}
`;


export const BackButton = styled.button`
  ${({ theme }) => css`
    width: 35px;
    height: 35px;
    background-color: ${theme.colors.background_light};
    border-radius: 10px;

    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 25px;
  `}
`;

export const EditUnity = styled.button`
  ${({ theme }) => css`
    height: 40px;
    width: 40px;

    display: flex;
    align-items: center;
    transition: opacity 0.2s;
    border: 1px solid ${theme.colors.primary};
    border-radius: 15px;
    padding: 10px;

    &:hover {
      opacity: 0.7;
    }
    img {
      width: 16px;
      height: 16px;
    }
  `}
`;


export const Header = styled.div`
  margin-bottom: 20px;
`;

export const Title = styled.p`
  ${({ theme }) => css`
    font-size: 16px;
    font-family: ${theme.font.bold};
    color: ${theme.colors.title};
  `}
`;


export const WrapperItem = styled.div`
  margin-top: 20px;
`;

export const WrapperIcon = styled.div`

  display:flex;  
  margin-top: 30px;
  justify-content: center;
  align-items: center;

  img{
    width:20vw;
    height:15vh;
  }
`;

export const Content = styled.div`
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
`;

export const HeaderCard = styled.div`
display:flex;  
flex-direction: row;
  align-items: flex-end;
`;

export const RightSide = styled.div``;

export const LeftSide = styled.div`
  width: 80%;
`;

export const TitleCard = styled.p`
  ${({ theme }) => css`
    font-size: 13px;
    font-family: ${theme.font.bold};
    color: ${theme.colors.title};
    letter-spacing: 0.26px;
    margin-right: 5px;
  `}
`;

export const Tag = styled.p`
  ${({ theme }) => css`
    font-size: 12px;
    font-family: ${theme.font.normal};
    color: ${theme.colors.primary_light};
    letter-spacing: 0.26px;
  `}
`;

export const EditItemButton = styled.button`
  ${({ theme }) => css`
    align-items: center;
    justify-content: center;
    border-radius: 13px;
    width: 40px;
    height: 40px;
    border: 1px solid ${theme.colors.primary_light};
  `}
`;

export const Inline = styled.div`
  flex-direction: row;
  align-items: center;
  display:flex;
`;

export const Circle = styled.div`
  ${({ theme }) => css`
    width: 8px;
    height: 8px;
    border-radius: 4px;
    background-color: ${theme.colors.primary_light};
    margin-top: 10px;
    margin-right: 5px;
  `}
`;

export const Text = styled.p`
  ${({ theme }) => css`
    font-size: 12px;
    font-family: ${theme.font.family};
    color: ${theme.colors.text_extra_light};
    letter-spacing: 0.26px;
    margin-top: 10px;
  `}
`;


export const TextSubTitle = styled.p`
  ${({ theme }) => css`
    font-size: 16px;
    font-family: ${theme.font.family};
    color: ${theme.colors.text_extra_light};
    letter-spacing: 0.26px;
    margin-top: 10px;
  `}
`;

export const TextInfo = styled.p`
  ${({ theme }) => css`
    font-size: 12px;
    font-family: ${theme.font.normal};
    color: ${theme.colors.text_light};
    letter-spacing: 0.26px;
    margin-top: 10px;
  `}
`;

export const CardInfo = styled.div`
  ${({ theme }) => css`
    min-height: 100px;
    background-color: ${theme.colors.background_light};
    padding: 15px;
    margin-top: 30px;
    border-radius: 12px;
  `}
`;

export const CardEnvironment = styled.div`
  ${({ theme }) => css`
    background-color: ${theme.colors.background_light};
    padding: 15px;
    margin-top: 5px;
    border-radius: 12px;
    justify-content: center;
    display:flex;

    cursor:pointer;

    &:hover {
      background: ${lighten(0.2, theme.colors.menu_detail)};
    }

    
  `}
`;

export const WrapperTextCardEnvironment = styled.div`
  flex-direction: row;
  display:flex;
`;

export const TextInfoEnvironment = styled.p`
  ${({ theme }) => css`
    font-size: 14px;
    font-family: ${theme.font.normal};
    color: ${theme.colors.text_light};
    letter-spacing: 0.26px;
    padding-left:2px;
  `}
`;

export const TextEnvironment = styled.p`
  ${({ theme }) => css`
    font-size: 14px;
    font-family: ${theme.font.normal};
    color: ${theme.colors.text_extra_light};
    letter-spacing: 0.26px;
  `}
`;

export const ViewMessage = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 60px;
  flex-direction: column;
`;

export const MessageText = styled.p`
  ${({ theme }) => css`
    font-size: 14px;
    font-family: ${theme.font.family};
    color: ${theme.colors.financial_title};
  `}
`;