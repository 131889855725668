import React, { useEffect, useState } from "react";

import { Button } from "components/Button";
import { ModalRight } from "components/ModalRight";
import { ArrowButton } from "components/ArrowButton";
import { SearchInput } from "components/SearchInput";
import { ModalPageClients } from "components/ModalPageClients";

import { useToast } from "hooks/toast";
import { useClient } from "hooks/budget/client";
import { useEditClient } from "hooks/editClient";
import { ClientProps } from "dtos/ClientDTO";

import { api } from "services/api";
import * as S from "./styles";
import { EmptyPage } from "components/EmptyPage";
import { LoadingProfiz } from "components/LoadingProfiz";

import { useTheme } from "styled-components";

import { RiUser3Line } from "react-icons/ri";
import { ModalName } from "components/ModalPageClients/types";
import { ShowWhenHavePermission } from "components/Permission";
import { useAccount } from "hooks/permission/account";
import { searchDatalayer } from "utils/pushDataLayer";

export function MyClients() {
  const theme = useTheme();

  const { addToast } = useToast();
  const { clearClientData, handleSetClient } = useClient();
  const { handleSetEditClient, clearEditClientData } = useEditClient();
  const { whoami } = useAccount();

  const [isLoading, setIsLoading] = useState(false);
  const [clients, setClients] = useState<ClientProps[]>([]);
  const [clientsResponse, setClientsResponse] = useState<ClientProps[]>([]);

  const [modalOpened, setModalOpened] = useState<ModalName>();

  const [searchInput, setSearchInput] = useState("");

  const [isOpen, setIsOpen] = useState(false);
  const [reRenderClients, setReRenderClients] = useState(false);

  const [clientGroup, setClientGroup] = useState<any>([]);

  useEffect(() => {
    async function fetchClients() {
      try {
        setIsLoading(true);
        const { data } = await api.get("/clients", {
          params: {
            accountId: whoami?.id!,
          },
        });
        const mappedClients = data.clients.map((client: ClientProps) => {
          const splittedName = client.name.split(" ");

          const firstLetter = splittedName[0].substring(0, 1).toUpperCase();
          const lastLetter =
            splittedName.length >= 2
              ? splittedName[splittedName.length - 1]
                  .substring(0, 1)
                  .toUpperCase()
              : "";

          return {
            ...client,
            initialsLetterName: `${firstLetter}${lastLetter}`,
            firstLetter: firstLetter,
          };
        });

        mappedClients.sort((a: ClientProps, b: ClientProps) =>
          a.name.localeCompare(b.name)
        );
        setClients(mappedClients);
        setClientsResponse(mappedClients);

        setClientGroup(groupByLetter(mappedClients, "firstLetter"));
      } catch (err) {
        // sendError(err)
        addToast({
          title: "Ops!!",
          description: "Ocorreu um erro ao listar os contatos",
          type: "error",
        });
      } finally {
        setIsLoading(false);
      }
    }
    fetchClients();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reRenderClients, whoami?.id]);

  function handleSearchClient(text: string) {
    setSearchInput(text);

    const filteredClients = clientsResponse.filter((client) => {
      const clientsLowerCase = client.name.toLowerCase();

      const searchValue = text.toLowerCase();

      return clientsLowerCase.includes(searchValue);
    });

    setClients(filteredClients);

    setClientGroup(groupByLetter(filteredClients, "firstLetter"));

    searchDatalayer({ search_term: text, success: true });
  }

  function handleSearchCancel() {
    setSearchInput("");
    setClients(clientsResponse);

    setClientGroup(groupByLetter(clientsResponse, "firstLetter"));
  }

  function handleToggleOpen() {
    setIsOpen(!isOpen);
    clearClientData();

    if (isOpen) {
      setReRenderClients(!reRenderClients);
    }
  }

  function handleChooseModal(modal: ModalName, client?: ClientProps) {
    setModalOpened(modal);
    handleSetEditClient(client!);
    if (client) handleSetClient(client);

    setIsOpen(!isOpen);
  }

  function handleChooseEditModal(modal: ModalName) {
    setModalOpened(modal);
  }

  function groupByLetter(array: any, key: any) {
    return array.reduce(
      (acc: any, item: any) => ({
        ...acc,
        [item[key]]: [...(acc[item[key]] ?? []), item],
      }),
      {}
    );
  }

  return (
    <S.Container>
      <header>
        <div>
          <ArrowButton />
          <h1>Meus clientes</h1>
        </div>

        <div>
          <SearchInput
            searchValue={searchInput}
            onChange={(e) => handleSearchClient(e.target.value)}
            placeholder="Faça uma busca pelo nome do cliente"
            handleCancel={() => handleSearchCancel()}
          />
        </div>
      </header>

      {isOpen && (
        <ModalRight
          isOpen={isOpen}
          handleToggleOpen={() => {
            handleToggleOpen();
            clearEditClientData();
          }}
        >
          <ModalPageClients
            modalSelected={modalOpened!}
            handleToggleOpen={handleToggleOpen}
            handleChooseEditModal={handleChooseEditModal}
          />
        </ModalRight>
      )}

      <S.Content>
        {isLoading ? (
          <LoadingProfiz isVisible={isLoading} />
        ) : (
          <>
            <div>
              <ShowWhenHavePermission moduleHash="client" actionHash="create">
                <Button
                  typeButton="outline"
                  onClick={() => handleChooseModal("createClient")}
                >
                  Criar novo cliente
                </Button>
              </ShowWhenHavePermission>
            </div>

            {clients.length === 0 ? (
              <EmptyPage />
            ) : (
              <S.ListClients>
                {Object.keys(clientGroup).map((element) => (
                  <React.Fragment key={element}>
                    <S.FirstLetter>
                      <span>{element}</span>
                    </S.FirstLetter>
                    {clientGroup[element].map((client: ClientProps) => (
                      <li key={client.id}>
                        <button
                          type="button"
                          onClick={() =>
                            handleChooseModal("clientDetails", client)
                          }
                        >
                          <S.UserPhoto key={client.id}>
                            <RiUser3Line
                              size={20}
                              color={theme.colors.primary}
                            />
                          </S.UserPhoto>

                          <p>{client.name}</p>
                        </button>
                      </li>
                    ))}
                  </React.Fragment>
                ))}
              </S.ListClients>
            )}
          </>
        )}
      </S.Content>
    </S.Container>
  );
}
