import { useState } from "react";
import { useHistory, useLocation } from "react-router";

import UserMoreDetailSvg from "assets/icons/permission/user-more-detail.svg";
import TeamsIconSvg from "assets/icons/permission/teams-icon.svg";

import { useAccount } from "hooks/permission/account";

import { Page } from "templates/Page";
import {
  ListItemCard,
  TeamMember,
  RoleAndPermission,
  ShowWhenHavePermission,
} from "components/Permission";
import TrophySvg from "assets/icons/permission/trophy.svg";
import * as S from "./styles";
import { Props } from "components/Permission/TeamMember";
export type Pages = "teamMember" | "roleAndPermission";

type RouteParams = {
  currentPageDetail?: Pages;
  activeTab?: "added";
};

export function ManagementOfTeam() {
  const history = useHistory();
  const location = useLocation<RouteParams>();
  const { state } = location;

  const { whoami } = useAccount();
  const [viewPageDetail, setViewPageDetail] = useState<Pages | null>(
    state?.currentPageDetail || null
  );

  const mappedTabs: Props = {
    0: "invited",
    1: "added",
    2: "removed",
  };

  const [edditingPermission, setEdditingPermission] = useState(false);
  const [memberInvitedId, setMemberInviteId] = useState<number>();
  const [currentprofileId, setCurrentProfileId] = useState(0);

  const [currentTab, setCurrentTab] = useState(
    Object.values(mappedTabs).indexOf("invited")
  );
  const pages = {
    teamMember: (
      <TeamMember
        setCurrentTab={setCurrentTab}
        currentTab={currentTab}
        setCurrentProfileId={setCurrentProfileId}
        setMemberInviteId={setMemberInviteId}
        setEdditingPermission={setEdditingPermission}
        setViewPageDetail={setViewPageDetail}
        activeTab={state?.activeTab}
      />
    ),
    roleAndPermission: <RoleAndPermission editingPermission={false} />,
  };

  if (edditingPermission) {
    return (
      <S.Container>
        <RoleAndPermission
          editingPermission
          setViewPageDetail={setViewPageDetail}
          currentprofileId={currentprofileId}
          setEdditingPermission={setEdditingPermission}
          memberInvitedId={memberInvitedId}
        />
      </S.Container>
    );
  }

  return (
    <Page
      title="Equipes"
      subtitle="Visualize os membros da equipe e cargos e permissões."
    >
      {!whoami?.isMe && (
        <ListItemCard
          title="Detalhes do convite"
          image={UserMoreDetailSvg}
          onClick={() => history.push("/teams/my-invite")}
        />
      )}

      <ShowWhenHavePermission moduleHash="team" actionHash="member-view">
        <ListItemCard
          title="Membros da equipe"
          image={TeamsIconSvg}
          isSelected={viewPageDetail === "teamMember"}
          onClick={() => setViewPageDetail("teamMember")}
        />
      </ShowWhenHavePermission>

      <ShowWhenHavePermission moduleHash="team" actionHash="profile-view">
        <ListItemCard
          title="Cargos e permissões"
          image={TrophySvg}
          isSelected={viewPageDetail === "roleAndPermission"}
          onClick={() => setViewPageDetail("roleAndPermission")}
        />
      </ShowWhenHavePermission>

      {!!viewPageDetail && pages[viewPageDetail]}
    </Page>
  );
}
