import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router";
import { useTheme } from "styled-components";
import { FiCheck } from "react-icons/fi";

import { useRegisterPhotosModal } from "hooks/registerPhotosModal";
import { useServiceOrder } from "hooks/serviceOrder";
import { useResumePmoc } from "hooks/resumePmoc";

import { ServiceWithChecklistDTO } from "dtos/ServiceWithChecklistDTO";

import { ModalRight } from "components/ModalRight";
import { ContentLeft } from "templates/ContentLeft";
import { ArrowButton } from "components/ArrowButton";
import { Button } from "components/Button";
import { RunServiceChecklist } from "./RunServiceChecklist";

import arrowRight from "assets/icons/arrow-right-service-hub.svg";
import lupa from "assets/lupa.svg";

import * as S from "./styles";

type RouteParams = {
  id: string;
  idPmoc: string;
  year: string;
  month: string;
};

export function PmocServicesHub() {
  const { id, idPmoc, year, month } = useParams<RouteParams>();

  const history = useHistory();

  const theme = useTheme();
  const {
    selectedServiceOrder,
    handleSetCurrentService,
    handleSetCurrentServiceChecklist,
  } = useServiceOrder();
  const { modalSelected, handleChangeModalSelected } = useResumePmoc();

  const { isOpenRegisterPhotosModal, handleToggleRegisterPhotosModal } =
    useRegisterPhotosModal();

  const [hasAnyServiceConcluded, setHasAnyServiceConcluded] = useState(false);

  useEffect(() => {
    function findServicesAlreadyConcluded() {
      const servicesAlreadyConcluded = selectedServiceOrder.services.find(
        (currentService: ServiceWithChecklistDTO) =>
          currentService.status === "concluded"
      );

      if (!!servicesAlreadyConcluded) {
        setHasAnyServiceConcluded(true);
      }
    }
    findServicesAlreadyConcluded();
  }, [selectedServiceOrder]);

  function handleNavigateToRunServiceChecklist(
    service: ServiceWithChecklistDTO
  ) {
    if (service.service.serviceChecklist) {
      handleSetCurrentService(service);
      handleSetCurrentServiceChecklist(service.service.serviceChecklist[0]);
      handleToggleRegisterPhotosModal();
    }
  }

  function handleRedirect() {
    if (modalSelected === "servicesHub") {
      handleChangeModalSelected("registerSignatures");
      return;
    }
    history.push(`/pmoc-service-signatures/${id}/${idPmoc}/${year}/${month}`);
  }

  function handleGoBack() {
    if (modalSelected === "servicesHub") {
      handleChangeModalSelected("serviceOrderDetail");
      return;
    }

    history.goBack();
  }
  return (
    <S.Container>
      <ModalRight
        isOpen={isOpenRegisterPhotosModal}
        // eslint-disable-next-line react/jsx-no-bind
        handleToggleOpen={handleToggleRegisterPhotosModal}
      >
        <RunServiceChecklist />
      </ModalRight>

      <header>
        <ArrowButton handleFunction={handleGoBack} />
        <h1>Checklist dos serviços</h1>
      </header>

      <ContentLeft>
        <S.Content>
          <S.ContentImage>
            <img src={lupa} alt="Homem segurando uma lupa" />
          </S.ContentImage>

          <S.Wrapper>
            <S.Label>Vamos fazer o checklist dos serviços realizados.</S.Label>
          </S.Wrapper>

          {selectedServiceOrder.services.map((currentService) => {
            let labelNameService = currentService.service.service.name +
                  " " +
                  currentService.service.equipment.name;

            return (
              <div key={currentService.idBudgetService}>
                <Button
                  type="button"
                  key={currentService.idBudgetService}
                  typeButton={
                    currentService.status === "concluded"
                      ? "unselected"
                      : "outline"
                  }
                  onClick={() => {
                    handleNavigateToRunServiceChecklist(currentService);
                  }}
                >

                  {labelNameService}
                  <div style={{marginLeft:10}}>
                    <strong>{selectedServiceOrder.equipment?.tagEquipment}</strong>
                  </div>

                  {currentService.status === "concluded" && (
                    <div>
                      <FiCheck size={20} color={theme.colors.primary} />
                    </div>
                  )}
                </Button>
              </div>
            );
          })}

          <S.WrapperButton>
            {hasAnyServiceConcluded ? (
              <button type="button" onClick={() => handleRedirect()}>
                <img src={arrowRight} alt="flecha pra direita" height={20} width={20} />
              </button>
            ) : (
              <button type="button" onClick={() => handleRedirect()}>
                <p className="title" >Pular</p>
              </button>
            )}
          </S.WrapperButton>
        </S.Content>
      </ContentLeft>
    </S.Container>
  );
}
