import styled, { css } from "styled-components";

export const OpportunityCard = styled.div`
  ${({ theme }) => css`
    margin-top: 20px;
    width: 100%;
    height: 95px;
    background: ${theme.colors.background_light};
    border-radius: 10px;
    padding: 15px;
    cursor: pointer;
  `}
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const Title = styled.p`
  ${({ theme }) => css`
    display: flex;
    color: ${theme.colors.title};
    font-size: ${theme.font.sizes.small};
    p {
      color: ${theme.colors.title};
      font-size: ${theme.font.sizes.small};
      margin-left: 8px;
    }
  `}
`;

export const ServiceOrderStatus = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    span {
      font-family: ${theme.font.family};
      font-style: normal;
      font-weight: ${theme.font.normal};
      font-size: 12px;
      line-height: 15px;
      color: rgba(255, 255, 255, 0.87);
    }
  `}
`;

export const StatusBadge = styled.div`
  ${({ theme }) => css`
    height: 7px;
    width: 7px;
    border-radius: 50%;
    margin-right: 7px;
    background-color: ${theme.colors.primary};
  `}
`;

export const Content = styled.div`
  width: 100%;
`;

export const InfoWrapper = styled.p`
  ${({ theme }) => css`
    margin-top: 10px;
    display: flex;

    p {
      font-family: ${theme.font.family};
      font-weight: ${theme.font.normal};
      font-size: ${theme.font.sizes.xsmall};
      color: ${theme.colors.text_extra_light};
    }

    span {
      margin-left: 4px;
      font-family: ${theme.font.family};
      font-weight: ${theme.font.normal};
      font-size: ${theme.font.sizes.xsmall};
      color: ${theme.colors.text};
    }
  `}
`;
