import React, { ButtonHTMLAttributes } from "react";

import certificationSvg from "assets/icons/certification.svg";

import * as S from "./styles";

type DropdownProps = {
  id: number;
  label: string;
};

export type CertificationProps = {
  id: string;
  area: DropdownProps;
  certificationType: DropdownProps;
  qualificationType: DropdownProps;
  companyBrand: DropdownProps;
  expirationDate: string;
  file: string;
  thumb: string;
};

type CardProps = ButtonHTMLAttributes<HTMLButtonElement> & {
  data: CertificationProps;
};

export function CertificationCard({ data, ...rest }: CardProps) {
  return (
    <S.Container {...rest}>
      <div>
        <img src={certificationSvg} alt="imagem de um capelo" />
      </div>

      <h4>{data.area.label}</h4>
      <p>{data.certificationType.label}</p>
      <p>
        {data.certificationType.id === 1
          ? data.qualificationType.label
          : data.companyBrand.label}
      </p>
    </S.Container>
  );
}
