import styled, { css } from "styled-components";
import { Button } from '../Button';

interface PaginationButtonProps {
  active?: boolean;
  isButton?: boolean;
}

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 20px 0;
  justify-content: space-between;
  width: 400px;
`;

export const PaginationButton = styled(Button)<PaginationButtonProps>`
  ${({ theme, active, isButton }) => css`
    width: ${isButton ? '70px' : '35px'};
    cursor: pointer;
    padding: 10px;
    height: 35px;
    background-color: ${active ? theme.colors.primary : theme.colors.pagination_item};
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 12px;
  `}
`;

export const ButtonText = styled.div<PaginationButtonProps>`
  ${({ theme, active }) => css`
    color: ${ active ? '#121212' : theme.colors.text_extra_light};
    font-family: ${theme.font.normal};
    font-size: 12px;
  `}
`;

