import styled, { css } from "styled-components";

export const ButtonBackground = styled.div`
  ${({ theme }) => css`
    width: 40px;
    height: 40px;
    background: ${theme.colors.background_modal};
    border-radius: 10px;
    display: flex;
    justify-content: center;
    cursor: pointer;
    color: ${theme.colors.primary};
    align-items: center;
  `}
`;

export const Card = styled.div`
  ${({ theme }) => css`
    width: 100%;
    min-height: 45px;
    background: ${theme.colors.background_modal};
    border-radius: 10px;
    display: flex;
    margin: 10px 0 20px;
    flex-direction: column;
    padding: 10px;
  `}
`;

export const Title = styled.div`
  ${({ theme }) => css`
    font-size: 18px;
    font-weight: 700;
    color: ${theme.colors.text_extra_light};
    margin-top: 30px;
    margin-bottom: 30px;
  `}
`;

export const WrapperName = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
`;

export const ChecklistName = styled.div`
  ${({ theme }) => css`
    font-size: 12px;
    font-weight: 400;
    color: ${theme.colors.text_light_60};
  `}
`;

export const ChecklistType = styled.div`
  ${({ theme }) => css`
    font-size: 12px;
    font-weight: 400;
    color: ${theme.colors.text_light};
  `}
`;

export const CheckboxBackground = styled.div`
  ${({ theme }) => css`
    width: 40px;
    height: 40px;
    background-color: rgba(211, 255, 154, 0.1);
    border-radius: 10px;
    display: flex;
    justify-content: center;
    cursor: pointer;
    color: ${theme.colors.primary};
    align-items: center;
  `}
`;

export const ImagesWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 12px;
`;

export const Image = styled.img`
  width: 146px;
  height: 146px;
  border-radius: 9px;
  margin-top: 10px;
  cursor: pointer;
`;

export const ObservationTextCard = styled.div`
  ${({ theme }) => css`
    width: 100%;
    background-color: ${theme.colors.background_light};
    margin-top: 10px;
    padding: 10px;
    border-radius: 10px;
  `}
`;
