import React from "react";

import * as S from "./styles";

function WelcomeThird() {
  return (
    <S.Container>
      <S.Title>
        <span>Oportunidades</span> de serviços
      </S.Title>

      <S.Line />

      <S.SubTitle>
        <strong>Receba</strong> mais serviços, <strong>conquiste</strong> novos
        clientes e<br /> <strong>alavanque</strong> seus negócios.
      </S.SubTitle>
    </S.Container>
  );
}

export { WelcomeThird };
