import styled, { css } from "styled-components";

export const Container = styled.div`
  min-height: 124px;
  position: relative;
  background-color: transparent;
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 3px 0 10px;

  & > img {
    width: 42px;
    height: 42px;
    position: absolute;
    top: 0;
    right: 0;
  }
`;

export const Title = styled.h4`
  ${({ theme }) => css`
    font-weight: ${theme.font.bold};
    font-size: ${theme.font.sizes.xsmall};
    color: ${theme.colors.text_light_60};
    margin-bottom: 10px;
  `}
`;

export const TextDetail = styled.p`
  ${({ theme }) => css`
    color: ${theme.colors.text_light_60};
    font-size: ${theme.font.sizes.xsmall};
    line-height: 18px;
  `}
`;

export const WrapperDetailService = styled.div`
  margin-top: 8px;
`

export const WrapperUserExecution = styled.div`
  margin-top: 16px;
`

export const DetailServiceText = styled.span`
  ${({ theme }) => css`
    color: ${theme.colors.primary} !important;
    font-size: ${theme.font.sizes.xsmall};

    &:hover {
      cursor: pointer;
      text-decoration: underline;
    }
  `}
`;
