import styled, { css } from "styled-components";

export const Container = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 100vh;
    gap: 10px;

    h1 {
      color: ${theme.colors.text_light_87};
      font-size: 40px;
    }

    p {
      font-size: 16px;
      color: ${theme.colors.text_light_60};
    }

    img {
      width: 100px;
      height: 100px;
    }
  `}
`;
