import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router";

import { ArrowButton } from "components/ArrowButton";
import { SearchComercialModelsManual } from "components/SearchComercialModelsManual";
import { EquipmentCard } from "components/EquipmentCard";

import { api } from "services/api";

import { EquipmentsShimmer } from "Shimmer/EquipmentsShimmer";

import { useToast } from "hooks/toast";

import * as S from "./styles";

export type Equipment = {
  id: string;
  image: string;
  name: string;
  itens?: string;
  brand: {
    id: string;
    name: string;
    image: string;
  };
};

type RouteParams = {
  brandId: string;
};

export function EquipmentsManual() {
  const { brandId } = useParams<RouteParams>();
  const history = useHistory();
  const { addToast } = useToast();

  const [isLoading, setIsLoading] = useState(true);
  const [equipmentsOfBrand, setEquipmentsOfBrand] = useState<Equipment[]>([]);

  useEffect(() => {
    async function loadEquipmentsOfBrand() {
      try {
        const response = await api.get(`equipment?idMarca=${brandId}`);

        const equipmentsData = response.data.categories.map(
          (equipment: Equipment) => ({
            id: equipment.id,
            image: equipment.image,
            name: equipment.name,
            itens: `${equipment?.itens?.toString()} modelos`,
          })
        );

        setEquipmentsOfBrand(equipmentsData);
      } catch (err) {
        // sendError(err)
        addToast({
          title: "Ops!!",
          description: "Ocorreu um erro ao listar os equipamentos",
          type: "error",
        });
      } finally {
        setIsLoading(false);
      }
    }

    loadEquipmentsOfBrand();
  }, [brandId, addToast]);

  function handleShowEquipmentsType(categoryId: string) {
    history.push(`/brands/${brandId}/equipments/${categoryId}/types`);
  }

  return (
    <S.Container>
      <header>
        <ArrowButton />

        <h2>Equipamentos</h2>

        <SearchComercialModelsManual description="Ou selecione um equipamento para ver os modelos." />
      </header>

      <S.Content>
        {isLoading && <EquipmentsShimmer />}
        {!isLoading &&
          equipmentsOfBrand.map((equipment) => (
            <EquipmentCard
              onClick={() => {
                handleShowEquipmentsType(equipment.id);
              }}
              equipment={equipment}
              key={equipment.id}
            />
          ))}
      </S.Content>
    </S.Container>
  );
}
