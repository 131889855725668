import React, { createContext, useState, useContext } from "react";
import { ProviderProps } from "hooks";

interface ServiceOpportunityTabIndexContextData {
  selectedIndex: number;
  handleSetSelectedIndex: (index: number) => void;
}

const ServiceOpportunityTabIndexContext = createContext<ServiceOpportunityTabIndexContextData>(
  {} as ServiceOpportunityTabIndexContextData
);

export function useServiceOpportunityTabIndex(): ServiceOpportunityTabIndexContextData {
  const context = useContext(ServiceOpportunityTabIndexContext);

  if (!context) {
    throw Error("useServiceOpportunityTabIndex must be used within an ServiceOpportunityTabIndexProvider");
  }

  return context;
}

export const ServiceOpportunityTabIndexProvider = ({ children }: ProviderProps) => {
  const [selectedIndex, setSelectedIndex] = useState(0);

  function handleSetSelectedIndex(index: number) {
    setSelectedIndex(index);
  }

  return (
    <ServiceOpportunityTabIndexContext.Provider
      value={{
        selectedIndex,
        handleSetSelectedIndex,
      }}
    >
      {children}
    </ServiceOpportunityTabIndexContext.Provider>
  );
};
