import { lighten } from "polished";
import styled, { css } from "styled-components";

export const Description = styled.p`
  ${({ theme }) => css`
    font-size: ${theme.font.sizes.xsmall};
    color: ${theme.colors.text};
    margin-bottom: 40px;
  `}
`;

export const WrapperInput = styled.form`
  width: 100%;
  position: relative;
  margin-bottom: 20px;
`;

export const WrapperPasswordBar = styled.div`
  margin: 20px 0;
  width: 100%;
  position: relative;
`;

export const WrapperButton = styled.div`
  width: 100%;
`;

export const Wrapper = styled.div`
  ${({ theme }) => css`
    div {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 20px;
      margin-bottom: 20px;

      /* & > a {
        margin-left: 8px;
        text-decoration: none;
        font-size: ${theme.font.sizes.xsmall};
        color: ${theme.colors.text};
        text-decoration: underline;
      } */
    }

    p {
      font-size: ${theme.font.sizes.xsmall};
      color: ${theme.colors.text};
    }
  `}
`;

export const WrapperCode = styled.div`
  width: 110%;
  margin-bottom: 28px;
`;

export const ShowPassword = styled.button`
  height: 68%;
  width: 42px;

  position: absolute;
  right: 2px;
  top: 30%;
  transition: background 0.2s;

  &:hover {
    background: ${({ theme }) => lighten(0.2, theme.colors.background_light)};
  }
`;

export const BackgroundContent = styled.div`
  width: 100%;
  height: 100vh;
  display: grid;
  grid-template-columns: 50% 1fr;
  align-items: center;
  background-position: right -190px center;

  @media (max-width: 1366px) {
    background-size: 300px;
    background-position: right -150px center;
  }
`;

export const Background = styled.div`
  width: 100%;
  display: flex;

  justify-content: space-between;
  align-items: center;
  flex-direction: column;

  .laptop {
    width: 410px;
    margin-left: 100px;
    margin-top: 60px;
    margin-bottom: 60px;

    @media (max-width: 1532px) {
      width: 600px;
    }

    @media (max-width: 1366px) {
      margin-top: 50px;
      margin-bottom: 50px;
      width: 410px;
    }
  }

  aside {
    width: 100%;
    max-width: 680px;

    @media (max-width: 1366px) {
      max-width: 500px;
    }

    .profiz {
      margin-bottom: 50px;
      height: 40px;
    }
  }

  section {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 0 auto;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .content {
    width: 530px;
    margin-right: 50px;
  }
`;

export const Title = styled.h1`
  ${({ theme }) => css`
    color: ${theme.colors.title};
    font-size: 42px;
    line-height: 30px;
    margin-bottom: 10px;

    @media (max-width: 1366px) {
      font-size: 37px;
    }

    span {
      color: ${theme.colors.text};
      font-size: 42px;
      font-weight: 400;

      @media (max-width: 1366px) {
        font-size: 24px;
      }
    }
  `}
`;

export const SubTitle = styled.p`
  ${({ theme }) => css`
    color: ${theme.colors.text};
    font-size: 28px;
    line-height: 30px;

    @media (max-width: 1366px) {
      font-size: 16px;
    }

    span {
      color: ${theme.colors.title};

      font-size: 28px;
      font-weight: 500;

      @media (max-width: 1366px) {
        font-size: 16px;
      }
    }
  `}
`;

export const QrCode = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    justify-content: flex-start;

    span {
      color: ${theme.colors.text_light_87};
      font-size: 20px;
      line-height: 14px;
      font-weight: 700;

      @media (max-width: 1366px) {
        font-size: 12px;
      }
    }

    p {
      color: ${theme.colors.text_light_60};
      font-size: 14px;
      line-height: 12px;
      font-weight: 400;

      @media (max-width: 1366px) {
        font-size: 10px;
      }
    }

    img {
      margin-right: 10px;
      width: 57px;
      height: 57px;
    }
  `}
`;
