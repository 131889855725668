import styled, { css } from "styled-components";
import { lighten } from "polished";

type WrapperArtProps = {
  buttonDisabled?: boolean;
};

export const WrapperAttachmentButton = styled.div<WrapperArtProps>`
  ${({ theme, buttonDisabled }) => css`
    div {
      width: 100%;
      height: 45px;
      border: 1px dashed ${theme.colors.primary};
      border-radius: 10px;
    }

    label {
      width: 100%;
      height: 100%;
      cursor: ${buttonDisabled ? "not-allowed" : "pointer"};
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: ${theme.font.sizes.xsmall};
      color: ${theme.colors.title};

      span {
        margin-left: 10px;
      }

      input {
        display: none;
      }
    }

    p {
      font-size: ${theme.font.sizes.xsmall};
      color: ${theme.colors.text};
      margin-top: 15px;
      margin-bottom: 29px;
    }

    img {
      width: 40px;
    }
  `}
`;

export const Wrapper = styled.div`
  margin-bottom: 24px;
`;

export const WrapperLoading = styled.div`
  margin-bottom: 24px;
  align-items: center;
  justify-content: center;
  display: flex;
`;

export const WrapperArt = styled.div<WrapperArtProps>`
  ${({ theme, buttonDisabled }) => css`
    p {
      font-size: 12px;
      font-family: ${theme.font.family};
      color: ${theme.colors.title};
    }

    div.inline {
      display: flex;
      align-items: center;
      margin-top: 5px;
    }

    div.container {
      height: 45px;
      width: 90%;
      background-color: ${theme.colors.background_light};

      display: flex;
      align-items: center;
      padding: 0 10px;

      border: 1px dashed ${theme.colors.primary_light};
      border-radius: 10px;

      p.attach {
        margin-left: 5px;
      }
    }

    button {
      height: 45px;
      width: 9%;

      background-color: ${theme.colors.background_light};
      border-radius: 10px;

      &:hover {
        background: ${lighten(0.2, theme.colors.background_light)};
      }

      display: flex;
      align-items: center;
      justify-content: center;

      margin-left: 1%;
    }

    button.buttonRemove {
      cursor: ${buttonDisabled ? "not-allowed" : "pointer"};
    }
  `}
`;
