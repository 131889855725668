import styled, { css } from "styled-components";

interface ContainerProps {
  photoLimitReached: boolean;
}

export const Container = styled.div<ContainerProps>`
  ${({ theme, photoLimitReached }) => css`
    width: 50px;
    height: 50px;

    border: 1px dashed ${theme.colors.primary};
    border-radius: 10px;

    display: flex;
    justify-content: center;
    align-items: center;

    cursor: ${photoLimitReached ? "not-allowed" : "pointer"};

    & > img {
      width: 50%;
      height: 50%;
    }

    label {
      width: 100%;
      height: 100%;
      cursor: ${photoLimitReached ? "not-allowed" : "pointer"};
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: ${theme.font.sizes.xsmall};
      color: ${theme.colors.title};

      span {
        margin-left: 10px;
      }

      input {
        display: none;
      }
    }

    p {
      font-size: ${theme.font.sizes.xsmall};
      color: ${theme.colors.text};
      margin-top: 15px;
      margin-bottom: 29px;
    }
  `}
`;
