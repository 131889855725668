import React, { useEffect, useState } from "react";

import { CommentsTextArea } from "components/CommentsTextArea";
import { Button } from "components/Button";
import { EditButton } from "components/EditButton";
import { DeleteButton } from "components/DeleteButton";

import { RegisterProps } from "dtos/ServiceChecklistDTO";
import { useServiceOrder } from "hooks/serviceOrder";

import CheckSvg from "assets/icons/check.svg";

import * as S from "./styles";

type CardCheckRegisterProps = {
  register: RegisterProps;
  editable?: boolean;
};

export function CardCheckRegister({
  register,
  editable = true,
}: CardCheckRegisterProps) {
  const [isChecked, setIsChecked] = useState(false);
  const [isOpenObservation, setIsOpenObservation] = useState(false);
  const [updateObservation, setUpdateObservation] = useState("");

  useEffect(() => {
    setIsChecked(register.isChecked || false);
    setUpdateObservation(register.observation || "");
  }, [register]);

  const { handleUpdateRegister } = useServiceOrder();

  function toggleCheck() {
    handleUpdateRegister({
      ...register,
      isChecked: !isChecked,
    });
    setIsChecked(!isChecked);
  }

  function toggleObservation() {
    setIsOpenObservation(!isOpenObservation);
  }

  function handleUpdateObservation(observation: string) {
    setUpdateObservation("");
    handleUpdateRegister({
      ...register,
      observation,
    });
  }

  function handleSave() {
    handleUpdateObservation(updateObservation);
    toggleObservation();
  }

  return (
    <S.Container>
      <S.Header>
        <S.Title>{register.name}</S.Title>

        {editable && (
          <S.ToggleButton onClick={toggleCheck}>
            <S.ToggleBall isChecked={isChecked} />
          </S.ToggleButton>
        )}

        {!editable && register.isChecked && (
          <S.WrapperIcon>
            <S.CheckIconImage src={CheckSvg} alt="check" />
          </S.WrapperIcon>
        )}
      </S.Header>

      {editable && !register.observation && !isOpenObservation && (
        <S.ObservationButton onClick={toggleObservation}>
          <S.ObservationText>Adicionar observações</S.ObservationText>
        </S.ObservationButton>
      )}

      {!!register.observation && !isOpenObservation && (
        <S.CommentsDetail>
          <S.WrapperComments>
            <S.CommentsText editable={editable}>
              {register.observation}
            </S.CommentsText>
          </S.WrapperComments>

          {editable && (
            <S.WrapperButtons>
              <EditButton onClick={toggleObservation} />
              <DeleteButton onClick={() => handleUpdateObservation("")} />
            </S.WrapperButtons>
          )}
        </S.CommentsDetail>
      )}

      {editable && isOpenObservation && (
        <S.WrapperTextArea>
          <S.CancelButton onClick={toggleObservation}>Cancelar</S.CancelButton>

          <CommentsTextArea
            placeholder="Adicione observações."
            value={updateObservation}
            onChange={(e) => setUpdateObservation(e.target.value.trimStart())}
            style={{ height: "120px" }}
          />

          <Button onClick={handleSave}>Salvar</Button>
        </S.WrapperTextArea>
      )}
    </S.Container>
  );
}
