import { useState } from "react";
import { useHistory, useLocation } from "react-router";

import { Page } from "templates/Page";
import { Input } from "components/Input";
import { Button } from "components/Button";
import {
  ConfirmAlert,
  Label,
  ListItemCard,
  ModuleList,
  mappedProfileIcons,
} from "components/Permission";

import { useAccount } from "hooks/permission/account";
import { useToast } from "hooks/toast";

import { Profile } from "dtos/permission/ProfileDTO";
import { api } from "services/api";

import * as S from "./styles";

type RouteParams = {
  name: string;
  email: string;
  memberRole: Profile;
};

export function ConfirmInvite() {
  const history = useHistory();
  const { state } = useLocation<RouteParams>();
  const { name, email, memberRole } = state;

  const { whoami } = useAccount();
  const { addToast } = useToast();

  const [loading, setLoading] = useState(false);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);

  async function handleSubmit(event: React.FormEvent) {
    try {
      event.preventDefault();
      setLoading(true);

      await api.post(`users/teams/${whoami?.id}/invite`, {
        name,
        email,
        profileId: memberRole.id,
      });

      setShowConfirmationModal(true);
    } catch (err) {
      addToast({
        title: "Ops!!",
        description: "Ocorreu um erro ao enviar convite",
        type: "error",
      });
    } finally {
      setLoading(false);
    }
  }

  return (
    <>
      {showConfirmationModal && (
        <ConfirmAlert
          title="Quase pronto!"
          labelConfirm="Concluir"
          handleConfirm={() =>
            history.push("/teams/management", {
              currentPageDetail: "teamMember",
            })
          }
        >
          <S.Label>
            Um link para finalizar o cadastro foi enviado para
            <S.Highlight> {email}</S.Highlight>. Você receberá um e-mail de
            confirmação quando {name} concluir o cadastro.
          </S.Label>
        </ConfirmAlert>
      )}

      <Page
        title="Revisão do cadastro"
        subtitle="Certifique-se de que todas as informações estão corretas."
        style={{ position: "relative" }}
      >
        <S.Wrapper>
          <Input
            name="Nome*"
            placeholder="Nome do cliente"
            value={name}
            disabled
          />
        </S.Wrapper>

        <S.Wrapper>
          <Input
            name="Email*"
            placeholder="Ex: email@email.com.br"
            value={email}
            disabled
          />
        </S.Wrapper>

        <S.Wrapper>
          <Label>Equipe*</Label>
          <ListItemCard
            image={whoami?.companyLogo || ""}
            title={whoami?.companyName || ""}
            roundedImage
            disabled
          />
        </S.Wrapper>

        <S.Wrapper>
          <Label>Cargo* </Label>

          <ListItemCard
            image={mappedProfileIcons[memberRole.id]}
            title={memberRole.name}
            subtitle={memberRole.default ? "Pré-definido" : "Personalizado"}
            disabled
          />
        </S.Wrapper>

        <S.Wrapper>
          <Label>Permissões</Label>
          <ModuleList modules={memberRole.modules} />
        </S.Wrapper>

        <Button
          loading={loading}
          onClick={handleSubmit}
          style={{ position: "fixed", bottom: 20, width: 500 }}
        >
          Enviar convite
        </Button>
      </Page>
    </>
  );
}
