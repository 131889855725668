import styled, { css, keyframes } from "styled-components";

interface OverlayProps {
  haveOverlay: boolean;
  overflow?: string;
}

const appearFromRight = keyframes`
  from{
    opacity: 0;
    transform: translateX(50%)
  } to {
    opacity: 1;
    transform: translateX(0)
  }
`;

const fadeInOverlay = keyframes`
  from {
    opacity: 0;
  } to {
    opacity: 1;
  }
`;

export const Container = styled.div`
  ${({ theme }) => css`
    width: 100%;
    height: 100%;
    min-height: 100vh;
    position: fixed;
    inset: 0;
    z-index: ${theme.layers.modal};

    display: flex;
    overflowY: auto;
  `}
`;

export const Overlay = styled.section<OverlayProps>`
  inset: 0;
  position: fixed;
  background-color: rgba(0, 0, 0, 0.5);
  animation: ${fadeInOverlay} 0.2s ease-in-out;
`;

export const Content = styled.section<OverlayProps>`
  ${({ theme, haveOverlay, overflow }) => css`

    width: ${haveOverlay ? "740px" : "800px"};
    max-width: ${haveOverlay ? "740px" : "800px"}
    min-width: ${haveOverlay ? "620px" : "600px"};
    overflow: ${overflow ? overflow : "auto"};
    display: flex;
    flex-direction: column;
    align-items: center;
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: ${theme.layers.alwaysOnTop};

    background-color: ${theme.colors.strong_background};
    box-shadow: 10px 5px 5px black;
    animation: ${appearFromRight} 0.6s ease-in-out backwards;
    animation-delay: 0.1s;

    header {
      width: 100%;
      min-height: 62px;
    }
  `}
`;

export const Wrapper = styled.section`
  width: 90%;
  padding: 30px;
`;
