import styled, { css } from "styled-components";

export const Top = styled.div`
  ${({ theme }) => css`
    margin-bottom: 30px;

    display: flex;
    align-items: center;

    p {
      font-size: ${theme.font.sizes.large};
      color: ${theme.colors.title};
      font-weight: ${theme.font.bold};
    }
  `}
`;

export const BackButton = styled.button`
  ${({ theme }) => css`
    width: 35px;
    height: 35px;
    background-color: ${theme.colors.background_light};
    border-radius: 10px;

    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 25px;
  `}
`;

export const Content = styled.main`
  margin-top: 50px;
`;

export const WrapperInput = styled.div`
  margin-bottom: 15px;
`;

export const WrapperCheckbox = styled.div`
  width: 290px;
  margin-bottom: 15px;

  display: flex;
  align-items: flex-end;
  justify-content: space-between;
`;

export const GroupInputs = styled.div`
  margin-bottom: 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  & > div {
    width: 80%;
  }

  & > div:last-of-type {
    width: 16%;
    margin-left: 14px;
  }
`;

export const WrapperButton = styled.div`
  margin-top: 40px;
`;
