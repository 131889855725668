import styled, { css } from "styled-components";

export const Container = styled.button`
  ${({ theme }) => css`
    width: 35px;
    height: 35px;
    border-radius: 10px;

    display: flex;
    justify-content: center;
    align-items: center;

    background-color: ${theme.colors.primary_opacity};

    & > img {
      width: 12px;
      height: 12px;
    }
  `}
`;
