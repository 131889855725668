import styled, { css } from "styled-components";
import {
  fadeInfo,
  translateToTop,
  translateToRight,
  translateToRightImg,
} from "../animations";

import { generateMedia } from "styled-media-query";

const customMedia = generateMedia({
  medium: "1000px",
});

type Props = {
  isActive: boolean;
};

type AnimationProps = {
  isActive: boolean;
};

export const ScrollView = styled.div`
  max-height: 100vh;
  overflow-y: scroll;
  padding-bottom: 100px;
  background-color: ${({ theme }) => theme.colors.strong_background};
`;

export const Container = styled.div`
  min-height: 100vh;
  overflow: hidden;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const Header = styled.header`
  margin-bottom: 44px;
  display: flex;
  flex-direction: column;
`;

export const LogoProfiz = styled.img<AnimationProps>`
  ${({ isActive }) => css`
    width: 42px;
    height: 42px;

    ${customMedia.greaterThan("medium")`
      ${
        !isActive &&
        css`
          animation: ${translateToRightImg} 1s forwards;
        `
      }
    `}
  `}
`;

export const Content = styled.main`
  margin-top: 40px;
  width: 60%;
  max-width: 600px;
  margin-bottom: 40px;

  ${customMedia.lessThan("medium")`
    width: 80%;
  `}
`;

export const WrapperInfo = styled.div<AnimationProps>`
  ${({ isActive }) => css`
    ${customMedia.greaterThan("medium")`
      ${
        !isActive &&
        css`
          animation: ${fadeInfo} 1.5s forwards;
        `
      }
    `}
  `}
`;

export const Title = styled.h4`
  ${({ theme }) => css`
    font-size: ${theme.font.sizes.large};
    font-weight: ${theme.font.bold};
    color: ${theme.colors.title};
    margin-bottom: 6px;
  `}
`;

export const Text = styled.p`
  ${({ theme }) => css`
    font-size: ${theme.font.sizes.xsmall};
    color: ${theme.colors.text};
    line-height: 20px;
  `}
`;

export const ButtonText = styled.button<AnimationProps>`
  ${({ theme, isActive }) => css`
    text-align: left;
    margin-top: 8px;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    cursor: pointer;

    ${customMedia.greaterThan("medium")`
      ${
        !isActive &&
        css`
          animation: ${translateToRight} 1s forwards;
        `
      }
    `}

    & > span {
      font-weight: 550;
      font-size: ${theme.font.sizes.xsmall};
      color: ${theme.colors.primary};
      opacity: 0.6;

      &:hover {
        text-decoration: underline;
      }
    }

    & > img {
      width: 15px;
      height: 15px;
      margin-left: 8px;
    }
  `}
`;

export const Separator = styled.div`
  ${({ theme }) => css`
    width: 130%;
    height: 1px;
    background-color: ${theme.colors.line};
    opacity: 0.6;
    margin-bottom: 40px;
  `}
`;

export const WrapperAction = styled.div<AnimationProps>`
  ${({ theme, isActive }) => css`
    width: 70%;
    margin-top: 44px;
    padding-top: 44px;
    border-top: 1px solid ${theme.colors.line};

    ${customMedia.lessThan("medium")`
      width: 100%;
    `}

    ${customMedia.greaterThan("medium")`
      ${
        !isActive &&
        css`
          animation: ${translateToTop} 1s forwards;
        `
      }
    `}

    & > div {
      display: flex;
      justify-content: space-between;
      align-items: flex-end;

      ${customMedia.lessThan("medium")`
        display: block;
      `}
    }
  `}
`;

export const WrapperButtons = styled.div`
  margin-left: 40px;
  width: 160px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  ${customMedia.lessThan("medium")`
    margin-left: 0;
    margin-top: 14px;
    width: 140px;
  `}
`;

export const Subtitle = styled.h6<AnimationProps>`
  ${({ theme, isActive }) => css`
    margin-bottom: 30px;

    ${customMedia.greaterThan("medium")`
    ${
      !isActive &&
      css`
        animation: ${fadeInfo} 1.5s forwards;
      `
    }
    `}

    font-size: ${theme.font.sizes.small};
    color: #ffffffde;
  `}
`;

export const Wrapper = styled.div`
  width: 100% !important;

  ${customMedia.lessThan("medium")`
    margin-top: 60px;
  `}
`;

export const WrapperButton = styled.div`
  width: 100% !important;

  ${customMedia.lessThan("medium")`
    padding-bottom: 24px;
  `}
`;

export const WrapperForm = styled.div`
  width: 80%;
  margin-top: 36px;
  flex-direction: column;
  align-items: flex-start !important;

  & > div {
    margin-bottom: 10px;
  }

  ${customMedia.lessThan("medium")`
    width: 100%;
  `}
`;

export const Button = styled.button<Props>`
  ${({ theme, isActive }) => css`
    height: 35px;
    width: 65px;
    border-radius: 8px;

    display: flex;
    align-items: center;
    justify-content: center;

    font-size: ${theme.font.sizes.xsmall};
    font-weight: 500;
    transition opacity 0.3s ease-in-out;

    ${
      isActive
        ? css`
            background-color: ${theme.colors.primary};
            color: ${theme.colors.background};
          `
        : css`
            background-color: ${theme.colors.background_modal};
            color: ${theme.colors.text};
          `
    }

    &:hover {
      opacity: 0.6;
    }
  `}
`;
