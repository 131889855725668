import React, { useEffect, useState } from "react";
import { EventItemProps, EventsListItemProps } from "../types";

import * as S from "./styles";

import favoriteIcon from "assets/icons/events-icons/favoriteIcon.svg";
import notFavoriteIcon from "assets/icons/events-icons/notFavoriteIcon.svg";

import phoneIcon from "assets/icons/events-icons/phoneIcon.svg";
import emailIcon from "assets/icons/events-icons/emailIcon.svg";
import shareIcon from "assets/icons/events-icons/shareIcon.svg";

import { Button } from "components/Button";
import { useToast } from "hooks/toast";
import { useEvent } from "hooks/events";
import { api } from "services/api";

import { useTheme } from "styled-components";
import { FiX } from "react-icons/fi";

type EventProps = {
  typeView?: number; // 0 -> all events , 1 -> favorite events
  handleCloseFunction: () => void;
};

const getMonthName = (idx: number) => {
  const names = [
    "Jan.",
    "Fev.",
    "Mar.",
    "Abr.",
    "Mai.",
    "Jun.",
    "Jul.",
    "Ago.",
    "Set.",
    "Out.",
    "Nov.",
    "Dez.",
  ];
  return names[idx];
};

const getDayName = (idx: number) => {
  const names = ["Dom.", "Seg.", "Ter.", "Qua.", "Qui.", "Sex.", "Sáb."];
  return names[idx];
};

const formatedAddressFunction = (event: EventItemProps) => {
  const streetNumberFormatted = !!event.address?.street
    ? !!event.address.number
      ? `${event.address.street}, ${event.address.number}`
      : `${event.address.street}`
    : "";

  const cityUfFormatted = !!event.address?.city
    ? !!event.address.uf
      ? `${event.address.city} - ${event.address.uf}`
      : `${event.address.city}`
    : "";

  const postalCodeFormatted = !!event.address?.postalCode
    ? `CEP: ${event.address.postalCode}`
    : "";

  const addressFormatted = !!event.address?.district
    ? `${streetNumberFormatted} - ${event.address.district} - ${cityUfFormatted}, ${postalCodeFormatted}`
    : `${streetNumberFormatted} ${cityUfFormatted} ${postalCodeFormatted}`;

  return addressFormatted;
};

export function DetailEvent({ typeView, handleCloseFunction }: EventProps) {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { addToast } = useToast();

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const theme = useTheme();

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const {
    allEvents,
    savedEvents,
    handleSetAllEvents,
    handleSetSavedEvents,
    selectedEvent,
    handleSetSelectedEvent,
  } =
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEvent();

  const [textCopied, setTextCopied] = useState(false);
  const [isHovering, setIsHovering] = useState(false);
  const [toolTip, setToolTip] = useState(false);

  // eslint-disable-next-line react-hooks/rules-of-hooks
  //let element = selectedEvent;
  //useState<EventItemProps | any>(selectedEvent);
  const setFavorite = (element: EventItemProps) => {
    if (selectedEvent && selectedEvent.id === element.id) {
      let event = { ...selectedEvent, saved: !selectedEvent.saved };
      handleSetSelectedEvent(event);
    }

    return element.saved
      ? disfavorEvent(element.id)
      : favoriteEvent(element.id, element);
  };

  async function favoriteEvent(
    idElement: number,
    eventCurrent: EventItemProps
  ) {
    try {
      await api.post(`/events/${idElement}/favorites/add`);
      let dataAux: EventsListItemProps[] =
        typeView === 0 ? allEvents : savedEvents;
      dataAux = dataAux.map((element) => {
        return element.id === idElement
          ? {
              ...element,
              saved: !element.saved,
            }
          : element;
      });

      if (typeView === 1) {
        let addelement: EventsListItemProps = {
          id: eventCurrent.id,
          date: eventCurrent.date,
          title: eventCurrent.title,
          organization: eventCurrent.organization,
          saved: !eventCurrent.saved ? !eventCurrent.saved : eventCurrent.saved,
          image: eventCurrent.image,
          modality: eventCurrent.modality,
          subscriptionLink: eventCurrent.subscriptionLink,
          address: eventCurrent.address,
        };
        dataAux = [...dataAux, addelement].sort((e1, e2) => {
          return e1.date.localeCompare(e2.date);
        });
      }
      typeView === 0
        ? handleSetAllEvents(dataAux)
        : handleSetSavedEvents(dataAux);
      addToast({
        title: "Ok!!",
        description: "Evento salvo na lista de favoritos.",
        type: "success",
      });
    } catch (error) {
      addToast({
        title: "Ops!!",
        description: "Erro ao favoritar o evento.",
        type: "error",
      });
    }
  }

  async function disfavorEvent(idElement: number) {
    try {
      await api.delete(`/events/favorites/${idElement}/remove`);
      let dataAux: EventsListItemProps[] =
        typeView === 0 ? allEvents : savedEvents;
      dataAux = dataAux.map((element) => {
        return element.id === idElement
          ? {
              ...element,
              saved: !element.saved,
            }
          : element;
      });

      if (typeView === 1) {
        dataAux = dataAux.filter((element) => {
          return element.saved === true;
        });

        if (selectedEvent && selectedEvent.id === idElement) {
          handleSetSelectedEvent(null);
        }

        handleCloseFunction();
      }
      typeView === 0
        ? handleSetAllEvents(dataAux)
        : handleSetSavedEvents(dataAux);

      addToast({
        title: "Ok!!",
        description: "Evento retirado da lista de favoritos.",
        type: "success",
      });
    } catch (error) {
      addToast({
        title: "Ops!!",
        description: "Erro ao desfavoritar o evento.",
        type: "error",
      });
    }
  }

  function shareEvent() {
    const eventLink = `${
      window.location.origin
    }/events/public/${selectedEvent.hash.toString()}`;
    navigator.clipboard.writeText(eventLink);

    setTextCopied(true);
  }

  useEffect(() => {
    if (textCopied && isHovering) {
      setToolTip(true);
    }

    return () => {
      setToolTip(false);
    };
  }, [isHovering, textCopied]);

  return (
    <>
      {selectedEvent ? (
        <S.CardItem>
          <S.ButtonClose onClick={handleCloseFunction}>
            <FiX size={10} color={theme.colors.text} />
          </S.ButtonClose>

          <S.WrapperImg>
            <img src={selectedEvent.image} alt="imagem" />
          </S.WrapperImg>

          <S.WrapperHeader>
            <S.WrapperTitle>
              <S.WrapperDataIconCard>
                <h5>{new Date(selectedEvent.date).getDate()}</h5>
                <p>{getMonthName(new Date(selectedEvent.date).getMonth())}</p>
              </S.WrapperDataIconCard>

              <S.WrapperMid>
                <S.HeaderTitle>{selectedEvent.title}</S.HeaderTitle>
                <p>{selectedEvent.subtitle}</p>
                <S.WrapperDescription>
                  <p>{selectedEvent.description}</p>
                </S.WrapperDescription>
              </S.WrapperMid>
              <S.WrapperFavButton
                onClick={() => {
                  setFavorite(selectedEvent);
                }}
              >
                <img
                  src={selectedEvent.saved ? favoriteIcon : notFavoriteIcon}
                  alt="imagem"
                />
              </S.WrapperFavButton>
            </S.WrapperTitle>
          </S.WrapperHeader>

          <S.WrapperInfoMid>
            <h5>Detalhes</h5>

            <h6>Organizador do evento</h6>
            <p>{selectedEvent.organization}</p>

            {selectedEvent && selectedEvent.periods.length > 0 && (
              <h6>Data / Horário</h6>
            )}
            {selectedEvent &&
              selectedEvent.periods.length > 0 &&
              selectedEvent.periods.map((period: any, index: any) => {
                return (
                  <p key={index}>
                    {getDayName(new Date(period.startDate).getDay())},{" "}
                    {new Date(period.startDate).getDate()} de{" "}
                    {getMonthName(new Date(period.startDate).getMonth())}{" "}
                    {period.startDate.split(" ")[1].substring(0, 5)} -{" "}
                    {period.endDate.split(" ")[1].substring(0, 5)}
                  </p>
                );
              })}

            <h6>Modalidade</h6>
            <p>{selectedEvent.modality}</p>

            {selectedEvent.modality.toLowerCase() !== "online" &&
              selectedEvent.address && (
                <>
                  <h6>Endereço</h6>
                  <a
                    href={`https://www.google.com/maps/search/?api=1&query=${selectedEvent.address.street},${selectedEvent.address.number}-${selectedEvent.address.district},${selectedEvent.address.city}-${selectedEvent.address.uf},${selectedEvent.address.postalcode}`}
                    target={"_blank"}
                    rel="noopener noreferrer"
                  >
                    {formatedAddressFunction(selectedEvent)}
                  </a>
                </>
              )}

            <h6>Investimento</h6>
            <p>
              {selectedEvent.value !== 0
                ? selectedEvent.value.toLocaleString("pt-BR", {
                    style: "currency",
                    currency: "BRL",
                  })
                : "Gratuito"}
            </p>

            <h6>Tipo de evento</h6>
            <p>{selectedEvent.typeEvent}</p>

            <h6>Possui certificado</h6>
            <p>{selectedEvent.hasCertification}</p>

            {selectedEvent.subscription && (
              <>
                <h6>Inscrições</h6>
                <p>
                  {selectedEvent.subscription.startDate
                    ? `${new Date(
                        selectedEvent.subscription.startDate
                      ).getDate()} de ${getMonthName(
                        new Date(
                          selectedEvent.subscription.startDate
                        ).getMonth()
                      )} `
                    : "      "}
                  {selectedEvent?.subscription.startDate &&
                  selectedEvent?.subscription.endDate
                    ? "à"
                    : "-"}
                  {selectedEvent.subscription.endDate &&
                    ` ${new Date(
                      selectedEvent.subscription.endDate
                    ).getDate()} de ${getMonthName(
                      new Date(selectedEvent.subscription.endDate).getMonth()
                    )}`}
                </p>
              </>
            )}

            <h6>Link das inscrições</h6>
            <a
              href={selectedEvent.subscriptionLink}
              target={"_blank"}
              rel="noopener noreferrer"
            >
              {selectedEvent.subscriptionLink}
            </a>
          </S.WrapperInfoMid>

          <S.WrapperInfoContact>
            <h5>Entre em contato</h5>

            <S.ContainerIconContact>
              {/*icone*/}
              <img src={phoneIcon} alt="imagem" />
              <S.ContainerTextContact>
                <h6>Telefone / Whatsapp</h6>
                <p>
                  {(selectedEvent.contact.telephone || "") +
                    (!!selectedEvent.contact.number &&
                    !!selectedEvent.contact.telephone
                      ? "/"
                      : "") +
                    (selectedEvent.contact.number || "")}

                  {!selectedEvent.contact.telephone &&
                    !selectedEvent.contact.number &&
                    "Não informado pelo organizador"}
                </p>
              </S.ContainerTextContact>
            </S.ContainerIconContact>

            <S.ContainerIconContact>
              {/*icone*/}
              <img src={emailIcon} alt="imagem" />
              <S.ContainerTextContact>
                <h6>E-mail</h6>
                <p>
                  {selectedEvent &&
                  selectedEvent.contact &&
                  selectedEvent.contact.email
                    ? selectedEvent.contact.email
                    : "Não informado pelo organizador"}
                </p>
              </S.ContainerTextContact>
            </S.ContainerIconContact>

            <S.ShareWrapper
              onMouseEnter={() => {
                setIsHovering(true);
              }}
              onMouseLeave={() => {
                setIsHovering(false);
              }}
            >
              <Button
                style={{ marginTop: "40px" }}
                typeButton="outlinePurple"
                onClick={() => {
                  shareEvent();
                }}
              >
                <img src={shareIcon} alt="compartilhe o evento" />
                <S.TextButton>Compartilhar</S.TextButton>
              </Button>

              {!!toolTip && (
                <S.ToolTipBox>
                  <S.TextToolTip>Link Copiado!</S.TextToolTip>
                </S.ToolTipBox>
              )}
            </S.ShareWrapper>
          </S.WrapperInfoContact>
        </S.CardItem>
      ) : null}
    </>
  );
}
