import { Route, Switch } from "react-router-dom";
import { TeamRegistrationPage } from "pages/permission/TeamRegistrationPage";
import { TeamInvitePage } from "pages/permission/TeamInvitePage";
import { PermissionsPage } from "pages/permission/PermissionsPage";
import { RefuseInvitePage } from "pages/permission/RefuseInvitePage";
import { AppStoreRedirectPage } from "pages/permission/AppStoreRedirectPage";

export function PermissionPublicRoutes() {
  return (
    <Switch>
      <Route
        path="/public/team-invite/:registerHash"
        exact
        component={TeamInvitePage}
      />
      <Route
        path="/permission/public/register/:registerHash"
        exact
        component={TeamRegistrationPage}
      />
      <Route
        path="/permission/public/permissions-list/:registerHash"
        exact
        component={PermissionsPage}
      />
      <Route
        path="/permission/public/refuse-invite"
        exact
        component={RefuseInvitePage}
      />
      <Route
        path="/permission/public/app-redirect"
        exact
        component={AppStoreRedirectPage}
      />
    </Switch>
  );
}
