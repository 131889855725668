import React, { useEffect, useState } from "react";

import CopyLinkIcon from "assets/icons/member-get-member/copy-link-icon.svg";
import ShareIcon from "assets/icons/member-get-member/share-icon.svg";
import ProfitzSVG from "assets/icons/member-get-member/profitz-cash-icon.svg";
import UserIconSVG from "assets/icons/member-get-member/user-share-icon.svg";

import { ArrowButton } from "components/ArrowButton";

import CardHelpComponent from "components/CardHelpComponent";
import { LoadingProfiz } from "components/LoadingProfiz";
import { ShareOnSocialMediaModal } from "components/ShareOnSocialMediaModal";

import { useHistory } from "react-router";

import { CampaignDTO } from "dtos/campaignDTO";

import addMaskMoney from "utils/addMaskMoney";

import { api } from "services/api";

import { useToast } from "hooks/toast";

import * as S from "./styles";
import { UserDTO } from "dtos/UserDTO";

type IndicationDetailsProps = {
  profitzValue: string;
  indicationRulesIndexes: number;
} & CampaignDTO;

export function HowItWorks() {
  const { addToast } = useToast();
  const history = useHistory();

  const [isLoading, setIsLoading] = useState(true);
  const [indicationDetails, setIndicationDetails] =
    useState<IndicationDetailsProps>({} as IndicationDetailsProps);

  const [isCopied, setIsCopied] = useState(false);

  const [shareModalIsVisible, setShareModalIsVisible] = useState(false);

  const [indicationUrl, setIndicationUrl] = useState("");

  const [isProfileApproved, setIsProfileApproved] = useState<boolean>();

  useEffect(() => {
    async function getIndicationDetails() {
      try {
        const { data } = await api.get<CampaignDTO>(
          "/users/me/member-get-member/campaign"
        );

        const profitzValue = addMaskMoney(data.profitzRealValue);

        const indicationRulesIndexes = data.indicationRules.length - 1;

        const indicationFormatted = {
          ...data,
          profitzValue,
          indicationRulesIndexes,
        };

        setIndicationDetails(indicationFormatted);
      } catch (error) {
        addToast({
          title: "Ops",
          description: "Ocorreu um erro ao carregar os detalhes de indicações",
          type: "error",
        });
      } finally {
        setIsLoading(false);
      }
    }

    getIndicationDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    (async () => {
      try {
        const { data } = await api.get<UserDTO>("users/me");

        setIsProfileApproved(data.profileApproved);
        setIndicationUrl(data.indication.url);
      } catch (error) {
        addToast({
          title: "Ops",
          description: "Não foi possível obter o link de indicação",
          type: "error",
        });
      }
    })();
  });

  const handleCopyIndicationURL = () => {
    if (!isProfileApproved) {
      history.push("/complete-profile");

      addToast({
        title: "Ops!",
        description: "Complete seu perfil para ter acesso ao link de indicação",
        type: "info",
      });

      return;
    }

    navigator.clipboard.writeText(indicationUrl);

    setIsCopied(true);
  };

  const handleShare = () => {
    if (!isProfileApproved) {
      history.push("/complete-profile");

      addToast({
        title: "Ops!",
        description: "Complete seu perfil para ter acesso ao link de indicação",
        type: "info",
      });

      return;
    }

    setShareModalIsVisible(true);
  };

  const truncateStr = (str: string, size: number) => {
    var shortText = str;
    if (str.length >= size + 3) {
      shortText = str.substring(0, size).concat("...");
    }
    return shortText;
  };

  const DropdownQuestion = () => {
    return (
      <>
        <S.InfoTitle>1 Profitz = {indicationDetails.profitzValue}</S.InfoTitle>

        <S.InfoWrapper>
          {indicationDetails.indicationRules.map((elem, i) => {
            if (indicationDetails.indicationRulesIndexes === i) {
              return (
                <div key={elem.id}>
                  <S.ItemInfoWrapper>
                    <S.Circle>
                      <S.ProfitzCoinText>
                        {elem.indicationQuantity}
                      </S.ProfitzCoinText>
                      <img src={UserIconSVG} alt="usuarios" />
                    </S.Circle>

                    <img src={ProfitzSVG} alt="profitz" />

                    <S.TextWrapper>
                      <S.ProfitzText>
                        {elem.profitzCashback} Profitz
                      </S.ProfitzText>
                      {elem.description && (
                        <S.ProfitzBonusText>
                          {elem.description}
                        </S.ProfitzBonusText>
                      )}
                    </S.TextWrapper>
                  </S.ItemInfoWrapper>
                </div>
              );
            } else {
              return (
                <div key={elem.id}>
                  <S.ItemInfoWrapper>
                    <S.Circle>
                      <S.ProfitzCoinText>
                        {elem.indicationQuantity}
                      </S.ProfitzCoinText>
                      <img src={UserIconSVG} alt="usuarios" />
                    </S.Circle>

                    <img src={ProfitzSVG} alt="profitz" />

                    <S.TextWrapper>
                      <S.ProfitzText>
                        {elem.profitzCashback} Profitz
                      </S.ProfitzText>
                      <S.ProfitzBonusText>
                        {elem.description}
                      </S.ProfitzBonusText>
                    </S.TextWrapper>
                  </S.ItemInfoWrapper>

                  <S.Line />
                </div>
              );
            }
          })}
        </S.InfoWrapper>

        <S.ChangeProfitzText>Troque seus Profitz</S.ChangeProfitzText>

        {indicationDetails.cashback && (
          <S.CashbackItemsWrapper>
            {indicationDetails.cashback.map((elem) => {
              return (
                <S.CashbackItem key={elem.id}>
                  <S.CashbackItemImage src={elem.image} />
                  <S.CashbackItemText>{elem.title}</S.CashbackItemText>
                </S.CashbackItem>
              );
            })}
          </S.CashbackItemsWrapper>
        )}
      </>
    );
  };

  return (
    <>
      {isLoading ? (
        <LoadingProfiz isVisible={isLoading} />
      ) : (
        <S.Container>
          <ShareOnSocialMediaModal
            isVisible={shareModalIsVisible}
            onCloseModal={() => {
              setShareModalIsVisible(false);
            }}
          ></ShareOnSocialMediaModal>
          <S.Header>
            <div>
              <ArrowButton />
              <h1>Indique e ganhe</h1>
              <p>
                Ganhe até{" "}
                <strong>
                  {indicationDetails.campaign.limit.toLocaleString()} Profitz
                </strong>
                , troque por prêmios e vantagens!
              </p>
            </div>
          </S.Header>

          <S.Content>
            <CardHelpComponent title={"Como funcionam as indicações"}>
              {DropdownQuestion()}
            </CardHelpComponent>

            <S.ShareArea>
              {isProfileApproved ? (
                <S.IndicationLink>
                  {truncateStr(indicationUrl, 35)}
                </S.IndicationLink>
              ) : (
                <S.IndicationLink>
                  {truncateStr(
                    `${window.location.origin}/register/indication/perfilNaoAprovado`,
                    35
                  )}
                </S.IndicationLink>
              )}
              <S.CopyButton onClick={handleCopyIndicationURL}>
                <img src={CopyLinkIcon} alt="copiar link de indicação" />
                <S.CopyText>{isCopied ? "Copiado" : "Copiar"}</S.CopyText>
              </S.CopyButton>

              <S.ShareButton onClick={handleShare}>
                <img src={ShareIcon} alt="compartilhar" />
                <p>Compartilhar</p>
              </S.ShareButton>
            </S.ShareArea>

            <S.MyEarningsButton
              onClick={() => {
                history.push("/my-earnings");
              }}
            >
              <S.MyEarningsButtonText>Meus ganhos</S.MyEarningsButtonText>
            </S.MyEarningsButton>
          </S.Content>
        </S.Container>
      )}
    </>
  );
}
