import styled, { css } from 'styled-components'

type ContainerCheckboxProps = {
  hasError: boolean
}

type ButtonProps = {
  isSelected: boolean
}

export const Container = styled.div<ButtonProps>`
  ${({ theme, isSelected }) => css`
    width: 100%;
    padding: 12px 14px 14px;
    border-radius: 10px;
    background-color: ${theme.colors.background_light};

    border: 1px solid ${isSelected ? theme.colors.primary : 'transparent'};
  `};
`

export const Header = styled.button`
  width: 100%;
`

export const WrapperCheckbox = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 12px;
`

export const CheckBox = styled.div<ContainerCheckboxProps>`
  ${({ theme, hasError }) => css`
    height: 15px;
    width: 16px;
    margin-right: 14px;

    display: flex;
    justify-content: center;
    align-items: center;

    border-radius: 3px;
    background-color: transparent;
    border: 1px solid ${hasError ? theme.colors.error : '#707070'};
  `}
`

export const CheckBoxContent = styled.div<ButtonProps>`
  ${({ theme, isSelected }) => css`
    height: 7px;
    width: 7px;
    border-radius: 1.4px;
    background-color: ${isSelected ? theme.colors.primary : 'transparent'};
  `}
`

export const ItemChecklistName = styled.span`
  ${({ theme }) => css`
    font-size: ${theme.font.sizes.small};
    font-family: ${theme.font.family};
    color: ${theme.colors.title};
  `}
`

export const Content = styled.div`
  width: 100%;
`

export const Label = styled.div`
  ${({ theme }) => css`
    text-align: left;
    margin-right: 15px;
    font-size: ${theme.font.sizes.xsmall};
    font-family: ${theme.font.family};
    color: ${theme.colors.text};
  `}
`

export const WrapperButton = styled.div``

export const InformativeText = styled.span`
  ${({ theme }) => css`
    display: block;
    text-align: left;
    font-size: ${theme.font.sizes.xsmall};
    font-family: ${theme.font.family};
    color: ${theme.colors.error};
    margin-top: 16px;
  `}
`

export const Footer = styled.div`
  width: 100%;
  margin-top: 16px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`

export const InputContainer = styled.div`
  ${({ theme }) => css`
    width: 36%;
    height: 45px;
    padding-right: 10px
    margin-left: 12px;
    
    display: flex;
    align-items: center;
    justify-content: space-between;
    
    border-radius: 12px;
    border: 1px solid ${theme.colors.text_light};

    &:focus-within {
      border: 1px solid ${theme.colors.primary};
    }
  `}
`

// export const InputPrice = styled.input`
//   ${({ theme }) => css`
//     width: 80%;
//     height: 90%;
//     padding-left: 15px;
//     font-size: ${theme.font.sizes.small};
//     font-family: ${theme.font.family};
//     color: ${theme.colors.text_extra_light};
//     background: none;
//     border: none;
//     outline: none;

//     &::placeholder {
//       color: ${theme.colors.text};
//     }
//   `}
// `

export const ButtonEditPrice = styled.button`
  height: 45px;
  width: 26%;

  display: flex;
  align-items: center;
  justify-content: center;
  transition: opacity 0.2s;

  &:hover {
    opacity: 0.7;
  }
  img {
    width: 16px;
    height: 16px;
  }
`
