import React from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";

import pmocSVG from "assets/icons/pmoc-icons/icon-pmoc.svg";
import X from "assets/icons/pmoc-icons/X-icon.svg";

import { UnscheduledServiceOrders } from "./UnscheduledServiceOrders";
import { ScheduledServiceOrders } from "./ScheduledServiceOrders";
import { ConcludedServiceOrders } from "./ConcludedServiceOrders";
import { ArchivedServiceOrders } from "./ArchivedServiceOrders";

import { usePmocTabIndex } from "hooks/pmoc/tabIndex";
import { useResumePmoc } from "hooks/resumePmoc";

import addMaskDate from "utils/addMaskDate";

import * as S from "./styles";

export function DetailOfMonthPmoc() {
  const { selectedIndex, handleSetSelectedIndex } = usePmocTabIndex();
  const { pmoc, monthSelected, handleToggleModalResumePmoc } = useResumePmoc();

  return (
    <>
      <S.Container>
        <S.CloseButton onClick={handleToggleModalResumePmoc}>
          <img src={X} alt="fechar detalhes de PMOC" />
        </S.CloseButton>
        <S.TitleDate>
          {monthSelected.name} de {monthSelected.year}
        </S.TitleDate>
        <S.Subtitle>
          Visualize o histórico de ordens de serviço deste PMOC.
        </S.Subtitle>
        <S.CardPmoc>
          <S.CardPmocImg>
            <img src={pmocSVG} alt="icone pmoc" />
          </S.CardPmocImg>

          <S.CardPmocContent>
            <S.CardPmocTitle>{pmoc.name}</S.CardPmocTitle>
            <S.CardPmocSubtitle>
              {`Início: ${addMaskDate(pmoc.startDate)}  |  Período: ${
                pmoc.contractPeriod.period
              } ${pmoc.contractPeriod.type}`}
            </S.CardPmocSubtitle>
          </S.CardPmocContent>
        </S.CardPmoc>

        {/* <S.FilterButton>
          <h1>Filtrar</h1>
        </S.FilterButton> */}

        <S.TabContainer>
          <Tabs
            selectedIndex={selectedIndex}
            onSelect={(index: number) => {
              handleSetSelectedIndex(index);
            }}
          >
            <TabList>
              <Tab>Não agendadas</Tab>
              <Tab>Agendadas</Tab>
              <Tab>Concluídas</Tab>
              <Tab>Arquivadas</Tab>
            </TabList>

            <TabPanel>
              <UnscheduledServiceOrders />
            </TabPanel>

            <TabPanel>
              <ScheduledServiceOrders />
            </TabPanel>

            <TabPanel>
              <ConcludedServiceOrders />
            </TabPanel>

            <TabPanel>
              <ArchivedServiceOrders />
            </TabPanel>
          </Tabs>
        </S.TabContainer>
      </S.Container>
    </>
  );
}
