import { UnityDTO } from "dtos/ClientDTO";

export function mappedUnity(selectedUnity: UnityDTO) {
  let completeUnity = { ...selectedUnity };

  if (!completeUnity.email) {
    completeUnity.email = "";
  }
  if (!completeUnity.phone) {
    completeUnity.phone = "";
  }
  if (!completeUnity.complement) {
    completeUnity.complement = "";
  }
  if (!completeUnity.additionalInfo) {
    completeUnity.additionalInfo = "";
  }

  return completeUnity;
}
