import styled, { css } from "styled-components";

export const Container = styled.div`
  margin: -50px 1px;
  width: 100%;
`;

export const CloseButton = styled.button`
  ${({ theme }) => css`
    width: 35px;
    height: 35px;
    background: ${theme.colors.background_light};
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
  `}
`;

export const TitleDate = styled.h1`
  ${({ theme }) => css`
    font-family: ${theme.font.family};
    font-style: normal;
    font-weight: ${theme.font.bold};
    font-size: 18px;
    line-height: 22px;
    color: rgba(255, 255, 255, 0.87);
    margin-top: 30px;
  `}
`;

export const Subtitle = styled.p`
  ${({ theme }) => css`
    font-family: ${theme.font.family};
    font-style: normal;
    font-weight: ${theme.font.normal};
    font-size: 12px;
    line-height: 15px;
    color: rgba(255, 255, 255, 0.6);
    margin-top: 4px;
  `}
`;

export const CardPmoc = styled.div`
  ${({ theme }) => css`
    width: 100%;
    height: 65px;
    margin-top: 22px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: ${theme.colors.background_light};
    border-radius: 10px;
  `}
`;

export const CardPmocImg = styled.div`
  ${({ theme }) => css`
    width: 35px;
    height: 35px;

    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(184, 149, 255, 0.1);

    border-radius: 10px;
  `}
`;

export const CardPmocContent = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 10px;
`;

export const CardPmocTitle = styled.h1`
  ${({ theme }) => css`
    font-family: ${theme.font.family};
    font-style: normal;
    font-weight: ${theme.font.bold};
    font-size: 14px;
    line-height: 17px;
    color: rgba(255, 255, 255, 0.87);
  `}
`;

export const CardPmocSubtitle = styled.h1`
  ${({ theme }) => css`
    font-family: ${theme.font.family};
    font-style: normal;
    font-weight: ${theme.font.normal};
    font-size: 12px;
    line-height: 15px;
    color: rgba(255, 255, 255, 0.6);
  `}
`;

export const FilterButton = styled.button`
  ${({ theme }) => css`
    margin-top: 25px;

    width: 100%;
    height: 45px;

    box-sizing: border-box;
    border: 1px solid ${theme.colors.primary};
    border-radius: 10px;

    display: flex;
    justify-content: center;
    align-items: center;

    h1 {
      font-family: ${theme.font.family};
      font-style: normal;
      font-weight: ${theme.font.normal};
      font-size: 12px;
      line-height: 15px;

      color: rgba(255, 255, 255, 0.87);
    }
  `}
`;

export const TabContainer = styled.div`
  ${({ theme }) => css`
    margin-top: 50px;

    .react-tabs__tab-list {
      border-bottom: 1px solid rgba(255, 255, 255, 0.12);
      display: flex;
      justify-content: space-between;
    }

    .react-tabs__tab {
      margin-right: 30px;
      color: ${theme.colors.text};
    }

    .react-tabs__tab--selected {
      background: inherit;
      border: 0px;
      color: ${theme.colors.text};

      font-weight: 500;
      line-height: 1.35;
      white-space: normal;
      letter-spacing: 0.02857em;
      border-bottom: 3px solid ${theme.colors.primary_light};
    }
  `}
`;
