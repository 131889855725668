import React, { useState } from "react";

import { EditButton } from "components/EditButton";
import { DeleteButton } from "components/DeleteButton";
import { AlertModal } from "components/AlertModal";

import { ServiceDTO } from "dtos/ServiceDTO";
import addMaskMoney from "utils/addMaskMoney";

import * as S from "./styles";

type CheckBoxServiceProps = {
  data: ServiceDTO;
  onSelect: () => void;
  handleChangeService: (data: ServiceDTO) => void;
  handleDeleteService: (idService: number) => void;
};

export function CheckBoxService({
  data,
  onSelect,
  handleChangeService,
  handleDeleteService,
}: CheckBoxServiceProps) {
  const [isSelected, setIsSelected] = useState(data.checked);
  const [isVisibleModal, setIsVisibleModal] = useState(false);

  function handleSelectService() {
    setIsSelected((prev) => !prev);

    onSelect();
  }

  function handleToggleModal() {
    handleDeleteService(data.id);
    setIsVisibleModal(false);
  }

  return (
    <>
      <AlertModal
        isVisible={isVisibleModal}
        title="Deseja apagar?"
        description="Deseja realmente apagar esse serviço?"
        action="choose"
        handleConfirm={() => handleToggleModal()}
        onCloseModal={() => setIsVisibleModal(false)}
      />

      <S.Container isSelected={isSelected}>
        <S.Button onClick={() => handleSelectService()}>
          <S.CheckBox>
            <S.CheckedFilled isSelected={isSelected} />
          </S.CheckBox>

          <S.Content>
            <S.WrapperDetail>
              <S.WrapperInfo>
                <S.Title>{data.service.name}</S.Title>
                {data.equipment.name ? (
                  <S.Description>
                    {data.equipment.name}{" "}
                    {!!data.equipmentType?.id && data.equipmentType.name}{" "}
                    {!!data.capacity?.name && `| ${data.capacity.name}`}
                  </S.Description>
                ) : (
                  <S.Description>Serviço sem equipamento.</S.Description>
                )}
              </S.WrapperInfo>

              {!!data?.brand && (
                <S.Description>
                  {!!data?.brand && `Marca: ${data.brand} `}
                  {!!data?.runtime?.time &&
                    `| Execução: ${data?.runtime?.time} `}
                  {!!data.runtime?.extension && data.runtime.extension}
                </S.Description>
              )}

              {!!data?.warranty?.time && (
                <S.Description>
                  {!!data?.warranty?.time &&
                    `Garantia: ${data?.warranty?.time} `}
                  {!!data?.warranty?.extension &&
                    data?.warranty?.time &&
                    data?.warranty?.extension}
                </S.Description>
              )}

              {data.serviceChecklist && data.serviceChecklist.length > 0 && (
                <S.Checklist>
                  <p>
                    {data.serviceChecklist?.length} checklist(s) vinculado(s)
                  </p>
                </S.Checklist>
              )}
            </S.WrapperDetail>
            <S.WrapperPrice>
              <p>{addMaskMoney(data.price)}</p>
            </S.WrapperPrice>
          </S.Content>
        </S.Button>

        <S.WrapperActions>
          <EditButton onClick={() => handleChangeService(data)} />
          <DeleteButton onClick={() => setIsVisibleModal(true)} />
        </S.WrapperActions>
      </S.Container>
    </>
  );
}
