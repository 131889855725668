import styled, { css } from "styled-components";

type TypeButton = {
  isIconButton: boolean;
};

type LabelProps = {
  typeButton: string;
};

export const Container = styled.div`
  margin-bottom: 30px;
`;

export const Title = styled.p`
  ${({ theme }) => css`
    font-size: 14px;
    font-family: ${theme.font.family};
    color: ${theme.colors.text_extra_light};
    letter-spacing: 0.26px;
    line-height: 16px;
  `}
`;

export const Content = styled.div`
  display: flex;
  flex-direction: row;
`;

export const Wrapper = styled.div<TypeButton>`
  ${({ isIconButton }) => css`
    width: ${isIconButton ? "150px" : "110px"};
    margin-right: 5px;
  `}
`;

export const Label = styled.p<LabelProps>`
  ${({ theme, typeButton }) => css`
    font-size: 12px;
    font-family: ${theme.font.family};
    color: ${typeButton === "financialFilterSelected"
      ? theme.colors.detail
      : theme.colors.text};
  `}
`;

type TIcon = {
  typeButton: string;
};

export const ViewCard = styled.div<TIcon>`
  ${({ theme, typeButton }) => css`
    display: flex;
    width: 35px;
    height: 35px;
    align-items: center;
    padding-top: 5px;
    padding-bottom: 5px;
    border-radius: 8px;
    margin-right: 5px;
    background: ${typeButton === "financialFilterSelected"
      ? theme.colors.background
      : theme.colors.background_light};
  `}
`;

export const ImageIcon = styled.img`
  width: 35px;
  height: 35px;
`;
