import { useHistory, useLocation } from "react-router";

import { Button } from "components/Button";
import { AccountData } from "dtos/permission/AnswerInviteDTO";

import InviteIcon from "assets/icons/permission/leave.svg";

import * as S from "./styles";

type LocationProps = {
  account: AccountData;
};

export function InviteRefused() {
  const history = useHistory();
  const { state } = useLocation<LocationProps>();

  return (
    <S.Container>
      <h1>Convite recusado</h1>
      <img src={InviteIcon} alt="Icone convite" />

      <S.Content>
        <S.Description>Você recusou o convite de </S.Description>
        <S.Highlight>{state.account.name}</S.Highlight>
        <S.Description> para fazer da equipe.</S.Description>

        <Button onClick={() => history.push("/")}>Ir para a home</Button>
      </S.Content>
    </S.Container>
  );
}
