import { lighten } from "polished";
import styled, { css } from "styled-components";

type ButtonProps = {
  typeButton: "primary" | "secondary";
  isSelected: boolean;
  colorSelected: string;
};

type WrapperProps = {
  isOpenMenu: boolean;
};

type LabelProps = {
  titleColor?: string;
};

type TagTypeProps = {
  color?: string;
  bgColor?: string;
};

export const Button = styled.button<ButtonProps>`
  ${({ theme, typeButton, isSelected, colorSelected }) => css`
    width: 100%;
    height: 45px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    position: relative;
    padding: 8px;
    margin-top: 1px;
    margin-bottom: 5px;
    border-radius: 10px;
    background-color: ${isSelected
      ? colorSelected
      : typeButton === "primary"
      ? theme.colors.menu_detail
      : theme.colors.menu_detail_light};

    text-decoration: none;
    cursor: pointer;

    &:hover {
      background: ${isSelected
        ? colorSelected
        : lighten(0.1, theme.colors.menu_detail_light)};
    }
  `}
`;

export const Wrapper = styled.div<WrapperProps>`
  ${({ theme, isOpenMenu }) => css`
    width: 100%;
    display: flex;
    justify-content: ${isOpenMenu ? "flex-start" : "center"};
    align-items: center;

    img {
      width: 35px;
      height: 34px;
    }

    p {
      font-family: ${theme.font.family};
      font-size: ${theme.font.sizes.xsmall};
      font-weight: ${theme.font.bold};
      color: ${theme.colors.text_light_87}
      line-height: 15px;
      margin-left: 14px;
    }
  `}
`;

export const Label = styled.p<LabelProps>`
  ${({ theme, titleColor }) => css`
    font-family: ${theme.font.family};
    font-size: ${theme.font.sizes.xsmall};
    font-weight: ${theme.font.bold};
    color: ${titleColor ? titleColor : theme.colors.text_light_87};
    line-height: 15px;
    margin-left: 14px;
  `}
`;

export const TextDetail = styled.p`
  ${({ theme }) => css`
    font-family: ${theme.font.family};
    font-size: ${theme.font.sizes.xsmall};
    font-weight: ${theme.font.normal};
    color: ${theme.colors.text_light};
    line-height: 14px;
  `}
`;

export const IconWrapper = styled.div`
  position: absolute;
  right: 15px;

  img {
    width: 15px;
    height: 18px;
  }
`;

export const DivTag = styled.div<TagTypeProps>`
  ${({ theme, color, bgColor }) => css`
    display: flex;

    padding: 0px 5px;
    background-color: ${bgColor ? bgColor : theme.colors.primary_opacity};
    border: solid 1px ${color ? color : theme.colors.primary};
    border-radius: 12px;
    margin-left: 5px;

    span {
      font-size: 10px;
      font-family: ${theme.font.family};
      color: ${color ? color : theme.colors.primary};
    }
  `}
`;
