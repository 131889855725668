import React, { createContext, useContext, useState } from "react";
import { ServiceDTO } from "../../dtos/ServiceDTO";
import addMaskMoney from "../../utils/addMaskMoney";
import { ProviderProps } from "hooks";

export type UpdateServiceData = Omit<
  ServiceDTO,
  "total" | "formattedTotal" | "quantity" | "idBudgetService"
>;

type SelectedServiceContextData = {
  selectedServices: ServiceDTO[];
  checkedServices: ServiceDTO[];
  latestServiceAsChecked: boolean;
  handleSetSelectedServices: (services: ServiceDTO[]) => void;
  handleSetCheckedServices: (services: ServiceDTO[]) => void;
  handleSetLatestServiceAsChecked: (isChecked: boolean) => void;
  handleUpdateQuantity: (id: number, quantity: number) => void;
  calculateTotalServices: () => number;
  clearServicesData: () => void;
  updatedSelectedService: (service: UpdateServiceData) => void;
  handleRemoveSelectedService: (id: number) => void;
};

const SelectedServiceContext = createContext<SelectedServiceContextData>(
  {} as SelectedServiceContextData
);

export function useSelectedService(): SelectedServiceContextData {
  const context = useContext(SelectedServiceContext);

  if (!context) {
    throw Error(
      "useSelectedService must be used within a SelectedServiceProvider"
    );
  }

  return context;
}

export const SelectedServiceProvider = ({ children }: ProviderProps) => {
  const [selectedServices, setSelectedServices] = useState<ServiceDTO[]>([]);

  const [checkedServices, setCheckedServices] = useState<ServiceDTO[]>([]);

  const [latestServiceAsChecked, setLatestServiceAsChecked] = useState(false);

  function handleSetSelectedServices(services: ServiceDTO[]) {
    setSelectedServices(services);
  }

  function handleSetCheckedServices(services: ServiceDTO[]) {
    setCheckedServices(services);
  }

  function handleSetLatestServiceAsChecked(isChecked: boolean) {
    setLatestServiceAsChecked(isChecked);
  }

  function handleUpdateQuantity(id: number, quantity: number) {
    const updatedQuantityService = selectedServices.map((selectedService) => {
      if (
        selectedService.idBudgetService &&
        selectedService.idBudgetService === id
      ) {
        return {
          ...selectedService,
          total: selectedService.price * quantity,
          formattedTotal: addMaskMoney(selectedService.price * quantity),
          quantity,
        };
      } else if (selectedService.id === id) {
        return {
          ...selectedService,
          total: selectedService.price * quantity,
          formattedTotal: addMaskMoney(selectedService.price * quantity),
          quantity,
        };
      }
      return selectedService;
    });

    setSelectedServices(updatedQuantityService);
  }

  function calculateTotalServices() {
    return selectedServices.reduce((accumulator, current) => {
      return accumulator + current.total;
    }, 0);
  }

  function updatedSelectedService(service: UpdateServiceData) {
    const payloadSelectedServices = selectedServices.map((selectedService) => {
      if (service.id === selectedService.id) {
        return {
          ...service,
          total: selectedService.quantity * service.price,
          formattedTotal: addMaskMoney(
            selectedService.quantity * service.price
          ),
          quantity: selectedService.quantity,
        };
      }

      return selectedService;
    }) as ServiceDTO[];
    setSelectedServices(payloadSelectedServices);
  }

  function clearServicesData() {
    setSelectedServices([]);
    setCheckedServices([]);
  }

  function handleRemoveSelectedService(id: number) {
    const parsedFilter = selectedServices.filter(
      (selectedService) => selectedService.id !== id
    );
    setSelectedServices(parsedFilter);
  }

  return (
    <SelectedServiceContext.Provider
      value={{
        selectedServices,
        checkedServices,
        latestServiceAsChecked,
        handleSetSelectedServices,
        handleSetCheckedServices,
        handleUpdateQuantity,
        calculateTotalServices,
        clearServicesData,
        updatedSelectedService,
        handleRemoveSelectedService,
        handleSetLatestServiceAsChecked,
      }}
    >
      {children}
    </SelectedServiceContext.Provider>
  );
};
