import { StageProps } from "dtos/ServiceStagesDTO";

export function switchServiceStage(stage: StageProps) {
  switch (stage) {
    case "before":
      return "Registros antes do serviço";
    case "while":
      return "Registros durante o serviço";
    case "after":
      return "Registros depois do serviço";
    case "default":
      return "Registros gerais do serviço";
    default:
      return "Assinaturas do serviço";
  }
}
