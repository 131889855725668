import { useState } from "react";

import { AccountModal } from "..";
import { useAccount } from "hooks/permission/account";

import * as S from "./styles";

export function SelectCurrentAccount() {
  const { accounts } = useAccount();
  const [isOpenChangeModal, setIsOpenChangeModal] = useState(false);

  function handleToggleChangeAccount() {
    setIsOpenChangeModal(!isOpenChangeModal);
  }

  if (accounts.length <= 1) return <></>;

  return (
    <>
      <AccountModal
        isVisible={isOpenChangeModal}
        onCloseModal={handleToggleChangeAccount}
      />

      <S.ChangeButton onClick={handleToggleChangeAccount}>
        Trocar
      </S.ChangeButton>
    </>
  );
}
