import { lighten } from "polished";
import styled, { css } from "styled-components";

interface ClientItemProps {
  isSelected?: boolean;
}

export const Top = styled.div`
  ${({ theme }) => css`
    margin-bottom: 30px;

    display: flex;
    align-items: center;

    p {
      font-size: ${theme.font.sizes.large};
      color: ${theme.colors.title};
      font-weight: ${theme.font.bold};
    }
  `}
`;

export const ButtonClose = styled.button`
  ${({ theme }) => css`
    width: 35px;
    height: 35px;
    background-color: ${theme.colors.background_light};
    border-radius: 10px;

    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 25px;
  `}
`;

export const Content = styled.main`
  div:nth-child(2) {
    margin-top: 24px;
    margin-bottom: 20px;
  }
`;

export const ClientItem = styled.li<ClientItemProps>`
  ${({ theme, isSelected }) => css`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border-bottom: 1px solid ${theme.colors.background_light};
    border: ${
      isSelected && `1px solid ${theme.colors.primary_light}`};
    border-radius: ${isSelected && "10px"};

    span, p {
      color: ${isSelected && `${theme.colors.primary_light} !important`}
    }
  `}
`;

export const EditClientButton = styled.button`
  ${({ theme }) => css`
    width: 10% !important;
    align-items: center;
    justify-content: center;
    color: ${theme.colors.text_extra_light};
    :hover {
      background-color: ${lighten(0.2, theme.colors.background_light)};
    }
  `}
`;

export const ListClients = styled.ul`
  ${({ theme }) => css`
    list-style: none;

    button {
      height: 54px;
      display: flex;
      align-items: center;
    }

    .select-client {
      padding-left: 8px;
      width: 100%;
    }

    li {

      p {
        font-size: ${theme.font.sizes.xsmall};
        color: ${theme.colors.text};
      }
    }

    /* .select-client:nth-child(1) {
      border-top: 1px solid ${theme.colors.background_light};
    } */

    .select-client:hover {
      background-color: ${lighten(0.2, theme.colors.background_light)};
    }
  `}
`;

export const Profile = styled.div`
  ${({ theme }) => css`
    height: 35px;
    width: 35px;
    border-radius: 50%;
    margin-right: 12px;

    display: flex;
    justify-content: center;
    align-items: center;
    background-color: ${theme.colors.background_light};

    span {
      text-transform: uppercase;
      letter-spacing: 0.4px;
      font-size: ${theme.font.sizes.xsmall};
      color: ${theme.colors.text_extra_light};
    }
  `}
`;
