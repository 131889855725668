import { FormEvent, useEffect, useState } from "react";
import { FiChevronLeft } from "react-icons/fi";
import { useTheme } from "styled-components";
import Modal from "react-modal";
import * as Yup from "yup";

import logoProfiz from "assets/icons/logo-profiz.svg";

import { Button } from "components/Button";

import { api } from "services/api";
import { getCep } from "services/cep";

import { useToast } from "hooks/toast";
import { AreasOfExpertise, useAuth } from "hooks/auth";

import getValidationErrors from "utils/getValidationErrors";

import "rc-slider/assets/index.css";
import * as S from "./styles";

Modal.setAppElement("#root");

type ActuationAreaModalProps = {
  isOpen: boolean;
  setIsOpen: (value: boolean) => void;
};

type ExpertiseArea = {
  id: string;
  name: string;
};

type ArrayExpertise = {
  letter: string;
  items: ExpertiseArea[];
};

type ExpertiseForm = {
  cellPhone: string;
  cep: string;
  city: string;
  state: string;
  street: string;
  neighborhood: string;
  distanceExpertice: number;
};

type Errors = {
  [key: string]: string;
};

const ActuationAreaModal = ({ isOpen, setIsOpen }: ActuationAreaModalProps) => {
  const { user, updateUser } = useAuth();
  const { addToast } = useToast();

  const theme = useTheme();

  const [step, setStep] = useState(0);
  const [expertiseArea, setExpertiseArea] = useState<ArrayExpertise[]>([]);
  const [loadingExpertiseArea, setLoadingExpertiseArea] = useState(false);
  const [checkedExpertise, setCheckedExpertise] = useState<string[]>([]);
  const [expertiseForm, setExpertiseForm] = useState<ExpertiseForm>({
    distanceExpertice: 10,
    cep: user.address?.cep,
    cellPhone: user.phone,
  } as ExpertiseForm);
  const [, setHasError] = useState<Errors>({} as Errors);

  const loadExpertiseArea = async () => {
    try {
      setLoadingExpertiseArea(true);
      const { data } = await api.get("/users/areas-of-expertise");
      const arrayToSet: ArrayExpertise[] = [];
      const letterArray: string[] = [];
      const filteredData = data.areasOfExpertise.sort(
        (a: AreasOfExpertise, b: AreasOfExpertise) =>
          a.name.localeCompare(b.name)
      );

      filteredData.forEach((item: ExpertiseArea) => {
        const letterArrayIndex = letterArray.findIndex(
          (letter) => item.name[0] === letter
        );
        if (letterArrayIndex < 0) {
          letterArray.push(item.name[0]);
          arrayToSet.push({
            letter: item.name[0],
            items: [item],
          });
        } else {
          const index = arrayToSet.findIndex(
            (setLetter) => setLetter.letter === item.name[0]
          );
          arrayToSet[index].items.push(item);
        }
      });

      setExpertiseArea(arrayToSet);
      setLoadingExpertiseArea(false);
    } catch (error) {
      addToast({
        title: "Erro",
        description: "Ocorreu um erro ao carregar os banners.",
        type: "error",
      });
    }
  };

  const getChecked = (id: string) => {
    return checkedExpertise.findIndex((item) => item === id);
  };

  const checkExpertise = (id: string) => {
    const index = checkedExpertise.findIndex((item) => item === id);
    if (index < 0) {
      setCheckedExpertise([...checkedExpertise, id]);
    } else {
      checkedExpertise.splice(index, 1);
      setCheckedExpertise([...checkedExpertise]);
    }
  };

  const handleSubmit = async () => {
    try {
      await api.put(`users/me`, {
        areasOfExpertise: checkedExpertise,
        phone: expertiseForm.cellPhone.replace(/[^0-9]/g, ""),
      });

      updateUser({
        ...user,
        areasOfExpertise: checkedExpertise,
      });

      setIsOpen(false);
      if (user.profileComplete && user.profileApproved) {
      } else {
        setStep(3);
      }

      addToast({
        title: "Sucesso !",
        description: "Informações salvas com sucesso !",
        type: "success",
      });
    } catch (error) {
      if (error instanceof Yup.ValidationError) {
        const errors = getValidationErrors(error);
        setHasError(errors);

        addToast({
          title: "Ops...",
          description: "Preencha todos os campos obrigatórios",
          type: "error",
        });

        return;
      }
    }
  };

  const loadingCep = async () => {
    const formattedCep = expertiseForm.cep.replaceAll("_", "").replace("-", "");
    if (formattedCep.length < 8) return;

    const response = await getCep(expertiseForm.cep);
    setExpertiseForm({
      ...expertiseForm,
      city: response.localidade,
      state: response.uf,
      street: response.logradouro,
      neighborhood: response.bairro,
    });
  };

  useEffect(() => {
    loadExpertiseArea();
    if (expertiseForm.cep.length > 0) {
      loadingCep();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      background: theme.colors.background,
      border: "none",
      width: 570,
      borderRadius: 10,
      maxHeight: "90vh",
    },
  };

  const handleSubmitForm = (event: FormEvent<HTMLFormElement>): void => {
    event.preventDefault();
  };

  return (
    <Modal
      isOpen={isOpen}
      style={{
        ...customStyles,
        overlay: {
          background: "rgba(0, 0, 0, 0.5)",
          zIndex: 1000,
        },
      }}
    >
      <S.ModalContent>
        {step !== 0 && step !== 3 && (
          <S.BackButton onClick={() => setStep(step - 1)}>
            <FiChevronLeft size={16} color={theme.colors.primary} />
          </S.BackButton>
        )}

        {step === 0 && (
          <form id="form_area_atuacao" onSubmit={handleSubmitForm}>
            <S.TextContainer style={{ marginTop: 0 }}>
              <S.BolderText style={{ color: "rgba(255, 255, 255, 0.87)" }}>
                Falta pouco para finalizar seu cadastro!
              </S.BolderText>
              <S.Text style={{ color: "rgba(255, 255, 255, 0.60)" }}>
                Indique suas áreas de atuação e receba oportunidades de serviços
                baseadas em suas qualificações.
              </S.Text>
              <S.BolderText
                style={{
                  color: "rgba(255, 255, 255, 0.87)",
                  fontSize: 14,
                  marginTop: 20,
                }}
              >
                Selecione abaixo todas suas áreas de atuação
              </S.BolderText>
            </S.TextContainer>
            <S.ExpertiseAreaContainer>
              {loadingExpertiseArea ? (
                <S.Loading>
                  <img src={logoProfiz} alt="logo animado da profiz" />
                </S.Loading>
              ) : (
                expertiseArea.map((item) => {
                  return (
                    <S.ExpertiseByLetter key={item.letter}>
                      <S.Text
                        style={{
                          color: theme.colors.primary,
                        }}
                      >
                        {item.letter}
                      </S.Text>
                      <S.Divider />
                      {item.items.map((expertise: ExpertiseArea) => (
                        <div key={expertise.id}>
                          <S.ExpertiseContainer>
                            <S.Checkbox
                              onClick={() => checkExpertise(expertise.id)}
                            >
                              {getChecked(expertise.id) >= 0 && <S.Check />}
                            </S.Checkbox>
                            <S.Text
                              style={{
                                marginLeft: 10,
                                fontSize: 12,
                                marginTop: 0,
                                color: "rgba(255, 255, 255, 0.87)",
                              }}
                            >
                              {expertise.name}
                            </S.Text>
                          </S.ExpertiseContainer>
                          <S.Divider />
                        </div>
                      ))}
                    </S.ExpertiseByLetter>
                  );
                })
              )}
            </S.ExpertiseAreaContainer>
            <Button
              onClick={() => {
                handleSubmit();
              }}
              style={{ marginTop: "30px" }}
              typeButton="default"
              disabled={checkedExpertise.length === 0}
            >
              Salvar
            </Button>
          </form>
        )}
      </S.ModalContent>
    </Modal>
  );
};

export default ActuationAreaModal;
