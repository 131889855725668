import styled, { css } from "styled-components";

export const Content = styled.div`
  padding: 25px;
`;

export const Header = styled.div`
  margin-bottom: 40px;
`;

export const ContainerProduct = styled.div`
  display: flex;
  gap: 25px;
`;

export const SelectedPhoto = styled.img`
  max-width: 524px;
  min-width: 524px;
  width: 100%;
  height: 380px;
  border-radius: 10px;
  margin: 0 auto;
  margin-left: 25px;
  object-fit: cover;
`;

export const Informations = styled.div`
  ${({ theme }) => css`
    width: 493px;
    min-height: 380px;
    background-color: ${theme.colors.menu_background};
    height: 100%;
    padding: 23px 30px;
    border-radius: 10px;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
  `}
`;

export const Images = styled.div`
  display: flex;
  height: 100%;
`;

export const Title = styled.div`
  ${({ theme }) => css`
    color: ${theme.colors.text_light_87};
    font-weight: bold;
    margin-bottom: 20px;
  `}
`;

export const Label = styled.div`
  ${({ theme }) => css`
    color: ${theme.colors.text_light_87};
    font-size: 14px;
    font-weight: 400;
    text-overflow: ;
    white-space: pre-wrap;
  `}
`;

export const LabelSpan = styled.div`
  ${({ theme }) => css`
    color: ${theme.colors.text_light_87};
    font-size: 14px;
    font-weight: 400;
  `}
`;

export const DetailsObservations = styled.a`
  ${({ theme }) => css`
    border-bottom: solid 1px ${theme.colors.text_light_87};
  `}
`;

export const ContainerVeriticalPhotos = styled.div`
  display: flex;
  flex-direction: column;
  gap: 25px;
`;

export const VerticalPhoto = styled.img`
  width: 112px;
  height: 110px;
  border-radius: 10px;
  cursor: pointer;
  object-fit: cover;
`;

export const BlankPhoto = styled.img`
  background-image: url("src/assets/blankImage.svg");
  width: 112px;
  height: 110px;
  border-radius: 10px;
`;
