import styled, { css } from "styled-components";

export const Container = styled.div`
  width: 100%;
`;

export const Header = styled.header`
  ${({ theme }) => css`
    display: flex;
    padding: 30px;

    h1 {
      display: flex;
      font-size: ${theme.font.sizes.large};
      color: ${theme.colors.title};
      font-weight: ${theme.font.bold};
      margin-top: 30px;
    }

    p {
      font-size: ${theme.font.sizes.medium};
      color: ${theme.colors.title};
      margin-top: 15px;
    }

    strong {
      font-weight: ${theme.font.bold};
    }
  `}
`;

export const Content = styled.div`
  align-items: center;
  padding-left: 30px;
`;

export const ProfitzInfoWrapper = styled.div`
  height: 100px;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const ProfitzInfo = styled.div`
  margin-left: 12px;
  display: flex;
  flex-direction: column;
`;

export const ProfitzTitle = styled.p`
  ${({ theme }) => css`
    font-family: ${theme.font.normal};
    font-size: ${theme.font.sizes.small};
    line-height: 12px;
    color: #fff595;
  `}
`;

export const ProfitzCounter = styled.p`
  ${({ theme }) => css`
    font-family: ${theme.font.normal};
    font-size: 18px;
    line-height: 22px;
    color: white;
    margin-top: 2px;
    margin-bottom: 6px;
  `}
`;

export const ProfitzBottomText = styled.p`
  ${({ theme }) => css`
    font-family: ${theme.font.normal};
    font-size: ${theme.font.sizes.small};
    line-height: 12px;
    color: white;
  `}
`;

export const Line = styled.div`
  background: rgba(220, 220, 220, 0.1);
  width: 470px;
  height: 1.5px;
  border-radius: 10px;
  margin-top: 25px;
  margin-bottom: 25px;
`;

export const ShareText = styled.p`
  ${({ theme }) => css`
    font-family: ${theme.font.family};
    font-weight: ${theme.font.bold};
    font-size: ${theme.font.sizes.small};
    color: white;

    line-height: 15px;
    width: 100%;
    margin-bottom: 16px;
  `}
`;

export const ShareArea = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    justify-content: space-between;

    width: 470px;
    height: 45px;
    background: ${theme.colors.background_modal};
    border-radius: 10px;

    &:hover {
      cursor: pointer;
    }
  `}
`;

export const IndicationLink = styled.p`
  ${({ theme }) => css`
    font-family: ${theme.font.family};
    font-weight: ${theme.font.normal};
    font-size: ${theme.font.sizes.xsmall};
    line-height: 16px;
    color: #ffffffde;
    margin: 15px;
  `}
`;

export const CopyButton = styled.button`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const CopyText = styled.p`
  ${({ theme }) => css`
    font-family: ${theme.font.family};
    font-weight: ${theme.font.normal};
    font-size: ${theme.font.sizes.xsmall};
    line-height: 16px;
    color: #ffffffde;
    margin-left: 8px;
    text-decoration: underline;
  `}
`;

export const ShareButton = styled.button`
  ${({ theme }) => css`
    display: flex;
    align-items: center;

    height: 45px;
    background: ${theme.colors.primary};
    border-radius: 10px;
    margin-left: 20px;
    padding-right: 24px;
    padding-left: 24px;
    p {
      margin-left: 8px;
    }

    img {
      margin-top: 1px;
    }
  `}
`;

export const ExtractButton = styled.button`
  ${({ theme }) => css`
    margin-top: 30px;
    width: 470px;
    height: 45px;
    background: ${theme.colors.background};
    border-radius: 10px;
    border: 1px solid ${theme.colors.primary};
    justify-content: center;
    align-items: center;
  `}
`;

export const ExtractButtonText = styled.p`
  ${({ theme }) => css`
    font-family: ${theme.font.family};
    color: ${theme.colors.primary};
    font-size: ${theme.font.sizes.small};
  `}
`;
