import { useEffect, useRef, useState } from "react";
import {
  addDays,
  addHours,
  addMinutes,
  format,
  isAfter,
  isBefore,
  lastDayOfMonth,
  parseISO,
  startOfMonth,
} from "date-fns";

import { useSelectedServiceToSchedule } from "hooks/selectedServiceToSchedule";
import { useAccount } from "hooks/permission/account";
import { useToast } from "hooks/toast";

import { LoadingProfiz } from "components/LoadingProfiz";
import { EmptyPage } from "components/EmptyPage";
import { AlertModal } from "components/AlertModal";
import { Button } from "components/Button";
import { Calendar, DayModifiers } from "components/Calendar";
import { Input } from "components/Input";
import { ButtonContentBox } from "components/ButtonContentBox";
import { MemberList, ShowWhenHavePermission } from "components/Permission";
import { UserExecutionCard } from "components/UserExecutionCard";

import trashSVG from "assets/icons/trash-disabled.svg";

import { ClientProps } from "dtos/ClientDTO";
import { ServiceItemProps, UserExecutionProps } from "dtos/ServiceOrderDetailDTO";

import apiv2 from "services/apiv2";

import * as S from "./styles";

type DateTimeProps = {
  time: string;
  date: string;
};

type ResponseProps = {
  data: {
    services: ServiceScheduledProps[];
  };
};

type CalendarToScheduleServiceProps = {
  currentScheduleDate?: string;
  handleConfirmDateTime: ({ date, time }: DateTimeProps) => void;
  handleCancel: () => void;
  onDeleteServiceSchedule: () => void;
  showDeleteOption?: boolean;
  blockChangeSchedule?: boolean;
};

export type ServiceScheduledProps = {
  date?: string;
  title?: string;
  events?: any;
  user?: {
    photo?: string;
    name?: string;
  };
  service: any;
  idBudgetService: number;
  idServiceOrder: number;
  statusServiceOrder: string;
  status: string;
  client: ClientProps;
  scheduleDate: string;
  finishDate: string;
  estimatedCompletionDate: string;
  estimatedCompletionTime: number;
  serviceDetails: ServiceItemProps;
  sequenceNumber: number;
  userExecution?: UserExecutionProps;
};

export function CalendarToScheduleService({
  currentScheduleDate,
  handleConfirmDateTime,
  handleCancel,
  onDeleteServiceSchedule,
  showDeleteOption = true,
  blockChangeSchedule = false
}: CalendarToScheduleServiceProps) {
  const { addToast } = useToast();

  const [isLoading, setIsLoading] = useState(true);
  const [isVisibleModal, setIsVisibleModal] = useState(false);
  const [isVisibleDeleteScheduleModal, setIsVisibleDeleteScheduleModal] =
    useState(false);

  const [selectedDate, setSelectedDate] = useState<Date | undefined>(undefined);
  const [selectedDateFormatted, setCurrentDateFormatted] = useState("");
  const [selectedHour, setSelectedHour] = useState("");
  const { selectedServiceToSchedule } = useSelectedServiceToSchedule();
  const hasConflicts = useRef(false);
  const [conflictsMessage, setConflictsMessage] = useState("");

  const [dayMonthSelected, setDayMonthSelected] = useState<Date>(new Date());

  const [allServices, setAllServices] = useState<ServiceScheduledProps[]>([]);
  const [allServicesByDate, setAllServicesByDate] = useState<
    ServiceScheduledProps[]
  >([]);
  const [datesWithServices, setDatesWithServices] = useState<string[]>([]);
  const [isAvailableToDelete, setIsAvailableToDelete] = useState(false);

  const { whoami } = useAccount();

  useEffect(() => {
    if (selectedServiceToSchedule.status === "scheduled" && showDeleteOption) {
      setIsAvailableToDelete(true);
    } else {
      setIsAvailableToDelete(false);
    }
  }, [selectedServiceToSchedule, showDeleteOption]);

  useEffect(() => {
    if (currentScheduleDate) {
      const [date, time] = currentScheduleDate.split(" ");
      const dateFormatted = addDays(new Date(date), 1);
      setSelectedDate(dateFormatted);
      setCurrentDateFormatted(date);
      setSelectedHour(time);
    } else {
      setSelectedDate(undefined);
      setCurrentDateFormatted("");
      setSelectedHour("");
    }
  }, []); // eslint-disable-line

  useEffect(() => {
    async function getServiceOrdersByDate() {
      try {
        const firstDayOfCurrentMonth = startOfMonth(dayMonthSelected);
        const lastDayOfCurrentMonth = lastDayOfMonth(dayMonthSelected);

        const currentDate = format(dayMonthSelected, "yyyy-MM-dd");
        const firstDayFormatted = format(firstDayOfCurrentMonth, "yyyy-MM-dd");
        const lastDayFormatted = format(lastDayOfCurrentMonth, "yyyy-MM-dd");

        const { data }: ResponseProps = await apiv2.get(
          "/budgets/service-orders/history",
          {
            params: {
              offset: 0,
              limit: 100,
              status: "scheduled",
              initDate: firstDayFormatted,
              endDate: lastDayFormatted,
              accountId: whoami?.id,
            },
          }
        );

        setAllServices(data.services);
        getDatesWithServices(data.services);

        let daySelectedOnSchedule = currentDate;

        if (!!currentScheduleDate) {
          const [date] = currentScheduleDate.split(" ");
          daySelectedOnSchedule = date;
        }

        const servicesScheduledByDate = data.services.filter(
          ({ scheduleDate }) => {
            const date = scheduleDate.split(" ")[0];

            return date === daySelectedOnSchedule;
          }
        );

        setAllServicesByDate(servicesScheduledByDate);
      } catch (error) {
        addToast({
          type: "error",
          title: "Ops...",
          description: "Erro ao buscar ordens de serviço",
        });
      } finally {
        setIsLoading(false);
      }
    }

    getServiceOrdersByDate();
  }, [dayMonthSelected, whoami?.id]); // eslint-disable-line

  function getDatesWithServices(services: ServiceScheduledProps[]) {
    const datesWithServices = services.map(({ scheduleDate }) => {
      return format(new Date(scheduleDate), "yyyy-MM-dd");
    });

    setDatesWithServices(datesWithServices);
  }

  function handleSelectTime(time: string) {
    setSelectedHour(time);
  }

  async function hasConflictsInSchedule() {
    try {
      setIsLoading(true);
      const { data }: ResponseProps = await apiv2.get(
        "/budgets/service-orders/history",
        {
          params: {
            offset: 0,
            limit: 100,
            status: "scheduled",
            initDate: selectedDateFormatted,
            endDate: selectedDateFormatted,
            accountId: whoami?.id,
          },
        }
      );

      const startAppointmentService = parseISO(
        `${selectedDateFormatted}T${selectedHour}`
      );

      const endAppointmentService = addMinutes(
        addHours(
          startAppointmentService,
          Number(selectedServiceToSchedule.serviceData?.runtime?.time)
        ),
        10
      );

      data.services.forEach(
        ({ estimatedCompletionDate, scheduleDate, idBudgetService }) => {
          if (selectedServiceToSchedule.idBudgetService === idBudgetService) {
            hasConflicts.current = false;
            return;
          }

          const startDateTime = parseISO(scheduleDate);
          const endDateTime = addMinutes(parseISO(estimatedCompletionDate), 10);

          if (
            startAppointmentService >= startDateTime &&
            endAppointmentService <= endDateTime
          ) {
            hasConflicts.current = true;
          }

          if (
            startAppointmentService <= startDateTime &&
            endAppointmentService >= startDateTime
          ) {
            hasConflicts.current = true;
          }

          if (
            startAppointmentService <= endDateTime &&
            endAppointmentService >= endDateTime
          ) {
            hasConflicts.current = true;
          }

          // Verificar se o horário do agendamento está dentro do gap de 10min ANTES de um serviço já agendado;
          if (
            isAfter(endAppointmentService, addMinutes(startDateTime, -10)) &&
            isBefore(endAppointmentService, endDateTime)
          ) {
            const timeScheduleService = scheduleDate.split(" ")[1].slice(0, 5);

            setConflictsMessage(
              `Você possui outro agendamento com início às ${timeScheduleService}. Deseja continuar mesmo assim? `
            );
            return;
          }

          // Verificar se o horário do agendamento está dentro do gap de 10min DEPOIS de um serviço já agendado;
          if (
            isBefore(startAppointmentService, endDateTime) &&
            isAfter(startAppointmentService, addMinutes(startDateTime, 10))
          ) {
            const endTimeServiceFormatted = estimatedCompletionDate
              .split(" ")[1]
              .slice(0, 5);

            setConflictsMessage(
              `Você possui outro agendamento com término às ${endTimeServiceFormatted}. Deseja continuar mesmo assim? `
            );
            return;
          }

          setConflictsMessage(
            "O horário selecionado está em conflito com outro agendamento, deseja continuar mesmo assim?"
          );
        }
      );
    } catch (err) {
      addToast({
        type: "error",
        title: "Ops...",
        description: "Erro ao validar possíveis conflitos de agendamento!",
      });
    } finally {
      setIsLoading(false);
    }
  }

  function handleToggleModal() {
    handleConfirmDateTime({
      date: selectedDateFormatted,
      time: selectedHour,
    });
    setIsVisibleModal(false);
  }

  async function onConfirm() {
    if (blockChangeSchedule) {
      addToast({
        title: "Erro",
        description: "Não é possivel reagendar essa OS",
        type: "error"
      });
      return;
    }

    if (!selectedDate) {
      addToast({
        type: "error",
        title: "Ops",
        description: "Selecione uma data antes",
      });
      return;
    }

    if (!selectedHour) {
      addToast({
        type: "error",
        title: "Ops",
        description: "Defina um horário antes",
      });
      return;
    }

    await hasConflictsInSchedule();

    if (hasConflicts.current) {
      setIsVisibleModal(true);
      hasConflicts.current = false;
      return;
    }

    handleConfirmDateTime({
      date: selectedDateFormatted,
      time: selectedHour,
    });
  }

  function handleChangeDate(day: Date, { selected, disabled }: DayModifiers) {
    if (disabled || selected) return;
    const formattedDate = format(day, "yyyy-MM-dd");

    const servicesScheduledByDate = allServices.filter(({ scheduleDate }) => {
      const date = scheduleDate.split(" ")[0];

      return date === formattedDate;
    });

    setSelectedDate(day);
    setCurrentDateFormatted(formattedDate);
    setAllServicesByDate(servicesScheduledByDate);
  }

  function onCancel() {
    handleCancel();
  }

  function handleGetDayMonthViewed(date: Date) {
    setDayMonthSelected(date);
  }

  async function handleDeleteServiceSchedule() {
    try {
      setIsVisibleDeleteScheduleModal(false);
      setIsLoading(true);

      const servicesToDeleteSchedule = [
        {
          id: selectedServiceToSchedule.serviceData.id,
          idBudgetService: selectedServiceToSchedule.idBudgetService,
        },
      ];

      const response = await apiv2.delete(
        `budgets/service-order/${selectedServiceToSchedule.serviceOrderId}/schedule`,
        {
          data: {
            services: servicesToDeleteSchedule,
          },
        }
      );

      if (response.data.sucesso) {
        addToast({
          type: "success",
          title: "Agendamento excluído com sucesso.",
        });

        setSelectedDate(undefined);
        setCurrentDateFormatted("");
        setSelectedHour("");

        onDeleteServiceSchedule();
      }
    } catch (error) {
      addToast({
        type: "error",
        title: "Não foi possível excluir o agendamento",
        description: "Certifique-se de que o serviço esteja agendado.",
      });
    } finally {
      setIsVisibleDeleteScheduleModal(false);
      setIsLoading(false);
    }
  }

  function validateOsOrigin() {
    if (blockChangeSchedule) {
      setIsVisibleDeleteScheduleModal(false);

      addToast({
        title: "Erro",
        description: "Não é possivel deletar o agendamento dessa OS",
        type: "error"
      });

    } else {
      handleDeleteServiceSchedule();
    }
  }
  return (
    <>
      <AlertModal
        isVisible={isVisibleModal}
        title="Conflito de agenda!"
        description={conflictsMessage}
        action="choose"
        handleConfirm={() => handleToggleModal()}
        onCloseModal={() => setIsVisibleModal(false)}
      />

      <AlertModal
        isVisible={isVisibleDeleteScheduleModal}
        title="Deseja realmente excluir o agendamento?"
        description="Esta é uma ação irreversível e após isso será necessário realizar um novo agendamento para o serviço."
        action="choose"
        handleConfirm={() => validateOsOrigin()}
        onCloseModal={() => setIsVisibleDeleteScheduleModal(false)}
      />

      {isLoading ? (
        <LoadingProfiz isVisible={isLoading} />
      ) : (
        <S.Content>
          <ShowWhenHavePermission
            moduleHash="schedule"
            actionHash="view-members-schedule"
          >
            <MemberList />
          </ShowWhenHavePermission>
          <S.WrapperCalendar>
            <Calendar
              selectDays={selectedDate}
              onChangeDate={handleChangeDate}
              datesWithServices={datesWithServices}
              onGetDayMonthViewed={handleGetDayMonthViewed}
            />

            <S.WrapperInput>
              <Input
                name="Definir horário"
                placeholder="Defina o horário"
                value={selectedHour}
                type="time"
                onChange={(e) => handleSelectTime(e.target.value)}
                // hasError={hasError.name}
                // onFocusClearError={() => setHasError({ ...hasError, name: '' })}
              />
            </S.WrapperInput>

            <S.WrapperButtons>
              <Button typeButton="outline" onClick={() => onCancel()}>
                Cancelar
              </Button>

              <Button onClick={() => onConfirm()}>Confirmar</Button>
            </S.WrapperButtons>

            {isAvailableToDelete && (
              <ShowWhenHavePermission moduleHash="schedule" actionHash="delete">
                <S.DeleteScheduleButton
                  onClick={() => setIsVisibleDeleteScheduleModal(true)}
                >
                  <img src={trashSVG} width={15} height={15} alt="Excluir" />
                  <p>Excluir agendamento</p>
                </S.DeleteScheduleButton>
              </ShowWhenHavePermission>
            )}
          </S.WrapperCalendar>

          <S.Description>Informações importantes sobre este dia</S.Description>
          {allServicesByDate.length === 0 ? (
            <EmptyPage />
          ) : (
            <>
              {allServicesByDate.map((service) => {
                const {
                  idBudgetService,
                  sequenceNumber,
                  scheduleDate,
                  finishDate,
                  status,
                  statusServiceOrder,
                  serviceDetails,
                  client,
                  userExecution
                } = service;

                const formattedDate = format(
                  new Date(scheduleDate),
                  "dd/MM/yyyy HH:mm"
                );
                const [date, hour] = formattedDate.split(" ");

                const scheduleDateText = `${date} às ${hour}`;

                const finishDateFormatted = finishDate
                  ? format(new Date(finishDate), "dd/MM/yyyy HH:mm")
                  : "";

                const [dateFinish, hourFinish] = finishDateFormatted.split(" ");

                return (
                  <S.WrapperOrder key={idBudgetService}>
                    <span>Agendado para: {scheduleDateText}</span>

                    <ButtonContentBox onClick={() => {}}>
                      <h4>
                        Serviço:{" "}
                        {`${serviceDetails.service.name} - ${serviceDetails.equipment.name}`}
                      </h4>

                      <S.WrapperStatusService>
                        <S.MarkerStatusService status={status} />
                        <h4 style={{ marginTop: 10 }}>
                          {status === "concluded"
                            ? "Concluído em: "
                            : "Em andamento"}
                          {finishDate && `${dateFinish} - ${hourFinish}`}
                        </h4>
                      </S.WrapperStatusService>

                      <p>Cliente: {client.name}</p>
                      <span>
                        Ordem de serviço: {sequenceNumber} (
                        {statusServiceOrder === "concluded"
                          ? "Concluído"
                          : "Em andamento"}
                        )
                      </span>

                      {!!userExecution?.name && <UserExecutionCard userExecution={userExecution} />}
                    </ButtonContentBox>
                  </S.WrapperOrder>
                );
              })}
            </>
          )}
        </S.Content>
      )}
    </>
  );
}
