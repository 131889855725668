import {
  ActionHash,
  useHasPermission,
} from "hooks/permission/useHasPermission";

type Props = {
  actionHash: ActionHash;
  moduleHash: string;
  children: React.ReactNode;
};

export function ShowWhenHavePermission({
  actionHash,
  moduleHash,
  children,
}: Props) {
  const { permission } = useHasPermission({
    actionHash,
    moduleHash,
  });

  return <>{permission && children}</>;
}
