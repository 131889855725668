import styled, { css } from "styled-components";

export const Container = styled.div`
  ${({ theme }) => css`
    display: flex;
    width: 100%;
    border-radius: 10px;
    background-color: ${theme.colors.background_light};
    overflow: hidden;
  `}
`;

export const Content = styled.button`
  width: 90%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 16px 0 18px 30px;

  & > div:first-of-type {
    width: 90%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 12px;
  }
`;

export const Title = styled.p`
  ${({ theme }) => css`
    font-size: 1.5rem;
    color: ${theme.colors.title};
    font-weight: ${theme.font.bold};
  `}
`;

export const PriceText = styled.p`
  ${({ theme }) => css`
    font-size: 1.5rem;
    color: ${theme.colors.title};
  `}
`;

export const Description = styled.p`
  ${({ theme }) => css`
  1
    font-size: 1.4rem;
    color: ${theme.colors.text};
    margin-bottom: 4px;
  `}
`;

export const WrapperContent = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    width: 10%;
    display: flex;
    flex-direction: column;
  `}
`;

export const WrapperActions = styled.div`
  ${({ theme }) => css`
    width: 100%;
    height: 100%;
    background-color: ${theme.colors.background_light};

    display: flex;
    flex-direction: column;
  `}
`;

export const Checklist = styled.div`
  ${({ theme }) => css`
    padding-top: 3px;
    p {
      color: ${theme.colors.primary};
      letter-spacing: 0.26px;
      line-height: 16px;
      font-size: ${theme.font.sizes.small};
      text-align: left;
    }
  `}
`;
