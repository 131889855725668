import styled, { css } from "styled-components";

export const WrapperUnderAnalysis = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: row;
    height: 45px;
    padding: 5px;
    border-radius: 10px;
    background-color: ${theme.colors.background_attention};
    margin-top: 20px;
    margin-bottom: 20px;
    align-items: center;
  `}
`;

export const WrapperIconExclamation = styled.div`
  ${({ theme }) => css`
    height: 35px;
    width: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    background-color: ${theme.colors.background_attention};
  `}
`;

export const TitleProfileUnderAnalysis = styled.p`
  ${({ theme }) => css`
    margin-left: 15px;
    color: ${theme.colors.text_light_87};
    line-height: 15px;
    font-size: 12px;
    font-family: ${theme.font.family};
    max-width: 280px;
  `}
`;
