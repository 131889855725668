import React, { useEffect, useState } from "react";
import { MdClose } from "react-icons/md";
import { useTheme } from "styled-components";

import CopyLinkIcon from "assets/icons/member-get-member/copy-link-icon.svg";
import GmailIcon from "assets/icons/member-get-member/share-with-gmail.svg";

import { useClickOutside } from "hooks/clickOutside";
import { useToast } from "hooks/toast";

import { api } from "services/api";
import { format, parseISO } from "date-fns";

import * as S from "./styles";

type ModalProps = {
  isVisible: boolean;
  onCloseModal: () => void;
  zIndex?: number;
  opportunityId: string;
};

export function SendMessageModal({
  isVisible,
  onCloseModal,
  zIndex,
  opportunityId,
}: ModalProps) {
  const { addToast } = useToast();
  const theme = useTheme();

  const [isCopied, setIsCopied] = useState(false);
  const [defaultMessage, setDefaultMessage] = useState("");
  useEffect(() => {
    const trigger = (event: KeyboardEvent) => {
      if (event.key === "Escape") {
        onCloseModal();
      }
    };
    document.addEventListener("keydown", trigger);

    return () => {
      document.removeEventListener("keydown", trigger);
    };
  });

  useEffect(() => {
    (async () => {
      try {
        const response = await api.get(`/opportunities/${opportunityId}`);

        const clientFirstName = response.data.name.split(" ")[0];
        const serviceCreationDate = format(
          parseISO(response.data.createdAt),
          "dd/MM/yyyy"
        );

        setDefaultMessage(
          `Olá ${clientFirstName}, tudo bem? O serviço executado em ${serviceCreationDate} completou 12 meses. Tudo certo com o seu equipamento? Podemos agendar uma visita, manutenção ou limpeza?`
        );
      } catch (error) {
        addToast({
          title: "Ops",
          description: "Não foi possível obter a mensagem padrão",
          type: "error",
        });
      }
    })();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  const domNode = useClickOutside(() => {
    onCloseModal();
  });

  const handleCopyIndicationURL = () => {
    navigator.clipboard.writeText(defaultMessage);

    setIsCopied(true);
  };

  const handleShareWithGmail = () => {
    const url = `https://mail.google.com/mail/?view=cm&fs=1&tf=1&to=&su='Digite o título aqui'&body=${defaultMessage}&ui=2&tf=1&pli=1`;

    window.open(url, "sharer", "toolbar=0,status=0,width=648,height=648");
  };

  return (
    <S.ContainerOverlay isVisible={isVisible} style={{ zIndex }}>
      <S.BoxModal ref={domNode}>
        <S.Header>
          <S.Title>Envie mensagem</S.Title>
          <button type="button" onClick={() => onCloseModal()}>
            <MdClose size={24} color={theme.colors.primary} />
          </button>
        </S.Header>

        <S.Main>
          <S.Share onClick={handleCopyIndicationURL}>
            <img src={CopyLinkIcon} alt="compartilhar link" />
            <p>{isCopied ? "Mensagem copiada!" : "Copiar mensagem"}</p>
          </S.Share>

          <S.Line />

          <S.Share onClick={handleShareWithGmail}>
            <img src={GmailIcon} alt="compartilhar com gmail" />
            <p>Gmail</p>
          </S.Share>
        </S.Main>
      </S.BoxModal>
    </S.ContainerOverlay>
  );
}
