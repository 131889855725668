import React from "react";

import { Route } from "./Route";

import { MarketPlaceHome } from "pages/marketplace/MarketPlaceHome";

export function MarketPlaceRoutes() {
  return (
    <>
      <Route path="/marketplace" component={MarketPlaceHome} exact isPrivate />
    </>
  )
}