import formatAddress, { AddressProps } from "utils/formatAddress";

import * as S from "./styles";

const RenderLocationAddress = (address: AddressProps) => {
  const addressFormatted = formatAddress({
    city: address?.city,
    complement: address?.complement,
    district: address?.district,
    number: Number(address?.number),
    postalCode: address?.postalCode,
    street: address?.street,
    uf: address?.uf,
  });

  return (
    <>
      <a
        href={`https://www.google.com/maps/search/?api=1&query=${addressFormatted}`}
        target={"_blank"}
        rel="noopener noreferrer"
      >
        <S.Link>
          {`${address?.street ? address?.street : ""}`}
          {`${address?.number ? `, ${address?.number}` : ""}`}
          {`${address?.district ? `, ${address?.district}` : ""}`}
        </S.Link>
      </a>

      <S.Text> {`${address!.postalCode ? address!.postalCode : ""}`}</S.Text>
      <S.Text>
        {" "}
        {`${address!.city ? address!.city : ""}`}
        {`${address!.uf ? `, ${address!.uf}` : ""}`}
      </S.Text>

      <S.Text>
        <br />
        {address!.complement}
      </S.Text>
    </>
  );
};
export { RenderLocationAddress };
