import React, { createContext, useContext, useState } from "react";
import { ProviderProps } from "hooks";

type FinancialMyWalletFilterContextData = {
  selectedPortfolioMovements: string[];
  selectedTransactionTypes: string[];
  selectedFlagCards: string[];
  selectedPaymentMethods: string[];
  handleSetSelectedPortfolioMovements: (newPortfolioMovement: string) => void;
  handleSetSelectedTransactionTypes: (newTransactionType: string) => void;
  handleSetFlagCards: (newCardBrand: string) => void;
  handleSetPaymentMethods: (PaymentMethod: string) => void;
};

const FinancialMyWalletFilterContext =
  createContext<FinancialMyWalletFilterContextData>(
    {} as FinancialMyWalletFilterContextData
  );

export function useFinancialMyWalletFilter(): FinancialMyWalletFilterContextData {
  const context = useContext(FinancialMyWalletFilterContext);

  if (!context) {
    throw Error(
      "useFinancialMyWalletFilter must be used within a FinancialMyWalletFilterProvider"
    );
  }

  return context;
}

export const FinancialMyWalletFilterProvider = ({ children }: ProviderProps) => {
  const [selectedPortfolioMovements, setSelectedPortfolioMovements] = useState([
    "input",
  ]);
  const [selectedTransactionTypes, setSelectedTransactionTypes] = useState([
    "all",
  ]);
  const [selectedFlagCards, setSelectedFlagCards] = useState<string[]>([]);
  const [selectedPaymentMethods, setSelectedPaymentMethods] = useState(["all"]);

  function handleSetSelectedPortfolioMovements(newPortfolioMovement: string) {
    setSelectedPortfolioMovements([newPortfolioMovement]);
  }

  function handleSetSelectedTransactionTypes(newTransactionType: string) {
    if (newTransactionType === "all") {
      setSelectedTransactionTypes([newTransactionType]);
      return;
    }

    const transactionTypeAlreadySelected =
      selectedTransactionTypes.includes(newTransactionType);

    if (transactionTypeAlreadySelected) {
      if (selectedTransactionTypes.length === 1) {
        setSelectedTransactionTypes(["all"]);
        return;
      }

      const transactionTypesFiltered = selectedTransactionTypes.filter(
        (selectedPortfolioMovement) =>
          selectedPortfolioMovement !== newTransactionType &&
          selectedPortfolioMovement !== "all"
      );
      setSelectedTransactionTypes(transactionTypesFiltered);
      return;
    }

    const transactionTypesFiltered = selectedTransactionTypes.filter(
      (selectedPortfolioMovement) => selectedPortfolioMovement !== "all"
    );
    setSelectedTransactionTypes([
      ...transactionTypesFiltered,
      newTransactionType,
    ]);
  }

  function handleSetFlagCards(newCardBrand: string) {
    let arrFilter = selectedFlagCards.filter(
      (element) => element === newCardBrand
    );

    return arrFilter.length === 0
      ? setSelectedFlagCards([...selectedFlagCards, newCardBrand])
      : setSelectedFlagCards(
          selectedFlagCards.filter((element) => element !== newCardBrand)
        );
  }

  function handleSetPaymentMethods(newPaymentMethod: string) {
    if (newPaymentMethod === "all") {
      setSelectedPaymentMethods([newPaymentMethod]);
      return;
    }

    const paymentMethodAlreadySelected =
      selectedPaymentMethods.includes(newPaymentMethod);

    if (paymentMethodAlreadySelected) {
      if (selectedPaymentMethods.length === 1) {
        setSelectedPaymentMethods(["all"]);
        return;
      }

      const paymentMethodsFiltered = selectedPaymentMethods.filter(
        (selectedPaymentMethod) =>
          selectedPaymentMethod !== newPaymentMethod &&
          selectedPaymentMethod !== "all"
      );
      setSelectedPaymentMethods(paymentMethodsFiltered);
      return;
    }

    const paymentMethodsFiltered = selectedPaymentMethods.filter(
      (selectedPaymentMethod) => selectedPaymentMethod !== "all"
    );
    setSelectedPaymentMethods([...paymentMethodsFiltered, newPaymentMethod]);
  }

  return (
    <FinancialMyWalletFilterContext.Provider
      value={{
        selectedPortfolioMovements,
        selectedTransactionTypes,
        selectedFlagCards,
        selectedPaymentMethods,
        handleSetSelectedPortfolioMovements,
        handleSetSelectedTransactionTypes,
        handleSetFlagCards,
        handleSetPaymentMethods,
      }}
    >
      {children}
    </FinancialMyWalletFilterContext.Provider>
  );
};
