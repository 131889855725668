import styled, { css } from "styled-components";

export const Container = styled.div`
  ${({ theme }) => css`
    height: 50px;
    padding: 0 16px;
    background-color: ${theme.colors.background_light};
    border-radius: 10px;

    display: flex;
    align-items: center;
    justify-content: space-between;
  `}
`;

export const Title = styled.span`
  ${({ theme }) => css`
    color: ${theme.colors.title};
    font-size: ${theme.font.sizes.xsmall};
  `}
`;

export const ButtonViewAllRegistration = styled.button`
  ${({ theme }) => css`
    color: ${theme.colors.text};
    font-size: ${theme.font.sizes.xsmall};
    text-decoration: underline;

    &:disabled {
      cursor: not-allowed;
      opacity: 0.6;
    }
  `}
`;
