import React, { useState } from "react";

import * as S from "./styles";

import { EditButton } from "components/EditButton";
import { DeleteButton } from "components/DeleteButton";
import { PMOCServiceProps } from "dtos/ServiceDTO";
import { AlertModal } from "components/AlertModal";

interface ServicePmocProps {
  currentService: PMOCServiceProps;
  onDeleteService: (serviceId: number) => void;
  onEditServicePmoc: (value: PMOCServiceProps) => void;
}

const PmocServiceContent = ({
  currentService,
  onDeleteService,
  onEditServicePmoc,
}: ServicePmocProps) => {
  const [isVisibleModal, setIsVisibleModal] = useState(false);

  return (
    <S.Container>
      <AlertModal
        isVisible={isVisibleModal}
        title="Deseja apagar?"
        description="Deseja realmente apagar esse serviço?"
        action="choose"
        handleConfirm={() => onDeleteService(currentService.id)}
        onCloseModal={() => setIsVisibleModal(false)}
      />

      <S.Content>
        <S.Title>{currentService.serviceType.name}</S.Title>
        <S.Description>
          Garantia: {currentService.warranty?.time}{" "}
          {currentService.warranty?.extension}
        </S.Description>

        <S.Steps>
          <p>Etapas do serviço</p>
          <span>{currentService.serviceChecklist?.length} checklist</span>
        </S.Steps>
      </S.Content>

      <S.WrapperActions>
        <EditButton
          onClick={() => {
            onEditServicePmoc(currentService);
          }}
        />
        <DeleteButton
          onClick={() => {
            setIsVisibleModal(true);
          }}
        />
      </S.WrapperActions>
    </S.Container>
  );
};
export { PmocServiceContent };
