import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import * as Yup from "yup";

import { Button } from "components/Button";
import { EmptyPage } from "components/EmptyPage";
import { ModalRight } from "components/ModalRight";
import { ArrowButton } from "components/ArrowButton";
import { LoadingProfiz } from "components/LoadingProfiz";
import { ChatFeedbackModal } from "components/ChatFeedbackModal";
import { categories } from "components/NewOccurrenceModal/categories";

import { FeedbackProps } from "dtos/FeedbackDTO";
import { useToast } from "hooks/toast";
import getValidationErrors from "utils/getValidationErrors";

import ChatSVG from "assets/icons/faq/chat.svg";
import EmailSVG from "assets/icons/faq/email-purple.svg";
import MessageSVG from "assets/icons/faq/message-ok.svg";
import loadingSvg from "assets/loading.svg";

import { api } from "services/api";
import * as S from "./styles";

import { Dropdown } from "components/DropDown";
import { CommentsTextArea } from "components/CommentsTextArea";

type RouteState = {
  feedbackParamId: number;
};

type Errors = {
  [key: string]: string;
};

type CategoryProps = {
  id: number;
  name: string;
};

export function Occurrences() {
  const { addToast } = useToast();
  const location = useLocation<RouteState>();

  const [feedbacks, setFeedbacks] = useState<FeedbackProps[]>([]);
  const [feedbackId, setFeedbackId] = useState(0);

  const [categorySelected, setCategorySelected] = useState({} as CategoryProps);
  const [message, setMessage] = useState("");
  const [hasErrorCategory, setHasErrorCategory] = useState("");
  const [hasError, setHasError] = useState<Errors>({} as Errors);

  const [openModalFeedback, setOpenModalFeedback] = useState(false);
  const [openModalOcurrenceOptions, setOpenModalOcurrenceOptions] =
    useState(false);
  const [loading, setLoading] = useState(true);
  const [openModalOccurrence, setOpenModalOccurrence] = useState(false);
  const [ocurrenceIsSent, setOcurrenceIsSent] = useState(false);

  useEffect(() => {
    if (location.state?.feedbackParamId) {
      handleOpenFeedbackChat(location.state.feedbackParamId);
    }
  }, [location.state?.feedbackParamId]);

  useEffect(() => {
    loadFeedbacks();
  }, []); // eslint-disable-line

  async function loadFeedbacks() {
    try {
      const { data } = await api.get("/feedbacks?offset=0&limit=100");
      setFeedbacks(data.feedbacks);
    } catch (error) {
      addToast({
        title: "Ops!",
        description: "Não foi possível carregar os feedbacks.",
        type: "error",
      });
    } finally {
      setLoading(false);
    }
  }

  async function handleSendNewOcurrence() {
    try {
      setLoading(true);
      if (!categorySelected.id) {
        setHasErrorCategory("Selecione uma categoria");
      } else {
        setHasErrorCategory("");
      }

      const schema = () => {
        return Yup.object().shape({
          message: Yup.string().required("Mensagem obrigatória"),
        });
      };

      await schema().validate({ message }, { abortEarly: false });

      if (!categorySelected.id) {
        return addToast({
          title: "Ops!",
          description: "Preencha todos os campos corretamente.",
          type: "error",
        });
      }

      const categoryValue = categories.find((category) => {
        return category.id === categorySelected.id;
      });

      const occurrenceData = {
        message,
        category: categoryValue?.value,
      };

      await api.post("/feedbacks", occurrenceData);

      addToast({
        title: "Sucesso!",
        description: "Ocorrência enviada com sucesso.",
        type: "success",
      });

      setOpenModalOcurrenceOptions(false);
      setOpenModalOccurrence(false);
      setOcurrenceIsSent(true);
      loadFeedbacks();
      handleClearOccurrenceData();
    } catch (error: any) {
      if (error instanceof Yup.ValidationError) {
        const errors = getValidationErrors(error);
        setHasError(errors);

        return addToast({
          title: "Ops!",
          description: "Preencha todos os campos corretamente.",
          type: "error",
        });
      }

      addToast({
        title: error.response.data.error,
        description: "Não foi possível enviar o feedback.",
        type: "error",
      });
    } finally {
      setLoading(false);
    }
  }

  function getCategorySelected(category: CategoryProps) {
    setCategorySelected(category);
    setHasErrorCategory("");
  }

  function handleClearOccurrenceData() {
    setMessage("");
    getCategorySelected({ id: 0, name: "" });
  }

  function handleToggleModalRight() {
    setOpenModalFeedback(!openModalFeedback);
  }

  function handleOpenFeedbackChat(feedbackId: number) {
    setOpenModalFeedback(true);
    setFeedbackId(feedbackId);
  }

  const optionsCategory = categories.map(({ id, name }) => ({
    id,
    name,
  }));

  return (
    <S.Container>
      <ModalRight
        isOpen={openModalFeedback}
        handleToggleOpen={handleToggleModalRight}
        overflow={"hidden"}
      >
        <ChatFeedbackModal
          feedbackId={feedbackId}
          handleToggleModal={handleToggleModalRight}
        />
      </ModalRight>

      {loading ? (
        <LoadingProfiz isVisible={loading} />
      ) : (
        <>
          <header>
            <div>
              <ArrowButton />
              <h1>Suporte e Sugestões</h1>
            </div>
          </header>

          <S.Title>Meus Chamados</S.Title>

          <S.Content>
            {feedbacks.length <= 0 ? (
              <S.ContainerEmptyPage>
                <EmptyPage />
              </S.ContainerEmptyPage>
            ) : (
              <>
                {feedbacks.map((feedback) => {
                  return (
                    <S.Feedback key={feedback.id}>
                      <S.StatusWrapper>
                        <p>Chamado {feedback.id}</p>

                        <div>
                          <S.BadgeStatus status={feedback.status} />
                          {feedback.label === "Em análise"
                            ? "Aguardando resposta"
                            : feedback.label}
                        </div>
                      </S.StatusWrapper>

                      <S.FeedbackMessage>{feedback.message}</S.FeedbackMessage>

                      <S.Button
                        key={feedback.id}
                        onClick={() => handleOpenFeedbackChat(feedback.id)}
                      >
                        <S.ButtonText>Ver Mais</S.ButtonText>
                      </S.Button>
                    </S.Feedback>
                  );
                })}
              </>
            )}

            <S.ButtonWrapper>
              <Button onClick={() => setOpenModalOcurrenceOptions(true)}>
                Novo chamado
              </Button>
            </S.ButtonWrapper>
          </S.Content>
        </>
      )}

      {openModalOcurrenceOptions && !ocurrenceIsSent ? (
        <S.ContainerOptionModal>
          <S.ContentOption>
            <S.HeaderModal>
              <S.CloseButton
                onClick={() => setOpenModalOcurrenceOptions(false)}
              >
                <S.TextCloseModal>Fechar</S.TextCloseModal>
              </S.CloseButton>
            </S.HeaderModal>
            <S.ModalTitle>Como podemos ajudar?</S.ModalTitle>

            <S.IconsView>
              <S.EmailSVG
                onClick={() => {
                  window.open("mailto:contato@profiz.com.br");
                }}
              >
                <img src={EmailSVG} alt="Icone email" width={35} height={35} />

                <S.SubTitle>
                  Enviar <br />
                  E-mail
                </S.SubTitle>
              </S.EmailSVG>

              <S.ChatSVG
                onClick={() => {
                  setOpenModalOccurrence(true);
                }}
              >
                <img src={ChatSVG} alt="Icone chat" width={35} height={35} />

                <S.SubTitle>
                  Abrir <br />
                  chamado
                </S.SubTitle>
              </S.ChatSVG>
            </S.IconsView>
          </S.ContentOption>
        </S.ContainerOptionModal>
      ) : (
        <></>
      )}

      {openModalOccurrence ? (
        <S.ContainerOptionModal>
          <S.ContentOption>
            <S.HeaderModal>
              <S.CloseButton onClick={() => setOpenModalOccurrence(false)}>
                <S.TextCloseModal>Fechar</S.TextCloseModal>
              </S.CloseButton>
            </S.HeaderModal>
            <S.Main>
              <S.ModalTitle>Abrir chamado</S.ModalTitle>

              <S.WrapperDropdown>
                <Dropdown
                  placeholder="Selecione uma categoria"
                  options={optionsCategory}
                  categoryIdSelected={categorySelected.id}
                  onClickedValue={getCategorySelected}
                  hasError={hasErrorCategory}
                />
              </S.WrapperDropdown>

              <CommentsTextArea
                placeholder="Digite aqui sua mensagem"
                value={message}
                onChange={(e) => setMessage(e.target.value.trimStart())}
                hasError={hasError.message}
                //onFocusClearError={() => clearErrorMessage()}
              />
              <S.Footer>
                <S.ButtonConfirm onClick={() => handleSendNewOcurrence()}>
                  {loading ? <img src={loadingSvg} alt="loading" /> : "Enviar"}
                </S.ButtonConfirm>
              </S.Footer>
            </S.Main>
          </S.ContentOption>
        </S.ContainerOptionModal>
      ) : (
        <></>
      )}

      {ocurrenceIsSent ? (
        <S.ContainerOptionModal>
          <S.ContentOption>
            <S.HeaderModal>
              <S.CloseButton onClick={() => setOcurrenceIsSent(false)}>
                <S.TextCloseModal>Fechar</S.TextCloseModal>
              </S.CloseButton>

              <S.MessageSVG>
                <img
                  src={MessageSVG}
                  alt="Mensagem enviada"
                  width={35}
                  height={35}
                />
              </S.MessageSVG>

              <S.ModalTitle>Chamado enviado!</S.ModalTitle>
            </S.HeaderModal>
          </S.ContentOption>
        </S.ContainerOptionModal>
      ) : (
        <></>
      )}
    </S.Container>
  );
}
