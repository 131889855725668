import React, { useState } from "react";

import { ModalRight } from "components/ModalRight";
import { EnvironmentDTO } from "dtos/PmocEnvironmentDTO";
import { useClient } from "hooks/budget/client";
import { useEnvironment } from "hooks/pmoc/environmentPmoc";
import { useToast } from "hooks/toast";
import { usePmoc } from "hooks/usePmoc";
import { EnvironmentFlow } from "pages/pmoc/EnvironmentFlow";
import { Page } from "pages/pmoc/EnvironmentFlow/types";

import * as S from "./styles";
import { Button } from "components/Button";
import { useServiceDropDown } from "hooks/servicesDropDown";

type EnvironmentAreaProps = {
  hasError?: string;
  environments: EnvironmentDTO[];
  setOpenModal?: (value: boolean) => void;
};

export function EnvironmentArea({
  hasError,
  environments,
  setOpenModal,
}: EnvironmentAreaProps) {
  const { addToast } = useToast();
  const [isOpen, setIsOpen] = useState(false);
  const [modalOpened, setModalOpened] = useState<Page>();

  const { reset } = useServiceDropDown();

  const { client, hasClient } = useClient();
  const { unity, hasUnity } = usePmoc();

  const {
    handleSetUnityIdPMOC,
    handleSetUserIdPMOC,
    handleSetNameFlow,
    environmentList,
  } = useEnvironment();

  function handleChooseEditModal(modal: Page) {
    setModalOpened(modal);
  }

  function handleToggleOpen() {
    if (!hasClient || !hasUnity) {
      addToast({
        type: "info",
        title: "Oops!",
        description: "Você deve selecionar um cliente e uma unidade primeiro.",
      });
      return;
    }
    reset();
    handleSetNameFlow("pmoc");
    handleSetUserIdPMOC(client.id);
    handleSetUnityIdPMOC(unity.id);
    handleChooseEditModal("listEnvironment");
    setIsOpen(!isOpen);
    setOpenModal && setOpenModal(!isOpen);
  }

  return (
    <>
      {environmentList.length > 0 && hasUnity ? (
        <div>
          <S.Label>Ambientes e equipamentos*</S.Label>
          {environments.map((item, index) => (
            <S.WrapperEnvironment key={index}>
              <p>{item.name} &nbsp;</p>
              <span>
                {" "}
                | {item.qtdEquipments}{" "}
                {item.qtdEquipments === 1 ? "equipamento" : "equipamentos"}
              </span>
            </S.WrapperEnvironment>
          ))}
        </div>
      ) : (
          <>
          <Button
            onClick={handleToggleOpen}
            typeButton="outline"
          >
              Ambientes e equipamentos
          </Button>
            {!!hasError && (
              <S.ErrorText>{hasError}</S.ErrorText>
            )}
          </>

      )}

      {isOpen && (
        <ModalRight isOpen={isOpen} handleToggleOpen={handleToggleOpen}>
          <EnvironmentFlow
            pageName={modalOpened!}
            handleToggleOpen={handleToggleOpen}
            handleScreenOption={handleChooseEditModal}
            handleSetOpenEnvironmentModal={setOpenModal}
          />
        </ModalRight>
      )}
    </>
  );
}
