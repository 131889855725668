import {
  createGlobalStyle,
  css
} from "styled-components";

const GlobalStyles = createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
     -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    &::before,
    &::after {
      box-sizing: inherit
    }
  }

  button {
    cursor: pointer;
    border: 0px;
    background: none;
    outline: none;
  }

  ${({ theme }) => css`
    html {
      font-size: 62.5%;
    }

    body {
      font-family: ${theme.font.family};
      font-size: ${theme.font.sizes.medium};
      background-color: ${theme.colors.strong_background};
      overflow: hidden;
    }

    *::-webkit-scrollbar {
      width: 1.1rem;
      height: 1.1rem;
    }

    *::-webkit-scrollbar-track {
      background: ${theme.colors.background_light};
    }

    *::-webkit-scrollbar-thumb {
      background: ${theme.colors.subtitle};
      border-radius: 10px;
    }
  `}
`;

export default GlobalStyles;
