import React from "react";

import * as S from "./styles";

interface PaginationProps {
  rowsLength: number;
  rowsPerPage: number;
  rowsPage: number;
  handlePageChange: (pageNumber: number, itemsPerPage: number) => void;
}

export function TablePagination({
  rowsLength,
  rowsPerPage,
  rowsPage,
  handlePageChange,
}: PaginationProps) {
  const pageNumbers = [];
  const pagesShowing = [];

  for (let i = 1; i <= Math.ceil(rowsLength / rowsPerPage); i++) {
    pageNumbers.push(i);
  }

  for (var j = 0; j < pageNumbers.length; j++) {
    var numberPlus = rowsPage === 1 ? 4 : rowsPage === 2 ? 3 : 2;
    var numberMinus =
      rowsPage === pageNumbers.length
        ? 4
        : rowsPage === pageNumbers.length - 1
        ? 3
        : 2;
    if (
      pageNumbers[j] >= rowsPage - numberMinus &&
      pageNumbers[j] <= rowsPage + numberPlus
    ) {
      pagesShowing.push(pageNumbers[j]);
    }
  }

  if (pageNumbers.length <= 0) {
    return <></>;
  }

  return (
    <S.Container>
      <S.PaginationButton
        onClick={() => handlePageChange(1, rowsPerPage)}
        disabled={rowsPage === 1}
        active={false}
        isButton
      >
        <S.ButtonText>Primeira</S.ButtonText>
      </S.PaginationButton>

      {pagesShowing.map((number, index) => (
        <S.PaginationButton
          key={index}
          onClick={() => handlePageChange(number, rowsPerPage)}
          active={rowsPage === number}
        >
          <S.ButtonText active={rowsPage === number}>{number}</S.ButtonText>
        </S.PaginationButton>
      ))}

      <S.PaginationButton
        onClick={() => handlePageChange(pageNumbers.length, rowsPerPage)}
        active={false}
        isButton
        disabled={rowsPage === pageNumbers.length}
      >
        <S.ButtonText>Última</S.ButtonText>
      </S.PaginationButton>
    </S.Container>
  );
}
