import React from "react";

import { LocalChecklistProps } from "dtos/LocalChecklistDTO";
import { QuantityCounter } from "components/QuantityCounter";

import * as S from "./styles";

type DetailsProps = {
  checklist: LocalChecklistProps;
  onIncrement: (id: number, price: number) => void;
};

export function ChecklistWithPriceDetails({
  checklist,
  onIncrement,
}: DetailsProps) {
  function handleIncrement(value: number) {
    onIncrement(checklist.id, value);
  }

  return (
    <S.Container>
      <S.WrapperMyResponsibilityText>
        <S.PrimaryText>{checklist.name}</S.PrimaryText>
        {checklist.type === "my_responsibility" && (
          <S.PrimaryText>{checklist.formattedTotal}</S.PrimaryText>
        )}
      </S.WrapperMyResponsibilityText>

      <S.WrapperMyResponsibilityText>
        <S.SecondaryText>Quantidade: </S.SecondaryText>

        <QuantityCounter
          initialValue={checklist.quantity}
          // eslint-disable-next-line react/jsx-no-bind
          onIncrement={handleIncrement}
        />
      </S.WrapperMyResponsibilityText>
    </S.Container>
  );
}
