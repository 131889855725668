export const categories = [
  {
    id: 1,
    name: "Encontrei um erro",
    value: "duvida-problema-sugestao",
  },
  {
    id: 2,
    name: "Sugestão",
    value: "duvida-problema-sugestao",
  },
  {
    id: 3,
    name: "Reclamação",
    value: "duvida-problema-sugestao",
  },
  {
    id: 4,
    name: "Orçamento",
    value: "orcamento",
  },
  {
    id: 5,
    name: "Ordem de serviço",
    value: "ordem-servico",
  },
  {
    id: 6,
    name: "Perfil",
    value: "perfil",
  },
  {
    id: 7,
    name: "Certificados",
    value: "certificado",
  },
];
