import styled, { css } from "styled-components";

export const ScheduledModal = styled.div`
  ${({ theme }) => css`
    padding: 0px 30px;

    div.arrow-button {
      margin-bottom: 20px;
    }

    p.subtitle {
      margin-top: 10px;
      font-size: ${theme.font.sizes.small};
      color: ${theme.colors.financial_title};
    }

    p.info {
      margin-top: 30px;
      font-size: ${theme.font.sizes.small};
      color: ${theme.colors.financial_title};
    }
  `}
`;

export const DivModal = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 12px;
    background: ${theme.colors.background_modal};
    padding-left: 30px;
    margin-top: 30px;
  `}
`;


export const Title = styled.h1`
  ${({ theme }) => css`
    font-size: ${theme.font.sizes.large};
    color: ${theme.colors.title};
    font-weight: ${theme.font.bold};
  `}
`;