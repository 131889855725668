import React, { createContext, useContext, useState } from "react";

import { MonthProps, ResumePmocDTO } from "dtos/ResumePmocDTO";
import { ModalName } from "components/ModalResumePmoc/types";
import { ProviderProps } from "hooks";

type ResumePmocContextData = {
  pmoc: ResumePmocDTO;
  monthSelected: MonthProps;
  idServiceOrderSelected: number;
  isOpenModalResumePmoc: boolean;
  modalSelected: ModalName;
  handleSetPmoc: (pmoc: ResumePmocDTO) => void;
  handleSetMonthSelected: (month: MonthProps) => void;
  handleSetIdServiceOrderSelected: (id: number) => void;
  handleToggleModalResumePmoc: () => void;
  handleChangeModalSelected: (modalName: ModalName) => void;
};

const ResumePmocContext = createContext<ResumePmocContextData>(
  {} as ResumePmocContextData
);

export function useResumePmoc(): ResumePmocContextData {
  const context = useContext(ResumePmocContext);

  if (!context) {
    throw Error("useResumePmoc must be used within a ResumePmocProvider");
  }

  return context;
}

export function ResumePmocProvider({ children }: ProviderProps) {
  const [pmoc, setPmoc] = useState({} as ResumePmocDTO);

  const [monthSelected, setMonthSelected] = useState({} as MonthProps);

  const [idServiceOrderSelected, setIdServiceOrderSelected] = useState(0);

  const [isOpenModalResumePmoc, setIsOpenModalResumePmoc] = useState(false);
  const [modalSelected, setModalSelected] =
    useState<ModalName>("detailOfMonthPmoc");

  function handleSetPmoc(pmoc: ResumePmocDTO) {
    setPmoc(pmoc);
  }

  function handleSetMonthSelected(month: MonthProps) {
    setMonthSelected(month);
  }

  function handleSetIdServiceOrderSelected(id: number) {
    setIdServiceOrderSelected(id);
  }

  function handleToggleModalResumePmoc() {
    setIsOpenModalResumePmoc(!isOpenModalResumePmoc);
  }

  function handleChangeModalSelected(modalName: ModalName) {
    setModalSelected(modalName);
  }

  return (
    <ResumePmocContext.Provider
      value={{
        pmoc,
        monthSelected,
        idServiceOrderSelected,
        isOpenModalResumePmoc,
        modalSelected,
        handleSetPmoc,
        handleSetMonthSelected,
        handleSetIdServiceOrderSelected,
        handleToggleModalResumePmoc,
        handleChangeModalSelected,
      }}
    >
      {children}
    </ResumePmocContext.Provider>
  );
}
