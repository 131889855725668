import styled, { css } from 'styled-components'

export const Container = styled.div`
  ${({ theme }) => css`
    width: 100%;
    max-width: 1400px;
    min-width: 470px;
    margin: 0 auto;
    padding: 30px;

    header {
      display: flex;
      align-items: center;

      h1 {
        font-size: ${theme.font.sizes.large};
        color: ${theme.colors.title};
        font-weight: ${theme.font.bold};
        margin-left: 25px;
      }

      div {
        width: 80px;
        display: flex;
        justify-content: space-between;
      }
    }
  `}
`

export const Content = styled.section`
  width: 100%;
  margin-top: 50px;

  button {
    div {
      margin-left: 10px;
    }
  }
`

export const ContentImage = styled.div`
  width: 100%;
  height: 190px;
  margin-top: 40px;
  display: flex;
  justify-content: center;
  margin-bottom: 48px;
`

export const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 40px;
`

export const WrapperButton = styled.div`
  ${({ theme }) => css`
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 100px;

    button {
      color: ${theme.colors.text_light};
      font-size: ${theme.font.sizes.small};

      &:hover {
        color: ${theme.colors.text_light_60};
        text-decoration: underline;
      }
    }
  `}
`

export const Label = styled.p`
  ${({ theme }) => css`
    color: ${theme.colors.text};
    font-size: ${theme.font.sizes.small};
    line-height: 20px;
  `}
`
