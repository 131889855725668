import React from "react";

import * as S from "../styles";

export interface TypeResponseProps {
  type?: string;
  value?: string;
}

type RadioButtonProps = {
  type: TypeResponseProps;
  value: string;
  name: string;
  setType: ({ type, value }: TypeResponseProps) => void;
};

const RadioBox: React.FC<RadioButtonProps> = ({
  setType,
  type,
  value,
  name,
}) => {
  return (
    <S.ContainerRadioBox
      onClick={() =>
        setType({
          value,
          type: name,
        })
      }
    >
      <S.RadioBox type="button" isActive={type?.type === name}>
        <div className="marked"></div>
      </S.RadioBox>
      <span>{name}</span>
    </S.ContainerRadioBox>
  );
};
export { RadioBox };
