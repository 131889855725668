import styled, { css } from "styled-components";

export const Container = styled.div`
  ${({ theme }) => css`
    width: 50%;
    min-width: 600px;
    height: 100%;
    margin-bottom: 50px;
    padding-left: 30px;
    padding-top: 20px;
    overflow: hidden;

    border-right: 1px solid ${theme.colors.background_light};
  `}
`;

export const TextHeaderRow = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
    h3 {
      font-size: ${theme.font.sizes.large};
      color: ${theme.colors.title};
      font-weight: ${theme.font.bold};
    }

    h5 {
      font-size: ${theme.font.sizes.small};
      font-weight: 300;
      color: ${theme.colors.title};
    }
  `}
`;

export const EmptyList = styled.div`
  ${({ theme }) => css`
    margin-left: 20px;
    margin-top: 20px;
    font-size: ${theme.font.sizes.small};
    font-weight: 300;
    color: ${theme.colors.title};
  `}
`;

export const Wrapper = styled.div`
  ${({ theme }) => css`
    padding: 5px;
    width: 90%;
    background-color: ${theme.colors.background_light};
    border-radius: 10px;
    display: flex;
    align-items: center;
    margin-bottom: 30px;
  `}
`;

export const TabContainer = styled.div`
  ${({ theme }) => css`
    margin-top: 50px;

    .react-tabs__tab-list {
      border-bottom: 1px solid rgba(255, 255, 255, 0.12);
      display: flex;
      justify-content: space-around;
    }

    .react-tabs__tab {
      margin-right: 30px;
      color: ${theme.colors.text};
    }

    .react-tabs__tab--selected {
      background: inherit;
      border: 0px;
      color: ${theme.colors.text};

      font-weight: 500;
      line-height: 1.35;
      white-space: normal;
      letter-spacing: 0.02857em;
      border-bottom: 3px solid ${theme.colors.primary_light};
    }
  `}
`;

export const TextRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  color: white;
`;

export const Top = styled.div`
  ${({ theme }) => css`
    margin-bottom: 30px;

    display: flex;
    align-items: center;
  `}
`;

export const ButtonClose = styled.button`
  ${({ theme }) => css`
    width: 35px;
    height: 35px;
    background-color: ${theme.colors.background_light};
    border-radius: 10px;

    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 25px;
  `}
`;

export const Content = styled.main`
  width: 90%;
  margin-bottom: 110px;
`;

export const WrapperAvaliation = styled.div`
  margin-bottom: 16px;
`;

export const BoldText = styled.div`
  font-weight: 700;
  color: white;
  margin-right: 5px;
`;
