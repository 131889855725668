import { useEffect, useState } from "react";
import { FiArrowLeft, FiArrowRight } from "react-icons/fi";
import { useTheme } from "styled-components";
import { getYear, format, startOfMonth, endOfMonth } from "date-fns";

import DayPicker, {
  DayModifiers,
  Modifier,
  NavbarElementProps,
} from "react-day-picker";
import "react-day-picker/lib/style.css";

import { months, weekDays } from "./locale";

import * as S from "./styles";

type DayCalendarProps = {
  day: Date;
  datesWithServices: string[];
};

type CalendarProps = {
  selectDays: Modifier | Modifier[];
  onChangeDate: (day: Date, { selected, disabled }: DayModifiers) => void;
  datesWithServices?: string[];
  onGetDayMonthViewed?: (month: Date) => void;
  isFinancialCalendar?: boolean;
  isPmocRescheduling?: boolean;
  finalDate?: Date;
  startDate?: Date;
};

export function Calendar({
  selectDays,
  onChangeDate,
  datesWithServices,
  onGetDayMonthViewed,
  isFinancialCalendar = false,
  isPmocRescheduling = false,
  finalDate,
  startDate
}: CalendarProps) {
  const [startMonth, setStartMonth] = useState(
    isPmocRescheduling?new Date():startOfMonth(new Date())
  );
  const [endMonth, setEndMonth] = useState(
    isPmocRescheduling && finalDate ?
      finalDate :
      endOfMonth(new Date()));

  const [storageValue, setStorageValue] = useState("");

  useEffect(() => {
    const item = sessionStorage.getItem("@Profiz:DayMonthSelected");
    if (item) {
      setStorageValue(item.replace(/"/g, ""));
    }
  }, []);

  function handleMonthChange(month: Date) {
    onGetDayMonthViewed?.(month);
    
    !isPmocRescheduling && setEndMonth(endOfMonth(month));

    if (isPmocRescheduling && startDate &&
        month.getMonth() === startDate?.getMonth() &&
        month.getFullYear() === startDate.getFullYear()
    ) {
      setStartMonth(startDate);
      return;
    }
    setStartMonth(startOfMonth(month));
  }


  function Navbar({ onPreviousClick, onNextClick, month }: NavbarElementProps) {
    const theme = useTheme();

    const currentMonth = months[month.getMonth()];
    const currentYear = getYear(month);

    return (
      <S.Navbar>
        <button
          disabled={isPmocRescheduling && startDate &&
            month.getMonth() === startDate.getMonth() && 
            getYear(month) === getYear(startDate)
          }
          type="button" onClick={() => onPreviousClick()}>
          <div
            style={{
              cursor: `${isPmocRescheduling && startDate &&
                          month.getMonth() === startDate.getMonth() && 
                          getYear(month) === getYear(startDate)?
                        'not-allowed': 'pointer'}`
            }}
          >
            <FiArrowLeft size={20} color={theme.colors.primary} />
          </div>
        </button>

        <span>
          {currentMonth} de {currentYear}
        </span>

        <button type="button"
          disabled={isPmocRescheduling && finalDate &&
            month.getMonth() === finalDate.getMonth() &&
            getYear(month) === getYear(finalDate)
          }
          onClick={() => onNextClick()}>
          <div
            style={{
              cursor: `${isPmocRescheduling && finalDate &&
                          month.getMonth() === finalDate.getMonth() &&
                          getYear(month) === getYear(finalDate) ?
                          'not-allowed':'pointer'}`
            }}
          >
            <FiArrowRight size={20} color={theme.colors.primary} />
          </div>
        </button>
      </S.Navbar>
    );
  }


  return (
    <S.Container>
      <DayPicker
        disabledDays={[{
          before: startMonth,
          after: endMonth
        }]}
        modifiersStyles={{
          disabled: {
            cursor: `${isPmocRescheduling ? 'not-allowed' : 'pointer'}`,
          },
          outside: {
            cursor: `${isPmocRescheduling?'default':'pointer'}`,
            opacity:`${isPmocRescheduling?0:1}`
          }
         }}
        selectedDays={selectDays}
        onDayClick={onChangeDate}
        onMonthChange={handleMonthChange}
        showOutsideDays={!isPmocRescheduling}
        months={months}
        weekdaysShort={weekDays}
        month={
          isFinancialCalendar && storageValue?.length > 0
            ? new Date(storageValue)
            : undefined
        }
        renderDay={(day) => (
          <DayCalendar day={day} datesWithServices={datesWithServices!} />
        )}
        navbarElement={Navbar}
        captionElement={<></>}
      />
    </S.Container>
  );
}

function DayCalendar({ day, datesWithServices }: DayCalendarProps) {
  const currentDayFormatted = format(day, "yyyy-MM-dd");

  return (
    <S.DayContainer>
      <span>{day.getDate()}</span>
      {datesWithServices?.includes(currentDayFormatted) && <div />}
    </S.DayContainer>
  );
}

export type { DayModifiers };
