import styled, { css } from 'styled-components'
import ReactCodeInput from "react-code-input";

export const InputCode = styled(ReactCodeInput)`
  ${({ theme }) => css`
    & > input:not(:focus) {
      border: 1px solid transparent;
    }

    & > input:focus-within {
      border: 1px solid ${theme.colors.primary};
    }
  `}
`

export const LabelError = styled.p`
  ${({ theme }) => css`
    color: ${theme.colors.error};
    font-size: ${theme.font.sizes.xsmall};
    margin-top: 8px;
    margin-left: 22px;
    text-align: left;
  `}
`