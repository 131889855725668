import { createContext, useContext, useState } from "react";
import { ProviderProps } from "hooks";
import { UserExecutionProps } from "dtos/ServiceOrderDetailDTO";

type MemberIdsByService = {
  [key: number]: UserExecutionProps; // Estrutura -> idBudgetService: {  id: number; name: string; photo: string;}
};

export type ServiceToScheduleProps = {
  idBudgetService: number;
  status: "pending" | "scheduled" | "concluded";
  serviceData: {
    id: number;
    name: string;
    runtime: {
      time: number;
      extension: string;
    };
  };
  serviceOrderId?: number;
  selectedMemberIds?: MemberIdsByService;
};

type SelectedServiceToScheduleData = {
  selectedServiceToSchedule: ServiceToScheduleProps;
  selectedMemberIdsByService: MemberIdsByService;
  handleSetSelectedServiceToSchedule: (
    serviceToScheduleProps: ServiceToScheduleProps
  ) => void;
  handleSelectedMemberIdByService: (
    memberIdByService: MemberIdsByService
  ) => void;
  handleClearSelectedMemberIds: () => void;
};

const SelectedServiceToScheduleContext = createContext(
  {} as SelectedServiceToScheduleData
);

export function useSelectedServiceToSchedule() {
  const context = useContext(SelectedServiceToScheduleContext);

  if (!context) {
    throw new Error(
      "useSelectedServiceToSchedule must be used within an ServiceOrdersProvider"
    );
  }

  return context;
}

export const SelectedServiceToSchedule = ({ children }: ProviderProps) => {
  const [selectedServiceToSchedule, setSelectedServiceOrders] = useState(
    {} as ServiceToScheduleProps
  );
  const [selectedMemberIdsByService, setSelectedMemberIdsByService] =
    useState<MemberIdsByService>({} as MemberIdsByService);

  function handleSetSelectedServiceToSchedule(
    selectedServiceToSchedule: ServiceToScheduleProps
  ) {
    setSelectedServiceOrders(selectedServiceToSchedule);
  }

  function handleSelectedMemberIdByService(
    memberIdByService: MemberIdsByService
  ) {
    setSelectedMemberIdsByService({
      ...selectedMemberIdsByService,
      ...memberIdByService,
    });
  }

  function handleClearSelectedMemberIds() {
    setSelectedMemberIdsByService({} as MemberIdsByService);
  }

  return (
    <SelectedServiceToScheduleContext.Provider
      value={{
        selectedServiceToSchedule,
        selectedMemberIdsByService,
        handleSetSelectedServiceToSchedule,
        handleSelectedMemberIdByService,
        handleClearSelectedMemberIds,
      }}
    >
      {children}
    </SelectedServiceToScheduleContext.Provider>
  );
};
