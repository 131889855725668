import styled, { css, keyframes } from "styled-components";

type ModalProps = {
  isVisible: boolean;
};

const ModalScale = keyframes`
  from {
    transform: scale(0.5);
    opacity: 0.4;
  }
  to {
    transform: scale(1);
    opacity: 1;
  }
`;

export const ContainerOverlay = styled.aside<ModalProps>`
  ${({ theme, isVisible }) => css`
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;

    display: ${isVisible ? "flex" : "none"};
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: ${theme.layers.alwaysOnTop};
  `}
`;

export const BoxModal = styled.div`
  ${({ theme }) => css`
    width: 400px;
    padding: 26px;
    border-radius: 10px;
    background-color: ${theme.colors.menu_background};
    animation: ${ModalScale} 0.3s ease-in-out;
  `}
`;

export const Header = styled.div`
  ${({ theme }) => css`
    display: flex;
    justify-content: space-between;

    div {
      width: 35px;
    }

    button {
      width: 35px;
      height: 35px;
      border-radius: 10px;
      background-color: ${theme.colors.primary_opacity};
    }
  `}
`;

export const Main = styled.div`
  margin-top: 40px;
  max-height: 100px;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
`;
