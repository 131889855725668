import styled, { css } from "styled-components";
import { shade } from "polished";

export const Container = styled.div`
  ${({ theme }) => css`
    margin-bottom: 30px;

    width: 100%;
    max-width: 1400px;

    padding: 0 70px;

    header {
      display: flex;
      flex-direction: column;
      margin: 20px 0;
    }

    div.content {
      width: 50%;
    }
  `}
`;

export const Title = styled.h1`
  ${({ theme }) => css`
    margin-top: 20px;
    font-size: ${theme.font.sizes.large};
    color: ${theme.colors.title};
    font-weight: ${theme.font.bold};
  `}
`;

export const Wrapper = styled.div`
  margin-bottom: 20px;
`;

export const AttachButton = styled.button`
  ${({ theme }) => css`
    background-color: transparent;
    width: 100%;
    height: 50px;

    display: flex;
    align-items: center;
    justify-content: center;

    border: 1px dashed ${theme.colors.primary_light};
    border-radius: 10px;

    img {
      margin-right: 10px;
    }

    font-size: 12px;
    font-family: ${theme.font.family};
    font-weight: ${theme.font.bold};
    color: ${theme.colors.title};

    transition: all 0.7s ease;
    &:hover {
      opacity: 0.7;
    }
  `}
`;

export const InlineContent = styled.div`
  display: flex;
  align-items: flex-end;
  width: 100%;

  div.input {
    margin-right: 2%;
    width: 45%;
  }

  div.dropdown {
    width: 100%;
  }
`;

export const WrapperInput = styled.div`
  display: flex;
  align-items: flex-end;

  & > div:first-of-type {
    width: 100%;
  }
`;

export const ButtonSaveHash = styled.button`
  ${({ theme }) => css`
    width: 90px;
    height: 46px;
    background-color: ${theme.colors.primary};
    color: ${theme.colors.background};
    border-radius: 6px;
    margin-left: 16px;

    display: flex;
    justify-content: center;
    align-items: center;

    &:hover {
      background: ${shade(0.2, theme.colors.primary_light)};
    }
  `}
`;

export const WrapperInfo = styled.div`
  ${({ theme }) => css`
    margin-top: 4px !important;
    margin-bottom: 20px;
    margin-left: 4px;

    & > span {
      font-size: ${theme.font.sizes.xsmall};
      color: ${theme.colors.text};
    }
  `}
`;

export const WrapperHashError = styled.div`
  ${({ theme }) => css`
    margin-top: 4px !important;
    margin-left: 4px;

    & > span {
      font-size: ${theme.font.sizes.xsmall};
      color: ${theme.colors.error};
    }
  `}
`;
