import styled, { css } from "styled-components";
import { Link } from "react-router-dom";

export const Container = styled.div`
  ${({ theme }) => css`
    width: 100%;
    max-width: 1400px;
    padding: 0 30px;

    header {
      display: flex;
      margin-top:20px;
      flex-direction:column;

      h1 {
        margin:20px 0px;
        font-size: ${theme.font.sizes.large};
        color: ${theme.colors.title};
        font-weight: ${theme.font.bold};
      }

    }

    div.content {
      width: 50%;
    }
  `}
`;

export const PmocButton = styled(Link)`
  ${({ theme }) => css`
    background-color: ${theme.colors.background_modal};
    height: 32px;
    width: 100%;

    display: flex;
    align-items: center;

    text-decoration: none;

    padding: 20px 15px;
    margin-bottom: 10px;

    border-radius: 8px;

    transition: all 0.3s ease;

    p {
      color: ${theme.colors.text_extra_light};
      font-family: ${theme.font.family};
      font-weight: ${theme.font.bold};
      font-size: ${theme.font.sizes.small};
    }

    &:hover {
      opacity: 0.6;
    }
  `}
`;

export const EmptyList = styled.div`
  ${({ theme }) => css`
    height: 30vh;
    display: flex;
    align-items: center;
    justify-content: center;

    p {
      color: ${theme.colors.text_extra_light};
      font-size: 12px;
      font-family: ${theme.font.family};
      font-weight: ${theme.font.normal};

      text-align: center;
      line-height: 20px;
    }
  `}
`;


export const TabContainer = styled.div`
  ${({ theme }) => css`
    margin-top: 20px;
    width: 100%;
    min-width: 500px;

    .react-tabs__tab-list {
      border-bottom: 1px solid rgba(255, 255, 255, 0.12);
      display: flex;
      justify-content: space-around;
      
    }

    .react-tabs__tab {
      color: ${theme.colors.text};
      width: 50%;
      display: flex;
      justify-content:center;
      align-items: center;
    }

    .react-tabs__tab--selected {
      background: inherit;
      border: 0px;
      color: ${theme.colors.text};

      font-weight: 500;
      line-height: 1.35;
      white-space: normal;
      letter-spacing: 0.02857em;
      border-bottom: 3px solid ${theme.colors.primary_light};
    }
  `}
`;
