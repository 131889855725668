import styled, { css } from "styled-components";

export const ChangeButton = styled.button`
  ${({ theme }) => css`
    font-family: ${theme.font.family};
    font-size: ${theme.font.sizes.xsmall};
    font-weight: ${theme.font.bold};
    color: ${theme.colors.primary};
  `}
`;
