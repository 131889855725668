import React from "react";

import { ArrowButton } from "components/ArrowButton";
// import { Button } from "components/Button";

import { DetailEquipmentDTO } from "dtos/PmocEnvironmentDTO";

import EquipmentIcon from "assets/icons/equipment-icon.svg";
// import QrCodeDownload from "assets/icons/qrcode-download.svg";

import * as S from "./styles";

type DetailEquipmentProps = {
  handleBackButton: () => void;
  equipment: DetailEquipmentDTO;
};

export function DetailEquipment({
  handleBackButton,
  equipment,
}: DetailEquipmentProps) {
  return (
    <S.Content>
      <S.Header style={{ marginBottom: 20 }}>
        <ArrowButton handleFunction={handleBackButton} />
      </S.Header>

      <S.Title>{equipment.tagEquipment}</S.Title>
      <img src={EquipmentIcon} alt="equipment" />

      <S.Card style={{ marginTop: 40 }}>
        <S.Negrito>{equipment.tagEquipment}</S.Negrito>
        <S.Description>
          {" "}
          {equipment.equipment ? equipment.equipment.name + " " : ""}
          {equipment.equipmentType ? equipment.equipmentType.name + " " : ""}
          {equipment.brand ? equipment.brand + " " : ""}
          {equipment.capacity ? equipment.capacity.name : ""}
        </S.Description>
        <S.Description>
          {equipment.componentName ? equipment.componentName + " | " : ""}

          {equipment.services && equipment.services.length > 0
            ? equipment.services.length + " serviço(s) vinculado(s)"
            : ""}
        </S.Description>
      </S.Card>
      <S.Title style={{ marginBottom: 10, marginTop: 30 }}>Fotos</S.Title>
      <S.Description style={{ marginRight: "auto" }}>
        Etiquetas ou TAGS já existentes
      </S.Description>
      {equipment.tagPhotos ? (
        <S.ImagesList>
          {equipment.tagPhotos?.map((photo, index) => (
            <S.ImageTag src={photo.link} key={index} />
          ))}
        </S.ImagesList>
      ) : (
        <S.Description style={{ marginRight: "auto" }}>
          Nenhuma foto cadastrada
        </S.Description>
      )}

      {equipment.invoice && (
        <>
          <S.Description style={{ marginRight: "auto", marginTop: 10 }}>
            Nota fiscal
          </S.Description>
          <S.ImagesList>
            <S.ImageTag src={equipment.invoice?.link} />
          </S.ImagesList>
        </>
      )}
      {equipment.componentName && (
        <>
          <S.Title style={{ marginBottom: 10, marginTop: 30 }}>
            Componentes individuais
          </S.Title>
          <S.Card>
            <S.Description>{equipment.componentName}</S.Description>
          </S.Card>
        </>
      )}
      {equipment.services && equipment.services.length > 0 && (
        <>
          <S.Title style={{ marginBottom: 10, marginTop: 30 }}>
            Serviços
          </S.Title>
          {equipment.services.map((service, index) => (
            <S.Card key={index}>
              <S.CardHeader>
                <S.Negrito>{service.name}</S.Negrito>
                <S.Description style={{ marginLeft: 10 }}>
                  ({service.frequencyService})
                </S.Description>
              </S.CardHeader>
            </S.Card>
          ))}
        </>
      )}

      {/* <S.DownloadQr src={QrCodeDownload} alt="qrcode"></S.DownloadQr>
      <Button typeButton="default">Salvar</Button> */}
    </S.Content>
  );
}
