import styled, { css } from "styled-components";

export const Link = styled.div`
  ${({ theme }) => css`
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.26px;
    margin-top: 5px;
    color: ${theme.colors.primary_medium};
    text-decoration: underline;
    text-decoration-color: ${theme.colors.primary_medium};
  `}
`;

export const Text = styled.div`
  ${({ theme }) => css`
    margin-top: 5px;
    font-size: 12px;
    letter-spacing: 0.26px;
    color: ${theme.colors.text};
  `}
`;
