import React, { useState } from "react";

import { CodeRecovery } from "./CodeRecovery";
import { EmailSendCode } from "./EmailSendCode";
import { ResetPassword } from "./ResetPassword";

type FormName = "sendEmail" | "sendCode" | "newPassword";

export type ResetPasswordData = {
  email: string;
  code: string;
};

export function ForgotPassword() {
  const [formOpened, setFormOpened] = useState({
    sendEmail: true,
    sendCode: false,
    newPassword: false,
  });

  const { sendEmail, sendCode, newPassword } = formOpened;

  const [resetPasswordData, setResetPasswordData] = useState(
    {} as ResetPasswordData
  );

  function handleToggleForm(formName: FormName) {
    const listFormOpened = Object.keys(formOpened).map((key) => {
      if (key === formName) {
        return { [key]: true };
      }

      return { [key]: false };
    });

    setFormOpened(Object.assign({}, ...listFormOpened));
  }

  const handleGetResetPasswordData = (data: ResetPasswordData) => {
    setResetPasswordData(data);
  };

  return (
    <>
      <form
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        {sendEmail && (
          <EmailSendCode
            handleToggleOpen={() => handleToggleForm("sendCode")}
          />
        )}

        {sendCode && (
          <CodeRecovery
            handleToggleOpen={() => handleToggleForm("newPassword")}
            handleGetResetPasswordData={handleGetResetPasswordData}
          />
        )}

        {newPassword && <ResetPassword resetPasswordData={resetPasswordData} />}
      </form>
    </>
  );
}
