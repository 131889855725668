import React from "react";
import { Switch, Route } from "react-router-dom";

import { WaitingForDownloadPdfMobile } from "../pages/fieldService/WaitingForDownloadPdfMobile";

export function DownloadRoutes() {
  return (
    <Switch>
      <Route
        path="/download/pdf/mobile"
        exact
        component={WaitingForDownloadPdfMobile}
      />
    </Switch>
  );
}
