import styled, { css } from "styled-components";

export const ButtonBack = styled.button`
  ${({ theme }) => css`
    width: 35px;
    height: 35px;
    border-radius: 10px;

    display: flex;
    justify-content: center;
    align-items: center;

    background-color: ${theme.colors.background_modal};
  `}
`;

export const Header = styled.section`
  ${({ theme }) => css`
    width: 70%;
    margin-top: 30px;
    margin-bottom: 30px;

    h2 {
      font-size: ${theme.font.sizes.large};
      color: ${theme.colors.text_extra_light};
      line-height: 22px;
    }

    p {
      font-size: ${theme.font.sizes.xsmall};
      color: ${theme.colors.text_light};
      margin-top: 15px;
    }
  `}
`;

export const Content = styled.div`
  height: "6vh";
  min-height: 530px;
  margin-bottom: 30px;
`;

export const Wrapper = styled.div`
  margin-bottom: 25px;
`;

export const WrapperDescription = styled.div`
  display: flex;
  align-items: center;
`;

export const Descrition = styled.p`
  ${({ theme }) => css`
    font-size: ${theme.font.sizes.xsmall};
    color: ${theme.colors.text_light};
  `}
`;

export const Footer = styled.div`
  width: 100%;
  height: 50px;
  margin-top: auto;

  display: flex;
  justify-content: flex-end;
`;
