import { Route, Switch } from "react-router-dom";

import { EventPublicPage } from "pages/common/EventPublicPage";

export function EventRoutes() {
  return (
    <Switch>
      <Route path="/events/public/:eventHash" exact component={EventPublicPage} />
    </Switch>
  );
}
