import styled, { css } from "styled-components";

export const Container = styled.button`
  ${({ theme }) => css`
    width: 100%;
    background-color: ${theme.colors.background_light};
    border-radius: 10px;
    padding: 15px;

    & > header {
      display: flex;
      justify-content: space-between;
    }

    span {
      font-size: ${theme.font.sizes.xsmall};
      color: ${theme.colors.primary};
    }

    p {
      font-size: ${theme.font.sizes.xsmall};
      color: ${theme.colors.text};
      text-align: left;
    }
  `}
`;

export const StarImage = styled.img`
  width: 16px;
  height: 16px;
  margin-right: 6px;
`;

export const Star = styled.img`
  width: 20px;
  height: 20px;
  margin-right: 5px;
`;

export const WrapperRating = styled.div`
  display: flex;
  align-items: center;

  & > p {
    ${({ theme }) => css`
      font-size: ${theme.font.sizes.small};
      font-weight: 700;
      color: white;
      margin-right: 5px;
    `}
  }
`;

export const OsText = styled.div`
  ${({ theme }) => css`
    display: flex;
    font-size: ${theme.font.sizes.small};
    font-weight: 700;
    color: white;
    margin-right: auto;
    margin-bottom: 10px !important;
  `}
`;

export const WrapperStatus = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;

    img {
      height: 14px;
      width: 14px;
      margin-right: 4px;
    }

    span {
      font-size: ${theme.font.sizes.small};
      color: ${theme.colors.text};
    }
  `}
`;
export const BottomStatus = styled.div`
  ${({ theme }) => css`
    margin-top: 10px;
    p {
      font-size: ${theme.font.sizes.xsmall};
      color: ${theme.colors.text};
      text-align: left;
      margin-top: 8px;
    }
    img {
      display: flex;
      margin-left: auto;
      margin-top: 4px;
    }
  `}
`;

export const StatusRow = styled.div`
  display: flex;
  align-items: center;
  color: rgba(255, 255, 255, 0.6);
`;
export const Dot = styled.div`
  width: 10px;
  height: 10px;
  border-radius: 10px;
  margin-right: 5px;
`;
