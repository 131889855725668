import React, { ReactElement, useState } from "react";

import { Button } from "components/Button";
import { ModalRight } from "components/ModalRight";
import { EditButton } from "components/EditButton";
import { DeleteButton } from "components/DeleteButton";
import { AlertModal } from "components/AlertModal";
//import { ModalServices } from "components/ModalServices";
import { CardSelectedServices } from "components/CardSelectedServices";

import { useSelectedService } from "hooks/budget/selectedServices";

import { ServiceDTO } from "dtos/ServiceDTO";

import { FormEditService } from "./FormEditService";

import * as S from "./styles";
import { useServiceChecklist } from "hooks/serviceChecklist";
import { ServiceChecklist } from "pages/fieldService/ServiceChecklist";
import { ChecklistPage } from "pages/fieldService/ServiceChecklist/ChecklistPage";
import { RegisterPage } from "pages/fieldService/ServiceChecklist/RegisterPage";
import { DetailRegisterPage } from "pages/fieldService/ServiceChecklist/DetailRegisterPage";
import { ListServices } from "components/ModalServices/ListServices";
import { FormCreateService } from "components/ModalServices/FormCreateService";
import { useServiceDropDown } from "hooks/servicesDropDown";
import { DiscardInformationModal } from "components/DiscardInformationModal";

type ServiceAreaProps = {
  disabled?: boolean;
};

type TOption = {
  [key: string]: ReactElement;
};

export function ServiceArea({ disabled }: ServiceAreaProps) {
  const {
    selectedServices,
    handleSetSelectedServices,
    handleUpdateQuantity,
    handleSetCheckedServices,
  } = useSelectedService();

  const [isOpenAlertModal, setIsOpenAlertModal] = useState(false);
  const [isOpenFormEditService, setIsOpenFormEditService] = useState(false);
  const [currentEditService, setCurrentEditService] = useState<ServiceDTO>(
    {} as ServiceDTO
  );
  const [isOpenListServices, setIsOpenListServices] = useState(false);
  const [isCreatePage, setIsCreatePage] = useState(false);
  const [currentPageModal, setCurrentPageModal] = useState("");
  const [canEditService, setCanEditService] = useState(true);
  const [showCompareAlert, setShowCompareAlert] = useState(false);
  const { isEqualService, handleSetEqualService } = useServiceDropDown();

  const {
    handleSetIsNotMenusAccess,
    selectedItemChecklist,
    isChecklistPage,
    detailChecklist,
    isEditPage,
    handleSetEditPage,
  } = useServiceChecklist();

  const setPageComponent = (keyPage: string) => {
    const pagesArr: TOption = {
      serviceChecklist: (
        <ServiceChecklist
          handleAssociationFunction={handleSaveChecklistService}
          handleEditItemChecklist={() => setCurrentPageModal("checklistPage")}
          handlePageClose={() => {
            setPageBefore();
          }}
        />
      ),
      formEditService: (
        <FormEditService
          data={currentEditService}
          handleGoBack={() => {
            isOpenListServices
              ? handleListServices()
              : handleToggleFormEditService();
          }}
          canEdit={canEditService}
          handleAddChecklist={setChecklistInEditPage}
          handleEditPage={setEditPage}
          handleSetDataFunction={setCurrentEditService}
        />
      ),
      checklistPage: (
        <ChecklistPage
          handleDetailRegister={setPageRegisterDetail}
          handleFunction={setPageRegister}
          handleFunctionClose={() => {
            setPageBeforeChecklistpage();
            //setCurrentPageModal("serviceChecklist");
          }}
          handleSaveInfo={() => {
            handleSaveChecklist();
          }}
        />
      ),
      registerPage: (
        <RegisterPage
          handleFunction={setPageRegisterDetail}
          handlePageClose={() => {
            setCurrentPageModal("checklistPage");
          }}
        />
      ),
      detailRegisterPage: (
        <DetailRegisterPage
          handlePageClose={() => {
            setCurrentPageModal(
              isChecklistPage ? "checklistPage" : "registerPage"
            );
          }}
        />
      ),
      listServices: (
        <ListServices
          handleToggleOpen={handleToggleListServices}
          // eslint-disable-next-line react/jsx-no-bind
          handleCreateService={handleCreateService}
          // eslint-disable-next-line react/jsx-no-bind
          handleEditService={handleEditService}
        />
      ),

      formCreateService: (
        <FormCreateService
          // eslint-disable-next-line react/jsx-no-bind
          data={currentEditService}
          handleGoBack={handleListServices}
          handleAddChecklist={setChecklistInCreatePage}
          handleEditPage={setEditPage}
        />
      ),
    };
    return pagesArr[keyPage];
  };

  function handleEditService(data: ServiceDTO) {
    setCurrentEditService(data);
    setCurrentPageModal("formEditService");
    setIsOpenFormEditService(false);
  }

  function handleListServices() {
    setCurrentPageModal("listServices");
  }

  function handleCreateService() {
    setCurrentEditService({} as ServiceDTO);
    setCurrentPageModal("formCreateService");
  }

  function setEditPage() {
    setCurrentPageModal("checklistPage");
  }

  function setPageRegisterDetail() {
    setCurrentPageModal("detailRegisterPage");
  }

  function setPageRegister() {
    setCurrentPageModal("registerPage");
  }

  function setPageBeforeChecklistpage() {
    if (!isEditPage) return setCurrentPageModal("serviceChecklist");
    isCreatePage
      ? setCurrentPageModal("formCreateService")
      : setCurrentPageModal("formEditService");
  }

  function setPageBefore() {
    isCreatePage
      ? setCurrentPageModal("formCreateService")
      : setCurrentPageModal("formEditService");
  }

  function setChecklistInEditPage() {
    if (isCreatePage) setIsCreatePage(false);
    handleSetIsNotMenusAccess(true);
    setCurrentPageModal("serviceChecklist");
  }

  function setChecklistInCreatePage() {
    setIsCreatePage(true);
    handleSetIsNotMenusAccess(true);
    setCurrentPageModal("serviceChecklist");
  }

  function handleSaveChecklist() {
    let aux = currentEditService;
    if (aux && aux.serviceChecklist) {
      aux.serviceChecklist = aux.serviceChecklist.map((element) => {
        if (element.id === detailChecklist.id) {
          element.name = detailChecklist.name;
          if (detailChecklist.default)
            element.default = detailChecklist.default;
          element.registers = detailChecklist.registers;
          return element;
        }
        return element;
      });
    }
    setCurrentEditService(aux);

    setPageBeforeChecklistpage();
  }

  function handleSaveChecklistService() {
    let aux = currentEditService;

    aux.serviceChecklist = selectedItemChecklist;
    setCurrentEditService(aux);

    setPageBefore();
  }

  function handleOpenFormEditService(currentService: ServiceDTO) {
    if (disabled) {
      return;
    }
    setCanEditService(false);
    setCurrentEditService(currentService);
    setIsOpenFormEditService(!isOpenFormEditService);
  }

  function handleToggleListServices() {
    setIsOpenListServices(!isOpenListServices);
    setCanEditService(true);
    handleSetIsNotMenusAccess(false);
    handleSetEditPage(false);
  }

  function handleToggleFormEditService() {
    setIsCreatePage(false);

    handleSetIsNotMenusAccess(false);
    handleSetEditPage(false);
    if (disabled) {
      return;
    }
    setIsOpenFormEditService(!isOpenFormEditService);
  }

  function onIncrement(id: number, quantity: number) {
    if (disabled) {
      return;
    }
    handleUpdateQuantity(id, quantity);
  }

  function handleDeleteService(currentService: ServiceDTO) {
    if (disabled) {
      return;
    }
    if (selectedServices.length === 1) {
      setIsOpenAlertModal(true);
      return;
    }

    if (currentService.idBudgetService === undefined) {
      const filteredServices = selectedServices.filter(
        ({ id }) => id !== currentService.id
      );
      handleSetCheckedServices(filteredServices);
      handleSetSelectedServices(filteredServices);
    } else {
      const filteredServices = selectedServices.filter(
        ({ idBudgetService }) =>
          idBudgetService !== currentService.idBudgetService
      );
      handleSetCheckedServices(filteredServices);
      handleSetSelectedServices(filteredServices);
    }
  }

  return (
    <S.Container>
      <AlertModal
        isVisible={isOpenAlertModal}
        title="Impossível excluir todos os serviços"
        description="Você precisa ter pelo menos um serviço adicionado neste orçamento."
        action="confirm"
        handleConfirm={() => setIsOpenAlertModal(false)}
        onCloseModal={() => setIsOpenAlertModal(false)}
      />
      <DiscardInformationModal
        showModal={showCompareAlert}
        handleConfirmButton={() => {
          setShowCompareAlert(false);
          handleSetEqualService(true);
          if (isOpenListServices) {
            handleToggleListServices();
            return;
          }
          if (isOpenFormEditService) {
            handleToggleFormEditService();
            return;
          }
        }}
        handleCancelButton={() => {
          setShowCompareAlert(false);
        }}
      />
      <ModalRight
        isOpen={isOpenListServices}
        handleToggleOpen={() => {
          if (
            (currentPageModal === "formEditService" ||
              currentPageModal === "formCreateService") &&
            !isEqualService
          ) {
            setShowCompareAlert(true);
            return;
          }
          handleToggleListServices();
        }}
      >
        {/* <ModalServices handleToggleOpen={handleToggleListServices} /> */}
        {setPageComponent(currentPageModal)}
      </ModalRight>

      <ModalRight
        isOpen={isOpenFormEditService}
        handleToggleOpen={() => {
          if (currentPageModal === "formEditService" && !isEqualService) {
            setShowCompareAlert(true);
            return;
          }
          handleToggleFormEditService();
        }}
      >
        {/* <FormEditService
          data={currentEditService}
          handleGoBack={handleToggleFormEditService}
        /> */}
        {setPageComponent(currentPageModal)}
      </ModalRight>

      <S.Label>Serviço*</S.Label>

      <S.Content>
        {selectedServices.map((currentService) => (
          <S.WrapperCard
            key={currentService.idBudgetService | currentService.id}
          >
            <CardSelectedServices
              key={currentService.idBudgetService}
              data={currentService}
              onIncrement={onIncrement}
              disabled={disabled}
            />

            <S.WrapperActions>
              <EditButton
                disabled={disabled}
                onClick={() => {
                  setCurrentPageModal("formEditService");
                  handleOpenFormEditService(currentService);
                }}
              />
              <DeleteButton
                disabled={disabled}
                onClick={() => handleDeleteService(currentService)}
              />
            </S.WrapperActions>
          </S.WrapperCard>
        ))}
      </S.Content>

      <Button
        disabled={disabled}
        typeButton={disabled ? "disabled" : "outline"}
        onClick={() => {
          setCurrentPageModal("listServices");
          handleToggleListServices();
        }}
      >
        <span>Adicionar outro serviço</span>
      </Button>
    </S.Container>
  );
}
