import { lighten } from "polished";
import styled, { css } from "styled-components";

export const Container = styled.button`
  ${({ theme, disabled }) => css`
    overflow: hidden;

    background-color: ${theme.colors.background_light};

    width: 100%;
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    border-bottom-right-radius: 10px;
    transition: background 0.2s;

    &:hover {
      background: ${lighten(0.2, theme.colors.background_light)};
    }

    img {
      width: ${theme.font.sizes.xsmall};
    }

    cursor: ${disabled ? "not-allowed" : "pointer"};
  `}
`;
