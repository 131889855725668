/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router";
import debounce from "lodash.debounce";

import DownloadSvg from "assets/icons/download-button.svg";

import { useToast } from "hooks/toast";
import { useAccount } from "hooks/permission/account";
import { useAuth } from "hooks/auth";

import { EmptyPage } from "components/EmptyPage";
import { LoadingProfiz } from "components/LoadingProfiz";
import { SearchInput } from "components/SearchInput";
import { ModalRejectedRegistration } from "components/ModalRejectedRegistration";

import apiv2 from "services/apiv2";

import { TablePagination } from "components/TablePagination";

import * as S from "./styles";
import { searchDatalayer } from "utils/pushDataLayer";

type ServiceProps = {
  id: number;
  idBudgetService: number;
  scheduledDate: string;
  service: string;
};

type ServiceOrdersResponse = {
  id: number;
  sequenceNumber: number;
  detailLink: string;
  status: string;
  client: {
    name: string;
  };
  services: ServiceProps[];
};

export function Concluded() {
  const { user } = useAuth();
  const { whoami } = useAccount();
  const history = useHistory();
  const { addToast } = useToast();

  const [searchInputValue, setSearchInputValue] = useState("");

  const [allServiceOrderConcluded, setAllServiceOrderConcluded] = useState<
    ServiceOrdersResponse[]
  >([]);

  const [isLoading, setIsLoading] = useState(true);
  const [loadingInput, setLoadingInput] = useState(false);

  const [totalItems, setTotalItems] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [offset, setOffset] = useState(0);
  const [activeModalRejectedRegistration, setActiveModalRejectedRegistration] =
    useState(false);
  const searchLimit = 50;

  useEffect(() => {
    getConcludedBudgets();
  }, [offset, whoami?.id]);

  async function getConcludedBudgets() {
    setIsLoading(true);
    try {
      if (whoami?.id) {
        setIsLoading(true);
        const response = await apiv2.get(`budgets/service-orders`, {
          params: {
            status: "concluded",
            offset: offset,
            limit: searchLimit,
            orderBy: "date-DESC",
            accountId: whoami?.id,
          },
        });

        setTotalItems(response.data.info.total);

        const { ordens } = response.data;

        setAllServiceOrderConcluded(ordens);
      }
    } catch (error) {
      addToast({
        title: "Ops!!",
        description:
          "Ocorreu um erro ao listar as ordens de serviços concluídas",
        type: "error",
      });
    } finally {
      setIsLoading(false);
      setLoadingInput(false);
    }
  }

  function handleChange(searchValue: string) {
    setLoadingInput(true);
    setSearchInputValue(searchValue);
    debounceFn(searchValue);
  }

  const debounceFn = useCallback(
    debounce(handleSearchServiceOrderByClient, 3000),
    []
  );

  function handleSearchCancel() {
    setSearchInputValue("");
    handleSearchServiceOrderByClient("");
  }

  async function handleSearchServiceOrderByClient(client: string) {
    try {
      const response = await apiv2.get(`budgets/service-orders`, {
        params: {
          offset: 0,
          limit: searchLimit,
          status: "concluded",
          client,
          orderBy: "date-DESC",
          accountId: whoami?.id,
        },
      });

      const { ordens } = response.data;

      setTotalItems(response.data.info.total);

      if (client) {
        searchDatalayer({ search_term: client, success: true });
      }

      setAllServiceOrderConcluded(ordens);
    } catch (error) {
      addToast({
        title: "Ops!!",
        description:
          "Ocorreu um erro ao listar as ordens de serviços concluídas",
        type: "error",
      });
    } finally {
      setIsLoading(false);
      setLoadingInput(false);
    }
  }

  function handleNavigateToServiceOrderDetails(idServiceOrder: number) {
    if (user?.profileDisapproved) {
      setActiveModalRejectedRegistration(true);
      return;
    }

    history.push(`/service-order/concluded/${idServiceOrder}`);
  }

  function handlePageChange(pageNumber: number, itemsPerPage: number) {
    setCurrentPage(pageNumber);
    setOffset((pageNumber - 1) * itemsPerPage);
  }

  function handleOpenPdfPage(pdfLink: string) {
    history.push({
      pathname: "/download/pdf",
      state: {
        pdfDownloadLink: pdfLink,
      },
    });
  }

  return (
    <>
      <ModalRejectedRegistration
        isVisible={activeModalRejectedRegistration}
        setIsActive={setActiveModalRejectedRegistration}
      />
      {isLoading ? (
        <LoadingProfiz isVisible={isLoading} />
      ) : (
        <S.Content>
          <S.ContainerSearch>
            <SearchInput
              loadingInput={loadingInput}
              searchValue={searchInputValue}
              placeholder="Procure pelo nome ou endereço do cliente"
              onChange={(event) => handleChange(event.target.value)}
              // eslint-disable-next-line react/jsx-no-bind
              handleCancel={handleSearchCancel}
            />
          </S.ContainerSearch>

          {allServiceOrderConcluded.length === 0 ? (
            <EmptyPage />
          ) : (
            <table>
              <thead>
                <tr>
                  <th>Número</th>

                  <th> Serviço(s) </th>
                  <th> Cliente </th>
                  <th> Status </th>
                  {user?.profileDisapproved && <th> PDF </th>}
                </tr>
              </thead>

              {!isLoading && (
                <tbody>
                  {allServiceOrderConcluded.map((serviceOrder) => (
                    <tr
                      key={serviceOrder.id}
                      onClick={() =>
                        handleNavigateToServiceOrderDetails(serviceOrder.id)
                      }
                    >
                      <td className="columm-id">
                        {serviceOrder.sequenceNumber}
                      </td>

                      <td>
                        {serviceOrder.services.map(
                          ({ service, idBudgetService }, index) => {
                            if (index > 3) return null;
                            if (index === 3) return <p>...</p>;
                            return (
                              <p key={idBudgetService}>
                                {service}
                                {index < serviceOrder.services.length - 1 &&
                                  ", "}
                              </p>
                            );
                          }
                        )}
                      </td>
                      <td>{serviceOrder.client.name}</td>

                      <td>
                        <span /> Concluído
                      </td>
                      {user?.profileDisapproved && (
                        <td
                          onClick={() =>
                            handleOpenPdfPage(serviceOrder?.detailLink)
                          }
                          style={{
                            width: 70,
                          }}
                        >
                          <S.ContainerImage>
                            <img src={DownloadSvg} alt="Download" />
                          </S.ContainerImage>
                        </td>
                      )}
                    </tr>
                  ))}
                </tbody>
              )}
            </table>
          )}

          <S.ContainerPagination>
            <TablePagination
              rowsLength={totalItems}
              rowsPerPage={searchLimit}
              rowsPage={currentPage}
              handlePageChange={(pageNumber, itemsPerPage) =>
                handlePageChange(pageNumber, itemsPerPage)
              }
            />
          </S.ContainerPagination>
        </S.Content>
      )}
    </>
  );
}
