import { useEffect, useState } from "react";
import { useEditClient } from "hooks/editClient";
import { useTheme } from "styled-components";
import * as S from "./styles";

import { FiChevronLeft } from "react-icons/fi";
import PencilSvg from "assets/icons/pencil.svg";

import UnityIcon from "assets/icons/pmoc/unityIcon.svg";
import { Button } from "components/Button";
import { useEnvironment } from "hooks/pmoc/environmentPmoc";
import { EnvironmentDTO } from "dtos/PmocEnvironmentDTO";
import { useToast } from "hooks/toast";
import { ModalPageProps } from "components/ModalPageClients/types";
import { api } from "services/api";
import { LoadingProfiz } from "components/LoadingProfiz";
import { ShowWhenHavePermission } from "components/Permission";

import { format } from "date-fns";

type PageProps = {
  handleChooseEditModal: ModalPageProps;
};

type ResponseEnvironmentsProps = {
  data: EnvironmentProps;
};

type EnvironmentProps = {
  environments: EnvironmentDTO[];
};

export function UnityDetail({ handleChooseEditModal }: PageProps) {
  const { selectedUnity, editedClient } = useEditClient();
  const { addToast } = useToast();
  const theme = useTheme();
  const {
    handleSetEnvironment,
    handleSetNameFlow,
    handleSetUserIdPMOC,
    handleSetUnityIdPMOC,
    handleSetEnvironmentId,
  } = useEnvironment();

  const [isLoading, setIsLoading] = useState(false);
  const [listEnvironments, setListEnvironments] = useState<EnvironmentDTO[]>(
    [] as EnvironmentDTO[]
  );

  useEffect(() => {
    SetData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedUnity]);

  async function SetData() {
    try {
      setIsLoading(true);

      handleSetUserIdPMOC(editedClient.id);
      handleSetUnityIdPMOC(selectedUnity.id);

      const { data }: ResponseEnvironmentsProps = await api.get(
        `/client/${editedClient.id}/unities/${selectedUnity.id}/environments`
      );

      if (selectedUnity.environments) {
        setListEnvironments(data.environments);
      }
    } catch (error) {
      addToast({
        title: "Ops!!",
        description: "Ocorreu um erro ao carregar dos dados do endereço.",
        type: "error",
      });
    } finally {
      setIsLoading(false);
    }
  }

  function handleAddEnvironment() {
    handleSetEnvironment({} as EnvironmentDTO);
    handleSetNameFlow("unity");

    handleChooseEditModal("createEnvironment");
  }

  async function handleUnityEdit(elementId: number) {
    handleSetNameFlow("unity");
    handleSetEnvironmentId(elementId);
    return handleChooseEditModal("detailEnvironment");
  }

  function formatDate(date: string) {
    const dt = new Date(date);
    return format(dt, "dd/MM/yyyy");
  }

  return (
    <>
      {isLoading && <LoadingProfiz isVisible={isLoading} />}
      <S.Container>
        <S.Top>
          <S.BackButton onClick={() => handleChooseEditModal("unityList")}>
            <FiChevronLeft size={16} color={theme.colors.text} />
          </S.BackButton>

          <S.Title>
            {selectedUnity.name ? selectedUnity.name : "Endereço sem nome"}
          </S.Title>

          <ShowWhenHavePermission moduleHash="client-unity" actionHash="edit">
            <S.EditUnity
              onClick={() => {
                handleChooseEditModal("unityForm");
              }}
            >
              <img src={PencilSvg} alt="Editar endereço" />
            </S.EditUnity>
          </ShowWhenHavePermission>
        </S.Top>

        <S.WrapperIcon>
          <img src={UnityIcon} alt="Imagem de detalhe do Endereço" />
        </S.WrapperIcon>

        <S.CardInfo>
          <S.Content>
            <S.LeftSide>
              <S.HeaderCard>
                <S.TitleCard>
                  {selectedUnity.name
                    ? selectedUnity.name
                    : "Endereço sem nome"}
                </S.TitleCard>
                <S.Tag>
                  {selectedUnity.default ? "(principal)" : "(adicional)"}
                </S.Tag>
              </S.HeaderCard>

              <S.Inline>
                <S.Circle />
                <S.Text>
                  Cadastrado em {formatDate(selectedUnity.createdAt)}
                </S.Text>
              </S.Inline>
              <S.TextInfo>
                {`${selectedUnity.street}, ${selectedUnity.number} ${selectedUnity.city} - ${selectedUnity.uf}`}
              </S.TextInfo>
            </S.LeftSide>
          </S.Content>
        </S.CardInfo>

        <ShowWhenHavePermission
          moduleHash="client-unity-environment"
          actionHash="create"
        >
          <Button typeButton="outline" onClick={handleAddEnvironment}>
            Criar ambientes e equipamentos
          </Button>
        </ShowWhenHavePermission>

        {listEnvironments && listEnvironments.length > 0 && (
          <S.WrapperItem>
            <S.TextSubTitle>Ambientes e equipamentos*</S.TextSubTitle>
          </S.WrapperItem>
        )}

        {listEnvironments && listEnvironments.length > 0 ? (
          <ShowWhenHavePermission
            moduleHash="client-unity-environment"
            actionHash="view"
          >
            {listEnvironments.map((element) => {
              return (
                <S.CardEnvironment
                  key={element.id}
                  onClick={() => {
                    element.id && handleUnityEdit(element.id);
                  }}
                >
                  <S.WrapperTextCardEnvironment>
                    {element.equipments && element.equipments?.length > 0 ? (
                      <>
                        <S.TextEnvironment>
                          {`${element.name} `} |{" "}
                        </S.TextEnvironment>
                        <S.TextInfoEnvironment>
                          {` ${element.equipments?.length} ${
                            element.equipments?.length &&
                            element.equipments?.length > 1
                              ? "equipamentos"
                              : "equipamento"
                          }`}
                        </S.TextInfoEnvironment>
                      </>
                    ) : (
                      <S.TextEnvironment>{element.name}</S.TextEnvironment>
                    )}
                  </S.WrapperTextCardEnvironment>
                </S.CardEnvironment>
              );
            })}
          </ShowWhenHavePermission>
        ) : (
          <S.ViewMessage>
            <S.MessageText>
              Você ainda não criou nenhum ambiente para esta unidade.
            </S.MessageText>
            <S.MessageText>
              Toque em <strong>“Criar ambientes e equipamentos”</strong> para
              começar.
            </S.MessageText>
          </S.ViewMessage>
        )}
      </S.Container>
    </>
  );
}
