import React, { ButtonHTMLAttributes } from "react";

import emptyStarImg from "assets/icons/empty-star.svg";
import fillStarImg from "assets/icons/fill-star.svg";
import * as S from "./styles";
import Rating from "react-rating";
import { ServiceOrderDTO } from "dtos/ServiceOrderDetailDTO";

export type AvaliationProps = {
  id: number;
  executed: boolean;
  stars: number;
  sequenceNumber: number;
  clientName: string;
  serviceOrder?: ServiceOrderDTO;
};

type CardProps = ButtonHTMLAttributes<HTMLButtonElement> & {
  data: AvaliationProps;
  showStatus?: boolean;
  status?: string;
};

export function AvaliationCard({
  data,
  showStatus = true,
  status,
  ...rest
}: CardProps) {
  return (
    <S.Container {...rest}>
      <header>
        <S.WrapperRating>
          {data.executed ? (
            <>
              <p>{data.stars}</p>
              <Rating
                readonly
                quiet
                emptySymbol={<S.Star src={emptyStarImg} />}
                fullSymbol={<S.Star src={fillStarImg} />}
                initialRating={data.stars}
              />
            </>
          ) : (
            <p>Sem avaliação</p>
          )}
        </S.WrapperRating>
        {showStatus && (
          <S.StatusRow>
            <S.Dot
              style={{
                background: status
                  ? status === "concluded"
                    ? "#D3FF9A"
                    : "#FFF595"
                  : !data.serviceOrder
                  ? "#D3FF9A"
                  : "#FFF595",
              }}
            ></S.Dot>
            {status
              ? status === "concluded"
                ? "Concluída"
                : "Pendente"
              : data.serviceOrder
              ? "Pendente"
              : "Concluida"}
          </S.StatusRow>
        )}
      </header>
      <S.BottomStatus>
        <S.OsText>
          OS: {data.serviceOrder?.sequenceNumber || data.sequenceNumber}
        </S.OsText>
        <p>Cliente: {data.clientName}</p>
      </S.BottomStatus>
    </S.Container>
  );
}
