import { useState, useRef, useEffect, RefObject } from "react";

import { NotificationList } from "components/NotificationList";

import UserPermissionsSVG from "assets/icons/permission/user-plus.svg";

import { useWarningMessage } from "hooks/warningMessage";

import * as S from "./styles";

export function PermissionNotification() {
  const [showNotification, setShowNotification] = useState(false);
  const [countNotification, setCountNotification] = useState(0);
  const notificationRef = useRef(null);
  const { modifyBudget, handleSetBudgetMessage } = useWarningMessage();

  useOnClickOutside(notificationRef, () => setShowNotification(false));

  function getQuantityNotification(quantity: number) {
    setCountNotification(quantity);
  }

  function handleToggleNotification() {
    setShowNotification(!showNotification);
  }

  function useOnClickOutside(
    ref: RefObject<HTMLElement>,
    handler: (event: MouseEvent | TouchEvent) => void
  ) {
    useEffect(() => {
      const listener = (event: MouseEvent | TouchEvent) => {
        if (!ref.current || ref.current.contains(event.target as Node)) {
          return;
        }
        handler(event);
      };
      document.addEventListener("mousedown", listener);
      document.addEventListener("touchstart", listener);
      return () => {
        document.removeEventListener("mousedown", listener);
        document.removeEventListener("touchstart", listener);
      };
    }, [ref, handler]);
  }

  return (
    <>
      <S.Container
        hasNotification={countNotification > 0}
        onClick={() => {
          if (modifyBudget) {
            return handleSetBudgetMessage(true);
          }
          handleToggleNotification();
        }}
      >
        {countNotification > 0 && (
          <div>
            <p>{countNotification > 99 ? "99+" : countNotification}</p>
          </div>
        )}

        <img
          src={UserPermissionsSVG}
          alt="Ícone de um prestador e um sinal de mais"
        />
      </S.Container>

      <div ref={notificationRef}>
        <NotificationList
          titleList="Convites e atribuições"
          toggleNotification={showNotification}
          handleToggleNotification={handleToggleNotification}
          getAmountNotification={getQuantityNotification}
          notificationType="permission"
        />
      </div>
    </>
  );
}
