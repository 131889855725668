import { useState, useCallback } from "react";
import { FiX } from "react-icons/fi";
import axios from "axios";

import { UnityDTO } from "dtos/ClientDTO";
import PencilSvg from "assets/icons/pencil.svg";

import { ModalRight } from "components/ModalRight";
import { FormItem } from "components/FormItem";
import { SearchInput } from "components/SearchInput";
import { LoadingProfiz } from "components/LoadingProfiz";
import { CardSelectedUnity } from "./CardSelectedUnity";

import { useClient } from "hooks/budget/client";
import { useToast } from "hooks/toast";
import { useTheme } from "styled-components";

import { mappedClientUnities } from "utils/mappedClientUnities";

import { api } from "services/api";

import * as S from "./styles";
import { Button } from "components/Button";
import { UnityForm } from "components/UnityForm";
import { useEditClient } from "hooks/editClient";
import { searchDatalayer } from "utils/pushDataLayer";

export type UnitiesResponse = {
  unities: UnityDTO[];
};

type Props = {
  hasError?: string;
  disabled?: boolean;
  label?: string;
  module?: string;
};

export function UnityAreaComponent({
  hasError,
  disabled = false,
  label,
  module,
}: Props) {
  const theme = useTheme();
  const {
    hasClient,
    client,
    hasUnity,
    handleSetHasUnityClient,
    handleSetUnityClient,
    unity,
  } = useClient();

  const { handleSetEditClient, clearUnityData, setSelectedUnity } =
    useEditClient();

  const { addToast } = useToast();

  const [unities, setUnities] = useState<UnityDTO[]>([]);
  const [filteredUnities, setFilteredUnities] = useState<UnityDTO[]>([]);
  const [modalOpen, setModalOpen] = useState(false);

  const [isLoading, setIsLoading] = useState(false);
  const [isOpenUnityArea, setIsOpenUnityArea] = useState(false);
  const [searchInput, setSearchInput] = useState("");
  const [createUnity, setCreateUnity] = useState(false);

  const getUnities = useCallback(async () => {
    try {
      setIsLoading(true);
      if (client.id) {
        const response = await api.get<UnitiesResponse>(
          `/clients/${client.id}/unities`,
          {
            params: {
              limit: 50,
              offset: 0,
              client_id: client.id,
            },
          }
        );

        const orderedList = response.data.unities.sort((current, next) => {
          if (current.default && !next.default) {
            return -1;
          } else {
            return 1;
          }
        });

        setUnities(mappedClientUnities(orderedList));
        setFilteredUnities(mappedClientUnities(orderedList));
      }
    } catch (error) {
      addToast({
        title: "Erro",
        description:
          axios.isAxiosError(error) && error.response?.data.error
            ? error.response?.data.error
            : "Não foi possível carregar os endereços.",
        type: "error",
      });
    } finally {
      setIsLoading(false);
    }
  }, [addToast, client]);

  function handleToggleUnityModal() {
    if (!hasClient) {
      addToast({
        type: "info",
        title: "Oops!",
        description: "Você deve selecionar um cliente primeiro.",
      });

      return;
    }
    setIsOpenUnityArea((prevState) => !prevState);
    getUnities();
  }

  function handleSearchUnity(text: string) {
    setSearchInput(text);

    const filtered = unities.filter((unity) => {
      const unitiesLowerCase = unity!.name.toLowerCase();
      const searchValue = text.toLowerCase();
      return unitiesLowerCase.includes(searchValue);
    });

    if (text) {
      searchDatalayer({ search_term: text, success: true });
    }
    setFilteredUnities(filtered);
  }

  function handleSearchCancel() {
    setSearchInput("");
    setFilteredUnities(unities);
  }

  function handleSwapCreateUnity() {
    clearUnityData();
    setCreateUnity((prevState) => !prevState);
    handleSetEditClient(client);
    if (filteredUnities.length >= 1) setSelectedUnity({} as UnityDTO);
  }

  function handleSelectUnity(unity: UnityDTO) {
    handleSetUnityClient(unity);
    handleSetHasUnityClient(true);
    handleToggleUnityModal();
  }

  const handleCloseNewUnity = () => {
    getUnities();
    setCreateUnity((prevState) => !prevState);
  };

  const hasDefaultUnity = unities.every((unity) => unity.default);

  return (
    <>
      {disabled ? (
        <CardSelectedUnity
          disabled={disabled}
          showUnitiesList={handleToggleUnityModal}
        />
      ) : (
        <>
          {hasUnity ? (
            <CardSelectedUnity
              module={module}
              showUnitiesList={handleToggleUnityModal}
            />
          ) : (
            <FormItem
              name="Endereço (opcional)"
              placeholder={label ? label : "Adicione um endereço"}
              hasError={hasError}
              onClick={handleToggleUnityModal}
            />
          )}

          <ModalRight
            handleToggleOpen={handleToggleUnityModal}
            isOpen={isOpenUnityArea}
          >
            {isLoading ? (
              <LoadingProfiz isVisible={isLoading} />
            ) : (
              <>
                {createUnity ? (
                  <UnityForm
                    module={module}
                    defaultUnity={hasDefaultUnity}
                    handleToggleOpen={() => {
                      handleToggleUnityModal();
                    }}
                    handleFunction={() => {
                      handleCloseNewUnity();
                    }}
                  />
                ) : (
                  <>
                    <S.Top>
                      <S.ButtonClose onClick={() => handleToggleUnityModal()}>
                        <FiX size={10} color={theme.colors.text} />
                      </S.ButtonClose>

                      <p>Endereços cadastrados</p>
                    </S.Top>

                    <S.Content>
                      <div>
                        <SearchInput
                          placeholder="Procure pelo nome ou endereço"
                          searchValue={searchInput}
                          onChange={(event) =>
                            handleSearchUnity(event.target.value)
                          }
                          // eslint-disable-next-line react/jsx-no-bind
                          handleCancel={handleSearchCancel}
                        />
                      </div>

                      <div>
                        <Button
                          typeButton="outline"
                          onClick={handleSwapCreateUnity}
                        >
                          {filteredUnities.length < 1
                            ? "Adicionar endereço principal"
                            : "Adicionar novo endereço"}
                        </Button>
                      </div>

                      {filteredUnities.length < 1 ? (
                        <S.WrapperMessage>
                          <p className="message">
                            Não existem endereços cadastrados.
                          </p>
                          <p className="message">
                            Pressione 'Adicionar endereço principal' para criar
                            uma endereço principal.
                          </p>
                        </S.WrapperMessage>
                      ) : (
                        <S.WrapperList>
                          {filteredUnities.map((item) => (
                            <S.CardUnity
                              isSelected={unity && item.id === unity.id}
                              key={item.id}
                            >
                              <S.WrapperCheckBox
                                onClick={() => handleSelectUnity(item)}
                              >
                                <S.Checkbox>
                                  {unity && item.id === unity.id && (
                                    <S.Marker />
                                  )}
                                </S.Checkbox>

                                <div className="content">
                                  <p className="unity-name">
                                    {item.name}
                                    <span>
                                      (
                                      {item.default ? "principal" : "adicional"}
                                      )
                                    </span>
                                  </p>

                                  <div className="register-date">
                                    <S.Circle />
                                    <p>Cadastrado em {item.createdAt}</p>
                                  </div>

                                  <p className="address">
                                    {item.street}, {item.number} - {item.city} -{" "}
                                    {item.uf}
                                  </p>
                                </div>
                              </S.WrapperCheckBox>

                              <S.Top>
                                <S.EditUnity
                                  onClick={() => {
                                    setSelectedUnity(item);
                                    setModalOpen(true);
                                  }}
                                >
                                  <img src={PencilSvg} alt="Editar endereço" />
                                </S.EditUnity>
                              </S.Top>
                            </S.CardUnity>
                          ))}
                        </S.WrapperList>
                      )}
                    </S.Content>
                  </>
                )}
              </>
            )}
          </ModalRight>

          <ModalRight
            isOpen={modalOpen}
            handleToggleOpen={() => setModalOpen(!modalOpen)}
          >
            <UnityForm
              getFilteredUnities={setFilteredUnities}
              module={module}
              defaultUnity={hasDefaultUnity}
              handleToggleOpen={() => {
                setModalOpen(!modalOpen);
                setIsOpenUnityArea(false);
              }}
              handlePmocEditModal={(unity?: UnityDTO) => {
                if (unity) {
                  handleSetUnityClient(unity);
                }
                setModalOpen(!modalOpen);
              }}
            />
          </ModalRight>
        </>
      )}
    </>
  );
}
