export const states = [
    {name: 'AC', id: 1},
    {name: 'AL', id: 2},
    {name: 'AP', id: 3},
    {name: 'AM', id: 4},
    {name: 'BA', id: 5},
    {name: 'CE', id: 6},
    {name: 'DF', id: 7},
    {name: 'ES', id: 8},
    {name: 'GO', id: 9},
    {name: 'MA', id: 10},
    {name: 'MT', id: 11},
    {name: 'MS', id: 12},
    {name: 'MG', id: 13},
    {name: 'PA', id: 14},
    {name: 'PB', id: 15},
    {name: 'PR', id: 16},
    {name: 'PE', id: 17},
    {name: 'PI', id: 18},
    {name: 'RJ', id: 19},
    {name: 'RN', id: 20},
    {name: 'RS', id: 21},
    {name: 'RO', id: 22},
    {name: 'RR', id: 23},
    {name: 'SC', id: 24},
    {name: 'SP', id: 25},
    {name: 'SE', id: 26},
    {name: 'TO', id: 27},
]