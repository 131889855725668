import { lighten } from "polished";
import styled, { css } from "styled-components";

export const Container = styled.div``;

interface ButtonCallenderProps {
  selectedDate: boolean;
}

export const ButtonCalendar = styled.button<ButtonCallenderProps>`
  ${({ theme, selectedDate }) => css`
    width: 145px;
    height: 40px;
    background-color: ${theme.colors.menu_detail_light};
    border: ${selectedDate && `solid 1px ${theme.colors.primary}`};
    border-radius: 10px;
    display: flex;
    align-items: center;
    padding: 0 10px;

    img {
      width: 35px;
      margin-right: 10px;
    }
  `}
`;

export const DetailCalendarText = styled.span`
  ${({ theme }) => css`
    font-family: ${theme.font.family};
    font-size: ${theme.font.sizes.small};
    color: ${theme.colors.financial_text};
    line-height: 16px;
    letter-spacing: 0.26px;
  `}
`;

export const ContainerOverlay = styled.div`
  ${({ theme }) => css`
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;

    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: ${theme.layers.modal};
  `}
`;

export const ModalCalendar = styled.div`
  ${({ theme }) => css`
    background-color: ${theme.colors.background_modal};
    border-radius: 10px;
    z-index: ${theme.layers.alwaysOnTop};

    .DayPicker {
      width: 100%;
    }

    .DayPicker-Day {
      padding-left: 14px;
      padding-right: 14px;
    }
  `}
`;

export const WrapperButton = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 10px;
  margin-right: 30px;
`;

export const ButtonCloseModal = styled.button`
  ${({ theme }) => css`
    width: 40px;
    height: 40px;
    border-radius: 2px;

    :hover {
      background-color: ${lighten(0.2, theme.colors.background_light)};
    }
  `}
`;
