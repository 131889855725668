import styled, { css } from "styled-components";

type ContainerProps = {
  isSelected: boolean;
};

export const Container = styled.button<ContainerProps>`
  ${({ theme, isSelected }) => css`
    width: 200px;
    margin-bottom: 5px;
    display: flex;
    align-items: center;
    padding: 5px;
    border-radius: 50px;
    background-color: ${isSelected
      ? theme.colors.primary_opacity_60
      : theme.colors.primary_opacity};

    img {
      width: 25px;
      height: 25px;
      border-radius: 15px;
    }

    span {
      width: 70%;
      font-size: ${theme.font.sizes.xsmall};
      font-weight: bold;
      color: ${theme.colors.text_light_87};
      margin-left: 10px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  `}
`;
