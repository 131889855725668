import React, { useEffect, useCallback } from "react";
import { useLocation } from "react-router-dom";
import { useHistory } from "react-router";

import { ContentLeft } from "templates/ContentLeft";

import { ArrowButton } from "components/ArrowButton";
import { Button } from "components/Button";

import * as S from "./styles";

type RouteState = {
  pdfDownloadLink: string;
};

export function DownloadServiceOrder() {
  const location = useLocation<RouteState>();
  const history = useHistory();

  const handleOpenDownloadPage = useCallback(() => {
    window.open(`${location.state.pdfDownloadLink}&download=true`);

    setTimeout(() => {
      history.goBack();
    }, 3000);
  }, [location.state.pdfDownloadLink, history]);

  useEffect(() => {
    if (location.state.pdfDownloadLink) {
      handleOpenDownloadPage();
    }
  }, [location.state.pdfDownloadLink, handleOpenDownloadPage]);

  return (
    <S.Container>
      <ContentLeft>
        <header>
          <div>
            <ArrowButton />
          </div>
        </header>
      </ContentLeft>

      <S.WrapperCenter>
        <h1>O download do seu PDF vai começar automaticamente.</h1>

        <Button typeButton="textOnly" onClick={handleOpenDownloadPage}>
          Clique aqui caso o download não inicie automaticamente
        </Button>
      </S.WrapperCenter>
    </S.Container>
  );
}
