import styled, { css } from "styled-components";

export const Container2 = styled.div`
  ${({ theme }) => css`
    flex: 1;
    background-color: ${theme.colors.background};
  `}
`;

export const BreakLine = styled.br``;

export const Container = styled.div.attrs({
  showsVerticalScrollIndicator: false,
})`
  ${({ theme }) => css`
    flex: 1;
    background-color: ${theme.colors.strong_background};
  `}
`;

export const Header = styled.header`
  ${({ theme }) => css`
    display: flex;
    padding: 30px;

    h1 {
      display: flex;
      font-size: ${theme.font.sizes.large};
      color: ${theme.colors.title};
      font-weight: ${theme.font.bold};
      margin-top: 30px;
    }
  `}
`;

export const Content = styled.div`
  padding-left: 30px;
`;

export const SearchWrapper = styled.div`
  width: 470px;
`;

export const WrapperPadding = styled.div`
  /* padding: 30px; */
  padding-left: 15px;
  padding-right: 15px;
  padding-bottom: 15px;
`;

export const WrapperFilter = styled.div`
  margin-bottom: 15px;
  margin-top: 15px;
`;

export const CategoryArea = styled.div`
  ${({ theme }) => css`
    width: 100%;
    padding: 10px 30px;
    border-top-width: 1px;
    border-top-color: ${theme.colors.text_light};
    border-bottom-width: 1px;
    border-bottom-color: ${theme.colors.text_light};
  `}
`;

export const Text = styled.p`
  ${({ theme }) => css`
    font-size: 13px;
    color: ${theme.colors.text_light};
    font-family: ${theme.font.normal};
    padding-top: 10px;
    padding-bottom: 10px;
  `}
`;

export const QuestionArea = styled.button`
  ${({ theme }) => css``}
`;

export const WrapperListQuestion = styled.div`
  flex: 1;
  padding-left: 15px;
  padding-right: 15px;
`;

export const Separator = styled.div`
  ${({ theme }) => css`
    width: 100%;
    border-bottom-width: 1px;
    border-bottom-color: ${theme.colors.text_light};
  `}
`;

export const Title = styled.p`
  ${({ theme }) => css`
    font-size: 13px;
    font-family: ${theme.font.normal};
    color: ${theme.colors.text_extra_light};
    letter-spacing: 0.31px;
    line-height: 20px;
    margin-bottom: 5px;
    text-align: left;
  `}
`;

export const SubTitle = styled.div`
  ${({ theme }) => css`
    font-size: 13px;
    font-family: ${theme.font.normal};
    color: ${theme.colors.financial_text};
    letter-spacing: 0.26px;
    text-align: left;
    padding-right: 15px;
    padding-top: 20px;
    opacity: 1;
  `}
`;

export const DoubtContainer = styled.div`
  margin-right: 20px;
  align-items: center;
`;

export const SubTitleDoubt = styled.p`
  ${({ theme }) => css`
    font-size: 13px;
    font-family: ${theme.font.normal};
    color: ${theme.colors.financial_title};
    letter-spacing: 0.26px;
    text-align: center;
    padding-right: 15px;
    opacity: 1;
  `}
`;

export const ContainerButtons = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  margin-top: 15px;
`;

export const WrapperButton = styled.div`
  width: 150px;
  padding-right: 15px;
`;

export const TitlePage = styled.p`
  ${({ theme }) => css`
    font-size: 18px;
    font-family: ${theme.font.bold};
    color: ${theme.colors.text_extra_light};
    letter-spacing: 0.4px;
    line-height: 20px;
    margin-bottom: 5px;
  `}
`;

export const WrapperMessage = styled.div`
  flex: 1;
  justify-content: center;
  text-align: center;
`;
