import React, { useState } from "react";

import { ListRegisterForServices } from "pages/fieldService/ServiceOrderConcludedDetail/ListRegisterForService";

import { CardPhotoService } from "components/CardPhotoService";

import arrowLeft from "assets/icons/arrow-left.svg";
import arrowRight from "assets/icons/arrow-right.svg";

import { useResumePmoc } from "hooks/resumePmoc";
import { usePmocTabIndex } from "hooks/pmoc/tabIndex";
import { useServiceOrder } from "hooks/serviceOrder";

import { useToast } from "hooks/toast";
import apiv2 from "services/apiv2";

import * as S from "./styles";
import { LoadingProfiz } from "components/LoadingProfiz";

type ChecklistDetailsProps = {
  goBack?: () => void;
};

export function ChecklistDetails({ goBack }: ChecklistDetailsProps) {
  const { addToast } = useToast();

  const { selectedServiceOrder } = useServiceOrder();
  const { handleSetSelectedIndex } = usePmocTabIndex();
  const { handleChangeModalSelected } = useResumePmoc();

  const [isLoading, setIsLoading] = useState(false);

  async function handleConcludeServiceOrder() {
    try {
      setIsLoading(true);
      await apiv2.put(`budgets/service-order/${selectedServiceOrder.id}`, {
        originUpdate: "web",
      });

      addToast({
        title: "Sucesso!",
        description: "Ordem de serviço encerrada com sucesso!",
        type: "success",
      });

      handleChangeModalSelected("detailOfMonthPmoc");
      handleSetSelectedIndex(2);
    } catch (error) {
      addToast({
        title: "Ops!",
        description: "Falha ao encerrar a ordem de serviço",
        type: "error",
      });
    } finally {
      setIsLoading(false);
    }
  }

  function handleGoBack() {
    if (typeof goBack !== "undefined") {
      goBack();
    } else {
      handleChangeModalSelected("registerSignatures");
    }
  }

  return (
    <>
      {isLoading ? (
        <LoadingProfiz isVisible={isLoading} />
      ) : (
        <>
          <S.ButtonClose onClick={handleGoBack}>
            <img src={arrowLeft} alt="flecha pra esquerda" />
          </S.ButtonClose>

          <S.Title>Checklist do serviço</S.Title>

          <div style={{ height: "6vh", minHeight: 530 }}>
            <ListRegisterForServices />

            {selectedServiceOrder?.signatures?.map((signature, index) => (
              <CardPhotoService
                key={index}
                title={
                  signature.type === "signature"
                    ? "Sua assinatura"
                    : "Assinatura do cliente"
                }
                photos={[
                  {
                    id: signature.id,
                    link: signature.link,
                  },
                ]}
              />
            ))}
          </div>

          {selectedServiceOrder.status !== "concluded" && (
            <S.Footer>
              <button type="button" onClick={handleConcludeServiceOrder}>
                <img src={arrowRight} alt="flecha pra direita" />
              </button>
            </S.Footer>
          )}
        </>
      )}
    </>
  );
}
