import { useEffect, useState } from "react";
import { useParams, useLocation } from "react-router";
import { useHistory } from "react-router";
import { format } from "date-fns";
import * as S from "./styles";
import { ArrowButton } from "components/ArrowButton";
import { usePmoc } from "hooks/usePmoc";
import closeIcon from "assets/icons/buttonClose.svg";
import PmocSvg from "assets/pmoc.svg";
import { Button } from "components/Button";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import { UnscheduledTab } from "./UnscheduledTab";
import { ScheduledTab } from "./ScheduledTab";
import { ConcludedTab } from "./ConcludedTab";
import { ArchivedTab } from "./ArchivedTab";
import { LoadingProfiz } from "components/LoadingProfiz";
import { ModalRight } from "components/ModalRight";
import { useToast } from "hooks/toast";
import axios from "axios";
import { api } from "services/api";
import { useClient } from "hooks/budget/client";
type RouteParams = {
  id: string;
  year: string;
  month: string;
  statusList: string;
};

type EnvironmentProps = {
  environmentId: number | string;
  environmentName: string;
  equipments: [
    {
      equipmentId: number | string;
      equipmentTag: string;
    }
  ];
};
type EnvironmentFilter = {
  id: number;
  name: string;
};
type DictOption = {
  [key: string]: string;
};
function monthNumberToMonthName(month: string) {
  const dictMonth: DictOption = {
    1: "janeiro",
    2: "fevereiro",
    3: "março",
    4: "abril",
    5: "maio",
    6: "junho",
    7: "julho",
    8: "agosto",
    9: "setembro",
    10: "outubro",
    11: "novembro",
    12: "dezembro",
  };
  return formmatMonthName(dictMonth[month]);
}
function formmatMonthName(monthName: string) {
  return monthName[0].toUpperCase() + monthName.substring(1);
}
export function PmocOrderServiceList() {
  const { id, year, month } = useParams<RouteParams>();
  const history = useHistory();
  const location = useLocation();
  const { addToast } = useToast();
  const { handleSetClient } = useClient();
  const [loadingList, setLoadingList] = useState(false);
  const [filterModal, setFilterModal] = useState(false);
  const [environmentFilter, setEnvironmentFilter] = useState<
    EnvironmentFilter[]
  >([]);
  const [selectedEnvironmentFilter, setSelectedEnvironmentFilter] =
    useState<EnvironmentFilter>({} as EnvironmentFilter);
  const {
    initialPmocProps,
    handleSetInitialPmocProps,
    periodProps,
    handleSetPeriodProps,
    handleSetStartDate,
  } = usePmoc();
  const queryParams = new URLSearchParams(location.search);
  const statusList = queryParams.get("statusList");
  const beforePage = !!statusList ? Number(statusList) : 0;
  const [selectPage, setSelectedPage] = useState<number>(beforePage || 0);
  const [initialDatePmoc, setInitialDatePmoc] = useState("");
  const [statusPmoc, setStatusPmoc] = useState("");
  function formattedDate(dateValue: string) {
    let dateFormatted = new Date(dateValue);
    return format(dateFormatted, "dd/MM/yyyy");
  }
  const setFilterInfos = async () => {
    try {
      const response = await api.get(`/pmoc/${id}`);
      response.data &&
        response.data.client &&
        handleSetClient(response.data.client);
      response.data &&
        response.data.status &&
        setStatusPmoc(response.data.status);
      response.data &&
        response.data.id &&
        response.data.name &&
        handleSetInitialPmocProps({
          id: response.data.id,
          name: response.data.name,
        });
      response.data &&
        response.data.contractPeriod &&
        response.data.contractPeriod.period &&
        response.data.contractPeriod.type &&
        handleSetPeriodProps({
          period: response.data.contractPeriod.period,
          frequency: response.data.contractPeriod.type,
        });
      if (response.data && response.data.startDate) {
        handleSetStartDate(response.data.startDate);
        setInitialDatePmoc(response.data.startDate);
      }
      if (response.data && response.data.environments) {
        setEnvironmentFilter(
          response.data.environments.map((env: EnvironmentProps) => {
            return { id: env.environmentId, name: env.environmentName };
          })
        );
      }
    } catch (error) {
      addToast({
        title: "Erro",
        description:
          axios.isAxiosError(error) && error.response?.data.error
            ? error.response?.data.error
            : "Não foi possível carregar os dados do filtro.",
        type: "error",
      });
    } finally {
    }
  };
  useEffect(() => {
    setFilterInfos();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  function redirectPage(status: string) {
    const redirectPage: DictOption = {
      pending: `/pmoc/finish-register/${id}`,
      execution: `/pmoc/execution/${id}`,
      concluded: `/pmoc/concluded/${id}`,
      archived: `/pmoc/archived/${id}`,
    };
    return redirectPage[status];
  }
  return (
    <>
      {loadingList && <LoadingProfiz isVisible={loadingList} />}
      <S.Container>
        <ModalRight
          isOpen={filterModal}
          handleToggleOpen={() => {
            setFilterModal(false);
          }}
        >
          <S.Container>
            <S.Wrapper>
              <S.CloseButton onClick={() => setFilterModal(false)}>
                <img src={closeIcon} alt="fechar" />
              </S.CloseButton>
            </S.Wrapper>
            <S.Wrapper>
              <S.Title>Filtrar</S.Title>
            </S.Wrapper>
            <S.Wrapper>
              {environmentFilter.map((env) => {
                return (
                  <S.FilterElement key={env.id}>
                    <S.CheckBox
                      onClick={() => {
                        if (
                          selectedEnvironmentFilter &&
                          Number(selectedEnvironmentFilter.id) ===
                            Number(env.id)
                        ) {
                          return setSelectedEnvironmentFilter(
                            {} as EnvironmentFilter
                          );
                        }
                        setSelectedEnvironmentFilter(env);
                      }}
                    >
                      <S.MarkChecked
                        isChecked={
                          selectedEnvironmentFilter &&
                          selectedEnvironmentFilter.id === env.id
                            ? true
                            : false
                        }
                      />
                    </S.CheckBox>
                    <p className="name-filter">{env.name}</p>
                  </S.FilterElement>
                );
              })}
            </S.Wrapper>
            <Button onClick={() => setFilterModal(false)}>Filtrar</Button>
          </S.Container>
        </ModalRight>
        <div style={{ marginTop: 20 }}>
          <ArrowButton
            handleFunction={() => {
              history.push(redirectPage(statusPmoc));
            }}
          />
        </div>
        <header>
          <S.Title>
            {" "}
            {monthNumberToMonthName(month)} de {year}{" "}
          </S.Title>
        </header>
        <div className="content">
          <S.Wrapper>
            <S.TitleCard>
              <img src={PmocSvg} alt="icone pmoc" height={37} />
              <div className="titleInfo">
                <p className="title"> {initialPmocProps.name} </p>
                <div className="pmoc-infos">
                  <p className="start-date">
                    {`Início: ${
                      initialDatePmoc && formattedDate(initialDatePmoc)
                    } |`}{" "}
                  </p>
                  <p className="period">
                    {" "}
                    {`Período: ${periodProps.period} ${periodProps.frequency}`}{" "}
                  </p>
                </div>
              </div>
            </S.TitleCard>
          </S.Wrapper>
          <S.Wrapper>
            <Button typeButton="outline" onClick={() => setFilterModal(true)}>
              Filtrar
            </Button>
          </S.Wrapper>
          <S.Title>Ordens de serviço</S.Title>
          <S.TabContainer>
            <Tabs
              selectedIndex={selectPage}
              onSelect={(index: number) => {
                setSelectedPage(index);
              }}
            >
              <TabList>
                <Tab>Não agendadas</Tab>
                <Tab>Agendadas</Tab>
                <Tab>Concluídas</Tab>
                <Tab>Arquivadas</Tab>
              </TabList>
              <TabPanel>
                <UnscheduledTab
                  year={Number(year)}
                  month={month}
                  idPmoc={Number(id)}
                  setLoading={setLoadingList}
                  filter={selectedEnvironmentFilter}
                  filterModalClose={!filterModal}
                />
              </TabPanel>
              <TabPanel>
                <ScheduledTab
                  year={Number(year)}
                  month={month}
                  idPmoc={Number(id)}
                  setLoading={setLoadingList}
                  filter={selectedEnvironmentFilter}
                  filterModalClose={!filterModal}
                />
              </TabPanel>
              <TabPanel>
                <ConcludedTab
                  year={Number(year)}
                  month={month}
                  idPmoc={Number(id)}
                  setLoading={setLoadingList}
                  filter={selectedEnvironmentFilter}
                  filterModalClose={!filterModal}
                />
              </TabPanel>
              <TabPanel>
                <ArchivedTab
                  year={Number(year)}
                  month={month}
                  idPmoc={Number(id)}
                  setLoading={setLoadingList}
                  filter={selectedEnvironmentFilter}
                  filterModalClose={!filterModal}
                />
              </TabPanel>
            </Tabs>
          </S.TabContainer>
        </div>
      </S.Container>
    </>
  );
}
