import { ArrowButton } from "components/ArrowButton";
import { Button } from "components/Button";

import * as S from './styles';
import { Calendar } from "components/Calendar";

type ScheduledProps = {
  closeModal: () => void;
  loading: boolean;
  selectedDate: Date | undefined;
  handleChangeDate: (day: Date) => void;
  handleUpdatePmoc: ()=> void;
}

export function ScheduledComponent({
  closeModal,
  loading,
  selectedDate,
  handleChangeDate,
  handleUpdatePmoc
}: ScheduledProps) {
  return (
          <S.ScheduledModal>
              <div className="arrow-button">
                  <ArrowButton
                  handleFunction={
                    closeModal
                  }
                />
              </div>
              <S.Title>Gerar ordens de serviço</S.Title>
              <p className="subtitle">
                Defina a data de início, a partir da qual as ordens de serviço
                serão geradas automaticamente.*
              </p>
              <S.DivModal>
                <Calendar
                  selectDays={selectedDate}
                  onChangeDate={handleChangeDate}
                />
              </S.DivModal>

              <Button
                disabled={!!!selectedDate || loading}
                loading={loading}
                onClick={handleUpdatePmoc}
              >
                Definir data de início e gerar ordens de serviço
              </Button>

              <p className="info">
                *Se você estiver migrando para a Profiz e cadastrando um PMOC
                que já iniciou fora do aplicativo, você pode definir a data que
                você deu início ao PMOC. As ordens de serviço antigas receberão
                um status de “não agendadas” e você poderá realizar o checklist
                normalmente.
              </p>
          </S.ScheduledModal>
  );
}