import { useHistory } from "react-router";

import { Page } from "templates/Page";

import {
  ListItemCard,
  Label,
  ModuleList,
  mappedProfileIcons,
} from "components/Permission";
import { Button } from "components/Button";

import { useAuth } from "hooks/auth";
import { useAccount } from "hooks/permission/account";

import * as S from "./styles";

export function MyInvite() {
  const history = useHistory();
  const { user } = useAuth();
  const { whoami } = useAccount();

  return (
    <>
      <Page
        title={user.name}
        titleAlignedBelow
        subtitle="Você foi convidado para fazer parte de uma equipe desta empresa."
        style={{ position: "relative", marginBottom: 40 }}
      >
        <S.Wrapper>
          <Label>Empresa</Label>
          <ListItemCard
            image={whoami?.companyLogo || ""}
            title={whoami?.companyName || ""}
            roundedImage
            disabled
          />
        </S.Wrapper>

        <S.Wrapper>
          <Label>Cargo</Label>
          <ListItemCard
            image={mappedProfileIcons[whoami?.profile?.id || 1]}
            title={whoami?.profile?.name || ""}
            subtitle={
              whoami?.profile?.default ? "Pré-definido" : "Personalizado"
            }
            disabled
          />
        </S.Wrapper>

        <S.Wrapper>
          <Label>Permissões</Label>
          <ModuleList modules={whoami?.profile?.modules || []} />
        </S.Wrapper>

        <Button
          typeButton="delete"
          onClick={() =>
            history.push("/teams/my-invite/confirm-exit", {
              profile: {
                id: whoami?.profile.id,
                name: whoami?.profile.name,
              },
            })
          }
          style={{ position: "fixed", bottom: 20, width: 500 }}
        >
          Solicitar saída da equipe
        </Button>
      </Page>
    </>
  );
}
