import React, { createContext, useContext, useState } from 'react';
import { ProductItemDTO } from '../../dtos/ProductItemDTO';
import addMaskMoney from '../../utils/addMaskMoney';
import { ProviderProps } from 'hooks';

export interface ProductPropsUsedInContext extends ProductItemDTO {
  idBudget?: number;
  quantity: number;
  total: number;
  formattedTotal: string;
  isChecked?: boolean;
}

type SelectedProductsContextData = {
  selectedProductsInContext: ProductPropsUsedInContext[];
  handleSetSelectedProducts: (products: ProductPropsUsedInContext[]) => void;
  handleUpdateQuantity: (id: number, quantity: number) => void;
  calculateTotalProducts: () => number;
  handleRemoveSelectedProductOnCreate: (id: number) => void;
  handleRemoveSelectedProductOnEdit: (idBudgetProduct: number) => void;
  clearSelectedProducts: () => void;
};

const SelectedProductsContext = createContext(
  {} as SelectedProductsContextData,
);

export function SelectedProductsProvider({ children }: ProviderProps) {
  const [selectedProductsInContext, setSelectedProductsInContext] = useState<
    ProductPropsUsedInContext[]
  >([]);

  function handleSetSelectedProducts(products: ProductPropsUsedInContext[]) {
    setSelectedProductsInContext(products);
  }

  function handleUpdateQuantity(id: number, quantity: number) {
    const updatedSelectedProducts = selectedProductsInContext.map(
      (selectedProduct: ProductPropsUsedInContext) => {
        if (selectedProduct.id === id) {
          return {
            ...selectedProduct,
            total: selectedProduct.price * quantity,
            formattedTotal: addMaskMoney(selectedProduct.price * quantity),
            quantity,
          };
        } else {
          return selectedProduct;
        }
      },
    );

    setSelectedProductsInContext(updatedSelectedProducts);
  }

  function calculateTotalProducts() {
    return selectedProductsInContext.reduce((accumulator, current) => {
      return current.total ? accumulator + current.total : accumulator;
    }, 0);
  }

  function handleRemoveSelectedProductOnCreate(id: number) {
    const parsedFilter = selectedProductsInContext.filter(
      selectedProduct => selectedProduct.id !== id,
    );

    setSelectedProductsInContext(parsedFilter);
  }

  function handleRemoveSelectedProductOnEdit(idBudgetProduct: number) {
    const parsedFilter = selectedProductsInContext.filter(
      selectedProduct => selectedProduct.idBudget !== idBudgetProduct,
    );

    setSelectedProductsInContext(parsedFilter);
  }

  function clearSelectedProducts() {
    setSelectedProductsInContext([]);
  }

  return (
    <SelectedProductsContext.Provider
      value={{
        selectedProductsInContext,
        handleSetSelectedProducts,
        handleUpdateQuantity,
        calculateTotalProducts,
        handleRemoveSelectedProductOnCreate,
        handleRemoveSelectedProductOnEdit,
        clearSelectedProducts,
      }}
    >
      {children}
    </SelectedProductsContext.Provider>
  );
}

export function useSelectedProducts() {
  const context = useContext(SelectedProductsContext);

  if (!context) {
    throw Error(
      'useSelectedProducts must be used within a SelectedProductsProvider',
    );
  }

  return context;
}
