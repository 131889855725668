import { AccountDTO } from "dtos/permission/AccountDTO";

import * as S from "./styles";

type Props = {
  data: AccountDTO;
  isSelected: boolean;
  onSelect: () => void;
};

export function AccountSelector({ data, isSelected, onSelect }: Props) {
  return (
    <S.Container type="button" isSelected={isSelected} onClick={onSelect}>
      <img src={data.photo} alt="Foto da conta" />
      <span>{data.name}</span>
    </S.Container>
  );
}
