import React, { createContext, useContext, useState } from "react";
import { ProviderProps } from "hooks";

type ChecklistItemList = {
  id: number;
  name: string;
  default?: boolean;
  registers: RegisterItem[];
};

type RegisterItem = {
  id: number; // ID do registro que o back devolve
  userId?: number;
  checklistId?: number;
  linkId?: number;
  order?: number; // ID da ordenacao
  type: "fotografar" | "medir" | "verificar" | "executar"; // "fotografar" | "medir" | "verificar" | "executar",
  name: string;
  description?: string;
};

type ChecklistDetail = {
  id: number;
  name: string;
  default?: boolean;
  isNew?: boolean;
  registers: RegisterItem[];
};

type NewItemChecklist = {
  name?: string;
  registers?: RegisterItem[];
};

type NewRegisterItem = {
  id?: number; // ID do registro que o back devolve
  order?: number; // ID da ordenacao
  type: "fotografar" | "medir" | "verificar" | "executar" | string; // "fotografar" | "medir" | "verificar" | "executar",
  name: string;
  linkId?: number;
  description?: string;
};

type DetailRegisterProps = {
  indexItem?: number;
  registerDetail?: NewRegisterItem | any;
};

export type Photo = {
  id: number;
  link: string;
};

export type RegisteredPhoto = {
  id: number;
  grupo: string;
  arquivo: string;
  tipo: string;
  url: string;
};

export type RegisterProps = {
  id: number;
  userId?: number;
  checklistId?: number;
  order?: number;
  type: "fotografar" | "medir" | "verificar" | "executar";
  name: string;
  description?: string;
  value?: string;
  isChecked?: boolean;
  observation?: string;
  photos?: RegisteredPhoto[];
  photosFormatted?: Photo[];
  linkId?: number;
};

type BackupRegisterProps = {
  type: string;
  name: string;
  description: string;
};

type ServiceChecklistContextData = {
  listChecklist: ChecklistItemList[];
  detailChecklist: ChecklistDetail;
  newItemChecklist: NewItemChecklist | any;
  selectedItemChecklist: ChecklistItemList[];
  isNewChecklist: boolean;
  isNotMenusAccess: boolean;
  isChecklistPage: boolean;
  isEditPage: boolean;
  //detailRegister:NewRegisterItem | any;
  detailRegister: DetailRegisterProps;
  checklistRegisters: RegisterProps[];
  backupRegister: BackupRegisterProps;
  actualRegister: BackupRegisterProps;
  handleSetBackupRegister: (register: BackupRegisterProps) => void;
  handleSetActualRegister: (register: BackupRegisterProps) => void;
  handleSetEditPage: (value: boolean) => void;
  handleSetIsChecklistPage: (isChecklist: boolean) => void;
  //handleSetDetailRegister: (register: NewRegisterItem | any) => void;
  handleSetDetailRegister: (register: DetailRegisterProps | any) => void;
  handleSetIsNotMenusAccess: (isAccess: boolean) => void;
  handleSetIsNewChecklist: (isNew: boolean) => void;
  handleNewItemChecklist: (newItem: NewItemChecklist | any) => void;
  handleSetSelectedItemChecklist: (
    checklists: ChecklistItemList[] | []
  ) => void;
  handleSetListChecklist: (checklists: ChecklistItemList[]) => void;
  handleSetChecklistDetail: (detail: ChecklistDetail) => void;
  handleSetChecklistRegister: (newRegister: RegisterProps) => void;
  handleDeleteChecklistRegister: (registerId: number) => void;
  handleSetChecklistRegisters: (registers: RegisterProps[]) => void;
};

const ServiceChecklistContext = createContext<ServiceChecklistContextData>(
  {} as ServiceChecklistContextData
);

export function useServiceChecklist(): ServiceChecklistContextData {
  const context = useContext(ServiceChecklistContext);
  if (!context) {
    throw Error("useEvents must be used within a ServiceContextProvider");
  }
  return context;
}

export const ServiceChecklistProvider = ({ children }: ProviderProps) => {
  const [listChecklist, setListChecklist] = useState<ChecklistItemList[] | []>(
    []
  );
  const [selectedItemChecklist, setSelectedItemChecklist] = useState<
    ChecklistItemList[] | []
  >([]);
  const [detailChecklist, setDetailChecklist] = useState<
    //ChecklistDetail | any
    DetailRegisterProps | any
  >();
  const [newItemChecklist, setNewItemChecklist] = useState<
    NewItemChecklist | any
  >({});
  const [detailRegister, setDetailRegister] = useState<NewRegisterItem | any>(
    {}
  );
  const [isChecklistPage, setIsChecklistPage] = useState(false);
  const [isNewChecklist, setIsNew] = useState(false);
  const [isEditPage, setEdit] = useState(false);
  const [checklistRegisters, setChecklistRegisters] = useState<RegisterProps[]>(
    []
  );
  const [actualRegister, setActualRegister] = useState<BackupRegisterProps>(
    {} as BackupRegisterProps
  );
  const [backupRegister, setBackupRegister] = useState<BackupRegisterProps>(
    {} as BackupRegisterProps
  );

  function handleSetBackupRegister(register: BackupRegisterProps) {
    setBackupRegister(register);
  }

  function handleSetActualRegister(register: BackupRegisterProps) {
    setActualRegister(register);
  }

  function handleSetEditPage(value: boolean) {
    setEdit(value);
  }

  function handleSetIsChecklistPage(isChecklist: boolean) {
    setIsChecklistPage(isChecklist);
  }

  // eslint-disable-next-line no-lone-blocks
  {
    /*
    seta o botao de vincular checklist para aparecer ou nao
      -> acesso pelo menu - Botao nao aparece(isNotMenuAccess - false)
      -> acesso por outros locais - Botao aparece(isNotMenuAccess - true)
    */
  }
  const [isNotMenusAccess, setIsNotMenusAccess] = useState(false);

  function handleSetIsNotMenusAccess(isAccess: boolean) {
    setIsNotMenusAccess(isAccess);
  }

  function handleNewItemChecklist(newItem: NewItemChecklist | any) {
    setNewItemChecklist(newItem);
  }

  function handleSetIsNewChecklist(isNew: boolean) {
    setIsNew(isNew);
  }

  function handleSetListChecklist(checklists: ChecklistItemList[]) {
    setListChecklist(checklists);
  }

  function handleSetSelectedItemChecklist(checklists: ChecklistItemList[]) {
    setSelectedItemChecklist(checklists);
  }

  function handleSetChecklistDetail(detail: ChecklistDetail) {
    setDetailChecklist(detail);
  }

  function handleSetDetailRegister(register: DetailRegisterProps | any) {
    //(register: NewRegisterItem | any) {
    setDetailRegister(register);
  }

  function handleSetChecklistRegister(newRegister: RegisterProps) {
    setChecklistRegisters([...checklistRegisters, newRegister]);
  }

  function handleSetChecklistRegisters(registers: RegisterProps[]) {
    setChecklistRegisters(registers);
  }

  function handleDeleteChecklistRegister(registerId: number) {
    const checklistsFiltered = checklistRegisters.filter(
      (checklistRegister) => checklistRegister.id !== registerId
    );
    setChecklistRegisters(checklistsFiltered);
  }

  return (
    <ServiceChecklistContext.Provider
      value={{
        isEditPage,
        isNewChecklist,
        listChecklist,
        detailChecklist,
        detailRegister,
        newItemChecklist,
        isNotMenusAccess,
        isChecklistPage,
        selectedItemChecklist,
        checklistRegisters,
        actualRegister,
        handleSetActualRegister,
        backupRegister,
        handleSetBackupRegister,
        handleSetListChecklist,
        handleSetChecklistDetail,
        handleSetSelectedItemChecklist,
        handleSetIsNewChecklist,
        handleNewItemChecklist,
        handleSetIsNotMenusAccess,
        handleSetDetailRegister,
        handleSetIsChecklistPage,
        handleSetEditPage,
        handleSetChecklistRegister,
        handleSetChecklistRegisters,
        handleDeleteChecklistRegister,
      }}
    >
      {children}
    </ServiceChecklistContext.Provider>
  );
};
