import styled, { css } from "styled-components";

export const Top = styled.div`
  margin-bottom: 30px;
  display: flex;
  align-items: center;
`;

export const ButtonClose = styled.button`
  ${({ theme }) => css`
    width: 35px;
    height: 35px;
    background-color: ${theme.colors.background_light};
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 25px;
  `}
`;

export const Title = styled.p`
  ${({ theme }) => css`
    font-size: ${theme.font.sizes.small};
    color: ${theme.colors.text};
    line-height: ${theme.font.sizes.xxlarge};
  `}
`;

export const Subtitle = styled.p`
  ${({ theme }) => css`
    font-size: ${theme.font.sizes.large};
    color: ${theme.colors.title};
    font-weight: ${theme.font.bold};
  `}
`;

export const CardContainer = styled.div`
  ${({ theme }) => css`
    margin-top: 20px;
    border-radius: 10px;
    background: ${theme.colors.background_light};
  `}
`;

export const WrapperInfoCard = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
  border-radius: 10px;
  min-height: 90px;
`;

export const ContainerImg = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: 15px;
`;

export const WrapperData = styled.div`
  ${({ theme }) => css`
    max-width: 390px;
    padding: 10px;
    padding-left: 30px;

    h6 {
      margin-top: 5px;
      color: ${theme.colors.title};
      font-size: ${theme.font.sizes.small};
      text-align: left;
      letter-spacing: 0.26px;
      line-height: 16px;
      margin-bottom: 10px;
    }

    p {
      margin-top: 5px;
      color: ${theme.colors.text_light};
      font-size: ${theme.font.sizes.xsmall};
      text-align: left;
      letter-spacing: 0.26px;
      line-height: 16px;
      white-space: pre-wrap;
    }
  `};
`;
