import styled, { css } from "styled-components";

export const Container = styled.div`
  ${({ theme }) => css`
    position: relative;
    z-index: ${theme.layers.base};
  `}
`;

export const Overlay = styled.div`
  ${({ theme }) => css`
    height: 100%;
    
    width: 100%;
    border-radius: 8px;
    background-color: rgba(0, 0, 0, 0.5);
    position: absolute;
    bottom: 1px;
    z-index: ${theme.layers.overlay};

    display: flex;
    justify-content: center;
    align-items: center;

    & > img {
      height: 12px;
      width: 45px;
    }
  `}
`;

export const ButtonDelete = styled.button`
  ${({ theme }) => css`
    height: 16px;
    width: 16px;
    border-radius: 30%;
    
    background-color: ${theme.colors.checkbox};

    display: flex;
    justify-content: center;
    align-items: center;

    position: absolute;
    right: -13px;
    top: -7px;
    transition: opacity 0.2s ease-in-out;

    &:hover {
      opacity: 0.7;
    }
  `}
`;

export const Photo = styled.img`
  ${({ theme }) => css`
    width: 50px;
    height: 50px;
    object-fit: cover;
    border-radius: 8px;
    background-color: ${theme.colors.text_extra_light};
  `}
`;
