import React, { useState } from "react";

import * as S from "./styles";

type Props = {
  children: React.ReactNode;
  title: string;
};

const CardHelpComponent: React.FC<Props> = ({ children, title }) => {
  const [active, setActive] = useState(false);

  const toggleActive = () => {
    setActive(!active);
  };

  const renderItem = () => {
    if (active) {
      return <S.WrapperElements>{children as string}</S.WrapperElements>;
    }
  };

  return (
    <S.Container defaultCursor={active}>
      <S.WrapperButton
        onClick={() => {
          toggleActive();
        }}
      >
        <S.Title>{title}</S.Title>

        <S.Icon>{active === true ? "x" : "+"}</S.Icon>
      </S.WrapperButton>
      {renderItem()}
    </S.Container>
  );
};

export default CardHelpComponent;
