import React, { createContext, useContext, useCallback, useState } from 'react'

import { nanoid } from 'nanoid'
import ToastContainer from '../components/ToastContainer'
import { ProviderProps } from 'hooks'

interface ToastContextData {
  addToast(message: Omit<ToastMessage, 'id'>): void
  removeToast(id: string): void
}

export interface ToastMessage {
  id: string
  description?: string
  type?: 'success' | 'error' | 'info'
  title: string
}

const Toast = createContext<ToastContextData>({} as ToastContextData)

const ToastProvider = ({ children }: ProviderProps) => {
  const [messages, setMessages] = useState<ToastMessage[]>([])

  const addToast = useCallback(
    ({ title, description, type }: Omit<ToastMessage, 'id'>) => {
      const message = {
        id: nanoid(),
        title,
        description,
        type
      }

      setMessages((state) => [...state, message])
    },
    []
  )

  const removeToast = useCallback((id: string) => {
    setMessages((state) => state.filter((message) => message.id !== id))
  }, [])

  return (
    <Toast.Provider value={{ addToast, removeToast }}>
      {children}
      <ToastContainer messages={messages} />
    </Toast.Provider>
  )
}

function useToast(): ToastContextData {
  const context = useContext(Toast)

  if (!context) {
    throw Error('useAuth must be used within an AuthProvider')
  }

  return context
}

export { ToastProvider, useToast }
