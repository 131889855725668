import React, { useEffect, useState } from "react";

import { SearchInput } from "components/SearchInput";
import { LoadingProfiz } from "components/LoadingProfiz";
import { ArrowButton } from "components/ArrowButton";

import CardHelpComponent from "components/CardHelpComponent";
import { Button } from "components/Button";

import { api } from "services/api";
import { useToast } from "hooks/toast";
import { useHistory } from "react-router";

import * as S from "./styles";
import { ButtonFaqFilter } from "components/ButtonFaqFilter";

type FaqProps = {
  id: number;
  category: string;
  question: string;
  answer: string;
};

type TOptions = {
  [key: string]: string;
};

export function Faq() {
  const { addToast } = useToast();
  const history = useHistory();
  const [loading, setLoading] = useState(true);
  const [filterOptions, setFilterOptions] = useState<TOptions>({});
  const [data, setData] = useState<FaqProps[]>([]);

  const [search, setSearch] = useState<string>("");
  const [dataBackup, setDataBackup] = useState<FaqProps[]>([]);
  const [itemsData, setItemsData] = useState<FaqProps[]>([]);
  const [categoryMethod, setCategoryMethod] = useState<string[] | []>([]);

  useEffect(() => {
    getFaq();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [isOpen, setIsOpen] = useState<Array<boolean>>([]);

  const setArr = (arrData: any) => {
    let arr: any = [];
    arrData.map((element: any) => {
      return arr.push(true);
    });
    setIsOpen(arr);
  };

  async function getFaq() {
    try {
      const response = await api.get("/faqs");

      setData(response.data.faqs);
      setItemsData(response.data.faqs);
      setDataBackup(response.data.faqs);

      setArr(response.data.faqs);

      const obj: any = {};
      response.data.categories.map((value: any) => {
        return (obj[`${value}`] = value);
      });
      setFilterOptions(obj);
    } catch (error) {
      addToast({
        title: "Ops!",
        description: "Erro ao carregar o FAQ.",
        type: "error",
      });
    } finally {
      setLoading(false);
    }
  }

  function handleSearchText(text: string) {
    setSearch(text);

    const filteredValues = dataBackup.filter((contacts) => {
      let contactsLowerCase = contacts.question.toLowerCase();

      let searchValue = text.toLowerCase();

      return contactsLowerCase.indexOf(searchValue) > -1;
    });
    setItemsData(filteredValues);
  }

  const setCatMethod = async (newMethod: string) => {
    let arrFilter = categoryMethod.filter((element) => element === newMethod);

    if (arrFilter.length === 0) {
      setCategoryMethod([newMethod]);
      let iqualArr = data.filter((element) => {
        return element.category === newMethod;
      });
      setArr(iqualArr);
      setDataBackup(iqualArr);

      return setItemsData(iqualArr);
    } else {
      setCategoryMethod([]);
      setArr(data);
      setDataBackup(data);
      return setItemsData(data);
    }
  };

  function handleSearchCancel() {
    setSearch("");
    setItemsData(dataBackup);
  }

  const DropdownQuestion = (item: any, index: number) => {
    const answer = item.answer.trim().split(".");

    const adjustedAnswer = answer.map((subText: string) => {
      return (
        <div key={subText}>
          {subText} <br />
          <br />
        </div>
      );
    });

    return (
      <>
        <S.SubTitle>{adjustedAnswer}</S.SubTitle>

        {isOpen[index] && (
          <S.DoubtContainer>
            <S.SubTitleDoubt>Ainda tem dúvidas?</S.SubTitleDoubt>
            <S.ContainerButtons>
              <S.WrapperButton>
                <Button
                  children="Sim"
                  typeButton="outline"
                  onClick={() => {
                    history.push("/feedback");
                  }}
                />
              </S.WrapperButton>

              <S.WrapperButton>
                <Button
                  children="Não"
                  //border="square"
                  typeButton="outline"
                  onClick={() => {
                    let arr = isOpen.map((item, idx) => {
                      return idx === index ? false : item;
                    });
                    setIsOpen(arr);
                  }}
                />
              </S.WrapperButton>
            </S.ContainerButtons>
          </S.DoubtContainer>
        )}
      </>
    );
  };

  return (
    <>
      {loading ? (
        <LoadingProfiz isVisible={true} />
      ) : (
        <S.Container>
          <S.Header>
            <div>
              <ArrowButton />
              <h1>Ajuda</h1>
            </div>
          </S.Header>

          <S.Content>
            <S.SearchWrapper>
              <SearchInput
                onChange={(e) => handleSearchText(e.target.value)}
                searchValue={search}
                handleCancel={handleSearchCancel}
                placeholder="Qual é a sua dúvida?"
              />
            </S.SearchWrapper>

            <S.WrapperFilter>
              <ButtonFaqFilter
                data={filterOptions}
                optionsSelected={categoryMethod}
                handlePressButton={setCatMethod}
              />
            </S.WrapperFilter>

            {itemsData ? (
              itemsData.map((item, index) => {
                return (
                  <CardHelpComponent title={item.question} key={item.id}>
                    {DropdownQuestion(item, index)}
                  </CardHelpComponent>
                );
              })
            ) : (
              <S.WrapperMessage>
                <S.TitlePage>Não foi encontrado nenhum dado.</S.TitlePage>
              </S.WrapperMessage>
            )}
          </S.Content>
        </S.Container>
      )}
    </>
  );
}
