import { ChangeEvent, useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import axios, { AxiosError } from "axios";

import { ContentLeft } from "templates/ContentLeft";
import { PhotoCardStep } from "pages/fieldService/ServicesHub/PhotoCardStep";

import { useToast } from "hooks/toast";
import { ArrowButton } from "components/ArrowButton";
import { Button } from "components/Button";
import { AddSignaturePhoto } from "./AddSignaturePhoto";
import { ServiceRegisterPhoto } from "components/ServiceRegisterPhoto";
import { LoadingPageModal } from "components/LoadingPageModal";
import { CarouselImages } from "components/ModalGalleryRegisterImages/CarouselImages";
import { CommentsTextArea } from "components/CommentsTextArea";

import { useServiceOrder } from "hooks/serviceOrder";
import { useAccount } from "hooks/permission/account";
import { ServiceReportProps } from "dtos/ServiceReport";
import { useRegisterPhotosModal } from "hooks/registerPhotosModal";
import { ServiceOrderDTO } from "dtos/ServiceOrderDTO2";
import { ModalExecutionServiceChecklistType } from "../ServicesHub";

import apiv2 from "services/apiv2";

import * as S from "./styles";

type SignatureHashType =
  | "signature"
  | "profile-sign"
  | "provider-sign"
  | "client-sign";

export type Photo = {
  id: number;
  link: string;
};

type SignaturesType = {
  provider: Photo | null;
  client: Photo | null;
};

type RegisterSignaturesProps = {
  onNavigateToStepChecklist?: (
    pageStep: ModalExecutionServiceChecklistType
  ) => void;
};

export function RegisterSignatures({
  onNavigateToStepChecklist,
}: RegisterSignaturesProps) {
  const { 2: serviceOrderIdFromUrl } = window.location.pathname.split("/");
  const history = useHistory();
  const { whoami } = useAccount();
  const { addToast } = useToast();

  const {
    selectedServiceOrder,
    currentService,
    currentServiceChecklist,
    handleSetCurrentServiceChecklist,
    handleSetSelectedServiceOrder,
    handleMarkServiceAsCompleted,
  } = useServiceOrder();

  const { handleToggleRegisterPhotosModal } = useRegisterPhotosModal();

  const [isLoadingPageModal, setIsLoadingPageModal] = useState(true);
  const [isFinishingRegistersSignatures, setIsFinishingRegistersSignatures] =
    useState(false);

  const [isDeletingSignatureById, setIsDeletingSignatureById] = useState<
    number | null
  >(null);
  const [providerSignaturePicture, setProviderSignaturePicture] =
    useState<Photo | null>(null);
  const [clientSignaturePicture, setClientSignaturePicture] =
    useState<Photo | null>(null);

  const [photoModal, setPhotoModal] = useState(false);
  const [signaturePicturePreviews, setSignaturePicturePreviews] = useState<
    Photo[]
  >([]);

  const [serviceReport, setServiceReport] = useState({} as ServiceReportProps);

  const handleTogglePhotoModal = () => {
    setPhotoModal(!photoModal);
  };

  async function getReportFromAPI() {
    try {
      const { data: serviceReport } = await apiv2.get<ServiceReportProps>(
        `budgets/order-service/${serviceOrderIdFromUrl}/budget-service/${currentService.idBudgetService}/report`
      );

      setServiceReport(serviceReport);
    } catch (error) {
      addToast({
        type: "error",
        title: "Erro!",
        description:
          axios.isAxiosError(error) && error.response?.data.error
            ? error.response.data.error
            : "Não foi possível buscar o relatório!",
      });
    }
  }

  useEffect(() => {
    if (selectedServiceOrder.isNewSignVersion) {
      getReportFromAPI();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentService.idBudgetService]);

  useEffect(() => {
    loadInfoOrderService();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loadInfoOrderService = async () => {
    try {
      const { data: serviceOrderDetail } = await apiv2.get<ServiceOrderDTO>(
        `/budgets/service-order/${serviceOrderIdFromUrl}`,
        {
          params: {
            accountId: whoami?.id,
          },
        }
      );

      handleSetSelectedServiceOrder(serviceOrderDetail);

      const { provider: providerSignature, client: clientSignature } =
        gettingSignaturesFromDifferentPlaces(serviceOrderDetail);

      setProviderSignaturePicture(providerSignature);
      setClientSignaturePicture(clientSignature);
    } catch (error) {
      addToast({
        title: "Ops!!",
        description: "Não foi possível carregar as assinaturas",
        type: "error",
      });
    } finally {
      setIsLoadingPageModal(false);
    }
  };

  function gettingSignaturesFromDifferentPlaces(
    serviceOrder: ServiceOrderDTO
  ): SignaturesType {
    if (serviceOrder.isNewSignVersion) {
      const currentServiceResponse = serviceOrder.services.find(
        (service) => service.idBudgetService === currentService.idBudgetService
      );

      if (!currentServiceResponse) {
        return {
          provider: null,
          client: null,
        };
      }

      const findProviderSignatureInService =
        currentServiceResponse.service.serviceSigns?.find(
          (serviceSign) =>
            serviceSign.signType === "provider-sign" ||
            serviceSign.signType === "profile-sign"
        );

      const findClientSignature =
        currentServiceResponse.service.serviceSigns?.find(
          (serviceSign) => serviceSign.signType === "client-sign"
        );

      return {
        provider: findProviderSignatureInService
          ? {
              id: findProviderSignatureInService.id,
              link: findProviderSignatureInService.url,
            }
          : null,
        client: findClientSignature
          ? {
              id: findClientSignature.id,
              link: findClientSignature.url,
            }
          : null,
      };
    }

    if (serviceOrder.signatures.length > 0) {
      const providerSignature = serviceOrder.signatures.find(
        (signature) =>
          signature.type === "provider-sign" ||
          signature.type === "signature" ||
          signature.type === "profile-sign"
      );

      const clientSignature = serviceOrder.signatures.find(
        (signature) => signature.type === "client-sign"
      );

      return {
        provider: providerSignature
          ? { id: providerSignature.id, link: providerSignature.link }
          : null,
        client: clientSignature
          ? { id: clientSignature.id, link: clientSignature.link }
          : null,
      };
    }

    return { provider: null, client: null };
  }

  const handleAddNewPhoto = useCallback(
    ({ id, link, type }: Photo & { type: SignatureHashType }) => {
      const photo = {
        id,
        link,
      };

      if (type === "provider-sign") {
        setProviderSignaturePicture(photo);
      } else {
        setClientSignaturePicture(photo);
      }
    },
    []
  );

  async function handleDeletePhotoInApi(
    signatureId: number,
    type: SignatureHashType
  ) {
    try {
      // OS's antigas não precisam do idBudgetService
      const idBudgetService = selectedServiceOrder.isNewSignVersion
        ? currentService.idBudgetService
        : 0;

      setIsDeletingSignatureById(signatureId);
      await apiv2.delete(
        `upload/service-order/${serviceOrderIdFromUrl}/budget-service/${idBudgetService}/service-sign/${signatureId}`
      );

      if (type === "provider-sign") {
        setProviderSignaturePicture(null);
      } else {
        setClientSignaturePicture(null);
      }

      addToast({
        type: "success",
        title: "OK!",
        description: "Foto removida com sucesso!",
      });
    } catch {
      addToast({
        type: "error",
        title: "Erro!",
        description: "Não foi possível remover a foto!",
      });
    } finally {
      setIsDeletingSignatureById(null);
    }
  }

  function handleToggleModalCarouselImages(
    type: "provider-sign" | "client-sign"
  ) {
    handleTogglePhotoModal();

    if (type === "provider-sign" && providerSignaturePicture?.link) {
      setSignaturePicturePreviews([providerSignaturePicture]);
    } else if (type === "client-sign" && clientSignaturePicture?.link) {
      setSignaturePicturePreviews([clientSignaturePicture]);
    }
  }

  function handleSetProviderDigitalSignature(photo: Photo) {
    setProviderSignaturePicture(photo);
  }

  function handleGoBack() {
    if (selectedServiceOrder.isNewSignVersion) {
      onNavigateToStepChecklist?.("runServiceChecklist");
    } else {
      history.goBack();
    }
  }

  async function handleSaveServiceReport() {
    try {
      const response = await apiv2.post<ServiceReportProps>(
        `budgets/order-service/${serviceOrderIdFromUrl}/budget-service/${currentService.idBudgetService}/report`,
        { report: serviceReport.report }
      );

      setServiceReport(response.data);
      await handleSaveChecklistAndCompleteService();

      addToast({
        type: "success",
        title: "Relatório salvo!",
        description: "Relatório salvo com sucesso!",
      });
    } catch (error: AxiosError<{ error: string }> | any) {
      addToast({
        type: "error",
        title: "Erro!",
        description:
          error?.response?.data.error || "Não foi possível salvar o relatório!",
      });
    }
  }

  async function handleUpdateServiceReport(
    reportId: number,
    serviceReportComments: string
  ) {
    try {
      const response = await apiv2.put<ServiceReportProps>(
        `budgets/order-service/${serviceOrderIdFromUrl}/budget-service/${currentService.idBudgetService}/report/${reportId}`,
        {
          report: serviceReportComments,
        }
      );

      setServiceReport(response.data);
      await handleSaveChecklistAndCompleteService();
    } catch (error: AxiosError<{ error: string }> | any) {
      addToast({
        type: "error",
        title: "Erro!",
        description:
          error?.response?.data.error || "Não foi possível salvar o relatório!",
      });
    }
  }

  function handleSaveOrUpdateServiceReport() {
    if (!serviceReport.id && !serviceReport.report) {
      handleSaveChecklistAndCompleteService();
      return;
    }

    if (serviceReport.id) {
      handleUpdateServiceReport(serviceReport.id, serviceReport.report);
    } else {
      handleSaveServiceReport();
    }
  }

  function handleChangeServiceReport(event: ChangeEvent<HTMLTextAreaElement>) {
    setServiceReport((oldState) => ({
      ...oldState,
      report: event.target.value,
    }));
  }

  async function handleCompleteService() {
    try {
      await apiv2.put(
        `/budgets/service-order/service/${currentService.idBudgetService}`
      );

      handleMarkServiceAsCompleted();
    } catch (err) {
      addToast({
        title: "Ops!!",
        description: "Não foi possível concluir o serviço atual",
        type: "error",
      });
    }
  }

  async function handleSaveChecklistAndCompleteService() {
    try {
      setIsFinishingRegistersSignatures(true);

      await apiv2.put(
        `/budgets/service-order/${selectedServiceOrder.id}/services/${currentService.idBudgetService}/checklists-register`,
        { serviceChecklist: [currentServiceChecklist] }
      );

      handleSetCurrentServiceChecklist(currentServiceChecklist);

      addToast({
        title: "Sucesso",
        description: "Checklist do serviço finalizado com sucesso",
        type: "success",
      });

      if (currentService.status !== "concluded") {
        await handleCompleteService();
      }

      onNavigateToStepChecklist?.("runServiceChecklist");
      handleToggleRegisterPhotosModal();
    } catch (err) {
      addToast({
        title: "Ops!!",
        description: "Não foi possível salvar as alterações do checklist",
        type: "error",
      });
    } finally {
      setIsFinishingRegistersSignatures(false);
    }
  }

  function handleNavigateToFinishServiceOrder() {
    const { 2: serviceOrderIdFromUrl } = window.location.pathname.split("/");
    history.push({
      pathname: `/service-order/concluded/${serviceOrderIdFromUrl}`,
      state: { before: "RegisterSignatures" },
    });
  }

  function handleFinishRegistersChecklist() {
    if (selectedServiceOrder.isNewSignVersion) {
      handleSaveOrUpdateServiceReport();
    } else {
      handleNavigateToFinishServiceOrder();
    }
  }

  return (
    <>
      {isLoadingPageModal ? (
        <LoadingPageModal />
      ) : (
        <>
          <S.Container>
            <CarouselImages
              imageSelected={0}
              images={signaturePicturePreviews}
              isVisible={photoModal}
              onCloseModal={handleTogglePhotoModal}
            />

            <header>
              <ArrowButton handleFunction={handleGoBack} />
              <h1>Checklist do serviço</h1>
            </header>

            <ContentLeft>
              <S.Content>
                <S.Label>Finalização dos registros do serviço.</S.Label>

                <PhotoCardStep
                  title="Sua assinatura"
                  maxPhotos={1}
                  photosQuantity={providerSignaturePicture?.id ? 1 : 0}
                >
                  <AddSignaturePhoto
                    maxPhotos={1}
                    signatureType="provider-sign"
                    photosQuantity={providerSignaturePicture?.id ? 1 : 0}
                    handleAddNewPhoto={(photo) =>
                      handleAddNewPhoto({
                        ...photo,
                        type: "provider-sign",
                      })
                    }
                    onSetProviderDigitalSignature={
                      handleSetProviderDigitalSignature
                    }
                  />
                  <S.Wrapper>
                    {!providerSignaturePicture?.link ? (
                      <S.Description>
                        Tire uma foto da sua assinatura para anexar a ordem de
                        serviço.
                      </S.Description>
                    ) : (
                      <ServiceRegisterPhoto
                        key={providerSignaturePicture.id}
                        url={providerSignaturePicture.link}
                        loading={
                          isDeletingSignatureById ===
                          providerSignaturePicture.id
                        }
                        onClick={() =>
                          handleToggleModalCarouselImages("provider-sign")
                        }
                        onDelete={() =>
                          handleDeletePhotoInApi(
                            providerSignaturePicture.id,
                            "provider-sign"
                          )
                        }
                      />
                    )}
                  </S.Wrapper>
                </PhotoCardStep>

                <PhotoCardStep
                  title="Assinatura do cliente"
                  maxPhotos={1}
                  photosQuantity={clientSignaturePicture?.id ? 1 : 0}
                >
                  <AddSignaturePhoto
                    maxPhotos={1}
                    signatureType="client-sign"
                    photosQuantity={clientSignaturePicture?.id ? 1 : 0}
                    handleAddNewPhoto={(photo) => {
                      handleAddNewPhoto({ ...photo, type: "client-sign" });
                    }}
                  />

                  <S.Wrapper>
                    {!clientSignaturePicture?.link ? (
                      <S.Description>
                        Tire uma foto da assinatura do cliente para anexar a
                        ordem de serviço.
                      </S.Description>
                    ) : (
                      <ServiceRegisterPhoto
                        loading={
                          isDeletingSignatureById === clientSignaturePicture.id
                        }
                        key={clientSignaturePicture.id}
                        url={clientSignaturePicture.link}
                        onDelete={() =>
                          handleDeletePhotoInApi(
                            clientSignaturePicture.id,
                            "client-sign"
                          )
                        }
                        onClick={() =>
                          handleToggleModalCarouselImages("client-sign")
                        }
                      />
                    )}
                  </S.Wrapper>
                </PhotoCardStep>

                {selectedServiceOrder.isNewSignVersion && (
                  <S.WrapperReportDetail>
                    <CommentsTextArea
                      value={serviceReport.report}
                      onChange={handleChangeServiceReport}
                      label="Relatório final de manutenção (opcional)"
                      placeholder="Texto livre."
                      style={{ height: 180 }}
                    />
                  </S.WrapperReportDetail>
                )}

                <S.Footer>
                  <Button
                    loading={isFinishingRegistersSignatures}
                    onClick={handleFinishRegistersChecklist}
                  >
                    Salvar
                  </Button>
                </S.Footer>
              </S.Content>
            </ContentLeft>
          </S.Container>
        </>
      )}
    </>
  );
}
