import { useHistory, useParams } from "react-router";
import * as S from "./styles";
import { useEffect, useState } from "react";
import axios from "axios";
import { useToast } from "hooks/toast";
import { LoadingProfiz } from "components/LoadingProfiz";
import apiv2 from "services/apiv2";
import { ArrowButton } from "components/ArrowButton";
import { useServiceOrder } from "hooks/serviceOrder";
import { ButtonSVG } from "components/ButtonSVG";

import { format } from "date-fns";
import { ModalChecklist } from "components/ModalChecklist";
import { ChecklistItemList } from "pages/fieldService/ServiceChecklist/types";
import { ButtonRegistrationDetails } from "components/ButtonRegistrationDetails";
import { SignatureProps } from "dtos/ServiceOrderDetailDTO";
import {
  ModalGalleryRegisterImages,
  StagePhotoData,
} from "components/ModalGalleryRegisterImages";
import { ModalRight } from "components/ModalRight";

type RouteParams = {
  id: string;
  idPmoc: string;
  year: string;
  month: string;
};

export function ConcludedOSPage() {
  const { id, idPmoc, year, month } = useParams<RouteParams>();
  const { addToast } = useToast();
  const [isLoading, setIsLoading] = useState(false);
  const history = useHistory();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedChecklist, setSelectedChecklist] = useState(
    {} as ChecklistItemList
  );
  const [signaturesInServiceOrder, setSignaturesInServiceOrder] = useState<
    SignatureProps[]
  >([]);
  const [isVisibleModal, setIsVisibleModal] = useState(false);
  const [stagePhotoData, setStagePhotoData] = useState({} as StagePhotoData);

  const { selectedServiceOrder, handleSetSelectedServiceOrder } =
    useServiceOrder();

  async function setData() {
    try {
      setIsLoading(true);
      const response = await apiv2.get(`/budgets/service-order/${id}`);
      handleSetSelectedServiceOrder(response.data);
      response.data.signatures &&
        setSignaturesInServiceOrder(response.data.signatures);
    } catch (error) {
      addToast({
        title: "Erro",
        description:
          axios.isAxiosError(error) && error.response?.data.error
            ? error.response?.data.error
            : "Não foi possível carregar as informações da ordem de serviço.",
        type: "error",
      });
    } finally {
      setIsLoading(false);
    }
  }

  useEffect(() => {
    setData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function formatedScheduledDate(date: string) {
    const dateSchedule = new Date(date);
    return format(dateSchedule, "dd/MM/yyyy HH:mm");
  }

  function handleOpenPdfPage() {
    history.push({
      pathname: "/download/pdf",
      state: {
        pdfDownloadLink: selectedServiceOrder.detailLink,
      },
    });
  }

  function handleToggleOpen() {
    setIsVisibleModal((prev) => !prev);
  }

  function handleOpenGallerySignatures(signatures: SignatureProps[]) {
    handleToggleOpen();
    setStagePhotoData({
      key: "",
      signatures,
    });
  }

  return (
    <>
      {isLoading && <LoadingProfiz isVisible={isLoading} />}
      <S.Container>
        <ModalChecklist
          isOpen={isModalOpen}
          setIsOpen={(value) => setIsModalOpen(value)}
          checklist={selectedChecklist}
        />

        <ModalRight isOpen={isVisibleModal} handleToggleOpen={handleToggleOpen}>
          <ModalGalleryRegisterImages
            stagePhotoData={stagePhotoData}
            handleToggleModal={handleToggleOpen}
          />
        </ModalRight>

        <S.Wrapper>
          <ArrowButton
            handleFunction={() => {
              history.push(
                `/pmoc/execution/order-services-list/${idPmoc}/${year}/${month}?statusList=2`
              );
            }}
          />
        </S.Wrapper>
        <header>
          {selectedServiceOrder.services &&
            selectedServiceOrder.services.map((element) => {
              return (
                <S.Title key={element.idBudgetService}>
                  {element.service.service.name}
                </S.Title>
              );
            })}
        </header>
        <S.Wrapper>
          {selectedServiceOrder.environment &&
            selectedServiceOrder.equipment && (
              <S.Subtitle>
                Esta ordem de serviço está vinculada ao equipamento
                <br />
                <strong>
                  {selectedServiceOrder.equipment.tagEquipment}
                </strong>{" "}
                do ambiente{" "}
                <strong>{selectedServiceOrder.environment.name}</strong>.
              </S.Subtitle>
            )}

          <S.WrapperStatusOS>
            <span>Status: </span>

            <div>
              <S.BadgeStatus />
              <span>Concluído</span>
            </div>
          </S.WrapperStatusOS>
        </S.Wrapper>
        <div className="content">
          {selectedServiceOrder.environment && (
            <S.Wrapper>
              <S.CardInfo>
                <p className="title">{selectedServiceOrder.environment.name}</p>
              </S.CardInfo>
            </S.Wrapper>
          )}

          {selectedServiceOrder.equipment && (
            <S.Wrapper>
              <S.CardInfo>
                <p className="title">
                  {selectedServiceOrder.equipment.tagEquipment}
                </p>
              </S.CardInfo>
            </S.Wrapper>
          )}

          {selectedServiceOrder.services &&
            selectedServiceOrder.services.map((element) => {
              return (
                <S.Wrapper key={element.idBudgetService}>
                  <S.OSCard status={element.status}>
                    <div className="text-container">
                      <p className="title">
                        <strong>{element.service.service.name}</strong>
                      </p>
                      {element.scheduleDate &&
                        element.scheduleDate.length > 0 && (
                          <p className="text-info">
                            <strong>
                              Agendado para:{" "}
                              {formatedScheduledDate(element.scheduleDate)}
                            </strong>
                          </p>
                        )}
                      <p className="text-info">
                        Cliente: {selectedServiceOrder.client.name}{" "}
                      </p>
                      <p className="text-info">
                        Periodicidade:{" "}
                        {!!element.service.frequency &&
                          element.service.frequency[0].toUpperCase() +
                            element.service.frequency.substring(1)}
                      </p>
                    </div>
                  </S.OSCard>
                  {element.service.serviceChecklist && (
                    <S.ChecklistClick
                      onClick={() => {
                        setSelectedChecklist(
                          element.service.serviceChecklist[0]
                        );
                        setIsModalOpen(true);
                      }}
                    >
                      Ver checklist {element?.service?.serviceChecklist[0].name}
                    </S.ChecklistClick>
                  )}
                </S.Wrapper>
              );
            })}

          <S.Wrapper>
            <S.WrapperSignature>
              <ButtonRegistrationDetails
                title="Assinaturas"
                amount={signaturesInServiceOrder.length}
                onViewRegistration={() =>
                  handleOpenGallerySignatures(signaturesInServiceOrder)
                }
              />
            </S.WrapperSignature>
          </S.Wrapper>

          <S.Wrapper>
            <ButtonSVG
              title="Download PDF"
              typeSvg="pdf"
              onClick={handleOpenPdfPage}
            />
          </S.Wrapper>
        </div>
      </S.Container>
    </>
  );
}
