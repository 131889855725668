import styled from "styled-components";
import media from "styled-media-query";

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100vh;

  img {
    width: 165px;
    height: 45px;
    margin-bottom: 50px;
  }
`;

export const Form = styled.div`
  max-width: 470px;
  width: 100%;

  ${media.lessThan("medium")`
    padding: 0 20px;
  `}

  display: flex;
  flex-direction: column;

  > div {
    margin-bottom: 15px;
  }
`;
