import styled, { css } from "styled-components";

interface ContainerProps {
  hasError?: boolean;
}

export const Label = styled.p`
  ${({ theme }) => css`
    font-size: ${theme.font.sizes.xsmall};
    color: ${theme.colors.title};
    text-align: left;
    margin-bottom: 5px;
  `}
`;

export const Container = styled.button<ContainerProps>`
  ${({ theme, hasError }) => css`
    width: 100%;
    height: 45px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    background-color: ${theme.colors.menu_background};
    color: ${theme.colors.text};

    border: 1px solid
      ${hasError ? theme.colors.error : theme.colors.menu_background};
    border-radius: 10px;
  `}
`;

export const ErrorText = styled.span`
  ${({ theme }) => css`
    font-size: 12px;
    color: ${theme.colors.error};
    padding-top: 5px;
    display: flex;
  `}
`;
