import styled, { css } from "styled-components";

export const Header = styled.div`
  margin-left: 40px;
  margin-top: 25px;
`;

export const Container = styled.div`
  margin-left: 40px;
  margin-top: 25px;
  width: 50%;
  position: relative;
`;

export const Title = styled.p`
  ${({ theme }) => css`
    font-size: ${theme.font.sizes.large};
    color: ${theme.colors.financial_title};
    font-family: ${theme.font.family};
    font-weight: ${theme.font.bold};
    margin-top: 10px;
  `}
`;

export const TabContainer = styled.div`
  ${({ theme }) => css`
    margin-top: 80px;

    .react-tabs__tab-list {
      border-bottom: 1px solid rgba(255, 255, 255, 0.12);
      display: flex;
      justify-content: space-between;
    }

    .react-tabs__tab {
      margin-right: 30px;
      color: ${theme.colors.text};
    }

    .react-tabs__tab--selected {
      background: inherit;
      border: 0px;
      color: ${theme.colors.text};

      font-weight: 500;
      line-height: 1.35;
      white-space: normal;
      letter-spacing: 0.02857em;
      border-bottom: 3px solid ${theme.colors.primary_light};
    }
  `}
`;
