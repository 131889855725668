import styled, { css } from "styled-components";
import { opacify } from "polished";

type CheckboxProps = {
  isChecked: boolean;
};

type ColorProps = {
  color: "default" | "primary";
  isChecked: boolean;
};

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Button = styled.button<ColorProps>`
  ${({ theme, color, isChecked = false }) => css`
    border-radius: 10px;
    //min-height: 90px;

    border: ${isChecked ? `1px solid ${theme.colors.primary}` : ``};

    background: ${color === "primary"
      ? theme.colors.primary_medium
      : theme.colors.background_light};
    display: flex;
    align-items: center;
    transition: background 0.2s;
    flex-direction: row;
    &:hover {
      background: ${opacify(0.03, theme.colors.background_light)};
    }
  `}
`;

export const CardContainer = styled.div<ColorProps>`
  ${({ theme, color, isChecked = false }) => css`
    border-radius: 10px;
    //min-height: 90px;

    border: ${isChecked ? `1px solid ${theme.colors.primary}` : ``};

    background: ${color === "primary"
      ? theme.colors.primary_medium
      : theme.colors.background_light};
    display: flex;
    align-items: center;
    //transition: background 0.2s;
    flex-direction: row;
    // &:hover {
    //   background: ${opacify(0.03, theme.colors.background_light)};
    // }
  `}
`;

export const CheckBox = styled.div`
  ${({ theme }) => css`
    padding: 3px;
    margin-right: 15px;

    display: flex;
    align-items: center;
    justify-content: center;

    border-radius: 3px;
    border: 1px solid ${theme.colors.text};
  `}
`;

// export const WrapperCheck = styled.div`
//   display: flex;
//   padding-left: 10px;
//   height: 60px;
//   width: 60px;
//   justify-content: center;
//   align-items: center;
// `;

export const WrapperCheck = styled.button`
  ${({ theme }) => css`
    display: flex;
    padding-left: 10px;
    height: 100px;
    width: 60px;
    justify-content: center;
    align-items: center;
    transition: background 0.2s;
    &:hover {
      background: ${opacify(0.03, theme.colors.background_light)};
    }
    &:disabled {
      cursor: not-allowed;
      background: transparent;
    }
  `};
`;

export const MarkChecked = styled.div<CheckboxProps>`
  ${({ theme, isChecked }) => css`
    width: 10px;
    height: 10px;
    border-radius: 1.2px;

    background-color: ${isChecked
      ? theme.colors.primary
      : theme.colors.background_light};
  `};
`;
