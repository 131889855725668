import React, { useState } from "react";

import { ModalRight } from "components/ModalRight";
import { EditButton } from "components/EditButton";
import { DeleteButton } from "components/DeleteButton";
import { ButtonModalAction } from "components/ButtonModalAction";
import { ModalPaymentConditions } from "components/ModalPaymentConditions";
import { useSelectedPaymentConditions } from "hooks/budget/selectedPaymentConditions";

import { useToast } from "hooks/toast";
import { useTotalBudget } from "hooks/budget/totalBudget";

import * as S from "./styles";

type ServiceAreaProps = {
  hasError?: string;
};

export function PaymentConditionsArea({ hasError }: ServiceAreaProps) {
  const {
    selectedPromptPayment,
    selectedInstallmentPayment,
    selectedIncomingPayment,
    handleDeleteSelectedPaymentConditions,
  } = useSelectedPaymentConditions();

  const { totalBudget } = useTotalBudget();
  const { addToast } = useToast();

  const [isOpen, setIsOpen] = useState(false);

  function handleToggleOpen() {
    if (totalBudget <= 0) {
      addToast({
        type: "info",
        title: "Espere!",
        description:
          "Adicione itens neste orçamento antes de definir as condições de pagamento",
      });
      return;
    }

    setIsOpen(!isOpen);
  }

  return (
    <S.Container>
      <ModalRight
        isOpen={isOpen}
        // eslint-disable-next-line react/jsx-no-bind
        handleToggleOpen={handleToggleOpen}
      >
        <ModalPaymentConditions
          // eslint-disable-next-line react/jsx-no-bind
          handleToggleOpen={handleToggleOpen}
        />
      </ModalRight>

      {totalBudget <= 0 ? (
        <>
          <S.Label>Condições de pagamento*</S.Label>

          <S.CardPaymentConditions>
            <S.WrapperContent>
              <S.WrapperPaymentText>
                <S.PaymentText>Gratuito</S.PaymentText>
              </S.WrapperPaymentText>
            </S.WrapperContent>

            <S.WrapperActions>
              <EditButton disabled />
              <DeleteButton disabled />
            </S.WrapperActions>
          </S.CardPaymentConditions>
        </>
      ) : selectedPromptPayment.length === 0 &&
        selectedInstallmentPayment.length === 0 &&
        selectedIncomingPayment.length === 0 ? (
        <ButtonModalAction
          label="Condições de pagamento*"
          placeholder="Selecione as condições de pagamento"
          isSelected={isOpen}
          // eslint-disable-next-line react/jsx-no-bind
          onClick={handleToggleOpen}
          hasError={hasError}
        />
      ) : (
        <>
          <S.Label>Condições de pagamento*</S.Label>

          <S.CardPaymentConditions>
            <S.WrapperContent>
              {selectedPromptPayment.map((item) => (
                <S.WrapperPaymentText key={item.index}>
                  <S.PaymentText>{item.description}</S.PaymentText>

                  <S.PaymentText>{item.formattedTotal}</S.PaymentText>
                </S.WrapperPaymentText>
              ))}

              {selectedInstallmentPayment.map((installment) => (
                <S.WrapperInstallment key={installment.typeInstallment}>
                  <S.WrapperPaymentText>
                    <S.PaymentText>{installment.description}</S.PaymentText>

                    <S.PaymentText>{installment.formattedTotal}</S.PaymentText>
                  </S.WrapperPaymentText>

                  <S.WrapperPaymentText>
                    <S.PaymentText>{installment.subdescription}</S.PaymentText>

                    {!!installment.installmentDescription && (
                      <S.PaymentText>
                        {installment.installmentDescription}
                      </S.PaymentText>
                    )}
                  </S.WrapperPaymentText>
                </S.WrapperInstallment>
              ))}

              {selectedIncomingPayment.map((incoming) => (
                <S.WrapperPaymentText key={incoming.index}>
                  <S.PaymentText>{incoming.description}</S.PaymentText>

                  <S.PaymentText>{incoming.formattedTotal}</S.PaymentText>
                </S.WrapperPaymentText>
              ))}
            </S.WrapperContent>

            <S.WrapperActions>
              <EditButton onClick={() => handleToggleOpen()} />
              <DeleteButton
                onClick={() => handleDeleteSelectedPaymentConditions()}
              />
            </S.WrapperActions>
          </S.CardPaymentConditions>
        </>
      )}
    </S.Container>
  );
}
