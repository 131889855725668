import React, { ButtonHTMLAttributes } from "react";
import { useHistory } from "react-router";

import { useMenu } from "hooks/menu";
import { useTopMenu } from "hooks/topMenu";
import { useWarningMessage } from "hooks/warningMessage";
import { useAuth } from "hooks/auth";

import BlockGraySvg from "assets/icons/permission/block-gray.svg";

import * as S from "./styles";

type MenuButtonProps = {
  id: string;
  icon: string;
  title: string;
  titleColor?: string;
  textDetail?: string;
  link?: string;
  typeButton?: "primary" | "secondary";
  colorSelected?: string;
  onClick?: () => void;
  isMenuOption?: boolean;
  hasPermission?: boolean;
  isNewModule?: boolean;
  textTag?: "Beta" | "Novo";
  tagColor?: string;
  tagBgColor?: string;
} & ButtonHTMLAttributes<HTMLButtonElement>;

export function MenuButton({
  id,
  icon,
  title,
  titleColor,
  textDetail,
  link,
  typeButton = "primary",
  colorSelected = "rgba(211, 255, 154, 0.2)",
  onClick,
  isMenuOption = false,
  hasPermission = true,
  isNewModule,
  textTag,
  tagColor,
  tagBgColor,
}: MenuButtonProps) {
  const history = useHistory();
  const {
    isOpenMenu,
    selectedMenu,
    selectedOption,
    handleSetSelectedMenu,
    handleSetSelectedOption,
  } = useMenu();

  const { user } = useAuth();

  const { modifyBudget, handleSetBudgetMessage, handleSetLinkPage } =
    useWarningMessage();

  const { handleSetMenu } = useTopMenu();

  function handleClick() {
    if (modifyBudget) {
      //tratamento para caso clique no botao da loja
      if (id === "shop") {
        handleSetLinkPage("shopLink");
        return handleSetBudgetMessage(true);
      }

      if (link) {
        handleSetLinkPage(link);
      }
      return handleSetBudgetMessage(true);
    }

    if (onClick) {
      onClick();
    } else {
      if (!link) return;
      if (isMenuOption) handleSetSelectedMenu(id);
      handleSetSelectedOption(id);
      handleSetMenu(true);
      history.push(link);
    }
  }

  return (
    <S.Button
      typeButton={typeButton}
      isSelected={isMenuOption ? selectedMenu === id : selectedOption === id}
      colorSelected={colorSelected}
      onClick={handleClick}
    >
      <S.Wrapper isOpenMenu={isOpenMenu}>
        <img src={icon} alt="ícone do botão" />
        {isOpenMenu && <S.Label titleColor={titleColor}>{title}</S.Label>}
      </S.Wrapper>

      {isOpenMenu && !!textDetail && <S.TextDetail>{textDetail}</S.TextDetail>}

      {!hasPermission && isOpenMenu && (
        <S.IconWrapper>
          <img src={BlockGraySvg} alt="Ícone de cadeado" />
        </S.IconWrapper>
      )}

      {user?.profileDisapproved && id !== "home" && id !== "app-version" && (
        <S.IconWrapper>
          <img src={BlockGraySvg} alt="Ícone de cadeado" />
        </S.IconWrapper>
      )}

      {isNewModule &&
        isOpenMenu &&
        hasPermission &&
        !user?.profileDisapproved && (
          <S.DivTag color={tagColor} bgColor={tagBgColor}>
            <span>{textTag}</span>
          </S.DivTag>
        )}
    </S.Button>
  );
}
