import { Page } from "templates/Page";
import { ListItemCard } from "components/Permission";

import { useAccount } from "hooks/permission/account";

import { useHistory } from "react-router";

import * as S from "./styles";

export function Teams() {
  const { whoami } = useAccount();
  const history = useHistory();

  function handleGoBack() {
    history.push("/home");
  }

  return (
    <Page
      title="Equipes"
      subtitle="Entre para ver os membros da sua equipe, cargos e permissões."
      handleGoBack={handleGoBack}
    >
      <S.Description>Minha equipe</S.Description>

      <ListItemCard
        image={whoami?.companyLogo || ""}
        roundedImage
        title={whoami?.companyName || ""}
        boldTitle
        onClick={() => history.push("/teams/management")}
      />
    </Page>
  );
}
