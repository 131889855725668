import { ServiceDTO } from "dtos/ServiceDTO";

export function mappedServicesForApi(selectedServices: ServiceDTO[]) {
  return selectedServices.map((service) => ({
    service: {
      id: service.id,
      categoryId: service.categoryId,
      service: service.service,
      equipment: service.equipment,
      equipmentType: service.equipmentType,
      capacity: service.capacity,
      price: service.price,
      brand: service.brand,
      runtime: {
        time: service.runtime?.time,
        extension: service.runtime?.extension,
      },
      warranty: {
        time: service.warranty?.time,
        extension: service.warranty?.extension,
      },
      description: service.description,
      serviceChecklist: service.serviceChecklist,
    },
    quantity: service.quantity,
  }));
}
