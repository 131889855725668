import styled, { css } from "styled-components";

type TagTypeProps = {
  color?: string;
  bgColor?: string;
};

export const Card = styled.button`
  ${({ theme }) => css`
    width: 100%;
    height: 80px;
    position: relative;
    background-color: ${theme.colors.background_modal};
    padding: 10px 20px;
    border: 0px;
    border-radius: 16px;

    display: flex;
    align-items: center;

    transition-duration: 0.2s, 0.2s, 0.35s;
    transition-property: top, bottom, width;
    transition-timing-function: linear, linear, ease;

    &:hover {
      background-color: ${theme.colors.background_light};
    }

    img {
      height: 35px;
      width: 35px;
    }
  `}
`;

export const IconWrapper = styled.div`
  position: absolute;
  right: 20px;

  img {
    width: 15px;
    height: 18px;
  }
`;

export const Content = styled.div`
  ${({ theme }) => css`
    margin-left: 15px;

    div {
      display: flex;
      align-items: center;

      h5 {
        font-size: ${theme.font.sizes.small};
        font-weight: bold;
        color: ${theme.colors.title};
        text-align: left;
      }
    }

    p {
      margin-top: 5px;
      color: ${theme.colors.text};
      font-size: ${theme.font.sizes.xsmall};
    }
  `}
`;
export const DivTag = styled.div<TagTypeProps>`
  ${({ theme, color, bgColor }) => css`
    display: flex;

    padding: 0px 5px;
    background-color: ${bgColor ? bgColor : theme.colors.primary_opacity};
    border: solid 1px ${color ? color : theme.colors.primary};
    border-radius: 12px;
    margin-left: 5px;

    span {
      font-size: 10px;
      font-family: ${theme.font.family};
      color: ${color ? color : theme.colors.primary};
    }
  `}
`;
