import { useState, useEffect } from "react";

import { useHistory, useParams } from "react-router";

import { useToast } from "hooks/toast";

import { useAccount } from "hooks/permission/account";
import { api } from "services/api";

import { ButtonSVG } from "components/ButtonSVG";
import { ArrowButton } from "components/ArrowButton";
import { LoadingProfiz } from "components/LoadingProfiz";

import * as S from "./styles";
import { CarouselImages } from "components/ModalGalleryRegisterImages/CarouselImages";

type ReceiptResponse = {
  id: number;
  receiptNumber: number;
  idProfizAppClient: number;
  clientName: string;
  description: string;
  amountPaid: number;
  assignedValue: string;
  paymentMethod: string;
  additionalInformation: string;
  observation: string;
  detailLink: string;
  receiptAt: string;
  createdAt: string;
  previewPDF: { encode: string };
  subscriptionProvider: {
    fileId: number;
    link: string;
  };
  subscriptionCustomer: {
    fileId: number;
    link: string;
  };
};

type RouteParams = {
  idReceipt: string;
};

function IssuePreviewReceipt() {
  const history = useHistory();
  const { whoami } = useAccount();
  const { addToast } = useToast();
  const { idReceipt } = useParams<RouteParams>();

  const [detailLink, setDetailLink] = useState("");
  const [preview, setPreview] = useState("");
  const [receiptNumber, setReceiptNumber] = useState(0);
  const [isLoadingPage, setIsLoadingPage] = useState(false);

  const [modalPreview, setModalPreview] = useState(false);

  useEffect(() => {
    if (!whoami?.isMe) history.push("/home");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [whoami]);

  const base64ToUrl = (base64String: string) => {
    const byteCharacters = atob(base64String);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);

    const blob = new Blob([byteArray], { type: "image/jpeg" });

    const url = URL.createObjectURL(blob);

    return url;
  };

  const fetchReceipts = async () => {
    try {
      setIsLoadingPage(true);
      const { data } = await api.get<ReceiptResponse>(`receipt/${idReceipt}`);

      if (data?.previewPDF.encode)
        setPreview(base64ToUrl(data.previewPDF.encode));
      if (data?.receiptNumber) setReceiptNumber(data?.receiptNumber);
      if (data?.detailLink) setDetailLink(data?.detailLink);
    } catch (error) {
      addToast({
        title: "Erro!",
        description: "Não foi possível carregar as informações do recibo.",
        type: "error",
      });
    } finally {
      setIsLoadingPage(false);
    }
  };

  useEffect(() => {
    fetchReceipts();
  }, [idReceipt]); // eslint-disable-line

  function handleOpenPdfPage() {
    history.push({
      pathname: "/download/pdf",
      state: {
        pdfDownloadLink: detailLink,
      },
    });
  }

  if (isLoadingPage) {
    return <LoadingProfiz isVisible={isLoadingPage} />;
  }

  return (
    <S.Container>
      <CarouselImages
        images={[
          {
            id: 1,
            link: preview,
          },
        ]}
        stageService={"Recibo"}
        imageSelected={0}
        isVisible={modalPreview}
        onCloseModal={() => {
          setModalPreview(false);
        }}
      />
      <S.Header>
        <ArrowButton />
        <S.Title>Recibo {receiptNumber}</S.Title>
      </S.Header>

      <S.Content>
        <div
          onClick={() => {
            setModalPreview(true);
          }}
          style={{
            minHeight: "60vh",
            padding: "10px 0",
            cursor: "pointer",
          }}
        >
          <img
            style={{
              objectFit: "contain",
              width: "100%",
              height: "100%",
            }}
            src={preview}
            alt="preview do pdf"
          />
        </div>
        <ButtonSVG
          title="Download PDF"
          typeSvg="pdf"
          onClick={handleOpenPdfPage}
        />
      </S.Content>
    </S.Container>
  );
}

export { IssuePreviewReceipt };
