import { FormEvent, useEffect, useState } from "react";
import { useTheme } from "styled-components";
import { FiChevronLeft } from "react-icons/fi";
import * as Yup from "yup";

import { FormNewAddress } from "../FormNewAddress";

import { Input } from "components/Input";
import { Button } from "components/Button";
import { UnityForm } from "components/UnityForm";
import { ModalRight } from "components/ModalRight";
import { ButtonCheckbox } from "components/ButtonCheckbox";

import { useToast } from "hooks/toast";
import { useClient } from "hooks/budget/client";
import { useEditClient } from "hooks/editClient";
import getValidationErrors from "utils/getValidationErrors";

import { api } from "services/api";

import * as S from "./styles";
import { UnityCard } from "components/UnityCard";
import { ClientProps, UnityDTO } from "dtos/ClientDTO";
import { isEqual } from "lodash";
import { useConfirmModalClient } from "hooks/budget/confirmModalClient";
import { AlertModalCheckbox } from "components/AlertModalCheckbox";
import {
  Label,
  SelectCurrentAccountForCreateNewItem,
  ShowWhenHavePermission,
} from "components/Permission";
import { useAccount } from "hooks/permission/account";

type DocumentProps = {
  document: "cpf" | "cnpj";
  isChecked: boolean;
};

type Errors = {
  [key: string]: string;
};

type CreateClientProps = {
  handleToggleOpen: () => void;
  module?: "budget" | "serviceOrder" | "PMOC" | "client";
  handleToggleModal?: () => void;
};

export function FormNewClient({
  handleToggleOpen,
  module,
  handleToggleModal,
}: CreateClientProps) {
  const theme = useTheme();
  const { addToast } = useToast();
  const { whoami } = useAccount();

  const {
    editedClient,
    handleSetEditClient,
    unities,
    clearEditClientData,
    setSelectedUnity,
    redefineClientUnity,
  } = useEditClient();

  const { handleChangeModalProps, setConfirmModalProps, setHasChangeds } =
    useConfirmModalClient();
  const { address, handleSetClient, hasClient } = useClient();

  const [openModal, setOpenModal] = useState(false);
  const [showAddressForm, setShowAddressForm] = useState(false);
  const [showModalNewUnity, setShowModalNewUnity] = useState(false);

  const [clientIsCreated, setClientIsCreated] = useState(false);

  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");

  const [document_cpf, setDocumentCpf] = useState("");
  const [document_cnpj, setDocumentCnpj] = useState("");
  const [typeCheckbox, setTypeCheckbox] = useState<DocumentProps[]>([
    {
      document: "cpf",
      isChecked: true,
    },
    {
      document: "cnpj",
      isChecked: false,
    },
  ]);

  const [loadingButton, setLoadingButton] = useState(false);

  const [hasError, setHasError] = useState<Errors>({} as Errors);

  const [cpfSelected, cnpjSelected] = typeCheckbox.map(
    ({ isChecked }) => isChecked
  );

  function handleToggleDocument(type: "cpf" | "cnpj") {
    setDocumentCnpj("");
    setDocumentCpf("");

    const documentType = typeCheckbox.map((item) => {
      if (item.document === type) {
        return {
          ...item,
          isChecked: true,
        };
      }

      if (item.document !== type) {
        return {
          ...item,
          isChecked: false,
        };
      }

      return item;
    });

    setTypeCheckbox(documentType);
  }

  const clientData = {
    name: name.trim(),
    phone,
    email,
    accountId: whoami?.id,
    cpf_cnpj: cpfSelected ? document_cpf : document_cnpj,
    address: address?.name
      ? {
          ...address,
          responsible: name,
          email,
          phone,
        }
      : undefined,
  };

  const validationInfo = async () => {
    try {
      const regexPhone =
        /\(?\b([0-9]{2,3}|0((x|[0-9]){2,3}[0-9]{2}))\)?\s*[0-9]{4,5}[- ]*[0-9]{4}\b/;
      const documentRegex =
        /(^\d{3}\.\d{3}\.\d{3}-\d{2}$)|(^\d{2}\.\d{3}\.\d{3}\/\d{4}-\d{2}$)/;

      const schema = () =>
        Yup.object().shape({
          name: Yup.string().required("Nome é obrigatório"),
          email: Yup.string().email("Digite um e-mail válido"),
          phone: Yup.string()
            .matches(new RegExp(regexPhone), "Digite um telefone válido")
            .nullable(),
          document_cpf: Yup.string()
            .matches(new RegExp(documentRegex), "Digite um CPF válido")
            .nullable(),
          document_cnpj: Yup.string()
            .matches(new RegExp(documentRegex), "Digite um CNPJ válido")
            .nullable(),
        });

      await schema().validate(
        {
          name,
          email,
          phone: phone.length > 0 ? phone : null,
          document_cpf: document_cpf.length > 0 ? document_cpf : null,
          document_cnpj: document_cnpj.length > 0 ? document_cnpj : null,
        },
        { abortEarly: false }
      );

      setHasError({});

      return true;
    } catch (error) {
      if (error instanceof Yup.ValidationError) {
        const errors = getValidationErrors(error);
        setHasError(errors);

        addToast({
          title: "Ops...",
          description: "Preencha todos os campos obrigatórios",
          type: "error",
        });

        return false;
      }
    }
  };

  async function handleSubmitDataClient() {
    setLoadingButton(true);
    try {
      if (clientIsCreated) {
        const { data } = await api.put(
          `/clients/${editedClient.id}`,
          clientData
        );

        handleSetClient(data);
      } else {
        const { data } = await api.post("clients", clientData);

        handleSetClient(data);
      }

      setClientIsCreated(false);

      if (module !== "client") {
        handleToggleModal?.();
      } else {
        handleToggleOpen();
      }

      addToast({
        title: "Certo!",
        description: "Cliente cadastrado com sucesso",
        type: "success",
      });
    } catch (error) {
      if (error instanceof Yup.ValidationError) {
        const errors = getValidationErrors(error);
        setHasError(errors);

        addToast({
          title: "Ops...",
          description: "Preencha todos os campos obrigatórios",
          type: "error",
        });

        return;
      }

      handleToggleOpen && handleToggleOpen();

      addToast({
        title: "Ops...",
        description: "Ocorreu um erro ao cadastrar o cliente",
        type: "error",
      });
    } finally {
      setLoadingButton(false);
    }
  }

  async function handleAddAdditionalUnity() {
    if (!clientIsCreated) {
      const objectClient = {
        name,
        phone,
        email,
        accountId: whoami?.id,
        cpf_cnpj: typeCheckbox[0].isChecked ? document_cpf : document_cnpj,
        address: address?.name && {
          ...address,
          responsible: name,
          email,
          phone,
        },
      };

      const { data } = await api.post("clients", objectClient);
      handleSetEditClient(data);

      setClientIsCreated(true);
    }
  }

  function handleEditUnity(unity: UnityDTO) {
    setSelectedUnity(unity);
    setShowModalNewUnity(true);
  }

  async function handleAddMainAddress() {
    setLoadingButton(true);
    try {
      const notHasValidations = await validationInfo();

      if (notHasValidations) {
        setShowAddressForm(true);
      }
    } catch (error) {
      if (error instanceof Yup.ValidationError) {
        const errors = getValidationErrors(error);
        setHasError(errors);

        addToast({
          title: "Ops...",
          description: "Preencha todos os campos obrigatórios",
          type: "error",
        });

        return;
      }

      addToast({
        title: "Ops...",
        description: "Ocorreu um erro ao cadastrar o cliente",
        type: "error",
      });
    } finally {
      setLoadingButton(false);
    }
  }

  useEffect(() => {
    clearEditClientData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function handleConfirm(): void {
    setConfirmModalProps(false);
  }

  function handleCloseModal(): void {
    setConfirmModalProps(false);
    handleToggleOpen();
  }
  const alterationValidationObj = {
    name: "",
    phone: "",
    email: "",
    cpf_cnpj: "",
    address: undefined,
    typeCheckbox: [
      { document: "cpf", isChecked: true },
      { document: "cnpj", isChecked: false },
    ],
  };

  const hasCreateClient = isEqual(
    { ...clientData, typeCheckbox },
    alterationValidationObj
  );

  useEffect(() => {
    setHasChangeds(hasCreateClient);
  }, [setHasChangeds, hasCreateClient]);

  function handleBackButton() {
    if (!hasCreateClient) {
      handleChangeModalProps({
        value: true,
        handleConfirm,
        handleCloseModal: () => {
          handleCloseModal();
        },
      });
      return;
    }
    handleToggleOpen();
  }

  const moduleValidation = module === "budget" || module === "serviceOrder";

  async function checkingIfTheClientExists() {
    const notHasValidations = await validationInfo();

    if (hasClient && moduleValidation && notHasValidations) {
      setOpenModal(true);
      return;
    }

    if (notHasValidations) {
      await handleSubmitDataClient();
    }

    return;
  }

  function saveClientInBudget() {
    handleSetClient(clientData as ClientProps);
    handleToggleModal && handleToggleModal();

    addToast({
      title: "Certo!",
      description: `Cliente salvo ${
        module === "serviceOrder" ? "na Ordem de serviço" : "no Orçamento"
      }`,
      type: "success",
    });
  }

  async function handleTypeSave(type: string) {
    if (type === "first") {
      saveClientInBudget();
    } else if (type === "second") {
      await handleSubmitDataClient();
    }
  }

  const handleSubmitForm = (event: FormEvent<HTMLFormElement>): void => {
    event.preventDefault();
  };

  return (
    <>
      <AlertModalCheckbox
        isVisible={openModal}
        labelConfirm={`Somente  ${
          module === "serviceOrder"
            ? "nesta Ordem de serviço"
            : "neste Orçamento"
        }`}
        labelCancel={"Cadastro geral deste Cliente"}
        description={
          "Você quer que a alteração dos dados do Cliente seja aplicada:"
        }
        action="choose"
        handleConfirm={(type) => handleTypeSave(type)}
        onCloseModal={() => setOpenModal(false)}
      />
      <S.Top>
        <S.BackButton onClick={handleBackButton}>
          <FiChevronLeft size={16} color={theme.colors.text} />
        </S.BackButton>

        <p>Criar novo cliente</p>
      </S.Top>

      <S.Content id="form_cadastro_clientes" onSubmit={handleSubmitForm}>
        <S.WrapperInput>
          <Label>Conta</Label>
          <SelectCurrentAccountForCreateNewItem
            moduleHash="client"
            actionHash="create"
          />
        </S.WrapperInput>

        <S.WrapperInput>
          <Input
            name="Cliente*"
            placeholder="Nome do cliente"
            value={name}
            onChange={(e) => setName(e.target.value.trimStart())}
            hasError={hasError.name}
            onFocusClearError={() => setHasError({ ...hasError, name: "" })}
          />
        </S.WrapperInput>

        <S.WrapperInput>
          <Input
            name="E-mail"
            placeholder="E-mail do cliente"
            value={email}
            onChange={(e) => setEmail(e.target.value.trimStart())}
            hasError={hasError.email}
            onFocusClearError={() => setHasError({ ...hasError, email: "" })}
          />
        </S.WrapperInput>

        <S.WrapperInput>
          <Input
            name="Telefone"
            placeholder="Ex: (11) 99999-9999"
            maskType="phone"
            value={phone}
            hasError={hasError.phone}
            onChange={(e) => setPhone(e.target.value)}
          />
        </S.WrapperInput>

        <S.WrapperCheckbox>
          <ButtonCheckbox
            label="Selecione uma opção:"
            placeholder="Pessoa física"
            isChecked={cpfSelected}
            onClick={() => handleToggleDocument("cpf")}
            type="button"
          />
          <ButtonCheckbox
            placeholder="Pessoa jurídica"
            isChecked={cnpjSelected}
            onClick={() => handleToggleDocument("cnpj")}
            type="button"
          />
        </S.WrapperCheckbox>

        {cpfSelected && (
          <S.WrapperInput>
            <Input
              name="CPF"
              placeholder="999.999.999-99"
              maskType="cpf"
              hasError={hasError.document_cpf}
              value={document_cpf}
              onChange={(e) => setDocumentCpf(e.target.value)}
            />
          </S.WrapperInput>
        )}

        {cnpjSelected && (
          <S.WrapperInput>
            <Input
              name="CNPJ"
              placeholder="99.999.999/9999-99"
              maskType="cnpj"
              hasError={hasError.document_cnpj}
              value={document_cnpj}
              onChange={(e) => setDocumentCnpj(e.target.value)}
            />
          </S.WrapperInput>
        )}

        {!!address?.name && (
          <UnityCard
            unity={address}
            handleEditUnity={() => setShowAddressForm(true)}
          />
        )}

        {!!unities &&
          unities.map((unity, index) => {
            return (
              <UnityCard
                key={index}
                unity={unity}
                handleEditUnity={handleEditUnity}
              />
            );
          })}

        <S.WrapperButton>
          {module !== "budget" && (
            <ShowWhenHavePermission
              moduleHash="client-unity"
              actionHash="create"
            >
              {address?.name ? (
                <Button
                  loading={loadingButton}
                  typeButton={"outline"}
                  onClick={() => {
                    handleAddAdditionalUnity();
                    redefineClientUnity();
                    setShowModalNewUnity(true);
                  }}
                >
                  Cadastrar endereço adicional
                </Button>
              ) : (
                <Button
                  loading={loadingButton}
                  typeButton={"outline"}
                  onClick={() => handleAddMainAddress()}
                >
                  Cadastrar endereço
                </Button>
              )}
            </ShowWhenHavePermission>
          )}

          <Button loading={loadingButton} onClick={checkingIfTheClientExists}>
            Salvar cliente
          </Button>
        </S.WrapperButton>

        <ModalRight
          isOpen={showAddressForm}
          handleToggleOpen={() => setShowAddressForm(false)}
        >
          <FormNewAddress handleToggleOpen={() => setShowAddressForm(false)} />
        </ModalRight>

        <ModalRight
          isOpen={showModalNewUnity}
          handleToggleOpen={() => setShowModalNewUnity(false)}
        >
          <UnityForm
            isNewClient
            handleToggleOpen={() => setShowModalNewUnity(false)}
          />
        </ModalRight>
      </S.Content>
    </>
  );
}
