import styled, { css } from "styled-components";
import media from "styled-media-query";

export const Container = styled.div`
  ${({ theme }) => css`
    width: 100%;
    max-width: 470px;
    margin-left: 20px;
    padding: 30px 0;

    header {
      width: 100%;

      display: flex;
      align-items: center;
      justify-content: flex-start;

      ${media.lessThan("large")`
        flex-direction: column;
        align-items: flex-start;

        & > div:last-of-type {
          margin-top: 20px;
          margin-left: 0;
        }
      `}

      h1 {
        font-size: ${theme.font.sizes.large};
        color: ${theme.colors.title};
        font-weight: ${theme.font.bold};
        margin-left: 30px;
      }

      & > div {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        gap: 25px;
      }

      & > div:last-of-type {
        width: 100%;
      }
    }
  `}
`;

export const Content = styled.div`
  width: 46%;
  min-height: 80vh;
  min-width: 470px;
  margin-top: 50px;

  & > div:first-of-type {
    margin: 20px 0 30px;
  }
`;

export const WrapperService = styled.div`
  margin-bottom: 20px;
`;

export const TabContainer = styled.div`
  ${({ theme }) => css`
    margin-top: 50px;

    .react-tabs__tab-list {
      border-bottom: 1px solid rgba(255, 255, 255, 0.12);
      display: flex;
      justify-content: space-around;
    }

    .react-tabs__tab {
      margin-right: 30px;
      color: ${theme.colors.text};
    }

    .react-tabs__tab--selected {
      background: inherit;
      border: 0px;
      color: ${theme.colors.text};

      font-weight: 500;
      line-height: 1.35;
      white-space: normal;
      letter-spacing: 0.02857em;
      border-bottom: 3px solid ${theme.colors.primary_light};
    }
  `}
`;
