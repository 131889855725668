import React, { useState, InputHTMLAttributes, CSSProperties } from "react";
import { FiAlertCircle } from "react-icons/fi";
import { IconBaseProps } from "react-icons";
import { useTheme } from "styled-components";
import NumberFormat, { NumberFormatValues } from "react-number-format";

import { MaskProps, switchMaskInput } from "utils/switchMaskInput";

import * as S from "./styles";

import AcceptIcon from 'assets/icons/financial-icons/accept-not-background.svg';

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  name?: string;
  labelIcon?: string;
  icon?: React.ComponentType<IconBaseProps> | string;
  onClickIcon?: () => void;
  customRef?: React.RefObject<HTMLInputElement>;
  maskType?: MaskProps;
  fontSize?: number;
  hasError?: string;
  readOnly?: boolean;
  onFocusClearError?: () => void;
  handleEditingBlur?: () => void;
  valueCurrency?: number | string | null;
  onChangeCurrency?: (values: NumberFormatValues) => void;
  containerStyle?: CSSProperties | undefined;
  inputName?: string;
  paddingRight?: number;
  isCheckedIcon?: boolean;
}

export function Input({
  name,
  maskType,
  fontSize = 12,
  hasError = "",
  customRef,
  readOnly = false,
  onFocusClearError,
  handleEditingBlur,
  valueCurrency,
  onChangeCurrency,
  labelIcon = "",
  icon,
  onClickIcon,
  containerStyle,
  inputName,
  paddingRight,
  isCheckedIcon = false,
  ...rest
}: InputProps) {
  const theme = useTheme();
  const [isFocused, setIsFocused] = useState(false);

  const mask = switchMaskInput(maskType!);

  function handleInputBlur() {
    setIsFocused(false);
    handleEditingBlur?.();
  }

  function handleOnfocus() {
    if (onFocusClearError) {
      onFocusClearError();
    }
    setIsFocused(true);
  }

  return (
    <>
      {!!name && <S.Label fontSize={fontSize}>{name}</S.Label>}
      <S.Container
        style={containerStyle}
        isErrored={!!hasError}
        isFocused={isFocused}
        isReadOnly={readOnly}
      >
        {/* {Icon && <Icon size="20" />} */}
        {maskType === "money" && (
          <NumberFormat
            style={{ fontFamily: theme.font.family }}
            prefix="R$ "
            placeholder="R$ 0,00"
            thousandSeparator="."
            decimalSeparator=","
            decimalScale={2}
            name={inputName}
            fixedDecimalScale
            value={valueCurrency}
            onValueChange={onChangeCurrency}
            onFocus={() => handleOnfocus()}
            onBlur={() => handleInputBlur()}
            readOnly={readOnly}
          />
        )}

        {maskType && maskType !== "money" && (
          <S.MaskInput
            style={{ fontFamily: theme.font.family }}
            mask={mask}
            readOnly={readOnly}
            name={inputName}
            onFocus={() => handleOnfocus()}
            onBlur={() => handleInputBlur()}
            {...rest}
          />
        )}

        {!maskType && (
          <S.WrapperInputIcon>
          <input
            style={{
              fontFamily: theme.font.family,
              paddingRight: paddingRight ? paddingRight : 0,
            }}
            onFocus={handleOnfocus}
            name={inputName}
            onBlur={handleInputBlur}
            readOnly={readOnly}
            ref={customRef}
            // defaultValue={defaultValue}
            // ref={inputRef}
            {...rest}
            />
            {isCheckedIcon && rest.value  && rest.value?.toString().length >= 9 && (
                <img src={AcceptIcon} alt="icone acerto" height={28} style={{marginRight: 8}} />
            )}
            </S.WrapperInputIcon>
        )}

        {!!icon && typeof icon === "string" && (
          <S.ButtonIcon title={labelIcon} onClick={onClickIcon}>
            <img src={icon} alt="icone de editar input" />
          </S.ButtonIcon>
        )}

        {!!hasError && (
          <S.Error title={hasError}>
            <FiAlertCircle color={theme.colors.error} size="20" />
          </S.Error>
        )}
      </S.Container>
    </>
  );
}
