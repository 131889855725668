import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { FaChevronDown } from "react-icons/fa";
import { useTheme } from "styled-components";

import Check from "assets/icons/check-background.svg";
import Edit from "assets/icons/edit.svg";
import Amex from "assets/icons/financial-icons/amex.svg";
import Elo from "assets/icons/financial-icons/elo.svg";
import Hiper from "assets/icons/financial-icons/hiper.svg";
import MasterCard from "assets/icons/financial-icons/master-card.svg";
import UndefinedCard from "assets/icons/financial-icons/undefined-card.svg";
import Visa from "assets/icons/financial-icons/visa.svg";
import Trash from "assets/icons/trash.svg";
import { AlertModal } from "components/AlertModal";
import { Input } from "components/Input";
import { useClickOutside } from "hooks/clickOutside";
import { useToast } from "hooks/toast";
import { api } from "services/api";

import * as S from "./styles";
import axios from "axios";
import { ShowWhenHavePermission } from "components/Permission";
import { useAccount } from "hooks/permission/account";
import { DisabledDropDown } from "components/DisabledDropDown";
import { permissionValidate } from "utils/permissionControl";

export type DropdownOption = {
  id: number;
  name: string;
  value?: string;
  default?: string;
};

type DropdownProps = {
  label?: string;
  name?: string;
  placeholder: string;
  options: DropdownOption[];
  categoryIdSelected?: number;
  optionSelected?: string | number;
  hasInput?: boolean;
  children?: React.ReactNode;
  hasError?: string;
  disabled?: boolean;
  clearDropDownItem?: boolean;
  handleClear?: () => void;
  onClickedValue: ({ id, name }: DropdownOption) => void;
  setCapacities?: Dispatch<SetStateAction<any>>;
  isCardBrand?: boolean;
  type?:
    | "default"
    | "equipment"
    | "service"
    | "type"
    | "capacity"
    | "manufacturers"
    | "categoryType"
    | "categoryMaterial";
  handleDeleteCategory?: (id: number) => void;
  handleDeleteCategoryType?: (id: number) => void;
  moduleHashValue?: string;
  actionView?: boolean;
};

enum DropdownTypes {
  default = "",
  equipment = "Equipamento",
  service = "Serviço",
  type = "Tipo de equipamento",
  capacity = "Capacidade",
  manufacturers = "",
  categoryType = "Tipo de categoria",
  categoryMaterial = "Material",
}

export function Dropdown({
  label,
  disabled = false,
  name,
  placeholder,
  options,
  categoryIdSelected = 0,
  hasInput = false,
  children,
  hasError = "",
  clearDropDownItem = false,
  handleClear,
  optionSelected,
  onClickedValue,
  isCardBrand = false,
  type = "default",
  setCapacities,
  handleDeleteCategory,
  handleDeleteCategoryType,
  moduleHashValue = "",
  actionView,
}: DropdownProps) {
  const theme = useTheme();
  const { addToast } = useToast();

  const [isOpen, setIsOpen] = useState(false);
  const [showInput, setShowInput] = useState(false);
  const [selectedValue, setSelectedValue] = useState("");
  const [editButton, setEditButton] = useState({
    showInput: false,
    index: 0,
    value: "",
  });
  const [alertModal, setAlertModal] = useState(false);
  const [data, setData] = useState<DropdownOption[]>([]);

  const containerStyle = {
    width: "60%",
    marginTop: 5,
    background: "#1E1E1E",
  };

  const { whoami } = useAccount();

  useEffect(() => {
    if (!optionSelected) {
      if (Number(categoryIdSelected) <= 0) {
        setSelectedValue("");
        return;
      }

      const currentValue = data.find(
        (option) => option.id === Number(categoryIdSelected)
      );

      if (currentValue) {
        setSelectedValue(currentValue.name);
      }
    } else {
      const currentValue = data.find(
        (option) => option.name === optionSelected
      );

      if (!currentValue) {
        const currentValueById = data.find(
          (option) => option.id === Number(optionSelected)
        );
        if (currentValueById) {
          setSelectedValue(currentValueById.name);
        }
      }

      if (currentValue) {
        setSelectedValue(currentValue.name);
      }
    }
  }, [categoryIdSelected, data, optionSelected, whoami?.id]);

  function toggleOptions() {
    setIsOpen((prev) => !prev);
  }

  function handleSelectedValue(option: DropdownOption) {
    setSelectedValue(option.name);
    onClickedValue(option);
    toggleOptions();
  }

  function handleShowInput() {
    setShowInput(true);
  }

  function handleClearSelectedDropDown() {
    setSelectedValue("");
    if (handleClear) {
      handleClear();
    }
  }

  const domNode = useClickOutside(() => {
    setIsOpen(false);
  });

  const getBrand = (option: DropdownOption, isSelectedValue = false) => {
    if (option) {
      const brandIcon = () => {
        if (option.name.toLowerCase().search("visa") >= 0) {
          return Visa;
        } else if (option.name.toLowerCase().search("mastercard") >= 0) {
          return MasterCard;
        } else if (option.name.toLowerCase().search("elo") >= 0) {
          return Elo;
        } else if (option.name.toLowerCase().search("hiper") >= 0) {
          return Hiper;
        } else if (option.name.toLowerCase().search("amex") >= 0) {
          return Amex;
        } else {
          return UndefinedCard;
        }
      };

      if (isSelectedValue) {
        return (
          <S.CardBrand>
            <img src={brandIcon()} alt="icon"></img>
            <S.CardBrandText>{option.name}</S.CardBrandText>
          </S.CardBrand>
        );
      } else {
        return (
          <S.OptionButton
            key={option.id}
            onClick={() => handleSelectedValue(option)}
          >
            <S.CardBrand>
              <img src={brandIcon()} alt="icon"></img>
              <S.CardBrandText>{option.name}</S.CardBrandText>
            </S.CardBrand>
          </S.OptionButton>
        );
      }
    } else {
      return;
    }
  };

  let objData: { label?: string; name?: string } = {
    label: editButton.value,
    name: editButton.value,
  };

  const getApi = () => {
    if (type === "equipment") {
      delete objData.label;
      return "services/equipment";
    } else if (type === "type") {
      delete objData.label;
      return "services/equipment/type";
    } else if (type === "capacity") {
      delete objData.label;
      return "services/equipment/capacity";
    } else if (type === "manufacturers") {
      delete objData.name;
      return "certifications/brands";
    } else if (type === "categoryType") {
      delete objData.label;
      return "/material/type";
    } else if (type === "categoryMaterial") {
      delete objData.label;
      return `/material/type/item`;
    } else {
      delete objData.label;
      return "services/name";
    }
  };

  const handleUpdate = async (index: number) => {
    try {
      await api.put(`${getApi()}/${data[index].id}`, objData);
      data[index].name = editButton.value;
      setData([...data]);
      setEditButton({ ...editButton, showInput: false });

      addToast({
        title: "Sucesso",
        description: "Equipamento atualizado com sucesso!",
        type: "success",
      });
    } catch (error) {
      addToast({
        title: "Ops",
        description: axios.isAxiosError(error)
          ? error.response?.data.error
          : "Ocorreu um erro ao editar",
        type: "error",
      });
    }
  };

  const handleDelete = async () => {
    try {
      if (type === "categoryType" && handleDeleteCategoryType) {
        handleDeleteCategoryType(Number(data[editButton.index].id));
      }

      if (type === "categoryMaterial" && handleDeleteCategory) {
        handleDeleteCategory(Number(data[editButton.index].id));
      }

      await api.delete(`${getApi()}/${data[editButton.index].id}`);
      data.splice(editButton.index, 1);
      setData([...data]);
      if (typeof setCapacities !== "undefined") {
        setCapacities([...data]);
      }
      addToast({
        title: "Sucesso",
        description: `${DropdownTypes[type]} deletado com sucesso!`,
        type: "success",
      });
    } catch (error) {
      addToast({
        title: "Ops",
        description:
          axios.isAxiosError(error) && error.response?.data.error
            ? error.response?.data.error
            : "Ocorreu um erro ao editar",
        type: "error",
      });
    } finally {
      setAlertModal(false);
      onClickedValue({ id: 0, name: "" });
    }
  };

  useEffect(() => {
    if (!actionView && moduleHashValue) {
      const filters = options.filter((item) => {
        return item.default === "true";
      });
      setData(filters);
    } else {
      setData(options);
    }
  }, [actionView, options, moduleHashValue]);

  function checkDefault(item: DropdownOption) {
    if (item.default !== undefined) {
      if (item.default.toString() === "false") {
        return true;
      }

      return false;
    }

    return false;
  }

  const handlePermissionAlert = () => {
    addToast({
      title: "Atenção!",
      description: "Você não possui permissão para executar essa ação!",
      type: "info",
    });
  };

  return (
    <>
      {options.length < 1 &&
      whoami &&
      !permissionValidate(whoami, moduleHashValue) ? (
        <DisabledDropDown
          label={label || ""}
          title={placeholder}
          onClick={handlePermissionAlert}
        />
      ) : (
        <S.Container>
          <>
            <AlertModal
              isVisible={alertModal}
              title="Aviso"
              description={`Você tem certeza que deseja excluir ${
                type === "equipment"
                  ? " esse equipamento ?"
                  : type === "service"
                  ? " esse serviço ?"
                  : type === "type"
                  ? "esse tipo ?"
                  : ""
              }`}
              action="choose"
              handleConfirm={() => handleDelete()}
              onCloseModal={() => setAlertModal(false)}
              zIndex={100000}
            />

            <S.Content ref={domNode}>
              {!!label && <p>{label}</p>}
              <S.Dropdown
                disabled={disabled}
                isOpen={isOpen}
                hasError={!!hasError}
                onClick={() => toggleOptions()}
                hasValue={!!selectedValue}
                type="button"
              >
                {isCardBrand
                  ? selectedValue
                    ? getBrand(
                        options[
                          options.findIndex((e) => e.name === selectedValue)
                        ],
                        true
                      )
                    : placeholder
                  : selectedValue || placeholder}

                <FaChevronDown color={theme.colors.text} />
              </S.Dropdown>
              {!!hasError && !isOpen && <S.ErrorText>{hasError}</S.ErrorText>}
              {isOpen && (
                <S.OptionsContainer>
                  {data.map((option, index) =>
                    isCardBrand ? (
                      getBrand(option, false)
                    ) : (
                      <div
                        key={index}
                        style={{ position: "relative", width: "100%" }}
                      >
                        {editButton.showInput && editButton.index === index ? (
                          <S.EditingWrapper>
                            <Input
                              containerStyle={containerStyle}
                              type="text"
                              value={editButton.value}
                              inputName={name}
                              onChange={(event) =>
                                setEditButton({
                                  ...editButton,
                                  value: event.target.value,
                                })
                              }
                            />
                            <img
                              onClick={() => handleUpdate(index)}
                              style={{
                                height: 45,
                                marginTop: 5,
                                marginLeft: 15,
                                cursor: "pointer",
                              }}
                              src={Check}
                              alt="icon"
                            ></img>
                          </S.EditingWrapper>
                        ) : (
                          <S.OptionButton
                            key={option.id}
                            onClick={() => handleSelectedValue(option)}
                          >
                            {option.name}
                          </S.OptionButton>
                        )}
                        {checkDefault(option) && (
                          <S.ButtonsWrapper
                            style={{
                              top:
                                editButton.showInput &&
                                editButton.index === index
                                  ? 15
                                  : 8,
                            }}
                          >
                            <>
                              {!!moduleHashValue ? (
                                <ShowWhenHavePermission
                                  moduleHash={moduleHashValue}
                                  actionHash="edit"
                                >
                                  <S.Buttons
                                    onClick={() =>
                                      setEditButton({
                                        showInput: !editButton.showInput,
                                        index,
                                        value: option.name,
                                      })
                                    }
                                  >
                                    <img
                                      style={{ height: 15 }}
                                      src={Edit}
                                      alt="icon"
                                    ></img>
                                  </S.Buttons>
                                </ShowWhenHavePermission>
                              ) : (
                                <S.Buttons
                                  onClick={() =>
                                    setEditButton({
                                      showInput: !editButton.showInput,
                                      index,
                                      value: option.name,
                                    })
                                  }
                                >
                                  <img
                                    style={{ height: 15 }}
                                    src={Edit}
                                    alt="icon"
                                  ></img>
                                </S.Buttons>
                              )}
                            </>
                            <>
                              {!!moduleHashValue ? (
                                <ShowWhenHavePermission
                                  actionHash="delete"
                                  moduleHash={moduleHashValue}
                                >
                                  <S.Buttons
                                    onClick={() => {
                                      setEditButton({ ...editButton, index });
                                      setAlertModal(true);
                                    }}
                                  >
                                    <img
                                      style={{ height: 15 }}
                                      src={Trash}
                                      alt="icon"
                                    ></img>
                                  </S.Buttons>
                                </ShowWhenHavePermission>
                              ) : (
                                <S.Buttons
                                  onClick={() => {
                                    setEditButton({ ...editButton, index });
                                    setAlertModal(true);
                                  }}
                                >
                                  <img
                                    style={{ height: 15 }}
                                    src={Trash}
                                    alt="icon"
                                  ></img>
                                </S.Buttons>
                              )}
                            </>
                          </S.ButtonsWrapper>
                        )}
                      </div>
                    )
                  )}

                  {hasInput && showInput && (
                    <S.WrapperChildren>{children}</S.WrapperChildren>
                  )}

                  {hasInput && !showInput && (
                    <>
                      {!!moduleHashValue ? (
                        <ShowWhenHavePermission
                          moduleHash={moduleHashValue}
                          actionHash="create"
                        >
                          <S.ShowInputButton
                            type="button"
                            style={{ color: theme.colors.primary }}
                            onClick={() => handleShowInput()}
                          >
                            Adicionar novo item
                          </S.ShowInputButton>
                        </ShowWhenHavePermission>
                      ) : (
                        <S.ShowInputButton
                          type="button"
                          style={{ color: theme.colors.primary }}
                          onClick={() => handleShowInput()}
                        >
                          Adicionar novo item
                        </S.ShowInputButton>
                      )}
                    </>
                  )}
                </S.OptionsContainer>
              )}
            </S.Content>

            {clearDropDownItem && !!selectedValue && (
              <S.ButtonClearSelectedItem
                onClick={() => handleClearSelectedDropDown()}
              >
                <S.ClearIcon name="close" />
              </S.ButtonClearSelectedItem>
            )}
          </>
        </S.Container>
      )}
    </>
  );
}
