import styled, { css } from "styled-components";

interface textColor {
  color?: string;
}

export const Container = styled.div<textColor>`
  ${({ theme, color }) => css`
    span {
      width: 100%;
      font-size: 14px;
      display: block;
      text-align: right;
      color: ${color};
    }
  `}
`;
