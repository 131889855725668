import styled, { css } from "styled-components";

export const Container = styled.div`
  ${({ theme }) => css`
    flex: 1;
    padding: 20px;
    background-color: ${theme.colors.background};
  `}
`;

export const Title = styled.p`
  ${({ theme }) => css`
    font-size: 18px;
    color: ${theme.colors.financial_title};
    font-family: ${theme.font.family};
    font-weight: ${theme.font.bold};
    margin-top: 24px;
    margin-top: 30px;
    line-height: 22px;
    padding-bottom: 20px;
  `}
`;

export const Subtitle = styled.p`
  ${({ theme }) => css`
    font-size: 14px;
    color: ${theme.colors.financial_title};
    font-family: ${theme.font.normal};
    max-width: 70%;
    line-height: 17px;
    margin-bottom: 20px;
  `}
`;

export const ContainerButtons = styled.div`
  width: 40%;
`;

export const ArchiveButton = styled.button`
  position: absolute;
  bottom: 0;
  width: 100%;
  align-items: center;
  margin-bottom: 50px;
`;

export const ArchiveButtonText = styled.p`
  ${({ theme }) => css`
    font-size: 12px;
    color: ${theme.colors.primary};
    font-family: ${theme.font.normal};
    letter-spacing: 0.26px;
    text-decoration: underline;
  `}
`;

export const WrapperArchiveButton = styled.div`
  width: 40%;
  display: flex;
  justify-content: center;
`;
