import styled, { css } from "styled-components";
import { ToggleButton } from "components/CardCheckRegister/styles";

export const Container = styled.div`
  padding-left: 30px;

  header {
    ${({ theme }) => css`
      width: 100vw;
      max-width: 1240px;
      display: flex;
      align-items: center;
      margin-top: 12px;

      & > div {
        margin-left: 40px;

        & > h2 {
          color: ${theme.colors.text_extra_light};
          font-size: 1.8rem;
        }

        & > p {
          color: ${theme.colors.text};
          font-size: ${theme.font.sizes.xsmall};
          margin-top: 8px;
        }
      }
    `}
  }

  form {
    width: 46%;
    height: 100%;
    margin-top: 50px;
  }
`;

export const WrapperInput = styled.div`
  margin-bottom: 15px;
`;

export const WrapperCheckBox = styled.div`
  margin-bottom: 15px;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;

  & > div {
    width: 49%;
    min-width: 60px;
  }
`;

export const LabelFillCPF = styled.button`
  ${({ theme }) => css`
    margin: 2px 0 22px;
    color: ${theme.colors.text};
    text-decoration: underline;
    font-size: ${theme.font.sizes.small};
  `}
`;

export const GroupInputs = styled.div`
  margin-bottom: 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  & > div {
    width: 49%;
  }
`;

export const WrapperButton = styled.div`
  margin-top: 100px;
  margin-bottom: 50px;
`;

export const Title = styled.div`
  ${({ theme }) => css`
    font-size: 18px;
    font-weight: 700;
    color: ${theme.colors.text_extra_light};
    margin-right: auto;
    margin-left: 30px;
    margin-top: 25px;

    & > p {
      color: ${theme.colors.text};
      font-size: ${theme.font.sizes.xsmall};
      margin-top: 8px;
    }
  `}
`;

export const Wrapper = styled.div`
  ${({ theme }) => css`
    width: 100%;
    padding: 10px;
    background-color: ${theme.colors.background_light};
    border-radius: 10px;
    display: flex;
    justify-content: space-between;
    color: ${theme.colors.text_extra_light};
    font-size: 12px;
    font-weight: 400;
  `}
`;

export const AddAreaOfExpertise = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const AddAreaText = styled.div`
  ${({ theme }) => css`
    margin-left: 10px;
    color: ${theme.colors.primary};
    font-size: 12px;
    font-weight: 400;
  `}
`;

export const MettersContainer = styled.div`
  display: flex;
  justify-self: center;
  align-self: center;
  justify-content: center;
  align-items: center;
  margin-bottom: 30px;
  margin-top: 10px;
`;

export const Text = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  margin-top: 10px;
`;

export const TextContainer = styled.div`
  margin-top: 70px;
  color: white;
  margin-right: 50px;
`;

export const BolderText = styled.div`
  font-size: 18px;
  line-height: 22px;
  font-weight: 700;
`;

export const ExpertiseAreaContainer = styled.div`
  overflow-y: auto;
`;

export const Divider = styled.div`
  ${({ theme }) => css`
    border-bottom: 0.5px solid ${theme.colors.background_light};
    margin-top: 10px;
    margin-bottom: 5px;
  `}
`;

export const ExpertiseContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 10px;
`;

export const Checkbox = styled(ToggleButton)`
  ${({ theme }) => css`
    border-radius: 5px;
    width: 20px;
    height: 20px;
    background: transparent;
    border: 1px solid ${theme.colors.text_light_87};
    display: flex;
    justify-content: center;
    align-items: center;
  `}
`;

export const Check = styled.div`
  ${({ theme }) => css`
    width: 10px;
    height: 10px;
    background: ${theme.colors.primary};
    border-radius: 3px;
  `};
`;

export const Marker = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 5px;
`;

export const MarkerLine = styled.div`
  ${({ theme }) => css`
    border: 0.3px solid ${theme.colors.menu_detail_light};
    height: 5px;
    width: 0;
    margin-bottom: 5px;
  `}
`;

export const AcceptView = styled.div`
  display: flex;
  margin-top: 80px;
`;
