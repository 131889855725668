import { HTMLAttributes } from "react";
import { ServiceOrderProps } from "hooks/selectedServiceOrder";

import * as S from "./styles";

type Props = {
  data: ServiceOrderProps;
} & HTMLAttributes<HTMLButtonElement>;

export function ServiceOrderCard({ data, ...rest }: Props) {
  const { services, client, checked } = data;

  return (
    <S.Container checked={checked} {...rest}>
      <S.WrapperCheckbox>
        <S.CheckBox checked={checked}>
          <div />
        </S.CheckBox>
      </S.WrapperCheckbox>

      <div>
        <S.WrapperTitle>
          <S.Title>
            OS {data.sequenceNumber} - {client.name}
          </S.Title>
          {/* <span>Finalizada em: 25/03/2022</span> */}
        </S.WrapperTitle>

        {services.map(({ idBudgetService, service }) => (
          <S.TextDetail key={idBudgetService}>{service}</S.TextDetail>
        ))}
      </div>
    </S.Container>
  );
}
