import styled, { css, keyframes } from "styled-components";

type Props = {
  hasNotification: boolean;
};

const vibrateBell = keyframes`
  0%,
  100% {
    transform: rotate(0deg);
  }
  10%,
  30%,
  50%,
  70% {
    transform: rotate(-16deg);
  }
  20%,
  40%,
  60% {
    transform: rotate(16deg);
  }
  80% {
    transform: rotate(10deg);
  }
  90% {
    transform: rotate(-10deg);
  }
`;

export const Container = styled.button<Props>`
  ${({ theme, hasNotification }) => css`
    height: 24px;
    width: 74px;
    border-radius: 22px;
    padding: 18px;
    position: relative;

    display: flex;
    align-items: center;
    justify-content: flex-end;

    cursor: pointer;

    ${!hasNotification &&
    css`
      height: 36px;
      width: 36px;
      border-radius: 50%;
      justify-content: center;
    `}

    img {
      width: 24px;
    }

    &:hover {
      img {
        animation: ${vibrateBell} 0.8s cubic-bezier(0.455, 0.03, 0.515, 0.955)
          both;
      }
    }

    & > div {
      position: absolute;
      left: 0;
      width: 30px;
      height: 20px;
      border-radius: 10px;
      margin-right: 12px;
      background-color: #e03232;

      display: flex;
      align-items: center;
      justify-content: center;

      & > p {
        font-family: ${theme.font.family};
        font-size: ${theme.font.sizes.small};
        font-weight: ${theme.font.bold};
        color: ${theme.colors.text_extra_light};
      }
    }
  `}
`;
