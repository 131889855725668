import { useEffect, useState } from "react";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";

import { useToast } from "hooks/toast";
import { useAccount } from "hooks/permission/account";

import { LoadingPageModal } from "components/LoadingPageModal";
import { ListItemCard } from "../ListItemCard";
import { ModalProfileDetail } from "../ModalProfileDetail";

import { PageDetail } from "pages/permission/PageDetail";

import HandShakeSvg from "assets/icons/permission/hand-shake.svg";
import HelmetSvg from "assets/icons/permission/orange-helmet.svg";

import { api } from "services/api";
import { Profile } from "dtos/permission/ProfileDTO";

import * as S from "./styles";
import { Pages } from "pages/permission/ManagementOfTeam";

type Props = {
  [key: number]: string;
};

export const mappedProfileIcons: Props = {
  1: HandShakeSvg,
  2: HelmetSvg,
};

type RoleAndPermissionProps = {
  handleSelectRole?: (memberRole: Profile) => void;
  editingPermission?: boolean;
  setEdditingPermission?: (value: boolean) => void;
  memberInvitedId?: number;
  currentprofileId?: number;
  setViewPageDetail?: (page: Pages | null) => void;
};
export function RoleAndPermission({
  handleSelectRole = undefined,
  editingPermission,
  memberInvitedId,
  currentprofileId,
  setEdditingPermission,
  setViewPageDetail,
}: RoleAndPermissionProps) {
  const { addToast } = useToast();
  const { whoami } = useAccount();

  const [loading, setLoading] = useState(true);
  const [openModalDetail, setOpenModalDetail] = useState(false);
  const [profiles, setProfiles] = useState<Profile[]>([]);
  const [selectedProfile, setSelectedProfile] = useState<Profile>({
    id: currentprofileId,
  } as Profile);

  function unSelectProfile() {
    if (openModalDetail) {
      setSelectedProfile({} as Profile);
    }
  }

  function handleToggleModal() {
    unSelectProfile();
    setOpenModalDetail(!openModalDetail);
  }

  function handleProfileSelected(profile: Profile) {
    setSelectedProfile(profile);
    handleToggleModal();
  }

  useEffect(() => {
    async function getProfiles() {
      try {
        if (whoami?.id) {
          const { data } = await api.get(`users/teams/${whoami?.id}/profiles`);
          setProfiles(data.profiles);
        }
      } catch (err) {
        addToast({
          title: "Oops!",
          description: "Não foi possível carregar os dados.",
          type: "error",
        });
      } finally {
        setLoading(false);
      }
    }

    getProfiles();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [whoami?.id]);

  return (
    <>
      {loading ? (
        <LoadingPageModal />
      ) : (
        <>
          {openModalDetail && (
            <ModalProfileDetail
              setEdditingPermission={setEdditingPermission}
              memberInvitedId={memberInvitedId}
              editingPermission={editingPermission}
              profile={selectedProfile}
              openModalDetail={openModalDetail}
              handleToggleModal={handleToggleModal}
              handleSelectRole={handleSelectRole}
            />
          )}
          <PageDetail
            hideGoBackButton={!editingPermission}
            handleGoBack={() => {
              setEdditingPermission && setEdditingPermission(false);
              setViewPageDetail && setViewPageDetail("teamMember");
            }}
            title="Cargos e permissões"
            subtitle="Selecione cargos pré-definidos ou personalizados."
          >
            <S.TabContainer>
              <Tabs selectedIndex={0} onSelect={() => {}}>
                <TabList>
                  <Tab>Pré-definidos</Tab>
                  <Tab>
                    <S.Wrapper>
                      Personalizados
                      <S.TagSoon>
                        <span>Em breve</span>
                      </S.TagSoon>
                    </S.Wrapper>
                  </Tab>
                </TabList>

                <TabPanel>
                  {profiles.map((currentProfile) => (
                    <ListItemCard
                      key={currentProfile.id}
                      image={mappedProfileIcons[currentProfile.id]}
                      title={currentProfile.name}
                      subtitle={
                        currentProfile.default
                          ? "Pré-definido"
                          : "Personalizado"
                      }
                      isSelected={currentProfile.id === selectedProfile.id}
                      onClick={() => handleProfileSelected(currentProfile)}
                    />
                  ))}
                </TabPanel>
              </Tabs>
            </S.TabContainer>
          </PageDetail>
        </>
      )}
    </>
  );
}
