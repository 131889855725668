import { Route, Switch } from "react-router-dom";

import { ExecuteServiceOrder } from "pages/fieldService/ResearchForm/ExecuteServiceOrder";
import { RatingServiceOrder } from "pages/fieldService/ResearchForm/RatingServiceOrder";
import { CompleteReseachFeedback } from "pages/fieldService/ResearchForm/CompleteReseachFeedback";

export function ResearchRoutes() {
  return (
    <Switch>
      <Route
        path="/research-form/:hash"
        exact
        component={ExecuteServiceOrder}
      />
      <Route
        path="/research-form/:hash/rating"
        exact
        component={RatingServiceOrder}
      />
      <Route
        path="/research-form/:hash/success"
        exact
        component={CompleteReseachFeedback}
      />
    </Switch>
  );
}
