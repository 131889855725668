import { useAccount } from "hooks/permission/account";

import HandShakeSvg from "assets/icons/permission/hand-shake.svg";

import * as S from "./styles";

export function RolePresentation() {
  const { whoami } = useAccount();

  const { isMe, profile, companyName, address } = whoami!;

  const label = isMe ? `Sócio proprietário` : profile.name;

  return (
    <S.Container>
      <img src={HandShakeSvg} alt="Ícone de aperto de mão" />

      <S.ContentInfo>
        <S.Bold>
          {label} <S.InfoHighlightText>em</S.InfoHighlightText> {companyName}
        </S.Bold>

        <S.InfoText>
          {address.city}, {address.state}
        </S.InfoText>
      </S.ContentInfo>
    </S.Container>
  );
}
