import React, { createContext, useContext, useEffect, useState } from "react";

import { AccountDTO } from "dtos/permission/AccountDTO";
import { useAuth } from "../auth";
import { useToast } from "hooks/toast";
import { ProviderProps } from "hooks";

type AccountContextData = {
  whoami: AccountDTO | null;
  handleSetWhoami: (account: AccountDTO) => Promise<void>;
  accounts: AccountDTO[];
};

const AccountContext = createContext<AccountContextData | null>(null);

export const KEY = "@profiz:account";

export const AccountProvider = ({ children }: ProviderProps) => {
  const { user } = useAuth();
  const { addToast } = useToast();
  const [whoami, setWhoAmi] = useState<AccountDTO | null>(null);

  const accounts = user?.permissions?.accounts || [];

  async function handleSetWhoami(account: AccountDTO) {
    setWhoAmi(account);

    localStorage.setItem(KEY, JSON.stringify(account));
  }

  const checkAndAddFirstItem = async () => {
    try {
      let accountOwner = accounts.find((account) => account.isMe);

      const accountOnStorage = localStorage.getItem(KEY);
      if (!accountOnStorage) {
        if (accountOwner) {
          localStorage.setItem(KEY, JSON.stringify(accountOwner));
          setWhoAmi(accountOwner);
        }
      } else {
        const parseAccount = JSON.parse(accountOnStorage);

        const ImSaved = accounts.find((account) => {
          return account.id === parseAccount.id;
        });

        if (!ImSaved) {
          const myAccount = accounts.find((account) => {
            return account.isMe;
          });

          handleSetWhoami(myAccount as AccountDTO);

          return;
        }

        setWhoAmi(parseAccount);
      }
    } catch (error) {
      addToast({
        title: "Ops!!",
        description: "Ocorreu um erro ao trazer a conta selecionada",
        type: "error",
      });
    }
  };

  useEffect(() => {
    checkAndAddFirstItem();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accounts]);

  return (
    <AccountContext.Provider
      value={{
        whoami,
        handleSetWhoami,
        accounts,
      }}
    >
      {children}
    </AccountContext.Provider>
  );
};

export function useAccount(): AccountContextData {
  const context = useContext(AccountContext);

  if (!context) {
    throw new Error("useAccount must be used within a AccountProvider");
  }

  return context;
}
