import styled, { css } from 'styled-components'
import { shade } from 'polished'

export const Container = styled.div`
  & > input {
    ${({ theme }) => css`
      height: 31px;
      width: 60px;
      outline: none;
      margin-left: 15px;
      margin-right: 10px;

      text-align: center;
      color: ${theme.colors.text_extra_light};
      font-size: ${theme.font.sizes.xsmall};

      border: 1px solid #707070;
      border-radius: 8px;
      background-color: transparent;

      &:focus-within {
        border: 1px solid ${theme.colors.primary};
      }

      &[type='number'] {
        -moz-appearance: textfield;
      }

      &::-webkit-outer-spin-button,
      &::-webkit-inner-spin-button {
        -webkit-appearance: none;
      }
    `}
  }
`

export const ToolTip = styled.div`
  ${({ theme }) => css`
    position: absolute;
    bottom: 44px;
    right: -14px;
    z-index: ${theme.layers.alwaysOnTop};
    border-radius: 8px;
    width: 116px;

    background-color: ${theme.colors.edit_area};

    & > button {
      width: 100%;
      height: 50%;
      padding: 8px 20px;
      border-radius: 8px;
      color: ${theme.colors.title};
      transition: background 0.2s;
    }

    & > button:hover {
      background: ${shade(0.1, theme.colors.edit_area)};
    }

    & > div {
      width: 100%;
      height: 1px;
      background-color: #707070;
      opacity: 0.5;
    }
  `}
`
