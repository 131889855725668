import React, { CSSProperties } from "react";
import { useTheme } from "styled-components";

import * as S from "./styles";

type InputCodeProps = {
  length: number;
  onChange: (value: string) => void;
  hasError?: string;
};

export function InputCode({ length, onChange, hasError }: InputCodeProps) {
  const theme = useTheme();

  const inputStyle: CSSProperties = {
    height: 50,
    width: 50,
    textAlign: "center",
    fontSize: "1.5rem",
    borderRadius: "0.6rem",
    backgroundColor: theme.colors.background_light,
    color: "#fff",
    marginLeft: "6px",
    fontWeight: theme.font.bold,
    outline: "none",
  };

  return (
    <div>
      <S.InputCode
        name="code"
        inputMode="verbatim"
        fields={length}
        onChange={onChange}
        forceUppercase
        isValid={!hasError}
        autoFocus
        inputStyle={inputStyle}
        inputStyleInvalid={{
          ...inputStyle,
          borderColor: theme.colors.error,
        }}
      />

      {!!hasError && <S.LabelError>{hasError}</S.LabelError>}
    </div>
  );
}
