import React, { createContext, useContext, useState } from "react";
import { ProviderProps } from "hooks";

type MenuContextData = {
  isOpenMenu: boolean;
  selectedMenu: string;
  selectedOption: string;
  selectedSubOption: string;
  handleSetIsOpenMenu: () => void;
  handleSetSelectedMenu: (currentMenu: string) => void;
  handleSetSelectedOption: (currentOption: string) => void;
  handleSetSelectedSubOption: (currentSubOption: string) => void;
};

const MenuContext = createContext<MenuContextData>({} as MenuContextData);

export function useMenu(): MenuContextData {
  const context = useContext(MenuContext);

  if (!context) {
    throw Error("useMenu must be used within a MenuProvider");
  }

  return context;
}

export const MenuProvider = ({ children }: ProviderProps) => {
  const [isOpenMenu, setIsOpenMenu] = useState(true);
  const [selectedMenu, setSelectedMenu] = useState("home");
  const [selectedOption, setSelectedOption] = useState("");
  const [selectedSubOption, setSelectedSubOption] = useState("");

  function handleSetIsOpenMenu() {
    setIsOpenMenu(!isOpenMenu);
  }

  function handleSetSelectedMenu(currentMenu: string) {
    setSelectedMenu(currentMenu);
  }

  function handleSetSelectedOption(currentOption: string) {
    setSelectedOption(currentOption);
  }

  function handleSetSelectedSubOption(currentSubOption: string) {
    setSelectedSubOption(currentSubOption);
  }

  return (
    <MenuContext.Provider
      value={{
        isOpenMenu,
        selectedMenu,
        selectedOption,
        selectedSubOption,
        handleSetIsOpenMenu,
        handleSetSelectedMenu,
        handleSetSelectedOption,
        handleSetSelectedSubOption,
      }}
    >
      {children}
    </MenuContext.Provider>
  );
};
