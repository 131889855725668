const listGoodOptions = [
  {
    id: 1,
    name: "Ótimo atendimento",
    active: false,
  },
  {
    id: 2,
    name: "Profissional competente",
    active: false,
  },
  {
    id: 3,
    name: "Serviço bem executado",
    active: false,
  },
  {
    id: 4,
    name: "Agilidade",
    active: false,
  },
  {
    id: 5,
    name: "Pontualidade",
    active: false,
  },
  {
    id: 6,
    name: "Limpeza",
    active: false,
  },
  {
    id: 7,
    name: "Recomendo!",
    active: false,
  },
];

const listBadOptions = [
  {
    id: 1,
    name: "Serviço incompleto",
    active: false,
  },
  {
    id: 2,
    name: "Falta de profissionalismo",
    active: false,
  },
  {
    id: 3,
    name: "Atraso",
    active: false,
  },
  {
    id: 4,
    name: "Não entregou o serviço combinado",
    active: false,
  },
  {
    id: 5,
    name: "Deixou ambiente sujo",
    active: false,
  },
  {
    id: 6,
    name: "Atendimento ruim",
    active: false,
  },
];

export { listGoodOptions, listBadOptions };
