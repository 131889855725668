import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";

import { ProfileOrderServiceShimmer } from "Shimmer/ProfileOrderServiceShimmer";
import {
  OrderServiceCard,
  OrderServiceProps,
} from "components/OrderServiceCard";

import { useAccount } from "hooks/permission/account";

import apiv2 from "services/apiv2";

import * as S from "./styles";

export function ProfileNextServices() {
  const { whoami } = useAccount();
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(true);
  const [previewNextServices, setPreviewNextServices] = useState<
    OrderServiceProps[]
  >([]);

  useEffect(() => {
    async function loadServices() {
      try {
        const response = await apiv2.get("budgets/service-orders", {
          params: {
            offset: 0,
            limit: 2,
            status: "scheduled",
            orderBy: "scheduledDate",
            accountId: whoami?.id,
          },
        });

        const orderedServices = response.data.ordens;

        setPreviewNextServices(orderedServices);
      } catch (err) {
        // sendError(err)
      } finally {
        setIsLoading(false);
      }
    }

    loadServices();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function handleNavigate() {
    history.push("/service-order");
  }

  function handleNavigateToServiceOrderDetails(serviceOrderId: number) {
    history.push(`/service-order/scheduled/${serviceOrderId}`);
  }

  if (previewNextServices.length === 0) {
    return <></>;
  }

  return (
    <S.Container>
      <h2>Próximos serviços</h2>

      <S.Content>
        {isLoading && <ProfileOrderServiceShimmer />}

        {!isLoading && (
          <>
            {previewNextServices.map((nextService) => (
              <OrderServiceCard
                style={{ marginBottom: 10 }}
                key={nextService.id}
                data={nextService}
                onClick={() =>
                  handleNavigateToServiceOrderDetails(nextService.id)
                }
              />
            ))}
          </>
        )}
      </S.Content>

      {!isLoading && (
        <S.WrapperButton>
          <button type="button" onClick={() => handleNavigate()}>
            ver todos
          </button>
        </S.WrapperButton>
      )}
    </S.Container>
  );
}
