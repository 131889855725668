import { MaterialProps } from 'dtos/MaterialDTO'

export function mappedMaterialsForApi(selectedMaterials: MaterialProps[]) {
  return selectedMaterials.map((material) => ({
    material: {
      id: material.id,
      categoryId: material.categoryId,
      type: {
        name: material.type.name,
        id: material.type.id
      },
      item: {
        name: material.item.name,
        id: material.item.id
      },

      price: material.price
    },
    quantity: material.quantity
  }))
}
