import React, { ChangeEvent, useCallback, useRef, useState } from "react";
import { FiPlus } from "react-icons/fi";
import { useTheme } from "styled-components";

import { AlertModal } from "components/AlertModal";

import { useToast } from "hooks/toast";
import { useAuth } from "hooks/auth";

import apiv2 from "services/apiv2";

import * as S from "./styles";

type Photo = {
  id: number;
  link: string;
};

type FileProps = {
  base64Image: string;
  extension: string;
};

type AddSignaturePhotoProps = {
  type: string;
  photosQuantity: number;
  handleAddNewPhoto: (signaturePhotos: Photo) => void;
  handleSetProviderSignaturePicture?: (providerSignaturePhoto: Photo) => void;
  maxPhotos: number;
};

export function AddSignaturePhoto({
  type,
  photosQuantity,
  maxPhotos,
  handleAddNewPhoto,
  handleSetProviderSignaturePicture,
}: AddSignaturePhotoProps) {
  const theme = useTheme();
  const { addToast } = useToast();
  const { user } = useAuth();

  const [isVisibleModal, setIsVisibleModal] = useState(false);

  const hiddenFileInput = useRef<HTMLInputElement | null>(null);
  const photoLimitReached = photosQuantity >= maxPhotos;

  const validationErrorAlert = () => {
    if (photoLimitReached) {
      addToast({
        title: "Máximo permitido",
        description: `Ops, o máximo de fotos permitidos é ${maxPhotos}`,
        type: "error",
      });
      return;
    }
  };

  const handleAddSignature = () => {
    validationErrorAlert();
    if (!photoLimitReached && type === "signature" && user.digitalSign) {
      setIsVisibleModal(true);
      return;
    }

    hiddenFileInput.current?.click();
  };

  async function confirmDigitalSignatureFromProfile() {
    try {
      validationErrorAlert();
      setIsVisibleModal(false);

      const { 2: serviceOrderIdFromUrl } = window.location.pathname.split("/");

      const { data } = await apiv2.post(
        `budgets/service-order/${serviceOrderIdFromUrl}/signature-photo`,
        { type: "profile-sign" }
      );

      if (handleSetProviderSignaturePicture) {
        handleSetProviderSignaturePicture({
          id: data.photoID,
          link: data.link,
        });
      }

      setIsVisibleModal(false);

      addToast({
        title: "Sucesso!",
        description: "Foto adicionada com sucesso",
        type: "success",
      });
    } catch (error) {
      addToast({
        title: "Ops!",
        description: "Falha ao adicionar uma foto, tente mais tarde.",
        type: "error",
      });
    }
  }

  function handleFileInput() {
    setIsVisibleModal(false);
    if (hiddenFileInput.current) hiddenFileInput.current.click();
  }

  const handleAddSignaturePhoto = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      if (e.target.files) {
        const file = e.target.files[0];
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const [_, extension] = file.type.split("/");

        const reader = new FileReader();

        reader.readAsDataURL(file);

        reader.onload = async () => {
          const result = reader.result as string;

          const { 1: fileBase64Splited } = result.split(",");

          await handleSubmitPhoto({
            base64Image: fileBase64Splited,
            extension,
          });
        };
      }
    },
    [photosQuantity, addToast] // eslint-disable-line react-hooks/exhaustive-deps
  );

  async function handleSubmitPhoto({ base64Image, extension }: FileProps) {
    try {
      const { 2: serviceOrderIdFromUrl } = window.location.pathname.split("/");

      const response = await apiv2.post(
        `budgets/service-order/${serviceOrderIdFromUrl}/signature-photo`,
        {
          type,
          photo: {
            encode: base64Image,
            extensao: extension,
          },
        }
      );

      const { link, photoID } = response.data;

      const photo = {
        id: photoID,
        link,
      };

      handleAddNewPhoto(photo);

      addToast({
        title: "Sucesso",
        description: "Foto adicionada com sucesso",
        type: "success",
      });
    } catch (err) {
      addToast({
        title: "Ops!",
        description: "Falha ao adicionar a foto, tente mais tarde.",
        type: "error",
      });
    }
  }

  return (
    <section>
      <AlertModal
        isVisible={isVisibleModal}
        title="Assinatura digital cadastrada"
        description="Você possui uma assinatura cadastrada em seu perfil, deseja anexar a Ordem de Serviço?"
        labelConfirm="Sim"
        labelCancel="Não, buscar arquivo"
        action="choose"
        handleConfirm={confirmDigitalSignatureFromProfile}
        handleCancel={handleFileInput}
        onCloseModal={() => setIsVisibleModal(false)}
      />

      <S.Container quantityPhotosValidation={photoLimitReached}>
        <button
          onClick={() => {
            handleAddSignature();
          }}
        >
          <FiPlus size="18" color={theme.colors.primary} />
        </button>

        {photoLimitReached || (
          <input
            id={`attachment-${type}`}
            type="file"
            ref={hiddenFileInput}
            accept="image/*"
            onChange={handleAddSignaturePhoto}
          />
        )}
      </S.Container>
    </section>
  );
}
