import React, { ButtonHTMLAttributes } from "react";

import editSvg from "assets/icons/edit.svg";
import editDisabledSvg from "assets/icons/edit-disabled.svg";

import * as S from "./style";

type ButtonProps = {
  disabled?: boolean;
} & ButtonHTMLAttributes<HTMLButtonElement>;

export function EditButton({ disabled, ...rest }: ButtonProps) {
  return (
    <S.Container disabled={disabled} type="button" {...rest}>
      {disabled ? (
        <img src={editDisabledSvg} alt="Imagem de lápis para editar" />
      ) : (
        <img src={editSvg} alt="Imagem de lápis para editar" />
      )}
    </S.Container>
  );
}
