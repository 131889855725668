import styled, { css } from "styled-components";
import media from "styled-media-query";
import { lighten } from "polished";

export const Container = styled.div`
  ${({ theme }) => css`
    width: 100%;
    max-width: 1400px;
    margin: 0 auto;
    padding: 30px;

    header {
      width: 50%;
      display: flex;
      justify-content: space-between;
      align-items: center;

      ${media.lessThan("large")`
        flex-direction: column;
        align-items: flex-start;

        & > div:last-of-type {
          margin-top: 20px;
          margin-left: 0;
        }
      `}

      h1 {
        font-size: ${theme.font.sizes.large};
        color: ${theme.colors.title};
        font-weight: ${theme.font.bold};
        margin-left: 30px;
      }

      & > div {
        display: flex;
        align-items: center;
        justify-content: space-between;
      }

      & > div:last-of-type {
        min-width: 350px;
        margin-left: 9%;
      }
    }
  `}
`;

export const Content = styled.div`
  width: 50%;
  min-height: 80vh;
  min-width: 470px;
  margin-top: 50px;

  & > div:first-of-type {
    margin: 20px 0 30px;
  }
`;

export const ListClients = styled.ul`
  ${({ theme }) => css`
    list-style: none;

    button {
      width: 100%;
      height: 60px;
      display: flex;
      align-items: center;
      padding-left: 8px;
    }

    li {
      border-bottom: 1px solid ${theme.colors.background_light};

      p {
        font-size: ${theme.font.sizes.xsmall};
        color: ${theme.colors.text};
      }
    }

    li:nth-child(1) {
      border-top: 1px solid ${theme.colors.background_light};
    }

    li:hover {
      background-color: ${lighten(0.2, theme.colors.background_light)};
    }
  `}
`;

export const Profile = styled.div`
  ${({ theme }) => css`
    height: 35px;
    width: 35px;
    border-radius: 50%;
    margin-right: 12px;

    display: flex;
    justify-content: center;
    align-items: center;
    background-color: ${theme.colors.background_light};

    span {
      text-transform: uppercase;
      letter-spacing: 0.4px;
      font-size: ${theme.font.sizes.xsmall};
      color: ${theme.colors.text_extra_light};
    }
  `}
`;

export const FirstLetter = styled.div`
  ${({ theme }) => css`
    height: 40px;
    width: 40px;
    margin-left: 5px;

    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
    margin-bottom: 5px;

    span {
      text-transform: uppercase;
      letter-spacing: 0.4px;
      font-size: ${theme.font.sizes.xsmall};
      color: ${theme.colors.text};
    }
  `}
`;

export const UserPhoto = styled.div`
  ${({ theme }) => css`
    margin-right: 12px;

    width: 35px;
    height: 35px;
    border-radius: 25px;

    background: ${theme.colors.background_light};

    align-items: center;
    justify-content: center;
    display: flex;
  `}
`;
