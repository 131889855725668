import styled, { css } from "styled-components";

export const Container = styled.div`
  ${({ theme }) => css`
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;

    input {
      width: 90%;
      height: 35px;
      border: 0;
      outline: none;
      padding: 10px;
      border-radius: 8px;

      background-color: ${theme.colors.background_light};
      color: ${theme.colors.text_extra_light};

      &:focus-within {
        border: 1px solid ${theme.colors.primary};
      }
    }

    button {
      width: 28px;
      height: 28px;
      border-radius: 4px;
      background-color: ${theme.colors.primary};
      display: flex;
      justify-content: center;
      align-items: center;

      img {
        width: 18px;
      }
    }
  `}
`;
