import React, { ReactElement, useCallback, useEffect, useState } from "react";
import { ChecklistItemList, RegisterItem, TInfo } from "../types";

import * as S from "./styles";
import { SearchInput } from "components/SearchInput";
import { Button } from "components/Button";
import { ModalRight } from "components/ModalRight";
import { DeleteButton } from "components/DeleteButton";
import { useServiceChecklist } from "hooks/serviceChecklist";
import { useToast } from "hooks/toast";
import { api } from "services/api";
import { RegisterPage } from "../RegisterPage";
import { DetailRegisterPage } from "../DetailRegisterPage";
import { ChecklistPage } from "../ChecklistPage";
import { CardChecklist } from "components/CardChecklist";
import { EditButton } from "components/EditButton";
import debounce from "lodash.debounce";
import { LoadingProfiz } from "components/LoadingProfiz";
import axios from "axios";
import { TablePagination } from "components/TablePagination";
import { isEqual } from "lodash";
import { DiscardInformationModal } from "components/DiscardInformationModal";
import { EnvironmentPageProps } from "pages/pmoc/EnvironmentFlow/types";
import { useEnvironment } from "hooks/pmoc/environmentPmoc";
import { NewAlertModal } from "components/NewAlertModal";
import { ShowWhenHavePermission } from "components/Permission";
import { useAccount } from "hooks/permission/account";
import { searchDatalayer } from "utils/pushDataLayer";
type TOption = {
  [key: string]: ReactElement;
};

type ChecklistProps = {
  links: RegisterItem[];
} & ChecklistItemList;

type ResponseProps = {
  data: {
    info: TInfo;
    serviceChecklists: ChecklistProps[];
  };
};

type ResponseDetailProps = {
  data: ChecklistProps;
};

type ChecklistTabProps = {
  handleEditItemChecklist?: () => void;
  handleChooseEnvironment?: EnvironmentPageProps;
};

export function ChecklistTab({
  handleEditItemChecklist,
  handleChooseEnvironment,
}: ChecklistTabProps) {
  const {
    listChecklist,
    handleSetListChecklist,
    handleSetIsNewChecklist,
    handleSetChecklistDetail,
    isNotMenusAccess,
    isChecklistPage,
    selectedItemChecklist,
    handleSetSelectedItemChecklist,
    handleNewItemChecklist,
    actualRegister,
    backupRegister,
  } = useServiceChecklist();

  const { pushStackNavigation } = useEnvironment();

  const { addToast } = useToast();
  const [searchInput, setSearchInput] = useState("");
  const [loading, setLoading] = useState(false);
  const [modalAlert, setModalAlert] = useState(false);
  const [isOpenModalChecklist, setIsOpenModalChecklist] = useState(false);
  const [showCompareAlert, setShowCompareAlert] = useState(false);
  const [currentPageModal, setCurrentPageModal] = useState("");
  const [currentChecklistId, setCurrentChecklistId] = useState(0);

  const [inputLoading, setInputLoading] = useState(false);

  const checklistLimit = 10;

  const [currentPage, setCurrentPage] = useState(1);
  const [offset, setOffset] = useState(0);
  const [totalItems, setTotalItems] = useState(0);
  const defaultLimit = 20;
  const { whoami } = useAccount();

  useEffect(() => {
    getServiceChecklist();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [offset]);

  async function getServiceChecklist(search?: string) {
    try {
      if (!handleEditItemChecklist) setLoading(true);

      handleNewItemChecklist({});

      if (search) {
        const validateString = /[a-zA-Z@!#$%^&*()/\\]/;
        if (!validateString.test(search)) {
          return addToast({
            title: "Ops!!",
            description: "Não é possível fazer busca apenas com números.",
            type: "error",
          });
        }
      }

      const { data }: ResponseProps = search
        ? await api.get("/services/checklist", {
            params: {
              offset: offset,
              limit: defaultLimit,
              search: search,
              accountId: whoami?.id,
            },
          })
        : await api.get("/services/checklist", {
            params: {
              offset: offset,
              limit: defaultLimit,
              accountId: whoami?.id,
            },
          });

      const { serviceChecklists, info } = data;

      if (search) {
        searchDatalayer({
          search_term: search,
          success: !!serviceChecklists.length,
        });
      }

      setTotalItems(info?.total || 0);

      handleSetListChecklist(serviceChecklists);
    } catch (error) {
      addToast({
        title: "Ops!!",
        description:
          axios.isAxiosError(error) && error.response?.data.error
            ? error.response.data.error
            : "Não foi possível carregar os dados da checklist.",
        type: "error",
      });
    } finally {
      if (!handleEditItemChecklist) setLoading(false);
      setInputLoading(false);
    }
  }

  async function handleDeleteServiceChecklist(id: number) {
    try {
      if (!handleEditItemChecklist) setLoading(true);

      await api.delete(`/service/checklist/${id}`);

      //só remove do contexto caso de certo a chamada da api...
      const listFilter = listChecklist.filter((item) => {
        return item.id !== id;
      });
      handleSetListChecklist(listFilter);
      handleToggleModalDeleteChecklist();

      addToast({
        title: "OK!",
        description: "Checklist deletado com sucesso.",
        type: "success",
      });
    } catch (error) {
      addToast({
        title: "Ops!!",
        description: "Não foi possível deletar checklist.",
        type: "error",
      });
    } finally {
      if (!handleEditItemChecklist) setLoading(false);
    }
  }

  const validateSelectedItemChecklist = (idx: number): boolean => {
    return selectedItemChecklist.some((e) => Number(e.id) === Number(idx));
  };

  const setPageComponent = (keyPage: string) => {
    const pagesArr: TOption = {
      checklistPage: (
        <ChecklistPage
          handleDetailRegister={setPageRegisterDetail}
          handleFunction={setPageRegister}
          handleFunctionClose={() => {
            setIsOpenModalChecklist(false);
            getServiceChecklist();
          }}
        />
      ),
      registerPage: (
        <RegisterPage
          handleFunction={setPageRegisterDetail}
          handlePageClose={() => {
            setCurrentPageModal("checklistPage");
          }}
          isRegisterFirstTime
        />
      ),
      detailRegisterPage: (
        <DetailRegisterPage
          handlePageClose={() => {
            setCurrentPageModal(
              isChecklistPage ? "checklistPage" : "registerPage"
            );
          }}
        />
      ),
    };
    return pagesArr[keyPage];
  };

  function setPageRegister() {
    setCurrentPageModal("registerPage");
  }

  function setPageRegisterDetail() {
    setCurrentPageModal("detailRegisterPage");
  }

  const filterSelected = (currentElement: ChecklistItemList) => {
    handleSetSelectedItemChecklist([currentElement]);
  };

  async function detailItem(idItem: number) {
    try {
      if (!handleEditItemChecklist) setLoading(true);

      const { data }: ResponseDetailProps = await api.get(
        `/services/checklist/${idItem}`
      );

      const formattedRegister = {
        id: Number(data.id),
        name: data.name,
        registers: data.registers,
        default: data.default,
        isNew: data.isNew,
      };

      handleSetChecklistDetail(formattedRegister);

      handleSetIsNewChecklist(false);

      if (handleChooseEnvironment) {
        pushStackNavigation("serviceChecklist");
        return handleChooseEnvironment("checklistPage");
      }

      if (handleEditItemChecklist) return handleEditItemChecklist();
      setCurrentPageModal("checklistPage");
      setIsOpenModalChecklist(true);
    } catch (error) {
      addToast({
        title: "Ops!!",
        description:
          axios.isAxiosError(error) && error.response?.data.error
            ? error.response.data.error
            : "Não foi possível obter os detalhes do checklist.",
        type: "error",
      });
    } finally {
      if (!handleEditItemChecklist) setLoading(false);
    }
  }

  function handleSearchService(text: string) {
    setInputLoading(true);
    setSearchInput(text);
    debounceFn(text);
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debounceFn = useCallback(debounce(getServiceChecklist, 3000), []);

  function handleSearchCancel() {
    setSearchInput("");
    getServiceChecklist();
  }

  const handleToggleCreateChecklist = () => {
    if (listChecklist.length > checklistLimit) {
      addToast({
        title: "Máximo permitido",
        description: "Ops, só é permitido criar 10 checklists.",
        type: "info",
      });
      return;
    }
    handleSetIsNewChecklist(true);

    if (handleChooseEnvironment) {
      pushStackNavigation("serviceChecklist");
      return handleChooseEnvironment("checklistPage");
    }

    if (handleEditItemChecklist) return handleEditItemChecklist();
    //mandando para a pagina real...
    setCurrentPageModal("checklistPage");
    setIsOpenModalChecklist(true);
  };

  function handleToggleModalDeleteChecklist() {
    setModalAlert((prevState) => !prevState);
  }

  function handlePageChange(pageNumber: number, itemsPerPage: number) {
    setCurrentPage(pageNumber);
    setOffset((pageNumber - 1) * itemsPerPage);
  }

  function handleToggleModalRight() {
    if (
      currentPageModal === "detailRegisterPage" &&
      !isEqual(actualRegister, backupRegister)
    ) {
      setShowCompareAlert(true);
      return;
    }

    setIsOpenModalChecklist(!isOpenModalChecklist);
  }

  return (
    <>
      {loading ? (
        <LoadingProfiz isVisible={loading} />
      ) : (
        <>
          <DiscardInformationModal
            showModal={showCompareAlert}
            handleConfirmButton={() => {
              setShowCompareAlert(false);
              setIsOpenModalChecklist(!isOpenModalChecklist);
            }}
            handleCancelButton={() => {
              setShowCompareAlert(false);
            }}
          />

          <NewAlertModal
            isVisible={modalAlert}
            title="Excluir checklist?"
            description='Deseja realmente excluir este checklist? Os registros criados ainda poderão ser visualizados na aba "registros".'
            action="choose"
            handleConfirm={() => {
              handleDeleteServiceChecklist(currentChecklistId);
            }}
            onCloseModal={handleToggleModalDeleteChecklist}
            labelConfirm="Excluir checklist"
            labelCancel="Cancelar"
            buttonConfirmColor="#FA8B94"
          />

          <div style={{ marginBottom: "10px" }}>
            <SearchInput
              searchValue={searchInput}
              onChange={(e) => handleSearchService(e.target.value)}
              placeholder="Busque pelo nome do checklist"
              loadingInput={inputLoading}
              handleCancel={handleSearchCancel}
            />
          </div>
          <ShowWhenHavePermission
            moduleHash="service-checklist"
            actionHash="create"
          >
            <div>
              <Button
                typeButton="outline"
                onClick={handleToggleCreateChecklist}
              >
                Criar novo checklist
              </Button>
            </div>
            <S.SubText>
              Crie até 10 checklists e vincule os serviços relacionados
            </S.SubText>
          </ShowWhenHavePermission>

          {/*modal*/}
          <ModalRight
            isOpen={isOpenModalChecklist}
            handleToggleOpen={handleToggleModalRight}
          >
            {setPageComponent(currentPageModal)}
          </ModalRight>

          {!listChecklist.length ? (
            <S.EmptyMessage>
              <p>Não existem checklists cadastradas</p>
            </S.EmptyMessage>
          ) : (
            listChecklist.map((currentChecklist) => (
              <S.WrapperService key={currentChecklist.id}>
                <CardChecklist
                  isChecked={
                    isNotMenusAccess &&
                    validateSelectedItemChecklist(currentChecklist.id)
                  }
                  isCheckbox={isNotMenusAccess}
                  isCheckedButtonClick={isNotMenusAccess}
                  nameTypeCard="checklists"
                  handlePressButton={() => {
                    filterSelected(currentChecklist);
                  }}
                >
                  <S.WrapperInfoCard>
                    <S.WrapperData>
                      <h6>{currentChecklist.name}</h6>
                      <p>{currentChecklist.registers.length} Registros</p>
                    </S.WrapperData>
                    <S.WrapperButtonContent>
                      <ShowWhenHavePermission
                        moduleHash="service-checklist"
                        actionHash="edit"
                      >
                        <S.WrapperButton>
                          <EditButton
                            disabled={
                              currentChecklist.default ||
                              validateSelectedItemChecklist(
                                Number(currentChecklist.id)
                              )
                            }
                            onClick={() => {
                              detailItem(currentChecklist.id);
                            }}
                          />
                        </S.WrapperButton>
                      </ShowWhenHavePermission>

                      <ShowWhenHavePermission
                        moduleHash="service-checklist"
                        actionHash="delete"
                      >
                        <S.WrapperButton>
                          <DeleteButton
                            disabled={
                              currentChecklist.default ||
                              validateSelectedItemChecklist(
                                Number(currentChecklist.id)
                              )
                            }
                            onClick={() => {
                              handleToggleModalDeleteChecklist();
                              setCurrentChecklistId(currentChecklist.id);
                            }}
                          />
                        </S.WrapperButton>
                      </ShowWhenHavePermission>
                    </S.WrapperButtonContent>
                  </S.WrapperInfoCard>
                </CardChecklist>
              </S.WrapperService>
            ))
          )}
          <S.ContainerPagination>
            <TablePagination
              rowsLength={totalItems}
              rowsPerPage={defaultLimit}
              rowsPage={currentPage}
              handlePageChange={(pageNumber, itemsPerPage) =>
                handlePageChange(pageNumber, itemsPerPage)
              }
            />
          </S.ContainerPagination>
        </>
      )}
    </>
  );
}
