import styled, { css } from "styled-components";

export const Container = styled.div`
  ${({ theme }) => css`
    flex: 1;
    padding: 20px;
    background-color: ${theme.colors.background};
  `}
`;

export const Form = styled.div`
  margin-bottom: 50px;
  width: 50%;
`;

export const Title = styled.p`
  ${({ theme }) => css`
    font-size: 18px;
    color: ${theme.colors.financial_title};
    font-family: ${theme.font.family};
    font-weight: ${theme.font.bold};
    margin-top: 20px;
    padding-bottom: 20px;
  `}
`;

export const Subtitle = styled.p`
  ${({ theme }) => css`
    font-size: 14px;
    color: ${theme.colors.financial_title};
    font-family: ${theme.font.normal};
    max-width: 70%;
    line-height: 17px;
    margin-bottom: 20px;
  `}
`;

export const FormItem = styled.div`
  ${({ theme }) => css`
    padding-vertical: 10px;
    border-bottom-width: 1px;
    border-bottom-color: ${theme.colors.background_light};
    margin-top: 10px;
  `}
`;

export const Label = styled.p`
  ${({ theme }) => css`
    font-size: 14px;
    color: ${theme.colors.financial_title};
    font-family: ${theme.font.family};
    font-weight: ${theme.font.normal};
    line-height: 17px;
  `}
`;

export const Value = styled.p`
  ${({ theme }) => css`
    font-size: 12px;
    color: ${theme.colors.financial_text};
    font-family: ${theme.font.family};
    font-weight: ${theme.font.normal};
    margin-top: 10px;
    line-height: 15px;
  `}
`;

export const Line = styled.div`
  border: 1px solid #353535;
  width: 100%;
  margin-top: 10px;
`;

export const Inline = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

export const Block = styled.div`
  width: 49%;
`;

export const WrapperButton = styled.div`
  margin-top: 40px;
`;

export const ArchiveButton = styled.button`
  margin-top: 20px;
  width: 100%;
`;

export const ArchiveText = styled.p`
  ${({ theme }) => css`
    color: ${theme.colors.primary};
    font-size: 12px;
    font-family: ${theme.font.normal};
    text-align: center;
    text-decoration: underline;
  `}
`;

export const ImagesWrapper = styled.div`
  margin-top: 12px;
  width: 103%;
  display: flex;
`;
