import styled, { css } from "styled-components";

export const Container = styled.div`
  width: 100%;
  max-width: 1400px;
  margin: 0 auto;

  padding: 0px 30px 30px 30px;

  /* width: 100%; */
`;

export const Header = styled.div`
  background: radial-gradient(
    80.75% 49% at 97.6% 49%,
    #d4e74f 0%,
    #79ae00 100%
  );
  border-radius: 0px 0px 50px 50px;
  height: 155px;
  width: 45%;
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 500px;
  position: relative;
`;
export const Wrapper = styled.div`
  ${({ theme }) => css`
    padding: 10px;
    margin-top: 5px;
    width: 100%;
    background-color: ${theme.colors.background_light};
    border-radius: 10px;
    display: flex;
    align-items: center;
    flex-direction: row;
    cursor: pointer;
  `}
`;

export const Image = styled.img`
  border-radius: 10px;
  width: 60px;
  height: 60px;
  object-fit: cover;
`;

export const Top = styled.div`
  ${({ theme }) => css`
    margin-bottom: 30px;

    display: flex;
    align-items: center;

    p {
      font-size: ${theme.font.sizes.large};
      color: ${theme.colors.title};
      font-weight: ${theme.font.bold};
    }
  `}
`;

export const TextColumn = styled.div`
  margin-left: 20px;
`;

export const Text = styled.div`
  ${({ theme }) => css`
    font-family: ${theme.font.family};
    color: ${theme.colors.text_extra_light};
    font-size: 12px;
    line-height: 15px;
    text-align: center;
  `};
`;

export const Date = styled.div`
  ${({ theme }) => css`
    font-family: ${theme.font.family};
    color: rgba(255, 255, 255, 0.6);
    font-weight: 400;
    font-size: 11px;
    line-height: 13px;
    text-align: left;
  `};
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const Content = styled.div`
  margin-top: 30px;
`;

export const BolderText = styled.div`
  ${({ theme }) => css`
    font-family: ${theme.font.family};
    font-weight: 800;
    font-size: 18px;
    line-height: 22px;
    color: ${theme.colors.text_extra_light};
  `};
`;

export const TabContainer = styled.div`
  ${({ theme }) => css`
    margin-top: 90px;
    width: 45%;
    min-width: 500px;

    .react-tabs__tab-list {
      border-bottom: 1px solid rgba(255, 255, 255, 0.12);
      display: flex;
      justify-content: space-around;
    }

    .react-tabs__tab {
      color: ${theme.colors.text};
    }

    .react-tabs__tab--selected {
      background: inherit;
      border: 0px;
      color: ${theme.colors.text};

      font-weight: 500;
      line-height: 1.35;
      white-space: normal;
      letter-spacing: 0.02857em;
      border-bottom: 3px solid ${theme.colors.primary_light};
    }
  `}
`;

export const WrapperButtonFilter = styled.div`
  display: flex;
  width: 100%;
  margin-top: 40px;

  justify-content: space-around;
`;

export const WrapperButton = styled.div`
  min-width: 80px;
`;

export const ButtonClose = styled.button`
  ${({ theme }) => css`
    width: 35px;
    height: 35px;
    background-color: rgba(0,0,0, 0.1);
    border-radius: 10px;

    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 25px;
    position: absolute;
    left: 20px;
    top: 20px;
  `}
`;


export const Card = styled.div`
  height: 400px;
  width: 100%;
  margin-top: 20px;
`

export const GrayCard = styled.div`
  ${({ theme }) => css`
    background-color: ${theme.colors.background_light};
    padding: 20px 20px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-top: 2px;
    &:first-child {
      border-radius: 10px 10px 0px 0px;
    }

    &:last-child {
      border-radius: 0px 0px 10px 10px;
    }

    &:nth-child(even) {
    background-color: ${theme.colors.menu_background} !important;

    }
  `};
`