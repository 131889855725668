import React from 'react'

import * as S from './styles'

export function ManualsShimmer() {
  return (
    <>
      <div>
        <S.ShimmerManual />
        <S.ShimmerManualText />
      </div>

      <div>
        <S.ShimmerManual />
        <S.ShimmerManualText />
      </div>

      <div>
        <S.ShimmerManual />
        <S.ShimmerManualText />
      </div>

      <div>
        <S.ShimmerManual />
        <S.ShimmerManualText />
      </div>

      <div>
        <S.ShimmerManual />
        <S.ShimmerManualText />
      </div>

      <div>
        <S.ShimmerManual />
        <S.ShimmerManualText />
      </div>

      <div>
        <S.ShimmerManual />
        <S.ShimmerManualText />
      </div>

      <div>
        <S.ShimmerManual />
        <S.ShimmerManualText />
      </div>

      <div>
        <S.ShimmerManual />
        <S.ShimmerManualText />
      </div>

      <div>
        <S.ShimmerManual />
        <S.ShimmerManualText />
      </div>

      <div>
        <S.ShimmerManual />
        <S.ShimmerManualText />
      </div>

      <div>
        <S.ShimmerManual />
        <S.ShimmerManualText />
      </div>
      <div>
        <S.ShimmerManual />
        <S.ShimmerManualText />
      </div>
    </>
  )
}
