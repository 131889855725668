import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import { getTime } from "date-fns";

import { Dropdown, DropdownOption } from "components/DropDown";
import { Input } from "components/Input";
import { Button } from "components/Button";

import { api } from "services/api";

import { MaterialCategoryResponseProps } from "dtos/MaterialCategoriesResponseDTO";
import { MaterialCategoryDropDownProps } from "dtos/MaterialCategoriesDropDownDTO";
import { MaterialTypeDropDownProps } from "dtos/MaterialTypeDropDownDTO";
import { MaterialDropDownProps } from "dtos/MaterialDropDownDTO";

import { useToast } from "hooks/toast";
import { useMaterialsForEditingBudget } from "hooks/budget/materialsForEditingBudget";

import getValidationErrors from "utils/getValidationErrors";

import { MaterialType } from "../Areas/MaterialType";
import { MaterialName } from "../Areas/MaterialName";

import arrowLeft from "assets/icons/arrow-left.svg";

import * as S from "./styles";

type FormCreateMaterialProps = {
  handleListMaterials: () => void;
};

type IdSelectedProps = null | number;

type Errors = {
  [key: string]: string;
};

export function FormCreateMaterial({
  handleListMaterials,
}: FormCreateMaterialProps) {
  const { addToast } = useToast();
  const { materialsForEditingBudget, handleSetMaterialsForEditingBudget } =
    useMaterialsForEditingBudget();

  const [loadingButton, setLoadingButton] = useState(false);

  const [categories, setCategories] = useState<MaterialCategoryDropDownProps[]>(
    []
  );
  const [materialsType, setMaterialsType] = useState<
    MaterialTypeDropDownProps[]
  >([]);
  const [materials, setMaterials] = useState<MaterialDropDownProps[]>([]);

  const [categorySelected, setCategorySelected] =
    useState<IdSelectedProps>(null);
  const [materialTypeSelected, setMaterialTypeSelected] =
    useState<DropdownOption>({} as DropdownOption);
  const [materialSelected, setMaterialSelected] = useState<DropdownOption>(
    {} as DropdownOption
  );

  const [formattedPrice, setFormattedPrice] = useState("");
  const [unMaskedPrice, setUnmaskedPrice] = useState<number>();

  const [hasError, setHasError] = useState<Errors>({} as Errors);
  const [hasErrorOnCategory, setHasErrorOnCategory] = useState("");
  const [hasErrorOnMaterialType, setHasErrorOnMaterialType] = useState("");
  const [hasErrorOnMaterialName, setHasErrorOnMaterialName] = useState("");

  async function getCategories() {
    const response = await api.get("/materials/categories");
    const { categories: categoriesResponse } = response.data;
    const parsedMaterialsCategory = categoriesResponse.map(
      (category: MaterialCategoryResponseProps) => ({
        id: category.id,
        name: category.label,
      })
    );
    setCategories(parsedMaterialsCategory);
  }

  async function getMaterialsAndTypeMaterials() {
    const response = await api.get("/material/form");
    const {
      materialsType: materialsTypeResponse,
      materials: materialsResponse,
    } = response.data;

    setMaterialsType(materialsTypeResponse);
    setMaterials(materialsResponse);
  }

  useEffect(() => {
    getCategories();
    getMaterialsAndTypeMaterials();
  }, []);

  function handleSelectCategory({ id }: DropdownOption) {
    setCategorySelected(id);
    setHasErrorOnCategory("");
  }

  function handleSelectMaterialType({ id, name }: DropdownOption) {
    setMaterialTypeSelected({ id, name: name.trimStart() });
    setMaterialSelected({} as DropdownOption);
    setHasErrorOnMaterialType("");
  }

  function handleSelectMaterialName({ id, name }: DropdownOption) {
    setMaterialSelected({ id, name: name.trimStart() });
    setHasErrorOnMaterialName("");
  }

  function handleChangePrice(text: string) {
    setFormattedPrice(text);
  }

  async function handleCreateMaterial() {
    try {
      if (!categorySelected) {
        setHasErrorOnCategory("Categoria do material é obrigatório");
      } else {
        setHasErrorOnCategory("");
      }

      if (!materialTypeSelected.id) {
        setHasErrorOnMaterialType("Tipo de material é obrigatório");
      } else {
        setHasErrorOnMaterialType("");
      }

      if (!materialSelected.id) {
        setHasErrorOnMaterialName("Material é obrigatório");
      } else {
        setHasErrorOnMaterialName("");
      }

      const schema = () => {
        return Yup.object().shape({
          formattedPrice: Yup.string().required("Valor do item é obrigatório"),
        });
      };

      await schema().validate(
        {
          formattedPrice,
        },
        { abortEarly: false }
      );

      if (
        !categorySelected ||
        !materialTypeSelected.id ||
        !materialSelected.id
      ) {
        return;
      }

      setLoadingButton(true);

      const formattedUnMaskedPrice = Number(unMaskedPrice);

      handleSetMaterialsForEditingBudget([
        ...materialsForEditingBudget,
        {
          id: getTime(new Date()),
          categoryId: categorySelected,
          type: {
            id: materialTypeSelected.id,
            name: materialTypeSelected.name,
          },
          item: {
            id: materialSelected.id,
            name: materialSelected.name,
          },
          price: formattedUnMaskedPrice,
          quantity: 1,
          formattedPrice,
          checked: false,
          total: formattedUnMaskedPrice,
          formattedTotal: formattedPrice,
        },
      ]);

      await api.post("/material", {
        categoryId: categorySelected,
        typeId: materialTypeSelected.id,
        itemId: materialSelected.id,
        price: formattedUnMaskedPrice,
      });

      addToast({
        title: "Sucesso",
        description: "Material criado com sucesso",
        type: "success",
      });

      handleListMaterials();
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        const errors = getValidationErrors(err);
        setHasError(errors);

        return;
      }

      addToast({
        title: "Ops!!",
        description: "Erro ao criar um novo material",
        type: "error",
      });

      // sendError(err);
      setLoadingButton(false);
      handleListMaterials();
    }
  }

  return (
    <>
      <S.Top>
        <S.ButtonClose onClick={() => handleListMaterials()}>
          <img src={arrowLeft} alt="flecha pra esquerda" />
        </S.ButtonClose>

        <p>Criar novo material</p>
      </S.Top>

      <S.Content>
        <S.Wrapper>
          <Dropdown
            label="Categoria*"
            placeholder="Selecione uma categoria"
            options={categories}
            // eslint-disable-next-line react/jsx-no-bind
            onClickedValue={handleSelectCategory}
            hasError={hasErrorOnCategory}
          />
        </S.Wrapper>

        <S.Wrapper>
          <MaterialType
            materialsType={materialsType}
            // eslint-disable-next-line react/jsx-no-bind
            handleSelectMaterialType={handleSelectMaterialType}
            hasError={hasErrorOnMaterialType}
          />
        </S.Wrapper>

        <S.Wrapper>
          <MaterialName
            selectedMaterialTypeId={materialTypeSelected.id}
            materialsType={materialsType}
            materials={materials}
            // eslint-disable-next-line react/jsx-no-bind
            handleSelectMaterialName={handleSelectMaterialName}
            // eslint-disable-next-line react/jsx-no-bind
            handleAddErrorOnMaterialType={setHasErrorOnMaterialType}
            hasError={hasErrorOnMaterialName}
          />
        </S.Wrapper>

        <S.Wrapper>
          <Input
            name="Valor*"
            maskType="money"
            value={formattedPrice}
            hasError={hasError.formattedPrice}
            onChangeCurrency={({ formattedValue, floatValue }) => {
              handleChangePrice(formattedValue);
              setUnmaskedPrice(floatValue!);
            }}
            onFocusClearError={() =>
              setHasError({ ...hasError, formattedPrice: "" })
            }
          />
        </S.Wrapper>

        <S.Wrapper>
          <Button
            onClick={() => handleCreateMaterial()}
            loading={loadingButton}
          >
            Criar material
          </Button>
        </S.Wrapper>
      </S.Content>
    </>
  );
}
