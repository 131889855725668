import styled, { css } from "styled-components";
import media from "styled-media-query";

export const Container = styled.div`
  ${({ theme }) => css`
    background-color: ${theme.colors.strong_background};
    width: 100%;
    max-width: 1400px;
    margin: 0 auto;
    padding: 30px;

    header {
      width: 50%;
      display: flex;
      justify-content: space-between;
      align-items: center;

      ${media.lessThan("large")`
        flex-direction: column;
        align-items: flex-start;

        & > div:last-of-type {
          margin-top: 20px;
          margin-left: 0;
        }
      `}

      h1 {
        font-size: 18px;
        color: ${theme.colors.text_light_87};
        font-weight: 700;
        margin-left: 15px;
      }

      & > div {
        display: flex;
        align-items: center;
      }

      & > div:last-of-type {
        min-width: 350px;
        margin-left: 45px;
      }
    }
  `}
`;

export const Content = styled.div`
  width: 50%;
  margin-top: 20px;
  padding-bottom: 10px;
  margin-left: 45px;
`;

export const Title = styled.label`
  ${({ theme }) => css`
    font-family: ${theme.font.family};
    font-weight: 700;
    font-size: 18px;
    margin-left: 5px;
    color: ${theme.colors.text_light_87};
  `}
`;

export const AddButtonReceipts = styled.button`
  ${({ theme }) => css`
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 20px;
    margin-bottom: 20px;
    width: 100%;
    height: 55px;
    padding: 12px 16px;
    border-radius: 10px;
    background: ${theme.colors.primary};
  `}
`;

export const ButtonText = styled.p`
  ${({ theme }) => css`
    font-size: 12px;
    color: ${theme.colors.strong_background};
    font-weight: 400;
    margin-left: 15px;
  `};
`;

export const Subtitle = styled.p`
  ${({ theme }) => css`
    font-size: 16px;
    color: ${theme.colors.text_light_87};
    font-weight: 700;
    margin-bottom: 20px;
  `};
`;

export const ReceipsContent = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    background-color: ${theme.colors.strong_background};
  `};
`;
export const HeaderReceips = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
`;
export const TextReceips = styled.label`
  ${({ theme }) => css`
    font-size: 10px;
    font-weight: 400;
    color: ${theme.colors.text_light_87};
    text-align: center;
  `};
`;
export const BalanceReceip = styled.label`
  ${({ theme }) => css`
    font-size: 12px;
    font-weight: 700;
    color: ${theme.colors.text_light_87};
    text-align: center;
  `};
`;

export const BalanceClient = styled.label`
  ${({ theme }) => css`
    font-size: 12px;
    color: ${theme.colors.text_light_60};
    text-align: center;
    font-weight: 400;
  `};
`;

export const BalanceClientTypeTransaction = styled.label`
  ${({ theme }) => css`
    font-size: 12px;
    color: ${theme.colors.text_light_87};
    text-align: center;
    font-weight: 400;
  `};
`;

export const BalanceReceipGreen = styled.p`
  ${({ theme }) => css`
    font-size: 12px;
    font-weight: 700;
    color: ${theme.colors.primary};
  `};
`;

export const Balance = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const Line = styled.div`
  ${({ theme }) => css`
    width: 100%;
    height: 1px;
    background-color: ${theme.colors.menu_detail};
    margin-top: 5px;
    margin-bottom: 5px;
  `};
`;

export const ItemReceipt = styled.div`
  ${({ theme }) => css`
    width: 100%;
    height: 70px;
    background-color: ${theme.colors.financial_card};
    padding: 12px 16px;
    border-radius: 10px;
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 10px;
    cursor: pointer;
  `};
`;

export const DetailsRecepitContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const ContainerPagination = styled.div`
  display: flex;
  justify-content: center;
`;
