import { Button } from "components/Button";

import ConfirmationSvg from "assets/icons/permission/confirmationInvite.svg";

import * as S from "./styles";

type ConfirmAlertProps = {
  title: string;
  labelConfirm: string;
  handleConfirm: () => void;
  children: React.ReactNode;
};

export function ConfirmAlert({
  title,
  labelConfirm,
  handleConfirm,
  children,
}: ConfirmAlertProps) {
  return (
    <S.ContainerOverlay>
      <S.BoxModal>
        <S.Title>{title}</S.Title>

        <img
          src={ConfirmationSvg}
          alt="Ícone para representar o e-mail enviado para o prestador"
        />

        {children}

        <Button onClick={() => handleConfirm()}>{labelConfirm}</Button>
      </S.BoxModal>
    </S.ContainerOverlay>
  );
}
