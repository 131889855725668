import React from "react";
import { format } from "date-fns";

import PencilSvg from "assets/icons/pencil.svg";

import * as S from "./styles";
import { UnityDTO } from "dtos/ClientDTO";

interface UnityCardProps {
  unity: UnityDTO | any;
  handleEditUnity: (unity: UnityDTO) => void;
}

export function UnityCard({ unity, handleEditUnity }: UnityCardProps) {
  return (
    <S.Card>
      <S.TextContainer>
        <S.TitleWrapper>
          <S.Title>{unity.name ? unity.name : "Endereço sem nome"}</S.Title>
          <S.AfterTitle>
            ({unity.default ? "Principal" : "Adicional"})
          </S.AfterTitle>
        </S.TitleWrapper>
        <S.TitleWrapper style={{ marginTop: 20 }}>
          <S.GreenCircle />
          <S.CreatedAt>
            Cadastrado em{" "}
            {unity.createdAt
              ? format(new Date(unity.createdAt), "dd/MM/yyyy")
              : format(new Date(), "dd/MM/yyyy")}
          </S.CreatedAt>
        </S.TitleWrapper>

        <S.AddressText>
          {unity.street}, {unity.number} - {unity.district} - {unity.city} -{" "}
          {unity.uf}
        </S.AddressText>
      </S.TextContainer>
      <S.EditUnity
        onClick={() => {
          handleEditUnity(unity);
        }}
      >
        <img src={PencilSvg} alt="Editar endereço" />
      </S.EditUnity>
    </S.Card>
  );
}
