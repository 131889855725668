import React, { ButtonHTMLAttributes } from "react";

import trashSvg from "assets/icons/trash.svg";
import trashDisabled from "assets/icons/trash-disabled.svg";

import * as S from "./style";

type ButtonProps = ButtonHTMLAttributes<HTMLButtonElement>;

export function DeleteButton({ disabled, ...rest }: ButtonProps) {
  return (
    <S.Container disabled={disabled} type="button" {...rest}>
      {disabled ? (
        <img src={trashDisabled} alt="imagem de lixeira para excluir" />
      ) : (
        <img src={trashSvg} alt="imagem de lixeira para excluir" />
      )}
    </S.Container>
  );
}
