import React, { InputHTMLAttributes, useState } from "react";

import * as S from "./styles";

type Props = InputHTMLAttributes<HTMLInputElement> & {
  inputValue: string;
  setInputValue: (value: string) => void;
  handleSelectFinanceType: (type: "money" | "percent") => void;
  addition?: boolean;
};

export function InputWithTooltip({
  inputValue,
  setInputValue,
  handleSelectFinanceType,
  addition = false,
  ...rest
}: Props) {
  const [isVisibleTooltip, setIsVisibleTooltip] = useState(false);

  function handleChooseFinanceType(type: "money" | "percent") {
    setIsVisibleTooltip(false);
    handleSelectFinanceType(type);
  }

  function handleChangeInputValue(value: string) {
    setInputValue(value);

    if (value.length > 0) {
      setIsVisibleTooltip(true);
    }
  }

  return (
    <S.Container>
      <input
        value={inputValue}
        onChange={(e) => handleChangeInputValue(e.target.value)}
        type="number"
        {...rest}
      />

      {!!inputValue && isVisibleTooltip && (
        <S.ToolTip>
          <button
            type="button"
            onClick={() => handleChooseFinanceType("percent")}
          >
            {addition
              ? `${inputValue}% de acréscimo`
              : `${inputValue}% de desconto`}
          </button>

          <div />

          <button
            type="button"
            onClick={() => handleChooseFinanceType("money")}
          >
            {addition
              ? `R$ ${inputValue} de acréscimo`
              : `R$ ${inputValue} de desconto`}
          </button>
        </S.ToolTip>
      )}
    </S.Container>
  );
}
