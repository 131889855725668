import React, { createContext, Dispatch, SetStateAction, useContext, useState } from 'react'
import { ProviderProps } from 'hooks'

export type DropDownProps = {
  id: number
  name: string
  default?: string
}

export type EquipmentsProps = {
  types: DropDownProps[]
  capacities: DropDownProps[]
  default?: string;
} & DropDownProps

export type ServiceProps = {
  equipments: EquipmentsProps[];
  default?: string;
} & DropDownProps

export type CategoriesTreeProps = {
  services: ServiceProps[]
} & DropDownProps

type ServiceFormTreeContextData = {
  categoriesTree: CategoriesTreeProps[]
  handleSetCategoriesTree: (categoriesTree: CategoriesTreeProps[]) => void
  setCategoriesTree: Dispatch<SetStateAction<CategoriesTreeProps[]>>
}

const ServiceFormTreeContext = createContext<ServiceFormTreeContextData>(
  {} as ServiceFormTreeContextData
)

export function useServiceFormTree(): ServiceFormTreeContextData {
  const context = useContext(ServiceFormTreeContext)

  if (!context) {
    throw Error('useServiceFormTree must be used within a ContactProvider')
  }

  return context
}

export const ServiceFormTreeProvider = ({ children }: ProviderProps) => {
  const [categoriesTree, setCategoriesTree] = useState<CategoriesTreeProps[]>(
    []
  )

  function handleSetCategoriesTree(categoriesTreeParam: CategoriesTreeProps[]) {
    setCategoriesTree(categoriesTreeParam)
  }

  return (
    <ServiceFormTreeContext.Provider
      value={{ categoriesTree, handleSetCategoriesTree, setCategoriesTree }}
    >
      {children}
    </ServiceFormTreeContext.Provider>
  )
}
