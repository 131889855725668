import React from "react";
import { MdClose } from "react-icons/md";
import { useTheme } from "styled-components";

import * as S from "./styles";
import LogoSVG from "assets/icons/logo-color.svg";

type ModalProps = {
  isVisible: boolean;
  title: string;
  description: string;
  labelConfirm?: string;
  labelCancel?: string;
  action: "confirm" | "choose";
  handleConfirm: () => void;
  handleCancel?: () => void;
  onCloseModal: () => void;
  zIndex?: number;
};

export function AlertModal({
  isVisible,
  title,
  description,
  labelConfirm = "Confirmar",
  labelCancel = "Fechar",
  action,
  handleConfirm,
  handleCancel,
  onCloseModal,
  zIndex,
}: ModalProps) {
  const theme = useTheme();

  function handleValidationCancel() {
    if (handleCancel) {
      handleCancel();
      return;
    }
    onCloseModal();
  }

  return (
    <S.ContainerOverlay isVisible={isVisible} style={{ zIndex }}>
      <S.BoxModal>
        <S.Header>
          <button type="button" onClick={() => onCloseModal()}>
            <MdClose size={28} color={theme.colors.text} />
          </button>
        </S.Header>

        <img
          src={LogoSVG}
          style={{ margin: "0 auto 20px auto" }}
          width={40}
          height={40}
          alt="logo profiz"
        />

        <S.Main>
          <S.Title>{title}</S.Title>
          <S.Description>{description}</S.Description>
        </S.Main>

        <S.Footer>
          {action === "confirm" ? (
            <S.ButtonConfirm onClick={() => onCloseModal()}>OK</S.ButtonConfirm>
          ) : (
            <S.WrapperButtons>
              <S.ButtonConfirm onClick={() => handleConfirm()}>
                {labelConfirm}
              </S.ButtonConfirm>
              <S.ButtonCancel onClick={handleValidationCancel}>
                {labelCancel}
              </S.ButtonCancel>
            </S.WrapperButtons>
          )}
        </S.Footer>
      </S.BoxModal>
    </S.ContainerOverlay>
  );
}
