import React, { createContext, useContext, useState } from "react";
import { ProviderProps } from "hooks";

type EventsListItemProps = {
  id: number;
  date: string;
  title: string;
  organization: string;
  saved: boolean;
  image?: string;
  modality: "presencial" | "semipresencial" | "online";
  subscriptionLink?: string;
  address?: {
    postalCode?: string;
    street?: string;
    number?: number;
    district?: string;
    city?: string;
    uf?: string;
    complement?: string;
  };
};

type TPeriod = {
  startDate: string;
  endDate: string;
};

type EventItemProps = {
  id: number;
  date: string;
  period: TPeriod[];
  title: string;
  subtitle: string;
  description: string;
  organization: string;
  saved: boolean;
  image?: string;
  modality: "presencial" | "semipresencial" | "online";
  eventLink?: string;
  address?: {
    postalCode?: string;
    street?: string;
    number?: number;
    district?: string;
    city?: string;
    uf?: string;
    complement?: string;
  };
  value: string;
  typeEvent: string;
  hasCertification: string;
  inscription: {
    startDate: string;
    endDate: string;
  };
  subscriptionLink: string;
  contact: {
    number: string;
    email: string;
  };
  hash: string;
};

type EventContextData = {
  allEvents: EventsListItemProps[] | any;
  savedEvents: EventsListItemProps[] | any;

  selectedEvent: EventItemProps | any;
  handleSetSelectedEvent: (event: EventItemProps | any) => void;

  handleSetAllEvents: (events: EventsListItemProps[]) => void;
  handleSetSavedEvents: (events: EventsListItemProps[]) => void;
};

const EventContext = createContext<EventContextData>({} as EventContextData);

export function useEvent(): EventContextData {
  const context = useContext(EventContext);

  if (!context) {
    throw Error("useEvents must be used within a EventProvider");
  }

  return context;
}

export const EventProvider = ({ children }: ProviderProps) => {
  const [allEvents, setAllEvent] = useState<EventsListItemProps[]>();
  const [savedEvents, setSavedEvent] = useState<EventsListItemProps[]>();

  const [selectedEvent, setSelectedEvent] = useState<EventItemProps | any>();

  function handleSetSelectedEvent(event: EventItemProps) {
    setSelectedEvent(event);
  }

  function handleSetAllEvents(eventList: EventsListItemProps[]) {
    setAllEvent(eventList);
  }

  function handleSetSavedEvents(eventList: EventsListItemProps[]) {
    setSavedEvent(eventList);
  }

  return (
    <EventContext.Provider
      value={{
        allEvents,
        savedEvents,
        selectedEvent,
        handleSetAllEvents,
        handleSetSavedEvents,
        handleSetSelectedEvent,
      }}
    >
      {children}
    </EventContext.Provider>
  );
};
