import styled, { css } from "styled-components";

export const PmocContainer = styled.div`
  ${({ theme }) => css`
    background-color: ${theme.colors.background_modal};
    height: 32px;
    width: 100%;
    display: flex;
    align-items: center;
    text-decoration: none;
    padding: 20px 15px;
    margin-bottom: 10px;
    border-radius: 8px;
    transition: all 0.3s ease;
    cursor: pointer;
    p {
      color: ${theme.colors.text_extra_light};
      font-family: ${theme.font.family};
      font-weight: ${theme.font.bold};
      font-size: ${theme.font.sizes.small};
    }
    &:hover {
      opacity: 0.6;
    }
  `}
`;
