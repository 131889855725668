import React from "react";

import { AlertModal } from "components/AlertModal";

type DiscartInformationProps = {
  showModal: boolean;
  handleConfirmButton: () => void;
  handleCancelButton: () => void;
}

export function DiscardInformationModal({
  showModal = false,
  handleConfirmButton,
  handleCancelButton
}: DiscartInformationProps) {
  return (
    <>
      <AlertModal
        isVisible={showModal}
        title="Retornar"
        description="Deseja salvar as alterações antes de retornar?"
        action="choose"
        handleConfirm={handleConfirmButton}
        onCloseModal={handleCancelButton}
        labelConfirm={"Descartar alterações"}
        labelCancel={"Continuar editando"}
      />  
   </>);
}

