import React, { InputHTMLAttributes } from "react";
import { Spinner } from "react-activity";
import { Input } from "components/Input";
import { useTheme } from "styled-components";

import * as S from "./styles";
import "react-activity/dist/Spinner.css";
import "react-activity/dist/Spinner";
type SearchInputProps = InputHTMLAttributes<HTMLInputElement> & {
  title?: string;
  description?: string;
  searchValue: string;
  handleCancel: () => void;
  loadingInput?: boolean;
};

export function SearchInput({
  title,
  description,
  searchValue,
  handleCancel,
  loadingInput = false,
  ...rest
}: SearchInputProps) {
  const theme = useTheme();

  return (
    <S.Container>
      {!!title && <h4>{title}</h4>}

      <div>
        <div>
          <Input type="search" value={searchValue} {...rest} />

          {loadingInput ? (
            <S.SearchButton>
              <Spinner style={{width: 23}} color={theme.colors.primary} />
            </S.SearchButton>

          ) : (
            <S.SearchButton>
              <S.SearchIcon />
            </S.SearchButton>
          )}
        </div>

        {!!searchValue.length && !loadingInput && (
          <S.CancelButton onClick={() => handleCancel()}>
            Cancelar
          </S.CancelButton>
        )}
      </div>

      {!!description && <p>{description}</p>}
    </S.Container>
  );
}
