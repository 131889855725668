import { useEffect, useState } from "react";
import { useParams, useHistory } from "react-router";
import { format, parseISO } from "date-fns";

import { useToast } from "hooks/toast";
import { useAccount } from "hooks/permission/account";
import {
  ServiceToScheduleProps,
  useSelectedServiceToSchedule,
} from "hooks/selectedServiceToSchedule";

import { ContentBox } from "components/ContentBox";
import { CalendarToScheduleService } from "components/CalendarToScheduleService";
import { Button } from "components/Button";
import { ModalRight } from "components/ModalRight";
import { ContentLeft } from "templates/ContentLeft";
import { AlertModal } from "components/AlertModal";
import { ArrowButton } from "components/ArrowButton";
import { LoadingProfiz } from "components/LoadingProfiz";
import { UserExecutionCard } from "components/UserExecutionCard";

import { ServiceDTO } from "../../../dtos/ServiceDTO";
import { UserExecutionProps } from "dtos/ServiceOrderDetailDTO";

import ScheduledImage from "./scheduleImage.svg";
import scheduleImage from "assets/schedule.svg";

import apiv2 from "services/apiv2";

import * as S from "./styles";

type ServicesParam = {
  idBudgetService: number;
  quantity: number;
  scheduleDate: string;
  formatedDate?: string;
  status: string;
  userExecution?: UserExecutionProps;
} & ServiceDTO;

type ServiceOrderResponse = {
  services: ServicesParam[];
};

type RouteParams = {
  id: string;
  idPmoc: string;
  year: string;
  month: string;
};

type DateTimeProps = {
  time: string;
  date: string;
};

type ScheduledService = {
  id: number;
  date: string;
  formatedDate?: string;
};

export function SchedulingServicePmoc() {
  const { id, idPmoc, year, month } = useParams<RouteParams>();
  const { addToast } = useToast();
  const { whoami } = useAccount();
  const history = useHistory();
  const {
    handleSetSelectedServiceToSchedule,
    handleClearSelectedMemberIds,
    handleSelectedMemberIdByService,
    selectedMemberIdsByService,
  } = useSelectedServiceToSchedule();

  const [serviceOrder, setServiceOrder] = useState<ServiceOrderResponse>(
    {} as ServiceOrderResponse
  );
  const [isLoading, setIsLoading] = useState(true);

  const [selectedServiceToSchedule, setSelectedServiceToSchedule] =
    useState<ServicesParam>({} as ServicesParam);

  const [openSchedule, setOpenSchedule] = useState(false);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [scheduleservicesSeparately, setScheduleservicesSeparately] =
    useState(false);

  const [justOneDateToScheduleAllService, setJustOneDateToScheduleAllService] =
    useState<DateTimeProps | null>(null);

  const [
    servicesScheduledWithDifferentDates,
    setServicesScheduledWithDifferentDates,
  ] = useState<ScheduledService[]>([]);

  const [showModalToConfirmSchedule, setShowModalToConfirmSchedule] =
    useState(false);

  const [loadingButton, setLoadingButton] = useState(false);
  const [isVisibleModal, setIsVisibleModal] = useState(false);
  const [serviceScheduled, setServiceScheduled] = useState(false);

  useEffect(() => {
    getServiceOrderDetail();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  async function getServiceOrderDetail() {
    try {
      const response = await apiv2.get(`budgets/service-order/${id}`);

      const filteredData = response.data.services.filter(
        (item: ServicesParam) => {
          return item;
        }
      );

      const formatedResponse = {
        ...response.data,
        services: filteredData.map((currentService: ServicesParam) => {
          const { idBudgetService, scheduleDate, userExecution } = currentService;
          setServiceScheduled(!!scheduleDate);
          return {
            ...currentService.service,
            idBudgetService,
            scheduleDate,
            userExecution
          };
        }),
      };

      setServiceOrder(formatedResponse);
    } catch (err) {
      addToast({
        title: "Ops...",
        description: "Erro ao buscar detalhes da ordem de serviço",
        type: "error",
      });
    } finally {
      setIsLoading(false);
    }
  }

  function handleOpenModalToScheduleTheService(service: ServicesParam) {
    handleOpenCalendarModalToSetDataTime();
    setSelectedServiceToSchedule(service);

    const serviceSomeData = {
      idBudgetService: service.idBudgetService,
      serviceData: {
        id: service.id,
        name: service.service.name,
        runtime: !!service?.runtime?.time
          ? service.runtime
          : { time: "2", extension: "Horas" },
      },
      serviceOrderId: Number(id),
    } as ServiceToScheduleProps;

    handleSetSelectedServiceToSchedule(serviceSomeData);
    handleSelectedMemberIdByService({
      [service.idBudgetService]: {
        id: whoami?.id || 0,
        name: whoami?.name || "",
        photo: whoami?.photo || "",
      }
    });
  }

  function isToScheduleTheSameDateToAllService(dateTime: DateTimeProps) {
    if (!scheduleservicesSeparately) {
      setJustOneDateToScheduleAllService(dateTime);

      const addDateIntoService = serviceOrder.services.map((service) => ({
        ...service,
        formatedDate: `${format(parseISO(dateTime.date), "dd/MM/yyyy")} - ${
          dateTime.time
          }`,
      }));

      const servicesWithDate = {
        ...serviceOrder,
        services: addDateIntoService,
      };

      setServiceOrder(servicesWithDate);
      handleOpenCalendarModalToSetDataTime();

      return;
    }

    setDifferentDatesFromEachService(dateTime);
    handleOpenCalendarModalToSetDataTime();
  }

  function handleSetServiceWhenOpenModalCalendar() {
    handleOpenCalendarModalToSetDataTime();

    const getServiceLongerRuntime = serviceOrder.services.reduce(
      (prev, curr) => {
        if (prev.runtime?.time! > curr.runtime?.time!) {
          return prev;
        }

        return curr;
      },
      {} as ServicesParam
    );

    const serviceWithLongerRuntime: ServiceToScheduleProps = {
      idBudgetService: getServiceLongerRuntime.idBudgetService,
      status: "scheduled",
      serviceData: {
        id: getServiceLongerRuntime.id,
        name: getServiceLongerRuntime.service.name,
        runtime: {
          time: Number(getServiceLongerRuntime.runtime?.time),
          extension: getServiceLongerRuntime.runtime?.extension!,
        },
      },
      serviceOrderId: Number(id),
    };

    handleSetSelectedServiceToSchedule(serviceWithLongerRuntime);
  }

  function handleOpenCalendarModalToSetDataTime() {
    setOpenSchedule((prevState) => !prevState);
  }

  function setDifferentDatesFromEachService(dateTime: DateTimeProps) {
    setServicesScheduledWithDifferentDates((oldDates) => ({
      ...oldDates,
      [selectedServiceToSchedule.idBudgetService]: {
        id: selectedServiceToSchedule.id,
        idBudgetService: selectedServiceToSchedule.idBudgetService,
        date: `${dateTime.date} ${dateTime.time}:00`,
        formatedDate: `${format(parseISO(dateTime.date), "dd/MM/yyyy")} - ${
          dateTime.time
        }`,
        userIdExecution:
          selectedMemberIdsByService[selectedServiceToSchedule.idBudgetService].id,
      },
    }));
  }

  function validateSchedule() {
    if (
      scheduleservicesSeparately &&
      Object.values(servicesScheduledWithDifferentDates).length > 1
    ) {
      const dates = Object.values(servicesScheduledWithDifferentDates).map(
        (item, i) => ({
          index: i,
          date: item.date,
        })
      );

      const validation = dates.map((date, i) => {
        const filteredDates = dates.filter((date) => date.index !== i);

        const formatedDates = filteredDates.map((item) => item.date);
        if (formatedDates.includes(date.date)) {
          setIsVisibleModal(true);
          return "modalVisible";
        }

        return "notVisible";
      });

      if (!validation.includes("modalVisible")) {
        verifyIfToScheduleTheSameDateToAllServicesOrSeparately();
      }
    } else {
      handleShowModalToConfirmSchedule();
    }
  }

  async function handleShowModalToConfirmSchedule() {
    setShowModalToConfirmSchedule(true);
  }

  async function verifyIfToScheduleTheSameDateToAllServicesOrSeparately() {
    setShowModalToConfirmSchedule(false);

    if (scheduleservicesSeparately) {
      parsedDateToScheduleServicesSeparately();
      return;
    }

    parseDateToScheduleAllServiceInTheSameDate();
  }

  function parsedDateToScheduleServicesSeparately() {
    const parsedServices = Object.values(servicesScheduledWithDifferentDates);

    if (parsedServices.length === 0) {
      addToast({
        title: "Ops",
        description: "Preencha as datas corretamente para todos os serviços",
        type: "info",
      });
      return;
    }

    saveSchedule(parsedServices);
  }

  function parseDateToScheduleAllServiceInTheSameDate() {
    if (!justOneDateToScheduleAllService) {
      addToast({
        title: "Ops",
        description: "Preencha a data dos serviços para o agendamento",
        type: "info",
      });
      return;
    }

    const mappedService = serviceOrder.services.map((service) => ({
      id: service.id,
      idBudgetService: service.idBudgetService,
      date: `${justOneDateToScheduleAllService?.date} ${justOneDateToScheduleAllService?.time}:00`,
      userIdExecution: Object.values(selectedMemberIdsByService)[0].id,
    }));

    saveSchedule(mappedService);
  }

  async function saveSchedule(sevicesToSchedule: ScheduledService[]) {
    setLoadingButton(true);
    try {
      serviceScheduled
        ? await apiv2.put(`budgets/service-order/${id}/reschedule`, {
            services: sevicesToSchedule,
            originUpdate: "web",
          })
        : await apiv2.post(`budgets/service-order/${id}/schedule`, {
            services: sevicesToSchedule,
            originUpdate: "web",
          });

      addToast({
        title: "Sucesso",
        description: "Agendamento concluído com sucesso",
        type: "success",
      });

      setLoadingButton(false);
      handleClearSelectedMemberIds();
      history.replace(
        `/pmoc/execution/order-service/scheduled/${id}/${idPmoc}/${year}/${month}`
      );
    } catch (err) {
      addToast({
        title: "Ops",
        description: "Algo deu errado ao agendar os serviços",
        type: "error",
      });

      setLoadingButton(false);
    }
  }

  function handleCancel() {
    handleOpenCalendarModalToSetDataTime();
  }

  function formatedScheduledDate(date: string) {
    const dateSchedule = new Date(date);
    return format(dateSchedule, "dd/MM/yyyy HH:mm");
  }

  return (
    <S.Container>
      {isLoading && <LoadingProfiz isVisible={isLoading} />}
      <AlertModal
        isVisible={isVisibleModal}
        title="Conflito de agenda!"
        description={
          "O horário selecionado está em conflito com outro agendamento, deseja continuar mesmo assim?"
        }
        action="choose"
        labelCancel="Corrigir"
        labelConfirm="Continuar"
        handleConfirm={() => {
          setIsVisibleModal(false);
          verifyIfToScheduleTheSameDateToAllServicesOrSeparately();
        }}
        onCloseModal={() => {
          setIsVisibleModal(false);
        }}
      />

      <ModalRight
        isOpen={openSchedule}
        handleToggleOpen={handleSetServiceWhenOpenModalCalendar}
      >
        <CalendarToScheduleService
          currentScheduleDate={
            justOneDateToScheduleAllService
              ? `${justOneDateToScheduleAllService?.date} ${justOneDateToScheduleAllService?.time}`
              : ""
          }
          handleConfirmDateTime={isToScheduleTheSameDateToAllService}
          handleCancel={handleCancel}
          showDeleteOption={serviceScheduled}
          onDeleteServiceSchedule={() => {
            handleOpenCalendarModalToSetDataTime();
            getServiceOrderDetail();
          }}
        />
      </ModalRight>

      <AlertModal
        isVisible={showModalToConfirmSchedule}
        title="Confirmação de agendamento"
        description="Deseja confirmar o agendamento?"
        action="choose"
        handleConfirm={() =>
          verifyIfToScheduleTheSameDateToAllServicesOrSeparately()
        }
        onCloseModal={() => setShowModalToConfirmSchedule(false)}
      />

      <ContentLeft>
        <header>
          <div>
            <ArrowButton
              handleFunction={() => {
                serviceScheduled
                  ? history.replace(
                      `/pmoc/execution/order-service/scheduled/${id}/${idPmoc}/${year}/${month}`
                    )
                  : history.replace(
                      `/pmoc/execution/order-service/unscheduled/${id}/${idPmoc}/${year}/${month}`
                    );
              }}
            />
          </div>

          <h1>Agendamento</h1>
        </header>

        <S.Content>
          <S.ContentImage>
            <img src={ScheduledImage} alt="Homem segurando um calendario" />
          </S.ContentImage>

          <S.Wrapper>
            <S.Label>
              Vamos agendar os serviços contidos nessa ordem de serviço.
            </S.Label>

            {!scheduleservicesSeparately && (
              <S.SchedulingButton
                onClick={() => {
                  if (!justOneDateToScheduleAllService) {
                    let scheduledDate = serviceOrder.services.map((service) => {
                      return !!service?.scheduleDate && service.scheduleDate;
                    })[0];

                    if (!!scheduledDate) {
                      let splitDate = scheduledDate.split(" ");
                      setJustOneDateToScheduleAllService({
                        date: splitDate[0],
                        time: splitDate[1],
                      });

                      handleClearSelectedMemberIds();
                      handleSetServiceWhenOpenModalCalendar();
                      return;
                    }
                    let actualDate = new Date();
                    let objDate = {
                      date: format(actualDate, "yyyy-MM-dd"),
                      time: "",
                    };
                    setJustOneDateToScheduleAllService(objDate);
                    handleClearSelectedMemberIds();
                    handleSetServiceWhenOpenModalCalendar();
                    return;
                  }
                  handleClearSelectedMemberIds();
                  handleSetServiceWhenOpenModalCalendar();
                }}
              >
                <img src={scheduleImage} alt="imagem de um calendario" />
              </S.SchedulingButton>
            )}
          </S.Wrapper>

          {!isLoading && (
            <S.ContentListService>
              {serviceOrder.services.map((service) => (
                <S.WrapperService key={service.idBudgetService}>
                  <ContentBox title="Serviço">
                    <S.ContentBoxTitle>
                      {service.service.name}
                    </S.ContentBoxTitle>

                    {!!servicesScheduledWithDifferentDates[
                      service.idBudgetService
                    ] &&
                      scheduleservicesSeparately && (
                        <S.ContentBoxTitle>
                          {
                            servicesScheduledWithDifferentDates[
                              service.idBudgetService
                            ].formatedDate
                          }
                        </S.ContentBoxTitle>
                      )}

                    {!!service.formatedDate && !scheduleservicesSeparately && (
                      <S.ContentBoxTitle>
                        {service.formatedDate}
                      </S.ContentBoxTitle>
                    )}

                    {!!service?.scheduleDate && !!!service.formatedDate && (
                      <S.ContentBoxTitle>
                        <strong>
                          Agendado para:{" "}
                          {formatedScheduledDate(service?.scheduleDate)}
                        </strong>
                      </S.ContentBoxTitle>
                    )}

                    <S.ServiceInfoBox>
                      {!!service?.equipment?.name && (
                        <span> {service.equipment.name} </span>
                      )}

                      {!!service?.equipmentType?.name && (
                        <span> {service.equipmentType.name} </span>
                      )}

                      {!!service?.capacity?.name && (
                        <span> {service.capacity.name} </span>
                      )}

                      {!!service?.brand && (
                        <span> | Marca: {service.brand} </span>
                      )}
                      {!!service?.runtime?.time && (
                        <span> | Execução: {service?.runtime?.time} </span>
                      )}

                      {!!service?.runtime?.time &&
                        !!service?.runtime?.extension && (
                          <span>{service?.runtime?.extension}</span>
                        )}

                      {!!service?.warranty?.time && (
                        <span>
                          {" "}
                          Garantia: {service.warranty.time}{" "}
                          {service.warranty.extension}{" "}
                        </span>
                      )}

                      {!!service?.userExecution?.name && Object.keys(selectedMemberIdsByService).length <= 0 &&
                        <UserExecutionCard userExecution={service.userExecution} />
                      }

                      {Object.keys(selectedMemberIdsByService).length > 0 && !!service.formatedDate && !scheduleservicesSeparately &&
                        <UserExecutionCard userExecution={Object.values(selectedMemberIdsByService)[0]} />
                      }
                    </S.ServiceInfoBox>
                  </ContentBox>
                  {!!scheduleservicesSeparately && (
                    <S.SchedulingButton
                      onClick={() => {
                        handleOpenModalToScheduleTheService(service);
                      }}
                    >
                      <img src={scheduleImage} alt="imagem de um calendario" />
                    </S.SchedulingButton>
                  )}
                </S.WrapperService>
              ))}
            </S.ContentListService>
          )}

          <Button
            loading={loadingButton}
            onClick={() => {
              validateSchedule();
            }}
          >
            Salvar
          </Button>
        </S.Content>
      </ContentLeft>
    </S.Container>
  );
}
