import React from "react";

import { ArrowButton } from "components/ArrowButton";

import { EnvironmentDTO } from "dtos/PmocEnvironmentDTO";

import EquipmentIcon from "assets/icons/equipment-icon.svg";

import * as S from "./styles";

type DetailEquipmentProps = {
  handleBackButton: () => void;
  environment: EnvironmentDTO;
};

export function DetailEnvironment({
  handleBackButton,
  environment,
}: DetailEquipmentProps) {
  return (
    <S.Content>
      <S.Header>
        <ArrowButton handleFunction={handleBackButton} />
      </S.Header>

      <S.Title>{environment.name}</S.Title>
      <img src={EquipmentIcon} alt="equipment" />

      <S.CardTitle>Ambiente</S.CardTitle>
      <S.Card>
        <S.Negrito>{environment.name}</S.Negrito>
        <S.Description>
          {environment.location ? environment.location : ""}
          {environment.airConditionedArea && environment.location ? " | " : ""}
          {environment.airConditionedArea
            ? environment.airConditionedArea + " m²"
            : ""}
          {environment.airConditionedArea &&
          environment.numberOccupants &&
          environment.numberOccupants?.fixed
            ? " | "
            : ""}

          {environment.numberOccupants && environment.numberOccupants?.fixed
            ? environment.numberOccupants?.fixed + " pessoa(s) fixa(s)"
            : ""}
        </S.Description>
        <S.Description>
          {environment.numberOccupants && environment.numberOccupants?.variable
            ? environment.numberOccupants?.variable +
              ` pessoa ${
                environment.numberOccupants?.variable > 1
                  ? "pessoa variável"
                  : "pessoas variáveis"
              }`
            : ""}
          {environment.numberOccupants &&
          environment.numberOccupants?.variable &&
          environment.thermalLoad
            ? " | "
            : ""}

          {environment.thermalLoad ? environment.thermalLoad : ""}
        </S.Description>
      </S.Card>

      {environment.equipments && environment.equipments.length > 0 && (
        <>
          <S.CardTitle>Equipamentos</S.CardTitle>
          {environment.equipments?.map((equipment) => (
            <S.Card key={equipment.id}>
              <S.Negrito>{equipment.tagEquipment}</S.Negrito>
              <S.Description>
                {equipment.equipment ? equipment.equipment.name + " " : ""}
                {equipment.equipmentType
                  ? equipment.equipmentType.name + " "
                  : ""}
                {equipment.brand ? equipment.brand + " " : ""}
                {"\n"}
                {equipment.capacity ? equipment.capacity.name : ""}
              </S.Description>
              <S.Description>
                {equipment.qtdServices
                  ? ` ${equipment.componentName ? " | " : ""} ${
                      equipment.qtdServices
                    } serviço(s) vinculado(s)`
                  : ""}
              </S.Description>
            </S.Card>
          ))}
        </>
      )}
    </S.Content>
  );
}
