import styled, { css } from "styled-components";

export const Card = styled.button`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    position: relative;
    width: 110px;
    height: 110px;
    background-color: ${theme.colors.financial_card};
    border-radius: 10px;
    padding: 15px;

    &:hover {
      background-color: ${theme.colors.background_light};
    }

    img {
      width: 40px;
      height: 40px;
      margin-bottom: 10px;
    }

    p {
      font-family: ${theme.font.family};
      font-size: ${theme.font.sizes.xsmall};
      font-weight: ${theme.font.bold};
      color: rgba(255, 255, 255, 0.87);
      line-height: 15px;
      letter-spacing: 0.26px;
      text-align: left;
    }
  `}
`;

export const IconWrapper = styled.div`
  position: absolute;
  right: 15px;

  img {
    width: 15px;
    height: 18px;
  }
`;
