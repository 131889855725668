import styled, { css } from "styled-components";

export const Container = styled.div``;

export const EquipmentsWrapper = styled.div`
  margin-bottom: 25px;
`;

export const TextTop = styled.div`
  ${({ theme }) => css`
    font-family: ${theme.font.normal};
    font-style: normal;
    font-size: 12px;
    line-height: 15px;
    color: rgba(255, 255, 255, 0.87);
    margin-top: 25px;
  `}
`;

export const Title = styled.h1`
  ${({ theme }) => css`
    margin-top: 25px;
    margin-bottom: 5px;
    font-family: ${theme.font.family};
    font-style: normal;
    font-weight: ${theme.font.bold};
    font-size: 12px;
    line-height: 15px;
    color: rgba(255, 255, 255, 0.87);
  `}
`;

export const CardServiceOrder = styled.button`
  ${({ theme }) => css`
    width: 100%;
    height: 65px;
    background: ${theme.colors.background_light};
    border-radius: 10px;
    display: flex;
    align-items: center;
    padding: 10px;
    margin-bottom: 8px;
  `}
`;

export const CardServiceOrderContent = styled.div`
  display: flex;
  flex-direction: column;
`;

export const CardServiceOrderHeader = styled.div`
  display: flex;
  flex-direction: row;
`;

export const CardServiceOrderTitle = styled.h1`
  ${({ theme }) => css`
    font-family: ${theme.font.family};
    font-style: normal;
    font-weight: ${theme.font.bold};
    font-size: 12px;
    line-height: 15px;
    color: #ffffffde;
  `}
`;

export const pmocPeriodType = styled.h1`
  ${({ theme }) => css`
    margin-left: 7px;
    font-family: ${theme.font.family};
    font-style: normal;
    font-weight: ${theme.font.normal};
    font-size: 12px;
    line-height: 15px;
    color: rgba(255, 255, 255, 0.6);
  `}
`;

export const ServiceOrderStatus = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;

    margin-top: 7px;

    span {
      font-family: ${theme.font.family};
      font-style: normal;
      font-weight: ${theme.font.normal};
      font-size: 12px;
      line-height: 15px;
      color: rgba(255, 255, 255, 0.87);
    }
  `}
`;

export const StatusBadge = styled.div`
  ${({ theme }) => css`
    height: 7px;
    width: 7px;
    border-radius: 50%;
    margin-right: 7px;
    background-color: ${theme.colors.primary};
  `}
`;

export const EmptyList = styled.div`
  ${({ theme }) => css`
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 100px;

    h1 {
      font-family: ${theme.font.family};
      font-style: normal;
      font-weight: ${theme.font.normal};
      font-size: 16px;
      line-height: 15px;
      color: rgba(255, 255, 255, 0.6);
    }
  `}
`;
