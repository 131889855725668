import styled, { css } from "styled-components";

type ButtonProps = {
  isSelected: boolean;
  hasError: boolean;
};

export const Container = styled.div`
  ${({ theme }) => css`
    p {
      font-size: ${theme.font.sizes.xsmall};
      margin-bottom: 5px;
      color: ${theme.colors.title};
    }
  `}
`;

export const Button = styled.button<ButtonProps>`
  ${({ theme, isSelected, hasError }) => css`
    width: 100%;
    height: 45px;
    border-radius: 12px;
    background: ${theme.colors.menu_background};
    text-align: left;
    padding: 16px;
    color: ${theme.colors.text_light};
    border: 1px solid
      ${isSelected
        ? theme.colors.primary
        : hasError
        ? theme.colors.error
        : theme.colors.menu_background};
  `}
`;

export const ErrorText = styled.span`
  ${({ theme }) => css`
    font-size: 12px;
    color: ${theme.colors.error};
    padding-top: 5px;
    display: flex;
  `}
`;
