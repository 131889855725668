import React, { createContext, useContext, useState } from "react";
import { DropdownOption } from "components/DropDown";
import { ChecklistItemList } from "pages/fieldService/ServiceChecklist/types";
import { ProviderProps } from "hooks";

type ServiceDropDownContextData = {
  selectedCategoryId: number;
  selectedService: DropdownOption;
  selectedEquipment: DropdownOption;
  selectedEquipmentType: DropdownOption;
  selectedCapacity: DropdownOption;
  handleCategoryId: (categoryId: number) => void;
  handleService: ({ id, name }: DropdownOption) => void;
  handleEquipment: ({ id, name }: DropdownOption) => void;
  handleEquipmentType: ({ id, name }: DropdownOption) => void;
  handleCapacity: ({ id, name }: DropdownOption) => void;
  isEqualService: boolean;
  handleSetEqualService: (value: boolean) => void;
  serviceChecklistCxt: ChecklistItemList[];
  handleSelectedChecklists: (checklists: ChecklistItemList[]) => void;
  reset: () => void;
  frequencyIdDropdown: DropdownOption
  handleSetFrequencyIdDropdown: ({ id, name }: DropdownOption) => void;
  runTime: string;
  handleSetRunTime: (runTime: string) => void;
  runTimeExtension: ExtensionProps;
  handleSetRunTimeExtension: (runTimeExtension: ExtensionProps) => void;
  warrantyTime: string;
  handleSetWarrantyTime: (warrantyTime: string) => void;
  warrantyExtension: ExtensionProps;
  handleSetWarrantyExtension: (runTimeExtension: ExtensionProps) => void;
  observationText: string;
  handleSetObservationText: (observationText: string) => void;
  priceService: number | string;
  handleSetPriceService: (priceService: number | string) => void;
  unMaskedPrice: number;
  handleSetUnmaskedPrice: (unmaskedPrice: number) => void;
};

type ExtensionProps = {
  id: number;
  name: string;
};

const ServiceDropDownContext = createContext<ServiceDropDownContextData>(
  {} as ServiceDropDownContextData
);

export function useServiceDropDown(): ServiceDropDownContextData {
  const context = useContext(ServiceDropDownContext);

  if (!context) {
    throw Error(
      "useServiceDropDown must be used within a ServiceDropDownProvider"
    );
  }

  return context;
}

export const ServiceDropDownProvider = ({ children }: ProviderProps) => {
  const [selectedCategoryId, setSelectedCategoryId] = useState(0);
  const [selectedService, setSelectedService] = useState<DropdownOption>(
    {} as DropdownOption
  );
  const [selectedEquipment, setSelectedEquipment] = useState<DropdownOption>(
    {} as DropdownOption
  );
  const [selectedEquipmentType, setSelectedEquipmentType] =
    useState<DropdownOption>({} as DropdownOption);
  const [selectedCapacity, setSelectedCapacity] = useState<DropdownOption>(
    {} as DropdownOption
  );

  const [serviceChecklistCxt, setChecklist] = useState<ChecklistItemList[]>(
    {} as ChecklistItemList[]
  );

  const [isEqualService, setIsEqualService] = useState(true);

  const [frequencyIdDropdown, setFrequencyIdDropdown] =
    useState<DropdownOption>({} as DropdownOption);

  const [runTime, setRunTime] = useState("");

  const [runTimeExtension, setRunTimeExtension] = useState(
    {} as ExtensionProps
  );

  const [warrantyTime, setWarrantyTime] = useState("");

  const [observationText, setObservationText] = useState("");

  const [warrantyExtension, setWarrantyExtension] = useState(
    {} as ExtensionProps
  );

  const [priceService, setPriceService] = useState<number | string>(0);

  const [unMaskedPrice, setUnmaskedPrice] = useState(0);

  function handleSetEqualService(value: boolean) {
    setIsEqualService(value);
  }

  function handleSelectedChecklists(checklists: ChecklistItemList[]) {
    setChecklist(checklists);
  }

  function handleCategoryId(categoryId: number) {
    setSelectedCategoryId(categoryId);
  }

  function handleService({ id, name }: DropdownOption) {
    setSelectedService({ id, name });
  }

  function handleEquipment({ id, name }: DropdownOption) {
    setSelectedEquipment({ id, name });
  }

  function handleEquipmentType({ id, name }: DropdownOption) {
    setSelectedEquipmentType({ id, name });
  }

  function handleCapacity({ id, name }: DropdownOption) {
    setSelectedCapacity({ id, name });
  }

  function handleSetFrequencyIdDropdown({ id, name }: DropdownOption) {
    setFrequencyIdDropdown({ id, name });
  }

  function handleSetRunTime(runtime: string) {
    setRunTime(runtime);
  }

  function handleSetRunTimeExtension(runTimeExtension: ExtensionProps) {
    setRunTimeExtension(runTimeExtension);
  }

  function handleSetWarrantyExtension(warrantyExtension: ExtensionProps) {
    setWarrantyExtension(warrantyExtension);
  }

  function handleSetWarrantyTime(warrantyTime: string) {
    setWarrantyTime(warrantyTime);
  }

  function handleSetObservationText(observationText: string) {
    setObservationText(observationText);
  }

  function handleSetPriceService(priceService: number | string) {
    setPriceService(priceService);
  }

  function handleSetUnmaskedPrice(unmaskedPrice: number) {
    setUnmaskedPrice(unmaskedPrice);
  }

  function reset() {
    setSelectedCategoryId(0);
    setSelectedService({} as DropdownOption);
    setSelectedEquipment({} as DropdownOption);
    setSelectedEquipmentType({} as DropdownOption);
    setSelectedCapacity({} as DropdownOption);
    setChecklist({} as ChecklistItemList[]);
    handleSetFrequencyIdDropdown({} as DropdownOption);
    handleSetRunTime("");
    setRunTimeExtension({} as ExtensionProps);
    setWarrantyTime("");
    setWarrantyExtension({} as ExtensionProps);
    setIsEqualService(true);
    handleSetObservationText("");
    handleSetPriceService("");
  }

  return (
    <ServiceDropDownContext.Provider
      value={{
        selectedCategoryId,
        selectedService,
        selectedEquipment,
        selectedEquipmentType,
        selectedCapacity,
        handleCategoryId,
        handleService,
        isEqualService,
        handleSetEqualService,
        handleEquipment,
        handleEquipmentType,
        handleCapacity,
        serviceChecklistCxt,
        handleSelectedChecklists,
        frequencyIdDropdown,
        handleSetFrequencyIdDropdown,
        runTime,
        handleSetRunTime,
        runTimeExtension,
        handleSetRunTimeExtension,
        warrantyTime,
        handleSetWarrantyTime,
        observationText,
        handleSetObservationText,
        warrantyExtension,
        handleSetWarrantyExtension,
        priceService,
        handleSetPriceService,
        unMaskedPrice,
        handleSetUnmaskedPrice,
        reset,
      }}
    >
      {children}
    </ServiceDropDownContext.Provider>
  );
};
