import React, { useEffect, useState } from "react";
import { parseISO } from "date-fns";

import ProfizLogo from "assets/icons/profizLogoEventsPage.svg";
import ButtonClose from "assets/icons/buttonClose.svg";

import { EventItemProps, TPeriod } from "../EventsCalendar/types";

import { LoadingProfiz } from "components/LoadingProfiz";

import { api } from "services/api";

import { useToast } from "hooks/toast";
import { useHistory } from "react-router";

import { Button } from "components/Button";

import phoneIcon from "assets/icons/events-icons/phoneIcon.svg";
import emailIcon from "assets/icons/events-icons/emailIcon.svg";
import shareIcon from "assets/icons/events-icons/shareIcon.svg";

import * as S from "./styles";
import { isMobile } from "react-device-detect";

const getMonthName = (idx: number) => {
  const names = [
    "Jan.",
    "Fev.",
    "Mar.",
    "Abr.",
    "Mai.",
    "Jun.",
    "Jul.",
    "Ago.",
    "Set.",
    "Out.",
    "Nov.",
    "Dez.",
  ];
  return names[idx];
};

const getDayName = (idx: number) => {
  const names = ["Sab.", "Seg.", "Ter.", "Qua.", "Qui.", "Sex.", "Dom."];
  return names[idx];
};

export function EventPublicPage() {
  const { addToast } = useToast();
  const history = useHistory();
  const eventHash = history.location.pathname.split("/")[3];

  const [isLoading, setIsLoading] = useState(true);
  const [eventDetail, setEventDetail] = useState<EventItemProps>();
  const [mobileWarning, setMobileWarning] = useState(true);

  const [textCopied, setTextCopied] = useState(false);
  const [isHovering, setIsHovering] = useState(false);
  const [toolTip, setToolTip] = useState(false);

  const formatSortDate = (listEvents: Array<TPeriod>) => {
    return listEvents.sort(function (elementA, elementB) {
      return elementA.startDate < elementB.startDate ? -1 : 1;
    });
  };

  useEffect(() => {
    async function fetchEventData() {
      try {
        const { data } = await api(`/events/public/${eventHash}`);

        const formatDateEvent =
          data.periods.length > 1 ? formatSortDate(data.periods) : data.periods;
        setEventDetail({
          ...data,
          periods: formatDateEvent,
        });
      } catch (error) {
        addToast({
          title: "Ops!!",
          description: "Ocorreu um erro ao listar o evento",
          type: "error",
        });
      } finally {
        setIsLoading(false);
      }
    }

    fetchEventData();
  }, []); // eslint-disable-line

  const formatedAddressFunction = (event: EventItemProps) => {
    const streetNumberFormatted = !!event.address?.street
      ? !!event.address.number
        ? `${event.address.street}, ${event.address.number}`
        : `${event.address.street}`
      : "";

    const cityUfFormatted = !!event.address?.city
      ? !!event.address.uf
        ? `${event.address.city} - ${event.address.uf}`
        : `${event.address.city}`
      : "";

    const postalCodeFormatted = !!event.address?.postalCode
      ? `CEP: ${event.address.postalCode}`
      : "";

    const addressFormatted = !!event.address?.district
      ? `${streetNumberFormatted} - ${event.address.district} - ${cityUfFormatted}, ${postalCodeFormatted}`
      : `${streetNumberFormatted} ${cityUfFormatted} ${postalCodeFormatted}`;

    return addressFormatted;
  };

  function shareEvent() {
    const eventLink = `${
      window.location.origin
    }/events/public/${eventHash.toString()}`;
    navigator.clipboard.writeText(eventLink);

    setTextCopied(true);
  }

  useEffect(() => {
    if (textCopied && isHovering) {
      setToolTip(true);
    }

    return () => {
      setToolTip(false);
    };
  }, [isHovering, textCopied]);

  return (
    <>
      {isLoading ? (
        <LoadingProfiz isVisible={isLoading} positionLogo={"40%"} />
      ) : (
        <>
          <S.Container isActive={mobileWarning}>
            {!!mobileWarning && (
              <S.MobileWarning>
                <img
                  src={ButtonClose}
                  alt="Fechar modal"
                  width={10}
                  height={10}
                  onClick={() => setMobileWarning(false)}
                />
                {/*
                <S.MobileWrapper>
                  <img
                    src={ProfizLogoMobile}
                    alt="Logo da profiz"
                    width={45}
                    height={45}
                  />
                  <div>
                    <S.WarningTitle>Profiz - App</S.WarningTitle>

                    <S.WarningSubtitle>
                      Uma plataforma, <br />
                      inúmeros benefícios!
                    </S.WarningSubtitle>
                  </div>

                  <S.ButtonOpenApp
                    onClick={() => {
                      redirectToRespectiveAppStore();
                    }}
                  >
                    Abrir
                  </S.ButtonOpenApp>
                </S.MobileWrapper> */}
              </S.MobileWarning>
            )}

            <S.ProfizLogo>
              <img
                src={ProfizLogo}
                alt="Logo Profiz"
                width="165px"
                height="45px"
              />
            </S.ProfizLogo>

            <S.EventCard>
              <S.CardItem>
                {!!eventDetail?.image && (
                  <S.WrapperImg>
                    <img src={eventDetail.image} alt="banner do evento" />
                  </S.WrapperImg>
                )}

                <S.Header>
                  <S.WrapperHeader>
                    <S.WrapperDataIconCard>
                      <h5>{parseISO(String(eventDetail?.date)).getDate()}</h5>
                      <p>
                        {getMonthName(
                          parseISO(String(eventDetail?.date)).getMonth()
                        )}
                      </p>
                    </S.WrapperDataIconCard>

                    <S.TitleWrapper>
                      <S.HeaderTitle>{eventDetail?.title}</S.HeaderTitle>
                      <p>{eventDetail?.subtitle}</p>
                    </S.TitleWrapper>
                  </S.WrapperHeader>

                  <S.WrapperDescription>
                    <p>{eventDetail?.description}</p>
                  </S.WrapperDescription>
                </S.Header>

                <S.WrapperInfoMid>
                  <h5>Detalhes</h5>

                  <h6>Organizador do evento</h6>
                  <p>{eventDetail?.organization}</p>

                  <h6>Data / Horário</h6>
                  {eventDetail &&
                    eventDetail.periods.map(
                      (period: TPeriod, index: number) => {
                        return (
                          <p key={index}>
                            {getDayName(parseISO(period.startDate).getDay())},{" "}
                            {parseISO(period.startDate).getDate()} de{" "}
                            {getMonthName(
                              parseISO(period.startDate).getMonth()
                            )}
                            {period.startDate.split(" ")[1].substring(0, 5)} -{" "}
                            {period.endDate.split(" ")[1].substring(0, 5)}
                          </p>
                        );
                      }
                    )}

                  <h6>Modalidade</h6>
                  <p>{eventDetail?.modality}</p>

                  {eventDetail?.modality.toLowerCase() !== "online" &&
                    eventDetail?.address && (
                      <>
                        <h6>Endereço</h6>
                        <a
                          href={`https://www.google.com/maps/search/?api=1&query=${eventDetail.address.street},${eventDetail.address.number}-${eventDetail.address.district},${eventDetail.address.city}-${eventDetail.address.uf},${eventDetail.address.postalCode}`}
                          target={"_blank"}
                          rel="noopener noreferrer"
                        >
                          {formatedAddressFunction(eventDetail)}
                        </a>
                      </>
                    )}

                  <h6>Investimento</h6>
                  {
                    <p>
                      {eventDetail?.value !== 0
                        ? eventDetail?.value.toLocaleString("pt-BR", {
                            style: "currency",
                            currency: "BRL",
                          })
                        : "Gratuito"}
                    </p>
                  }

                  <h6>Tipo de evento</h6>
                  <p>{eventDetail?.typeEvent}</p>

                  <h6>Possui certificado</h6>
                  <p>{eventDetail?.hasCertification}</p>

                  <h6>Inscrições</h6>
                  <p>
                    {parseISO(
                      String(eventDetail?.subscription.startDate)
                    ).getDate()}{" "}
                    {!!eventDetail?.subscription.startDate && "de"}{" "}
                    {getMonthName(parseISO(String()).getMonth())}{" "}
                    {eventDetail?.subscription.startDate &&
                    eventDetail?.subscription.endDate
                      ? "à"
                      : "-"}{" "}
                    {parseISO(
                      String(eventDetail?.subscription.endDate)
                    ).getDate()}{" "}
                    {!!eventDetail?.subscription.endDate && "de"}{" "}
                    {getMonthName(
                      parseISO(
                        String(eventDetail?.subscription.endDate)
                      ).getMonth()
                    )}
                  </p>

                  <h6>Link das inscrições</h6>
                  <a
                    href={eventDetail?.subscriptionLink}
                    target={"_blank"}
                    rel="noopener noreferrer"
                  >
                    {eventDetail?.subscriptionLink}
                  </a>
                </S.WrapperInfoMid>

                <S.WrapperInfoContact>
                  <h5>Detalhes</h5>

                  <S.ContainerIconContact>
                    {/*icone*/}
                    <img src={phoneIcon} alt="telefone para contato" />
                    <S.ContainerTextContact>
                      <h6>Telefone / Whatsapp</h6>
                      <p>
                        {(eventDetail?.contact?.telephone || "") +
                          (!!eventDetail?.contact?.number &&
                          !!eventDetail?.contact?.telephone
                            ? " / "
                            : "") +
                          (eventDetail?.contact?.number || "")}

                        {!eventDetail?.contact?.telephone &&
                          !eventDetail?.contact?.number &&
                          "Não informado pelo organizador"}
                      </p>
                    </S.ContainerTextContact>
                  </S.ContainerIconContact>

                  <S.ContainerIconContact>
                    {/*icone*/}
                    <img src={emailIcon} alt="email para contato" />
                    <S.ContainerTextContact>
                      <h6>E-mail</h6>
                      <p>
                        {eventDetail &&
                        eventDetail.contact &&
                        eventDetail.contact.email
                          ? eventDetail.contact.email
                          : "Não informado pelo organizador"}
                      </p>
                    </S.ContainerTextContact>
                  </S.ContainerIconContact>

                  <S.ShareWrapper
                    onMouseEnter={() => {
                      setIsHovering(true);
                    }}
                    onMouseLeave={() => {
                      setIsHovering(false);
                    }}
                  >
                    <Button
                      style={{
                        marginTop: "40px",
                        marginBottom: isMobile ? 50 : "",
                      }}
                      typeButton="outlinePurple"
                      onClick={() => {
                        shareEvent();
                      }}
                    >
                      <img src={shareIcon} alt="compartilhe o evento" />
                      <S.TextButton>Compartilhar</S.TextButton>
                    </Button>

                    {!!toolTip && (
                      <S.ToolTipBox>
                        <S.TextToolTip>Link Copiado!</S.TextToolTip>
                      </S.ToolTipBox>
                    )}
                  </S.ShareWrapper>
                </S.WrapperInfoContact>
              </S.CardItem>
            </S.EventCard>

            <S.Button
              href={window.location.origin}
              target={"_blank"}
              rel="noopener noreferrer"
            >
              Entrar
            </S.Button>
          </S.Container>
        </>
      )}
    </>
  );
}
