import styled, { css } from "styled-components";
import { generateMedia } from "styled-media-query";
import { lighten } from 'polished'


const customMedia = generateMedia({
  medium: "700px",
  small: "368px",
  xsmall: "294px",
});


export const Container = styled.div`
  ${({ theme }) => css`
    background-color: ${theme.colors.strong_background};
    overflow-x: hidden;
    display: flex;
    flex-direction: row;
    height: 100vh;
    justify-content: center;
    align-items:center;
    min-width:368px;
    ${customMedia.lessThan("medium")`
      ${
        css`
          padding-top: 70px;
        `
      }
    `}
  `}
`;


export const ProfizLogo = styled.div`
  display:flex;
  justify-content:center;
  align-items:center;
  height: 45px;
  margin-bottom:10px;
  ${customMedia.lessThan("xsmall")`
      display: none;
  `}
`;

export const Header = styled.div`
  ${({ theme }) => css`
  display:flex;
  flex-direction:column;
  justify-content:center;
  align-items:center;
  .title{
    font-size:18px;
    font-weight:${theme.font.bold};
    color:${theme.colors.primary};
    padding:10px;
  }
  .subtitle{
    font-size:12px;
    font-weight:${theme.font.normal};
    color:${theme.colors.text_light_60};
    padding:10px;
    text-align:center;

    strong {
      color:${theme.colors.text_light_87};
    }
  }

  `}
`;


export const TeamCard = styled.div`
  ${({ theme }) => css`
    margin-top: 30px;
    width: 36%;
    max-height: 93vh;
    overflow-x: auto;
    border-radius: 15px;
    justify-content:center;
    padding:0px 10px;
    ${customMedia.lessThan("medium")`
      width: 100%;
      margin-top: 0px;
      background-color: #121212;
    `}
  `}
`;

export const WrapperInput = styled.div`
  margin-bottom: 14px;
  padding:0px 15px;
`;

export const WrapperInputPassword = styled.form`
  position: relative;
  margin-bottom: 20px;
  padding:0px 15px;
`;
export const ShowPassword = styled.button`
  height: 68%;
  width: 42px;
  position: absolute;
  right: 15px;
  top: 30%;
  transition: background 0.2s;
  &:hover {
    background: ${({ theme }) => lighten(0.2, theme.colors.background_light)};
  }
`;

