declare global {
  interface Window {
    opera: any;
    MSStream: any;
  }
}

const userAgent = navigator.userAgent || navigator.vendor || window.opera;

const APP_STORE_LINK = "https://apps.apple.com/br/app/profiz/id1534493326";

const PLAY_STORE_LINK = "https://play.google.com/store/apps/details?id=br.com.profiz.appprestador";

export const redirectToRespectiveAppStore = () => {
  // Windows Phone
  if (/windows phone/i.test(userAgent)) {
    window.open(PLAY_STORE_LINK);
  }

  //Android
  if (/android/i.test(userAgent)) {
    window.open(PLAY_STORE_LINK);
  }

  //IOS
  if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
    window.open(APP_STORE_LINK);
  }
};
