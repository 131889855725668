import { useEffect, useState } from "react";
import axios from "axios";

import { Button } from "components/Button";
import { AlertModal } from "components/AlertModal";
import { LoadingPageModal } from "components/LoadingPageModal";
import { useObservation } from "hooks/budget/observation";

import apiv2 from "services/apiv2";
import { useToast } from "hooks/toast";
import trashSVG from "assets/icons/trash.svg";
import editSVG from "assets/icons/edit.svg";

import * as S from "./styles";

type ObservationItems = {
  id: string;
  message: string;
};

type Props = {
  handleToggleOpen: () => void;
  openCreateObservation: () => void;
  openEditObservation: () => void;
  setObservation: (observation: ObservationItems) => void;
};

export function ListObservations({
  handleToggleOpen,
  openCreateObservation,
  openEditObservation,
  setObservation,
}: Props) {
  const { addToast } = useToast();
  const [isLoading, setIsLoading] = useState(true);

  const { handleSetText } = useObservation();
  const [observationItems, setObservationItems] = useState<ObservationItems[]>(
    []
  );

  const [itemSelected, setItemSelected] = useState("");
  const [selectedOption, setSelectedOption] = useState<Number | null>(null);

  const [isVisibleDeleteModal, setIsVisibleDeleteModal] = useState(false);
  const [optionalItemSelected, setOptionalItemSelected] =
    useState<ObservationItems | null>(null);

  useEffect(() => {
    async function getDefaultObservations() {
      try {
        const response = await apiv2.get("/budgets/default-observations");

        setObservationItems(response.data.messages);
      } catch (error) {
        addToast({
          title: "Ops!!",
          description:
            axios.isAxiosError(error) && error.response?.data.error
              ? error.response.data.error
              : "Ocorreu um erro ao obter a listagem de textos.",
          type: "error",
        });
      } finally {
        setIsLoading(false);
      }
    }

    getDefaultObservations();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function handleChangeOption(optionId: ObservationItems, index: number) {
    if (selectedOption === index) {
      setSelectedOption(null);
      setItemSelected("");
      return;
    }

    setSelectedOption(index);
    setItemSelected(optionId.message);
  }

  function handleAddObservationIntoBudget() {
    handleSetText(itemSelected);
    setItemSelected("");
    handleToggleOpen();
  }

  async function handleDeleteObservation() {
    setIsVisibleDeleteModal(false);
    if (!optionalItemSelected) return;
    try {
      setIsLoading(true);
      await apiv2.delete(
        `/budgets/default-observations/${optionalItemSelected.id}`
      );

      setObservationItems(
        observationItems.filter((element) => {
          return Number(element.id) !== Number(optionalItemSelected.id);
        })
      );

      addToast({
        title: "Ok!",
        description: "Texto padrão excluido com sucesso!",
        type: "success",
      });
    } catch (error) {
      addToast({
        title: "Ops!!",
        description:
          axios.isAxiosError(error) && error.response?.data.error
            ? error.response.data.error
            : "Ocorreu um erro ao excluir o texto padrão.",
        type: "error",
      });
    } finally {
      setOptionalItemSelected(null);
      setIsLoading(false);
    }
  }

  function handleEditObservation(observation: ObservationItems) {
    setObservation(observation);
    openEditObservation();
  }

  if (isLoading) {
    return <LoadingPageModal />;
  }

  return (
    <>
      <AlertModal
        isVisible={isVisibleDeleteModal}
        title="Excluir texto padrão"
        description="Tem certeza que deseja excluir este texto padrão?"
        action="choose"
        handleConfirm={() => handleDeleteObservation()}
        onCloseModal={() => setIsVisibleDeleteModal(false)}
      />

      {observationItems.map((observation, index) => (
        <S.ContainerObservations key={observation.id}>
          <S.CardObservation
            key={observation.id}
            isSelected={selectedOption === index}
            onClick={() => handleChangeOption(observation, index)}
          >
            <S.CheckboxArea>
              <S.Checkbox isSelected={selectedOption === index}>
                <div />
              </S.Checkbox>
            </S.CheckboxArea>
            <p>{observation.message}</p>
          </S.CardObservation>

          <S.OptionButton onClick={() => handleEditObservation(observation)}>
            <img src={editSVG} width={15} height={15} alt="Lapis" />
          </S.OptionButton>

          <S.OptionButton
            onClick={() => {
              setOptionalItemSelected(observation);
              setIsVisibleDeleteModal(true);
            }}
          >
            <img src={trashSVG} width={15} height={15} alt="Excluir" />
          </S.OptionButton>
        </S.ContainerObservations>
      ))}

      <S.WrapperButtons>
        <Button typeButton="outline" onClick={() => openCreateObservation()}>
          Criar novo texto
        </Button>

        <Button onClick={() => handleAddObservationIntoBudget()}>
          Adicionar
        </Button>
      </S.WrapperButtons>
    </>
  );
}
