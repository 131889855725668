import React from "react";

import { AuthProvider } from "./auth";
import { AccountProvider } from "./permission/account";
import { ToastProvider } from "./toast";
import { MenuProvider } from "./menu";
import { ContactProvider } from "./contact";
import { ClientProvider } from "./budget/client";
import { ServiceFormTreeProvider } from "./serviceFormTree";
import { ServiceDropDownProvider } from "./servicesDropDown";
import { SelectedServiceProvider } from "./budget/selectedServices";
import { SelectedChecklistProvider } from "./budget/selectedLocalChecklist";
import { SelectedPaymentConditionsProvider } from "./budget/selectedPaymentConditions";
import { MaterialsForEditingBudgetProvider } from "./budget/materialsForEditingBudget";
import { SelectedMaterialProvider } from "./budget/selectedMaterials";
import { ObservationProvider } from "./budget/observation";
import { TotalBudgetProvider } from "./budget/totalBudget";
import { EditClientProvider } from "./editClient";
import { ServiceSignaturePhotosProvider } from "./serviceSignaturePhotos";
import { RegisterPhotosModalProvider } from "./registerPhotosModal";
import { SelectedServiceOrderProvider } from "./selectedServiceOrder";
import { SelectedServiceToSchedule } from "./selectedServiceToSchedule";
import { ServiceOrderProvider } from "./serviceOrder";
import { FinancialWithdrawalsProvider } from "./financialWithdrawals";
import { FinancialMyWalletFilterProvider } from "./financialMyWalletFilter";
import { BudgetTabIndexProvider } from "./budgetTabIndex";
import { ConfirmModalClientProvider } from "hooks/budget/confirmModalClient";
import { TopMenuProvider } from "./topMenu";
import { EventProvider } from "./events";
import { ServiceChecklistProvider } from "./serviceChecklist";
import { EnvironmentPmocProvider } from "./pmoc/environmentPmoc";
import { ResumePmocProvider } from "./resumePmoc";
import { PmocTabIndexProvider } from "./pmoc/tabIndex";
import { PmocProvider } from "./usePmoc";
import { ServiceOpportunityTabIndexProvider } from "./opportunityServiceTabIndex";
import { WarningMessageProvider } from "./warningMessage";
import { ThemeProvider } from "styled-components";
import { HandleModalProvider } from "./handleModalServices";
import theme from "styles/theme";
import { SelectedProductsProvider } from "./budget/selectedProducts";

export type ProviderProps = {
  children: React.ReactNode;
};

const AppProvider = ({ children }: ProviderProps) => (
  <ThemeProvider theme={theme}>
    <AuthProvider>
      <AccountProvider>
        <ToastProvider>
          <MenuProvider>
            <BudgetTabIndexProvider>
              <ContactProvider>
                <ClientProvider>
                  <ConfirmModalClientProvider>
                    <ServiceFormTreeProvider>
                      <ServiceDropDownProvider>
                        <SelectedProductsProvider>
                          <SelectedServiceProvider>
                            <SelectedChecklistProvider>
                              <MaterialsForEditingBudgetProvider>
                                <SelectedMaterialProvider>
                                  <TotalBudgetProvider>
                                    <SelectedPaymentConditionsProvider>
                                      <EditClientProvider>
                                        <ObservationProvider>
                                          <ServiceSignaturePhotosProvider>
                                            <RegisterPhotosModalProvider>
                                              <ServiceOrderProvider>
                                                <SelectedServiceOrderProvider>
                                                  <SelectedServiceToSchedule>
                                                    <FinancialWithdrawalsProvider>
                                                      <FinancialMyWalletFilterProvider>
                                                        <TopMenuProvider>
                                                          <EventProvider>
                                                            <ServiceChecklistProvider>
                                                              <PmocTabIndexProvider>
                                                                <PmocProvider>
                                                                  <HandleModalProvider>
                                                                    <ResumePmocProvider>
                                                                      <EnvironmentPmocProvider>
                                                                        <ServiceOpportunityTabIndexProvider>
                                                                          <WarningMessageProvider>
                                                                            {
                                                                              children
                                                                            }
                                                                          </WarningMessageProvider>
                                                                        </ServiceOpportunityTabIndexProvider>
                                                                      </EnvironmentPmocProvider>
                                                                    </ResumePmocProvider>
                                                                  </HandleModalProvider>
                                                                </PmocProvider>
                                                              </PmocTabIndexProvider>
                                                            </ServiceChecklistProvider>
                                                          </EventProvider>
                                                        </TopMenuProvider>
                                                      </FinancialMyWalletFilterProvider>
                                                    </FinancialWithdrawalsProvider>
                                                  </SelectedServiceToSchedule>
                                                </SelectedServiceOrderProvider>
                                              </ServiceOrderProvider>
                                            </RegisterPhotosModalProvider>
                                          </ServiceSignaturePhotosProvider>
                                        </ObservationProvider>
                                      </EditClientProvider>
                                    </SelectedPaymentConditionsProvider>
                                  </TotalBudgetProvider>
                                </SelectedMaterialProvider>
                              </MaterialsForEditingBudgetProvider>
                            </SelectedChecklistProvider>
                          </SelectedServiceProvider>
                        </SelectedProductsProvider>
                      </ServiceDropDownProvider>
                    </ServiceFormTreeProvider>
                  </ConfirmModalClientProvider>
                </ClientProvider>
              </ContactProvider>
            </BudgetTabIndexProvider>
          </MenuProvider>
        </ToastProvider>
      </AccountProvider>
    </AuthProvider>
  </ThemeProvider>
);

export default AppProvider;
