import axios from "axios";

const api = axios.create({
  baseURL: `${process.env.REACT_APP_BASE_URL}/v1`,
  headers: {
    "App-Token": process.env.REACT_APP_TOKEN_CONNECT_BACKEND,
  },
});

export { api };
