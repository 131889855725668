import React, { useState } from "react";

import { AlertModal } from "components/AlertModal";

import { useConfirmModalClient } from "hooks/budget/confirmModalClient";

import * as S from "./styles";

type ModalRightProps = {
  isOpen: boolean;
  children: React.ReactNode;
  handleToggleOpen: () => void;
  haveOverlay?: boolean;
  lowSize?: boolean;
  showHeader?: boolean;
  overflow?: string;
  minWidth?: string;
};

export function ModalRight({
  isOpen,
  children,
  handleToggleOpen,
  haveOverlay = true,
  lowSize = false,
  showHeader = true,
  overflow = "auto",
  minWidth = "470px",
}: ModalRightProps) {
  const {
    handleChangeModalProps,
    setConfirmModalProps,
    modalName,
    hasChangeds,
    showToastModalRight,
  } = useConfirmModalClient();

  const [warningMessage, setWarningMessage] = useState(false);

  const showToast = () => {
    setWarningMessage(true);
  };

  const closeModalClickOverlayContent = () => {
    if (showToastModalRight) {
      showToast();
    }

    const modals = ["editClient", "createClient", "editClient", "unityForm"];

    if (!hasChangeds) {
      if (modals.includes(modalName || "")) {
        handleChangeModalProps({
          value: true,
          handleConfirm: () => {
            setConfirmModalProps(false);
          },
          handleCloseModal: () => {
            handleToggleOpen();
            setConfirmModalProps(false);
          },
        });
        return;
      }
    }
    !showToastModalRight && handleToggleOpen();
  };

  return (
    <>
      <AlertModal
        isVisible={warningMessage}
        title="Retornar"
        description="É necessário revisar os campos obrigatórios"
        action="choose"
        handleConfirm={() => {
          setWarningMessage(false);
        }}
        onCloseModal={() => {
          setWarningMessage(false);
        }}
        labelConfirm={"Continuar editando"}
      />

      {isOpen && (
        <S.Container aria-hidden={!isOpen}>
          <S.Overlay
            haveOverlay={haveOverlay}
            onClick={closeModalClickOverlayContent}
          />

          <S.Content
            style={{ width: lowSize ? 600 : "" }}
            haveOverlay={haveOverlay}
            overflow={overflow}
          >
            {showHeader && <header />}

            <S.Wrapper style={{ minWidth }}>{children}</S.Wrapper>
          </S.Content>
        </S.Container>
      )}
    </>
  );
}
