import React, { useState } from "react";

import { QuantityCounter } from "components/QuantityCounter";
import { ModalRight } from "components/ModalRight";
import { ModalObservationDetail } from "components/ModalObservationDetail";

import * as S from "./styles";
import { ProductPropsUsedInContext } from "hooks/budget/selectedProducts";

type CardSelectedServicesProps = {
  data: ProductPropsUsedInContext;
  onIncrement: (id: number, price: number) => void;
  disabled?: boolean;
};

export function CardSelectedProducts({
  data,
  onIncrement,
  disabled = false,
}: CardSelectedServicesProps) {
  const [isOpenObservationDetail, setIsOpenObservationDetail] = useState(false);

  function handleIncrement(quantity: number) {
    if (disabled) {
      return;
    }
    onIncrement(data.id, quantity);
  }

  function handleToggle() {
    setIsOpenObservationDetail(!isOpenObservationDetail);
  }

  return (
    <>
      <ModalRight
        isOpen={isOpenObservationDetail}
        // eslint-disable-next-line react/jsx-no-bind
        handleToggleOpen={handleToggle}
      >
        <ModalObservationDetail
          observation={data.observations || ""}
          // eslint-disable-next-line react/jsx-no-bind
          handleGoBack={handleToggle}
          isProduct
        />
      </ModalRight>

      <S.Container>
        <header>
          <S.Title style={{ marginRight: "1rem" }}>{data.name}</S.Title>
          <S.Title>{data.formattedTotal}</S.Title>
        </header>

        <S.Description>
          {!!data.brand && `Marca: ${data.brand}`}
          {!!data.brand &&
            !!data.warranty &&
            Object.keys(data.warranty).length > 0 &&
            " | "}
          {!!data.warranty &&
            Object.keys(data.warranty).length > 0 &&
            `Garantia: ${data.warranty.time} ${data.warranty.extension}`}
        </S.Description>
        <S.Description>
          {!!data.observations && (
            <S.ObservationButton type="button" onClick={() => handleToggle()}>
              Detalhamento do produto
            </S.ObservationButton>
          )}
        </S.Description>

        <S.WrapperContent>
          <S.TextDetail>Quantidade:</S.TextDetail>

          <QuantityCounter
            disabled={disabled}
            initialValue={data.quantity}
            // eslint-disable-next-line react/jsx-no-bind
            onIncrement={handleIncrement}
          />
        </S.WrapperContent>
      </S.Container>
    </>
  );
}
