import styled, { css, keyframes } from "styled-components";

const appearFromRight = keyframes`
  from{
    opacity: 0;
    transform: translateX(50%)
  } to {
    opacity: 1;
    transform: translateX(0)
  }
`;

export const Container = styled.div`
  ${({ theme }) => css`
    width: 100%;
    min-height: 100vh;
    height: 100%;
    position: absolute;
    top: 0;
    right: 0;
    z-index: ${theme.layers.modal};

    display: flex;
    animation: ${appearFromRight} 1.5s;
  `}
`;

export const Overlay = styled.section`
  flex: 1;
  background-color: rgba(0, 0, 0, 0.5);
`;

export const Content = styled.section`
  ${({ theme }) => css`
    width: 740px;
    max-width: 740px;
    min-width: 620px;
    overflow: auto;
    display: flex;
    flex-direction: column;
    align-items: center;

    background-color: ${theme.colors.background};

    header {
      width: 100%;
      min-height: 62px;
    }

    section {
      width: 90%;
      min-width: 470px;
      padding: 30px;
    }
  `}
`;
