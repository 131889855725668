import styled from "styled-components";

export const Content = styled.div`
  margin-top: 40px;
  margin-bottom: 40px;
`;

export const Wrapper = styled.div`
  margin-top: 20px;
`;
