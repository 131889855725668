import { useState } from "react";

import { ModalRight } from "components/ModalRight";
import { ChecklistItemList } from "pages/fieldService/ServiceChecklist/types";

import * as S from "./styles";
import { CarouselImages } from "components/ModalGalleryRegisterImages/CarouselImages";
import { Photo } from "dtos/ServiceChecklistDTO";

import CloseIcon from "assets/icons/buttonClose.svg";

interface ModalProps {
  isOpen: boolean;
  setIsOpen(value: boolean): void;
  checklist: ChecklistItemList;
}

export function ModalChecklist({ isOpen, setIsOpen, checklist }: ModalProps) {
  const [photoModal, setPhotoModal] = useState(false);

  const [stagePhotoData, setStagePhotoData] = useState<Photo[]>([]);
  const [photoIndex, setPhotoIndex] = useState(0);

  const handleTogglePhotoModal = () => {
    setPhotoModal(!photoModal);
  };

  return (
    <ModalRight isOpen={isOpen} handleToggleOpen={() => setIsOpen(!isOpen)}>
      <S.ButtonBackground onClick={() => setIsOpen(false)}>
        <img src={CloseIcon} alt="Fechar" />
      </S.ButtonBackground>

      <S.Title>Checklist - {checklist?.name}</S.Title>

      {checklist?.registers?.map((item) => (
        <div key={item.id}>
          {item.type === "fotografar" && (
            <>
              <CarouselImages
                isVisible={photoModal}
                imageSelected={photoIndex}
                images={stagePhotoData}
                onCloseModal={handleTogglePhotoModal}
              />

              <S.WrapperName>
                <S.ChecklistName>{item.name}</S.ChecklistName>

                <S.ChecklistName>
                  Total de fotos: {item.photos?.length}
                </S.ChecklistName>
              </S.WrapperName>

              {!item.photos || (item.photos && item.photos.length === 0) ? (
                <S.ChecklistName style={{ marginTop: 20 }}>
                  Nenhuma imagem
                </S.ChecklistName>
              ) : (
                <S.ImagesWrapper>
                  {item.photos?.map((photo, index) => (
                    <S.Image
                      onClick={() => {
                        setPhotoIndex(index);
                        typeof item.photos !== "undefined" &&
                          setStagePhotoData(
                            item.photos?.map((pData) => {
                              return { id: pData.id, link: pData.url };
                            })
                          );
                        handleTogglePhotoModal();
                      }}
                      alt={photo.arquivo}
                      key={photo.id}
                      src={photo.url}
                    ></S.Image>
                  ))}
                </S.ImagesWrapper>
              )}
            </>
          )}
          {item.type === "medir" && (
            <>
              <S.ChecklistName style={{ marginTop: 20 }}>
                {item.name}
              </S.ChecklistName>
              {!item.value ? (
                <S.ChecklistName style={{ marginTop: 10 }}>
                  Sem resposta
                </S.ChecklistName>
              ) : (
                <S.Card>
                  <S.ChecklistType>{item.value}</S.ChecklistType>
                </S.Card>
              )}
            </>
          )}
          {(item.type === "verificar" || item.type === "executar") && (
            <S.Card style={{ marginTop: 20 }}>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <S.ChecklistName>{item.name}</S.ChecklistName>
                {item.isChecked && (
                  <S.CheckboxBackground>✓</S.CheckboxBackground>
                )}
              </div>
              {!item.isChecked && (
                <S.ChecklistName style={{ marginTop: 10 }}>
                  {item.type === "verificar"
                    ? "Não verificado"
                    : "Não executado"}
                </S.ChecklistName>
              )}
              {item.observation && (
                <S.ObservationTextCard>
                  <S.ChecklistName>{item.observation}</S.ChecklistName>
                </S.ObservationTextCard>
              )}
            </S.Card>
          )}
        </div>
      ))}
    </ModalRight>
  );
}
