import { SocialAuthenticationCredentials, useAuth } from "hooks/auth";
import { api } from "services/api";
import { useToast } from "hooks/toast";
import axios from "axios";

import google from "assets/white_google.svg";

import { useGoogleLogin } from "@react-oauth/google";

import { Button } from "components/Button";

interface ButtonProps {
  text: string;
  indicationHash?: string
}

export function GoogleButton({ text, indicationHash }: ButtonProps) {
  const { socialAuthentication } = useAuth();
  const { addToast } = useToast();

  const signInWithGoogle = useGoogleLogin({
    onSuccess: async ({ access_token }) => {
      const userInfo = await axios.get(
        "https://www.googleapis.com/oauth2/v3/userinfo",
        { headers: { Authorization: `Bearer ${access_token}` } }
      );
      handleSignInWithGoogle(userInfo.data.email, access_token);
    },

    onError: async () => {
      addToast({
        title: "Ops!!",
        description: "Não foi possível conectar a conta Google",
        type: "error",
      });
    },
  });

  const handleSignInWithGoogle = async (email: string, accessToken: string) => {
    try {
      const responseApi = await api.post("users/social-auth", {
        email: email,
        token: accessToken,
        type: "google",
        ...(!!indicationHash && {indicationHash})
      });

      const { user, token }: SocialAuthenticationCredentials = responseApi.data;

      socialAuthentication({ user, token });
    } catch (err: any) {
      addToast({
        title: "Ops!!",
        description: err.response.status === 400 ? err.response.data.error : "Não foi possível conectar a conta Google",
        type: "error",
      });
    }
  };

  return (
    <Button
      style={{ backgroundColor: "rgba(255, 255, 255, 0.87)" }}
      type="button"
      onClick={() => signInWithGoogle()}
    >
      <img
        style={{ width: "18px", height: "18px", marginRight: "10px" }}
        src={google}
        alt="logo do google"
      />
      {text}
    </Button>
  );
}
