import React, { ButtonHTMLAttributes } from 'react'

import * as S from './styles'

type ButtonProps = ButtonHTMLAttributes<HTMLButtonElement> & {
  label: string
}

export function ButtonPlusDashed({ label, ...rest }: ButtonProps) {
  return (
    <S.Container {...rest}>
      <div>
        <span>+</span>
      </div>

      <p>{label}</p>
    </S.Container>
  )
}
