import styled, { css } from "styled-components";

export const ContainerSearch = styled.div`
  width: 100%;
  position: absolute;
  top: -60px;
`;

export const EmptyList = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
`;

export const Text = styled.p`
  ${({ theme }) => css`
    font-size: 12px;
    font-family: ${theme.font.normal};
    color: ${theme.colors.text};
    letter-spacing: 0.26px;
  `}
`;
