import styled, { css } from "styled-components";

export const Container = styled.div`
  display: flex;
  margin-top: 16px;
  margin-bottom: 20px;
`;

export const ContentInfo = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 10px;
`;

export const InfoHighlightText = styled.span`
  ${({ theme }) => css`
    font-family: ${theme.font.family};
    font-size: ${theme.font.sizes.xsmall};
    font-weight: ${theme.font.normal};
    color: ${theme.colors.text_light_87};
  `}
`;

export const Bold = styled.span`
  ${({ theme }) => css`
    font-family: ${theme.font.family};
    font-size: ${theme.font.sizes.xsmall};
    font-weight: ${theme.font.bold};
    color: ${theme.colors.text_light_87};
  `}
`;

export const InfoText = styled.span`
  ${({ theme }) => css`
    font-family: ${theme.font.family};
    font-size: ${theme.font.sizes.xsmall};
    font-weight: ${theme.font.normal};
    color: ${theme.colors.text_light_60};
    margin-top: 3px;
  `}
`;
