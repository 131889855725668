import styled, { css } from "styled-components";

export const Container = styled.div`
  ${({ theme }) => css`
    overflow-x: hidden;
    display: flex;
    flex-direction: row;
    max-width: 1400px;
    width: 600px !important;
    padding: 30px;
    background-color: ${theme.colors.strong_background};
    align-items: center;
    justify-content: center;
    padding: 0px 40px;

    .image {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .title {
      font-size: 16px;
      font-weight: ${theme.font.bold};
      color: ${theme.colors.text_extra_light};
      padding: 10px;
      text-align: center;
    }
    .subtitle {
      font-size: 12px;
      font-weight: ${theme.font.normal};
      color: ${theme.colors.text_light_60};
      padding: 10px;
      text-align: center;
      strong {
        color: ${theme.colors.text_light_87};
      }
    }
  `}
`;
