import { useState } from "react";
import { useTheme } from "styled-components";
import { useHistory, useLocation } from "react-router";

import { Page } from "templates/Page";
import { Button } from "components/Button";
import { LoadingProfiz } from "components/LoadingProfiz";

import { useAccount } from "hooks/permission/account";
import { useToast } from "hooks/toast";

import LeaveSvg from "assets/icons/permission/leave.svg";

import { api } from "services/api";

import * as S from "./styles";

type LocationProps = {
  profile: {
    id: number;
    name: string;
  };
};

export function ConfirmExitTeam() {
  const theme = useTheme();
  const history = useHistory();
  const { whoami } = useAccount();
  const { addToast } = useToast();

  const { state } = useLocation<LocationProps>();

  const [loading, setLoading] = useState(false);

  async function handleConfirm() {
    try {
      setLoading(true);
      await api.delete(`users/teams/my-account/teams/${whoami?.id}`);

      history.push("/teams/my-invite/confirm-exit/success");
    } catch (e) {
      addToast({
        title: "Erro",
        description: "Ocorreu um erro ao remover membro",
        type: "error",
      });
    } finally {
      setLoading(false);
    }
  }
  return (
    <>
      {loading ? (
        <LoadingProfiz isVisible={loading} />
      ) : (
        <Page title="Solicitar saída da equipe" subtitle="" titleAlignedBelow>
          <S.Content>
            <img
              src={LeaveSvg}
              alt="Ícone prestador solicitando saída do time"
            />

            <S.WrapperText>
              <S.Description>
                Tem certeza de que deseja sair da equipe? Caso prossiga, você
                perderá todas as permissões do cargo
                <S.Highlight> {state.profile.name} </S.Highlight>
                desta empresa.
              </S.Description>
            </S.WrapperText>

            <Button typeButton="delete" onClick={handleConfirm}>
              Solicitar saída da equipe
            </Button>

            <Button
              style={{ color: theme.colors.text_light_60 }}
              typeButton="textOnly"
              onClick={() => history.goBack()}
            >
              Cancelar
            </Button>
          </S.Content>
        </Page>
      )}
    </>
  );
}
