import styled, { css } from "styled-components";

type InputProps = {
  marginTop?: number;
  marginBottom?: number;
};

type ButtonModal = {
  isSelected?: boolean;
};

export const Container = styled.div``;

export const Top = styled.div`
  ${({ theme }) => css`
    margin-bottom: 30px;

    display: flex;
    align-items: center;
    justify-content: space-between;

    p {
      font-size: ${theme.font.sizes.large};
      color: ${theme.colors.title};
      font-weight: ${theme.font.bold};
    }
  `}
`;

export const BackButton = styled.button`
  ${({ theme }) => css`
    width: 35px;
    height: 35px;
    background-color: ${theme.colors.background_light};
    border-radius: 10px;

    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 25px;
  `}
`;

export const EditUnity = styled.button`
  ${({ theme }) => css`
    height: 40px;
    width: 40px;

    display: flex;
    align-items: center;
    transition: opacity 0.2s;
    border: 1px solid ${theme.colors.primary};
    border-radius: 15px;
    padding: 10px;

    &:hover {
      opacity: 0.7;
    }
    img {
      width: 16px;
      height: 16px;
    }
  `}
`;

export const Title = styled.p`
  ${({ theme }) => css`
    font-size: 18px;
    font-family: ${theme.font.bold};
    color: ${theme.colors.text_extra_light};
    letter-spacing: 0.4px;
    line-height: 20px;
    margin-bottom: 5px;
  `}
`;

export const wrapperText = styled.div`
  justify-content: center;
`;

export const SubTitle = styled.p<ButtonModal>`
  ${({ theme, isSelected = false }) => css`
    font-size: 12px;
    font-family: ${theme.font.family};
    color: ${isSelected ? theme.colors.detail : theme.colors.financial_title};
    letter-spacing: 0.26px;
    line-height: 16px;
  `}
`;

export const CardEquipmentInfo = styled.div`
  ${({ theme }) => css`
    flex: 1;
    min-height: 100px;
    width: 100%;
    padding: 10px;
    background: ${theme.colors.background_light};
    border-radius: 8px;
    max-height: 20%;
  `}
`;

export const TitleCard = styled.p`
  ${({ theme }) => css`
    font-size: 12px;
    font-family: ${theme.font.bold};
    color: ${theme.colors.text_extra_light};
    letter-spacing: 0.26px;
    line-height: 16px;
    margin-bottom: 8px;
    margin-top: 5px;
  `}
`;

export const CardInfo = styled.p`
  ${({ theme }) => css`
    font-size: 12px;
    font-family: ${theme.font.family};
    color: ${theme.colors.financial_text};
    letter-spacing: 0.26px;
    line-height: 16px;
    margin-bottom: 8px;
  `}
`;

export const WrapperInput = styled.div<InputProps>`
  ${({ marginTop, marginBottom }) => css`
    margin-top: ${marginTop ? marginTop : 15}px;
    margin-bottom: ${marginBottom ? marginBottom : 15}px;
  `}
`;

export const ViewInfoItem = styled.div`
  ${({ theme }) => css`
    display: flex;
    min-height: 50px;
    padding: 15px;
    flex-direction: column;
  `}
`;

export const CardService = styled.div`
  ${({ theme }) => css`
    display: flex;
    min-height: 50px;
    background: ${theme.colors.card_container};
    margin-bottom: 10px;
    border-radius: 8px;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  `}
`;

export const EquipmentInfoText = styled.p`
  ${({ theme }) => css`
    display: flex;
    flex-direction: row;
    font-size: 12px;
    font-family: ${theme.font.family};
    color: ${theme.colors.financial_text};
    letter-spacing: 0.26px;
    line-height: 16px;
    margin-bottom: 5px;
  `}
`;

export const TitleEquipmentInfo = styled.p`
  ${({ theme }) => css`
    font-size: 12px;
    font-family: ${theme.font.bold};
    color: ${theme.colors.text_extra_light};
    letter-spacing: 0.26px;
    line-height: 16px;
    margin-bottom: 5px;
  `}
`;

export const WrapperChecklist = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: row;

    p.title {
      font-size: 12px;
      font-family: ${theme.font.bold};
      color: ${theme.colors.financial_text};
      letter-spacing: 0.26px;
      line-height: 16px;
      margin-right: 5px;
      text-decoration: underline;
    }

    p.quantityChecklist {
      font-size: 12px;
      font-family: ${theme.font.bold};
      color: ${theme.colors.primary};
      letter-spacing: 0.26px;
      line-height: 16px;
    }
  `}
`;

export const ButtonQRCode = styled.div`
  background: transparent;
  outline: 1px solid yellow;
  justify-content: center;
  align-items: center;
  height: 100px;
  width: 100px;
  border-radius: 8px;
  display: flex;
  flex-direction: column;

  img {
    height: 40px;
    width: 40px;
  }
`;

export const EquipmentInfoTextButton = styled.p`
  ${({ theme }) => css`
    font-size: 12px;
    font-family: ${theme.font.family};
    color: ${theme.colors.financial_text};
    letter-spacing: 0.26px;
    line-height: 16px;
    text-align: center;
    padding: 5px;
  `}
`;

export const WrapperChoises = styled.div`
  flex-direction: row;
`;

export const ButtonChoiseNegative = styled.div`
  height: 37px;
  flex: 1;
  justify-content: center;
  align-items: center;
`;

export const ButtonText = styled.p`
  ${({ theme }) => css`
    color: ${theme.colors.primary};
    font-size: 11px;
    font-family: ${theme.font.family};
  `}
`;

export const SeparatorAlert = styled.div`
  ${({ theme }) => css`
    width: 1px;
    background-color: ${theme.colors.modal_detail};
  `}
`;

export const ButtonChoiseAffirmative = styled.div`
  ${({ theme }) => css`
    height: 37px;
    flex: 1;
    justify-content: center;
    align-items: center;
  `}
`;

export const ContainerCarousel = styled.div`
  margin-top: 30px;
  height: 350px;
  justify-content: center;
  align-items: center;
  margin-bottom: 30px;
`;

export const WrapperIcon = styled.div`
  justify-content: center;
  align-items: center;
  margin-top: 50px;
  margin-bottom: 50px;
  display: flex;

  img {
    height: 82px;
    width: 82px;
  }
`;
export const CardInfoError = styled.p`
  ${({ theme }) => css`
    font-size: 12px;
    font-family: ${theme.font.family};
    color: #fa8b94;
    letter-spacing: 0.26px;
    line-height: 16px;
    margin-bottom: 8px;
  `}
`;

export const ContainerModalView = styled.div`
  ${({ theme }) => css`
    background: rgba(0, 0, 0, 0.7);
    justify-content: flex-end;
    flex: 1;
    padding-bottom: 10px;
    padding-left: 20px;
    padding-right: 20px;
  `}
`;

export const ButtonArrow = styled.button`
  height: 50px;
  width: 40px;

  position: absolute;
  top: 44%;

  display: flex;
  justify-content: center;
  align-items: center;
  transition: opacity 0.2s;

  &:hover {
    opacity: 0.6;
  }
`;

export const ButtonArrowPrev = styled(ButtonArrow)`
  left: 0;
  z-index: ${({ theme }) => theme.layers.alwaysOnTop};
`;

export const Image = styled.img`
  width: 100px;
  height: 100px;
  border-radius: 5px;
  margin-right: 15px;
  cursor: pointer;
`;

export const ButtonArrowNext = styled(ButtonArrow)`
  right: 0;
  z-index: ${({ theme }) => theme.layers.alwaysOnTop};
`;

export const ContainerCarouselWeb = styled.div`
  width: 80%;
  align-items: center;
  justify-content: center;
`;

export const ViewImage = styled.div`
  display: flex;
  flex-direction: row;
`;

export const WrapperButton = styled.div`
  ${({ theme }) => css`
    width: 10%;
    min-height: 100px;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    background-color: ${theme.colors.background_light};

    display: flex;
    flex-direction: column;
  `}
`;

export const Footer = styled.div`
  ${({ theme }) => css`
    width: 100%;
    max-width: 602px;
    padding-bottom: 26px;
    position: fixed;
    bottom: 0px;
    background-color: ${theme.colors.background_modal};
  `}
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 35%;
`;
