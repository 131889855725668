import React, { useEffect, useState } from "react";

import { Dropdown, DropdownOption } from "components/DropDown";

import { MaterialTypeDropDownProps } from "dtos/MaterialTypeDropDownDTO";
import { MaterialDropDownProps } from "dtos/MaterialDropDownDTO";
import { useToast } from "hooks/toast";
import { api } from "services/api";
import { InputAddNewCategory } from "components/InputAddNewCategory";

type MaterialNameProps = {
  selectedMaterialTypeId: null | number;
  selectedMaterialId?: number;
  materialsType: MaterialTypeDropDownProps[];
  materials: MaterialDropDownProps[];
  hasError: string;
  handleSelectMaterialName: ({ id, name }: DropdownOption) => void;
  handleAddErrorOnMaterialType: (text: string) => void;
};

export function MaterialName({
  selectedMaterialTypeId,
  selectedMaterialId,
  materialsType,
  materials,
  hasError,
  handleSelectMaterialName,
  handleAddErrorOnMaterialType,
}: MaterialNameProps) {
  const { addToast } = useToast();

  const [loadingButton, setLoadingButton] = useState(false);

  const [newMaterialName, setNewMaterialName] = useState("");
  const [materialItems, setMaterialItems] = useState<MaterialDropDownProps[]>(
    []
  );

  useEffect(() => {
    if (selectedMaterialTypeId) {
      const materialTypeSelected = materialsType.find(
        (currentMaterialsType) =>
          currentMaterialsType.id === selectedMaterialTypeId
      );

      if (materialTypeSelected && materialTypeSelected.materials.length > 0) {
        setMaterialItems(materialTypeSelected.materials);
        return;
      }
    }

    setMaterialItems(materials);
  }, [materials, materialsType, selectedMaterialTypeId]);

  function handleChangeNewCategory(text: string) {
    setNewMaterialName(text);
  }

  async function handleSaveNewCategory() {
    try {
      setLoadingButton(true);

      if (!selectedMaterialTypeId) {
        handleAddErrorOnMaterialType("Selecione um tipo de material");
        return;
      }

      if (!newMaterialName) {
        addToast({
          title: "Campo Vazio",
          description: "Digite um novo tipo de material primeiro",
          type: "info",
        });
        return;
      }

      const { data } = await api.post(
        `/material/type/${selectedMaterialTypeId}/item`,
        {
          name: newMaterialName,
        }
      );

      setMaterialItems((oldMaterialItems) => [
        ...oldMaterialItems,
        {
          id: data.materials.id,
          name: data.materials.name,
          default: 'false',
        },
      ]);

      setNewMaterialName("");

      addToast({
        title: "Sucesso",
        description: "Material criado com sucesso",
        type: "success",
      });
    } catch (error) {
      // sendError(error)
      addToast({
        title: "Falha ao atualizar",
        description: "Não foi possível criar um novo material!",
        type: "error",
      });
    } finally {
      setLoadingButton(false);
    }
  }

  function handleDeleteCategory(id: number) {
    const filteredMaterialItems = materialItems.filter(item => Number(item.id) !== id);

    setMaterialItems(filteredMaterialItems);
  }

  return (
    <Dropdown
      label="Material*"
      type={"categoryMaterial"}
      placeholder="Selecione o material"
      options={materialItems}
      categoryIdSelected={
        selectedMaterialId ? Number(selectedMaterialId) : undefined
      }
      // eslint-disable-next-line react/jsx-no-bind
      onClickedValue={handleSelectMaterialName}
      hasInput
      handleDeleteCategory={handleDeleteCategory}
      hasError={hasError}
    >
      <InputAddNewCategory
        isLoadingButton={loadingButton}
        value={newMaterialName}
        // eslint-disable-next-line react/jsx-no-bind
        handleChangeNewCategory={handleChangeNewCategory}
        // eslint-disable-next-line react/jsx-no-bind
        handleSave={handleSaveNewCategory}
      />
    </Dropdown>
  );
}
