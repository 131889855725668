import React from 'react';

import * as S from './styles';


export type Photo = {
  id: number;
  link: string;
};

type CardPhotoServiceProps = {
  title: string;
  photos: Photo[];
};

export function CardPhotoService({ title, photos }: CardPhotoServiceProps) {
  return (
    <S.Container>
      <S.Title>{title}</S.Title>

      <S.WrapperPhotos>
        {photos.map(photo => (
          <S.ImagePreview key={photo.id} src={photo.link} />
        ))}
      </S.WrapperPhotos>
    </S.Container>
  );
}
