import { opacify } from "polished";
import styled, { css, keyframes } from "styled-components";

type Props = {
  toggleNotification: boolean;
};

type StatusProps = {
  readStatus: boolean;
};

const appearFromLeft = keyframes`
  from{
    opacity: 0;
    transform: translateX(50%)
  } to {
    opacity: 1;
    transform: translateX(0)
  }
`;

export const Container = styled.div<Props>`
  ${({ theme, toggleNotification }) => css`
    width: 450px;
    max-height: 95vh;
    padding: 20px;
    padding-bottom: 100px;
    background-color: ${theme.colors.edit_area};

    border-top-left-radius: 12px;
    border-bottom-left-radius: 12px;

    position: absolute;
    right: 0;
    bottom: 0;
    top: 72px;
    display: ${toggleNotification ? "block" : "none"};

    animation: ${appearFromLeft} 0.6s ease-in-out backwards;
    overflow: auto;
    z-index: ${theme.layers.alwaysOnTop};

    h1 {
      font-family: ${theme.font.family};
      font-size: ${theme.font.sizes.large};
      color: ${theme.colors.text_light_87};
      margin-bottom: 15px;
    }
  `}
`;

export const HeaderDate = styled.header`
  ${({ theme }) => css`
    background-color: ${theme.colors.background_light};
    margin-bottom: 15px;
    border-radius: 10px;

    h4 {
      font-size: ${theme.font.sizes.xsmall};
      color: ${theme.colors.title};
      padding: 15px;
    }
  `}
`;

export const ListNotifications = styled.ul`
  list-style-type: none;
`;

export const ItemNotification = styled.li<StatusProps>`
  ${({ theme, readStatus }) => css`
    display: flex;
    position: relative;
    padding: 15px;
    margin-bottom: 15px;
    border-radius: 10px;
    background: ${theme.colors.background_light};
    transition: background 0.2s;
    cursor: pointer;

    ${readStatus &&
    css`
      opacity: 0.8;
    `}

    & > img {
      width: 35px;
      margin-right: 10px;
    }

    &:hover {
      background: ${opacify(0.07, theme.colors.background_light)};
    }
  `}
`;

export const BadgeUnread = styled.div`
  ${({ theme }) => css`
    width: 9px;
    height: 9px;
    border-radius: 5px;
    background-color: ${theme.colors.primary};

    position: absolute;
    top: 15px;
    right: 15px;
  `}
`;

export const WrapperNotification = styled.div`
  ${({ theme }) => css`
    h4 {
      font-size: ${theme.font.sizes.xsmall};
      color: ${theme.colors.title};
      margin-bottom: 5px;
    }

    p {
      font-size: ${theme.font.sizes.xsmall};
      color: ${theme.colors.text};
    }
  `}
`;

export const Wrapperempty = styled.div`
  height: 62vh;
  display: flex;
  align-items: flex-end;
`;

export const MarkAllRead = styled.div`
  ${({ theme }) => css`
    display: flex;
    justify-content: flex-end;
    width: 100%;
    margin: 20px 0;
    button {
      cursor: pointer;
      margin-left: auto;
      color: ${theme.colors.primary};
      border-bottom: solid 1px ${theme.colors.primary};
    }
  `}
`;
