import React, { useEffect, useState } from "react";

import { ArrowButton } from "components/ArrowButton";

import CopyLinkIcon from "assets/icons/member-get-member/copy-link-icon.svg";
import ShareIcon from "assets/icons/member-get-member/share-icon.svg";
import ProfitzSVG from "assets/icons/member-get-member/profitz-cash-icon.svg";

import { useToast } from "hooks/toast";

import { useHistory } from "react-router";

import { ShareOnSocialMediaModal } from "components/ShareOnSocialMediaModal";
import { LoadingProfiz } from "components/LoadingProfiz";

import { api } from "services/api";

import * as S from "./styles";

export function MyEarnings() {
  const { addToast } = useToast();
  const history = useHistory();

  const [isLoading, setIsLoading] = useState(true);

  const [isCopied, setIsCopied] = useState(false);

  const [indicationUrl, setIndicationUrl] = useState("");
  const [profitzTotal, setProfitzTotal] = useState<number>();

  const [shareModalIsVisible, setShareModalIsVisible] = useState(false);

  const [remainingProfitzToEarn, setRemainingProfitzToEarn] =
    useState<number>();

  const [isProfileApproved, setIsProfileApproved] = useState<boolean>();

  useEffect(() => {
    (async () => {
      try {
        const { data } = await api.get("users/me");

        setIsProfileApproved(data.profileApproved);
        setProfitzTotal(data.profitz.total);
        setIndicationUrl(data.indication.url);
      } catch (error) {
        addToast({
          title: "Ops",
          description: "Não foi possível obter dados do usuário",
          type: "error",
        });
      }
    })();
  });

  useEffect(() => {
    (async () => {
      try {
        const { data } = await api.get("/users/me/member-get-member/profitz");

        setRemainingProfitzToEarn(data.balance.remaining);
      } catch (error) {
        addToast({
          title: "Ops",
          description: "Não foi possível obter o limite de Profitz da campanha",
          type: "error",
        });
      } finally {
        setIsLoading(false);
      }
    })();
  });

  const handleCopyIndicationURL = () => {
    if (!isProfileApproved) {
      history.push("/complete-profile");

      addToast({
        title: "Ops!",
        description: "Complete seu perfil para ter acesso ao link de indicação",
        type: "info",
      });

      return;
    }

    navigator.clipboard.writeText(indicationUrl);

    setIsCopied(true);
  };

  const truncateStr = (str: string, size: number) => {
    var shortText = str;
    if (str.length >= size + 3) {
      shortText = str.substring(0, size).concat("...");
    }
    return shortText;
  };

  const handleShare = () => {
    if (!isProfileApproved) {
      history.push("/complete-profile");

      addToast({
        title: "Ops!",
        description: "Complete seu perfil para ter acesso ao link de indicação",
        type: "info",
      });

      return;
    }

    setShareModalIsVisible(true);
  };
  return (
    <>
      {isLoading ? (
        <LoadingProfiz isVisible={isLoading} />
      ) : (
        <S.Container>
          <ShareOnSocialMediaModal
            isVisible={shareModalIsVisible}
            onCloseModal={() => {
              setShareModalIsVisible(false);
            }}
          ></ShareOnSocialMediaModal>
          <S.Header>
            <div>
              <ArrowButton />
              <h1>Meus ganhos</h1>
            </div>
          </S.Header>

          <S.Content>
            <S.ProfitzInfoWrapper>
              <img src={ProfitzSVG} alt="profitz" />

              <S.ProfitzInfo>
                <S.ProfitzTitle>Profitz</S.ProfitzTitle>
                <S.ProfitzCounter>{profitzTotal}</S.ProfitzCounter>
                <S.ProfitzBottomText>Pago</S.ProfitzBottomText>
              </S.ProfitzInfo>
            </S.ProfitzInfoWrapper>

            <S.Line />

            <S.ProfitzInfoWrapper>
              <img src={ProfitzSVG} alt="profitz" />

              <S.ProfitzInfo>
                <S.ProfitzTitle>Profitz</S.ProfitzTitle>
                <S.ProfitzCounter>{remainingProfitzToEarn}</S.ProfitzCounter>
                <S.ProfitzBottomText>Ganhos em potencial</S.ProfitzBottomText>
              </S.ProfitzInfo>
            </S.ProfitzInfoWrapper>

            <S.Line />

            <S.ShareText>
              Ganhe Profitz compartilhando o link abaixo
            </S.ShareText>

            <S.ShareArea>
              <S.IndicationLink>
                {truncateStr(
                  `${window.location.origin}/indicacao/12321312321`,
                  35
                )}
              </S.IndicationLink>

              <S.CopyButton onClick={handleCopyIndicationURL}>
                <img src={CopyLinkIcon} alt="copiar link de indicação" />
                <S.CopyText>{isCopied ? "Copiado" : "Copiar"}</S.CopyText>
              </S.CopyButton>

              <S.ShareButton onClick={handleShare}>
                <img src={ShareIcon} alt="compartilhar" />
                <p>Compartilhar</p>
              </S.ShareButton>
            </S.ShareArea>

            <S.ExtractButton
              onClick={() => {
                history.push("/profitz-extract");
              }}
            >
              <S.ExtractButtonText>Ver extrato</S.ExtractButtonText>
            </S.ExtractButton>
          </S.Content>
        </S.Container>
      )}
    </>
  );
}
