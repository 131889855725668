import React, { ButtonHTMLAttributes } from 'react'

import * as S from './styles'

type ButtonCheckboxProps = {
  label?: string
  placeholder: string
  isChecked: boolean
  color?: 'default' | 'primary'
} & ButtonHTMLAttributes<HTMLButtonElement>

export function ButtonCheckbox({
  label,
  placeholder,
  isChecked,
  color = 'default',
  ...rest
}: ButtonCheckboxProps) {
  return (
    <S.Container>
      {!!label && <S.Label>{label}</S.Label>}

      <S.Button color={color} {...rest}>
        <S.CheckBox>
          <S.MarkChecked isChecked={isChecked} />
        </S.CheckBox>

        <S.Placeholder color={color}>{placeholder}</S.Placeholder>
      </S.Button>
    </S.Container>
  )
}
