import styled, { css } from "styled-components";

import { opacify } from "polished";

type CheckboxProps = {
  isChecked: boolean;
};

export const Container = styled.div<CheckboxProps>`
  ${({ theme, isChecked }) => css`
    display: flex;

    flex-direction:row;
    align-items:center;


    width: 100%;
    border-radius: 10px;
    background-color: ${theme.colors.background_light};
    
    border: ${isChecked ? `1px solid ${theme.colors.primary}` : ``};

    & + & {
      margin-top: 15px;
    }
  `}
`;

export const Content = styled.button`
  ${({ theme }) => css`
    width: 90%;
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    padding: 16px 0 16px 30px;

    & > div:first-of-type {
      width: 90%;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      gap: 10px;
      font-size: 1.6rem;

      p {
        font-weight: 500;
      }

      span {
        font-weight: bold;
        color: ${theme.colors.primary};
      }
    }
  `}
`;

export const Title = styled.p`
  ${({ theme }) => css`
    font-size: 1.5rem;

    color: ${theme.colors.title};
    font-weight: ${theme.font.bold};
  `}
`;

export const PriceText = styled.p`
  ${({ theme }) => css`
    font-size: 1.5rem;
    color: ${theme.colors.title};
  `}
`;

export const Description = styled.p`
  ${({ theme }) => css`
    font-size: 1.4rem;
    color: ${theme.colors.text};
    margin-bottom: 4px;
  `}
`;

export const WrapperActions = styled.div`
  ${({ theme }) => css`
    width: 10%;
    min-height: 110px;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    background-color: ${theme.colors.background_light};

    display: flex;
    flex-direction: column;
  `}
`;

export const Steps = styled.div`
  display: flex;
  justify-content: flex-start;

  ${({ theme }) => css`
    p {
      font-size: 1.6rem;
      color: ${theme.colors.text};
      margin-bottom: 4px;
      text-decoration: underline;
    }
  `}
`;

export const WrapperCheck = styled.button`
  ${({ theme }) => css`
    display: flex;
    padding-left: 10px;
    height: 110px;

    border-top-left-radius:10px;
    border-bottom-left-radius:10px;

    width: 60px;
    justify-content: center;
    align-items: center;
    transition: background 0.2s;
    &:hover {
      background: ${opacify(0.03, theme.colors.background_light)};
    }
    &:disabled {
      cursor: not-allowed;
      background: transparent;
    }
  `};
`;

export const MarkChecked = styled.div<CheckboxProps>`
  ${({ theme, isChecked }) => css`
    width: 10px;
    height: 10px;
    border-radius: 1.2px;

    background-color: ${isChecked
      ? theme.colors.primary
      : theme.colors.background_light};
  `};
`;


export const CheckBox = styled.div`
  ${({ theme }) => css`
    padding: 3px;
    margin-right: 15px;

    display: flex;
    align-items: center;
    justify-content: center;

    border-radius: 3px;
    border: 1px solid ${theme.colors.text};
  `}
`;
