import React, { createContext, useContext, useState } from "react";
import { AlertModal } from "components/AlertModal";
import { ModalName } from "components/ModalPageClients/types";
import { ProviderProps } from "hooks";

type ModalProps = {
  value:boolean,
  handleConfirm?:() => void,
  handleCloseModal?: () =>void,
}

type ActionsModal = {
  handleConfirm?: () =>void
  handleCloseModal?: () =>void
}

type ConfirmModalClientContextData = {
  handleChangeModalProps: ({handleConfirm,value,handleCloseModal}:ModalProps) => void
  setConfirmModalProps:(value:boolean) => void
  isVisibleModal:boolean
  setModalName:(value:ModalName)=>void
  modalName:ModalName | undefined
  hasChangeds:boolean
  setHasChangeds(value:boolean):void
  showToastModalRight:boolean
  setShowToastModalRight(value:boolean):void
};

const ConfirmModalClientContext = createContext<ConfirmModalClientContextData>({} as ConfirmModalClientContextData);

export function useConfirmModalClient(): ConfirmModalClientContextData {
  const context = useContext(ConfirmModalClientContext);

  if (!context) {
    throw Error("useConfirmModalClient must be used within a ConfirmModalClientProvider");
  }

  return context;
}

export const ConfirmModalClientProvider = ({ children }: ProviderProps) => {
  const [isVisibleModal, setConfirmModalProps] = useState(false);
  const [actionsConfirmModal, setActionsConfirmModal] = useState<ActionsModal>();
  const [modalName,setModalName] = useState<ModalName>()
  const [hasChangeds, setHasChangeds] = useState(false)
  const [showToastModalRight, setShowToastModalRight] = useState(false);

  const handleChangeModalProps = ({handleConfirm,value,handleCloseModal}:ModalProps) =>{
    setConfirmModalProps(value)
    setActionsConfirmModal({
      handleConfirm,
      handleCloseModal,
    })
  }

  return (
    <ConfirmModalClientContext.Provider
      value={{
        setConfirmModalProps,
        handleChangeModalProps,
        isVisibleModal:isVisibleModal,
        modalName,
        setModalName,
        hasChangeds,
        setHasChangeds,
        showToastModalRight,
        setShowToastModalRight
      }}
    >
    <AlertModal
      labelConfirm="Descartar alterações"
      labelCancel="Continuar editando"
      zIndex={999999}
      isVisible={isVisibleModal}
      title="Retornar"
      description="Deseja salvar as alterações antes de retornar?"
      action="choose"
      handleConfirm={() => {
        actionsConfirmModal?.handleCloseModal && actionsConfirmModal?.handleCloseModal()

      }}
      onCloseModal={() => {
        actionsConfirmModal?.handleConfirm && actionsConfirmModal?.handleConfirm()

      }}
      />
    {children}
  </ConfirmModalClientContext.Provider>
  );
};
