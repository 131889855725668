import React, { createContext, useState, useContext } from "react";
import { ProviderProps } from "hooks";

interface PmocTabIndexContextData {
  selectedIndex: number;
  handleSetSelectedIndex: (index: number) => void;
}

const PmocTabIndexContext = createContext<PmocTabIndexContextData>(
  {} as PmocTabIndexContextData
);

export function usePmocTabIndex(): PmocTabIndexContextData {
  const context = useContext(PmocTabIndexContext);

  if (!context) {
    throw Error("usePmocTabIndex must be used within an PmocTabIndexProvider");
  }

  return context;
}

export const PmocTabIndexProvider = ({ children }: ProviderProps) => {
  const [selectedIndex, setSelectedIndex] = useState(0);

  function handleSetSelectedIndex(index: number) {
    setSelectedIndex(index);
  }

  return (
    <PmocTabIndexContext.Provider
      value={{
        selectedIndex,
        handleSetSelectedIndex,
      }}
    >
      {children}
    </PmocTabIndexContext.Provider>
  );
};
