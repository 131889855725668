import React, { useState } from "react";

import { ModalRight } from "components/ModalRight";
import { ModalEditRight } from "components/ModalEditRight";
import { ModalClient } from "components/ModalClient";
import { ButtonModalAction } from "components/ButtonModalAction";
import { CardSelectedClient } from "components/CardSelectedClient";
import { FormEditClient } from "components/ModalPageClients/FormEditClient";
import { UnityForm } from "components/UnityForm";

import { useClient } from "hooks/budget/client";
import { useEditClient } from "hooks/editClient";

import * as S from "./styles";

type ClientAreaProps = {
  hasError?: string;
  disabledDelete?: boolean;
  module?: 'budget' | 'serviceOrder' | 'PMOC' | 'client';
};

export function ClientArea({
  module,
  hasError,
  disabledDelete = false,
}: ClientAreaProps) {
  const [isOpen, setIsOpen] = useState(false);
  const [modalAddress, setModalAddress] = useState(false);
  const [isModalEditOpen, setIsModalEditOpen] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);

  const { client, hasClient } = useClient();
  const { handleSetEditClient } = useEditClient();

  function handleToggleOpen() {
    handleSetEditClient(client);
    setIsOpen(!isOpen);
    setModalAddress(true);
  }

  function handleToggleListClients() {
    setIsOpen(!isOpen);
    setModalAddress(false);
  }

  function handleToggleEditClientModal() {
    setIsModalEditOpen(!isModalEditOpen);
    setShowEditModal((oldState) => !oldState);
  }

  return (
    <S.Container>
      <ModalRight
        isOpen={isOpen}
        // eslint-disable-next-line react/jsx-no-bind
        handleToggleOpen={handleToggleOpen}
      >
        {modalAddress ? (
          // eslint-disable-next-line react/jsx-no-bind
          <UnityForm defaultUnity handleToggleOpen={handleToggleListClients} />
        ) : (
          <ModalClient
            module={module}
            // eslint-disable-next-line react/jsx-no-bind
            handleToggleOpen={handleToggleOpen}
          />
        )}
      </ModalRight>

      {showEditModal ? (
        <ModalEditRight
          isModalEditOpen={isModalEditOpen}
          // eslint-disable-next-line react/jsx-no-bind
          toggleModal={handleToggleEditClientModal}
        >
          <FormEditClient
            module={module}
            toggleModal={handleToggleEditClientModal}
            isFromList={true}
          />
        </ModalEditRight>
      ) : (
        <></>
      )}

      {hasClient ? (
        <CardSelectedClient
          // eslint-disable-next-line react/jsx-no-bind
          showClientsList={handleToggleEditClientModal}
          // eslint-disable-next-line react/jsx-no-bind
          showModalAddress={handleToggleOpen}
          disabledDelete={disabledDelete}
        />
      ) : (
        <ButtonModalAction
          label="Cliente*"
          placeholder="Selecione um cliente"
          isSelected={isOpen}
          // eslint-disable-next-line react/jsx-no-bind
          onClick={handleToggleListClients}
          hasError={hasError}
        />
      )}
    </S.Container>
  );
}
