/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { FiChevronLeft, FiX } from "react-icons/fi";
import { format } from "date-fns";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import { useTheme } from "styled-components";

import { LoadingProfiz } from "components/LoadingProfiz";
import { ModalRight } from "components/ModalRight";
import { Button } from "components/Button";

import { api } from "services/api";
import { useToast } from "hooks/toast";

import ProfitzIcon from "assets/icons/member-get-member/profitz-cash-icon.svg";

import { Item, ProfitzExtract as ProfitzExtractType } from "./types/profitz";

import * as S from "./styles";
import { useHistory } from "react-router";

const labelsButtonFilter = [
  "5 dias",
  "15 dias",
  "30 dias",
  "3 meses",
  "6 meses",
  "1 ano",
];

export default function ProfitzExtract() {
  const [selectedPage, setSelectedPage] = useState(0);
  const [loading, setLoading] = useState(false);
  const [selectedFilterId, setSelectedFilterId] = useState(0);
  const [profitzExtract, setProfitzExtract] = useState<ProfitzExtractType>(
    {} as ProfitzExtractType
  );
  const [modalVisible, setModalVisible] = useState(false);
  const [selectedProfitz, setSelectedProfitz] = useState<Item>({} as Item);
  const { addToast } = useToast();
  const theme = useTheme();
  const history = useHistory();

  const toogleButtonActive = (id: number) => {
    return selectedFilterId === id && selectedFilterId !== -1
      ? "financialSelected"
      : "financialNotSelected";
  };

  const handleClick = (id: number) => {
    setSelectedFilterId(id);
    let daysToDecrement = 0;

    switch (id) {
      case 0:
        daysToDecrement = 5;
        break;
      case 1:
        daysToDecrement = 15;
        break;
      case 2:
        daysToDecrement = 30;
        break;
      case 3:
        daysToDecrement = 90;
        break;
      case 4:
        daysToDecrement = 180;
        break;
      case 5:
        daysToDecrement = 365;
        break;
    }

    const filter = format(
      new Date(new Date().setDate(new Date().getDate() - daysToDecrement)),
      "yyyy-MM-dd"
    );

    getHistory(filter);
  };

  const getHistory = async (filter?: string) => {
    setLoading(true);
    try {
      const { data } = await api.get(
        `/users/me/profitz${filter ? `?startDate=${filter}` : ""}`
      );
      setProfitzExtract(data);
    } catch (error) {
      addToast({
        title: "Ops!!",
        description: "Não foi possível carregar o extrato de profitz.",
      });
    } finally {
      setLoading(false);
    }
  };

  const selectProfitz = (profitz: Item) => {
    setSelectedProfitz(profitz);
    setModalVisible(true);
  };

  function handleGoBack() {
    history.push("/home");
  }

  useEffect(() => {
    handleClick(0);
  }, []);

  if (loading) {
    return <LoadingProfiz isVisible={loading} />;
  }

  return (
    <S.Container>
      <ModalRight
        isOpen={modalVisible}
        handleToggleOpen={() => setModalVisible(!modalVisible)}
      >
        <S.Top>
          <S.ButtonClose onClick={() => setModalVisible(!modalVisible)}>
            <FiX size={10} color={theme.colors.primary} />
          </S.ButtonClose>
        </S.Top>
        <S.BolderText style={{ color: "rgba(255, 255, 255, 0.87)" }}>
          Profitz
        </S.BolderText>

        <S.Card>
          <S.GrayCard style={{ justifyContent: "unset" }}>
            <S.Image src={selectedProfitz.image}></S.Image>
            <S.Text
              style={{ color: "rgba(255, 255, 255, 0.87)", marginLeft: 20 }}
            >
              {selectedProfitz.title}
            </S.Text>
          </S.GrayCard>
          {selectedProfitz?.details?.map((item, index) => (
            <S.GrayCard
              key={index}
              style={index % 2 === 0 ? { backgroundColor: "black" } : {}}
            >
              <S.Text
                style={{
                  color: "rgba(255, 255, 255, 0.87)",
                  textAlign: "left",
                  width: "60%",
                }}
              >
                {item.label}:
              </S.Text>
              {item.label !== "Profitz" ? (
                <S.Date
                  style={{
                    fontSize: 12,
                    textAlign: "left",
                    width: "40%",
                  }}
                >
                  {item.value}
                </S.Date>
              ) : (
                <S.BolderText
                  style={{
                    fontSize: 12,
                    color:
                      selectedProfitz.type === "debit"
                        ? theme.colors.error
                        : selectedProfitz.type === "update"
                        ? theme.colors.update
                        : theme.colors.model,
                    width: "40%",
                  }}
                >
                  {selectedProfitz.type !== "credit"
                    ? item.value
                    : `+ ${item.value}`}
                </S.BolderText>
              )}
            </S.GrayCard>
          ))}
        </S.Card>
      </ModalRight>
      <S.Header>
        <S.ButtonClose onClick={handleGoBack}>
          <FiChevronLeft size={10} color={theme.colors.primary} />
        </S.ButtonClose>
        <div>
          <S.Text style={{ marginTop: 10 }}>Seu saldo total: </S.Text>
          <S.Row style={{ marginTop: 10 }}>
            <img
              src={ProfitzIcon}
              style={{ marginRight: 10, width: 22, marginLeft: 5 }}
              alt="Logo Profitz"
            ></img>
            <S.BolderText>
              {profitzExtract?.balance?.total} Profitz
            </S.BolderText>
          </S.Row>
          {profitzExtract?.balance?.validity && (
            <S.Text style={{ marginTop: 10 }}>
              Validade: {profitzExtract?.balance?.validity}{" "}
            </S.Text>
          )}
        </div>
      </S.Header>
      <S.TabContainer>
        <Tabs
          selectedIndex={selectedPage}
          onSelect={(index: number) => {
            setSelectedPage(index);
          }}
        >
          <TabList>
            <Tab>Recebidos</Tab>
            <Tab>Gastos</Tab>
          </TabList>

          <TabPanel>
            <S.WrapperButtonFilter>
              {labelsButtonFilter.map((label, index) => (
                <S.WrapperButton key={label}>
                  <Button
                    typeButton={toogleButtonActive(index)}
                    onClick={() => handleClick(index)}
                  >
                    {label}
                  </Button>
                </S.WrapperButton>
              ))}
            </S.WrapperButtonFilter>
            {profitzExtract?.historic?.map((item, index) => {
              if (
                item.items.filter((extract) => extract.type !== "debit")
                  .length > 0
              ) {
                return (
                  <S.Content key={index}>
                    <S.Wrapper style={{ cursor: "unset" }}>
                      <S.Date>{item.label}</S.Date>
                    </S.Wrapper>
                    {item.items
                      .filter((extract) => extract.type !== "debit")
                      .map((extract) => (
                        <S.Wrapper
                          style={{ height: 100, cursor: "pointer" }}
                          key={extract.id}
                          onClick={() => selectProfitz(extract)}
                        >
                          <S.Image src={extract.image} />
                          <S.TextColumn>
                            <S.Text
                              style={{ color: "rgba(255, 255, 255, 0.87)" }}
                            >
                              {extract.title}
                            </S.Text>
                            <S.Date style={{ marginTop: 10 }}>
                              {extract.description}
                            </S.Date>
                          </S.TextColumn>
                          <S.BolderText
                            style={{
                              fontSize: 14,
                              marginLeft: "auto",
                              marginRight: 20,
                              color:
                                extract.type === "update"
                                  ? theme.colors.update
                                  : theme.colors.model,
                            }}
                          >
                            {extract.type === "credit" ? "+ " : ""}
                            {extract.profitz}
                          </S.BolderText>
                        </S.Wrapper>
                      ))}
                  </S.Content>
                );
              } else {
                return <></>;
              }
            })}
            {profitzExtract?.historic?.length === 0 && (
              <S.Wrapper
                style={{
                  marginTop: 20,
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <S.Text
                  style={{
                    textAlign: "center",
                    color: "rgba(255, 255, 255, 0.87)",
                  }}
                >
                  Não foi encontrado nenhum historico.
                </S.Text>
              </S.Wrapper>
            )}
          </TabPanel>

          <TabPanel>
            <S.WrapperButtonFilter>
              {labelsButtonFilter.map((label, index) => (
                <S.WrapperButton key={label}>
                  <Button
                    typeButton={toogleButtonActive(index)}
                    onClick={() => handleClick(index)}
                  >
                    {label}
                  </Button>
                </S.WrapperButton>
              ))}
            </S.WrapperButtonFilter>
            {profitzExtract?.historic?.map((item, index) => {
              if (
                item.items.filter((extract) => extract.type === "debit")
                  .length > 0
              ) {
                return (
                  <S.Content key={index}>
                    <S.Wrapper style={{ cursor: "unset" }}>
                      <S.Date>{item.label}</S.Date>
                    </S.Wrapper>
                    {item.items
                      .filter((extract) => extract.type === "debit")
                      .map((extract) => (
                        <S.Wrapper
                          style={{ height: 100, cursor: "pointer" }}
                          key={extract.id}
                          onClick={() => selectProfitz(extract)}
                        >
                          <S.Image src={extract.image} />
                          <S.TextColumn>
                            <S.Text
                              style={{
                                color: "rgba(255, 255, 255, 0.87)",
                                textAlign: "left",
                              }}
                            >
                              {extract.title}
                            </S.Text>
                            <S.Date style={{ marginTop: 10 }}>
                              {extract.description}
                            </S.Date>
                          </S.TextColumn>
                          <S.BolderText
                            style={{
                              fontSize: 14,
                              marginLeft: "auto",
                              marginRight: 20,
                              color: theme.colors.error,
                            }}
                          >
                            {extract.profitz}
                          </S.BolderText>
                        </S.Wrapper>
                      ))}
                  </S.Content>
                );
              } else {
                return <></>;
              }
            })}
            {profitzExtract?.historic?.length === 0 && (
              <S.Wrapper
                style={{
                  marginTop: 20,
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <S.Text
                  style={{
                    textAlign: "center",
                    color: "rgba(255, 255, 255, 0.87)",
                  }}
                >
                  Não foi encontrado nenhum historico.
                </S.Text>
              </S.Wrapper>
            )}
          </TabPanel>
        </Tabs>
      </S.TabContainer>
    </S.Container>
  );
}
