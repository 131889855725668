import { LoadingProfiz } from "components/LoadingProfiz";
import React, { useCallback, useEffect, useState } from "react";
import {
  EventFavorite,
  EventItemProps,
  EventsListItemProps,
  TFilter,
  TPeriod,
} from "../types";

import * as S from "./styles";

import { useToast } from "hooks/toast";
import { useEvent } from "hooks/events";
import { SearchInput } from "components/SearchInput";

import debounce from "lodash.debounce";
import OptionsFilterComponent from "components/OptionsFilterComponent";

import { api } from "services/api";
import { ModalRight } from "components/ModalRight";
import { DetailEvent } from "../detailEvent";
import { CardEvent } from "../cardEvent";
import { searchDatalayer } from "utils/pushDataLayer";

const dateFilterProps: TFilter = {
  nextEvents: "Próximos eventos",
  today: "Hoje",
  tomorrow: "Amanhã",
  thisWeek: "Esta semana",
  nextWeek: "Próxima semana",
  thisMonth: "Neste mês",
  nextMonth: "Próximo mês",
};

type Props = {
  idElement?: number;
};

type ResponseProps = {
  data: {
    events: EventsListItemProps[];
  };
};
type ResponseDetailEventProps = {
  data: EventItemProps;
};

type ResponseFavoriteProps = {
  data: { favoriteEvents: EventFavorite[] };
};

export function AllEvents({ idElement }: Props) {
  const { addToast } = useToast();
  const [dateFilter, setDateFilter] = useState<string[]>(["nextEvents"]);
  const [searchInputValue, setSearchInputValue] = useState("");
  const { handleSetAllEvents, allEvents, handleSetSelectedEvent } = useEvent();
  const [isLoading, setIsLoading] = useState(false);
  const [eventDetail, setEventDetail] = useState<EventItemProps>();
  const [modalOpen, setModalOpen] = useState(false);
  const [inputLoading, setInputLoading] = useState(false);

  useEffect(() => {
    getEventsData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateFilter]);

  const getEventsData = async () => {
    try {
      setIsLoading(true);

      //deixar a rota meio pronta(lista de todos)
      const paramsObj: TFilter = {
        period: dateFilter
          ? dateFilter.toString().replace(new RegExp(",", "g"), "|")
          : "",
        search: searchInputValue ? searchInputValue : "",
      };
      if (!searchInputValue || !searchInputValue.trim()) {
        delete paramsObj.search;
      }
      if (!dateFilter || dateFilter.length < 1) {
        delete paramsObj.period;
      }

      const { data }: ResponseProps = await api.get("/events", {
        params: paramsObj,
      });
      const { events } = data;

      const favEvent: ResponseFavoriteProps = await api.get(
        "/events/favorites"
      );

      let listCpy: EventsListItemProps[] = JSON.parse(JSON.stringify(events));

      const listItemFav = listCpy.map((element) => {
        if (
          favEvent.data.favoriteEvents.find((elementFav) => {
            return elementFav.eventId === element.id;
          })
        ) {
          return { ...element, saved: true };
        }
        return element;
      });

      //handleSetAllEvents(events);

      handleSetAllEvents(listItemFav);

      //se caso o idElement existir, o usuário está vindo
      //da tela de notificações e então a div de detalhamento
      //do evento já deve abrir com os dados relacionados
      //com a notificação
      // if (idElement) {
      //   handleChange(idElement.toString());
      // }
    } catch (error) {
      addToast({
        title: "Ops!!",
        description: "Ocorreu um erro ao listar os eventos",
        type: "error",
      });
    } finally {
      setIsLoading(false);
    }
  };

  const formatSortDate = (listEvents: Array<TPeriod>) => {
    return listEvents.sort(function (elementA, elementB) {
      return elementA.startDate < elementB.startDate ? -1 : 1;
    });
  };

  const handleDetailEvent = async (idxEvent: number) => {
    try {
      setIsLoading(true);

      //deixando requisição...
      const { data }: ResponseDetailEventProps = await api(
        `/events/${idxEvent}`
      );

      const favEvent: ResponseFavoriteProps = await api.get(
        "/events/favorites"
      );
      let auxData = favEvent.data.favoriteEvents.find(
        (element) => element.eventId === data.id
      )
        ? { ...data, saved: true }
        : data;

      auxData.periods =
        auxData.periods.length > 1
          ? formatSortDate(auxData.periods)
          : auxData.periods;

      setEventDetail(auxData);
      handleSetSelectedEvent(auxData);

      setModalOpen(true);

      //setEventDetail(data);
      //handleSetSelectedEvent(data);

      //onst event = await EventDetailResponse(idxEvent);
      //setEventDetail(event as EventItemProps);
      //handleSetSelectedEvent(event as EventItemProps);
    } catch (error) {
      addToast({
        title: "Ops!!",
        description: "Ocorreu um erro ao listar os eventos",
        type: "error",
      });
    } finally {
      setIsLoading(false);
    }
  };

  function handleSearchCancel() {
    setSearchInputValue("");
    handleSearchEvents("");
  }
  function handleChange(searchValue: string) {
    setInputLoading(true);
    setSearchInputValue(searchValue);
    debounceFn(searchValue, allEvents);
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debounceFn = useCallback(debounce(handleSearchEvents, 3000), []);

  async function handleSearchEvents(
    event: string,
    eventList?: EventsListItemProps[]
  ) {
    if (event === "" || !event.trim()) {
      getEventsData();
      return;
    }
    try {
      //chamar a api...
      //getEventsData();

      //filtrando os dados...
      const result = eventList?.filter((element) => {
        return (
          element.title.indexOf(event) !== -1 ||
          element.organization.indexOf(event) !== -1
        );
      });

      searchDatalayer({ search_term: event, success: true });

      handleSetAllEvents(result as EventsListItemProps[]);
    } catch (error) {
      addToast({
        type: "error",
        title: "Não foi possível realizar a busca.",
      });
    } finally {
      setInputLoading(false);
    }
  }

  const setOptionDate = (newfilter: string) => {
    let arrFilter = dateFilter.filter((element) => element === newfilter);
    return arrFilter.length === 0
      ? setDateFilter([...dateFilter, newfilter])
      : setDateFilter(dateFilter.filter((element) => element !== newfilter));
  };

  return (
    <>
      {isLoading ? (
        <LoadingProfiz isVisible={isLoading} />
      ) : (
        <>
          <ModalRight
            isOpen={modalOpen}
            handleToggleOpen={() => setModalOpen(!modalOpen)}
          >
            {eventDetail && (
              <DetailEvent
                typeView={0}
                handleCloseFunction={() => setModalOpen(!modalOpen)}
              />
            )}
          </ModalRight>

          <S.Content>
            <S.WrapperSearch>
              <SearchInput
                placeholder="Procure por um evento ou treinamento"
                searchValue={searchInputValue}
                loadingInput={inputLoading}
                onChange={(event) => handleChange(event.target.value)}
                handleCancel={handleSearchCancel}
              />
            </S.WrapperSearch>

            <S.WrapperFilter>
              <OptionsFilterComponent
                data={dateFilterProps}
                optionsSelected={dateFilter}
                handlePressButton={setOptionDate}
              />
            </S.WrapperFilter>

            {!allEvents || allEvents.length < 1 ? (
              <S.MessageDiv>
                <h6>Não existem eventos cadastrados neste momento.</h6>
              </S.MessageDiv>
            ) : (
              <S.ContentPages>
                <S.LeftContent>
                  {allEvents &&
                    allEvents.map((element: EventsListItemProps) => {
                      return (
                        <CardEvent
                          element={element}
                          setFunction={handleDetailEvent}
                          key={element.id}
                          typeView={0}
                        />
                      );
                    })}
                </S.LeftContent>

                {/* <S.RightContent>
                {eventDetail && <DetailEvent typeView={0} />}
              </S.RightContent> */}
              </S.ContentPages>
            )}
          </S.Content>
        </>
      )}
    </>
  );
}
