import React, { useEffect, useState } from "react";
import * as S from "./styles";
import { api } from "services/api";
import { PMOCServiceProps } from "dtos/ServiceDTO";
import { useToast } from "hooks/toast";
import axios from "axios";
import { LoadingProfiz } from "components/LoadingProfiz";
import { SearchInput } from "components/SearchInput";
import { Button } from "components/Button";

import { EmptyPage } from "components/EmptyPage";
import { PmocServiceContent } from "./PmocServiceContent";
import { EnvironmentPageProps } from "../EnvironmentFlow/types";

import { FiChevronLeft } from "react-icons/fi";
import { useTheme } from "styled-components";
import { useEnvironment } from "hooks/pmoc/environmentPmoc";
import { usePmoc } from "hooks/usePmoc";
import { PmocServiceProps } from "dtos/PmocEnvironmentDTO";
import { useServiceChecklist } from "hooks/serviceChecklist";

type PageProps = {
  handleChooseEnvironment?: EnvironmentPageProps;
};

type ResponseProps = {
  data: {
    userServices: PMOCServiceProps[];
  }
}

export function ServiceListPmoc({handleChooseEnvironment}:PageProps) {
  const { addToast } = useToast();
  const theme = useTheme();

  const { initialPmocProps } = usePmoc();
  const {
    equipment,
    handleSetPmocService,
    //handleSetBeforePage,
    pushStackNavigation,
  } = useEnvironment();
  
  const {
    handleSetSelectedItemChecklist,
  } = useServiceChecklist();
  

  const [isLoading, setIsLoading] = useState(false);
  const [servicesPmoc, setServicesPmoc] = useState<PMOCServiceProps[]>([]);
  const [servicesBackup, setServicesBackup] = useState<PMOCServiceProps[]>([]);
  const [searchInput, setSearchInput] = useState("");
  const [selectedServicesPmoc, setSelectedServicesPmoc] = useState<PMOCServiceProps[]>([]);

  useEffect(() => { 
    getServices();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function getServices() {
    try {
      setIsLoading(true);

      const route = '/pmoc/service';

      if (!equipment.category.id) return;
      const {data}:ResponseProps = await api.get(`${route}?categoryId=${equipment.category.id}`);
      const { userServices } = data;

      let serviceFilter: PMOCServiceProps[] = JSON.parse(JSON.stringify(userServices));
      if (equipment.services && equipment.services?.length > 0) {
        for (let service of equipment.services) {
          for (let serviceItem of serviceFilter) {
            if (Number(serviceItem.id) > Number(service.serviceModelId) ||
              Number(service.serviceModelId) < 1) {
              break;
            }
            if (Number(serviceItem.id) === Number(service.serviceModelId)) {
              serviceFilter.splice(serviceFilter.indexOf(serviceItem), 1);
            }
          }
        }
      }
      const reverseArray = JSON.parse(JSON.stringify(serviceFilter)).reverse();
      setServicesPmoc(reverseArray);
      setServicesBackup(reverseArray);
    } catch (error) {
      addToast({
        title: "Erro",
        description:
          axios.isAxiosError(error) && error.response?.data.error
            ? error.response.data.error
            : 'Não foi possível carregar a lista de serviços',
        type: "error",
      });
    } finally {
      setIsLoading(false);
    }
  }

  function handleSearchService(text: string) {
    setSearchInput(text);

    const filteredServices = servicesPmoc.filter((service) => {
      const serviceLowerCase = service.serviceType.name.toLowerCase();
      const searchValue = text.toLowerCase();

      return serviceLowerCase.includes(searchValue);
    });

    setServicesPmoc(filteredServices);
  }

  function handleSearchCancel() {
    setSearchInput("");
    setServicesPmoc(servicesBackup);
  }

  async function handleDeleteService(idService: number) {
    try {
      setIsLoading(true);
      await api.delete(`/service/${idService}`);

      addToast({
        title: "Sucesso",
        description: "Serviço deletado com sucesso",
        type: "success",
      });

      const filteredList = servicesPmoc.filter(service => {
        return Number(service.id) !== Number(idService)
      });

      setServicesPmoc(filteredList);
      setServicesBackup(filteredList);
    } catch(err){
      addToast({
        title: "Opss!",
        description:
          axios.isAxiosError(err) && err.response?.data.error
            ? err.response.data.error
            : "Não foi possível deletar o serviço.",
        type: "error",
      });
    }
    finally {
      setIsLoading(false);
    }
  }

  function handleBackButton() {
    handleChooseEnvironment && handleChooseEnvironment('detailEquipment');
  }

  function filterServices(service: PMOCServiceProps) {
    if (selectedServicesPmoc.length < 1) {
      return setSelectedServicesPmoc([service]);
    }
    const filterSelected  = selectedServicesPmoc.find(element => {
        return Number(element.id) === Number(service.id)
    }) ? selectedServicesPmoc.filter(element => { return Number(element.id) !== Number(service.id) }) : [
      ...selectedServicesPmoc,  service] as PMOCServiceProps[]

    setSelectedServicesPmoc(filterSelected);
  }

  function ValidateSelectedItemChecklist(id: number){
    return selectedServicesPmoc.find(service => Number(service.id) === Number(id))?true:false;
  }

  async function handleSelectedServices(){
    try {
      setIsLoading(true);
      const serviceIds = selectedServicesPmoc.map(element =>  {return Number(element.id)})

      await api.post(`pmoc/${initialPmocProps.id}/service/equipment/${equipment.id}`, {
        serviceIds
      });

      addToast({
        title: "Ok!",
        description: "Serviços vinculados ao equipamento com sucesso.",
        type: "success",
      });

      handleBackButton();
    } catch (err) {
      addToast({
        title: "Opss!",
        description:
          axios.isAxiosError(err) && err.response?.data.error
            ? err.response.data.error
            : "Não foi possível vincular os serviços ao equipamento.",
        type: "error",
      });
    } finally {
      setIsLoading(false);
    }
  }

  function handleCreateService() {
    handleSetSelectedItemChecklist([]);
    pushStackNavigation('servicesList');
    handleSetPmocService({} as PmocServiceProps);
    return handleChooseEnvironment && handleChooseEnvironment("createService");
  }

  async function handleEditService(idService:number) {
    try {
      setIsLoading(true);
      pushStackNavigation('servicesList');
      const {data} = await api.get(`/pmoc/service/${idService}`);
      handleSetPmocService(data as PmocServiceProps);
      return handleChooseEnvironment && handleChooseEnvironment("editService");
    } catch (err){
      addToast({
        title: "Opss!",
        description:
          axios.isAxiosError(err) && err.response?.data.error
            ? err.response.data.error
            : "Não foi possível carregar as informações do serviço.",
        type: "error",
      });
    } finally {
      setIsLoading(false);
    }
  
  }

  return (
    <>
      {isLoading && <LoadingProfiz isVisible={isLoading} />}
      <S.Container>

        <S.Content>
          <S.Top>
            <S.BackButton
              onClick={() => {
                handleBackButton();
              }}
            >
            <FiChevronLeft size={16} color={theme.colors.text} />
            
          </S.BackButton>
          <p>Serviços de PMOC</p>
          </S.Top>
          
          <SearchInput
            searchValue={searchInput}
          onChange={(e) => handleSearchService(e.target.value)}
          placeholder="Busque pelo nome do serviço"
          description="Abaixo estão listados os serviços de PMOC de acordo com a categoria do equipamento."
            handleCancel={handleSearchCancel}
          />
          
          <div style={{ marginBottom: "20px" }}>
          <Button typeButton="outline"
            onClick={()=> handleCreateService()}
          >
            Criar novo serviço de PMOC
          </Button>
          </div>

          {servicesPmoc.length === 0 && <EmptyPage />}
          {servicesPmoc.map((currentService) => (
            <PmocServiceContent
              key={currentService.id}
              isChecked={
                ValidateSelectedItemChecklist(Number(currentService.id))
              }
              isCheckbox
              isCheckedButtonClick
              currentService={currentService}
              onDeleteService={handleDeleteService}
              onEditServicePmoc={() => handleEditService(Number(currentService.id))}
              handlePressButton={()=>{filterServices(currentService)}}
            />
          ))}
        </S.Content>


        <S.Footer>
          {selectedServicesPmoc.length > 0 && <p>{selectedServicesPmoc.length} serviço(s) selecionado(s)</p>}
          <Button
            onClick={handleSelectedServices}
            disabled={selectedServicesPmoc.length<1}
          >
            Vincular ao equipamento
          </Button> 
        </S.Footer>

      </S.Container>
    </>
  );
}