import styled, { css } from 'styled-components'

export const Container = styled.div`
  ${({ theme }) => css`
    width: 100%;
    height: 100vh;
    max-width: 1400px;
    margin: 0 auto;
    padding: 30px;

    header {
      display: flex;
      align-items: center;
      margin-top: 30px;

      h1 {
        font-size: ${theme.font.sizes.large};
        color: ${theme.colors.title};
        font-weight: ${theme.font.bold};
      }

      div {
        width: 80px;
        display: flex;
        justify-content: space-between;
      }

      div + div {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        padding-left: 25px;
      }
    }
  `}
`

export const Content = styled.div`
  width: 46%;
  height: 80%;
  min-width: 470px;
  margin-top: 29px;
`

export const WrapperPreview = styled.div`
  ${({ theme }) => css`
    width: 100%;
    height: 46%;
    border-radius: 10px;
    background-color: ${theme.colors.background_modal};

    display: flex;
    justify-content: center;
    align-items: center;

    overflow: hidden;
  `}
`

export const PreViewCertificate = styled.img`
  ${({ theme }) => css`
    width: 100%;
    height: 100%;
    background-color: ${theme.colors.text};
    object-fit: contain;
    border-radius: 10px;
    transition: opacity 0.3s;

    &:hover {
      cursor: pointer;
      opacity: 0.7;
    }
  `}
`

export const WrapperDetails = styled.div`
  width: 100%;
  margin-top: 16px;
  display: flex;
  flex-direction: column;

  div {
    display: flex;
    justify-content: space-between;
    margin: 0 12px 10px;

    span {
      font-size: ${({ theme }) => theme.font.sizes.small};
      color: ${({ theme }) => theme.colors.text};
    }

    span:nth-child(1) {
      font-weight: ${({ theme }) => theme.font.bold};
      color: ${({ theme }) => theme.colors.title};
    }
  }
`

export const WrapperButton = styled.div`
  width: 100%;
  margin-top: 30px;
`
