import styled, { css, keyframes } from "styled-components";

const ModalScale = keyframes`
  from {
    transform: scale(0.5);
    opacity: 0.4;
  }
  to {
    transform: scale(1);
    opacity: 1;
  }
`;

export const ContainerOverlay = styled.aside`
  ${({ theme }) => css`
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;

    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: ${theme.layers.alwaysOnTop};
  `}
`;

export const BoxModal = styled.div`
  ${({ theme }) => css`
    width: 430px;
    padding: 38px;
    border-radius: 10px;
    background-color: ${theme.colors.menu_background};

    display: flex;
    align-items: center;
    flex-direction: column;

    animation: ${ModalScale} 0.3s ease-in-out;
    text-align: center;

    img {
      width: 125px;
      height: 112px;
      margin-top: 25px;
      margin-bottom: 25px;
    }
  `}
`;

export const Title = styled.h4`
  ${({ theme }) => css`
    font-family: ${theme.font.family};
    font-size: ${theme.font.sizes.large};
    font-weight: ${theme.font.bold};
    color: ${theme.colors.text_light_87};
  `}
`;
