import { EventsListItemProps } from "../types";

import * as S from "./styles";

import favoriteIcon from "assets/icons/events-icons/favoriteIcon.svg";
import notFavoriteIcon from "assets/icons/events-icons/notFavoriteIcon.svg";
import FallbackEvent from "assets/icons/event_fallback.svg";

import { useToast } from "hooks/toast";
import { useEvent } from "hooks/events";
import { api } from "services/api";

type CardProps = {
  element: EventsListItemProps;
  setFunction: any;
  typeView?: number; // 0 -> all events , 1 -> favorite events
};

const getMonthName = (idx: number) => {
  const names = [
    "Jan.",
    "Fev.",
    "Mar.",
    "Abr.",
    "Mai.",
    "Jun.",
    "Jul.",
    "Ago.",
    "Set.",
    "Out.",
    "Nov.",
    "Dez.",
  ];
  return names[idx];
};

const formatedAddressFunction = (event: EventsListItemProps) => {
  const streetNumberFormatted = !!event.address?.street
    ? !!event.address.number
      ? `${event.address.street}, ${event.address.number}`
      : `${event.address.street}`
    : "";

  const cityUfFormatted = !!event.address?.city
    ? !!event.address.uf
      ? `${event.address.city} - ${event.address.uf}`
      : `${event.address.city}`
    : "";

  const postalCodeFormatted = !!event.address?.postalCode
    ? `CEP: ${event.address.postalCode}`
    : "";

  const addressFormatted = !!event.address?.district
    ? `${streetNumberFormatted} - ${event.address.district} - ${cityUfFormatted}, ${postalCodeFormatted}`
    : `${streetNumberFormatted} ${cityUfFormatted} ${postalCodeFormatted}`;

  return addressFormatted;
};

export function CardEvent({ element, setFunction, typeView }: CardProps) {
  const { addToast } = useToast();

  const {
    allEvents,
    savedEvents,
    handleSetAllEvents,
    handleSetSavedEvents,
    selectedEvent,
    handleSetSelectedEvent,
  } = useEvent();

  const setFavorite = (element: EventsListItemProps) => {
    //se true-> deve-se desfavoritar
    //se false -> favoritar

    if ((selectedEvent && selectedEvent.id) === element.id) {
      let event = { ...selectedEvent, saved: !selectedEvent.saved };
      handleSetSelectedEvent(event);
    }

    return element.saved
      ? disfavorEvent(element.id)
      : favoriteEvent(element.id);
  };

  async function favoriteEvent(idElement: number) {
    try {
      //bate na api...
      await api.post(`/events/${idElement}/favorites/add`);

      //filtra o contexto...
      let dataAux: EventsListItemProps[] =
        typeView === 0 ? allEvents : savedEvents;
      dataAux = dataAux.map((element) => {
        return element.id === idElement
          ? {
              ...element,
              saved: !element.saved,
            }
          : element;
      });

      if (typeView === 1) {
        dataAux = dataAux.filter((element) => {
          return element.saved === true;
        });
      }

      //seta o contexto
      typeView === 0
        ? handleSetAllEvents(dataAux)
        : handleSetSavedEvents(dataAux);

      //mensagem toast
      addToast({
        title: "Ok!!",
        description: "Evento salvo na lista de favoritos.",
        type: "success",
      });
    } catch (error) {
      addToast({
        title: "Ops!!",
        description: "Erro ao favoritar o evento.",
        type: "error",
      });
    }
  }

  async function disfavorEvent(idElement: number) {
    try {
      //bate na api...
      await api.delete(`/events/favorites/${idElement}/remove`);
      //filtra o contexto...
      let dataAux: EventsListItemProps[] =
        typeView === 0 ? allEvents : savedEvents;
      dataAux = dataAux.map((element) => {
        return element.id === idElement
          ? {
              ...element,
              saved: !element.saved,
            }
          : element;
      });

      if (typeView === 1) {
        dataAux = dataAux.filter((element) => {
          return element.saved === true;
        });

        //caso esteja na tela de favoritos
        //remove elemento da lista de salvos
        //remove os dados dos detalhes
        //caso esteja aberto
        if (selectedEvent && selectedEvent.id === idElement) {
          handleSetSelectedEvent(null);
        }
      }

      //seta o contexto
      typeView === 0
        ? handleSetAllEvents(dataAux)
        : handleSetSavedEvents(dataAux);

      //mensagem toast
      addToast({
        title: "Ok!!",
        description: "Evento retirado da lista de favoritos.",
        type: "success",
      });
    } catch (error) {
      addToast({
        title: "Ops!!",
        description: "Erro ao desfavoritar o evento.",
        type: "error",
      });
    }
  }

  return (
    <>
      <S.CardItem
        onClick={() => {
          setFunction(element.id);
        }}
      >
        <S.WrapperDataIconCard>
          <h5>{new Date(element.date).getDate()}</h5>

          <p>{getMonthName(new Date(element.date).getMonth())}</p>

          <S.WrapperFavButton
            onClick={(event) => {
              //favoritar...
              setFavorite(element);
              event.stopPropagation();
            }}
          >
            <img
              src={element.saved ? favoriteIcon : notFavoriteIcon}
              alt="imagem"
            />
          </S.WrapperFavButton>
        </S.WrapperDataIconCard>

        <S.WrapperMid>
          <S.HeaderTitle>{element.title}</S.HeaderTitle>

          {element.modality !== "online" && element.address ? (
            <p>
              {/* {element.address.street}, {element.address.number} -{" "}
              {element.address.district} - <br />
              CEP: {element.address.postalCode} */}
              {formatedAddressFunction(element)}
            </p>
          ) : (
            <p>Evento online.</p>
          )}
        </S.WrapperMid>

        <S.WrapperImg>
          {!!element.imagemMiniature ? (
            <img
              src={element.imagemMiniature}
              alt="imagem miniatura do evento"
            />
          ) : (
            <img src={FallbackEvent} alt="imagem miniatura default" />
          )}
        </S.WrapperImg>
      </S.CardItem>
    </>
  );
}
