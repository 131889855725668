import styled, { css } from "styled-components";

export const Container = styled.div`
  ${({ theme }) => css`
    margin-bottom: 30px;
    width: 100%;
    max-width: 1400px;
    padding: 0 70px;

    margin-top: 20px;

    header {
      display: flex;
      flex-direction: row;
      margin: 20px 0;
      width: 50%;
      justify-content: space-between;
    }

    div.content {
      width: 50%;
    }

    div.list {
      margin-top: 30px;
      margin-bottom: 50px;
    }
  `}
`;

export const Title = styled.h1`
  ${({ theme }) => css`
    font-size: ${theme.font.sizes.large};
    color: ${theme.colors.title};
    font-weight: ${theme.font.bold};
  `}
`;

export const Wrapper = styled.div`
  margin-bottom: 20px;
`;

export const WrapperStatusOS = styled.div`
  ${({ theme }) => css`
    width: 50%;
    margin: 12px 0 16px;

    display: flex;
    flex-direction: row;
    justify-content: space-between;

    & span {
      font-size: ${theme.font.sizes.xsmall};
      color: ${theme.colors.text_extra_light};
    }

    & > div {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 6px;
    }
  `}
`;

export const BadgeStatus = styled.div`
  ${({ theme }) => css`
    height: 8px;
    width: 8px;
    background: ${theme.colors.primary};
    border-radius: 8px;
  `}
`;

export const CardInfo = styled.div`
  ${({ theme }) => css`
      background: ${theme.colors.background_modal};
      border-radius: 8px;
      padding:15px;
      margin-bottom:5px;
      display:flex;
      .title {
        font-size: ${theme.font.sizes.small};
        color: ${theme.colors.text_extra_light};
        font-weight: ${theme.font.bold};
      }
    }
  `}
`;

export const Subtitle = styled.p`
  ${({ theme }) => css`
    font-size: 12px;
    color: rgba(255, 255, 255, 0.6);
    font-weight: ${theme.font.normal};
    margin-top: 10px;

    strong {
      color: rgba(255, 255, 255, 0.87);
      font-weight: ${theme.font.bold};
    }
  `}
`;

export const WrapperButtons = styled.div`
  margin-top: 20%;
`;

type OSCardProps = {
  status: string;
};

export const OSCard = styled.div<OSCardProps>`
  ${({ theme, status }) => css`
    background: ${theme.colors.background_modal};
    border-radius: 8px;
    padding: 15px;
    margin-bottom: 5px;

    display: flex;
    flex-direction: row;
    justify-content: space-between;
    cursor: default;

    .text-container {
      .title {
        font-size: 12px;
        color: rgba(255, 255, 255, 0.87);
        font-weight: ${theme.font.normal};
        margin-bottom: 10px;
      }
      .text-info {
        font-size: 12px;
        color: rgba(255, 255, 255, 0.6);
        font-weight: ${theme.font.normal};
        margin-top: 10px;
      }
    }

    .container-ball {
      display: flex;
      flex-direction: row;

      .element-ball {
        height: 8px;
        width: 8px;
        background: ${status === "pending" ? "#FA8B94" : theme.colors.primary};
        border-radius: 8px;
        margin-right: 5px;
        margin-top: 3px;
      }
      .text-status {
        font-size: 12px;
        color: rgba(255, 255, 255, 0.6);
        font-weight: ${theme.font.normal};
      }
    }
  `}
`;

export const ChecklistClick = styled.div`
  ${({ theme }) => css`
    text-decoration: underline;
    color: ${theme.colors.primary};
    cursor: pointer;
    font-size: 14px;
    margin-top: 10px;
  `}
`;

export const WrapperSignature = styled.div`
  ${({ theme }) => css`
    margin-top: 10px;
  `}
`;
