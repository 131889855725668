import React, { useState } from "react";
import * as Yup from "yup";

import { Button } from "components/Button";
import { CommentsTextArea } from "components/CommentsTextArea";

import { useToast } from "hooks/toast";
import getValidationErrors from "utils/getValidationErrors";

import * as S from "./styles";
import apiv2 from "services/apiv2";

type Errors = {
  [key: string]: string;
};

type Props = {
  handleToggleOpen: () => void;
};

export function CreateNewObservation({ handleToggleOpen }: Props) {
  const { addToast } = useToast();

  const [value, setValue] = useState("");
  const [loading, setLoading] = useState(false);
  const [hasError, setHasError] = useState<Errors>({} as Errors);

  async function handlePostTextDefault() {
    try {
      const schema = () => {
        return Yup.object().shape({
          value: Yup.string().required("Texto padrão é obrigatório"),
        });
      };

      await schema().validate({ value }, { abortEarly: false });

      setLoading(true);

      await apiv2.post("/budgets/default-observations", {
        message: value.trim(),
      });

      addToast({
        title: "Sucesso",
        description: "Mensagem criada com sucesso",
        type: "success",
      });

      handleToggleOpen();
      setHasError({} as Errors);
    } catch (error) {
      if (error instanceof Yup.ValidationError) {
        const errors = getValidationErrors(error);
        setHasError(errors);
        return;
      }

      addToast({
        title: "Ops, erro ao criar um novo texto padrão",
        type: "error",
        description: "",
      });
    } finally {
      setLoading(false);
      setValue("");
    }
  }

  return (
    <S.Container>
      <CommentsTextArea
        value={value}
        onChange={(e) => setValue(e.target.value.trimStart())}
        hasError={hasError.value}
        onFocusClearError={() => setHasError({} as Errors)}
        style={{ height: "220px" }}
      />

      <div>
        <Button loading={loading} onClick={() => handlePostTextDefault()}>
          Salvar novo texto padrão
        </Button>
      </div>
    </S.Container>
  );
}
