import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { useHistory } from "react-router";
import "react-tabs/style/react-tabs.css";

import { ArrowButton } from "components/ArrowButton";
import { MyProducts } from "./MyProducts";

import * as S from "./styles";
import Plus from "assets/plus.svg";
import { useState } from "react";

const Products = () => {
  const history = useHistory();
  const [isOpenFormProducts, setIsOpenFormProducts] = useState(false);

  return (
    <S.Container>
      <header>
        <div>
          <ArrowButton handleFunction={() => history.push("/home")} />
          <h1>Meus produtos</h1>
        </div>

        <S.ButtonAdd
          onClick={() => {
            setIsOpenFormProducts(true);
          }}
        >
          <img src={Plus} alt="+" />
          Novo produto
        </S.ButtonAdd>
      </header>

      <S.TabContainer>
        <Tabs>
          <TabList>
            <Tab>Meus Produtos</Tab>
          </TabList>

          <TabPanel>
            <MyProducts
              handleOpenModalCreateProducts={setIsOpenFormProducts}
              openModalCreateProducts={isOpenFormProducts}
            />
          </TabPanel>
        </Tabs>
      </S.TabContainer>
    </S.Container>
  );
};
export { Products };
