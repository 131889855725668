import React, { createContext, useContext, useState } from "react";

import {
  EnvironmentDTO,
  DetailEquipmentDTO,
  ServiceProps,
  ItemEnvironmentDTO,
  ListEquipment,
  PmocServiceProps,
} from "../../dtos/PmocEnvironmentDTO";
import { Page } from "pages/pmoc/EnvironmentFlow/types";
import { ProviderProps } from "hooks";

type EnvironmentContextData = {
  nameFlow: string;
  handleSetNameFlow: (name: string) => void;
  beforePage: string;
  handleSetBeforePage: (name: string) => void;
  handleClearBeforePage: () => void;
  beforePageChecklist: string;
  handleSetBeforePageChecklist: (name: string) => void;

  stackNavigation: Page[];
  pushStackNavigation: (namePage: Page) => void;
  popStackNavigation: () => string;
  popAndDeleteStackNavigation: () => string;

  handleClearBeforePageChecklist: () => void;
  equipmentId: number;
  handleSetEquipmentId: (id:number) => void;
  pmocServiceId: number;
  handleSetPmocServiceId: (id: number) => void;
  environmentId: number;
  handleSetEnvironmentId: (id: number) => void;
  environment: EnvironmentDTO;
  userIdPMOC: number;
  handleSetUserIdPMOC: (value: number) => void;
  unityIdPMOC: number;
  handleSetUnityIdPMOC: (value: number) => void;
  handleSetEnvironment: (current: EnvironmentDTO) => void;
  equipment: DetailEquipmentDTO;
  handleSetEquipment: (current: DetailEquipmentDTO) => void;
  handleRemoveEquipment: (idEquipment: number) => void;
  handleReset: () => void;
  environmentList: ItemEnvironmentDTO[];
  handleSetEnvironmentList: (listEnv: ItemEnvironmentDTO[]) => void;
  serviceListEqt: ServiceProps[];
  handleServiceListEqt: (listServices: ServiceProps[]) => void;
  pmocService: PmocServiceProps;
  handleSetPmocService: (current: PmocServiceProps) => void;
};

const EnvironmentContext = createContext<EnvironmentContextData>(
  {} as EnvironmentContextData
);

export function useEnvironment(): EnvironmentContextData {
  const context = useContext(EnvironmentContext);

  if (!context) {
    throw Error("useClient must be used within a EnvironmentPmocProvider");
  }
  return context;
}

export const EnvironmentPmocProvider = ({ children }: ProviderProps) => {
  const [pmocService, setPmocService] = useState<PmocServiceProps>(
    {} as PmocServiceProps
  );

  const [environment, setEnvironment] = useState<EnvironmentDTO>(
    {} as EnvironmentDTO
  );

  const [equipment, setEquipment] = useState<DetailEquipmentDTO>(
    {} as DetailEquipmentDTO
  );

  const [nameFlow, setFlow] = useState("pmoc");
  const [environmentList, setEnvList] = useState([] as ItemEnvironmentDTO[]);
  const [serviceListEqt, SetServiceList] = useState([] as ServiceProps[]);
  const [userIdPMOC, setUser] = useState(0);
  const [unityIdPMOC, setUnity] = useState(0);
  const [beforePage, setBeforePage] = useState("");
  const [pmocServiceId, setPmocServiceId] = useState(0);
  const [environmentId, setEnvironmentId] = useState(0);
  const [equipmentId, setEquipmentId] = useState(0);
  const [beforePageChecklist, setBeforePageChecklist] = useState("");
  const [stackNavigation, setStackNavigation] = useState<Page[]>([] as Page[]);

  function pushStackNavigation(namePage: Page) {
    setStackNavigation([...stackNavigation, namePage]);
  };
  function popStackNavigation() {
    let pageName = stackNavigation[stackNavigation.length - 1];
    return pageName;
  }

    function popAndDeleteStackNavigation() {
    let pageName = stackNavigation[stackNavigation.length - 1];
    setStackNavigation(stackNavigation.slice(0, stackNavigation.length - 1));
    return pageName;
  }

  function handleSetEquipmentId(id: number) {
    setEquipmentId(id);
  }

  function handleSetEnvironmentId(id: number) {
    setEnvironmentId(id);
  }

  function handleSetPmocServiceId(value: number) {
    setPmocServiceId(value);
  }

  function handleSetBeforePage(name: string) {
    setBeforePage(name);
  }

  function handleClearBeforePage() {
    setBeforePage("");
  }

  function handleSetBeforePageChecklist(name: string) {
    setBeforePageChecklist(name);
  }

  function handleClearBeforePageChecklist() {
    setBeforePageChecklist("");
  }

  function handleSetUserIdPMOC(value: number) {
    setUser(value);
  }

  function handleSetUnityIdPMOC(value: number) {
    setUnity(value);
  }

  function handleServiceListEqt(listServices: ServiceProps[]) {
    SetServiceList(listServices);
  }

  function handleSetEnvironmentList(listEnv: ItemEnvironmentDTO[]) {
    setEnvList(listEnv);
  }

  function handleSetNameFlow(name: string) {
    setFlow(name);
  }

  function handleSetPmocService(current: PmocServiceProps) {
    setPmocService(current);
  }

  function handleSetEnvironment(current: EnvironmentDTO) {
    setEnvironment(current);
  }
  function handleSetEquipment(current: DetailEquipmentDTO) {
    setEquipment(current);
  }

  function handleRemoveEquipment(idEquipment: number) {
    if (
      idEquipment &&
      environment.equipments &&
      environment.equipments.length > 0
    ) {
      let aux = environment;

      aux.equipments = environment.equipments.filter(
        (element: ListEquipment) => {
          return element.id !== idEquipment;
        }
      );

      return setEnvironment(aux);
    }
  }

  function handleReset() {
    handleSetEnvironmentList([] as ItemEnvironmentDTO[]);
    setEnvironment({} as EnvironmentDTO);
    setEquipment({} as DetailEquipmentDTO);
    setFlow("pmoc");
  }

  return (
    <EnvironmentContext.Provider
      value={{
        nameFlow,
        userIdPMOC,
        handleSetUserIdPMOC,
        unityIdPMOC,
        handleSetUnityIdPMOC,
        environment,
        equipment,
        environmentList,
        handleSetEnvironment,
        handleSetEquipment,
        handleReset,
        handleRemoveEquipment,
        handleSetNameFlow,
        handleSetEnvironmentList,
        serviceListEqt,
        handleServiceListEqt,
        pmocService,
        handleSetPmocService,
        beforePage,
        handleSetBeforePage,
        handleClearBeforePage,
        beforePageChecklist,
        handleSetBeforePageChecklist,
        handleClearBeforePageChecklist,
        pmocServiceId,
        handleSetPmocServiceId,
        environmentId,
        handleSetEnvironmentId,
        equipmentId,
        handleSetEquipmentId,
        stackNavigation,
        pushStackNavigation,
        popStackNavigation,
        popAndDeleteStackNavigation,
      }}
    >
      {children}
    </EnvironmentContext.Provider>
  );
};
