import React from 'react'

import * as S from './styles'

export function InsertCertificatesShimmer() {
  return (
    <>
      <div>
        <S.ShimmerDropDownTitle />
        <S.ShimmerDropDown />
      </div>

      <div>
        <S.ShimmerDropDownTitle />
        <S.ShimmerDropDown />
      </div>

      <div>
        <S.ShimmerDropDownTitle />
        <S.ShimmerDropDown />
      </div>

      <div>
        <S.ShimmerDropDown />
      </div>

      <div>
        <S.ShimmerDropDownDetail />
      </div>

      <div>
        <S.ShimmerDropDown />
      </div>
    </>
  )
}
