import styled, { css } from "styled-components";



type ContainerProps = {
    enableColor?:string;
    disableColor?:string;
    buttonColor?:string;
    isChecked:boolean;
}

export const Container = styled.div<ContainerProps>`
${({ theme, buttonColor,isChecked, disableColor, enableColor }) => css`
input {
    appearance: none;
    min-width: 48px;
    min-height: 22px;
    margin: 0;

    position: relative;
    background-color: ${disableColor?disableColor:theme.colors.background_modal};
    border-radius: 32px;
    border: 1px solid ${disableColor?disableColor:theme.colors.background_modal};

    cursor: pointer;
    user-select: none;
    transition: background-color 0.3s,
                border-color 0.3s;

    &:checked {
        background-color: ${enableColor?enableColor:theme.colors.financial_card};
        border-color: ${enableColor?enableColor:theme.colors.financial_card};


        &::before {
            left: unset;
            transform: translateX(calc(100% + 12px));
        }

        &::after {  
            content: attr(data-on);
            left: 0.4em;
            right: unset;
        }
    }

    &::before {
        content: '';
        position: absolute;
        height: 16px;
        width: 16px;
        top: 2px;
        left: 2px;
        bottom: 2px;
        background-color: ${isChecked? buttonColor?buttonColor:theme.colors.primary:"white"};
        transition: all 0.3s;
        border-radius: 50%;
        cursor: pointer;
        z-index: 1;
    }

    &::after {
        content: attr(data-off);
        display: block;
        position: absolute;
        font-size: 10px;
        line-height: 1;
        top: 0.5em;
        right: 0.4em;
        bottom: 0.5em;
    }
    
    &:focus {
        outline: 2px solid #222222;
        outline-offset: 2px;
    }
}


`}
`;






