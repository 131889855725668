import styled, { css } from 'styled-components'

export const Container = styled.div`
  margin-top: 24px;
`

export const Label = styled.p`
  ${({ theme }) => css`
    font-size: ${theme.font.sizes.xsmall};
    margin-bottom: 5px;
    text-transform: capitalize;
    color: ${theme.colors.title};
  `}
`

export const CardPaymentConditions = styled.div`
  ${({ theme }) => css`
    width: 100%;
    min-height: 85px;
    background-color: ${theme.colors.background_light};
    border-radius: 10px;

    display: flex;
    justify-content: space-between;
  `}
`

export const WrapperContent = styled.div`
  width: 80%;
  display: flex;
  flex-direction: column;
  padding: 15px;
`

export const WrapperPaymentText = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;

  &:first-child {
    margin-bottom: 1px;
  }
`

export const WrapperInstallment = styled.div`
  width: 100%;
  margin: 8px 0 10px;
`

export const PaymentText = styled.span`
  ${({ theme }) => css`
    font-size: ${theme.font.sizes.xsmall};
    color: ${theme.colors.text};
  `}
`

export const WrapperActions = styled.div`
  ${({ theme }) => css`
    min-width: 47px;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    background-color: ${theme.colors.background_light};

    display: flex;
    flex-direction: column;
    justify-content: space-between;
  `}
`
